import { List, fromJS, Map } from "immutable";
import { createAsyncReducer } from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";
import { combineReducers } from "redux-immutable";

export const FETCH_PERMISSION_LIST = "PERMISSION/FETCH_PERMISSION_LIST";
export const FETCH_ROLES_LIST = "PERMISSION/FETCH_ROLE_LIST";
export const FETCH_ROLE_BY_ID = "PERMISSION/FETCH_ROLE_BY_ID";

const selector = injectReducer(
  "permission_management",
  combineReducers({
    permissions: createAsyncReducer(
      FETCH_PERMISSION_LIST,
      Map({ isLoading: false, data: List() }),
      {
        pending: state => state.merge({ isLoading: true }),
        fulfilled: (state, action) =>
          state.merge({
            isLoading: false,
            data: fromJS(action.payload.data.data),
          }),
      },
    ),
    roles: createAsyncReducer(
      FETCH_ROLES_LIST,
      Map({ isLoading: false, data: Map({ list: List(), total: 0 }) }),
      {
        pending: state => state.merge({ isLoading: true }),
        fulfilled: (state, action) =>
          state.merge({
            isLoading: false,
            data: fromJS(action.payload.data.data),
          }),
        rejected: state => state.merge({ isLoading: false }),
      },
    ),
    role: createAsyncReducer(
      FETCH_ROLE_BY_ID,
      Map({ isLoading: false, data: Map() }),
      {
        pending: state => state.merge({ isLoading: true }),
        fulfilled: (state, action) =>
          state.merge({
            isLoading: false,
            data: fromJS(action.payload.data.data),
          }),
      },
    ),
  }),
);

export function getRoleList(state) {
  return selector(state).getIn(["roles", "data"]);
}

export function getRolesLoading(state) {
  return selector(state).getIn(["roles", "isLoading"]);
}

export function getPermissionList(state) {
  return selector(state).getIn(["permissions", "data"]);
}

export function isPermissionLoading(state) {
  return selector(state).getIn(["permissions", "isLoading"]);
}

export function getRole(state) {
  return selector(state).getIn(["role", "data"]);
}

export function getRoleLoading(state) {
  return selector(state).getIn(["role", "isLoading"]);
}
