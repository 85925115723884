import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { renderIf } from "../../../helpers/HOCUtils";
import ModalPaper from "../../../components/ui-core/ModalPaper";
import FlexBox from "../../../components/ui-core/FlexBox";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../../api/admin/AdminServiceTypesApi";
import OrderFilterFormForOffline from "../../../components/orders-core/OrderFilterFormForOffline";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      "@media (max-width: 998px)": {
        minWidth: "300px",
      },
    },
  }),
);

AdminOfflineOrderFilterWrapperForOrderList.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  closeDialog: PropTypes.func,
  setIsSelected: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  sorting: PropTypes.bool,
};

function AdminOfflineOrderFilterWrapperForOrderList({
  classes,
  filter,
  getLocalisationMessage,
  onFilterChange,
  onRequestClose,
  open,
  setFilter,
  closeDialog,
  sorting,
  setIsSelected,
}) {
  return (
    <ModalPaper
      open={open}
      paperClassName={classes.paper}
      title={
        <FlexBox flex={true}>
          <FlexBox>
            {sorting
              ? getLocalisationMessage("filter")
              : getLocalisationMessage("order_filter")}
          </FlexBox>
        </FlexBox>
      }
      onRequestClose={onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <OrderFilterFormForOffline
          filter={filter}
          setIsSelected={setIsSelected}
          setFilter={setFilter}
          closeDialog={closeDialog}
          onDismiss={onRequestClose}
          onFilterChange={onFilterChange}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedServiceType={getCachedServiceType}
          getServiceTypePredictions={getServiceTypePredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOfflineOrderFilterWrapperForOrderList);
