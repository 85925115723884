import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { batchUpdatePickupDriver } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import BatchUpdateOrderPickupDriverDialog from "../../components/orders-core/BatchUpdateOrderPickupDriverDialog";

AdminBatchUpdateOrderPickupDriverDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

export default function AdminBatchUpdateOrderPickupDriverDialogWrapper(props) {
  return (
    <BatchUpdateOrderPickupDriverDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      initialValues={props.initialValues}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchUpdatePickupDriver(values).catch(ResponseError.throw),
      )}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
    />
  );
}
