import React from "react";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withHandlers,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FormWarehouseDialog from "../../components/form/FormWarehouseDialog";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { Map, Set } from "immutable";
import {
  addBatchSortingTaskOrderNumber,
  clearOrderSortingBatchOrders,
  getOrderSortingTask,
  getOrderSortingTaskBatchRootOrder,
  updateCursorOrder,
  updateSortingTask,
} from "../../reducers/OrderBinValidationReducer";
import { mergeSideEffectStreams, pipeStreams } from "../../helpers/StreamUtils";
import { OrderBinValidationDB } from "../../firebase/OrderBinValidationDB";
import OrderSortingBinValidationForm from "../../components/order-sorting-bin-validation/OrderSortingBinValidationForm";
import { Card, CardContent, CardHeader, MenuItem } from "@material-ui/core";
import { Observable } from "rxjs";
import { isEqualData } from "../../helpers/DataUtils";
import FlexBox, {
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import fp from "lodash/fp";
import OrderSortingBinDetailsCard from "../../components/order-sorting-bin-validation/OrderSortingBinDetailsCard";
import OrderSortingBinHeirarchyCard from "../../components/order-sorting-bin-validation/OrderSortingBinHeirarchyCard";
import FirebaseOfflineDialog from "../../components/firebase/FirebaseOfflineDialog";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import {
  batchBarcodesStatusUpdate,
  batchUpdateOrderWarehouse,
  getBarcode,
  getBatchChildren,
  updateBatchStatusSorting,
} from "../../api/admin/AdminOrderApi";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  IN_SORTING_FACILITY,
  LOST_OR_DAMAGED,
  MISROUTED,
  PREPARED_FOR_TRANSIT,
  PROCESSING_AT_WAREHOUSE,
} from "../../constants/OrderStatusCodes";
import { parseStringNotUnique } from "../../helpers/SerializeUtils";
import { green, grey, orange, red } from "@material-ui/core/colors";
import { CheckCircle, HourglassEmpty, ReportProblem } from "@material-ui/icons";
import { OrderSortingDB } from "../../firebase/OrderSortingDB";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  bottomSpace: {
    marginBottom: 8,
    minHeight: 220,
  },
  iconsWrapper: {
    flexWrap: "wrap",
    "& > *": {
      flex: "1 1 auto",
    },
  },
}));

// eslint-disable-next-line no-unused-vars
const shouldOpen = (order, warehouseId) =>
  order.getIn(["to_warehouse", "id"]) === warehouseId;

const changeCursorOrder = ({
  db,
  id,
  cursor,
  assignToWarehouse,
  getLocalisationMessage,
  ...props
}) =>
  Observable.defer(() => db.getBatchQueue())
    .take(1)
    .switchMap((queueOrders) => {
      // let taskStream = db.removeTask(id);

      if (queueOrders.size > 0) {
        const firstQueue = queueOrders.keySeq().toSet().first();
        props.updateCursorOrder(firstQueue);

        props.showSuccessMessage(
          `${cursor} ${getLocalisationMessage(
            "has_been_finished_moved_to",
            "has been finished. Moved to",
          )} ${firstQueue}`,
        );

        // if (assignToWarehouse) {
        //   return Observable.merge(
        //     db.updateBatchStatus(
        //       cursor,
        //       {
        //         warehouse_id: props.warehouseId,
        //         status: IN_SORTING_FACILITY,
        //       },
        //       false,
        //     ),
        //     db.removeTask(id),
        //     db.removeBatchQueue(firstQueue),
        //   );
        // }

        return Observable.merge(
          db.removeTask(id),
          db.removeBatchQueue(firstQueue),
        );
      }

      props.showSuccessMessage(
        getLocalisationMessage(
          "the_validation_process_has_been_finished",
          "The Validation Process has been finished.",
        ),
      );
      props.updateCursorOrder(null);

      // if (assignToWarehouse) {
      //   return Observable.merge(
      //     db.removeTask(id),
      //     db.updateBatchStatus(
      //       cursor,
      //       {
      //         warehouse_id: props.warehouseId,
      //         status: IN_SORTING_FACILITY,
      //       },
      //       false,
      //     ),
      //   );
      // }

      return db.removeTask(id);
    });

const generatedStatus = (order) => {
  if (fp.get("wrong_location", order)) return MISROUTED;

  if (!fp.get("scanned", order)) return LOST_OR_DAMAGED;

  if (!fp.get("info.type", order)) return IN_SORTING_FACILITY;

  return PREPARED_FOR_TRANSIT;
};

const forceCompleteTask = (props) => {
  const { db, id, task, sortingDB } = props;

  const order = fp.get("forceComplete.details.order", task);
  const assignToWarehouse = fp.get("forceComplete.assignToWarehouse", task);
  const statuses = Map().asMutable();

  if (!assignToWarehouse)
    return changeCursorOrder({
      ...props,
      db,
      id,
      cursor: order.number,
      assignToWarehouse,
    });

  // Check status of parent order first
  // const parentStatus = generatedStatus(order) || IN_SORTING_FACILITY;
  // if (parentStatus) {
  //   if (!statuses.has(parentStatus)) {
  //     statuses.set(
  //       parentStatus,
  //       Map({
  //         status: parentStatus,
  //         barcodes: Set(),
  //         ids: Set(),
  //       }),
  //     );
  //   }
  //
  //   statuses.updateIn([parentStatus, "barcodes"], x => x.add(order.number));
  //   statuses.updateIn([parentStatus, "ids"], x => x.add(order.id));
  // }

  const children = fp.get("children", order);
  const sortedShipments = {};

  if (children) {
    children.forEach((child) => {
      const status = generatedStatus(child);

      if (status) {
        if (!statuses.has(status)) {
          statuses.set(
            status,
            Map({
              status,
              barcodes: Set(),
              ids: Set(),
            }),
          );
        }

        if (status === IN_SORTING_FACILITY) {
          sortedShipments[`${child.number}/number`] = fp.get(
            "info.barcode",
            child,
          );
        }

        statuses.updateIn([status, "barcodes"], (x) => x.add(child.number));
        statuses.updateIn([status, "ids"], (x) => x.add(child.id));
      }
    });
  }

  const statusesArray = statuses.asMutable().valueSeq().toSet();

  return Observable.defer(() =>
    statusesArray.size > 0
      ? batchBarcodesStatusUpdate(statusesArray.toJS()).catch((err) =>
          Observable.of(err),
        )
      : Observable.of(null),
  )
    .switchMap((response) => {
      if (!fp.isError(response) && sortedShipments) {
        return Observable.merge(sortingDB.batchUpdateOrders(sortedShipments));
      }

      return Observable.of(null);
    })
    .switchMap(() =>
      changeCursorOrder({
        ...props,
        db,
        id,
        assignToWarehouse,
        cursor: order.number,
      }),
    )
    .catch(() => db.removeTask(id));
};

const shipmentStatusUpdate = (props) => {
  const { db, id, task } = props;

  return Observable.defer(() =>
    batchUpdateOrderWarehouse(task.shipment_status_update),
  )
    .switchMap(() => db.removeTask(id))
    .catch(() => db.removeTask(id));
};

const batchStatusUpdate = (props) => {
  const { db, id, task } = props;

  return Observable.defer(() =>
    updateBatchStatusSorting(task.batch_status_update),
  )
    .switchMap(() => Observable.merge(db.removeTask(id)))
    .catch(() => db.removeTask(id));
};

const getBatchChildrenTask = (props) => {
  const { db, id, order } = props;

  return Observable.defer(() => getBatchChildren(order.getIn(["info", "id"])))
    .switchMap(({ children }) => {
      const values = {};
      const parentValue = {};
      if (children) {
        // TODO: code should be changed to barcode
        children.forEach((child) => {
          values[`${child.barcode}/number`] = child.barcode;
          values[`${child.barcode}/id`] = child.id;
          values[`${child.barcode}/parent_id`] = order.get("number");
        });
        parentValue[`${order.get("number")}/child_count`] = children.length;
      }
      return Observable.merge(
        db.removeTask(id),
        db.updateBatchOrders(parentValue),
        db.updateBatchOrders(values),
      );
    })
    .catch((error) => db.updateTask(id, { error: error.message }));
};

const checkIfCursorBatchCompleted = (props) => {
  const {
    db,
    id,
    order,
    sortingDB,
    task: {
      completed: { assignToWarehouse },
    },
  } = props;

  return Observable.defer(() =>
    db.getBatchOrderScannedChildren(order.get("number")),
  )
    .take(1)
    .switchMap((children) => {
      if (children.size === order.get("child_count")) {
        return Observable.defer(() =>
          db.getBarcodeFromTree(order.get("number")),
        )
          .take(1)
          .switchMap((response) => {
            const scannedChildren = parseStringNotUnique(response.val());
            const sortedShipments = {};

            if (scannedChildren) {
              const statuses = Map().asMutable();

              scannedChildren.forEach((child) => {
                let status = MISROUTED;
                if (children.has(child)) {
                  const childMoreInfo = children.get(child).toJS();
                  status = generatedStatus(childMoreInfo);

                  if (status === IN_SORTING_FACILITY) {
                    const barcode = fp.get("info.barcode", childMoreInfo);
                    sortedShipments[`${barcode}/number`] = barcode;
                  }
                }

                if (!statuses.has(status)) {
                  statuses.set(
                    status,
                    Map({
                      status,
                      barcodes: Set(),
                    }),
                  );
                }
                statuses.updateIn([status, "barcodes"], (x) => x.add(child));
              });

              const statusesArray = statuses.asMutable().valueSeq().toSet();

              return Observable.defer(() =>
                statusesArray.size > 0
                  ? batchBarcodesStatusUpdate(statusesArray.toJS()).catch(
                      (err) => Observable.of(err),
                    )
                  : Observable.of({}),
              )
                .switchMap((res) => {
                  if (!fp.isError(res) && sortedShipments) {
                    return Observable.merge(
                      sortingDB.batchUpdateOrders(sortedShipments),
                    );
                  }

                  return Observable.of(null);
                })
                .switchMap(() =>
                  changeCursorOrder({
                    ...props,
                    db,
                    id,
                    assignToWarehouse,
                    cursor: order.get("number"),
                  }),
                )
                .catch(() => db.removeTask(id));
            }

            return changeCursorOrder({
              ...props,
              db,
              id,
              assignToWarehouse,
              cursor: order.get("number"),
            });
          });
      }

      return db.removeTask(id);
    })
    .catch((error) => db.updateTask(id, { error: error.message }));
};

const enhancer = compose(
  withTheme,
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    (state) => {
      const task = getOrderSortingTask(state);
      return {
        task,
        warehouseId: task.getIn(["warehouse", "id"]),
        cursorOrder: getOrderSortingTaskBatchRootOrder(state),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      updateSortingTask,
      addBatchSortingTaskOrderNumber,
      updateCursorOrder,
      clearOrderSortingBatchOrders,
    },
  ),
  withHandlers({
    scanBarcodes:
      (props) =>
      ({ orderNumbers, cursorOrder, assignToWarehouse, scannedOrderDetails }) =>
        getBarcode(orderNumbers[0])
          .takeLast(1)
          .let(mapObjectResponseStream)
          .map((x) => x.get("payload"))
          .switchMap((order) => {
            const db = new OrderBinValidationDB(props.warehouseId);

            const values = {};
            const orderNumber = orderNumbers[0];
            const alreadyScanned =
              scannedOrderDetails &&
              scannedOrderDetails.get("info") &&
              order &&
              order.has("type") &&
              shouldOpen(order, props.warehouseId);

            values[`${orderNumber}/failed`] = null;
            values[`${orderNumber}/info`] = order.toJS();
            values[`${orderNumber}/hash`] = order.hashCode();
            values[`${orderNumber}/hash_time`] = Date.now();

            if (!alreadyScanned && cursorOrder) {
              values[`${orderNumber}/scanned_parent_id`] = cursorOrder;
            }

            if (!order) {
              values[`${orderNumber}/failed`] = true;
            }

            let taskStream = db.updateBatchOrders(values);

            if (alreadyScanned) {
              props.updateCursorOrder(orderNumber);
              return taskStream
                .merge(db.addBatchQueue(cursorOrder))
                .merge(db.removeBatchQueue(orderNumber));
            }

            // Considers It is as single shipment
            // if (order && !order.has("type")) {
            //   // Change the status of the shipment as In sorting Warehouse
            //   if (
            //     (!cursorOrder || cursorOrder === order.get("parent_id")) &&
            //     assignToWarehouse
            //   ) {
            //     return taskStream.merge(
            //       db.updateShipmentStatus(orderNumber, {
            //         order_numbers: orderNumber,
            //         order_status: IN_SORTING_FACILITY,
            //         warehouse: { id: props.warehouseId },
            //       }),
            //     );
            //   }
            // }

            // Check the order is BATCH
            if (order && order.has("type")) {
              // Check if the batch should be opened
              if (!shouldOpen(order, props.warehouseId) && assignToWarehouse)
                return taskStream.merge(
                  db.updateBatchStatus(
                    orderNumber,
                    {
                      warehouse_id: props.warehouseId,
                      status: PREPARED_FOR_TRANSIT,
                    },
                    true,
                  ),
                );

              // Get Batch Child Orders
              taskStream = taskStream.merge(db.addBarcodeChildren(orderNumber));

              // if CURSOR order is exists, add the batch to the QUEUE
              if (cursorOrder) {
                return taskStream.merge(db.addBatchQueue(orderNumber));
              }

              // Update order as CURSOR if the batch should be open
              props.updateCursorOrder(orderNumber);

              // the CURSOR not exists and current batch will be Processed at Warehouse
              return assignToWarehouse
                ? taskStream.merge(
                    db.updateBatchStatus(
                      orderNumber,
                      {
                        id: order.get("id"),
                        warehouse_id: props.warehouseId,
                        status: PROCESSING_AT_WAREHOUSE,
                      },
                      true,
                    ),
                  )
                : taskStream;
            }

            // Add the Order to the Firebase
            if (cursorOrder) {
              return taskStream.merge(
                db.addBarcodeToTree(orderNumber, cursorOrder),
              );
            }

            return taskStream;
          }),
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const dbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .map((props) => new OrderBinValidationDB(props.warehouseId));

        const initialState = {
          tasks: Map(),
          orders: Map(),
          tree: Map(),
          queue: Map(),
          scannedOrders: Map(),
        };

        const stateStream = dbStream
          .switchMap((db: OrderBinValidationDB) =>
            !db.warehouseId
              ? Observable.of(initialState)
              : Observable.combineLatest(
                  db.getTasks(),
                  db.getBatchOrders(),
                  db.getBatchTree(),
                  db.getBatchQueue(),
                  db.getBatchScannedOrders(),
                  (tasks, orders, tree, queue, scannedOrders) => ({
                    tasks,
                    orders,
                    tree,
                    queue,
                    scannedOrders,
                  }),
                ).startWith(initialState),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          ...state,
        }));
      },

      /**
       * Step 2 - Normalize task values.
       */
      (propsStream) => {
        const cursorChildOrdersStream = propsStream
          .map(fp.pick(["orders", "cursorOrder", "warehouseId"]))
          .filter((props) => props.warehouseId)
          .distinctUntilChanged(isEqualData)
          .switchMap((props) => {
            const db = new OrderBinValidationDB(props.warehouseId);

            return props.cursorOrder
              ? db.getBatchOrderScannedChildren(props.cursorOrder).take(1)
              : Observable.of(Map());
          })
          .startWith(Map())
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          cursorChildOrdersStream,
          (props, cursorChildOrders) => ({
            ...props,
            cursorChildOrders,
          }),
        );
      },

      (propsStream) => {
        const { handler: onOrderSubmit, stream: onOrderSubmitStream } =
          createEventHandler();

        const sideEffects = mergeSideEffectStreams(
          propsStream
            .filter((props) => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) => {
              const db = new OrderBinValidationDB(props.warehouseId);

              return mergeSideEffectStreams(
                onOrderSubmitStream.do((request) => {
                  props.addBatchSortingTaskOrderNumber(
                    Set(request.orderNumbers),
                  );
                }),
                // onOrderSubmitStream.mergeMap(request =>
                //   Observable.merge(
                //     db.addBarcodeToTree(
                //       fp.get("orderNumbers.[0]", request),
                //       request.cursorOrder,
                //     ),
                //   ),
                // ),
                onOrderSubmitStream.mergeMap(({ orderNumbers }) => {
                  const orderValues = {};

                  orderNumbers.forEach((orderNumber) => {
                    orderValues[`${orderNumber}/number`] = orderNumber;
                    orderValues[`${orderNumber}/scanned`] = true;
                  });

                  return Observable.merge(db.updateBatchOrders(orderValues));
                }),
                onOrderSubmitStream.switchMap((request) =>
                  props
                    .scanBarcodes(request)
                    .take(1)
                    .switchMap(() =>
                      request.cursorOrder
                        ? db.checkBatchCompleted(request.cursorOrder, {
                            assignToWarehouse: request.assignToWarehouse,
                          })
                        : Observable.of({}),
                    ),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffects).map((props) => ({
          ...props,
          onOrderSubmit,
        }));
      },

      /**
       * Step 4 - Handle order task requests.
       *
       * 1. Handle order size change request.
       * 1. Handle failed task retry request.
       */
      (propsStream) => {
        const {
          handler: onBatchCompleteRequest,
          stream: onBatchCompleteRequestStream,
        } = createEventHandler();

        const {
          handler: onRetryTaskRequest,
          stream: onRetryTaskRequestStream,
        } = createEventHandler();

        const {
          handler: onCancelTaskRequest,
          stream: onCancelTaskRequestStream,
        } = createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter((props) => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) => {
              const db = new OrderBinValidationDB(props.warehouseId);

              return mergeSideEffectStreams(
                onBatchCompleteRequestStream.mergeMap((x) =>
                  db.forceCompleteBatch(x.orderNumber, {
                    details: x.details,
                    assignToWarehouse: x.assignToWarehouse,
                  }),
                ),
                onRetryTaskRequestStream.mergeMap((taskId) =>
                  db.retryTask(taskId),
                ),
                onCancelTaskRequestStream.mergeMap((taskId) =>
                  db.removeTask(taskId),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream).map((props) => ({
          ...props,
          onBatchCompleteRequest,
          onRetryTaskRequest,
          onCancelTaskRequest,
        }));
      },

      /**
       * Step 5 - Register side effect workers.
       *
       * 1. Sync removed orders in firebase with reducer.
       * 2. Loads orders without hash.
       * 4. Execute order tasks.
       */
      (propsStream) => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter((props) => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) => {
              const db = new OrderBinValidationDB(props.warehouseId);
              const sortingDB = new OrderSortingDB(props.warehouseId);

              return mergeSideEffectStreams(
                db.getTaskAddStream().mergeMap((response) => {
                  const id = response.key;
                  const payload = response.val();

                  // Remove task if it's corrupted.
                  if (!payload || !payload.task || !payload.number) {
                    return db.removeTask(id);
                  }

                  const { task, number: orderNumber } = payload;

                  return db
                    .getBatchOrder(orderNumber)
                    .take(1)
                    .switchMap((order) => {
                      // Remove task if it's order not found or failed to load.
                      if (order.isEmpty() || order.get("failed")) {
                        return db.removeTask(id);
                      }

                      if (task.duplicated) {
                        return Observable.merge(
                          db.removeTask(id),
                          db.updateBatchOrderScannedCount(
                            orderNumber,
                            order.get("scanned_count", 0) + 1,
                          ),
                        );
                      }

                      if (task.children) {
                        return getBatchChildrenTask({
                          ...props,
                          id,
                          db,
                          order,
                        });
                      }

                      if (task.completed) {
                        return checkIfCursorBatchCompleted({
                          ...props,
                          id,
                          db,
                          order,
                          task,
                          sortingDB,
                        });
                      }

                      if (task.batch_status_update) {
                        return batchStatusUpdate({ ...props, id, db, task });
                      }

                      if (task.shipment_status_update) {
                        return shipmentStatusUpdate({ ...props, id, db, task });
                      }

                      if (task.forceComplete) {
                        return forceCompleteTask({
                          ...props,
                          id,
                          db,
                          task,
                          sortingDB,
                        });
                      }

                      // If it's unknown task - remove it.
                      return db.removeTask(id);
                    });
                }, 5),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream);
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "warehouseId",
      "location",
      "state",
      "task",
      "cursorOrder",
      "orders",
      "tree",
      "queue",
    ]),
  ),
);

AdminBinValidationContainer.propTypes = {
  warehouseId: PropTypes.number,
  cursorOrder: PropTypes.string,

  task: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),
  tree: PropTypes.instanceOf(Map),
  cursorChildOrders: PropTypes.instanceOf(Map),
  scannedOrders: PropTypes.instanceOf(Map),
  queue: PropTypes.instanceOf(Map),

  onOrderSubmit: PropTypes.func,
  updateSortingTask: PropTypes.func,
  clearOrderSortingBatchOrders: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onBatchCompleteRequest: PropTypes.func,
  updateCursorOrder: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,

  theme: PropTypes.object,
  location: PropTypes.object,
};

function AdminBinValidationContainer(props) {
  const classes = useStyles();
  const {
    getLocalisationMessage,
    task,
    tree,
    location: { query },
  } = props;

  if (!props.warehouseId) {
    return (
      <FormWarehouseDialog
        open={true}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        onSubmit={(values) => {
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  const activeBatchActiveOrderNumber = task.get("activeBatchActiveOrderNumber");
  const db = new OrderBinValidationDB(props.warehouseId);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("bin_validation", "Bin Validation")}
      appBarRightAction={
        <MenuButtonMore color={props.theme.palette.appBarTextColor}>
          <MenuItem
            onClick={() => props.setLocationQuery(fp.set("remove_all", true))}
          >
            {getLocalisationMessage("remove_all", "Remove All")}
          </MenuItem>

          <MenuItem
            onClick={() => props.setLocationQuery(fp.set("log_out", true))}
          >
            {getLocalisationMessage("log_out_warehouse", "Log Out Warehouse")}
          </MenuItem>
        </MenuButtonMore>
      }
    >
      <FirebaseOfflineDialog />

      {query.remove_all === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();

            return db
              .removeBatchOrders()
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() => props.setLocationQuery(fp.unset("remove_all")));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_all_data",
            "Are you sure you want to remove all data?",
          )}
        </ConfirmDialog>
      )}

      {query.log_out === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask((x) => x.clear());

            return db
              .removeBatchOrders()
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() => props.setLocationQuery(fp.unset("log_out")));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      <FlexBox container={8} direction="column" className={classes.root}>
        <FlexBox
          element={<Card />}
          direction="column"
          className={classes.bottomSpace}
        >
          <CardHeader title={<h5>{task.getIn(["warehouse", "name"])} </h5>} />
          <CardContent>
            <FlexBox flex={true} style={{ marginBottom: "5px" }}>
              <FlexBox flex={true} justify={JUSTIFY_SPACE_AROUND}>
                <div>
                  <strong>
                    {getLocalisationMessage("scanned_orders", "Scanned Items")}:
                  </strong>{" "}
                  {props.scannedOrders.size} (
                  {getLocalisationMessage(
                    "batches_shipments",
                    "Batches/Shipments",
                  )}
                  ){" "}
                </div>
                <div>
                  <strong>
                    {getLocalisationMessage("need_to_scan", "Need to Scan")}:
                  </strong>{" "}
                  {props.orders.size - props.scannedOrders.size} (
                  {getLocalisationMessage(
                    "batches_shipments",
                    "Batches/Shipments",
                  )}
                  ){" "}
                </div>
                <div>
                  <strong>
                    {getLocalisationMessage("total_orders", "Total Items")}:
                  </strong>{" "}
                  {props.orders.size} (
                  {getLocalisationMessage(
                    "batches_shipments",
                    "Batches/Shipments",
                  )}
                  ){" "}
                </div>
              </FlexBox>
            </FlexBox>

            <OrderSortingBinValidationForm
              focusInput={false}
              onSubmit={(x) =>
                props.onOrderSubmit({
                  orderNumbers: x,
                  scannedOrderDetails: props.orders.get(fp.get("0", x), null),
                  cursorOrder: props.cursorOrder,
                  assignToWarehouse: task.get("autoAssign"),
                })
              }
              autoAssign={task.get("autoAssign")}
              onAutoAssignChange={(x) =>
                props.updateSortingTask((t) => t.set("autoAssign", x))
              }
            />
          </CardContent>
        </FlexBox>

        <FlexBox flex={true}>
          <FlexBox container={8} flex={true} justify={JUSTIFY_SPACE_AROUND}>
            <FlexBox
              direction="column"
              justify={JUSTIFY_SPACE_AROUND}
              flex={6}
              gutter={8}
              element={<Card />}
            >
              <OrderSortingBinDetailsCard
                activeOrder={activeBatchActiveOrderNumber}
                queueOrders={props.orders}
              />

              <FlexBox className={classes.iconsWrapper}>
                <FlexBox>
                  <HourglassEmpty style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("loading", "Loading")}</span>
                </FlexBox>
                <FlexBox>
                  <ReportProblem style={{ color: red[500] }} />
                  <span>{getLocalisationMessage("error", "Error")}</span>
                </FlexBox>
                <FlexBox>
                  <ReportProblem style={{ color: orange[500] }} />
                  <span>{getLocalisationMessage("warning", "Warning")}</span>
                </FlexBox>
                <FlexBox>
                  <CheckCircle style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("success", "Success")}</span>
                </FlexBox>
                <FlexBox>
                  <CheckCircle style={{ color: grey[500] }} />
                  <span>{getLocalisationMessage("inactive", "Inactive")}</span>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column" flex={6} gutter={8} element={<Card />}>
              <FlexBox>
                <OrderSortingBinHeirarchyCard
                  onBatchCompleteRequest={(request) =>
                    props.onBatchCompleteRequest({
                      assignToWarehouse: task.get("autoAssign"),
                      ...request,
                    })
                  }
                  onChangeCursor={(barcode) => props.updateCursorOrder(barcode)}
                  cursorOrder={props.cursorOrder}
                  cursorChildOrders={props.cursorChildOrders}
                  activeNodes={props.task.get("activeNodes")}
                  orders={props.orders}
                  queueOrders={props.queue}
                  pendingOrdersCount={
                    props.orders.size - props.scannedOrders.size
                  }
                  tree={tree}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminBinValidationContainer);
