import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMarketplaceDefaultBatchUpdateStatuses } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import { createOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getPaymentMethods,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import { getTimeSlots } from "../../api/customer/CustomerTimeSlotApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderForm from "../../components/orders-core/OrderForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminOrderCreateDialogWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  orderStatuses: PropTypes.object,
  showSuccessMessage: PropTypes.func,
};

function AdminOrderCreateDialogWrapper(props) {
  const statusList =
    props.orderStatuses && props.orderStatuses.size > 0
      ? props.orderStatuses
      : OrderStatusCodes;
  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage("create_order") || "Create Order"}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox direction="column">
        <OrderForm
          orderStatusCodes={statusList}
          onDismiss={props.onRequestClose}
          onSubmit={values => createOrder(values).catch(ResponseError.throw)}
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "con_your_order_placed_successfully",
                "Congratulations! Your Order Placed Successfully",
              ),
            );
          }}
          getTimeSlots={getTimeSlots}
          getPaymentMethods={getPaymentMethods}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedSupplier={getCachedSupplier}
          getCustomerPredictions={getCustomerPredictions}
          getCachedCustomer={getCachedCustomer}
          getSupplierPredictions={getSupplierPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderCreateDialogWrapper);
