import { Map, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "../ui-core/Tabs";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData, isEqualDataKeys } from "../../helpers/DataUtils";
import { wordsTo } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  DEMO,
  READY,
  NOT_READY,
  FREE_TRIAL,
  NOT_MAPPED,
} from "../../constants/MarketplaceStatusConstants";

const ALL_TAB = "All";
const READY_TAB = "Ready";
const NOT_READY_TAB = "Not Ready";

const tabBaseFilters = OrderedMap()
  .set(ALL_TAB, fromJS({ page: 0, marketplace_types: null }))
  .set(
    READY_TAB,
    fromJS({
      page: 0,
      marketplace_types: [READY, FREE_TRIAL, DEMO],
    }),
  )
  .set(
    NOT_READY_TAB,
    fromJS({
      page: 0,
      marketplace_types: [NOT_READY, NOT_MAPPED],
    }),
  );

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withRouter,
  mapPropsStream(propsStream =>
    propsStream
      .distinctUntilChanged(isEqualDataKeys(["filter", "basePathname"]))
      .map(props => {
        const filters = tabBaseFilters.map(x => props.filter.setValueMap(x));

        return {
          value: props.router.createHref({
            pathname: props.basePathname,
            query: props.filter.setPage(0).getDefinedValues(),
          }),

          tabs: filters.map((filter, label) => {
            const value = props.router.createHref({
              pathname: props.basePathname,
              query: filter.getDefinedValues(),
            });
            const localisedLabel =
              props.getLocalisationMessage(wordsTo(label)) || label;

            return Map({ label: localisedLabel, value });
          }),
        };
      })
      .distinctUntilChanged(isEqualData),
  ),
  renderIf(props => props.tabs.find(x => props.value === x.get("value"))),
  mapPropsStream(propsStream =>
    propsStream.distinctUntilChanged(isEqualData).map(
      fp.update("tabs", tabs =>
        tabs.reduce((acc, x) => {
          acc.push({
            value: x.get("value"),
            path: x.get("value"),
            label: x.get("label"),
          });

          return acc;
        }, []),
      ),
    ),
  ),
);

const MarketplaceListTabs = enhancer(Tabs);

MarketplaceListTabs.propTypes = {
  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
};

export default MarketplaceListTabs;
