import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import AdminCODCodes from "../../constants/AdminCODCodes";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import BatchUpdateJobDialog from "../../components/order-job/BatchUpdateJobDialog";

AdminBatchUpdateJobDialogWrapper.propTypes = {
  open: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

export default function AdminBatchUpdateJobDialogWrapper(props) {
  return (
    <BatchUpdateJobDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      codCodes={AdminCODCodes}
      statusCodes={OrderStatusCodes}
      withInTransitToSupplier={true}
      privacyTypes={AdminPrivacyTypes}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      initialValues={props.initialValues}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}
