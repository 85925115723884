import React from "react";
import { isAfter, startOfToday } from "date-fns";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  IconButton,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FlexBox from "../ui-core/FlexBox";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { isValidDate, safeParseDate } from "../../helpers/DateUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({ content: { width: "320px" } }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        fromDate: safeParseDate(props.filter.getValue("from_date")),
        toDate: safeParseDate(props.filter.getValue("to_date")),
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) => {
      props.onFilterChange(
        props.filter.setValueMap({
          from_date: formatDateToUrl(values.fromDate),
          to_date: formatDateToUrl(values.toDate),
        }),
      );
    };

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({ ...props, onSubmit, initialValues }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "CODWithdrawListFilterDialog",
    validate: values => ({
      toDate:
        Boolean(
          isValidDate(values.fromDate) &&
            isValidDate(values.toDate) &&
            isAfter(values.fromDate, values.toDate),
        ) && "Invalid date range",
    }),
  }),
  formValues({ fromDate: "fromDate", toDate: "toDate" }),
  pureComponent(fp.pick(["fromDate", "toDate"])),
);

CODWithdrawListFilterDialog.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function CODWithdrawListFilterDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      open={props.open}
      contentClassName={classes.content}
      onClose={props.onRequestClose}
    >
      <DialogContent style={{ minWidth: 400 }}>
        <form>
          <FlexBox align="flex-end">
            <FlexBox
              flex={true}
              direction="column"
              style={{ marginBottom: 15 }}
            >
              <FormDateField
                name="fromDate"
                fullWidth={true}
                notSetValue={startOfToday()}
                hintText={getLocalisationMessage("from_date", "From Date")}
              />
            </FlexBox>

            {Boolean(props.fromDate) && (
              <FlexBox>
                <IconButton onClick={() => props.change("fromDate", null)}>
                  <NavigationCancel />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox align="flex-end">
            <FlexBox flex={true} direction="column">
              <FormDateField
                name="toDate"
                fullWidth={true}
                notSetValue={startOfToday()}
                hintText={getLocalisationMessage("to_date", "To Date")}
              />
            </FlexBox>

            {Boolean(props.toDate) && (
              <FlexBox>
                <IconButton onClick={() => props.change("toDate", null)}>
                  <NavigationCancel />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {" "}
          {getLocalisationMessage("dismiss", "Dismiss")}{" "}
        </Button>
        <Button onClick={props.handleSubmit}>
          {" "}
          {getLocalisationMessage("submit", "Submit")}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CODWithdrawListFilterDialog);
