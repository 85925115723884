import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getSupplierUser,
  updateSupplierUser,
} from "../../api/admin/AdminSupplierUserApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SupplierUserForm from "../../components/suppliers-core/SupplierUserForm";

const enhancer = compose(
  renderIf(props => props.supplierUserId > 0),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("supplierUserId", isEqualData)
      .switchMap(props =>
        getSupplierUser(props.supplierUserId)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        supplierUser: response.get("payload"),
        isLoading: response.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierUserEditDialogWrapper.propTypes = {
  supplierUserId: PropTypes.number,
  isLoading: PropTypes.bool,
  supplierUser: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  onSupplierClick: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function AdminSupplierUserEditDialogWrapper(props) {
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={`${props.i18n.get("supplier_user", "Supplier User")} #${
        props.supplierUserId
      }`}
    >
      <SupplierUserForm
        onDismiss={props.onRequestClose}
        initialValues={toCamelCase(props.supplierUser)}
        onSubmit={values =>
          updateSupplierUser(
            props.supplierUserId,
            toSnakeCase({
              ...values,
              login: values.email,
            }),
          ).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.onRequestClose();
          props.showSuccessMessage(
            props.i18n.get("successfully_saved", "Successfully saved"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        onSupplierClick={props.onSupplierClick}
        onCreateSupplierClick={props.onCreateSupplierClick}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminSupplierUserEditDialogWrapper);
