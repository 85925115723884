import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { types, mapSchema } from "../../shared/helpers/ObjectMapper";

export const toCustomsFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    status: types.string,
    type: types.string,
  }),
);
