import { OrderedSet } from "immutable";

export const PERCENT_DISCOUNT = "percent_discount";
export const ABSOLUTE_DISCOUNT = "absolute_discount";
export const FREE_ORDER = "free_order";
export const BALANCE_TOP_UP = "balance_top_up";

export default OrderedSet.of(
  FREE_ORDER,
  PERCENT_DISCOUNT,
  ABSOLUTE_DISCOUNT,
  BALANCE_TOP_UP,
);
