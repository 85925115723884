import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Dialog, Button } from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { getPayfortSettingsAny } from "../../api/shared/PayfortApi";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    actions: {
      padding: 8,
      textAlign: "right",
      margin: "24px -24px -24px -24px",
    },
  }),
  mapPropsStream(propsStream => {
    const settingsStream = propsStream
      .distinctUntilKeyChanged("params")
      .first()
      .switchMap(props =>
        getPayfortSettingsAny(props.params)
          .let(mapObjectResponseStream)
          .map(response =>
            response.update("payload", payload =>
              payload.set(
                "values",
                Map({
                  command: payload.get("command"),
                  currency: payload.get("currency"),
                  language: payload.get("language"),
                  signature: payload.get("signature"),
                  return_url: payload.get("return_url"),
                  amount: payload.get("pay_fort_amount"),
                  access_code: payload.get("access_code"),
                  customer_email: payload.get("customer_email"),
                  merchant_reference: payload.get("merchant_reference"),
                  merchant_identifier: payload.get("merchant_identifier"),
                }),
              ),
            ),
          ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(settingsStream, (props, settings) => ({
      ...props,
      settings: settings.get("payload"),
      isLoading: settings.get("pending"),
    }));
  }),
  pureComponent(fp.pick(["settings", "isLoading"])),
);

AnyPayfortDialog.propTypes = {
  classes: PropTypes.object,

  isLoading: PropTypes.bool,
  settings: PropTypes.instanceOf(Map),

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  message: PropTypes.string,

  params: PropTypes.object.isRequired,

  i18n: PropTypes.instanceOf(Map),
};

function AnyPayfortDialog(props) {
  const { classes, settings, message, i18n } = props;

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.isLoading} />

      <form method="POST" target="_blank" action={settings.get("payment_url")}>
        <p>
          {message ||
            i18n.get(
              "please_proceed_to_payments_page_to_complete_creation_process",
              "Please proceed to payments page to complete creation process.",
            )}
        </p>

        {settings
          .get("values")
          .map((value, name) => (
            <input key={name} name={name} type="hidden" value={value} />
          ))
          .toArray()}

        <div className={classes.actions}>
          <Button type="button" onClick={props.onRequestClose}>
            {i18n.get("dismiss", "Dismiss")}
          </Button>
          <Button
            type="submit"
            onClick={() => setTimeout(props.onRequestClose, 500)}
          >
            {i18n.get("proceed", "Proceed")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

export default enhancer(AnyPayfortDialog);
