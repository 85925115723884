import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const GET_SORT_LANGUAGES = `${API_ROOT_URL}/language`;

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: () => api.getStream(GET_SORT_LANGUAGES),
});

export const getUserSortLanguage = () => predictionsCache.get();
