import React from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { TransitionUp } from "../../dialog/TransitionUp";
import FlexBox from "../../ui-core/FlexBox";
import { OUTLINED, SECONDARY } from "../../form/FormTextField";
import {
  getCacheRegion,
  getInternationalRegionChoose,
} from "../../../api/admin/AdminInternationalReportsApi";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import FormAutocompleteNew from "../../form/FormInternationalSettingsAutocomplete";
import FormDateField from "../../form/FormDateField";
import { getFormValues, reduxForm } from "redux-form";
import FormSubclassChips from "../../form/FormSubclassChips";
import FormFormatChips from "../../form/FormFormatChips";
import FormCheckbox from "../../form/FormCheckbox";
import fp from "lodash/fp";
import { parseString, stringifyArray } from "../../../helpers/SerializeUtils";
import { getObjectId, idsToObjectArray } from "../../../helpers/FormUtils";
import {
  formatDate,
  formatDateToUrl,
  safeParseDate,
} from "../../../helpers/FormatUtils";
import _ from "lodash";
import {
  localisationAction,
  localisationCategory,
} from "./InternationalParcelForm";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormToggle from "../../form/FormToggle";
import FormChipAutoComplete from "../../form/FormChipAutoComplete";
import { OrderedSet } from "immutable";

const getValues = getFormValues("InternationalLetterFilter");

const actions = OrderedSet.of("OUT", "IN");
const categories = OrderedSet.of("AIR", "GROUND", "SAL");

const cleanupValues = fp.omit([
  "actions",
  "categories",
  "dateStart",
  "dateEnd",
]);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const { filter: x } = props;

        return {
          hasValued: x.has_valued,
          hasMbag: x.has_mbag,
          reference: x.reference,
          hasRegistered: x.has_registered,

          actions: parseString(x.actions),
          categories: parseString(x.categories),

          from: { id: x.from || null },
          to: { id: x.from || null },
          transit: { id: x.from || null },

          formats: idsToObjectArray(x.formats),
          subclasses: idsToObjectArray(x.subclasses),

          dateStart: safeParseDate(x.date_start),
          dateEnd: safeParseDate(x.date_end),

          formationDateStart: safeParseDate(x.formation_date_start),
          formationDateEnd: safeParseDate(x.formation_date_end),
        };
      })
      .distinctUntilChanged(isEqualData);
    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "InternationalLetterFilter" }),
  connect(state => ({
    values: getValues(state),
  })),
);

const handleValuesToFilter = (values, filter, setFilter, setSelectedItems) => {
  const tempValues = {};
  tempValues.date_start = formatDateToUrl(values.dateStart);
  tempValues.date_end = formatDateToUrl(values.dateEnd);
  tempValues.formation_date_start = formatDateToUrl(values.formationDateStart);
  tempValues.formation_date_end = formatDateToUrl(values.formationDateEnd);
  tempValues.from = getObjectId(values.from);
  tempValues.to = getObjectId(values.to);
  tempValues.transit = getObjectId(values.transit);
  tempValues.has_registered = values.hasRegistered;
  tempValues.has_valued = values.hasValued;
  tempValues.reference = values.reference;
  tempValues.has_mbag = values.hasMbag;

  if (!fp.isEmpty(values.subclasses)) {
    tempValues.subclasses = stringifyArray(values.subclasses.map(getObjectId));
  }

  if (!fp.isEmpty(values.actions)) {
    tempValues.actions = stringifyArray(values.actions);
  }

  if (!fp.isEmpty(values.categories)) {
    tempValues.categories = stringifyArray(values.categories);
  }

  if (!fp.isEmpty(values.formats)) {
    tempValues.formats = stringifyArray(values.formats.map(getObjectId));
  }
  setSelectedItems([]);
  setFilter({
    ..._.omitBy(tempValues, v => v !== 0 && !v),
    page: filter.page || 0,
    size: filter.size,
  });
};

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "400px",
    maxWidth: "500px",
    minHeight: "auto",
  },
  dialogTitle: {
    color: theme.palette.appBarTextColor,
    backgroundColor: theme.palette.primary1Color,
  },
  dialogHeaderTitle: {
    flexGrow: 1,
  },
  dialogHeaderToggleContainer: {
    padding: "10px 5px 10px 20px",
    borderRadius: 1000,
    backgroundColor: "#DBE1E6",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

const InternationalLetterFilter = ({
  values,
  getLocalisationMessage,
  filter,
  setFilter,
  onRequestClose,
  open,
  initialValues,
  change,
  setSelectedItems,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={onRequestClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FlexBox flex={true} justify="space-between">
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage("filter")}
          </div>

          <FormToggle
            name="reference"
            label={getLocalisationMessage("reference", "Reference")}
            onChange={() => {
              const ignoreValues = cleanupValues(initialValues);
              fp.keys(ignoreValues).forEach(key => {
                change(key, null);
              });
            }}
          />
        </FlexBox>
      </DialogTitle>
      <FlexBox align="center" container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                {!values.reference && (
                  <FlexBox gutter={16} flex={true}>
                    <FlexBox flex={true}>
                      <FormAutocompleteNew
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        getById={getCacheRegion}
                        chooseAPI={getInternationalRegionChoose}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>
                              {getValue(option, "value.code", " ")
                                ? `${option.name}(${getValue(
                                    option,
                                    "value.code",
                                    " ",
                                  )})`
                                : option.name}
                            </span>
                            {getValue(option, "value.parent.id") && (
                              <span
                                style={{
                                  fontSize: ".8rem",
                                  fontStyle: "italic",
                                }}
                              >
                                {getValue(option, "value.parent.code")
                                  ? `${getValue(
                                      option,
                                      "value.parent.name",
                                    )} (${getValue(
                                      option,
                                      "value.parent.code",
                                      " ",
                                    )})`
                                  : getValue(option, "value.parent.name")}
                              </span>
                            )}
                          </FlexBox>
                        )}
                        name="from"
                        fullWidth={true}
                        label={`${getLocalisationMessage("from", "From")}`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormAutocompleteNew
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        getById={getCacheRegion}
                        chooseAPI={getInternationalRegionChoose}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>
                              {getValue(option, "value.code", " ")
                                ? `${option.name}(${getValue(
                                    option,
                                    "value.code",
                                    " ",
                                  )})`
                                : option.name}
                            </span>
                            {getValue(option, "value.parent.id") && (
                              <span
                                style={{
                                  fontSize: ".8rem",
                                  fontStyle: "italic",
                                }}
                              >
                                {getValue(option, "value.parent.code")
                                  ? `${getValue(
                                      option,
                                      "value.parent.name",
                                    )} (${getValue(
                                      option,
                                      "value.parent.code",
                                      " ",
                                    )})`
                                  : getValue(option, "value.parent.name")}
                              </span>
                            )}
                          </FlexBox>
                        )}
                        name="to"
                        fullWidth={true}
                        label={`${getLocalisationMessage("to", "to")}`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormAutocompleteNew
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        getById={getCacheRegion}
                        chooseAPI={getInternationalRegionChoose}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>
                              {getValue(option, "value.code", " ")
                                ? `${option.name}(${getValue(
                                    option,
                                    "value.code",
                                    " ",
                                  )})`
                                : option.name}
                            </span>
                            {getValue(option, "value.parent.id") && (
                              <span
                                style={{
                                  fontSize: ".8rem",
                                  fontStyle: "italic",
                                }}
                              >
                                {getValue(option, "value.parent.code")
                                  ? `${getValue(
                                      option,
                                      "value.parent.name",
                                    )} (${getValue(
                                      option,
                                      "value.parent.code",
                                      " ",
                                    )})`
                                  : getValue(option, "value.parent.name")}
                              </span>
                            )}
                          </FlexBox>
                        )}
                        name="transit"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "transit",
                          "Transit",
                        )}`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormSubclassChips
                        name="subclasses"
                        label={getLocalisationMessage("subclass", "Subclass")}
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormFormatChips
                        name="formats"
                        label={getLocalisationMessage("format", "Format")}
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                      />
                    </FlexBox>
                  </FlexBox>
                )}

                <FlexBox gutter={16} flex={true} align="center">
                  <FlexBox flex={true}>
                    <FormChipAutoComplete
                      name="actions"
                      fullWidth={true}
                      options={actions}
                      formatOption={option =>
                        localisationAction(option, getLocalisationMessage)
                      }
                      hintText={getLocalisationMessage("type_here_to_search")}
                      label={`${getLocalisationMessage("action", "Action")}`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormChipAutoComplete
                      name="categories"
                      fullWidth={true}
                      hintText={getLocalisationMessage("type_here_to_search")}
                      options={categories}
                      formatOption={option =>
                        localisationCategory(option, getLocalisationMessage)
                      }
                      label={`${getLocalisationMessage(
                        "category",
                        "Category",
                      )}`}
                    />
                  </FlexBox>
                  {!values.reference && (
                    <FlexBox flex={true} align="center" justify="center">
                      <FormCheckbox
                        name="hasRegistered"
                        label={getLocalisationMessage(
                          "has_registered",
                          "Has Registered",
                        )}
                        fullWidth={true}
                      />
                    </FlexBox>
                  )}
                  {!values.reference && (
                    <FlexBox flex={true} align="center" justify="center">
                      <FormCheckbox
                        name="hasValued"
                        label={getLocalisationMessage(
                          "has_valued",
                          "Has Valued",
                        )}
                        fullWidth={true}
                      />
                    </FlexBox>
                  )}
                  {!values.reference && (
                    <FlexBox flex={true} align="center" justify="center">
                      <FormCheckbox
                        name="hasMbag"
                        label={getLocalisationMessage("has_mbag", "Has MBag")}
                        fullWidth={true}
                      />
                    </FlexBox>
                  )}
                </FlexBox>

                <FlexBox gutter={16} flex={true}>
                  <FlexBox flex={true} style={{ gap: 16 }}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        name="dateStart"
                        variant={OUTLINED}
                        formatValue="dd-MM-yyyy"
                        formatDate={date => formatDate(date, "dd-MM-yyyy")}
                        hintText={getLocalisationMessage(
                          "date_start",
                          "Date Start",
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        name="dateEnd"
                        variant={OUTLINED}
                        formatValue="dd-MM-yyyy"
                        formatDate={date => formatDate(date, "dd-MM-yyyy")}
                        hintText={getLocalisationMessage(
                          "date_end",
                          "Date End",
                        )}
                      />
                    </FlexBox>

                    <FlexBox>
                      <IconButton
                        onClick={() => {
                          change("dateStart", null);
                          change("dateEnd", null);
                        }}
                      >
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                  {!values.reference && (
                    <FlexBox flex={true} style={{ gap: 16 }}>
                      <FlexBox flex={true}>
                        <FormDateField
                          fullWidth={true}
                          name="formationDateStart"
                          variant={OUTLINED}
                          formatValue="dd-MM-yyyy"
                          formatDate={date => formatDate(date, "dd-MM-yyyy")}
                          hintText={getLocalisationMessage(
                            "formation_date_start",
                            "Formation Date Start",
                          )}
                        />
                      </FlexBox>
                      <FlexBox flex={true}>
                        <FormDateField
                          fullWidth={true}
                          name="formationDateEnd"
                          variant={OUTLINED}
                          formatValue="dd-MM-yyyy"
                          formatDate={date => formatDate(date, "dd-MM-yyyy")}
                          hintText={getLocalisationMessage(
                            "formation_date_end",
                            "Formation Date End",
                          )}
                        />
                      </FlexBox>

                      <FlexBox>
                        <IconButton
                          onClick={() => {
                            change("formationDateStart", null);
                            change("formationDateEnd", null);
                          }}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  )}
                </FlexBox>
              </CardContent>
              <CardActions>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} justify="flex-end" style={{ gap: 16 }}>
                    <CustomButton
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => {
                        fp.keys(initialValues).forEach(key => {
                          change(key, null);
                        });
                      }}
                    >
                      {getLocalisationMessage("clear", "Clear")}
                    </CustomButton>

                    <CustomButton
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => onRequestClose()}
                    >
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </CustomButton>

                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      onClick={() => {
                        handleValuesToFilter(
                          values,
                          filter,
                          setFilter,
                          setSelectedItems,
                        );
                        onRequestClose();
                      }}
                    >
                      {getLocalisationMessage("submit", "Submit")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>
              </CardActions>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Dialog>
  );
};
InternationalLetterFilter.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  initialValues: PropTypes.object,
  values: PropTypes.object,
  setSelectedItems: PropTypes.func,
};

export default enhancer(InternationalLetterFilter);
