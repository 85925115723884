import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Cancel, CheckCircle, List, Timelapse } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogActions from "@material-ui/core/DialogActions";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import { getValue } from "../../helpers/DataUtils";
import { getSimpleOrderUploadPublishProgressV2 } from "../../api/admin/AdminOrderUploadApi";

const useStyles = makeStyles({
  root: {
    padding: "16px 20px 20px",
    height: "60vh",
  },
  cardTitle: {
    padding: "14px 18px",
    gap: 8,
    width: 200,
    height: 57,
    background: "rgba(25, 118, 210, 0.1)",
    borderRadius: "4px",
  },
  cardTitleWaiting: {
    padding: "14px 18px",
    gap: 8,
    width: 200,
    height: 57,
    background: "rgba(255, 152, 0, 0.1)",
    borderRadius: "4px",
  },
  cardTitleSuccess: {
    padding: "14px 18px",
    gap: 8,
    width: 200,
    height: 57,
    background: "rgba(76, 175, 80, 0.1)",
    borderRadius: "4px",
  },
  cardTitleFailed: {
    padding: "14px 18px",
    gap: 8,
    width: 200,
    height: 57,
    background: "rgba(235, 87, 87, 0.1)",
    borderRadius: "4px",
  },
  list: {
    gap: 8,
    width: 80,
    height: 20,
  },
  listIcon: {
    width: 20,
    height: 20,
    color: "#1976D2",
  },
  listIconWaiting: {
    width: 20,
    height: 20,
    color: "rgba(255, 152, 0)",
  },
  listIconSuccess: {
    width: 20,
    height: 20,
    color: "rgba(76, 175, 80)",
  },
  listIconFailed: {
    width: 20,
    height: 20,
    color: "rgba(235, 87, 87)",
  },
  cardText: {
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 400,
  },
  listNumber: {
    textTransform: "uppercase",
    fontSize: 18,
    fontWeight: 500,
    width: 11,
    height: 21,
  },
  dialogTitle: {
    padding: "16px 24px",
    fontSize: "20px",
    fontWeight: 500,
  },
  progressStart: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#4CAF50",
    },
    "& .MuiLinearProgress-colorSecondary": {
      backgroundColor: "#4CAF50",
    },
  },
  progressFinish: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#4CAF50",
    },
    "& .MuiLinearProgress-colorSecondary": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    },
  },
  saveButton: {
    "& .MuiButton-containedSecondary": {
      backgroundColor: "#27AE60",
    },
  },
});

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
);

SimpleOrderCreateProgressDialog.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  batchId: PropTypes.number,
};

function SimpleOrderCreateProgressDialog(props) {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [proceed, setProceed] = useState(0);
  const [succeed, setSucceed] = useState(0);
  const [failed, setFailed] = useState(0);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (props.batchId) {
      getSimpleOrderUploadPublishProgressV2(props.batchId)
        .then(res => {
          if (res && res.data) {
            setFailed(getValue(res, "data.failed"));
            setTotal(getValue(res, "data.total"));
            setProceed(getValue(res, "data.pending"));
            setSucceed(getValue(res, "data.created"));
          }
        })
        .catch(error => props.showErrorMessage(error));
    }
  }, [props.batchId, refresh]);

  let refreshList;
  useEffect(() => {
    if (succeed < total)
      refreshList = setInterval(() => setRefresh(prev => !prev), 3000);
    return () => clearInterval(refreshList);
  }, [props.batchId, proceed, total]);

  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          maxWidth: "900px",
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {props.i18n.get("process_to_complete", "Process to complete")}
      </DialogTitle>

      <DialogContent>
        <FlexBox style={{ gap: 40 }} direction="column">
          <FlexBox style={{ gap: 24 }}>
            <FlexBox
              justify="space-between"
              align="center"
              className={classes.cardTitle}
            >
              <FlexBox className={classes.list} align="center">
                <List className={classes.listIcon} />
                <Typography className={classes.cardText}>
                  {props.i18n.get("total")}
                </Typography>
              </FlexBox>
              <FlexBox className={classes.listNumber} align="center">
                {total}
              </FlexBox>
            </FlexBox>

            <FlexBox
              justify="space-between"
              align="center"
              className={classes.cardTitleWaiting}
            >
              <FlexBox className={classes.list} align="center">
                <Timelapse className={classes.listIconWaiting} />
                <Typography className={classes.cardText}>
                  {props.i18n.get("waiting", "Waiting")}
                </Typography>
              </FlexBox>
              <FlexBox className={classes.listNumber} align="center">
                {proceed}
              </FlexBox>
            </FlexBox>

            <FlexBox
              justify="space-between"
              align="center"
              className={classes.cardTitleSuccess}
            >
              <FlexBox className={classes.list} align="center">
                <CheckCircle className={classes.listIconSuccess} />
                <Typography className={classes.cardText}>
                  {props.i18n.get("succeed", "Succeed")}
                </Typography>
              </FlexBox>
              <FlexBox className={classes.listNumber} align="center">
                {succeed}
              </FlexBox>
            </FlexBox>

            <FlexBox
              justify="space-between"
              align="center"
              className={classes.cardTitleFailed}
            >
              <FlexBox className={classes.list} align="center">
                <Cancel className={classes.listIconFailed} />
                <Typography className={classes.cardText}>
                  {props.i18n.get("failed", "Failed")}
                </Typography>
              </FlexBox>
              <FlexBox className={classes.listNumber} align="center">
                {failed}
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox direction="column" style={{ gap: 12 }}>
            <FlexBox>
              <span
                style={{
                  color: "#27AE60",
                  fontWeight: 700,
                  fontSize: 18,
                }}
              >
                {`${
                  total > 0
                    ? parseFloat(((succeed + failed) / total) * 100).toFixed(1)
                    : 0
                } % ${props.i18n.get("completed_1", "completed")}`}
              </span>
            </FlexBox>
            <FlexBox
              className={
                succeed === total
                  ? classes.progressFinish
                  : classes.progressStart
              }
            >
              <LinearProgress
                style={{
                  height: "32px",
                  width: "100%",
                }}
                variant="determinate"
                value={total > 0 ? ((succeed + failed) / total) * 100 : 0}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </DialogContent>

      <DialogActions>
        <FlexBox style={{ gap: 16 }}>
          <CustomButton color={SECONDARY} onClick={props.onSubmit}>
            {props.i18n.get("exit", "Exit")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(SimpleOrderCreateProgressDialog);
