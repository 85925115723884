import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { ACTION, COLUMN, SAVE } from "../../components/orders-core/MUITable";
import _ from "lodash";
import {
  createBatchCategory,
  deleteBatchCategory,
  getBatchCategories,
  updateBatchCategory,
} from "../../api/shared/OrderApi";
import { Add, Delete, KeyboardArrowLeft, Save } from "@material-ui/icons";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import BatchCategoriesTable from "../../components/orders-core/BatchCategoriesTable";
import { TransitionUp } from "../../components/dialog/TransitionUp";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
} from "../../components/ui-core/FlexBox";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminBatchCategoriesList.propTypes = {
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminBatchCategoriesList({
  getLocalisationMessage,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
}) {
  const [deletedId, setDeletedId] = useState(0);
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableRow, setEditableRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(20);
  useEffect(() => {
    setIsLoading(true);
    getBatchCategories({
      page,
      size,
      custom: true,
    })
      .then((res) => {
        setIsLoading(false);
        setList(_.get(res, "data.list"));
        setTotal(_.get(res, "data.total"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage1(error);
      });
  }, [page, size, refresh]);
  const [name, setName] = useState("");
  const handleChange = (event) => setName(event.target.value);
  const editDispatch = (row) => setEditableRow(row);
  const cancelDispatch = () => setEditableRow({});
  const createNewCategory = () => {
    setIsLoadingCreate(true);
    createBatchCategory({ name })
      .then(() => {
        setName("");
        showSuccessMessage1(getLocalisationMessage("successfully"));
        setRefresh(!refresh);
        setIsOpenNew(false);
      })
      .catch((error) => showErrorMessage1(error))
      .finally(() => setIsLoadingCreate(false));
  };
  const saveDispatch = (id, name2) => {
    setIsLoadingUpdate(true);
    updateBatchCategory(id, { name: name2 })
      .then(() => {
        showSuccessMessage1(getLocalisationMessage("successfully"));
        setRefresh(!refresh);
        setEditableRow({});
      })
      .catch((error) => showErrorMessage1(error))
      .finally(() => setIsLoadingUpdate(false));
  };
  const deleteCategory = () => {
    setIsLoadingDelete(true);
    deleteBatchCategory(deletedId)
      .then(() => {
        setDeletedId(0);
        showSuccessMessage1(getLocalisationMessage("successfully_deleted"));
        setRefresh(!refresh);
      })
      .catch((error) => showErrorMessage1(error))
      .finally(() => setIsLoadingDelete(false));
  };
  const deleteDispatch = (id) => setDeletedId(id);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <AdminAppLayout title={getLocalisationMessage("additional_services")}>
      <Dialog
        TransitionComponent={TransitionUp}
        open={deletedId}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setDeletedId(0)}
      >
        <DialogTitle style={{ borderBottom: "1px solid #eeeeee" }}>
          <h5 style={{ textAlign: "center" }}>
            {getLocalisationMessage("are_you_sure_you_want_to_remove")}
          </h5>
        </DialogTitle>
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox>
              <CustomButton
                onClick={() => setDeletedId(0)}
                startIcon={<KeyboardArrowLeft />}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("close_window", "Close window")}
              </CustomButton>
              <CustomButton
                style={{ marginLeft: "1rem" }}
                onClick={() => deleteCategory()}
                endIcon={
                  isLoadingDelete ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <Delete />
                  )
                }
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("delete")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={TransitionUp}
        open={isOpenNew}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setIsOpenNew(false)}
      >
        <DialogTitle style={{ borderBottom: "1px solid #eeeeee" }}>
          <h5 style={{ textAlign: "center" }}>
            {getLocalisationMessage("create")}
          </h5>
        </DialogTitle>
        <DialogContent style={{ margin: "1rem" }}>
          <FlexBox flex={true} align={ALIGN_CENTER} justify={JUSTIFY_CENTER}>
            <TextField
              fullWidth={true}
              variant="outlined"
              size="small"
              label={getLocalisationMessage("name")}
              value={name}
              onChange={handleChange}
            />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox>
              <CustomButton
                onClick={() => setIsOpenNew(false)}
                startIcon={<KeyboardArrowLeft />}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("close_window", "Close window")}
              </CustomButton>
              <CustomButton
                disabled={!name}
                style={{ marginLeft: "1rem" }}
                onClick={() => createNewCategory()}
                endIcon={
                  isLoadingCreate ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <Save />
                  )
                }
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("save")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      </Dialog>
      <BatchCategoriesTable
        isLoading={isLoading}
        isLoadingUpdate={isLoadingUpdate}
        title={getLocalisationMessage("additional_services")}
        size="small"
        list={list}
        editableRow={editableRow}
        total={total}
        page={page}
        rowsPerPage={size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        headerActions={
          <CustomButton
            onClick={() => setIsOpenNew(true)}
            startIcon={<Add />}
            variant={OUTLINED}
            color={SECONDARY}
            style={{
              marginTop: "1rem",
              marginRight: "1rem",
              marginBottom: ".5rem",
            }}
          >
            {getLocalisationMessage("create")}
          </CustomButton>
        }
        columns={[
          {
            type: COLUMN,
            name: "id",
            label: getLocalisationMessage("id"),
          },
          {
            type: COLUMN,
            name: "name",
            label: getLocalisationMessage("name"),
          },
          {
            type: SAVE,
            label: getLocalisationMessage("action"),
            cancelDispatch,
            editDispatch,
            saveDispatch,
          },
          {
            type: ACTION,
            label: getLocalisationMessage("delete"),
            dispatch: deleteDispatch,
          },
        ]}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminBatchCategoriesList);
