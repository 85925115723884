import React from "react";
import { endOfYesterday, startOfYesterday } from "date-fns";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { FilterList as ContentFilterList } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import { mapArrayResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber, formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toNeighborhoodFilter } from "../../helpers/NieghborhoodsFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getAllNeighborhoodPolygons } from "../../api/admin/AdminPostcodesApi";
import AdminNeighborhoodsFilterDialogWrapper from "../../wrappers/admin/AdminNeighborhoodsFilterDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import NeighborhoodsMap from "../../components/neighborhoods-core/NeighborhoodsMap";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const totalOrderCount = neighborhoods => {
  let totalCount = 0;
  neighborhoods.forEach(item => {
    totalCount += item.get("order_count") || 0;
  });
  return totalCount;
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withTheme,
  useSheet({
    appBarRightAction: {
      fontSize: "15px",
      color: props => props.theme.palette.appBarTextColor,
      marginTop: "0",
    },
    appBarRightActionInfo: {
      fontSize: "18px",
      marginRight: "10px",
    },
    appBarRightActionInfoOrderCount: {
      color: props => props.theme.palette.appBarTextColor,
      display: "block",
      paddingLeft: "10px",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toNeighborhoodFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const neighborhoodsStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getAllNeighborhoodPolygons(props.filter).let(
              mapArrayResponseStream,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(neighborhoodsStream, (props, neighborhoods) => ({
            ...props,
            isLoading: neighborhoods.get("pending"),
            neighborhoods: neighborhoods.get("payload"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const statsStream = propsStream
          .filter(props => !props.isLoading)
          .map(fp.pick("neighborhoods"))
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const totalOrdersCount = totalOrderCount(props.neighborhoods);

            return {
              totalOrders: totalOrdersCount,
            };
          })
          .distinctUntilChanged(isEqualData)
          .startWith(null);

        return propsStream
          .combineLatest(statsStream, (props, stats) => ({
            ...props,
            stats,
            isLoading: props.isLoading || !stats,
            totalOrderCount: stats ? stats.totalOrders : 0,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminOrdersPerPostcodesContainer.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,

  isLoading: PropTypes.bool,
  totalOrderCount: PropTypes.number,
  neighborhoods: PropTypes.instanceOf(List),

  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

function AdminOrdersPerPostcodesContainer(props) {
  const {
    getLocalisationMessage,
    location: { query },
  } = props;

  const activeId = fp.toNumber(query.active_id);

  return (
    <AdminAppLayout
      slug="orders_in_NBHD"
      title={getLocalisationMessage(
        "orders_per_neighborhoods",
        "Orders Per Neighborhoods",
      )}
      appBarRightAction={
        <FlexBox
          flex={true}
          align="center"
          justify="flex-end"
          className={props.classes.appBarRightAction}
        >
          <FlexBox className={props.classes.appBarRightActionInfo}>
            {getLocalisationMessage(
              "total_number_of_orders",
              "Total Number of Orders",
            )}
            :{" "}
            <span className={props.classes.appBarRightActionInfoOrderCount}>
              {formatNumber(props.totalOrderCount)}
            </span>
          </FlexBox>

          <IconButton
            onClick={() => props.setLocationQuery(fp.set("show_filter", true))}
            iconStyle={{ color: props.theme.palette.appBarTextColor }}
          >
            <ContentFilterList />
          </IconButton>
        </FlexBox>
      }
    >
      <Redirect
        when={
          !(
            props.filter.getValue("from_date_time") &&
            props.filter.getValue("to_date_time")
          )
        }
        to={updateQuery(
          props.location,
          fp.flow(
            fp.set("from_date_time", formatDateTimeToUrl(startOfYesterday())),
            fp.set("to_date_time", formatDateTimeToUrl(endOfYesterday())),
          ),
        )}
      />

      <PageLoading isLoading={props.isLoading} />

      {query.show_filter === "true" && (
        <AdminNeighborhoodsFilterDialogWrapper
          open={true}
          filter={props.filter}
          onFilterChange={filter => {
            props.setLocationQueryFilter(filter);
            props.setLocationQuery(fp.unset("show_filter"));
          }}
          onRequestClose={() => props.setLocationQuery(fp.unset("show_filter"))}
        />
      )}

      <NeighborhoodsMap
        activeId={activeId}
        neighborhoods={props.neighborhoods}
        onActivate={x =>
          props.setLocationQuery(fp.set("active_id", x.get("id")))
        }
        onDeactivate={() => props.setLocationQuery(fp.unset("active_id"))}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrdersPerPostcodesContainer);
