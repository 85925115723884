import React from "react";
import _ from "lodash";
import { List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import TextWithLink from "../router/TextWithLink";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, { DataListColumn } from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { COMPLETED } from "../../constants/CODTransferStatus";

const NA = "N/A";
const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

BankDepositTable.propTypes = {
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.instanceOf(List).isRequired,

  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  onRemoveItem: PropTypes.func,
  createBankDepositHref: PropTypes.func,

  getLocalisationMessage: PropTypes.func.isRequired,
};

BankDepositTable.defaultProps = { createBankDepositHref: _.noop };

function BankDepositTable(props) {
  const { getLocalisationMessage } = props;
  return (
    <DataList
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      overscanRowCount={10}
      totalCount={props.total}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      altHeader={<div />}
      rowGetter={row => props.list.get(row.index)}
    >
      <DataListColumn
        width={120}
        label={getLocalisationMessage("id", "ID")}
        dataKey="id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <TextWithLink
            to={props.createBankDepositHref(row.cellData.get("id"))}
          >
            {row.cellData.get("id")}
          </TextWithLink>
        )}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("created_date", "Created Date")}
        dataKey="created_date"
        justifyContent="center"
        cellRenderer={row => (
          <DateTimeCell date={row.cellData.get("created_date")} />
        )}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("deposit_date", "Deposit Date")}
        dataKey="deposit_date"
        justifyContent="center"
        cellRenderer={row => (
          <DateTimeCell date={row.cellData.get("deposit_date")} />
        )}
      />

      {props.filter.getValue("status") === COMPLETED && (
        <DataListColumn
          width={120}
          label={getLocalisationMessage("process_date", "Process Date")}
          dataKey="completed_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("process_date")} />
          )}
        />
      )}

      <DataListColumn
        width={120}
        label={getLocalisationMessage("reference", "Reference")}
        dataKey="reference"
        justifyContent="center"
        cellRenderer={row => row.cellData.get("reference")}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("note", "Note")}
        dataKey="note"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => row.cellData.get("note") || NA}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("amount", "Amount")}
        dataKey="amount"
        justifyContent="center"
        cellRenderer={row => (
          <PriceWrapper
            price={row.cellData.get("amount")}
            code={row.cellData.getIn(["currency", "code"])}
          />
        )}
      />

      {Boolean(
        props.filter.getValue("status") !== COMPLETED && props.onRemoveItem,
      ) && (
        <DataListColumn
          width={120}
          label={getLocalisationMessage("actions", "Actions")}
          dataKey="actions"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Button onClick={() => props.onRemoveItem(row.rowData.get("id"))}>
              {getLocalisationMessage("remove", "Remove")}
            </Button>
          )}
        />
      )}
    </DataList>
  );
}

export default enhancer(BankDepositTable);
