import React from "react";
import { isDate, isValid, isFuture } from "date-fns";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { formatText } from "../../helpers/FormatUtils";
import { validateReferenceForLocalisation } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CODTransferStatus, {
  COMPLETED,
} from "../../constants/CODTransferStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({
    form: "UpdateCODTransferStatusDialog",
    validate: (values, props) => ({
      status:
        fp.isEmpty(values.status) &&
        props.getLocalisationMessage("select_status", "Select Status"),
      bankReference:
        values.status === COMPLETED &&
        validateReferenceForLocalisation(
          values.bankReference,
          props.getLocalisationMessage,
        ),
      bankPaymentDate:
        Boolean(
          values.status === COMPLETED &&
            (!isDate(values.bankPaymentDate) ||
              !isValid(values.bankPaymentDate) ||
              isFuture(values.bankPaymentDate)),
        ) &&
        props.getLocalisationMessage(
          "add_bank_payment_date_properly",
          "Add Bank Payment Date Properly",
        ),
    }),
  }),
  formValues({
    status: "status",
  }),
);

UpdateCODTransferStatusDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  status: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function UpdateCODTransferStatusDialog(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog open={true} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogContent style={{ minWidth: 400 }}>
        <form autoComplete="off">
          <FormSelectField
            name="status"
            autoWidth={true}
            fullWidth={true}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            options={CODTransferStatus}
            label={getLocalisationMessage(
              "cod_transfer_status",
              "COD Transfer Status",
            )}
          />

          {props.status === COMPLETED && (
            <div>
              <FormTextField
                name="bankReference"
                fullWidth={true}
                label={getLocalisationMessage(
                  "bank_payment_reference",
                  "Bank Payment Reference",
                )}
              />

              <FormDateField
                name="bankPaymentDate"
                label={getLocalisationMessage(
                  "bank_payment_date",
                  "Bank Payment Date",
                )}
              />
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(UpdateCODTransferStatusDialog);
