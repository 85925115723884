import { Observable } from "rxjs";
import React, { useState } from "react";
import { fromJS, List, Map } from "immutable";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add as ContentAdd, FilterList } from "@material-ui/icons";
import { loadWarehouseList } from "../../actions/AdminWarehouseActions";
import {
  isEqualData,
  isEqualWithoutFunctions,
  toJS,
} from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  WAREHOUSE_CREATE_URL,
  WAREHOUSE_ITEM_URL,
} from "../../constants/AdminPathConstants";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import FlexBox from "../../components/ui-core/FlexBox";
import fp from "lodash/fp";
import { warehouseFilterMapper } from "../../helpers/WarehouseFilterMapper";
import WarehouseListFilter from "../../wrappers/admin/WarehouseListFilter";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { loadWarehouseList },
  ),
  getContext({ setLocationQueryFilter: PropTypes.func.isRequired }),
  mapPropsStream((propsStream: Observable) => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), warehouseFilterMapper))
      .distinctUntilChanged(isEqualData);

    const warehouseListResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        props
          .loadWarehouseList(filter)
          .catch(() => Observable.of({ pending: false })),
      )
      .map((response) => fromJS(response))
      .startWith(Map());

    return propsStream
      .combineLatest(
        filterStream,
        warehouseListResponseStream,
        (props, filter, warehouseList) => ({
          ...props,
          filter,
          list: warehouseList.getIn(["payload", "data", "list"], List()),
          total: warehouseList.getIn(["payload", "data", "total"], 0),
          isLoading: warehouseList.get("pending", false),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminWarehouseList.propTypes = {
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
};

function AdminWarehouseList({
  filter,
  getLocalisationMessage,
  isLoading,
  list,
  setLocationQueryFilter,
  total,
}) {
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  return (
    <AdminAppLayout
      slug="warehouse"
      title={getLocalisationMessage("warehouses", "Warehouses")}
    >
      <WarehouseListFilter
        initialValues={toJS(filter)}
        open={isOpenFilter}
        onFilterChange={(v) => setLocationQueryFilter(filter.setValueMap(v))}
        onRequestClose={() => setIsOpenFilter(false)}
      />
      <DataList
        isLoading={isLoading}
        totalCount={total}
        list={list}
        rowCount={list.size}
        overscanRowCount={9}
        rowGetter={(options) => list.get(options.index)}
        filter={filter}
        onFilterChange={(v) => setLocationQueryFilter(v)}
        cardActionIcons={
          <FlexBox>
            <Link to={WAREHOUSE_CREATE_URL}>
              <Tooltip
                title={getLocalisationMessage(
                  "create_new_warehouse",
                  "Create New Warehouse",
                )}
              >
                <IconButton>
                  <ContentAdd />
                </IconButton>
              </Tooltip>
            </Link>
            <IconButton onClick={() => setIsOpenFilter(true)}>
              <FilterList />
            </IconButton>
          </FlexBox>
        }
      >
        <DataListColumn
          width={104}
          label={getLocalisationMessage(
            "warehouse_indices",
            "Почтовые отделения",
          )}
          dataKey="postal_code"
          cellRenderer={(row) => (
            <Link to={WAREHOUSE_ITEM_URL + row.cellData.get("id")}>
              {row.cellData.get("name") || getLocalisationMessage("na", "N/A")}
            </Link>
          )}
        />

        <DataListColumn
          width={104}
          label={getLocalisationMessage("jurisdiction", "Юрисдикция")}
          dataKey="najurisdictionme"
          cellRenderer={(row) => {
            const jurisdiction = row.cellData.get("jurisdiction");
            return jurisdiction
              ? jurisdiction.get("name")
              : getLocalisationMessage("na", "N/A");
          }}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("postcode")}
          cellRenderer={(row) => {
            const postcode = row.cellData.get("postcode");
            return postcode
              ? postcode.get("name")
              : getLocalisationMessage("na", "N/A");
          }}
        />

        <DataListColumn
          width={104}
          label={getLocalisationMessage("operators_count", "Кол-во операторов")}
          dataKey="operators_count"
          cellRenderer={(row) => row.cellData.get("user_count", 0)}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("parent_department")}
          cellRenderer={(row) => row.cellData.getIn(["parent", "name"])}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("warehouse_child_count")}
          dataKey="children"
          cellRenderer={(row) => {
            let children = row.cellData.get("children");
            children = children ? children.toJS() : [];
            return children.length;
          }}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("warehouse_cover_type", "Тип покрытия")}
          dataKey="coverage_type"
          cellRenderer={(row) => {
            const type = row.cellData.get("coverage_type");
            if (type) {
              switch (type) {
                case "POSTAL_CODE":
                  return getLocalisationMessage("warehouse_by_postalcode");
                case "JURISDICTION":
                  return getLocalisationMessage("warehouse_by_jurisdiction");
                case "CHILD":
                  return getLocalisationMessage("warehouse_by_children");
                default:
                  return getLocalisationMessage("warehouse_by_postalcode");
              }
            } else {
              return getLocalisationMessage("na", "N/A");
            }
          }}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("type", "Тип")}
          dataKey="type"
          cellRenderer={(row) => {
            const type = row.cellData.get("type");
            if (type) {
              return getLocalisationMessage(
                `warehouse_${type.toLowerCase()}`,
                "N/A",
              );
            }
            return getLocalisationMessage("na", "N/A");
          }}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("status")}
          dataKey="type"
          cellRenderer={(row) =>
            getLocalisationMessage(row.cellData.get("status"))
          }
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminWarehouseList);
