import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../../helpers/HOCUtils";
import { getMessage } from "../../../reducers/LocalizationReducer";
import ModalPaper from "../../../components/ui-core/ModalPaper";
import DashboardFilterForChartForm from "../../../components/dashboard-core/DashboardFilterForChartForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "600px",
      maxWidth: "700px",
      minHeight: "auto",
    },
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
);

AdminDashboardFilterDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  withWarehouse: PropTypes.bool,
  withServiceType: PropTypes.bool,
};

function AdminDashboardFilterDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage("filter", "Filter")}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={props.onRequestClose}
    >
      <DashboardFilterForChartForm
        withWarehouse={props.withWarehouse}
        withServiceType={props.withServiceType}
        onCancel={props.onRequestClose}
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminDashboardFilterDialogWrapper);
