import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, mapPropsStream } from "recompose";
import { getMessage } from "../../reducers/LocalizationReducer";
import AdminOrderBatchUpdateContainer from "./AdminOrderBatchUpdateContainer";
import DataListFilter from "../../helpers/DataListFilter";
import { IN_TRANSIT } from "../../constants/OrderStatusCodes";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import { OrderSortingDB } from "../../firebase/OrderSortingDB";
import { Map } from "immutable";
import { Observable } from "rxjs";
import { isEqualData } from "../../helpers/DataUtils";
import { CONTAINER } from "../../constants/OrderType";

const enhancer = compose(
  connect((state) => ({
    warehouseId: getUserWarehouseId(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const dbStream = propsStream
      .distinctUntilKeyChanged("warehouseId")
      .map((props) => new OrderSortingDB(props.warehouseId));

    const initialState = {
      orders: Map(),
      registries: Map(),
    };

    const stateStream = dbStream
      .switchMap((db: OrderSortingDB) =>
        !db.warehouseId
          ? Observable.of(initialState)
          : Observable.combineLatest(
              db.getOrders(),
              db.getRegistries(),
              (orders, registries) => ({
                orders,
                registries,
              }),
            ).startWith(initialState),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(stateStream, (props, state) => ({
      ...props,
      ...state,
    }));
  }),
);

const AdminOrderOutboundContainerContainerWrapper = (props) => (
  <AdminOrderBatchUpdateContainer
    {...props}
    orders={props.orders}
    registries={props.registries}
    outBoundSorting={true}
    tab={CONTAINER}
    filter={
      new DataListFilter({
        page: 0,
        size: 50,
        status: IN_TRANSIT,
        type: CONTAINER,
      })
    }
  />
);
AdminOrderOutboundContainerContainerWrapper.propTypes = {
  orders: PropTypes.instanceOf(Map),
  registries: PropTypes.instanceOf(Map),

  getLocalisationMessage: PropTypes.func,
};
export default enhancer(AdminOrderOutboundContainerContainerWrapper);
