import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { closeDriverDynamicRunSheet } from "../../api/shared/DriverApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriverRunsheetFilterForm from "../../components/drivers-core/DriverRunSheetFilterForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_DRIVER_DYNAMIC_RUNSHEET_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "500px",
      maxWidth: "500px",
      minHeight: "auto",
    },
  }),
);

AdminDriverRunSheetFilterWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  driverId: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminDriverRunSheetFilterWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage(
        "driver_run_sheet_filter",
        "Run Sheet Filter",
      )}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <DriverRunsheetFilterForm
        onCloseDriverRunSheet={() => {
          Promise.resolve(
            closeDriverDynamicRunSheet(props.driverId).catch(
              ResponseError.throw,
            ),
          )
            .then(() =>
              props.showSuccessMessage(
                props.getLocalisationMessage(
                  "successfully_updated",
                  "Successfully Updated",
                ),
              ),
            )
            .catch(() =>
              props.showErrorMessage(
                props.getLocalisationMessage("update_field", "Update Field!"),
              ),
            );
        }}
        onSubmit={values => {
          window.open(
            updateQuery(CREATE_DRIVER_DYNAMIC_RUNSHEET_URL, {
              date: formatDateToUrl(values.date),
              ids: [props.driverId],
            }),
          );
          props.onRequestClose();
        }}
        onDismiss={props.onRequestClose}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminDriverRunSheetFilterWrapper);
