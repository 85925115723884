import sprintf from "sprintf";
import WingApi from "../helpers/WingApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../shared/constants/ApiConstants";

const CUSTOM_PACKAGE_PRICELIST_URL = `${API_ROOT_URL}/admin/packages/pricing`;
const DEFAULT_PACKAGE_PRICELIST_URL = `${
  API_ROOT_URL
}/admin/default_packages/pricing`;
// GET - POST
const CUSTOMER_PACKAGE_PRICELIST_URL = `${
  API_ROOT_URL
}/admin/packages/%s/pricing`;
const CUSTOMER_PACKAGE_PRICELIST_ITEM_URL = `${
  API_ROOT_URL
}/admin/packages/%s/pricing/%s`;
const BATCH_COPY_CUSTOMER_PACKAGE_PRICE = `${
  API_ROOT_URL
}/admin/packages/%s/batch_pricing`;

// V2
const DEFAULT_PACKAGE_PRICELIST_V2_URL = `${
  API_V2_ROOT_URL
}/admin/packages/pricing`;

export function loadCustomPackagePricelist(filter) {
  return (getState, dispatch, api: WingApi) =>
    api.getStream(CUSTOM_PACKAGE_PRICELIST_URL, {
      params: filter.toRequestParams(),
    });
}

export function loadDefaultPackagePricelist(filter) {
  return (getState, dispatch, api: WingApi) =>
    api.getStream(DEFAULT_PACKAGE_PRICELIST_URL, {
      params: filter.toRequestParams(),
    });
}

export function loadDefaultPackagePricelistV2(filter) {
  return (getState, dispatch, api: WingApi) =>
    api.getStream(DEFAULT_PACKAGE_PRICELIST_V2_URL, {
      params: filter.toRequestParams(),
    });
}

export function createDefaultPackagePricelistItem(body) {
  return (getState, dispatch, api: WingApi) =>
    api.post(DEFAULT_PACKAGE_PRICELIST_URL, { body });
}

export function createDefaultPackageNewPricelistItem(body) {
  return (getState, dispatch, api: WingApi) =>
    api.post(DEFAULT_PACKAGE_PRICELIST_V2_URL, { body });
}

export function createPackagePricelistItem(customerId, body) {
  return (getState, dispatch, api: WingApi) =>
    api.post(sprintf(CUSTOMER_PACKAGE_PRICELIST_URL, customerId), { body });
}

export function updatePackagePricelistItem(customerId, body) {
  return (getState, dispatch, api: WingApi) =>
    api.put(sprintf(CUSTOMER_PACKAGE_PRICELIST_URL, customerId), { body });
}

export function deletePackagePricelistItem(customerId, packageId) {
  return (getState, dispatch, api: WingApi) =>
    api.delete(
      sprintf(CUSTOMER_PACKAGE_PRICELIST_ITEM_URL, customerId, packageId),
    );
}

export function batchCopyCustomerPackagePricelist(customerId, body) {
  return (getState, dispatch, api: WingApi) =>
    api.post(sprintf(BATCH_COPY_CUSTOMER_PACKAGE_PRICE, customerId), { body });
}
