import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getVenueCategoryItem,
  updateVenueCategoryItem,
} from "../../api/admin/AdminVenueCategoryApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import ShopCategoryForm from "../../components/shop-core/ShopCategoryForm";

const enhancer = compose(
  renderIf(props => props.categoryId > 0),
  useSheet({
    content: {
      maxWidth: "1000px",
      minWidth: "1000px",
      backgroundColor: "#fff",
    },
  }),
  connect(null, { showSuccessMessage, showErrorMessage }),
  mapPropsStream(propsStream => {
    const categoryItemResponseStream = propsStream
      .distinctUntilKeyChanged("categoryId")
      .switchMap(props =>
        getVenueCategoryItem(props.categoryId).catch(error =>
          Observable.of({ error }),
        ),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      categoryItemResponseStream,
      (props, categoryItemResponse) => ({
        ...props,
        categoryItem: categoryItemResponse.get("payload"),
        isLoading: categoryItemResponse.get("pending"),
      }),
    );
  }),
);

AdminShopCategoryEditDialogWrapper.propTypes = {
  classes: PropTypes.object,
  categoryId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  categoryItem: PropTypes.instanceOf(Map),
};

function AdminShopCategoryEditDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      onRequestClose={props.onRequestClose}
      title="Edit Category"
      paperClassName={classes.content}
      isLoading={props.isLoading}
    >
      <ShopCategoryForm
        onRequestClose={props.onRequestClose}
        onSubmit={values =>
          updateVenueCategoryItem(props.categoryId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        initialValues={{
          ...toCamelCase(props.categoryItem),
          status: _.lowerCase(props.categoryItem.get("status")),
        }}
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.onRequestClose();
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminShopCategoryEditDialogWrapper);
