import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

// const CUSTOMER_FEEDBACK_URL = `${API_ROOT_URL}/admin/customer/feedback`;
const CUSTOMER_FEEDBACK_LIST_URL = `${API_ROOT_URL}/admin/customer/feedback`;
const OPERATOR_FEEDBACK_CREATE_URL = `${API_ROOT_URL}/admin/order/operator_feedback`;
const CUSTOMER_FEEDBACK_ITEM_URL = `${API_ROOT_URL}/admin/customer/feedback/%s`;

export const getCustomerFeedbackList = (request: DataListFilter) =>
  api.getStream(CUSTOMER_FEEDBACK_LIST_URL, {
    params: request.getDefinedValues(),
  });

export const saveCustomerFeedback = body =>
  api.post(OPERATOR_FEEDBACK_CREATE_URL, {
    body,
  });

export const getCustomerFeedback = id =>
  api.getStream(sprintf(CUSTOMER_FEEDBACK_ITEM_URL, id));
