import React from "react";
import { List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  mapPropsStream,
  withHandlers,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  ListSubheader,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import Audio from "../ui-core/Audio";
import ScannerTextField from "../deprecated/ScannerTextField";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import ErrorSound from "../../assets/voices/error.mp3";
import { withTheme } from "@material-ui/core/styles";
import OrderSortingVerifyBinOrdersTableWrapper from "./OrderSortingVerifyBinOrdersTableWrapper";
import OrderSortingVerifyBinConfirmDialog from "./OrderSortingVerifyBinConfirmDialog";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import Tabs from "../ui-core/Tabs";
import FlexBox, { JUSTIFY_END } from "../ui-core/FlexBox";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";

const UNVERIFIED = "Unverified";
const VERIFIED = "Verified";
const UNKNOWN = "Unknown";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("selectedBin"),
  useSheet({
    chip: { margin: "4px" },
    chipDanger: { backgroundColor: danger1 },
    modal: { width: "800px", minWidth: 800 },
    scannerField: { marginBottom: "10px" },
  }),
  withState(
    "state",
    "setState",
    Map({
      scannedOrders: Set(),
      tab: UNVERIFIED,
      inputRef: null,
    }),
  ),
  mapPropsStream((propsStream) => {
    const { handler: refInput, stream: refStream } = createEventHandler();
    const { handler: onSetFocus, stream: onSetFocusStream } =
      createEventHandler();

    const invalidOrdersStream = propsStream.map((props) => {
      const unknown = props.state
        .get("scannedOrders")
        .subtract(props.selectedOrders);
      const unverified = props.selectedOrders.subtract(
        props.state.get("scannedOrders"),
      );

      const allOrders = List().withMutations((list) => {
        unverified.forEach((number) => {
          list.push(new Map({ number, status: UNVERIFIED }));
        });

        unknown.forEach((number) => {
          list.push(new Map({ number, status: UNKNOWN }));
        });

        return list;
      });

      const verifiedOrders = List().withMutations((list) => {
        props.state.get("scannedOrders").forEach((number) => {
          list.push(new Map({ number, status: VERIFIED }));
        });

        return list;
      });

      return {
        unknown,
        unverified,
        allOrders,
        verifiedOrders,
      };
    });

    const sideEffectsStream = mergeSideEffectStreams(
      onSetFocusStream
        .withLatestFrom(refStream)
        .delay(1000)
        .do(([, input]) => {
          input.focus();
        }),
    ).startWith(null);

    return propsStream.combineLatest(
      invalidOrdersStream,
      sideEffectsStream,
      (props, invalidOrders) => ({
        ...props,

        onSetFocus,
        refInput,
        unknown: invalidOrders.unknown,
        unverified: invalidOrders.unverified,
        allOrders: invalidOrders.allOrders,
        verifiedOrders: invalidOrders.verifiedOrders,
      }),
    );
  }),
  withHandlers({
    onScanOrder: (props) => (orderNumber) => {
      props.setState((s) =>
        s.update("scannedOrders", (x) => x.add(fp.trim(orderNumber))),
      );
    },
  }),
  withTheme,
);

OrderSortingVerifyBinOrdersDialog.propTypes = {
  classes: PropTypes.object,

  unknown: PropTypes.instanceOf(Set).isRequired,
  unverified: PropTypes.instanceOf(Set).isRequired,
  allOrders: PropTypes.instanceOf(List),
  verifiedOrders: PropTypes.instanceOf(List),

  setState: PropTypes.func,
  state: PropTypes.instanceOf(Map),

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Set).isRequired,
  orders: PropTypes.instanceOf(Map),

  onConfirmOrders: PropTypes.func.isRequired,

  bins: PropTypes.instanceOf(Map),
  selectedBin: PropTypes.instanceOf(Map),

  getLocalisationMessage: PropTypes.func,
  onScanOrder: PropTypes.func,
  onConfirmUnknownOrder: PropTypes.func,
  theme: PropTypes.object,

  onSetFocus: PropTypes.func,
  refInput: PropTypes.func,
};

function OrderSortingVerifyBinOrdersDialog(props) {
  const { classes, getLocalisationMessage } = props;

  const tab = props.state.get("tab", UNVERIFIED);
  const unknownCount = props.unknown.size;
  const unverifiedCount = props.unverified.size;
  const totalCount = props.selectedOrders.size;
  const verifiedCount = totalCount - unverifiedCount;
  const blockView = props.unknown.size > 0;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: "800px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${getLocalisationMessage("verify_orders", "Verify Orders")} - ${
              props.selectedBin && props.selectedBin.get("label", "")
            }`}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            {`${getLocalisationMessage(
              "verified",
              "Verified",
            )}: ${verifiedCount}, ${getLocalisationMessage(
              "total",
              "Total",
            )}: ${totalCount}`}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <ScannerTextField
          className={classes.scannerField}
          focus={true}
          autoFocus={true}
          fullWidth={true}
          hintText={getLocalisationMessage("verify_orders", "Verify Orders")}
          disabled={blockView}
          onChange={props.onScanOrder}
          inputRef={props.refInput}
        />

        {Boolean(unknownCount === 0 && unverifiedCount === 0) && (
          <ListSubheader>
            {getLocalisationMessage(
              "all_orders_were_verified",
              "All Orders Were Verified",
            )}
          </ListSubheader>
        )}

        {unknownCount > 0 && (
          <div>
            <OrderSortingVerifyBinConfirmDialog
              open={blockView}
              bins={props.bins}
              orders={props.orders}
              selectedBin={props.selectedBin}
              scannedOrder={props.unknown.keySeq().get(0)}
              onRequestClose={(orderNumber) => {
                props.setState((s) =>
                  s.update("scannedOrders", (x) => x.subtract([orderNumber])),
                );
                props.onSetFocus(orderNumber);
              }}
              onConfirm={(order) => {
                props.onConfirmUnknownOrder(order, props.selectedBin);
                props.onSetFocus(order.get("barcode"));
              }}
            >
              <Audio play={true} src={ErrorSound} />
            </OrderSortingVerifyBinConfirmDialog>
          </div>
        )}

        <Tabs
          value={tab}
          width="auto"
          onChange={(e, v) => props.setState((s) => s.update("tab", () => v))}
          tabs={[
            {
              label: `${getLocalisationMessage(
                "unverified_orders",
                "Unverified Orders",
              )} (${props.allOrders.size})`,
              value: UNVERIFIED,
            },
            {
              label: `${getLocalisationMessage(
                "verified_orders",
                "Verified Orders",
              )} (${props.verifiedOrders.size})`,
              value: VERIFIED,
            },
          ]}
        />

        {tab === UNVERIFIED && (
          <OrderSortingVerifyBinOrdersTableWrapper list={props.allOrders} />
        )}

        {tab === VERIFIED && (
          <OrderSortingVerifyBinOrdersTableWrapper
            list={props.verifiedOrders}
          />
        )}
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() =>
              props.onConfirmOrders({
                validated: props.state.get("scannedOrders"),
                unverified: props.unverified,
              })
            }
          >
            {getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderSortingVerifyBinOrdersDialog);
