import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminSMSSettingsContainer from "./AdminSMSSettingsContainer";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

SMSSettings.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  initialValues: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SMSSettings(props) {
  return (
    <AdminSMSSettingsContainer
      onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
      data={props.location.query.data}
      tab={props.location.query.view_tab}
      initialValues={props.initialValues}
      location={props.location}
      filter={props.filter}
      onSubmitSuccess={() => {
        props.showSuccessMessage(
          props.getLocalisationMessage(
            "settings_has_been_saved_successfully",
            "Settings has been saved successfully",
          ),
        );
      }}
      onSubmitFail={error => {
        props.showErrorMessage(error);
      }}
    />
  );
}

export default enhancer(SMSSettings);
