import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderBriefSubjectInfo from "./OrderBriefSubjectInfo";
import OrderItemPaymentInfoFood from "./OrderItemPaymentInfoFood";
import LinkButton from "../ui-core/LinkButton";
import OrderItemProducts from "./OrderItemProducts";
import { fade } from "../../helpers/FormatUtils";

const enhancer = compose(
  useSheet({
    info: {
      color: fade("#263238", 0.7),
    },
    active: {
      color: "white",
    },
    multiLineFirst: {
      lineHeight: "20px",
      fontSize: "18px",
      textAlign: "center",
      fontWeight: 500,
      marginTop: "1px,",
    },
    multiLineSecond: {
      lineHeight: "18px",
      fontSize: "16px",
    },
    address: {
      listStyle: "none",
      paddingLeft: 0,
      width: "100%",
      "& > li": {
        paddingLeft: "70px",
        position: "relative",
        minHeight: "70px",
        display: "flex",
      },
    },
    line: {
      paddingBottom: "30px",
      "&:after": {
        content: '""',
        width: "2px",
        height: "100%",
        position: "absolute",
        left: "30px",
        top: 0,
        borderLeft: "2px dashed rgba(38, 50, 56, 0.12)",
      },
    },
    hr: {
      borderColor: "#fff",
    },
    closeLink: {
      color: "#fff",
      textTransform: "uppercase",
      textDecoration: "none",
      marginTop: "20px",
    },
    closeIcon: {
      color: "#fff",
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderBriefInfoFood.propTypes = {
  classes: PropTypes.object,
  isActiveOrder: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
  onItemCloseClick: PropTypes.func,
};

function OrderBriefInfoFood(props) {
  const { classes, order } = props;

  return (
    <FlexBox
      className={cx(classes.info, { [classes.active]: props.isActiveOrder })}
      direction="column"
    >
      <ul className={classes.address}>
        <li className={classes.line}>
          <OrderBriefSubjectInfo
            title="sender"
            subject={order.get("sender_data")}
          />
        </li>
        <li>
          <OrderBriefSubjectInfo
            title="recipient"
            subject={order.get("recipient_data")}
          />
        </li>
      </ul>
      <hr className={classes.hr} />
      <OrderItemProducts order={order.get("food_order_custom_details")} />
      <hr className={classes.hr} />
      <OrderItemPaymentInfoFood order={order} />
      <FlexBox flex={true} justify={JUSTIFY_CENTER}>
        <LinkButton
          className={classes.closeLink}
          onClick={() => props.onItemCloseClick()}
        >
          <Close className={classes.closeIcon} /> Close
        </LinkButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderBriefInfoFood);
