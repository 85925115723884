import React, { useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Menu as NavigationMenu,
  Search as ActionSearch,
} from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { getMarketplace } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import { reduxForm } from "redux-form";

const useStyles = makeStyles(() => ({
  root: { width: "500px" },
}));
const enhancer = compose(
  withTheme,
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplace: getMarketplace(state),
  })),
  reduxForm({ form: "JMSearchForm" }),
);

SearchWrapper.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.any,
  getLocalisationMessage: PropTypes.func,
  menuLayoutDrawer: PropTypes.any.isRequired,
  change: PropTypes.any,
};

function SearchWrapper(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const { getLocalisationMessage, searchText, setSearchText } = props;
  const classes = useStyles();
  return (
    <FlexBox container={16} className={classes.root}>
      <FlexBox flex={true} element={<Card />}>
        {props.menuLayoutDrawer({
          visibleMenu: showMenu,
          toggleMenu: () => setShowMenu(!showMenu),
        })}

        <FlexBox align={ALIGN_CENTER}>
          <IconButton onClick={() => setShowMenu(!showMenu)}>
            <NavigationMenu />
          </IconButton>
        </FlexBox>

        {!showSearch && (
          <FlexBox flex={true} align={ALIGN_CENTER} justify={JUSTIFY_CENTER}>
            Jurisdiction Management
          </FlexBox>
        )}

        {showSearch && (
          <FlexBox flex={true} align={ALIGN_CENTER} justify={JUSTIFY_CENTER}>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FormTextField
                  name="search"
                  fullWidth={true}
                  value={searchText}
                  onChange={(_, v) => setSearchText(v)}
                  hintText={getLocalisationMessage("search", "Search")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}

        <FlexBox align={ALIGN_CENTER}>
          <IconButton
            onClick={() => {
              setSearchText("");
              props.change("search", "");
              setShowSearch(!showSearch);
            }}
          >
            <ActionSearch />
          </IconButton>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SearchWrapper);
