import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";

const useStyles = makeStyles({
  root: {
    minHeight: "40px",
    padding: "2px 0 2px 20px",
    width: "100%",
  },
  title: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: 500,
  },
});

AppTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

function AppTitle(props) {
  const classes = useStyles();

  return (
    <FlexBox className={classes.root} justify="space-between" align="center">
      <Typography variant="h5" className={classes.title}>
        {props.title}
      </Typography>

      {props.children}
    </FlexBox>
  );
}

export default AppTitle;
