import React, { useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import FlexBox from "../../components/ui-core/FlexBox";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import SimpleAutocomplete from "../../components/form/SimpleAutocomplete";
import { getJMById, getJMList2 } from "../../api/shared/CountryV2Api";
import {
  getPostcodeById,
  postcodeChoose,
} from "../../api/admin/AdminPostcodesApi";
import {
  getWarehouseById,
  warehouseChoose,
} from "../../api/admin/AdminWarehouseApi";
import AdminWarehouseFilterTypes from "../../constants/AdminWarehouseFilterTypes";

const enhancer = compose(
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "500px",
      minHeight: "auto",
    },
  }),
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

WarehouseListFilter.propTypes = {
  classes: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  initialValues: PropTypes.object,
};

function WarehouseListFilter({
  classes,
  getLocalisationMessage,
  onFilterChange,
  onRequestClose,
  open,
  initialValues,
}) {
  const [filter, setFilter] = useState(initialValues);
  return (
    <ModalPaper
      open={open}
      title={getLocalisationMessage("filter", "Filter")}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={onRequestClose}
    >
      <FlexBox align="center" container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FlexBox
                  direction="column"
                  flex={true}
                  container={24}
                  gutter={16}
                >
                  <FlexBox>
                    <FormControl
                      fullWidth={true}
                      size="medium"
                      variant="outlined"
                    >
                      <InputLabel>
                        {getLocalisationMessage("status")}
                      </InputLabel>
                      <Select
                        label={getLocalisationMessage("status")}
                        value={filter.status}
                        onChange={(e) =>
                          setFilter({ ...filter, status: e.target.value })
                        }
                      >
                        {AdminWarehouseFilterTypes.map((i) => (
                          <MenuItem key={i} value={i}>
                            {getLocalisationMessage(i)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FlexBox>
                  <FlexBox>
                    <SimpleAutocomplete
                      initialId={filter.jurisdiction_id}
                      onChange={(v) =>
                        setFilter({ ...filter, jurisdiction_id: v.id })
                      }
                      getById={getJMById}
                      chooseAPI={getJMList2}
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction")}
                    />
                  </FlexBox>
                  <FlexBox>
                    <SimpleAutocomplete
                      initialId={filter.parent_id}
                      onChange={(v) =>
                        setFilter({ ...filter, parent_id: v.id })
                      }
                      getById={getWarehouseById}
                      chooseAPI={warehouseChoose}
                      fullWidth={true}
                      label={getLocalisationMessage("parent_department")}
                    />
                  </FlexBox>
                  <FlexBox>
                    <SimpleAutocomplete
                      replaceName="index"
                      initialId={filter.postcode_id}
                      onChange={(v) =>
                        setFilter({ ...filter, postcode_id: v.id })
                      }
                      getById={getPostcodeById}
                      chooseAPI={postcodeChoose}
                      fullWidth={true}
                      label={getLocalisationMessage("postcode")}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
              <CardActions>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} justify="flex-end">
                    <Button onClick={() => onRequestClose()}>
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </Button>
                    <Button
                      onClick={() => {
                        onFilterChange(filter);
                        onRequestClose();
                      }}
                    >
                      {getLocalisationMessage("submit", "Submit")}
                    </Button>
                  </FlexBox>
                </FlexBox>
              </CardActions>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(WarehouseListFilter);
