import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import _ from "lodash";
import { getReportWarehouses } from "../../api/shared/RouteTemplateApi";

export const EDIT = "edit";
export const SAVE = "save";
export const LINK = "link";
export const DIALOG = "dialog";
export const DATE = "date";
export const COLUMN = "column";
export const RENDER = "render";
export const ACTION = "action";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {props.isCheckboxEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {props.headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={index === 0 ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array,
  isCheckboxEnabled: PropTypes.bool,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  container: {
    maxHeight: "calc(100vh - 11rem)",
  },
  rowRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  unsetBackgroundColor: {
    backgroundColor: `unset`,
  },
  redBackgroundColor: {
    backgroundColor: `rgba(242, 104, 74, 0.6) !important`,
  },
});
const Row2 = ({
  row,
  childrens,
  loadingChilds,
  fetchChilds,
  getLocalisationMessage,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const jurisdictionName = _.get(row, "jurisdiction.name");
  const parentId = _.get(row, "jurisdiction.id");

  return (
    <React.Fragment>
      <TableRow
        className={classes.rowRoot}
        style={{ backgroundColor: "#e8f5e9" }}
      >
        <TableCell
          style={{
            width: "25rem",
            paddingLeft: "3rem",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!childrens[parentId]) fetchChilds(parentId);
              setOpen(!open);
            }}
          >
            {loadingChilds.find((v) => v === parentId) ? (
              <CircularProgress size={16} color="secondary" />
            ) : open ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </IconButton>
          {jurisdictionName}
        </TableCell>
        <TableCell align="center">{_.get(row, "warehouses_total")}</TableCell>
        <TableCell align="center">{_.get(row, "warehouses_active")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_created")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_in_sorting")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_issued")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_delivered")}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead style={{ backgroundColor: "#e8f5e9" }}>
                  <TableRow style={{ opacity: 0 }}>
                    <TableCell>{getLocalisationMessage("postcode")}</TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("connected_to_the_program")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("you_work_in_the_program")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("created")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("in_transit_public")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("boss_delivered_in_comp")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("delivered_by_postman")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childrens[parentId] ? (
                    childrens[parentId].map((children, index) => (
                      <TableRow
                        className={
                          classes[
                            _.get(children, "orders_created") ||
                            _.get(children, "orders_delivered") ||
                            _.get(children, "orders_in_sorting") ||
                            _.get(children, "orders_issued")
                              ? "unsetBackgroundColor"
                              : "redBackgroundColor"
                          ]
                        }
                        key={index}
                      >
                        <TableCell
                          style={{ width: "25rem", paddingLeft: "5rem" }}
                          component="th"
                          scope="row"
                        >
                          {_.get(
                            children,
                            "jurisdiction.name",
                            _.get(children, "warehouse.name"),
                          )}
                        </TableCell>
                        <TableCell align="center">-</TableCell>
                        <TableCell align="center">-</TableCell>
                        <TableCell align="center">
                          {_.get(children, "orders_created")}
                        </TableCell>
                        <TableCell align="center">
                          {_.get(children, "orders_in_sorting")}
                        </TableCell>
                        <TableCell align="center">
                          {_.get(children, "orders_issued")}
                        </TableCell>
                        <TableCell align="center">
                          {_.get(children, "orders_delivered")}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <LinearProgress
                      style={{ position: "absolute", width: "100%" }}
                      mode="indeterminate"
                      color="secondary"
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
Row2.propTypes = {
  row: PropTypes.object,
  childrens: PropTypes.object,
  loadingChilds: PropTypes.func,
  fetchChilds: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};
const Row = ({
  row,
  getLocalisationMessage,
  childrens,
  loadingChilds,
  fetchChilds,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const jurisdictionName = _.get(row, "jurisdiction.name");
  const parentId = _.get(row, "jurisdiction.id");
  return (
    <React.Fragment>
      <TableRow className={classes.rowRoot}>
        <TableCell
          style={{
            width: "25rem",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!childrens[parentId]) fetchChilds(parentId);
              setOpen(!open);
            }}
          >
            {loadingChilds.find((v) => v === parentId) ? (
              <CircularProgress size={16} color="secondary" />
            ) : open ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </IconButton>
          {jurisdictionName}
        </TableCell>
        <TableCell align="center">{_.get(row, "warehouses_total")}</TableCell>
        <TableCell align="center">{_.get(row, "warehouses_active")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_created")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_in_sorting")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_issued")}</TableCell>
        <TableCell align="center">{_.get(row, "orders_delivered")}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead style={{ opacity: 0 }}>
                  <TableRow>
                    <TableCell>{getLocalisationMessage("postcode")}</TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("connected_to_the_program")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("you_work_in_the_program")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("created")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("acceptance")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("boss_delivered_in_comp")}
                    </TableCell>
                    <TableCell align="center">
                      {getLocalisationMessage("delivered_by_postman")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childrens[parentId] ? (
                    childrens[parentId].map((children, index) =>
                      _.get(children, "jurisdiction.id") ? (
                        <Row2
                          key={index}
                          row={children}
                          childrens={childrens}
                          loadingChilds={loadingChilds}
                          fetchChilds={fetchChilds}
                          getLocalisationMessage={getLocalisationMessage}
                        />
                      ) : (
                        <TableRow
                          className={
                            classes[
                              _.get(children, "orders_created") > 0
                                ? "unsetBackgroundColor"
                                : "redBackgroundColor"
                            ]
                          }
                          key={index}
                        >
                          <TableCell
                            style={{ width: "25rem" }}
                            component="th"
                            scope="row"
                          >
                            {_.get(
                              children,
                              "jurisdiction.name",
                              _.get(children, "warehouse.name"),
                            )}
                          </TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">
                            {_.get(children, "orders_created")}
                          </TableCell>
                          <TableCell align="center">
                            {_.get(children, "orders_in_sorting")}
                          </TableCell>
                          <TableCell align="center">
                            {_.get(children, "orders_issued")}
                          </TableCell>
                          <TableCell align="center">
                            {_.get(children, "orders_delivered")}
                          </TableCell>
                        </TableRow>
                      ),
                    )
                  ) : (
                    <LinearProgress
                      style={{ position: "absolute", width: "100%" }}
                      mode="indeterminate"
                      color="secondary"
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
Row.propTypes = {
  row: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  childrens: PropTypes.object,
  loadingChilds: PropTypes.func,
  fetchChilds: PropTypes.func,
};

function MUITableReport({
  columns,
  getLocalisationMessage,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  list,
  rowsPerPage,
  total,
  withCheckbox,
  size,
  isLoading,
  withoutPagination,
  filter,
  showErrorMessage: showErrorMessage1,
  childrens,
  setChildrens,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [loadingChilds, setLoadingChilds] = useState([]);
  const fetchChilds = (parentId) => {
    let tempLoadingChilds = [...loadingChilds];
    tempLoadingChilds.push(parentId);
    setLoadingChilds(tempLoadingChilds);
    getReportWarehouses({
      ..._.omit(filter, "jurisdiction_id"),
      parentId,
    })
      .then((res) => {
        const tempChildrens = { ...childrens };
        tempChildrens[parentId] = _.get(res, "data.items");
        setChildrens(tempChildrens);
      })
      .catch((error) => {
        showErrorMessage1(error);
      })
      .finally(() => {
        tempLoadingChilds = loadingChilds.filter((v) => v !== parentId);
        setLoadingChilds(tempLoadingChilds);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={total}
              headCells={columns}
              isCheckboxEnabled={withCheckbox}
            />
            {isLoading ? (
              <LinearProgress
                style={{ position: "absolute", width: "100%" }}
                mode="indeterminate"
                color="secondary"
              />
            ) : (
              <TableBody>
                {list.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    childrens={childrens}
                    getLocalisationMessage={getLocalisationMessage}
                    loadingChilds={loadingChilds}
                    fetchChilds={fetchChilds}
                  />
                ))}
              </TableBody>
            )}
          </Table>
          {!withoutPagination && (
            <TablePagination
              style={{
                position: "fixed",
                background: "white",
                bottom: 0,
                width: "100vw",
                borderTop: "1px solid rgba(0, 0, 0, 0.3)",
              }}
              labelRowsPerPage={getLocalisationMessage("rows_per_page")}
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Paper>
    </div>
  );
}

MUITableReport.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  columns: PropTypes.array,
  total: PropTypes.number,
  withCheckbox: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium"]),
  isLoading: PropTypes.bool,
  withoutPagination: PropTypes.bool,
  filter: PropTypes.object,
  showErrorMessage: PropTypes.func,
  childrens: PropTypes.object,
  setChildrens: PropTypes.func,
};

export default enhancer(MUITableReport);
