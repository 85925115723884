import React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, withStyles } from "@material-ui/core";
import { Link } from "react-router";
// import _ from 'lodash';

// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: 'white',
//         height: 50,
//         color: '#555',
//     },
//     indicator: {
//         backgroundColor: theme.palette.primary.main,
//         height: 3,
//     },
// }));

const LinkTab = props => <StyledTab component={Link} {...props} />;

// const StyledTabs = withStyles(theme => ({
// root: {
//   color: "#444",
// },
// indicator: {
//   height: 1,
//   backgroundColor: theme.palette.primary.main,
// },
// }))(Tabs);

const StyledTab = withStyles({
  root: {
    height: 40,
    backgroundColor: "white",
  },
  // wrapper: {
  //   color: "#444",
  // },
})(Tab);

const TabsND = props => (
  // const classes = useStyles();
  <Tabs {...props}>
    {props.items &&
      props.items.map((item, index) =>
        props.isLink ? (
          <LinkTab
            tabIndex={item.value}
            id={`nav-tab-${index}`}
            key={item.value}
            to={item.value}
            label={item.title}
            icon={item.icon}
          />
        ) : (
          <StyledTab
            disabled={props.disabled}
            tabIndex={item.value}
            id={`tab-${index}`}
            key={item.value}
            value={item.value}
            label={item.title}
            icon={item.icon}
          />
        ),
      )}
  </Tabs>
);
TabsND.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf({
      title: PropTypes.string,
      value: PropTypes.any,
      icon: PropTypes.element,
    }),
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLink: PropTypes.bool,
  variant: PropTypes.oneOf(["fullWidth", "scrollable", "standard"]),
  value: PropTypes.any,
};

TabsND.defaultProps = {
  variant: "standard",
};

export default TabsND;
