import React, { useEffect, useRef, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "../../components/ui-core/TabsND";
import { Search, MoreVert } from "@material-ui/icons";
import { compose, mapPropsStream } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { isEqualData } from "../../helpers/DataUtils";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import _ from "lodash";
import ChipTextField from "../../components/deprecated/ChipTextField";
import DataListFilter from "../../helpers/DataListFilter";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    marginBottom: 10,
    marginTop: 10,
  },
});
const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const chipsStream = propsStream
      .map(props => props.filter && props.filter.getSearch())
      .distinctUntilChanged()
      .map(parseString)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(chipsStream, (props, chips) => ({
        ...props,
        chips,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);
AdminPermissionListHeaderWrapper.propTypes = {
  onFilterChange: PropTypes.func,
  chips: PropTypes.array,
  tabItems: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf({
      title: PropTypes.string,
      value: PropTypes.any,
      icon: PropTypes.element,
    }),
  ]),
  tabValue: PropTypes.any,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
};

function AdminPermissionListHeaderWrapper({
  getLocalisationMessage,
  onFilterChange,
  tabItems,
  tabValue,
  chips,
  filter,
}) {
  const { root, search, tabs } = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  return (
    <div className={root}>
      <div className={tabs}>
        <Tabs
          variant="scrollable"
          isLink={true}
          items={tabItems}
          value={tabValue}
        />
      </div>
      <div className={search} ref={ref}>
        <Search
          fontSize="large"
          style={{
            color: "rgba(0, 0, 0, 0.23)",
            top: ".5rem",
            left: ".5rem",
            position: "absolute",
          }}
        />
        <ChipTextField
          fullWidth={true}
          addOnBlur={false}
          clearOnBlur={false}
          value={chips}
          style={{
            padding: ".5rem 1rem 0 3rem",
            maxHeight: divHeight > 86 && "5.8rem",
            overflow: divHeight > 86 && "auto",
          }}
          maxItems={1}
          disableUnderline={true}
          hintText={getLocalisationMessage("search", "Search")}
          onChange={value =>
            onFilterChange(filter.setSearch(stringifyArray(value)))
          }
        />
      </div>
      <IconButton style={{ marginLeft: 4, marginRight: 10 }}>
        <MoreVert />
      </IconButton>
    </div>
  );
}

export default enhancer(AdminPermissionListHeaderWrapper);
