import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import BatchOrderCanceledUpdate from "../../components/orders-core/BatchOrderCanceledUpdate";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { RETURNING_TO_ORIGIN } from "../../constants/OrderStatusCodes";
import { getValue } from "../../helpers/DataUtils";
import { getSortingBatchUpdateItem } from "../../api/v2/admin/AdminOrderSortingApi";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

OfflineBatchReturningUpdateDialogWrapper.propTypes = {
  open: PropTypes.bool,
  isBulkReturning: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  warehouseId: PropTypes.number,
  filter: PropTypes.object,
  selectedOrderBarcodes: PropTypes.array,
  excludeSelectedItems: PropTypes.array,
  i18n: PropTypes.instanceOf(Map),
};

function OfflineBatchReturningUpdateDialogWrapper({
  initialValues,
  isBulkReturning,
  ...props
}) {
  const [batchItem, setBatchItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      isBulkReturning &&
      (props.filter.service_type_id ||
        props.filter.bean ||
        props.selectedOrderBarcodes.length)
    ) {
      setIsLoading(true);
      getSortingBatchUpdateItem({
        ...props.filter,
        type: "ORDER",
        barcodes: props.selectedOrderBarcodes,
      })
        .then(res => {
          const orderBarcodes = [];
          res.items.map(item => {
            orderBarcodes.push(item.barcode);
            return item;
          });

          setBatchItem({
            orderBarcodes,
            innerShipmentType: res.inner_shipment_type,
            weight: res.weight,
            to_warehouse:
              getValue(props.filter, "bean.to_warehouse") ||
              getValue(res, "to_warehouse"),
          });

          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [props.filter, props.selectedOrderBarcodes]);

  return (
    <BatchOrderCanceledUpdate
      isLoading={isLoading}
      open={props.open}
      initialValues={{
        ...batchItem,
        privacy: PUBLIC,
        orderStatus: RETURNING_TO_ORIGIN,
        supplier: { id: 1 },
      }}
      isBulkReturning={isBulkReturning}
      onRequestClose={() => {
        props.onRequestClose();
        setBatchItem({});
      }}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
        setBatchItem({});
      }}
    />
  );
}

export default enhancer(OfflineBatchReturningUpdateDialogWrapper);
