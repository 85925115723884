import React from "react";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormToggle from "../form/FormToggle";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormCashierUserAutoComplete from "../form/FormCashierUserAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText, parseFloat } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import genderTypes from "../../constants/GenderTypes";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    padding: { paddingTop: 10 },
    toggle: {
      paddingTop: "15px",
    },
    toggleLabel: { paddingTop: "20px" },
  }),
  reduxForm({
    form: "CashierForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      user:
        !isValidObjectId(values.user) &&
        props.getLocalisationMessage("select_user", "Select User"),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
    }),
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
);

CashierForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  readOnlySelectedFields: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getCachedWarehouse: PropTypes.func,
  getWarehousePredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

CashierForm.defaultProps = {
  readOnlySelectedFields: false,
};

function CashierForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormCashierUserAutoComplete
                  name="user"
                  fullWidth={true}
                  disabled={props.readOnlySelectedFields}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                  label={`${getLocalisationMessage("user", "User")} *`}
                />

                <FormWarehouseAutoComplete
                  fullWidth={true}
                  name="warehouse"
                  label={`${getLocalisationMessage("warehouse", "Warehouse")}*`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />

                <FormSelectField
                  name="gender"
                  label={getLocalisationMessage("gender", "Gender")}
                  fullWidth={true}
                  options={genderTypes}
                  className={classes.select}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                />

                <FormTextField
                  parseOnBlur={parseFloat}
                  label={getLocalisationMessage("quota", "Quota")}
                  fullWidth={true}
                  name="quota"
                />

                <FlexBox>
                  <FlexBox flex={10}>
                    <div className={classes.toggleLabel}>
                      {getLocalisationMessage("status", "Status")}
                    </div>
                  </FlexBox>
                  <FlexBox flex={2}>
                    <FormToggle
                      trueValue={ACTIVE}
                      falseValue={INACTIVE}
                      className={props.classes.toggle}
                      name="status"
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {props.getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {props.getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </form>
  );
}

export default enhancer(CashierForm);
