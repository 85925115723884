import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedOwnerUser,
  getCachedSalesUser,
  getOwnerUserPredictions,
  getSalesUserPredictions,
} from "../../api/admin/AdminUserApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import {
  getCachedCity,
  getCityPredictions,
} from "../../api/shared/CountryV2Api";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import JobFilterForm from "../../components/order-job/JobFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    paper: {
      "@media (max-width: 998px)": {
        minWidth: "300px",
      },
    },
  }),
  renderIf("open"),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

AdminJobFilterWrapper.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  showCashier: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
};

AdminJobFilterWrapper.defaultProps = {
  showCashier: false,
};

function AdminJobFilterWrapper(props) {
  return (
    <ModalPaper
      open={props.open}
      paperClassName={props.classes.paper}
      title={props.getLocalisationMessage("job_filter", "Job Filter")}
      onRequestClose={props.onRequestClose}
      rightIcon={
        <div ref={x => props.setState(fp.set("toggleContainer", x))} />
      }
    >
      <FlexBox container={8} direction="column">
        <JobFilterForm
          showCompany={true}
          showMerchant={true}
          showAttemptCounts={true}
          showCashier={props.showCashier}
          showPromiseDate={true}
          filter={props.filter}
          onDismiss={props.onRequestClose}
          onFilterChange={props.onFilterChange}
          toggleContainer={props.state.toggleContainer}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedOwnerUser={getCachedOwnerUser}
          getOwnerUserPredictions={getOwnerUserPredictions}
          getCachedSalesUser={getCachedSalesUser}
          getSalesUserPredictions={getSalesUserPredictions}
          getCachedTimeSlot={getCachedTimeSlot}
          getTimeSlotPredictions={getTimeSlotPredictions}
          getCachedServiceType={getCachedServiceType}
          getServiceTypePredictions={getServiceTypePredictions}
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminJobFilterWrapper);
