import React from "react";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createMenuItem } from "../../api/admin/AdminShopMenuApi";
import {
  getCachedVenueCategory,
  getVenueCategoryPredictions,
} from "../../api/admin/AdminVenueCategoryApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import ShopMenuForm from "../../components/shop-core/ShopMenuForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    content: {
      maxWidth: "1000px",
      minWidth: "1000px",
      backgroundColor: "#fff",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withContext(
    {
      getCachedVenueCategory: PropTypes.func,
      getVenueCategoryPredictions: PropTypes.func,
    },
    () => ({
      getCachedVenueCategory,
      getVenueCategoryPredictions,
    }),
  ),
);

AdminShopMenuCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminShopMenuCreateDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      onRequestClose={props.onRequestClose}
      title={props.getLocalisationMessage("create_menu", "Create Menu")}
      paperClassName={classes.content}
    >
      <ShopMenuForm
        onRequestClose={props.onRequestClose}
        onSubmit={values =>
          createMenuItem(toSnakeCase(values)).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully Saved",
            ),
          );
          props.onRequestClose();
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminShopMenuCreateDialogWrapper);
