import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import FormTextField from "./FormTextField";
import CustomButton, {
  CONTAINED,
  SECONDARY,
  OUTLINED,
} from "../ui-core/CustomButton";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({ form: "ReceptacleCloseDialog" }),
  withTheme,
);

ReceptacleCloseDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  title: PropTypes.node,
  children: PropTypes.node,
  form: PropTypes.string,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  theme: PropTypes.object,
  className: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function ReceptacleCloseDialog(props) {
  return (
    <Dialog
      open={props.open}
      className={props.className}
      onClose={props.onRequestClose}
      maxWidth="md"
      fullWidth={true}
      onSubmit={props.handleSubmit}
      PaperProps={{
        style: {
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        {props.title}
      </DialogTitle>
      <PageLoading isLoading={props.submitting} />
      <DialogContent style={{ color: "gray" }}>
        <form id={props.form} onSubmit={props.handleSubmit}>
          <FlexBox style={{ gap: 16 }}>
            <FlexBox flex={true}>
              <FormTextField
                name="weight"
                label={props.getLocalisationMessage(
                  "gross_weight_kg",
                  "Gross Weight (kg)",
                )}
                fullWidth={true}
                type="number"
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                disabled={true}
                name="receptacleNo"
                label={props.getLocalisationMessage("serial_no", "Serial No")}
                fullWidth={true}
              />
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <FlexBox style={{ gap: 16 }}>
          {props.onRequestClose && (
            <CustomButton
              onClick={props.onRequestClose}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {" "}
              {props.getLocalisationMessage("cancel")}
            </CustomButton>
          )}

          <CustomButton
            onClick={props.handleSubmit}
            variant={CONTAINED}
            color={SECONDARY}
          >
            {" "}
            {props.getLocalisationMessage("ok")}{" "}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ReceptacleCloseDialog);
