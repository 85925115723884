import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { RETURNING_TO_ORIGIN } from "../../constants/OrderStatusCodes";
import BatchRTOOrdersDialog from "../../components/orders-core/BatchRTOOrdersDialog";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { INACTIVE } from "../../constants/OverallStatus";
import { getMessage } from "../../reducers/LocalizationReducer";
import { renderIf } from "../../helpers/HOCUtils";
import { getSortingBatchUpdateItem } from "../../api/v2/admin/AdminOrderSortingApi";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

OfflineBatchRTOOrdersFormDialogWrapper.propTypes = {
  open: PropTypes.bool,
  isHybrid: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  filter: PropTypes.object,
  isRedirected: PropTypes.bool,
  selectedOrderBarcodes: PropTypes.array,
  excludeSelectedItems: PropTypes.array,
  getLocalisationMessage: PropTypes.func,
};

function OfflineBatchRTOOrdersFormDialogWrapper({
  initialValues,
  isHybrid,
  ...props
}) {
  const [barcodes, setBarcodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      if (
        props.filter.service_type_id ||
        props.filter.bean ||
        props.selectedOrderBarcodes.length
      ) {
        setIsLoading(true);
        getSortingBatchUpdateItem({
          ...props.filter,
          type: "ORDER",
          barcodes: props.selectedOrderBarcodes,
        })
          .then(res => {
            const orderBarcodes = [];
            res.items.map(item => {
              orderBarcodes.push(item.barcode);
              return item;
            });
            setBarcodes(orderBarcodes);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      }
    }
  }, [props.filter, props.selectedOrderBarcodes]);

  return (
    <BatchRTOOrdersDialog
      open={props.open}
      hideOrderNumber={true}
      isHybrid={isHybrid}
      isLoading={isLoading}
      initialValues={{
        orderBarcodes: barcodes,
        batch_type: INACTIVE,
        privacy: PUBLIC,
        order_status: RETURNING_TO_ORIGIN,
      }}
      onRequestClose={() => {
        props.onRequestClose();
      }}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.getLocalisationMessage(
            "successfully_updated",
            "Successfully Updated",
          ),
        );
        props.onSubmitSuccess(response);
      }}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
    />
  );
}

export default enhancer(OfflineBatchRTOOrdersFormDialogWrapper);
