import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEnableVehicleMake } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import useSheet from "react-jss";
import FormServiceTypeChips from "../form/FormServiceTypeChipsV2";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { Save } from "@material-ui/icons";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    enableVehicleMake: isEnableVehicleMake(state),
  })),
  useSheet({
    innerMarginBottom: {
      "&>div": {
        marginBottom: 15,
      },
    },
    includeButton: {
      padding: 8,
      marginLeft: 10,
    },
  }),
  withContext(
    {
      getCachedServiceTypeById: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    props => ({
      getCachedServiceTypeById: props.getCachedServiceTypeById,
      getServiceTypePredictions: props.getServiceTypePredictions,
    }),
  ),
  reduxForm({
    form: "CategoryServiceTypeForm",
    enableReinitialize: true,
  }),
  formValues({
    ids: "ids",
  }),
);

CategoryServiceTypeForm.propTypes = {
  classes: PropTypes.object,
  ids: PropTypes.array,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function CategoryServiceTypeForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <form
      autoComplete="off"
      name="CategoryServiceTypeForm"
      onSubmit={props.handleSubmit}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="row" style={{ gap: 16 }}>
            <FlexBox flex={true} direction="column">
              <FormServiceTypeChips
                name="ids"
                fullWidth={true}
                hintText={getLocalisationMessage("type_here_to_search")}
                label={`${getLocalisationMessage("service_type")}`}
              />
            </FlexBox>

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              type="submit"
              endIcon={<Save />}
              primary={true}
            >
              {getLocalisationMessage("add")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(CategoryServiceTypeForm);
