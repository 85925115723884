import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getValue } from "../../helpers/DataUtils";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { getPDF, getPDF2 } from "../../helpers/FormUtils";
import { IS_DOWNLOADING, IS_PRINTING } from "../orders-core/AdminPostForm";
import { GetApp, Print } from "@material-ui/icons";
import {
  DRIVER_MANIFEST_URL,
  FORM10_URL,
  FORM16_URL,
  FORM24_URL,
  getBatchOrderUpdateItem2,
  MANIFEST_URL,
  RETURNED_MANIFEST_URL,
} from "../../api/admin/AdminBatchApi";
import {
  cleanupStringArray,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";
import { CONSOLIDATED, CONTAINER, SIMPLE } from "../../constants/OrderType";
import {
  DISPATCHED,
  RETURNED_TO_ORIGIN,
} from "../../constants/OrderStatusCodes";
import ScannerTextField from "../deprecated/ScannerTextField";
import fp from "lodash/fp";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";

const parseOrderNumbers = fp.flow(
  fp.trim,
  x => x.replace(/\W/g, " "),
  // fp.split(" "),
  cleanupStringArray,
);

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
      },
    },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
);

SearchBatchItemDialog.propTypes = {
  classes: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
};

function SearchBatchItemDialog(props) {
  const { classes, getLocalisationMessage, title } = props;

  const [id, setId] = useState(null);

  const [batch, setBatch] = useState({});
  const [loading, setLoading] = useState(false);

  const [stateManifest, setStateManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: MANIFEST_URL,
  });
  const [stateManifestNew, setStateManifestNew] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: MANIFEST_URL,
  });
  const [form10, setForm10] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM10_URL,
  });
  const [form16, setForm16] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM16_URL,
  });
  const [form24, setForm24] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM24_URL,
  });
  const [driverManifest, setDriverManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DRIVER_MANIFEST_URL,
  });
  const [returnedManifest, setReturnedManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RETURNED_MANIFEST_URL,
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      getBatchOrderUpdateItem2(id)
        .then(r => {
          if (r && r.data) {
            setBatch(r.data);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          setBatch({});
        });
    }
  }, [id]);

  const buttons = (
    <FlexBox>
      {getValue(batch, "type") === CONSOLIDATED ? (
        <FlexBox>
          <ButtonGroup
            style={{ marginRight: ".5rem" }}
            variant={CONTAINED}
            color={SECONDARY}
          >
            <CustomButton
              onClick={() =>
                getPDF2(
                  stateManifest,
                  setStateManifest,
                  IS_PRINTING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                  false,
                )
              }
              endIcon={
                stateManifest[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("consolidated")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF2(
                  stateManifest,
                  setStateManifest,
                  IS_DOWNLOADING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                  false,
                )
              }
            >
              {stateManifest[IS_DOWNLOADING] ? (
                <CircularProgress size={20} />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
          <ButtonGroup variant={CONTAINED} color={SECONDARY}>
            <CustomButton
              style={{ minWidth: "200px" }}
              data-cy="receipt_print"
              onClick={() =>
                getPDF(
                  form10,
                  setForm10,
                  IS_PRINTING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
              endIcon={
                form10[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("svodnik_yarlik")}
            </CustomButton>
            <CustomButton
              data-cy="receipt_download"
              onClick={() =>
                getPDF(
                  form10,
                  setForm10,
                  IS_DOWNLOADING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
            >
              {form10[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
        </FlexBox>
      ) : getValue(batch, "type") === SIMPLE ? (
        <FlexBox>
          {getValue(batch, "status") === RETURNED_TO_ORIGIN ? (
            <ButtonGroup
              style={{ marginRight: "1rem" }}
              variant={CONTAINED}
              color={SECONDARY}
            >
              <CustomButton
                onClick={() =>
                  getPDF(
                    returnedManifest,
                    setReturnedManifest,
                    IS_PRINTING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  stateManifest[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("return")}
              </CustomButton>
              <CustomButton
                onClick={() =>
                  getPDF(
                    returnedManifest,
                    setReturnedManifest,
                    IS_PRINTING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
              >
                {stateManifest[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup
              style={{ marginRight: "1rem" }}
              variant={CONTAINED}
              color={SECONDARY}
            >
              <CustomButton
                onClick={() =>
                  getPDF(
                    stateManifest,
                    setStateManifest,
                    IS_PRINTING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  stateManifest[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("simple")}
              </CustomButton>
              <CustomButton
                onClick={() =>
                  getPDF(
                    stateManifest,
                    setStateManifest,
                    IS_DOWNLOADING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
              >
                {stateManifest[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          )}
          {getValue(batch, "bag", false) && (
            <ButtonGroup variant={CONTAINED} color={SECONDARY}>
              <CustomButton
                style={{ minWidth: "200px" }}
                data-cy="receipt_print"
                onClick={() =>
                  getPDF(
                    form10,
                    setForm10,
                    IS_PRINTING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  form10[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("svodnik_yarlik")}
              </CustomButton>
              <CustomButton
                data-cy="receipt_download"
                onClick={() =>
                  getPDF(
                    form10,
                    setForm10,
                    IS_DOWNLOADING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
              >
                {form10[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          )}
          {getValue(batch, "status") === DISPATCHED && (
            <ButtonGroup variant={CONTAINED} color={SECONDARY}>
              <CustomButton
                style={{ minWidth: "200px" }}
                data-cy="receipt_print"
                onClick={() =>
                  getPDF(
                    driverManifest,
                    setDriverManifest,
                    IS_PRINTING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  driverManifest[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("driver_manifest")}
              </CustomButton>
              <CustomButton
                data-cy="receipt_download"
                onClick={() =>
                  getPDF(
                    driverManifest,
                    setDriverManifest,
                    IS_DOWNLOADING,
                    getValue(batch, "id"),
                    props.showErrorMessage,
                  )
                }
              >
                {driverManifest[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          )}
        </FlexBox>
      ) : getValue(batch, "type") === CONTAINER ? (
        <FlexBox>
          <ButtonGroup
            style={{ marginRight: ".5rem" }}
            variant={CONTAINED}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF2(
                  stateManifestNew,
                  setStateManifestNew,
                  IS_PRINTING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                  true,
                )
              }
              endIcon={
                stateManifestNew[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage(
                "batch_of_registers",
                "batch of registers",
              )}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF2(
                  stateManifestNew,
                  setStateManifestNew,
                  IS_DOWNLOADING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                  true,
                )
              }
            >
              {stateManifestNew[IS_DOWNLOADING] ? (
                <CircularProgress size={20} />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
          <ButtonGroup
            style={{ marginRight: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
          >
            <CustomButton
              onClick={() =>
                getPDF(
                  stateManifest,
                  setStateManifest,
                  IS_PRINTING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
              endIcon={
                stateManifest[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("container")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  stateManifest,
                  setStateManifest,
                  IS_DOWNLOADING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
            >
              {stateManifest[IS_DOWNLOADING] ? (
                <CircularProgress size={20} />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
        </FlexBox>
      ) : (
        <FlexBox>
          <ButtonGroup
            variant={CONTAINED}
            style={{ marginRight: "1rem" }}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF(
                  form16,
                  setForm16,
                  IS_PRINTING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
              endIcon={
                form16[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("route_nakladnoy")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  form16,
                  setForm16,
                  IS_DOWNLOADING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
            >
              {form16[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
          <ButtonGroup
            variant={CONTAINED}
            style={{ marginRight: "1rem" }}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF(
                  form24,
                  setForm24,
                  IS_PRINTING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
              endIcon={
                form24[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("forma24")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  form24,
                  setForm24,
                  IS_DOWNLOADING,
                  getValue(batch, "id"),
                  props.showErrorMessage,
                )
              }
            >
              {form24[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
        </FlexBox>
      )}
    </FlexBox>
  );

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={props.open}
      modal={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      bodyClassName={classes.body}
      contentClassName={classes.contentStyle}
      className={classes.rootStyle}
    >
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <FlexBox align="center" direction="row">
          <FlexBox flex={true} gutter={8}>
            {title}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <FlexBox style={{ gap: 20 }} direction="column">
          <FlexBox
            flex={true}
            direction="column"
            style={{ gap: 16, marginTop: 16 }}
          >
            <FlexBox flex={true}>
              <ScannerTextField
                fullWidth={true}
                variant="outlined"
                label={getLocalisationMessage("search", "Search")}
                focus={true}
                className={classes.input}
                id="SearchButton"
                onChange={value => {
                  const orderNumbers = value.split(",");
                  setId(parseOrderNumbers(orderNumbers[0]));
                }}
              />
            </FlexBox>

            {loading && (
              <LinearProgress
                style={{ width: "99.8%" }}
                mode="indeterminate"
                color="secondary"
              />
            )}

            {getValue(batch, "id", null) ? (
              <FlexBox flex={true} direction="column" style={{ gap: 16 }}>
                <FlexBox gutter={8} justify="space-between">
                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage("status", "Status")}
                      secondary={formatOrderStatusCodeForLocalisation(
                        getValue(batch, "status", "N/A"),
                        getLocalisationMessage,
                      )}
                    />
                  </FlexBox>

                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage(
                        "created_by",
                        "Created By",
                      )}
                      secondary={getValue(batch, "user.name", "N/A")}
                    />
                  </FlexBox>
                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage(
                        "created_date",
                        "Created Date",
                      )}
                      secondary={formatDateTimeToUrl(
                        getValue(batch, "created_date", "N/A"),
                      )}
                    />
                  </FlexBox>
                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage("type", "Type")}
                      secondary={getLocalisationMessage(
                        getValue(batch, "type", "N/A"),
                      )}
                    />
                  </FlexBox>
                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage("category", "Category")}
                      secondary={
                        getValue(batch, "category", "N/A")
                          ? getLocalisationMessage(
                              getValue(batch, "category", "N/A").toLowerCase(),
                            )
                          : "-"
                      }
                    />
                  </FlexBox>
                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage(
                        "from_warehouse",
                        "Warehouse",
                      )}
                      secondary={
                        getValue(batch, "from_warehouse.name", "N/A") || "-"
                      }
                    />
                  </FlexBox>

                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage("next_warehouse")}
                      secondary={
                        getValue(batch, "next_warehouse.name", "N/A") || "-"
                      }
                    />
                  </FlexBox>
                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary={getLocalisationMessage("to_warehouse")}
                      secondary={
                        getValue(batch, "to_warehouse.name", "N/A") || "-"
                      }
                    />
                  </FlexBox>

                  <FlexBox direction="column" flex={true}>
                    <ListItemText
                      disabled={true}
                      primary="Общий вес:"
                      secondary={`${getValue(batch, "weight", "0") || 0}  кг`}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} justify="flex-end">
                  {buttons}
                </FlexBox>
              </FlexBox>
            ) : (
              id &&
              !loading && (
                <FlexBox justify="center">
                  <Typography color="error">
                    {getLocalisationMessage("data_not_found", "Data not found")}
                  </Typography>
                </FlexBox>
              )
            )}
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <FlexBox style={{ padding: 16 }}>
          <CustomButton
            variant={OUTLINED}
            color={SECONDARY}
            onClick={() => {
              props.onRequestClose();
              setBatch({});
              setId(null);
            }}
          >
            {getLocalisationMessage("close", "Close")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(SearchBatchItemDialog);
