import { Observable } from "rxjs";
import React from "react";
import { getYear } from "date-fns";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, ListSubheader } from "@material-ui/core";
import { connect } from "react-redux";
import {
  mapListResponseStream,
  mapObjectResponseStream,
} from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toHolidaySettingsFilter } from "../../helpers/HolidaySettingsFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { HOLIDAY, WEEK_END } from "../../constants/HolidayTypes";
import {
  getHolidaySettings,
  getWeekendSettings,
  saveHolidaySettings,
  saveWeekendSettings,
  deleteHolidaySettingsItem,
} from "../../api/admin/AdminSettingsApi";
import AdminHolidaySettingsEditDialogWrapper from "../../wrappers/admin/AdminHolidaySettingsEditDialogWrapper";
import AdminHolidaySettingsCreateDialogWrapper from "../../wrappers/admin/AdminHolidaySettingsCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import NavTabs from "../../components/ui-core/NavTabs";
import PageLoading from "../../components/ui-core/PageLoading";
import HolidaySettingsList from "../../components/settings-core/HolidaySettingsList";
import WeekendSettingsForm from "../../components/settings-core/WeekendSettingsForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  useSheet({
    container: { width: "1000px" },
    card: { "& > div": { flex: "1 1 0%", display: "flex" } },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
  withState("state", "setState", {
    isHolidayUpdating: false,
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toHolidaySettingsFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const weekendSettingsListStream = propsStream
          .distinctUntilKeyChanged("filter")
          .filter(props => props.filter.getValue("dayOffType") === WEEK_END)
          .switchMap(() =>
            getWeekendSettings()
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .startWith({})
          .let(mapObjectResponseStream)
          .distinctUntilChanged(isEqualData);

        const holidaySettingsListResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .filter(props => props.filter.getValue("dayOffType") === HOLIDAY)
          .switchMap(props =>
            getHolidaySettings(props.filter)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .startWith({})
          .let(mapListResponseStream)
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            holidaySettingsListResponseStream,
            weekendSettingsListStream,
            (props, holidaySettingsListResponse, weekendSettings) => ({
              ...props,

              isLoading: holidaySettingsListResponse.get("pending"),
              list: holidaySettingsListResponse.getIn(["payload", "list"]),
              total: holidaySettingsListResponse.getIn(["payload", "total"]),
              weekendData: weekendSettings.get("payload"),
              isLoadingWeekend: weekendSettings.get("pending"),
              onRequestRefresh,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminHolidaySettingsContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.object,
  weekendData: PropTypes.instanceOf(Map),
  isLoadingWeekend: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminHolidaySettingsContainer(props) {
  const { classes, location, state, getLocalisationMessage } = props;
  const currentTab = updateQuery(location, fp.unset("page"));

  return (
    <AdminAppLayout
      title={getLocalisationMessage("holiday_settings", "Holiday Settings")}
    >
      <Redirect
        to={updateQuery(location, fp.set("dayOffType", HOLIDAY))}
        when={fp.isEmpty(location.query.dayOffType)}
      />

      <AdminHolidaySettingsCreateDialogWrapper
        open={Boolean(location.query.create === "true")}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminHolidaySettingsEditDialogWrapper
        open={Boolean(location.query.edit)}
        initialValues={props.list.get(fp.toFinite(location.query.edit))}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("edit"));
        }}
      />

      <PageLoading
        isLoading={state.isHolidayUpdating || props.isLoadingWeekend}
      />

      <FlexBox container={8} justify="center" flex={true}>
        <FlexBox className={classes.container} direction="column">
          <FlexBox>
            <NavTabs
              width={150}
              value={currentTab}
              tabs={[
                {
                  label: getLocalisationMessage("holiday", "Holiday"),
                  value: updateQuery(currentTab, fp.set("dayOffType", HOLIDAY)),
                },
                {
                  label: getLocalisationMessage("weekend", "Weekend"),
                  value: updateQuery(
                    currentTab,
                    fp.set("dayOffType", WEEK_END),
                  ),
                },
              ]}
            />
          </FlexBox>

          <FlexBox flex={true} element={<Card className={classes.card} />}>
            <FlexBox
              gutter={8}
              flex={true}
              element={<CardContent />}
              direction="column"
            >
              <FlexBox>
                <ListSubheader>
                  {`${
                    location.query.dayOffType === WEEK_END
                      ? getLocalisationMessage("weekend", "Weekend")
                      : getLocalisationMessage("holiday", "Holiday")
                  } ${getLocalisationMessage(
                    "settings",
                    "Settings",
                  )} (${getLocalisationMessage(
                    "current_year",
                    "Current Year",
                  )}: ${getYear(new Date())})`}
                </ListSubheader>
              </FlexBox>

              <FlexBox flex={true}>
                {Boolean(location.query.dayOffType === WEEK_END) && (
                  <WeekendSettingsForm
                    initialValues={props.weekendData.toJS()}
                    onSubmit={values =>
                      saveWeekendSettings(values).catch(ResponseError.throw)
                    }
                    onSubmitFail={props.showErrorMessage}
                    onSubmitSuccess={() => {
                      props.showSuccessMessage(
                        getLocalisationMessage(
                          "successfully_saved",
                          "Successfully Saved",
                        ),
                      );
                      props.onRequestRefresh();
                    }}
                  />
                )}

                {location.query.dayOffType === HOLIDAY && (
                  <HolidaySettingsList
                    total={props.total}
                    isLoading={props.isLoading}
                    list={props.list}
                    onEditHolidayClick={id =>
                      updateQuery(location, fp.set("edit", id))
                    }
                    onCreateHolidayClick={() =>
                      props.setLocationQuery(fp.set("create", true))
                    }
                    onDeleteHolidayClick={id => {
                      props.setState({
                        ...state,
                        isHolidayUpdating: true,
                      });

                      deleteHolidaySettingsItem(id)
                        .then(() => {
                          props.setState({
                            ...state,
                            isHolidayUpdating: false,
                          });
                          props.showSuccessMessage(
                            getLocalisationMessage(
                              "holiday_successfully_removed",
                              "Holiday Successfully Removed",
                            ),
                          );
                          props.onRequestRefresh();
                        })
                        .catch(error => {
                          props.setState({
                            ...state,
                            isHolidayUpdating: false,
                          });
                          props.showErrorMessage(error);
                        });
                    }}
                    onSwitchRepeated={values => {
                      props.setState({
                        ...state,
                        isHolidayUpdating: true,
                      });

                      saveHolidaySettings(values)
                        .then(() => {
                          props.setState({
                            ...state,
                            isHolidayUpdating: false,
                          });
                          props.showSuccessMessage(
                            getLocalisationMessage(
                              "recurring_successfully_changed",
                              "Recurring Successfully Changed!",
                            ),
                          );
                          props.onRequestRefresh();
                        })
                        .catch(error => {
                          props.setState({
                            ...state,
                            isHolidayUpdating: false,
                          });
                          props.showErrorMessage(error);
                        });
                    }}
                    filter={props.filter}
                  />
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminHolidaySettingsContainer);
