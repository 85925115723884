import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";
import DataListFilter from "../../helpers/DataListFilter";

const RECEPTACLE_MAIN_ITEM_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/item/add`;
const CLOSE_DISPATCH_URL = `${API_V2_ROOT_URL}/ips/dispatch/close/%s`;
const CLOSE_RECEPTACLE_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/close`;
const CREATE_DISPATCH_URL = `${API_V2_ROOT_URL}/ips/dispatch/create`;
const VALIDATE_DISPATCH_URL = `${API_V2_ROOT_URL}/ips/dispatch/validate`;
const CREATE_RECEPTACLE_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/create`;
const DISPATCH_MAIL_CATEGORIES_URL = `${API_V2_ROOT_URL}/ips/dispatch/mailCategories`;
const DISPATCH_MAIL_SUB_CLASS_URL = `${API_V2_ROOT_URL}/ips/dispatch/mailSubClasses`;
const RECEPTACLE_SUB_TYPE_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacleSubType`;
const RECEPTACLE_OWNERSHIP_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacleOwnership`;
const DISPATCH_OFFICES_URL = `${API_V2_ROOT_URL}/ips/dispatch/offices`;
const REMOVE_ITEM_RECEPTACLE = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/item/remove`;
const DISPATCH_ITEM_URL = `${API_V2_ROOT_URL}/ips/dispatch/%s`;
const RECEPTACLE_ITEM_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/%s`;
const RECEPTACLE_REOPEN_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/reopen/%s`;
const DISPATCH_LIST_URL = `${API_V2_ROOT_URL}/ips/dispatch/list`;
const ORDER_VALUABLE_URL = `${API_ROOT_URL}/admin/order/%s/valuable`;
export const DISPATCH_BILL_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/pdf/bill`;
export const DISPATCH_CN65_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/pdf/cn65`;
export const DISPATCH_CNDM_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/pdf/cndm`;
export const DISPATCH_CNDMS_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/pdf/cndms`;
export const DISPATCH_CNDS_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/pdf/cnds`;
export const DISPATCH_TRIALNOTE_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/pdf/trialnote`;
export const DISPATCH_RECEPTACLE_LABEL_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/pdf/label`;
export const DISPATCH_RECEPTACLE_SPECIAL_LIST_PDF_URL = `${API_V2_ROOT_URL}/ips/dispatch/receptacle/pdf/specialList`;

export const itemAddToReceptacle = body =>
  api.post(RECEPTACLE_MAIN_ITEM_URL, { body });

export const closeDispatch = dispatchId =>
  api.post(sprintf(CLOSE_DISPATCH_URL, dispatchId));

export const closeReceptacle = body => api.post(CLOSE_RECEPTACLE_URL, { body });

export const createDispatch = body => api.post(CREATE_DISPATCH_URL, { body });

export const validateDispatch = body =>
  api.post(VALIDATE_DISPATCH_URL, { body });

export const createReceptacle = body =>
  api.post(CREATE_RECEPTACLE_URL, { body });

export const getMailCategories = () => api.get(DISPATCH_MAIL_CATEGORIES_URL);

export const getReceptacleSubType = () => api.get(RECEPTACLE_SUB_TYPE_URL);

export const getReceptacleOwnerShip = () => api.get(RECEPTACLE_OWNERSHIP_URL);

export const getMailSubClass = () => api.get(DISPATCH_MAIL_SUB_CLASS_URL);

export const getDispatchOffices = (filter: DataListFilter) =>
  api.getStream(DISPATCH_OFFICES_URL, { params: filter.getDefinedValues() });

export const itemRemoveFromReceptacle = body =>
  api.post(REMOVE_ITEM_RECEPTACLE, { body });

export const getDispatchItem = dispatchId =>
  api.get(sprintf(DISPATCH_ITEM_URL, dispatchId));

export const getReceptacleItem = receptacleId =>
  api.get(sprintf(RECEPTACLE_ITEM_URL, receptacleId));

export const reOpenReceptacle = receptacleId =>
  api.post(sprintf(RECEPTACLE_REOPEN_URL, receptacleId));

export const deleteReceptacleItem = receptacleId =>
  api.delete(sprintf(RECEPTACLE_ITEM_URL, receptacleId));

export const getOrderValuable = barcode =>
  api.get(sprintf(ORDER_VALUABLE_URL, barcode));

export const getDispatchList = filter =>
  api.get(DISPATCH_LIST_URL, { params: filter });
