import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { IconButton, makeStyles, Tab, Tabs } from "@material-ui/core";
import TabBadge from "../../components/deprecated/TabBadge";
import { LINK, RENDER } from "../../components/orders-core/MUITable";
import { DATE } from "../../components/orders-core/MUITableSimple";
import _ from "lodash";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { Add, Refresh, Search } from "@material-ui/icons";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import NewDispatchDialog from "../../components/order-create-wizard-new/ips/NewDispatchDialog";
import { getDispatchList } from "../../api/admin/AdminIPSApi";
import { getValue } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { DISPATCH_ITEM_URL } from "../../constants/AdminPathConstants";
import IPSMUITable from "../../components/orders-core/IPSMUITable";
import { formatMailCategory } from "../../components/order-create-wizard-new/ips/NewDispatchForm";

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

function AdminIPSLettersList(props) {
  const { getLocalisationMessage } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const tabs = [
    {
      title: getLocalisationMessage("all"),
      value: "all",
    },
    {
      title: getLocalisationMessage("open"),
      value: "DP_CREATE",
    },
    {
      title: getLocalisationMessage("closed"),
      value: "DP_CLOSE",
    },
    {
      title: getLocalisationMessage("reopen", "Reopen"),
      value: "DP_REOPEN",
    },
  ];

  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [addDispatch, setAddDispatch] = useState(false);

  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
    status: "all",
  });

  const onTabChange = v => {
    setFilter(prev => ({
      ...prev,
      status: v,
    }));
  };

  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  useEffect(() => {
    setIsLoading(true);

    const deleteFilter = {
      ...filter,
    };

    delete deleteFilter.status;

    getDispatchList(filter.status !== "all" ? filter : deleteFilter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  return (
    <AdminAppLayout title={getLocalisationMessage("letters", "Letters")}>
      <Tabs
        variant="fullWidth"
        className={classes.tabs}
        onChange={(e, v) => onTabChange(v)}
        value={filter.status}
      >
        {tabs.map((type, index) => (
          <Tab
            key={index}
            value={type.value}
            label={<TabBadge label={getLocalisationMessage(type.title)} />}
            style={{
              lineHeight: "24px",
            }}
          />
        ))}
      </Tabs>

      <NewDispatchDialog
        open={addDispatch}
        onRequestClose={() => setAddDispatch(false)}
        refreshOrderList={refreshOrderList}
      />

      <IPSMUITable
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        filter={filter}
        isLoading={isLoading}
        list={list}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_SPACE_AROUND}
            style={{ padding: ".25rem 1rem", gap: 13 }}
          >
            <React.Fragment>
              <div className={classes.search}>
                <Search
                  fontSize="large"
                  style={{
                    color: "rgba(0, 0, 0, 0.23)",
                    top: ".5rem",
                    left: ".5rem",
                    position: "absolute",
                  }}
                />
                <ChipTextField
                  style={{
                    padding: ".5rem 1rem 0 3rem",
                  }}
                  fullWidth={true}
                  addOnBlur={false}
                  clearOnBlur={false}
                  value={parseString(filter.search)}
                  disableUnderline={true}
                  placeholder={getLocalisationMessage("type_here_to_search")}
                  onChange={v =>
                    setFilter({ ...filter, search: stringifyArray(v) })
                  }
                />
              </div>
            </React.Fragment>
            <FlexBox style={{ minWidth: 350 }} align={ALIGN_CENTER}>
              <CustomButton
                onClick={() => setAddDispatch(true)}
                startIcon={<Add />}
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px", marginRight: 10 }}
              >
                {getLocalisationMessage(
                  "create_new_dispatch",
                  "Create New Dispatch",
                )}
              </CustomButton>

              <IconButton
                className={isLoading && classes.refresh}
                tooltip={getLocalisationMessage("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
            </FlexBox>
          </FlexBox>
        }
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        columns={[
          {
            type: LINK,
            name: "dispatch_id_string",
            label: getLocalisationMessage("identifier", "Identifier"),
            keyName: "dispatch_id_string",
            link: DISPATCH_ITEM_URL,
            align: "center",
          },
          {
            type: RENDER,
            name: "destination_office",
            label: getLocalisationMessage(
              "destination_office",
              "Destination Office",
            ),
            render: row => _.get(row, `dispatch_id.destination_office`, ""),
          },
          {
            type: RENDER,
            name: "mail_category",
            label: getLocalisationMessage("mail_category", "Mail category"),
            render: row =>
              formatMailCategory(
                _.get(row, `dispatch_id.mail_category`, ""),
                getLocalisationMessage,
              ),
          },
          {
            type: RENDER,
            name: "mail_subclass",
            label: getLocalisationMessage("mail_sub_class", "Mail Sub class"),
            render: row => _.get(row, `dispatch_id.mail_sub_class`, ""),
          },
          {
            type: RENDER,
            name: "status",
            label: getLocalisationMessage("status", "Status"),
            render: row =>
              getLocalisationMessage(
                _.get(row, `status`, ""),
                formatText(_.get(row, `status`, "")),
              ),
          },
          {
            type: RENDER,
            name: "receptacle_count",
            label: getLocalisationMessage(
              "receptacle_count",
              "Receptacle count",
            ),
            render: row => _.get(row, `receptacles`, []).length,
          },
          {
            type: DATE,
            name: "earliest_departure",
            keyName: "earliest_departure",
            label: getLocalisationMessage(
              "earliest_departure",
              "Earliest Departure",
            ),
          },
          {
            type: DATE,
            name: "closure_date_time",
            keyName: "closure_date_time",
            label: getLocalisationMessage(
              "closure_date_time",
              "Closure Date Time",
            ),
          },
          {
            type: RENDER,
            name: "weight",
            label: getLocalisationMessage("weight", "weight"),
            render: row =>
              _.get(row, `weight`) || getLocalisationMessage("na", "N/A"),
          },
        ]}
      />
    </AdminAppLayout>
  );
}

AdminIPSLettersList.propTypes = {
  getLocalisationMessage: PropTypes.func,
};

const mapStateToProps = state => ({
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminIPSLettersList);
