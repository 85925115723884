import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { connect } from "react-redux";
import { Cancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { isValidDate } from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { Card, CardActions, IconButton } from "@material-ui/core";
import CustomButton from "../ui-core/CustomButton";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import { toSnakeCase } from "../../helpers/CaseMapper";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const onSubmit = (values, dispatch, props) => {
      const request = toSnakeCase(values);

      props.onFilterChange(request);
    };

    return propsStream
      .combineLatest(props => ({
        ...props,
        onSubmit,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "LocalActManagementFilterForm",
    enableReinitialize: true,
  }),
  formValues({
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
  }),
);

LocalActManagementFilterForm.propTypes = {
  change: PropTypes.func,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onDismiss: PropTypes.func,
  values: PropTypes.object,
  onFilterChange: PropTypes.func,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,

  getLocalisationMessage: PropTypes.func,
};

function LocalActManagementFilterForm(props) {
  const {
    getLocalisationMessage,
    handleSubmit,
    dirty,
    onDismiss,
    reset,
  } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card>
          <FlexBox container={24} direction="column">
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage(
                        "from_date",
                        "From Date",
                      )}
                    />
                  </FlexBox>

                  {Boolean(isValidDate(props.fromDateTime)) && (
                    <FlexBox>
                      <IconButton
                        onClick={() => props.change("fromDateTime", null)}
                        style={{ padding: 4 }}
                      >
                        <Cancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="toDateTime"
                    hintText={getLocalisationMessage("to_date", "To Date")}
                  />
                </FlexBox>

                {Boolean(isValidDate(props.toDateTime)) && (
                  <FlexBox>
                    <IconButton
                      style={{ padding: 4 }}
                      onClick={() => props.change("toDateTime", null)}
                    >
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormWarehouseAutoComplete
                      hintText={getLocalisationMessage(
                        "from_warehouse_1",
                        "From Warehouse",
                      )}
                      label={getLocalisationMessage(
                        "from_warehouse_1",
                        "From Warehouse",
                      )}
                      name="fromWarehouseId"
                      fullWidth={true}
                      margin="normal"
                    />
                  </FlexBox>

                  <FlexBox flex={true} direction="column">
                    <FormWarehouseAutoComplete
                      hintText={getLocalisationMessage(
                        "to_warehouse_1",
                        "To Warehouse",
                      )}
                      label={getLocalisationMessage(
                        "to_warehouse_1",
                        "To Warehouse",
                      )}
                      name="toWarehouseId"
                      fullWidth={true}
                      margin="normal"
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <CardActions>
            <FlexBox flex={true} justify="flex-end">
              {dirty ? (
                <CustomButton
                  label={getLocalisationMessage("reset", "Reset")}
                  onClick={reset}
                />
              ) : (
                Boolean(onDismiss) && (
                  <CustomButton
                    label={getLocalisationMessage("dismiss", "Dismiss")}
                    onClick={onDismiss}
                  />
                )
              )}
              <CustomButton
                label={getLocalisationMessage("clear", "Clear")}
                onClick={() => {
                  fp.keys(props.initialValues).forEach(key => {
                    props.change(key, null);
                  });
                }}
              />
              <CustomButton
                label={getLocalisationMessage("submit", "Submit")}
                type="submit"
              />
            </FlexBox>
          </CardActions>
        </Card>
      </form>
    </div>
  );
}

export default enhancer(LocalActManagementFilterForm);
