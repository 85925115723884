import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormFileField from "../form/FormFileField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue } from "../../helpers/DataUtils";
import { validateString, isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const FORM = "MarketplaceLogoForm";
const valueSelector = formValueSelector(FORM);

const enhancer = compose(
  useSheet({
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
    padding: { paddingTop: 10 },
    card: { width: "100%" },
    map: { flex: "1 1 0%", zIndex: 0 },
    imageContainer: {
      paddingLeft: "15px",
      " & img": {
        width: "100%",
        maxWidth: "300px",
      },
    },
  }),
  connect(state => ({
    countryCode: getValue(valueSelector(state, "country"), "description"),
    i18n: getMessages(state),
  })),
  reduxForm({
    form: FORM,
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.i18n.get("enter_name", "Enter name")
        : null,
      country:
        !isValidObjectId(values.country) &&
        props.i18n.get("please_select_country", "Please select country"),
      zone:
        !getValue(values.zone, "name") &&
        props.i18n.get("please_select_zone", "Please select zone"),
    }),
  }),
);

MarketplaceLogoForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  photoUrl: PropTypes.string,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  marketplace: PropTypes.instanceOf(Map),
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function MarketplaceLogoForm(props) {
  const { classes, i18n } = props;

  const logoURL = props.marketplace.get("logo_url", null);
  const loginLogoURL = props.marketplace.get("login_logo_url", null);
  const faviconURL = props.marketplace.get("favicon_url", null);

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <Card className={classes.card}>
        <CardContent>
          <FlexBox container={8} flex={true}>
            <FlexBox flex={true} direction="column" container={8}>
              <FlexBox flex={true} direction="column" justify="space-between">
                <ListSubheader>{i18n.get("logo", "Logo")}</ListSubheader>
                <div className={classes.imageContainer}>
                  {logoURL && (
                    <img alt={i18n.get("logo", "Logo")} src={logoURL} />
                  )}
                </div>

                <FormFileField
                  name="logo"
                  fullWidth={true}
                  accept="image/*"
                  label={i18n.get("select_logo", "Select Logo")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} container={8}>
              <FlexBox flex={true} direction="column" justify="space-between">
                <ListSubheader>
                  {i18n.get("logo_for_login", "Logo for Login")}
                </ListSubheader>
                <div className={classes.imageContainer}>
                  {loginLogoURL && (
                    <img
                      alt={i18n.get("logo_for_login", "Logo for Login")}
                      src={loginLogoURL}
                    />
                  )}
                </div>

                <FormFileField
                  name="login_logo"
                  fullWidth={true}
                  accept="image/*"
                  label={i18n.get("select_logo", "Select Logo")}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} container={8}>
              <FlexBox flex={true} direction="column" justify="space-between">
                <ListSubheader>{i18n.get("favicon", "Favicon")}</ListSubheader>
                <div className={classes.imageContainer}>
                  {faviconURL && <img alt="Login Logo" src={faviconURL} />}
                </div>

                <FormFileField
                  name="favicon"
                  fullWidth={true}
                  accept="image/*"
                  label={i18n.get("select_favicon", "Select Favicon")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox justify="flex-end">
            {props.dirty ? (
              <Button onClick={props.reset}>
                {i18n.get("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {i18n.get("dismiss", "Dismiss")}
                </Button>
              )
            )}

            <Button onClick={props.handleSubmit}>
              {i18n.get("submit", "Submit")}
            </Button>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(MarketplaceLogoForm);
