import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormCityV2AutoComplete from "../form/FormCityV2AutoComplete";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("PackagePricelistFormNew");

const enhancer = compose(
  useSheet({
    root: {
      position: "relative",
    },
    collapse: {
      minWidth: 200,
    },
    filter: {
      marginTop: 5,
    },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
      getCachedCustomer: PropTypes.func.isRequired,
      getCustomerPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    propsStream
      .filter(
        props =>
          !(
            props.filter.getValue("from_country_id") &&
            props.filter.getValue("to_country_id")
          ),
      )
      .filter(fp.get("marketplace"))
      .first()
      .subscribe(props => {
        props.onFilterChange(
          props.filter.withMutations((filter: DataListFilter) => {
            filter.setValueMap({
              from_country_id: props.marketplace.getIn(["country", "id"]),
              to_country_id: props.marketplace.getIn(["country", "id"]),
            });
          }),
        );
      });

    const initialValuesStream = propsStream
      .pluck("filter")
      .distinctUntilChanged(isEqualData)
      .map((filter: DataListFilter) => ({
        supplierId: { id: filter.getIntegerValue("supplier_id") },
        customerId: { id: filter.getIntegerValue("customer_id") },
        fromCountryId: {
          id: filter.getIntegerValue("from_country_id"),
        },
        toCountryId: { id: filter.getIntegerValue("to_country_id") },
        fromCityId: { id: filter.getIntegerValue("from_city_id") },
        toCityId: { id: filter.getIntegerValue("to_city_id") },
      }));

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            supplier_id: getObjectId(values.supplierId),
            customer_id: getObjectId(values.customerId),
            from_country_id: getObjectId(values.fromCountryId),
            to_country_id: getObjectId(values.toCountryId),
            from_city_id: getObjectId(values.fromCityId),
            to_city_id: getObjectId(values.toCityId),
          });
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, onSubmit, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  connect(state => ({
    i18n: getMessages(state),
    values: valueSelector(
      state,
      "supplierId",
      "customerId",
      "fromCountryId",
      "toCountryId",
      "fromCityId",
      "toCityId",
    ),
  })),
  reduxForm({
    enableReinitialize: true,
    form: "PackagePricelistFormNew",
    validate: (values, props) => {
      const supplierId = fp.get(["supplierId", "id"], values);
      const fromCountryId = fp.get(["fromCountryId", "id"], values);
      const toCountryId = fp.get(["toCountryId", "id"], values);
      const fromCityId = fp.get(["fromCityId", "id"], values);
      const toCityId = fp.get(["toCityId", "id"], values);
      const isDomestic = fromCountryId === toCountryId;

      return {
        supplierId:
          !supplierId &&
          props.i18n.get("supplier_is_required", "Supplier is Required"),
        fromCountryId:
          !fromCountryId &&
          props.i18n.get("country_is_required", "Country is Required"),
        toCountryId:
          !toCountryId &&
          props.i18n.get("country_is_required", "Country is Required"),
        fromCityId:
          isDomestic &&
          !fromCityId &&
          props.i18n.get("city_is_required", "City is Required"),
        toCityId:
          isDomestic &&
          !toCityId &&
          props.i18n.get("city_is_required", "City is Required"),
      };
    },
  }),
  mapPropsStream(propsStream => {
    propsStream
      .distinctUntilChanged(null, fp.get("values.fromCountryId.id"))
      .skip(1)
      .subscribe(props => props.change("fromCityId", null));

    propsStream
      .distinctUntilChanged(null, fp.get("values.toCountryId.id"))
      .skip(1)
      .subscribe(props => props.change("toCityId", null));

    return propsStream;
  }),
);

PackagePricelistFormNew.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  filter: PropTypes.object,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function PackagePricelistFormNew(props) {
  const { classes, filter, i18n } = props;
  const fromCountryId =
    fp.get("values.fromCountryId.id", props) ||
    filter.getIntegerValue("from_country_id");
  const toCountryId =
    fp.get("values.toCountryId.id", props) ||
    filter.getIntegerValue("to_country_id");

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <FlexBox gutter={8} direction="column" className={classes.filter}>
        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            <FormSupplierAutoComplete
              name="supplierId"
              fullWidth={true}
              hintText={`${i18n.get("supplier", "Supplier")} *`}
              label={`${i18n.get("supplier", "Supplier")} *`}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormCustomerAutoComplete
              name="customerId"
              fullWidth={true}
              hintText={`${i18n.get("customer", "Customer")} *`}
              label={`${i18n.get("customer", "Customer")} *`}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            <FormCountryAutoComplete
              name="fromCountryId"
              fullWidth={true}
              hintText={`${i18n.get("from_country", "From Country")} *`}
              label={`${i18n.get("from_country", "From Country")} *`}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormCountryAutoComplete
              name="toCountryId"
              fullWidth={true}
              hintText={`${i18n.get("to_country", "To Country")} *`}
              label={`${i18n.get("to_country", "To Country")} *`}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            {Boolean(fromCountryId) && (
              <FormCityV2AutoComplete
                name="fromCityId"
                countryId={fromCountryId}
                fullWidth={true}
                hintText={`${i18n.get("from_city", "From City")} *`}
                label={`${i18n.get("from_city", "From City")} *`}
              />
            )}
          </FlexBox>

          <FlexBox flex={true}>
            {Boolean(toCountryId) && (
              <FormCityV2AutoComplete
                name="toCityId"
                countryId={toCountryId}
                fullWidth={true}
                hintText={`${i18n.get("to_city", "To City")} *`}
                label={`${i18n.get("to_city", "To City")} *`}
              />
            )}
          </FlexBox>
        </FlexBox>
        <FlexBox justify="flex-end">
          <Button type="submit"> {i18n.get("submit", "Submit")} </Button>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(PackagePricelistFormNew);
