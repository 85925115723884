import React from "react";
import { List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { mapListResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  ADDRESS_BOOK_ITEM_NEW_URL,
  ADDRESS_BOOK_CREATE_NEW_URL,
} from "../../constants/AdminPathConstants";
import {
  getAddressBookList,
  deleteBatchAddressBook,
} from "../../api/shared/AdminAddressBookV2Api";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../../components/data-list/DataList";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocationQueryFilter: PropTypes.func }),
  mapPropsStream(propsStream => {
    const {
      handler: onRowSelect,
      stream: onRowSelectStream,
    } = createEventHandler();
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const selectedItemsStream = onRowSelectStream
      .distinctUntilChanged(isEqualData)
      .startWith(OrderedSet());

    const filterStream = propsStream
      .map(
        fp.flow(fp.get("location.query"), fp.unset("status"), toDataListFilter),
      )
      .distinctUntilChanged(isEqualData);

    const addressBookListResponseStream = filterStream
      // .distinctUntilChanged(isEqualData)  // When create a new AddressBook getAddressBookList not worked
      .switchMap(filter =>
        getAddressBookList(filter)
          .let(mapListResponseStream)
          .repeatWhen(() => onRequestRefreshStream),
      );

    return propsStream.combineLatest(
      filterStream,
      addressBookListResponseStream,
      selectedItemsStream,
      (props, filter, addressBookList, selectedItems) => ({
        ...props,
        filter,
        selectedItems,
        onRowSelect,
        onRequestRefresh,

        isLoading: addressBookList.get("pending"),
        list: addressBookList.getIn(["payload", "list"]),
        total: addressBookList.getIn(["payload", "total"]),
      }),
    );
  }),
);

AdminAddressBookListNew.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  setLocationQueryFilter: PropTypes.func,

  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  onRequestRefresh: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),

  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedSet),
  getLocalisationMessage: PropTypes.func,
};

function AdminAddressBookListNew(props) {
  const { selectedItems, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="address_book"
      title={getLocalisationMessage("address_books", "Address Books")}
    >
      <DataList
        isLoading={props.isLoading}
        selectedRowCount={selectedItems.size}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={7}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={props.setLocationQueryFilter}
        altHeader={
          <Button
            onClick={() =>
              deleteBatchAddressBook(selectedItems.toJS().join(","))
                .then(() => {
                  props.onRowSelect(OrderedSet());
                  props.onRequestRefresh();
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_deleted_items",
                      "Successfully deleted items",
                    ),
                  );
                })
                .catch(props.showErrorMessage)
            }
          >
            {getLocalisationMessage("delete_items", "Delete Items")}
          </Button>
        }
        cardActionIcons={
          <Link to={ADDRESS_BOOK_CREATE_NEW_URL}>
            <Tooltip
              title={getLocalisationMessage(
                "create_new_address",
                "Create New Address",
              )}
            >
              <IconButton>
                <Add />
              </IconButton>
            </Tooltip>
          </Link>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            selectedItems.size > 0 && selectedItems.size === props.list.size
          }
          onAllRowsSelect={select =>
            props.onRowSelect(
              !select
                ? OrderedSet()
                : props.list.toOrderedSet().map(x => x.get("id")),
            )
          }
          rowSelected={row => selectedItems.has(row.cellData.get("id"))}
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? selectedItems.add(row.cellData.get("id"))
                : selectedItems.delete(row.cellData.get("id")),
            )
          }
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Link to={ADDRESS_BOOK_ITEM_NEW_URL + row.cellData.get("id")}>
              {row.cellData.get("id")}
            </Link>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("name") || getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("phone") || getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("country", "Country")}
          dataKey="country"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.getIn(["country", "name"]) ||
            getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("city", "City")}
          dataKey="city"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.getIn(["city", "name"]) ||
            getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("neighborhood", "Neighborhood")}
          dataKey="neighborhood"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.getIn(["neighborhood", "name"]) ||
            getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("address", "Address")}
          dataKey="address"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("address") || getLocalisationMessage("na", "N/A")
          }
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminAddressBookListNew);
