import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { FieldArray, formValueSelector } from "redux-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  Remove,
} from "@material-ui/icons";
import reduxActions from "redux-form/es/actions";
import { grey } from "@material-ui/core/colors";
import {
  getFieldFieldKey,
  isDateField,
  isNameField,
  isObjectField,
  isTimeField,
} from "./PackageRuleTree";
import PackageRuleTreeLeaf from "./PackageRuleTreeLeaf";
import PackageRuleTreeAddMultipleDialog from "./PackageRuleTreeAddMultipleDialog";
import PackageRuleConditionsUploadDialog from "./PackageRuleConditionsUploadDialog";
import FormToggle from "../form/FormToggle";
import { FormToggleButton } from "../form/FormToggleButton";
import FlexBox from "../ui-core/FlexBox";
import { safeParseDate, shortTimeToDate } from "../../helpers/DateUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { EQUAL_TO } from "../../constants/RuleComparationOperator";
import RoundButton from "../ui-core/RoundButton";

const valueSelector = formValueSelector("PackageRuleTree");

TreeBranchArray.propTypes = {
  classes: PropTypes.object,
  fields: PropTypes.object,
  actions: PropTypes.node,

  i18n: PropTypes.instanceOf(Map),
};

function TreeBranchArray(props) {
  const { classes, fields, actions, i18n } = props;

  return (
    <ul className={classes.ul}>
      <li className={classes.li}>
        <FlexBox element={<Paper />} gutter={8} className={classes.actions}>
          <FlexBox>{actions}</FlexBox>

          <FlexBox>
            <Button onClick={() => fields.push({})}>
              {i18n.get("add_rules_group", "Add Rules Group")}
            </Button>
          </FlexBox>
        </FlexBox>
      </li>

      {fields.map((condition, index) => (
        <li key={condition} className={classes.li}>
          <PackageRuleTreeBranch
            name={condition}
            cardClassName={classes.liCard}
            onRemove={() => fields.remove(index)}
            onMoveUp={index > 0 ? () => fields.swap(index, index - 1) : null}
            onMoveDown={
              index + 1 < fields.length
                ? () => fields.swap(index, index + 1)
                : null
            }
          />
        </li>
      ))}
    </ul>
  );
}

TreeLeafArray.propTypes = {
  classes: PropTypes.object,
  fields: PropTypes.object,
  actions: PropTypes.node,

  batchAdd: PropTypes.bool,
  conditionBatchAdd: PropTypes.bool,
  onBatchAdd: PropTypes.func,
  onConditionBatchAdd: PropTypes.func,
  ruleType: PropTypes.string,

  leaf: PropTypes.array,

  i18n: PropTypes.instanceOf(Map),
  needsAValidation: PropTypes.bool,
};

function TreeLeafArray(props) {
  const { classes, fields, actions, i18n } = props;

  const hidden = false;

  return (
    <ul className={classes.ul}>
      {hidden && (
        <PackageRuleConditionsUploadDialog
          open={props.conditionBatchAdd}
          ruleType={props.ruleType}
          onRequestClose={() => props.onConditionBatchAdd(false)}
          onSubmit={(values) =>
            new Promise((resolve) =>
              fp.defer(() => {
                values.fields.forEach((item) => {
                  const field = item.get("field");
                  const value = item.get(getFieldFieldKey(field));

                  fields.push({
                    field,
                    [`${item.get("field")}-value`]: isObjectField(field)
                      ? { id: value }
                      : isTimeField(field)
                      ? shortTimeToDate(value)
                      : isDateField(field)
                      ? safeParseDate(value)
                      : isNameField(field)
                      ? value
                      : _.toUpper(value),
                    comparationOperator: item.get("comparison_operator"),
                  });
                });

                resolve();
              }),
            )
          }
          onSubmitSuccess={() => props.onConditionBatchAdd(false)}
        />
      )}

      <PackageRuleTreeAddMultipleDialog
        open={props.batchAdd}
        selectedLeafs={props.leaf}
        ruleType={props.ruleType}
        onRequestClose={() => props.onBatchAdd(false)}
        onSubmit={(values) =>
          new Promise((resolve) =>
            fp.defer(() => {
              values.value.forEach((id) => {
                fields.push({
                  field: values.field,
                  [`${values.field}-value`]: { id },
                  comparationOperator: values.comparationOperator,
                });
              });

              resolve();
            }),
          )
        }
        onSubmitSuccess={() => props.onBatchAdd(false)}
      />

      <li className={classes.li}>
        <FlexBox element={<Paper />} gutter={8} className={classes.actions}>
          <FlexBox>{actions}</FlexBox>

          <FlexBox>
            <Button
              onClick={() => fields.push({ comparationOperator: EQUAL_TO })}
            >
              {i18n.get("add_condition", "Add Condition")}
            </Button>
          </FlexBox>

          {2 === 3 && (
            <FlexBox>
              <Button onClick={() => props.onBatchAdd(true)}>
                {i18n.get("add_multiple_conditions", "Add Multiple Conditions")}
              </Button>
              {hidden && (
                <Button onClick={() => props.onConditionBatchAdd(true)}>
                  {i18n.get(
                    "upload_multiple_conditions",
                    "Upload Multiple Conditions",
                  )}
                </Button>
              )}
            </FlexBox>
          )}
        </FlexBox>
      </li>

      {fields.length > 0 && (
        <li className={classes.li}>
          <Card className={classes.liCard} style={{ width: "100%" }}>
            <CardContent>
              {fields.map((condition, index) => (
                <div key={condition}>
                  <FlexBox style={{ width: "100%", marginTop: 10 }}>
                    <PackageRuleTreeLeaf
                      needsAValidation={props.needsAValidation}
                      name={condition}
                    />

                    <IconButton onClick={() => fields.remove(index)}>
                      <Remove />
                    </IconButton>
                  </FlexBox>
                </div>
              ))}
            </CardContent>
          </Card>
        </li>
      )}
    </ul>
  );
}

const enhancer = compose(
  useSheet({
    root: {
      position: "relative",
      width: "60%",
      minWidth: "60%",
      maxWidth: "60%",
    },
    card: { whiteSpace: "nowrap" },
    accordion: {
      backgroundColor: "transparent",
      boxShadow: "none",
      paddingLeft: "1rem",
      marginLeft: 0,
    },
    accordionSummary: {
      maxWidth: 180,
      position: "relative",
      backgroundColor: "white",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      borderRadius: 4,
    },
    actionButtonsDiv: {
      position: "absolute",
      right: -125,
      top: 7,
    },
    actionButtons: {
      background: "white",
      marginLeft: ".5rem",
    },
    ul: {
      width: "100%",
      margin: 0,
      paddingLeft: "8px",
      position: "relative",
      listStyleType: "none",
    },

    li: {
      important: false,

      display: "flex",
      marginLeft: "12px",
      paddingBottom: "8px",
      listStyleType: "none",
      borderLeft: `2px solid ${grey[800]}`,

      "&:last-child:not(:first-child)": { borderLeftColor: "transparent" },
    },

    actions: { margin: "12px 0 0px 12px", padding: "6px", width: "100%" },
    overAny: { transform: "rotate3d(1, 0, 0, 180deg)" },
    conditionButton: {
      zIndex: 10,
      top: "56px",
      left: "38px",
      position: "absolute",
    },

    liCard: {
      marginLeft: "12px",
      position: "relative",
      overflow: "visible",

      "&:before": {
        content: '""',
        top: 0,
        left: "-14px",
        width: "14px",
        bottom: "50%",
        borderTop: "none",
        borderRight: "none",
        position: "absolute",
        border: `2px solid ${grey[800]}`,
      },
    },

    leaf: { position: "relative" },
    toggle: {
      "& label": { color: "#000000" },
    },

    removeLeaf: {
      position: "absolute",
    },
  }),
  connect(
    (state, props) => ({
      ruleType: valueSelector(state, "ruleType"),

      i18n: getMessages(state),

      batchAdd: Boolean(valueSelector(state, `${props.name}.batchAdd`)),
      conditionBatchAdd: Boolean(
        valueSelector(state, `${props.name}.conditionBatchAdd`),
      ),
      expanded: Boolean(valueSelector(state, `${props.name}.expanded`)),

      isLeaf: Boolean(valueSelector(state, `${props.name}.isLeaf`)),
      leafCount: fp.size(valueSelector(state, `${props.name}.leaf`)),
      leaf: valueSelector(state, `${props.name}.leaf`),
      branchCount: fp.size(valueSelector(state, `${props.name}.branch`)),
    }),
    {
      change: (key, value) =>
        reduxActions.change("PackageRuleTree", key, value),
    },
  ),
);

const PackageRuleTreeBranch = enhancer((props) => {
  const { classes, i18n, needsAValidation } = props;
  const itemCount = props.isLeaf ? props.leafCount : props.branchCount;

  const actions = (
    <FlexBox gutter={16} flex={true} style={{ width: "100%" }}>
      <FlexBox>
        <FormToggleButton
          trueLabel={i18n.get("or", "OR")}
          falseLabel={i18n.get("and", "AND")}
          name={`${props.name}.overAny`}
        />
      </FlexBox>

      <FlexBox align="center">
        <FormToggle
          className={classes.toggle}
          label={i18n.get("conditions", "Conditions")}
          name={`${props.name}.isLeaf`}
        />
      </FlexBox>
    </FlexBox>
  );

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          className={classes.accordionSummary}
        >
          <Typography>
            {i18n.get("rules", "Rules")} ({itemCount})
          </Typography>
          <div className={classes.actionButtonsDiv}>
            {props.onMoveUp && (
              <RoundButton
                className={classes.actionButtons}
                onClick={props.onMoveUp}
              >
                <ArrowUpward />
              </RoundButton>
            )}

            {props.onMoveDown && (
              <RoundButton
                className={classes.actionButtons}
                onClick={props.onMoveDown}
              >
                <ArrowDownward />
              </RoundButton>
            )}

            {props.onRemove && (
              <RoundButton
                className={classes.actionButtons}
                onClick={props.onRemove}
              >
                <Remove />
              </RoundButton>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {props.isLeaf ? (
            <FieldArray
              {...props}
              actions={actions}
              props={{
                i18n: props.i18n,
              }}
              component={TreeLeafArray}
              name={`${props.name}.leaf`}
              ruleType={props.ruleType}
              batchAdd={props.batchAdd}
              conditionBatchAdd={props.conditionBatchAdd}
              onBatchAdd={(x) => props.change(`${props.name}.batchAdd`, x)}
              onConditionBatchAdd={(x) =>
                props.change(`${props.name}.conditionBatchAdd`, x)
              }
              needsAValidation={needsAValidation}
            />
          ) : (
            <FieldArray
              {...props}
              actions={actions}
              props={{
                i18n: props.i18n,
              }}
              component={TreeBranchArray}
              name={`${props.name}.branch`}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

PackageRuleTreeBranch.propTypes = {
  classes: PropTypes.object,

  change: PropTypes.func,

  batchAdd: PropTypes.bool,
  conditionBatchAdd: PropTypes.bool,
  expanded: PropTypes.bool,

  ruleType: PropTypes.string,

  leaf: PropTypes.array,
  isLeaf: PropTypes.bool,
  leafCount: PropTypes.number,
  branchCount: PropTypes.number,

  root: PropTypes.bool,
  name: PropTypes.string.isRequired,

  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onRemove: PropTypes.func,

  cardClassName: PropTypes.string,

  i18n: PropTypes.instanceOf(Map),
  needsAValidation: PropTypes.bool,
};

export default PackageRuleTreeBranch;
