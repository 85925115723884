import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadDefaultPackagePricelistV2 } from "../../actions/AdminPackageActions";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDefaultPackagePricelistFilter } from "../../helpers/DefaultPackagePricelistFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import AdminPackageNewEditDialogWrapper from "../../wrappers/admin/AdminPackageNewEditDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DefaultPackagePricelistNew from "../../components/package-pricelist-new/DefaultPackagePricelistNew";

const enhancer = compose(
  getContext({ setLocationQueryFilter: PropTypes.func.isRequired }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      loadDefaultPackagePricelistV2,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onItemClick,
      stream: onItemClickStream,
    } = createEventHandler();
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .pluck("location", "query")
      .map(toDefaultPackagePricelistFilter)
      .distinctUntilChanged(isEqualData);

    const priceListStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        props
          .loadDefaultPackagePricelistV2(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .map(value => fromJS(value).getIn(["payload", "data"]))
      .distinctUntilChanged(isEqualData)
      .startWith(null)
      .share();

    const marketplaceStream = getMarketplace()
      .takeLast(1)
      .map(fp.flow(fp.get("payload.data"), fromJS))
      .catch(() => Observable.of({}));

    const selectedItemStream = onItemClickStream
      .distinctUntilChanged(isEqualData)
      .startWith(null);

    return propsStream
      .combineLatest(
        filterStream,
        priceListStream,
        marketplaceStream,
        selectedItemStream,
        (props, filter, priceList, marketplace, selectedItem) => ({
          ...props,
          onRequestRefresh,
          filter,
          priceList,
          onItemClick,
          selectedItem,
          marketplace,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminPackagePricelistContainerNew.propTypes = {
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  priceList: PropTypes.instanceOf(List),
  onRequestRefresh: PropTypes.func,
  onItemClick: PropTypes.func,
  selectedItem: PropTypes.instanceOf(Map),
  marketplace: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function AdminPackagePricelistContainerNew(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      slug="marketplace_package"
      title={getLocalisationMessage(
        "marketplace_package",
        "Marketplace Package",
      )}
    >
      <AdminPackageNewEditDialogWrapper
        item={props.selectedItem}
        onRequestClose={() => props.onItemClick(null)}
        onSubmitSuccess={() => {
          props.onItemClick(null);
          props.onRequestRefresh();
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully Saved"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        filter={props.filter}
      />

      <DefaultPackagePricelistNew
        filter={props.filter}
        priceList={props.priceList}
        marketplace={props.marketplace}
        onItemClick={props.onItemClick}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPackagePricelistContainerNew);
