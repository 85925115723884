import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent as CardText } from "@material-ui/core";
import { connect } from "react-redux";
import DescriptionTerm from "../ui-core/DescriptionTerm";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const concatWithSpace = fp.flow(
  fp.rest(fp.identity),
  fp.map(fp.trim),
  fp.compact,
  fp.join(" "),
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    row: {
      flex: "1 1 0%",
      display: "flex",
      flexDirection: "row",

      "& > div": {
        flex: "1 1 0%",
        margin: "6px",
        display: "flex",
        fontFamily: "Blogger Sans",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const itemResponseStream = propsStream
      .distinctUntilKeyChanged("supplierId")
      .switchMap(props =>
        props
          .getSupplier(props.supplierId)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(itemResponseStream, (props, itemResponse) => ({
        ...props,
        item: itemResponse.get("payload"),
        isLoading: itemResponse.get("isLoading"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

OrderDetailsDialogCourier.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function OrderDetailsDialogCourier(props) {
  const { classes, getLocalisationMessage } = props;
  const NA = props.isLoading
    ? getLocalisationMessage("loading", "Loading")
    : getLocalisationMessage("na", "N/A");

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Card>
          <CardText>
            <DescriptionTerm
              title={getLocalisationMessage("code", "Code")}
              fallbackValue={NA}
            >
              {props.item.get("code")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("name_1", "Name")}
              fallbackValue={NA}
            >
              {props.item.get("name")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("email", "Email")}
              fallbackValue={NA}
            >
              {props.item.get("supplier_email")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("phone_number", "Phone Number")}
              fallbackValue={NA}
            >
              {props.item.get("supplier_phone")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("fax_number", "Fax Number")}
              fallbackValue={NA}
            >
              {props.item.get("fax")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("website_url", "Website URL")}
              fallbackValue={NA}
            >
              {props.item.get("website") && (
                <a
                  target="_blank"
                  href={props.item.get("website")}
                  rel="noreferrer"
                >
                  {props.item.get("website")}
                </a>
              )}
            </DescriptionTerm>
          </CardText>
        </Card>
        <Card>
          <CardText>
            <DescriptionTerm
              title={getLocalisationMessage(
                "number_of_fleet",
                "Number of Fleet",
              )}
              fallbackValue={NA}
            >
              {props.item.get("number_of_fleet")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage(
                "number_of_drivers",
                "Number of Drivers",
              )}
              fallbackValue={NA}
            >
              {props.item.get("number_of_drivers")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("contact_person", "Contact Person")}
              fallbackValue={NA}
            >
              {concatWithSpace(
                props.item.get("contact_first_name"),
                props.item.get("contact_last_name"),
              )}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("phone_number", "Phone Number")}
              fallbackValue={NA}
            >
              {props.item.get("contact_phone")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("email", "Email")}
              fallbackValue={NA}
            >
              {props.item.get("contact_email")}
            </DescriptionTerm>
          </CardText>
        </Card>

        <Card>
          <CardText>
            <DescriptionTerm
              title={getLocalisationMessage(
                "shipping_address",
                "Shipping Address",
              )}
              fallbackValue={NA}
            >
              {props.item.getIn(["shipping_address", "address"])}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("country", "Country")}
              fallbackValue={NA}
            >
              {props.item.getIn(["shipping_address", "country", "name"])}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("city", "City")}
              fallbackValue={NA}
            >
              {props.item.getIn(["shipping_address", "city", "name"])}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("post_code", "Post Code")}
              fallbackValue={NA}
            >
              {props.item.getIn(["shipping_address", "zip"])}
            </DescriptionTerm>
          </CardText>
        </Card>

        <Card>
          <CardText>
            <DescriptionTerm
              title={getLocalisationMessage(
                "billing_address",
                "Billing Address",
              )}
              fallbackValue={NA}
            >
              {props.item.getIn(["billing_address", "address"])}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("country", "Country")}
              fallbackValue={NA}
            >
              {props.item.getIn(["billing_address", "country", "name"])}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("city", "City")}
              fallbackValue={NA}
            >
              {props.item.getIn(["billing_address", "city", "name"])}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("post_code", "Post Code")}
              fallbackValue={NA}
            >
              {props.item.getIn(["billing_address", "zip"])}
            </DescriptionTerm>
          </CardText>
        </Card>
      </div>
    </div>
  );
}

export default enhancer(OrderDetailsDialogCourier);
