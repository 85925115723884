import fp from "lodash/fp";
import {
  updateHash,
  updateQuery,
  combineCleanQuery,
} from "../../../shared/helpers/UrlUtils";

export const useNavigationHooks = router => {
  const navigateTo = (to, replace) =>
    replace ? router.replace(to) : router.push(to);
  const updateLocationHash = hash =>
    updateHash(router.getCurrentLocation(), hash);
  const updateLocationQuery = query =>
    updateQuery(router.getCurrentLocation(), query);
  const updateLocationQueryFilter = filter =>
    updateLocationQuery(router, query =>
      combineCleanQuery(query, filter.getValues()),
    );

  return {
    setLocation: (to, replace) => {
      if (!fp.isUndefined(replace) && process.env.NODE_ENV === "development") {
        console.warn(
          "setLocation(to, replace) is deprecated, use replaceLocation(to)",
        );
      }
      return navigateTo(to, replace);
    },
    replaceLocation: to => navigateTo(to, true),
    setLocationHash: (hash, replace) => {
      if (!fp.isUndefined(replace) && process.env.NODE_ENV === "development") {
        console.warn(
          "setLocationHash(hash, replace) is deprecated, use replaceLocationHash(hash)",
        );
      }

      navigateTo(updateLocationHash(hash), replace);
    },
    replaceLocationHash: hash => navigateTo(updateLocationHash(hash), true),
    setLocationQuery: (query, replace) => {
      if (!fp.isUndefined(replace) && process.env.NODE_ENV === "development") {
        console.warn(
          "setLocationQuery(query, replace) is deprecated, use replaceLocationQuery(query)",
        );
      }

      navigateTo(updateLocationQuery(query), replace);
    },
    replaceLocationQuery: query => {
      navigateTo(updateLocationQuery(query), true);
    },
    setLocationQueryFilter: (filter, replace) => {
      if (!fp.isUndefined(replace) && process.env.NODE_ENV === "development") {
        console.warn(
          "setLocationQueryFilter(query, replace) is deprecated, use replaceLocationQueryFilter(query)",
        );
      }

      navigateTo(updateLocationQueryFilter(filter), replace);
    },
    replaceLocationQueryFilter: filter =>
      navigateTo(updateLocationQueryFilter(filter), true),
    goBack: router.goBack,
  };
};
