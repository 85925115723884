import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { LinearProgress } from "@material-ui/core";

export const EDIT = "edit";
export const SAVE = "save";
export const LINK = "link";
export const DIALOG = "dialog";
export const DATE = "date";
export const COLUMN = "column";
export const RENDER = "render";
export const ACTION = "action";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: "100%",
    overflow: "hidden",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    paddingLeft: "1rem",
  },
  container: {
    height: "45vh",
  },
  link: {
    color: "#9c0008",
    textDecoration: "none",
    cursor: "pointer",
  },
});

function ReceptacleMailItemsTable({
  columns,
  list,
  total,
  withCheckbox,
  size,
  isLoading,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              rowCount={total}
              headCells={columns}
              isCheckboxEnabled={withCheckbox}
            />
            {isLoading ? (
              <LinearProgress
                style={{ position: "absolute", width: "99.8%" }}
                mode="indeterminate"
                color="secondary"
              />
            ) : (
              <TableBody>
                {list.map((row, index) => (
                  <TableRow hover={true} tabIndex={-1} key={index}>
                    {columns.map((item, i) => (
                      <TableCell key={i} padding="normal">
                        {item.render(row, index)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

ReceptacleMailItemsTable.propTypes = {
  list: PropTypes.array,
  columns: PropTypes.array,
  total: PropTypes.number,
  withCheckbox: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  isLoading: PropTypes.bool,
};

export default enhancer(ReceptacleMailItemsTable);
