import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SUPPLIER_USER_LIST_URL = `${API_ROOT_URL}/admin/supplier/users`;
const SUPPLIER_USER_ITEM_URL = `${API_ROOT_URL}/admin/supplier/user/%s`;
const SUPPLIER_USER_CREATE_URL = `${API_ROOT_URL}/admin/supplier/user`;
const SUPPLIER_USER_CHOOSE_URL = `${API_ROOT_URL}/admin/supplier/users/choose`;

export const getSupplierUserList = (request: DataListFilter) =>
  api.getStream(SUPPLIER_USER_LIST_URL, { params: request.getDefinedValues() });
export const getSupplierUser = id =>
  api.getStream(sprintf(SUPPLIER_USER_ITEM_URL, id));
export const updateSupplierUser = (id, body) =>
  api.put(sprintf(SUPPLIER_USER_ITEM_URL, id), { body });

type createSupplierRequest = {
  authorities: [],
  billing_address: {
    address: string,
    address_type: number,
    city: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    country: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    id: number,
    primary: boolean,
    zip: string,
  },
  code: string,
  contact_email: string,
  contact_first_name: string,
  contact_last_name: string,
  contact_password: string,
  contact_phone: string,
  created_date: string,
  fax: string,
  id: number,
  name: string,
  number_of_drivers: number,
  number_of_fleet: number,
  price_formula: string,
  shipping_address: {
    address: string,
    address_type: number,
    city: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    country: {
      code: string,
      gparent_code: string,
      id: number,
      name: string,
      parent_code: string,
    },
    id: number,
    primary: boolean,
    zip: string,
  },
  status: string,
  supplier_email: string,
  supplier_phone: string,
  website: string,
};

export const createSupplierUser = (body: createSupplierRequest) =>
  api.post(SUPPLIER_USER_CREATE_URL, { body });

const itemSupplierUserCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(SUPPLIER_USER_ITEM_URL, id)),
});

const supplierUserPredictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(SUPPLIER_USER_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getCachedSupplierUser = id =>
  itemSupplierUserCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getSupplierUserPredictions = (request: DataListFilter) =>
  supplierUserPredictionsCache.get(request);
