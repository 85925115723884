import React, { useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import FlexBox from "../../components/ui-core/FlexBox";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import SimpleAutocomplete from "../../components/form/SimpleAutocomplete";
import { getJMById, getJMList2 } from "../../api/shared/CountryV2Api";

const enhancer = compose(
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "500px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

LockerPostFilterForm.propTypes = {
  classes: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  initialValues: PropTypes.object,
};

function LockerPostFilterForm({
  classes,
  getLocalisationMessage,
  onFilterChange,
  onRequestClose,
  open,
  initialValues,
}) {
  const [filter, setFilter] = useState(initialValues);
  return (
    <ModalPaper
      open={open}
      title={getLocalisationMessage("filter", "Filter")}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={onRequestClose}
    >
      <FlexBox align="center" container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FlexBox
                  direction="column"
                  flex={true}
                  container={24}
                  gutter={16}
                >
                  <FlexBox>
                    <SimpleAutocomplete
                      initialId={filter.jurisdiction_id}
                      onChange={v =>
                        setFilter({ ...filter, jurisdiction_id: v.id })
                      }
                      getById={getJMById}
                      chooseAPI={getJMList2}
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction")}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
              <CardActions>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} justify="flex-end">
                    <Button onClick={() => onRequestClose()}>
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </Button>
                    <Button
                      onClick={() => {
                        onFilterChange(filter);
                        onRequestClose();
                      }}
                    >
                      {getLocalisationMessage("submit", "Submit")}
                    </Button>
                  </FlexBox>
                </FlexBox>
              </CardActions>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(LockerPostFilterForm);
