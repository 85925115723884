import React from "react";
import { Map, OrderedSet } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormLanguageSelectField from "../form/FormLanguageSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import EditorField from "../html-editor-core/EditorField";
import { getMessages } from "../../reducers/LocalizationReducer";
import TermsSettingConstants, {
  COD_TERMS,
  PUBLIC_PROHIBITS,
  PUBLIC_TERMS,
} from "../../constants/TermsSettingConstants";
import { Languages } from "../../constants/LocaleTypes";
import { formatLanguage18 } from "../../containers/admin/AdminDefaultPublicTermsContainer";

const trueFalseOptions = OrderedSet.of(true, false);
const formatTrueFalseOption = (x, i18n) =>
  x === true ? i18n.get("yes", "Yes") : i18n.get("no", "No");

function formatTermsSettings(code, i18n) {
  switch (code) {
    case PUBLIC_TERMS:
      return i18n.get("public_terms", "Public Terms");
    case PUBLIC_PROHIBITS:
      return i18n.get("public_prohibits", "Public Prohibits");
    case COD_TERMS:
      return i18n.get("cod_terms", "COD Terms");
    default:
      return null;
  }
}

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  reduxForm({
    form: "TermsSettingsForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: !values.name && props.i18n.get("enter_name", "Enter Name"),
      languageCode:
        !values.languageCode &&
        props.i18n.get("enter_language", "Enter Language"),
      termsType:
        !values.termsType &&
        props.i18n.get("enter_terms_type", "Enter Terms Type"),
    }),
  }),
);

TermsSettingsForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function TermsSettingsForm(props) {
  const { i18n } = props;

  return (
    <form autoComplete="off">
      <PageLoading isLoading={props.submitting} />

      <Card>
        <CardContent>
          <FlexBox flex={true} gutter={8}>
            <FlexBox flex={true} direction="column">
              <FormTextField
                name="name"
                autoWidth={true}
                label={`${i18n.get("terms_name", "Terms Name")} *`}
              />
              <FormLanguageSelectField
                name="languageCode"
                label={`${i18n.get("language", "Language")} *`}
                autoWidth={true}
              />
              <FormSelectField
                name="languageCode"
                label={`${i18n.get("language", "Language")} *`}
                autoWidth={true}
                options={Languages}
                formatOption={value => formatLanguage18(value, i18n)}
                fullWidth={true}
              />
              <FormSelectField
                name="required"
                autoWidth={true}
                label={`${i18n.get("required_terms", "Required Terms")} *`}
                options={trueFalseOptions}
                formatOption={x => formatTrueFalseOption(x, i18n)}
              />
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <FormSelectField
                name="termsType"
                autoWidth={true}
                options={TermsSettingConstants}
                formatOption={value => formatTermsSettings(value, i18n)}
                label={`${i18n.get(
                  "terms_settings_type",
                  "Terms Settings Type",
                )} *`}
              />
              <FormSelectField
                name="enabled"
                autoWidth={true}
                label={`${i18n.get("visible", "Visible")} *`}
                options={trueFalseOptions}
                formatOption={x => formatTrueFalseOption(x, i18n)}
              />
            </FlexBox>
          </FlexBox>
          <Card>
            <CardContent title={i18n.get("terms", "Terms")}>
              <ListSubheader>{`${i18n.get("terms", "Terms")} *`}</ListSubheader>
              <FlexBox flex={true} gutter={8}>
                <FlexBox>
                  <EditorField
                    name="terms"
                    autoWidth={true}
                    hintText={i18n.get(
                      "type_here_to_terms",
                      "Type here to Terms",
                    )}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </CardContent>
        <CardActions>
          <FlexBox justify="flex-end">
            <Button onClick={props.handleSubmit}>
              {i18n.get("save", "Save")}
            </Button>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(TermsSettingsForm);
