import React from "react";
import { Set, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import copy from "copy-to-clipboard";
import FlexBox from "../ui-core/FlexBox";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../data-list/DataList";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { showSuccessMessage } from "../../reducers/NotificationsReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  useSheet({
    list: { overflowY: "auto" },
  }),
  connect(null, { showSuccessMessage }),
  pureComponent(fp.pick(["list", "selectedIds"])),
);

NeighborhoodsCoordinatesList.propTypes = {
  classes: PropTypes.object,

  list: PropTypes.instanceOf(List),

  selectedIds: PropTypes.instanceOf(Set),
  onSelectIds: PropTypes.func,

  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function NeighborhoodsCoordinatesList(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox container={8} flex={true} element={<Paper />}>
      <FlexBox gutter={8} direction="column" flex={true}>
        <FlexBox
          direction="column"
          gutter={8}
          flex={true}
          className={classes.list}
        >
          <DataList
            isLoading={false}
            list={props.list}
            rowCount={props.list.size}
            overscanRowCount={2}
            selectedRowCount={props.selectedIds.size}
            rowGetter={options => props.list.get(options.index)}
            altHeader={
              <FlexBox flex={true} align="flex-end">
                <Button
                  disabled={props.selectedIds.size === 0}
                  onClick={() => {
                    const list = [];

                    props.list.forEach(item => {
                      if (props.selectedIds.includes(item.get("id"))) {
                        list.push(`${item.get("id")}\t${item.get("name")}`);
                      }
                    });

                    copy(list.join("\n"));

                    props.showSuccessMessage(
                      getLocalisationMessage(
                        "data_has_been_copied_to_clipboard",
                        "Data has been copied to clipboard",
                      ),
                    );
                  }}
                >
                  {" "}
                  {getLocalisationMessage("copy", "copy")}{" "}
                </Button>
              </FlexBox>
            }
          >
            <DataListCheckbox
              allRowsSelected={() =>
                props.selectedIds.size > 0 &&
                props.selectedIds.size === props.list.size
              }
              onAllRowsSelect={value =>
                props.onSelectIds(
                  value
                    ? fp.set(
                        "selectedIds",
                        props.list.toSet().map(item => item.get("id")),
                      )
                    : fp.set("selectedIds", Set()),
                )
              }
              rowSelected={row => props.selectedIds.has(row.cellData.get("id"))}
              onRowSelect={row =>
                props.onSelectIds(
                  row.selected
                    ? fp.set(
                        "selectedIds",
                        props.selectedIds.add(row.cellData.get("id")),
                      )
                    : fp.set(
                        "selectedIds",
                        props.selectedIds.delete(row.cellData.get("id")),
                      ),
                )
              }
            />

            <DataListColumn
              width={94}
              label={getLocalisationMessage("id", "ID")}
              dataKey="id"
              disableSort={true}
              justifyContent="center"
              cellRenderer={row => row.cellData.get("id")}
            />

            <DataListColumn
              width={94}
              label={getLocalisationMessage("name_1", "Name")}
              dataKey="name"
              disableSort={true}
              justifyContent="center"
              cellRenderer={row => row.cellData.get("name")}
            />
          </DataList>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NeighborhoodsCoordinatesList);
