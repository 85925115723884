import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import TermsSettingsFilterForm from "../../components/terms-settings/TermsSettingFilterForm";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "600px",
      minHeight: "auto",
    },
  }),
);

AdminTermsFilterWrapper.propTypes = {
  classes: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

function AdminTermsFilterWrapper(props) {
  const { i18n, classes } = props;

  return (
    <ModalPaper
      open={props.open}
      title={i18n.get("terms_filter", "Terms Filter")}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <TermsSettingsFilterForm
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        onDismiss={props.onRequestClose}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminTermsFilterWrapper);
