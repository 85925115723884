import React from "react";
import { OrderedMap } from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "../ui-core/NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream =>
    propsStream
      .distinctUntilChanged(isEqualData)
      .map(({ tab, tabCount, basePathname, locationQuery, ...props }) => ({
        ...props,
        value: { pathname: `${basePathname}/${tab}`, query: locationQuery },
        tabs: tabCount
          .map((count, item) => ({
            label: (
              <TabBadge
                badge={count}
                label={props.getLocalisationMessage(item, formatText(item))}
              />
            ),
            to: { pathname: `${basePathname}/${item}`, query: locationQuery },
          }))
          .toArray(),
      })),
  ),
);

const OrdersRequiredAttentionTabs = enhancer(NavTabs);

OrdersRequiredAttentionTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  tabCount: PropTypes.instanceOf(OrderedMap).isRequired,
  basePathname: PropTypes.string.isRequired,
  locationQuery: PropTypes.object.isRequired,
  getLocalisationMessage: PropTypes.func,
};

export default OrdersRequiredAttentionTabs;
