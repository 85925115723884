import React from "react";
import { isDate, isValid } from "date-fns";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { COMPLETED, PENDING } from "../../constants/AdminBankDepositCodes";

const valueSelector = formValueSelector("UpdateBankDepositStatusDialog");

const AdminBankDepositCodes = OrderedSet.of(PENDING, COMPLETED);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({
    form: "UpdateBankDepositStatusDialog",
    validate: (values, props) => ({
      status:
        fp.isEmpty(values.status) &&
        props.getLocalisationMessage("select_status", "Select Status"),
      bankReference:
        Boolean(
          values.status === COMPLETED && fp.isEmpty(values.bankReference),
        ) && props.getLocalisationMessage("add_reference", "Add Reference"),
      bankPaymentDate:
        Boolean(
          values.status === COMPLETED &&
            (!isDate(values.bankPaymentDate) ||
              !isValid(values.bankPaymentDate)),
        ) &&
        props.getLocalisationMessage("add_payment_data", "Add Payment Data"),
    }),
  }),
  connect(state => ({
    values: valueSelector(state, "status", "reference"),
  })),
  mapPropsStream(propsStream => {
    const sideEffectsStream = propsStream
      .distinctUntilKeyChanged(isEqualDataIn(["values", "status"]))
      .filter(
        fp.overEvery([
          fp.get("dirty"),
          fp.flow(fp.get("values.status"), fp.negate(fp.eq(COMPLETED))),
          fp.flow(fp.get("values.reference"), fp.negate(fp.isEmpty)),
        ]),
      )
      .do(props => props.change("reference", null))
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

UpdateBankDepositStatusDialog.propTypes = {
  values: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function UpdateBankDepositStatusDialog(props) {
  const { values, getLocalisationMessage } = props;

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogContent style={{ minWidth: 400 }}>
        <form autoComplete="off">
          <FormSelectField
            name="status"
            autoWidth={true}
            fullWidth={true}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            options={AdminBankDepositCodes}
            label={getLocalisationMessage("status", "Status")}
          />

          {values.status === COMPLETED && (
            <div>
              <FormTextField
                name="bankReference"
                fullWidth={true}
                disabled={true}
                label={getLocalisationMessage("payment_ref", "Payment ref #")}
              />

              <FormDateField
                name="bankPaymentDate"
                label={getLocalisationMessage("payment_date", "Payment Date")}
              />
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(UpdateBankDepositStatusDialog);
