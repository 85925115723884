import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITable, {
  ACTION_BY_INDEX,
  COLUMN,
  DIALOG,
} from "../../components/orders-core/MUITable";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { Add, Refresh } from "@material-ui/icons";
import {
  createInternationalSettingsFormat,
  deleteInternationalSettingsFormat,
  getInternationalSettingsFormatItem,
  getInternationalSettingsFormatList,
  updateInternationalSettingsFormat,
} from "../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../helpers/DataUtils";
import NewSettingsDialog from "../../components/order-create-wizard-new/international-reports/NewSettingsDialog";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import UpdateSettingsDialog from "../../components/order-create-wizard-new/international-reports/UpdateSettingsDialog";

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
);

AdminInternationalSettingsFormatContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminInternationalSettingsFormatContainer(props) {
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
    status: "all",
  });
  const [id, setId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const classes = useStyles();

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });

  useEffect(() => {
    setIsLoading(true);
    getInternationalSettingsFormatList(filter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const onDelete = removeId => {
    deleteInternationalSettingsFormat(removeId)
      .then(() => {
        setDeleteId(0);
        props.showSuccessMessage(
          props.i18n.get("successfully_removed", "Successfully Removed"),
        );
        refreshList();
      })
      .catch(error => {
        props.showErrorMessage(error);
        setDeleteId(0);
      });
  };

  return (
    <AdminAppLayout title={props.i18n.get("format", "Format")}>
      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {props.i18n.get(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <NewSettingsDialog
        open={open}
        title={props.i18n.get("new_format", "New Format")}
        refreshList={refreshList}
        createApi={createInternationalSettingsFormat}
        onRequestClose={() => {
          setOpen(false);
          setId(null);
        }}
      />

      <UpdateSettingsDialog
        open={id > 0}
        id={id}
        title={props.i18n.get("update_format", "Update Format")}
        getItemApi={getInternationalSettingsFormatItem}
        refreshList={refreshList}
        updateApi={updateInternationalSettingsFormat}
        onRequestClose={() => {
          setOpen(false);
          setId(null);
        }}
      />

      <MUITable
        isLoading={isLoading}
        title={props.i18n.get("format", "Format")}
        size="small"
        list={list}
        total={total}
        setId={setId}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        headerActions={
          <FlexBox justify={JUSTIFY_END} align={ALIGN_CENTER}>
            <CustomButton
              onClick={() => setOpen(true)}
              startIcon={<Add />}
              variant={OUTLINED}
              color={SECONDARY}
              style={{
                marginRight: "1rem",
              }}
            >
              {props.i18n.get("create")}
            </CustomButton>

            <IconButton
              className={isLoading && classes.refresh}
              tooltip={props.i18n.get("refresh", "Refresh")}
              onClick={() => refreshList()}
            >
              <Refresh />
            </IconButton>
          </FlexBox>
        }
        columns={[
          {
            type: DIALOG,
            name: "id",
            label: props.i18n.get("id"),
            keyName: "id",
            align: "center",
          },
          {
            type: COLUMN,
            name: "name",
            label: props.i18n.get("name"),
          },
          {
            type: ACTION_BY_INDEX,
            name: "action",
            label: props.i18n.get("action", "Action"),
            // eslint-disable-next-line  react/prop-types
            dispatch: removeId => setDeleteId(removeId),
          },
        ]}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminInternationalSettingsFormatContainer);
