import { Observable } from "rxjs";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { FirebaseSDK } from "./FirebaseSDK";
import {
  MATCHES_FN,
  OVER_SOME_FN,
  OVER_EVERY_FN,
  NOT_MATCHES_FN,
} from "../helpers/Rule";
import {
  COD_NOT_READY,
  WRONG_SHIPMENT,
  DELIVERY_REJECTED,
  INCOMPLETE_PARCEL,
  NO_TIME_FOR_DELIVERY,
  BAD_RECIPIENT_ADDRESS,
  RECIPIENT_MOBILE_WRONG,
  RECIPIENT_NAME_MISSING,
  RECIPIENT_NOT_AVAILABLE,
  NO_CAPACITY_FOR_DELIVERY,
  FUTURE_DELIVERY_REQUESTED,
  BAD_WEATHER_DURING_DELIVERY,
  RECIPIENT_MOBILE_NO_RESPONSE,
  RECIPIENT_MOBILE_SWITCHED_OFF,
  ID_OR_DOCUMENT_REQUIRED_MISSING,
  COLLECTION_ARRANGED_OR_REQUESTED,
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
  RECIPIENT_ADDRESS_CHANGE_REQUESTED,
  UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
} from "../constants/OrderStatusCodes";
import {
  RTO,
  ON_HOLD,
  FUTURE_DELIVERY_FUTURE,
  FUTURE_DELIVERY_TOMORROW,
  FUTURE_DELIVERY_UNSORTED,
} from "../constants/OrderSortingReturnField";

const basePath = `/order_return`;
const warehouseSettingsPath = `${basePath}/warehouse_settings`;

const roleObj = {
  bin_rules: {
    on_hold: {
      weight: 0,
      code: "on_hold",
      name: "On Hold",
      group_by: [ON_HOLD],
      predicate: {
        fn: OVER_EVERY_FN,
        args: [
          {
            fn: OVER_SOME_FN,
            args: [
              { fn: MATCHES_FN, args: [{ value: { attempt: 0 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 1 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 2 } }] },
            ],
          },
          {
            fn: OVER_SOME_FN,
            args: [
              {
                fn: MATCHES_FN,
                args: [{ value: { status: BAD_RECIPIENT_ADDRESS } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: RECIPIENT_NOT_AVAILABLE } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: RECIPIENT_MOBILE_SWITCHED_OFF } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: RECIPIENT_MOBILE_WRONG } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: RECIPIENT_MOBILE_NO_RESPONSE } }],
              },
              {
                fn: MATCHES_FN,
                args: [
                  { value: { status: RECIPIENT_ADDRESS_CHANGE_REQUESTED } },
                ],
              },
              {
                fn: MATCHES_FN,
                args: [
                  { value: { status: UNABLE_TO_ACCESS_RECIPIENT_PREMISES } },
                ],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: RECIPIENT_NAME_MISSING } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: INCOMPLETE_PARCEL } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: COLLECTION_ARRANGED_OR_REQUESTED } }],
              },
            ],
          },
        ],
      },
    },
    tomorrow_delivery: {
      weight: 1,
      code: "tomorrow_delivery",
      name: "Tomorrow Delivery",
      group_by: [FUTURE_DELIVERY_TOMORROW],
      predicate: {
        fn: OVER_EVERY_FN,
        args: [
          {
            fn: MATCHES_FN,
            args: [{ value: { delivery_type: FUTURE_DELIVERY_TOMORROW } }],
          },
          {
            fn: OVER_SOME_FN,
            args: [
              { fn: MATCHES_FN, args: [{ value: { attempt: 0 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 1 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 2 } }] },
            ],
          },
          {
            fn: OVER_SOME_FN,
            args: [
              {
                fn: MATCHES_FN,
                args: [{ value: { status: COD_NOT_READY } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: FUTURE_DELIVERY_REQUESTED } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: ID_OR_DOCUMENT_REQUIRED_MISSING } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: BAD_WEATHER_DURING_DELIVERY } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: NO_CAPACITY_FOR_DELIVERY } }],
              },
              {
                fn: MATCHES_FN,
                args: [
                  { value: { status: DELIVERY_DELAY_DUE_TO_LATE_BOOKING } },
                ],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: NO_TIME_FOR_DELIVERY } }],
              },
            ],
          },
        ],
      },
    },
    future_delivery: {
      weight: 2,
      code: "future_delivery",
      name: "Future Delivery",
      group_by: [FUTURE_DELIVERY_FUTURE],
      predicate: {
        fn: OVER_EVERY_FN,
        args: [
          {
            fn: MATCHES_FN,
            args: [{ value: { delivery_type: FUTURE_DELIVERY_FUTURE } }],
          },
          {
            fn: OVER_SOME_FN,
            args: [
              { fn: MATCHES_FN, args: [{ value: { attempt: 0 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 1 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 2 } }] },
            ],
          },
          {
            fn: OVER_SOME_FN,
            args: [
              {
                fn: MATCHES_FN,
                args: [{ value: { status: COD_NOT_READY } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: FUTURE_DELIVERY_REQUESTED } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: ID_OR_DOCUMENT_REQUIRED_MISSING } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: BAD_WEATHER_DURING_DELIVERY } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: NO_CAPACITY_FOR_DELIVERY } }],
              },
              {
                fn: MATCHES_FN,
                args: [
                  { value: { status: DELIVERY_DELAY_DUE_TO_LATE_BOOKING } },
                ],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: NO_TIME_FOR_DELIVERY } }],
              },
            ],
          },
        ],
      },
    },
    unsorted_delivery: {
      weight: 3,
      code: "unsorted_delivery",
      name: "Unsorted Delivery",
      group_by: [FUTURE_DELIVERY_UNSORTED],
      predicate: {
        fn: OVER_EVERY_FN,
        args: [
          {
            fn: MATCHES_FN,
            args: [{ value: { delivery_type: FUTURE_DELIVERY_UNSORTED } }],
          },
          {
            fn: OVER_SOME_FN,
            args: [
              { fn: MATCHES_FN, args: [{ value: { attempt: 0 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 1 } }] },
              { fn: MATCHES_FN, args: [{ value: { attempt: 2 } }] },
            ],
          },
          {
            fn: OVER_SOME_FN,
            args: [
              {
                fn: MATCHES_FN,
                args: [{ value: { status: COD_NOT_READY } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: FUTURE_DELIVERY_REQUESTED } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: ID_OR_DOCUMENT_REQUIRED_MISSING } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: BAD_WEATHER_DURING_DELIVERY } }],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: NO_CAPACITY_FOR_DELIVERY } }],
              },
              {
                fn: MATCHES_FN,
                args: [
                  { value: { status: DELIVERY_DELAY_DUE_TO_LATE_BOOKING } },
                ],
              },
              {
                fn: MATCHES_FN,
                args: [{ value: { status: NO_TIME_FOR_DELIVERY } }],
              },
            ],
          },
        ],
      },
    },
    rto: {
      weight: 4,
      code: "rto",
      name: "RTO",
      group_by: [RTO],
      predicate: {
        fn: OVER_SOME_FN,
        args: [
          {
            fn: OVER_EVERY_FN,
            args: [
              {
                fn: OVER_SOME_FN,
                args: [
                  { fn: NOT_MATCHES_FN, args: [{ value: { attempt: 0 } }] },
                  { fn: NOT_MATCHES_FN, args: [{ value: { attempt: 1 } }] },
                  { fn: NOT_MATCHES_FN, args: [{ value: { attempt: 2 } }] },
                ],
              },
              {
                fn: OVER_SOME_FN,
                args: [
                  {
                    fn: MATCHES_FN,
                    args: [{ value: { status: RECIPIENT_NOT_AVAILABLE } }],
                  },
                  {
                    fn: MATCHES_FN,
                    args: [
                      { value: { status: RECIPIENT_MOBILE_SWITCHED_OFF } },
                    ],
                  },
                  {
                    fn: MATCHES_FN,
                    args: [{ value: { status: RECIPIENT_MOBILE_NO_RESPONSE } }],
                  },
                  {
                    fn: MATCHES_FN,
                    args: [
                      { value: { status: COLLECTION_ARRANGED_OR_REQUESTED } },
                    ],
                  },
                  {
                    fn: MATCHES_FN,
                    args: [{ value: { status: COD_NOT_READY } }],
                  },
                  {
                    fn: MATCHES_FN,
                    args: [
                      { value: { status: ID_OR_DOCUMENT_REQUIRED_MISSING } },
                    ],
                  },
                  {
                    fn: MATCHES_FN,
                    args: [{ value: { status: WRONG_SHIPMENT } }],
                  },
                ],
              },
            ],
          },
          { fn: MATCHES_FN, args: [{ value: { status: DELIVERY_REJECTED } }] },
        ],
      },
    },
  },
};

export const getOrderReturnWarehouseSettings = warehouseId =>
  Observable.of(roleObj)
    .map(
      fp.flow(
        x => ({ ...x, warehouse: { id: warehouseId } }),
        fp.toPlainObject,
        fromJS,
      ),
    )
    .share();

export const setOrderReturnWarehouseBinRule = (warehouseId, code, value) =>
  new FirebaseSDK().set(
    `${warehouseSettingsPath}/${warehouseId}/bin_rules/${code}`,
    value,
  );

export const removeOrderReturnWarehouseBinRule = (warehouseId, code) =>
  setOrderReturnWarehouseBinRule(warehouseId, code, null);

export const setOrderReturnWarehouseBinRuleList = (warehouseId, value) =>
  new FirebaseSDK().set(
    `${warehouseSettingsPath}/${warehouseId}/bin_rules`,
    value,
  );

export const setDefaultOrderReturnWarehouseBinRules = warehouseId =>
  setOrderReturnWarehouseBinRuleList(warehouseId);
