import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";
import PropTypes from "prop-types";
import { Observable } from "rxjs";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import { reduxForm } from "redux-form";
import FormTextField from "../form/FormTextField";
import FormVoiceUpload from "../form/FormVoiceUpload";
import _ from "lodash";
import { isEqualData } from "../../helpers/DataUtils";
import { createVoice } from "../../api/admin/AdminVoiceSortingApi";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const { handler: onSubmitHandler, stream: onSubmitHandlerStream } =
      createEventHandler();

    const clickStream = onSubmitHandlerStream
      .distinctUntilChanged(isEqualData)
      .switchMap((values) => {
        if (values.voices && values.voices.length) {
          return createVoice({
            title: values.name,
            name: values.name,
            url: values.voices[0],
          });
        }
        return Observable.from(() => {});
      })
      .startWith(null);

    return propsStream.combineLatest(clickStream, (props) => ({
      ...props,
      onSubmitHandler,
    }));
  }),
  reduxForm({
    form: "VoiceSortingDialogForm",
    validate: (values) => ({
      name:
        _.isEmpty(values.name) || _.isNil(values.name)
          ? "Введите навание"
          : undefined,
      voices:
        values.voices && values.voices.length
          ? undefined
          : "Выберите звук для загрузки",
    }),
    onSubmit: (values, dispatch, props) => {
      props.onSubmitHandler(values);
    },
    onSubmitSuccess: (v, dispatch, props) => {
      props.onRequestClose();
    },
    onSubmitFail: (error, dispatch, props) => {
      props.onSubmitFail(error);
    },
  }),
);

const VoiceCreateDialog = ({
  getLocalisationMessage,
  handleSubmit,
  onRequestClose,
}) => (
  <Dialog open={true} onClose={onRequestClose} fullWidth={true}>
    <DialogTitle> {getLocalisationMessage("add_voice_dialog")} </DialogTitle>
    <DialogContent>
      <FlexBox direction="column" flex={true} gutter={8}>
        <FlexBox>
          <FormTextField
            fullWidth={true}
            name="name"
            label={getLocalisationMessage("title")}
            hintText="Enter here..."
          />
        </FlexBox>
        <FlexBox>
          <FormVoiceUpload
            fullWidth={true}
            name="voices"
            label={getLocalisationMessage("upload_a_voice")}
          />
        </FlexBox>
      </FlexBox>
    </DialogContent>
    <DialogActions>
      <div>
        <Button onClick={onRequestClose}>
          {" "}
          {getLocalisationMessage("close")}{" "}
        </Button>
        <Button type="submit" style={{ marginLeft: 10 }} onClick={handleSubmit}>
          {getLocalisationMessage("save")}
        </Button>
      </div>
    </DialogActions>
  </Dialog>
);

VoiceCreateDialog.propTypes = {
  onRequestClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

export default enhancer(VoiceCreateDialog);
