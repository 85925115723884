import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, SubmissionError } from "redux-form";
import { Dialog, Button, DialogActions } from "@material-ui/core";
import { connect } from "react-redux";
import ImportFileDialog from "../files/ImportFileDialog";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { OVERRIDE } from "../../constants/MergeStrategies";
import { fromJSON } from "../../../shared/helpers/DataSerializer";

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),

  renderIf("open"),
  reduxForm({
    form: "OrderSortingImportRulesDialog",
    initialValues: { mergeStrategy: OVERRIDE },
  }),
  formValues({ data: "data" }),
);

OrderSortingImportRulesDialog.propTypes = {
  data: PropTypes.object,

  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool,
  onRequestClose: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderSortingImportRulesDialog(props) {
  const { getLocalisationMessage } = props;
  if (!props.data) {
    return (
      <ImportFileDialog
        open={props.open}
        accept=".wostjson"
        onRequestClose={props.onRequestClose}
        onSubmit={(file) =>
          Promise.resolve(file)
            .then(fromJSON)
            .catch(fp.noop)
            .then((x) => {
              if (fp.isPlainObject(x)) {
                return x;
              }

              throw new SubmissionError({ file: "Corrupted File Content" });
            })
        }
        onSubmitSuccess={(x) => props.change("data", x)}
      />
    );
  }

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />

      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderSortingImportRulesDialog);
