import { Set } from "immutable";

export const SHIPOX = "shipox";
export const PURPLE = "purple";
export const GREEN = "green";
export const ORANGE = "orange";
export const BLACK = "black";
export const BLUE = "blue";
export const BROWN = "brown";
export const GREY = "grey";
export const PINK = "pink";
export const RED = "red";
export const CREAM = "cream";
export const DARK_MODERATE_BLUE = "dark_moderate_blue";
export const VIVID_ORANGE = "vivid_orange";
export const BRIGHT_ORANGE = "bright_orange";
export const YELLOW = "yellow";

export default Set([
  PURPLE,
  GREEN,
  ORANGE,
  BLACK,
  BLUE,
  BROWN,
  GREY,
  PINK,
  RED,
  CREAM,
  DARK_MODERATE_BLUE,
  VIVID_ORANGE,
  BRIGHT_ORANGE,
  YELLOW,
  SHIPOX,
]).sort();
