import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toActivityHistoryFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),
    from_date: queryTypes.date,
    to_date: queryTypes.date,
    logType: types.string,
    provider: types.string,
    smsLogStatus: types.string,
  }),
);
