import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        warehouseId: { id: props.filter.getValue("warehouse_id") },
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            warehouse_id: null,
          });
          if (!fp.isEmpty(values.warehouseId)) {
            filter.setValue("warehouse_id", getValue(values.warehouseId, "id"));
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "DriverFilterForm",
    enableReinitialize: true,
  }),
);

DriverFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  dirty: PropTypes.bool,
  onDismiss: PropTypes.func,
  reset: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function DriverFilterForm(props) {
  return (
    <FlexBox
      container={16}
      element={<form onSubmit={props.handleSubmit} />}
      flex={true}
    >
      <FlexBox gutter={8} flex={true} direction="column">
        <Card>
          <CardContent>
            <FlexBox flex={true}>
              <FormWarehouseAutoComplete
                name="warehouseId"
                margin="normal"
                fullWidth={true}
                label={props.getLocalisationMessage("warehouse", "Warehouse")}
                hintText={props.getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>
          </CardContent>

          <FlexBox justify="flex-end">
            <CardActions>
              <Button
                onClick={() => {
                  props.change("warehouseId", null);
                }}
              >
                {props.getLocalisationMessage("clear", "Clear")}
              </Button>

              {props.dirty ? (
                <Button onClick={props.reset}>
                  {props.getLocalisationMessage("reset", "Reset")}
                </Button>
              ) : (
                Boolean(props.onDismiss) && (
                  <Button onClick={props.onDismiss}>
                    {props.getLocalisationMessage("dismiss", "Dismiss")}
                  </Button>
                )
              )}

              <Button type="submit">
                {props.getLocalisationMessage("submit", "Submit")}
              </Button>
            </CardActions>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverFilterForm);
