import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FormCountryV2AutoComplete from "../form/FormCountryV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue, isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  validateEmail,
  validateString,
  isValidObjectId,
  validatePassword,
} from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  DEMO,
  READY,
  NOT_READY,
} from "../../constants/MarketplaceStatusConstants";
import { getZones } from "../../api/shared/MarketplaceApi";

const FORM = "MarketplaceForm";
const valueSelector = formValueSelector(FORM);

const marketplaceStatuses = OrderedSet.of(READY, NOT_READY, DEMO);

const enhancer = compose(
  connect(state => ({
    countryCode: getValue(valueSelector(state, "country"), "description"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
    padding: { paddingTop: 10 },
    card: { width: "100%" },
  }),
  withContext(
    {
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => values =>
      props.onSubmit(_.omit(values, ["passwordConfirm"])),
  }),
  reduxForm({
    form: FORM,
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("enter_name", "Enter name")
        : null,
      country:
        !isValidObjectId(values.country) &&
        props.getLocalisationMessage(
          "please_select_country",
          "Please select country",
        ),
      zone:
        !getValue(values.zone, "id") &&
        props.getLocalisationMessage(
          "please_select_zone",
          "Please select zone",
        ),
      default_user: {
        first_name: validateString(
          fp.get(["default_user", "first_name"], values),
        )
          ? props.getLocalisationMessage("enter_first_name", "Enter First Name")
          : null,
        last_name: validateString(fp.get(["default_user", "last_name"], values))
          ? props.getLocalisationMessage("enter_last_name", "Enter Last Name")
          : null,
        email: validateEmail(fp.get(["default_user", "email"], values))
          ? props.getLocalisationMessage("enter_email", "Enter Email")
          : null,
        login: validateEmail(fp.get(["default_user", "login"], values))
          ? props.getLocalisationMessage("enter_login", "Enter Login")
          : null,
        password: validatePassword(fp.get(["default_user", "password"], values))
          ? props.getLocalisationMessage("enter_password", "Enter Password")
          : null,
      },
      passwordConfirm:
        values.passwordConfirm !== fp.get(["default_user", "password"], values)
          ? props.getLocalisationMessage(
              "passwords_are_not_equal",
              "Passwords are not equal",
            )
          : validatePassword(values.passwordConfirm),
    }),
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const zoneStream = propsStream
          .map(fp.pick("countryCode"))
          .distinctUntilChanged(isEqualData)
          .filter(props => props.countryCode)
          .switchMap(props =>
            getZones(props.countryCode).catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response => fromJS(response).getIn(["payload", "data"], List()))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(zoneStream, (props, zones) => ({
            ...props,
            zones,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .filter(props => !props.zones.isEmpty())
            .distinctUntilChanged(isEqualData)
            .do(props => {
              const item = props.zones.get(0);

              if (item) {
                props.change("zone", item);
              }
            }),
        ).startWith(null);

        return propsStream
          .combineLatest(sideEffectsStream, fp.identity)
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

MarketplaceForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  zones: PropTypes.instanceOf(List),
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getCachedCountry: PropTypes.func,
  getCountryPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function MarketplaceForm(props) {
  const { zones, classes, getLocalisationMessage } = props;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={5}>
            <Card className={classes.card}>
              <CardContent>
                <ListSubheader className={classes.subheader}>
                  {getLocalisationMessage("marketplace", "Marketplace")}
                </ListSubheader>

                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "marketplace_name",
                    "Marketplace Name",
                  )} *`}
                />

                <FormCountryV2AutoComplete
                  readOnly={false}
                  fullWidth={true}
                  openOnFocus={true}
                  name="country"
                  label={`${getLocalisationMessage("country", "Country")} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                />

                <FormSelectField
                  name="zone"
                  autoWidth={true}
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "select_zone",
                    "Select Zone",
                  )} *`}
                  formatOption={item => item.get("name")}
                  compareOptions={isEqualDataIn("id")}
                  options={zones}
                  disabled={zones.isEmpty() || zones.size === 1}
                />

                {false && (
                  <FormSupplierAutoComplete
                    name="supplier"
                    fullWidth={true}
                    label={getLocalisationMessage(
                      "select_supplier",
                      "Select Supplier",
                    )}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <FormSelectField
                  name="type"
                  autoWidth={true}
                  fullWidth={true}
                  label={getLocalisationMessage("select_type", "Select Type")}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  options={marketplaceStatuses}
                />
              </CardContent>
            </Card>
          </FlexBox>
          <FlexBox flex={true}>
            <Card className={classes.card}>
              <CardContent>
                <ListSubheader className={classes.subheader}>
                  {getLocalisationMessage("default_user", "Default User")}
                </ListSubheader>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true} container={8}>
                    <FormTextField
                      name="default_user.first_name"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "first_name",
                        "First Name",
                      )} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true} container={8}>
                    <FormTextField
                      name="default_user.last_name"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "last_name",
                        "Last Name",
                      )} *`}
                    />
                  </FlexBox>
                </FlexBox>

                <FormTextField
                  name="default_user.email"
                  fullWidth={true}
                  type="email"
                  label={`${getLocalisationMessage("email", "Email")} *`}
                />

                <FormTextField
                  name="default_user.login"
                  fullWidth={true}
                  label={`${getLocalisationMessage("login", "Login")} *`}
                />

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true} container={8}>
                    <FormTextField
                      name="default_user.password"
                      type="password"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "password",
                        "Password",
                      )} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true} container={8}>
                    <FormTextField
                      name="passwordConfirm"
                      type="password"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "repeat_password",
                        "Repeat Password",
                      )} *`}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </form>
  );
}

export default enhancer(MarketplaceForm);
