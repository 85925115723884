import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { HOLIDAY } from "../../constants/HolidayTypes";
import { saveHolidaySettings } from "../../api/admin/AdminSettingsApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import HolidaySettingsForm from "../../components/settings-core/HolidaySettingsForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px" },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

AdminHolidaySettingsCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminHolidaySettingsCreateDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("create_a_holiday", "Create A Holiday")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <HolidaySettingsForm
        onSubmit={({ country, ...values }) =>
          saveHolidaySettings({
            ...toSnakeCase(values),
            day_off_type: HOLIDAY,
            country_id: country.get("id"),
          }).catch(ResponseError.throw)
        }
        onDismiss={props.onRequestClose}
        onSubmitSuccess={() => {
          props.onRequestClose();
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_holiday_settings_created",
              "Successfully Holiday Settings Created",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminHolidaySettingsCreateDialogWrapper);
