import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withState("isLoading", "setIsLoading", false),

  useSheet({
    overall: { height: "64px" },
    error: { color: "red" },
    providerBox: { padding: 0, background: "rgba(0,0,0,0.001)" },
    providersList: { padding: 2, maxHeight: 450 },
    title: { fontSize: 15, color: "#666", marginBottom: 5 },
    fieldsBox: { padding: "0 15px" },
    providerButton: {
      padding: "15px",
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.2)",
      marginBottom: 3,
      borderRadius: 4,
    },
    activeMenu: { background: "rgba(0, 151, 136,0.2)" },
    saveButton: { background: "rgba(0,0,0,0.1)", height: 45, marginTop: 15 },
    noProvider: {
      color: "rgba(200,0,0,0.6)",
      fontSize: 16,
      textAlign: "center",
    },
  }),
  reduxForm({
    form: "TelegramProviderSettingsForm",
    enableReinitialize: true,
  }),
);

TelegramProviderSettingsForm.propTypes = {
  classes: PropTypes.object,
  fields: PropTypes.array,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  provider: PropTypes.string,
  providers: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function TelegramProviderSettingsForm(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <FlexBox
      gutter={16}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox flex={true} gutter={8}>
        <FlexBox
          style={{ width: 200 }}
          className={classes.providerBox}
          direction="column"
        >
          <FlexBox
            flex="grow"
            className={classes.providersList}
            direction="column"
          >
            <FlexBox flex={true}>
              <FormTextField
                name="BotName"
                fullWidth={true}
                label={getLocalisationMessage("BotName", "BotName")}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox className={classes.fieldsBox} flex={true}>
          <FlexBox
            direction="column"
            style={{ width: "100%" }}
            justify="space-between"
          >
            <FlexBox direction="column">
              <h4 className={classes.title}>
                {getLocalisationMessage("provider_config", "Provider Config")}
              </h4>
              <FormTextField
                required={true}
                name="BotToken"
                fullWidth={true}
                label={props.getLocalisationMessage("BotToken", "BotToken")}
              />
              <Button type="submit" className={classes.saveButton}>
                {getLocalisationMessage("save", "Save")}
              </Button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(TelegramProviderSettingsForm);
