import React from "react";
import PropTypes from "prop-types";

const CustomLegend = ({
  chart,
  customLegendIcon,
  getLocalisationMessage,
  legendLabelLocalisation,
  activeIndex,
  setActiveIndex,
}) => {
  const { data } = chart;

  const handleLegendClick = index => {
    setActiveIndex(prev => {
      if (prev.includes(index)) {
        const idx = prev.findIndex(i => i === index);
        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      }
      return [...prev, index];
    });

    // Toggle visibility of the dataset
    const meta = chart.getDatasetMeta(0);
    meta.data[index].hidden = !meta.data[index].hidden;

    chart.update();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data && data.labels
        ? data.labels.map((label, index) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
            <div
              key={index}
              onClick={() => handleLegendClick(index)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
                textDecoration: activeIndex.includes(index)
                  ? "line-through"
                  : "none",
              }}
            >
              {customLegendIcon ? (
                customLegendIcon(label)
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    fill={data.datasets[0].backgroundColor[index]}
                  />
                </svg>
              )}
              <span style={{ marginLeft: "8px" }}>
                {legendLabelLocalisation(label, getLocalisationMessage)}
              </span>
            </div>
          ))
        : null}
    </div>
  );
};

CustomLegend.propTypes = {
  chart: PropTypes.object.isRequired,
  customLegendIcon: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  legendLabelLocalisation: PropTypes.func,
  activeIndex: PropTypes.array,
  setActiveIndex: PropTypes.func,
};

export default CustomLegend;
