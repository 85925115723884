import { Observable } from "rxjs";
import React from "react";
import { Map, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { indigo } from "@material-ui/core/colors";
import RadarOrderFilterTabs from "./RadarOrderFilterTabs";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DRIVER_ORDER_RADAR_URL } from "../../constants/AdminPathConstants";
import AdminOrderFilterRadarWrapper from "../../wrappers/admin/AdminOrderFilterRadarWrapper";

const enhancer = compose(
  useSheet({
    tabs: {
      width: "100%",
    },
    loader: {
      height: "48px",
      backgroundColor: "#2E3359",
    },
    buttonStyle: {
      backgroundColor: "white",
      color: "black",
      borderBottom: "1px solid #eee",
      "& > div > div": {
        position: "relative",
        paddingRight: "10px",
        "& > div": {
          "& span": {
            position: "absolute",
            top: "5px",
            backgroundColor: indigo[500],
            color: "white",
          },
        },
      },
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withState("state", "setState", {
    customFilter: false,
  }),
  mapPropsStream((propsStream) => {
    const batchGetOrderListCount = (filters) =>
      Observable.from(
        filters
          .map(({ filter, getOrderList }, key) =>
            getOrderList(filter)
              .catch((error) => Observable.of({ error }))
              .map(fp.get("payload.data.total"))
              .takeLast(1)
              .delay(1000)
              .map((value) => ({ key, value })),
          )
          .toArray(),
      )
        .concatAll()
        .scan((acc, response) => acc.set(response.key, response.value), Map())
        .startWith(Map());

    return propsStream.combineLatest((props) => ({
      ...props,
      batchGetOrderListCount,
    }));
  }),
  pureComponent(
    fp.pick([
      "tabs",
      "state",
      "filters",
      "showDateFilter",
      "isLoadingFilters",
      "onRequestRefreshFilters",
      "orderFilter",
      "newOrdersCount",
    ]),
  ),
);

RadarOrderTabs.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  tabs: PropTypes.instanceOf(OrderedMap),
  getLocalisationMessage: PropTypes.func.isRequired,

  orderFilter: PropTypes.instanceOf(DataListFilter),
  filters: PropTypes.instanceOf(List),
  isLoadingFilters: PropTypes.bool,
  onRequestRefreshFilters: PropTypes.func,
  batchGetOrderListCount: PropTypes.func,
  onFilterClick: PropTypes.func,
  onOrderRefreshRequest: PropTypes.func,
  getOrderList: PropTypes.func,
  showCustomFilter: PropTypes.bool,
  newOrdersCount: PropTypes.number,
  showDateFilter: PropTypes.bool,
  onClickDateFilter: PropTypes.func,
};

function RadarOrderTabs(props) {
  return (
    <FlexBox flex={true} direction="column">
      {props.showCustomFilter && (
        <AdminOrderFilterRadarWrapper
          openEditFiltersDialog={props.state.customFilter}
          onToggleEditFiltersDialog={() =>
            props.setState(fp.set("customFilter", false))
          }
          filters={props.filters}
          onRequestRefresh={props.onRequestRefreshFilters}
        />
      )}

      <RadarOrderFilterTabs
        onFilterClick={props.onFilterClick}
        filterList={props.filters}
        isLoading={props.isLoadingFilters}
        basePathname={DRIVER_ORDER_RADAR_URL}
        filter={props.orderFilter}
        showCustomFilter={props.showCustomFilter}
        newOrdersCount={props.newOrdersCount}
        onOrderRefreshRequest={props.onOrderRefreshRequest}
        onCustomFilterClick={() => props.setState(fp.set("customFilter", true))}
        batchGetOrderListCount={props.batchGetOrderListCount}
        getOrderList={props.getOrderList}
      />
    </FlexBox>
  );
}

export default enhancer(RadarOrderTabs);
