import sprintf from "sprintf";

import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const AREAS_URL = `${API_ROOT_URL}/areas`;
const AREAS_ITEM_URL = `${API_ROOT_URL}/areas/%s`;
const AREAS_SORT_URL = `${API_ROOT_URL}/areas/z_index`;
const COUNTRY_CENTER_URL = `${API_ROOT_URL}/admin/country/%s/center`;
const AREA_ALIASES_URL = `${API_ROOT_URL}/areas/aliases`;
const COUNTRY_POLYGON_URL = `${API_ROOT_URL}/areas/country/%s`;
const AREA_DELETE_CONFIRM_URL = `${API_ROOT_URL}/area/delete_confirmed`;
const AREA_CHECK_UPDATE_CONFIRM_URL = `${
  API_ROOT_URL
}/area/check_update_confirmation`;

export const getAreaList = (filter: DataListFilter) =>
  api.getStream(AREAS_URL, { params: filter.getDefinedValues() });

export const addArea = body => api.post(AREAS_URL, { body });
export const editArea = body => api.put(AREAS_URL, { body });
export const deleteArea = id => api.delete(sprintf(AREAS_ITEM_URL, id));
export const sortAreas = body => api.put(AREAS_SORT_URL, { body });
export const getAreaAliases = ids =>
  api.getStream(AREA_ALIASES_URL, { params: { ids: ids.join(",") } });

export const getCountryCenter = id =>
  api.getStream(sprintf(COUNTRY_CENTER_URL, id));

export const getCountryPolygon = id =>
  api.getStream(sprintf(COUNTRY_POLYGON_URL, id));

export const deleteAreaConfirm = (areaId, attemptId) =>
  api.delete(AREA_DELETE_CONFIRM_URL, {
    params: {
      area_id: areaId,
      attempt_id: attemptId,
    },
  });
export const checkAreaUpdateConfirm = params =>
  api.post(AREA_CHECK_UPDATE_CONFIRM_URL, { params });
