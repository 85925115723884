import jcb from "../assets/cardTypes/jcb.png";
import ebay from "../assets/cardTypes/ebay.png";
import eway from "../assets/cardTypes/eway.png";
import sage from "../assets/cardTypes/sage.png";
import solo from "../assets/cardTypes/solo.png";
import visa from "../assets/cardTypes/visa.png";
import amazon from "../assets/cardTypes/amazon.png";
import cirrus from "../assets/cardTypes/cirrus.png";
import direct from "../assets/cardTypes/direct.png";
import paypal from "../assets/cardTypes/paypal.png";
import skrill from "../assets/cardTypes/skrill.png";
import maestro from "../assets/cardTypes/maestro.png";
import shopify from "../assets/cardTypes/shopify.png";
import discover from "../assets/cardTypes/discover.png";
import worldpay from "../assets/cardTypes/worldPay.png";
import undefinedcard from "../assets/cardTypes/undefined.png";
import mastercard from "../assets/cardTypes/masterCard.png";
import dinnersclub from "../assets/cardTypes/dinnersClub.png";
import visaelektron from "../assets/cardTypes/visaElektron.png";
import westernunion from "../assets/cardTypes/westernUnion.png";
import amex from "../assets/cardTypes/americanExpress.png";

export const cardTypes = {
  amazon,
  amex,
  cirrus,
  dinnersclub,
  direct,
  discover,
  ebay,
  eway,
  jcb,
  maestro,
  mastercard,
  paypal,
  sage,
  shopify,
  skrill,
  solo,
  visa,
  visaelektron,
  westernunion,
  worldpay,
  undefinedcard,
};
