import React, { useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import AppTitle from "../../components/deprecated/AppTitle";
import { formatDateTimeToUrl, safeParseDate } from "../../helpers/FormatUtils";
import { endOfToday, startOfToday, subDays } from "date-fns";
import AdminInternationOrderCountByCountryChartWrapper from "../../components/dashboard-core/AdminInternationOrderCountByCountryChartWrapper";
import AdminInternationalOrderCountByServiceTypeChartWrapper from "../../components/dashboard-core/AdminInternationalOrderCountByServiceTypeChartWrapper";
import InternationalDashboardFilterForm from "../../components/dashboard-core/InternationalDashboardFilterForm";
import AdminInternationalTotalChartWrapper from "../../components/dashboard-core/AdminInternationalTotalChartWrapper";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
);

AdminInternationalDashboardContainer.propTypes = {
  getLocalisationMessage: PropTypes.func,
};

function AdminInternationalDashboardContainer(props) {
  const { getLocalisationMessage } = props;

  const date = startOfToday();
  const date2 = endOfToday();

  const [filter, setFilter] = useState({
    from_date_time: formatDateTimeToUrl(subDays(date, 31)),
    to_date_time: formatDateTimeToUrl(subDays(date2, 1)),
  });

  return (
    <AdminAppLayout
      slug="international_dashboard"
      title={getLocalisationMessage(
        "international_dashboard",
        "International Dashboard",
      )}
    >
      <div style={{ marginTop: 5 }}>
        <AppTitle
          title={getLocalisationMessage(
            "international_dashboard",
            "International Dashboard",
          )}
        >
          <InternationalDashboardFilterForm
            international={true}
            initialValues={{
              fromDateTime: safeParseDate(filter.from_date_time),
              toDateTime: safeParseDate(filter.to_date_time),
            }}
            onSubmit={v =>
              setFilter({
                from_date_time: formatDateTimeToUrl(v.fromDateTime),
                to_date_time: formatDateTimeToUrl(v.toDateTime),
              })
            }
          />
        </AppTitle>
      </div>
      <FlexBox
        container={24}
        flex="none"
        direction="column"
        style={{ gap: 16 }}
      >
        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminInternationalTotalChartWrapper />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminInternationOrderCountByCountryChartWrapper filter={filter} />
          </FlexBox>
          <FlexBox flex={true} container={8}>
            <AdminInternationalOrderCountByServiceTypeChartWrapper
              title={getLocalisationMessage(
                "order_count_by_shipment_type",
                "Order Count by Shipment Type",
              )}
              filter={filter}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminInternationalDashboardContainer);
