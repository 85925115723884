import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toJobsFilter } from "../../helpers/JobsFilterMapper";
import { ORDER_JOBS_URL } from "../../constants/AdminPathConstants";
import { getJobs } from "../../api/admin/AdminJobsApi";
import AdminOrderFilterWrapper from "../../wrappers/admin/AdminOrderFilterWrapper";
import AdminOrderJobsItemDialogWrapper from "../../wrappers/admin/AdminOrderJobsItemDialogWrapper";
import AdminOrderJobCreateDialogWrapper from "../../wrappers/admin/AdminOrderJobCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AvatarWithName from "../../components/avatars/AvatarWithName";
import Text, { PRIMARY } from "../../components/ui-core/Text";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import FlagMultiLineCell from "../../components/data-list/FlagMultiLineCell";
import ProgressNumberCell from "../../components/data-list/ProgressNumberCell";
import OrderStatus from "../../components/orders-core/OrderStatus";
import OrderJobsTabs from "../../components/orders-core/OrderJobsTabs";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";

const NA = "N/A";

const ORDER_FILTER_DIALOG_HASH = "#OFDH";

const enhancer = compose(
  useSheet({
    headerCenter: { justifyContent: "center" },
    noDriver: { textAlign: "center", width: "100%" },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toJobsFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const jobsListResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getJobs(props.filter).catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update(
                "payload",
                fp.flow(
                  fp.get("data"),
                  fp.update("list", fp.toArray),
                  fp.update("total", fp.toFinite),
                ),
              ),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const batchGetOrderJobsCount = filters =>
          Observable.from(
            filters
              .map((filter, key) =>
                getJobs(filter)
                  .catch(error => Observable.of({ error }))
                  .map(fp.get("payload.data.total"))
                  .takeLast(1)
                  .delay(1000)
                  .map(value => ({ key, value })),
              )
              .toArray(),
          )
            .concatAll()
            .scan(
              (acc, response) => acc.set(response.key, response.value),
              Map(),
            )
            .startWith(Map());

        return propsStream
          .combineLatest(jobsListResponseStream, (props, jobsListResponse) => ({
            ...props,
            list: jobsListResponse.getIn(["payload", "list"]),
            total: jobsListResponse.getIn(["payload", "total"]),
            isLoading: jobsListResponse.get("pending"),
            batchGetOrderJobsCount,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminOrderJobsContainer.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  batchGetOrderJobsCount: PropTypes.func,
};

function AdminOrderJobsContainer(props) {
  const { location, classes } = props;

  return (
    <AdminAppLayout title="Jobs">
      <AdminOrderJobCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("create"))}
      />

      <AdminOrderJobsItemDialogWrapper
        jobId={
          fp.toFinite(location.query.view_order) > 0
            ? 0
            : fp.toFinite(location.query.view_job)
        }
        onClickDetails={id => updateQuery(location, fp.set("view_order", id))}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_job"))}
      />

      <AdminOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view_order)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view_order", "view_tab"]))
        }
        location={location}
      />

      <AdminOrderFilterWrapper
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);

          props.setLocationQueryFilter(filter);
        }}
        open={location.hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <OrderJobsTabs
        filter={props.filter}
        basePathname={ORDER_JOBS_URL}
        batchGetOrderJobsCount={props.batchGetOrderJobsCount}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={12}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            <Link to={updateQuery(location, fp.set("create", true))}>
              <Tooltip title="Create New Order Job">
                <IconButton>
                  <Add />
                </IconButton>
              </Tooltip>
            </Link>

            <Link to={updateHash(location, ORDER_FILTER_DIALOG_HASH)}>
              <Tooltip title="Filter Orders">
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>
          </div>
        }
      >
        <DataListColumn
          width={100}
          label="ID"
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Link
              to={updateQuery(
                location,
                fp.set("view_job", row.cellData.get("id")),
              )}
            >
              {row.cellData.get("id")}
            </Link>
          )}
        />

        <DataListColumn
          width={100}
          label="Type"
          dataKey="type"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => formatText(row.cellData.get("type")) || NA}
        />

        <DataListColumn
          width={180}
          label="Person"
          dataKey="customer"
          disableSort={true}
          headerClassName={classes.headerCenter}
          cellRenderer={row => (
            <AvatarWithName
              name={row.cellData.getIn(["customer", "name"])}
              imagePath={row.cellData.getIn(["customer", "photo"])}
            >
              {row.cellData.getIn(["customer", "name"])}
            </AvatarWithName>
          )}
        />

        <DataListColumn
          width={180}
          label="Address & Neighborhood"
          dataKey="neighborhood"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <FlagMultiLineCell
              countryCode={row.cellData.getIn(["country", "code"])}
              flagSize={36}
              firstLine={row.cellData.getIn(["neighborhood", "name"])}
              secondLine={row.cellData.get("address")}
            />
          )}
        />

        <DataListColumn
          width={180}
          label="Orders"
          dataKey="total_quantity"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row =>
            `${row.cellData.get("total_quantity") || 0} orders`
          }
        />

        <DataListColumn
          width={180}
          label="Status"
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <OrderStatus colored={true} status={row.cellData.get("status")} />
          )}
        />

        <DataListColumn
          width={180}
          label="Driver"
          dataKey="driver"
          disableSort={true}
          headerClassName={classes.headerCenter}
          cellRenderer={row => {
            const driverName = row.cellData.getIn(["driver", "name"]);

            if (driverName) {
              return (
                <AvatarWithName
                  name={driverName}
                  imagePath={row.cellData.getIn(["driver", "photo"])}
                >
                  {driverName}
                </AvatarWithName>
              );
            }

            return (
              <Text className={classes.noDriver} type={PRIMARY}>
                No Driver
              </Text>
            );
          }}
        />

        <DataListColumn
          width={94}
          label="Supplier Name"
          dataKey="supplier"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => row.cellData.getIn(["supplier", "name"]) || NA}
        />

        <DataListColumn
          width={100}
          label="Quantity"
          dataKey="total_quantity"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <ProgressNumberCell
              value={row.cellData.get("complete_quantity") || 0}
              total={row.cellData.get("total_quantity") || 0}
            />
          )}
        />

        <DataListColumn
          width={100}
          label="Service Charge"
          dataKey="total_service_charge"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <ProgressNumberCell
              value={row.cellData.get("completed_service_charge") || 0}
              total={row.cellData.get("total_service_charge") || 0}
              unit={row.cellData.getIn(["currency", "code"])}
            />
          )}
        />

        <DataListColumn
          width={100}
          label="Weight"
          dataKey="total_weight"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <ProgressNumberCell
              value={row.cellData.get("completed_weight") || 0}
              total={row.cellData.get("total_weight") || 0}
              unit="kg"
              valueWithUnit={true}
            />
          )}
        />

        <DataListColumn
          width={100}
          label="COD"
          dataKey="total_cod"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <ProgressNumberCell
              value={row.cellData.get("completed_cod") || 0}
              total={row.cellData.get("total_cod") || 0}
              unit={row.cellData.getIn(["currency", "code"])}
            />
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderJobsContainer);
