import React from "react";
import { compose, mapPropsStream } from "recompose";
import { DataPolygon, withMapInstance } from "react-google-map-components";
import { computeCenter } from "../../helpers/GeoUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMapsStream } from "../../helpers/GoogleMapsHelper";

const enhancer = compose(
  withMapInstance(),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const polygonCenterStream = propsStream
          .distinctUntilKeyChanged("geometry")
          .map(props => computeCenter(props.geometry[0]))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(polygonCenterStream, (props, labelPosition) => ({
            ...props,
            labelPosition,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const mapsStream = propsStream
          .switchMap(props =>
            getMapsStream().map(maps => {
              function CustomMarker(latLng, map) {
                this.latLng = new maps.LatLng(latLng.lat, latLng.lng);
                this.setMap(map);
              }

              CustomMarker.prototype = new maps.OverlayView();

              CustomMarker.prototype.draw = function() {
                let { div } = this;

                if (!this.div) {
                  // eslint-disable-next-line no-multi-assign
                  div = this.div = document.createElement("span");

                  div.className = "marker";

                  div.style.position = "absolute";
                  div.style.cursor = "pointer";
                  div.style.background = "#fff";
                  div.style.border = "1px solid";
                  div.style.padding = "2px";
                  div.innerHTML = props.label;

                  const panes = this.getPanes();
                  panes.overlayImage.appendChild(div);
                }

                const point = this.getProjection().fromLatLngToDivPixel(
                  this.latLng,
                );

                if (point) {
                  div.style.left = `${point.x - 10}px`;
                  div.style.top = `${point.y - 20}px`;
                }
              };

              return new CustomMarker(props.labelPosition, props.map);
            }),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(mapsStream, props => ({
            ...props,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

DataPolygonWrapper.propTypes = {};

function DataPolygonWrapper(props) {
  return <DataPolygon {...props} />;
}

export default enhancer(DataPolygonWrapper);
