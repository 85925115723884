import React, { useEffect, useState } from "react";
import { compose, getContext, mapPropsStream } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
  SUCCESS_MESSAGE,
} from "../../reducers/NotificationsReducer";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Map, OrderedSet } from "immutable";
import { InputAdornment, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import FormCustomToggle from "../form/FormCustomToggle";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import {
  BANDEROL,
  LETTER,
  M_BAG,
  PARCEL,
  SMALL_PACKETS,
} from "../../helpers/OrderOutboundSortingHelper";
import FormSelectField from "../form/FormSelectField";
import { formatText } from "../../helpers/FormatUtils";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import FormTextField from "../form/FormTextField";
import IconButton from "@material-ui/core/IconButton";
import { Add, ArrowBackIos, Close, Save } from "@material-ui/icons";
import { SAMPLE_ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import {
  getValue,
  isShallowEqual,
  kirilToLatinByKeyboardPlace,
} from "../../helpers/DataUtils";
import fp from "lodash/fp";
import SimpleOrderConfirmDialog from "./SimpleOrderConfirmDialog";
import {
  createByBatchIdSimpleOrder,
  createSimpleOrder,
} from "../../api/admin/AdminSimpleOrderApi";
import SimpleOrderProgressDialog from "./SimpleOrderProgressDialog";
import { api } from "../../api/shared/BaseApi";
import { BARCODE_VALIDATION_URL } from "./AdminPostForm";
import _ from "lodash";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import {
  createNotNullValidator,
  createObjectIdValidator,
  createPhoneEmptyValidator,
  createRegisteredBarcodeValidator,
  createValidateStringValidator,
} from "../../helpers/FormUtils";
import FormPhoneField from "../form/FormPhoneField";
import { validateString } from "../../helpers/ValidateUtils";

const serviceType = OrderedSet.of(
  LETTER,
  PARCEL,
  SMALL_PACKETS,
  BANDEROL,
  M_BAG,
);

const styles = makeStyles({
  root: {
    padding: "10px 24px",
    gap: 16,
    "@media (max-width: 1440px)": {
      "& .MuiCardContent-root:last-child": {
        paddingBottom: "none",
      },
      "& .MuiCardContent-root": {
        padding: "10px 16px",
      },
    },
  },
  orderType: {
    minHeight: 80,
    "@media (max-width: 1440px)": {
      minHeight: 60,
    },
  },
  card: {
    minHeight: 140,
    "@media (max-width: 1440px)": {
      minHeight: 110,
    },
  },
  title: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    textTransform: "capitalize",
  },
  cardTitle: {
    borderBottom: "1px solid #EEEEEE",
  },
  saveButton: {
    "& .MuiButton-containedSecondary": {
      backgroundColor: "#27AE60",
    },
  },
});

const OrderUnregisteredItems = props => {
  const { i18n } = props;

  const [barcodeErrorMessage, setBarcodeErrorMessage] = useState([]);
  const [barcode, setBarcode] = useState(null);
  const [findIndex, setFindIndex] = useState(null);
  const [findBarcode, setFindBarcode] = useState(null);
  const [jurisdiction, setJurisdiction] = useState([]);
  useEffect(() => {
    if (props.fields.getAll() && props.fields.getAll().length > 0) {
      const tempFields = [];
      // eslint-disable-next-line array-callback-return
      props.fields.getAll().map((field, index) => {
        tempFields[index] = field.recipient_data.jurisdiction;
      });
      setJurisdiction([...tempFields]);
    }
  }, [props.fields]);

  useEffect(() => {
    if (barcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(barcode);
        const tempBarcode =
          convertedValue !== barcode ? convertedValue : barcode;

        if (props.barcodes.includes(tempBarcode)) {
          const tempBarcodeErrorMessage = [...barcodeErrorMessage];
          tempBarcodeErrorMessage[findIndex] = i18n.get(
            "you_used_this_barcode",
            "You used this barcode",
          );

          setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
        } else {
          api
            .get(BARCODE_VALIDATION_URL, {
              params: { barcode: tempBarcode },
            })
            .then(res => {
              if (res.status !== SUCCESS_MESSAGE) {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = res.message;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              } else {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = null;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              }
            })
            .catch(error => {
              props.showErrorMessage(error);
            })
            .finally(
              () =>
                convertedValue !== barcode &&
                props.change(findBarcode, convertedValue),
            );
        }
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [barcode, findIndex]);

  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column" style={{ gap: 16 }}>
        {props.fields.map((field, fieldIndex) => (
          <FlexBox
            style={{
              gap: 24,
              padding: 16,
              border: "1px dashed rgba(0, 0, 0, 0.1)",
              borderRadius: 10,
            }}
            key={fieldIndex}
          >
            <FlexBox align={ALIGN_CENTER}>
              <FlexBox
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#27AE60",
                  borderRadius: 20,
                }}
                align={ALIGN_CENTER}
                justify={ALIGN_CENTER}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#FFFFFF",
                  }}
                >
                  {fieldIndex + 1}
                </span>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                immediatelyShowError={true}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                fullWidth={true}
                name={`${field}.recipient_data.jurisdiction`}
                label={`${i18n.get("jurisdiction", "Jurisdiction")} *`}
                onChange={value => {
                  const tempJur = [...jurisdiction];
                  tempJur[fieldIndex] = { ...value };
                  setJurisdiction(tempJur);
                }}
                validate={createObjectIdValidator(
                  i18n.get("this_field_is_required"),
                )}
                hintText={i18n.get("type_to_search_hint")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <PostcodeAutoCompleteWithJM
                name={`${field}.recipient_data.postcode`}
                fullWidth={true}
                jurisdictionId={
                  jurisdiction[fieldIndex] ? jurisdiction[fieldIndex].id : null
                }
                jurisdiction={jurisdiction[fieldIndex]}
                label={i18n.get("index", "Index")}
                hintText={i18n.get("type_to_search", "Type To Search...")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                name={`${field}.recipient_data.customer.name`}
                label={`${i18n.get("full_name", "Full Name")} *`}
                validate={createValidateStringValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormPhoneField
                disableDropdown={true}
                disableAreaCodes={true}
                name={`${field}.recipient_data.phone`}
                variant={OUTLINED}
                size="small"
                fullWidth={true}
                label={`${i18n.get("phone", "Phone")} *`}
                validate={createPhoneEmptyValidator(
                  i18n.get("invalid_phone_number", "Invalid phone number"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                name={`${field}.recipient_data.address`}
                label={`${i18n.get("address", "Address")} *`}
                validate={createValidateStringValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                name={`${field}.weight`}
                type="number"
                onFocus={event => {
                  event.target.select();
                }}
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
                validate={createNotNullValidator(
                  i18n.get("this_field_is_required"),
                )}
                label={`${i18n.get("weight_g", "Weight (g)")} *`}
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {i18n.get("g", "g")}
                    </InputAdornment>
                  ),
                }}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                name={`${field}.barcode`}
                label={i18n.get("barcode", "Barcode")}
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                        <path
                          fill="currentColor"
                          d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                onChange={(e, value) => {
                  setBarcode(value);
                  setFindIndex(fieldIndex);
                  setFindBarcode(`${field}.barcode`);
                }}
                validate={createRegisteredBarcodeValidator(
                  barcodeErrorMessage[fieldIndex],
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox align={ALIGN_CENTER}>
              <IconButton
                size="small"
                onClick={() => props.fields.remove(fieldIndex)}
              >
                <Close color="error" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

OrderUnregisteredItems.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  fields: PropTypes.array,
  barcodes: PropTypes.array,
  showErrorMessage: PropTypes.func,
  change: PropTypes.func,
};

const OrderRegisteredItems = props => {
  const { i18n } = props;

  const [barcodeErrorMessage, setBarcodeErrorMessage] = useState([]);
  const [barcode, setBarcode] = useState(null);
  const [findIndex, setFindIndex] = useState(null);
  const [findBarcode, setFindBarcode] = useState(null);
  const [jurisdiction, setJurisdiction] = useState([]);
  useEffect(() => {
    if (props.fields.getAll() && props.fields.getAll().length > 0) {
      const tempFields = [];
      // eslint-disable-next-line array-callback-return
      props.fields.getAll().map((field, index) => {
        tempFields[index] = field.recipient_data.jurisdiction;
      });
      setJurisdiction([...tempFields]);
    }
  }, [props.fields]);

  useEffect(() => {
    if (barcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(barcode);
        const tempBarcode =
          convertedValue !== barcode ? convertedValue : barcode;

        if (props.barcodes.includes(tempBarcode)) {
          const tempBarcodeErrorMessage = [...barcodeErrorMessage];
          tempBarcodeErrorMessage[findIndex] = i18n.get(
            "you_used_this_barcode",
            "You used this barcode",
          );

          setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
        } else {
          api
            .get(BARCODE_VALIDATION_URL, {
              params: { barcode: tempBarcode },
            })
            .then(res => {
              if (res.status !== SUCCESS_MESSAGE && res.code === "DUPLICATE") {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = res.message;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              } else {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = null;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              }
            })
            .catch(error => {
              props.showErrorMessage(error);
            })
            .finally(
              () =>
                convertedValue !== barcode &&
                props.change(findBarcode, convertedValue),
            );
        }
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [barcode, findIndex]);

  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column" style={{ gap: 16 }}>
        {props.fields.map((field, fieldIndex) => (
          <FlexBox
            style={{
              gap: 24,
              padding: 16,
              border: "1px dashed rgba(0, 0, 0, 0.1)",
              borderRadius: 10,
            }}
            key={fieldIndex}
          >
            <FlexBox align={ALIGN_CENTER}>
              <FlexBox
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#27AE60",
                  borderRadius: 20,
                }}
                align={ALIGN_CENTER}
                justify={ALIGN_CENTER}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#FFFFFF",
                  }}
                >
                  {fieldIndex + 1}
                </span>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                name={`${field}.barcode`}
                label={`${i18n.get("barcode", "Barcode")} *`}
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                        <path
                          fill="currentColor"
                          d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                onChange={(e, value) => {
                  setBarcode(value);
                  setFindIndex(fieldIndex);
                  setFindBarcode(`${field}.barcode`);
                }}
                validate={createRegisteredBarcodeValidator(
                  barcodeErrorMessage[fieldIndex],
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                immediatelyShowError={true}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                hintText={i18n.get("type_to_search_hint")}
                fullWidth={true}
                name={`${field}.sender_data.jurisdiction`}
                label={`${i18n.get(
                  "sender_jurisdiction",
                  "Sender Jurisdiction",
                )} *`}
                validate={createObjectIdValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                immediatelyShowError={true}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                hintText={i18n.get("type_to_search_hint")}
                fullWidth={true}
                name={`${field}.recipient_data.jurisdiction`}
                label={`${i18n.get(
                  "recipient_jurisdiction",
                  "Recipient Jurisdiction",
                )} *`}
                onChange={value => {
                  const tempJur = [...jurisdiction];
                  tempJur[fieldIndex] = { ...value };
                  setJurisdiction(tempJur);
                }}
                validate={createObjectIdValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <PostcodeAutoCompleteWithJM
                name={`${field}.recipient_data.postcode`}
                fullWidth={true}
                jurisdictionId={
                  jurisdiction[fieldIndex] ? jurisdiction[fieldIndex].id : null
                }
                jurisdiction={jurisdiction[fieldIndex]}
                label={i18n.get("index", "Index")}
                hintText={i18n.get("type_to_search", "Type To Search...")}
              />
            </FlexBox>
            <FlexBox align={ALIGN_CENTER}>
              <IconButton
                size="small"
                onClick={() => props.fields.remove(fieldIndex)}
              >
                <Close color="error" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

OrderRegisteredItems.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  fields: PropTypes.array,
  barcodes: PropTypes.array,
  showErrorMessage: PropTypes.func,
  change: PropTypes.func,
};

const getValues = getFormValues("AdminSimplePostForm");

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const handleSubmit = props => (values, change) => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values, change);
    };
    const initialValuesStream = propsStream
      .map(() => ({
        orders: [],
        unRegisteredOrders: [],
        senderJurisdiction: {},
        recipientJurisdiction: {},
        unRecipientJurisdiction: {},
      }))
      .startWith({});

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdminSimplePostForm",
    validate: (values, props) => ({
      serviceType:
        !values.orderType &&
        !values.serviceType &&
        props.i18n.get("this_field_is_required"),
      senderJurisdiction:
        !values.orderType &&
        !fp.get(["senderJurisdiction", "id"], values) &&
        props.i18n.get("this_field_is_required"),
      recipientJurisdiction:
        !values.orderType &&
        !fp.get(["recipientJurisdiction", "id"], values) &&
        props.i18n.get("this_field_is_required"),
      senderName:
        !values.orderType &&
        validateString(
          values.senderName,
          props.i18n.get("this_field_is_required"),
        ),
      recipientName:
        !values.orderType &&
        validateString(
          values.recipientName,
          props.i18n.get("this_field_is_required"),
        ),
      recipientPhone:
        !values.orderType && !values.recipientPhone
          ? props.i18n.get("this_field_is_required")
          : values.recipientPhone &&
            values.recipientPhone.length < 8 &&
            props.i18n.get("invalid_phone_number", "Invalid phone number"),
      recipientAddress:
        !values.orderType &&
        validateString(
          values.recipientAddress,
          props.i18n.get("this_field_is_required"),
        ),
      weight:
        !values.orderType &&
        !values.weight &&
        props.i18n.get("this_field_is_required"),
      barcode:
        !values.orderType && !values.barcode
          ? props.i18n.get("this_field_is_required")
          : values.barcode && _.get(values, "barcodeErrorMessage"),

      unSenderJurisdiction:
        values.orderType &&
        !fp.get(["unSenderJurisdiction", "id"], values) &&
        props.i18n.get("this_field_is_required"),
      unRecipientJurisdiction:
        values.orderType &&
        !fp.get(["unRecipientJurisdiction", "id"], values) &&
        props.i18n.get("this_field_is_required"),
      recipientBarcode:
        values.orderType && !values.recipientBarcode
          ? props.i18n.get("this_field_is_required")
          : values.recipientBarcode && _.get(values, "barcodeErrorMessage"),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
        orders: values.orders || [],
        unRegisteredOrders: values.unRegisteredOrders || [],
      },
    })),
  ),
);

function AdminSimplePostForm(props) {
  const { i18n, values } = props;

  const classes = styles();

  const {
    senderJurisdiction,
    recipientJurisdiction,
    unRecipientJurisdiction,
    orderType,
    barcode,
    recipientBarcode,
  } = values;
  const senderJurisdictionId = getValue(senderJurisdiction, "id");
  const recipientJurisdictionId = getValue(recipientJurisdiction, "id");
  const unRecipientJurisdictionId = getValue(unRecipientJurisdiction, "id");
  const [confirm, setConfirm] = useState(false);
  const [
    showErrorRecipientJurisdiction,
    setErrorRecipientJurisdiction,
  ] = useState(false);
  const [showErrorSenderJurisdiction, setErrorSenderJurisdiction] = useState(
    false,
  );
  const [
    showErrorUnRecipientJurisdiction,
    setErrorUnRecipientJurisdiction,
  ] = useState(false);
  const [
    showErrorUnSenderJurisdiction,
    setErrorUnSenderJurisdiction,
  ] = useState(false);

  const [firstStep, setFirstStep] = useState(false);
  const [secondStep, setSecondStep] = useState(null);

  useEffect(() => {
    if (!orderType) {
      if (barcode) {
        const delayDebounceFn = setTimeout(() => {
          const convertedValue = kirilToLatinByKeyboardPlace(barcode);
          const tempBarcode =
            convertedValue !== barcode ? convertedValue : barcode;
          const arrayBarcode =
            values.orders && values.orders.length
              ? values.orders.map(item => item.barcode)
              : [];
          if (arrayBarcode.includes(tempBarcode)) {
            props.change(
              "barcodeErrorMessage",
              i18n.get("you_used_this_barcode", "You used this barcode"),
            );
          } else {
            api
              .get(BARCODE_VALIDATION_URL, {
                params: { barcode: tempBarcode },
              })
              .then(res => {
                if (res.status !== SUCCESS_MESSAGE)
                  props.change("barcodeErrorMessage", res.message);
                else props.change("barcodeErrorMessage", false);
              })
              .catch(error => {
                props.showErrorMessage(error);
              })
              .finally(
                () =>
                  convertedValue !== barcode &&
                  props.change("barcode", convertedValue),
              );
          }
        }, 100);
        return () => clearTimeout(delayDebounceFn);
      }
    }
    return null;
  }, [barcode, orderType]);

  useEffect(() => {
    if (orderType) {
      if (recipientBarcode) {
        const delayDebounceFn = setTimeout(() => {
          const convertedValue = kirilToLatinByKeyboardPlace(recipientBarcode);
          const tempBarcode =
            convertedValue !== recipientBarcode
              ? convertedValue
              : recipientBarcode;
          const arrayBarcode =
            values.unRegisteredOrders && values.unRegisteredOrders.length
              ? values.unRegisteredOrders.map(item => item.barcode)
              : [];
          if (arrayBarcode.includes(tempBarcode)) {
            props.change(
              "barcodeErrorMessage",
              i18n.get("you_used_this_barcode", "You used this barcode"),
            );
          } else {
            api
              .get(BARCODE_VALIDATION_URL, {
                params: { barcode: tempBarcode },
              })
              .then(res => {
                if (res.status !== SUCCESS_MESSAGE && res.code === "DUPLICATE")
                  props.change("barcodeErrorMessage", res.message);
                else props.change("barcodeErrorMessage", false);
              })
              .catch(error => {
                props.showErrorMessage(error);
              })
              .finally(
                () =>
                  convertedValue !== recipientBarcode &&
                  props.change("recipientBarcode", convertedValue),
              );
          }
        }, 100);
        return () => clearTimeout(delayDebounceFn);
      }
    }
    return null;
  }, [recipientBarcode, orderType]);

  return (
    <form
      onSubmit={props.handleSubmit}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {confirm && (
        <ConfirmDialog
          confirmButtonLabel={i18n.get("yes", "Yes")}
          dismissButtonLabel={i18n.get("no", "No")}
          open={confirm}
          onConfirm={() => props.setLocation(SAMPLE_ORDER_LIST_URL)}
          onRequestClose={() => setConfirm(false)}
        >
          {i18n.get(
            "are_you_sure_you_want_to_exit",
            "Are you sure you want to exit?",
          )}
        </ConfirmDialog>
      )}
      {secondStep && (
        <SimpleOrderProgressDialog
          batchId={secondStep}
          open={Boolean(secondStep)}
          onRequestClose={() => {
            setSecondStep(null);
            setFirstStep(true);
          }}
          onSubmit={() => {
            if (secondStep) {
              createByBatchIdSimpleOrder(secondStep)
                .then(res => {
                  if (res && res.data) {
                    props.showSuccessMessage(
                      props.i18n.get("successfully_order_created"),
                    );
                    setSecondStep(res.data);
                    setFirstStep(false);
                  }
                })
                .catch(error => props.showErrorMessage(error));

              props.setLocation(
                `${SAMPLE_ORDER_LIST_URL}?batch_id=${secondStep}`,
              );
            }
          }}
          showErrorMessage={props.showErrorMessage}
          showSuccessMessage={props.showSuccessMessage}
        />
      )}

      {firstStep && (
        <SimpleOrderConfirmDialog
          open={firstStep}
          onRequestClose={() => setFirstStep(false)}
          orders={orderType ? values.unRegisteredOrders : values.orders}
          change={props.change}
          orderType={orderType}
          onSubmit={() =>
            createSimpleOrder(
              orderType ? values.unRegisteredOrders : values.orders,
            )
              .then(res => {
                if (res && res.data) {
                  props.showSuccessMessage(
                    props.i18n.get("successfully_order_created"),
                  );
                  setSecondStep(res.data);
                  setFirstStep(false);
                }
              })
              .catch(error => props.showErrorMessage(error))
          }
        />
      )}

      <FlexBox
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <FlexBox
          className={classes.root}
          direction="column"
          justify="space-between"
        >
          <Typography variant="h5" className={classes.title}>
            <IconButton
              style={{ width: 40, height: 40 }}
              onClick={() => setConfirm(true)}
            >
              <ArrowBackIos style={{ width: 20, height: 20 }} />
            </IconButton>

            {i18n.get("order_creation", "Order Creation")}
          </Typography>

          <Card className={classes.orderType}>
            <CardContent>
              <FlexBox style={{ gap: 16, padding: 5 }} align="center">
                <Typography variant="p">{i18n.get("type")}</Typography>
                <FlexBox>
                  <FormCustomToggle
                    name="orderType"
                    rightLabel={i18n.get("unregistered", "Unregistered")}
                    leftLabel={i18n.get("registered", "Registered")}
                  />
                </FlexBox>

                {!orderType && (
                  <FlexBox flex={true}>
                    <FormSelectField
                      style={{ width: 222 }}
                      name="serviceType"
                      options={serviceType}
                      formatOption={option =>
                        i18n.get(option.toLowerCase(), formatText(option))
                      }
                      label={`${i18n.get("shipment_type")} *`}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </CardContent>
          </Card>

          {!orderType && (
            <Card className={classes.card}>
              <CardContent className={classes.cardTitle}>
                <Typography variant="body1">
                  {i18n.get("sender_information", "Sender Information")}
                </Typography>
              </CardContent>
              <CardContent>
                <FlexBox style={{ gap: 16 }}>
                  <FlexBox flex={true}>
                    <FormJMAutoComplete
                      hintText={i18n.get("type_to_search_hint")}
                      immediatelyShowError={showErrorSenderJurisdiction}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{option.name}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {option.value.hierarchy.map((item, index, arr) =>
                              index === arr.length - 1
                                ? item.name
                                : `${item.name} > `,
                            )}
                          </span>
                        </FlexBox>
                      )}
                      fullWidth={true}
                      name="senderJurisdiction"
                      label={`${i18n.get("jurisdiction", "Jurisdiction")} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <PostcodeAutoCompleteWithJM
                      name="senderPostcode"
                      fullWidth={true}
                      jurisdictionId={senderJurisdictionId}
                      jurisdiction={senderJurisdiction}
                      label={i18n.get("index", "Index")}
                      hintText={i18n.get("type_to_search", "Type To Search...")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      name="senderName"
                      label={`${i18n.get("full_name", "Full Name")} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormPhoneField
                      disableDropdown={true}
                      disableAreaCodes={true}
                      name="senderPhone"
                      variant={OUTLINED}
                      size="small"
                      fullWidth={true}
                      label={i18n.get("phone", "Phone")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      name="senderAddress"
                      label={i18n.get("address", "Address")}
                    />
                  </FlexBox>
                  <FlexBox flex={true} />
                  <FlexBox flex={true} />
                </FlexBox>
              </CardContent>
            </Card>
          )}

          {!orderType && (
            <Card style={{ overflow: "auto" }}>
              <CardContent className={classes.cardTitle}>
                <Typography variant="body1">
                  {i18n.get("recipient_information", "Recipient Information")}
                </Typography>
              </CardContent>
              <CardContent>
                <FlexBox style={{ gap: 16 }} direction="column">
                  <FlexBox style={{ gap: 16 }}>
                    <FlexBox flex={true}>
                      <FormJMAutoComplete
                        hintText={i18n.get("type_to_search_hint")}
                        immediatelyShowError={showErrorRecipientJurisdiction}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>{option.name}</span>
                            <span
                              style={{
                                fontSize: ".8rem",
                                fontStyle: "italic",
                              }}
                            >
                              {option.value.hierarchy.map((item, index, arr) =>
                                index === arr.length - 1
                                  ? item.name
                                  : `${item.name} > `,
                              )}
                            </span>
                          </FlexBox>
                        )}
                        fullWidth={true}
                        name="recipientJurisdiction"
                        label={`${i18n.get("jurisdiction", "Jurisdiction")} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <PostcodeAutoCompleteWithJM
                        name="recipientPostcode"
                        fullWidth={true}
                        jurisdictionId={recipientJurisdictionId}
                        jurisdiction={recipientJurisdiction}
                        label={i18n.get("index", "Index")}
                        hintText={i18n.get(
                          "type_to_search",
                          "Type To Search...",
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        fullWidth={true}
                        name="recipientName"
                        label={`${i18n.get("full_name", "Full Name")} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormPhoneField
                        disableDropdown={true}
                        disableAreaCodes={true}
                        name="recipientPhone"
                        variant={OUTLINED}
                        size="small"
                        fullWidth={true}
                        label={`${i18n.get("phone", "Phone")} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        fullWidth={true}
                        name="recipientAddress"
                        label={`${i18n.get("address", "Address")} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="weight"
                        type="number"
                        onFocus={event => {
                          event.target.select();
                        }}
                        parse={value => {
                          if (value <= 0) {
                            return 0;
                          }

                          return String(Math.round(parseFloat(String(value))));
                        }}
                        label={`${i18n.get("weight_g", "Weight (g)")} *`}
                        fullWidth={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {i18n.get("g", "g")}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="barcode"
                        label={`${i18n.get("barcode", "Barcode")} *`}
                        fullWidth={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <svg
                                style={{ width: "1.5rem", height: "1.5rem" }}
                              >
                                <path
                                  fill="currentColor"
                                  d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                                />
                              </svg>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    flex={true}
                    justify="flex-end"
                    style={{ gap: 16, marginTop: 16 }}
                  >
                    <CustomButton
                      variant={OUTLINED}
                      color={SECONDARY}
                      style={{ width: 130 }}
                      onClick={() => {
                        props.change("recipientJurisdiction", {});
                        props.change("recipientName", "");
                        props.change("recipientPhone", "+");
                        props.change("recipientAddress", "");
                        props.change("recipientPostcode", {});
                        props.change("weight", "");
                        props.change("barcode", "");
                      }}
                    >
                      {i18n.get("clear", "Clear")}
                    </CustomButton>
                    <CustomButton
                      type={props.valid ? "button" : "submit"}
                      variant={CONTAINED}
                      color={SECONDARY}
                      startIcon={<Add />}
                      style={{ width: 130 }}
                      onClick={() => {
                        if (props.valid) {
                          props.change("orders", [
                            ...values.orders,
                            {
                              service_type: {
                                code: values.serviceType,
                              },
                              sender_data: {
                                jurisdiction: values.senderJurisdiction,
                                customer: {
                                  name: values.senderName,
                                },
                                phone: values.senderPhone,
                                address: values.senderAddress,
                                postcode: values.senderPostcode,
                              },
                              recipient_data: {
                                jurisdiction: values.recipientJurisdiction,
                                customer: {
                                  name: values.recipientName,
                                },
                                phone: values.recipientPhone,
                                address: values.recipientAddress,
                                postcode: values.recipientPostcode,
                              },
                              weight: values.weight,
                              barcode: values.barcode,
                              registered: true,
                            },
                          ]);
                          props.change("recipientJurisdiction", {});
                          props.change("recipientName", "");
                          props.change("recipientPhone", "+");
                          props.change("recipientAddress", "");
                          props.change("recipientPostcode", {});
                          props.change("weight", "");
                          props.change("barcode", "");
                        } else {
                          setErrorSenderJurisdiction(true);
                          setErrorRecipientJurisdiction(true);
                        }
                      }}
                    >
                      {i18n.get("add", "Add")}
                    </CustomButton>
                  </FlexBox>

                  <FlexBox>
                    <FieldArray
                      name="orders"
                      component={OrderUnregisteredItems}
                      props={{
                        i18n,
                        change: props.change,
                        showErrorMessage: props.showErrorMessage,
                        barcodes:
                          props.values.orders && props.values.orders.length > 0
                            ? props.values.orders.map(order => order.barcode)
                            : [],
                      }}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          )}

          {orderType && (
            <Card style={{ overflow: "auto" }}>
              <CardContent className={classes.cardTitle}>
                <Typography variant="body1">
                  {i18n.get("order_information", "Order Information")}
                </Typography>
              </CardContent>
              <CardContent>
                <FlexBox style={{ gap: 16 }} direction="column">
                  <FlexBox style={{ gap: 16 }}>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="recipientBarcode"
                        label={`${i18n.get("barcode", "Barcode")} *`}
                        fullWidth={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <svg
                                style={{ width: "1.5rem", height: "1.5rem" }}
                              >
                                <path
                                  fill="currentColor"
                                  d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                                />
                              </svg>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormJMAutoComplete
                        immediatelyShowError={showErrorUnSenderJurisdiction}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>{option.name}</span>
                            <span
                              style={{
                                fontSize: ".8rem",
                                fontStyle: "italic",
                              }}
                            >
                              {option.value.hierarchy.map((item, index, arr) =>
                                index === arr.length - 1
                                  ? item.name
                                  : `${item.name} > `,
                              )}
                            </span>
                          </FlexBox>
                        )}
                        fullWidth={true}
                        name="unSenderJurisdiction"
                        label={`${i18n.get(
                          "sender_jurisdiction",
                          "Sender Jurisdiction",
                        )} *`}
                        hintText={i18n.get("type_to_search_hint")}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormJMAutoComplete
                        immediatelyShowError={showErrorUnRecipientJurisdiction}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>{option.name}</span>
                            <span
                              style={{
                                fontSize: ".8rem",
                                fontStyle: "italic",
                              }}
                            >
                              {option.value.hierarchy.map((item, index, arr) =>
                                index === arr.length - 1
                                  ? item.name
                                  : `${item.name} > `,
                              )}
                            </span>
                          </FlexBox>
                        )}
                        fullWidth={true}
                        name="unRecipientJurisdiction"
                        label={`${i18n.get(
                          "recipient_jurisdiction",
                          "Recipient Jurisdiction",
                        )} *`}
                        hintText={i18n.get("type_to_search_hint")}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <PostcodeAutoCompleteWithJM
                        name="unRecipientPostIndex"
                        fullWidth={true}
                        jurisdictionId={unRecipientJurisdictionId}
                        jurisdiction={unRecipientJurisdiction}
                        label={i18n.get("index", "Index")}
                        hintText={i18n.get(
                          "type_to_search",
                          "Type To Search...",
                        )}
                      />
                    </FlexBox>

                    <FlexBox
                      flex={true}
                      justify="flex-end"
                      style={{ gap: 16, width: 280 }}
                    >
                      <CustomButton
                        variant={OUTLINED}
                        color={SECONDARY}
                        style={{ minWidth: 130, maxWidth: 130, height: "40px" }}
                        onClick={() => {
                          props.change("unSenderJurisdiction", {});
                          props.change("unServiceType", null);
                          props.change("unRecipientJurisdiction", {});
                          props.change("unRecipientPostIndex", {});
                          props.change("recipientBarcode", "");
                        }}
                      >
                        {i18n.get("clear", "Clear")}
                      </CustomButton>
                      <CustomButton
                        type={props.valid ? "button" : "submit"}
                        variant={CONTAINED}
                        color={SECONDARY}
                        startIcon={<Add />}
                        style={{ minWidth: 130, maxWidth: 130, height: "40px" }}
                        onClick={() => {
                          if (props.valid) {
                            props.change("unRegisteredOrders", [
                              ...values.unRegisteredOrders,
                              {
                                service_type: {
                                  code: SMALL_PACKETS,
                                },
                                sender_data: {
                                  jurisdiction: values.unSenderJurisdiction,
                                },
                                recipient_data: {
                                  jurisdiction: values.unRecipientJurisdiction,
                                  postcode: values.unRecipientPostIndex,
                                },
                                barcode: values.recipientBarcode,
                                registered: false,
                              },
                            ]);
                            props.change("unRecipientJurisdiction", {});
                            props.change("unRecipientPostIndex", {});
                            props.change("recipientBarcode", "");
                          } else {
                            setErrorUnSenderJurisdiction(true);
                            setErrorUnRecipientJurisdiction(true);
                          }
                        }}
                      >
                        {i18n.get("add", "Add")}
                      </CustomButton>
                    </FlexBox>
                  </FlexBox>

                  <FlexBox>
                    <FieldArray
                      name="unRegisteredOrders"
                      component={OrderRegisteredItems}
                      props={{
                        i18n,
                        change: props.change,
                        showErrorMessage: props.showErrorMessage,
                        barcodes:
                          props.values.unRegisteredOrders &&
                          props.values.unRegisteredOrders.length > 0
                            ? props.values.unRegisteredOrders.map(
                                order => order.barcode,
                              )
                            : [],
                      }}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          )}
        </FlexBox>
      </FlexBox>

      <Card style={{ bottom: 0, zIndex: 1000 }}>
        <CardContent>
          <FlexBox
            justify="flex-end"
            className={
              orderType
                ? values.unRegisteredOrders &&
                  values.unRegisteredOrders.length > 0
                  ? classes.saveButton
                  : ""
                : values.orders && values.orders.length > 0
                ? classes.saveButton
                : ""
            }
          >
            <CustomButton
              disabled={
                !(orderType
                  ? values.unRegisteredOrders &&
                    values.unRegisteredOrders.length > 0
                  : values.orders && values.orders.length > 0)
              }
              variant={CONTAINED}
              color={SECONDARY}
              style={{ width: 246, height: 40 }}
              startIcon={<Save />}
              onClick={() => setFirstStep(true)}
            >
              {i18n.get("save", "Save")}
            </CustomButton>
          </FlexBox>
        </CardContent>
      </Card>
    </form>
  );
}

AdminSimplePostForm.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  orderType: PropTypes.bool,
  setLocation: PropTypes.func,
  change: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  values: PropTypes.object,
  valid: PropTypes.bool,
};

export default enhancer(AdminSimplePostForm);
