import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import {
  createNotEmptyValidator,
  createNotFalsyValidator,
} from "../../helpers/FormUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "500px",
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func.isRequired,
      getDriverPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  reduxForm({ form: "BatchUpdateOrderPickupDriverDialog" }),
);

BatchUpdateOrderPickupDriverDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getCachedDriver: PropTypes.func.isRequired,
  getDriverPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function BatchUpdateOrderPickupDriverDialog(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <Dialog open={props.open} maxWidth="auto">
      <PageLoading isLoading={props.submitting} />
      <DialogContent className={classes.paper}>
        <form>
          <FormChipInput
            name="orderNumbers"
            focus={true}
            fullWidth={true}
            label={getLocalisationMessage("order_numbers", "Order Numbers")}
            validate={createNotEmptyValidator(
              getLocalisationMessage(
                "add_at_least_one_order",
                "Add At Least One Order",
              ),
            )}
          />

          <FormDriverAutoComplete
            name="driver"
            fullWidth={true}
            label={getLocalisationMessage("driver", "Driver")}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_driver", "Select Driver"),
            )}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type to search ...",
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button primary={true} onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchUpdateOrderPickupDriverDialog);
