import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const WHATSAPP_PROVIDER_LIST_URL = `${API_ROOT_URL}/admin/whatsapp_providers`;
const WHATSAPP_PROVIDER_URL = `${API_ROOT_URL}/admin/whatsapp_provider`;
const WHATSAPP_PROVIDER_FIELDS_URL = `${
  API_ROOT_URL
}/admin/whatsapp_provider_fields`;

export const getWhatsappProviderList = () =>
  api.getStream(WHATSAPP_PROVIDER_LIST_URL);

export const getWhatsappProvider = messengerType =>
  api.getStream(WHATSAPP_PROVIDER_URL, {
    params: {
      messengerType,
    },
  });

export const saveWhatsappProvider = (messengerType, body) =>
  api.post(WHATSAPP_PROVIDER_URL, {
    body,
    params: { messengerType },
  });

export const getWhatsappProviderFields = params =>
  api.getStream(WHATSAPP_PROVIDER_FIELDS_URL, { params });
