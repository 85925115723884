import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Add as ContentAdd,
  FilterList as ContentFilterList,
} from "@material-ui/icons";
import { mapListResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderExceptionsFilter } from "../../helpers/OrderExceptionsFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { RESOLVED, UNRESOLVED } from "../../constants/ExceptionsConstants";
import { getOrderExceptions } from "../../api/admin/AdminOrderApi";
import AdminOrderExceptionsCreateDialogWrapper from "../../wrappers/admin/AdminOrderExceptionsCreateDialogWrapper";
import AdminOrderExceptionsFilterDialogWrapper from "../../wrappers/admin/AdminOrderExceptionsFilterDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AvatarWithName from "../../components/avatars/AvatarWithName";
import Text, {
  MUTED,
  ACTUAL,
  DANGER,
  SUCCESS,
} from "../../components/ui-core/Text";
import PopoverOverlay from "../../components/ui-core/PopoverOverlay";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const NA = "N/A";

const ORDER_EXCEPTIONS_CREATE_DIALOG_HASH = "#OECDH";
const ORDER_EXCEPTIONS_FILTER_DIALOG_HASH = "#OEFDH";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .map(fp.flow(fp.get("location.query"), toOrderExceptionsFilter))
            .distinctUntilChanged(isEqualData),
          (props, filter) => ({
            ...props,
            filter,
          }),
        ),
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const orderExceptionsListStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getOrderExceptions(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .let(mapListResponseStream)
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          orderExceptionsListStream,
          (props, orderExceptionsList) => ({
            ...props,
            onRequestRefresh,
            isLoading: orderExceptionsList.get("pending"),
            list: orderExceptionsList.getIn(["payload", "list"]),
            total: orderExceptionsList.getIn(["payload", "total"]),
          }),
        );
      },
    ),
  ),
);

AdminOrderExceptionsContainer.propTypes = {
  location: PropTypes.object,

  list: PropTypes.instanceOf(Immutable.List),
  total: PropTypes.number,
  isLoading: PropTypes.bool,

  onRequestRefresh: PropTypes.func,

  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),

  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderExceptionsContainer(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="order_exceptions"
      title={getLocalisationMessage("exceptions") || "Exceptions"}
    >
      <AdminOrderExceptionsCreateDialogWrapper
        open={location.hash === ORDER_EXCEPTIONS_CREATE_DIALOG_HASH}
        onRequestClose={() => {
          props.replaceLocationHash(null);
          props.onRequestRefresh();
        }}
      />

      <AdminOrderExceptionsFilterDialogWrapper
        open={location.hash === ORDER_EXCEPTIONS_FILTER_DIALOG_HASH}
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        onRequestClose={() => {
          props.replaceLocationHash(null);
        }}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={8}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Tooltip
                title={getLocalisationMessage("new_exception", "New Exception")}
              >
                <IconButton
                  onClick={() =>
                    props.replaceLocationHash(
                      ORDER_EXCEPTIONS_CREATE_DIALOG_HASH,
                    )
                  }
                >
                  <ContentAdd />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip
              title={getLocalisationMessage(
                "filter_exception",
                "Filter Exception",
              )}
            >
              <IconButton
                onClick={() =>
                  props.replaceLocationHash(ORDER_EXCEPTIONS_FILTER_DIALOG_HASH)
                }
              >
                <ContentFilterList />
              </IconButton>
            </Tooltip>
          </div>
        }
      >
        <DataListColumn
          width={94}
          label={getLocalisationMessage("created_date", "Created Date")}
          dataKey="created_date"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row =>
            formatDate(row.cellData.get("created_date")) ||
            getLocalisationMessage("na", NA)
          }
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("created_by", "Created by")}
          dataKey="created_by"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row =>
            formatText(row.cellData.get("created_by")) ||
            getLocalisationMessage("na", NA)
          }
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("order_number", "Order Number")}
          dataKey="order_code"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => row.cellData.get("order_code")}
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("order_status", "Order Status")}
          dataKey="order_status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row =>
            getLocalisationMessage(
              row.cellData.get("order_status"),
              formatText(row.cellData.get("order_status")),
            ) || getLocalisationMessage("na", NA)
          }
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("exception_type", "Exception Type")}
          dataKey="type"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row =>
            getLocalisationMessage(
              row.cellData.get("type"),
              formatText(row.cellData.get("type")),
            )
          }
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("driver", "Driver")}
          dataKey="driver"
          disableSort={true}
          cellRenderer={row => {
            const driverName = row.cellData.getIn(["driver", "name"]);

            if (driverName) {
              return (
                <AvatarWithName
                  name={driverName}
                  imagePath={row.cellData.getIn(["driver", "photo"])}
                >
                  {driverName}
                </AvatarWithName>
              );
            }

            return getLocalisationMessage("na", NA);
          }}
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("courier", "Courier")}
          dataKey="supplier"
          disableSort={true}
          cellRenderer={row => {
            const supplierName = row.cellData.getIn(["supplier", "name"]);

            if (supplierName) {
              return (
                <AvatarWithName
                  name={supplierName}
                  imagePath={row.cellData.getIn(["supplier", "photo"])}
                >
                  {supplierName}
                </AvatarWithName>
              );
            }

            return getLocalisationMessage("na", NA);
          }}
        />

        <DataListColumn
          width={250}
          label={getLocalisationMessage("note", "Note")}
          dataKey="note"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <PopoverOverlay
              width={350}
              header="Note"
              content={row.cellData.get("note") || NA}
            >
              <span>{row.cellData.get("note")}</span>
            </PopoverOverlay>
          )}
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("exception_status", "Exception Status")}
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => {
            const status = row.cellData.get("status");

            return (
              <Text
                type={
                  status === RESOLVED
                    ? SUCCESS
                    : status === UNRESOLVED
                    ? ACTUAL
                    : DANGER
                }
                fallbackType={MUTED}
                fallbackValue={NA}
              >
                {getLocalisationMessage(status, formatText(status))}
              </Text>
            );
          }}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderExceptionsContainer);
