import React from "react";
import { List, OrderedMap, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { mapListResponseStream } from "../../../helpers/ApiUtils";
import { isEqualData } from "../../../helpers/DataUtils";
import { formatText } from "../../../helpers/FormatUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { toPricePlansFilter } from "../../../helpers/PricePlansFilterMapper";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  SETTINGS_PRICING_PLANS_CREATE_URL,
  SETTINGS_PRICING_PLANS_ITEM_URL,
} from "../../../constants/AdminPathConstants";
import { getPricingPlansList } from "../../../api/admin/AdminSubscriptionApi";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import Redirect from "../../../components/router/Redirect";
import PriceWrapper from "../../../components/ui-core/PriceWrapper";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../../../components/data-list/DataList";
import { updateQuery } from "../../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocationQueryFilter: PropTypes.func }),
  mapPropsStream(propsStream => {
    const {
      handler: onRowSelect,
      stream: onRowSelectStream,
    } = createEventHandler();
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const selectedItemsStream = onRowSelectStream
      .distinctUntilChanged(isEqualData)
      .startWith(OrderedSet());

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toPricePlansFilter))
      .distinctUntilChanged(isEqualData);

    const pricingPlansListResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        getPricingPlansList(filter)
          .let(mapListResponseStream)
          .repeatWhen(() => onRequestRefreshStream),
      );

    return propsStream.combineLatest(
      filterStream,
      pricingPlansListResponseStream,
      selectedItemsStream,
      (props, filter, pricingPlanList, selectedItems) => ({
        ...props,
        filter,
        selectedItems,
        onRowSelect,
        onRequestRefresh,

        isLoading: pricingPlanList.get("pending", false),
        list: pricingPlanList.getIn(["payload", "list"], List()),
        total: pricingPlanList.getIn(["payload", "total"], 0),
      }),
    );
  }),
);

AdminPricingPlansListContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  setLocationQueryFilter: PropTypes.func,
  location: PropTypes.object,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),

  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedSet),
};

function AdminPricingPlansListContainer(props) {
  const { selectedItems, getLocalisationMessage, location } = props;

  const searchTypes = OrderedMap()
    .set("MONTHLY")
    .set("YEARLY");

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "pricing_plans",
        "Subscription Pricing Plans",
      )}
    >
      <Redirect
        when={!searchTypes.has(location.query.plan_period)}
        to={updateQuery(location, fp.flow(fp.set("plan_period", "MONTHLY")))}
      />

      <DataList
        isLoading={props.isLoading}
        selectedRowCount={selectedItems.size}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={7}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={props.setLocationQueryFilter}
        cardActionIcons={
          <Tooltip
            title={getLocalisationMessage(
              "create_new_price_plan",
              "Create Pricing Plan",
            )}
          >
            <Link to={SETTINGS_PRICING_PLANS_CREATE_URL}>
              <IconButton>
                <Add />
              </IconButton>
            </Link>
          </Tooltip>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            selectedItems.size > 0 && selectedItems.size === props.list.size
          }
          onAllRowsSelect={select =>
            props.onRowSelect(
              !select
                ? OrderedSet()
                : props.list.toOrderedSet().map(x => x.get("id")),
            )
          }
          rowSelected={row => selectedItems.has(row.cellData.get("id"))}
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? selectedItems.add(row.cellData.get("id"))
                : selectedItems.delete(row.cellData.get("id")),
            )
          }
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Link to={SETTINGS_PRICING_PLANS_ITEM_URL + row.cellData.get("id")}>
              {row.cellData.get("id")}
            </Link>
          )}
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("title", "Name")}
          dataKey="title"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("title") || getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("monthly_price", "Monthly Price")}
          dataKey="monthly_price"
          disableSort={true}
          cellRenderer={row =>
            <PriceWrapper price={row.cellData.get("monthly_price")} /> ||
            getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("yearly_price", "Yearly Price")}
          dataKey="yearly_price"
          disableSort={true}
          cellRenderer={row =>
            <PriceWrapper price={row.cellData.get("yearly_price")} /> ||
            getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage(
            "overlimit_price_per_order",
            "OverLimit Price Per Order",
          )}
          dataKey="overlimit_price_per_order"
          disableSort={true}
          cellRenderer={row =>
            (
              <PriceWrapper
                price={row.cellData.get("overlimit_price_per_order")}
              />
            ) || getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("monthly_includes", "Monthly includes")}
          dataKey="monthly_includes"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("monthly_includes") ||
            getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("yearly_includes", "Yearly includes")}
          dataKey="yearly_includes"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("yearly_includes") ||
            getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          cellRenderer={row =>
            fp.toUpper(
              getLocalisationMessage(
                row.cellData.get("status"),
                formatText(row.cellData.get("status")),
              ),
            ) || getLocalisationMessage("na", "N/A")
          }
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminPricingPlansListContainer);
