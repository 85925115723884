import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { TERMS_SETTINGS_URL } from "../../constants/AdminPathConstants";
import {
  updateTermsSettings,
  getTermsSettingsItem,
} from "../../api/admin/AdminTermsApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import TermsSettingsForm from "../../components/terms-settings/TermsSettingForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  useSheet({
    paper: { minWidth: "600px", maxWidth: "900px" },
  }),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const IdStream = propsStream.map(
          fp.flow(fp.get("params.id"), fp.toFinite),
        );

        return propsStream
          .combineLatest(IdStream, (props, termsId) => ({
            ...props,
            termsId,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const responseTermsStream = propsStream
          .distinctUntilKeyChanged("termsId", isEqualData)
          .switchMap(props =>
            getTermsSettingsItem(props.termsId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(responseTermsStream, (props, response) => ({
            ...props,
            item: response.get("payload", Map()),
            isLoading: response.get("pending", false),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminTermsEditDialogWrapper.propTypes = {
  item: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,

  termsId: PropTypes.number.isRequired,
  i18n: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
};

function AdminTermsEditDialogWrapper(props) {
  const { i18n } = props;

  return (
    <AdminAppLayout title={`${props.item.get("name")}`}>
      <PageLoading isLoading={props.isLoading} />
      <TermsSettingsForm
        initialValues={toCamelCase(props.item)}
        onSubmit={values =>
          updateTermsSettings(toSnakeCase(values)).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            i18n.get("edited_terms_settings", "Edited Terms Settings"),
          );
          props.setLocation(updateQuery(TERMS_SETTINGS_URL));
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminTermsEditDialogWrapper);
