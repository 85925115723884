import React from "react";
import PropTypes from "prop-types";
import JurisdictionTreeViewItem from "./JurisdictionTreeViewItem";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { compose } from "recompose";
import JurisdictionTreeModel from "./JurisdictionTreeModel";

export const JurisdictionActionType = Object.freeze({
  create: "create",
  edit: "edit",
  cancel: "cancel",
});

const useStyles = makeStyles({
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
});

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

const treeModel = new JurisdictionTreeModel();

/* eslint-enable */
const JurisdictionView = props => {
  const [model, setModel] = React.useState();
  const [openedForm, setOpenedForm] = React.useState({});

  React.useEffect(() => {
    treeModel.setOnModelChange(newModel => {
      setModel(newModel);
    });
    treeModel.setOnSelect(selected => {
      if (selected) {
        if (!(selected || []).length) {
          props.onSelect(null);
        } else {
          props.onSelect(selected[selected.length - 1]);
        }
      }
    });

    treeModel.fetchJurisdictionsList();
    // treeModel.fetchCountryList();
  }, []);

  const classes = useStyles();

  const done = item => action => {
    setOpenedForm({});
    switch (action) {
      case JurisdictionActionType.create:
        treeModel.fetchJurisdictionsList(item.id);
        break;
      case JurisdictionActionType.edit:
        treeModel.fetchJurisdictionsList(item.parent_id);
        break;
      case JurisdictionActionType.cancel:
      default:
        break;
    }
  };

  const renderItems = (list = []) =>
    list
      .filter(
        v =>
          v.name.toLowerCase().indexOf(props.search.toLowerCase()) > -1 ||
          v.level_name !== "country",
      )
      .map((child, index) => (
        <JurisdictionTreeViewItem
          key={index}
          selectedChildren={
            treeModel.hasSelected(child) ||
            (treeModel.hasChildren(child) && treeModel.hasInSelection(child)) ||
            treeModel.isInRestChildren(child)
          }
          selectedThrough={
            treeModel.isThrough(child) || treeModel.isInRestChildren(child)
          }
          selectedNode={
            treeModel.hasSelected(child) ||
            (treeModel.hasChildren(child) && treeModel.hasInSelection(child)) ||
            treeModel.isInRestChildren(child)
          }
          isLast={Boolean(index === list.length - 1)}
          hasChildren={treeModel.hasChildren(child)}
          showExpand={treeModel.hasChildren(child)}
          title={child.name}
          description={child.level_name}
          node={child.level_id}
          onExpand={() => {
            const children = treeModel.getChildren(child.id);
            if (!children) {
              treeModel.fetchJurisdictionsList(child.id, () => {
                treeModel.toggleExpansion(child);
              });
            } else {
              treeModel.toggleExpansion(child);
            }
          }}
          expanded={treeModel.isExpanded(child)}
          content={<div>{renderItems(treeModel.getChildren(child.id))}</div>}
          isLoading={model && model.fetchingId === child.id}
          onClick={() => {
            if (treeModel.hasSelected(child)) {
              treeModel.unselect(child);
            } else {
              treeModel.select(child);
            }
            setOpenedForm({});
          }}
          showEdit={treeModel.hasSelected(child)}
          showAddButton={!openedForm[child.id] && treeModel.hasSelected(child)}
          onEdit={() => {
            treeModel.select(child);
            if (props.onEdit) {
              props.onEdit(child);
            }
            setOpenedForm({
              [child.id]: props.formForJurisdictionEdit(child, done(child)),
            });
          }}
          selected={treeModel.hasSelected(child)}
          form={openedForm[child.id]}
          onAddClick={() => {
            treeModel.select(child);
            if (props.onCreate) props.onCreate();
            setOpenedForm({
              [child.id]: props.formForJurisdictionLevel(child, done(child)),
            });
          }}
        />
      ));

  if (model && model.isCountryLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress variant="indeterminate" />
      </div>
    );
  }

  return <div>{renderItems(treeModel.getChildren())}</div>;
};

JurisdictionView.propTypes = {
  onSelect: PropTypes.func,
  search: PropTypes.string,
  formForJurisdictionLevel: PropTypes.func,
  formForJurisdictionEdit: PropTypes.func,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
};

export default enhancer(JurisdictionView);
