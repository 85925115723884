import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import { makeStyles } from "@material-ui/core";
import { renderIf } from "../../helpers/HOCUtils";
import PropTypes from "prop-types";
import BatchHistoryUpdateFilter from "./BatchHistoryUpdateFilter";

const useStyles = makeStyles({
  paper: {
    width: "600px",
    minWidth: "600px !important",
    maxWidth: "600px !important",
    minHeight: "auto !important",
  },
});

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

BatchHistoryUpdateFilterDialog.propTypes = {
  isLoading: PropTypes.bool,

  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

function BatchHistoryUpdateFilterDialog(props) {
  const { getLocalisationMessage } = props;

  const classes = useStyles();

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
      title={`${getLocalisationMessage("filter", "Filter")}`}
    >
      <BatchHistoryUpdateFilter
        initialValues={props.initialValues}
        onFilterChange={props.onFilterChange}
      />
    </ModalPaper>
  );
}

export default enhancer(BatchHistoryUpdateFilterDialog);
