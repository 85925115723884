import React from "react";
import { Map } from "immutable";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedCity,
  getCityPredictions,
} from "../../api/shared/CountryV2Api";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderFormV2WithoutMap from "../../components/orders-core/OrderFormV2WithoutMap";
import { getOrder, updateOrder } from "../../api/v2/admin/AdminOrderApi";
import { getPaymentMethods } from "../../api/v2/admin/AdminCustomerApi";
import { getTimeSlots } from "../../api/v2/admin/AdminTimeslotApi";

const enhancer = compose(
  renderIf(props => props.orderId > 0),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const requestRefresh = createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        getOrder(props.orderId)
          .let(mapObjectResponseStream)
          .repeatWhen(() => requestRefresh.stream),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        item: response.get("payload"),
        isLoading: response.get("pending"),
        onRequestRefresh: requestRefresh.handler,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminOrderEditDialogWrapperV2WithoutMap.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,

  orderId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  isReverseLogisticEnabled: PropTypes.bool,
};

function AdminOrderEditDialogWrapperV2WithoutMap(props) {
  const { i18n } = props;
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={
        props.item.get("id") > 0 &&
        `${i18n.get("edit_order", "Edit Order")} #${props.item.get(
          "order_number",
        )}`
      }
    >
      <FlexBox direction="column">
        <OrderFormV2WithoutMap
          isReverseLogisticEnabled={props.isReverseLogisticEnabled}
          orderStatusCodes={OrderStatusCodes}
          order={props.item}
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            updateOrder(props.item.get("id"), values).catch(ResponseError.throw)
          }
          onSubmitFail={error => {
            if (props.onSubmitFail) {
              props.onSubmitFail(error);
            } else {
              props.showErrorMessage(error);
            }
          }}
          onSubmitSuccess={() => {
            props.onRequestClose();

            props.showSuccessMessage(
              i18n.get("successfully_updated", "Successfully Updated"),
            );
          }}
          getTimeSlots={getTimeSlots}
          getPaymentMethods={getPaymentMethods}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedSupplier={getCachedSupplier}
          getCustomerPredictions={getCustomerPredictions}
          getCachedCustomer={getCachedCustomer}
          getSupplierPredictions={getSupplierPredictions}
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderEditDialogWrapperV2WithoutMap);
