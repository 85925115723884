import React from "react";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = getContext({
  getCachedTimeSlot: PropTypes.func.isRequired,
  getTimeSlotPredictions: PropTypes.func.isRequired,
});

FormTimeSlotChips.propTypes = {
  getCachedTimeSlot: PropTypes.func,
  getTimeSlotPredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  timeslotType: PropTypes.string.isRequired,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
};

function FormTimeSlotChips({
  getCachedTimeSlot,
  getTimeSlotPredictions,
  timeslotType,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={getCachedTimeSlot}
      getPredictions={searchText =>
        getTimeSlotPredictions(
          baseFilter
            .setSearch(searchText)
            .setValue("timeslotType", timeslotType),
        )
      }
    />
  );
}

export default enhancer(FormTimeSlotChips);
