import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { types, mapSchema } from "../../shared/helpers/ObjectMapper";

export const toPricePlansFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    plan_period: types.string,
    shipment: types.ID,
  }),
);
