import React from "react";
import { List } from "immutable";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormMarketplaceAutoComplete from "../form/FormMarketplaceAutoComplete";
import FlexBox, { JUSTIFY_SPACE_BETWEEN } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  withContext(
    {
      types: PropTypes.instanceOf(List),
      getMarketplaceCache: PropTypes.func,
      getMarketplacePredictions: PropTypes.func,
    },
    props => ({
      types: props.types,
      getMarketplaceCache: props.getMarketplaceCache,
      getMarketplacePredictions: props.getMarketplacePredictions,
    }),
  ),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      attributes: [],
      getLocalisationMessage,
    };
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdminHtmlPdfTemplateForm",
    validate: values => ({
      name: !values.name && "Enter name",
      content: !values.content && "Enter html content",
      templateType: !values.templateType && "Select Template Type",
      marketplaceId:
        !isValidObjectId(values.marketplaceId) && "Select Marketplace",
    }),
  }),
);

AdminHtmlPdfTemplateForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  getLocalisationMessage: PropTypes.func.isRequired,

  types: PropTypes.instanceOf(List),
  getMarketplace: PropTypes.func,
  onDelete: PropTypes.func,
  initialValues: PropTypes.object,
  getMarketplaceCache: PropTypes.func,
  getMarketplacePredictions: PropTypes.func,
};

function AdminHtmlPdfTemplateForm(props) {
  const { types, getLocalisationMessage } = props;
  return (
    <form autoComplete="off" onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox
        justify="center"
        style={{ width: "100%" }}
        gutter={8}
        flex={true}
      >
        <FlexBox>
          <Card>
            <CardContent>
              <FlexBox flex={true}>
                <FormMarketplaceAutoComplete
                  name="marketplaceId"
                  fullWidth={true}
                  label="Marketplace"
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormSelectField
                  name="templateType"
                  fullWidth={true}
                  autoWidth={true}
                  options={types}
                  formatOption={value => formatText(value)}
                  label="Template Type"
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField name="name" fullWidth={true} label="Name" />
              </FlexBox>
              <FlexBox flex={true} container={8}>
                <FormTextField name="width" fullWidth={true} label="Width" />
              </FlexBox>
              <FlexBox flex={true} container={8}>
                <FormTextField name="height" fullWidth={true} label="Height" />
              </FlexBox>
              <FlexBox flex={true} container={8}>
                <FormTextField
                  name="marginTop"
                  fullWidth={true}
                  label="margin_top"
                />
              </FlexBox>
              <FlexBox flex={true} container={8}>
                <FormTextField
                  name="marginRight"
                  fullWidth={true}
                  label="margin_right"
                />
              </FlexBox>
              <FlexBox flex={true} container={8}>
                <FormTextField
                  name="marginBottom"
                  fullWidth={true}
                  label="margin_bottom"
                />
              </FlexBox>
              <FlexBox flex={true} container={8}>
                <FormTextField
                  name="marginLeft"
                  fullWidth={true}
                  label="margin_left"
                />
              </FlexBox>
            </CardContent>
            <CardActions>
              <FlexBox flex={true} justify={JUSTIFY_SPACE_BETWEEN}>
                {Boolean(props.initialValues && props.initialValues.id) && (
                  <Button
                    variant="text"
                    onClick={() => props.onDelete(props.initialValues.id)}
                    type="button"
                    secondary={true}
                  >
                    {getLocalisationMessage("delete", "Delete")}
                  </Button>
                )}
                <Button type="submit">
                  {getLocalisationMessage("submit", "Submit")}
                </Button>
              </FlexBox>
            </CardActions>
          </Card>
        </FlexBox>
        <FlexBox style={{ width: "83%" }} flex={true} direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true}>
                  <FormTextField
                    rows={21}
                    rowsMax={21}
                    fullWidth={true}
                    multiLine={true}
                    name="content"
                    label="Content"
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(AdminHtmlPdfTemplateForm);
