import React from "react";
import { fromJS, List, Map, OrderedMap, Set } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withState } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Avatar, Chip, Icon, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import {
  amber,
  blue,
  blueGrey,
  indigo,
  lightGreen,
  orange,
  red,
} from "@material-ui/core/colors";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import { isEqualData } from "../../helpers/DataUtils";
import {
  FAILED_BIN,
  INCOMPLETE_BIN,
  UNASSIGNED_BIN,
} from "../../helpers/OrderSortingHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { accent1, success1 } from "../../../shared/theme/main-theme";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  BANDEROL,
  COURIER_SERVICE,
  COURIER_SERVICE_WITHOUT_BAG,
  LETTER,
  LETTER_VALUED,
  ONE_STEP,
  ONE_STEP_WITHOUT_BAG,
  PARCEL,
  POST_PACKET,
  SEKOGRAMM,
  SMALL_PACKETS,
} from "../../helpers/OrderOutboundSortingHelper";
import { INSURED_BAG } from "../orders-core/BatchUpdateOrderDialog2";
import { toSnakeCase } from "../../helpers/CaseMapper";
import allTypes from "../../assets/orderTypes/all.png";
import letter from "../../assets/orderTypes/letter.png";
import banderol from "../../assets/orderTypes/banderol.png";
import smallParcel from "../../assets/orderTypes/small-parcel.png";
import parcel from "../../assets/orderTypes/parcel.png";
import courier from "../../assets/orderTypes/courier.png";
import oneStep from "../../assets/orderTypes/one-step.png";
import cekogram from "../../assets/orderTypes/cekogram.png";
import undefinedPic from "../../assets/placeholder.png";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import useSheet from "react-jss";
import { wordsTo2 } from "../../helpers/FormatUtils";
import { pureComponent } from "../../helpers/HOCUtils";

const ALL_TAB = "all";
const MIXED_TAB = "mixed";
const CURRENT_TAB = "current";
const POST_PACKET_TAB = "post_packet";

const chipGroups = fromJS({
  [MIXED_TAB]: {
    children: "X",
  },
  [POST_PACKET_TAB]: {
    children: "P",
    backgroundColor: amber[100],
    color: "white",
  },
});
const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    tabs: {
      margin: "0 -1rem",
    },
    chipWrapper: {
      marginTop: ".5rem",
    },
    chip: {
      marginRight: 5,
      marginBottom: 5,
    },
    chipAccent: { backgroundColor: accent1 },
    chipSuccess: { backgroundColor: success1 },
    chipAvatar: { backgroundColor: blueGrey[100] },
    chipPostPackage: { backgroundColor: blue[600], color: "white" },
    chipReadyPostPackage: { backgroundColor: orange[500], color: "white" },
    chipInsuredBag: { backgroundColor: blue[500], color: "white" },
    mixedTab: { backgroundColor: blueGrey[500] },
    currentTab: { backgroundColor: indigo[500] },
    saver: { backgroundColor: blue[500] },
    nextDay: { backgroundColor: lightGreen[500] },
    sameDay: { backgroundColor: amber[500] },
    urgent: { backgroundColor: red[500] },
    tab: {
      backgroundColor: "white",
      width: 182,
      height: 100,
      margin: "0 1rem",
      borderRadius: "1rem",
      cursor: "pointer",
    },
    activeTab: {
      backgroundColor: "#3F51B5",
      color: "white",
    },
  }),
  withState("state", "setState", { tab: ALL_TAB }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const tabsStream = propsStream
          .distinctUntilKeyChanged("filterTabs", isEqualData)
          .map(props =>
            Map().withMutations(tabs => {
              props.filterTabs.forEach(chip => {
                const tab = chip.get("key");
                const addOrders = fp.add(chip.get("orders").size);

                tabs.setIn([tab, "label"], chip.get("label"));
                tabs.setIn([tab, "orders"], chip.get("orders"));
                tabs.updateIn([tab, "count"], addOrders);
                tabs.updateIn([ALL_TAB, "count"], addOrders);
                tabs.setIn([ALL_TAB, "label"], ALL_TAB);
              });
            }),
          )
          .distinctUntilChanged(isEqualData)
          .map(tabs =>
            tabs
              .filter(x => x.get("count") > 0)
              .map((x, tab) =>
                x.merge({
                  tab,
                  label: x.get("label"),
                }),
              )
              .toOrderedMap()
              .sort((a, b) => {
                const tabTypes = [ALL_TAB, CURRENT_TAB, MIXED_TAB];

                for (let i = 0; i < tabTypes.length; i++) {
                  const tab = tabTypes[i];

                  if (a.get("tab") === tab) {
                    return -1;
                  }

                  if (b.get("tab") === tab) {
                    return 1;
                  }
                }

                if (!a.get("tab") || !b.get("tab")) {
                  return -1;
                }

                return a.get("tab").localeCompare(b.get("tab"));
              }),
          );

        return propsStream.combineLatest(tabsStream, (props, tabs) => ({
          ...props,
          tabs,
        }));
      },
      propsStream => {
        const filterChipsStream = propsStream
          .map(fp.pick(["state", "filterChips", "tabs"]))
          .distinctUntilChanged(isEqualData)
          .map(
            fp.flow(props =>
              List().withMutations(chips => {
                const { tab } = props.state;
                const { tabs } = props;
                const allTab = tab === ALL_TAB;

                props.filterChips.forEach(chip => {
                  const courierTypes = chip.get("courierTypes", Set());

                  if (
                    allTab ||
                    Boolean(
                      tabs && tabs.get(tab, null) && courierTypes.includes(tab),
                    )
                  ) {
                    chips.push(
                      chip.merge({
                        orders: chip.get("orders"),
                      }),
                    );
                  }
                });
              }),
            ),
          );

        return propsStream.combineLatest(
          filterChipsStream,
          (props, filterChips) => ({ ...props, filterChips }),
        );
      },
    ),
  ),
  pureComponent(fp.pick(["tabs", "state", "groups", "filterChips"])),
);

OrderSortingChips.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  tabs: PropTypes.instanceOf(OrderedMap),

  onSelectOrders: PropTypes.func.isRequired,
  onDeselectOrders: PropTypes.func.isRequired,

  groups: PropTypes.instanceOf(List).isRequired,
  bins: PropTypes.instanceOf(List).isRequired,
  filterChips: PropTypes.instanceOf(List).isRequired,
  filterTabs: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderSortingChips(props) {
  const { tabs, state, classes, getLocalisationMessage } = props;

  return (
    <React.Fragment>
      <FlexBox flex={true} style={{ margin: "1rem 0" }}>
        {tabs
          .map(x => (
            <Paper
              key={x.get("tab")}
              elevation={3}
              className={`${classes.tab} ${state.tab === x.get("tab") &&
                classes.activeTab}`}
              onClick={() => props.setState(fp.set("tab", x.get("tab")))}
            >
              <FlexBox
                style={{ height: "100%", justifyContent: "space-evenly" }}
                direction="column"
                align={ALIGN_CENTER}
              >
                <img
                  style={{
                    height: 35,
                    objectFit: "contain",
                  }}
                  alt="asd"
                  src={
                    x.get("tab") === ALL_TAB
                      ? allTypes
                      : x.get("tab") === LETTER
                      ? letter
                      : x.get("tab") === LETTER_VALUED
                      ? letter
                      : x.get("tab") === SMALL_PACKETS
                      ? smallParcel
                      : x.get("tab") === BANDEROL
                      ? banderol
                      : x.get("tab") === SEKOGRAMM
                      ? cekogram
                      : x.get("tab") === PARCEL
                      ? parcel
                      : x.get("tab") === COURIER_SERVICE_WITHOUT_BAG ||
                        x.get("tab") === COURIER_SERVICE
                      ? courier
                      : x.get("tab") === ONE_STEP ||
                        x.get("tab") === ONE_STEP_WITHOUT_BAG
                      ? oneStep
                      : undefinedPic
                  }
                />
                <FlexBox>
                  {getLocalisationMessage(
                    fp.snakeCase(x.get("label", ALL_TAB)),
                    x.get("label", ALL_TAB),
                  )}
                  : {x.get("count")}
                </FlexBox>
              </FlexBox>
            </Paper>
          ))
          .toArray()}
      </FlexBox>

      <div className={classes.chipWrapper}>
        {props.filterChips
          .sortBy(x =>
            x.get("label") === UNASSIGNED_BIN ||
            x.get("label") === FAILED_BIN ||
            x.get("label") === INCOMPLETE_BIN
              ? 1
              : 0,
          )
          .map((chip, index) => {
            const orders = chip.get("orders");
            const selectedAny = chip.get("selected") > 0;
            const selectedAll = chip.get("selected") === orders.size;
            const courierTypes = chip.get("courierTypes", Set());

            const group = chip.get("group");
            const avatarProps = !chipGroups.has(group)
              ? chipGroups.get(MIXED_TAB)
              : chipGroups.get(group);

            const readyForDirect =
              chip.get("group") === POST_PACKET_TAB ||
              courierTypes.includes(fp.toUpper(POST_PACKET));
            const insuredBag = courierTypes.includes(fp.toUpper(INSURED_BAG));
            const binIcon = readyForDirect
              ? "dynamic_feed"
              : insuredBag
              ? "gpp_good"
              : "mail_outline";
            return (
              <TooltipOverlay
                touch={true}
                label={`${getLocalisationMessage("shipments", "Shipments")}: ${
                  orders.size
                }`}
                key={`${chip.get("group")}-${index}`}
              >
                <Chip
                  className={cx(classes.chip, {
                    [classes.chipSuccess]: selectedAll,
                    [classes.chipAccent]: selectedAny && !selectedAll,
                  })}
                  onClick={() => {
                    if (selectedAll) {
                      props.onDeselectOrders(orders);
                    } else {
                      props.onSelectOrders(orders, chip);
                    }
                  }}
                  avatar={
                    <Avatar
                      {...avatarProps.toJS()}
                      className={cx(classes.chipAvatar, {
                        [classes.chipReadyPostPackage]: readyForDirect,
                        [classes.chipInsuredBag]: insuredBag,
                      })}
                      size={32}
                    >
                      {binIcon && <Icon fontSize="small">{binIcon}</Icon>}
                    </Avatar>
                  }
                  label={`${getLocalisationMessage(
                    wordsTo2(chip.get("label")),
                    chip.get("label"),
                  )}
                  (${orders.size})`}
                />
              </TooltipOverlay>
            );
          })}
      </div>

      {props.groups.size > 0 && (
        <div className={classes.chipWrapper}>
          {props.groups.map((chip, index) => {
            const orders = chip.get("orders");
            const selectedAny = chip.get("selected") > 0;
            const selectedAll = chip.get("selected") === orders.size;
            return (
              <Chip
                key={index}
                className={cx(classes.chip, {
                  [classes.chipSuccess]: selectedAll,
                  [classes.chipAccent]: selectedAny && !selectedAll,
                })}
                onClick={() => {
                  if (selectedAll) {
                    props.onDeselectOrders(orders);
                  } else {
                    props.onSelectOrders(orders);
                  }
                }}
                label={`${getLocalisationMessage(
                  toSnakeCase(chip.get("label").toLocaleLowerCase()),
                  chip.get("label"),
                )} (${orders.size})`}
              />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}

export default enhancer(OrderSortingChips);
