import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Tab as MUITab, Tabs as MUITabs } from "@material-ui/core";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router";
import SubscriptionPlanSlider from "./SubscriptionPlanSlider";
import SubscriptionPlanTabItem from "./SubscriptionPlanTabItem";
import SubscriptionPlanPriceBoxes from "./SubscriptionPlanPriceBoxes";
import { renderIf } from "../../helpers/HOCUtils";
import { isEmpty, isEqualData } from "../../helpers/DataUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { getMarketplace } from "../../api/shared/MarketplaceApi";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
    marketplace: getMarketplace(state),
  })),
  renderIf(fp.flow(fp.get("plans"), fp.negate(isEmpty))),
  withRouter,
  mapPropsStream(propsStream =>
    propsStream
      .map(props => ({
        ...props,
        value: props.filter.get("tab"),
        tabs: props.plans.map(plan => {
          const { code, title, icon } = plan;
          const link = props.router.createHref({
            pathname: props.basePathname,
            query: { ...props.filter.toJS(), tab: code },
          });
          return Map({
            ...plan,
            label: fp.startCase(title),
            link,
            value: code,
            price: plan.price,
            includes: plan.includes,
            image: icon,
          });
        }),
      }))
      .distinctUntilChanged(isEqualData),
  ),
  withContext(
    {
      selectedValue: PropTypes.string,
    },
    props => ({
      selectedValue: props.value,
    }),
  ),
);

SubscriptionPlanTabs.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(Map),
  plans: PropTypes.instanceOf(List),
  tabs: PropTypes.instanceOf(List),
  value: PropTypes.string,
  basePathname: PropTypes.string.isRequired,
};
const minWidth = 1090;

function SubscriptionPlanTabs(props) {
  const isSelectedFn = fp.eq(props.value);
  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      <SubscriptionTabs {...props} />
      <SubscriptionPlanSlider
        tabs={props.tabs}
        minWidth={minWidth}
        selectedIndex={props.tabs.findIndex(
          fp.flow(tab => tab.get("value"), fp.eq(props.value)),
        )}
      />
      <SubscriptionPlanPriceBoxes
        filter={props.filter}
        tabs={props.tabs}
        minWidth={minWidth}
        isSelectedFn={isSelectedFn}
      />
    </div>
  );
}

export default enhancer(SubscriptionPlanTabs);

const tabEnhancer = compose(
  mapPropsStream(propsStream =>
    propsStream.map(
      fp.update("tabs", tabs =>
        tabs.reduce((acc, x) => {
          const icon = x.get("icon_dto");
          acc.push({
            value: x.get("value"),
            buttonStyle: { height: "auto", borderList: "1px  solid white" },
            // purchased: { backgroundColor: "rgb(156, 0, 8)" },
            containerElement: (!x.get("disabled") && (
              <Link to={x.get("link")} />
            )) || <div />,
            label: (
              <SubscriptionPlanTabItem
                key={x.get("value")}
                value={x.get("value")}
                disabled={x.get("disabled")}
                label={x.get("label")}
                image={icon ? icon.url : ""}
              />
            ),
          });

          return acc;
        }, []),
      ),
    ),
  ),
);

const getTabProps = fp.pick(["value", "onChange"]);

const SubscriptionTabs = tabEnhancer(props => (
  <MUITabs
    {...getTabProps(props)}
    style={{
      minWidth,
    }}
    inkBarStyle={{ height: 3, marginTop: "-3px" }}
    tabItemContainerStyle={{ color: "red" }}
  >
    {props.subscribedPlan &&
      props.tabs.map((item, index) => (
        <MUITab
          {...item}
          label={{
            ...item.label,
            props: {
              ...item.label.props,
              subscribedPlan: props.subscribedPlan,
            },
          }}
          buttonStyle={{ ...item.buttonStyle }}
          style={{ fontSize: "12px", ...item.style }}
          key={fp.isUndefined(item.key) ? index : item.key}
        />
      ))}
  </MUITabs>
));

SubscriptionTabs.propTypes = {
  tabs: PropTypes.instanceOf(List).isRequired,
  value: PropTypes.string,
  classes: PropTypes.object,
};
