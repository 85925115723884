// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-bar {
    position: relative;
    margin: 0 auto;
    height: 30px;
    width: 100%;
    border-radius: 50px;
}

.success-bar {
    position: relative;
    margin: 0 auto;
    height: 30px;
    width: 100%;
    border-radius: 50px;
}

.span {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;

    border-radius: 50px;
    background-image: linear-gradient(to bottom, #6191c1, #85b6e7 25%);
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

.span:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    border-radius: 20px 8px 8px 20px;
    overflow: hidden;
}

.loading-bar > .span:after, .animate > .span > .span {
    animation: load 1s infinite;
}

@keyframes load {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}`, "",{"version":3,"sources":["webpack://./src/client/theme/fonts/blogger-sans/loading.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,WAAW;;IAEX,mBAAmB;IACnB,kEAAkE;IAClE;uCACmC;IACnC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,SAAS;IACT,QAAQ;IACR;;;;;;;;;KASC;IACD,UAAU;IACV,0BAA0B;IAC1B,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,8BAA8B;IAClC;AACJ","sourcesContent":[".loading-bar {\n    position: relative;\n    margin: 0 auto;\n    height: 30px;\n    width: 100%;\n    border-radius: 50px;\n}\n\n.success-bar {\n    position: relative;\n    margin: 0 auto;\n    height: 30px;\n    width: 100%;\n    border-radius: 50px;\n}\n\n.span {\n    display: block;\n    position: relative;\n    height: 100%;\n    width: 100%;\n\n    border-radius: 50px;\n    background-image: linear-gradient(to bottom, #6191c1, #85b6e7 25%);\n    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),\n    inset 0 -2px 6px rgba(0, 0, 0, 0.4);\n    overflow: hidden;\n}\n\n.span:after {\n    content: \"\";\n    position: absolute;\n    top: 0px;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background-image: linear-gradient(\n            -45deg,\n            rgba(255, 255, 255, .2) 25%,\n            transparent 25%,\n            transparent 50%,\n            rgba(255, 255, 255, .2) 50%,\n            rgba(255, 255, 255, .2) 75%,\n            transparent 75%,\n            transparent\n    );\n    z-index: 1;\n    background-size: 50px 50px;\n    border-radius: 20px 8px 8px 20px;\n    overflow: hidden;\n}\n\n.loading-bar > .span:after, .animate > .span > .span {\n    animation: load 1s infinite;\n}\n\n@keyframes load {\n    0% {\n        background-position: 0 0;\n    }\n    100% {\n        background-position: 50px 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
