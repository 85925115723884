import React from "react";
import { Map, OrderedMap } from "immutable";
import PropTypes from "prop-types";
import FinanceOrderTableTabs from "../finance-core/FinanceOrderTableTabs";
import { formatCODStatusCode } from "../../helpers/OrderHelper";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_WITH_WING,
  COD_TRANSFERRED,
  COD_NOT_ASSIGNED,
  COD_WITH_SAAS_BANK,
  COD_WITH_WING_BANK,
  COD_TO_BE_COLLECTED,
  COD_PAID_TO_MERCHANT,
  COD_TRANSFER_REJECTED,
  COD_WITHDRAW_REJECTED,
  COD_WITHDRAW_REQUESTED,
  ALL_STATUSES_IN_FINANCE,
} from "../../constants/CODStatus";

export const ALL_TAB_STATUS_LIST = [
  ALL_STATUSES_IN_FINANCE,
  COD_TO_BE_COLLECTED,
  COD_NOT_ASSIGNED,
  COD_COLLECTED,
  COD_WITH_WING_BANK,
  COD_WITH_SAAS_BANK,
  COD_WITH_WING,
  COD_PAID_TO_MERCHANT,
  COD_TRANSFERRED,
  COD_WITHDRAW_REQUESTED,
  COD_WITHDRAW_REJECTED,
  COD_PENDING,
  COD_TRANSFER_REJECTED,
];

export const statusCodes = OrderedMap()
  .set(COD_TO_BE_COLLECTED, COD_TO_BE_COLLECTED)
  .set(COD_COLLECTED, COD_COLLECTED)
  .set(COD_PENDING, formatCODStatusCode(COD_PENDING))
  .set(COD_WITH_WING, COD_WITH_WING)
  .set(COD_WITH_WING_BANK, formatCODStatusCode(COD_WITH_WING_BANK))
  .set(COD_WITH_SAAS_BANK, formatCODStatusCode(COD_PAID_TO_MERCHANT))
  .set(COD_PAID_TO_MERCHANT, formatCODStatusCode(COD_PAID_TO_MERCHANT));

AdminFinanceOrderListTabs.propTypes = {
  location: PropTypes.object.isRequired,
  totalCounts: PropTypes.instanceOf(Map),
  tabStatusesCodes: PropTypes.instanceOf(OrderedMap),
};

function AdminFinanceOrderListTabs(props) {
  return props.tabStatusesCodes.size > 0 ? (
    <FinanceOrderTableTabs
      location={props.location}
      statusCodes={props.tabStatusesCodes}
      totalCounts={props.totalCounts}
      formatCODStatusCode={code => props.tabStatusesCodes.get(code)}
    />
  ) : (
    <div />
  );
}

export default AdminFinanceOrderListTabs;
