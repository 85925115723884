import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Tab, Tabs, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { indigo } from "@material-ui/core/colors";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import TabBadge from "../deprecated/TabBadge";
import { getMessages } from "../../reducers/LocalizationReducer";

import {
  ALL_TAB,
  BUSY_TAB,
  AVAILABLE_TAB,
} from "./DriversRadarDriversListLive";

const styles = {
  tabsInkBar: {
    zIndex: 2,
    height: "4px",
    marginTop: "-4px",
    backgroundColor: indigo[500],
  },
};

const enhancer = compose(
  connect((state) => ({
    i18n: getMessages(state),
  })),
  useSheet({
    tabs: {
      width: "100%",
    },
    loader: {
      height: "48px",
      backgroundColor: "transparent",
      margin: "auto",
    },
    hidden: {
      top: "-9999px",
      left: "-9999px",
      position: "absolute",
      visibility: "hidden",
    },
    buttonStyle: {
      backgroundColor: "white",
      color: "black",
      borderBottom: "1px solid #eee",
      "& > div > div": {
        position: "relative",
        paddingRight: "10px",
        "& > div": {
          "& span": {
            position: "absolute",
            top: "10px",
            backgroundColor: indigo[500],
            color: "white",
          },
        },
      },
    },
    active: {
      borderBottom: `3px solid ${indigo[500]}`,
    },
  }),
  withRouter,
);

RadarLiveDriverFilterTabs.propTypes = {
  classes: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),

  driversList: PropTypes.instanceOf(Map),
  onChangeTab: PropTypes.func,
  isLoading: PropTypes.bool,
};

function RadarLiveDriverFilterTabs(props) {
  const { classes } = props;

  if (props.isLoading)
    return (
      <FlexBox flex={true}>
        <FlexBox
          align="center"
          justify={JUSTIFY_CENTER}
          className={classes.loader}
        >
          <CircularProgress color="#2E3359" size={24} thickness={2} />
        </FlexBox>
      </FlexBox>
    );

  return (
    <FlexBox flex={true}>
      <FlexBox flex={true}>
        <Tabs
          className={classes.tabs}
          inkBarStyle={styles.tabsInkBar}
          onChange={(x) => props.onChangeTab(x)}
        >
          <Tab
            key="1"
            value={ALL_TAB}
            label={
              <TabBadge
                label={props.i18n.get("all", "ALL")}
                badge={props.driversList.get("all").size}
              />
            }
            className={classes.buttonStyle}
            buttonStyle={{
              lineHeight: "24px",
            }}
          />
          <Tab
            key="2"
            value={BUSY_TAB}
            label={
              <TabBadge
                label={props.i18n.get("busy", "BUSY")}
                badge={props.driversList.get("busy").size}
              />
            }
            className={classes.buttonStyle}
            buttonStyle={{
              lineHeight: "24px",
            }}
          />
          <Tab
            key="2"
            value={AVAILABLE_TAB}
            label={
              <TabBadge
                label={props.i18n.get("available", "AVAILABLE")}
                badge={props.driversList.get("available").size}
              />
            }
            className={classes.buttonStyle}
            buttonStyle={{
              lineHeight: "24px",
            }}
          />
        </Tabs>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(RadarLiveDriverFilterTabs);
