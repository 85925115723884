import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { CloudDownload } from "@material-ui/icons";
import FormFileField from "../form/FormFileField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { createFileValidator } from "../../helpers/FormUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { CREATE_VEHICLE_TEMPLATE_URL } from "../../../shared/constants/FileProxyControllerConstants";

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    root: {
      overflow: "hidden",
    },
  }),
  reduxForm({ form: "UploadVehicleForm" }),
);

UploadVehicleForm.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  loading: PropTypes.bool,
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

function UploadVehicleForm(props) {
  const { i18n, loading, onDismiss, classes } = props;

  return (
    <FlexBox
      container={8}
      direction="column"
      className={classes.root}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <FlexBox flex={true} direction="column">
        <Card>
          <CardContent>
            <PageLoading isLoading={loading} />
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormFileField
                  name="file"
                  fullWidth={true}
                  accept={CSV_FILE_TYPES}
                  label={i18n.get("select_file", "Select File")}
                  validate={createFileValidator(
                    i18n.get("please_select_file", "Please Select File"),
                  )}
                />
              </FlexBox>
              <FlexBox align="center">
                <Tooltip
                  title={i18n.get("download_template", "Download Template")}
                >
                  <IconButton
                    // target="_blank"
                    component="a"
                    href={CREATE_VEHICLE_TEMPLATE_URL}
                  >
                    <CloudDownload />
                  </IconButton>
                </Tooltip>
              </FlexBox>
            </FlexBox>
          </CardContent>
          <CardActions>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} justify="flex-end">
                <Button onClick={onDismiss}>
                  {i18n.get("dismiss", "Dismiss")}
                </Button>
                <Button type="submit">{i18n.get("submit", "Submit")}</Button>
              </FlexBox>
            </FlexBox>
          </CardActions>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(UploadVehicleForm);
