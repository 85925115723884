import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import { isValidObjectId } from "../../helpers/ValidateUtils";

const valueSelector = formValueSelector("BatchRedirectOrdersDialog");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      orderBarcodes: valueSelector(state, "orderBarcodes"),
      jurisdiction: valueSelector(state, "jurisdiction"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => ({ orderBarcodes, jurisdiction, index, ...values }) => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit({
        ...values,
        barcodes: orderBarcodes,
        index: fp.get("name", index),
      });
    },
  }),
  reduxForm({
    form: "BatchRedirectOrdersDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderBarcodes:
        fp.isEmpty(values.orderBarcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_orders")) ||
          "Select Orders"),
      jurisdiction:
        !isValidObjectId(values.jurisdiction) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_jurisdiction")) ||
          "Select Jurisdiction"),
      index:
        !isValidObjectId(values.index) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("enter_postcode")) ||
          "Enter Postcode"),
    }),
  }),
);

BatchRedirectOrdersDialog.propTypes = {
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,

  jurisdiction: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function BatchRedirectOrdersDialog(props) {
  const { getLocalisationMessage, classes, jurisdiction } = props;

  const jurisdictionId = fp.get("id", jurisdiction);

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
          minHeight: 200,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {getLocalisationMessage(
          "batch_redirect_orders",
          "Batch Redirect Orders",
        )}
      </DialogTitle>
      <form onSubmit={props.handleSubmit}>
        <DialogContent className={classes.paper}>
          {props.isLoading ? (
            <FlexBox justify="center" align="center">
              <CircularProgress size={60} color="secondary" />
            </FlexBox>
          ) : (
            <FlexBox container={8} direction="column">
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true}>
                  <FormChipInput
                    name="orderBarcodes"
                    focus={true}
                    fullWidth={true}
                    label={getLocalisationMessage("orders_barcodes")}
                  />
                </FlexBox>
              </FlexBox>

              <FormJMAutoComplete
                margin="normal"
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                fullWidth={true}
                name="jurisdiction"
                label={getLocalisationMessage("jurisdiction", "Jurisdiction")}
              />

              <PostcodeAutoCompleteWithJM
                label={getLocalisationMessage("index", "Index")}
                name="index"
                margin="normal"
                fullWidth={true}
                jurisdictionId={jurisdictionId}
                disabled={!jurisdictionId}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            </FlexBox>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onRequestClose} primary={true}>
            {getLocalisationMessage("dismiss")}
          </Button>

          <Button type="submit" primary={true}>
            {getLocalisationMessage("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default enhancer(BatchRedirectOrdersDialog);
