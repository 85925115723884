import { Observable } from "rxjs";
import React from "react";
import { List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getShopCityList,
  removeShopCities,
} from "../../api/admin/AdminShopCityApi";
import AdminShopCityEditDialogWrapper from "../../wrappers/admin/AdminShopCityEditDialogWrapper";
import AdminShopCityCreateDialogWrapper from "../../wrappers/admin/AdminShopCityCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../../components/data-list/DataList";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const NA = "N/A";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getShopCityList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data"], 0),
                  list: response.getIn(["payload", "data"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminShopCityList.propTypes = {
  isListLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  setLocation: PropTypes.func,
  onRowSelect: PropTypes.func,
  selectedIds: PropTypes.instanceOf(OrderedSet),
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminShopCityList(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout title={getLocalisationMessage("cities", "Cities")}>
      <AdminShopCityCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("create"));
          props.onRequestRefresh();
        }}
      />

      <AdminShopCityEditDialogWrapper
        cityId={fp.toFinite(location.query.edit)}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("edit"));
          props.onRequestRefresh();
        }}
      />

      <DataList
        isLoading={props.isLoading}
        selectedRowCount={props.selectedIds.size}
        totalCount={props.list.size}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        altHeader={
          <Button
            onClick={() =>
              removeShopCities(props.selectedIds.toArray().join(",")).then(
                props.onRequestRefresh,
              )
            }
          >
            {props.getLocalisationMessage("delete_items", "Delete Items")}
          </Button>
        }
        cardActionIcons={
          <div>
            <IconButton
              tooltip={props.getLocalisationMessage(
                "create_new_city",
                "Create New City",
              )}
              onClick={() => props.setLocationQuery(fp.set("create", true))}
            >
              <Add />
            </IconButton>
          </div>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            props.selectedIds.size > 0 &&
            props.selectedIds.size === props.list.size
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.list.toOrderedSet().map(item => item.get("id"))
                : OrderedSet(),
            )
          }
          rowSelected={row => props.selectedIds.has(row.cellData.get("id"))}
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedIds.add(row.cellData.get("id"))
                : props.selectedIds.delete(row.cellData.get("id")),
            )
          }
        />

        <DataListColumn
          width={96}
          label={props.getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              <Link
                to={updateQuery(
                  location,
                  fp.set("edit", row.cellData.get("id")),
                )}
              >
                {row.cellData.get("name")}
              </Link>
            </strong>
          )}
        />

        <DataListColumn
          width={96}
          label={props.getLocalisationMessage("code", "Code")}
          dataKey="code"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("code") || NA}</div>}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminShopCityList);
