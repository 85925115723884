import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { CardContent, CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getValue } from "../../helpers/DataUtils";
import Text from "../ui-core/Text";
import LinkButton from "../ui-core/LinkButton";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import ErrorSound from "../../assets/voices/error.mp3";
import AlarmSound from "../../assets/voices/alarm.ogg";
import BeepSound from "../../assets/voices/beep.wav";
import Audio from "../ui-core/Audio";
import { DELIVERY, RETURNING } from "../../constants/FlowTypes";
import { Edit, NotificationsActive } from "@material-ui/icons";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import { api } from "../../api/shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getUserId } from "../../reducers/ProfileReducer";
import _ from "lodash";
import OrderSortingEditInsufficientOrderFormDialog from "../order-sorting-bin-validation/OrderSortingEditInsufficientOrderFormDialog";
import { SIMPLE } from "../../constants/TransportationType";
import { formatText } from "../../helpers/FormatUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";

const formatTransportationTypesLocalised = (
  code,
  getLocalisationMessage,
  notAvailableValue = "N/A",
) => {
  switch (code) {
    case SIMPLE:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("simple_type", "Simple")) ||
        "Simple"
      );

    default:
      return (
        (getLocalisationMessage && getLocalisationMessage(code)) ||
        formatText(code) ||
        notAvailableValue
      );
  }
};

const useStyles = makeStyles(() => ({
  details: { flexDirection: "column", marginBottom: 8 },
  detailsRow: {
    marginBottom: 20,
  },
  detailsLabel: {
    lineHeight: "26px",
    fontWeight: "400",
    "@media (max-width: 991px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
  detailsValue: {
    lineHeight: "26px",
    "@media (max-width: 991px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
  orderErrorStatus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > div": {
      fontSize: "18px",
      lineHeight: "1.2",
      fontWeight: "bold",
      color: red[400],
    },
  },
  orderStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > span": {
      fontWeight: "bold",
      color: red[400],
      textTransform: "uppercase",
    },
  },
  orderLoading: {
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
  },
  "@media (min-width: 998px)": {
    details: { flexDirection: "column" },
  },
  "@media (max-width: 991px)": {
    content: { flex: 1 },
  },
}));

const enhancer = compose(
  connect(
    state => {
      const userId = getUserId(state);
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        userId,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
);

const getShipmentCity = (orderInfo, flow, from = true) => {
  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "fromCity")
      : _.get(orderInfo, "toCity");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "toCity")
    : _.get(orderInfo, "fromCity");
};

const getShipmentPostcode = (orderInfo, flow, from = true) => {
  const type = getValue(orderInfo, "type");

  if (type) {
    if (from) {
      return _.get(orderInfo, "fromPostCode.name", "");
    }
    return _.get(orderInfo, "toPostCode.name", "");
  }

  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "fromPostCode.name", "")
      : _.get(orderInfo, "toPostCode.name", "");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "toPostCode.name", "")
    : _.get(orderInfo, "fromPostCode.name", "");
};

OrderSortingBinDetailsCard.propTypes = {
  getLocalisationMessage: PropTypes.func.isRequired,
  order: PropTypes.object,
  activeOrder: PropTypes.string,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onReloadClick: PropTypes.func,
};

function OrderSortingBinDetailsCard(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { getLocalisationMessage, order } = props;
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenEditBtn, setIsOpenEditBtn] = useState(false);

  const [orderInfo, setOrderInfo] = useState({});

  useEffect(() => {
    if (order) {
      setOrderInfo(
        getValue(order, "batch")
          ? getValue(order, "batch", {})
          : getValue(order, "orders", {}),
      );
      setIsOpenEdit(getValue(order, "orders.insufficientData", false));
      setIsOpenEditBtn(getValue(order, "orders.ips", false));
    }
  }, [order]);

  const createIPSDraftOrder = async values => {
    setIsLoading(true);
    await api
      .post(`${API_V2_ROOT_URL}/orders/create_ips_draft_orders`, {
        body: values,
      })
      .then(() => {
        setOrderInfo({
          ...orderInfo,
          serviceType: values.service_type,
          toCity: _.get(values, "recipient_jurisdiction.name"),
          toJurisdiction: _.get(values, "recipient_jurisdiction"),
          toPostCode: _.get(values, "recipient_postcode"),
          weight: values.weight,
          insufficientData: false,
          flow: DELIVERY,
          ips: true,
        });
        setIsOpenEdit(false);
        props.showSuccessMessage("successfully");
      })
      .catch(error => {
        props.showErrorMessage(error);
      })
      .finally(() => setIsLoading(false));
  };

  if (!props.activeOrder || !order) return null;

  if (getValue(order, "isLoading") && !getValue(order, "error"))
    return (
      <Text element="h3" type="align-center" className={classes.orderLoading}>
        <strong>{props.activeOrder}</strong>
        <br />
        {getLocalisationMessage("loading")}
        {"..."}
      </Text>
    );

  if (getValue(order, "error") || getValue(order, "retry"))
    return (
      <Text
        element="h3"
        type="align-center"
        className={classes.orderErrorStatus}
      >
        <strong>{props.activeOrder}</strong>
        <div>
          {getValue(order, "error.message") ||
            getLocalisationMessage("loading_failed")}
        </div>
        <div>
          (
          <LinkButton onClick={() => props.onReloadClick(props.activeOrder)}>
            {getLocalisationMessage("reload")}
          </LinkButton>
          )
        </div>
        <Audio play={true} key={props.activeOrder} src={ErrorSound} />
      </Text>
    );

  const innerShipmentType = getValue(orderInfo, "type")
    ? orderInfo.innerShipmentType
    : _.get(orderInfo, "serviceType.name", null);
  const tempFlow =
    getValue(orderInfo, "flow") === null
      ? DELIVERY
      : getValue(orderInfo, "flow") === DELIVERY
      ? DELIVERY
      : RETURNING;
  const fromPostcode = getShipmentPostcode(orderInfo, tempFlow);
  const toPostcode = getShipmentPostcode(orderInfo, tempFlow, false);

  const fromCity = getValue(orderInfo, "type")
    ? _.get(orderInfo, "fromJurisdiction.name", "")
    : getShipmentCity(orderInfo, tempFlow);
  const toCity = getValue(orderInfo, "type")
    ? _.get(orderInfo, "toJurisdiction.name", "")
    : getShipmentCity(orderInfo, tempFlow, false);

  return (
    <div className={classes.details}>
      <OrderSortingEditInsufficientOrderFormDialog
        isLoading={isLoading}
        open={isOpenEdit}
        order={toSnakeCase(orderInfo)}
        onSubmit={values => createIPSDraftOrder(values)}
        onDismiss={() => setIsOpenEdit(false)}
      />

      <CardHeader
        title={
          <h5>
            {props.getLocalisationMessage("batch_details", "Batch Details")}
          </h5>
        }
      />
      <CardContent className={classes.content}>
        {!props.activeOrder ? (
          <div />
        ) : (
          <FlexBox flex={true} direction="column">
            <FlexBox flex={true}>
              <Text
                element="h3"
                type="align-center"
                className={classes.orderStatus}
                style={{ justifyContent: "space-between" }}
              >
                <strong>
                  {getLocalisationMessage("details", "Details")} -{" "}
                  {props.activeOrder}{" "}
                </strong>
                <FlexBox>
                  {getValue(orderInfo, "redirected") && (
                    <strong
                      style={{
                        margin: "0 .5rem",
                        border: ".25rem solid #f44336",
                        color: "#f44336",
                        padding: ".25rem 2rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {getLocalisationMessage("redirect_order")}
                    </strong>
                  )}
                  {tempFlow === RETURNING && (
                    <strong
                      style={{
                        border: ".25rem solid rgb(255, 152, 0)",
                        margin: "0 .5rem",
                        color: "rgb(255, 152, 0)",
                        padding: ".25rem 2rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {getLocalisationMessage("return")}
                    </strong>
                  )}
                  {isOpenEditBtn && (
                    <CustomButton
                      onClick={() => setIsOpenEdit(true)}
                      startIcon={<Edit />}
                      variant={OUTLINED}
                      color={SECONDARY}
                    >
                      {getLocalisationMessage("edit")}
                    </CustomButton>
                  )}
                </FlexBox>
              </Text>
            </FlexBox>
            {getValue(orderInfo, "notificationEnabled") && (
              <FlexBox>
                <strong
                  style={{
                    color: "rgb(76, 175, 80)",
                    textTransform: "uppercase",
                    marginBottom: "1rem",
                    backgroundColor: "rgba(76, 175, 80,.2)",
                    alignItems: "center",
                    padding: ".5rem 2rem",
                    borderRadius: ".25rem",
                  }}
                >
                  <NotificationsActive
                    style={{ marginRight: ".5rem" }}
                    color="rgb(76, 175, 80)"
                  />
                  {getLocalisationMessage("with_notification")}
                </strong>
              </FlexBox>
            )}
            <FlexBox />
            {getValue(orderInfo, "type") === "SHIPMENT" &&
              !order.get("parent_id") && (
                <FlexBox
                  style={{ textTransform: "uppercase" }}
                  flex={true}
                  className={classes.detailsRow}
                >
                  {getLocalisationMessage("misrouted", "Misrouted order")}

                  <Audio play={true} key={props.activeOrder} src={AlarmSound} />
                </FlexBox>
              )}
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("type", "Type")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong style={{ textTransform: "capitalize" }}>
                    {getLocalisationMessage(
                      getValue(orderInfo, "is_notification")
                        ? "notification"
                        : getValue(orderInfo, "type"),
                    )}
                  </strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("status", "Status")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {fp.capitalize(
                      formatOrderStatusCodeForLocalisation(
                        getValue(orderInfo, "status"),
                        getLocalisationMessage,
                      ),
                    )}
                  </strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getValue(orderInfo, "type")
                    ? getLocalisationMessage(
                        "inner_shipment_type",
                        "Inner Shipment Type",
                      )
                    : getLocalisationMessage("shipment_type", "Shipment Type")}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {innerShipmentType &&
                      getLocalisationMessage(
                        innerShipmentType.toLocaleLowerCase(),
                        innerShipmentType,
                      )}
                  </strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage(
                    "transportation_type",
                    "Transportation Type",
                  )}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {getValue(orderInfo, "transportationType") &&
                      formatTransportationTypesLocalised(
                        getValue(orderInfo, "transportationType"),
                        getLocalisationMessage,
                      )}
                  </strong>
                </h5>
              </FlexBox>
            </FlexBox>
            {getValue(orderInfo, "type") && (
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h5 className={classes.detailsLabel}>
                    {getLocalisationMessage("from_warehouse", "From Warehouse")}
                    :
                  </h5>
                  <h5 className={classes.detailsValue}>
                    <strong>{_.get(orderInfo, "fromWarehouse.name")}</strong>
                  </h5>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h5 className={classes.detailsLabel}>
                    {getLocalisationMessage("to_warehouse", "To Warehouse")}:
                  </h5>
                  <h5 className={classes.detailsValue}>
                    <strong>
                      {_.get(orderInfo, "toWarehouse.name", null) &&
                      getValue(orderInfo, "type") !== "CONTAINER"
                        ? _.get(orderInfo, "toWarehouse.name")
                        : _.get(orderInfo, "warehouse.name")}
                    </strong>
                  </h5>
                </FlexBox>
              </FlexBox>
            )}
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("from_postcode", "From Postcode")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{fromPostcode}</strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("to_postcode", "To Postcode")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{toPostcode}</strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getValue(orderInfo, "type")
                    ? getLocalisationMessage(
                        "from_jurisdiction",
                        "From Jurisdiction",
                      )
                    : getLocalisationMessage("from_city")}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{fromCity}</strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getValue(orderInfo, "type")
                    ? getLocalisationMessage(
                        "to_jurisdiction",
                        "To Jurisdiction",
                      )
                    : getLocalisationMessage("to_city")}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{toCity}</strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true}>
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("weight")}:&nbsp;
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {getValue(orderInfo, "weight")}{" "}
                    {getLocalisationMessage("kg")}
                  </strong>
                </h5>
              </FlexBox>
              {!getValue(orderInfo, "type") && (
                <FlexBox flex={true}>
                  <h5 className={classes.detailsLabel}>
                    {getLocalisationMessage("paid")}:&nbsp;
                  </h5>
                  <h5 className={classes.detailsValue}>
                    <strong>
                      {getValue(orderInfo, "paid")
                        ? getValue(orderInfo, "amount")
                        : getLocalisationMessage("no")}
                      {getValue(orderInfo, "paid")
                        ? getLocalisationMessage("sum")
                        : ""}
                    </strong>
                  </h5>
                </FlexBox>
              )}
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true}>
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("shipping_cost")}:&nbsp;
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {_.get(orderInfo, "parcelValue", 0)}{" "}
                    {getLocalisationMessage("sum")}
                  </strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <Audio play={true} key={props.activeOrder} src={BeepSound} />
          </FlexBox>
        )}
      </CardContent>
    </div>
  );
}

export default enhancer(OrderSortingBinDetailsCard);
