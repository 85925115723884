import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { getObjectId } from "../../helpers/FormUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { uploadOverweightCSV } from "../../api/admin/AdminOrderApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OverweightUploadForm from "../../components/orders-core/OverweightUploadForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "400px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

AdminOverweightUploadDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminOverweightUploadDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage(
        "upload_overweight_csv",
        "Upload Overweight Csv",
      )}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={props.onRequestClose}
    >
      <OverweightUploadForm
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        onSubmit={fp.flow(
          values =>
            uploadOverweightCSV(getObjectId(values.supplier), values.file),
          request => request.toPromise().catch(ResponseError.throw),
        )}
        onDismiss={props.onRequestClose}
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_upload_overweight_csv",
              "Successfully Upload Overweight Csv",
            ),
          );
          props.onRequestClose();
        }}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminOverweightUploadDialogWrapper);
