import React from "react";
import { Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import SampleItemImage from "./SampleItemImage";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatUnitsOfMeasureTypes,
  parseInteger,
} from "../../helpers/FormatUtils";
import { getMessage, getMessages } from "../../reducers/LocalizationReducer";
import UnitsOfMeasureTypes, {
  CM_KG,
  INCHES_POUNDS,
} from "../../constants/UnitsOfMeasureTypes";
import { responsive } from "../../../shared/theme/jss-responsive";
import { Languages } from "../../constants/LocaleTypes";
import { formatLanguage18 } from "../../containers/admin/AdminDefaultPublicTermsContainer";

const enhancer = compose(
  useSheet({ map: { [responsive("$xs")]: { display: "none" } } }),
  connect((state) => ({
    i18n: getMessages(state),
  })),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(
        fp.flow(fp.update("initialValues", fp.toPlainObject), (props) => ({
          ...props.initialValues,
          priority: props.initialValues.priority || 0,
          domestic: props.initialValues.domestic || true,
          unit: fp.toUpper(props.initialValues.unit) || CM_KG,
        })),
      );

    const onSubmit = (values, dispatch, props) => props.actualOnSubmit(values);

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
        actualOnSubmit: props.onSubmit,
        onSubmit: props.onSubmit && onSubmit,
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "SampleItemForm",
    validate: (values, props) => ({
      name: !values.name && props.i18n.get("enter_name", "Enter name"),
      fakeImage:
        !values.image && props.i18n.get("choose_image", "Choose image"),
    }),
  }),
  formValues({ unit: "unit" }),
);

const trueFalseOptions = OrderedSet.of(true, false);
const formatTrueFalseOption = (x, i18n) =>
  x === true ? i18n.get("yes", "Yes") : i18n.get("no", "No");

SampleItemForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  unit: PropTypes.oneOf([CM_KG, INCHES_POUNDS]),
  i18n: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
  sampleItemsForSettings: PropTypes.bool,
};

function SampleItemForm(props) {
  const { classes, i18n, getLocalisationMessage } = props;
  return (
    <FlexBox
      element={<form onSubmit={props.handleSubmit} />}
      container={8}
      direction="column"
    >
      <FlexBox gutter={8} element={<Card />} direction="column">
        <FlexBox element={<CardContent />} direction="column">
          <PageLoading isLoading={props.submitting} />
          <FlexBox gutter={8} justify="flex-start" align="flex-start">
            <FlexBox flex={true} direction="column">
              <FormTextField
                name="name"
                label={`${i18n.get("name", "Name")} *`}
                fullWidth={true}
                margin="normal"
              />
              {props.sampleItemsForSettings && (
                <FormSelectField
                  name="languageCode"
                  options={Languages}
                  formatOption={(value) => formatLanguage18(value, i18n)}
                  label={getLocalisationMessage("language", "Language")}
                  fullWidth={true}
                  margin="normal"
                />
              )}
              <FlexBox gutter={8} direction="column">
                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="domestic"
                      fullWidth={true}
                      label={i18n.get("domestic", "Domestic")}
                      options={trueFalseOptions}
                      formatOption={(x) => formatTrueFalseOption(x, i18n)}
                      margin="normal"
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      parseOnBlur={parseInteger}
                      name="priority"
                      label={i18n.get("priority", "Priority")}
                      margin="normal"
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox direction="column">
                <FormSelectField
                  name="unit"
                  fullWidth={true}
                  options={UnitsOfMeasureTypes}
                  label={`${i18n.get("unit", "Unit")} *`}
                  formatOption={(value) =>
                    formatUnitsOfMeasureTypes(value, i18n)
                  }
                  margin="normal"
                />
              </FlexBox>
              <FlexBox flex={true} gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    name="widthInCm"
                    label={`${i18n.get("width", "Width")} *`}
                    margin="normal"
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    name="heightInCm"
                    label={`${i18n.get("height", "Height")} *`}
                    margin="normal"
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    name="lengthInCm"
                    label={`${i18n.get("length", "Length")} *`}
                    margin="normal"
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true} gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    name="weight"
                    label={`${i18n.get("weight_g", "Weight")} *`}
                    margin="normal"
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    name="weightMax"
                    label={`${i18n.get("max_weight", "Max Weight")} *`}
                    margin="normal"
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox
              flex={true}
              direction="column"
              className={classes.map}
              justify="flex-start"
              align="flex-start"
              margin="normal"
            >
              <SampleItemImage />
              <FormTextField
                style={{ height: "0" }}
                name="fakeImage"
                fullWidth={true}
                margin="normal"
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox element={<CardActions />}>
          <Button type="submit">{i18n.get("save", "Save")}</Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SampleItemForm);
