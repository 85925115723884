import React, { useEffect, useRef, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PageLoading from "../../ui-core/PageLoading";
import FlexBox from "../../ui-core/FlexBox";
import FormTextField, { OUTLINED, SECONDARY } from "../../form/FormTextField";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import FormDateField from "../../form/FormDateField";
import { formatDate, formatText } from "../../../helpers/FormatUtils";
import FormAutocompleteNew from "../../form/FormInternationalSettingsAutocomplete";
import {
  getCacheRegion,
  getCacheRegionCode,
  getInternationalRegionChoose,
} from "../../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../../helpers/DataUtils";
import fp from "lodash/fp";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../../reducers/NotificationsReducer";
import { isValidDate, isValidObjectId } from "../../../helpers/ValidateUtils";
import FormAutoComplete from "../../form/FormAutoComplete";

const actions = ["OUT", "IN"];
const categories = ["AIR", "GROUND", "SAL"];

const getValues = getFormValues("InternationalEmptyBagsForm");

export const localisationAction = (option, getLocalisationMessage) => {
  switch (option) {
    case "IN":
      return getLocalisationMessage("inbound", "Inbound");

    case "OUT":
      return getLocalisationMessage("outbound", "Outbound");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

export const localisationCategory = (option, getLocalisationMessage) => {
  switch (option) {
    case "SAL":
      return getLocalisationMessage("sal", "S.A.L");

    case "GROUND":
      return getLocalisationMessage("surface", "Surface");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage },
  ),
  reduxForm({
    form: "InternationalEmptyBagsForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      action:
        !values.action &&
        props.getLocalisationMessage("this_field_is_required"),
      category:
        !values.category &&
        props.getLocalisationMessage("this_field_is_required"),
      date:
        !isValidDate(values.date) &&
        props.getLocalisationMessage("this_field_is_required"),
      weight:
        !values.weight &&
        props.getLocalisationMessage("this_field_is_required"),
      fromCountry:
        !isValidObjectId(values.fromCountry) &&
        props.getLocalisationMessage("this_field_is_required"),
      toCountry:
        !isValidObjectId(values.toCountry) &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

const InternationalEmptyBagsForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  change,
  onClose,
  update,
  success,
  setSuccess,
  reset,
}) => {
  const { action, category, fromCountry, toCountry, date, weight } = values;
  const [ignore, setIgnore] = useState(false);

  const actionRef = useRef(null);
  const categoryRef = useRef(null);
  const fromCountryRef = useRef(null);
  const toCountryRef = useRef(null);
  const transitCountryRef = useRef(null);
  const dateRef = useRef(null);
  const weightRef = useRef(null);
  const commentsRef = useRef(null);
  const submitButtonRef = useRef(null);

  const handleKeyDown = (e, ref, dateClick) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (dateClick) {
        ref.current.click();
        ref.current.focus();
      } else {
        ref.current.focus();
      }
    }
  };

  const handleSelectChange = (ref, dateClick) => {
    if (dateClick) {
      ref.current.click();
      ref.current.focus();
    } else {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (!update) {
      if (action === "OUT") {
        getCacheRegionCode("UZB").then(r => {
          change("fromCountry", getValue(r, "data", {}));
          change("toCountry", null);
        });
      } else if (action === "IN") {
        getCacheRegionCode("UZB").then(r => {
          change("toCountry", getValue(r, "data", {}));
          change("fromCountry", null);
        });
      }
    }
  }, [action]);

  useEffect(() => {
    if (success) {
      fp.keys(values).forEach(key => {
        change(key, null);
        setSuccess(false);
        setIgnore(false);
        reset();
      });
    }
  }, [success]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <PageLoading isLoading={submitting} />
      <FlexBox container={8} direction="column" style={{ gap: 13 }}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="action"
              fullWidth={true}
              options={actions}
              formatOption={option =>
                localisationAction(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage("action", "Action")}*`}
              onKeyDown={e => handleKeyDown(e, categoryRef)}
              inputRef={actionRef}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="category"
              fullWidth={true}
              options={categories}
              formatOption={option =>
                localisationCategory(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage("category", "Category")}*`}
              onKeyDown={e => handleKeyDown(e, fromCountryRef)}
              inputRef={categoryRef}
            />
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={fromCountryRef}
              onKeyDown={e => handleKeyDown(e, toCountryRef)}
              ignoreError={ignore && !success}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="fromCountry"
              levelId={1}
              fullWidth={true}
              label={`${getLocalisationMessage(
                "from_country",
                "From Country",
              )} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={toCountryRef}
              onKeyDown={e => handleKeyDown(e, transitCountryRef)}
              chooseAPI={getInternationalRegionChoose}
              name="toCountry"
              getByCode={getCacheRegionCode}
              levelId={1}
              fullWidth={true}
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage("to_country", "To Country")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormAutocompleteNew
              inputRef={transitCountryRef}
              onKeyDown={e => handleKeyDown(e, dateRef, true)}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                  {getValue(option, "value.parent.id") && (
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {getValue(option, "value.parent.code")
                        ? `${getValue(option, "value.parent.name")} (${getValue(
                            option,
                            "value.parent.code",
                            " ",
                          )})`
                        : getValue(option, "value.parent.name")}
                    </span>
                  )}
                </FlexBox>
              )}
              name="transitRegion"
              fullWidth={true}
              label={getLocalisationMessage("through", "Through")}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormDateField
              name="date"
              inputRef={dateRef}
              onKeyDown={e => handleKeyDown(e, weightRef)}
              handleSelectChange={() => handleSelectChange(weightRef)}
              fullWidth={true}
              formatValue="dd-MM-yyyy"
              ignoreError={ignore}
              formatDate={value => formatDate(value, "dd-MM-yyyy")}
              hintText={`${getLocalisationMessage("date", "Date")} *`}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 10 }}>
            <FormTextField
              inputRef={weightRef}
              onKeyDown={e => handleKeyDown(e, commentsRef)}
              name="weight"
              type="number"
              fullWidth={true}
              label={`${getLocalisationMessage("weight", "Weight")} *`}
              ignoreError={ignore}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormTextField
              inputRef={commentsRef}
              onKeyDown={e => handleKeyDown(e, submitButtonRef)}
              name="comments"
              fullWidth={true}
              label={getLocalisationMessage("comments", "Comments")}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          <FlexBox style={{ gap: 16 }} flex={true} justify="flex-end">
            <CustomButton
              onClick={() => {
                setSuccess(true);
                setIgnore(true);
                onClose();
              }}
              style={{
                height: 40,
              }}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("discard", "Discard")}
            </CustomButton>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              type="submit"
              style={{
                height: 40,
              }}
              disabled={
                !(
                  action &&
                  category &&
                  getValue(fromCountry, "id") &&
                  getValue(toCountry, "id") &&
                  date &&
                  weight
                )
              }
              buttonRef={submitButtonRef}
              onClick={() => {
                setIgnore(true);
                handleSelectChange(actionRef);
              }}
            >
              {update
                ? getLocalisationMessage("update", "update")
                : getLocalisationMessage("add", "Add")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
};
InternationalEmptyBagsForm.propTypes = {
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  update: PropTypes.bool,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  onClose: PropTypes.func,
  setSuccess: PropTypes.func,
  reset: PropTypes.func,
  untouch: PropTypes.func,
  valid: PropTypes.bool,
};
export default enhancer(InternationalEmptyBagsForm);
