import React, { useEffect, useRef, useState } from "react";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getFormValues, reduxForm } from "redux-form";
import {
  getCurrentLanguage,
  getMessages,
} from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import PropTypes from "prop-types";
import fp from "lodash/fp";
import ConfirmDialog from "../../deprecated/ConfirmDialog";
import FlexBox from "../../ui-core/FlexBox";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import FormTextField from "../../form/FormTextField";
import CustomButton, { CONTAINED, SECONDARY } from "../../ui-core/CustomButton";
import FormSelectField from "../../form/FormSelectField";
import {
  closeReceptacle,
  getMailSubClass,
  getOrderValuable,
  getReceptacleItem,
  getReceptacleOwnerShip,
  getReceptacleSubType,
  itemAddToReceptacle,
  itemRemoveFromReceptacle,
} from "../../../api/admin/AdminIPSApi";
import ScannerTextField from "../../deprecated/ScannerTextField";
import { RENDER } from "../../orders-core/MUITable";
import _ from "lodash";
import ReceptacleMailItemsTable from "../../orders-core/ReceptacleMailItemsTable";
import { getValue } from "../../../helpers/DataUtils";
import { DISPATCH_ITEM_URL } from "../../../constants/AdminPathConstants";
import ReceptacleCloseDialog from "../../form/ReceptacleCloseDialog";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import { DeleteForever } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ValuableConfirmDialog from "../../form/ValuableConfirmDialog";
import {
  RUSSIAN_LANGUAGE,
  UZBEK_LANGUAGE,
} from "../../../constants/LocaleTypes";

export const formatConfirmLocalisation = (
  currentLanguage,
  receptacleNumber,
) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return (
        <span>
          Вы хотите закрыть контейнер{" "}
          <span style={{ fontWeight: "bold" }}>{receptacleNumber}</span> ?
        </span>
      );
    case UZBEK_LANGUAGE:
      return (
        <span>
          {" "}
          <span style={{ fontWeight: "bold" }}>{receptacleNumber}</span> qopni
          yopmoqchimisiz?
        </span>
      );
    default:
      return (
        <span>
          Do you want to close{" "}
          <span style={{ fontWeight: "bold" }}>{receptacleNumber}</span>{" "}
          receptacle?
        </span>
      );
  }
};

const styles = makeStyles({
  root: {
    padding: "10px 24px",
    "@media (max-width: 1440px)": {
      "& .MuiCardContent-root:last-child": {
        paddingBottom: "none",
      },
      "& .MuiCardContent-root": {
        padding: "10px 16px",
      },
    },
  },
  orderType: {
    minHeight: 80,
    "@media (max-width: 1440px)": {
      minHeight: 60,
    },
  },
  card: {
    minHeight: 140,
    "@media (max-width: 1440px)": {
      minHeight: 110,
    },
  },
  title: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    textTransform: "capitalize",
  },
  cardTitle: {
    borderBottom: "1px solid #EEEEEE",
  },
  saveButton: {
    "& .MuiButton-containedSecondary": {
      backgroundColor: "#27AE60",
    },
  },
});

const getValues = getFormValues("ReceptacleForm");

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
      currentLanguage: getCurrentLanguage(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  reduxForm({
    enableReinitialize: true,
    form: "ReceptacleForm",
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

function ReceptacleForm(props) {
  const { i18n, values, receptacleID, dispatchId } = props;
  const classes = styles();
  const [confirm, setConfirm] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [receptacleSubType, setReceptacleSubType] = useState([]);
  const [receptacleOwnership, setReceptacleOwnership] = useState([]);
  const [mailSubClass, setMailSubClass] = useState([]);
  const [isLoadingReceptacleSubType, setIsLoadingReceptacleSubType] = useState(
    false,
  );
  const [
    isLoadingReceptacleOwnership,
    setIsLoadingReceptacleOwnership,
  ] = useState(false);
  const [isLoadingMailSubClass, setIsLoadingMailSubClass] = useState(false);
  const [mailItems, setMailItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [receptacleClose, setReceptacleClose] = useState(false);
  const [valuable, setValuable] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setIsLoadingMailSubClass(true);
    getMailSubClass()
      .then(r => {
        if (r && r.data) {
          setMailSubClass(r.data);
          setIsLoadingMailSubClass(false);
        }
      })
      .catch(() => setIsLoadingMailSubClass(false));
  }, []);

  const addItem = (barcode, value) => {
    if (barcode && values.receptacleNo && dispatchId) {
      itemAddToReceptacle({
        barcode,
        dispatch_id: dispatchId,
        receptacle_no: values.receptacleNo,
        value,
      })
        .then(r => {
          if (r && r.status === "success") {
            props.showSuccessMessage(i18n.get("successfully"));
            setRefresh(prev => !prev);
            setOrderNumber(null);
            setTimeout(() => {
              ref.current.focus();
            }, 1);
          }
        })
        .catch(error => {
          props.showErrorMessage(error);
          setTimeout(() => {
            ref.current.focus();
          }, 1);
        });
    }
  };

  useEffect(() => {
    if (orderNumber) {
      getOrderValuable(orderNumber).then(res => {
        if (res && res.data) {
          setValuable(res.data);
        } else {
          addItem(orderNumber);
        }
      });
    }
  }, [orderNumber]);

  useEffect(() => {
    setIsLoadingReceptacleSubType(true);
    getReceptacleSubType()
      .then(r => {
        if (r && r.data) {
          setReceptacleSubType(r.data);
          setIsLoadingReceptacleSubType(false);
        }
      })
      .catch(() => setIsLoadingReceptacleSubType(false));
  }, []);

  useEffect(() => {
    setIsLoadingReceptacleOwnership(true);
    getReceptacleOwnerShip()
      .then(r => {
        if (r && r.data) {
          setReceptacleOwnership(r.data);
          setIsLoadingReceptacleOwnership(false);
        }
      })
      .catch(() => setIsLoadingReceptacleOwnership(false));
  }, []);

  useEffect(() => {
    if (receptacleID) {
      setIsLoading(false);
      getReceptacleItem(receptacleID)
        .then(r => {
          if (r && r.data) {
            setMailItems(getValue(r, "data.mail_items"), []);
            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  }, [refresh]);

  const [closeReceptacleValues, setCloseReceptacleValues] = useState({});

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      {receptacleClose && (
        <ConfirmDialog
          confirmButtonLabel={i18n.get("yes", "Yes")}
          dismissButtonLabel={i18n.get("no", "No")}
          open={receptacleClose}
          title={`${i18n.get("are_you_sure", "Are you sure")}`}
          onConfirm={() =>
            closeReceptacle(closeReceptacleValues)
              .then(res => {
                if (res && res.status === "success") {
                  props.showSuccessMessage(
                    i18n.get("receptacle_is_closed", "Receptacle is closed"),
                  );
                  props.setLocation(DISPATCH_ITEM_URL + dispatchId);
                }
              })
              .catch(error => props.showErrorMessage(error))
          }
          onRequestClose={() => {
            setReceptacleClose(false);
            setOpenClose(true);
          }}
        >
          <FlexBox direction="column" style={{ gap: 13 }}>
            {formatConfirmLocalisation(
              props.currentLanguage,
              values.receptacle_id_string,
            )}
          </FlexBox>
        </ConfirmDialog>
      )}

      {openClose && (
        <ReceptacleCloseDialog
          open={openClose}
          initialValues={{
            weight: closeReceptacleValues.weight
              ? closeReceptacleValues.weight
              : values.weight,
            receptacleNo: closeReceptacleValues.receptacleNo
              ? closeReceptacleValues.receptacleNo
              : values.receptacleNo,
          }}
          onRequestClose={() => setOpenClose(false)}
          title={values.receptacle_id_string}
          onSubmit={formValues => {
            setCloseReceptacleValues({
              ...toSnakeCase(formValues),
              dispatch_id: dispatchId,
            });
            setReceptacleClose(true);
            setOpenClose(false);
          }}
          style={{ width: 500 }}
        />
      )}

      {valuable && (
        <ValuableConfirmDialog
          open={valuable}
          onRequestClose={() => setValuable(false)}
          title={values.receptacle_id_string}
          onSubmit={formValues => {
            addItem(orderNumber, formValues.value);
            setValuable(false);
          }}
          style={{ width: 500 }}
        />
      )}
      {confirm && (
        <ConfirmDialog
          confirmButtonLabel={i18n.get("yes", "Yes")}
          dismissButtonLabel={i18n.get("no", "No")}
          open={confirm}
          onConfirm={() => props.setLocation(DISPATCH_ITEM_URL + dispatchId)}
          onRequestClose={() => setConfirm(false)}
        >
          {i18n.get(
            "are_you_sure_you_want_to_exit",
            "Are you sure you want to exit?",
          )}
        </ConfirmDialog>
      )}

      <FlexBox direction="column" style={{ height: 250 }}>
        <FlexBox
          className={classes.root}
          direction="column"
          justify="space-between"
        >
          <Typography variant="h5" className={classes.title}>
            {i18n.get("add_items_to_receptacle", "Add items to receptacle")}
          </Typography>

          <Card style={{ height: "200px" }}>
            <CardContent>
              <FlexBox style={{ gap: 30 }} direction="column">
                <FlexBox style={{ gap: 16 }}>
                  <FlexBox flex={true}>
                    <Typography variant="h5">
                      {i18n.get("dispatch_1", "Dispatch")}
                    </Typography>
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="originOffice"
                    />
                  </FlexBox>
                  <FlexBox>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="destinationOffice"
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="mailCategory"
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="mailSubClass"
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="yearDigit"
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="serialNo"
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="receptacleNo"
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true}>
                  <Divider style={{ width: "100%", height: 1 }} />
                </FlexBox>

                <FlexBox style={{ gap: 16 }}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="receptacleMailSubclass"
                      loading={isLoadingMailSubClass}
                      fullWidth={true}
                      label={`${i18n.get(
                        "mail_sub_class",
                        "Mail Sub Class",
                      )} *`}
                      formatOption={x => x}
                      options={mailSubClass}
                      disabled={!mailSubClass.length}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="subType"
                      loading={isLoadingReceptacleSubType}
                      fullWidth={true}
                      label={`${i18n.get("type", "Type")} *`}
                      formatOption={x => x}
                      options={receptacleSubType}
                      disabled={!receptacleSubType.length}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      disabled={true}
                      name="contentFormat"
                      label={i18n.get("content_format", "Content Format")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="ownership"
                      loading={isLoadingReceptacleOwnership}
                      fullWidth={true}
                      label={`${i18n.get("ownership", "Ownership")} *`}
                      formatOption={x => x}
                      options={receptacleOwnership}
                      disabled={!receptacleOwnership.length}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>

      <FlexBox direction="column" style={{ height: 90 }}>
        <FlexBox
          className={classes.root}
          direction="column"
          justify="space-between"
        >
          <Card>
            <CardContent>
              <FlexBox style={{ gap: 13 }}>
                <ScannerTextField
                  fullWidth={true}
                  inputRef={ref}
                  label={i18n.get("scan_identifiers", "Scan Identifiers")}
                  className={classes.input}
                  focus={true}
                  id="ReceptacleFormScannerTextField"
                  onChange={value => {
                    setOrderNumber(value);
                  }}
                  size="small"
                />

                <CustomButton
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{ width: 246, height: 40 }}
                  onClick={() => {
                    const value = ref && ref.current.value;
                    getOrderValuable(orderNumber || value).then(res => {
                      if (res && res.data) {
                        setValuable(res.data);
                      } else {
                        addItem(orderNumber || value);
                      }
                    });
                  }}
                >
                  {i18n.get("add_ins", "Add(INS)")}
                </CustomButton>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>

      <FlexBox direction="column" className={classes.root}>
        <ReceptacleMailItemsTable
          list={mailItems}
          isLoading={isLoading}
          columns={[
            {
              type: RENDER,
              name: "item_id",
              render: row => _.get(row, `item_id`, ""),
              label: i18n.get("barcode"),
            },
            {
              type: RENDER,
              name: "destination_country",
              label: i18n.get("destination_country", "Destination Country"),
              render: row => _.get(row, `destination_country_code`, "N/A"),
            },
            {
              type: RENDER,
              name: "origin_country",
              label: i18n.get("origin_country", "Origin Country"),
              render: row => _.get(row, `origin_country_code`, "N/A"),
            },
            {
              type: RENDER,
              name: "postal_status",
              label: i18n.get("postal_status", "Postal Status"),
              render: row => _.get(row, `postal_status`, "N/A"),
            },
            {
              type: RENDER,
              name: "value",
              label: i18n.get("valuable", "Valuable"),
              render: row => _.get(row, `value`, "N/A"),
            },
            {
              type: RENDER,
              name: "weight",
              label: i18n.get("weight", "Weight"),
              render: row => _.get(row, `weight`, ""),
            },
            {
              type: RENDER,
              name: "action",
              label: i18n.get("action", "Action"),
              render: row => (
                <IconButton
                  onClick={() =>
                    itemRemoveFromReceptacle({
                      barcode: _.get(row, `item_id`, ""),
                      dispatch_id: dispatchId,
                      receptacle_no: values.receptacleNo,
                    })
                      .then(() => {
                        props.showSuccessMessage(
                          i18n.get("removed", "Removed"),
                        );
                        setRefresh(prev => !prev);
                      })
                      .catch(error => props.showErrorMessage(error))
                  }
                >
                  <DeleteForever style={{ color: "#F44336" }} />
                </IconButton>
              ),
            },
          ]}
        />
      </FlexBox>

      <Card style={{ zIndex: 1000, width: "100%" }}>
        <CardContent>
          <FlexBox justify="flex-end" style={{ gap: 20 }}>
            <CustomButton
              style={{ width: 246, height: 40, color: "red" }}
              onClick={() => setConfirm(true)}
            >
              {i18n.get("exit", "Exit")}
            </CustomButton>

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              style={{ width: 246, height: 40 }}
              onClick={() => setOpenClose(true)}
            >
              {i18n.get("close", "close")}
            </CustomButton>
          </FlexBox>
        </CardContent>
      </Card>
    </form>
  );
}

ReceptacleForm.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  setLocation: PropTypes.func,
  handleSubmit: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  values: PropTypes.object,
  receptacleID: PropTypes.object,
  dispatchId: PropTypes.object,
  currentLanguage: PropTypes.string,
};

export default enhancer(ReceptacleForm);
