import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import { marketplaceDefaultOrderCreationEnabled } from "../../reducers/MarketplaceReducer";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import AddressBookFormNew from "../../components/address-book-core/AddressBookFormNew";
import AddressBookFormWithoutMap from "../../components/address-book-core/AddressBookFormWithoutMap";

const enhancer = compose(
  connect(state => ({
    isDefaultOrderCreation: marketplaceDefaultOrderCreationEnabled(state),
  })),
);

AdminAddressBookItemWrapper.propTypes = {
  isDefaultOrderCreation: PropTypes.bool,
};

function AdminAddressBookItemWrapper(props) {
  return (
    <Card style={{ width: "800px", margin: "0 auto" }}>
      {props.isDefaultOrderCreation ? (
        <AddressBookFormWithoutMap
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
          {...props}
        />
      ) : (
        <AddressBookFormNew
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
          {...props}
        />
      )}
    </Card>
  );
}

export default enhancer(AdminAddressBookItemWrapper);
