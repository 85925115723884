import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { connect } from "react-redux";
import reduxActions from "redux-form/es/actions";
import NewMapAreasList from "./NewMapAreasList";
import LeafletMapAreasMap from "./LeafletMapAreasMap";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { changeLngLatToLatLng } from "../../helpers/MapPolygonUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { addArea, editArea } from "../../api/admin/AdminAreasApi";

const enhancer = compose(
  useSheet({
    map: { flex: "1 1 0%", zIndex: 0 },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isRTL: getIsRTL(state),
    }),
    dispatch => ({
      resetForm: () => dispatch(reduxActions.reset("AreaDrawingWizard")),
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.pick(["createItem", "editItem", "areaList"]))
      .distinctUntilChanged(isEqualData)
      .filter(props => props.createItem || props.editItem > 0)
      .map(props => {
        const currentPolygon = props.areaList.find(
          item => item.get("id") === props.editItem,
        );

        if (currentPolygon) {
          return currentPolygon.get("polygon", Immutable.List());
        }

        return Immutable.List();
      })
      .startWith(Immutable.List());

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues: {
          currentEditingArea: initialValues,
        },
      }),
    );
  }),
  reduxForm({
    form: "AreaDrawingWizard",
    enableReinitialize: true,
  }),
  formValues("currentEditingArea"),
  withState("isActiveTask", "setActiveTask", false),
  withHandlers({
    onAddPolygon: props => values => {
      const polygons = props.currentEditingArea || Immutable.List();
      const list = changeLngLatToLatLng(values.geometry.coordinates);
      props.change("polygonEdited", true);
      props.change(
        "currentEditingArea",
        polygons.push(Immutable.fromJS(list[0])),
      );
    },
    onEditPolygon: props => values => {
      const polygons = props.currentEditingArea || Immutable.List();
      const list = changeLngLatToLatLng(values.polygon.geometry.coordinates);
      props.change("polygonEdited", true);
      props.change(
        "currentEditingArea",
        polygons.set(values.idx, Immutable.fromJS(list[0])),
      );
    },
    onCutPolygon: props => values => {
      // TODO: Currently not working, it is not ready yet
      const polygons = props.currentEditingArea || Immutable.List();
      const list = changeLngLatToLatLng(values.polygon.geometry.coordinates);
      props.change("polygonEdited", true);
      props.change(
        "currentEditingArea",
        polygons.set(values.idx, Immutable.fromJS(list[0])),
      );
    },
    onRemovePolygon: props => removalIndexes => {
      const polygons = props.currentEditingArea || Immutable.List();
      const newPolygons = polygons.filter(
        (value, index) => removalIndexes.indexOf(index) === -1,
      );
      props.change("polygonEdited", true);
      props.change("currentEditingArea", newPolygons);
    },
  }),
);

AreaDrawingWizard.propTypes = {
  classes: PropTypes.object,

  isActiveTask: PropTypes.bool,
  setActiveTask: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),
  areaList: PropTypes.instanceOf(Immutable.List),

  resetForm: PropTypes.func,

  editItem: PropTypes.number,
  createItem: PropTypes.bool,
  countryCode: PropTypes.string,
  currentEditingArea: PropTypes.instanceOf(Immutable.List),
  selectedItems: PropTypes.instanceOf(Immutable.List),
  areaListLoading: PropTypes.bool,
  hoveredId: PropTypes.number,
  mapCenter: PropTypes.object,
  polygonEdited: PropTypes.bool,

  onSortAreas: PropTypes.func,
  onHoverArea: PropTypes.func,
  onDeleteArea: PropTypes.func,
  onSetCoordinates: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  onAddItem: PropTypes.func,
  onEditItem: PropTypes.func,
  onSwitchItem: PropTypes.func,
  onCloseForm: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,

  onAddPolygon: PropTypes.func,
  onEditPolygon: PropTypes.func,
  onCutPolygon: PropTypes.func,
  onRemovePolygon: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AreaDrawingWizard({ classes, ...props }) {
  return (
    <div className={classes.map}>
      <NewMapAreasList
        isAdminArea={true}
        isActiveTask={props.isActiveTask}
        filter={props.filter}
        areaList={props.areaList}
        editItem={props.editItem}
        createItem={props.createItem}
        onSortList={props.onSortAreas}
        onHoverArea={props.onHoverArea}
        countryCode={props.countryCode}
        editPolygons={props.currentEditingArea}
        polygonEdited={props.polygonEdited}
        onDeleteArea={props.onDeleteArea}
        selectedItems={props.selectedItems}
        areaListLoading={props.areaListLoading}
        onSetCoordinates={props.onSetCoordinates}
        onSelectAllClick={props.onSelectAllClick}
        allSelected={props.selectedItems.size === props.areaList.size}
        onEditArea={values => editArea(values).catch(ResponseError.throw)}
        onCreateArea={values => addArea(values).catch(ResponseError.throw)}
        onAddItem={props.onAddItem}
        onSwitchItem={props.onSwitchItem}
        onCloseForm={() => {
          props.resetForm();
          props.onCloseForm();
        }}
        onSubmitFail={props.onSubmitFail}
        onSubmitSuccess={() => {
          props.resetForm();
          props.onSubmitSuccess();
        }}
      />

      <LeafletMapAreasMap
        filter={props.filter}
        isAdminArea={true}
        areaList={props.areaList}
        editItem={props.editItem}
        hoveredId={props.hoveredId}
        createItem={props.createItem}
        editPolygons={props.currentEditingArea}
        selectedItems={props.selectedItems}
        areaListLoading={props.areaListLoading}
        onSetRawPolygon={event => props.onAddPolygon(event)}
        onEditRawPolygon={event => props.onEditPolygon(event)}
        onCutRawPolygon={event => props.onCutPolygon(event)}
        mapCenter={props.mapCenter}
        onRemovePolygon={idx => props.onRemovePolygon(idx)}
        onEditItem={props.onEditItem}
        onDeleteArea={props.onDeleteArea}
        setActiveTask={enable => props.setActiveTask(enable)}
        getLocalisationMessage={props.getLocalisationMessage}
      />
    </div>
  );
}

export default enhancer(AreaDrawingWizard);
