import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox, { JUSTIFY_END } from "../ui-core/FlexBox";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { withTheme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
    },
  ),
  withTheme,
);

ShiftingDialog.propTypes = {
  theme: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,
  shiftHandOver: PropTypes.func,
  shiftAccept: PropTypes.func,
  shiftHistory: PropTypes.func,
};

function ShiftingDialog(props) {
  const {
    getLocalisationMessage,
    shiftHandOver,
    shiftAccept,
    shiftHistory,
  } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          minWidth: "800px",
          height: 500,
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox
            flex={true}
            justify="flex-start"
            align="center"
            style={{ gap: 20 }}
          >
            <IconButton onClick={props.onRequestClose}>
              <Close style={{ width: 30, height: 30 }} />
            </IconButton>
            {getLocalisationMessage("change_shift", "Change Shift")}
          </FlexBox>

          <FlexBox flex={true} justify={JUSTIFY_END}>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              onClick={shiftHistory}
            >
              {getLocalisationMessage("shift_history", "Shift History")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FlexBox
          flex={true}
          style={{ gap: 20, width: "100%" }}
          justify="center"
          align="center"
          direction="column"
        >
          <FlexBox style={{ width: "80%" }} flex={true}>
            <CustomButton
              fullWidth={true}
              variant={OUTLINED}
              color={SECONDARY}
              onClick={shiftHandOver}
              style={{ height: 80 }}
            >
              {getLocalisationMessage("handover_shift", " Handover Shift")}
            </CustomButton>
          </FlexBox>

          <FlexBox flex={true} style={{ width: "80%" }}>
            <CustomButton
              fullWidth={true}
              variant={OUTLINED}
              color={SECONDARY}
              onClick={shiftAccept}
              style={{ height: 80 }}
            >
              {getLocalisationMessage("accept_shift", " Accept Shift")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(ShiftingDialog);
