import React from "react";
import { formatDistanceStrict } from "date-fns";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import DescriptionTerm from "../ui-core/DescriptionTerm";
import { getMessage } from "../../reducers/LocalizationReducer";
import { formatDistance } from "../../helpers/FormatUtils";
import fp from "lodash/fp";
import { isEqualData } from "../../helpers/DataUtils";

const NA = "N/A";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    description: {
      margin: 0,
      color: "#fefefe",
      fontSize: "13px",
    },
    descriptionTitle: {
      fontSize: "11px",
      color: "rgba(255, 255, 255, 0.54)",
    },
    descriptionContainer: {
      margin: "12px 0",
      lineHeight: "16px",
    },
    root: {
      minWidth: "450px",
    },
  }),
  mapPropsStream(propsStream => {
    const statisticsStream = propsStream
      .map(fp.pick(["alertDetailed"]))
      .distinctUntilChanged(isEqualData)
      .map(props => {
        const pickupTime = props.alertDetailed.get("pickup_eta_time", NA);
        const deliveryTime = props.alertDetailed.get("delivery_eta_time", NA);

        let totalTime = NA;
        if (fp.isFinite(pickupTime) && fp.isFinite(deliveryTime)) {
          totalTime = pickupTime + deliveryTime;
        }

        const pickupDistance = props.alertDetailed.get("distance", NA);
        const deliveryDistance = props.alertDetailed.get(
          "delivery_eta_distance",
          NA,
        );

        let totalDistance = NA;
        if (fp.isFinite(pickupDistance) && fp.isFinite(deliveryDistance)) {
          totalDistance = pickupDistance + deliveryDistance;
        }

        return Immutable.Map({
          pickupTime: fp.isFinite(pickupTime)
            ? Math.ceil(pickupTime / 60)
            : pickupTime,
          deliveryTime: fp.isFinite(deliveryTime)
            ? Math.ceil(deliveryTime / 60)
            : deliveryTime,
          totalTime: fp.isFinite(totalTime)
            ? Math.ceil(totalTime / 60)
            : totalTime,
          pickupDistance,
          deliveryDistance,
          totalDistance,
        });
      })
      .startWith(
        Immutable.fromJS({
          pickupTime: NA,
          deliveryTime: NA,
          totalTime: NA,
          pickupDistance: NA,
          deliveryDistance: NA,
          totalDistance: NA,
        }),
      );

    return propsStream
      .combineLatest(statisticsStream, (props, stats) => ({
        ...props,
        stats,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

SuggestedDriverAlertInfo.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
  alertDetailed: PropTypes.instanceOf(Immutable.Map),
  stats: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func,
};

function SuggestedDriverAlertInfo(props) {
  const { classes, getLocalisationMessage, stats } = props;

  return (
    <FlexBox style={props.style} className={classes.root}>
      <FlexBox gutter={8} flex={true} className={classes.descriptionContainer}>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage("to_pick_up", "To Pick Up")}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {fp.isFinite(stats.get("pickupTime")) ? (
              <div>
                {stats.get("pickupTime")}m {"/"}{" "}
                {formatDistance(stats.get("pickupDistance", NA))}
              </div>
            ) : (
              NA
            )}
          </DescriptionTerm>
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage("to_delivery", "To Delivery")}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {fp.isFinite(stats.get("deliveryTime")) ? (
              <div>
                {stats.get("deliveryTime")}m {"/"}{" "}
                {formatDistance(stats.get("deliveryDistance", NA))}
              </div>
            ) : (
              NA
            )}
          </DescriptionTerm>
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage("total", "Total")}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {fp.isFinite(stats.get("totalTime")) ? (
              <div>
                {stats.get("totalTime")}m {"/"}{" "}
                {formatDistance(stats.get("totalDistance", NA))}
              </div>
            ) : (
              NA
            )}
          </DescriptionTerm>
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage("capacity", "Capacity")}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {props.alertDetailed.get("last_status_update_date")
              ? `${formatDistanceStrict(
                  props.alertDetailed.get("last_status_update_date"),
                  new Date(),
                )} ${getLocalisationMessage("ago", "ago")}`
              : getLocalisationMessage("na", "N/A")}
          </DescriptionTerm>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SuggestedDriverAlertInfo);
