import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";
import { formValues, reduxForm } from "redux-form";
import fp from "lodash/fp";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import FlexBox from "../ui-core/FlexBox";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import { Home as HomeIcon, Info as InfoIcon } from "@material-ui/icons";
import { getValue } from "../../helpers/DataUtils";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      maxWidth: "1000px",
      minWidth: "500px",
    },
  }),
  reduxForm({
    form: "OrderSortingRedirectDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      barcode:
        fp.isEmpty(values.barcode) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_order")) ||
          "Select Order"),
      index:
        fp.isEmpty(fp.get("index.name", values)) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_postcode")) ||
          "Select Postcode"),
    }),
  }),
  formValues({
    barcode: "barcode",
    jurisdiction: "jurisdiction",
    index: "index",
  }),
  withTheme,
);

OfflineOrderSortingRedirectOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dirty: PropTypes.bool,
  showOrderData: PropTypes.bool,

  classes: PropTypes.object,
  theme: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,

  order: PropTypes.object,
  initialValues: PropTypes.object,
};

function OfflineOrderSortingRedirectOrderDialog(props) {
  const { classes, getLocalisationMessage, order, showOrderData } = props;

  const jurisdictionId = fp.get("jurisdiction.id", props);

  return (
    <Dialog
      modal={true}
      open={props.open}
      maxWidth="auto"
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        {getLocalisationMessage("redirect_order", "Redirect Order")}
      </DialogTitle>

      <DialogContent className={classes.paper}>
        {showOrderData && order && order.barcode && (
          <FlexBox flex={true}>
            <FlexBox flex={true}>
              <List className={classes.root}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <InfoIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={order.barcode}
                    secondary={getValue(order, "service_type.name", "N/A")}
                  />
                </ListItem>
              </List>
            </FlexBox>

            <FlexBox flex={true}>
              <List className={classes.root}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HomeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={getValue(order, "to_jurisdiction.name", "N/A")}
                    secondary={getValue(order, "to_postcode.name", "N/A")}
                  />
                </ListItem>
              </List>
            </FlexBox>
          </FlexBox>
        )}

        <FormJMAutoComplete
          margin="normal"
          renderOption={option => (
            <FlexBox direction="column">
              <span>{option.name}</span>
              <span
                style={{
                  fontSize: ".8rem",
                  fontStyle: "italic",
                }}
              >
                {option.value.hierarchy.map((item, index, arr) =>
                  index === arr.length - 1 ? item.name : `${item.name} > `,
                )}
              </span>
            </FlexBox>
          )}
          fullWidth={true}
          name="jurisdiction"
          label={getLocalisationMessage("jurisdiction", "Jurisdiction")}
        />

        <PostcodeAutoCompleteWithJM
          label={getLocalisationMessage("index", "Index")}
          name="index"
          margin="normal"
          fullWidth={true}
          jurisdictionId={jurisdictionId}
          disabled={!jurisdictionId}
          hintText={getLocalisationMessage(
            "type_to_search",
            "Type To Search...",
          )}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <Button disabled={!props.dirty} onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OfflineOrderSortingRedirectOrderDialog);
