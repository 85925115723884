import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import DriverAttendanceTypes from "../../constants/DriverAttendanceTypes";
import {
  driverCheckInNote,
  driverCheckInUpdate,
} from "../../api/admin/AdminDriverApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriverRejectionReasonForm from "../../components/drivers-core/DriverRejectionReasonForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    paper: {
      minWidth: "700px",
      maxWidth: "700px",
      minHeight: "auto",
    },
  }),
);

AdminDriverRejectionReasonDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  data: PropTypes.instanceOf(Map),
  i18n: PropTypes.instanceOf(Map),
};

function AdminDriverRejectionReasonDialogWrapper(props) {
  const { classes, i18n } = props;

  return (
    <ModalPaper
      open={true}
      title={i18n.get("rejection_reason", "Rejection Reason")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <DriverRejectionReasonForm
        onDismiss={props.onRequestClose}
        initialValues={{
          ids: props.data.get("checkInIds").toArray(),
        }}
        worked={props.data.get("worked")}
        onSubmit={values =>
          DriverAttendanceTypes.has(values.type)
            ? driverCheckInUpdate(values).catch(ResponseError.throw)
            : driverCheckInNote(values).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            i18n.get("successfully_updated", "Successfully Updated"),
          );
          props.onRequestClose();
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminDriverRejectionReasonDialogWrapper);
