import React from "react";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({
    enableReinitialize: true,
    initialValues: { manual: false },
    form: "OrderReturnChangeBinDialog",
    validate: (values, props) => ({
      name:
        !values.name &&
        (props.getLocalisationMessage("bin_name_is_required") ||
          "Bin name is Required"),
    }),
  }),
  formValues("manual"),
  mapPropsStream(propsStream => {
    const sideEffectsStream = propsStream
      .distinctUntilKeyChanged("manual")
      .do(props => props.change("name", ""));

    return propsStream.combineLatest(sideEffectsStream, fp.identity);
  }),
);

OrderReturnChangeBinDialog.propTypes = {
  manual: PropTypes.bool,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  orderBins: PropTypes.instanceOf(Map).isRequired,
  selectedOrders: PropTypes.instanceOf(Set).isRequired,

  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderReturnChangeBinDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <Dialog
      open={props.open}
      form="OrderReturnChangeBinDialog"
      onClose={props.onRequestClose}
      validate={values => ({
        name:
          !values.name &&
          getLocalisationMessage(
            "bin_name_is_required",
            "Bin name is Required",
          ),
      })}
    >
      <DialogTitle>
        {" "}
        {`${getLocalisationMessage("move", "Move")} ${
          props.selectedOrders.size
        } ${getLocalisationMessage("orders_to", "orders to")}`}{" "}
      </DialogTitle>
      <DialogContent>
        <PageLoading isLoading={props.submitting} />

        {props.manual ? (
          <FormTextField
            fullWidth={true}
            name="name"
            label={getLocalisationMessage("enter_bin_name", "Enter Bin Name")}
          />
        ) : (
          <FormSelectField
            name="name"
            fullWidth={true}
            label={getLocalisationMessage("select_bin", "Select Bin")}
            options={props.orderBins.toSet().sortBy()}
          />
        )}

        <FormCheckbox
          name="manual"
          label={getLocalisationMessage("add_new", "Add New")}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {" "}
            {getLocalisationMessage("dismiss", "Dismiss")}{" "}
          </Button>
          <Button onClick={props.handleSubmit}>
            {" "}
            {getLocalisationMessage("submit", "Submit")}{" "}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderReturnChangeBinDialog);
