import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Field, reduxForm, FieldArray } from "redux-form";
import { Paper, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import {
  MATCHES_FN,
  OVER_SOME_FN,
  OVER_EVERY_FN,
  NOT_MATCHES_FN,
  MATCHES_FN_AND_NOT_MATCHES_FN,
} from "../../helpers/Rule";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import OrderSortingReturnField from "../../constants/OrderSortingReturnField";

// const pickId = fp.pick(["id"]);

OrderRuleMatch.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderRuleMatch({ fields, getLocalisationMessage }) {
  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FormSelectField
          fullWidth={true}
          options={OrderStatusCodes}
          formatOption={x =>
            formatOrderStatusCodeForLocalisation(x, getLocalisationMessage)
          }
          label={getLocalisationMessage("order_status", "Order Status")}
          name={`${fields.name}[0].value.status`}
        />
      </FlexBox>

      <FlexBox flex={true}>
        <FormSelectField
          fullWidth={true}
          options={List(fp.range(0, 10))}
          label={getLocalisationMessage("attempt", "Attempt")}
          name={`${fields.name}[0].value.attempt`}
        />
      </FlexBox>

      <FlexBox flex={true}>
        <FormSelectField
          fullWidth={true}
          formatOption={x => getLocalisationMessage(x, formatText(x))}
          options={OrderSortingReturnField}
          label={getLocalisationMessage("delivery_type", "Delivery Type")}
          name={`${fields.name}[0].value.deliveryType`}
        />
      </FlexBox>
    </FlexBox>
  );
}

OrderRulePredicateArray.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderRulePredicateArray({ fields, getLocalisationMessage }) {
  return (
    <FlexBox flex={true} direction="column" style={{ marginLeft: "16px" }}>
      {fields.map((name, index) => (
        <OrderRulePredicate
          key={index}
          name={name}
          onRemoveClick={() => fields.remove(index)}
          onCopyClick={() => fields.insert(index + 1, fields.get(index))}
          getLocalisationMessage={getLocalisationMessage}
        />
      ))}

      <div>
        <Button onClick={() => fields.push({})}>
          {" "}
          {getLocalisationMessage("add_argument", "Add Argument")}{" "}
        </Button>
      </div>
    </FlexBox>
  );
}

OrderRulePredicateArgs.propTypes = {
  root: PropTypes.string,
  input: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderRulePredicateArgs({ root, input, getLocalisationMessage }) {
  const name = `${root}.${input.value}.args`;

  switch (input.value) {
    case OVER_SOME_FN:
    case OVER_EVERY_FN:
      return (
        <FieldArray
          name={name}
          component={OrderRulePredicateArray}
          getLocalisationMessage={getLocalisationMessage}
        />
      );

    case MATCHES_FN:
    case NOT_MATCHES_FN:
      return (
        <FieldArray
          name={name}
          component={OrderRuleMatch}
          getLocalisationMessage={getLocalisationMessage}
        />
      );

    default:
      return null;
  }
}

OrderRulePredicate.propTypes = {
  name: PropTypes.string,
  onCopyClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderRulePredicate(props) {
  const { getLocalisationMessage } = props;
  return (
    <div>
      <FlexBox direction="column">
        <FlexBox align="center">
          <FormSelectField
            options={MATCHES_FN_AND_NOT_MATCHES_FN}
            name={`${props.name}.fn`}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            label={getLocalisationMessage("function", "Function")}
          />

          {props.onCopyClick && (
            <Button onClick={props.onCopyClick}>
              {" "}
              {getLocalisationMessage("copy", "Copy")}{" "}
            </Button>
          )}

          {props.onRemoveClick && (
            <Button onClick={props.onRemoveClick}>
              {" "}
              {getLocalisationMessage("remove", "Remove")}{" "}
            </Button>
          )}
        </FlexBox>

        <FlexBox>
          <Field
            root={props.name}
            name={`${props.name}.fn`}
            component={OrderRulePredicateArgs}
            getLocalisationMessage={getLocalisationMessage}
          />
        </FlexBox>
      </FlexBox>
    </div>
  );
}

const enhancer = compose(
  renderIf("open"),
  withContext(
    {
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(props => {
        if (!props.initialValues) {
          return {};
        }

        const mapArgs = fp.map(mapPredicate);

        function mapPredicate(predicate) {
          return !predicate.fn
            ? { value: predicate.value }
            : {
                fn: predicate.fn,
                [predicate.fn]: {
                  args: mapArgs(predicate.args),
                },
              };
        }

        return {
          ...props.initialValues,
          predicate: mapPredicate(props.initialValues.predicate),
        };
      });

    const onSubmit = (values, dispatch, props) => {
      const mapArgs = fp.flow(fp.get("args"), fp.map(mapPredicate));

      function mapPredicate(predicate) {
        return !predicate.fn
          ? { value: predicate.value }
          : {
              fn: predicate.fn,
              args: mapArgs(predicate[predicate.fn]),
            };
      }

      return props.actualOnSubmit({
        ...values,
        predicate: mapPredicate(values.predicate),
      });
    };

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,

        actualOnSubmit: props.onSubmit,
        onSubmit: props.onSubmit ? onSubmit : props.onSubmit,
      }),
    );
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: !module.hot,
    form: "OrderReturnRuleDialog",
    validate: (values, props) => ({
      name:
        fp.isEmpty(values.name) &&
        props.getLocalisationMessage("enter_rule_name", "Enter rule name"),
      code:
        fp.isEmpty(values.code) &&
        props.getLocalisationMessage("enter_rule_code", "Enter rule code"),
      weight:
        !fp.isFinite(values.weight) &&
        props.getLocalisationMessage("enter_weight", "Enter weight"),
    }),
  }),
);

OrderReturnRuleDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  initialValues: PropTypes.object,

  getCachedSupplier: PropTypes.func.isRequired,
  getSupplierPredictions: PropTypes.func.isRequired,
  getCachedWarehouse: PropTypes.func.isRequired,
  getWarehousePredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderReturnRuleDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <ModalPaper open={props.open} onRequestClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column" flex="none">
        <FlexBox gutter={8} direction="column">
          <FlexBox direction="column" container={8}>
            <Paper>
              <CardContent>
                <FlexBox gutter={8}>
                  <FlexBox flex={true} align="flex-end">
                    <FormTextField
                      name="code"
                      fullWidth={true}
                      label={getLocalisationMessage("rule_code", "Rule Code")}
                    />
                  </FlexBox>

                  <FlexBox flex={true} align="flex-end">
                    <FormTextField
                      name="name"
                      fullWidth={true}
                      label={getLocalisationMessage("rule_name", "Rule Name")}
                    />
                  </FlexBox>

                  <FlexBox flex={true} align="flex-end">
                    <FormTextField
                      name="weight"
                      fullWidth={true}
                      parseOnBlur={fp.toLength}
                      label={getLocalisationMessage(
                        "rule_weight",
                        "Rule Weight",
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormChipAutoComplete
                      name="groupBy"
                      fullWidth={true}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                      options={OrderSortingReturnField}
                      label={getLocalisationMessage("group_by", "Group By")}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Paper>
          </FlexBox>

          <FlexBox direction="column">
            <Paper>
              <CardContent>
                <OrderRulePredicate
                  getLocalisationMessage={getLocalisationMessage}
                  name="predicate"
                />
              </CardContent>
            </Paper>
          </FlexBox>

          <FlexBox direction="column">
            <Paper>
              <CardActions>
                <FlexBox justify="flex-end">
                  <Button onClick={props.onRequestClose}>
                    {" "}
                    {getLocalisationMessage("dismiss", "Dismiss")}{" "}
                  </Button>
                  <Button onClick={props.handleSubmit}>
                    {" "}
                    {getLocalisationMessage("submit", "Submit")}{" "}
                  </Button>
                </FlexBox>
              </CardActions>
            </Paper>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(OrderReturnRuleDialog);
