import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SIGN_UP_URL = `${API_ROOT_URL}/dms/signup`;
const PRICING_PLANS_URL = `${API_ROOT_URL}/price_plans/`;
const PRICING_PLAN_ITEM_URL = `${API_ROOT_URL}/price_plans/%s`;
const CHECK_SUB_DOMAIN = `${API_ROOT_URL}/public/domain-check`;

type dmsCustomer = {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  compan_website: string,
  company_name: string,
  daily_delivery_volume: string,
  password: string,
  country: { id: number, name: string },
  zone: { id: number, name: string },
};

export type PricingPlanRequest = {
  yearly_price: number,
  monthly_price: number,
  monthly_includes: string,
  yearly_includes: string,
  status: string,
  icon: string,
  code: string,
  id: number,
  title: string,
};

export const registerDmsCustomer = (values: dmsCustomer) =>
  api.post(SIGN_UP_URL, { body: values });

export const checkSubDomain = subdomain =>
  api.post(CHECK_SUB_DOMAIN, { params: { subdomain } });

export const getPricingPlansList = (request: DataListFilter) =>
  api.getStream(PRICING_PLANS_URL, { params: request.getDefinedValues() });

export const getPricingPlanItem = id =>
  api.getStream(sprintf(PRICING_PLAN_ITEM_URL, id));

export const createPricingPlan = body => api.post(PRICING_PLANS_URL, { body });

export const updatePricingPlan = (id, body: PricingPlanRequest) =>
  api.put(sprintf(PRICING_PLAN_ITEM_URL, id), { body });

export const deletePricingPlan = id =>
  api.delete(sprintf(PRICING_PLAN_ITEM_URL, id));
