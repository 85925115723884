import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { AddCircle, Cancel, RemoveCircle } from "@material-ui/icons";
import { red, lightGreen } from "@material-ui/core/colors";
import FormToggle from "../form/FormToggle";
import FormCheckbox from "../form/FormCheckbox";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormDriverChips from "../form/FormDriverChips";
import FormSelectField from "../form/FormSelectField";
import FormCustomerChips from "../form/FormCustomerChips";
import FormSupplierChips from "../form/FormSupplierChips";
import FormTimeSlotChips from "../form/FormTimeSlotChips";
import FormCitySelectField from "../form/FormCitySelectField";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormCashierAutoComplete from "../form/FormCashierAutoComplete";
import FormCompanyAutoComplete from "../form/FormCompanyAutoComplete";
import FormOwnerUserAutoComplete from "../form/FormOwnerUserAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormCityFilterAutoComplete from "../form/FromCityFilterAutoComplete";
import FormSalesRepresentativeUserAutoComplete from "../form/FormSalesRepresentativeUserAutoComplete";
import RenderInNode from "../portals/RenderInNode";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import {
  formatText,
  safeParseDate,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";
import { formatLocalisedPaymentType } from "../../helpers/OrderHelper";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseString,
  parseIntString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DROP, PICKUP } from "../../constants/TimeSlotType";
import JobStatusCodes from "../../constants/JobStatusCodes";
import OrderSizeCodes from "../../constants/OrderSizeCodes";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import { hasRole } from "../../helpers/RoleUtils";

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";

const BOTH = null;
const MERCHANT = "merchant";
const NON_MERCHANT = "non_merchant";

const isMerchantOptions = OrderedSet.of(BOTH, MERCHANT, NON_MERCHANT);
const formatIsMerchant = (v, getLocalisationMessage) =>
  v === MERCHANT
    ? getLocalisationMessage("merchant", "Merchant")
    : v === NON_MERCHANT
    ? getLocalisationMessage("non_merchant", "Non Merchant")
    : getLocalisationMessage("both", "Both");

const startTime = startOfToday();
const endTime = endOfToday();

const enhancer = compose(
  useSheet({
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
    includeButton: { paddingTop: "32px" },
    toggle: {
      marginTop: "13px",
      whiteSpace: "nowrap",
    },
    wrapContent: {
      "@media (max-width: 998px)": {
        flexWrap: "wrap",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& > div": { justifyContent: "flex-start", width: "100%" },
      },
    },
  }),
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isFinanceAdmin: hasRole(userRoles, ROLE_FINANCE_ADMIN),
    };
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getCachedOwnerUser: PropTypes.func,
      getCachedSalesUser: PropTypes.func,
      getOwnerUserPredictions: PropTypes.func,
      getSalesUserPredictions: PropTypes.func,
      getCachedTimeSlot: PropTypes.func,
      getTimeSlotPredictions: PropTypes.func,
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedOwnerUser: props.getCachedOwnerUser,
      getCachedSalesUser: props.getCachedSalesUser,
      getOwnerUserPredictions: props.getOwnerUserPredictions,
      getSalesUserPredictions: props.getSalesUserPredictions,
      getCachedTimeSlot: props.getCachedTimeSlot,
      getTimeSlotPredictions: props.getTimeSlotPredictions,
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        const includeCustomers = Boolean(
          x.getValue("customer_ids") || !x.getValue("exclude_customer_ids"),
        );

        const includeDrivers = Boolean(
          x.getValue("driver_ids") || !x.getValue("exclude_driver_ids"),
        );

        const includeSuppliers = Boolean(
          x.getValue("supplier_ids") || !x.getValue("exclude_supplier_ids"),
        );

        const includeParentSuppliers = Boolean(
          x.getValue("parent_supplier_ids") ||
            !x.getValue("exclude_parent_supplier_ids"),
        );

        const includePickupAttemptCounts = Boolean(
          x.getValue("pickup_attempt_counts") ||
            !x.getValue("exclude_pickup_attempt_counts"),
        );

        const includeDeliveryAttemptCounts = Boolean(
          x.getValue("delivery_attempt_counts") ||
            !x.getValue("exclude_delivery_attempt_counts"),
        );

        const isMerchant = x.getValue("is_merchant", null);

        return {
          isDomestic: x.getBoolValue("is_uae", true),

          status: parseString(fp.toLower(x.getValue("status"))),
          orderSize: parseString(x.getValue("order_size")),
          includeException: x.getBoolValue("include_exception", null),

          courierTypes: parseString(x.getValue("courier_types")),

          company: { id: x.getIntegerValue("company_id") || null },

          cashier: { id: x.getIntegerValue("cashier_id") || null },

          includePickupAttemptCounts,
          pickupAttemptCounts: includePickupAttemptCounts
            ? parseString(x.getValue("pickup_attempt_counts"))
            : parseString(x.getValue("exclude_pickup_attempt_counts")),

          includeDeliveryAttemptCounts,
          deliveryAttemptCounts: includeDeliveryAttemptCounts
            ? parseString(x.getValue("delivery_attempt_counts"))
            : parseString(x.getValue("exclude_delivery_attempt_counts")),

          includeCustomers,
          customerIds: includeCustomers
            ? idsToObjectArray(x.getValue("customer_ids"))
            : idsToObjectArray(x.getValue("exclude_customer_ids")),

          includeDrivers,
          driverIds: includeDrivers
            ? idsToObjectArray(x.getValue("driver_ids"))
            : idsToObjectArray(x.getValue("exclude_driver_ids")),

          includeSuppliers,
          supplierIds: includeSuppliers
            ? idsToObjectArray(x.getValue("supplier_ids"))
            : idsToObjectArray(x.getValue("exclude_supplier_ids")),

          includeParentSuppliers,
          parentSupplierIds: includeParentSuppliers
            ? idsToObjectArray(x.getValue("parent_supplier_ids"))
            : idsToObjectArray(x.getValue("exclude_parent_supplier_ids")),

          pickupTimeslotIds: idsToObjectArray(x.getValue("pickupTimeslotIds")),
          dropoffTimeslotIds: idsToObjectArray(
            x.getValue("dropoffTimeslotIds"),
          ),

          fromDateTime: safeParseDate(x.getValue("from_date_time")),
          toDateTime: safeParseDate(x.getValue("to_date_time")),

          fromPromiseDateTime: safeParseDate(
            x.getValue("from_promise_date_time"),
          ),
          toPromiseDateTime: safeParseDate(x.getValue("to_promise_date_time")),

          fromDeadlineTime: safeParseDate(x.getValue("from_deadline_time")),
          toDeadlineTime: safeParseDate(x.getValue("to_deadline_time")),

          statusFromDateTime: safeParseDate(
            x.getValue("status_from_date_time"),
          ),
          statusToDateTime: safeParseDate(x.getValue("status_to_date_time")),

          fromCityId: { id: x.getIntegerValue("from_city_id") || null },
          toCityId: { id: x.getIntegerValue("to_city_id") || null },

          warehouse: { id: x.getIntegerValue("warehouse_id") || null },
          destinationWarehouse: {
            id: x.getIntegerValue("destination_warehouse_id") || null,
          },

          contractOwner: {
            id: x.getIntegerValue("contract_owner_id") || null,
          },

          salesRepresentative: {
            id: x.getIntegerValue("sales_id") || null,
          },

          isMerchant:
            isMerchant === "true"
              ? MERCHANT
              : isMerchant === "false"
              ? NON_MERCHANT
              : BOTH,

          paymentTypes: parseString(fp.toLower(x.getValue("payment_type"))),

          lastMileDriverIds: {
            id: x.getIntegerValue("last_mile_driver_id") || null,
          },
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            status: null,
            order_size: fp.toUpper(stringifyArray(values.orderSize)),
            include_exception: null,
            customer_ids: null,
            exclude_customer_ids: null,
            driver_ids: null,
            exclude_driver_ids: null,
            supplier_ids: null,
            exclude_supplier_ids: null,
            parent_supplier_ids: null,
            exclude_parent_supplier_ids: null,
            dropoffTimeslotIds: null,
            pickupTimeslotIds: null,
            pickup_attempt_counts: null,
            exclude_pickup_attempt_counts: null,
            delivery_attempt_counts: null,
            exclude_delivery_attempt_counts: null,
            is_uae: values.isDomestic,
            from_city_id: getObjectId(values.fromCityId) || null,
            to_city_id: getObjectId(values.toCityId) || null,
            courier_types: stringifyArray(values.courierTypes),
            company_id: getObjectId(values.company),
            cashier_id: getObjectId(values.cashier),
            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),
            from_promise_date_time: formatDateTimeToUrl(
              values.fromPromiseDateTime,
            ),
            to_promise_date_time: formatDateTimeToUrl(values.toPromiseDateTime),
            from_deadline_time: formatDateTimeToUrl(values.fromDeadlineTime),
            to_deadline_time: formatDateTimeToUrl(values.toDeadlineTime),
            status_from_date_time: formatDateTimeToUrl(
              values.statusFromDateTime,
            ),
            status_to_date_time: formatDateTimeToUrl(values.statusToDateTime),
            warehouse_id: getObjectId(values.warehouse),
            destination_warehouse_id: getObjectId(values.destinationWarehouse),
            contract_owner_id: getObjectId(values.contractOwner),
            sales_id: getObjectId(values.salesRepresentative),
            is_merchant:
              values.isMerchant === BOTH
                ? null
                : values.isMerchant === MERCHANT,
            payment_type: fp.toUpper(stringifyArray(values.paymentTypes)),
            last_mile_driver_id: getObjectId(values.lastMileDriverIds),
          });

          if (!fp.isEmpty(values.dropoffTimeslotIds)) {
            filter.setValue(
              "dropoffTimeslotIds",
              stringifyArray(values.dropoffTimeslotIds.map(getObjectId)),
            );
          }

          if (!fp.isEmpty(values.pickupTimeslotIds)) {
            filter.setValue(
              "pickupTimeslotIds",
              stringifyArray(values.pickupTimeslotIds.map(getObjectId)),
            );
          }

          if (!fp.isEmpty(values.status)) {
            filter.setValueMap({
              status: stringifyArray(values.status),
              include_exception: Boolean(values.includeException),
            });
          }

          if (!fp.isEmpty(values.customerIds)) {
            if (values.includeCustomers) {
              filter.setValue(
                "customer_ids",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_customer_ids",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.driverIds)) {
            if (values.includeDrivers) {
              filter.setValue(
                "driver_ids",
                stringifyArray(values.driverIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_driver_ids",
                stringifyArray(values.driverIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.pickupAttemptCounts)) {
            if (values.includePickupAttemptCounts) {
              filter.setValue(
                "pickup_attempt_counts",
                stringifyArray(values.pickupAttemptCounts),
              );
            } else {
              filter.setValue(
                "exclude_pickup_attempt_counts",
                stringifyArray(values.pickupAttemptCounts),
              );
            }
          }

          if (!fp.isEmpty(values.deliveryAttemptCounts)) {
            if (values.includeDeliveryAttemptCounts) {
              filter.setValue(
                "delivery_attempt_counts",
                stringifyArray(values.deliveryAttemptCounts),
              );
            } else {
              filter.setValue(
                "exclude_delivery_attempt_counts",
                stringifyArray(values.deliveryAttemptCounts),
              );
            }
          }

          if (!fp.isEmpty(values.supplierIds)) {
            if (values.includeSuppliers) {
              filter.setValue(
                "supplier_ids",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_supplier_ids",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.parentSupplierIds)) {
            if (values.includeParentSuppliers) {
              filter.setValue(
                "parent_supplier_ids",
                stringifyArray(values.parentSupplierIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_parent_supplier_ids",
                stringifyArray(values.parentSupplierIds.map(getObjectId)),
              );
            }
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "JobFilterForm", enableReinitialize: true }),
  formValues({
    status: "status",

    isDomestic: "isDomestic",

    fromCityId: "fromCityId",
    toCityId: "toCityId",

    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",

    fromPromiseDateTime: "fromPromiseDateTime",
    toPromiseDateTime: "toPromiseDateTime",

    statusFromDateTime: "statusFromDateTime",
    statusToDateTime: "statusToDateTime",

    fromDeadlineTime: "fromDeadlineTime",
    toDeadlineTime: "toDeadlineTime",

    includeDrivers: "includeDrivers",
    includeCustomers: "includeCustomers",
    includeSuppliers: "includeSuppliers",
    includeParentSuppliers: "includeParentSuppliers",
    includePickupAttemptCounts: "includePickupAttemptCounts",
    includeDeliveryAttemptCounts: "includeDeliveryAttemptCounts",
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .map(fp.update("isDomestic", Boolean))
        .distinctUntilKeyChanged("isDomestic")
        .filter(props => props.dirty)
        .do(props => {
          if (props.fromCityId !== null) {
            props.change("fromCityId", null);
          }

          if (props.toCityId !== null) {
            props.change("toCityId", null);
          }
        }),
    );

    return propsStream.merge(sideEffectsStream);
  }),
  pureComponent(
    fp.pick([
      "dirty",
      "initialValues",
      "status",
      "isDomestic",
      "toDateTime",
      "fromPromiseDateTime",
      "toPromiseDateTime",
      "statusFromDateTime",
      "statusToDateTime",
      "fromDeadlineTime",
      "toDeadlineTime",
      "includeDrivers",
      "includeCustomers",
      "includeSuppliers",
      "includeParentSuppliers",
      "toggleContainer",
      "isFinanceAdmin",
      "includeDeliveryAttemptCounts",
      "includePickupAttemptCounts",
      "getLocalisationMessage",
    ]),
  ),
);

const styles = {
  toggle: {
    thumbSwitchedStyle: { backgroundColor: "#fdd000" },
    trackSwitchedStyle: { backgroundColor: "#e3bf16" },
  },
  chipAutoComplete: {
    chipContainer: {
      maxHeight: "96px",
      overflowY: "auto",
    },
  },
};

JobFilterForm.propTypes = {
  classes: PropTypes.object,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,

  status: PropTypes.array,

  isDomestic: PropTypes.bool,

  fromCityId: PropTypes.object,
  toCityId: PropTypes.object,

  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,
  fromPromiseDateTime: PropTypes.any,
  toPromiseDateTime: PropTypes.any,
  statusFromDateTime: PropTypes.any,
  statusToDateTime: PropTypes.any,
  fromDeadlineTime: PropTypes.any,
  toDeadlineTime: PropTypes.any,

  includeDrivers: PropTypes.bool,
  includeCustomers: PropTypes.bool,
  includeSuppliers: PropTypes.bool,
  includeParentSuppliers: PropTypes.bool,
  includePickupAttemptCounts: PropTypes.bool,
  includeDeliveryAttemptCounts: PropTypes.bool,

  onDismiss: PropTypes.func,

  showCompany: PropTypes.bool.isRequired,
  showMerchant: PropTypes.bool.isRequired,
  showPromiseDate: PropTypes.bool.isRequired,
  showCashier: PropTypes.bool,
  showAttemptCounts: PropTypes.bool,

  toggleContainer: PropTypes.any,

  getCustomerPredictions: PropTypes.func,
  getDriverPredictions: PropTypes.func,
  getWarehousePredictions: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getOwnerUserPredictions: PropTypes.func,
  getSalesUserPredictions: PropTypes.func,
  getTimeSlotPredictions: PropTypes.func,

  isFinanceAdmin: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function JobFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <RenderInNode node={props.toggleContainer}>
        <FormToggle
          name="isDomestic"
          label={getLocalisationMessage("domestic_jobs", "Domestic Jobs")}
          className={classes.toggle}
          thumbSwitchedStyle={styles.toggle.thumbSwitchedStyle}
          trackSwitchedStyle={styles.toggle.trackSwitchedStyle}
        />
      </RenderInNode>

      <Card>
        <CardContent>
          <FlexBox gutter={8} direction="column">
            <FlexBox flex={true}>
              <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormChipAutoComplete
                    name="status"
                    fullWidth={true}
                    options={JobStatusCodes}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage("job_status", "Job Status")}
                    chipContainerStyle={styles.chipAutoComplete.chipContainer}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormCourierTypeChips
                    name="courierTypes"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage(
                      "service_type",
                      "Service Type",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormChipAutoComplete
                    name="paymentTypes"
                    fullWidth={true}
                    options={OrderPaymentTypes}
                    formatOption={x =>
                      formatLocalisedPaymentType(x, getLocalisationMessage)
                    }
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage(
                      "payment_method",
                      "Payment Method",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormChipAutoComplete
                    name="orderSize"
                    fullWidth={true}
                    options={OrderSizeCodes}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage("job_size", "Job Size")}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} className={classes.wrapContent}>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true}>
                  <FormCheckbox
                    name="includeException"
                    label={getLocalisationMessage(
                      "include_exceptions",
                      "Include Exceptions",
                    )}
                    disabled={fp.isEmpty(props.status)}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
              <FlexBox flex={true} direction="column">
                <ListSubheader className={classes.subheader}>
                  {getLocalisationMessage("created_date", "Created Date")}
                </ListSubheader>

                <FlexBox gutter={8} flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormDateField
                        fullWidth={true}
                        name="fromDateTime"
                        hintText={getLocalisationMessage(
                          "from_date",
                          "From Date",
                        )}
                        initialTime={startTime}
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormTimeField
                        fullWidth={true}
                        name="fromDateTime"
                        hintText={getLocalisationMessage(
                          "from_time",
                          "From Time",
                        )}
                      />
                    </FlexBox>

                    {Boolean(props.fromDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => props.change("fromDateTime", null)}
                        >
                          <Cancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormDateField
                        fullWidth={true}
                        name="toDateTime"
                        hintText={getLocalisationMessage("to_date", "To Date")}
                        initialTime={endTime}
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormTimeField
                        fullWidth={true}
                        name="toDateTime"
                        hintText={getLocalisationMessage("to_time", "To Time")}
                      />
                    </FlexBox>

                    {Boolean(props.toDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => props.change("toDateTime", null)}
                        >
                          <Cancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox flex={true} direction="column">
                <ListSubheader className={classes.subheader}>
                  {getLocalisationMessage(
                    "status_changed_date",
                    "Status Changed Date",
                  )}
                </ListSubheader>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true} gutter={8}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_date",
                          "From Date",
                        )}
                        initialTime={startTime}
                        name="statusFromDateTime"
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_time",
                          "From Time",
                        )}
                        name="statusFromDateTime"
                      />
                    </FlexBox>

                    {Boolean(props.statusFromDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() =>
                            props.change("statusFromDateTime", null)
                          }
                        >
                          <Cancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>

                  <FlexBox flex={true} gutter={8}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_date", "To Date")}
                        initialTime={endTime}
                        name="statusToDateTime"
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_time", "To Time")}
                        name="statusToDateTime"
                      />
                    </FlexBox>

                    {Boolean(props.statusToDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => props.change("statusToDateTime", null)}
                        >
                          <Cancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            {props.showPromiseDate && (
              <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true} direction="column">
                  <ListSubheader className={classes.subheader}>
                    {getLocalisationMessage("promise_date", "Promise Date")}
                  </ListSubheader>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true} direction="column">
                        <FormDateField
                          fullWidth={true}
                          name="fromPromiseDateTime"
                          hintText={getLocalisationMessage(
                            "from_date",
                            "From Date",
                          )}
                          initialTime={startTime}
                        />
                      </FlexBox>

                      <FlexBox flex={true} direction="column">
                        <FormTimeField
                          fullWidth={true}
                          name="fromPromiseDateTime"
                          hintText={getLocalisationMessage(
                            "from_time",
                            "From Time",
                          )}
                        />
                      </FlexBox>

                      {Boolean(props.fromPromiseDateTime) && (
                        <FlexBox>
                          <IconButton
                            onClick={() =>
                              props.change("fromPromiseDateTime", null)
                            }
                          >
                            <Cancel />
                          </IconButton>
                        </FlexBox>
                      )}
                    </FlexBox>

                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true} direction="column">
                        <FormDateField
                          fullWidth={true}
                          name="toPromiseDateTime"
                          hintText={getLocalisationMessage(
                            "to_date",
                            "To Date",
                          )}
                          initialTime={endTime}
                        />
                      </FlexBox>

                      <FlexBox flex={true} direction="column">
                        <FormTimeField
                          fullWidth={true}
                          name="toPromiseDateTime"
                          hintText={getLocalisationMessage(
                            "to_time",
                            "To Time",
                          )}
                        />
                      </FlexBox>

                      {Boolean(props.toPromiseDateTime) && (
                        <FlexBox>
                          <IconButton
                            onClick={() =>
                              props.change("toPromiseDateTime", null)
                            }
                          >
                            <Cancel />
                          </IconButton>
                        </FlexBox>
                      )}
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}

            <FlexBox
              gutter={8}
              flex={true}
              align="flex-end"
              className={classes.wrapContent}
            >
              <FlexBox flex={true} direction="column">
                <ListSubheader className={classes.subheader}>
                  {props.isDomestic
                    ? getLocalisationMessage("cities", "Cities")
                    : getLocalisationMessage("countries", "Countries")}
                </ListSubheader>

                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true}>
                    {props.isDomestic ? (
                      <FormCityFilterAutoComplete
                        name="fromCityId"
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_here_to_search",
                          "Type Here To Search",
                        )}
                        label={getLocalisationMessage("from_city")}
                      />
                    ) : (
                      <FormCitySelectField
                        name="fromCityId"
                        optional={true}
                        autoWidth={true}
                        fullWidth={true}
                        domestic={props.isDomestic}
                        label={getLocalisationMessage("from_country")}
                      />
                    )}
                  </FlexBox>

                  <FlexBox flex={true}>
                    {props.isDomestic ? (
                      <FormCityFilterAutoComplete
                        name="toCityId"
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_here_to_search",
                          "Type Here To Search",
                        )}
                        label={getLocalisationMessage("to_city")}
                      />
                    ) : (
                      <FormCitySelectField
                        name="toCityId"
                        optional={true}
                        autoWidth={true}
                        fullWidth={true}
                        domestic={props.isDomestic}
                        label={getLocalisationMessage("to_country")}
                      />
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox flex={true} direction="column">
                <ListSubheader className={classes.subheader}>
                  {getLocalisationMessage(
                    "estimated_delivery",
                    "Estimated Delivery",
                  )}
                </ListSubheader>

                <FlexBox gutter={8} flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_date",
                          "From Date",
                        )}
                        name="fromDeadlineTime"
                        initialTime={startTime}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_time",
                          "From Time",
                        )}
                        name="fromDeadlineTime"
                      />
                    </FlexBox>

                    {Boolean(props.fromDeadlineTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => props.change("fromDeadlineTime", null)}
                        >
                          <Cancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_date", "To Date")}
                        name="toDeadlineTime"
                        initialTime={endTime}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_time", "To Time")}
                        name="toDeadlineTime"
                      />
                    </FlexBox>

                    {Boolean(props.toDeadlineTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => props.change("toDeadlineTime", null)}
                        >
                          <Cancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} gutter={8} className={classes.wrapContent}>
              {props.getCustomerPredictions && (
                <FlexBox gutter={8} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormCustomerChips
                      name="customerIds"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={
                        props.includeCustomers
                          ? getLocalisationMessage(
                              "include_customers",
                              "Include Customers",
                            )
                          : getLocalisationMessage(
                              "exclude_customers",
                              "Exclude Customers",
                            )
                      }
                    />
                  </FlexBox>

                  <FlexBox align="flex-end" className={classes.includeButton}>
                    <IconButton
                      onClick={() =>
                        props.change(
                          "includeCustomers",
                          !props.includeCustomers,
                        )
                      }
                    >
                      {props.includeCustomers ? (
                        <AddCircle color={lightGreen[600]} />
                      ) : (
                        <RemoveCircle color={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}

              {Boolean(
                props.showCompany &&
                  (!props.showCashier || !props.isFinanceAdmin),
              ) && (
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormCompanyAutoComplete
                    name="company"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage("company", "Company")}
                  />
                </FlexBox>
              )}

              {Boolean(props.showCashier && props.isFinanceAdmin) && (
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormCashierAutoComplete
                    name="cashier"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage("cashier", "Cashier")}
                  />
                </FlexBox>
              )}

              {props.showMerchant && (
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormSelectField
                    name="isMerchant"
                    fullWidth={true}
                    autoWidth={true}
                    options={isMerchantOptions}
                    formatOption={value =>
                      formatIsMerchant(value, getLocalisationMessage)
                    }
                    label={getLocalisationMessage("is_merchant", "Is Merchant")}
                  />
                </FlexBox>
              )}
            </FlexBox>

            <FlexBox flex={true} gutter={8} className={classes.wrapContent}>
              {props.getSupplierPredictions && (
                <FlexBox gutter={8} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormSupplierChips
                      fullWidth={true}
                      name="supplierIds"
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={
                        props.includeSuppliers
                          ? getLocalisationMessage(
                              "include_suppliers",
                              "Include Suppliers",
                            )
                          : getLocalisationMessage(
                              "exclude_suppliers",
                              "Exclude Suppliers",
                            )
                      }
                    />
                  </FlexBox>

                  <FlexBox align="flex-end" className={classes.includeButton}>
                    <IconButton
                      onClick={() =>
                        props.change(
                          "includeSuppliers",
                          !props.includeSuppliers,
                        )
                      }
                    >
                      {props.includeSuppliers ? (
                        <AddCircle color={lightGreen[600]} />
                      ) : (
                        <RemoveCircle color={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}

              {props.getSupplierPredictions && (
                <FlexBox gutter={8} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormSupplierChips
                      name="parentSupplierIds"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={
                        props.includeParentSuppliers
                          ? getLocalisationMessage(
                              "include_source_suppliers",
                              "Include Source Suppliers",
                            )
                          : getLocalisationMessage(
                              "exclude_source_suppliers",
                              "Exclude Source Suppliers",
                            )
                      }
                    />
                  </FlexBox>

                  <FlexBox align="flex-end" className={classes.includeButton}>
                    <IconButton
                      onClick={() =>
                        props.change(
                          "includeParentSuppliers",
                          !props.includeParentSuppliers,
                        )
                      }
                    >
                      {props.includeParentSuppliers ? (
                        <AddCircle color={lightGreen[600]} />
                      ) : (
                        <RemoveCircle color={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}

              {props.getDriverPredictions && (
                <FlexBox gutter={8} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormDriverChips
                      name="driverIds"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={
                        props.includeDrivers
                          ? getLocalisationMessage(
                              "include_drivers",
                              "Include Drivers",
                            )
                          : getLocalisationMessage(
                              "exclude_drivers",
                              "Exclude Drivers",
                            )
                      }
                    />
                  </FlexBox>

                  <FlexBox align="flex-end" className={classes.includeButton}>
                    <IconButton
                      onClick={() =>
                        props.change("includeDrivers", !props.includeDrivers)
                      }
                    >
                      {props.includeDrivers ? (
                        <AddCircle color={lightGreen[600]} />
                      ) : (
                        <RemoveCircle color={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>

            {Boolean(
              props.getWarehousePredictions ||
                props.getTimeSlotPredictions ||
                props.getWarehousePredictions,
            ) && (
              <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true} align="flex-end">
                  <FormTimeSlotChips
                    name="pickupTimeslotIds"
                    fullWidth={true}
                    timeslotType={PICKUP}
                    label={getLocalisationMessage(
                      "pickup_timeslot",
                      "Pickup Timeslot",
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} align="flex-end">
                  <FormTimeSlotChips
                    name="dropoffTimeslotIds"
                    fullWidth={true}
                    timeslotType={DROP}
                    label={getLocalisationMessage(
                      "dropoff_timeslot",
                      "Dropoff Timeslot",
                    )}
                  />
                </FlexBox>
                {props.getWarehousePredictions && (
                  <FlexBox flex={true}>
                    <FormWarehouseAutoComplete
                      fullWidth={true}
                      name="warehouse"
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage("warehouse", "Warehouse")}
                    />
                  </FlexBox>
                )}
                {props.getWarehousePredictions && (
                  <FlexBox flex={true}>
                    <FormWarehouseAutoComplete
                      fullWidth={true}
                      name="destinationWarehouse"
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage(
                        "destination_warehouse",
                        "Destination Warehouse",
                      )}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            )}

            {Boolean(
              props.getDriverPredictions ||
                props.getOwnerUserPredictions ||
                props.getSalesUserPredictions,
            ) && (
              <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
                {props.getDriverPredictions && (
                  <FlexBox flex={true}>
                    <FormDriverAutoComplete
                      name="lastMileDriverIds"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage(
                        "last_mile_driver",
                        "Last Mile Driver",
                      )}
                    />
                  </FlexBox>
                )}

                {props.getOwnerUserPredictions && (
                  <FlexBox flex={true}>
                    <FormOwnerUserAutoComplete
                      name="contractOwner"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage(
                        "account_owner",
                        "Account Owner",
                      )}
                    />
                  </FlexBox>
                )}

                {props.getSalesUserPredictions && (
                  <FlexBox flex={true}>
                    <FormSalesRepresentativeUserAutoComplete
                      name="salesRepresentative"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage(
                        "sales_representative",
                        "Sales Representative",
                      )}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            )}

            {props.showAttemptCounts && (
              <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormChipInput
                        fullWidth={true}
                        name="pickupAttemptCounts"
                        label={
                          props.includePickupAttemptCounts
                            ? getLocalisationMessage(
                                "include_pickup_attempt_counts",
                                "Include Pickup Attempt Counts",
                              )
                            : getLocalisationMessage(
                                "exclude_pickup_attempt_counts",
                                "Exclude Pickup Attempt Counts",
                              )
                        }
                      />
                    </FlexBox>

                    <FlexBox align="flex-end" className={classes.includeButton}>
                      <IconButton
                        onClick={() =>
                          props.change(
                            "includePickupAttemptCounts",
                            !props.includePickupAttemptCounts,
                          )
                        }
                      >
                        {props.includePickupAttemptCounts ? (
                          <AddCircle color={lightGreen[600]} />
                        ) : (
                          <RemoveCircle color={red[500]} />
                        )}
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormChipInput
                        fullWidth={true}
                        name="deliveryAttemptCounts"
                        label={
                          props.includeDeliveryAttemptCounts
                            ? getLocalisationMessage(
                                "include_delivery_attempt_counts",
                                "Include Delivery Attempt Counts",
                              )
                            : getLocalisationMessage(
                                "exclude_delivery_attempt_counts",
                                "Exclude Delivery Attempt Counts",
                              )
                        }
                      />
                    </FlexBox>

                    <FlexBox align="flex-end" className={classes.includeButton}>
                      <IconButton
                        onClick={() =>
                          props.change(
                            "includeDeliveryAttemptCounts",
                            !props.includeDeliveryAttemptCounts,
                          )
                        }
                      >
                        {props.includeDeliveryAttemptCounts ? (
                          <AddCircle color={lightGreen[600]} />
                        ) : (
                          <RemoveCircle color={red[500]} />
                        )}
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox justify="flex-end">
            <Button
              label={getLocalisationMessage("clear", "Clear")}
              onClick={() => {
                const includeKeys = [
                  "includeCustomers",
                  "includeDrivers",
                  "includeSuppliers",
                  "includeParentSuppliers",
                  "includePickupAttemptCounts",
                  "includeDeliveryAttemptCounts",
                ];

                fp.keys(props.initialValues).forEach(key => {
                  if (includeKeys.indexOf(key) === -1) {
                    props.change(key, null);
                  } else {
                    props.change(key, true);
                  }
                });

                props.change("isDomestic", true);
              }}
            />

            {props.dirty ? (
              <Button onClick={props.reset}>
                {getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}

            <Button type="submit">
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(JobFilterForm);
