import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { spring, Motion } from "react-motion";
import { Collapse } from "react-collapse";
import { NewReleases, CheckCircle, VisibilityOff } from "@material-ui/icons";
import DriverAlertInfo from "./DriverAlertInfo";
import DriverAlertProgress from "./DriverAlertProgress";
import DriverAvatarMultiLine from "./DriverAvatarMultiLine";
import AlertLocationUpdateInfo from "./AlertLocationUpdateInfo";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import PageLoading from "../ui-core/PageLoading";

export const MUTED_TYPE = "muted";
export const DANGER_TYPE = "danger";
export const PRIMARY_TYPE = "primary";
export const SUCCESS_TYPE = "success";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
  useSheet({
    root: {
      "-ms-user-select": "none",
      "-moz-user-select": "none",
      "-webkit-user-select": "none",
      userSelect: "none",
      position: "relative",
      fontFamily: "Blogger Sans",
    },
    "root-hover": {
      "&:hover": {
        backgroundColor: "aliceblue",
      },
    },
    item: {
      cursor: "pointer",
    },
    "root-background-success": {
      backgroundColor: "#8BC34A",
    },
    "root-background-danger": {
      backgroundColor: "#D32F2F",
    },
    "root-background-muted": {
      backgroundColor: "#9E9E9E",
    },
    "root-background-primary": {
      backgroundColor: "#2196F3",
    },
    driverInfo: {
      "& > div": {
        lineHeight: "18px",
        color: "#d2d2d2",
        "&:first-child": {
          color: "#fefefe",
        },
      },
    },
    description: {
      margin: 0,
      color: "#fefefe",
    },
    driverActions: {
      "& > div": {
        minHeight: "48px",
        width: "48px",
      },
    },
    avatarContainer: {
      padding: "4px 12px",
    },
    alertStatus: {
      cursor: "pointer",
      position: "relative",
      height: "80px",
      padding: "12px 0",
      backgroundColor: "rgba(40,40,40,0.15)",
    },
    statusIcon: {
      padding: "16px",
    },
    statusInfo: {
      "& > div": {
        lineHeight: "18px",
        color: "rgba(255, 255, 255, 0.54)",
        "&:first-child": {
          color: "#fefefe",
        },
      },
    },
    alerts: {
      padding: "0 16px",
      "& > div": {
        fontSize: "16px",
        "&:last-child": {
          fontSize: "14px",
        },
      },
    },
    count: {
      color: "#8a7a7a",
    },
    countInactive: {
      color: "rgba(162, 162, 162, 0.54)",
    },
    countOpen: {
      color: "#fff",
    },
    loader: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: "absolute",
    },
  }),
  withState("state", "setState", { collapsed: false }),
  mapPropsStream(propsStream => {
    const typeStream = propsStream
      .distinctUntilKeyChanged("item")
      .map(props => {
        let type;

        if (props.item.get("status") === INACTIVE) {
          type = MUTED_TYPE;
        }

        if (props.item.get("status") === ACTIVE && props.item.get("busy")) {
          type = DANGER_TYPE;
        }

        if (props.item.get("status") === ACTIVE && !props.item.get("busy")) {
          type = SUCCESS_TYPE;
        }

        // if (
        //   props.item.get("driver_status") === ACTIVE &&
        //   props.item.get("last_week_active_alerts") === 0 &&
        //   props.item.get("orders") === 0
        // ) {
        //   type = PRIMARY_TYPE;
        // }

        return type;
      })
      .startWith(PRIMARY_TYPE)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(typeStream, (props, type) => ({
        ...props,
        type,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "state",
      "item",
      "isLoading",
      "itemOpen",
      "type",
      "alertDetailed",
    ]),
  ),
);

NewRadarDriverItem.propTypes = {
  classes: PropTypes.object,

  isLoading: PropTypes.bool,

  state: PropTypes.object,
  setState: PropTypes.func,

  itemOpen: PropTypes.bool,

  onItemClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  onAlertStatusClick: PropTypes.func,

  item: PropTypes.instanceOf(Immutable.Map),
  driverDetails: PropTypes.instanceOf(Immutable.Map),
  driverLastAlert: PropTypes.instanceOf(Immutable.Map),

  type: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function NewRadarDriverItem(props) {
  const { classes, type, state, getLocalisationMessage } = props;

  return (
    <FlexBox
      flex="none"
      container={8}
      className={cx(classes.root, {
        [classes.item]: !props.itemOpen,
        [classes[`root-background-${type}`]]: props.itemOpen,
        [classes["root-hover"]]: !props.itemOpen,
      })}
      onClick={() => props.onItemClick(props.item.get("driver_id"))}
    >
      <FlexBox gutter={8} flex={true} direction="column">
        <FlexBox>
          <FlexBox gutter={8} flex={true} style={{ padding: "8px 12px" }}>
            <FlexBox flex={true}>
              <DriverAvatarMultiLine
                open={props.itemOpen}
                inactive={props.item.get("status") === INACTIVE}
                vehicleType={props.item.getIn(["vehicle", "type"])}
                driverPhoto={props.item.get("photo")}
                firstLine={props.item.get("full_name")}
                secondLine={props.item.getIn(["supplier", "name"])}
              />
            </FlexBox>

            <FlexBox>
              <FlexBox className={classes.driverActions}>
                <FlexBox
                  align="center"
                  justify="center"
                  className={cx({
                    [classes.count]: type !== MUTED_TYPE && !props.itemOpen,
                    [classes.countOpen]: type !== MUTED_TYPE && props.itemOpen,
                    [classes.countInactive]: type === MUTED_TYPE,
                  })}
                >
                  <DriverAlertProgress
                    itemOpen={props.itemOpen}
                    totalOrders={props.item.get("order_count")}
                    activeOrders={props.item.get(
                      "todays_completed_order_count",
                      0,
                    )}
                  />
                </FlexBox>

                {!props.itemOpen && (
                  <FlexBox align="center" justify="center">
                    {type === MUTED_TYPE ? (
                      <VisibilityOff color="rgba(162, 162, 162, 0.54)" />
                    ) : type === DANGER_TYPE ? (
                      <NewReleases color="#D32F2F" />
                    ) : type === SUCCESS_TYPE ? (
                      <CheckCircle color="#8BC34A" />
                    ) : null}
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <Collapse
          isOpened={props.itemOpen}
          onRest={() =>
            props.itemOpen && props.setState(fp.set("collapsed", true))
          }
        >
          <FlexBox flex={true} direction="column">
            <Motion
              defaultStyle={{ opacity: 0 }}
              style={{ opacity: spring(state.collapsed ? 1 : 0) }}
            >
              {({ opacity }) => (
                <DriverAlertInfo
                  style={{ opacity }}
                  driverLastAlert={props.driverLastAlert}
                />
              )}
            </Motion>

            <FlexBox
              className={classes.alertStatus}
              onClick={event => {
                event.stopPropagation();

                props.onAlertStatusClick(props.item);
              }}
            >
              <Motion
                defaultStyle={{ left: -500 }}
                style={{ left: spring(state.collapsed ? 0 : -500) }}
              >
                {({ left }) => (
                  <FlexBox
                    flex={true}
                    style={{
                      left: `${left}px`,
                      position: "absolute",
                      width: "100%",
                    }}
                  >
                    <FlexBox flex={true}>
                      <AlertLocationUpdateInfo
                        item={props.item}
                        detailed={props.driverDetails}
                      />
                    </FlexBox>

                    <FlexBox>
                      <FlexBox
                        direction="column"
                        className={cx(classes.statusInfo, classes.alerts)}
                        justify="center"
                        align="center"
                      >
                        <FlexBox>
                          {props.item.get("all_alerts")}{" "}
                          {getLocalisationMessage("alerts", "Alerts")}
                        </FlexBox>

                        <FlexBox>
                          {getLocalisationMessage("this_week", "this week")}
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                )}
              </Motion>
            </FlexBox>
          </FlexBox>
        </Collapse>
        <PageLoading isLoading={props.isLoading && props.itemOpen} />
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NewRadarDriverItem);
