import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  mapListResponseStream,
  mapObjectResponseStream,
} from "../../helpers/ApiUtils";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getAlertsList,
  deleteAlertItem,
  dismissAlertItem,
  getAlertsHistoryList,
  getDriverDetailedAlert,
} from "../../api/admin/AdminAlertsApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import PageLoading from "../../components/ui-core/PageLoading";
import DriverAlertHistoryInfo from "../../components/drivers-radar/DriverAlertHistoryInfo";
import DriverAlertHistoryList from "../../components/drivers-radar/DriverAlertHistoryList";
import DriverAlertHistoryTabs, {
  ALL_TAB,
  WEEK_TAB,
  CURRENT_TAB,
} from "../../components/drivers-radar/DriverAlertHistoryTabs";

const enhancer = compose(
  renderIf(props => props.driverId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    paper: {
      minWidth: "1000px",
      maxWidth: "1000px",
      fontFamily: "Blogger Sans",
    },
    waitingPaper: {
      backgroundColor: "#ccc",
    },
    alertedPaper: {
      backgroundColor: "#D32F2F",
    },
    successPaper: {
      backgroundColor: "#8BC34A",
    },
    listContainer: {
      height: "320px",
      backgroundColor: "#eee",
    },
    infoContainer: {
      height: "88px",
    },
  }),
  withState("state", "setState", { isLoading: false, excludeCount: 0 }),
  mapPropsStream(
    pipeStreams(propsStream => {
      const alertItemStream = propsStream
        .distinctUntilKeyChanged("driverId")
        .switchMap(props =>
          getAlertsList(
            props.filter
              .setValue("driver_id", props.driverId)
              .setValue("alert_statuses", null),
          ).catch(error => Observable.of({ error })),
        )
        .map(
          fp.flow(
            fp.update("pending", Boolean),
            fp.update(
              "payload",
              fp.flow(fp.get("data.list"), fp.first, fp.toPlainObject),
            ),
            Immutable.fromJS,
          ),
        )
        .distinctUntilChanged(isEqualData);

      const alertItemDetailStream = propsStream
        .distinctUntilKeyChanged("driverId")
        .switchMap(props =>
          getDriverDetailedAlert(props.driverId).let(mapObjectResponseStream),
        )
        .distinctUntilChanged(isEqualData);

      const historyItemStream = propsStream
        .distinctUntilKeyChanged("filter")
        .switchMap(props =>
          getAlertsHistoryList(props.driverId, props.filter).let(
            mapListResponseStream,
          ),
        )
        .distinctUntilChanged(isEqualData);

      const batchGetAlertsHistoryListCount = (driverId, filters) =>
        Observable.from(
          filters
            .map((filter, key) =>
              getAlertsHistoryList(driverId, filter)
                .catch(error => Observable.of({ error }))
                .map(fp.get("payload.data.total"))
                .takeLast(1)
                .delay(1000)
                .map(value => ({ key, value })),
            )
            .toArray(),
        )
          .concatAll()
          .scan(
            (acc, response) => acc.set(response.key, response.value),
            Immutable.Map(),
          )
          .startWith(Immutable.Map());

      return propsStream
        .combineLatest(
          historyItemStream,
          alertItemStream,
          alertItemDetailStream,
          (
            props,
            historyListResponse,
            alertItemResponse,
            alertItemDetailResponse,
          ) => ({
            ...props,
            alertItem: alertItemResponse.get("payload"),
            alertItemDetail: alertItemDetailResponse.get("payload"),
            historyList: historyListResponse.getIn(["payload", "list"]),
            isLoading:
              historyListResponse.get("pending") ||
              alertItemResponse.get("pending") ||
              alertItemDetailResponse.get("pending"),
            batchGetAlertsHistoryListCount,
          }),
        )
        .distinctUntilChanged(isEqualData);
    }),
  ),
  pureComponent(
    fp.pick([
      "state",
      "filter",
      "driverId",
      "isLoading",
      "alertItem",
      "historyList",
      "alertItemDetail",
      "batchGetAlertsHistoryListCount",
    ]),
  ),
);

AdminDriverAlertHistoryWrapper.propTypes = {
  classes: PropTypes.object,

  driverId: PropTypes.number.isRequired,
  alertItemDetail: PropTypes.instanceOf(Immutable.Map),
  tab: PropTypes.oneOf([ALL_TAB, WEEK_TAB, CURRENT_TAB]),

  isLoading: PropTypes.bool,

  onTabChange: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,

  batchGetAlertsHistoryListCount: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  state: PropTypes.object,
  setState: PropTypes.func,

  alertItem: PropTypes.instanceOf(Immutable.Map),
  historyList: PropTypes.instanceOf(Immutable.List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminDriverAlertHistoryWrapper(props) {
  const { classes, state, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      paperClassName={cx(classes.paper, {
        [classes.waitingPaper]: props.isLoading,
        [classes.alertedPaper]:
          !props.isLoading &&
          props.alertItem.get("last_week_active_alerts") > 0,
        [classes.successPaper]:
          (!props.isLoading &&
            !props.alertItem.get("last_week_active_alerts")) ||
          props.alertItem.get("last_week_active_alerts") === 0,
      })}
      onRequestClose={props.onRequestClose}
    >
      <PageLoading isLoading={state.isLoading || props.isLoading} />

      <FlexBox>
        <FlexBox flex={true} direction="column">
          <FlexBox className={classes.infoContainer}>
            <DriverAlertHistoryInfo
              onCloseClick={props.onRequestClose}
              alerts={props.alertItem}
              alertDetailed={props.alertItemDetail}
            />
          </FlexBox>

          <FlexBox>
            <DriverAlertHistoryTabs
              tab={props.tab}
              driverId={props.driverId}
              filter={props.filter}
              onTabChange={props.onTabChange}
              excludeCount={state.excludeCount}
              batchGetAlertsHistoryListCount={
                props.batchGetAlertsHistoryListCount
              }
            />
          </FlexBox>

          <FlexBox flex={true} className={classes.listContainer}>
            {Boolean(
              !props.isLoading &&
                props.historyList.size &&
                props.alertItem.size,
            ) && (
              <DriverAlertHistoryList
                list={props.historyList}
                alertDetailed={props.alertItemDetail}
                onDeleteClick={id => {
                  deleteAlertItem(id)
                    .then(() => {
                      props.setState(fp.update("excludeCount", x => x + 1));
                      props.showSuccessMessage(
                        getLocalisationMessage(
                          "alert_successfully_deleted",
                          "Alert Successfully Deleted",
                        ),
                      );
                    })
                    .catch(error => {
                      props.showErrorMessage(error);
                    });
                }}
                onDismissClick={id =>
                  dismissAlertItem(id)
                    .then(() => {
                      props.setState(fp.update("excludeCount", x => x + 1));
                      props.showSuccessMessage(
                        getLocalisationMessage(
                          "alert_successfully_dismissed",
                          "Alert Successfully Dismissed",
                        ),
                      );
                    })
                    .catch(error => {
                      props.showErrorMessage(error);
                    })
                }
              />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminDriverAlertHistoryWrapper);
