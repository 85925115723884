import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITableInternationalReports, {
  RENDER,
} from "../../components/orders-core/MUITableInternationalReports";
import {
  DeleteForever,
  Edit,
  FilterList,
  Print,
  Refresh,
} from "@material-ui/icons";
import {
  createInternationalLetter,
  deleteInternationalLetter,
  getInternationalLetterItem,
  getInternationalLetterList,
  updateInternationalLetter,
} from "../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../helpers/DataUtils";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import InternationalLettersForm from "../../components/order-create-wizard-new/international-reports/InternationalLettersForm";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import _ from "lodash";
import {
  formatDate,
  formatDateTimeToUrl,
  safeParseDate,
} from "../../helpers/FormatUtils";
import {
  localisationAction,
  localisationCategory,
} from "./AdminInternationalParcelContainer";
import InternationalLetterFilter from "../../components/order-create-wizard-new/international-reports/InternationalLetterFilter";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import CustomButton, { SECONDARY } from "../../components/ui-core/CustomButton";
import { getForm55PDF } from "../../helpers/FormUtils";

const CN_55_PDF = `${API_V2_ROOT_URL}/cn55`;
const CN_55_REPORT_PDF = `${API_V2_ROOT_URL}/cn55_report`;

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  title: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: 500,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
);

AdminInternationalLetterContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminInternationalLetterContainer(props) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
  });
  const [id, setId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [data, setData] = useState({});

  const classes = useStyles();

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });
  const [success, setSuccess] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [cn55PDF, setCn55PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_55_PDF,
  });

  const [cn55ReportPDF, setCn55ReportPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_55_REPORT_PDF,
  });

  useEffect(() => {
    setIsLoading(true);
    getInternationalLetterList(filter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    if (id) {
      getInternationalLetterItem(id).then(res => {
        if (res && res.data) {
          setData(res.data);
        }
      });
    }
  }, [id]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const onDelete = removeId => {
    deleteInternationalLetter(removeId)
      .then(() => {
        setDeleteId(0);
        props.showSuccessMessage(
          props.i18n.get("successfully_removed", "Successfully Removed"),
        );
        refreshList();
      })
      .catch(error => {
        props.showErrorMessage(error);
        setDeleteId(0);
      });
  };

  return (
    <AdminAppLayout
      title={props.i18n.get("international_letter", "International letter")}
    >
      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {props.i18n.get(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <Card>
        <CardContent>
          <FlexBox direction="column" style={{ gap: 10 }}>
            <FlexBox
              style={{
                height: "35px",
                padding: "2px 0 2px 20px",
              }}
              justify="space-between"
              align="center"
            >
              <Typography variant="h5" className={classes.title}>
                {props.i18n.get("international_letter", "International Letter")}
              </Typography>

              <FlexBox justify={JUSTIFY_END} align={ALIGN_CENTER}>
                <CustomButton
                  color={SECONDARY}
                  className={classes.pLR1rem}
                  onClick={() =>
                    getForm55PDF(
                      cn55ReportPDF,
                      setCn55ReportPDF,
                      IS_PRINTING,
                      selectedItems,
                      filter,
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    cn55ReportPDF[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {props.i18n.get("reference", "Reference #")}
                </CustomButton>
                <CustomButton
                  color={SECONDARY}
                  className={classes.pLR1rem}
                  onClick={() =>
                    getForm55PDF(
                      cn55PDF,
                      setCn55PDF,
                      IS_PRINTING,
                      selectedItems,
                      filter,
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    cn55PDF[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {props.i18n.get("cn55", "CN 55")}
                </CustomButton>
                <IconButton
                  className={isLoading && classes.refresh}
                  tooltip={props.i18n.get("refresh", "Refresh")}
                  onClick={() => refreshList()}
                >
                  <Refresh />
                </IconButton>

                <IconButton
                  tooltip={props.i18n.get("filter", "Filter")}
                  onClick={() => setOpenFilter(true)}
                >
                  <FilterList />
                </IconButton>
              </FlexBox>
            </FlexBox>

            <InternationalLetterFilter
              open={openFilter}
              onRequestClose={() => setOpenFilter(false)}
              filter={filter}
              setFilter={setFilter}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />

            <InternationalLettersForm
              onClose={() => {
                setData({});
                setId(null);
              }}
              success={success}
              setSuccess={setSuccess}
              update={id > 0}
              onSubmit={values => {
                if (id) {
                  updateInternationalLetter(id, {
                    ...toSnakeCase(values),
                    mbag_items_more_than5_kg: values.mbagItemsMoreThan5Kg,
                    date: formatDateTimeToUrl(values.date),
                    formation_date: formatDateTimeToUrl(values.formationDate),
                  })
                    .then(res => {
                      if (res && res.status === "success") {
                        props.showSuccessMessage(
                          props.i18n.get("successfully_updated"),
                        );

                        refreshList();
                        setData({});
                        setId(null);
                      }
                    })
                    .catch(error => props.showErrorMessage(error));
                } else {
                  createInternationalLetter({
                    ...toSnakeCase(values),
                    mbag_items_more_than5_kg: values.mbagItemsMoreThan5Kg,
                    date: formatDateTimeToUrl(values.date),
                    formation_date: formatDateTimeToUrl(values.formationDate),
                  })
                    .then(res => {
                      if (res && res.status === "success") {
                        props.showSuccessMessage(
                          props.i18n.get("successfully_created"),
                        );

                        refreshList();
                        setData({});
                        setSuccess(true);
                      }
                    })
                    .catch(error => props.showErrorMessage(error));
                }
              }}
              initialValues={{
                ...toCamelCase(data),
                date: safeParseDate(data.date),
                formationDate: safeParseDate(data.formation_date),
                mbagItemsMoreThan5Kg: data.mbag_items_more_than5_kg,
              }}
            />

            <Divider style={{ height: 1, weight: "100%" }} />

            <MUITableInternationalReports
              isLoading={isLoading}
              size="small"
              list={list}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              total={total}
              activeId={id}
              page={filter.page}
              rowsPerPage={filter.size}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              withCheckbox={true}
              orderBy={filter.order_by}
              orderByDirection={filter.order_by_direction}
              setOrderBy={v =>
                setFilter({
                  ...filter,
                  order_by: v,
                  order_by_direction: filter.order_by_direction
                    ? filter.order_by_direction === "desc"
                      ? "asc"
                      : "desc"
                    : "asc",
                })
              }
              columns={[
                {
                  type: RENDER,
                  isSortable: false,
                  name: "date",
                  align: "center",
                  label: props.i18n.get("date", "Date"),
                  render: row => formatDate(_.get(row, `date`), "dd-MM-yyyy"),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "formation_date",
                  label: props.i18n.get("formation_date", "Formation Date"),
                  render: row =>
                    formatDate(_.get(row, `formation_date`), "dd-MM-yyyy"),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "action",
                  label: props.i18n.get("action", "Action"),
                  render: row =>
                    localisationAction(
                      _.get(row, `action`, props.i18n.get("na", "N/A")),
                      props.i18n,
                    ),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "category",
                  label: props.i18n.get("category", "Category"),
                  render: row =>
                    localisationCategory(
                      _.get(row, `category`, props.i18n.get("na", "N/A")),
                      props.i18n,
                    ),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "from",
                  label: props.i18n.get("from", "From"),
                  render: row =>
                    `${_.get(
                      row,
                      `from_country.name`,
                      props.i18n.get("na", "N/A"),
                    )} -> ${_.get(
                      row,
                      `from_city.name`,
                      props.i18n.get("na", "N/A"),
                    )} (${_.get(
                      row,
                      `from_city.code`,
                      props.i18n.get("na", "N/A"),
                    )})`,
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "to",
                  label: props.i18n.get("to", "to"),
                  render: row =>
                    `${_.get(
                      row,
                      `to_country.name`,
                      props.i18n.get("na", "N/A"),
                    )} -> ${_.get(
                      row,
                      `to_city.name`,
                      props.i18n.get("na", "N/A"),
                    )} (${_.get(
                      row,
                      `to_city.code`,
                      props.i18n.get("na", "N/A"),
                    )})`,
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "transit_region",
                  label: props.i18n.get("through", "Through"),
                  render: row =>
                    _.get(row, `transit_region`)
                      ? _.get(row, `transit_region.parent`)
                        ? `${_.get(
                            row,
                            `transit_region.parent.name`,
                            props.i18n.get("na", "N/A"),
                          )} -> ${_.get(
                            row,
                            `transit_region.name`,
                            props.i18n.get("na", "N/A"),
                          )} (${_.get(
                            row,
                            `transit_region.code`,
                            props.i18n.get("na", "N/A"),
                          )})`
                        : _.get(
                            row,
                            `transit_region.code`,
                            props.i18n.get("na", "N/A"),
                          )
                        ? `${_.get(
                            row,
                            `transit_region.name`,
                            props.i18n.get("na", "N/A"),
                          )} (${_.get(
                            row,
                            `transit_region.code`,
                            props.i18n.get("na", "N/A"),
                          )})`
                        : _.get(
                            row,
                            `transit_region.name`,
                            props.i18n.get("na", "N/A"),
                          )
                      : props.i18n.get("na", "N?A"),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "subclass",
                  label: props.i18n.get("subclass", "Subclass"),
                  render: row =>
                    _.get(row, `subclass.name`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "format",
                  label: props.i18n.get("format", "Format"),
                  render: row =>
                    _.get(row, `format.name`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "weight",
                  label: props.i18n.get("weight", "Weight"),
                  render: row =>
                    _.get(row, `weight`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  isSortable: true,
                  name: "dispatch_number",
                  label: props.i18n.get("dispatch_number", "Dispatch No."),
                  render: row =>
                    _.get(row, `dispatch_number`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "registered",
                  label: props.i18n.get("registered", "Registered"),
                  render: row =>
                    _.get(row, `registered`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "valued",
                  label: props.i18n.get("valued", "Valued"),
                  render: row =>
                    _.get(row, `valued`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "un_registered",
                  label: props.i18n.get("tracked", "Tracked"),
                  render: row =>
                    _.get(row, `un_registered`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "comments",
                  label: props.i18n.get("comments", "Comments"),
                  render: row =>
                    _.get(row, `comments`) && _.get(row, `comments`).length > 50
                      ? `${_.get(row, `comments`).slice(0, 50)}...`
                      : _.get(row, `comments`, props.i18n.get("na", "N/A")),
                },
                // {
                //   type: RENDER,
                //   name: "type",
                //   label: props.i18n.get("type", "Type"),
                //   render: row =>
                //     _.get(row, `mbag_type.name`, props.i18n.get("na", "N/A")),
                // },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "mbag_items_more_than5_kg",
                  label: props.i18n.get("weight_m_5_kg", "Weight M > 5kg"),
                  render: row =>
                    _.get(
                      row,
                      `mbag_items_more_than5_kg`,
                      props.i18n.get("na", "N/A"),
                    ),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "action",
                  label: props.i18n.get("action", "Action"),
                  align: "center",
                  render: row => (
                    <FlexBox
                      flex={true}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      style={{ gap: 16 }}
                    >
                      <IconButton onClick={() => setDeleteId(_.get(row, "id"))}>
                        <DeleteForever />
                      </IconButton>

                      <IconButton onClick={() => setId(_.get(row, "id"))}>
                        <Edit />
                      </IconButton>
                    </FlexBox>
                  ),
                },
              ]}
            />
          </FlexBox>
        </CardContent>
      </Card>
    </AdminAppLayout>
  );
}

export default enhancer(AdminInternationalLetterContainer);
