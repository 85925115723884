import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import BatchOrderCanceledUpdate from "../../components/orders-core/BatchOrderCanceledUpdate";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

BatchCanceledUpdateDialogWrapper.propTypes = {
  open: PropTypes.bool,
  isBulkReturning: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setIsCanceled: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function BatchCanceledUpdateDialogWrapper({
  initialValues,
  setIsCanceled,
  ...props
}) {
  return (
    <BatchOrderCanceledUpdate
      open={props.open}
      initialValues={{
        ...initialValues,
        privacy: PUBLIC,
        supplier: { id: 1 },
      }}
      setIsCanceled={setIsCanceled}
      onRequestClose={props.onRequestClose}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
    />
  );
}

export default enhancer(BatchCanceledUpdateDialogWrapper);
