import React from "react";
import _ from "lodash";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "../ui-core/NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import ActivityHistoryStatus, {
  SMS,
  EMAIL,
  WHATSAPP,
} from "../../constants/ActivityHistoryStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

const formatActivityHistory = (code, getLocalisationMessage) => {
  switch (code) {
    case SMS:
      return getLocalisationMessage("sms", "SMS");

    case WHATSAPP:
      return getLocalisationMessage("whatsapp", "WHATSAPP");

    case EMAIL:
      return getLocalisationMessage("email", "EMAIL");

    default:
      return getLocalisationMessage(code, formatText(code));
  }
};

ActivityHistoryTableTabs.propTypes = {
  location: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function ActivityHistoryTableTabs({ location, getLocalisationMessage }) {
  const query = _.omit(location.query, ["page"]);
  const value = ActivityHistoryStatus.has(query.logType)
    ? query.logType
    : ActivityHistoryStatus.first();

  return (
    <NavTabs
      width={540}
      value={value}
      tabs={ActivityHistoryStatus.map(type => ({
        value: type,
        to: { pathname: location.pathname, query: { ...query, logType: type } },
        label: (
          <TabBadge
            label={formatActivityHistory(type, getLocalisationMessage)}
          />
        ),
      })).toArray()}
    />
  );
}

export default enhancer(ActivityHistoryTableTabs);
