import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List as MuiList,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FlexBox from "../ui-core/FlexBox";
import { green, grey, red, yellow } from "@material-ui/core/colors";
import { isConsolidatedBag } from "../../helpers/OrderOutboundSortingHelper";
import { getValue } from "../../helpers/DataUtils";

export const shouldOpen = (order, warehouseId) => {
  if (getValue(order, "type") === "CONTAINER") return true;

  const toWarehouseId = getValue(order, "to_warehouse.id");

  if (toWarehouseId) return toWarehouseId === warehouseId;

  return getValue(order, "warehouse.id") === warehouseId;
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withTheme,
);
const useStyles = makeStyles({
  total: { backgroundColor: grey[400], color: "black" },
  verified: { backgroundColor: green[300], color: "black" },
  unverified: { backgroundColor: yellow[300], color: "black" },
  misRouted: { backgroundColor: yellow[900], color: "black" },
  failed: { backgroundColor: red[500], color: "black" },
  listItem: {
    margin: ".5rem 0",
  },
});

OrderOfflineInboundSortingVerifyOrdersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  theme: PropTypes.object,
  orders: PropTypes.array,
  warehouseId: PropTypes.number,
};

function OrderOfflineInboundSortingVerifyOrdersDialog(props) {
  const classes = useStyles();
  const { getLocalisationMessage, orders } = props;

  const [shipments, setShipments] = useState([]);
  const [verifiedOrders, setVerifiedOrders] = useState([]);
  const [unVerifiedOrders, setUnVerifiedOrders] = useState([]);
  const [misRoutedOrders, setMisRoutedOrders] = useState([]);
  const [failedOrders, setFailedOrders] = useState([]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      // eslint-disable-next-line array-callback-return
      orders.map(order => {
        const type =
          getValue(order, "orders.type") || getValue(order, "batch.type");

        const needOpen = shouldOpen(order, props.warehouseId);
        const unOpenedRegistry = !needOpen && type;

        const isConsolidated = isConsolidatedBag(getValue(order, "batch.type"));

        if (getValue(order, "error")) {
          setFailedOrders(prev => [...prev, order.barcode]);
        } else if (!type || needOpen || unOpenedRegistry) {
          setShipments(prev => [...prev, order.barcode]);

          if (order.scanned && order.createdDate) {
            setVerifiedOrders(prev => [...prev, order.barcode]);
          } else {
            setUnVerifiedOrders(prev => [...prev, order.barcode]);
          }

          if (!order.parentId && !isConsolidated) {
            setMisRoutedOrders(prev => [...prev, order.barcode]);
          }
        }
      });
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          width: "500px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${getLocalisationMessage("verify_orders", "Verify Orders")}`}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <MuiList dense={true}>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage("total_orders", "Total Orders")}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.total}>{shipments.length}</Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "verified_orders",
                "Verified Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.verified}>
                {verifiedOrders.length}
              </Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "unverified_orders",
                "Unverified Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.unverified}>
                {unVerifiedOrders.length}
              </Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "misrouted_orders",
                "Misrouted Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.misRouted}>
                {misRoutedOrders.length}
              </Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "non_existed_orders",
                "Non Existed Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.failed}>{failedOrders.length}</Avatar>
            </ListItemSecondaryAction>
          </ListItem>
        </MuiList>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={props.onConfirm}
          >
            {getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderOfflineInboundSortingVerifyOrdersDialog);
