import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormCountryAutoComplete from "../form/FormCountryAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import {
  createDateValidator,
  createNotEmptyValidator,
  createNotFalsyValidator,
} from "../../helpers/FormUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "HolidaySettingsForm",
    enableReinitialize: true,
  }),
);

HolidaySettingsForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function HolidaySettingsForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox container={8} element={<form onSubmit={props.handleSubmit} />}>
      <FlexBox gutter={8} flex={true} direction="column">
        <Card>
          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox flex={true} gutter={8} direction="column">
              <PageLoading isLoading={props.submitting} />

              <FlexBox flex={true}>
                <FormTextField
                  name="holidayName"
                  label={getLocalisationMessage("holiday", "Holiday")}
                  fullWidth={true}
                  validate={createNotFalsyValidator(
                    getLocalisationMessage(
                      "enter_holiday_name",
                      "Enter Holiday Name",
                    ),
                  )}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormCountryAutoComplete
                  name="country"
                  label={getLocalisationMessage("country", "Country")}
                  fullWidth={true}
                  validate={createNotEmptyValidator(
                    getLocalisationMessage("select_country", "Select Country"),
                  )}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                />
              </FlexBox>

              <FlexBox>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true}>
                    <FormDateField
                      name="startDate"
                      label={getLocalisationMessage("start_date", "Start Date")}
                      validate={createDateValidator(
                        getLocalisationMessage(
                          "select_start_date",
                          "Select Start Date",
                        ),
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormDateField
                      name="endDate"
                      label={getLocalisationMessage("end_date", "End Date")}
                      validate={createDateValidator(
                        getLocalisationMessage(
                          "select_end_date",
                          "Select End Date",
                        ),
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox>
                <FormCheckbox
                  name="repeated"
                  label={getLocalisationMessage("recurring", "Recurring")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox element={<CardActions />} justify="flex-end">
            {props.onDismiss && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )}

            <Button type="submit">
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(HolidaySettingsForm);
