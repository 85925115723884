import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  CardContent,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: { "& > div": { display: "flex", flex: "1 1 0%" } },
    table: {
      "& tr > *": {
        textAlign: "center",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const reportListStream = propsStream
      .distinctUntilChanged("reportList")
      .filter(props => props.showTable)
      .startWith({ reportList: null })
      .map(
        fp.flow(
          fp.get("reportList"),
          fp.update("items", fp.toArray),
          fp.update("titles", fp.toPlainObject),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(reportListStream, (props, reportList) => ({
        ...props,
        list: reportList.get("items"),
        titles: reportList.get("titles"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

CODReceivablesAgingReport.propTypes = {
  showTable: PropTypes.bool,
  classes: PropTypes.object,
  reportList: PropTypes.instanceOf(Map),

  list: PropTypes.instanceOf(List),
  titles: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CODReceivablesAgingReport(props) {
  const { classes, getLocalisationMessage } = props;
  const rows = [];

  props.list.forEach((i, idx) => {
    rows.push(
      <TableRow key={idx}>
        <TableCell>{i.get("courier")}</TableCell>
        <TableCell>{formatNumber(i.get("this_week_amount"))}</TableCell>
        <TableCell>{formatNumber(i.get("this_week_count"))}</TableCell>
        <TableCell>{formatNumber(i.get("last_week_amount"))}</TableCell>
        <TableCell>{formatNumber(i.get("last_week_count"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_1_amount"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_1_count"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_2_amount"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_2_count"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_3_amount"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_3_count"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_4_amount"))}</TableCell>
        <TableCell>{formatNumber(i.get("month_4_count"))}</TableCell>
        <TableCell>{formatNumber(i.get("total_amount"))}</TableCell>
        <TableCell>{formatNumber(i.get("total_count"))}</TableCell>
      </TableRow>,
    );
  });

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        {props.showTable && (
          <FlexBox gutter={8} flex={true} direction="column">
            <FlexBox flex={true} element={<Card className={classes.card} />}>
              <FlexBox
                gutter={8}
                flex={true}
                direction="column"
                element={<CardContent />}
              >
                <FlexBox flex={true}>
                  <Table className={classes.table}>
                    <TableHead
                      adjustForCheckbox={false}
                      displaySelectAll={false}
                    >
                      <TableRow>
                        <TableCell colSpan="15">
                          {getLocalisationMessage(
                            "cod_receivables_aging_report",
                            "COD receivables aging report",
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell />
                        <TableCell colSpan="2">
                          {props.titles.get("this_week")}
                        </TableCell>
                        <TableCell colSpan="2">
                          {props.titles.get("last_week")}
                        </TableCell>
                        <TableCell colSpan="2">
                          {props.titles.get("month_1")}
                        </TableCell>
                        <TableCell colSpan="2">
                          {props.titles.get("month_2")}
                        </TableCell>
                        <TableCell colSpan="2">
                          {props.titles.get("month_3")}
                        </TableCell>
                        <TableCell colSpan="2">
                          {props.titles.get("month_4")}
                        </TableCell>
                        <TableCell colSpan="2">
                          {props.titles.get("total")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {getLocalisationMessage(
                            "courier_companies",
                            "Courier Companies",
                          )}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("amount", "Amount")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("count", "Count")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("amount", "Amount")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("count", "Count")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("amount", "Amount")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("count", "Count")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("amount", "Amount")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("count", "Count")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("amount", "Amount")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("count", "Count")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("amount", "Amount")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("count", "Count")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("amount", "Amount")}
                        </TableCell>
                        <TableCell>
                          {getLocalisationMessage("count", "Count")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody displayRowCheckbox={false}>{rows}</TableBody>
                  </Table>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CODReceivablesAgingReport);
