import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import { uploadKml } from "../../api/admin/AdminAreasApi";
import MapAreasUploadKmlForm from "../../components/map-areas/MapAreasUploadKmlForm";
import { Dialog, DialogTitle } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withTheme,
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
);

function MapAreasUploadKmlDialogWrapper({ classes, ...props }) {
  return (
    <Dialog
      open={true}
      isLoading={false}
      onClose={fp.noop}
      paperClassName={classes.paper}
    >
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {props.getLocalisationMessage("upload_kml", "Upload Kml")}
      </DialogTitle>
      <MapAreasUploadKmlForm
        onSubmit={fp.flow(
          ({ file }) => uploadKml(props.countryId, file),
          request =>
            request
              .toPromise()
              .then(() => props.onRequestClose(true))
              .catch(ResponseError.throw),
        )}
        onDismiss={() => props.onRequestClose()}
      />
    </Dialog>
  );
}

MapAreasUploadKmlDialogWrapper.propTypes = {
  classes: PropTypes.object,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
  countryId: PropTypes.number,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

export default enhancer(MapAreasUploadKmlDialogWrapper);
