import React from "react";
import PropTypes from "prop-types";
import { getHybridBatchOrderUpdateItem } from "../../api/admin/AdminBatchApi";
import HybredBatchUpdatesItemDialog from "../../components/batch-updates-dialog/HybredBatchUpdatesItemDialog";

const AdminHybredReturnBatchItemDialogWrapper = ({
  batchId,
  onRequestClose,
  refreshBatchList,
  isCustoms,
  isHybrid,
}) =>
  batchId ? (
    <HybredBatchUpdatesItemDialog
      isHybrid={isHybrid}
      isCustoms={isCustoms}
      refreshBatchList={refreshBatchList}
      batchId={batchId}
      onRequestClose={onRequestClose}
      getBatchOrderUpdateItem={getHybridBatchOrderUpdateItem}
    />
  ) : null;
AdminHybredReturnBatchItemDialogWrapper.propTypes = {
  batchId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  refreshBatchList: PropTypes.func,
  isCustoms: PropTypes.bool,
  isHybrid: PropTypes.bool,
};
export default AdminHybredReturnBatchItemDialogWrapper;
