import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { DoneAll } from "@material-ui/icons";
import SlaRuleTreeBranch from "./SlaRuleTreeBranch";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageFab from "../ui-core/PageFab";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate, shortTimeToDate } from "../../helpers/DateUtils";
import { createNotFalsyValidator } from "../../helpers/FormUtils";
import {
  formatText,
  formatTime,
  parseInteger,
} from "../../helpers/FormatUtils";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { SLARuleTypes as RuleType, RULE_ALERT } from "../../constants/RuleType";
import { AlertTypes } from "../../constants/AlertsContastants";
import {
  COD,
  COST,
  LIMIT,
  PRICE,
  RATING,
  CITY_TO,
  COMPANY,
  CUSTOMER,
  MERCHANT,
  CITY_FROM,
  IS_RETURN,
  RTO_PRICE,
  SELLER_ID,
  COUNTRY_TO,
  ITEM_VALUE,
  PARCEL_SIZE,
  SELLER_NAME,
  SENDER_NAME,
  COUNTRY_FROM,
  CREATED_DATE,
  CREATED_TIME,
  ORDER_STATUS,
  HAS_FETCH_ITEMS,
  NEIGHBORHOOD_TO,
  PICKUP_WAREHOUSE,
  CURRENT_WAREHOUSE,
  NEIGHBORHOOD_FROM,
  TIME_SLOT_PICK_UP,
  TIME_SLOT_DROP_OFF,
  SENDER_ADDRESS_TYPE,
  DESTINATION_WAREHOUSE,
  RECIPIENT_ADDRESS_TYPE,
} from "../../constants/RuleConditionField";
import UpperOverallStatus, { ACTIVE } from "../../constants/UpperOverallStatus";
import { OR, AND } from "../../constants/RuleConditionalOperator";

const valueSelector = formValueSelector("SlaRuleTree");

export const isObjectField = field => {
  switch (field) {
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case NEIGHBORHOOD_FROM:
    case NEIGHBORHOOD_TO:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case COUNTRY_FROM:
    case COUNTRY_TO:
      return true;

    default:
      return false;
  }
};

export const isNameField = field => {
  switch (field) {
    case SELLER_ID:
    case SELLER_NAME:
    case SENDER_NAME:
    case CITY_TO:
    case CITY_FROM:
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case NEIGHBORHOOD_FROM:
    case NEIGHBORHOOD_TO:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case MERCHANT:
    case HAS_FETCH_ITEMS:
    case IS_RETURN:
    case COD:
    case COST:
    case PRICE:
    case RATING:
    case PARCEL_SIZE:
    case RTO_PRICE:
    case RECIPIENT_ADDRESS_TYPE:
    case SENDER_ADDRESS_TYPE:
      return true;

    default:
      return false;
  }
};

export const isDateField = field => {
  switch (field) {
    case CREATED_DATE:
      return true;

    default:
      return false;
  }
};

export const isTimeField = field => {
  switch (field) {
    case CREATED_TIME:
      return true;

    default:
      return false;
  }
};

export const getFieldFieldKey = field => {
  switch (field) {
    case LIMIT:
    case CITY_FROM:
    case CITY_TO:
    case COUNTRY_FROM:
    case COUNTRY_TO:
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case NEIGHBORHOOD_FROM:
    case NEIGHBORHOOD_TO:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case ITEM_VALUE:
      return "whole_number_value";

    case COD:
    case COST:
    case PRICE:
    case RATING:
    case RTO_PRICE:
      return "fraction_number_value";

    case MERCHANT:
    case HAS_FETCH_ITEMS:
    case IS_RETURN:
      return "boolean_value";

    case CREATED_DATE:
      return "date_value";

    default:
      return "string_value";
  }
};

const getFieldValue = item => {
  const value = item[`${item.field}-value`];

  return isObjectField(item.field) ? value.id : value;
};

const getExtraFieldValue = item => {
  const value = item[`${item.field}-extra-value`];

  return isObjectField(item.field) ? value.id : value;
};

const enhancer = compose(
  withContext(
    {
      getWarehousePolygons: PropTypes.func.isRequired,
      getNeighborhoodPolygons: PropTypes.func.isRequired,

      getCachedRule: PropTypes.func.isRequired,
      getRulePredictions: PropTypes.func.isRequired,
      getChildRulePredictions: PropTypes.func.isRequired,
      getCachedCustomer: PropTypes.func.isRequired,
      getCustomerPredictions: PropTypes.func.isRequired,
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
      getCachedDriver: PropTypes.func.isRequired,
      getDriverPredictions: PropTypes.func.isRequired,
      getCachedTimeSlot: PropTypes.func.isRequired,
      getTimeSlotPredictions: PropTypes.func.isRequired,
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getWarehousePolygons: props.getWarehousePolygons,
      getNeighborhoodPolygons: props.getNeighborhoodPolygons,

      getCachedRule: props.getCachedRule,
      getRulePredictions: props.getRulePredictions,
      getChildRulePredictions: props.getChildRulePredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedTimeSlot: props.getCachedTimeSlot,
      getTimeSlotPredictions: props.getTimeSlotPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
    }),
  ),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplaceId: getMarketplaceId(state),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("tree", isEqualData)
      .map(props => {
        const { tree } = props;

        if (!tree) {
          return {
            ...props.initialValues,
            priority: 0,
            ruleStatus: ACTIVE,
            ruleType: RULE_ALERT,
            triggerType: "rule_alert_late_update",
          };
        }

        const mapFromCondition = group => {
          const isLeaf = Boolean(
            group &&
              group.get("conditions") &&
              !group.get("conditions").isEmpty(),
          );
          const isBranch = Boolean(
            group &&
              group.get("condition_groups") &&
              !group.get("condition_groups").isEmpty(),
          );

          const item = { isLeaf: false, expanded: true, leaf: [], branch: [] };

          if (isLeaf) {
            item.isLeaf = true;
            item.overAny =
              group.getIn(["conditions", 0, "conditional_operator"]) !== AND;

            group
              .get("conditions")
              .sortBy(v => v.get("priority"))
              .forEach(condition => {
                const field = condition.get("field");
                const value = condition.get(getFieldFieldKey(field));

                if (field === ORDER_STATUS) {
                  const extraValue = condition.get("whole_number_value");

                  item.leaf.push({
                    field,
                    comparationOperator: condition.get("comparison_operator"),
                    [`${field}-value`]: value,
                    [`${field}-extra-value`]: extraValue,
                  });
                } else {
                  item.leaf.push({
                    field,
                    comparationOperator: condition.get("comparison_operator"),
                    [`${field}-value`]: isObjectField(field)
                      ? { id: value }
                      : isTimeField(field)
                      ? shortTimeToDate(value)
                      : isDateField(field)
                      ? safeParseDate(value)
                      : isNameField(field)
                      ? value
                      : _.toUpper(value),
                  });
                }
              });
          }

          if (isBranch) {
            item.overAny =
              group.getIn(["condition_groups", 0, "conditional_operator"]) !==
              AND;

            group
              .get("condition_groups")
              .sortBy(v => v.get("priority"))
              .forEach(childGroup => {
                item.branch.push(mapFromCondition(childGroup));
              });
          }

          return item;
        };

        return {
          id: tree.get("id"),
          name: tree.get("name"),
          priority: tree.get("priority"),
          ruleType: tree.get("rule_type"),
          triggerType: tree.get("trigger_type"),
          ruleStatus: tree.get("rule_status"),
          description: tree.get("description"),
          orderRules: mapFromCondition(tree.get("condition_group")),
        };
      });

    const handleSubmit = fp.curry((props, values) => {
      const mapToCondition = (item, priority = 0, overAny = false) => {
        const group = { priority, conditional_operator: overAny ? OR : AND };

        if (item.isLeaf) {
          group.conditions = _.map(item.leaf, (v, k) => {
            const condition = Map({
              priority: k,
              field: v.field,
              comparison_operator: v.comparationOperator,
              conditional_operator: item.overAny ? OR : AND,
              [getFieldFieldKey(v.field)]: isTimeField(v.field)
                ? formatTime(getFieldValue(v))
                : isNameField(v.field)
                ? getFieldValue(v)
                : _.toLower(getFieldValue(v)),
            }).asMutable();

            if (v.field === ORDER_STATUS)
              condition.set("whole_number_value", getExtraFieldValue(v));

            return condition.asImmutable().toJS();
          });
        } else {
          group.condition_groups = _.map(item.branch, (v, k) =>
            mapToCondition(v, k, item.overAny),
          );
        }

        return group;
      };

      const rule = {
        id: values.id,
        name: values.name,
        rule_type: values.ruleType,
        trigger_type: values.triggerType,
        rule_status: values.ruleStatus,
        description: values.description,
        priority: fp.toInteger(values.priority),
        condition_group: mapToCondition(values.orderRules),
      };

      return props.onSubmit(rule);
    });

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "SlaRuleTree",
    enableReinitialize: true,
    destroyOnUnmount: !module.hot,
    forceUnregisterOnUnmount: false,
  }),
  connect(state => ({
    values: valueSelector(state, "ruleType", "orderRules"),
  })),
  useSheet({
    parentRule: {
      " & > h6": {
        fontWeight: "bold",
      },
      " & > p": {
        marginBottom: 0,
      },
    },
  }),
);

SlaRuleTree.propTypes = {
  values: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SlaRuleTree(props) {
  const { values, getLocalisationMessage } = props;

  return (
    <FlexBox
      container={8}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <PageFab type="submit" autoHide={false}>
        <DoneAll />
      </PageFab>

      <FlexBox direction="column" gutter={8}>
        <FlexBox flex="none" direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="name"
                    fullWidth={true}
                    label={`${getLocalisationMessage("name", "Name")} *`}
                    validate={createNotFalsyValidator(
                      getLocalisationMessage(
                        "enter_rule_name",
                        "Enter Rule Name",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="description"
                    fullWidth={true}
                    label={getLocalisationMessage("description", "Description")}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    autoWidth={true}
                    fullWidth={true}
                    name="ruleStatus"
                    label={`${getLocalisationMessage("status", "Status")} *`}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    options={UpperOverallStatus}
                    validate={createNotFalsyValidator(
                      getLocalisationMessage("select_status", "Select Status"),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="priority"
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={getLocalisationMessage("priority", "Priority")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="ruleType"
                    autoWidth={true}
                    fullWidth={true}
                    options={RuleType}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    label={`${getLocalisationMessage(
                      "rule_type",
                      "Rule Type",
                    )} *`}
                    validate={createNotFalsyValidator(
                      getLocalisationMessage(
                        "select_rule_type",
                        "Select Rule Type",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ display: "none" }}
                >
                  <FormSelectField
                    name="alertType"
                    autoWidth={true}
                    fullWidth={true}
                    options={AlertTypes}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    label={getLocalisationMessage("alert_type", "Alert Type")}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        {Boolean(values.ruleType) && (
          <FlexBox
            flex="none"
            direction="column"
            style={{ paddingBottom: "128px" }}
          >
            <SlaRuleTreeBranch
              root={true}
              name="orderRules"
              getLocalisationMessage={getLocalisationMessage}
            />
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SlaRuleTree);
