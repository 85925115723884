import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEmpty } from "../../helpers/DataUtils";
import { formatDate, DATE_TIME_URL_FORMAT } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { primary1 } from "../../../shared/theme/main-theme";

const getMonthlyShipment = fp.flow(fp.get("includes.monthly.shipment_count"));
const getYearlyShipment = fp.flow(fp.get("includes.yearly.shipment_count"));
const getUsedShipment = fp.flow(fp.get("shipment"));
const enhancer = compose(
  useSheet({
    heading: {
      backgroundColor: primary1,
      textAlign: "left",
      color: "white",
      "& > div": {
        paddingRight: 0,
      },
    },
    rowItem: {
      marginBottom: "5px",
      color: "#10132D",
    },
    label: {
      fontSize: 12,
    },
    info: {
      fontSize: 13,
      display: "block",
      textAlign: "right",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
    marketplace: getMarketplace(state),
  })),
);

SubscriptionActiveInfo.propTypes = {
  subscribedPlan: PropTypes.instanceOf(Map),
  i18n: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
};

function SubscriptionActiveInfo(props) {
  const { classes, subscribedPlan, i18n } = props;

  if (isEmpty(subscribedPlan)) return <div />;

  const planObj = subscribedPlan.get("plan");
  const period = subscribedPlan.get("plan_period_type");

  const limit =
    period === "MONTHLY"
      ? getMonthlyShipment(planObj)
      : getYearlyShipment(planObj);
  const used = subscribedPlan.get("occupied", {});

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader className={classes.heading}>
        {i18n.get("current_plan_info", "Current Plan Info")}
      </CardHeader>
      <CardContent>
        <FlexBox className={classes.rowItem} justify="space-between">
          <span className={classes.label}>
            {i18n.get("current_plan", "Current Plan")}:
          </span>
          <strong className={classes.info}>{planObj.title}</strong>
        </FlexBox>
        <FlexBox className={classes.rowItem} justify="space-between">
          <span className={classes.label}>
            {i18n.get("start_date", "Start Date")}:
          </span>
          <strong className={classes.info}>
            {formatDate(
              new Date(subscribedPlan.get("start")),
              DATE_TIME_URL_FORMAT,
            )}
          </strong>
        </FlexBox>
        <FlexBox className={classes.rowItem} justify="space-between">
          <span className={classes.label}>
            {i18n.get("end_date", "End Date")}:
          </span>
          <strong className={classes.info}>
            {formatDate(
              new Date(subscribedPlan.get("end")),
              DATE_TIME_URL_FORMAT,
            )}
          </strong>
        </FlexBox>
        <FlexBox className={classes.rowItem} justify="space-between">
          <span className={classes.label}>
            {i18n.get("used_limit", "Used/Limit")}:
          </span>
          <strong className={classes.info}>
            {getUsedShipment(used)}/{limit}
          </strong>
        </FlexBox>
      </CardContent>
    </Card>
  );
}

export default enhancer(SubscriptionActiveInfo);
