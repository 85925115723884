import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: {
      backgroundColor: "#F1F1F1",
      height: "100%",
      backgroundPosition: "center",
      backgroundSize: "cover",
      position: "relative",
      color: "black",
      fontSize: "25px",
    },
    wrapper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  }),
);

ComingSoonContainer.propTypes = {
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function ComingSoonContainer(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <h1>{`${getLocalisationMessage("coming_soon", "COMING SOON")}!!!`}</h1>
        <hr />
      </div>
    </div>
  );
}

export default enhancer(ComingSoonContainer);
