import React, { useState } from "react";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  FAILED_BIN,
  NOTFOUND_BIN,
  SMALL_PACKETS,
  UNASSIGNED_BIN,
  UNSORTED_BIN,
} from "../../helpers/OrderOutboundSortingHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ASSIGNED_TO_COURIER,
  IN_SORTING_FACILITY,
  ON_HOLD,
  UNASSIGNED,
} from "../../constants/OrderStatusCodes";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import FormDialog from "../../components/form/FormDialog";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OrderSortingChips from "../../components/order-outbound-sorting/OrderSortingChips";
import OrderSortingChangeBinDialog from "../../components/order-outbound-sorting/OrderSortingChangeBinDialog";
import OrderSortingVerifyOrdersDialog from "../../components/order-outbound-sorting/OrderSortingVerifyOrdersDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_ORDER_AIRWAYBILL_URL,
  CREATE_ORDER_MANIFEST_URL,
} from "../../../shared/constants/FileProxyControllerConstants";
import AdminBatchUpdateOrderDialogWrapper2 from "../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import { getUser } from "../../reducers/ProfileReducer";
import { hasRole } from "../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../constants/AdminRoleTypes";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { RETURNING } from "../../constants/FlowTypes";
import { POST_PACKET } from "../../components/orders-core/BatchUpdateOrderDialog2";
import { ACTIVE } from "../../constants/OverallStatus";
import OrderRegistrySortingCreateBagsDialog from "../../components/order-outbound-sorting/OrderRegistrySortingCreateBagsDialog";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import OrderRegistrySortingTable from "../../components/order-outbound-sorting/OrderRegistrySortingTable";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  CONSOLIDATED,
  CONTAINER,
  SHIPMENT,
  SIMPLE,
} from "../../constants/OrderType";
import {
  ORDER_OUTBOUND_CONSOLIDATED_VIEW_URL,
  ORDER_OUTBOUND_CONTAINER_VIEW_URL,
  ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_URL,
  ORDER_OUTBOUND_ROUTES_VIEW_URL,
  ORDER_OUTBOUND_SORTING_VIEW_URL,
} from "../../constants/AdminPathConstants";
import OrderSortingTypeTabs from "../../components/order-outbound-sorting/OrderSortingTypeTabs";
import { OrderSortingDB as RethinkOrderSortingDb } from "../../realtimeDb/OrderSortingDB";
import { isParcelServiceType } from "../../helpers/OrderSortingHelper";

const SORTED_GROUP = "Sorted";
const UNSORTED_GROUP = "Unsorted";
const PROCESSED_GROUP = "Processed";

function getOrderGroup(taskWarehouseId, order) {
  if (order.get("info")) {
    // const status = order.getIn(["info", "status"]);
    const flow = order.getIn(["info", "flow"]);
    const warehouseId = order.getIn(["info", "warehouse", "id"]);
    const inWarehouse = warehouseId === taskWarehouseId;

    if (flow === RETURNING) return RETURNING;

    if (
      inWarehouse &&
      (status === ASSIGNED_TO_COURIER ||
        status === ON_HOLD ||
        status === IN_SORTING_FACILITY)
    ) {
      return "Sorted";
    }

    if (status === UNASSIGNED || status === ASSIGNED_TO_COURIER) {
      return "Unsorted";
    }

    return "Processed";
  }

  return null;
}

function formatPromptMessage(
  orderSize,
  getLocalisationMessage,
  defaultMessage = "",
) {
  if (!orderSize) {
    return defaultMessage;
  }
  const messageHead = getLocalisationMessage("regroup_orders_prompt");
  if (!messageHead) {
    return defaultMessage;
  }
  let messageTail = null;
  switch (orderSize) {
    case 1:
      messageTail = getLocalisationMessage("orders_1");
      break;
    case 0:
    case 2:
    case 3:
    case 4:
    case 5:
      messageTail = getLocalisationMessage("orders_2");
      break;
    default:
      messageTail = getLocalisationMessage("orders_3");
  }
  return `${messageHead} ${orderSize} ${messageTail}`;
}

const batchUpdateInitialValues = ({ stats, activeBin, selectedOrders }) => {
  const values = {
    orderBarcodes: selectedOrders.toArray(),
    orderStatus: activeBin.get("transitStatus", null),
    onlyBarcode: true,
    category:
      activeBin.get("group", null) === POST_PACKET
        ? POST_PACKET
        : activeBin.getIn(["selectedBin", "category"], null),
    isBag:
      activeBin.get("group", null) === SMALL_PACKETS ||
      isParcelServiceType(activeBin.get("group", null)),
    batchType: ACTIVE,
    weight:
      Math.round(fp.toNumber(stats.get("selectedOrderWeight", 0)) * 100) / 100,
  };

  if (activeBin.get("group", null) === POST_PACKET) {
    if (activeBin.get("binPostcode", null)) {
      values.to_postcode = {
        name: activeBin.get("binPostcode", null),
      };
    }

    if (activeBin.get("binJurisdiction", null)) {
      values.to_jurisdiction = {
        id: activeBin.get("binJurisdiction", null),
      };
    }
  }

  if (activeBin.getIn(["selectedBin", "nextPostcode"], null)) {
    values.next_postcode = {
      name: activeBin.getIn(["selectedBin", "nextPostcode"], null),
    };
  }

  if (activeBin.getIn(["selectedBin", "nextJurisdiction"], null)) {
    values.next_jurisdiction = {
      id: activeBin.getIn(["selectedBin", "nextJurisdiction"], null),
    };
  }

  if (
    !activeBin.getIn(["selectedBin", "nextPostcode"], null) &&
    activeBin.get("transitWarehouse", null)
  ) {
    values.warehouse = {
      id: activeBin.get("transitWarehouse", null),
    };
  }

  // eslint-disable-next-line no-console
  console.log("Batch update initial Values", values);

  return values;
};

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        isOperator: hasRole(userRoles, ROLE_OPERATOR),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    chipsCard: { paddingTop: 0, paddingBottom: 0 },
    tableCard: { paddingTop: 0 },
  }),
  withState("state", "setState", {
    transitWarehouse: null,
    transitStatus: null,
    selectedBin: null,
  }),
  withState("activeBin", "setActiveBin", Map()),
  mapPropsStream(
    /**
     * 1. Generate sets of selected order, driver and warehouse ids.
     * 2. Count failed orders.
     * 3. Combine and sort order bins.
     */
    propsStream => {
      const statsStream = propsStream
        .map(props => ({
          orders: props.orders,
          binRules: props.binRules,
          warehouseId: props.warehouseId,
          selectedOrders: props.task.get("selectedRegistries"),
          getLocalisationMessage: props.getLocalisationMessage,
        }))
        .distinctUntilChanged(isEqualData)
        .map(props => {
          const bins = Map().asMutable();
          const groups = Map().asMutable();

          let selectedOrderWeight = 0;

          const ids = Set().asMutable();
          const driverIds = Set().asMutable();
          const warehouseIds = Set().asMutable();
          const courierTypes = Map().asMutable();
          const selectedBins = Set().asMutable();

          const orderIdPath = ["info", "id"];
          const driverIdPath = ["info", "driver", "id"];
          const warehouseIdPath = ["info", "destination_warehouse", "id"];
          const courierTypePath = ["info", "inner_shipment_type"];
          const categoryTypePath = ["info", "category"];
          const orderWeightPath = ["info", "weight"];

          props.orders.forEach((order, orderNumber) => {
            const orderId = order.getIn(orderIdPath);
            const loaded = orderId > 0;
            const code = order.get("code");
            const selected = props.selectedOrders.has(orderNumber);

            const orderCourierTypeKey = order.getIn(courierTypePath);
            const orderCourierType = props.getLocalisationMessage(
              order.getIn(courierTypePath),
            );

            // const orderCategoryKey = order.getIn(categoryTypePath);
            const orderCategory = props.getLocalisationMessage(
              order.getIn(categoryTypePath),
            );

            const orderWarehouse = order.get("warehouse", null);

            if (loaded && orderCourierTypeKey) {
              if (!courierTypes.has(orderCourierTypeKey)) {
                courierTypes.set(
                  orderCourierTypeKey,
                  Map({
                    key: orderCourierTypeKey,
                    label: orderCourierType,
                    orders: Set(),
                  }),
                );
              }

              courierTypes.updateIn([orderCourierTypeKey, "orders"], x =>
                x.add(orderNumber),
              );
            }

            const bin = order.get("bin")
              ? orderCourierTypeKey
                ? `${order.get("bin")}_${orderCourierTypeKey}`
                : order.get("bin")
              : !loaded && order.get("failed")
              ? FAILED_BIN
              : UNASSIGNED_BIN;

            if (!bins.has(bin)) {
              bins.set(
                bin,
                Map({
                  code,
                  label: orderCourierType
                    ? `${order.get("bin")} - ${orderCourierType}`
                    : order.get("bin")
                    ? order.get("bin")
                    : bin,
                  category: orderCategory,
                  selected: 0,
                  orders: Set(),
                  warehouses: Set(),
                  courierTypes: Set(),
                }),
              );
            }

            bins.updateIn([bin, "orders"], x => x.add(orderNumber));
            if (selected) {
              bins.updateIn([bin, "selected"], fp.add(1));
              if (!selectedBins.has(bin)) {
                selectedBins.add(bin);
              }
            }

            bins.updateIn([bin, "courierTypes"], x =>
              x.add(orderCourierTypeKey),
            );

            if (orderWarehouse) {
              bins.updateIn([bin, "warehouses"], x => x.add(orderWarehouse));
            }

            const group = getOrderGroup(props.warehouseId, order);

            if (group) {
              if (!groups.has(group)) {
                groups.set(
                  group,
                  Map({ label: group, selected: 0, orders: Set() }),
                );
              }

              groups.updateIn([group, "orders"], x => x.add(orderNumber));

              if (selected) {
                groups.updateIn([group, "selected"], fp.add(1));
              }
            }

            if (loaded) {
              if (selected) {
                ids.add(orderId);
                selectedOrderWeight +=
                  fp.toNumber(order.getIn(orderWeightPath, 0)) * 10;

                const driverId = order.getIn(driverIdPath);

                if (driverId > 0) {
                  driverIds.add(driverId);
                }

                const warehouseId = order.getIn(warehouseIdPath);

                if (warehouseId > 0) {
                  warehouseIds.add(warehouseId);
                }
              }
            }
          });

          // eslint-disable-next-line no-console
          // console.log(bins.toJS(), courierTypes.asImmutable().toJS());

          return Map({
            selectedOrderWeight: selectedOrderWeight / 10,
            selectedIds: ids.asImmutable(),
            selectedDriverIds: driverIds.asImmutable(),
            selectedWarehouseIds: warehouseIds.asImmutable(),
            selectedBins: selectedBins.asImmutable(),
            courierTypes: courierTypes.asImmutable(),

            groups: groups
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultGroups = [
                  UNSORTED_GROUP,
                  SORTED_GROUP,
                  PROCESSED_GROUP,
                ];

                for (let i = 0; i < defaultGroups.length; i++) {
                  const bin = defaultGroups[i];

                  if (aName === bin) {
                    return -1;
                  }

                  if (bName === bin) {
                    return 1;
                  }
                }

                return 0;
              }),
            bins: bins
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultBins = [
                  UNASSIGNED_BIN,
                  FAILED_BIN,
                  UNSORTED_BIN,
                  NOTFOUND_BIN,
                ];

                for (let i = 0; i < defaultBins.length; i++) {
                  const bin = defaultBins[i];

                  if (aName === bin) {
                    return 1;
                  }

                  if (bName === bin) {
                    return -1;
                  }
                }

                if (!aName || !bName) return -1;

                return aName.localeCompare(bName);
              }),
          });
        })
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
    },
  ),
  pureComponent(
    fp.pick(["location", "task", "stats", "orders", "shipments", "state"]),
  ),
);

AdminOrderOutboundRegistrySortingTableContainer.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  activeBin: PropTypes.object,
  stats: PropTypes.instanceOf(Map),
  location: PropTypes.object.isRequired,
  task: PropTypes.instanceOf(Map).isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
  shipments: PropTypes.instanceOf(Map),
  registryBinRules: PropTypes.instanceOf(Map),
  warehouseId: PropTypes.number.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  updateSortingTask: PropTypes.func.isRequired,
  setActiveBin: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onSubmitFail: PropTypes.func,
  rethinkDb: PropTypes.instanceOf(RethinkOrderSortingDb),
  bagsCount: PropTypes.number,
};

function AdminOrderOutboundRegistrySortingTableContainer(props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    getLocalisationMessage,
    classes,
    task,
    stats,
    orders,
    state,
    activeBin,
    location: { query },
  } = props;
  const [batchId, setBatchId] = useState(false);
  // const db = new OrderSortingDB(props.warehouseId);

  const selectedOrders = task.get("selectedRegistries");
  const selectedIds = stats.get("selectedIds").toArray();
  const selectedIdsString = selectedIds.join(",");

  return (
    <div>
      {query.remove_selected === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmit={() =>
            props.rethinkDb
              .batchRemoveRegistries(selectedOrders.toArray())
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage("delete_prompt") ||
            `${getLocalisationMessage("are_you_sure_you_want_to_remove")} (${
              selectedOrders.size
            })`}
        </FormDialog>
      )}

      {query.create_bag === "true" && (
        <OrderRegistrySortingCreateBagsDialog
          open={true}
          isLoading={isLoading}
          currentWarehouse={props.warehouseId}
          selectedOrders={selectedOrders}
          orders={orders}
          binRules={props.registryBinRules}
          onAddOrder={orderNumber => {
            props.updateSortingTask(x =>
              x.update("selectedRegistries", selected =>
                selected.merge([orderNumber]),
              ),
            );
          }}
          onUnselectOrder={orderNumber => {
            props.updateSortingTask(x =>
              x.update("selectedRegistries", selected =>
                selected.subtract([orderNumber]),
              ),
            );
          }}
          onSubmit={values => {
            setIsLoading(true);
            return batchAsyncUpdateOrder(toSnakeCase(values))
              .catch(ResponseError.throw)
              .finally(() => setIsLoading(false));
          }}
          onSubmitSuccess={response => {
            props.setLocationQuery(
              fp.flow(
                fp.unset("create_bag"),
                fp.set("batch_id", response.data.id),
              ),
            );
          }}
          onSubmitFail={props.onSubmitFail}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          onRequestClose={() => props.setLocationQuery(fp.unset("create_bag"))}
        />
      )}

      {query.verify_selected === "true" && (
        <OrderSortingVerifyOrdersDialog
          selectedOrders={selectedOrders}
          onSelectOrders={numbers => {
            props.setLocationQuery(fp.unset("verify_selected"));
            props.updateSortingTask(x => x.set("selectedRegistries", numbers));
          }}
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.set("confirm_close_verify_selected", true),
            )
          }
        />
      )}

      {query.regroup_bins === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmit={() =>
            props.rethinkDb
              .batchUpdateRegistryBins(selectedOrders.toArray(), null)
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {formatPromptMessage(
            selectedOrders.size,
            getLocalisationMessage,
            `Are you sure you want to regroup ${selectedOrders.size} orders?`,
          )}
        </FormDialog>
      )}

      {query.change_bin === "true" && (
        <OrderSortingChangeBinDialog
          open={true}
          orderBins={stats.get("bins")}
          selectedOrders={selectedOrders}
          onRequestClose={() => props.setLocationQuery(fp.unset("change_bin"))}
          onSubmit={values =>
            props.rethinkDb
              .batchUpdateOrderBins(selectedOrders.toArray(), values.name)
              .toPromise()
              .then(() => ({ selectedOrders, bin: values.name }))
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={request => {
            const nextBin = stats
              .get("bins")
              .find(x => x.get("label") === request.bin);

            if (nextBin) {
              const getSupplierId = x =>
                orders.getIn([x, "info", "supplier", "id"]);

              const originSuppliers = request.selectedOrders
                .map(getSupplierId)
                .filter(Boolean);

              const destinationSuppliers = nextBin
                .get("orders")
                .map(getSupplierId)
                .filter(Boolean);

              if (
                destinationSuppliers.size === 1 &&
                !destinationSuppliers.equals(originSuppliers)
              ) {
                props.setLocationQuery(
                  fp.flow(
                    fp.set("set_supplier", true),
                    fp.set("set_supplier_id", destinationSuppliers.first()),
                  ),
                );
              }
            }

            props.setLocationQuery(fp.unset("change_bin"));
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      {query.batch_update === "true" && (
        <AdminBatchUpdateOrderDialogWrapper2
          title="create_bag"
          open={true}
          initialValues={batchUpdateInitialValues({
            stats,
            activeBin,
            selectedOrders,
          })}
          list={orders}
          isSorting={true}
          selectedItems={selectedOrders}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_update_status"),
                fp.unset("batch_update"),
              ),
            )
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={response => {
            props.setActiveBin(Map());
            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_update"),
                fp.unset("batch_update_status"),
                fp.set("batch_id", response.data.id),
              ),
            );
          }}
        />
      )}
      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        // refreshBatchList={onRequestRefresh}
        onRequestClose={() => {
          setBatchId(false);
          if (selectedOrders.size > 0) {
            // Auto remove orders after batch update
            // props.setLocationQuery(fp.set("remove_after_update", true));
            props.rethinkDb
              .batchRemoveRegistries(selectedOrders.toArray())
              .toPromise()
              .catch(ResponseError.throw);
          }
        }}
        // onShowOrdersClick={(numbers) => setLocationQuery({ search: numbers.join(",") })}
      />
      {fp.toFinite(query.batch_id) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(query.batch_id)}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("batch_id"));

            if (selectedOrders.size > 0) {
              // Auto remove orders after batch update
              // props.setLocationQuery(fp.set("remove_after_update", true));
              props.rethinkDb
                .batchRemoveRegistries(selectedOrders.toArray())
                .toPromise()
                .catch(ResponseError.throw);
            }
          }}
        />
      )}

      <FlexBox direction="column">
        <Card>
          <CardContent className={classes.chipsCard}>
            <OrderSortingTypeTabs
              orderType={SIMPLE}
              orders={props.shipments}
              registries={orders}
              onTabChange={actualTab => {
                props.setLocation(
                  actualTab === SHIPMENT
                    ? ORDER_OUTBOUND_SORTING_VIEW_URL
                    : actualTab === SIMPLE
                    ? ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_URL
                    : actualTab === CONSOLIDATED
                    ? ORDER_OUTBOUND_CONSOLIDATED_VIEW_URL
                    : actualTab === CONTAINER
                    ? ORDER_OUTBOUND_CONTAINER_VIEW_URL
                    : ORDER_OUTBOUND_ROUTES_VIEW_URL,
                );
              }}
            />
            <OrderSortingChips
              groups={stats.get("groups")}
              filterChips={stats.get("bins")}
              filterTabs={stats.get("courierTypes")}
              onSelectOrders={orderNumbers => {
                props.updateSortingTask(x =>
                  x.update("selectedRegistries", selected =>
                    selected.merge(orderNumbers),
                  ),
                );
              }}
              onDeselectOrders={orderNumbers => {
                props.updateSortingTask(x =>
                  x.update("selectedRegistries", selected =>
                    selected.subtract(orderNumbers),
                  ),
                );
              }}
            />
          </CardContent>

          <CardContent className={classes.tableCard}>
            <OrderRegistrySortingTable
              orders={orders}
              showAllButton={false}
              onOrderClick={x => setBatchId(x)}
              selectedOrders={selectedOrders}
              onOrdersSelect={numbers =>
                props.updateSortingTask(x =>
                  x.set("selectedRegistries", numbers),
                )
              }
              onReloadClick={orderNumber =>
                props.rethinkDb
                  .reloadOrder(orderNumber)
                  .toPromise()
                  .catch(props.showErrorMessage)
              }
              onRemoveClick={orderNumber =>
                props.rethinkDb
                  .removeOrder(orderNumber)
                  .toPromise()
                  .catch(props.showErrorMessage)
              }
              onCancelRegistry={orderNumber =>
                props.rethinkDb
                  .cancelRegistryTask(orderNumber)
                  .toPromise()
                  .catch(props.showErrorMessage)
              }
              onBatchStatusUpdate={() => {
                props.setLocationQuery(
                  fp.flow(
                    fp.set("batch_update", true),
                    fp.set(
                      "batch_update_status",
                      fp.get("transitStatus", state),
                    ),
                  ),
                );
              }}
              cardActionIcons={
                <FlexBox align="center">
                  {selectedOrders.size > 0 && (
                    <FlexBox flex={true}>
                      <CustomButton
                        style={{
                          flex: "1 1 auto",
                          margin: "0 .5rem",
                        }}
                        variant={OUTLINED}
                        color={SECONDARY}
                        onClick={() =>
                          props.setLocationQuery(fp.set("create_bag", true))
                        }
                      >
                        {getLocalisationMessage("create_bag", "Create Bag")}
                      </CustomButton>
                      <MenuButtonMore desktop={true}>
                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(fp.set("change_bin", true))
                          }
                        >
                          {getLocalisationMessage("change_bin")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(fp.set("regroup_bins", true))
                          }
                        >
                          {getLocalisationMessage("regroup_bins")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(
                              fp.set("remove_selected", true),
                            )
                          }
                        >
                          {getLocalisationMessage("remove")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.rethinkDb
                              .batchReloadRegistries(selectedOrders.toArray())
                              .toPromise()
                              .catch(props.showErrorMessage)
                          }
                        >
                          {getLocalisationMessage("batch_reload")}
                        </MenuItem>

                        <MenuItem
                          target="_blank"
                          component="a"
                          href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
                            ids: selectedIdsString,
                          })}
                        >
                          {getLocalisationMessage("airwaybill")}
                        </MenuItem>

                        <MenuItemForm
                          url={CREATE_ORDER_MANIFEST_URL}
                          params={{
                            ids: selectedIds,
                          }}
                          primaryText={getLocalisationMessage("manifest")}
                        />

                        <MenuItemForm
                          url={CREATE_ORDER_MANIFEST_URL}
                          params={{
                            compact: true,
                            ids: selectedIds,
                          }}
                          primaryText={getLocalisationMessage(
                            "compact_manifest",
                          )}
                        />
                      </MenuButtonMore>
                    </FlexBox>
                  )}
                </FlexBox>
              }
            />
          </CardContent>
        </Card>
      </FlexBox>
    </div>
  );
}

export default enhancer(AdminOrderOutboundRegistrySortingTableContainer);
