import React from "react";
import _ from "lodash";
import { fromJS } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { dateToMinutes } from "../../helpers/DateUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SHOP_VENUE_LIST_URL } from "../../constants/AdminPathConstants";
import { createVenueItem } from "../../api/admin/AdminVenueApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminVenueForm from "../../components/admin/AdminVenueForm";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdminShopVenueCreate.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminShopVenueCreate(props) {
  return (
    <AdminAppLayout
      title={props.getLocalisationMessage(
        "create_shop_venue",
        "Create Shop Venue",
      )}
    >
      <AdminVenueForm
        initialValues={{
          country: fromJS({
            code: "AE",
            name: "United Arab Emirates",
          }),
          location: {},
        }}
        onSubmit={({ country, location, merchant, businessHours, ...values }) =>
          createVenueItem({
            ...toSnakeCase(values),
            lat: _.get(location, "lat"),
            lon: _.get(location, "lng"),
            merchant_id: _.get(merchant, "id"),
            business_hours: {
              sunday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "sunday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "sunday.closeTime"),
                ),
              },
              monday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "monday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "monday.closeTime"),
                ),
              },
              tuesday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "tuesday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "tuesday.closeTime"),
                ),
              },
              wednesday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "wednesday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "wednesday.closeTime"),
                ),
              },
              thursday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "thursday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "thursday.closeTime"),
                ),
              },
              friday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "friday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "friday.closeTime"),
                ),
              },
              saturday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "saturday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "saturday.closeTime"),
                ),
              },
            },
          }).catch(ResponseError.throw)
        }
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully Saved",
            ),
          );
          props.setLocation(SHOP_VENUE_LIST_URL);
        }}
        onDismissClick={() => props.setLocation(SHOP_VENUE_LIST_URL)}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminShopVenueCreate);
