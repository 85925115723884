import React, { useEffect, useRef, useState } from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { IconButton, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Add,
  ClearAll,
  FilterList,
  MapOutlined,
  Search,
} from "@material-ui/icons";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  POSTCODES_CREATE_URL,
  POSTCODES_ITEM_URL,
} from "../../constants/AdminPathConstants";
import {
  deletePostCode,
  getPostcodeIndexList2,
} from "../../api/admin/AdminPostcodesApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PostcodeListFilter from "../../wrappers/admin/PostcodeListFilter";
import _ from "lodash";
import MUITable, {
  ACTION,
  LINK,
  RENDER,
} from "../../components/orders-core/MUITable";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import fp from "lodash/fp";
import AppTitle from "../../components/deprecated/AppTitle";
import Button from "@material-ui/core/Button";
import AdminPostcodesMapView from "./AdminPostcodesMapView";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import FormDialog from "../../components/form/FormDialog";
import ResponseError from "../../helpers/ResponseError";
import { formatTrueFalseOption } from "../../components/admin/AdminPostcodesForm";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";

const useStyles = makeStyles({
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
});

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "5px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

AdminPostcodesList.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,
  location: PropTypes.object,
};

function AdminPostcodesList(props) {
  const { getLocalisationMessage, location } = props;
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(50);

  // eslint-disable-next-line prefer-destructuring
  const tab = location.query.tab;

  const [search, setSearch] = useState(null);
  const [jurisdictionId, setJurisdictionId] = useState(null);

  const classes = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  useEffect(() => {
    if (tab !== "map") {
      setIsLoading(true);
      getPostcodeIndexList2({
        jurisdiction_ids: jurisdictionId,
        search,
        page,
        size,
      })
        .then(res => {
          setIsLoading(false);
          setList(_.get(res, "data.list"));
          setTotal(_.get(res, "data.total"));
        })
        .catch(error => {
          setIsLoading(false);
          props.showErrorMessage(error);
        });
    }
  }, [jurisdictionId, search, page, size, refresh]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <AdminAppLayout
      slug="postcodes"
      title={getLocalisationMessage("postcodes", "Postcodes")}
    >
      <AppTitle title={getLocalisationMessage("postcodes", "Postcodes")}>
        <FormDialog
          onSubmitFail={props.showErrorMessage}
          onRequestClose={() => props.setLocationQuery(fp.unset("delete_id"))}
          open={fp.toFinite(props.location.query.delete_id) > 0}
          onSubmit={() =>
            deletePostCode(props.location.query.delete_id).catch(
              ResponseError.throw,
            )
          }
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_removed",
                "Successfully Removed",
              ),
            );
            setRefresh(true);
            props.setLocationQuery(fp.unset("delete_id"));
          }}
        >
          {props.getLocalisationMessage(
            "are_you_sure_you_want_to_remove",
            "Are you sure you want to remove?",
          )}
        </FormDialog>

        {tab === "map" ? (
          <Button
            style={{ color: "#3F51B5" }}
            startIcon={<ClearAll />}
            onClick={() => {
              props.setLocationQuery(fp.unset("tab"));
              props.setLocationQuery(fp.unset("jurisdiction"));
              props.setLocationQuery(fp.unset("jurisdictionLevelId"));
              setRefresh("");
            }}
          >
            {getLocalisationMessage("show_in_list", "Show In List")}
          </Button>
        ) : (
          <Button
            style={{ color: "#3F51B5" }}
            startIcon={<MapOutlined />}
            onClick={() => {
              props.setLocationQuery(fp.set("tab", "map"));
            }}
          >
            {getLocalisationMessage("show_in_the_map", "Show In the Map")}
          </Button>
        )}
      </AppTitle>
      <PostcodeListFilter
        open={isOpenFilter}
        onFilterChange={v => setJurisdictionId(v)}
        onRequestClose={() => setIsOpenFilter(false)}
      />
      {tab === "map" ? (
        <AdminPostcodesMapView
          setLocation={props.setLocation}
          setLocationQuery={props.setLocationQuery}
          location={location}
        />
      ) : (
        <MUITable
          isLoading={isLoading}
          size="small"
          list={list}
          total={total}
          page={page}
          rowsPerPage={size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          withCheckbox={false}
          headerActions={
            <FlexBox
              flex={true}
              align={ALIGN_CENTER}
              style={{
                flex: "1 1 auto",
                padding: ".5rem 0",
              }}
            >
              <div className={classes.search} ref={ref}>
                <Search
                  fontSize="large"
                  style={{
                    color: "rgba(0, 0, 0, 0.23)",
                    top: ".5rem",
                    left: ".5rem",
                    position: "absolute",
                  }}
                />
                <ChipTextField
                  style={{
                    padding: ".5rem 1rem 0 3rem",
                    maxHeight: divHeight > 86 && "5.8rem",
                    overflow: divHeight > 86 && "auto",
                  }}
                  fullWidth={true}
                  addOnBlur={false}
                  clearOnBlur={false}
                  value={parseString(search)}
                  maxItems={1}
                  disableUnderline={true}
                  placeholder={getLocalisationMessage("type_here_to_search")}
                  onChange={v => setSearch(stringifyArray(v))}
                />
              </div>
              <FlexBox>
                <CustomButton
                  onClick={() => props.setLocation(POSTCODES_CREATE_URL)}
                  startIcon={<Add />}
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{ borderRadius: "20px" }}
                >
                  {getLocalisationMessage(
                    "create_new_postcode",
                    "create new postcode",
                  )}
                </CustomButton>
                <IconButton onClick={() => setIsOpenFilter(true)}>
                  <FilterList />
                </IconButton>
              </FlexBox>
            </FlexBox>
          }
          columns={[
            {
              type: LINK,
              name: "index",
              label: getLocalisationMessage("postcode"),
              link: POSTCODES_ITEM_URL,
              align: "center",
            },
            {
              type: RENDER,
              name: "description",
              label: getLocalisationMessage("jurisdiction"),
              render: row => {
                const jurisdictions = _.get(row, `jurisdictions`, []);
                return jurisdictions.map((v, i) => (
                  <span key={i}>
                    {v.name} {i === jurisdictions.length - 1 ? "" : " , "}{" "}
                  </span>
                ));
              },
            },
            {
              type: RENDER,
              name: "address",
              label: getLocalisationMessage("address"),
              render: row => <span>{row.address}</span>,
            },
            {
              type: RENDER,
              name: "active",
              label: getLocalisationMessage("active"),
              render: row => (
                <span>
                  {formatTrueFalseOption(row.active, getLocalisationMessage)}
                </span>
              ),
            },
            {
              type: ACTION,
              align: "center",
              label: getLocalisationMessage("delete"),
              dispatch: id => props.setLocationQuery(fp.set("delete_id", id)),
            },
          ]}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminPostcodesList);
