import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  ORDER_TRACK_URL,
  CUSTOMER_ITEM_URL,
} from "../../constants/AdminPathConstants";
import {
  getBankDepositItem,
  updateBankDepositStatus,
  getBankDepositItemOrders,
} from "../../api/admin/AdminFinanceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import BankDepositDetails from "../../components/finance-core/BankDepositDetails";
import BankDepositItemOrderList from "../../components/finance-core/BankDepositItemOrderList";
import UpdateBankDepositStatusDialog from "../../components/finance-core/UpdateBankDepositStatusDialog";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { hasRole } from "../../helpers/RoleUtils";

const UPDATE_STATUS_DIALOG_HASH = "#USDH";

const ROLE_FINANCE_APPROVER = "ROLE_FINANCE_APPROVER";
const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";

const enhancer = compose(
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      return {
        isFinanceAdmin:
          hasRole(userRoles, ROLE_FINANCE_ADMIN) ||
          hasRole(userRoles, ROLE_FINANCE_APPROVER),
        i18n: getMessages(state),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    root: { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    orders: {
      flex: "1 1 0%",
      marginTop: "12px",
      display: "flex",
      "& > div": { flex: "1 1 0%", display: "flex" },
    },
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionButton: {
      color: "#fff",
      top: "-7px",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const idStream = propsStream
      .map(fp.flow(fp.get("params.id"), fp.toFinite))
      .distinctUntilChanged();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), DataListFilter.create))
      .distinctUntilChanged(isEqualData);

    const itemResponseStream = idStream
      .switchMap(id =>
        getBankDepositItem(id)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const ordersResponseStream = idStream
      .combineLatest(filterStream)
      .switchMap(([id, filter]) =>
        getBankDepositItemOrders(id, filter)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(
        filterStream,
        itemResponseStream,
        ordersResponseStream,
        (props, filter, itemResponse, ordersResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          item: itemResponse.get("payload"),
          isLoading: itemResponse.get("pending"),
          orders: ordersResponse.get("payload"),
          ordersTotal: ordersResponse.get("total"),
          ordersLoading: ordersResponse.get("pending"),
        }),
      );
  }),
);

AdminFinanceBankDepositItemContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  ordersTotal: PropTypes.number,
  ordersLoading: PropTypes.bool,
  orders: PropTypes.instanceOf(List),
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  isFinanceAdmin: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
};

function AdminFinanceBankDepositItemContainer(props) {
  const { item, classes, location, i18n } = props;

  return (
    <AdminAppLayout
      title={`${i18n.get("bank_deposit", "Bank Deposit")} # ${item.get("id")}`}
    >
      <PageLoading isLoading={props.isLoading} />

      <UpdateBankDepositStatusDialog
        open={location.hash === UPDATE_STATUS_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{
          status: item.get("status"),
          bankReference: item.get("reference"),
        }}
        onSubmit={fp.flow(toSnakeCase, values =>
          updateBankDepositStatus(item.get("id"), values).catch(
            ResponseError.throw,
          ),
        )}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage("Successfully Updated");
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <div className={classes.root}>
        {!props.isLoading && (
          <BankDepositDetails
            item={item}
            onEditStatusClick={() =>
              props.replaceLocationHash(UPDATE_STATUS_DIALOG_HASH)
            }
          />
        )}

        <Card className={classes.orders}>
          <BankDepositItemOrderList
            list={props.orders}
            totalCount={props.ordersTotal}
            isLoading={props.ordersLoading}
            filter={props.filter}
            onFilterChange={filter => props.setLocationQueryFilter(filter)}
            createCustomerHref={id => CUSTOMER_ITEM_URL + id}
            createOrderHref={id => updateQuery(ORDER_TRACK_URL, { view: id })}
          />
        </Card>
      </div>
    </AdminAppLayout>
  );
}

export default enhancer(AdminFinanceBankDepositItemContainer);
