import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { validateString } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { Observable } from "rxjs";
import FormSelectField from "../form/FormSelectField";
import { fromJS, List } from "immutable";
import { getSampleItemsForSelect } from "../../api/shared/SampleItemsApi";
import FormCheckbox from "../form/FormCheckbox";

const codeToSnakeCase = fp.flow(fp.snakeCase, fp.toUpper);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const sampleItemsStream = propsStream
      .first()
      .switchMap(props =>
        getSampleItemsForSelect().catch(error => {
          props.showErrorMessage(error);
          return Observable.of({});
        }),
      )
      .startWith(List())
      .map(
        fp.flow(
          response => fromJS(response),
          response => response.getIn(["data", "list"], List()),
        ),
      )
      .distinctUntilChanged(isEqualData);
    return propsStream
      .combineLatest(sampleItemsStream, (props, sampleItems) => ({
        ...props,
        sampleItems,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit({
        ...values,
        code: codeToSnakeCase(values.code),
      });
    },
  }),
  reduxForm({
    form: "ServiceTypeForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("enter_name", "Enter name")
        : null,
      sampleItem:
        !values.sampleItem &&
        props.getLocalisationMessage("this_field_is_required"),
      code: validateString(values.code)
        ? props.getLocalisationMessage("enter_code", "Enter code")
        : null,
    }),
  }),
);

ServiceTypeForm.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  sampleItems: PropTypes.object,
};

function ServiceTypeForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={16} direction="column">
        <FlexBox gutter={16}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={`${getLocalisationMessage("name", "Name")} *`}
                  style={{ marginBottom: 15 }}
                />
                <FormTextField
                  name="nameRu"
                  fullWidth={true}
                  label={getLocalisationMessage("name_ru", "Name ru")}
                  style={{ marginBottom: 15 }}
                />

                <FormTextField
                  name="code"
                  fullWidth={true}
                  label={`${getLocalisationMessage("code", "Code")} *`}
                  style={{ marginBottom: 15 }}
                />

                <FormTextField
                  name="sorder"
                  fullWidth={true}
                  type="number"
                  label={getLocalisationMessage("sort_order", "Sort Order")}
                  style={{ marginBottom: 15 }}
                />

                <FormTextField
                  name="description"
                  fullWidth={true}
                  multiLine={true}
                  label={`${getLocalisationMessage(
                    "description",
                    "Description",
                  )} `}
                  style={{ marginBottom: 15 }}
                />
                <FormSelectField
                  autoWidth={true}
                  fullWidth={true}
                  name="sampleItem"
                  label={getLocalisationMessage("sample_items", "Sample items")}
                  disabled={!props.sampleItems.size}
                  options={props.sampleItems}
                  formatOption={x => x.get("name")}
                  compareOptions={isEqualDataIn("id")}
                />

                <FormCheckbox
                  name="showToDriver"
                  label={getLocalisationMessage(
                    "show_in_mobile_app",
                    "Show in mobile app",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <CardActions>
        <FlexBox flex={true} justify="flex-end">
          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </CardActions>
    </form>
  );
}

export default enhancer(ServiceTypeForm);
