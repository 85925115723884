import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import MultiLineCell from "./MultiLineCell";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(
  useSheet({
    textLeft: {
      textAlign: "left",
    },
    flag: {
      borderRadius: "50%",
      backgroundPosition: "35% center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "160%",
      border: "1px solid #eee",
    },
  }),
);

FlagMultiLineCell.propTypes = {
  classes: PropTypes.object,
  firstLine: PropTypes.node,
  secondLine: PropTypes.node,
  thirdLine: PropTypes.node,
  fallBackValue: PropTypes.node,
  countryCode: PropTypes.string,
  flagSize: PropTypes.number,
};

FlagMultiLineCell.defaultProps = {
  flagSize: 38,
};

function FlagMultiLineCell(props) {
  const { classes } = props;

  return (
    <FlexBox gutter={8} flex={true}>
      {props.countryCode && (
        <FlexBox align="center">
          <div
            className={classes.flag}
            style={{
              backgroundImage: `url(https://unpkg.com/react-flag-kit@0.2.3/assets/${
                props.countryCode
              }.svg)`,
              width: `${props.flagSize}px`,
              height: `${props.flagSize}px`,
            }}
          />
        </FlexBox>
      )}

      <FlexBox flex={true} className={classes.textLeft}>
        <MultiLineCell
          firstLine={props.firstLine}
          secondLine={props.secondLine}
          thirdLine={props.thirdLine}
          fallBackValue={props.fallBackValue}
        />
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(FlagMultiLineCell);
