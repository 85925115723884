import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import JMItemForm from "./JMItemForm";
import fp from "lodash/fp";
import { isEqualData } from "../../helpers/DataUtils";
import {
  addJurisdiction,
  editJurisdiction,
  getCachedJurisdictionLevel,
  getJurisdictionLevelPredictions,
} from "../../api/admin/AdminJurisdictionsApi";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { toSnakeCase } from "../../helpers/CaseMapper";
import PageLoading from "../ui-core/PageLoading";

const useTreeItemStyles = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  addButton: {
    textTransform: "capitalize",
    color: "#4CAF50",
  },
}));

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withState("isSubmitLoading", "setSubmitLoading", false),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.pick(["initialValues"]))
      .distinctUntilChanged(isEqualData)
      .map(({ initialValues }) => {
        const levelId = fp.get("level_id", initialValues);
        const levelName = fp.get("level_name", initialValues);
        const aliases = fp.get("aliases", initialValues);
        if (levelId) {
          return {
            ...initialValues,
            level: { id: levelId, name: levelName, aliases },
          };
        }

        return initialValues;
      })
      .startWith({});

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
      }),
    );
  }),
);

JMItemFormWrapper.propTypes = {
  initialValues: PropTypes.object,
  isCreate: PropTypes.bool,
  isSubmitLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRefreshRequest: PropTypes.func,
  setSubmitLoading: PropTypes.func,
};

function JMItemFormWrapper(props) {
  const classes = useTreeItemStyles();

  return (
    <FlexBox className={classes.root} direction="column" flex={true}>
      <PageLoading isLoading={props.isSubmitLoading} />

      <JMItemForm
        isCreate={props.isCreate}
        initialValues={props.initialValues}
        onDismiss={() => props.onRefreshRequest()}
        onSubmit={values => {
          props.setSubmitLoading(true);
          if (!values) return ResponseError.throw;

          if (fp.get("id", values)) {
            return editJurisdiction(toSnakeCase(values)).catch(
              ResponseError.throw,
            );
          }

          return addJurisdiction(toSnakeCase(values)).catch(
            ResponseError.throw,
          );
        }}
        onSubmitSuccess={response => {
          props.setSubmitLoading(false);
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully saved",
            ),
          );
          props.onRefreshRequest(fp.get("data", response));
        }}
        onSubmitFail={error => {
          props.setSubmitLoading(false);
          props.showErrorMessage(error);
        }}
        getCachedJurisdictionLevel={getCachedJurisdictionLevel}
        getJurisdictionLevelPredictions={getJurisdictionLevelPredictions}
      />
    </FlexBox>
  );
}

export default enhancer(JMItemFormWrapper);
