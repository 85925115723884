import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import { PROMO_LIST_URL } from "../../constants/AdminPathConstants";
import { getPromo, updatePromo } from "../../api/admin/AdminPromoApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import PromoForm from "../../components/promos-core/PromoForm";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .map(fp.flow(fp.get("params.id"), fp.toFinite))
      .distinctUntilChanged()
      .switchMap(id => getPromo(id).catch(error => Observable.of({ error })))
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data"), fp.toPlainObject, toCamelCase),
          ),
        ),
      )
      .distinctUntilChanged(fp.isEqual);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        isLoading: response.pending,
        initialValues: response.payload,
      }));
  }),
);

AdminPromoItem.propTypes = {
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  setLocation: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function AdminPromoItem(props) {
  return (
    <AdminAppLayout title={props.i18n.get("edit_promo", "Edit Promo")}>
      <PageLoading isLoading={props.isLoading} />

      <PromoForm
        initialValues={props.initialValues}
        getCachedServiceType={getCachedServiceType}
        getServiceTypePredictions={getServiceTypePredictions}
        onDismiss={() => props.setLocation(PROMO_LIST_URL)}
        onSubmit={fp.flow(toSnakeCase, values =>
          updatePromo(values.id, values).catch(ResponseError.throw),
        )}
        onSubmitSuccess={() => props.setLocation(PROMO_LIST_URL)}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPromoItem);
