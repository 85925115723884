import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { formValues, formValueSelector, reduxForm } from "redux-form";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import InfoIcon from "../icons/InfoIcon";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import { isEmpty, isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ACTIVE,
  ANDROID_MAP_KEY,
  DISTANCE_MATRIX_KEY,
  GEO_CODING_API_KEY,
  IOS_MAP_KEY,
  MAP_KEY,
  PLACE_API_KEY,
  PROVIDER_KEY_TYPE,
  PROVIDER_TYPE,
  YANDEX_MAP,
} from "../../constants/MapSettingsConstants";
import {
  getActiveMapProvider,
  getMapProviderList,
} from "../../api/admin/AdminMapApi";

const styles = {
  infoTooltip: {
    position: "absolute",
    right: "-.5rem",
    top: "-.5rem",
  },
};
const valueSelector = formValueSelector("MapSettingForm");

const keySelector = (keys, callback) => {
  const response = callback;
  keys.get("keys", List()).forEach(key => {
    const keygen = key.get("key");
    switch (key.get(PROVIDER_KEY_TYPE, "")) {
      case PLACE_API_KEY:
        response.placeApiKey = keygen;
        break;
      case MAP_KEY:
        response.mapKey = keygen;
        break;
      case IOS_MAP_KEY:
        response.iosMapKey = keygen;
        break;
      case ANDROID_MAP_KEY:
        response.androidMapKey = keygen;
        break;
      case DISTANCE_MATRIX_KEY:
        response.distanceMatrixKey = keygen;
        break;
      case GEO_CODING_API_KEY:
        response.geoCodingApiKey = keygen;
        break;
      default:
        break;
    }
  });

  return response;
};

const enhancer = compose(
  withState("isLoading", "setIsLoading", false),
  getContext({
    getMapProviderKeysTypes: PropTypes.func.isRequired,
    getMapProviderList: PropTypes.func.isRequired,
  }),
  useSheet({
    overall: { height: "64px" },
    error: { color: "red" },
    providerBox: { padding: 0, background: "rgba(0,0,0,0.001)" },
    providersList: { padding: 2, maxHeight: 450 },
    title: { fontSize: 15, color: "#666", marginBottom: 5 },
    fieldsBox: { padding: "0 15px" },
    providerButton: {
      padding: "15px",
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.2)",
      marginBottom: 3,
      borderRadius: 4,
    },
    activeMenu: { background: "rgba(0, 151, 136,0.2)" },
    saveButton: { background: "rgba(0,0,0,0.1)", height: 45, marginTop: 15 },
    noProvider: {
      color: "rgba(200,0,0,0.6)",
      fontSize: 16,
      textAlign: "center",
    },
    infoIconParent: {
      position: "relative",
    },
    infoIcon: {
      width: "13px",
    },
  }),
  connect(state => ({
    providerType: valueSelector(state, "providerType"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const activeProviderStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props => props.providerType)
      .distinctUntilChanged(isEqualData)
      .filter(providerType => !providerType)
      .switchMap(() =>
        getActiveMapProvider().catch(error => Observable.of({ error })),
      )
      .filter(response => response.payload)
      .map(
        fp.flow(
          response => fromJS(response),
          response => response.get("payload", Map()),
          response => response.get("data", Map()),
          fp.toPlainObject,
        ),
      )
      .withLatestFrom(propsStream)
      .map(([response, props]) => {
        const activeProviderKeys = {
          id: response.get("id"),
          providerType: props.providers
            .toJS()
            .find(
              provider => provider.description === response.get(PROVIDER_TYPE),
            ),
          placeApiKey: "",
          mapKey: "",
          iosMapKey: "",
          androidMapKey: "",
          distanceMatrixKey: "",
          geoCodingApiKey: "",
          active: response.get(ACTIVE),
        };

        return keySelector(response, activeProviderKeys);
      })
      .startWith(Map());

    const getProviderKeysStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props => props.providerType)
      .distinctUntilChanged(isEqualData)
      .filter(providerType => getObjectId(providerType))
      .map(
        providerType =>
          new DataListFilter({ provider_type: providerType.description }),
      )
      .switchMap(filter =>
        getMapProviderList(filter).catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response => response.get("data", List()),
          response => response.get(0, Map()),
          fp.toPlainObject,
        ),
      )
      .withLatestFrom(propsStream)
      .map(([response, props]) => {
        const tempInitialValues = {
          id: response.get("id"),
          providerType: props.providerType,
          placeApiKey: "",
          mapKey: "",
          iosMapKey: "",
          androidMapKey: "",
          distanceMatrixKey: "",
          geoCodingApiKey: "",
          active: response.get(ACTIVE),
        };
        return keySelector(response, tempInitialValues);
      })
      .startWith(Map())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        activeProviderStream,
        getProviderKeysStream,
        (props, activeProvider, providerKeys) => ({
          ...props,
          initialValues: isEmpty(providerKeys) ? activeProvider : providerKeys,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "MapSettingForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      mapKey:
        !values.mapKey &&
        props.getLocalisationMessage(
          "please_enter_map_key",
          "Please enter map key",
        ),
      placeApiKey:
        !values.placeApiKey &&
        props.getLocalisationMessage(
          "please_enter_place_api_key",
          "Please enter place api key",
        ),
    }),
  }),

  formValues(
    "providerType",
    "placeApiKey",
    "mapKey",
    "iosMapKey",
    "androidMapKey",
    "distanceMatrixKey",
    "geoCodingApiKey",
    "active",
  ),
);

MapSettingsForm.propTypes = {
  providerType: PropTypes.object,
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  providers: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function MapSettingsForm(props) {
  const { classes, getLocalisationMessage } = props;
  const isChoosedYandex =
    props.providerType && props.providerType.name === YANDEX_MAP;
  return (
    <FlexBox
      gutter={16}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox flex={true} gutter={8}>
        <FlexBox
          style={{ width: 200 }}
          className={classes.providerBox}
          direction="column"
        >
          <FlexBox
            flex="grow"
            className={classes.providersList}
            direction="column"
          >
            <FlexBox flex={true} className={classes.infoIconParent}>
              <FormSelectField
                name="providerType"
                fullWidth={true}
                label={getLocalisationMessage("provider", "Provider")}
                hintText={getLocalisationMessage(
                  "select_provider",
                  "Select Provider",
                )}
                formatOption={x => x.name}
                options={props.providers}
              />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "please_note_that_only_a_single_map_provider_can_be_used",
                  "Please note that only a single map provider can be used",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox className={classes.fieldsBox} flex={true}>
          {!isEmpty(props.providerType) ? (
            <FlexBox
              direction="column"
              style={{ width: "100%" }}
              justify="space-between"
            >
              <FlexBox direction="column" style={{ gap: 16 }}>
                <h4 className={classes.title}>
                  {getLocalisationMessage("provider_config", "Provider Config")}
                </h4>
                <FormTextField
                  name="mapKey"
                  fullWidth={true}
                  label={`${props.getLocalisationMessage(
                    "map_key",
                    "Map Key",
                  )} *`}
                />
                <FormTextField
                  name="iosMapKey"
                  fullWidth={true}
                  label={props.getLocalisationMessage(
                    "ios_map_key",
                    "iOS Map Key",
                  )}
                />
                <FormTextField
                  name="androidMapKey"
                  fullWidth={true}
                  label={props.getLocalisationMessage(
                    "android_map_key",
                    "Android Map Key",
                  )}
                />
                <FormTextField
                  name="placeApiKey"
                  fullWidth={true}
                  label={`${props.getLocalisationMessage(
                    "place_api_key",
                    "Place API Key",
                  )} *`}
                />
                {isChoosedYandex && (
                  <FlexBox style={{ gap: 16 }} direction="column">
                    <FormTextField
                      name="distanceMatrixKey"
                      fullWidth={true}
                      label={props.getLocalisationMessage(
                        "distance_matrix_key",
                        "Distance Matrix Api Key",
                      )}
                    />
                    <FormTextField
                      name="geoCodingApiKey"
                      fullWidth={true}
                      label={props.getLocalisationMessage(
                        "geo_coding_api_key",
                        "Geo Coding Api Key",
                      )}
                    />
                  </FlexBox>
                )}
                <FormCheckbox
                  name="active"
                  label={getLocalisationMessage("active", "Active")}
                />
                <Button type="submit" className={classes.saveButton}>
                  {getLocalisationMessage("save", "Save")}
                </Button>
              </FlexBox>
            </FlexBox>
          ) : props.isLoading ? (
            <PageLoading isLoading={true} />
          ) : (
            <FlexBox
              justify="center"
              align="center"
              flex={true}
              className={classes.noProvider}
            >
              {getLocalisationMessage(
                "please_select_map_provider",
                "Please, select Map provider",
              )}
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(MapSettingsForm);
