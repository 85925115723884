import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import { Card, CardContent, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import FormSelectField from "../form/FormSelectField";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue, isEqualData, isShallowEqual } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import TimeSlotType, { PICKUP } from "../../constants/TimeSlotType";

const getValues = getFormValues("OrderJobForm");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    modal: {
      maxWidth: "450px",
      minWidth: "450px",
      minHeight: "auto",
    },
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit({
        type: values.type,
        order_numbers: values.orderNumbers,
        warehouse: isValidObjectId(values.warehouse) ? values.warehouse : null,
      });
    };
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(() => ({ type: PICKUP }));

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    form: "OrderJobForm",
    enableReinitialize: true,

    validate: (values, props) => ({
      warehouse:
        !getValue(values.warehouse, "id") &&
        props.getLocalisationMessage(
          "please_select_warehouse",
          "Please Select Warehouse",
        ),
      orderNumbers:
        fp.isEmpty(values.orderNumbers) &&
        props.getLocalisationMessage("add_orders", "Add Orders"),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
  mapPropsStream(propsStream =>
    propsStream
      .map(
        fp.flow(
          fp.pick([
            "values",
            "dirty",
            "pristine",
            "submitting",
            "reset",
            "onDismiss",
            "handleSubmit",
            "getCachedCustomer",
            "getCustomerPredictions",
            "getCachedWarehouse",
            "getWarehousePredictions",
            "orderStatusCodes",
            "getLocalisationMessage",
          ]),
        ),
      )
      .combineLatest(props => ({
        ...props,
      }))
      .distinctUntilChanged(isEqualData),
  ),
);

OrderJobForm.propTypes = {
  values: PropTypes.object,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onDismiss: PropTypes.func,

  getCachedWarehouse: PropTypes.func,
  getWarehousePredictions: PropTypes.func,
  getCachedCustomer: PropTypes.func,
  getCustomerPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function OrderJobForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox
      align="center"
      container={16}
      direction="column"
      element={<form id="OrderJobForm" onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox
        flex="none"
        gutter={8}
        direction="column"
        style={{ width: "100%" }}
      >
        <Card>
          <CardContent>
            <FlexBox direction="column" flex={true}>
              <FormSelectField
                name="type"
                autoWidth={true}
                fullWidth={true}
                formatOption={x => getLocalisationMessage(x, formatText(x))}
                options={TimeSlotType}
                label={getLocalisationMessage("service_type", "Service Type")}
              />
            </FlexBox>

            <FlexBox direction="column" flex={true}>
              <FormCustomerAutoComplete
                name="customer"
                label={getLocalisationMessage("customer", "Customer")}
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>

            <FlexBox direction="column" flex={true}>
              <FormWarehouseAutoComplete
                name="warehouse"
                fullWidth={true}
                label={`${getLocalisationMessage("warehouse", "Warehouse")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>

            <FlexBox direction="column" flex={true}>
              <FormChipInput
                name="orderNumbers"
                focus={true}
                fullWidth={true}
                addOnBlur={true}
                label={`${getLocalisationMessage(
                  "orders_numbers",
                  "Orders Numbers",
                )} *`}
              />
            </FlexBox>

            <FlexBox direction="column">
              <FlexBox justify="flex-end">
                <Button onClick={props.onDismiss}>
                  {getLocalisationMessage("cancel", "Cancel")}
                </Button>
                <Button type="submit" onClick={props.onSubmit}>
                  {getLocalisationMessage("save", "Save")}
                </Button>
              </FlexBox>
            </FlexBox>
          </CardContent>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderJobForm);
