import React from "react";
import { List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DataList, { DataListColumn } from "../data-list/DataList";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import TextWithLink from "../../../client/components/router/TextWithLink";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

CallTimingsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  altHeader: PropTypes.node,
  selectable: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  createOrderHref: PropTypes.func,
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func,
};

CallTimingsList.defaultProps = {
  maxSearchItems: 0,
};

function CallTimingsList(props) {
  const { getLocalisationMessage } = props;
  return (
    <DataList
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      maxSearchItems={props.maxSearchItems}
      totalCount={props.totalCount}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      overscanRowCount={10}
      rowGetter={options => props.list.get(options.index)}
      cardActionIcons={props.cardActionIcons}
      altHeader={props.altHeader}
    >
      <DataListColumn
        width={104}
        disableSort={true}
        label={getLocalisationMessage("id", "ID")}
        dataKey="id"
        justifyContent="center"
        cellRenderer={row => (
          <strong>
            <TextWithLink to={props.createOrderHref(row.cellData.get("id"))}>
              {row.cellData.get("id")}
            </TextWithLink>
          </strong>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("start_time", "Start Time")}
        dataKey="start_time"
        justifyContent="center"
        cellRenderer={row => row.cellData.get("start_time")}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("end_time", "End Time")}
        dataKey="end_time"
        justifyContent="center"
        cellRenderer={row => row.cellData.get("end_time")}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("address_type", "Address Type")}
        dataKey="address_type"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {getLocalisationMessage(row.cellData.get("address_type")) ||
              formatText(row.cellData.get("address_type"))}
          </div>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("courier_type", "Courier Type")}
        dataKey="courier_type"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {getLocalisationMessage(row.cellData.get("courier_type")) ||
              formatText(row.cellData.get("courier_type"))}
          </div>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage(
          "call_timing_permission_type",
          "Call Timing Permission Type",
        )}
        dataKey="call_timing_permission_type"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {getLocalisationMessage(
              row.cellData.get("call_timing_permission_type"),
            ) || formatText(row.cellData.get("call_timing_permission_type"))}
          </div>
        )}
      />
    </DataList>
  );
}

export default enhancer(CallTimingsList);
