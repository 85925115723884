import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { COLUMN } from "../../components/orders-core/MUITableReport";
import _ from "lodash";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { Add, FilterList, Refresh, Search } from "@material-ui/icons";
import MUITable, {
  DIALOG,
  RENDER,
} from "../../components/orders-core/MUITable";
import TableListHeader from "../../wrappers/admin/TableListHeader";
import {
  CONSOLIDATED,
  ISSUED,
  RETURN,
  SIMPLE,
} from "../../constants/OrderType";
import {
  getBatchOrderUpdateList2,
  getHybridBatchOrderUpdateList,
  updateOrderReason,
} from "../../api/admin/AdminBatchApi";
import HybridListFilter from "../../wrappers/admin/HybridListFilter";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { DEFAULT_SUPPLIER } from "../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import AdminCODCodes from "../../constants/AdminCODCodes";
import OrderStatusCodes, {
  ISSUED_TO_RECIPIENT,
} from "../../constants/OrderStatusCodes";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  batchAsyncUpdateOrder,
  batchAsyncUpdateOrderHybrid,
  getBarcodeV2,
} from "../../api/admin/AdminOrderApi";
import ResponseError from "../../helpers/ResponseError";
import { getUserId, getUserWarehouse } from "../../reducers/ProfileReducer";
import HybridCreateConsolidatedForm from "../../components/orders-core/HybridCreateConsolidatedForm";
import { DATE } from "../../components/orders-core/MUITableSimple";
import { clearHybridOrders } from "../../reducers/HybridOrdersReducer";
import HybridCreatePostPackageForm from "../../components/orders-core/HybridCreatePostPackageForm";
import { endOfToday, startOfToday } from "date-fns";
import Tabs from "../../components/ui-core/TabsND";
import HybridReturnCreate from "./HybridReturnCreate";
import AdminHybredReturnBatchItemDialogWrapper from "../../wrappers/admin/AdminHybredReturnBatchItemDialogWrapper";
import HybridIIssuedCreate from "./HybridIIssuedCreate";

const enhancer = compose(
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      userId: getUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, clearHybridOrders },
  ),
);
const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  input: { "& input": { fontSize: "20px" } },
});

const HybridContainer = props => {
  const {
    getLocalisationMessage,
    showErrorMessage: showErrorMessage1,
    userWarehouse,
    userId,
  } = props;
  const classes = useStyles();
  const [isOpenSimpleCreate, setIsOpenSimpleCreate] = useState(false);
  const [isLoadingSimpleCreate, setIsLoadingSimpleCreate] = useState(false);
  const [
    isLoadingConsolidatedCreate,
    setIsLoadingConsolidatedCreate,
  ] = useState(false);
  const [isLoadingIssuedCreated, setIsLoadingIssuedCreated] = useState(false);
  const [isOpenConsolidatedCreate, setIsOpenConsolidatedCreate] = useState(
    false,
  );
  const [isOpenReturnCreate, setIsOpenReturnCreate] = useState(false);
  const [isOpenIssuedCreate, setIsOpenIssuedCreate] = useState(false);
  const [batchId, setBatchId] = useState(false);
  const [hybridBatchId, setHybridBatchId] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderNumberIssued, setOrderNumberIssued] = useState(null);
  const [order, setOrder] = useState({});
  const [orderIssued, setOrderIssued] = useState({});
  const [errorReturn, setErrorReturn] = useState(false);
  const [errorIssued, setErrorIssued] = useState(false);
  const [isOrderLoading, setOrderLoading] = useState(false);
  const [isOrderIssuedLoading, setOrderIssuedLoading] = useState(false);
  const date = startOfToday();
  const date2 = endOfToday();
  const [filter, setFilter] = useState({
    refresh: false,
    from_date_time: formatDateTimeToUrl(date),
    to_date_time: formatDateTimeToUrl(date2),
    type: SIMPLE,
    page: 0,
    size: 20,
    user_id: userId,
    hybrid: true,
    excludedStatuses: ISSUED_TO_RECIPIENT,
  });

  useEffect(() => {
    if (orderNumber) {
      if (orderNumber && orderNumber.length > 0) {
        setOrderLoading(true);
        getBarcodeV2(orderNumber[0])
          .then(res => {
            if (res && res.data) {
              setOrder(res.data);
              setErrorReturn(false);
            } else {
              setErrorReturn(true);
            }
            setOrderLoading(false);
          })
          .catch(() => {
            setOrderLoading(false);
            setErrorReturn(true);
          });
      }
    }
  }, [orderNumber]);

  useEffect(() => {
    if (orderNumberIssued) {
      setOrderIssuedLoading(true);
      if (orderNumberIssued && orderNumberIssued.length > 0) {
        getBarcodeV2(orderNumberIssued[0])
          .then(res => {
            if (res && res.data) {
              setOrderIssuedLoading(false);
              setOrderIssued(res.data);
              setErrorIssued(false);
            } else {
              setErrorIssued(true);
            }
            setOrderIssuedLoading(false);
          })
          .catch(() => {
            setOrderIssuedLoading(false);
            setErrorIssued(true);
          });
      }
    }
  }, [orderNumberIssued]);

  useEffect(() => {
    setIsLoading(true);

    if (filter.type === SIMPLE || filter.type === CONSOLIDATED) {
      getBatchOrderUpdateList2(filter)
        .then(res => {
          setIsLoading(false);
          setList(_.get(res, "data.list"));
        })
        .catch(error => {
          setIsLoading(false);
          showErrorMessage1(error);
        });
    }

    if (filter.type === RETURN) {
      getHybridBatchOrderUpdateList({
        from_date_time: filter.from_date_time,
        to_date_time: filter.to_date_time,
        page: filter.page,
        size: filter.size,
        user_id: filter.user_id,
        hybrid: true,
      })
        .then(res => {
          setIsLoading(false);
          setList(_.get(res, "data.list"));
        })
        .catch(error => {
          setIsLoading(false);
          showErrorMessage1(error);
        });
    }

    if (filter.type === ISSUED) {
      getBatchOrderUpdateList2({
        from_date_time: filter.from_date_time,
        to_date_time: filter.to_date_time,
        page: filter.page,
        size: filter.size,
        user_id: filter.user_id,
        statuses: ISSUED_TO_RECIPIENT,
        hybrid: true,
      })
        .then(res => {
          setIsLoading(false);
          setList(_.get(res, "data.list"));
        })
        .catch(error => {
          setIsLoading(false);
          showErrorMessage1(error);
        });
    }
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });
  const tabs = [
    {
      title: getLocalisationMessage("simple"),
      value: SIMPLE,
    },
    {
      title: getLocalisationMessage("consolidated"),
      value: CONSOLIDATED,
    },
    {
      title: getLocalisationMessage("return"),
      value: RETURN,
    },
    {
      title: getLocalisationMessage("issued"),
      value: ISSUED,
    },
  ];
  return (
    <AdminAppLayout
      title={getLocalisationMessage("Hybrid")}
      className={classes.contentRoot}
    >
      <HybridListFilter
        open={isOpenFilter}
        defaultFilter={filter}
        onFilterChange={v => setFilter({ ...filter, ...v })}
        onRequestClose={() => setIsOpenFilter(false)}
      />
      <HybridCreatePostPackageForm
        title="batch_update"
        open={isOpenSimpleCreate}
        isLoadingSubmit={isLoadingSimpleCreate}
        codCodes={AdminCODCodes}
        statusCodes={OrderStatusCodes}
        withInTransitToSupplier={true}
        privacyTypes={AdminPrivacyTypes}
        onSubmit={fp.flow(toSnakeCase, values => {
          setIsLoadingSimpleCreate(true);
          const request = {
            ...values,
            privacy: PUBLIC,
            supplier: DEFAULT_SUPPLIER,
            warehouse: userWarehouse,
            type: SIMPLE,
          };

          return batchAsyncUpdateOrderHybrid(
            fp.omit(["batch_ids", "is_barcode", "only_barcode"], request),
          )
            .catch(ResponseError.throw)
            .finally(() => setIsLoadingSimpleCreate(false));
        })}
        onRequestClose={() => {
          props.clearHybridOrders();
          setIsOpenSimpleCreate(false);
        }}
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={response => {
          setIsOpenSimpleCreate(false);
          setBatchId(response.data.id);
          setFilter({ ...filter, refresh: !filter.refresh });
          return props.clearHybridOrders();
        }}
      />
      <HybridCreateConsolidatedForm
        title="batch_update"
        open={isOpenConsolidatedCreate}
        isLoadingSubmit={isLoadingConsolidatedCreate}
        codCodes={AdminCODCodes}
        statusCodes={OrderStatusCodes}
        withInTransitToSupplier={true}
        privacyTypes={AdminPrivacyTypes}
        selectedItems={selectedItems}
        onSubmit={fp.flow(toSnakeCase, values => {
          setIsLoadingConsolidatedCreate(true);
          const request = {
            ...values,
            privacy: PUBLIC,
            supplier: DEFAULT_SUPPLIER,
            warehouse: userWarehouse,
            type: CONSOLIDATED,
          };

          return batchAsyncUpdateOrderHybrid(
            fp.omit(["batch_ids", "is_barcode", "only_barcode"], request),
          )
            .catch(ResponseError.throw)
            .finally(() => setIsLoadingConsolidatedCreate(false));
        })}
        onRequestClose={() => setIsOpenConsolidatedCreate(false)}
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={response => {
          setIsOpenConsolidatedCreate(false);
          setBatchId(response.data.id);
          setFilter({ ...filter, refresh: !filter.refresh });
        }}
      />
      <AdminBatchUpdatesItemDialogWrapper
        isHybrid={true}
        hideAddBatch={filter.type === ISSUED}
        batchId={batchId}
        onRequestClose={() => {
          setIsOpenSimpleCreate(false);
          setIsOpenConsolidatedCreate(false);
          setBatchId(false);
        }}
      />

      <AdminHybredReturnBatchItemDialogWrapper
        isHybrid={true}
        batchId={hybridBatchId}
        onRequestClose={() => {
          setIsOpenReturnCreate(false);
          setHybridBatchId(false);
        }}
      />

      {!(isOpenReturnCreate || isOpenIssuedCreate) && (
        <FlexBox flex={true}>
          <Tabs
            disabled={isOpenReturnCreate || isOpenIssuedCreate}
            style={{ width: "100%" }}
            variant="scrollable"
            items={tabs}
            value={filter.type}
            onChange={(event, value) => {
              setFilter({ ...filter, type: value });
              setSelectedItems([]);
            }}
          />
        </FlexBox>
      )}

      {!(isOpenReturnCreate || isOpenIssuedCreate) && (
        <FlexBox
          style={{ backgroundColor: "white", height: "100%" }}
          direction="column"
          justify="space-between"
        >
          <TableListHeader
            buttons={
              <FlexBox
                align={ALIGN_CENTER}
                style={{ flex: "1 1 auto", gap: 16 }}
              >
                <div className={classes.search}>
                  <Search
                    fontSize="large"
                    style={{
                      color: "rgba(0, 0, 0, 0.23)",
                      width: 24,
                      height: 24,
                      left: 16,
                      top: 7,
                      position: "absolute",
                    }}
                  />
                  <ChipTextField
                    style={{
                      padding: "0 3rem",
                    }}
                    fullWidth={true}
                    addOnBlur={false}
                    clearOnBlur={false}
                    value={parseString(filter.search)}
                    disableUnderline={true}
                    placeholder={getLocalisationMessage("type_here_to_search")}
                    onChange={v =>
                      setFilter({ ...filter, search: stringifyArray(v) })
                    }
                  />
                </div>
                <FlexBox>
                  {selectedItems.length > 0 ? (
                    <CustomButton
                      size="small"
                      startIcon={<Add />}
                      variant={CONTAINED}
                      color={SECONDARY}
                      style={{ borderRadius: "20px" }}
                      onClick={() => {
                        setIsOpenConsolidatedCreate(true);
                      }}
                    >
                      {getLocalisationMessage("create_bag")}
                    </CustomButton>
                  ) : filter.type === RETURN ? (
                    <CustomButton
                      size="small"
                      startIcon={<Add />}
                      variant={CONTAINED}
                      color={SECONDARY}
                      style={{ borderRadius: "20px" }}
                      onClick={() => {
                        setIsOpenReturnCreate(true);
                      }}
                    >
                      {getLocalisationMessage("create_new")}
                    </CustomButton>
                  ) : filter.type === ISSUED ? (
                    <CustomButton
                      size="small"
                      startIcon={<Add />}
                      variant={CONTAINED}
                      color={SECONDARY}
                      style={{ borderRadius: "20px" }}
                      onClick={() => {
                        setIsOpenIssuedCreate(true);
                      }}
                    >
                      {getLocalisationMessage("create_new")}
                    </CustomButton>
                  ) : (
                    filter.type === SIMPLE && (
                      <CustomButton
                        size="small"
                        startIcon={<Add />}
                        variant={CONTAINED}
                        color={SECONDARY}
                        style={{ borderRadius: "20px" }}
                        onClick={() => {
                          setIsOpenSimpleCreate(true);
                        }}
                      >
                        {getLocalisationMessage("create_post_paket")}
                      </CustomButton>
                    )
                  )}
                </FlexBox>
                <FlexBox>
                  <IconButton
                    className={isLoading && classes.refresh}
                    tooltip={getLocalisationMessage("refresh")}
                    onClick={() => refreshOrderList()}
                  >
                    <Refresh />
                  </IconButton>
                </FlexBox>
                <FlexBox>
                  <IconButton onClick={() => setIsOpenFilter(true)}>
                    <FilterList />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            }
          />

          {(filter.type === SIMPLE || filter.type === CONSOLIDATED) && (
            <MUITable
              setId={filter.type !== RETURN ? setBatchId : setHybridBatchId}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              filter={filter}
              isLoading={isLoading}
              list={list}
              total={list.length}
              page={filter.page}
              rowsPerPage={filter.size}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              withCheckbox={filter.type === SIMPLE}
              columns={[
                {
                  type: DIALOG,
                  name: "id",
                  label: getLocalisationMessage("id"),
                  align: "center",
                  keyName: "id",
                },
                {
                  type: DATE,
                  name: "created_date",
                  keyName: "created_date",
                  label: getLocalisationMessage("created_date"),
                },
                {
                  type: RENDER,
                  name: "user",
                  label: getLocalisationMessage("user"),
                  render: row => _.get(row, `user.name`, ""),
                },
                {
                  type: RENDER,
                  name: "to_jurisdiction",
                  label: getLocalisationMessage("to_jurisdiction"),
                  render: row => _.get(row, `to_jurisdiction.name`, ""),
                },
                {
                  type: RENDER,
                  name: "to_warehouse",
                  label: getLocalisationMessage("to_warehouse"),
                  render: row => _.get(row, `to_warehouse.name`, ""),
                },
                {
                  type: COLUMN,
                  name: "orders_count",
                  label: getLocalisationMessage("number_of_items"),
                },
              ]}
            />
          )}

          {(filter.type === RETURN || filter.type === ISSUED) && (
            <MUITable
              setId={filter.type !== RETURN ? setBatchId : setHybridBatchId}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              filter={filter}
              isLoading={isLoading}
              list={list}
              total={list.length}
              page={filter.page}
              rowsPerPage={filter.size}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              withCheckbox={filter.type === SIMPLE}
              columns={[
                {
                  type: DIALOG,
                  name: "id",
                  label: getLocalisationMessage("id"),
                  align: "center",
                  keyName: "id",
                },
                {
                  type: DATE,
                  name: "created_date",
                  keyName: "created_date",
                  label: getLocalisationMessage("created_date"),
                },
                {
                  type: RENDER,
                  name: "user",
                  label: getLocalisationMessage("user"),
                  render: row => _.get(row, `user.name`, ""),
                },
                {
                  type: RENDER,
                  name: "warehouse",
                  label: getLocalisationMessage("warehouse"),
                  render: row => _.get(row, `warehouse.name`, ""),
                },
                {
                  type: COLUMN,
                  name: "orders_count",
                  label: getLocalisationMessage("number_of_items"),
                },
              ]}
            />
          )}
        </FlexBox>
      )}

      {isOpenIssuedCreate && (
        <HybridIIssuedCreate
          setError={setErrorIssued}
          error={errorIssued}
          isOrderLoading={isOrderIssuedLoading}
          classes={classes}
          onClose={() => {
            setIsOpenIssuedCreate(false);
            setOrderIssued({});
            setErrorIssued(false);
            setFilter({ ...filter, refresh: !filter.refresh });
          }}
          setOrderNumber={setOrderNumberIssued}
          setOrder={setOrderIssued}
          setHybridBatchId={setHybridBatchId}
          orderNumber={orderNumberIssued}
          order={orderIssued}
          isLoadingSubmit={isLoadingIssuedCreated}
          onSubmit={orders => {
            setIsLoadingIssuedCreated(true);
            const result = Object.values(
              orders.reduce((c, { status, barcode }) => {
                // eslint-disable-next-line no-param-reassign
                c[status] = c[status] || {
                  order_status: status,
                  order_barcodes: [],
                };
                c[status].order_barcodes.push(barcode);
                return c;
              }, {}),
            );
            const request = {
              order_barcodes: result[0].order_barcodes,
              order_status: result[0].order_status,
              privacy: PUBLIC,
              warehouse: userWarehouse,
            };

            return batchAsyncUpdateOrder(request)
              .then(res => {
                if (res && res.data) {
                  setBatchId(res.data.id);
                }
              })
              .catch(error => showErrorMessage1(error))
              .finally(() => {
                setIsLoadingIssuedCreated(false);
                setIsOpenIssuedCreate(false);
                setOrderIssued({});
                setErrorIssued(false);
                setFilter({ ...filter, refresh: !filter.refresh });
              });
          }}
        />
      )}

      {isOpenReturnCreate && (
        <HybridReturnCreate
          setError={setErrorReturn}
          error={errorReturn}
          isOrderLoading={isOrderLoading}
          setOrderLoading={setOrderIssuedLoading}
          classes={classes}
          onClose={() => {
            setIsOpenReturnCreate(false);
            setOrder({});
            setErrorReturn(false);
            setFilter({ ...filter, refresh: !filter.refresh });
          }}
          setOrderNumber={setOrderNumber}
          setOrder={setOrder}
          setHybridBatchId={setHybridBatchId}
          orderNumber={orderNumber}
          order={order}
          onSubmit={orders => {
            const result = Object.values(
              orders.reduce((c, { status, barcode }) => {
                // eslint-disable-next-line no-param-reassign
                c[status] = c[status] || { status, barcodes: [] };
                c[status].barcodes.push(barcode);
                return c;
              }, {}),
            );

            updateOrderReason(result)
              .then(res => {
                if (res && res.data) {
                  setHybridBatchId(res.data.id);
                }
              })
              .catch(ResponseError.throw)
              .finally(() => {
                setOrder({});
                setIsOpenReturnCreate(false);
                setErrorReturn(false);
                setFilter({ ...filter, refresh: !filter.refresh });
              });
          }}
        />
      )}
    </AdminAppLayout>
  );
};
HybridContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  clearHybridOrders: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  userWarehouse: PropTypes.object,
  userId: PropTypes.object,
};

export default enhancer(HybridContainer);
