import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Chip, Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: { padding: "5px 15px", backgroundColor: "#fff" },
    checkbox: { whiteSpace: "nowrap" },
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  reduxForm({
    enableReinitialize: true,
    form: "BankDepositFilterForm",
  }),
  formValues({ toDate: "toDate", fromDate: "fromDate" }),
  pureComponent(fp.pick(["toDate", "fromDate"])),
);

BankDepositFilterForm.propTypes = {
  classes: PropTypes.object,

  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  toDate: PropTypes.instanceOf(Date),
  fromDate: PropTypes.instanceOf(Date),

  initialValues: PropTypes.object,
  onChange: PropTypes.func,
  customers: PropTypes.instanceOf(List),

  getCachedCustomer: PropTypes.func.isRequired,
  getCustomerPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function BankDepositFilterForm(props) {
  const { classes, customers, getLocalisationMessage } = props;

  return (
    <div className={classes.container}>
      <form autoComplete="off" onSubmit={props.handleSubmit}>
        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            {Boolean(customers && customers.size > 0) && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                }}
              >
                {customers
                  .map(item => (
                    <Chip
                      style={{ margin: 4, flex: "1 auto" }}
                      key={item.get("id")}
                      label={item.get("name")}
                    />
                  ))
                  .toArray()}
              </div>
            )}
          </FlexBox>

          <FlexBox flex={true} gutter={8}>
            <FlexBox>
              <FormDateField
                name="fromDate"
                fullWidth={true}
                hintText={getLocalisationMessage("from_date", "From Date")}
              />
            </FlexBox>

            {Boolean(props.toDate) && (
              <FlexBox>
                <IconButton onClick={() => props.change("fromDate", null)}>
                  <NavigationCancel />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox flex={true} gutter={8}>
            <FlexBox>
              <FormDateField
                name="toDate"
                fullWidth={true}
                hintText={getLocalisationMessage("to_date", "To Date")}
              />
            </FlexBox>

            {Boolean(props.toDate) && (
              <FlexBox>
                <IconButton onClick={() => props.change("toDate", null)}>
                  <NavigationCancel />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox flex={true} align="center">
            <Button type="submit">
              {" "}
              {getLocalisationMessage("get_orders", "Get Orders")}{" "}
            </Button>
          </FlexBox>
        </FlexBox>
      </form>
    </div>
  );
}

export default enhancer(BankDepositFilterForm);
