import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const PACKAGE_RULE_URL = `${API_ROOT_URL}/admin/package/rules`;
const PACKAGE_RULE_ITEM_URL = `${API_ROOT_URL}/admin/package/rules/%s`;
const PACKAGE_RULE_CHOOSE_URL = `${API_ROOT_URL}/admin/package/rules/choose`;
const PACKAGE_RULE_CLONE_URL = `${API_ROOT_URL}/admin/package/rules/%s/clone`;
const PACKAGE_RULE_CONDITIONS_UPLOAD_URL = `${API_ROOT_URL}/admin/package/rules/conditions/upload`;
const PACKAGE_RULE_MATCH_MODES_URL = `${API_ROOT_URL}/admin/package/rules/match_modes`;

const PACKAGE_RULE_CLIENT_TYPE = `${API_ROOT_URL}/admin/rule_client_type`;

// const PACKAGE_RULE_EVALUATE_URL = `${API_ROOT_URL}/admin/package/rules/evaluate`;
// const PACKAGE_RULE_FIELDS_URL = `${API_ROOT_URL}/admin/package/rules/fields`;
// const PACKAGE_RULE_REINDEX_URL = `${API_ROOT_URL}/admin/package/rules/reindex`;
// const PACKAGE_RULE_CUSTOMERS_URL = `${API_ROOT_URL}/admin/package/rules/customers`;

type PackageRuleType = "CUSTOM_PACKAGE" | "ORDER_RULE";
type RuleStatus = "ACTIVE" | "INACTIVE";
type ConditionalOperator = "AND" | "OR";

type Condition = {
  id: number,
  field: string,
  priority: 0,
  date_value: string,
  string_value: string,
  boolean_value: boolean,
  whole_number_value: number,
  fraction_number_value: number,
  conditional_operator: ConditionalOperator,
};

type ConditionGroup = {
  id: number,
  conditions: Condition[],
  conditional_operator: ConditionalOperator,
};

type SupplierRule = { id: number };

type PackageRule = {
  id: number,
  priority: number,
  rule_type: PackageRuleType,
  rule_status: RuleStatus,
  condition_group: ConditionGroup,
  suppliers: number[],
  drivers: number[],
  any_supplier: boolean,
  supplier_rule: SupplierRule,
};

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(PACKAGE_RULE_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(PACKAGE_RULE_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getPackageRule = id => itemCache.refresh(id);
export const getCachedPackageRule = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getPackageRulePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const createPackageRule = (rule: PackageRule) =>
  api.post(PACKAGE_RULE_URL, { body: rule });
export const updatePackageRule = (rule: PackageRule) =>
  api.put(PACKAGE_RULE_URL, { body: rule });
export const deletePackageRule = (id: number) =>
  api.delete(sprintf(PACKAGE_RULE_ITEM_URL, id));
export const getPackageRuleList = (filter: DataListFilter) =>
  api.getStream(PACKAGE_RULE_URL, { params: filter.getDefinedValues() });

export const clonePackageRules = (id: number, body) =>
  api.post(sprintf(PACKAGE_RULE_CLONE_URL, id), { body });

export const uploadRuleConditionsCSV = (params, file: File) =>
  api.postStream(PACKAGE_RULE_CONDITIONS_UPLOAD_URL, {
    file,
    params,
  });

export const getPackageRuleMatchModes = () =>
  api.getStream(PACKAGE_RULE_MATCH_MODES_URL);

export const getPackageRuleClientType = () =>
  api.getStream(PACKAGE_RULE_CLIENT_TYPE);
