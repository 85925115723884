import React from "react";
import { endOfToday, startOfMonth } from "date-fns";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import { renderIf } from "../../helpers/HOCUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_ORDER_REVENUE_REPORT_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({ container: { maxWidth: "320px" } }),
  reduxForm({
    form: "OrderRevenueReportDialog",
    initialValues: { fromDate: startOfMonth(new Date()), toDate: endOfToday() },
  }),
  formValues({ fromDate: "fromDate", toDate: "toDate" }),
);

OrderRevenueReportDialog.propTypes = {
  classes: PropTypes.object,
  toDate: PropTypes.instanceOf(Date),
  fromDate: PropTypes.instanceOf(Date),
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderRevenueReportDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      open={true}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          maxWidth: "320px",
        },
      }}
    >
      <DialogTitle>
        {getLocalisationMessage("revenue_report", "Revenue Report")}
      </DialogTitle>
      <DialogContent className={classes.container}>
        <FormDateField
          name="fromDate"
          fullWidth={true}
          label={getLocalisationMessage("from_date", "From Date")}
        />
        <FormDateField
          name="toDate"
          fullWidth={true}
          label={getLocalisationMessage("to_date", "To Date")}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button
            target="_blank"
            component="a"
            href={updateQuery(CREATE_ORDER_REVENUE_REPORT_URL, {
              from_date: formatDateToUrl(props.fromDate),
              to_date: formatDateToUrl(props.toDate),
            })}
          >
            {getLocalisationMessage("download", "Download")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderRevenueReportDialog);
