import React from "react";
import { List, Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import KpiForm from "./KpiSettingsForm";
import MarketplaceMenuSettingsForm from "./MarketplaceMenuSettingsForm";
import CustomizableOrderListSettingsForm from "./CustomizableOrderListSettingsForm";
import MarketplaceOrderStatusSettingsForm from "./MarketplaceOrderStatusSettingsForm";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import InfoIcon from "../icons/InfoIcon";
import FlexBox from "../ui-core/FlexBox";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import { isEqualDataIn } from "../../helpers/DataUtils";
import { formatText, parseInteger } from "../../helpers/FormatUtils";
import { integerInputRangeValidator } from "../../helpers/MarketplaceFormUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import unitOfDistanceConstants from "../../constants/UnitOfDistanceConstants";
import marketplaceThemeConstants from "../../constants/MarketplaceThemeConstants";
import { Languages } from "../../constants/LocaleTypes";
import CustomizableIOtpSettingsForm from "./CustomizableIOtpSettingsForm";

const styles = {
  infoTooltip: {
    position: "absolute",
    right: "0",
  },
};
const valueSelector = formValueSelector("MarketplaceSettingsForm");

const trueFalseOptions = OrderedSet.of(true, false);
const formatTrueFalseOption = (x, i18n) =>
  x === true ? i18n.get("yes", "Yes") : i18n.get("no", "No");

const enhancerFields = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  getContext({
    marketplaceId: PropTypes.number.isRequired,
  }),
  useSheet({
    infoIconParent: {
      position: "relative",
    },
    infoIcon: {
      width: "15px",
    },
  }),
);
const RenderFields = enhancerFields(props => {
  const { i18n } = props;
  switch (fp.toLower(props.field.get("widget_type"))) {
    case "boolean":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <FormSelectField
              name={`${props.name}.${props.field.get("short_name")}`}
              options={trueFalseOptions}
              fullWidth={true}
              formatOption={x => formatTrueFalseOption(x, i18n)}
            />
          </FlexBox>
          <FlexBox justify="center" flex={true}>
            {i18n.get("values_yes_no", "Values: Yes & No")}
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "numeric":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <FormTextField
              parseOnBlur={parseInteger}
              validate={integerInputRangeValidator(
                `${i18n.get(
                  "add_proper_value",
                  "Add Proper Value",
                )}: [${props.field.get("min_value")}, ${props.field.get(
                  "max_value",
                )}]`,
                props.field.get("min_value"),
                props.field.get("max_value"),
              )}
              name={`${props.name}.${props.field.get("short_name")}`}
            />
          </FlexBox>
          <FlexBox justify="center" flex={true}>
            {`${i18n.get("values", "Values")}: [${props.field.get(
              "min_value",
            )}, ${props.field.get("max_value")}]`}
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "string":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <FormTextField
              name={`${props.name}.${props.field.get("short_name")}`}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "menu":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <MarketplaceMenuSettingsForm
              name={`${props.name}.${props.field.get("short_name")}`}
              type={props.field.get("key")}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "order_list":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <CustomizableOrderListSettingsForm
              name={`${props.name}.${props.field.get("short_name")}`}
              type={props.field.get("key")}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "otp_settings":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <CustomizableIOtpSettingsForm
              name={`${props.name}.${props.field.get("short_name")}`}
              type={props.field.get("key")}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "theme":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <FormSelectField
              name={`${props.name}.${props.field.get("short_name")}`}
              options={marketplaceThemeConstants}
              formatOption={x => fp.startCase(i18n.get(x, formatText(x)))}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "language":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <FormSelectField
              name={`${props.name}.${props.field.get("short_name")}`}
              options={Languages}
              // formatOption={(value) =>
              //   formatLanguage(value, i18n.get)
              // }
              label={i18n.get("language", "Language")}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "unit_of_distance":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <FormSelectField
              name={`${props.name}.${props.field.get("short_name")}`}
              options={unitOfDistanceConstants}
              formatOption={x => i18n.get(x, formatText(x))}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "kpi":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <KpiForm name={`${props.name}.${props.field.get("short_name")}`} />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "service_type":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true} className={props.classes.courierTypeChips}>
            <FormCourierTypeChips
              name={`${props.name}.${props.field.get("short_name")}`}
              fullWidth={true}
              hintText={i18n.get("service_types", "Service Types")}
              filterPayload={{ marketplaceId: props.marketplaceId }}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    case "default_statuses":
      return (
        <FlexBox
          align="center"
          flex={true}
          className={props.classes.infoIconParent}
        >
          <FlexBox flex={true}>
            <MarketplaceOrderStatusSettingsForm
              name={`${props.name}.${props.field.get("short_name")}`}
            />
          </FlexBox>
          <TooltipOverlay
            label={i18n.get(props.field.get("info"), props.field.get("info"))}
            style={styles.infoTooltip}
          >
            <IconButton aria-label="delete">
              <InfoIcon className={props.classes.infoIcon} />
            </IconButton>
          </TooltipOverlay>
        </FlexBox>
      );
    default:
      return null;
  }
});

RenderFields.propTypes = {
  name: PropTypes.string,
  field: PropTypes.instanceOf(Map),
  marketplaceId: PropTypes.number,
  i18n: PropTypes.instanceOf(Map),
};

const enhancer = compose(
  connect((state, props) => ({
    i18n: getMessages(state),
    field: valueSelector(state, `${props.name}.field`),
    leafValues: valueSelector(state, "settings"),
  })),
  useSheet({
    infoH6: {
      maxWidth: " 380px",
      overflow: " hidden",
      textOverflow: " ellipsis",
      maxHeight: " 50px",
      fontWeight: 400,
      fontSize: 16,
    },
  }),
);

MarketplaceSettingsFormLeaf.propTypes = {
  field: PropTypes.instanceOf(Map),
  settingsOptions: PropTypes.instanceOf(List),
  leafValues: PropTypes.array,
  name: PropTypes.string.isRequired,
  classes: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function MarketplaceSettingsFormLeaf(props) {
  const { field, i18n } = props;

  return (
    <FlexBox gutter={8}>
      <FlexBox flex={4}>
        <div style={{ display: "none" }}>
          <FormSelectField
            name={`${props.name}.field`}
            fullWidth={true}
            options={props.settingsOptions}
            disabled={true}
            formatOption={v => v.get("info")}
            compareOptions={isEqualDataIn("key")}
          />
        </div>
        <span className={props.classes.infoH6}>
          {i18n.get(props.field.get("info"), props.field.get("info"))}
        </span>
      </FlexBox>
      {field && <RenderFields name={props.name} field={field} />}
    </FlexBox>
  );
}

export default enhancer(MarketplaceSettingsFormLeaf);
