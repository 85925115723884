import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withHandlers,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox, { JUSTIFY_END } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import UploadPublicImageDialog from "../upload-photo-dialog/UploadPublicImageDialog";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatText,
  parseFloat,
  parseInteger,
} from "../../helpers/FormatUtils";
import { isBlankString } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import overallStatusOptions from "../../constants/OverallStatus";
import UserImagePlaceholder from "../../assets/placeholder.png";

const getMonthlyExtra = fp.flow(fp.get("includes.monthly.extra"));
const getMonthlyExtraPrice = fp.flow(fp.get("includes.monthly.price"));
const getMonthlyShipmentCount = fp.flow(
  fp.get("includes.monthly.shipmentCount"),
);
const getMonthlyShipmentPrice = fp.flow(
  fp.get("includes.monthly.shipmentPrice"),
);
const getMonthlyLimitExceedPrice = fp.flow(
  fp.get("includes.monthly.limitExceedPrice"),
);

const getYearlyExtra = fp.flow(fp.get("includes.yearly.extra"));
const getYearlyExtraPrice = fp.flow(fp.get("includes.yearly.price"));
const getYearlyShipmentCount = fp.flow(fp.get("includes.yearly.shipmentCount"));
const getYearlyShipmentPrice = fp.flow(fp.get("includes.yearly.shipmentPrice"));
const getYearlyLimitExceedPrice = fp.flow(
  fp.get("includes.yearly.limitExceedPrice"),
);

const enhancer = compose(
  useSheet({
    imageContainer: {
      paddingTop: "20px",
    },
    photo: {
      maxWidth: "100%",
      maxHeight: "200px",
      objectFit: "contain",
    },
    subheader: {
      paddingLeft: 0,
      marginTop: "30px",
      lineHeight: "0",
    },
  }),
  withHandlers({
    onSubmit: props => ({
      photoId,
      photoUrl,
      monthlyExtra,
      monthlyExtraPrice,
      monthlyShipmentCount,
      monthlyShipmentPrice,
      monthlyLimitExceedPrice,
      yearlyExtra,
      yearlyExtraPrice,
      yearlyShipmentCount,
      yearlyShipmentPrice,
      yearlyLimitExceedPrice,
      ...values
    }) => {
      const includes = {
        monthly: {
          extra: monthlyExtra,
          price: monthlyExtraPrice,
          shipmentCount: monthlyShipmentCount,
          shipmentPrice: monthlyShipmentPrice,
          limitExceedPrice: monthlyLimitExceedPrice,
        },
        yearly: {
          extra: yearlyExtra,
          price: yearlyExtraPrice,
          shipmentCount: yearlyShipmentCount,
          shipmentPrice: yearlyShipmentPrice,
          limitExceedPrice: yearlyLimitExceedPrice,
        },
      };

      let icon = null;
      if (!isBlankString(photoId)) {
        icon = {
          id: photoId,
          url: photoUrl,
        };
      }
      return props.onSubmit({
        ...values,
        includes,
        icon_dto: icon,
      });
    },
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onShowSelectImageDialog,
      stream: onShowSelectImageDialogStream,
    } = createEventHandler();

    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(
        fp.flow(fp.update("initialValues", fp.toPlainObject), props => ({
          ...props.initialValues,
          photoId:
            props.initialValues.iconDto && props.initialValues.iconDto.id,
          photoUrl:
            props.initialValues.iconDto && props.initialValues.iconDto.url,
          monthlyExtra: getMonthlyExtra(props.initialValues),
          monthlyExtraPrice: getMonthlyExtraPrice(props.initialValues),
          monthlyShipmentCount: getMonthlyShipmentCount(props.initialValues),
          monthlyShipmentPrice: getMonthlyShipmentPrice(props.initialValues),
          monthlyLimitExceedPrice: getMonthlyLimitExceedPrice(
            props.initialValues,
          ),
          yearlyExtra: getYearlyExtra(props.initialValues),
          yearlyExtraPrice: getYearlyExtraPrice(props.initialValues),
          yearlyShipmentCount: getYearlyShipmentCount(props.initialValues),
          yearlyShipmentPrice: getYearlyShipmentPrice(props.initialValues),
          yearlyLimitExceedPrice: getYearlyLimitExceedPrice(
            props.initialValues,
          ),
        })),
      );

    return propsStream.combineLatest(
      initialValuesStream,
      onShowSelectImageDialogStream.startWith(false),
      (props, initialValues, showSelectImageDialog) => ({
        ...props,
        initialValues,
        showSelectImageDialog,
        onShowSelectImageDialog,
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "PricingPlanForm",
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  formValues({
    photoId: "photoId",
    photoUrl: "photoUrl",
  }),
);

PricingPlanForm.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  change: PropTypes.func,
  onDelete: PropTypes.func,
  handleSubmit: PropTypes.func,
  photoUrl: PropTypes.string,
  photoId: PropTypes.number,
  showSelectImageDialog: PropTypes.bool,
  onShowSelectImageDialog: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function PricingPlanForm(props) {
  const { classes, getLocalisationMessage, photoUrl, photoId } = props;

  return (
    <FlexBox
      element={<form onSubmit={props.handleSubmit} />}
      container={8}
      direction="column"
    >
      <FlexBox gutter={8} element={<Card />} direction="column">
        <FlexBox element={<CardContent />} direction="column">
          <PageLoading isLoading={props.submitting} />

          <UploadPublicImageDialog
            open={props.showSelectImageDialog}
            onRequestClose={() => props.onShowSelectImageDialog(false)}
            initialValues={{
              photoId,
              photo: photoUrl,
            }}
            onSubmit={response => {
              props.change("photoId", response.photoId);
              props.change("photoUrl", response.photo);
              props.onShowSelectImageDialog(false);
            }}
          />

          <FlexBox gutter={8}>
            <FlexBox flex={8} direction="column">
              <FlexBox flex={true}>
                <FormTextField
                  name="title"
                  label={getLocalisationMessage("title", "Title")}
                  fullWidth={true}
                />

                <FormTextField
                  name="code"
                  fullWidth={true}
                  label={getLocalisationMessage("code", "Code")}
                />

                <FormSelectField
                  name="status"
                  fullWidth={true}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  options={overallStatusOptions}
                  label={getLocalisationMessage("status", "Status")}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="monthlyPrice"
                  label={getLocalisationMessage(
                    "monthly_price",
                    "Monthly Price",
                  )}
                  fullWidth={true}
                  disabled={true}
                  parseOnBlur={parseInteger}
                />

                <FormTextField
                  name="yearlyPrice"
                  label={getLocalisationMessage("yearly_price", "Yearly Price")}
                  fullWidth={true}
                  disabled={true}
                  parseOnBlur={parseInteger}
                />

                <FormTextField
                  name="sortOrder"
                  label={getLocalisationMessage("sort_order", "Sort Order")}
                  fullWidth={true}
                  parseOnBlur={parseInteger}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  fullWidth={true}
                  multiLine={true}
                  rows={3}
                  name="monthlyIncludes"
                  label={getLocalisationMessage(
                    "monthly_includes",
                    "Monthly Includes",
                  )}
                />

                <FormTextField
                  fullWidth={true}
                  multiLine={true}
                  rows={3}
                  name="yearlyIncludes"
                  label={getLocalisationMessage(
                    "yearly_includes",
                    "Yearly Includes",
                  )}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <FlexBox justify="center" className={classes.imageContainer}>
                {photoUrl && (
                  <img
                    className={classes.photo}
                    alt="Sample Item"
                    src={photoUrl}
                  />
                )}
                {!photoUrl && (
                  <img
                    className={classes.photo}
                    alt="Sample Item"
                    src={UserImagePlaceholder}
                  />
                )}
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                {photoUrl && (
                  <FlexBox flex={true} justify="center">
                    <Button onClick={() => props.onShowSelectImageDialog(true)}>
                      {getLocalisationMessage("change_photo", "Change Photo")}
                    </Button>
                  </FlexBox>
                )}
                {photoUrl && (
                  <FlexBox flex={true} justify="center">
                    <Button
                      onClick={() => {
                        props.change("photoId", null);
                        props.change("photoUrl", null);
                      }}
                    >
                      {getLocalisationMessage("remove_photo", "Remove Photo")}
                    </Button>
                  </FlexBox>
                )}
                {!photoUrl && (
                  <FlexBox flex={true} justify="center">
                    <Button onClick={() => props.onShowSelectImageDialog(true)}>
                      {getLocalisationMessage("select_photo", "Select Photo")}
                    </Button>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            <FlexBox flex={8} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage(
                  "monthly_extra_includes",
                  "Monthly (Extra) Includes",
                )}
              </ListSubheader>

              <FlexBox flex={true}>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="monthlyExtra"
                    label={getLocalisationMessage("extra", "Extra")}
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="monthlyExtraPrice"
                    fullWidth={true}
                    parseOnBlur={parseFloat}
                    label={getLocalisationMessage("price", "Price")}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="monthlyShipmentCount"
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={getLocalisationMessage(
                      "shipment_count",
                      "Shipment Count",
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="monthlyShipmentPrice"
                    fullWidth={true}
                    parseOnBlur={parseFloat}
                    label={getLocalisationMessage(
                      "shipment_price",
                      "Shipment Price",
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="monthlyLimitExceedPrice"
                    fullWidth={true}
                    parseOnBlur={parseFloat}
                    label={getLocalisationMessage(
                      "limit_exceed_price",
                      "Limit Exceed Price",
                    )}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            <FlexBox flex={8} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage(
                  "yearly_extra_includes",
                  "Yearly (Extra) Includes",
                )}
              </ListSubheader>

              <FlexBox flex={true}>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="yearlyExtra"
                    label={getLocalisationMessage("extra", "Extra")}
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="yearlyExtraPrice"
                    fullWidth={true}
                    parseOnBlur={parseFloat}
                    label={getLocalisationMessage("price", "Price")}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="yearlyShipmentCount"
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={getLocalisationMessage(
                      "shipment_count",
                      "Shipment Count",
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="yearlyShipmentPrice"
                    fullWidth={true}
                    parseOnBlur={parseFloat}
                    label={getLocalisationMessage(
                      "shipment_price",
                      "Shipment Price",
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} container={8}>
                  <FormTextField
                    name="yearlyLimitExceedPrice"
                    fullWidth={true}
                    parseOnBlur={parseFloat}
                    label={getLocalisationMessage(
                      "limit_exceed_price",
                      "Limit Exceed Price",
                    )}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox element={<CardActions />} justify={JUSTIFY_END}>
          {props.onDelete && (
            <Button secondary={true} onClick={() => props.onDelete()}>
              {getLocalisationMessage("delete", "Delete")}
            </Button>
          )}
          <Button type="submit">
            {getLocalisationMessage("save", "Save")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(PricingPlanForm);
