import React, { useState } from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValues, formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import {
  COMPLETED,
  RETURNED_TO_ORIGIN,
  RETURNING_TO_ORIGIN,
} from "../../constants/OrderStatusCodes";
import { OrderedSet } from "immutable";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import { MIXED } from "../../helpers/OrderOutboundSortingHelper";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormTextField from "../form/FormTextField";

const options = OrderedSet.of(RETURNED_TO_ORIGIN, COMPLETED);
const optionsForBulkReturning = OrderedSet.of(RETURNING_TO_ORIGIN);

const valueSelector = formValueSelector("BatchOrderCanceledUpdate");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      orderBarcodes: valueSelector(state, "orderBarcodes"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "BatchOrderCanceledUpdate",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderBarcodes:
        fp.size(values.orderBarcodes) === 0 &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_orders")) ||
          "Select Orders"),
      orderStatus:
        !values.orderStatus &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("select_status", "Select Status"),
    }),
  }),
  formValues({
    orderStatus: "orderStatus",
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
);

BatchOrderCanceledUpdate.propTypes = {
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  isBulkReturning: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  orderStatus: PropTypes.string,
  theme: PropTypes.object,
};

BatchOrderCanceledUpdate.defaultProps = {
  disableBarcodes: false,
};

function BatchOrderCanceledUpdate(props) {
  const { getLocalisationMessage, classes } = props;
  const [confirm, setConfirm] = useState(false);

  if (confirm) {
    return (
      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "NO")}
        open={confirm}
        onConfirm={props.handleSubmit}
        onRequestClose={() => setConfirm(false)}
      >
        {getLocalisationMessage(
          "please_note_that_you_can_not_change_this_after_confirmation",
          "Please note that You can not change this after confirmation",
        )}
      </ConfirmDialog>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 1000,
          maxWidth: 1000,
          minHeight: 200,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {getLocalisationMessage(
          "bulk_update_shipments",
          "Bulk update shipments",
        )}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          {props.isLoading ? (
            <FlexBox justify="center" align="center">
              <CircularProgress size={60} color="secondary" />
            </FlexBox>
          ) : (
            <FlexBox container={8} style={{ gap: 16 }}>
              <FlexBox style={{ width: "70%" }}>
                <FormChipInput
                  name="orderBarcodes"
                  focus={true}
                  margin="normal"
                  fullWidth={true}
                  label={getLocalisationMessage("orders_barcodes")}
                />
              </FlexBox>

              <FlexBox style={{ width: "30%", gap: 16 }} direction="column">
                <FormAutoComplete
                  name="orderStatus"
                  fullWidth={true}
                  margin="normal"
                  label={getLocalisationMessage("status", "Status")}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                  disabled={props.isBulkReturning}
                  options={
                    props.isBulkReturning ? optionsForBulkReturning : options
                  }
                  formatOption={option =>
                    formatOrderStatusCodeForLocalisation(
                      option,
                      getLocalisationMessage,
                    )
                  }
                />

                {props.orderStatus === RETURNING_TO_ORIGIN && (
                  <FormCourierTypeSelectField
                    hintText={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    label={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    name="innerShipmentType"
                    fullWidth={true}
                    additionalOption={{
                      code: MIXED,
                      name: getLocalisationMessage(fp.toLower(MIXED)),
                    }}
                  />
                )}

                {props.orderStatus === RETURNING_TO_ORIGIN && (
                  <FormWarehouseAutoComplete
                    name="to_warehouse"
                    label={getLocalisationMessage(
                      "destination_warehouse",
                      "Destination Warehouse",
                    )}
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                {props.orderStatus === RETURNING_TO_ORIGIN && (
                  <FormTextField
                    type="number"
                    name="weight"
                    fullWidth={true}
                    label={getLocalisationMessage("weight_kg", "Weight (kg)")}
                  />
                )}
              </FlexBox>
            </FlexBox>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose} primary={true}>
          {getLocalisationMessage("dismiss")}
        </Button>

        <Button onClick={() => setConfirm(true)} primary={true}>
          {getLocalisationMessage("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchOrderCanceledUpdate);
