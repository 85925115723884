import React from "react";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { List } from "immutable";
import MultiLineCell from "../data-list/MultiLineCell";
import DataList, { DataListColumn } from "../data-list/DataList";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import DataListFilter from "../../helpers/DataListFilter";
import { formatWeight } from "../../helpers/FormatUtils";
import useSheet from "react-jss";
import { green, grey, red } from "@material-ui/core/colors";
import Text, { DANGER, MUTED } from "../ui-core/Text";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const NA = "N/A";

OrderFieldCell.propTypes = {
  children: PropTypes.node,
  failed: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderFieldCell(props) {
  return props.fetched ? (
    <Text fallbackType={MUTED} fallbackValue="-">
      {props.children}
    </Text>
  ) : props.failed ? (
    <Text type={DANGER}>
      {props.getLocalisationMessage("failed", "Failed")}
    </Text>
  ) : (
    <Text type={MUTED}>
      {props.getLocalisationMessage("loading", "Loading...")}
    </Text>
  );
}

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    unverified: {
      color: grey[500],
    },
    unknown: {
      color: red[500],
    },
    verified: {
      color: green[500],
    },
  }),
  withState("filter", "onChangeFilter", new DataListFilter()),
);

OrderRegistrySortingCreateBagsTableWrapper.propTypes = {
  list: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRemove: PropTypes.func,
};

function OrderRegistrySortingCreateBagsTableWrapper(props) {
  const { getLocalisationMessage } = props;

  const list = props.list.sortBy((item) => !item.get("failed"));

  return (
    <div>
      <DataList
        filter={props.filter}
        selectedRowCount={0}
        totalCount={list.size}
        rowCount={list.size}
        list={list}
        isLoading={false}
        overscanRowCount={10}
        withHeader={false}
        withFooter={false}
        rowGetter={(options) => list.get(options.index)}
      >
        <DataListColumn
          width={100}
          label={getLocalisationMessage("number")}
          dataKey="number"
          disableSort={true}
          cellRenderer={(row) => (
            <MultiLineCell
              firstLine={
                <div>
                  <strong>{row.cellData.get("number")}</strong>
                </div>
              }
              secondLine={
                <div>
                  <strong>
                    {getLocalisationMessage(
                      row.cellData.getIn(["info", "category"]),
                    ) || NA}
                  </strong>
                </div>
              }
            />
          )}
        />

        <DataListColumn
          width={130}
          label={getLocalisationMessage("warehouse")}
          dataKey="warehouse"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {row.cellData.getIn(["info", "warehouse", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={130}
          label={getLocalisationMessage("destination_warehouse")}
          dataKey="info.to_warehouse.name"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {row.cellData.getIn(["info", "to_warehouse", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("from_city", "From City")}
          dataKey="info.package.from_jurisdiction"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={
                  row.cellData.getIn(["info", "from_jurisdiction", "name"]) ||
                  NA
                }
                secondLine={
                  row.cellData.getIn(["info", "from_postcode", "name"]) || NA
                }
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("to_city", "To City")}
          dataKey="info.package.to_jurisdiction"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={
                  row.cellData.getIn(["info", "to_jurisdiction", "name"]) || NA
                }
                secondLine={
                  row.cellData.getIn(["info", "to_postcode", "name"]) || NA
                }
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={10}
          label={getLocalisationMessage("weight")}
          dataKey="weight"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={
                  <div>
                    {formatWeight(row.cellData.getIn(["info", "weight"]))}
                  </div>
                }
              />
            </OrderFieldCell>
          )}
        />

        {props.onRemove && (
          <DataListColumn
            width={10}
            label={getLocalisationMessage("action")}
            dataKey="action"
            justifyContent="end"
            cellRenderer={(row) => (
              <div>
                <IconButton
                  size="small"
                  onClick={() => props.onRemove(row.cellData.get("number"))}
                >
                  <Close color="error" />
                </IconButton>
              </div>
            )}
          />
        )}
      </DataList>
    </div>
  );
}

export default enhancer(OrderRegistrySortingCreateBagsTableWrapper);
