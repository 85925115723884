import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CustomButton from "../ui-core/CustomButton";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import _ from "lodash";
import DateTimeCell from "../data-list/DateTimeCell";
import { LinearProgress } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { DATE, DIALOG, RENDER } from "../orders-core/MUITable";
import MultiLineCell from "../data-list/MultiLineCell";
import { getValue } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";

export const LINK = "link";
export const COLUMN = "column";
export const ACTION = "action";

// eslint-disable-next-line consistent-return
export function getMatchedBean(binCode, beans) {
  for (let j = 0; j < beans.length; j++) {
    if (beans[j].code === binCode) {
      return beans[j];
    }
  }
}

// eslint-disable-next-line consistent-return
export function getMatchedBin(binCode, beans) {
  for (let j = 0; j < beans.length; j++) {
    if (beans[j].code === binCode) {
      return beans[j].code;
    }
  }
}

const EnhancedTableHead = ({
  getLocalisationMessage,
  columns,
  isCheckboxEnabled,
  numSelected,
  onSelectAllClick,
  rowCount,
}) => (
  <TableHead>
    <TableRow>
      {isCheckboxEnabled && (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
      )}
      {columns.map(v => (
        <TableCell key={v.slug} align="center" padding="normal">
          <FlexBox justify={JUSTIFY_CENTER} align={ALIGN_CENTER}>
            <span
              style={{
                padding: ".5rem 0",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "block",
                textOverflow: "ellipsis",
              }}
            >
              {getLocalisationMessage(v.label)}
            </span>
          </FlexBox>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  rowCount: PropTypes.number,
  columns: PropTypes.array,
  isCheckboxEnabled: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    maxHeight: "70vh",
    height: "100%",
    position: "relative",
    overflowY: "auto",
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  container: {
    zIndex: 0,
    overflow: "overlay",
    flexGrow: "1",
    marginBottom: 20,
  },
  link: {
    color: "#1976d2",
    textDecoration: "none",
    cursor: "pointer",
  },
  rowOdd: { important: false, backgroundColor: "white" },
  rowEven: { important: false, backgroundColor: grey[100] },
  tableRow: {
    transition: `background-color 300ms ease-out`,
    "&:hover": { backgroundColor: `${grey[200]} !important` },
  },
  unpaidOrderRow: {
    backgroundColor: `rgba(242, 104, 74, 0.4) !important`,
    "&:hover": { backgroundColor: `rgba(242, 104, 74, 0.6) !important` },
    transition: `background-color 300ms ease-out`,
  },
  longString: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
  },
  tooltip: {
    zIndex: 5,
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
    transition: `all 300ms ease`,
    borderRadius: 6,
    padding: ".5rem 1rem",
    backgroundColor: "#F2684A",
    color: "white",
    position: "absolute",
    width: 250,
  },
  arrow: {
    top: ".5rem",
    position: "relative",
    "&:after": {
      zIndex: 6,
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderTop: "10px solid #F2684A",
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
    },
  },
});

const OfflineOrderRegistrySortingTable = ({
  columns,
  getLocalisationMessage,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  list,
  rowsPerPage,
  total,
  isCheckboxEnabled,
  headerActions,
  size,
  selectedItems,
  setSelectedItems,
  isLoading,
  orderBy,
  orderByDirection,
  setOrderBy,
  filter,
  setFilter,
  setBatchId,
  setExcludeSelectedItems,
  setSelectBin,
  sortingBeans,
  setSelectFilterBin,
}) => {
  const classes = useStyles();
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelectedItems(list);
      setExcludeSelectedItems([]);
      setSelectBin(
        list.map(item =>
          getMatchedBin(getValue(item, "bean.code", {}), sortingBeans),
        ),
      );
      setSelectFilterBin(
        list.map(item =>
          getMatchedBean(getValue(item, "bean.code", {}), sortingBeans),
        ),
      );
    } else {
      setSelectedItems([]);
      setSelectBin([]);
      setSelectFilterBin([]);
      setExcludeSelectedItems(list);

      if (filter.beans && filter.beans.length > 0) {
        setFilter(prev => ({
          ...prev,
          size: 20,
          beans: [],
        }));
      }
    }
  };

  const handleClick = (event, row) => {
    if (event.target.checked) {
      setSelectedItems(prev => [...prev, row]);
      setSelectBin(prev => [
        ...prev,
        getMatchedBin(getValue(row, "bean.code", {}), sortingBeans),
      ]);
      setSelectFilterBin(prev => [
        ...prev,
        getMatchedBean(getValue(row, "bean.code", {}), sortingBeans),
      ]);
      setExcludeSelectedItems(prev => {
        const idx = prev.findIndex(i => i.barcode === row.barcode);
        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      });
    } else {
      setExcludeSelectedItems(prev => [...prev, row]);
      setSelectBin(prev => {
        const idx = prev.findIndex(i => i === getValue(row, "bean.code"));
        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      });

      setSelectFilterBin(prev => {
        const idx = prev.findIndex(i => i.code === getValue(row, "bean.code"));
        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      });

      setSelectedItems(prev => {
        const idx = prev.findIndex(i => i.barcode === row.barcode);
        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      });

      if (selectedItems && selectedItems.length === 1) {
        if (filter.beans && filter.beans.length > 0) {
          setFilter(prev => ({
            ...prev,
            size: 20,
            beans: [],
          }));
        }
      }
    }
  };

  const isSelected = barcode => _.findIndex(selectedItems, { barcode }) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <FlexBox
          flex={true}
          justify={JUSTIFY_SPACE_AROUND}
          align={ALIGN_CENTER}
          style={{
            flexGrow: 0,
          }}
        >
          {headerActions}
        </FlexBox>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              filter={filter}
              setFilter={setFilter}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              classes={classes}
              selectedItems={selectedItems}
              numSelected={selectedItems.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={list.length}
              columns={columns}
              isCheckboxEnabled={isCheckboxEnabled}
              getLocalisationMessage={getLocalisationMessage}
            />
            {isLoading ? (
              <LinearProgress
                style={{ position: "absolute", width: "99.6%" }}
                mode="indeterminate"
                color="secondary"
              />
            ) : (
              <TableBody>
                {list.map((row, index) => {
                  const isItemSelected = isSelected(row.barcode);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.barcode}
                      selected={isItemSelected}
                      className={classes.tableRow}
                      hover={true}
                    >
                      {isCheckboxEnabled && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={event => handleClick(event, row)}
                          />
                        </TableCell>
                      )}
                      {columns.map((item, i) =>
                        i === 0 ? (
                          <TableCell
                            key={i}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                            align="center"
                          >
                            {item.type === DIALOG ? (
                              // eslint-disable-next-line
                              <MultiLineCell
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  gap: 3,
                                }}
                                firstLine={
                                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                                  <span
                                    className={classes.link}
                                    onClick={() => {
                                      setBatchId(_.get(row, "shipment_id"));
                                    }}
                                  >
                                    {row[item.keyName]}
                                  </span>
                                }
                                secondLine={
                                  row.type === "SIMPLE" ? (
                                    <span>
                                      {formatText(
                                        getValue(row, "category", "N/A"),
                                      )}
                                    </span>
                                  ) : (
                                    <span>{row.barcode}</span>
                                  )
                                }
                              />
                            ) : (
                              row[item.keyName]
                            )}
                          </TableCell>
                        ) : item.type === COLUMN ? (
                          <TableCell align="center" key={i} padding="normal">
                            {row[item.name]}
                          </TableCell>
                        ) : item.type === RENDER ? (
                          <TableCell
                            align="center"
                            style={{ padding: 10 }}
                            key={i}
                            padding="normal"
                          >
                            {item.render(row, getLocalisationMessage)}
                          </TableCell>
                        ) : item.type === DATE ? (
                          <TableCell align="center" key={i} padding="normal">
                            <DateTimeCell date={_.get(row, item.keyName)} />
                          </TableCell>
                        ) : (
                          <TableCell align="center" key={i} padding="normal">
                            <CustomButton
                              secondary={true}
                              onClick={() => item.dispatch(row.shipment_id)}
                            >
                              {item.name}
                            </CustomButton>
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          <TablePagination
            style={{
              position: "fixed",
              background: "white",
              bottom: 0,
              width: "100vw",
              borderTop: "1px solid rgba(0, 0, 0, 0.3)",
            }}
            labelRowsPerPage={getLocalisationMessage("rows_per_page")}
            rowsPerPageOptions={[20, 50, 100, 200, 500]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

OfflineOrderRegistrySortingTable.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  columns: PropTypes.array,
  total: PropTypes.number,
  isCheckboxEnabled: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  headerActions: PropTypes.any,
  setBatchId: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  isLoading: PropTypes.bool,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  setOrderBy: PropTypes.func,
  filter: PropTypes.func,
  setFilter: PropTypes.func,
  sortingBeans: PropTypes.array,
  setSelectBin: PropTypes.func,
  setSelectFilterBin: PropTypes.func,
  excludeSelectedItems: PropTypes.array,
  setExcludeSelectedItems: PropTypes.func,
};

export default enhancer(OfflineOrderRegistrySortingTable);
