import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import { getVehicle, updateVehicle } from "../../api/admin/AdminVehicleApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedReference,
  getReferencePredictions,
} from "../../api/shared/ReferenceApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import VehicleForm from "../../components/vehicles-core/VehicleForm";

const enhancer = compose(
  renderIf(props => props.vehicleId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("vehicleId", isEqualData)
      .switchMap(props =>
        getVehicle(props.vehicleId)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        vehicle: response.get("payload"),
        isLoading: response.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminVehicleEditDialogWrapper.propTypes = {
  vehicleId: PropTypes.number,
  isLoading: PropTypes.bool,
  vehicle: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  onDriverClick: PropTypes.func,
  onCreateDriverClick: PropTypes.func,
  onSupplierClick: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminVehicleEditDialogWrapper(props) {
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={`${props.getLocalisationMessage("vehicle", "Vehicle")} #${
        props.vehicleId
      }`}
    >
      <VehicleForm
        onDismiss={props.onRequestClose}
        initialValues={toCamelCase(props.vehicle)}
        onSubmit={values =>
          updateVehicle(props.vehicleId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.onRequestClose();
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully saved",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedReference={getCachedReference}
        getReferencePredictions={getReferencePredictions}
        onDriverClick={props.onDriverClick}
        onCreateDriverClick={props.onCreateDriverClick}
        onSupplierClick={props.onSupplierClick}
        onCreateSupplierClick={props.onCreateSupplierClick}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminVehicleEditDialogWrapper);
