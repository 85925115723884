import sprintf from "sprintf";

import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const AREAS_URL = `${API_ROOT_URL}/admin/areas`;
const AREAS_ITEM_URL = `${API_ROOT_URL}/admin/areas/%s`;
const AREAS_SORT_URL = `${API_ROOT_URL}/admin/areas/z_index`;
const AREA_CENTER_URL = `${API_ROOT_URL}/admin/areas/%s/center`;
const AREA_KML_URL = `${API_ROOT_URL}/admin/areas/upload_kml/%s`;
const CUSTOM_AREA_KML_URL = `${API_ROOT_URL}/area/custom/upload_kml/%s`;
const COUNTRY_CENTER_URL = `${API_ROOT_URL}/admin/country/%s/center`;
const NEIGHBORHOOD_CUSTOM_URL = `${API_ROOT_URL}/admin/neighborhood/custom/upload_kml`;

export const getAreaList = (filter: DataListFilter) =>
  api.getStream(AREAS_URL, { params: filter.getDefinedValues() });

export const getAreaCenter = id => api.getStream(sprintf(AREA_CENTER_URL, id));

export const addArea = body => api.post(AREAS_URL, { body });
export const editArea = body => api.put(AREAS_URL, { body });
export const deleteArea = id => api.delete(sprintf(AREAS_ITEM_URL, id));

export const uploadKml = (countryId, file) =>
  api.postStream(sprintf(AREA_KML_URL, countryId), { file });

export const uploadNeighborhoodKml = (cityId, file) =>
  api.postStream(NEIGHBORHOOD_CUSTOM_URL, {
    params: { city_id: cityId },
    file,
  });

export const uploadCustomKml = (countryId, file) =>
  api.postStream(sprintf(CUSTOM_AREA_KML_URL, countryId), { file });

export const sortAreas = body => api.put(AREAS_SORT_URL, { body });

export const getCountryCenter = id =>
  api.getStream(sprintf(COUNTRY_CENTER_URL, id));
