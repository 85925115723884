import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  mapSchema,
  queryTypes,
  types,
} from "../../shared/helpers/ObjectMapper";

export const toOrdersSummaryFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    customer_id: types.ID,

    use_solr: types.boolean,

    only_with_wing: types.boolean,
    only_with_supplier: types.boolean,

    from_date: queryTypes.date,
    to_date: queryTypes.date,
  }),
);
