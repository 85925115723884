import { Set } from "immutable";
import { combineReducers } from "redux-immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const CLEAR_FINANCE_SETTLEMENT_QUEUE =
  "FINANCE_SETTLEMENT/CLEAR_FINANCE_SETTLEMENT_QUEUE";
const UPDATE_FINANCE_SETTLEMENT_QUEUE =
  "FINANCE_SETTLEMENT/UPDATE_FINANCE_SETTLEMENT_QUEUE";

const selector = injectReducer(
  "financeSettlement",
  combineReducers({
    selectedNumbers: (state = Set(), { type, payload }) => {
      switch (type) {
        case CLEAR_FINANCE_SETTLEMENT_QUEUE:
          return state.clear();

        case UPDATE_FINANCE_SETTLEMENT_QUEUE:
          return state.clear().union(payload);

        default:
          return state;
      }
    },
  }),
);

export function getFinanceSettlementQueue(state) {
  return selector(state).get("selectedNumbers");
}

export const clearFinanceSettlementQueue = () => ({
  payload: [],
  type: CLEAR_FINANCE_SETTLEMENT_QUEUE,
});

export const updateFinanceSettlementQueue = numbers => ({
  payload: numbers,
  type: UPDATE_FINANCE_SETTLEMENT_QUEUE,
});
