import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const GET_SMS_LOG_URL = `${API_V2_ROOT_URL}/sms/logs`;

export const getSmsLog = (request: DataListFilter) =>
  api.getStream(GET_SMS_LOG_URL, {
    params: request.getDefinedValues(),
  });
