import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const ACT_LIST_URL = `${API_ROOT_URL}/admin/act/list`;
const CREATE_ACT_URL = `${API_ROOT_URL}/admin/act`;
const ACT_ITEM_URL = `${API_ROOT_URL}/admin/act/%s`;
const DELETE_ACT_URL = `${API_ROOT_URL}/admin/act/%s`;
const ACT_REASON_LIST_URL = `${API_ROOT_URL}/admin/act-reason/list`;

const ACT_CREATE_REPLY_URL = `${API_ROOT_URL}/admin/act/reply/%s`;

const COMPLETE_ACT_STATUS_URL = `${API_ROOT_URL}/admin/act/complete/%s`;
export const ACT_PDF_URL = `${API_V2_ROOT_URL}/act/local`;

export const getActList = params => api.get(ACT_LIST_URL, { params });
export const getActItem = id => api.getStream(sprintf(ACT_ITEM_URL, id));
export const getAsyncActItem = id => api.get(sprintf(ACT_ITEM_URL, id));

export const getActReasonList = params =>
  api.getStream(ACT_REASON_LIST_URL, { params });

export const createActURL = body => api.post(CREATE_ACT_URL, { body });

export const deleteAct = id => api.delete(sprintf(DELETE_ACT_URL, id));

export const createActReply = (id, body) =>
  api.post(sprintf(ACT_CREATE_REPLY_URL, id), { body });

export const completeActStatus = id =>
  api.put(sprintf(COMPLETE_ACT_STATUS_URL, id));
