import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import { renderIf } from "../../helpers/HOCUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_SUPPLIER_BALANCE_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  withTheme,
  useSheet({
    container: {
      maxWidth: "320px",
    },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  reduxForm({
    form: "SupplierBalanceDialog",
    initialValues: {
      date: new Date(),
    },
  }),
  formValues({ date: "date" }),
);

SupplierBalanceDialog.propTypes = {
  classes: PropTypes.object,
  date: PropTypes.instanceOf(Date),
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierBalanceDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <DialogTitle className={classes.dialogTitle}>
        {getLocalisationMessage(
          "supplier_past_due_balance",
          "Supplier Past Due Balance",
        )}
      </DialogTitle>
      <DialogContent>
        <FormDateField
          name="date"
          fullWidth={true}
          label={getLocalisationMessage("date", "Date")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button
          component="a"
          target="_blank"
          href={updateQuery(CREATE_SUPPLIER_BALANCE_URL, {
            to_date: formatDateToUrl(props.date),
          })}
        >
          {getLocalisationMessage("download", "Download")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(SupplierBalanceDialog);
