import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import InfoDialog from "../deprecated/InfoDialog";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { formatDate, MONTH_YEAR_FORMAT } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const getId = fp.get("id");
const getPlanTitle = fp.flow(fp.get("title"), fp.toUpper);
// const getPlanShipmentCount = fp.flow(
//   fp.get("selectedIncludes"),
//   fp.get("shipment_count"),
// );
const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    dialogRoot: {
      marginBottom: 5,
    },
    buttonRight: { float: "right" },
  }),
);
SubscriptionExpireInfoDialog.propTypes = {
  classes: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  subscribedPlan: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(Map),
  selectedPlan: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function SubscriptionExpireInfoDialog(props) {
  const { subscribedPlan, selectedPlan, i18n } = props;
  const planObj = subscribedPlan.get("plan");
  const hasConfirmToBuy = getId(planObj) !== getId(selectedPlan);
  return hasConfirmToBuy ? (
    <ConfirmDialog
      open={true}
      onRequestClose={() => props.closeDialog()}
      onConfirm={() =>
        (hasConfirmToBuy && props.onConfirm && props.onConfirm()) || null
      }
    >
      <FlexBox direction="column" style={{ paddingBottom: 5 }}>
        <h4 style={{ marginBottom: 10 }}>Package Expire Info</h4>
        <p>
          {i18n.get("you_have_subscribed", "You've subscribed")}{" "}
          {getPlanTitle(planObj)} {i18n.get("package_with", "package with")}{" "}
          {subscribedPlan.get("plan_period_type") === "MONTHLY"
            ? planObj.includes.monthly.shipment_count
            : planObj.includes.yearly.shipment_count}{" "}
          {i18n.get("shipments_per", "shipments per")}{" "}
          {i18n.get(
            fp.toLower(
              subscribedPlan.get("plan_period_type").replace(/ly$/i, ""),
            ),
            fp.toLower(
              subscribedPlan.get("plan_period_type").replace(/ly$/i, ""),
            ),
          )}.{" "}
          {i18n.get(
            "your_subscription_will_expire",
            "Your subscription will expire",
          )}{" "}
          <strong>
            {formatDate(new Date(subscribedPlan.get("end")), MONTH_YEAR_FORMAT)}
          </strong>{" "}
          {i18n.get("and_it_started", "and it started")}{" "}
          <strong>
            {formatDate(
              new Date(subscribedPlan.get("start")),
              MONTH_YEAR_FORMAT,
            )}.
          </strong>
          <b style={{ color: "rgb(156, 0, 8)" }}>
            {hasConfirmToBuy &&
              i18n.get(
                "do_you_want_to_buy_another_package",
                " Do you want to buy another package?",
              )}
          </b>
        </p>
      </FlexBox>
    </ConfirmDialog>
  ) : (
    <InfoDialog
      open={true}
      onRequestClose={() => props.closeDialog()}
      onConfirm={() =>
        (hasConfirmToBuy && props.onConfirm && props.onConfirm()) || null
      }
    >
      <FlexBox direction="column" style={{ paddingBottom: 5 }}>
        <h4 style={{ marginBottom: 10 }}>Package Expire Info</h4>
        <p>
          {i18n.get("you_have_subscribed", "You've subscribed")}{" "}
          {getPlanTitle(planObj)} {i18n.get("package_with", "package with")}{" "}
          {subscribedPlan.get("plan_period_type") === "MONTHLY"
            ? planObj.includes.monthly.shipment_count
            : planObj.includes.yearly.shipment_count}{" "}
          {i18n.get("shipments_per", "shipments per")}{" "}
          {i18n.get(
            fp.toLower(
              subscribedPlan.get("plan_period_type").replace(/ly$/i, ""),
            ),
            fp.toLower(
              subscribedPlan.get("plan_period_type").replace(/ly$/i, ""),
            ),
          )}.{" "}
          {i18n.get(
            "your_subscription_will_expire",
            "Your subscription will expire",
          )}{" "}
          <strong>
            {formatDate(new Date(subscribedPlan.get("end")), MONTH_YEAR_FORMAT)}
          </strong>{" "}
          {i18n.get("and_it_started", "and it started")}{" "}
          <strong>
            {formatDate(
              new Date(subscribedPlan.get("start")),
              MONTH_YEAR_FORMAT,
            )}.
          </strong>
          <b style={{ color: "rgb(156, 0, 8)" }}>
            {hasConfirmToBuy &&
              i18n.get(
                "do_you_want_to_buy_another_package",
                " Do you want to buy another package?",
              )}
          </b>
        </p>
      </FlexBox>
    </InfoDialog>
  );
}

export default enhancer(SubscriptionExpireInfoDialog);
