import React from "react";
import { OrderedSet } from "immutable";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FormVenueCategoryAutoComplete from "../form/FormVenueCategoryAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import {
  getCachedVenueCategory,
  getVenueCategoryPredictions,
} from "../../api/admin/AdminVenueCategoryApi";

const FILTER_CODES = OrderedSet.of(ACTIVE, INACTIVE);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({ form: "VenueListFilterDialog" }),
  withContext(
    {
      getCachedVenueCategory: PropTypes.func,
      getVenueCategoryPredictions: PropTypes.func,
    },
    () => ({ getCachedVenueCategory, getVenueCategoryPredictions }),
  ),
);

VenueListFilterDialog.propTypes = {
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  change: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function VenueListFilterDialog(props) {
  return (
    <Dialog
      open={props.open}
      modal={false}
      onClose={props.onRequestClose}
      autoScrollBodyContent={false}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <form>
          <FlexBox container={8}>
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox flex={true}>
                <FormVenueCategoryAutoComplete
                  name="category"
                  label={props.getLocalisationMessage("category", "Category")}
                  fullWidth={true}
                  hintText={props.getLocalisationMessage(
                    "choose_category",
                    "Choose Category",
                  )}
                />
              </FlexBox>
              <FlexBox>
                <FormSelectField
                  name="status"
                  hintText={props.getLocalisationMessage("status", "Status")}
                  label={props.getLocalisationMessage("status", "Status")}
                  options={FILTER_CODES}
                  formatOption={x =>
                    props.getLocalisationMessage(x) || formatText(x)
                  }
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            primary={true}
            onClick={() => {
              props.change("category", null);
              props.change("status", null);
            }}
          >
            {props.getLocalisationMessage("clear", "Clear")}
          </Button>
          <Button primary={true} onClick={props.onRequestClose}>
            {props.getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button primary={true} onSubmit={props.handleSubmit}>
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(VenueListFilterDialog);
