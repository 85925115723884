import React from "react";
import useSheet from "react-jss";
import {
  compose,
  mapPropsStream,
  withContext,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { FieldArray, formValueSelector, reduxForm } from "redux-form";
import { Button, Card, CardContent, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox, { ALIGN_CENTER, JUSTIFY_START } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import UploadButton from "../deprecated/UploadButton";
import { toPlainObject } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  changeLngLatToLatLng,
  parseMultipolygonLeaflet,
  parseToMultipolygonLeaflet,
} from "../../helpers/MapPolygonUtils";
import {
  getMarketplace,
  getMarketplaceId,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import {
  DataPolygon as LeafletDataPolygon,
  DrawingControl as LeafletDrawingControl,
} from "react-leflet-map-components";
import fp from "lodash/fp";
import Immutable, { OrderedSet } from "immutable";
import JurisdictionMapFitBounds from "../jurisdiction-tree/JurisdictionMapFitBounds";
import { isBlankString } from "../../helpers/ValidateUtils";
import { Observable } from "rxjs";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import {
  Add,
  CloudUpload,
  KeyboardArrowLeft,
  RemoveCircleOutline,
  Save,
} from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import FormSelectField from "../form/FormSelectField";

const RaisedButton = props => (
  <Button
    {...props}
    variant="contained"
    startIcon={<CloudUpload />}
    style={{
      background: "#4CAF50",
      color: "white",
    }}
  />
);

const getFormValue = formValueSelector("AdminPostcodesForm");
const Jurisdictions = props => {
  const { getLocalisationMessage, fields } = props;

  return (
    <FlexBox flex={true} direction="column" style={{ gap: 16 }}>
      {fields.map((item, index) => (
        <FlexBox align={ALIGN_CENTER} key={index} style={{ gap: 16 }}>
          <FormJMAutoComplete
            name={`${item}`}
            fullWidth={true}
            label={getLocalisationMessage("jurisdiction", "Jurisdiction")}
            renderOption={option => (
              <FlexBox direction="column">
                <span>{option.name}</span>
                <span
                  style={{
                    fontSize: ".8rem",
                    fontStyle: "italic",
                  }}
                >
                  {option.value.hierarchy.map((v, i, arr) =>
                    i === arr.length - 1 ? v.name : `${v.name} > `,
                  )}
                </span>
              </FlexBox>
            )}
          />
          <IconButton size="small" onClick={() => fields.remove(index)}>
            <RemoveCircleOutline style={{ color: "#FF9800" }} />
          </IconButton>
        </FlexBox>
      ))}
      <FlexBox justify={JUSTIFY_START}>
        <CustomButton
          variant={OUTLINED}
          color={SECONDARY}
          onClick={() =>
            fields.push({
              jurisdiction: "",
            })
          }
          startIcon={<Add />}
        >
          {getLocalisationMessage("add_jurisdiction", "Add jurisdiction")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
};
Jurisdictions.propTypes = {
  fields: PropTypes.object,
  change: PropTypes.any,
  contentItemsSum: PropTypes.number,
  contentItems: PropTypes.array,
  getLocalisationMessage: PropTypes.func,
};

const trueFalseOptions = OrderedSet.of(true, false);
export const formatTrueFalseOption = (x, getLocalisationMessage) =>
  x === true
    ? getLocalisationMessage("active", "active")
    : getLocalisationMessage("inactive", "Inactive");

const enhancer = compose(
  useSheet({
    map: { height: "100%" },
    mapContainer: { position: "relative" },
    uploadButton: {
      zIndex: 1,
      top: "10px",
      left: "20px",
      position: "absolute",
    },
    saveButton: {
      zIndex: 1,
      top: "10px",
      left: "180px",
      position: "absolute",
    },
    customControl: {
      zIndex: 1000,
      top: "10px",
      left: "10px",
      position: "absolute",
    },
    listCard: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    root: {
      padding: 10,
      overflow: "hidden",
      height: "100%",
    },
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
    }),
  ),
  connect(
    state => {
      const country = getMarketplace(state).get("country");
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        countryId: country && country.get("id"),
        marketplaceId: getMarketplaceId(state),
        mapProvider: getMapProvider(state),
        currentEditingArea: getFormValue(state, "currentEditingArea"),
        jurisdiction: getFormValue(state, "jurisdiction"),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  withState("removed", "onRemove", []),
  withHandlers({
    onSubmit: props => ({ currentEditingArea, ...values }) => {
      if (!props.onSubmit) {
        return null;
      }

      const request = {};

      if (!currentEditingArea) {
        props.showErrorMessage(
          props.getLocalisationMessage("please_add_area", "Please Add Area"),
        );
        props.onSubmit(null);
      }

      request.index = values.index;
      request.address = values.address;
      request.description = values.description;
      request.jurisdictions = values.jurisdictions;
      request.active = values.active;
      request.wkt = parseToMultipolygonLeaflet(currentEditingArea.toJS());

      return props.onSubmit(request);
    },
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdminPostcodesForm",
    validate: (values, props) => ({
      index:
        isBlankString(values.index) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      jurisdiction:
        fp.isEmpty(values.jurisdiction) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
    }),
  }),
  withHandlers({
    onAddPolygon: props => values => {
      const polygons = props.currentEditingArea || Immutable.List();
      const list = changeLngLatToLatLng(values.geometry.coordinates);
      props.change("polygonEdited", true);
      props.change(
        "currentEditingArea",
        polygons.push(Immutable.fromJS(list[0])),
      );
    },
    onEditPolygon: props => values => {
      const polygons = props.currentEditingArea || Immutable.List();
      const list = changeLngLatToLatLng(values.polygon.geometry.coordinates);
      props.change("polygonEdited", true);
      props.change(
        "currentEditingArea",
        polygons.set(values.idx, Immutable.fromJS(list[0])),
      );
    },
    onRemovePolygon: props => removalIndexes => {
      const polygons = props.currentEditingArea || Immutable.List();
      const newPolygons = polygons.filter(
        (value, index) => removalIndexes.indexOf(index) === -1,
      );
      props.change("polygonEdited", true);
      props.change("currentEditingArea", newPolygons);
    },
  }),
  mapPropsStream(
    pipeStreams(propsStream => {
      const jurisdictionPolygonStream = propsStream
        .distinctUntilKeyChanged("jurisdiction")
        .filter(props => props.jurisdiction)
        .map(props => {
          const jurisdiction = toPlainObject(props.jurisdiction);

          return parseMultipolygonLeaflet(jurisdiction.polygon);
        })
        .startWith([])
        .distinctUntilChanged();

      const sideEffectsStream = Observable.merge(
        propsStream
          .distinctUntilKeyChanged("wkt")
          .filter(props => props.wkt)
          .withLatestFrom(propsStream)
          .do(([req, props]) => {
            props.change(
              "currentEditingArea",
              Immutable.fromJS(parseMultipolygonLeaflet(req.wkt)),
            );
          }),
      ).startWith(null);

      return propsStream.combineLatest(
        jurisdictionPolygonStream,
        sideEffectsStream,
        (props, jurisdictionPolygon) => ({
          ...props,
          jurisdictionPolygon,
        }),
      );
    }),
  ),
);

AdminPostcodesForm.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  onAddPolygon: PropTypes.func,
  onEditPolygon: PropTypes.func,
  onRemovePolygon: PropTypes.func,

  removed: PropTypes.array,
  onRemove: PropTypes.func,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onUploadFile: PropTypes.func,
  uploadNeighborhoodKML: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  jurisdictionPolygon: PropTypes.array,
  router: PropTypes.object,
  currentEditingArea: PropTypes.instanceOf(Immutable.List),
};

function AdminPostcodesForm(props) {
  const { classes, getLocalisationMessage, router } = props;

  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      style={{ height: "100%" }}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox flex={true} className={classes.root} style={{ gap: 16 }}>
        <FlexBox style={{ width: 416 }}>
          <Card className={classes.listCard}>
            <CardContent style={{ height: "100%" }}>
              <FlexBox
                justify="space-between"
                direction="column"
                style={{ height: "100%" }}
              >
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="index"
                    fullWidth={true}
                    margin="normal"
                    label={`${getLocalisationMessage("index", "Index")} *`}
                  />
                  <FormTextField
                    margin="normal"
                    name="address"
                    data-cy="senderAddress"
                    fullWidth={true}
                    withSuccessIcon={true}
                    label={getLocalisationMessage("address", "Address")}
                  />
                  <FormTextField
                    margin="normal"
                    style={{ marginBottom: "2rem" }}
                    name="description"
                    data-cy="senderAddress"
                    fullWidth={true}
                    withSuccessIcon={true}
                    label={getLocalisationMessage("description", "description")}
                  />
                  <FormSelectField
                    name="active"
                    style={{ marginBottom: "2rem" }}
                    fullWidth={true}
                    options={trueFalseOptions}
                    formatOption={x =>
                      formatTrueFalseOption(x, getLocalisationMessage)
                    }
                    label={props.getLocalisationMessage("status", "Status")}
                  />

                  <FieldArray
                    name="jurisdictions"
                    props={{
                      getLocalisationMessage,
                    }}
                    component={Jurisdictions}
                  />
                </FlexBox>
                <FlexBox justify="flex-end" align="flex-end">
                  <CustomButton
                    style={{ marginRight: "1rem" }}
                    onClick={() => router.goBack()}
                    startIcon={<KeyboardArrowLeft />}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("back", "Back")}
                  </CustomButton>
                  <CustomButton
                    variant={CONTAINED}
                    color={SECONDARY}
                    endIcon={<Save />}
                    type="submit"
                  >
                    {getLocalisationMessage("save", "Save")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox flex={true}>
          <Card className={classes.listCard}>
            <CardContent style={{ height: "100%", paddingBottom: 10 }}>
              <FlexBox style={{ gap: 16, height: "100%" }} direction="column">
                <FlexBox justify="flex-end">
                  {Boolean(props.uploadNeighborhoodKML) && (
                    <UploadButton
                      data-cy="warehouse_upload_kml_file"
                      label={getLocalisationMessage(
                        "upload_kml_file",
                        "Upload KML File",
                      )}
                      buttonComponent={RaisedButton}
                      onError={props.showErrorMessage}
                      onChange={response => {
                        props.onUploadFile();
                        props.showSuccessMessage(response.data);
                      }}
                      uploadFileStream={props.uploadNeighborhoodKML}
                    />
                  )}
                </FlexBox>
                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ height: "100%" }}
                >
                  <LeafletMapWrapper className={classes.map} zoom={6}>
                    <LeafletDrawingControl
                      position="topright"
                      allowSelfIntersection={true}
                      drawingModes={[
                        "drawPolygon",
                        "editMode",
                        "dragMode",
                        "removalMode",
                      ]}
                      onDrawComplete={({ shape, layer }) => {
                        if (shape === "Polygon") {
                          props.onAddPolygon(layer.toGeoJSON());
                        }
                      }}
                      onGlobalRemoveModeToggled={({ enabled }) => {
                        if (!enabled && props.removed.length > 0) {
                          props.onRemovePolygon(props.removed);
                          props.onRemove([]);
                        }
                      }}
                    />

                    {props.currentEditingArea &&
                      props.currentEditingArea.map((x, idx) => (
                        <LeafletDataPolygon
                          key={`region-area-${idx}`}
                          editable={true}
                          geometry={[x.toJS()]}
                          stroke={true}
                          weight={2}
                          opacity={1}
                          fill={true}
                          fillOpacity={0.25}
                          fillColor="#3F51B5"
                          color="#3F51B5"
                          onUpdate={({ shape, layer }) => {
                            if (shape === "Polygon") {
                              props.onEditPolygon({
                                idx,
                                polygon: layer.toGeoJSON(),
                              });
                            }
                          }}
                          onRemove={() => {
                            const removedItems = props.removed;
                            removedItems.push(idx);
                            props.onRemove(fp.uniq(removedItems));
                          }}
                          onDragEnd={({ layer, shape }) => {
                            if (shape === "Polygon") {
                              props.onEditPolygon({
                                idx,
                                polygon: layer.toGeoJSON(),
                              });
                            }
                          }}
                        />
                      ))}

                    {props.jurisdictionPolygon && (
                      <LeafletDataPolygon
                        key="region-parent-static-area-create"
                        editable={false}
                        geometry={props.jurisdictionPolygon}
                        stroke={true}
                        weight={2}
                        opacity={1}
                        fill={false}
                        fillOpacity={0.25}
                        fillColor="#000000"
                        color="#000000"
                      />
                    )}

                    <JurisdictionMapFitBounds
                      polygon={
                        props.currentEditingArea
                          ? props.currentEditingArea.toJS()
                          : props.jurisdictionPolygon
                      }
                    />
                  </LeafletMapWrapper>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(AdminPostcodesForm);
