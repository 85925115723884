import { api } from "../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CREATE_PDF_TEMPLATE_URL = `${API_V2_ROOT_URL}/pdf_template/item`;
const CREATE_HTML_PDF_TEMPLATE_URL = `${
  API_V2_ROOT_URL
}/pdf_template/html-item`;
const PDF_TEMPLATE_URL = `${API_V2_ROOT_URL}/pdf_template/item`;
const GET_PDF_TEMPLATE_LIST_URL = `${API_V2_ROOT_URL}/pdf_template/list`;
const GET_DEFAULT_ATTRIBUTES_URL = `${
  API_V2_ROOT_URL
}/pdf_template/default-attributes`;
const GET_TEMPLATE_TYPES_URL = `${API_V2_ROOT_URL}/pdf_template/template-types`;

export type CreatePdfSettingsRequest = {
  id: number,
  name: string,
  width: string,
  height: string,
  content: string,
  template_type: string,
  marketplace_id: number,
};

export const createPdfTemplate = (body: CreatePdfSettingsRequest) =>
  api.post(CREATE_PDF_TEMPLATE_URL, { body });

export const updatePdfTemplate = (body: CreatePdfSettingsRequest) =>
  api.put(CREATE_PDF_TEMPLATE_URL, { body });

export const createHtmlPdfTemplate = (body: CreatePdfSettingsRequest) =>
  api.post(CREATE_HTML_PDF_TEMPLATE_URL, { body });

export const updateHtmlPdfTemplate = (body: CreatePdfSettingsRequest) =>
  api.put(CREATE_HTML_PDF_TEMPLATE_URL, { body });

export const getPdfTemplateList = request =>
  api.getStream(GET_PDF_TEMPLATE_LIST_URL, {
    params: request.getDefinedValues(),
  });

export const getPdfTemplate = value =>
  api.getStream(PDF_TEMPLATE_URL, { params: value });

export const deletePdfTemplate = value =>
  api.delete(PDF_TEMPLATE_URL, { params: value });

export const getDefaultAttributes = value =>
  api.getStream(GET_DEFAULT_ATTRIBUTES_URL, { params: value });

export const getTemplateTypes = () => api.getStream(GET_TEMPLATE_TYPES_URL);
