import { Observable } from "rxjs";
import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import { compose, withState, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import SubscriptionPlanTabs from "./SubscriptionPlanTabs";
import SubscriptionAddOnWrap from "./SubscriptionAddOnWrap";
import SubscriptionActiveInfo from "./SubscriptionActiveInfo";
import SubscriptionPeriodTabs from "./SubscriptionPeriodTabs";
import SubscriptionTotalCheck from "./SubscriptionTotalCheck";
import SubscriptionCheckoutDialog from "./SubscriptionCheckoutDialog";
import SubscriptionCurrencyChoose from "./SubscriptionCurrencyChoose";
import SubscriptionBuyConfirmDialog from "./SubscriptionBuyConfirmDialog";
import SubscriptionExpireInfoDialog from "./SubscriptionExpireInfoDialog";
import SubscriptionAddOnPlanGroupWrap from "./SubscriptionAddOnPlanGroupWrap";
import AnyPayfortDialog from "../ui-core/AnyPayfortDialog";
import SubscriptionStripePaymentDialog from "../orders-core/SubscriptionStripePaymentDialog";
import { isEmpty, isEqualData } from "../../helpers/DataUtils";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SUBSCRIPTION_URL } from "../../constants/AdminPathConstants";
import { MONTHLY } from "../../constants/SubscriptionPeriodTypes";
import { setupStripeIntent } from "../../api/shared/PricePlanApi";
import FlexBox from "../../components/ui-core/FlexBox";

const trainingSubPlans = [
  {
    code: "ON_SITE",
    title: "On Site Training",
  },
  {
    code: "REMOTE_SETUO",
    title: "Remote Set up",
  },
  {
    code: "REMOTE_TRAINING",
    title: "Remote Training",
  },
  {
    code: "SUPPORT",
    title: "Support",
  },
];
const integrationSubPlans = [
  {
    code: "SHOPIFY",
    title: "Shopify",
  },
  {
    code: "MAGENTO",
    title: "Magento",
  },
  {
    code: "WOO_COMMERCE",
    title: "WooCommerce",
  },
  {
    code: "GATEWAY",
    title: "Payment Gateway",
  },
];
const DEFAULT_FILTER = { tab: "free", period_type: MONTHLY, currency: "USD" };
const getId = fp.get("id");
const getCurrency = fp.flow(fp.get("currency"), fp.defaultTo("USD"));
const isResourceMissing = fp.flow(
  fp.get("responseCode"),
  fp.isEqual("error.stripe.default.payment.not.found"),
);
const getData = fp.get("data");
const getPeriodTypeOnLower = fp.flow(
  fp.get("filter"),
  f => f.get("period_type"),
  fp.toLower,
  fp.defaultTo("monthly"),
);
const shouldDisable = (filter, plan) => {
  if (filter.has("subscribed_sort_order") && filter.has("package_grade")) {
    if (filter.get("package_grade") === "downgrade") {
      return filter.get("subscribed_sort_order") < plan.sort_order;
    }

    return filter.get("subscribed_sort_order") > plan.sort_order;
  }
  return false;
};
const isMonthlyPeriodType = fp.flow(
  getPeriodTypeOnLower,
  fp.toUpper,
  fp.eq(MONTHLY),
);
const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    getCachedPricePlans: PropTypes.func.isRequired,
    buyPricePlanItem: PropTypes.func.isRequired,
    getCachedPriceAddOns: PropTypes.func.isRequired,
    getActivePlan: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      i18n: getMessages(state),
      marketplaceId: getMarketplaceId(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withRouter,
  withState("state", "setState", {
    payfortEnabled: false,
    stripeEnabled: false,
    marketplacePlan: null,
    buyConfirmDialog: false,
    expireDialog: false,
    userKey: "",
    publicKey: "",
  }),

  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), fp.merge(DEFAULT_FILTER), Map))
      .distinctUntilChanged(isEqualData)
      .startWith(Map(DEFAULT_FILTER));

    return propsStream.combineLatest(filterStream, (props, filter) => ({
      ...props,
      filter,
    }));
  }),
  mapPropsStream(propsStream => {
    const getMarketplaceActivePlanStream = propsStream
      .distinctUntilChanged(isEqualData)
      .first()
      .switchMap(props =>
        props.getActivePlan().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => Map(response),
          response =>
            Map({
              error: response.get("error", null),
              pending: response.get("pending", false),
              data: Map(getData(response.getIn(["payload"]))),
            }),
        ),
      );

    return propsStream.combineLatest(
      getMarketplaceActivePlanStream,
      (props, subscribedPlan) => ({
        ...props,
        subscribedPlan: subscribedPlan.get("data"),
      }),
    );
  }),
  mapPropsStream(propsStream => {
    const getPriceAddOnsStream = propsStream
      .first()
      .distinctUntilChanged(isEqualData)
      .switchMap(props =>
        props
          .getCachedPriceAddOns()
          .map(List)
          .catch(() => Observable.of(List())),
      )
      .startWith(List());

    return propsStream.combineLatest(getPriceAddOnsStream, (props, addOns) => ({
      ...props,
      addOns: addOns.map(addOn => ({
        ...addOn,
        price: addOn[getPeriodTypeOnLower(props).concat("_price")],
      })),
    }));
  }),
  mapPropsStream(propsStream => {
    const getPricePlansStream = propsStream
      .first()
      .distinctUntilChanged(isEqualData)
      .switchMap(props =>
        props
          .getCachedPricePlans()
          .map(pricePlans => List(pricePlans.data.list)),
      )
      .startWith(List());

    return propsStream.combineLatest(getPricePlansStream, (props, plans) => ({
      ...props,
      plans: plans.map(plan => ({
        ...plan,
        selectedIncludes: plan.includes[getPeriodTypeOnLower(props)],
        disabled: shouldDisable(props.filter, plan),
      })),
    }));
  }),
  reduxForm({
    form: "SubscriptionPlansWizard",
    destroyOnUnmount: false,
  }),
);

SubscriptionPlansWizard.propTypes = {
  plans: PropTypes.instanceOf(List),
  // i18n: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  marketplaceId: PropTypes.number,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  buyPricePlanItem: PropTypes.func,
  getActivePlan: PropTypes.func,
  subscribedPlan: PropTypes.instanceOf(Map),
  addOns: PropTypes.instanceOf(List),
  state: PropTypes.object,
  setState: PropTypes.func,
};

function SubscriptionPlansWizard(props) {
  const { filter, plans, state, setState } = props;
  const selectedPlan = plans.find(plan => plan.code === filter.get("tab"));
  const activeAddOnArray = fp.castArray(filter.get("add_ons", []) || []);

  const activeAddonList = props.addOns.filter(addOn =>
    activeAddOnArray.includes(addOn.code),
  );
  return (
    <FlexBox direction="column">
      {false && (
        <FlexBox direction="row" justify="center" style={{ paddingTop: 20 }}>
          <SubscriptionCurrencyChoose
            value={filter.get("currency")}
            onCheck={(e, value) =>
              props.setLocationQuery(fp.set("currency", value))
            }
          />
        </FlexBox>
      )}
      <FlexBox direction="column">
        <FlexBox flex={true} container={24}>
          <FlexBox flex={10} container={8} direction="column">
            <FlexBox element={<Paper />}>
              <SubscriptionPlanTabs
                subscribedPlan={
                  isEmpty(props.subscribedPlan)
                    ? props.plans.get(0)
                    : props.subscribedPlan.get("plan")
                }
                plans={props.plans}
                filter={filter}
                basePathname={SUBSCRIPTION_URL}
              />
            </FlexBox>
            <Paper>
              {false && (
                <SubscriptionAddOnWrap
                  addOns={props.addOns}
                  filter={filter}
                  onCheck={(field, checked) => {
                    const addOnArray = Array.from(activeAddOnArray);
                    if (checked) {
                      addOnArray.push(field);
                    } else {
                      const foundIndex = addOnArray.findIndex(
                        li => li === field,
                      );
                      addOnArray.splice(foundIndex, 1);
                    }
                    props.setLocationQuery(fp.set("add_ons", addOnArray));
                  }}
                />
              )}
              {false && (
                <SubscriptionAddOnPlanGroupWrap
                  groupField="training"
                  plans={trainingSubPlans}
                  title="Training & Support"
                  description="Track your parcel and get Real-time updates"
                />
              )}
              {false && (
                <SubscriptionAddOnPlanGroupWrap
                  groupField="integration"
                  plans={integrationSubPlans}
                  title="Integration with"
                  description="Track your parcel and get Real-time updates"
                />
              )}
            </Paper>
          </FlexBox>
          <FlexBox flex={2} container={8}>
            <FlexBox direction="column" flex={true}>
              <FlexBox
                flex={true}
                element={<Paper />}
                style={{ marginBottom: "20px" }}
              >
                <SubscriptionPeriodTabs
                  filter={filter}
                  onChange={tab =>
                    props.setLocationQuery(fp.set("period_type", tab))
                  }
                  basePathname={SUBSCRIPTION_URL}
                >
                  <SubscriptionTotalCheck
                    filter={filter}
                    activeAddons={activeAddonList}
                    handleBuyButton={() =>
                      setState(
                        fp.set(
                          isEmpty(props.subscribedPlan.size) &&
                            new Date() >
                              new Date(props.subscribedPlan.get("end"))
                            ? "expireDialog"
                            : "buyConfirmDialog",
                          true,
                        ),
                      )
                    }
                    activePlan={selectedPlan}
                  />

                  {state.buyConfirmDialog && (
                    <SubscriptionBuyConfirmDialog
                      closeDialog={() =>
                        setState(
                          fp.flow(
                            fp.set("buyConfirmDialog", false),
                            fp.set("expireDialog", false),
                          ),
                        )
                      }
                      filter={filter}
                      subscribedPlan={props.subscribedPlan}
                      selectedPlan={selectedPlan}
                      handleConfirm={({ renew }) => {
                        props
                          .buyPricePlanItem(selectedPlan.id, {
                            plan_period_type: filter.get("period_type"),
                            currency: filter.get("currency"),
                            renew,
                            addons: activeAddonList.map(fp.get("id")),
                            months: isMonthlyPeriodType(props) ? 1 : 12,
                          })
                          .then(data => {
                            props.setState({
                              payfortEnabled: false,
                              stripeEnabled: true,
                              marketplacePlan: data.data,
                            });
                            setState(
                              fp.flow(
                                fp.set("buyConfirmDialog", false),
                                fp.set("expireDialog", false),
                              ),
                            );
                          })
                          .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(error.responseCode);
                            if (isResourceMissing(error)) {
                              Promise.resolve(setupStripeIntent()).then(
                                response => {
                                  // eslint-disable-next-line prefer-destructuring
                                  const data = response.data;
                                  props.setState(
                                    fp.set("publicKey", data.public_key),
                                  );
                                  props.setState(
                                    fp.set("userKey", data.client_secret),
                                  );
                                },
                              );
                            }
                          });
                      }}
                    />
                  )}

                  {!fp.isEmpty(state.userKey) && !fp.isEmpty(state.publicKey) && (
                    <SubscriptionCheckoutDialog
                      open={true}
                      clientSecret={state.userKey}
                      publicKey={state.publicKey}
                      closeDialog={() => {
                        props.setState(fp.set("userKey", ""));
                        props.setState(fp.set("publicKey", ""));
                      }}
                    />
                  )}

                  {state.expireDialog && (
                    <SubscriptionExpireInfoDialog
                      filter={filter}
                      closeDialog={() =>
                        setState(fp.set("expireDialog", false))
                      }
                      subscribedPlan={props.subscribedPlan}
                      selectedPlan={selectedPlan}
                      onConfirm={() =>
                        setState(
                          fp.flow(
                            fp.set("buyConfirmDialog", true),
                            fp.set("expireDialog", false),
                          ),
                        )
                      }
                    />
                  )}
                  {state.payfortEnabled && (
                    <AnyPayfortDialog
                      open={true}
                      params={{
                        id: getId(state.marketplacePlan),
                        for_what: "marketplaceplan",
                      }}
                      onRequestClose={() =>
                        props.setState(fp.set("payfortEnabled", false))
                      }
                    />
                  )}
                  {false && state.stripeEnabled && (
                    <SubscriptionStripePaymentDialog
                      open={true}
                      params={{
                        currency: getCurrency(state.marketplacePlan),
                        pay_for_id: getId(state.marketplacePlan),
                        pay_for: "marketplaceplan",
                        payment_system: "STRIPE",
                      }}
                      onRequestClose={() =>
                        props.setState(fp.set("stripeEnabled", false))
                      }
                    />
                  )}
                </SubscriptionPeriodTabs>
              </FlexBox>

              <FlexBox flex={true}>
                <SubscriptionActiveInfo subscribedPlan={props.subscribedPlan} />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SubscriptionPlansWizard);
