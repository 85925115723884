import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { formatText, formatDateTime } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  PDF_TEMPLATE_ITEM_URL,
  PDF_TEMPLATE_CREATE_URL,
  PDF_TEMPLATE_HTML_ITEM_URL,
  PDF_TEMPLATE_CREATE_HTML_URL,
} from "../../constants/AdminPathConstants";
import { getPdfTemplateList } from "../../api/admin/AdminPdfSettingsApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  getContext({
    setLocation: PropTypes.func,
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream((propsStream: Observable) => {
    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map(query => new DataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const pdfTemplateListResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        getPdfTemplateList(filter).catch(() =>
          Observable.of({ pending: false }),
        ),
      )
      .map(response => fromJS(response))
      .startWith(Map());

    return propsStream
      .combineLatest(
        filterStream,
        pdfTemplateListResponseStream,
        (props, filter, pdfTemplateList) => ({
          ...props,
          filter,
          list: pdfTemplateList.getIn(["payload", "data", "list"], List()),
          total: pdfTemplateList.getIn(["payload", "data", "total"], 0),
          isLoading: pdfTemplateList.get("pending", false),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminPdfSettingsList.propTypes = {
  filter: PropTypes.instanceOf(DataListFilter),
  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  setLocation: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  replaceLocationHash: PropTypes.func,

  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminPdfSettingsList(props) {
  const { getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage("pdf_settings", "PDF Settings")}
    >
      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={9}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <MenuButtonMore>
                <MenuItem
                  onClick={() => props.setLocation(PDF_TEMPLATE_CREATE_URL)}
                >
                  {getLocalisationMessage("create_new_pdf")}
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    props.setLocation(PDF_TEMPLATE_CREATE_HTML_URL)
                  }
                >
                  {getLocalisationMessage("create_html_pdf")}
                </MenuItem>
              </MenuButtonMore>
            )}
          </div>
        }
      >
        <DataListColumn
          width={200}
          label={getLocalisationMessage("name", "Name")}
          dataKey="name"
          justifyContent="center"
          disableSort={true}
          cellRenderer={row => (
            <strong>
              {!props.isAdminViewer &&
              row.cellData.get("content_type") === "HTML" ? (
                <Link to={PDF_TEMPLATE_HTML_ITEM_URL + row.cellData.get("id")}>
                  {row.cellData.get("name")}
                </Link>
              ) : row.cellData.get("content_type") === "GRID" ? (
                <Link to={PDF_TEMPLATE_ITEM_URL + row.cellData.get("id")}>
                  {row.cellData.get("name")}
                </Link>
              ) : (
                row.cellData.get("name")
              )}
            </strong>
          )}
        />
        <DataListColumn
          width={180}
          label="Marketplace Name"
          dataKey="marketplace_name"
          justifyContent="center"
          disableSort={true}
          cellRenderer={row => row.cellData.get("marketplace_name")}
        />
        <DataListColumn
          width={150}
          label="Template Type"
          dataKey="template_type"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => formatText(row.cellData.get("template_type"))}
        />
        <DataListColumn
          width={30}
          label="Content Type"
          dataKey="content_type"
          justifyContent="center"
          disableSort={true}
          cellRenderer={row => formatText(row.cellData.get("content_type"))}
        />
        <DataListColumn
          width={70}
          label="Created Date"
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={row => (
            <div>{formatDateTime(row.cellData.get("created_date", "N/A"))}</div>
          )}
        />
        <DataListColumn
          width={70}
          label="Updated Date"
          justifyContent="center"
          dataKey="last_modified_date"
          cellRenderer={row => (
            <div>
              {formatDateTime(row.cellData.get("last_modified_date", "N/A"))}
            </div>
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminPdfSettingsList);
