import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapListResponseStream } from "../../helpers/ApiUtils";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toCODTransferFilter } from "../../helpers/CODTransferFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CODTransferStatus from "../../constants/CODTransferStatus";
import { FINANCE_TRANSFER_ITEM_URL } from "../../constants/AdminPathConstants";
import {
  getCODTransferList,
  deleteCODTransferItem,
  getCODTransferStatusCount,
} from "../../api/admin/AdminFinanceApi";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import CODTransferTable from "../../components/finance-core/CODTransferTable";
import CODTransferTableTabs from "../../components/finance-core/CODTransferTableTabs";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const createCODTransferHref = id => FINANCE_TRANSFER_ITEM_URL + id;

const enhancer = compose(
  getContext({
    setLocationQueryFilter: PropTypes.func,
    setLocationQuery: PropTypes.func,
  }),

  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return { getLocalisationMessage };
    },
    { showSuccessMessage, showErrorMessage },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .map(fp.flow(fp.get("location.query"), toCODTransferFilter))
            .distinctUntilChanged(isEqualData),
          (props, filter) => ({ ...props, filter }),
        ),
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();
        const {
          handler: onRequestRefreshCounts,
          stream: onRequestRefreshCountsStream,
        } = createEventHandler();
        const statusCountsStream = propsStream
          .map(
            fp.flow(fp.get("filter"), filter =>
              filter.setValue("status", null),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getCODTransferStatusCount(filter)
              .repeatWhen(() => onRequestRefreshCountsStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.get("payload.data"),
              fp.toArray,
              fp.keyBy("cod_transfer_status"),
              fp.mapValues("count"),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const responseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getCODTransferList(props.filter).repeatWhen(
              () => onRequestRefreshStream,
            ),
          )
          .let(mapListResponseStream)
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            responseStream,
            statusCountsStream,
            (props, response, statusCounts) => ({
              ...props,
              statusCounts,
              onRequestRefresh,
              onRequestRefreshCounts,
              list: response.getIn(["payload", "list"]),
              total: response.getIn(["payload", "total"]),
              isLoading: response.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualWithoutFunctions);
      },
    ),
  ),
);

AdminCODTransferListContainer.propTypes = {
  location: PropTypes.object,
  columns: PropTypes.array,
  statusCounts: PropTypes.instanceOf(Map),
  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,

  onRequestRefresh: PropTypes.func,
  onRequestRefreshCounts: PropTypes.func,

  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminCODTransferListContainer(props) {
  return (
    <AdminAppLayout
      slug="supplier_cod_transfers"
      title={props.getLocalisationMessage(
        "supplier_cod_transfers",
        "Supplier Cod Transfers",
      )}
    >
      <Redirect
        when={!CODTransferStatus.has(props.location.query.status)}
        to={updateQuery(
          props.location,
          fp.set("status", CODTransferStatus.first()),
        )}
      />

      <FormDialog
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => props.setLocationQuery(fp.unset("delete_id"))}
        open={fp.toFinite(props.location.query.delete_id) > 0}
        onSubmit={() =>
          deleteCODTransferItem(props.location.query.delete_id).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
          props.setLocationQuery(fp.unset("delete_id"));
          props.onRequestRefresh();
          props.onRequestRefreshCounts();
        }}
      >
        {props.getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_item",
          "Are you sure you want to remove this item?",
        )}
      </FormDialog>

      <CODTransferTableTabs
        location={props.location}
        statusCounts={props.statusCounts}
        getLocalisationMessage={props.getLocalisationMessage}
      />

      <CODTransferTable
        columns={props.columns}
        onRemoveItem={id => props.setLocationQuery(fp.set("delete_id", id))}
        list={props.list}
        total={props.total}
        isLoading={props.isLoading}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        createCODTransferHref={createCODTransferHref}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminCODTransferListContainer);
