import { Observable } from "rxjs";
import React from "react";
import Immutable, { fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import {
  CloudUpload as FileFileUpload,
  FilterList as ContentFilterList,
} from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { parseMultipolygonLeaflet } from "../../helpers/MapPolygonUtils";
import { toMapAreasListFilter } from "../../helpers/MapAreasFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../reducers/NotificationsReducer";
import {
  sortAreas,
  deleteArea,
  getAreaList,
  getCountryCenter,
  deleteAreaConfirm,
  getCountryPolygon,
} from "../../api/shared/AreasApi";
import { getCachedCountry } from "../../api/shared/CountryV2Api";
import MapAreasImpactsDialogWrapper from "../../wrappers/admin/MapAreasImpactsDialogWrapper";
import MapAreasUploadKmlDialogWrapper from "../../wrappers/admin/MapAreasUploadKmlDialogWrapper";
import AdminMapAreasFilterDialogWrapper from "../../wrappers/admin/AdminMapAreasFilterDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import MarketplaceAreaDrawingWizard from "../../components/map-areas/MarketplaceAreaDrawingWizard";
import { ROLE_AREA_ADMIN } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const MAP_AREAS_FILTER_HASH = "#MAFH";
const MAP_AREAS_UPLOAD_KML_HASH = "#MAUKH";
const INITIAL_DELETE_EDIT_AREA_STATE = {};

const enhancer = compose(
  useSheet({
    container: {
      height: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
  }),
  withTheme,
  getContext({
    replaceLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        // currentEditingArea: valueSelector(state, "id", "polygon"),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        country: getMarketplaceCountry(state),
        isAreaEditable: hasRole(userRoles, ROLE_AREA_ADMIN),
      };
    },
    {
      showWarningMessage,
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withState("state", "setState", {
    isWarningToDeleteArea: INITIAL_DELETE_EDIT_AREA_STATE,
    isWarningToEditArea: INITIAL_DELETE_EDIT_AREA_STATE,
  }),
  mapPropsStream(
    pipeStreams(
      // ----------- Initial Filter -----------
      propsStream => {
        const initialFilterStream = propsStream
          .filter(({ country }) => Boolean(country && country.get("id")))
          .first()
          .do(({ location, replaceLocationQuery, country }) => {
            if (!location.query.countryId) {
              replaceLocationQuery(fp.set("countryId", country.get("id")));
            }
          })
          .startWith(null);

        return propsStream
          .combineLatest(initialFilterStream, props => ({
            ...props,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ----------- Initial Filter -----------

      // --------------- Filter ---------------
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toMapAreasListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // --------------- Filter ---------------

      // ----------- Mapping Query ------------
      propsStream => {
        const mappingQueryStream = propsStream
          .distinctUntilChanged(isEqualDataIn(["location", "query"]))
          .map(
            fp.flow(
              fp.get("location.query"),
              fp.pick(["selectedItems", "editItem", "createItem"]),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .map(({ selectedItems = "", editItem, createItem }) => {
            const finiteEditItem = fp.toFinite(editItem);

            return {
              editItem: finiteEditItem > 0 ? finiteEditItem : undefined,
              createItem: createItem === "true",
              selectedItems: Immutable.fromJS(
                selectedItems
                  .split(",")
                  .map(fp.toFinite)
                  .filter(x => x > 0),
              ),
            };
          })
          .startWith({});

        return propsStream
          .combineLatest(mappingQueryStream, (props, queries) => ({
            ...props,
            ...queries,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ----------- Mapping Query ------------

      // --------- Getting Area List ----------
      propsStream => {
        const {
          handler: onRefreshList,
          stream: onRefreshListStream,
        } = createEventHandler();

        const {
          handler: onUpdateIndex,
          stream: onUpdateIndexStream,
        } = createEventHandler();

        const areaListStream = propsStream
          .distinctUntilKeyChanged("filter", isEqualData)
          .filter(props => props.filter.getValue("countryId") > 0)
          .switchMap(({ filter }) =>
            getAreaList(filter)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRefreshListStream),
          )
          .startWith({})
          .map(
            fp.flow(
              response => Immutable.fromJS(response),
              response =>
                Immutable.fromJS({
                  pending: response.get("pending", false),
                  list: response
                    .getIn(["payload", "data"], Immutable.List())
                    .map(x =>
                      x.update("polygon", polygon =>
                        Immutable.fromJS(parseMultipolygonLeaflet(polygon)),
                      ),
                    ),
                }),
            ),
          )
          .merge(onUpdateIndexStream);

        return propsStream
          .combineLatest(areaListStream, (props, mapAreaListResponse) => ({
            ...props,

            onUpdateIndex,
            onRefreshList,

            areaList: mapAreaListResponse.get("list").sort((a, b) => {
              const aIndex = a.get("zindex");
              const bIndex = b.get("zindex");

              if (aIndex > bIndex) {
                return 1;
              }

              if (aIndex < bIndex) {
                return -1;
              }

              return 0;
            }),
            areaListLoading: mapAreaListResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      // --------- Getting Area List ----------

      // -------- Getting Area Center ---------
      propsStream => {
        const {
          handler: onGetAreaCenter,
          stream: onGetAreaCenterStream,
        } = createEventHandler();

        const areaCenterStream = onGetAreaCenterStream
          .filter(x => fp.toFinite(x) > 0)
          .distinctUntilChanged(isEqualData)
          .switchMap(x =>
            getCountryCenter(x).catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              x => Immutable.fromJS(x),
              x => {
                const lat = x.getIn(["payload", "data", "lat"], 0);
                const lng = x.getIn(["payload", "data", "lon"], 0);

                return {
                  lat,
                  lng,
                };
              },
            ),
          );

        const countryPolygonStream = onGetAreaCenterStream
          .filter(x => fp.toFinite(x) > 0)
          .distinctUntilChanged(isEqualData)
          .switchMap(x =>
            getCountryPolygon(x).catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update(
                "payload",
                fp.flow(fp.get("data.polygon"), parseMultipolygonLeaflet),
              ),
              fromJS,
            ),
          )
          .startWith(Immutable.Map());

        return propsStream
          .combineLatest(
            areaCenterStream,
            countryPolygonStream,
            (props, mapCenter, countryPolygon) => ({
              ...props,

              mapCenter,
              countryPolygon: countryPolygon.get("payload", null),
              isCountryPolygonLoading: countryPolygon.get("pending", false),

              onGetAreaCenter,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // -------- Getting Area Center ---------

      // ------------ Sort Areas --------------
      propsStream => {
        const {
          handler: onSortAreas,
          stream: onSortAreasStream,
        } = createEventHandler();

        const sortedStream = onSortAreasStream
          .distinctUntilChanged(isEqualData)
          .withLatestFrom(propsStream)
          .switchMap(([data, props]) => {
            const list = props.areaList.map(item =>
              item.update(
                "zindex",
                () => data.find(r => r.id === item.get("id")).z_index,
              ),
            );

            props.onUpdateIndex(Immutable.fromJS({ list }));

            return sortAreas(data).catch(error =>
              props.showErrorMessage(error),
            );
          })
          .startWith(null);

        return propsStream
          .combineLatest(sortedStream, props => ({
            ...props,

            onSortAreas,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ------------ Sort Areas --------------

      // ------------ Side Effects ------------
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .map(fp.pick(["filter", "onGetAreaCenter"]))
            .distinctUntilChanged(isEqualData)
            .do(({ filter, onGetAreaCenter }) =>
              onGetAreaCenter(filter.getIntegerValue("countryId")),
            ),

          propsStream.first().do(props => {
            if (!props.isAreaEditable) {
              props.showErrorMessage(
                props.getLocalisationMessage(
                  "you_do_not_have_the_required_permissions_to_perform_this_operation",
                  "You do not have the required permissions to perform this operation.",
                ),
              );
            }
          }),
        ).startWith(null);

        return propsStream
          .combineLatest(sideEffectsStream, props => props)
          .distinctUntilChanged(isEqualData);
      },
      // ------------ Side Effects ------------

      // ------------ Country Code ------------
      propsStream => {
        const {
          stream: onSetCountryCodeStream,
          handler: onSetCountryCode,
        } = createEventHandler();

        const countryCodeStream = propsStream
          .first()
          .filter(props => props.filter.getIntegerValue("countryId") > 0)
          .switchMap(props =>
            getCachedCountry(props.filter.getIntegerValue("countryId")),
          )
          .map(fp.flow(x => x.get("code"), fp.toUpper))
          .merge(onSetCountryCodeStream)
          .startWith("AE");

        return propsStream
          .combineLatest(countryCodeStream, (props, countryCode) => ({
            ...props,

            countryCode,
            onSetCountryCode,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ------------ Country Code ------------

      // --------- Country Coordinates --------
      propsStream => {
        const {
          stream: onSetCoordinatesStream,
          handler: onSetCoordinates,
        } = createEventHandler();

        return propsStream
          .combineLatest(
            onSetCoordinatesStream.startWith(null),
            (props, placeCoordinates) => ({
              ...props,

              placeCoordinates,
              onSetCoordinates,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // --------- Country Coordinates --------

      // ------------- Hovered ID -------------
      propsStream => {
        const {
          handler: onHoverArea,
          stream: onHoverAreaStream,
        } = createEventHandler();

        return propsStream
          .combineLatest(
            onHoverAreaStream.startWith(null),
            (props, hoveredId) => ({
              ...props,

              hoveredId,

              onHoverArea,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // ------------- Hovered ID -------------
    ),
  ),
  pureComponent(
    fp.pick([
      "filter",
      "location",
      "areaList",
      "editItem",
      "hoveredId",
      "mapCenter",
      "countryPolygon",
      "createItem",
      "countryCode",
      "editPolygons",
      "selectedItems",
      "areaListLoading",
      "placeCoordinates",
      "currentEditingArea",
      "state",
      "setState",
    ]),
  ),
);

AdminNewMarkerplaceAreasContainer.propTypes = {
  filter: PropTypes.instanceOf(DataListFilter),

  state: PropTypes.object,
  classes: PropTypes.object,
  theme: PropTypes.object,

  countryCode: PropTypes.string,
  onSetCountryCode: PropTypes.func,

  setState: PropTypes.func,

  mapCenter: PropTypes.object,
  placeCoordinates: PropTypes.object,
  onSetCoordinates: PropTypes.func,

  areaListLoading: PropTypes.bool,
  isCountryPolygonLoading: PropTypes.bool,
  replaceLocationHash: PropTypes.func,
  location: PropTypes.object,

  hoveredId: PropTypes.number,
  onHoverArea: PropTypes.func,

  editPolygons: PropTypes.instanceOf(Immutable.List),

  onSortAreas: PropTypes.func,
  onRefreshList: PropTypes.func,
  onSetRawPolygon: PropTypes.func,
  onEditRawPolygon: PropTypes.func,

  replaceLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  onAddPolygon: PropTypes.func,
  onGetAreaCenter: PropTypes.func,

  areaList: PropTypes.instanceOf(Immutable.List),
  countryPolygon: PropTypes.instanceOf(Immutable.List),

  // Queries
  editItem: PropTypes.number,
  createItem: PropTypes.bool,
  selectedItems: PropTypes.instanceOf(Immutable.List),
  getLocalisationMessage: PropTypes.func,
  isAreaEditable: PropTypes.bool,
};

function AdminNewMarkerplaceAreasContainer(props) {
  const {
    classes,
    location,
    getLocalisationMessage,
    setState,
    state: { isWarningToDeleteArea },
  } = props;

  return (
    <AdminAppLayout
      slug="areas"
      title={getLocalisationMessage("areas", "Areas")}
      className={classes.container}
      appBarRightAction={
        <FlexBox>
          <IconButton
            title={getLocalisationMessage("filter", "Filter")}
            onClick={() => props.replaceLocationHash(MAP_AREAS_FILTER_HASH)}
            iconStyle={{ color: props.theme.palette.appBarTextColor }}
          >
            <ContentFilterList />
          </IconButton>
          <IconButton
            title={getLocalisationMessage("upload_kml", "Upload Kml")}
            onClick={() => props.replaceLocationHash(MAP_AREAS_UPLOAD_KML_HASH)}
            iconStyle={{ color: props.theme.palette.appBarTextColor }}
          >
            <FileFileUpload />
          </IconButton>
        </FlexBox>
      }
    >
      <MarketplaceAreaDrawingWizard
        countryCode={props.countryCode}
        areaList={props.areaList}
        countryPolygon={props.countryPolygon}
        areaListLoading={props.areaListLoading}
        isCountryPolygonLoading={props.isCountryPolygonLoading}
        editItem={props.editItem}
        hoveredId={props.hoveredId}
        createItem={props.createItem}
        onHoverArea={props.onHoverArea}
        onDeleteArea={areaId => {
          Promise.resolve(deleteArea(areaId).catch(ResponseError.throw))
            .then(({ status, data }) => {
              if (status === "warning") {
                setState(
                  fp.set("isWarningToDeleteArea", { ...data, area_id: areaId }),
                );
              } else {
                props.onRefreshList();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "the_area_has_been_deleted_successfully",
                    "The Area has been deleted, successfully",
                  ),
                );
                setState(
                  fp.set(
                    "isWarningToDeleteArea",
                    INITIAL_DELETE_EDIT_AREA_STATE,
                  ),
                );
                if (props.selectedItems.size > 1) {
                  const items = props.selectedItems
                    .filter(item => item !== areaId)
                    .toJS()
                    .join(",");
                  props.replaceLocationQuery(
                    fp.flow(
                      fp.unset("editItem"),
                      fp.unset("createItem"),
                      fp.unset("selectedItems"),
                      fp.set("selectedItems", items),
                    ),
                  );
                } else {
                  props.replaceLocationQuery(
                    fp.flow(
                      fp.unset("editItem"),
                      fp.unset("createItem"),
                      fp.unset("selectedItems"),
                    ),
                  );
                }
              }
            })
            .catch(error => props.showErrorMessage(error));
        }}
        filter={props.filter}
        selectedItems={props.selectedItems}
        mapCenter={props.placeCoordinates || props.mapCenter}
        onEditItem={id => props.replaceLocationQuery(fp.set("editItem", id))}
        onSelectAllClick={() => {
          if (props.selectedItems.size < props.areaList.size) {
            const items = props.areaList
              .map(x => x.get("id"))
              .toJS()
              .join(",");

            props.replaceLocationQuery(
              fp.flow(
                fp.unset("selectedItems"),
                fp.set("selectedItems", items),
              ),
            );
          } else {
            props.replaceLocationQuery(fp.unset("selectedItems"));
          }
        }}
        onAddItem={() => props.replaceLocationQuery(fp.set("createItem", true))}
        onSwitchItem={id => {
          const { selectedItems: items } = props;

          const currentItemIndex = items.indexOf(id);

          props.replaceLocationQuery(
            fp.set(
              "selectedItems",
              currentItemIndex >= 0
                ? items.splice(currentItemIndex, 1).join(",")
                : items.push(id).join(","),
            ),
          );
        }}
        onCloseForm={() =>
          props.replaceLocationQuery(
            fp.flow(fp.unset("editItem"), fp.unset("createItem")),
          )
        }
        onSubmitSuccess={() => {
          props.replaceLocationQuery(
            fp.flow(fp.unset("editItem"), fp.unset("createItem")),
          );

          props.onRefreshList();

          props.showSuccessMessage(
            getLocalisationMessage("successfully", "Successfully!"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        showErrorMessage={props.showErrorMessage}
        showSuccessMessage={props.showSuccessMessage}
      />

      <AdminMapAreasFilterDialogWrapper
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        show={location.hash === MAP_AREAS_FILTER_HASH}
        onSubmit={({ city, country }) => {
          const countryCode = country.description;

          if (countryCode) {
            props.onSetCountryCode(fp.toUpper(countryCode));
          }

          props.onSetCoordinates(null);

          props.setLocationQueryFilter(
            props.filter.setValueMap({
              areaId: city && city.id ? city.id : null,
              countryId: country && country.id ? country.id : null,
            }),
          );
          props.onGetAreaCenter(country.id);
          props.replaceLocationQuery(
            fp.flow(fp.unset("selectedItems"), fp.unset("editItem")),
          );
          props.replaceLocationHash(null);
        }}
      />

      <MapAreasUploadKmlDialogWrapper
        open={location.hash === MAP_AREAS_UPLOAD_KML_HASH}
        onRequestClose={uploaded => {
          if (uploaded) {
            props.onRefreshList();
          }

          props.replaceLocationHash(null);
        }}
        countryId={props.filter.getIntegerValue("countryId")}
      />

      {isWarningToDeleteArea.attempt_id && (
        <MapAreasImpactsDialogWrapper
          open={true}
          onRequestClose={() => {
            setState(
              fp.set("isWarningToDeleteArea", INITIAL_DELETE_EDIT_AREA_STATE),
            );
          }}
          impacts={fromJS(isWarningToDeleteArea)}
          onConfirm={() => {
            Promise.resolve(
              deleteAreaConfirm(
                isWarningToDeleteArea.area_id,
                isWarningToDeleteArea.attempt_id,
              ).catch(ResponseError.throw),
            )
              .then(() => {
                props.onRefreshList();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "the_area_has_been_deleted_successfully",
                    "The Area has been deleted, successfully",
                  ),
                );
                setState(
                  fp.set(
                    "isWarningToDeleteArea",
                    INITIAL_DELETE_EDIT_AREA_STATE,
                  ),
                );

                if (props.selectedItems.size > 1) {
                  const items = props.selectedItems
                    .filter(item => item !== isWarningToDeleteArea.area_id)
                    .toJS()
                    .join(",");
                  props.replaceLocationQuery(
                    fp.flow(
                      fp.unset("editItem"),
                      fp.unset("createItem"),
                      fp.unset("selectedItems"),
                      fp.set("selectedItems", items),
                    ),
                  );
                } else {
                  props.replaceLocationQuery(
                    fp.flow(
                      fp.unset("editItem"),
                      fp.unset("createItem"),
                      fp.unset("selectedItems"),
                    ),
                  );
                }
              })
              .catch(error => props.showErrorMessage(error));
          }}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminNewMarkerplaceAreasContainer);
