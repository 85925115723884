import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS, OrderedMap, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ReasonTypes from "../../constants/ReasonTypes";
import InquiriesTypes from "../../constants/InquiriesTypes";
import NoteTypeCategory from "../../constants/NoteTypeCategory";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import {
  ORDER_TRACK_URL,
  ORDER_LIST_REQUIRED_ATTENTION_URL,
} from "../../constants/AdminPathConstants";
import { getOrderList, createOrderNote } from "../../api/admin/AdminOrderApi";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OrderList from "../../components/orders-core/OrderList";
import OrderNoteDialog from "../../components/orders-core/OrderNoteDialog";
import OrdersRequiredAttentionTabs from "../../components/orders-required-attention/OrdersRequiredAttentionTabs";

const createOrderHref = id => `${ORDER_TRACK_URL}?view=${id}`;

const tabTypes = OrderedSet.of(
  "requires_attention",
  "scheduled_for_cancellation",
  "auto_cancelled",
);

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
  useSheet({
    appBarRightAction: {
      marginTop: "5px",
      height: "100%",
      paddingBottom: "8px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      marginLeft: "12px",
    },
  }),
  getContext({ setLocationQueryFilter: PropTypes.func.isRequired }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = Observable.combineLatest(
          propsStream
            .pluck("location", "query")
            .distinctUntilChanged(isEqualData),
          propsStream
            .map(
              fp.flow(fp.get("params.tab"), tab =>
                tabTypes.has(tab) ? tab : tabTypes.first(),
              ),
            )
            .distinctUntilChanged(),
          (query, tab) =>
            new DataListFilter(query)
              .setValue("use_solr", true)
              .withMutations((filter: DataListFilter) => {
                tabTypes.forEach(type => {
                  filter.setValue(type, tab === type ? true : null);
                });
              }),
        ).distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const responseStream = propsStream
          .pluck("filter")
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getOrderList(filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const tabCountStream = propsStream
          .pluck("filter")
          .distinctUntilChanged(isEqualData)
          .map((filter: DataListFilter) =>
            filter.setValueMap({
              size: 1,
              page: null,
              simple: true,
              order_by: null,
              order_by_direction: null,
              auto_cancelled: null,
              requires_attention: null,
              scheduled_for_cancellation: null,
            }),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(
            fp.flow(
              filter =>
                tabTypes
                  .map(key =>
                    getOrderList(filter.setValue(key, true))
                      .takeLast(1)
                      .map(fp.get("payload.data.total"))
                      .catch(() => Observable.of(null))
                      .startWith(null)
                      .distinctUntilChanged()
                      .map(value => ({ key, value })),
                  )
                  .toArray(),
              requests =>
                Observable.from(requests)
                  .concatAll()
                  .scan(
                    (acc: OrderedMap, response) =>
                      acc.set(response.key, response.value),
                    tabTypes.toOrderedMap().map(fp.constant(null)),
                  )
                  .repeatWhen(() => onRequestRefreshStream),
            ),
          )
          .startWith(OrderedMap());

        return propsStream.combineLatest(
          tabCountStream,
          responseStream,
          (props, tabCount, response) => ({
            ...props,
            tabCount,
            onRequestRefresh,
            list: response.get("list"),
            total: response.get("total"),
            isLoading: response.get("pending"),
          }),
        );
      },
      propsStream => {
        const {
          handler: onAddNoteClick,
          stream: onAddNoteClickStream,
        } = createEventHandler();

        const addNoteToStream = onAddNoteClickStream
          .combineLatest(
            propsStream.pluck("list").distinctUntilChanged(isEqualData),
            (order, list) =>
              order
                ? list.find(item => item.get("id") === order.get("id"))
                : undefined,
          )
          .distinctUntilChanged(isEqualData)
          .startWith(undefined);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(addNoteToStream, (props, addNoteTo) => ({
            ...props,
            addNoteTo,
            onAddNoteClick,
          }));
      },
    ),
  ),
);

AdminOrdersRequiredAttentionContainer.propTypes = {
  sheet: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  tabCount: PropTypes.instanceOf(OrderedMap),
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onAddNoteClick: PropTypes.func,
  addNoteTo: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminOrdersRequiredAttentionContainer(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
  } = props;
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </FlexBox>
      }
      slug="requires_attention"
      title={getLocalisationMessage("requires_attention")}
    >
      <Redirect
        when={!tabTypes.has(props.params.tab)}
        to={`${ORDER_LIST_REQUIRED_ATTENTION_URL}/${tabTypes.first()}`}
      />

      {Boolean(props.addNoteTo) && (
        <OrderNoteDialog
          open={true}
          reasonTypes={ReasonTypes}
          inquiriesTypes={InquiriesTypes}
          onRequestClose={() => props.onAddNoteClick(null)}
          privacyTypes={AdminPrivacyTypes}
          noteTypeCategory={NoteTypeCategory}
          withRequiresAttention={true}
          withScheduledForCancellation={true}
          initialValues={{
            requiresAttention: Boolean(
              props.addNoteTo.get("requires_attention"),
            ),
            scheduledForCancellation: Boolean(
              props.addNoteTo.get("scheduled_for_cancellation"),
            ),
          }}
          onSubmit={values =>
            createOrderNote(
              props.addNoteTo.get("id"),
              toSnakeCase(values),
            ).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestRefresh();
            props.onAddNoteClick(null);
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_note_created",
                "Successfully Note Created",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      {tabTypes.has(props.params.tab) && (
        <OrdersRequiredAttentionTabs
          width={900}
          tab={props.params.tab}
          tabCount={props.tabCount}
          locationQuery={props.location.query}
          basePathname={ORDER_LIST_REQUIRED_ATTENTION_URL}
        />
      )}

      <OrderList
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
        withCODStatus={false}
        withLastDriver={false}
        createOrderHref={createOrderHref}
        rowActionIconRenderer={row => (
          <MenuButtonMore>
            <MenuItem onClick={() => props.onAddNoteClick(row.rowData)}>
              {getLocalisationMessage("add_note", "Add Note")}
            </MenuItem>
          </MenuButtonMore>
        )}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrdersRequiredAttentionContainer);
