import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getDriverAttendance } from "../../api/admin/AdminDriverApi";
import AdminDriverRejectionReasonDialogWrapper from "../../wrappers/admin/AdminDriverRejectionReasonDialogWrapper";
import { WEEK_TYPE } from "../../components/form/FormSwitcherDatePicker";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import DriversAttendanceTable from "../../components/drivers-core/DriversAttendanceTable";
import DriversAttendanceNavigationForm from "../../components/drivers-core/DriversAttendanceNavigationForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onSelectDate,
          stream: onSelectDateStream,
        } = createEventHandler();
        const {
          handler: onSelectData,
          stream: onSelectDataStream,
        } = createEventHandler();

        return propsStream.combineLatest(
          onSelectDateStream.map(fp.get("date")).startWith(new Date()),
          onSelectDataStream.startWith(new Map()),
          (props, selectedDate, selectData) => ({
            ...props,
            selectedDate,
            onSelectData,
            onSelectDate,
            selectData,
          }),
        );
      },
      propsStream => {
        const listResponseStream = propsStream
          .distinctUntilKeyChanged("selectedDate")
          .switchMap(props =>
            getDriverAttendance(
              props.filter.setValue(
                "from_date",
                formatDateToUrl(props.selectedDate),
              ),
            ).catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data"], List()),
                }),
            ),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const daysStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(props => !props.isLoading && props.list.size > 0)
          .map(props =>
            props.list
              .first()
              .get("period")
              .keySeq()
              .toList(),
          )
          .startWith(List());

        return propsStream
          .combineLatest(daysStream, (props, days) => ({
            ...props,
            days,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminDriversAttendanceContainer.propTypes = {
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  onSelectDate: PropTypes.func,
  days: PropTypes.instanceOf(List),
  selectData: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
  onSelectData: PropTypes.func,
  location: PropTypes.object,
};

function AdminDriversAttendanceContainer(props) {
  const { getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="drivers_attendance"
      title={getLocalisationMessage("drivers_attendance", "Drivers Attendance")}
    >
      <AdminDriverRejectionReasonDialogWrapper
        open={props.selectData.get("checkInIds", new List()).size > 0}
        data={props.selectData}
        onRequestClose={() => props.onSelectData(new Map())}
      />

      <FlexBox container={8} element={<Paper />} flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox>
            <DriversAttendanceNavigationForm
              isLoading={props.isLoading}
              rightButtonLabel={getLocalisationMessage(
                "next_week",
                "Next Week",
              )}
              leftButtonLabel={getLocalisationMessage(
                "previous_week",
                "Previous Week",
              )}
              navigationType={WEEK_TYPE}
              initialValues={{
                date: new Date(),
              }}
              onChange={values => props.onSelectDate(values)}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <DriversAttendanceTable
              onClickCell={props.onSelectData}
              location={props.location}
              list={props.list}
              days={props.days}
              total={props.total}
              isLoading={props.isLoading}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDriversAttendanceContainer);
