// eslint-disable-next-line no-unused-vars
import React from "react";
import { List } from "immutable";

export const CUSTOMER = {
  ind: 1,
  slug: "customer_name",
  name: "Customer",
};
export const WAREHOUSE = {
  ind: 2,
  slug: "warehouse",
  name: "Warehouse",
};
export const SUPPLIER = {
  ind: 3,
  slug: "supplier_name",
  name: "Supplier Name",
};
export const DROPOFF_ADDRESS = {
  ind: 4,
  slug: "dropoff_address",
  name: "DropOff Address",
};
export const SERVICE = {
  ind: 5,
  slug: "service",
  name: "Service",
};
export const TIMESLOT = {
  ind: 6,
  slug: "timeslot",
  name: "Timeslot (Pickup | Dropoff)",
};
export const BOX_COUNT = {
  ind: 7,
  slug: "piece_count",
  name: "Box Count",
};
export const ATTEMPTS = {
  ind: 8,
  slug: "attempts_count",
  name: "Attempts",
};
export const CREATED_DATE = {
  ind: 9,
  slug: "created_date",
  name: "Created Time",
};
export const PICKUP_DATE = {
  ind: 10,
  slug: "pickup_time",
  name: "Pickup Time",
};
export const DEADLINE_TIME = {
  ind: 11,
  slug: "deadline_time",
  name: "Est. Delivery",
};
export const PROMISE_DATE = {
  ind: 12,
  slug: "merchant_promise_date",
  name: "Promise Date",
};
export const PICKED_UP_BY = {
  ind: 13,
  slug: "pickup_supplier_name",
  name: "Picked Up By",
};
export const PICKUP_TOTAL_COLLECTABLE = {
  ind: 14,
  slug: "pickup_collectible",
  name: "Pickup Total Collectible",
};
export const DELIVERED_BY = {
  ind: 15,
  slug: "delivery_supplier_name",
  name: "Delivered By",
};
export const DROPOFF_TOTAL_COLLECTABLE = {
  ind: 16,
  slug: "dropoff_collectible",
  name: "Dropoff Total Collectible",
};
export const STATUS = {
  ind: 17,
  slug: "last_modified_date",
  name: "Status",
};
export const COD_CHARGE = {
  ind: 18,
  slug: "cod_charge",
  name: "COD",
};
export const AMOUNT = {
  ind: 19,
  slug: "amount",
  name: "Service Price, Parcel Price",
};
export const SHIPPING_TYPE = {
  ind: 20,
  slug: "shipping_type",
  name: "Shipping Type",
};

export default List([
  CUSTOMER,
  WAREHOUSE,
  SUPPLIER,
  DROPOFF_ADDRESS,
  SERVICE,
  TIMESLOT,
  BOX_COUNT,
  ATTEMPTS,
  CREATED_DATE,
  PICKUP_DATE,
  DEADLINE_TIME,
  PROMISE_DATE,
  PICKED_UP_BY,
  PICKUP_TOTAL_COLLECTABLE,
  DELIVERED_BY,
  DROPOFF_TOTAL_COLLECTABLE,
  STATUS,
  COD_CHARGE,
  AMOUNT,
  SHIPPING_TYPE,
]);
