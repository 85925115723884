import { Observable } from "rxjs";
import React from "react";
import { startOfToday } from "date-fns";
import { fromJS, List, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { Button, Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import {
  isEqualData,
  isEqualDataIn,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatDateToUrl, safeParseDate } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { toBankDepositFilterMapper } from "../../helpers/BankDepositFilterMapper";
import {
  clearBankDepositQueue,
  getBankDepositQueue,
  updateBankDepositQueue,
} from "../../reducers/BankDepositReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  CUSTOMER_ITEM_URL,
  ORDER_TRACK_URL,
} from "../../constants/AdminPathConstants";
import {
  calculateBankDepositTotal,
  createBankDeposit,
  getBankDepositOrdersList,
  getCompanyCustomers,
} from "../../api/admin/AdminFinanceApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import NavigationPrompt from "../../components/router/NavigationPrompt";
import BankDepositFilterForm from "../../components/finance-core/BankDepositFilterForm";
import CreateBankDepositDialog from "../../components/finance-core/CreateBankDepositDialog";
import BankDepositItemOrderList from "../../components/finance-core/BankDepositItemOrderList";
import Notification from "../../components/notifications/Notification";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_SETTLEABLE_CUSTOMERS_URL } from "../../../shared/constants/FileProxyControllerConstants";

const CREATE_COD_WITHDRAW_DIALOG_HASH = "#CCWD";
const COMPANY_ID = 264525000;

const enhancer = compose(
  useSheet({
    orders: {
      flex: "1 1 0%",
      display: "flex",
      marginTop: "12px",
      "& > div": { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    },
    appBarRightAction: {
      marginTop: "5px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
    appBarRightActionButton: {
      color: "#fff",
      top: "-7px",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      selectedNumbers: getBankDepositQueue(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      clearBankDepositQueue,
      updateBankDepositQueue,
    },
  ),
  getContext({
    replaceLocationHash: PropTypes.func,
    setLocationQuery: PropTypes.func,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const getCustomersStream = propsStream
          .distinctUntilKeyChanged(COMPANY_ID, isEqualData)
          .switchMap(() =>
            getCompanyCustomers(COMPANY_ID).catch(error =>
              Observable.of({
                error: error.reasonMessage || error.message,
              }),
            ),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  payload: response.getIn(["payload", "data"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toBankDepositFilterMapper))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            filterStream,
            getCustomersStream,
            (props, filter, customers) => ({
              ...props,
              filter,
              customers: customers.get("payload"),
              isLoading: customers.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualWithoutFunctions);
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const formInitialValuesStream = propsStream
          .map(props => ({
            customerIds: props.customers.toJS(),
            toDate: safeParseDate(props.filter.getValue("to_date")),
            fromDate:
              safeParseDate(props.filter.getValue("from_date")) ||
              startOfToday(),
          }))
          .distinctUntilChanged(isEqualData);

        const responseStream = propsStream
          .distinctUntilChanged(isEqualData)
          .map(fp.pick(["filter", "customers"]))
          .distinctUntilChanged(isEqualData)
          .filter(props => props.filter && props.customers)
          .switchMap(props =>
            props.filter.getValue("to_date")
              ? getBankDepositOrdersList(
                  props.filter.setValue(
                    "customer_ids",
                    stringifyArray(props.customers.toJS().map(getObjectId)),
                  ),
                  // .setValue(
                  //   "from_date",
                  //   formatDateToUrl(subDays(startOfToday(), 300)),
                  // ),
                )
                  .repeatWhen(() => onRequestRefreshStream)
                  .catch(error =>
                    Observable.of({
                      error: error.reasonMessage || error.message,
                    }),
                  )
              : Observable.of({}),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  error: response.get("error", null),
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const sideEffectStream = Observable.merge(
          propsStream.take(1).do(props => props.clearBankDepositQueue()),
          propsStream
            .distinctUntilChanged(
              isEqualDataIn(["location", "query", "customer_id"]),
            )
            .skip(1)
            .do(props => {
              onRowSelect(Set());
              props.clearBankDepositQueue();
            }),
          onRowSelectStream
            .distinctUntilChanged(isEqualData)
            .withLatestFrom(propsStream)
            .do(([numbers, props]) => props.updateBankDepositQueue(numbers)),
        ).startWith(null);

        return propsStream
          .combineLatest(
            responseStream,
            formInitialValuesStream,
            sideEffectStream,
            (props, response, formInitialValues) => ({
              ...props,
              formInitialValues,
              onRowSelect,
              onRequestRefresh,
              list: response.get("list"),
              total: response.get("total"),
              listError: response.get("error"),
              isLoading: response.get("isLoading"),
            }),
          )
          .distinctUntilChanged(isEqualWithoutFunctions);
      },
    ),
  ),
);

AdminFinanceBankDepositOrdersContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  clearBankDepositQueue: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  listError: PropTypes.string,
  onRequestRefresh: PropTypes.func,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  onRowSelect: PropTypes.func,
  selectedNumbers: PropTypes.instanceOf(Set),
  customers: PropTypes.instanceOf(List),
  formInitialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminFinanceBankDepositOrdersContainer(props) {
  const {
    sheet: { classes },
    location,
    getLocalisationMessage,
  } = props;
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      slug="bank_deposit_order"
      title={getLocalisationMessage(
        "bank_deposit_order",
        "Bank Deposit Orders",
      )}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={getLocalisationMessage("domestic", "Domestic")}
            className={classes.appBarRightActionToggle}
            value={props.filter.getBoolValue("is_uae")}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("is_uae", value),
              )
            }
          />

          {Boolean(
            props.filter.getValue("from_date") &&
              props.filter.getValue("to_date"),
          ) && (
            <Button
              target="_blank"
              component="a"
              className={classes.appBarRightActionButton}
              href={updateQuery(
                CREATE_SETTLEABLE_CUSTOMERS_URL,
                props.filter.getDefinedValues(),
              )}
            >
              {getLocalisationMessage(
                "generate_customer_list",
                "Generate Customer List",
              )}
            </Button>
          )}

          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      <NavigationPrompt
        when={!props.selectedNumbers.isEmpty()}
        message={nextLocation =>
          location.pathname !== nextLocation.pathname ||
          location.query.customer_id !== nextLocation.query.customer_id
            ? getLocalisationMessage(
                "all_selections_will_be_lost_are_you_sure",
                "All selections will be lost, are you sure?",
              )
            : undefined
        }
      />

      <Notification
        uid="AdminFinanceBankDepositContainer"
        type="error"
        open={Boolean(props.listError)}
      >
        {props.listError}
      </Notification>

      {location.hash === CREATE_COD_WITHDRAW_DIALOG_HASH && (
        <CreateBankDepositDialog
          open={true}
          onRequestClose={() => props.replaceLocationHash(null)}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          calculateBankDepositTotal={calculateBankDepositTotal}
          initialValues={{
            orderNumbers: props.selectedNumbers.toArray(),
            toDate: safeParseDate(props.filter.getValue("to_date")),
            fromDate: safeParseDate(props.filter.getValue("from_date")),
          }}
          onSubmit={values =>
            createBankDeposit(toSnakeCase(values)).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestRefresh();
            props.replaceLocationHash(null);
            props.clearBankDepositQueue();
            props.showSuccessMessage(
              getLocalisationMessage(
                "bank_deposit_created",
                "Bank Deposit Created",
              ),
            );
          }}
          onSubmitFailure={error => {
            props.onRequestRefresh();
            props.showErrorMessage(error);
          }}
        />
      )}

      <Card>
        <CardContent>
          {props.customers && props.customers.size > 0 && (
            <BankDepositFilterForm
              getCachedCustomer={getCachedCustomer}
              getCustomerPredictions={getCustomerPredictions}
              customers={props.customers}
              initialValues={props.formInitialValues}
              onSubmit={values => {
                const nextFilter = props.filter.setValueMap({
                  page: 0,
                  customer_ids: stringifyArray(
                    values.customerIds.map(getObjectId),
                  ),
                  to_date: formatDateToUrl(values.toDate),
                  from_date: formatDateToUrl(values.fromDate),
                });

                if (!props.filter.equals(nextFilter)) {
                  props.setLocationQueryFilter(nextFilter);
                } else {
                  props.onRequestRefresh();
                }
              }}
            />
          )}
        </CardContent>
      </Card>

      <Card style={{ marginTop: "1rem" }}>
        {!props.isLoading && (
          <CardContent>
            <div>
              {props.total}{" "}
              {getLocalisationMessage("orders_found", "Orders Found")}.{" "}
              {props.total === 0 && (
                <Button
                  onClick={() =>
                    props.replaceLocationHash(CREATE_COD_WITHDRAW_DIALOG_HASH)
                  }
                >
                  {getLocalisationMessage("create_transfer", "Create Transfer")}
                </Button>
              )}
            </div>
          </CardContent>
        )}

        <BankDepositItemOrderList
          list={props.list}
          selectable={true}
          onRowSelect={props.onRowSelect}
          selectedNumbers={props.selectedNumbers}
          totalCount={props.total}
          isLoading={props.isLoading}
          filter={props.filter}
          maxSearchItems={Infinity}
          createCustomerHref={id => CUSTOMER_ITEM_URL + id}
          createOrderHref={id => `${ORDER_TRACK_URL}?view=${id}`}
          onFilterChange={filter => props.setLocationQueryFilter(filter)}
          altHeader={
            <div>
              <Button
                onClick={() =>
                  props.replaceLocationHash(CREATE_COD_WITHDRAW_DIALOG_HASH)
                }
              >
                {`${getLocalisationMessage(
                  "confirm_transfer",
                  "Confirm Transfer",
                )} (${props.selectedNumbers.size})`}
              </Button>
              <Button
                onClick={() => {
                  props.onRowSelect(Set());
                  props.clearBankDepositQueue();
                }}
              >
                {" "}
                {getLocalisationMessage("clear_queue", "Clear Queue")}{" "}
              </Button>
            </div>
          }
        />
      </Card>
    </AdminAppLayout>
  );
}

export default enhancer(AdminFinanceBankDepositOrdersContainer);
