import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getAddressBook,
  updateAddressBook,
} from "../../api/shared/AdminAddressBookApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import AddressBookForm from "../../components/address-book-core/AddressBookForm";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const addressBookIdStream = propsStream.map(
          fp.flow(fp.get("params.id"), fp.toFinite),
        );

        return propsStream
          .combineLatest(addressBookIdStream, (props, addressBookId) => ({
            ...props,
            addressBookId,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const addressBookItemResponseStream = propsStream
          .distinctUntilKeyChanged("addressBookId")
          .switchMap(props =>
            getAddressBook(props.addressBookId)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            addressBookItemResponseStream,
            (props, addressBookItemResponse) => ({
              ...props,
              onRequestRefresh,
              addressBook: addressBookItemResponse.get("payload"),
              isLoading: addressBookItemResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminAddressBookItem.propTypes = {
  addressBookId: PropTypes.number,
  onRequestRefresh: PropTypes.func,
  addressBook: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminAddressBookItem(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={
        props.addressBook
          ? `${getLocalisationMessage(
              "address_book_item",
              "Address Book Item",
            )}  - ${props.addressBook.get("name", "")}`
          : getLocalisationMessage("address_book_item", "Address Book Item")
      }
    >
      <PageLoading isLoading={props.isLoading} />
      <AddressBookForm
        onSubmit={values =>
          updateAddressBook(props.addressBookId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() =>
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          )
        }
        onSubmitFail={props.showErrorMessage}
        initialValues={{
          ...toCamelCase(props.addressBook),
          markLocation: {
            lat: props.addressBook.get("lat"),
            lng: props.addressBook.get("lon"),
          },
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminAddressBookItem);
