import { Observable } from "rxjs";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import FormSelectField from "./FormSelectField";
import { isEqualData } from "../../helpers/DataUtils";
import { getUserSortLanguage } from "../../api/shared/LanguageApi";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getUserSortLanguage()
          .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
          .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(
        fp.flow(
          list =>
            list
              .toOrderedMap()
              .mapEntries(([, v]) => [v.get("code"), v.get("name")]),
          map => ({
            options: map.keySeq().toList(),
            formatOption: code => map.get(code),
          }),
        ),
      );

    return propsStream.combineLatest(
      responseStream,
      ({ optional, ...props }, response) => ({
        ...props,
        formatOption: response.formatOption,
        disabled: response.options.isEmpty() || props.disabled,
        options: optional ? response.options.unshift(null) : response.options,
      }),
    );
  }),
);

const FormLanguageSelectField = enhancer(FormSelectField);

FormLanguageSelectField.propTypes = {
  name: PropTypes.string.isRequired,

  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,

  maxHeight: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,

  optional: PropTypes.bool,

  validate: PropTypes.func,

  formatInput: PropTypes.func,
  formatOption: PropTypes.func,

  renderOptionLeftIcon: PropTypes.func,
  renderOptionRightIcon: PropTypes.func,
};

export default FormLanguageSelectField;
