import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, makeStyles } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import {
  formatDateTimeToUrl,
  formatText,
  safeParseDate,
} from "../../helpers/FormatUtils";
import { getOrderCountByDevice } from "../../api/admin/AdminDashboardNewApi";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import AdminDashboardFilterDialogWrapper from "../../wrappers/admin/dashboard/AdminDashboardFilterDialogWrapper";
import Android from "../charts/assests/android 1.svg";
import Ios from "../charts/assests/apple 1.svg";
import Excel from "../charts/assests/file-excel-solid 1.svg";
import ViaIntegration from "../charts/assests/via-integration.svg";
import Hybrid from "../charts/assests/hybrid.svg";
import Web from "../charts/assests/globe-africa-solid 1.svg";
import CustomDoughnutChart from "../charts/CustomDoughnutChart";

const customLegendIcon = label => {
  switch (label) {
    case "ios":
      return <img src={Ios} alt="delete" height={32} width={32} />;

    case "android":
      return <img src={Android} alt="delete" height={32} width={32} />;

    case "excel_import":
      return <img src={Excel} alt="delete" height={32} width={32} />;

    case "web":
      return <img src={Web} alt="delete" height={32} width={32} />;

    case "hybrid":
      return <img src={Hybrid} alt="delete" height={32} width={32} />;

    default:
      return <img src={ViaIntegration} alt="delete" height={32} width={32} />;
  }
};

const customColor = label => {
  switch (label) {
    case "ios":
      return "#2196F3";

    case "android":
      return "#3F51B5";

    case "excel_import":
      return "#4CAF50";

    case "web":
      return "#800080";

    case "hybrid":
      return "#FFFF00";

    default:
      return "#FFA500";
  }
};

const legendLabelLocalisation = (label, getLocalisationMessage) => {
  switch (label) {
    case "others":
      return getLocalisationMessage("via_integration");

    case "web":
      return getLocalisationMessage("web_1");

    default:
      return getLocalisationMessage(label, formatText(label));
  }
};

export function getRandomColor() {
  const l = "0123456789ABCDEF";
  let col = "#";
  for (let i = 0; i < 6; i++) {
    col += l[Math.floor(Math.random() * 16)];
  }
  return col;
}

const useStyles = makeStyles(() => ({
  total: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "@media (min-width: 1700px)": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  },
}));

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderCountByPaymentTypeChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
};

function AdminOrderCountByPaymentTypeChartWrapper(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState();
  const doughnutRef = React.useRef(null);
  const [doughnutData, setDoughnutData] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterRefresh, setFilterRefresh] = useState(false);
  const [filter, setFilter] = useState({
    ...props.filter,
  });
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    setFilter({ ...props.filter });
    setFilterRefresh(false);
  }, [props.filter]);

  const styles = useStyles();

  useEffect(() => {
    setIsLoading(true);
    getOrderCountByDevice(filterRefresh ? filter : props.filter)
      .then(r => {
        if (r && r.data) {
          const doughnut = getValue(r, "data.result");
          setActiveIndex([]);

          setDoughnutData(
            doughnut.map(item => ({
              ...item,
              color: customColor(item.label),
            })),
          );
          setIsLoading(false);
        } else {
          setNote(props.getLocalisationMessage("no_data", "No Data"));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setActiveIndex([]);
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setDoughnutData([]);
      });
  }, [props.filter, filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={doughnutRef}
    >
      <AdminDashboardFilterDialogWrapper
        open={open}
        onRequestClose={() => {
          setOpen(false);
          setFilterRefresh(false);
        }}
        withServiceType={true}
        initialValues={{
          jurisdiction: {
            id: filter.jurisdiction_id,
          },
          serviceTypeCode: {
            code: filter.service_type_code,
          },
          fromDateTime: safeParseDate(filter.from_date_time),
          toDateTime: safeParseDate(filter.to_date_time),
        }}
        onSubmit={v => {
          setFilter({
            jurisdiction_id: getValue(v, "jurisdiction.id"),
            from_date_time: formatDateTimeToUrl(v.fromDateTime),
            to_date_time: formatDateTimeToUrl(v.toDateTime),
            service_type_code: getValue(v, "serviceTypeCode.code"),
          });
          setOpen(false);
          setFilterRefresh(true);
        }}
      />

      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_count_by_source",
              "Order Count by source",
            )}
          </FlexBox>
          <FlexBox>
            <CustomButton
              color={SECONDARY}
              fullWidth={true}
              startIcon={<FilterList />}
              onClick={() => {
                setOpen(true);
                setFilterRefresh(false);
              }}
            >
              {props.getLocalisationMessage("filter", "Filter")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && doughnutRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(doughnutRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <div
          className="relative"
          style={{ position: "relative", marginTop: "30px" }}
        >
          <CustomDoughnutChart
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            customLegendIcon={customLegendIcon}
            getLocalisationMessage={props.getLocalisationMessage}
            legendLabelLocalisation={legendLabelLocalisation}
            filter={filter}
            data={{
              labels: doughnutData.map(doughnut => doughnut.label),
              datasets: [
                {
                  data: doughnutData.map(doughnut => doughnut.value || 0),
                  backgroundColor: doughnutData.map(doughnut => doughnut.color),
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              ],
            }}
            position="bottom"
          />
          <div className={styles.total}>
            <h4
              style={{
                fontSize: 48,
                fontWeight: 700,
              }}
            >
              {doughnutData && doughnutData.length
                ? new Intl.NumberFormat("ru-RU").format(
                    doughnutData
                      .filter((item, index) => !activeIndex.includes(index))
                      .reduce((acc, x) => acc + x.value, 0),
                  )
                : 0}{" "}
            </h4>

            <h6
              style={{
                fontSize: 24,
                fontWeight: 400,
                marginTop: 13,
              }}
            >
              {props.getLocalisationMessage("in_total", "In Total")}
            </h6>
          </div>
        </div>
      )}
    </Card>
  );
}

export default enhancer(AdminOrderCountByPaymentTypeChartWrapper);
