import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import { Avatar, ListSubheader } from "@material-ui/core";
import parcelIcon from "./assets/box.svg";
import boxIcon from "./assets/boxIcon.svg";
import serviceIcon from "./assets/serviceIcon.svg";
import receivedIcon from "./assets/receviedIcon.svg";
import moneyIcon from "./assets/money.svg";
import personIcon from "./assets/person.svg";
import RadarBriefItem from "./RadarBriefItem";
import { INCHES_POUNDS } from "../../constants/UnitsOfMeasureTypes";
import {
  isMagentoV1,
  isMagentoV2,
  isOpencart,
  isShopify,
  isWeb,
  isWordpress,
} from "../../helpers/OrderHelper";
import PriceWrapper from "../ui-core/PriceWrapper";

const enhancer = compose(
  useSheet({
    details: {
      color: "#fff",
    },
    title: {
      color: "white",
      fontSize: "16px",
      textTransform: "uppercase",
      paddingLeft: 0,
      lineHeight: "19px",
      marginBottom: "10px",
    },
    name: {
      lineHeight: "28px",
      fontSize: "24px",
      fontWeight: 500,
    },
    phone: {
      lineHeight: "16px",
      fontSize: "14px",
    },
    bottomSpacing: {
      marginBottom: "10px",
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderItemDetailsInfo.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function OrderItemDetailsInfo(props) {
  const { classes, order, getLocalisationMessage } = props;

  if (!order) return null;

  const unit =
    order.get(["dimensions", "unit"]) === INCHES_POUNDS ? "lbs" : "kg";
  const currencyCode = order.getIn(["currency", "code"]);
  const deviceDetails = isMagentoV1(order.get("device_details"))
    ? getLocalisationMessage("magento_v1_extension", "Magento V1 Extension")
    : isMagentoV2(order.get("device_details"))
    ? getLocalisationMessage("magento_v2_extension", "Magento V2 Extension")
    : isOpencart(order.get("device_details"))
    ? getLocalisationMessage("opencart_extension", "Opencart Extension")
    : isShopify(order.get("device_details"))
    ? getLocalisationMessage("shopify_app", "Shopify App")
    : isWordpress(order.get("device_details"))
    ? getLocalisationMessage("wordpress_plugin", "Wordpress Plugin")
    : isWeb(order.get("device_details"))
    ? getLocalisationMessage("web", "Web")
    : getLocalisationMessage("web_and_api", "Web and Api");

  return (
    <FlexBox className={classes.details}>
      <FlexBox flex={true} direction="column">
        <ListSubheader className={classes.title}>
          {getLocalisationMessage("item_details", "Item Details")}
        </ListSubheader>
        <FlexBox className={classes.bottomSpacing}>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("chargeable_weight")}
              text={`${order.get("chargeable_weight")} ${unit}`}
              avatar={
                <Avatar
                  backgroundColor="transparent"
                  size={15}
                  src={parcelIcon}
                />
              }
            />
          </FlexBox>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("box_count")}
              text={`0 / ${order.get("piece_count")}`}
              avatar={
                <Avatar backgroundColor="transparent" size={15} src={boxIcon} />
              }
            />
          </FlexBox>
        </FlexBox>

        <FlexBox className={classes.bottomSpacing}>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("service_type")}
              text={`${order.getIn(["package_type", "name"])}`}
              avatar={
                <Avatar
                  backgroundColor="transparent"
                  size={15}
                  src={serviceIcon}
                />
              }
            />
          </FlexBox>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("received_via")}
              text={`${deviceDetails}`}
              avatar={
                <Avatar
                  backgroundColor="transparent"
                  size={15}
                  src={receivedIcon}
                />
              }
            />
          </FlexBox>
        </FlexBox>
        <FlexBox>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("item_value")}
              text={
                <PriceWrapper
                  code={currencyCode}
                  price={order.get("parcel_value")}
                />
              }
              avatar={
                <Avatar
                  backgroundColor="transparent"
                  size={15}
                  src={moneyIcon}
                />
              }
            />
          </FlexBox>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("selected_package")}
              text={`${order.getIn(["package_rule", "name"])}`}
              avatar={
                <Avatar
                  backgroundColor="transparent"
                  size={15}
                  src={personIcon}
                />
              }
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderItemDetailsInfo);
