import React from "react";
import { fromJS } from "immutable";
import PropTypes from "prop-types";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getInternationalFormatChoose,
  getFormatCache,
} from "../../api/admin/AdminInternationalReportsApi";
import FormAbstractChipsV2 from "./FormAbstractChipsV2";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

FormFormatChips.propTypes = {
  getCachedDriver: PropTypes.func,
  getDriverPredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

function FormFormatChips({ filter, ...props }) {
  return (
    <FormAbstractChipsV2
      {...props}
      getValue={value =>
        getFormatCache(value).map(item =>
          fromJS({ id: item.get("id"), name: item.get("name") }),
        )
      }
      getPredictions={searchText =>
        getInternationalFormatChoose(
          baseFilter.setSearch(searchText).setValueMap(filter),
        )
      }
    />
  );
}

export default FormFormatChips;
