import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CustomButton from "../ui-core/CustomButton";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import { LinearProgress } from "@material-ui/core";
import _ from "lodash";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export const EDIT = "edit";
export const SAVE = "save";
export const LINK = "link";
export const DIALOG = "dialog";
export const DATE = "date";
export const COLUMN = "column";
export const RENDER = "render";
export const INDEX = "index";
export const ACTION_BY_INDEX = "action_by_index";
export const ACTION_WITH_INDEX = "action_with_index";
export const ACTION = "action";
export const CUSTOM_ACTION = "custom_action";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    orderBy,
    orderByDirection,
    setOrderBy,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {props.isCheckboxEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onClick={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {props.headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            style={{ minWidth: 130 }}
            align={headCell.align ? headCell.align : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.name ? orderByDirection : false}
          >
            <TableSortLabel
              disabled={!headCell.isSortable}
              active={orderBy === headCell.name}
              direction={orderBy === headCell.name ? orderByDirection : "asc"}
              onClick={() => setOrderBy(headCell.name)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array,
  isCheckboxEnabled: PropTypes.bool,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  setOrderBy: PropTypes.func,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: "100%",
    overflow: "hidden",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    paddingLeft: "1rem",
  },
  container: {
    height: "calc(77vh - 12.3rem)",
  },
  "@media (min-width: 1680px)": {
    container: {
      height: "calc(86.5vh - 12.3rem)",
    },
  },

  "@media (min-width: 1460px)": {
    container: {
      height: "calc(86.5vh - 12.3rem)",
    },
  },
  link: {
    color: "#9c0008",
    textDecoration: "none",
    cursor: "pointer",
  },
  active: {
    background: "#ffdbfe",
  },
  activeHover: {
    background: "#ffdbfe",
  },
});

function MUITableInternationalReports({
  columns,
  getLocalisationMessage,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  list,
  rowsPerPage,
  total,
  withCheckbox,
  headerActions,
  size,
  title,
  isLoading,
  withoutPagination,
  selectedItems: selected,
  setSelectedItems: setSelected,
  activeId,
  orderBy,
  orderByDirection,
  setOrderBy,
}) {
  const classes = useStyles();

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelected(list.map(v => v.id));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = id => selected && selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {(title || headerActions) && (
          <FlexBox
            flex={true}
            justify={JUSTIFY_SPACE_AROUND}
            align={ALIGN_CENTER}
          >
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {title}
            </Typography>
            {headerActions}
          </FlexBox>
        )}
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={_.get(selected, "length", 0)}
              onSelectAllClick={handleSelectAllClick}
              rowCount={list.length}
              headCells={columns}
              isCheckboxEnabled={withCheckbox}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
            />
            {isLoading ? (
              <LinearProgress
                style={{ position: "absolute", width: "99.8%" }}
                mode="indeterminate"
                color="secondary"
              />
            ) : (
              <TableBody>
                {list.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      className={activeId === row.id && classes.active}
                    >
                      {withCheckbox && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={event => handleClick(event, row.id)}
                          />
                        </TableCell>
                      )}
                      {columns.map((item, i) =>
                        item.type === RENDER ? (
                          <TableCell
                            key={i}
                            style={item.style}
                            padding="normal"
                          >
                            {item.render(row)}
                          </TableCell>
                        ) : (
                          <TableCell key={i} padding="normal">
                            <CustomButton onClick={() => item.dispatch(row.id)}>
                              {item.label}
                            </CustomButton>
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {!withoutPagination && (
            <TablePagination
              style={{
                position: "fixed",
                background: "white",
                bottom: 0,
                left: 0,
                width: "100vw",
                borderTop: "1px solid rgba(0, 0, 0, 0.3)",
              }}
              labelRowsPerPage={getLocalisationMessage("rows_per_page")}
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Paper>
    </div>
  );
}

MUITableInternationalReports.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  columns: PropTypes.array,
  total: PropTypes.number,
  withCheckbox: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  headerActions: PropTypes.any,
  title: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  isLoading: PropTypes.bool,
  withoutPagination: PropTypes.bool,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  activeId: PropTypes.number,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  setOrderBy: PropTypes.func,
};

export default enhancer(MUITableInternationalReports);
