import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";

import fp from "lodash/fp";
import { getValue, isEmpty } from "../../helpers/DataUtils";
import FlexBox, { JUSTIFY_CENTER, JUSTIFY_END } from "../ui-core/FlexBox";
import { withTheme } from "@material-ui/core/styles";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import cx from "classnames";
import { red } from "@material-ui/core/colors";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { getShipmentSearch } from "../../api/v2/admin/AdminOrderSortingApi";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    detailsRow: {
      marginBottom: 20,
    },
    detailsLabel: {
      lineHeight: "14px",
      fontWeight: "400",
      fontSize: "12px",
    },
    detailsValue: {
      lineHeight: "26px",
    },
    alert: {
      color: red[500],
      fontSize: "22px",
      fontWeight: "bold",
    },
  }),
  withTheme,
);

OfflineOrderSortingVerifyBinConfirmDialog.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,

  onRequestClose: PropTypes.func,
  children: PropTypes.node,

  scannedOrder: PropTypes.string,
  binName: PropTypes.string,

  onConfirm: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  theme: PropTypes.object,
};

function OfflineOrderSortingVerifyBinConfirmDialog(props) {
  const {
    getLocalisationMessage,
    classes,
    onConfirm,
    theme,
    scannedOrder,
  } = props;

  const [shipment, setShipment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getShipmentSearch(scannedOrder)
      .then(res => {
        if (res) {
          setShipment(res);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError(true);
      });
  }, [scannedOrder]);

  useEffect(() => {
    if (error && !isLoading) {
      setTimeout(() => props.onRequestClose(scannedOrder), 1000);
    }
  }, [scannedOrder, isLoading, error]);

  const expectedBin = getValue(shipment, "bean.name", "");

  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      PaperProps={{
        style: {
          width: "780px",
        },
      }}
      onKeyPress={event => {
        if (fp.get("code", event) === "Enter") {
          props.onConfirm(shipment);
        }
      }}
    >
      <DialogTitle
        style={{
          color: theme.palette.appBarTextColor,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${props.scannedOrder} ${
              isLoading ? getLocalisationMessage("loading", "Loading...") : ""
            }`}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            {getLocalisationMessage("current_bin", "Current Bin")}
            {": "}
            {props.binName}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      {!isLoading && (
        <DialogContent>
          {!isEmpty(shipment) ? (
            <FlexBox flex={true} direction="column">
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("barcode", "Barcode")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {getValue(shipment, "barcode")}
                  </h6>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("status", "Status")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {formatOrderStatusCodeForLocalisation(
                      getValue(shipment, "status"),
                      getLocalisationMessage,
                    )}
                  </h6>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("jurisdiction", "Jurisdiction")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {getValue(shipment, "to_jurisdiction.name", "")}
                  </h6>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("postcode", "Postcode")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {getValue(shipment, "to_postcode.name", "")}
                  </h6>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage(
                      "current_warehouse",
                      "Current Warehouse",
                    )}
                    :
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {getValue(shipment, "warehouse.name", "")}
                  </h6>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage(
                      "destination_warehouse",
                      "Destination Warehouse",
                    )}
                    :
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {getValue(shipment, "to_warehouse.name", "")}
                  </h6>
                </FlexBox>
              </FlexBox>

              <FlexBox flex={true}>
                {expectedBin ? (
                  <FlexBox flex={true} direction="column">
                    <h6 className={classes.detailsLabel}>
                      {getLocalisationMessage(
                        "current_bin_of_the_order",
                        "Current bin of the Order",
                      )}
                      :
                    </h6>
                    <h6 className={cx(classes.detailsValue, classes.alert)}>
                      {expectedBin}
                    </h6>
                  </FlexBox>
                ) : (
                  <FlexBox flex={true} direction="column">
                    <h6 className={cx(classes.detailsValue, classes.alert)}>
                      {getLocalisationMessage(
                        "according_to_system_the_order_is_not_exists_at_current_warehouse",
                        "According to system the order is not exists at current warehouse",
                      )}
                    </h6>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox flex={true} justify={JUSTIFY_CENTER}>
              <div className={classes.alert}>
                {getLocalisationMessage("order_not_found", "Order not found")}
              </div>
            </FlexBox>
          )}

          {props.children}
        </DialogContent>
      )}

      <DialogActions>
        {props.onRequestClose && (
          <Button onClick={() => props.onRequestClose(props.scannedOrder)}>
            {props.getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        )}

        {onConfirm && !isEmpty(shipment) && (
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() => onConfirm(shipment)}
          >
            {props.getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OfflineOrderSortingVerifyBinConfirmDialog);
