import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Chip } from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { accent1, success1 } from "../../../shared/theme/main-theme";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    chipWrapper: { display: "flex", flexWrap: "wrap" },
    divider: { marginTop: "4px", marginBottom: "4px" },

    chip: { margin: "4px" },
    chipAccent: { backgroundColor: accent1 },
    chipSuccess: { backgroundColor: success1 },
  }),
);

OrderReturnChips.propTypes = {
  classes: PropTypes.object,
  onAddOrders: PropTypes.func.isRequired,
  onRemoveOrders: PropTypes.func.isRequired,
  filterChips: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderReturnChips(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <div className={classes.chipWrapper}>
      {props.filterChips
        .map((chip, index) => {
          const orders = chip.get("orders");
          const selectedAny = chip.get("selected") > 0;
          const selectedAll = chip.get("selected") === orders.size;

          return (
            <Chip
              className={cx(classes.chip, {
                [classes.chipSuccess]: selectedAll,
                [classes.chipAccent]: selectedAny && !selectedAll,
              })}
              key={`${chip.get("group")}-${index}`}
              onClick={() => {
                if (selectedAll) {
                  props.onRemoveOrders(orders);
                } else {
                  props.onAddOrders(orders);
                }
              }}
              label={` ${getLocalisationMessage(
                chip.get("label").toLocaleLowerCase(),
                chip.get("label"),
              )}${" "}
                (${orders.size})`}
            />
          );
        })
        .toArray()}
    </div>
  );
}

export default enhancer(OrderReturnChips);
