import React from "react";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormCountryV2AutoComplete from "../form/FormCountryV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import marketplaceStatuses from "../../constants/MarketplaceStatusConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: {
      "& > div": { display: "flex", flexDirection: "column", flex: "1 1 0%" },
    },
  }),
  withContext(
    {
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        country: {
          id: props.filter.getIntegerValue("country_id") || null,
        },
        type: parseString(props.filter.getValue("marketplace_types")),
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            country_id: getObjectId(values.country),
            marketplace_types: stringifyArray(values.type),
          });
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "MarketplaceFilterForm",
    enableReinitialize: true,
  }),
);

MarketplaceFilterForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  dirty: PropTypes.bool,
  onDismiss: PropTypes.func,
  onFilterChange: PropTypes.func,
  reset: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function MarketplaceFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox
      container={16}
      element={<form onSubmit={props.handleSubmit} />}
      flex={true}
    >
      <FlexBox
        gutter={8}
        flex={true}
        element={<Card className={classes.card} />}
        direction="column"
      >
        <FlexBox element={<CardContent />} flex={true}>
          <FormCountryV2AutoComplete
            readOnly={false}
            fullWidth={true}
            openOnFocus={true}
            hintText={getLocalisationMessage(
              "type_to_search_hint",
              "Type to search ...",
            )}
            name="country"
            label={`${getLocalisationMessage("country", "Country")}*`}
          />
        </FlexBox>
        <FlexBox element={<CardContent />} flex={true}>
          <FormChipAutoComplete
            name="type"
            fullWidth={true}
            autoWidth={true}
            options={marketplaceStatuses}
            hintText={getLocalisationMessage(
              "type_to_search_hint",
              "Type to search ...",
            )}
            label={getLocalisationMessage("types", "Types")}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
          />
        </FlexBox>
        <FlexBox element={<CardActions />} justify="flex-end">
          <Button
            onClick={() => {
              props.change("country", null);
              props.change("type", null);
            }}
          >
            {getLocalisationMessage("clear", "Clear")}
          </Button>

          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(MarketplaceFilterForm);
