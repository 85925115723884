import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PackageRuleUploadForm from "./PackageRuleUploadForm";
import ModalPaper from "../ui-core/ModalPaper";
import { renderIf } from "../../helpers/HOCUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { packageRuleUploadFile } from "../../api/shared/PackageRuleApi";

PackageRuleUploadDialog.propTypes = {
  classes: PropTypes.object,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onRequestClose: PropTypes.func,
  onComplete: PropTypes.func,
  submitting: PropTypes.bool,
  onSubmitting: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

const enchancer = compose(
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "400px",
      minHeight: "auto",
    },
  }),
  connect(null, { showSuccessMessage, showErrorMessage }),
  withState("submitting", "onSubmitting", false),
);

function PackageRuleUploadDialog({
  classes,
  onRequestClose,
  getLocalisationMessage,
  ...props
}) {
  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage(
        "upload_package_rules",
        "Upload Package Rules",
      )}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={onRequestClose}
    >
      <PackageRuleUploadForm
        loading={props.submitting}
        onDismiss={onRequestClose}
        onSubmit={fp.flow(
          values => {
            props.onSubmitting(true);
            return packageRuleUploadFile(values.file);
          },
          request => request.toPromise().catch(ResponseError.throw),
        )}
        getLocalisationMessage={getLocalisationMessage}
        onSubmitFail={error => {
          props.onSubmitting(false);
          props.showErrorMessage(error);
        }}
        onSubmitSuccess={() => {
          props.onSubmitting(false);
          props.showSuccessMessage(
            getLocalisationMessage(
              "package_rule_uploaded_successfully",
              "Package Rule Uploaded Successfully!",
            ),
          );
          return onRequestClose();
        }}
      />
    </ModalPaper>
  );
}

export default enchancer(PackageRuleUploadDialog);
