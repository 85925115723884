import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SAMPLE_ITEMS_SETTINGS_URL } from "../../constants/AdminPathConstants";
import {
  getSampleItemSettings,
  updateSampleItemSettings,
} from "../../api/shared/SampleItemsApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import SampleItemForm from "../../components/sample-items-core/SampleItemForm";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func.isRequired }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      stream: onRequestRefreshStream,
      handler: onRequestRefresh,
    } = createEventHandler();

    const responseStream = propsStream
      .map(fp.flow(fp.get("params.id"), fp.toFinite))
      .distinctUntilChanged()
      .switchMap(id =>
        getSampleItemSettings(id)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      );

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        sampleItem: response.get("payload"),
        isLoading: response.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSampleItemSettinsEditContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  sampleItem: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSampleItemSettinsEditContainer(props) {
  const { getLocalisationMessage, sampleItem } = props;

  return (
    <AdminAppLayout
      title={
        props.isLoading
          ? getLocalisationMessage("loading", "Loading...")
          : `${getLocalisationMessage("edit", "Edit")} - ${sampleItem.get(
              "name",
            )}`
      }
    >
      <PageLoading isLoading={props.isLoading} />

      <SampleItemForm
        sampleItemsForSettings={true}
        initialValues={toCamelCase(props.sampleItem)}
        onSubmit={values => {
          updateSampleItemSettings(toSnakeCase(values)).catch(
            ResponseError.throw,
          );
        }}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
          props.setLocation(SAMPLE_ITEMS_SETTINGS_URL);
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSampleItemSettinsEditContainer);
