import React from "react";
import _ from "lodash";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import CustomizableOrderListSettingsDialogForm from "./CustomizableOrderListSettingsDialogForm";
import FlexBox from "../ui-core/FlexBox";
import { getMessages } from "../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("MarketplaceSettingsForm");

const enhancer = compose(
  connect((state, props) => ({
    i18n: getMessages(state),
    field: valueSelector(state, `${props.name}`),
  })),
  reduxForm({
    form: "MarketplaceSettingsForm",
    enableReinitialize: true,
  }),
  withState("state", "setState", {
    isOpenDialog: false,
  }),
  useSheet({
    textCenter: { textAlign: "center" },
    actionTitle: { width: "200px" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    alignSelf: { alignSelf: "center" },
  }),
);

CustomizableOrderListSettingsForm.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  change: PropTypes.func,
  field: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  i18n: PropTypes.instanceOf(Map),
};

function CustomizableOrderListSettingsForm(props) {
  const selectedMenuSize = props.field
    ? fromJS(_.filter(props.field, { active: true })).size
    : 0;

  return (
    <FlexBox container={8} flex={true}>
      {props.state.isOpenDialog && (
        <CustomizableOrderListSettingsDialogForm
          initialValues={props.field}
          type={props.type}
          onSubmit={values => {
            props.change(props.name, values);
            props.setState(fp.set("isOpenDialog", false));
          }}
        />
      )}

      <FlexBox
        gutter={8}
        flex={true}
        direction="column"
        className={props.classes.alignSelf}
      >
        {`${props.i18n.get("total", "Total")} ${selectedMenuSize} ${fp.toLower(
          props.i18n.get("items_selected", "items selected"),
        )}`}
      </FlexBox>
      <FlexBox gutter={8} flex={true} direction="column">
        <Button onClick={() => props.setState(fp.set("isOpenDialog", true))}>
          {props.i18n.get("add_edit_columns", "Add/Edit Columns")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomizableOrderListSettingsForm);
