import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { CardContent, CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import Text from "../ui-core/Text";
import LinkButton from "../ui-core/LinkButton";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import ErrorSound from "../../assets/voices/error.mp3";
import AlarmSound from "../../assets/voices/alarm.ogg";
import BeepSound from "../../assets/voices/beep.wav";
import Audio from "../ui-core/Audio";
import { DELIVERY, RETURNING } from "../../constants/FlowTypes";
import { Edit, NotificationsActive } from "@material-ui/icons";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import { api } from "../../api/shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getUserId } from "../../reducers/ProfileReducer";
import _ from "lodash";
import OrderSortingEditInsufficientOrderFormDialog from "../order-sorting-bin-validation/OrderSortingEditInsufficientOrderFormDialog";
import { SIMPLE } from "../../constants/TransportationType";
import { formatText } from "../../helpers/FormatUtils";

const formatTransportationTypesLocalised = (
  code,
  getLocalisationMessage,
  notAvailableValue = "N/A",
) => {
  switch (code) {
    case SIMPLE:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("simple_type", "Simple")) ||
        "Simple"
      );

    default:
      return (
        (getLocalisationMessage && getLocalisationMessage(code)) ||
        formatText(code) ||
        notAvailableValue
      );
  }
};

const useStyles = makeStyles(() => ({
  details: { flexDirection: "column", marginBottom: 8 },
  detailsRow: {
    marginBottom: 20,
  },
  detailsLabel: {
    lineHeight: "26px",
    fontWeight: "400",
    "@media (max-width: 991px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
  detailsValue: {
    lineHeight: "26px",
    "@media (max-width: 991px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
  orderErrorStatus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > div": {
      fontSize: "18px",
      lineHeight: "1.2",
      fontWeight: "bold",
      color: red[400],
    },
  },
  orderStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > span": {
      fontWeight: "bold",
      color: red[400],
      textTransform: "uppercase",
    },
  },
  orderLoading: {
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
  },
  "@media (min-width: 998px)": {
    details: { flexDirection: "column" },
  },
  "@media (max-width: 991px)": {
    content: { flex: 1 },
  },
}));

const enhancer = compose(
  connect(
    state => {
      const userId = getUserId(state);
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        userId,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  withState("isOpenEdit", "setIsOpenEdit", false),
  withState("isOpenEditBtn", "setIsOpenEditBtn", false),
  mapPropsStream(propsStream => {
    const stateStream = propsStream
      .map(fp.pick(["activeOrder", "queueOrders"]))
      .distinctUntilChanged(isEqualData)
      .map(props => {
        const queueOrder = props.queueOrders.get(props.activeOrder);

        const state = {};

        if (queueOrder) {
          state.order = queueOrder.get("info");
          state.failed = queueOrder.get("failed");
        }

        return state;
      });

    const inSufficientDataStream = propsStream
      .map(fp.pick(["activeOrder", "queueOrders"]))
      .filter(props =>
        props.queueOrders.getIn([props.activeOrder, "info"], null),
      )
      .map(props => props.queueOrders.getIn([props.activeOrder, "info"], null))
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .do(([order, props]) => {
        props.setIsOpenEdit(order.get("insufficient_data", false));
        props.setIsOpenEditBtn(order.get("ips", false));
      })
      .startWith(false);

    return propsStream.combineLatest(
      stateStream,
      inSufficientDataStream,
      (props, state) => ({
        ...props,
        ...state,
      }),
    );
  }),
  pureComponent(
    fp.pick(["activeOrder", "queueOrders", "isOpenEdit", "isOpenEditBtn"]),
  ),
);

const getShipmentCity = (orderInfo, flow, from = true) => {
  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "from_city")
      : _.get(orderInfo, "to_city");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "to_city")
    : _.get(orderInfo, "from_city");
};

const getShipmentPostcode = (orderInfo, flow, from = true) => {
  const { type } = orderInfo;

  if (type) {
    if (from) {
      return _.get(orderInfo, "from_postcode.name", "");
    }
    return _.get(orderInfo, "to_postcode.name", "");
  }

  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "from_post_code.name", "")
      : _.get(orderInfo, "to_post_code.name", "");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "to_post_code.name", "")
    : _.get(orderInfo, "from_post_code.name", "");
};

OrderSortingBinDetailsCard.propTypes = {
  getLocalisationMessage: PropTypes.func.isRequired,
  activeOrder: PropTypes.object,
  queueOrders: PropTypes.object,
  change: PropTypes.func,
  service_type: PropTypes.object,
  jurisdiction: PropTypes.object,
  postcode: PropTypes.object,
  weight: PropTypes.string,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onReloadClick: PropTypes.func,
  insufficientData: PropTypes.bool,
  setInsufficientData: PropTypes.func,
  isOpenEdit: PropTypes.bool,
  setIsOpenEdit: PropTypes.func,
  isOpenEditBtn: PropTypes.bool,
};

function OrderSortingBinDetailsCard(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { getLocalisationMessage, queueOrders, activeOrder } = props;
  const order = queueOrders.get(activeOrder);

  const [
    {
      flow,
      redirected,
      type,
      transportation_type: transportationType,
      ips,
      ...orderInfo
    },
    setOrderInfo,
  ] = useState(order && order.get("info") ? toJS(order.get("info", {})) : {});
  useEffect(() => order && setOrderInfo(toJS(order.get("info", {}))), [order]);

  const createIPSDraftOrder = async values => {
    setIsLoading(true);
    await api
      .post(`${API_V2_ROOT_URL}/orders/create_ips_draft_orders`, {
        body: values,
      })
      .then(() => {
        setOrderInfo({
          ...orderInfo,
          service_type: values.service_type,
          to_city: _.get(values, "recipient_jurisdiction.name"),
          to_jurisdiction: _.get(values, "recipient_jurisdiction"),
          to_post_code: _.get(values, "recipient_postcode"),
          weight: values.weight,
          insufficient_data: false,
          flow: DELIVERY,
          ips: true,
        });
        props.setIsOpenEdit(false);
        props.showSuccessMessage("successfully");
      })
      .catch(error => {
        props.showErrorMessage(error);
      })
      .finally(() => setIsLoading(false));
  };

  if (!props.activeOrder || !order) return null;

  if (!order.get("info") && !order.get("failed"))
    return (
      <Text element="h3" type="align-center" className={classes.orderLoading}>
        <strong>{props.activeOrder}</strong>
        <br />
        {getLocalisationMessage("loading")}
        {"..."}
      </Text>
    );

  if (order.get("failed"))
    return (
      <Text
        element="h3"
        type="align-center"
        className={classes.orderErrorStatus}
      >
        <strong>{props.activeOrder}</strong>
        <div>
          {order.get("failed_message", null) ||
            getLocalisationMessage("loading_failed")}
        </div>
        <div>
          (
          <LinkButton onClick={() => props.onReloadClick(props.activeOrder)}>
            {getLocalisationMessage("reload")}
          </LinkButton>
          )
        </div>
        <Audio play={true} key={props.activeOrder} src={ErrorSound} />
      </Text>
    );

  const innerShipmentType = type
    ? orderInfo.inner_shipment_type
    : _.get(orderInfo, "service_type.name", null);
  const tempFlow =
    flow === null ? DELIVERY : flow === DELIVERY ? DELIVERY : RETURNING;
  const fromPostcode = getShipmentPostcode(orderInfo, tempFlow);
  const toPostcode = getShipmentPostcode(orderInfo, tempFlow, false);

  const fromCity = type
    ? _.get(orderInfo, "from_jurisdiction.name", "")
    : getShipmentCity(orderInfo, tempFlow);
  const toCity = type
    ? _.get(orderInfo, "to_jurisdiction.name", "")
    : getShipmentCity(orderInfo, tempFlow, false);

  return (
    <div className={classes.details}>
      <OrderSortingEditInsufficientOrderFormDialog
        isLoading={isLoading}
        open={props.isOpenEdit}
        order={orderInfo}
        onSubmit={values => createIPSDraftOrder(values)}
        onDismiss={() => props.setIsOpenEdit(false)}
      />

      <CardHeader
        title={
          <h5>
            {props.getLocalisationMessage("batch_details", "Batch Details")}
          </h5>
        }
      />
      <CardContent className={classes.content}>
        {!props.activeOrder ? (
          <div />
        ) : (
          <FlexBox flex={true} direction="column">
            <FlexBox flex={true}>
              <Text
                element="h3"
                type="align-center"
                className={classes.orderStatus}
                style={{ justifyContent: "space-between" }}
              >
                <strong>
                  {getLocalisationMessage("details", "Details")} -{" "}
                  {props.activeOrder}{" "}
                </strong>
                <FlexBox>
                  {redirected && (
                    <strong
                      style={{
                        margin: "0 .5rem",
                        border: ".25rem solid #f44336",
                        color: "#f44336",
                        padding: ".25rem 2rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {getLocalisationMessage("redirect_order")}
                    </strong>
                  )}
                  {tempFlow === RETURNING && (
                    <strong
                      style={{
                        border: ".25rem solid rgb(255, 152, 0)",
                        margin: "0 .5rem",
                        color: "rgb(255, 152, 0)",
                        padding: ".25rem 2rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {getLocalisationMessage("return")}
                    </strong>
                  )}
                  {props.isOpenEditBtn && (
                    <CustomButton
                      onClick={() => props.setIsOpenEdit(true)}
                      startIcon={<Edit />}
                      variant={OUTLINED}
                      color={SECONDARY}
                    >
                      {getLocalisationMessage("edit")}
                    </CustomButton>
                  )}
                </FlexBox>
              </Text>
            </FlexBox>
            {orderInfo.notification_enabled && (
              <FlexBox>
                <strong
                  style={{
                    color: "rgb(76, 175, 80)",
                    textTransform: "uppercase",
                    marginBottom: "1rem",
                    backgroundColor: "rgba(76, 175, 80,.2)",
                    alignItems: "center",
                    padding: ".5rem 2rem",
                    borderRadius: ".25rem",
                  }}
                >
                  <NotificationsActive
                    style={{ marginRight: ".5rem" }}
                    color="rgb(76, 175, 80)"
                  />
                  {getLocalisationMessage("with_notification")}
                </strong>
              </FlexBox>
            )}
            <FlexBox />
            {type === "Shipment" && !order.get("parent_id") && (
              <FlexBox
                style={{ textTransform: "uppercase" }}
                flex={true}
                className={classes.detailsRow}
              >
                {getLocalisationMessage("misrouted", "Misrouted order")}

                <Audio play={true} key={props.activeOrder} src={AlarmSound} />
              </FlexBox>
            )}
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("type", "Type")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong style={{ textTransform: "capitalize" }}>
                    {getLocalisationMessage(
                      orderInfo.is_notification ? "notification" : type,
                    )}
                  </strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("status", "Status")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {fp.capitalize(
                      formatOrderStatusCodeForLocalisation(
                        orderInfo.status,
                        getLocalisationMessage,
                      ),
                    )}
                  </strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {type
                    ? getLocalisationMessage(
                        "inner_shipment_type",
                        "Inner Shipment Type",
                      )
                    : getLocalisationMessage("shipment_type", "Shipment Type")}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {innerShipmentType &&
                      getLocalisationMessage(
                        innerShipmentType.toLocaleLowerCase(),
                        innerShipmentType,
                      )}
                  </strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage(
                    "transportation_type",
                    "Transportation Type",
                  )}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {transportationType &&
                      formatTransportationTypesLocalised(
                        transportationType,
                        getLocalisationMessage,
                      )}
                  </strong>
                </h5>
              </FlexBox>
            </FlexBox>
            {type && (
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h5 className={classes.detailsLabel}>
                    {getLocalisationMessage("from_warehouse", "From Warehouse")}
                    :
                  </h5>
                  <h5 className={classes.detailsValue}>
                    <strong>{_.get(orderInfo, "from_warehouse.name")}</strong>
                  </h5>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h5 className={classes.detailsLabel}>
                    {getLocalisationMessage("to_warehouse", "To Warehouse")}:
                  </h5>
                  <h5 className={classes.detailsValue}>
                    <strong>
                      {_.get(orderInfo, "to_warehouse.name", null) &&
                      type !== "CONTAINER"
                        ? _.get(orderInfo, "to_warehouse.name")
                        : _.get(orderInfo, "warehouse.name")}
                    </strong>
                  </h5>
                </FlexBox>
              </FlexBox>
            )}
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("from_postcode", "From Postcode")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{fromPostcode}</strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("to_postcode", "To Postcode")}:
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{toPostcode}</strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {type
                    ? getLocalisationMessage(
                        "from_jurisdiction",
                        "From Jurisdiction",
                      )
                    : getLocalisationMessage("from_city")}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{fromCity}</strong>
                </h5>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <h5 className={classes.detailsLabel}>
                  {type
                    ? getLocalisationMessage(
                        "to_jurisdiction",
                        "To Jurisdiction",
                      )
                    : getLocalisationMessage("to_city")}
                  :
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>{toCity}</strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true}>
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("weight")}:&nbsp;
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {orderInfo.weight} {getLocalisationMessage("kg")}
                  </strong>
                </h5>
              </FlexBox>
              {!orderInfo.type && (
                <FlexBox flex={true}>
                  <h5 className={classes.detailsLabel}>
                    {getLocalisationMessage("paid")}:&nbsp;
                  </h5>
                  <h5 className={classes.detailsValue}>
                    <strong>
                      {orderInfo.paid
                        ? orderInfo.amount
                        : getLocalisationMessage("no")}
                      {orderInfo.paid ? getLocalisationMessage("sum") : ""}
                    </strong>
                  </h5>
                </FlexBox>
              )}
            </FlexBox>
            <FlexBox flex={true} className={classes.detailsRow}>
              <FlexBox flex={true}>
                <h5 className={classes.detailsLabel}>
                  {getLocalisationMessage("shipping_cost")}:&nbsp;
                </h5>
                <h5 className={classes.detailsValue}>
                  <strong>
                    {_.get(orderInfo, "parcel_value", 0)}{" "}
                    {getLocalisationMessage("sum")}
                  </strong>
                </h5>
              </FlexBox>
            </FlexBox>
            <Audio play={true} key={props.activeOrder} src={BeepSound} />
          </FlexBox>
        )}
      </CardContent>
    </div>
  );
}

export default enhancer(OrderSortingBinDetailsCard);
