import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SubscriptionAddOnItem from "./SubscriptionAddOnItem";
import FlexBox from "../ui-core/FlexBox";
import { renderIf } from "../../helpers/HOCUtils";
import { isEmpty } from "../../helpers/DataUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  renderIf(fp.flow(fp.get("addOns"), fp.negate(isEmpty))),
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    root: {
      margin: "10px 15px 15px",
    },
    title: {
      fontSize: 14,
      fontWeight: "normal",
    },
  }),
);
SubscriptionAddOnWrap.propTypes = {
  classes: PropTypes.object,
  addOns: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(Map),
  onCheck: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function SubscriptionAddOnWrap(props) {
  const { classes, addOns, filter, i18n } = props;
  return (
    <FlexBox className={classes.root} direction="column">
      <h3 className={classes.title}>{i18n.get("add_ons", "Add ons")}</h3>
      <FlexBox wrap={true} direction="row" justify="space-between">
        {addOns.map(addOn => (
          <SubscriptionAddOnItem
            filter={filter}
            key={addOn.code}
            {...addOn}
            onCheck={props.onCheck}
          />
        ))}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SubscriptionAddOnWrap);
