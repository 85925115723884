import sprintf from "sprintf";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";

const LOCKER_POST_LIST_URL = `${API_ROOT_URL}/admin/locker_posts`;
const LOCKER_POST_ITEM_URL = `${API_ROOT_URL}/admin/locker_post/%s`;
const LOCKER_POST_CREATE_URL = `${API_ROOT_URL}/admin/locker_post`;

export const getLockerPostList = params =>
  api.get(LOCKER_POST_LIST_URL, {
    params,
  });

export const getLockerPostItem = id =>
  api.get(sprintf(LOCKER_POST_ITEM_URL, id));

export const updateLockerPost = (id, body) =>
  api.put(sprintf(LOCKER_POST_ITEM_URL, id), {
    body,
  });

export const createLockerPost = body =>
  api.post(LOCKER_POST_CREATE_URL, {
    body,
  });
