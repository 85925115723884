import React from "react";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import AdminVoiceSortingTabsWrapper from "../../wrappers/admin/AdminVoiceSortingTabsWrapper";
import DataListFilter from "../../helpers/DataListFilter";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { isEqualData } from "../../helpers/DataUtils";
import VoiceUploadList from "../../components/orders-core/VoiceUploadList";
import { AddOutlined } from "@material-ui/icons";
import VoiceCreateDialog from "../../components/orders-core/VoiceCreateDialog";
import VoiceViewDialog from "../../components/orders-core/VoiceViewDialog";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  getCachedVoice,
  getVoiceList,
  getVoicePredictions,
  removeVoice,
} from "../../api/admin/AdminVoiceSortingApi";
import { fromJS, List, OrderedMap } from "immutable";
import AdminVoiceSortingMapForm from "./AdminVoiceSortingMapForm";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import { SettingsApi } from "../../api/shared/SettingsApi";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";

const voiceSortingDataPath = "map-voice";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withState("state", "setState", {
    voiceId: null,
  }),
  mapPropsStream(
    pipeStreams((propsStream) => {
      const db = new SettingsApi({ domain: "voice" });
      const sideEffectsStream = propsStream
        .distinctUntilChanged(isEqualData)
        .do((props) => {
          if (fp.isEmpty(props.location.query)) {
            props.setLocationQueryFilter(
              new DataListFilter({ page: 0, size: 50, tab: "voice_sorting" }),
            );
          }
        })
        .map((props) => ({
          ...props,
          filter: new DataListFilter(props.location.query),
        }));

      const { handler: refreshRequest, stream: refreshRequestStream } =
        createEventHandler();

      const voiceListStream = propsStream
        .distinctUntilChanged(isEqualData)
        .combineLatest(sideEffectsStream, (props, sideEffects) => ({
          props,
          ...sideEffects,
        }))
        .switchMap((props) =>
          getVoiceList(props.filter)
            .filter((v) => !v.pending)
            .map((response) => fromJS(response.payload.data)),
        )
        .repeatWhen(() => refreshRequestStream)
        .startWith(null);

      const { handler: removeClick, stream: removeClickStream } =
        createEventHandler();

      const removeClickStreamAction = removeClickStream
        .switchMap(removeVoice)
        .do(refreshRequest)
        .withLatestFrom(propsStream)
        .switchMap(([, props]) => {
          // eslint-disable-next-line prefer-destructuring
          const voiceId = props.state.voiceId;
          props.setState({ voiceId: null });
          return db
            .getItem(voiceSortingDataPath)
            .map(fp.get("payload.data.settings"))
            .filter(Boolean)
            .map((settings) => {
              let json = {};
              if (fp.isString(settings)) {
                json = JSON.parse(settings);
                if (fp.get("failedVoice.id", json) === voiceId) {
                  json.failedVoice = null;
                }
                if (fp.get("duplicatedVoice.id", json) === voiceId) {
                  json.duplicatedVoice = null;
                }
                if (fp.get("multiboxVoice.id", json) === voiceId) {
                  json.multiboxVoice = null;
                }
              }
              return json;
            })
            .mergeMap((settings) =>
              db.saveItem(voiceSortingDataPath, JSON.stringify(settings)),
            );
        })
        .distinctUntilChanged(isEqualData)
        .startWith(null);

      return propsStream
        .distinctUntilChanged(isEqualData)
        .combineLatest(
          sideEffectsStream,
          voiceListStream,
          removeClickStreamAction,
          (props, sideEffects, voiceResponse) => {
            const item =
              voiceResponse && props.location.query.view
                ? voiceResponse
                    .get("list")
                    .find(
                      (i) => i.get("id") === Number(props.location.query.view),
                    )
                : null;
            return {
              props,
              ...sideEffects,
              list: voiceResponse ? voiceResponse.get("list") : List(),
              total: voiceResponse ? voiceResponse.get("total") : 0,
              item,
              removeClick,
              refreshRequest,
            };
          },
        );
    }),
  ),
);

const useStyles = makeStyles({
  root: {
    height: "100%",
  },
});

const AdminVoiceSortingContainer = ({
  filter,
  getLocalisationMessage,
  item,
  list,
  location,
  removeClick,
  setLocationQuery,
  setLocationQueryFilter,
  setState,
  state,
  total,
}) => {
  const classes = useStyles();
  const tab = filter.getValue("tab", "voice_sorting");
  const isVoiceSorting = tab === "voice_sorting";

  return (
    <AdminAppLayout title={getLocalisationMessage("setup_voice")}>
      <FlexBox direction="column" flex={true} className={classes.root}>
        <AdminVoiceSortingTabsWrapper
          value={filter.getValue("tab", "voice_sorting")}
          filter={filter}
          location={location}
        />
        {location.query.create && (
          <VoiceCreateDialog
            onRequestClose={() => {
              setLocationQuery(fp.set("create", null));
            }}
          />
        )}
        {item && location.query.view && (
          <VoiceViewDialog
            item={item}
            onRequestClose={() => {
              setLocationQuery(fp.set("view", null));
            }}
          />
        )}
        {state && state.voiceId && (
          <ConfirmDialog
            open={true}
            onRequestClose={() => {
              setState({ voiceId: null });
            }}
            onConfirm={() => removeClick(state.voiceId)}
          >
            <div>{getLocalisationMessage("are_you_want_delete_sound")}</div>
          </ConfirmDialog>
        )}

        {isVoiceSorting ? (
          <FlexBox
            style={{ backgroundColor: "white", flex: 1, minHeight: 300 }}
          >
            <AdminVoiceSortingMapForm
              getCachedVoice={getCachedVoice}
              getVoicePredictions={getVoicePredictions}
            />
          </FlexBox>
        ) : (
          <FlexBox
            style={{ backgroundColor: "white", flex: 1, minHeight: 300 }}
          >
            <VoiceUploadList
              data={list}
              totalCount={total}
              filter={filter}
              onFilterChange={(f) => setLocationQueryFilter(f)}
              location={location}
              onRemoveClick={(id) => {
                setState({ voiceId: id });
              }}
              cardActionIcons={
                <FlexBox direction="row">
                  <IconButton
                    onClick={() => {
                      setLocationQuery(fp.set("create", true));
                    }}
                  >
                    <AddOutlined />
                  </IconButton>
                </FlexBox>
              }
            />
          </FlexBox>
        )}
      </FlexBox>
    </AdminAppLayout>
  );
};

AdminVoiceSortingContainer.propTypes = {
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQuery: PropTypes.func,
  location: PropTypes.object,
  list: PropTypes.instanceOf(OrderedMap),
  setLocationQueryFilter: PropTypes.func,
  total: PropTypes.number,
  item: PropTypes.object,
  removeClick: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

AdminVoiceSortingContainer.defaultProps = {
  filter: new DataListFilter({ page: 0, size: 50, tab: "voice_sorting" }),
};

export default enhancer(AdminVoiceSortingContainer);
