import React from "react";
import { Map, Set } from "immutable";
import { compose, mapPropsStream, withHandlers, withState } from "recompose";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List as MuiList,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FlexBox from "../ui-core/FlexBox";
import { green, grey, red, yellow } from "@material-ui/core/colors";
import { IN_SORTING_FACILITY } from "../../constants/OrderStatusCodes";
import { shouldOpen } from "../../containers/admin/AdminInboundSortingContainer";
import { isConsolidatedBag } from "../../helpers/OrderOutboundSortingHelper";
import { CONTAINER } from "../../constants/OrderType";
import { SIMPLE } from "../../constants/TransportationType";
import { isParcelServiceType } from "../../helpers/OrderSortingHelper";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withState(
    "state",
    "setState",
    Map({
      scannedOrders: Set(),
    }),
  ),
  mapPropsStream(propsStream => {
    const verifyOrdersStream = propsStream.map(props => {
      const shipments = Set().asMutable();
      const registries = Set().asMutable();
      const verifiedOrders = Set().asMutable();
      const unVerifiedOrders = Set().asMutable();
      const misRoutedOrders = Set().asMutable();
      const failedOrders = Set().asMutable();
      const container = Set().asMutable();

      props.orders.forEach(order => {
        const type = order.getIn(["info", "type"], null);
        const transportationType = order.getIn(
          ["transportation_type", "type"],
          null,
        );

        if (transportationType === SIMPLE) {
          return;
        }

        const needOpen = shouldOpen(order, props.warehouseId);
        const unOpenedRegistry =
          !needOpen && type && order.get("child_count", 0) === 0;
        const isConsolidated = isConsolidatedBag(type);

        if (order.get("failed")) {
          failedOrders.add(order.get("number"));
        } else if (!type || needOpen || unOpenedRegistry) {
          // It is order
          shipments.add(order.get("number"));

          if (type === CONTAINER) container.add(order.get("number"));

          if (unOpenedRegistry && !isConsolidated)
            registries.add(order.get("number"));

          if (order.get("scanned") && order.get("scanned_time")) {
            verifiedOrders.add(order.get("number"));
          } else {
            unVerifiedOrders.add(order.get("number"));
          }

          if (!order.get("parent_id") && !isConsolidated) {
            misRoutedOrders.add(order.get("number"));
          }

          // if (order.get("parent_id") || isConsolidated) {
          //   // the order has parent and assumed as Expected Order
          //   if (order.get("scanned")) {
          //     // It is scanned and verified
          //     verifiedOrders.add(order.get("number"));
          //   } else {
          //     unVerifiedOrders.add(order.get("number"));
          //   }
          // } else if (order.get("warehouse")) {
          //   misRoutedOrders.add(order.get("number"));
          // }
        }
      });

      return {
        registries: registries.asImmutable(),
        shipments: shipments.asImmutable(),
        verifiedOrders: verifiedOrders.asImmutable(),
        unVerifiedOrders: unVerifiedOrders.asImmutable(),
        misRoutedOrders: misRoutedOrders.asImmutable(),
        failedOrders: failedOrders.asImmutable(),
        container: container.asImmutable(),
      };
    });

    return propsStream.combineLatest(verifyOrdersStream, (props, response) => ({
      ...props,

      registries: response.registries,
      shipments: response.shipments,
      verifiedOrders: response.verifiedOrders,
      unVerifiedOrders: response.unVerifiedOrders,
      misRoutedOrders: response.misRoutedOrders,
      failedOrders: response.failedOrders,
      container: response.container,
    }));
  }),
  withHandlers({
    onConfirmOrders: props => ({ registries, misRoutedOrders, container }) => {
      const orders = props.orders || Map();
      const statuses = Map().asMutable();
      const dbOrders = {};

      if (registries.size > 0) {
        registries.forEach(order => {
          if (
            isParcelServiceType(
              orders.getIn([order, "info", "inner_shipment_type"], null),
            )
          ) {
            const toWarehouseId = orders.getIn(
              [order, "info", "to_warehouse", "id"],
              null,
            );
            if (toWarehouseId) {
              if (toWarehouseId === props.warehouseId) {
                dbOrders[`${order}/number`] = order;
              }
            } else {
              dbOrders[`${order}/number`] = order;
            }
          } else {
            dbOrders[`${order}/number`] = order;
          }
        });
      }

      if (misRoutedOrders.size > 0) {
        // if (unVerifiedOrders.size > 0) {
        //   unVerifiedOrders.forEach(order => {
        //     dbOrders[`${order}/number`] = order;
        //   });
        // }

        if (misRoutedOrders.size > 0) {
          misRoutedOrders.forEach(order => {
            dbOrders[`${order}/number`] = order;
          });
        }

        // const inSortingFacilityOrders = unVerifiedOrders.merge(misRoutedOrders);

        statuses.set(
          IN_SORTING_FACILITY,
          Map({
            status: IN_SORTING_FACILITY,
            barcodes: misRoutedOrders,
          }),
        );
      }

      const statusesArray = statuses
        .asMutable()
        .valueSeq()
        .toSet();

      props.onConfirm({
        statuses: statusesArray.toJS(),
        container,
        dbOrders,
      });
      // if (unverifiedBarcodes.length > 0) {
      //   // TODO Unverified Orders will sent to the Outbound Sorting as usual Status
      //
      //   // Promise.resolve(
      //   //   fetchOrderList(baseFilter.setSearch(unverifiedBarcodes.join(","))),
      //   // )
      //   //   .then(fp.flow(fp.get("data.list"), fp.defaultTo([]), fromJS))
      //   //   .then((response) => {
      //   //     response.forEach((order) => {
      //   //       const orderNumber = order.get("barcode");
      //   //       dbOrders[`${orderNumber}/number`] = orderNumber;
      //   //       dbOrders[`${orderNumber}/bin`] = LOST;
      //   //     });
      //   //
      //   //     props.onConfirm({
      //   //       statuses: statusesArray.toJS(),
      //   //       container,
      //   //       dbOrders,
      //   //     });
      //   //   });
      // } else {
      //   props.onConfirm({
      //     statuses: statusesArray.toJS(),
      //     container,
      //     dbOrders,
      //   });
      // }
    },
  }),
  withTheme,
);
const useStyles = makeStyles({
  total: { backgroundColor: grey[400], color: "black" },
  verified: { backgroundColor: green[300], color: "black" },
  unverified: { backgroundColor: yellow[300], color: "black" },
  misRouted: { backgroundColor: yellow[900], color: "black" },
  failed: { backgroundColor: red[500], color: "black" },
  listItem: {
    margin: ".5rem 0",
  },
});

OrderInboundSortingVerifyOrdersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  verifiedOrders: PropTypes.instanceOf(Set),
  unVerifiedOrders: PropTypes.instanceOf(Set),
  misRoutedOrders: PropTypes.instanceOf(Set),
  shipments: PropTypes.instanceOf(Set),
  registries: PropTypes.instanceOf(Set),
  container: PropTypes.instanceOf(Set),
  failedOrders: PropTypes.instanceOf(Set),
  onConfirmOrders: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  theme: PropTypes.object,
};

function OrderInboundSortingVerifyOrdersDialog(props) {
  const classes = useStyles();
  const {
    registries,
    shipments,
    verifiedOrders,
    unVerifiedOrders,
    misRoutedOrders,
    failedOrders,
    container,
    getLocalisationMessage,
  } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          width: "500px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${getLocalisationMessage("verify_orders", "Verify Orders")}`}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <MuiList dense={true}>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage("total_orders", "Total Orders")}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.total}>{shipments.size}</Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "verified_orders",
                "Verified Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.verified}>
                {verifiedOrders.size}
              </Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "unverified_orders",
                "Unverified Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.unverified}>
                {unVerifiedOrders.size}
              </Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "misrouted_orders",
                "Misrouted Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.misRouted}>
                {misRoutedOrders.size}
              </Avatar>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getLocalisationMessage(
                "non_existed_orders",
                "Non Existed Orders",
              )}
            />
            <ListItemSecondaryAction>
              <Avatar className={classes.failed}>{failedOrders.size}</Avatar>
            </ListItemSecondaryAction>
          </ListItem>
        </MuiList>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() =>
              props.onConfirmOrders({
                registries,
                unVerifiedOrders,
                misRoutedOrders,
                failedOrders,
                container,
              })
            }
          >
            {getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderInboundSortingVerifyOrdersDialog);
