import React, { useEffect, useRef, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PageLoading from "../../ui-core/PageLoading";
import FlexBox, { JUSTIFY_END } from "../../ui-core/FlexBox";
import FormTextField, { OUTLINED, SECONDARY } from "../../form/FormTextField";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import FormCheckbox from "../../form/FormCheckbox";
import FormDateField from "../../form/FormDateField";
import { formatDate, formatText } from "../../../helpers/FormatUtils";
import FormAutocompleteNew from "../../form/FormInternationalSettingsAutocomplete";
import {
  getCacheRegion,
  getCacheRegionCode,
  getInternationalInTariffCalculate,
  getInternationalRegionChoose,
  getInternationalSubclassesChoose,
  getSubclassesCache,
} from "../../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../../helpers/DataUtils";
import fp from "lodash/fp";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../../reducers/NotificationsReducer";
import { toCamelCase } from "../../../helpers/CaseMapper";
import { isValidDate, isValidObjectId } from "../../../helpers/ValidateUtils";
import FormAutoComplete from "../../form/FormAutoComplete";
import { makeStyles } from "@material-ui/core";

const actions = ["OUT", "IN"];
const categories = ["AIR", "GROUND", "SAL"];

const getValues = getFormValues("InternationalParcelForm");

const cleanupValues = fp.omit([
  "action",
  "category",
  "fromCountry",
  "fromCity",
  "toCountry",
  "toCity",
  "transitRegion",
  "date",
  "formationDate",
  "subclass",
]);

export const localisationAction = (option, getLocalisationMessage) => {
  switch (option) {
    case "IN":
      return getLocalisationMessage("inbound", "Inbound");

    case "OUT":
      return getLocalisationMessage("outbound", "Outbound");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

export const localisationCategory = (option, getLocalisationMessage) => {
  switch (option) {
    case "SAL":
      return getLocalisationMessage("sal", "S.A.L");

    case "GROUND":
      return getLocalisationMessage("surface", "Surface");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

const useStyles = makeStyles({
  textField: {
    "@media (max-width: 2560px)": {
      "& .MuiFormControl-root": {
        "& .MuiInputLabel-root": {
          wordWrap: "break-all",
          whiteSpace: "pre-line",
          overflowWrap: "anywhere",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "13px",
          width: "95%",
          paddingBottom: 12,
          transform: "translate(12px, 6px) scale(1)",
        },
        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
  },
  textField2: {
    "@media (max-width: 2560px)": {
      "& .MuiFormControl-root": {
        "& .MuiInputLabel-root": {
          transform: "translate(8px, 10px) scale(1)",
          fontSize: "12px",
          width: "95%",
        },
        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage },
  ),
  reduxForm({
    form: "InternationalParcelForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      action:
        !values.action &&
        props.getLocalisationMessage("this_field_is_required"),
      category:
        !values.category &&
        props.getLocalisationMessage("this_field_is_required"),
      date:
        !isValidDate(values.date) &&
        props.getLocalisationMessage("this_field_is_required"),
      items:
        !(values.hasReturn || values.hasTransit) &&
        !values.items &&
        props.getLocalisationMessage("this_field_is_required"),
      weight:
        !(values.hasReturn || values.hasTransit) &&
        !values.weight &&
        props.getLocalisationMessage("this_field_is_required"),
      returnWeight:
        values.hasReturn &&
        !values.returnWeight &&
        props.getLocalisationMessage("this_field_is_required"),
      returnItems:
        values.hasReturn &&
        !values.returnItems &&
        props.getLocalisationMessage("this_field_is_required"),
      transitWeight:
        values.hasTransit &&
        !values.transitWeight &&
        props.getLocalisationMessage("this_field_is_required"),
      transitItems:
        values.hasTransit &&
        !values.transitItems &&
        props.getLocalisationMessage("this_field_is_required"),
      subclass:
        !isValidObjectId(values.subclass) &&
        props.getLocalisationMessage("this_field_is_required"),
      fromCountry:
        !isValidObjectId(values.fromCountry) &&
        props.getLocalisationMessage("this_field_is_required"),
      fromCity:
        !isValidObjectId(values.fromCity) &&
        props.getLocalisationMessage("this_field_is_required"),
      toCountry:
        !isValidObjectId(values.toCountry) &&
        props.getLocalisationMessage("this_field_is_required"),
      toCity:
        !isValidObjectId(values.toCity) &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

const InternationalParcelForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  initialValues,
  change,
  onClose,
  update,
  success,
  setSuccess,
  reset,
  clear,
  ...props
}) => {
  const {
    action,
    category,
    fromCountry,
    toCountry,
    transitRegion,
    tariff,
  } = values;
  const [ignore, setIgnore] = useState(false);
  const [clear103, setClear103] = useState(false);
  const [clearAdd, setClearAdd] = useState(false);

  const actionRef = useRef(null);
  const categoryRef = useRef(null);
  const fromCountryRef = useRef(null);
  const fromCityRef = useRef(null);
  const toCountryRef = useRef(null);
  const toCityRef = useRef(null);
  const transitCountryRef = useRef(null);
  const formatDateRef = useRef(null);
  const dateRef = useRef(null);
  const dispatchNumberRef = useRef(null);
  const subclassRef = useRef(null);
  const weightRef = useRef(null);
  const itemsRef = useRef(null);
  const commentsRef = useRef(null);
  const returnRef = useRef(null);
  const returnWeightRef = useRef(null);
  const returnItemRef = useRef(null);
  const returnFirstAmountRef = useRef(null);
  const returnSecondAmountRef = useRef(null);
  const transitRef = useRef(null);
  const transitWeightRef = useRef(null);
  const transitItemsRef = useRef(null);
  const transitFirstAmountRef = useRef(null);
  const transitSecondAmountRef = useRef(null);
  const submitButtonRef = useRef(null);

  const handleKeyDown = (e, ref, dateClick) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (dateClick) {
        ref.current.click();
        ref.current.focus();
      } else {
        ref.current.focus();
      }
    }
  };

  const handleSelectChange = (ref, dateClick) => {
    if (dateClick) {
      ref.current.click();
      ref.current.focus();
    } else {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (!update) {
      if (action === "OUT") {
        getCacheRegionCode("UZB").then(r => {
          change("fromCountry", getValue(r, "data", {}));
          change("toCountry", null);
          change("toCity", null);
          change("fromCity", null);
        });
      } else if (action === "IN") {
        getCacheRegionCode("UZB").then(r => {
          change("toCountry", getValue(r, "data", {}));
          change("fromCountry", null);
          change("toCity", null);
          change("fromCity", null);
        });
      }
    }
  }, [action]);

  useEffect(() => {
    if (
      ((action === "IN" && fromCountry && fromCountry.id) ||
        (action === "OUT" && toCountry && toCountry.id)) &&
      category
    ) {
      getInternationalInTariffCalculate({
        action,
        category,
        from: getValue(fromCountry, "id"),
        to: getValue(toCountry, "id"),
        transit: getValue(transitRegion, "id"),
      })
        .then(res => {
          change("tariff", toCamelCase(res));
        })
        .catch(error => props.showErrorMessage(error));
    }
  }, [action, category, fromCountry, toCountry, transitRegion]);

  useEffect(() => {
    const ignoreValues = cleanupValues(values);
    if (success) {
      if (clear103) {
        fp.keys(ignoreValues).forEach(key => {
          change(key, null);
          setSuccess(false);
          setClear103(false);
          setIgnore(false);
        });
      } else if (clearAdd) {
        reset();
        fp.keys(values).forEach(key => {
          change(key, null);
          setClearAdd(false);
          setSuccess(false);
          setIgnore(false);
        });
      }
    }
  }, [success]);

  const classes = useStyles();

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <PageLoading isLoading={submitting} />
      <FlexBox container={8} direction="column" style={{ gap: 13 }}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="action"
              fullWidth={true}
              options={actions}
              formatOption={option =>
                localisationAction(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage("action", "Action")}*`}
              onChange={() => {
                change("tariff", null);
              }}
              onKeyDown={e => handleKeyDown(e, categoryRef)}
              inputRef={actionRef}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="category"
              fullWidth={true}
              options={categories}
              formatOption={option =>
                localisationCategory(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage("category", "Category")}*`}
              onChange={() => {
                change("tariff", null);
              }}
              onKeyDown={e => handleKeyDown(e, fromCountryRef)}
              inputRef={categoryRef}
            />
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={fromCountryRef}
              onKeyDown={e => handleKeyDown(e, fromCityRef)}
              ignoreError={ignore && !success}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="fromCountry"
              levelId={1}
              fullWidth={true}
              label={`${getLocalisationMessage(
                "from_country",
                "From Country",
              )} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
              onChange={() => {
                change("fromCity", null);
                change("tariff", null);
              }}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={fromCityRef}
              onKeyDown={e => handleKeyDown(e, toCountryRef)}
              ignoreError={ignore && !success}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="fromCity"
              disabled={!getValue(fromCountry, "id")}
              levelId={2}
              parentId={getValue(fromCountry, "id")}
              fullWidth={true}
              label={`${getLocalisationMessage("from_city", "From City")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={toCountryRef}
              onKeyDown={e => handleKeyDown(e, toCityRef)}
              chooseAPI={getInternationalRegionChoose}
              name="toCountry"
              getByCode={getCacheRegionCode}
              levelId={1}
              fullWidth={true}
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage("to_country", "To Country")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              onChange={() => {
                change("toCity", null);
                change("tariff", null);
              }}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={toCityRef}
              onKeyDown={e => handleKeyDown(e, transitCountryRef)}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="toCity"
              fullWidth={true}
              disabled={!getValue(toCountry, "id")}
              levelId={2}
              parentId={getValue(toCountry, "id")}
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage("to_city", "To City")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormAutocompleteNew
              inputRef={transitCountryRef}
              onKeyDown={e => handleKeyDown(e, formatDateRef)}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                  {getValue(option, "value.parent.id") && (
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {getValue(option, "value.parent.code")
                        ? `${getValue(option, "value.parent.name")} (${getValue(
                            option,
                            "value.parent.code",
                            " ",
                          )})`
                        : getValue(option, "value.parent.name")}
                    </span>
                  )}
                </FlexBox>
              )}
              name="transitRegion"
              fullWidth={true}
              label={getLocalisationMessage("through", "Through")}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormDateField
              inputRef={formatDateRef}
              onKeyDown={e => handleKeyDown(e, dateRef, true)}
              handleSelectChange={() => handleSelectChange(dateRef, true)}
              ignoreError={ignore}
              name="formationDate"
              fullWidth={true}
              formatValue="dd-MM-yyyy"
              formatDate={date => formatDate(date, "dd-MM-yyyy")}
              hintText={getLocalisationMessage(
                "formation_date",
                "Formation Date",
              )}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormDateField
              name="date"
              inputRef={dateRef}
              onKeyDown={e => handleKeyDown(e, dispatchNumberRef)}
              handleSelectChange={() => handleSelectChange(dispatchNumberRef)}
              fullWidth={true}
              formatValue="dd-MM-yyyy"
              ignoreError={ignore}
              formatDate={date => formatDate(date, "dd-MM-yyyy")}
              hintText={`${getLocalisationMessage("date", "Date")} *`}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormTextField
              inputRef={dispatchNumberRef}
              onKeyDown={e => handleKeyDown(e, subclassRef)}
              name="dispatchNumber"
              fullWidth={true}
              label={getLocalisationMessage(
                "dispatch_number",
                "Dispatch Number",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormAutocompleteNew
              inputRef={subclassRef}
              onKeyDown={e => handleKeyDown(e, weightRef)}
              chooseData="data"
              getById={getSubclassesCache}
              chooseAPI={getInternationalSubclassesChoose}
              name="subclass"
              fullWidth={true}
              label={`${getLocalisationMessage("subclass", "Subclass")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              ignoreError={ignore}
            />
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 10 }}>
            <FormTextField
              inputRef={weightRef}
              onKeyDown={e => handleKeyDown(e, itemsRef)}
              name="weight"
              type="number"
              fullWidth={true}
              label={
                !(values.hasReturn || values.hasTransit)
                  ? `${getLocalisationMessage("weight", "Weight")} *`
                  : getLocalisationMessage("weight", "Weight")
              }
              ignoreError={ignore}
            />

            <span className={classes.textField2}>
              <FormTextField
                inputRef={itemsRef}
                onKeyDown={e => handleKeyDown(e, commentsRef)}
                name="items"
                type="number"
                fullWidth={true}
                ignoreError={ignore}
                label={
                  !(values.hasReturn || values.hasTransit)
                    ? `${getLocalisationMessage("items", "Items")} *`
                    : getLocalisationMessage("items", "Items")
                }
              />
            </span>
          </FlexBox>

          <FlexBox flex={true}>
            <FormTextField
              inputRef={commentsRef}
              onKeyDown={e => handleKeyDown(e, returnRef)}
              name="comments"
              fullWidth={true}
              label={getLocalisationMessage("comments", "Comments")}
            />
          </FlexBox>

          <FlexBox
            flex={true}
            style={{ gap: 10 }}
            className={classes.textField}
          >
            <FormTextField
              name="tariff.pricePerItem"
              type="number"
              disabled={true}
              fullWidth={true}
              label={getLocalisationMessage("spz_item", "SPZ Item")}
            />
            <FormTextField
              name="tariff.pricePerKg"
              type="number"
              disabled={true}
              fullWidth={true}
              label={getLocalisationMessage("spz_weight", "SPZ Weight")}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} style={{ gap: 13 }}>
            <FlexBox flex={true} justify="center">
              <FormCheckbox
                name="hasReturn"
                label={`${getLocalisationMessage("return", "Return")}`}
                inputRef={returnRef}
                onKeyDown={e => {
                  handleKeyDown(
                    e,
                    values.hasReturn ? returnWeightRef : transitRef,
                  );
                }}
                onChange={() => {
                  change("returnWeight", null);
                  change("returnItems", null);
                  change("returnAmount", null);
                  change("returnAmountSecond", null);
                }}
              />
            </FlexBox>

            {values.hasReturn && (
              <FlexBox flex={true}>
                <FormTextField
                  inputRef={returnWeightRef}
                  onKeyDown={e => handleKeyDown(e, returnItemRef)}
                  name="returnWeight"
                  fullWidth={true}
                  type="number"
                  disabled={!values.hasReturn}
                  label={`${getLocalisationMessage("weight", "Weight")} *`}
                />
              </FlexBox>
            )}
          </FlexBox>

          {values.hasReturn ? (
            <FlexBox flex={true} style={{ gap: 10 }}>
              <FormTextField
                inputRef={returnItemRef}
                onKeyDown={e => handleKeyDown(e, returnFirstAmountRef)}
                name="returnItems"
                fullWidth={true}
                type="number"
                disabled={!values.hasReturn}
                label={`${getLocalisationMessage("item", "Item")} *`}
              />

              <FormTextField
                inputRef={returnFirstAmountRef}
                onKeyDown={e => handleKeyDown(e, returnSecondAmountRef)}
                name="returnAmount"
                fullWidth={true}
                type="number"
                disabled={!values.hasReturn}
                label={getLocalisationMessage("entered_spz", "Entered SPZ")}
              />
            </FlexBox>
          ) : (
            <FlexBox flex={true} />
          )}

          {values.hasReturn ? (
            <FlexBox flex={true} style={{ gap: 10 }}>
              <FlexBox flex={true}>
                <FormTextField
                  inputRef={returnSecondAmountRef}
                  onKeyDown={e => handleKeyDown(e, transitRef)}
                  name="returnAmountSecond"
                  fullWidth={true}
                  type="number"
                  disabled={!values.hasReturn}
                  label={getLocalisationMessage("entered_spz", "Entered SPZ")}
                />
              </FlexBox>

              <FlexBox flex={true} justify="center">
                <FormCheckbox
                  inputRef={transitRef}
                  onKeyDown={e =>
                    handleKeyDown(
                      e,
                      values.hasTransit ? transitWeightRef : submitButtonRef,
                    )
                  }
                  name="hasTransit"
                  label={`${getLocalisationMessage("transit", "Transit")}`}
                  onChange={() => {
                    change("transitWeight", null);
                    change("transitItems", null);
                    change("transitAmount", null);
                    change("transitAmountSecond", null);
                  }}
                />
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true} />
              <FlexBox flex={true} justify="center">
                <FormCheckbox
                  inputRef={transitRef}
                  onKeyDown={e =>
                    handleKeyDown(
                      e,
                      values.hasTransit ? transitWeightRef : submitButtonRef,
                    )
                  }
                  name="hasTransit"
                  label={`${getLocalisationMessage("transit", "Transit")}`}
                  onChange={() => {
                    change("transitWeight", null);
                    change("transitItems", null);
                    change("transitAmount", null);
                    change("transitAmountSecond", null);
                  }}
                />
              </FlexBox>
            </FlexBox>
          )}

          {values.hasTransit && (
            <FlexBox flex={true} style={{ gap: 16 }}>
              <FormTextField
                inputRef={transitWeightRef}
                onKeyDown={e => handleKeyDown(e, transitItemsRef)}
                name="transitWeight"
                fullWidth={true}
                type="number"
                disabled={!values.hasTransit}
                label={`${getLocalisationMessage("weight", "Weight")} *`}
              />

              <FormTextField
                inputRef={transitItemsRef}
                onKeyDown={e => handleKeyDown(e, transitFirstAmountRef)}
                name="transitItems"
                fullWidth={true}
                type="number"
                disabled={!values.hasTransit}
                label={`${getLocalisationMessage("item", "Item")} *`}
              />
            </FlexBox>
          )}

          {values.hasTransit ? (
            <FlexBox flex={true} style={{ gap: 10 }}>
              <FormTextField
                inputRef={transitFirstAmountRef}
                onKeyDown={e => handleKeyDown(e, transitSecondAmountRef)}
                name="transitAmount"
                fullWidth={true}
                type="number"
                disabled={!values.hasTransit}
                label={getLocalisationMessage("entered_spz", "Entered SPZ")}
              />
              <FormTextField
                inputRef={transitSecondAmountRef}
                onKeyDown={e => handleKeyDown(e, submitButtonRef)}
                name="transitAmountSecond"
                fullWidth={true}
                type="number"
                disabled={!values.hasTransit}
                label={getLocalisationMessage("entered_spz", "Entered SPZ")}
              />
            </FlexBox>
          ) : (
            <FlexBox flex={true} />
          )}

          <FlexBox flex={true} justify={JUSTIFY_END} style={{ gap: 16 }}>
            {!update && (
              <CustomButton
                variant={CONTAINED}
                color={SECONDARY}
                disabled={!(tariff && tariff.pricePerItem && tariff.pricePerKg)}
                fullWidth={true}
                buttonRef={submitButtonRef}
                style={{
                  height: 40,
                }}
                type="submit"
                onClick={() => {
                  setIgnore(true);
                  setClear103(true);
                  handleSelectChange(actionRef);
                }}
              >
                {getLocalisationMessage("continue", "Continue")}
              </CustomButton>
            )}
          </FlexBox>

          <FlexBox style={{ gap: 16 }} flex={true} justify="space-between">
            <CustomButton
              onClick={() => {
                setSuccess(true);
                setIgnore(true);
                setClearAdd(true);
                onClose();
              }}
              style={{
                height: 40,
              }}
              fullWidth={true}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("discard", "Discard")}
            </CustomButton>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              type="submit"
              style={{
                height: 40,
              }}
              fullWidth={true}
              disabled={!tariff}
              onClick={() => {
                setIgnore(true);
                setClearAdd(true);
              }}
            >
              {update
                ? getLocalisationMessage("update", "update")
                : getLocalisationMessage("add", "Add")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
};
InternationalParcelForm.propTypes = {
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  update: PropTypes.bool,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  onClose: PropTypes.func,
  setSuccess: PropTypes.func,
  reset: PropTypes.func,
  clear: PropTypes.func,
};
export default enhancer(InternationalParcelForm);
