import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import FlexBox from "../ui-core/FlexBox";
import CustomPriceWrapper from "../ui-core/CustomPriceWrapper";
import { getMessages } from "../../reducers/LocalizationReducer";

const getSelectedIncludes = tab => tab.get("selectedIncludes", {});
const getPlanPrice = fp.flow(
  getSelectedIncludes,
  fp.get("price"),
  fp.defaultTo(0),
);
const getPlanShipmentCount = fp.flow(
  getSelectedIncludes,
  fp.get("shipment_count"),
  fp.defaultTo(0),
);
const getPlanLimitExceedPrice = fp.flow(
  getSelectedIncludes,
  fp.get("limit_exceed_price"),
  fp.defaultTo(0),
);
const getPlanShipmentPerPrice = fp.flow(
  getSelectedIncludes,
  fp.get("shipment_price"),
  fp.defaultTo(0),
);
const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    priceInfoBox: {
      "&  a:hover": {
        textDecoration: "none",
      },
      "& .disabled": {
        opacity: 0.6,
      },
    },
    priceInfoLink: {},

    priceBoxOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(255,255,255,0.6)",
    },
  }),
);

SubscriptionPlanPriceBoxes.propTypes = {
  tabs: PropTypes.instanceOf(List).isRequired,
  isSelectedFn: PropTypes.func.isRequired,
  minWidth: PropTypes.number.isRequired,
  classes: PropTypes.object,
  filter: PropTypes.instanceOf(Map),
  i18n: PropTypes.instanceOf(Map),
};

function SubscriptionPlanPriceBoxes(props) {
  const { classes, tabs, i18n } = props;
  const periodType = props.filter.get("period_type");
  return (
    <FlexBox
      className={classes.priceInfoBox}
      style={{ minWidth: props.minWidth, zIndex: 1, position: "relative" }}
    >
      {tabs.map(tab => (
        <Link
          key={tab.get("value")}
          style={{
            display: "block",
            position: "relative",
            minWidth: `${100 / props.tabs.count()}%`,
          }}
          activeClassName={cx({
            selected: props.isSelectedFn(tab.get("value")),
            disabled: tab.get("disabled"),
          })}
          to={tab.get("disabled") ? null : tab.get("link")}
        >
          {props.isSelectedFn(tab.get("value")) ? null : (
            <div className={classes.priceBoxOverlay} />
          )}
          <div>
            <h4 style={{ textAlign: "center" }}>
              <CustomPriceWrapper
                price={getPlanPrice(tab)}
                decimalLength={0}
                code={props.filter.get("currency")}
              />
            </h4>

            <div style={{ padding: 10 }}>
              <p style={{ fontSize: 12, marginBottom: 5, lineHeight: 1.2 }}>
                <strong>{getPlanShipmentCount(tab)}</strong>{" "}
                {i18n.get("shipments", "shipments")} /{" "}
                {i18n.get(
                  fp.toLower(periodType.replace(/ly$/, "")),
                  periodType.replace(/ly$/, ""),
                )}
              </p>
              <p style={{ fontSize: 12, marginBottom: 5, lineHeight: 1.2 }}>
                <strong>
                  <CustomPriceWrapper
                    price={getPlanShipmentPerPrice(tab)}
                    code={props.filter.get("currency")}
                  />
                </strong>{" "}
                / {i18n.get("per_shipment_1", "per shipment")}
              </p>
              <p style={{ fontSize: 12, marginBottom: 5, lineHeight: 1.2 }}>
                <strong>
                  <CustomPriceWrapper
                    price={getPlanLimitExceedPrice(tab)}
                    code={props.filter.get("currency")}
                  />
                </strong>{" "}
                / {i18n.get("limit_exceed", "limit exceed")}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </FlexBox>
  );
}

export default enhancer(SubscriptionPlanPriceBoxes);
