import { Observable } from "rxjs";
import React from "react";
import { Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormFileField from "../form/FormFileField";
import FormSelectField from "../form/FormSelectField";
import FormCityAutoComplete from "../form/FormCityV2AutoComplete";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { createNotFalsyValidator } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { isBlankString } from "../../helpers/ValidateUtils";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  CITY_TO,
  CITY_FROM,
  SELLER_ID,
  COUNTRY_TO,
  SENDER_NAME,
  COUNTRY_FROM,
  NEIGHBORHOOD_TO,
  PICKUP_WAREHOUSE,
  CURRENT_WAREHOUSE,
  NEIGHBORHOOD_FROM,
} from "../../constants/RuleConditionField";
import RuleComparationOperator from "../../constants/RuleComparationOperator";

const orderRuleFields = OrderedSet.of(
  SELLER_ID,
  SENDER_NAME,
  NEIGHBORHOOD_FROM,
  NEIGHBORHOOD_TO,
  CURRENT_WAREHOUSE,
  PICKUP_WAREHOUSE,
  COUNTRY_FROM,
  COUNTRY_TO,
  CITY_FROM,
  CITY_TO,
);

const getFormValue = formValueSelector("PackageRuleConditionsUploadForm");

const isEnabledCityFormField = fp.flow(
  fp.over([
    fp.eq(NEIGHBORHOOD_FROM),
    fp.eq(NEIGHBORHOOD_TO),
    fp.eq(COUNTRY_FROM),
    fp.eq(COUNTRY_TO),
  ]),
  fp.some(Boolean),
);
const isValidationCityIdField = fp.flow(
  fp.over([
    fp.eq(NEIGHBORHOOD_FROM),
    fp.eq(NEIGHBORHOOD_TO),
    fp.eq(COUNTRY_FROM),
    fp.eq(COUNTRY_TO),
    fp.eq(CITY_FROM),
    fp.eq(CITY_TO),
  ]),
  fp.some(Boolean),
);
const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
    marketplaceCountry: getMarketplaceCountry(state),
    field: getFormValue(state, "field"),
    value: getFormValue(state, "value"),
    cityId: getFormValue(state, "cityId"),
  })),
  reduxForm({
    form: "PackageRuleConditionsUploadForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      file: values.file
        ? values.file.error
        : props.i18n.get("please_select_file", "Please Select File"),
      field:
        isBlankString(values.field) &&
        props.i18n.get("please_select_field", "Please Select Field"),
      operator:
        isBlankString(values.operator) &&
        props.i18n.get("please_select_operator", "Please Select Operator"),
      cityId:
        isValidationCityIdField(values.field) &&
        !getValue(values.cityId, "id") &&
        props.i18n.get(
          "please_select_city_country",
          "Please Select City/Country",
        ),
    }),
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream
        .filter(fp.get("field"))
        .distinctUntilKeyChanged("field", isEqualData)
        .do(props => {
          props.change("cityId", null);
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

PackageRuleConditionsUploadForm.propTypes = {
  isDomestic: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  value: PropTypes.array,
  field: PropTypes.string,
  cityId: PropTypes.number,

  onRequestClose: PropTypes.func,
  ruleType: PropTypes.string.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  i18n: PropTypes.instanceOf(Map),
  marketplaceCountry: PropTypes.object,
};

function PackageRuleConditionsUploadForm(props) {
  const { i18n, marketplaceCountry } = props;
  const countryId = marketplaceCountry.get("id");

  return (
    <Dialog modal={true} open={true} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />

      <DialogTitle>
        {i18n.get("import_rule_conditions", "Import Rule Conditions")}
      </DialogTitle>

      <DialogContent style={{ maxWidth: "400px" }}>
        <FlexBox container={16} direction="column">
          <FlexBox gutter={8}>
            <FormSelectField
              name="field"
              style={{ marginBottom: "20px" }}
              fullWidth={true}
              hintText={i18n.get("field", "Field")}
              formatOption={x => props.i18n.get(x.toLowerCase(), formatText(x))}
              validate={createNotFalsyValidator(
                i18n.get("select_field", "Select Field"),
              )}
              options={orderRuleFields}
            />
          </FlexBox>

          {isEnabledCityFormField(props.field) && (
            <FlexBox align="center">
              <FormCityAutoComplete
                name="cityId"
                style={{ marginBottom: "20px" }}
                fullWidth={true}
                hintText={i18n.get("select_city", "Select City")}
                countryId={countryId}
              />
            </FlexBox>
          )}

          {(props.field === CITY_FROM || props.field === CITY_TO) && (
            <FlexBox align="center">
              <FormCountryAutoComplete
                hintText={i18n.get("select_country", "Select Country")}
                name="cityId"
                fullWidth={true}
                style={{ marginBottom: "20px" }}
              />
            </FlexBox>
          )}

          {Boolean(props.field) && (
            <FlexBox align="center">
              <FormSelectField
                fullWidth={true}
                style={{ marginBottom: "20px" }}
                hintText={i18n.get("compare_by", "Compare By")}
                name="operator"
                formatOption={x =>
                  props.i18n.get(x.toLowerCase(), formatText(x))
                }
                options={RuleComparationOperator}
                validate={createNotFalsyValidator(
                  i18n.get("select_comparator", "Select Comparator"),
                )}
              />
            </FlexBox>
          )}

          <FlexBox gutter={8}>
            <FlexBox flex={true}>
              <FormFileField
                name="file"
                fullWidth={true}
                hintText={i18n.get("select_file", "Select File")}
                accept={CSV_FILE_TYPES}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <div>
          {Boolean(props.onRequestClose) && (
            <Button onClick={props.onRequestClose}>
              {i18n.get("dismiss", "Dismiss")}
            </Button>
          )}

          <Button onClick={props.handleSubmit}>
            {i18n.get("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(PackageRuleConditionsUploadForm);
