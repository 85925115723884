import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { types, mapSchema } from "../../shared/helpers/ObjectMapper";

export const toTermsSettingsFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),
    termsType: types.string,
    languageKey: types.string,
  }),
);
