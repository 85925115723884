import React from "react";
import PropTypes from "prop-types";
import DataListFilter from "../../../helpers/DataListFilter";
import { CONTAINER } from "../../../constants/OrderType";
import AdminOutboundOfflineContainerList from "./AdminOutboundOfflineContainerList";

const AdminOfflineOrderOutboundContainerContainerWrapper = props => (
  <AdminOutboundOfflineContainerList
    {...props}
    outBoundSorting={true}
    tab={CONTAINER}
    filter={
      new DataListFilter({
        page: 0,
        size: 200,
      })
    }
  />
);
AdminOfflineOrderOutboundContainerContainerWrapper.propTypes = {
  onTabChange: PropTypes.func,
  tabCount: PropTypes.string,
};

export default AdminOfflineOrderOutboundContainerContainerWrapper;
