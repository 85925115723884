import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import DataList, { DataListColumn } from "../data-list/DataList";
import DriversAttendanceNavigationForm from "../drivers-core/DriversAttendanceNavigationForm";
import { getMessage } from "../../reducers/LocalizationReducer";
import { MONTH_TYPE } from "../../components/form/FormSwitcherDatePicker";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),

  useSheet({
    card: { "& > div": { display: "flex", flex: "1 1 0%" } },
  }),
);

DriverAttendanceReport.propTypes = {
  onChangeMonth: PropTypes.func,
  showTable: PropTypes.bool,
  classes: PropTypes.object,
  reportList: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function DriverAttendanceReport(props) {
  const { classes, getLocalisationMessage } = props;
  const list =
    props.showTable &&
    props.reportList.get("list").map(item =>
      item.update("period", period =>
        period.setIn(
          [
            getLocalisationMessage(
              "total_for_the_month",
              "Total For The Month",
            ),
            "worked_days",
          ],
          period.reduce((r, v) => r + v.get("worked_days"), 0),
        ),
      ),
    );
  const period =
    props.showTable &&
    list.size > 0 &&
    list
      .first()
      .get("period")
      .keySeq()
      .toList();

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true} element={<Card className={classes.card} />}>
            <FlexBox
              gutter={8}
              flex={true}
              direction="column"
              element={<CardContent />}
            >
              <FlexBox>
                <DriversAttendanceNavigationForm
                  isLoading={Boolean(!props.showTable)}
                  rightButtonLabel={getLocalisationMessage(
                    "next_month",
                    "Next Month",
                  )}
                  leftButtonLabel={getLocalisationMessage(
                    "previous_month",
                    "Previous Month",
                  )}
                  navigationType={MONTH_TYPE}
                  initialValues={{
                    date: new Date(),
                  }}
                  onChange={props.onChangeMonth}
                />
              </FlexBox>

              {props.showTable && (
                <FlexBox flex={true}>
                  <DataList
                    isLoading={false}
                    list={list}
                    rowCount={list.size}
                    overscanRowCount={8}
                    rowGetter={options => list.get(options.index)}
                  >
                    {period.size > 0 && (
                      <DataListColumn
                        width={150}
                        label={getLocalisationMessage(
                          "driver_name",
                          "Driver Name",
                        )}
                        dataKey="id"
                        disableSort={true}
                        justifyContent="center"
                        cellRenderer={row =>
                          row.cellData.getIn(["driver", "name"])
                        }
                      />
                    )}

                    {period.map(item => (
                      <DataListColumn
                        key={item}
                        width={94}
                        // label={formatDate(item, FULL_DATE_URL_FORMAT)}
                        label={item}
                        dataKey="worked"
                        disableSort={true}
                        justifyContent="center"
                        cellRenderer={row =>
                          row.cellData.getIn(["period", item, "worked_days"])
                        }
                      />
                    ))}
                  </DataList>
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverAttendanceReport);
