import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const CREATE_OPERATOR_URL = `${API_ROOT_URL}/admin/driver`;
const OPERATOR_ITEM_URL = `${API_ROOT_URL}/admin/driver/%s`;
const OPERATOR_LIST_URL = `${API_ROOT_URL}/admin/drivers`;
const OPERATORS_LOCATION_URL = `${API_ROOT_URL}/admin/drivers/locations`;
const OPERATOR_CHOOSE_URL = `${API_ROOT_URL}/admin/driver/choose`;
const OPERATOR_CHECK_IN_NOTE_URL = `${API_ROOT_URL}/driver/check_in/note`;
const OPERATOR_CHECK_IN_UPDATE_URL = `${API_ROOT_URL}/driver/check_in/update`;
const OPERATOR_LOCATION_ITEM_URL = `${API_ROOT_URL}/admin/driver/%s/locations`;
const OPERATOR_MARKETPLACE_URL = `${API_ROOT_URL}/admin/driver/%s/marketplaces`;
const OPERATOR_ORDERS_LOCATION_URL = `${API_ROOT_URL}/admin/driver/%s/order/locations`;
const UPDATE_BOUNDARY_URL = `${API_ROOT_URL}/radar/boundary/`;
const CREATE_OPERATOR_FINANCE_URL = `${API_ROOT_URL}/admin/driver/finance_settings`;
const GET_OPERATOR_FINANCE_URL = `${API_ROOT_URL}/admin/driver/%s/finance_settings`;
const OPERATOR_LIABILITY_TOTALS_URL = `${API_ROOT_URL}/admin/driver/%s/liability_totals`;
const CASHIER_LIABILITY_TOTALS_OPEN_URL = `${API_ROOT_URL}/cashier/driver/%s/open_liability_totals`;
const OPERATOR_ATTENDANCE_REPORT_URL = `${API_ROOT_URL}/admin/driver/daily_attendance_report`;
const OPERATORS_BATCH_CREATE_IN_KPI_URL = `${API_ROOT_URL}/admin/drivers_batch/create_in_kpi`;
const OPERATORS_DASHBOARD_URL = `${API_ROOT_URL}/dashboard/admin/drivers_dashboard`;
const CALCULATE_LIABILITY_TOTAL_URL = `${API_ROOT_URL}/cashier/driver/total_liability_for_selected`;

// const CASHIER_LIABILITY_TOTALS_URL = `${API_V2_ROOT_URL}/cashier/credits/%s`;
const CASHIER_LIABILITY_TOTALS_URL = `${API_V2_ROOT_URL}/operator/balance/%s`;
const OPERATOR_LIABILITY_URL = `${API_V2_ROOT_URL}/operator/order_balance_list/%s`;
const UPDATE_OPERATOR_CASHIER_PSF_COD_CHARGE = `${API_V2_ROOT_URL}/cashier/orders/change_flow`;
const STATUS_TOTALS_URL = `${API_V2_ROOT_URL}/operator/order_balance_list/count/%s`;
export const REFUND_CREATE_URL = `${API_V2_ROOT_URL}/cashier/credits/create`;
export const REFUND_REVERT_URL = `${API_V2_ROOT_URL}/cashier/credits/revert`;
export const GET_REFUND_LIST_URL = `${API_V2_ROOT_URL}/cashier/credits/%s`;
export const GET_CREDITS_BALANCE_URL = `${API_V2_ROOT_URL}/cashier/credits/total_balance/%s`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: (id) => api.getStream(sprintf(OPERATOR_ITEM_URL, id)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: (request) =>
    api.getStream(OPERATOR_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getOperator = (id) => itemCache.refresh(id);

export const saveMarketplaces = (id, marketplaceIds) =>
  api.post(sprintf(OPERATOR_MARKETPLACE_URL, id), {
    body: {
      marketplace_ids: marketplaceIds,
    },
  });

export const getCachedOperator = (id) =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getOperatorPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const createOperator = (body) => api.post(CREATE_OPERATOR_URL, { body });

export const getOperatorAttendance = (request: DataListFilter) =>
  api.getStream(OPERATOR_ATTENDANCE_REPORT_URL, {
    params: request.getDefinedValues(),
  });

export const updateOperator = (id, body) =>
  api.put(sprintf(OPERATOR_ITEM_URL, id), { body });

export const getOperatorList = (request) =>
  api.getStream(OPERATOR_LIST_URL, { params: request.getDefinedValues() });

export const getOperatorsDashboard = (request) =>
  api.getStream(OPERATORS_DASHBOARD_URL, {
    params: request.getDefinedValues(),
  });

export type OperatorCheckInRequest = {
  ids: number[],
  note: string,
  type: string,
};

export const getOperatorLiability = (operatorId, request: DataListFilter) =>
  api.getStream(sprintf(OPERATOR_LIABILITY_URL, operatorId), {
    params: request.getDefinedValues(),
  });

export const getOperatorLiabilityTotals = (operatorId) =>
  api.getStream(sprintf(OPERATOR_LIABILITY_TOTALS_URL, operatorId));

export const getOperatorCreditsBalance = (operatorId) =>
  api.get(sprintf(GET_CREDITS_BALANCE_URL, operatorId));

export const getCashierLiabilityTotals = (
  operatorId,
  request: DataListFilter,
) =>
  api.getStream(sprintf(CASHIER_LIABILITY_TOTALS_URL, operatorId), {
    params: request.getDefinedValues(),
  });

export const getStatusTotal = (operatorId) =>
  api.getStream(sprintf(STATUS_TOTALS_URL, operatorId));

export const getRefundList = (operatorId, request) =>
  api.getStream(sprintf(GET_REFUND_LIST_URL, operatorId), {
    params: request.getDefinedValues(),
  });

export const getCashierOpenLiabilityTotals = (operatorId) =>
  api.getStream(sprintf(CASHIER_LIABILITY_TOTALS_OPEN_URL, operatorId));

// eslint-disable-next-line camelcase
export const updateCashierOrderUpdatePsfCodCharge = (body) =>
  api.post(UPDATE_OPERATOR_CASHIER_PSF_COD_CHARGE, { body });

export const getCalculateLiabilityTotal = (body) =>
  api.post(CALCULATE_LIABILITY_TOTAL_URL, { body });

export const driverCheckInUpdate = (body: OperatorCheckInRequest) =>
  api.put(OPERATOR_CHECK_IN_UPDATE_URL, { body });

export const driverCheckInNote = (body: OperatorCheckInRequest) =>
  api.put(OPERATOR_CHECK_IN_NOTE_URL, { body });

export const getOperatorFinanceSettings = (operatorId) =>
  api.getStream(sprintf(GET_OPERATOR_FINANCE_URL, operatorId));

export type OperatorFinanceSettings = {
  id: number,
  cost: number,
  driver: {
    id: number,
  },
  payment_type: string,
  vehicle_type: string,
  marketplace_id: number,
};

export const updateBoundary = (body) => api.put(UPDATE_BOUNDARY_URL, { body });
const UPDATE_BOUNDARY_URL_BY_ORDER = `${API_ROOT_URL}/radar/boundary/by_order/`;
export const updateBoundaryByOrder = (body) =>
  api.put(UPDATE_BOUNDARY_URL_BY_ORDER, { body });

export const saveOperatorFinanceSettings = (body: OperatorFinanceSettings) =>
  api.post(CREATE_OPERATOR_FINANCE_URL, { body });

export const getOperatorAttendanceReport = () =>
  api.getStream("/api/v1/operational_report/driver_attendance_excel");

export const getOperatorLocationList = (params) =>
  api.getStream(OPERATORS_LOCATION_URL, { params, timeout: Infinity });

export const getOperatorLocationItem = (operatorId, request: DataListFilter) =>
  api.getStream(sprintf(OPERATOR_LOCATION_ITEM_URL, operatorId), {
    params: request.getDefinedValues(),
  });

export const getOperatorOrdersLocation = (operatorId) =>
  api.getStream(sprintf(OPERATOR_ORDERS_LOCATION_URL, operatorId));

export const createInKPI = (driversIds) =>
  api.post(OPERATORS_BATCH_CREATE_IN_KPI_URL, {
    params: { driversIds: driversIds.join(",") },
  });
