import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(
  useSheet({
    paper: {
      color: "#bbb",
      flex: "1 1 0%",
      display: "flex",
      height: "200px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      fontSize: 24,
      fontWeight: "bold",
    },
    enabled: {
      color: "#2E3359",
      cursor: "pointer",
      "&:hover": {
        fontWeight: "bold",
      },
    },
  }),
);

InternationalSettingsItem.propTypes = {
  title: PropTypes.node,
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

function InternationalSettingsItem(props) {
  const { classes } = props;

  return (
    <FlexBox flex={true}>
      {props.title && (
        <Paper
          className={cx(classes.paper, {
            [classes.enabled]: Boolean(props.onClick),
          })}
          onClick={props.onClick || fp.noop}
        >
          {props.title}
        </Paper>
      )}
    </FlexBox>
  );
}

export default enhancer(InternationalSettingsItem);
