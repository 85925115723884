import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "./NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import OperatorLiabiltyStatus from "../../constants/OperatorLiabiltyStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OperatorLiabilityStatusTabs.propTypes = {
  location: PropTypes.object,
  statusKey: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
  statusCounts: PropTypes.instanceOf(Map),
};

OperatorLiabilityStatusTabs.defaultProps = {
  statusKey: "payment_flow",
};

function OperatorLiabilityStatusTabs(props) {
  const { getLocalisationMessage } = props;
  const query = _.omit(props.location.query, ["page"]);
  const value = OperatorLiabiltyStatus.has(query.payment_flow)
    ? query.payment_flow
    : OperatorLiabiltyStatus.first();
  return (
    <NavTabs
      width={300}
      value={value}
      tabs={OperatorLiabiltyStatus.map(type => ({
        to: {
          pathname: props.location.pathname,
          query: { ...query, payment_flow: type },
        },
        value: type,
        label: (
          <TabBadge
            label={getLocalisationMessage(type, formatText(type))}
            badge={props.statusCounts && props.statusCounts[type]}
          />
        ),
      })).toArray()}
    />
  );
}

export default enhancer(OperatorLiabilityStatusTabs);
