import { Observable } from "rxjs";
import _ from "lodash";
// eslint-disable-next-line import/no-internal-modules
import { bufferCount, concatMap, delay, switchMap } from "rxjs/operators";
import React, { useContext } from "react";
import { List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router";
import { Autorenew, FilterList as FilterListIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import {
  loadAllValues,
  mapListResponseStream,
  mapObjectResponseStream,
} from "../../../helpers/ApiUtils";
import { pureComponent } from "../../../helpers/HOCUtils";
import { isEqualData, toJS } from "../../../helpers/DataUtils";
import {
  mergeSideEffectStreams,
  pipeStreams,
} from "../../../helpers/StreamUtils";
import { captureException } from "../../../helpers/ErrorTracker";
import ResponseError from "../../../helpers/ResponseError";
import DataListFilter from "../../../helpers/DataListFilter";
import {
  createOrderSortingBinCreator,
  isExpiredOrderRecord,
  TYPE_BATCH,
  TYPE_SHIPMENT,
} from "../../../helpers/OrderOutboundSortingHelper";
import { OrderSortingDB } from "../../../firebase/OrderSortingDB";
import { OrderSortingDB as RethinkSortingDb } from "../../../realtimeDb/OrderSortingDB";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  addSortingTaskOrderBarcodes,
  addSortingTaskOrderNumbers,
  cacheOrderSortingOrders,
  clearOrderSortingOrders,
  getOrderSortingOrders,
  getOrderSortingTask,
  removeOrderSortingOrders,
  updateSortingTask,
  updateSortingVerifiedOrders,
} from "../../../reducers/OrderOutboundSortingReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  IN_SORTING_FACILITY,
  MISROUTED,
  READY_FOR_DELIVERY,
  READY_FOR_ISSUE,
  RETURNING_TO_ORIGIN,
} from "../../../constants/OrderStatusCodes";
import {
  batchUpdateOrderWarehouse,
  getOrderList,
  getOrderObject,
  updateRecipientPostcode,
} from "../../../api/admin/AdminOrderApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../../api/admin/AdminDriverApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../../api/shared/CountryV2Api";
import AdminOrderFilterWrapper from "../../../wrappers/admin/AdminOrderFilterWrapper";
import FormDialog from "../../../components/form/FormDialog";
import FormWarehouseDialog from "../../../components/form/FormWarehouseDialog";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import Redirect from "../../../components/router/Redirect";
import NavigationPrompt from "../../../components/router/NavigationPrompt";
import FlexBox from "../../../components/ui-core/FlexBox";
import PageLoading from "../../../components/ui-core/PageLoading";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import FirebaseOfflineDialog from "../../../components/firebase/FirebaseOfflineDialog";
import ConfirmDialog from "../../../components/deprecated/ConfirmDialog";
import Notification from "../../../components/notifications/Notification";
import OrderSortingRuleListDialog from "../../../components/order-outbound-sorting/OrderSortingRuleListDialog";
import { updateQuery } from "../../../../shared/helpers/UrlUtils";
import { getTokenUserId } from "../../../../shared/reducers/AuthReducer";
import CustomButton from "../../../components/ui-core/CustomButton";
import {
  getUserWarehouse,
  getUserWarehouseId,
  getUserWarehousesIds,
} from "../../../reducers/ProfileReducer";
import AdminOrderOutboundSortingTableContainer from "./AdminOrderOutboundSortingTableContainer";
import OrderSortingExportRulesDialog from "../../../components/order-outbound-sorting/OrderSortingExportRulesDialog";
import OrderSortingImportRulesDialog from "../../../components/order-outbound-sorting/OrderSortingImportRulesDialog";
import { validateUserWarehouse } from "../../../helpers/OrderSortingHelper";
import { GlobalContext } from "../../shared/ClientApp";
import { fetchMongoToken } from "../../../realtimeDb/MongoDBSDK";

const baseFilter = new DataListFilter({
  size: 200,
  simple: true,
  sorting: true,
  use_solr: true,
  include_dw: true,
  search_type: "order_number",
});

const basePrefetchFilter = baseFilter.setValueMap({
  status: [
    IN_SORTING_FACILITY,
    MISROUTED,
    READY_FOR_DELIVERY,
    RETURNING_TO_ORIGIN,
    READY_FOR_ISSUE,
  ],
});

const getAllOrdersByFilter = loadAllValues(getOrderList);

const enhancer = compose(
  withTheme,
  useSheet({
    counter: { display: "none" },
    mobile: { fontSize: "12px", lineHeight: "20px" },
    sortingStatsHeader: {
      display: "block",
      lineHeight: "10px",
      marginTop: "15px",
    },
    mobileCounter: { display: "initial" },
    cacheServer: {
      display: "block",
      fontSize: "14px",
      paddingTop: "5px",
    },
    "@media (min-width: 998px)": {
      counter: { display: "flex" },
      mobileCounter: { display: "none" },
      cacheServer: {
        fontSize: "18px",
      },
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const task = getOrderSortingTask(state);
      const userWarehouse = toJS(getUserWarehouse(state));
      return {
        task,
        userWarehouse,
        userWarehouseId: getUserWarehouseId(state),
        userWarehousesIds: getUserWarehousesIds(state),
        userId: getTokenUserId(state),
        allOrders: getOrderSortingOrders(state),
        warehouseId: task.getIn(["warehouse", "id"]),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,

      updateSortingTask,

      cacheOrderSortingOrders,
      clearOrderSortingOrders,
      removeOrderSortingOrders,
      addSortingTaskOrderNumbers,
      addSortingTaskOrderBarcodes,
      updateSortingVerifiedOrders,
    },
  ),
  withState("state", "setState", {
    pendingTasks: Set(),
    prefetchPending: false,
    binGenerationPending: false,
    fetchedOrders: 0,
  }),
  withHandlers({
    refreshOrders: props => orderNumbers => {
      const numbers = Set(orderNumbers);

      return getOrderList(baseFilter.setSearch(numbers.join(",")))
        .takeLast(1)
        .let(mapListResponseStream)
        .map(x => x.getIn(["payload", "list"]))
        .switchMap(list => {
          const db = new OrderSortingDB(props.warehouseId);

          const values = {};
          const notLoaded = numbers.asMutable();

          list.forEach(order => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values[`${orderNumber}/number`] = orderNumber;
              values[`${orderNumber}/failed`] = null;
              values[`${orderNumber}/type`] = TYPE_SHIPMENT;
              values[`${orderNumber}/info`] = order.toJS();
              values[`${orderNumber}/hash`] = order.hashCode();
              values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          notLoaded.forEach(orderNumber => {
            values[`${orderNumber}/failed`] = true;
          });

          return db.batchUpdateOrders(values);
        });
    },

    searchOrderObjects: props => orderNumbers => {
      const numbers = Set(orderNumbers);

      return getOrderObject(baseFilter.setValue("barcodes", numbers.join(",")))
        .takeLast(1)
        .let(mapObjectResponseStream)
        .map(x => x.getIn(["payload"]))
        .switchMap(data => {
          const orders = data.get("orders", List());
          const batches = data.get("batches", List());

          const db = new OrderSortingDB(props.warehouseId);

          const values = {};
          const notLoaded = numbers.asMutable();

          orders.forEach(order => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values[`${orderNumber}/number`] = orderNumber;
              values[`${orderNumber}/type`] = TYPE_SHIPMENT;
              values[`${orderNumber}/failed`] = null;
              values[`${orderNumber}/info`] = order.toJS();
              values[`${orderNumber}/hash`] = order.hashCode();
              values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          batches.forEach(order => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values[`${orderNumber}/number`] = orderNumber;
              values[`${orderNumber}/type`] = TYPE_BATCH;
              values[`${orderNumber}/failed`] = null;
              values[`${orderNumber}/info`] = order.toJS();
              values[`${orderNumber}/hash`] = order.hashCode();
              values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          notLoaded.forEach(orderNumber => {
            values[`${orderNumber}/failed`] = true;
          });

          return db.batchUpdateOrders(values);
        });
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .map(
              fp.pick([
                "warehouseId",
                "userWarehouseIds",
                "updateSortingTask",
                "clearOrderSortingOrders",
              ]),
            )
            .filter(
              props =>
                props.warehouseId > 0 && fp.size(props.userWarehouseIds) > 0,
            )
            // .distinctUntilChanged(isEqualData)
            .do(props => {
              if (
                !validateUserWarehouse(
                  props.warehouseId,
                  props.userWarehouseIds,
                )
              ) {
                props.clearOrderSortingOrders();
                props.updateSortingTask(x => x.clear());
              }
            }),

          propsStream
            .take(1)
            .map(fp.pick(["task", "updateSortingTask"]))
            .distinctUntilChanged(isEqualData)
            .do(props => {
              props.updateSortingTask(x =>
                x.update("selectedOrders", () => []),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream);
      },

      /**
       * Step 1 - Load sorting job details from Firebase.
       */
      propsStream => {
        const dbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .map(props => new OrderSortingDB(props.warehouseId));

        const initialState = {
          tasks: Map(),
          orders: Map(),
          binRules: null,
          registryBinRules: null,
          registries: Map(),
        };

        const stateStream = dbStream
          .switchMap((db: OrderSortingDB) =>
            !db.warehouseId
              ? Observable.of(initialState)
              : Observable.combineLatest(
                  db.getTasks(),
                  db.getOrders(),
                  db.getBinRules(),
                  db.getRegistries(),
                  db.getRegistryBinRules(),
                  (tasks, orders, binRules, registries, registryBinRules) => ({
                    tasks,
                    orders,
                    binRules,
                    registries,
                    registryBinRules,
                  }),
                ).startWith(initialState),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          ...state,
        }));
      },
      /**
       * Step 2 - Normalize task values.
       */
      propsStream => {
        const taskStream = propsStream
          .map(fp.pick(["task", "orders"]))
          .distinctUntilChanged(isEqualData)
          .map(props =>
            props.task.withMutations(task => {
              if (props.orders.size > 0) {
                task.update(
                  "activeOrder",
                  x => x || props.orders.last().get("number"),
                );
              }

              task.update("selectedOrders", selected =>
                selected.filter(x => props.orders.has(x)),
              );
            }),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(taskStream, (props, task) => ({
          ...props,
          task,
        }));
      },
      propsStream => {
        const {
          handler: onMigrateRulesRequest,
          stream: onMigrateRulesRequestStream,
        } = createEventHandler();

        const sideEffectStream = propsStream
          .filter(
            props => Boolean(props.binRules) && Boolean(props.registryBinRules),
          )
          .distinctUntilKeyChanged("warehouseId")
          .switchMap(props =>
            onMigrateRulesRequestStream.do(() => {
              fetchMongoToken(props.warehouseId).then(() => {
                const { binRules, registryBinRules } = props;

                const registryBinRulesJs = registryBinRules.toJS() || [];
                const binRulesJs = binRules.toJS() || [];

                const registryBinRulesMap = Object.keys(registryBinRulesJs).map(
                  key => registryBinRulesJs[key],
                );
                const binRulesMap = Object.keys(binRulesJs).map(
                  key => binRulesJs[key],
                );

                const rethinkDb = new RethinkSortingDb();
                rethinkDb.setBinRules(binRulesMap);
                rethinkDb.setRegistryBinRules(registryBinRulesMap);
              });
            }),
          )
          .startWith(null);

        return propsStream.combineLatest(sideEffectStream, props => ({
          ...props,
          onMigrateRulesRequest,
        }));
      },

      /**
       * Step 4 - Handle order task requests.
       *
       * 1. Handle order size change request.
       * 1. Handle failed task retry request.
       */
      propsStream => {
        const {
          handler: onRetryTaskRequest,
          stream: onRetryTaskRequestStream,
        } = createEventHandler();

        const {
          handler: onCancelTaskRequest,
          stream: onCancelTaskRequestStream,
        } = createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderSortingDB(props.warehouseId);

              return mergeSideEffectStreams(
                onRetryTaskRequestStream.mergeMap(taskId =>
                  db.retryTask(taskId),
                ),
                onCancelTaskRequestStream.mergeMap(taskId =>
                  db.removeTask(taskId),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream).map(props => ({
          ...props,
          onRetryTaskRequest,
          onCancelTaskRequest,
        }));
      },
      /**
       * Step 5 - Register side effect workers.
       *
       * 1. Sync removed orders in firebase with reducer.
       * 2. Loads orders without hash.
       * 3. Generates bin names for orders without bin names.
       * 4. Execute order tasks.
       * 5. Prefetch orders by filter and repeat every 20 minutes.
       */
      propsStream => {
        const {
          handler: onOrderSync,
          stream: onOrderSyncStream,
        } = createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderSortingDB(props.warehouseId);

              const addStream = db.getOrderAddStream();
              const changeStream = db.getOrderChangeStream();
              const removeStream = db.getOrderRemoveStream();

              const addOrChangeStream = Observable.merge(
                addStream,
                changeStream,
              );

              return mergeSideEffectStreams(
                removeStream
                  .map(x => x.get("number"))
                  .bufferTime(1000, null, 100)
                  .filter(buffer => buffer.length > 0)
                  .do(orders => {
                    props.removeOrderSortingOrders(Set(orders));
                  }),

                db
                  .getBinRules()
                  .distinctUntilChanged(isEqualData)
                  .switchMap(rules => {
                    const createBin = createOrderSortingBinCreator(rules);

                    return Observable.of(null).expand(() =>
                      addOrChangeStream
                        .filter(
                          x =>
                            x.get("info") &&
                            !x.get("bin") &&
                            !isExpiredOrderRecord(x),
                        )
                        .do(() => {
                          props.setState(fp.set("binGeneration", true));
                        })
                        .bufferTime(1000, null, 100)
                        .filter(buffer => buffer.length > 0)
                        .take(1)
                        .switchMap(orders => {
                          const values = orders.reduce((acc, order) => {
                            const bin = createBin(order.get("info"));
                            acc[`${order.get("number")}/bin`] = fp.get(
                              "bin",
                              bin,
                            );
                            acc[`${order.get("number")}/code`] = fp.get(
                              "code",
                              bin,
                            );

                            return acc;
                          }, {});

                          return db.batchUpdateOrders(values);
                        })
                        .catch(error => {
                          captureException(error);

                          return Observable.of(null);
                        })
                        .do(() => {
                          props.setState(fp.set("binGeneration", false));
                        })
                        .delay(1000),
                    );
                  }),

                Observable.of(null).expand(() =>
                  addOrChangeStream
                    .filter(x => !x.get("failed") && isExpiredOrderRecord(x))
                    .bufferTime(1000, null, 100)
                    .filter(x => x.length > 0)
                    .take(1)
                    .switchMap(buffer =>
                      props.searchOrderObjects(
                        buffer.map(x => x.get("number")),
                      ),
                    )
                    .catch(error => {
                      captureException(error);

                      return Observable.of(null);
                    })
                    .delay(1000),
                ),

                // Observable.of(null).expand(() =>
                //   db
                //     .getCorruptedOrderStream()
                //     .map(fp.get("key"))
                //     .bufferTime(1000, null, 100)
                //     .filter((buffer) => buffer.length > 0)
                //     .take(1)
                //     .switchMap((x) => db.batchRemoveOrders(x))
                //     .catch((error) => {
                //       captureException(error);
                //
                //       return Observable.of(null);
                //     })
                //     .delay(1000),
                // ),

                db.getTaskAddStream().mergeMap(response => {
                  const id = response.key;
                  const payload = response.val();

                  // Remove task if it's corrupted.
                  if (!payload || !payload.task || !payload.number) {
                    return db.removeTask(id);
                  }

                  const { task, number: orderNumber } = payload;

                  return db
                    .getOrder(orderNumber)
                    .take(1)
                    .switchMap(order => {
                      let taskStream;

                      if (task.in_sorting_warehouse) {
                        taskStream = Observable.defer(() =>
                          batchUpdateOrderWarehouse({
                            order_barcodes: [orderNumber],
                            order_status: IN_SORTING_FACILITY,
                            warehouse: { id: task.in_sorting_warehouse },
                          }),
                        );
                      }

                      // Remove task if it's order not found or failed to load.
                      if (order.isEmpty() || order.get("failed")) {
                        return db.removeTask(id);
                      }

                      // Retry to execute task after one second if it's order not loaded.
                      if (!order.getIn(["info", "id"])) {
                        return Observable.timer(1000).switchMap(() =>
                          db.retryTask(id),
                        );
                      }

                      if (task.update_postcode) {
                        taskStream = Observable.defer(() =>
                          updateRecipientPostcode(task.update_postcode),
                        );
                      }

                      // If task found - execute it.
                      // If it's succeed remove it and reload order.
                      // If not update it with error message
                      if (taskStream) {
                        return taskStream
                          .switchMap(() =>
                            Observable.merge(
                              db.removeTask(id),
                              db.reloadOrder(orderNumber),
                            ),
                          )
                          .catch(error =>
                            db.updateTask(id, { error: error.message }),
                          );
                      }

                      // If it's unknown task - remove it.
                      return db.removeTask(id);
                    });
                }, 5),
              );
            }),
          onOrderSyncStream
            .withLatestFrom(propsStream)
            .map(([filter, props]) => ({
              ...props,
              filter,
            }))
            .filter(props => props.warehouseId > 0)
            .map(props => ({
              ...props,
              filter:
                props.filter.size > 0
                  ? props.filter
                  : new DataListFilter({
                      warehouse_ids: props.warehouseId,
                    }).setValueMap(basePrefetchFilter),
            }))
            .map(
              fp.update("filter", x =>
                new DataListFilter(x).setValueMap(baseFilter).setPageSize(200),
              ),
            )
            .switchMap(props => {
              const db = new OrderSortingDB(props.warehouseId);
              props.setState(fp.set("prefetchPending", true));

              return getAllOrdersByFilter(props.filter)
                .map(response => response.get("list"))
                .takeLast(1)
                .do(orders => {
                  props.setState(fp.set("fetchedOrders", orders.size));
                  props.showSuccessMessage(
                    `Получено ${orders.size} отправлений`,
                  );
                })
                .map(orders => {
                  if (orders.size > 0) {
                    return Observable.from(orders)
                      .pipe(
                        bufferCount(200),
                        concatMap(txn => Observable.of(txn).pipe(delay(1000))),
                        switchMap(chunkOrders => {
                          const values = {};
                          chunkOrders.forEach(order => {
                            const orderNumber = fp.trim(order.get("barcode"));
                            values[`${orderNumber}/number`] = orderNumber;
                            values[`${orderNumber}/failed`] = null;
                            values[`${orderNumber}/type`] = TYPE_SHIPMENT;
                            values[`${orderNumber}/info`] = order.toJS();
                            values[`${orderNumber}/hash`] = order.hashCode();
                            values[`${orderNumber}/hash_time`] = Date.now();
                          });

                          if (values) {
                            return db.batchUpdateOrders(values);
                          }

                          return Observable.of({});
                        }),
                      )
                      .subscribe();
                  }
                  return Observable.of({});
                })
                .do(() => {
                  props.setState(fp.set("prefetchPending", false));
                });
            }),
        );

        return propsStream.merge(sideEffectsStream).map(props => ({
          ...props,
          onOrderSync,
        }));
      },
      /**
       * Step 6 - Generate order stats.
       *
       * 1. Count failed orders.
       */
      propsStream => {
        const statsStream = propsStream
          .map(props => ({ orders: props.orders }))
          .distinctUntilChanged(isEqualData)
          .map(({ orders }) => {
            const failedOrders = Set().asMutable();

            orders.forEach((order, orderNumber) => {
              if (!order.hasIn(["info", "id"]) && order.get("failed")) {
                failedOrders.add(orderNumber);
              }
            });

            return Map({ failedOrders: failedOrders.size });
          })
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(statsStream, (props, stats) => ({
          ...props,
          stats,
        }));
      },
      /**
       * Step 7 - Collect order task stats.
       */
      propsStream => {
        const taskStatsStream = propsStream
          .distinctUntilKeyChanged("tasks", isEqualData)
          .map(props => {
            const failed = Set().asMutable();
            const pending = Set().asMutable();

            props.tasks.forEach((task, id) => {
              if (task.get("error")) {
                failed.add(task.set("id", id));
              } else {
                pending.add(task.set("id", id));
              }
            });

            return Map({
              failed: failed.asImmutable(),
              pending: pending.asImmutable(),
            });
          });

        return propsStream.combineLatest(
          taskStatsStream,
          (props, taskStats) => ({ ...props, taskStats }),
        );
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "userWarehousesIds",
      "warehouseId",
      "location",
      "state",
      "task",
      "stats",
      "orders",
      "registries",
      "binRules",
      "allOrders",
      "taskStats",
    ]),
  ),
);

AdminOrderOutboundSortingContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,

  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,

  userWarehousesIds: PropTypes.array,
  state: PropTypes.object,
  binGeneration: PropTypes.bool,
  warehouseId: PropTypes.number,
  userWarehouseId: PropTypes.number,
  task: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,

  orders: PropTypes.instanceOf(Map),
  registries: PropTypes.instanceOf(Map),
  binRules: PropTypes.instanceOf(Map),
  taskStats: PropTypes.instanceOf(Map),
  userWarehouse: PropTypes.object,
  onOrderSync: PropTypes.func,
  updateSortingTask: PropTypes.func,
  clearOrderSortingOrders: PropTypes.func,
  onMigrateRulesRequest: PropTypes.func,
  theme: PropTypes.object,
  updateSortingVerifiedOrders: PropTypes.func,
};

function AdminOrderOutboundSortingContainer(props) {
  const { setUW } = useContext(GlobalContext);
  const {
    task,
    state,
    orders,
    taskStats,
    getLocalisationMessage,
    location: { query },
  } = props;

  if (!validateUserWarehouse(props.warehouseId, props.userWarehousesIds)) {
    return (
      <FormWarehouseDialog
        open={true}
        isDisabled={!fp.size(props.userWarehousesIds) > 1}
        initialValues={{ warehouse: props.userWarehouse }}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        includeWarehouses={props.userWarehousesIds}
        onSubmit={values => {
          setUW(_.get(values, "warehouse.id"));
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  const db = new OrderSortingDB(props.warehouseId);

  const isLoading = !props.binRules;

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage(
        "order_sorting_firebase",
        "Order Sorting",
      )} | ${task.getIn(["warehouse", "name"])}`}
      appBarRightAction={
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          className={props.classes.appBarRightAction}
        >
          {false && Boolean(props.orders.size) && (
            <Button
              startIcon={<Autorenew />}
              color="secondary"
              variant="contained"
              style={{ marginRight: 10, color: "#fff" }}
            >
              Автосортировка
            </Button>
          )}

          <ButtonGroup variant="contained" color="secondary">
            <CustomButton
              disabled={state.prefetchPending || state.binGeneration}
              onClick={() => {
                db.clearOrders()
                  .toPromise()
                  .then(props.onOrderSync(task.get("filter")));
              }}
            >
              {state.prefetchPending || state.binGeneration ? (
                <FlexBox>
                  {state.prefetchPending && <div>Сбор данных...{"  "}</div>}
                  <CircularProgress size={20} color="secondary" />
                </FlexBox>
              ) : (
                getLocalisationMessage("sync_orders", "Sync Orders")
              )}
            </CustomButton>
            <CustomButton
              size="small"
              onClick={() => props.setLocationQuery(fp.set("prefetch", true))}
            >
              <FilterListIcon />
            </CustomButton>
          </ButtonGroup>

          <MenuButtonMore color={props.theme.palette.appBarTextColor}>
            {Boolean(props.binRules) && (
              <div>
                <MenuItem
                  onClick={() =>
                    props.setLocationQuery(fp.set("view_rule_list", true))
                  }
                >
                  {getLocalisationMessage("view_rules", "View Rules")}
                </MenuItem>

                <Divider />

                <MenuItem
                  onClick={() => props.setLocationQuery(fp.set("export", true))}
                >
                  {getLocalisationMessage("export_rules", "Export Rules")}
                </MenuItem>
                <MenuItem
                  onClick={() => props.setLocationQuery(fp.set("import", true))}
                >
                  {getLocalisationMessage("import_rules", "Import Rules")}
                </MenuItem>

                <Divider />
              </div>
            )}

            <MenuItem
              onClick={() =>
                props.setLocationQuery(fp.set("view_rule_list", true))
              }
            >
              <Link to="voice-sorting?tab=voice_sorting">
                {getLocalisationMessage("setup_voice")}
              </Link>
            </MenuItem>

            <MenuItem
              onClick={() => {
                props.setLocationQuery(fp.set("migrate_rules", true));
              }}
            >
              <div>{getLocalisationMessage("migrate_bin_rules")} </div>
            </MenuItem>

            {orders.size > 0 && (
              <MenuItem
                onClick={() =>
                  props.setLocationQuery(fp.set("remove_all", true))
                }
              >
                {getLocalisationMessage("remove_orders", "Remove Orders")}
              </MenuItem>
            )}

            <Divider />

            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("log_out_warehouse", "Log Out Warehouse")}
            </MenuItem>
          </MenuButtonMore>
        </FlexBox>
      }
    >
      <PageLoading isLoading={isLoading} />

      <FirebaseOfflineDialog />

      <NavigationPrompt
        when={state.pendingTasks.size > 0}
        message={[
          `${getLocalisationMessage("there_are", "There are ")} ${
            state.pendingTasks.size
          } ${getLocalisationMessage("unfinished_tasks", "unfinished tasks")}`,
          getLocalisationMessage(
            "are_you_sure_you_want_to_exit",
            "Are you sure you want to exit?",
          ),
        ].join(" ")}
      />

      {Boolean(
        !isLoading && orders.size === 0 && query.view_table === "true",
      ) && (
        <Redirect
          when={true}
          to={updateQuery(props.location, fp.unset("view_table"))}
        />
      )}

      {Boolean(props.binRules) &&
        (query.view_rule_list === "true" ? (
          <OrderSortingRuleListDialog
            open={query.view_rule_list === "true"}
            onRequestClose={() =>
              props.setLocationQuery(fp.unset("view_rule_list"))
            }
            initialValues={{ rules: props.binRules }}
            onSubmit={values =>
              db
                .setBinRules(values.rules.toJS())
                .toPromise()
                .catch(ResponseError.throw)
            }
            onSubmitSuccess={() =>
              props.setLocationQuery(fp.unset("view_rule_list"))
            }
            onSubmitFail={props.showErrorMessage}
            getCachedSupplier={getCachedSupplier}
            getSupplierPredictions={getSupplierPredictions}
            getCachedWarehouse={getCachedWarehouse}
            getWarehousePredictions={getWarehousePredictions}
            getCachedPostcode={getCachedPostcode}
            getPostcodePredictions={getPostcodePredictions}
          />
        ) : (
          props.binRules.isEmpty() && (
            <ConfirmDialog
              open={true}
              confirmButtonLabel={getLocalisationMessage(
                "add_rules",
                "Add Rules",
              )}
              onConfirm={() =>
                props.setLocationQuery(fp.set("view_rule_list", true))
              }
            >
              {getLocalisationMessage(
                "there_are_no_bin_sorting_rules",
                "There Are No Bin Sorting Rules",
              )}
            </ConfirmDialog>
          )
        ))}

      {query.migrate_rules === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("migrate_rules"))
          }
          onSubmit={() =>
            new Promise(res => {
              props.onMigrateRulesRequest();
              res();
            })
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("migrate_rules"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage(
            "are_you_sure_to_move_bin_rules",
            "Are you sure to move rules?",
          )}
        </FormDialog>
      )}

      {query.remove_all === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
          onSubmit={() => {
            props.clearOrderSortingOrders();
            props.updateSortingVerifiedOrders(x => x.clear());

            return db
              .clearOrders()
              .toPromise()
              .catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => props.setLocationQuery(fp.unset("remove_all"))}
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_all_orders_from_queue",
            "Are you sure you want to remove all orders from queue?",
          )}
        </FormDialog>
      )}

      {query.log_out === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingOrders();
            props.updateSortingTask(x => x.clear());
            props.updateSortingVerifiedOrders(x => x.clear());
            props.setLocationQuery(fp.unset("log_out"));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      {query.prefetch === "true" && (
        <AdminOrderFilterWrapper
          open={true}
          showSearch={true}
          filter={basePrefetchFilter
            .setValueMap(task.get("filter"))
            .setValueMap({
              warehouse_ids: props.userWarehouseId,
            })}
          onFilterChange={filter => {
            props.setLocationQuery(fp.unset("prefetch"));
            props.updateSortingTask(x =>
              x.set("filter", Map(filter.getDefinedValues())),
            );
          }}
          onRequestClose={() => props.setLocationQuery(fp.unset("prefetch"))}
        />
      )}

      <Notification
        uid="pending_count"
        open={taskStats.get("pending").size > 0}
      >
        {`${taskStats.get("pending").size} ${getLocalisationMessage(
          "pending_tasks",
          "Pending Tasks",
        )}`}
      </Notification>

      {props.binRules && query.export === "true" && (
        <OrderSortingExportRulesDialog
          open={true}
          rules={props.binRules}
          onRequestClose={() => props.setLocationQuery(fp.unset("export"))}
        />
      )}

      {query.import === "true" && (
        <OrderSortingImportRulesDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("import"))}
          onSubmit={values => {
            const { data } = values;

            return db
              .setBinRules(data.bin_rules)
              .toPromise()
              .catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage("rules_imported", "Rules Imported"),
            );
            props.setLocationQuery(fp.unset("import"));
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      <AdminOrderOutboundSortingTableContainer
        task={task}
        orders={props.orders}
        registries={props.registries}
        location={props.location}
        warehouseId={props.warehouseId}
        setLocationQuery={props.setLocationQuery}
        setLocation={props.setLocation}
        showErrorMessage={props.showErrorMessage}
        showSuccessMessage={props.showSuccessMessage}
        updateSortingTask={props.updateSortingTask}
        binRules={props.binRules}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderOutboundSortingContainer);
