import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toDataListFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    neighborhoodIds: queryTypes.IDSeq,
    excludeNeighborhoodIds: queryTypes.IDSeq,

    supplierIds: queryTypes.IDSeq,
    excludeSupplierIds: queryTypes.IDSeq,

    use_solr: types.boolean,

    courierTypes: queryTypes.stringSeq,
    timeslotTypes: queryTypes.stringSeq,
  }),
);
