import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter, {
  getListFilterSchema,
} from "../../helpers/DataListFilter";
import { getMessage, getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SAMPLE_ITEMS_CREATE_URL_WITH_SETTINGS } from "../../constants/AdminPathConstants";
import {
  getSampleItemsSettings,
  deleteSampleItemSettings,
} from "../../api/shared/SampleItemsApi";
import AdminSampleItemsFilterWrapper from "../../wrappers/admin/AdminSampleItemsFilterWrapper";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import SampleItemsList from "../../components/sample-items-core/SampleItemsList";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { types, mapSchema } from "../../../shared/helpers/ObjectMapper";

const SAMPLE_ITEMS_FILTER_DIALOG_HASH = "#SIFDH";
export const toPackageRuleFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    languageCode: types.string,
  }),
);
const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .map(fp.flow(fp.get("location.query"), toPackageRuleFilter))
            .distinctUntilChanged(isEqualData),
          (props, filter) => ({ ...props, filter }),
        ),
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .pluck("filter")
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getSampleItemsSettings(filter)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  error: response.get("error", null),
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          listResponseStream,
          (props, response) => ({
            ...props,
            onRequestRefresh,
            list: response.get("list"),
            total: response.get("total"),
            isFetching: response.get("pending"),
          }),
        );
      },
    ),
  ),
);

AdminSampleItemsSettingsListContainer.propTypes = {
  location: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,

  total: PropTypes.number,
  isFetching: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),

  i18n: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSampleItemsSettingsListContainer(props) {
  const { location, getLocalisationMessage } = props;
  const removeId = fp.toFinite(props.location.query.remove);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("sample_items", "Sample Items")}
    >
      <FormDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset("remove"))}
        onSubmit={() =>
          deleteSampleItemSettings(removeId).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("remove"));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_sample_item",
          "Are you sure you want to remove this Sample Item?",
        )}
      </FormDialog>

      <AdminSampleItemsFilterWrapper
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
        open={location.hash === SAMPLE_ITEMS_FILTER_DIALOG_HASH}
      />
      <SampleItemsList
        sampleItemsForSettings={true}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        location={props.location}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <div>
            <Tooltip
              title={getLocalisationMessage(
                "create_sample_item",
                "Create Sample Item",
              )}
            >
              <Link to={updateQuery(SAMPLE_ITEMS_CREATE_URL_WITH_SETTINGS)}>
                <IconButton>
                  <Add />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip
              title={getLocalisationMessage(
                "sample_item_filter",
                "Sample Item Filter",
              )}
            >
              <Link to={updateHash(location, SAMPLE_ITEMS_FILTER_DIALOG_HASH)}>
                <IconButton>
                  <FilterList />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSampleItemsSettingsListContainer);
