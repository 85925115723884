import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormFileField from "../form/FormFileField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { createFileValidator } from "../../helpers/FormUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({ form: "UploadWithdrawableReferenceTemplate" }),
);

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

UploadWithdrawableReferenceTemplate.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function UploadWithdrawableReferenceTemplate(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog
      modal={true}
      open={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogContent style={{ minWidth: 400 }}>
        <form autoComplete="off">
          <FormFileField
            name="file"
            fullWidth={true}
            accept={CSV_FILE_TYPES}
            label={getLocalisationMessage("select_file", "Select File")}
            validate={createFileValidator(
              getLocalisationMessage(
                "please_select_file",
                "Please Select File",
              ),
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {" "}
          {getLocalisationMessage("dismiss", "Dismiss")}{" "}
        </Button>
        <Button onClick={props.handleSubmit}>
          {" "}
          {getLocalisationMessage("upload", "Upload")}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(UploadWithdrawableReferenceTemplate);
