import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getTimeSlot, updateTimeSlot } from "../../api/admin/AdminTimeSlotApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import TimeSlotForm from "../../components/time-slots-core/TimeSlotForm";

const enhancer = compose(
  renderIf(props => props.timeslotId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const supplierResponseStream = propsStream
      .distinctUntilKeyChanged("timeslotId")
      .switchMap(props =>
        getTimeSlot(props.timeslotId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(supplierResponseStream, (props, supplierResponse) => ({
        ...props,
        onRequestRefresh,
        timeslot: supplierResponse.get("payload"),
        isLoading: supplierResponse.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminTimeSlotEditDialogWrapper.propTypes = {
  timeslot: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  rightIcon: PropTypes.node,

  timeslotId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function AdminTimeSlotEditDialogWrapper(props) {
  const initialValues = {
    id: props.timeslot.get("id"),
    sortOrder: props.timeslot.get("sort_order"),
    name: props.timeslot.get("name"),
    description: props.timeslot.get("description"),
    startTime: new Date(`1970-01-01 ${props.timeslot.get("start_time")}`),
    endTime: new Date(`1970-01-01 ${props.timeslot.get("end_time")}`),
    type: props.timeslot.get("type", "").replace(" ", "_"),
  };

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={`${props.getLocalisationMessage("timeslot", "Timeslot")} #${
        props.timeslotId
      }`}
    >
      <FlexBox container={8} direction="column">
        <TimeSlotForm
          onDismiss={props.onRequestClose}
          initialValues={initialValues}
          onSubmit={values =>
            updateTimeSlot(toSnakeCase(values)).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_updated",
                "Successfully Updated",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminTimeSlotEditDialogWrapper);
