import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import AppTitle from "../../components/deprecated/AppTitle";
import { formatDateTimeToUrl, safeParseDate } from "../../helpers/FormatUtils";
import { endOfToday, startOfToday, subDays, format } from "date-fns";
import { getValue } from "../../helpers/DataUtils";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import DriverDashboardFilterForm from "../../components/dashboard-core/DriverDashboardFilterForm";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { getDriverReports } from "../../api/admin/AdminDriverReportsApi";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";

const useStyles = makeStyles({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    "&>tr": {
      border: "2.5px solid #dddddd",
      textAlign: "center",
      padding: 16,
      fontSize: 20,
    },
    "&>tr>td": {
      border: "2.5px solid #dddddd",
      textAlign: "center",
      padding: 16,
      fontSize: 20,
    },
  },
});

const uzbekMonthsLatin = [
  "yanvar",
  "fevral",
  "mart",
  "aprel",
  "may",
  "iyun",
  "iyul",
  "avgust",
  "sentabr",
  "oktabr",
  "noyabr",
  "dekabr",
];
function formatUzbekLatin(date) {
  const day = date.getDate();
  const month = uzbekMonthsLatin[date.getMonth()];
  return `${day}-${month}`;
}

const localisationDateAndTitle = (currentLanguage, date) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Осталось с ${new Intl.DateTimeFormat("ru-RU", {
        day: "numeric",
        month: "long",
      }).format(subDays(new Date(date), 1))}`;

    case UZBEK_LANGUAGE:
      return `${formatUzbekLatin(subDays(new Date(date), 1))}dan qoldi`;

    default:
      return `Pending from ${new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
      }).format(subDays(new Date(date), 1))}`;
  }
};

const localisationDateAndTitleSecond = (currentLanguage, date) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Отчет за ${format(safeParseDate(date), "dd/MM/yy")}`;

    case UZBEK_LANGUAGE:
      return ` ${format(safeParseDate(date), "dd/MM/yy")} uchun hisobot`;

    default:
      return `Report for ${format(safeParseDate(date), "dd/MM/yy")}`;
  }
};

const enhancer = compose(
  connect(
    state => ({
      warehouseId: getUserWarehouseId(state),
      currentLanguage: getCurrentLanguage(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
);

AdminDriverDashboardContainer.propTypes = {
  getLocalisationMessage: PropTypes.func,
  warehouseId: PropTypes.string,
  currentLanguage: PropTypes.string,
};

function AdminDriverDashboardContainer(props) {
  const { getLocalisationMessage, warehouseId, currentLanguage } = props;

  const date = startOfToday();
  const date2 = endOfToday();

  const [data, setData] = useState({});

  const classes = useStyles();

  const [filter, setFilter] = useState({
    from_date_time: formatDateTimeToUrl(subDays(date, 1)),
    to_date_time: formatDateTimeToUrl(subDays(date2, 1)),
    warehouse: {
      id: warehouseId,
    },
  });

  useEffect(() => {
    getDriverReports({
      from_date_time: filter.from_date_time,
      to_date_time: filter.to_date_time,
      driver_id: getValue(filter, "driver.id", null),
    }).then(r => {
      setData(r.data);
    });
  }, [filter]);

  return (
    <AdminAppLayout
      slug="driver_reports"
      title={getLocalisationMessage("drivers_report", "Driver's Report")}
    >
      <FlexBox style={{ marginTop: 20, gap: 13, width: "100%" }}>
        <FlexBox style={{ width: "100%" }}>
          <AppTitle
            title={getLocalisationMessage("drivers_report", "Driver's Report")}
          >
            <DriverDashboardFilterForm
              initialValues={{
                fromDateTime: safeParseDate(filter.from_date_time),
                toDateTime: safeParseDate(filter.to_date_time),
                warehouse: filter.warehouse,
                driver: filter.driver,
              }}
              onSubmit={v =>
                setFilter({
                  from_date_time: formatDateTimeToUrl(v.fromDateTime),
                  to_date_time: formatDateTimeToUrl(v.toDateTime),
                  warehouse: getValue(v, "warehouse"),
                  driver: getValue(v, "driver"),
                })
              }
            />
          </AppTitle>
        </FlexBox>

        {/* <FlexBox style={{ width: "100%" }}> */}
        {/*  <AppTitle */}
        {/*    title={getLocalisationMessage( */}
        {/*      "warehouse_reports", */}
        {/*      "Warehouse Reports", */}
        {/*    )} */}
        {/*  > */}
        {/*    <DriverDashboardFilterForm */}
        {/*      initialValues={{ */}
        {/*        fromDateTime: safeParseDate(filter.from_date_time), */}
        {/*        toDateTime: safeParseDate(filter.to_date_time), */}
        {/*        warehouse: filter.warehouse, */}
        {/*        driver: filter.driver, */}
        {/*      }} */}
        {/*      onSubmit={v => */}
        {/*        setFilter({ */}
        {/*          from_date_time: formatDateTimeToUrl(v.fromDateTime), */}
        {/*          to_date_time: formatDateTimeToUrl(v.toDateTime), */}
        {/*          warehouse: getValue(v, "warehouse"), */}
        {/*          driver: getValue(v, "driver"), */}
        {/*        }) */}
        {/*      } */}
        {/*    /> */}
        {/*  </AppTitle> */}
        {/* </FlexBox> */}
      </FlexBox>
      <FlexBox style={{ gap: 13, width: "100%" }} flex={true}>
        <Card style={{ width: "100%", height: "99%", marginTop: 20 }}>
          <CardContent>
            <FlexBox
              flex="none"
              direction="column"
              style={{
                padding: "2px 0 2px 20px",
                gap: 16,
              }}
            >
              <FlexBox>
                <Typography
                  style={{
                    fontWeight: 800,
                    fontSize: "22px",
                  }}
                >
                  {localisationDateAndTitleSecond(
                    currentLanguage,
                    getValue(filter, "to_date_time", new Date()),
                  )}
                </Typography>
              </FlexBox>
              <FlexBox justify="space-between">
                <FlexBox
                  style={{ gap: 16, fontSize: "24px", fontWeight: "600" }}
                >
                  <FlexBox style={{ gap: 13 }}>
                    <Typography style={{ fontWeight: 500, fontSize: "18px" }}>
                      {getLocalisationMessage("driver", "Driver")}:
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "18px",
                      }}
                    >
                      {getValue(filter, "driver.name", "")}
                    </Typography>
                  </FlexBox>
                  <span> |</span>
                  <FlexBox style={{ gap: 13 }}>
                    <Typography style={{ fontWeight: 500, fontSize: "18px" }}>
                      {getLocalisationMessage("date", "Date")}:
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "18px",
                      }}
                    >
                      {format(safeParseDate(date), "dd/MM/yy")}
                    </Typography>
                  </FlexBox>
                  <span>|</span>
                </FlexBox>

                <FlexBox style={{ gap: 16 }}>
                  <FlexBox>
                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      style={{ backgroundColor: "#3F51B5" }}
                      type="submit"
                      size="large"
                      fullWidth={true}
                    >
                      {getLocalisationMessage("save_pdf", "Save PDF")}
                    </CustomButton>
                  </FlexBox>

                  <FlexBox>
                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      style={{ backgroundColor: "#3F51B5" }}
                      type="submit"
                      size="large"
                      fullWidth={true}
                    >
                      {getLocalisationMessage("save_excel", "Save Excel")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox>
                <table className={classes.table}>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {localisationDateAndTitle(
                        currentLanguage,
                        getValue(filter, "from_date_time", new Date()),
                      )}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "left_by_from_date", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage(
                        "dispatched_today",
                        "Dispatched today",
                      )}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "dispatched", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage("delivered", "Delivered")}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "delivered", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage("returned", "Returned")}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "returned_to_warehouse", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage("pending", "Pending")}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "left_by_end_date", 0)}
                    </td>
                  </tr>
                </table>
              </FlexBox>
            </FlexBox>
          </CardContent>
        </Card>

        {/* <Card style={{ width: "100%", height: "99%", marginTop: 20 }}> */}
        {/*  <CardContent> */}
        {/*    <FlexBox */}
        {/*      flex="none" */}
        {/*      direction="column" */}
        {/*      style={{ */}
        {/*        padding: "2px 0 2px 20px", */}
        {/*        gap: 16, */}
        {/*      }} */}
        {/*    > */}
        {/*      <FlexBox justify="space-between"> */}
        {/*        <FlexBox */}
        {/*          style={{ gap: 16, fontSize: "24px", fontWeight: "600" }} */}
        {/*        > */}
        {/*          <FlexBox style={{ gap: 13 }}> */}
        {/*            <Typography style={{ fontWeight: 500, fontSize: "18px" }}> */}
        {/*              {getLocalisationMessage("driver", "Driver")}: */}
        {/*            </Typography> */}
        {/*            <Typography */}
        {/*              style={{ */}
        {/*                fontWeight: 400, */}
        {/*                fontSize: "18px", */}
        {/*              }} */}
        {/*            > */}
        {/*              {getValue(filter, "driver.name", "Firdavs Xasanov")} */}
        {/*            </Typography> */}
        {/*          </FlexBox> */}
        {/*          <span> |</span> */}
        {/*          <FlexBox style={{ gap: 13 }}> */}
        {/*            <Typography style={{ fontWeight: 500, fontSize: "18px" }}> */}
        {/*              {getLocalisationMessage("date", "Date")}: */}
        {/*            </Typography> */}
        {/*            <Typography */}
        {/*              style={{ */}
        {/*                fontWeight: 400, */}
        {/*                fontSize: "18px", */}
        {/*              }} */}
        {/*            > */}
        {/*              {formatDateToUrl( */}
        {/*                new Date(getValue(filter, "from_date_time")), */}
        {/*              )} */}
        {/*            </Typography> */}
        {/*          </FlexBox> */}
        {/*          <span>|</span> */}
        {/*        </FlexBox> */}

        {/*        <FlexBox style={{ gap: 16 }}> */}
        {/*          <FlexBox> */}
        {/*            <CustomButton */}
        {/*              variant={CONTAINED} */}
        {/*              color={SECONDARY} */}
        {/*              style={{ backgroundColor: "#3F51B5" }} */}
        {/*              type="submit" */}
        {/*              size="large" */}
        {/*              fullWidth={true} */}
        {/*            > */}
        {/*              {getLocalisationMessage("save_pdf", "Save PDF")} */}
        {/*            </CustomButton> */}
        {/*          </FlexBox> */}

        {/*          <FlexBox> */}
        {/*            <CustomButton */}
        {/*              variant={CONTAINED} */}
        {/*              color={SECONDARY} */}
        {/*              style={{ backgroundColor: "#3F51B5" }} */}
        {/*              type="submit" */}
        {/*              size="large" */}
        {/*              fullWidth={true} */}
        {/*            > */}
        {/*              {getLocalisationMessage("save_excel", "Save Excel")} */}
        {/*            </CustomButton> */}
        {/*          </FlexBox> */}
        {/*        </FlexBox> */}
        {/*      </FlexBox> */}

        {/*      <FlexBox> */}
        {/*        <table className={classes.table}> */}
        {/*          <tr> */}
        {/*            <td */}
        {/*              style={{ */}
        {/*                width: 350, */}
        {/*                fontWeight: 500, */}
        {/*                textAlign: "left", */}
        {/*              }} */}
        {/*            > */}
        {/*              {getLocalisationMessage("left_from_date")} */}
        {/*            </td> */}
        {/*            <td style={{ width: 120 }}>12</td> */}
        {/*          </tr> */}
        {/*          <tr> */}
        {/*            <td */}
        {/*              style={{ */}
        {/*                width: 350, */}
        {/*                fontWeight: 500, */}
        {/*                textAlign: "left", */}
        {/*              }} */}
        {/*            > */}
        {/*              Got shipments */}
        {/*            </td> */}
        {/*            <td style={{ width: 120 }}>23</td> */}
        {/*          </tr> */}
        {/*          <tr> */}
        {/*            <td */}
        {/*              style={{ */}
        {/*                width: 350, */}
        {/*                fontWeight: 500, */}
        {/*                textAlign: "left", */}
        {/*              }} */}
        {/*            > */}
        {/*              Delivered */}
        {/*            </td> */}
        {/*            <td style={{ width: 120 }}>43</td> */}
        {/*          </tr> */}
        {/*          <tr> */}
        {/*            <td */}
        {/*              style={{ */}
        {/*                width: 350, */}
        {/*                fontWeight: 500, */}
        {/*                textAlign: "left", */}
        {/*              }} */}
        {/*            > */}
        {/*              Returned to warehouse */}
        {/*            </td> */}
        {/*            <td style={{ width: 120 }}>34</td> */}
        {/*          </tr> */}
        {/*          <tr> */}
        {/*            <td */}
        {/*              style={{ */}
        {/*                width: 350, */}
        {/*                fontWeight: 500, */}
        {/*                textAlign: "left", */}
        {/*              }} */}
        {/*            > */}
        {/*              Left by 25.11 */}
        {/*            </td> */}
        {/*            <td style={{ width: 120 }}>32</td> */}
        {/*          </tr> */}
        {/*        </table> */}
        {/*      </FlexBox> */}
        {/*    </FlexBox> */}
        {/*  </CardContent> */}
        {/* </Card> */}
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDriverDashboardContainer);
