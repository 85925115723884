import { Observable } from "rxjs";
import React from "react";
import sprintf from "sprintf";
import { List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  SHOP_VENUE_ITEM_URL,
  SHOP_ADDRESS_ITEM_URL,
  SHOP_ADDRESS_LIST_URL,
  SHOP_PRODUCT_LIST_URL,
  SHOP_ADDRESS_CREATE_URL,
  SHOP_VENUE_MENU_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  removeAddress,
  getAddressList,
} from "../../api/admin/AdminShopAddressApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../../components/data-list/DataList";
import RoutingTabs from "../../components/deprecated/RoutingTabs";

const NA = "N/A";

const enhancer = compose(
  connect(null, {
    showErrorMessage,
    showSuccessMessage,
  }),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const venueIdStream = propsStream.map(
          fp.flow(fp.get("params.venueId"), fp.toFinite),
        );

        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          venueIdStream,
          filterStream,
          (props, venueId, filter) => ({
            ...props,
            venueId,
            filter,
          }),
        );
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getAddressList(props.venueId, props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  list: response.getIn(["payload", "data"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminVenueAddressList.propTypes = {
  isListLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  setLocation: PropTypes.func,
  onRowSelect: PropTypes.func,
  selectedIds: PropTypes.instanceOf(OrderedSet),
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  venueId: PropTypes.number,
};

function AdminVenueAddressList(props) {
  const { venueId } = props;

  return (
    <AdminAppLayout title="Addresses">
      <RoutingTabs
        width={300}
        tabs={[
          {
            label: "Venue Details",
            value: SHOP_VENUE_ITEM_URL + venueId,
          },
          {
            label: "Menus",
            value: sprintf(SHOP_VENUE_MENU_LIST_URL, venueId),
          },
          {
            label: "Products",
            value: sprintf(SHOP_PRODUCT_LIST_URL, venueId),
          },
          {
            label: "Addresses",
            value: sprintf(SHOP_ADDRESS_LIST_URL, venueId),
          },
        ]}
        omitQueryParams={["page"]}
      />

      <DataList
        isLoading={props.isLoading}
        selectedRowCount={props.selectedIds.size}
        totalCount={props.list.size}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        altHeader={
          <Button
            onClick={() =>
              removeAddress(
                venueId,
                props.selectedIds.toArray().join(","),
              ).then(props.onRequestRefresh)
            }
          >
            Delete Items
          </Button>
        }
        cardActionIcons={
          <div>
            <IconButton
              tooltip="Create Address"
              onClick={() =>
                props.setLocation(sprintf(SHOP_ADDRESS_CREATE_URL, venueId))
              }
            >
              <Add />
            </IconButton>
          </div>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            props.selectedIds.size > 0 &&
            props.selectedIds.size === props.list.size
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.list.toOrderedSet().map(item => item.get("id"))
                : OrderedSet(),
            )
          }
          rowSelected={row => props.selectedIds.has(row.cellData.get("id"))}
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedIds.add(row.cellData.get("id"))
                : props.selectedIds.delete(row.cellData.get("id")),
            )
          }
        />

        <DataListColumn
          width={96}
          label="Contact Name"
          dataKey="contact_name"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              <Link
                to={sprintf(
                  SHOP_ADDRESS_ITEM_URL,
                  venueId,
                  row.cellData.get("id"),
                )}
              >
                {row.cellData.get("contact_name") || NA}
              </Link>
            </strong>
          )}
        />

        <DataListColumn
          width={96}
          label="City"
          dataKey="city"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              {row.cellData.get("city")
                ? row.cellData.get("city").get("name")
                : NA}
            </div>
          )}
        />

        <DataListColumn
          width={96}
          label="Street"
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("street") || NA}</div>}
        />

        <DataListColumn
          width={96}
          label="Building"
          dataKey="building"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("building") || NA}</div>}
        />

        <DataListColumn
          width={96}
          label="Apartment"
          dataKey="apartment"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("apartment") || NA}</div>}
        />

        <DataListColumn
          width={96}
          label="Mobile"
          dataKey="mobile"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("mobile") || NA}</div>}
        />

        <DataListColumn
          width={96}
          label="Status"
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("status") || NA}</div>}
        />

        <DataListColumn
          width={96}
          label="Note"
          dataKey="note"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("address") || NA}</div>}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminVenueAddressList);
