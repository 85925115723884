import React from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { TransitionUp } from "../../dialog/TransitionUp";
import FlexBox from "../../ui-core/FlexBox";
import { OUTLINED, SECONDARY } from "../../form/FormTextField";
import {
  getCacheRegion,
  getInternationalRegionChoose,
} from "../../../api/admin/AdminInternationalReportsApi";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import FormAutocompleteNew from "../../form/FormInternationalSettingsAutocomplete";
import FormDateField from "../../form/FormDateField";
import { getFormValues, reduxForm } from "redux-form";
import FormSubclassChips from "../../form/FormSubclassChips";
import FormCheckbox from "../../form/FormCheckbox";
import fp from "lodash/fp";
import { stringifyArray } from "../../../helpers/SerializeUtils";
import { getObjectId, idsToObjectArray } from "../../../helpers/FormUtils";
import {
  formatDate,
  formatDateToUrl,
  safeParseDate,
} from "../../../helpers/FormatUtils";
import _ from "lodash";
import FormSelectField from "../../form/FormSelectField";
import {
  localisationAction,
  localisationCategory,
} from "./InternationalParcelForm";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormToggle from "../../form/FormToggle";

const getValues = getFormValues("InternationalParcelFilter");

const actions = ["OUT", "IN"];
const categories = ["AIR", "GROUND", "SAL"];

const cleanupValues = fp.omit(["action", "category", "dateStart", "dateEnd"]);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const { filter: x } = props;

        return {
          hasReturn: x.has_return,
          reference: x.reference,
          hasTransit: x.has_transit,

          action: x.action,
          category: x.category,

          from: { id: x.from || null },
          to: { id: x.from || null },
          transit: { id: x.from || null },

          subclasses: idsToObjectArray(x.subclasses),

          dateStart: safeParseDate(x.date_start),
          dateEnd: safeParseDate(x.date_end),

          formationDateStart: safeParseDate(x.formation_date_start),
          formationDateEnd: safeParseDate(x.formation_date_end),
        };
      })
      .distinctUntilChanged(isEqualData);
    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "InternationalParcelFilter" }),
  connect(state => ({
    values: getValues(state),
  })),
);

const handleValuesToFilter = (values, filter, setFilter) => {
  const tempValues = {};
  tempValues.date_start = formatDateToUrl(values.dateStart);
  tempValues.date_end = formatDateToUrl(values.dateEnd);
  tempValues.formation_date_start = formatDateToUrl(values.formationDateStart);
  tempValues.formation_date_end = formatDateToUrl(values.formationDateEnd);
  tempValues.from = getObjectId(values.from);
  tempValues.to = getObjectId(values.to);
  tempValues.transit = getObjectId(values.transit);
  tempValues.has_return = values.hasReturn;
  tempValues.has_transit = values.hasTransit;
  tempValues.action = values.action;
  tempValues.category = values.category;
  tempValues.reference = values.reference;

  if (!fp.isEmpty(values.subclasses)) {
    tempValues.subclasses = stringifyArray(values.subclasses.map(getObjectId));
  }
  setFilter({
    ..._.omitBy(tempValues, v => v !== 0 && !v),
    page: filter.page || 0,
    size: filter.size,
  });
};

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "400px",
    maxWidth: "500px",
    minHeight: "auto",
  },
  dialogTitle: {
    color: theme.palette.appBarTextColor,
    backgroundColor: theme.palette.primary1Color,
  },
  dialogHeaderTitle: {
    flexGrow: 1,
  },
  dialogHeaderToggleContainer: {
    padding: "10px 5px 10px 20px",
    borderRadius: 1000,
    backgroundColor: "#DBE1E6",
  },
}));

const InternationalParcelFilter = ({
  values,
  getLocalisationMessage,
  filter,
  setFilter,
  onRequestClose,
  open,
  initialValues,
  change,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={onRequestClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FlexBox flex={true} justify="space-between">
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage("filter")}
          </div>

          <FormToggle
            name="reference"
            label={getLocalisationMessage("reference", "Reference")}
            onChange={() => {
              const ignoreValues = cleanupValues(initialValues);
              fp.keys(ignoreValues).forEach(key => {
                change(key, null);
              });
            }}
          />
        </FlexBox>
      </DialogTitle>
      <FlexBox align="center" container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                {!values.reference && (
                  <FlexBox gutter={16} flex={true}>
                    <FlexBox flex={true}>
                      <FormAutocompleteNew
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        getById={getCacheRegion}
                        chooseAPI={getInternationalRegionChoose}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>
                              {getValue(option, "value.code", " ")
                                ? `${option.name}(${getValue(
                                    option,
                                    "value.code",
                                    " ",
                                  )})`
                                : option.name}
                            </span>
                            {getValue(option, "value.parent.id") && (
                              <span
                                style={{
                                  fontSize: ".8rem",
                                  fontStyle: "italic",
                                }}
                              >
                                {getValue(option, "value.parent.code")
                                  ? `${getValue(
                                      option,
                                      "value.parent.name",
                                    )} (${getValue(
                                      option,
                                      "value.parent.code",
                                      " ",
                                    )})`
                                  : getValue(option, "value.parent.name")}
                              </span>
                            )}
                          </FlexBox>
                        )}
                        name="from"
                        fullWidth={true}
                        label={`${getLocalisationMessage("from", "From")}`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormAutocompleteNew
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        getById={getCacheRegion}
                        chooseAPI={getInternationalRegionChoose}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>
                              {getValue(option, "value.code", " ")
                                ? `${option.name}(${getValue(
                                    option,
                                    "value.code",
                                    " ",
                                  )})`
                                : option.name}
                            </span>
                            {getValue(option, "value.parent.id") && (
                              <span
                                style={{
                                  fontSize: ".8rem",
                                  fontStyle: "italic",
                                }}
                              >
                                {getValue(option, "value.parent.code")
                                  ? `${getValue(
                                      option,
                                      "value.parent.name",
                                    )} (${getValue(
                                      option,
                                      "value.parent.code",
                                      " ",
                                    )})`
                                  : getValue(option, "value.parent.name")}
                              </span>
                            )}
                          </FlexBox>
                        )}
                        name="to"
                        fullWidth={true}
                        label={`${getLocalisationMessage("to", "to")}`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormAutocompleteNew
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                        getById={getCacheRegion}
                        chooseAPI={getInternationalRegionChoose}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>
                              {getValue(option, "value.code", " ")
                                ? `${option.name}(${getValue(
                                    option,
                                    "value.code",
                                    " ",
                                  )})`
                                : option.name}
                            </span>
                            {getValue(option, "value.parent.id") && (
                              <span
                                style={{
                                  fontSize: ".8rem",
                                  fontStyle: "italic",
                                }}
                              >
                                {getValue(option, "value.parent.code")
                                  ? `${getValue(
                                      option,
                                      "value.parent.name",
                                    )} (${getValue(
                                      option,
                                      "value.parent.code",
                                      " ",
                                    )})`
                                  : getValue(option, "value.parent.name")}
                              </span>
                            )}
                          </FlexBox>
                        )}
                        name="transit"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "transit",
                          "Transit",
                        )}`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormSubclassChips
                        name="subclasses"
                        label={getLocalisationMessage(
                          "subclasses",
                          "Subclasses",
                        )}
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                      />
                    </FlexBox>
                  </FlexBox>
                )}

                <FlexBox gutter={16} flex={true} align="center">
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="action"
                      fullWidth={true}
                      options={actions}
                      formatOption={option =>
                        localisationAction(option, getLocalisationMessage)
                      }
                      label={`${getLocalisationMessage("action", "Action")}`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="category"
                      fullWidth={true}
                      options={categories}
                      formatOption={option =>
                        localisationCategory(option, getLocalisationMessage)
                      }
                      label={`${getLocalisationMessage(
                        "category",
                        "Category",
                      )}`}
                    />
                  </FlexBox>
                  {!values.reference && (
                    <FlexBox flex={true} align="center" justify="center">
                      <FormCheckbox
                        name="hasReturn"
                        label={getLocalisationMessage(
                          "has_return",
                          "Has Return",
                        )}
                        fullWidth={true}
                      />
                    </FlexBox>
                  )}
                  {!values.reference && (
                    <FlexBox flex={true} align="center" justify="center">
                      <FormCheckbox
                        name="hasTransit"
                        label={getLocalisationMessage(
                          "has_transit",
                          "Has Transit",
                        )}
                        fullWidth={true}
                      />
                    </FlexBox>
                  )}
                </FlexBox>

                <FlexBox gutter={16} flex={true}>
                  <FlexBox flex={true} style={{ gap: 16 }}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        name="dateStart"
                        variant={OUTLINED}
                        formatValue="dd-MM-yyyy"
                        formatDate={date => formatDate(date, "dd-MM-yyyy")}
                        hintText={getLocalisationMessage(
                          "date_start",
                          "Date Start",
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        name="dateEnd"
                        variant={OUTLINED}
                        formatValue="dd-MM-yyyy"
                        formatDate={date => formatDate(date, "dd-MM-yyyy")}
                        hintText={getLocalisationMessage(
                          "date_end",
                          "Date End",
                        )}
                      />
                    </FlexBox>

                    <FlexBox>
                      <IconButton
                        onClick={() => {
                          change("dateStart", null);
                          change("dateEnd", null);
                        }}
                      >
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                  {!values.reference && (
                    <FlexBox flex={true} style={{ gap: 16 }}>
                      <FlexBox flex={true}>
                        <FormDateField
                          fullWidth={true}
                          name="formationDateStart"
                          variant={OUTLINED}
                          formatValue="dd-MM-yyyy"
                          formatDate={date => formatDate(date, "dd-MM-yyyy")}
                          hintText={getLocalisationMessage(
                            "formation_date_start",
                            "Formation Date Start",
                          )}
                        />
                      </FlexBox>
                      <FlexBox flex={true}>
                        <FormDateField
                          fullWidth={true}
                          name="formationDateEnd"
                          variant={OUTLINED}
                          formatValue="dd-MM-yyyy"
                          formatDate={date => formatDate(date, "dd-MM-yyyy")}
                          hintText={getLocalisationMessage(
                            "formation_date_end",
                            "Formation Date End",
                          )}
                        />
                      </FlexBox>

                      <FlexBox>
                        <IconButton
                          onClick={() => {
                            change("formationDateStart", null);
                            change("formationDateEnd", null);
                          }}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  )}
                </FlexBox>
              </CardContent>
              <CardActions>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} justify="flex-end" style={{ gap: 16 }}>
                    <CustomButton
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => {
                        fp.keys(initialValues).forEach(key => {
                          change(key, null);
                        });
                      }}
                    >
                      {getLocalisationMessage("clear", "Clear")}
                    </CustomButton>

                    <CustomButton
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => onRequestClose()}
                    >
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </CustomButton>
                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      onClick={() => {
                        handleValuesToFilter(values, filter, setFilter);
                        onRequestClose();
                      }}
                    >
                      {getLocalisationMessage("submit", "Submit")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>
              </CardActions>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Dialog>
  );
};
InternationalParcelFilter.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  change: PropTypes.func,
};

export default enhancer(InternationalParcelFilter);
