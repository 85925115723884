import sprintf from "sprintf";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SUPPLIER_CONTACTS_LIST_URL = `${API_ROOT_URL}/admin/supplier/contacts`;
const SUPPLIER_CONTACT_CREATE_URL = `${API_ROOT_URL}/admin/supplier/contact`;
const SUPPLIER_CONTACT_ITEM_URL = `${API_ROOT_URL}/admin/supplier/contact/%s`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(SUPPLIER_CONTACT_ITEM_URL, id)),
});

export const getSupplierContact = id => itemCache.refresh(id);

//  Supplier Contacts
export const getSupplierContactsList = request =>
  api.getStream(SUPPLIER_CONTACTS_LIST_URL, {
    params: request.getDefinedValues(),
  });

type CreateSupplierContactRequest = {
  contact_role: string,
  email: string,
  id: number,
  phone_number: string,
  supplier: {
    id: number,
    name: string,
  },
};

export const createSupplierContact = (body: CreateSupplierContactRequest) =>
  api.post(SUPPLIER_CONTACT_CREATE_URL, { body });

export const updateSupplierContact = (body: CreateSupplierContactRequest) =>
  api.post(SUPPLIER_CONTACT_CREATE_URL, { body });
