import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import TariffForm from "../../components/order-create-wizard-new/international-reports/TariffForm";
import {
  getInternationalOutTariffItem,
  updateInternationalOutTariff,
} from "../../api/admin/AdminInternationalReportsApi";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { INTERNATIONAL_OUT_TARIFF_URL } from "../../constants/AdminPathConstants";
import { Card, CardContent, IconButton, Typography } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import { getValue } from "../../helpers/DataUtils";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

function AdminOutboundTariffUpdateContainer(props) {
  const { id } = props.params;
  const [data, setData] = useState({});

  useEffect(() => {
    if (id) {
      getInternationalOutTariffItem(id).then(res => {
        if (res && res.data) {
          setData(res.data);
        }
      });
    }
  }, [id]);

  return (
    <AdminAppLayout
      title={props.i18n.get("create_new_tariff", "Create new tariff")}
    >
      <FlexBox direction="column" style={{ padding: 16, gap: 16 }}>
        <FlexBox
          style={{
            height: "35px",
          }}
          justify="space-between"
          align="center"
        >
          <FlexBox flex={true} style={{ gap: 10 }} align="center">
            <IconButton
              onClick={() => props.setLocation(INTERNATIONAL_OUT_TARIFF_URL)}
            >
              <KeyboardBackspace size="large" />
            </IconButton>
            <Typography
              variant="h5"
              style={{
                fontSize: "24px",
                textTransform: "capitalize",
                fontWeight: 500,
              }}
            >
              {`${getValue(data, "id")} | ${getValue(data, "country.name")}`}
            </Typography>
          </FlexBox>
        </FlexBox>
        <Card style={{ height: "100%" }}>
          <CardContent>
            <TariffForm
              edit={true}
              initialValues={toCamelCase(data)}
              onSubmit={values =>
                updateInternationalOutTariff(id, toSnakeCase(values))
                  .then(res => {
                    if (res && res.status === "success") {
                      props.showSuccessMessage(
                        props.i18n.get("successfully_created"),
                      );
                      props.setLocation(INTERNATIONAL_OUT_TARIFF_URL);
                    }
                  })
                  .catch(error => props.showErrorMessage(error))
              }
            />
          </CardContent>
        </Card>
      </FlexBox>
    </AdminAppLayout>
  );
}

AdminOutboundTariffUpdateContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  params: PropTypes.object,
};

export default enhancer(AdminOutboundTariffUpdateContainer);
