import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isValidDate, isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import FormTextField from "../../../client/components/form/FormTextField";
import FormTimeField from "../../../client/components/form/FormTimeField";
import FormSupplierAutoComplete from "../../../client/components/form/FormSupplierAutoComplete";
import FormTimeSlotAutoComplete from "../../../client/components/form/FormTimeSlotAutoComplete";
import FormPostcodeIndexAutoComplete from "../../../client/components/form/FormPostcodeIndexAutoComplete";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "TimeSlotAvailabilityForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      timeslot: isValidObjectId(values.timeslot)
        ? null
        : props.getLocalisationMessage("select_timeslot", "Select Timeslot"),
      supplier: isValidObjectId(values.supplier)
        ? null
        : props.getLocalisationMessage("select_supplier", "Select Supplier"),
      // neighborhood: isValidObjectId(neighborhood)
      //   ? null
      //   : "Select Neighbourhood",
      courierTypes:
        !values.courierTypes &&
        props.getLocalisationMessage(
          "please_select_type",
          "Please Select Type",
        ),
      cutoffTime: isValidDate(values.cutoffTime)
        ? null
        : props.getLocalisationMessage(
            "select_cutoff_time",
            "Select Cutoff Time",
          ),
    }),
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedPostcode: PropTypes.func,
      getPostcodePredictions: PropTypes.func,
      getCachedTimeSlot: PropTypes.func,
      getTimeSlotPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
      getCachedTimeSlot: props.getCachedTimeSlot,
      getTimeSlotPredictions: props.getTimeSlotPredictions,
    }),
  ),
);

TimeSlotAvailabilityForm.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getSupplierPredictions: PropTypes.func,
  getCachedSupplier: PropTypes.func,
  getPostcodePredictions: PropTypes.func,
  getCachedPostcode: PropTypes.func,
  getCachedTimeSlot: PropTypes.func,
  getTimeSlotPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function TimeSlotAvailabilityForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox gutter={8}>
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FormTimeSlotAutoComplete
                name="timeslot"
                fullWidth={true}
                label={`${getLocalisationMessage("timeslot", "Timeslot")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
              <FormSupplierAutoComplete
                name="supplier"
                fullWidth={true}
                label={`${getLocalisationMessage("supplier", "Supplier")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />

              <FormCourierTypeSelectField
                fullWidth={true}
                name="courierType"
                label={`${getLocalisationMessage(
                  "service_type",
                  "Service Type",
                )} *`}
              />

              <FormPostcodeIndexAutoComplete
                includeUnknownNeighborhood={false}
                name="neighborhood"
                fullWidth={true}
                label={getLocalisationMessage("neighborhood", "Neighborhood")}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FormTimeField
                name="cutoffTime"
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "cutoff_time",
                  "Cutoff Time",
                )} *`}
              />
              <FormTextField
                name="capacity"
                fullWidth={true}
                label={getLocalisationMessage("capacity", "Capacity")}
              />
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>

      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </form>
  );
}

export default enhancer(TimeSlotAvailabilityForm);
