import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

export const MAP_PROVIDER_LIST_URL = `${API_ROOT_URL}/map_geo_provider/list`;
const MAP_PROVIDER_SAVE_URL = `${API_ROOT_URL}/map_geo_provider/save`;
const MAP_PROVIDER_TYPES_URL = `${API_ROOT_URL}/map_geo_provider/types`;
const MAP_PROVIDER_KEYS_TYPES_URL = `${
  API_ROOT_URL
}/map_geo_provider/keys/types`;
const GET_ACTIVE_MAP_PROVIDER = `${
  API_ROOT_URL
}/map_geo_provider/active_map_provider`;
const CHANGE_ACTIVE_MAP_PROVIDER = `${
  API_ROOT_URL
}/map_geo_provider/change_active_provider`;

export const getActiveMapProvider = () =>
  api.getStream(GET_ACTIVE_MAP_PROVIDER);
export const getMapProviderList = request =>
  api.get(MAP_PROVIDER_LIST_URL, { params: request.getDefinedValues() });
export const getMapProviderTypes = params =>
  api.getStream(MAP_PROVIDER_TYPES_URL, { params });
export const getMapProviderKeysTypes = params =>
  api.getStream(MAP_PROVIDER_KEYS_TYPES_URL, { params });
export const saveMapProvider = body =>
  api.post(MAP_PROVIDER_SAVE_URL, { body });
export const changeActiveMapProvider = providerType =>
  api.post(CHANGE_ACTIVE_MAP_PROVIDER, {
    body: { map_geo_provider_type: providerType },
  });
