import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  List,
  Paper,
  ListItem,
  Button,
  CardActions,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Warning as AlertWarning } from "@material-ui/icons";
import { amber, orange } from "@material-ui/core/colors";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ORDER_RULE_LIST_URL,
  PACKAGE_RULE_LIST_URL,
  POSTCODES_LIST_URL,
} from "../../constants/AdminPathConstants";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      maxWidth: "500px",
      minWidth: "500px",
      minHeight: "188px",
      backgroundColor: "white",
    },
    listStyle: {
      width: "100%",
    },
    error: {
      width: "100%",
      padding: "10px",
      " & > div": {
        border: `1px solid ${orange[500]}`,
        background: amber[300],
        padding: "10px",
        paddingLeft: "40px",
        borderRadius: "3px",
        position: "relative",
        lineHeight: "1.2",
      },
    },
    warningIcon: {
      position: "absolute",
      top: "10px",
      left: "10px",
    },
  }),
);

function MapAreasImpactsDialogWrapper({ classes, impacts, ...props }) {
  return (
    <ModalPaper
      open={true}
      isLoading={false}
      title={props.getLocalisationMessage("impacts", "Impacts")}
      onRequestClose={fp.noop}
      paperClassName={classes.paper}
    >
      <div className={classes.error}>
        <div>
          <AlertWarning className={classes.warningIcon} />
          {props.getLocalisationMessage(
            "please_note_that_deleting_selected_area_may_cause_following_impacts",
            "Please note that deleting selected area may cause following impacts",
          )}
          {": "}
        </div>
      </div>
      <List>
        {impacts.get("has_neighborhood_impacts") && (
          <ListItem
            className={classes.listStyle}
            target="_blank"
            component="a"
            href={updateQuery(POSTCODES_LIST_URL)}
          >
            <ListItemText
              primary={props.getLocalisationMessage(
                "neighborhood_impacts",
                "Neighborhood Impacts",
              )}
              secondary={props.getLocalisationMessage(
                "neighborhoods_related_to_deleted_area_will_no_longer_be_available_for_further_use",
                "Neighborhoods related to deleted Area will no longer be available for further use",
              )}
              secondaryLines={2}
            />
          </ListItem>
        )}
        {impacts.get("has_allocation_rule_impacts") && (
          <ListItem
            className={classes.listStyle}
            target="_blank"
            component="a"
            href={updateQuery(ORDER_RULE_LIST_URL)}
          >
            <ListItemText
              primary={props.getLocalisationMessage(
                "allocation_rule_impacts",
                "Allocation Rule Impacts",
              )}
              secondary={props.getLocalisationMessage(
                "allocation_rules_related_to_deleted_area_will_stop_functioning",
                "Allocation Rules related to deleted Area will stop functioning",
              )}
            />
          </ListItem>
        )}
        {impacts.get("has_package_rule_impacts") && (
          <ListItem
            className={classes.listStyle}
            target="_blank"
            component="a"
            href={updateQuery(PACKAGE_RULE_LIST_URL)}
          >
            <ListItemText
              primary={props.getLocalisationMessage(
                "package_rule_impacts",
                "Package Rule Impacts",
              )}
              secondary={props.getLocalisationMessage(
                "package_rules_related_to_deleted_area_will_stop_functioning",
                "Package Rules related to deleted Area will stop functioning",
              )}
            />
          </ListItem>
        )}
      </List>
      <FlexBox direction="column">
        <Paper>
          <CardActions>
            <FlexBox justify="flex-end">
              <Button onClick={props.onRequestClose}>
                {" "}
                {props.getLocalisationMessage("dismiss", "Dismiss")}{" "}
              </Button>
              <Button onClick={props.onConfirm}>
                {" "}
                {props.getLocalisationMessage("confirm", "Confirm")}{" "}
              </Button>
            </FlexBox>
          </CardActions>
        </Paper>
      </FlexBox>
    </ModalPaper>
  );
}

MapAreasImpactsDialogWrapper.propTypes = {
  classes: PropTypes.object,
  onRequestClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  impacts: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

export default enhancer(MapAreasImpactsDialogWrapper);
