import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";
import sprintf from "sprintf";

const ORDER_CUSTOMS_CHARGE_URL = `${API_ROOT_URL}/admin/order/customs_charge_item`;
const ORDER_CUSTOMS_CHARGE_DELETE_URL = `${API_ROOT_URL}/admin/order/%s/customs_charge_item`;
const ORDER_CUSTOMS_ORDER_LIST_URL = `${API_ROOT_URL}/admin/customs_orders`;

export const createCustomsChargeItem = body =>
  api.post(ORDER_CUSTOMS_CHARGE_URL, { body });

export const updateCustomsChargeItem = body =>
  api.put(ORDER_CUSTOMS_CHARGE_URL, { body });

export const getCustomsOrderList = params =>
  api.get(ORDER_CUSTOMS_ORDER_LIST_URL, {
    params,
  });

export const deleteCustomsChargeItem = id =>
  api.delete(sprintf(ORDER_CUSTOMS_CHARGE_DELETE_URL, id));
