import { OrderedSet } from "immutable";
import { formatText } from "../helpers/FormatUtils";

export const COMPLETED = "COMPLETED";
export const RESENT = "RESENT";
export const REPLIED = "REPLIED";
export const SENT = "SENT";

export const formatActStatus = (statusCode, getLocalisationMessage) => {
  let localizationKey = statusCode;
  switch (statusCode) {
    case COMPLETED:
      localizationKey = "act_completed";
      break;
    case RESENT:
      localizationKey = "resent";
      break;
    case REPLIED:
      localizationKey = "replied";
      break;
    case SENT:
      localizationKey = "sent";
      break;
    default:
  }

  return (
    (getLocalisationMessage && getLocalisationMessage(localizationKey)) ||
    formatText(localizationKey)
  );
};

export const colorActStatus = statusCode => {
  switch (statusCode) {
    case COMPLETED:
      return "statusError";
    case RESENT:
      return "statusWarning";
    case REPLIED:
      return "statusInfo";
    default:
      return "statusSuccess";
  }
};

export default OrderedSet.of(SENT, REPLIED, RESENT, COMPLETED);
