import { Observable } from "rxjs";
import React from "react";
import { isDate, isValid, isFuture } from "date-fns";
import Immutable, { Map, fromJS, Iterable, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import PriceWrapper from "../ui-core/PriceWrapper";
import { mapArrayResponseStream } from "../../helpers/ApiUtils";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { formatCODStatusCodeLocalised } from "../../helpers/OrderHelper";
import { validateReferenceForLocalisation } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_WITH_WING,
  COD_TRANSFERRED,
  COD_WITH_SAAS_BANK,
  COD_WITH_WING_BANK,
  COD_PAID_TO_MERCHANT,
  COD_WITHDRAW_REQUESTED,
} from "../../constants/CODStatus";
import CODTransferType from "../../constants/CODTransferType";
import useSheet from "react-jss";

const codStatusOptions = OrderedSet.of(
  COD_COLLECTED,
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_WITH_SAAS_BANK,
  COD_PAID_TO_MERCHANT,
);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  withTheme,
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  renderIf("open"),
  reduxForm({
    form: "BatchUpdateCODStatusDialog",
    validate: (values, props) => ({
      status:
        !values.status &&
        props.getLocalisationMessage("select_cod_status", "Select COD Status"),
      bankReference:
        Boolean(
          values.status === COD_WITH_WING_BANK ||
            values.status === COD_PAID_TO_MERCHANT,
        ) &&
        validateReferenceForLocalisation(
          values.bankReference,
          props.getLocalisationMessage,
        ),
      bankPaymentDate:
        Boolean(
          Boolean(
            values.status === COD_WITH_WING_BANK ||
              values.status === COD_PAID_TO_MERCHANT,
          ) &&
            Boolean(
              !isDate(values.bankPaymentDate) ||
                !isValid(values.bankPaymentDate) ||
                isFuture(values.bankPaymentDate),
            ),
        ) &&
        props.getLocalisationMessage(
          "add_bank_payment_date_properly",
          "Add Bank Payment Date Properly ",
        ),
      orderNumbers:
        fp.isEmpty(values.orderNumbers) &&
        props.getLocalisationMessage("add_orders", "Add Orders"),
    }),
  }),
  formValues({ orderNumbers: "orderNumbers", status: "status" }),
  mapPropsStream(propsStream => {
    const totalAmountStream = propsStream
      .distinctUntilKeyChanged("orderNumbers")
      .switchMap(props =>
        !props.orderNumbers.length
          ? Observable.of(null)
          : props
              .getCalculateTotal({
                order_numbers: props.orderNumbers,
              })
              .catch(() => Observable.of(null)),
      )
      .map(fp.flow(fp.get("data"), fromJS))
      .distinctUntilChanged(isEqualData);

    const transferTypesStream = propsStream
      .distinctUntilKeyChanged("orderNumbers")
      .filter(props => props.getTransferTypes)
      .switchMap(props =>
        !props.orderNumbers.length
          ? Observable.of(null)
          : props.getTransferTypes().catch(() => Observable.of(null)),
      )
      .startWith({})
      .let(mapArrayResponseStream)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        totalAmountStream,
        transferTypesStream,
        (props, totalAmount, transferTypesResponse) => ({
          ...props,
          totalAmount,
          transferTypes:
            transferTypesResponse.get("payload").size > 0
              ? transferTypesResponse.get("payload")
              : CODTransferType,
          isLoadingTransferTypes: transferTypesResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

function marketplaceCodStatusOptions(marketplace, props) {
  if (!marketplace) return codStatusOptions;

  if (
    (props.filterStatus === [COD_COLLECTED, COD_TRANSFERRED].join(",") ||
      props.filterStatus === COD_PENDING) &&
    marketplace
  )
    return codStatusOptions.remove(COD_COLLECTED).remove(COD_WITH_SAAS_BANK);

  if (props.filterStatus === COD_WITH_WING && marketplace)
    return codStatusOptions
      .remove(COD_COLLECTED)
      .remove(COD_WITH_WING)
      .remove(COD_WITH_SAAS_BANK);

  if (
    props.filterStatus ===
      [COD_WITH_WING_BANK, COD_WITHDRAW_REQUESTED].join(",") &&
    marketplace
  )
    return codStatusOptions
      .remove(COD_COLLECTED)
      .remove(COD_WITH_WING)
      .remove(COD_WITH_WING_BANK)
      .remove(COD_WITH_SAAS_BANK);

  return codStatusOptions.remove(COD_WITH_SAAS_BANK);
}

BatchUpdateCODStatusDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  orderNumbers: PropTypes.array,
  status: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  totalAmount: PropTypes.instanceOf(Map),
  transferTypes: PropTypes.instanceOf(Iterable),
  isLoadingTransferTypes: PropTypes.bool,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

function BatchUpdateCODStatusDialog(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog
      modal={true}
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle className={props.classes.dialogTitle}>
        {getLocalisationMessage(
          "batch_update_cod_status",
          "Batch Update COD Status",
        )}{" "}
        ({props.orderNumbers.length}).{" "}
        {getLocalisationMessage("total_amount", "Total Amount")}:{" "}
        {props.totalAmount ? (
          <PriceWrapper
            price={props.totalAmount.get("total")}
            code={props.totalAmount.getIn(["currency", "code"])}
          />
        ) : (
          getLocalisationMessage("calculating", "Calculating")
        )}
      </DialogTitle>
      <DialogContent>
        <FormChipInput
          name="orderNumbers"
          fullWidth={true}
          focus={true}
          label={getLocalisationMessage(
            "enter_order_numbers",
            "Enter Order Numbers",
          )}
        />

        <FormSelectField
          name="status"
          autoWidth={true}
          fullWidth={true}
          options={marketplaceCodStatusOptions(props.marketplace, props)}
          label={getLocalisationMessage("cod_status", "COD Status")}
          formatOption={x =>
            formatCODStatusCodeLocalised(x, getLocalisationMessage)
          }
        />

        {Boolean(
          props.status === COD_WITH_WING_BANK ||
            props.status === COD_WITH_SAAS_BANK ||
            props.status === COD_PAID_TO_MERCHANT,
        ) && (
          <div>
            <FormTextField
              name="bankReference"
              fullWidth={true}
              label={getLocalisationMessage(
                "bank_payment_reference",
                "Bank Payment Reference",
              )}
            />

            <FormDateField
              name="bankPaymentDate"
              label={getLocalisationMessage(
                "bank_payment_date",
                "Bank Payment Date",
              )}
            />
          </div>
        )}

        <FormSelectField
          name="type"
          autoWidth={true}
          fullWidth={true}
          floatingLabelFixed={
            !props.isLoadingTransferTypes && props.transferTypes.size === 0
          }
          disabled={
            !props.isLoadingTransferTypes && props.transferTypes.size === 0
          }
          hintText={
            props.isLoadingTransferTypes
              ? `${getLocalisationMessage("loading", "Loading")} ...`
              : ""
          }
          options={props.transferTypes}
          formatOption={x => getLocalisationMessage(x, formatText(x))}
          label={getLocalisationMessage("transfer_type", "Transfer Type")}
        />
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button primary={true} onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchUpdateCODStatusDialog);
