import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle, mapPropsStream, withState } from "recompose";
import { getMessage } from "../../reducers/LocalizationReducer";
import AdminOrderBatchUpdateContainer from "./AdminOrderBatchUpdateContainer";
import DataListFilter from "../../helpers/DataListFilter";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import { OrderSortingDB } from "../../realtimeDb/OrderSortingDB";
import { Map } from "immutable";
import { Observable } from "rxjs";
import { isEqualData } from "../../helpers/DataUtils";
import { ROUTE } from "../../constants/OrderType";
import { fetchMongoToken, MONGO_DB_URL } from "../../realtimeDb/MongoDBSDK";
import io from "socket.io-client";

let socketConnection = false;

const enhancer = compose(
  withState("socket", "setSocket", null),
  withState("rethinkDb", "setRethinkDb", null),
  lifecycle({
    componentWillUnmount() {
      if (this.props && this.props.socket) {
        this.props.socket.disconnect();
      }
      this.props.setSocket(undefined);
      this.props.setRethinkDb(undefined);
      socketConnection = false;
    },
  }),
  connect((state) => ({
    warehouseId: getUserWarehouseId(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const dbStream = propsStream
      .distinctUntilKeyChanged("warehouseId")
      .filter((props) => props.warehouseId > 0)
      .mergeMap(({ warehouseId }) =>
        Observable.from(fetchMongoToken(warehouseId)),
      )
      .withLatestFrom(propsStream)
      .mergeMap(([token, props]) => {
        let retinkDB;
        if (!socketConnection) {
          socketConnection = true;
          return new Observable((emit) => {
            // if (props.socket && props.socket.connected) {
            //   props.socket.disconnect();
            // }
            props.setSocket(true);
            const socket = io(`${MONGO_DB_URL}?token=${token}&page=outbound`, {
              path: "/websocket/socket.io",
              autoConnect: true,
              transports: ["websocket"],
              reconnect: true,
            });

            socket.on("connect_error", (e) => {
              // eslint-disable-next-line no-console
              console.log("connect_error", e);
              socketConnection = false;
            });
            socket.on("connect", () => {
              // eslint-disable-next-line no-console
              console.log("connected to the socket routes");
              retinkDB = new OrderSortingDB(socket);
              props.setSocket(socket);
              props.setRethinkDb(retinkDB);
              emit.next(retinkDB);
            });
            socket.on("disconnect", (reason) => {
              // eslint-disable-next-line no-console
              console.log("socket disconnect, ", reason);
            });
          });
        }
        return Observable.of(retinkDB);
      })
      .startWith(undefined);

    const initialState = {
      orders: Map(),
      registries: Map(),
    };

    const stateStream = dbStream
      .switchMap((db: OrderSortingDB) =>
        !db
          ? Observable.of(initialState)
          : Observable.combineLatest(
              db.getOrders(),
              db.getRegistries(),
              (orders, registries) => ({
                orders,
                registries,
              }),
            ).startWith(initialState),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(stateStream, (props, state) => ({
      ...props,
      ...state,
    }));
  }),
);

const AdminOrderOutboundRoutesContainerWrapper = (props) => (
  <AdminOrderBatchUpdateContainer
    {...props}
    orders={props.orders}
    registries={props.registries}
    outBoundSorting={true}
    tab={ROUTE}
    filter={
      new DataListFilter({
        page: 0,
        size: 50,
        // status: IN_TRANSIT,
        type: ROUTE,
      })
    }
  />
);
AdminOrderOutboundRoutesContainerWrapper.propTypes = {
  orders: PropTypes.instanceOf(Map),
  registries: PropTypes.instanceOf(Map),

  getLocalisationMessage: PropTypes.func,
};
export default enhancer(AdminOrderOutboundRoutesContainerWrapper);
