import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  SHOP_VENUE_ITEM_URL,
  SHOP_VENUE_CREATE_URL,
} from "../../constants/AdminPathConstants";
import { removeVenue, getVenueList } from "../../api/admin/AdminVenueApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import VenueListFilterDialog from "../../components/admin/VenueListFilterDialog";
import PageLoading from "../../components/ui-core/PageLoading";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../../components/data-list/DataList";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const NA = "N/A";
const VENUE_FILTER_DIALOG_HASH = "#VFDH";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getVenueList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds, categoryId, status) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              categoryId,
              status,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminShopVenueList.propTypes = {
  total: PropTypes.number,
  isListLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  setLocation: PropTypes.func,
  onRowSelect: PropTypes.func,
  selectedIds: PropTypes.instanceOf(OrderedSet),
  categoryId: PropTypes.number,
  status: PropTypes.string,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationHash: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminShopVenueList(props) {
  const { location, list, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage("shop_venues", "Shop Venues")}
    >
      <PageLoading isLoading={!list} />
      <DataList
        isLoading={props.isLoading}
        selectedRowCount={props.selectedIds.size}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={list.size}
        rowGetter={options => list.get(options.index)}
        altHeader={
          <Button
            onClick={() =>
              removeVenue(props.selectedIds.toArray().join(",")).then(
                props.onRequestRefresh,
              )
            }
          >
            {props.getLocalisationMessage("delete_items", "Delete Items")}
          </Button>
        }
        cardActionIcons={
          <div>
            <IconButton
              tooltip={props.getLocalisationMessage(
                "create_new_venue",
                "Create New Venue",
              )}
              onClick={() => props.setLocation(SHOP_VENUE_CREATE_URL)}
            >
              <Add />
            </IconButton>

            <IconButton
              tooltip={props.getLocalisationMessage(
                "menus_filter_dialog",
                "Menus Filter Dialog",
              )}
              onClick={() => props.setLocationHash(VENUE_FILTER_DIALOG_HASH)}
            >
              <FilterList />
              <VenueListFilterDialog
                title={props.getLocalisationMessage(
                  "venues_filter_dialog",
                  "Venues Filter Dialog",
                )}
                open={location.hash === VENUE_FILTER_DIALOG_HASH}
                initialValues={{
                  category: { id: _.toInteger(props.categoryId) },
                  status,
                }}
                onSubmit={data => {
                  const query = _.omit(location.query, [
                    "status",
                    "categoryId",
                  ]);

                  if (_.get(data, "status")) {
                    query.status = _.upperCase(_.get(data, "status"));
                  }

                  if (_.get(data, "category.id")) {
                    query.categoryId = _.get(data, "category.id");
                  }
                  props.setLocation(updateQuery(props.location, query));
                  props.setLocationHash(null);
                }}
                onRequestClose={() => props.setLocationHash(null)}
              />
            </IconButton>
          </div>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            props.selectedIds.size > 0 &&
            props.selectedIds.size === props.list.size
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.list.toOrderedSet().map(item => item.get("id"))
                : OrderedSet(),
            )
          }
          rowSelected={row => props.selectedIds.has(row.cellData.get("id"))}
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedIds.add(row.cellData.get("id"))
                : props.selectedIds.delete(row.cellData.get("id")),
            )
          }
        />

        <DataListColumn
          width={96}
          label={props.getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              <Link to={SHOP_VENUE_ITEM_URL + row.cellData.get("id")}>
                {row.cellData.get("name")}
              </Link>
            </strong>
          )}
        />

        <DataListColumn
          width={96}
          label={props.getLocalisationMessage("merchant_id", "Merchant ID")}
          dataKey="merchant_id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>{row.cellData.get("merchant_id") || NA}</div>
          )}
        />

        <DataListColumn
          width={96}
          label={props.getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("status") || NA}</div>}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminShopVenueList);
