// eslint-disable-next-line import/no-internal-modules
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import EditorFieldComponent from "./EditorFieldComponents";

EditorField.propTypes = {
  name: PropTypes.string.isRequired,

  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,

  maxHeight: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,

  validate: PropTypes.func,

  formatInput: PropTypes.func,
  formatOption: PropTypes.func,

  compareOptions: PropTypes.func,

  onChange: PropTypes.func,
};

export default function EditorField(props) {
  return <Field {...props} component={EditorFieldComponent} />;
}
