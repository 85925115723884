import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import SubscriptionCheckboxLabel from "./SubscriptionCheckboxLabel";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    addOnItem: {
      border: "1px solid #C6C7CD",
      padding: 10,
      borderRadius: 6,
      color: "#10132D",
      "&.active, &:hover": {
        border: "1px solid #10132D",
        backgroundColor: "#F7F7F8",
        boxShadow: "0 3px 9px rgba(0,0,0,0.16)",
      },
    },
  }),
);

const getStyles = props => ({
  root: {
    width: props.width,
  },
});

SubscriptionAddOnItem.propTypes = {
  classes: PropTypes.object,
  code: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Map).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  priceLabel: PropTypes.string,
  price: PropTypes.number.isRequired,
  onCheck: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getLocalisationMessage: PropTypes.func,
  key: PropTypes.string,
};
SubscriptionAddOnItem.defaultProps = {
  width: "32%",
};

function SubscriptionAddOnItem(props) {
  const { classes, code, filter, getLocalisationMessage } = props;
  const styles = getStyles(props);
  const checked = filter.get("add_ons", []).includes(code);
  return (
    <FlexBox
      className={cx(classes.addOnItem, { active: checked })}
      style={styles.root}
      direction="column"
    >
      <Checkbox
        checked={checked}
        onCheck={(event, value) => props.onCheck(code, value)}
        label={
          <SubscriptionCheckboxLabel
            title={getLocalisationMessage(code, props.title)}
            description={props.description}
            price={`$${props.price}`}
            priceLabel={props.priceLabel}
          />
        }
      />
    </FlexBox>
  );
}

export default enhancer(SubscriptionAddOnItem);
