import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, Button, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import FormFileField from "../form/FormFileField";
import FlexBox from "../ui-core/FlexBox";
import { createFileValidator } from "../../helpers/FormUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    container: {
      "& > div": {
        flex: 1,
      },
    },
    root: {
      padding: "12px",
      position: "relative",
    },
    loaderContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  }),
  reduxForm({
    form: "MapAreasUploadKmlForm",
  }),
);

function MapAreasUploadKmlForm({ classes, i18n, ...props }) {
  return (
    <FlexBox className={classes.container} element={<Card />} container={8}>
      <FlexBox
        className={classes.root}
        direction="column"
        gutter={8}
        element={<form onSubmit={props.handleSubmit} />}
      >
        <FlexBox>
          <FormFileField
            name="file"
            fullWidth={true}
            hintText={i18n.get("select_kml", "Select kml")}
            validate={createFileValidator(
              i18n.get("please_select_file", "Please Select File"),
            )}
          />
        </FlexBox>

        <FlexBox justify="flex-end">
          <Button onClick={props.onDismiss}>
            {i18n.get("dismiss", "Dismiss")}
          </Button>
          <Button type="submit" disabled={props.pristine}>
            {i18n.get("submit", "Submit")}
          </Button>
        </FlexBox>

        {props.submitting && (
          <FlexBox
            className={classes.loaderContainer}
            align="center"
            justify="center"
          >
            <CircularProgress />
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

MapAreasUploadKmlForm.propTypes = {
  classes: PropTypes.object,

  handleSubmit: PropTypes.func,

  change: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,

  onDismiss: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

export default enhancer(MapAreasUploadKmlForm);
