import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const MONTHS_URL = `${API_ROOT_URL}/ranking/month`;
const DAYS_OF_WEEK_URL = `${API_ROOT_URL}/ranking/day_of_weeks`;
const SCORE_SETTINGS_URL = `${API_ROOT_URL}/admin/ranking/score_settings`;
const HOLIDAY_SETTINGS_URL = `${API_ROOT_URL}/admin/country/holiday/settings`;
const SAVE_SCORE_SETTINGS_URL = `${
  API_ROOT_URL
}/admin/ranking/save_score_settings`;
const GET_WEEKEND_SETTINGS_URL = `${
  API_ROOT_URL
}/admin/holiday/settings/week_ends`;
const HOLIDAY_SETTINGS_ITEM_URL = `${
  API_ROOT_URL
}/admin/country/holiday/settings/%s`;
const SAVE_WEEKEND_SETTINGS_URL = `${
  API_ROOT_URL
}/admin/holiday/settings/save_week_ends`;

const CALL_TIMINGS_SETTINGS_URL = `${API_ROOT_URL}/admin/call-timings`;
const CALL_TIMING_SETTINGS_ITEM_URL = `${API_ROOT_URL}/admin/call-timing/%s`;

type HolidaySettingsRequest = {
  country: {
    description: string,
    id: number,
    name: string,
  },
  country_id: number,
  day_off_type: string,
  end_date: string,
  holiday_name: string,
  id: number,
  repeated: true,
  start_date: string,
  week_day: string,
  marketplace_id: number,
};

type CallTimingSettingsRequest = {
  customer_id: number,
  end_time: string,
  start_time: string,
  courier_type: string,
  address_type: string,
  call_timing_permission_type: string,
  id: number,
};

export const getScoreSettings = (request: DataListFilter) =>
  api.getStream(SCORE_SETTINGS_URL, {
    params: request.getDefinedValues(),
  });
export const getHolidaySettings = (request: DataListFilter) =>
  api.getStream(HOLIDAY_SETTINGS_URL, {
    params: request.getDefinedValues(),
  });
export const getWeekendSettings = () => api.getStream(GET_WEEKEND_SETTINGS_URL);
export const saveWeekendSettings = body =>
  api.post(SAVE_WEEKEND_SETTINGS_URL, { body });
export const deleteHolidaySettingsItem = id =>
  api.delete(sprintf(HOLIDAY_SETTINGS_ITEM_URL, id));
export const saveHolidaySettings = (body: HolidaySettingsRequest) =>
  api.post(HOLIDAY_SETTINGS_URL, { body });
export const saveScoreSettings = body =>
  api.post(SAVE_SCORE_SETTINGS_URL, { body });
export const getDayOfWeek = () => api.getStream(DAYS_OF_WEEK_URL);
export const getMonths = () => api.getStream(MONTHS_URL);

export const getCallTimingsSettings = (request: DataListFilter) =>
  api.getStream(CALL_TIMINGS_SETTINGS_URL, {
    params: request.getDefinedValues(),
  });
export const saveCallTimingsSettings = (body: CallTimingSettingsRequest) =>
  api.post(CALL_TIMINGS_SETTINGS_URL, {
    body,
  });
export const getCallTimingSettingsItem = id =>
  api.getStream(sprintf(CALL_TIMING_SETTINGS_ITEM_URL, id));
