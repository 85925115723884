import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  blue,
  blueGrey,
  deepPurple,
  green,
  grey,
  lightGreen,
  orange,
  purple,
  red,
  teal,
} from "@material-ui/core/colors";
import { formatOrderStatusCode } from "../../helpers/OrderHelper";
import {
  ARRIVED_TO_DELIVERY_ADDRESS,
  ASSIGNED_TO_COURIER,
  COMPLETED,
  DELIVERY_SCHEDULED,
  DESTROYED_ON_CUSTOMER_REQUEST,
  DISPATCHED,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  LOST_OR_DAMAGED,
  PICKED_UP,
  RECIPIENT_NOT_AVAILABLE,
  RETURNED_TO_ORIGIN,
  TO_BE_RETURNED,
} from "../../constants/OrderStatusCodes";

const statuses = new Map({
  [PICKED_UP]: blue[500],
  [COMPLETED]: green[500],
  [DISPATCHED]: orange[500],
  [IN_TRANSIT]: deepPurple[500],
  [LOST_OR_DAMAGED]: purple[500],
  [TO_BE_RETURNED]: blueGrey[500],
  [ASSIGNED_TO_COURIER]: teal[500],
  [IN_SORTING_FACILITY]: purple[500],
  [RECIPIENT_NOT_AVAILABLE]: red[500],
  [RETURNED_TO_ORIGIN]: blueGrey[500],
  [DELIVERY_SCHEDULED]: lightGreen[500],
  [ARRIVED_TO_DELIVERY_ADDRESS]: orange[500],
  [DESTROYED_ON_CUSTOMER_REQUEST]: blueGrey[500],
});

const enhancer = compose(
  useSheet({
    status: {
      fontWeight: 500,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  }),
);

OrderStatus.propTypes = {
  colored: PropTypes.bool,
  status: PropTypes.string,
  classes: PropTypes.object,
  fallBackValue: PropTypes.node,
};

function OrderStatus(props) {
  const { classes } = props;

  if (!fp.isEmpty(props.status)) {
    return (
      <span
        className={classes.status}
        style={
          props.colored ? { color: statuses.get(props.status, grey[500]) } : {}
        }
      >
        {formatOrderStatusCode(props.status)}
      </span>
    );
  }

  return props.fallBackValue;
}

export default enhancer(OrderStatus);
