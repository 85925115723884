import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createTimeSlot } from "../../api/admin/AdminTimeSlotApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import TimeSlotForm from "../../components/time-slots-core/TimeSlotForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

AdminTimeSlotCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function AdminTimeSlotCreateDialogWrapper(props) {
  return (
    <ModalPaper
      title={props.getLocalisationMessage("create_timeslot", "Create Timeslot")}
      open={props.open}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <TimeSlotForm
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            createTimeSlot(toSnakeCase(values)).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminTimeSlotCreateDialogWrapper);
