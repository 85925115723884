import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import {
  ERROR,
  LOADING,
  NOT_STARTED,
  SUCCESS,
} from "../../components/orders-core/AdminPostForm";
import _ from "lodash";
import { ROUTE_TEMPLATES_URL } from "../../constants/AdminPathConstants";
import RouteTemplateForm from "../../components/orders-core/RouteTemplateForm";
import {
  getRouteTemplate,
  updateRouteTemplate,
} from "../../api/shared/RouteTemplateApi";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

RouteTemplateEditWrapper.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  params: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
  setLocation: PropTypes.func,
};

function RouteTemplateEditWrapper({
  getLocalisationMessage,
  onSubmitFail,
  params,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  setLocation,
}) {
  const [service, setService] = useState({});
  const [isLoading, setIsLoading] = useState(NOT_STARTED);

  useEffect(
    () =>
      getRouteTemplate(params.id)
        .then((res) => {
          const tempData = _.get(res, "data", {});
          setService({
            ...tempData,
            points: _.get(tempData, "points", []).map((v) => ({
              ...v,
              time_of_arrival: new Date(v.time_of_arrival),
              time_of_departure: new Date(v.time_of_departure),
            })),
          });
        })
        .catch((error) => showErrorMessage1(error)),
    [params.id],
  );
  return (
    <AdminAppLayout
      title={`${getLocalisationMessage("route_templates")} - ${params.id}`}
    >
      <RouteTemplateForm
        edit={true}
        initialValues={{ ...service }}
        serviceId={params.id}
        isLoading={isLoading}
        service={service}
        onSubmit={(values) => {
          setIsLoading(LOADING);
          updateRouteTemplate(params.id, toSnakeCase(values))
            .then((res) => {
              if (res.status === "success") {
                setIsLoading(SUCCESS);
                showSuccessMessage1(
                  getLocalisationMessage(
                    "successfully_updated",
                    "Successfully Updated",
                  ),
                );
                setLocation(ROUTE_TEMPLATES_URL);
              } else {
                setIsLoading(ERROR);
                showErrorMessage1(res.message);
              }
            })
            .catch((error) => {
              setIsLoading(ERROR);
              if (onSubmitFail) {
                onSubmitFail(error);
              } else {
                showErrorMessage1(error);
              }
            });
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(RouteTemplateEditWrapper);
