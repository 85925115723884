import React from "react";
import fp from "lodash/fp";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CustomerFeedbackFilterForm from "../../components/customer-feedback/CustomerFeedbackFilterForm";
import {
  getCachedUser,
  getOperatorPredictions,
} from "../../api/admin/AdminUserApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

CustomerFeedbackFilterDialog.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,

  open: PropTypes.bool.isRequired,

  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  getLocalisationMessage: PropTypes.func,
};

function CustomerFeedbackFilterDialog(props) {
  return (
    <ModalPaper
      open={props.open}
      autoScrollBodyContent={true}
      title={props.getLocalisationMessage("filter", "Filter")}
      onRequestClose={props.onRequestClose}
    >
      <CustomerFeedbackFilterForm
        filter={props.filter}
        onRequestClose={props.onRequestClose}
        onFilterChange={props.onFilterChange}
        toggleContainer={props.state.toggleContainer}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedServiceType={getCachedServiceType}
        getServiceTypePredictions={getServiceTypePredictions}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        getCachedUser={getCachedUser}
        getOperatorPredictions={getOperatorPredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(CustomerFeedbackFilterDialog);
