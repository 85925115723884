import React, { useState } from "react";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { CardContent, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { createObjectIdValidator } from "../../helpers/FormUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getDraftOrder,
  getOrderUploadList,
  updateDraftOrderV2,
  uploadDomesticOrdersV2,
} from "../../api/admin/AdminOrderUploadApi";
import FormContainer from "../../components/form/FormContainer";
import FormCustomerAutoComplete from "../../components/form/FormCustomerAutoComplete";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderUploadDialogV2 from "../../components/order-upload/OrderUploadDialogV2";
import AdminOrderUploadList from "../../components/order-upload/AdminOrderUploadList";
import AdminOrderUploadDetails from "../../components/order-upload/AdminOrderUploadDetails";
import OrderUploadEditDialogV2, {
  valuesToDraft,
} from "../../components/order-upload/OrderUploadEditDialogV2";
import {
  getCachedPostServices,
  getPostServicesPredictions,
} from "../../api/shared/OrderApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import { getPaymentMethods } from "../../api/v2/admin/AdminCustomerApi";
import { getCachedJM, getJMPredictions } from "../../api/shared/CountryV2Api";
import moment from "moment";
import _ from "lodash";
import FormSelectField from "../../components/form/FormSelectField";
import MerchantTypes, { MERCHANTS } from "../../constants/MerchantTypes";
import { formatMerchantType } from "../../helpers/OrderHelper";

const createOrderHref = id => `${ORDER_LIST_URL}?view_v2=${id}`;

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return { getLocalisationMessage };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    () => ({ getCachedCustomer, getCustomerPredictions }),
  ),
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), DataListFilter.create))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(filterStream, (props, filter) => ({
      ...props,
      filter,
    }));
  }),
);

AdminUploadOrdersV2Container.propTypes = {
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter),
};

function AdminUploadOrdersV2Container(props) {
  const { location, getLocalisationMessage } = props;
  const [customer, setCustomer] = useState({
    id: fp.toFinite(location.query.customer_id),
  });
  const [merchantType, setMerchantType] = useState(MERCHANTS);

  const batchId = fp.toFinite(location.query.id);
  const [draftId, setDraftId] = useState(false);
  const customerId = _.get(customer, "id");
  const removeId = fp.toFinite(location.query.remove);
  const clearId = fp.toFinite(location.query.clear);

  const openUploadDialog =
    !batchId && !draftId && Boolean(location.query.upload);
  const openPublishDialog = batchId > 0 && Boolean(location.query.publish);

  return (
    <AdminAppLayout
      slug="new_upload_orders"
      title={getLocalisationMessage("upload_orders", "Upload Orders")}
    >
      <OrderUploadDialogV2
        open={openUploadDialog}
        customerId={customerId}
        onRequestClose={() => props.setLocationQuery(fp.unset("upload"))}
        onSubmit={fp.flow(
          values => uploadDomesticOrdersV2(customerId, values.file),
          request => request.toPromise().catch(ResponseError.throw),
        )}
        onSubmitSuccess={fp.flow(fp.get("payload.data"), id =>
          props.setLocationQuery(fp.set("id", id)),
        )}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderUploadEditDialogV2
        customer={customer}
        customerId={customerId}
        draftId={draftId}
        getDraftOrder={getDraftOrder}
        onRequestClose={() => setDraftId(false)}
        onSubmit={v =>
          updateDraftOrderV2({
            ...valuesToDraft(v),
            id: draftId,
            additional_services: v.additionalServices,
            // additional_services: { content:v.additionalServices },
            payment_type_for_additional_service: {
              code: v.additionalPaymentType,
            },
            payment_type: { code: v.paymentType },
            transportation_type: { code: v.transportationType },
            promised_date: {
              content:
                v.promisedDate && moment(v.promisedDate).format("DD/MM/YY"),
            },
            recipient_jurisdiction: {
              id: v.recipientJurisdiction.id,
              content: v.recipientJurisdiction.name,
            },
            sender_jurisdiction: {
              id: v.senderJurisdiction.id,
              content: v.senderJurisdiction.name,
            },
            recipient_postcode: {
              id: v.recipientPostcode.id,
              content: v.recipientPostcode.name,
            },
            sender_postcode: {
              id: v.senderPostcode.id,
              content: v.senderPostcode.name,
            },
            service_type: {
              id: toJS(v.serviceType).id,
              content: toJS(v.serviceType).name,
            },
          }).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => setDraftId(false)}
        onSubmitFail={props.showErrorMessage}
        getCachedPostServices={getCachedPostServices}
        getPostServicesPredictions={getPostServicesPredictions}
        getCachedPostcode={getCachedPostcode}
        getPostcodePredictions={getPostcodePredictions}
        getPaymentMethods={getPaymentMethods}
        getCachedJM={getCachedJM}
        getJMPredictions={getJMPredictions}
      />

      <FlexBox container={8} direction="column" flex={true}>
        <FlexBox gutter={8} direction="column" flex={true}>
          <FlexBox direction="column">
            <Paper>
              <CardContent>
                <FormContainer
                  enableReinitialize={true}
                  form="AdminUploadOrdersContainerCustomer"
                  initialValues={{
                    customer: { id: customerId },
                    merchantType,
                  }}
                  onSubmit={() => {}}
                >
                  <FlexBox gutter={8}>
                    <FlexBox style={{ width: "20%" }}>
                      <FormSelectField
                        name="merchantType"
                        fullWidth={true}
                        options={MerchantTypes}
                        formatOption={option =>
                          formatMerchantType(option, getLocalisationMessage)
                        }
                        onChange={(e, v) => {
                          if (v) {
                            setMerchantType(v);
                            setCustomer({});
                            props.setLocationQuery(fp.unset("customer_id"));
                            props.setLocationQuery(fp.unset("id"));
                          }
                        }}
                        label={getLocalisationMessage("type", "Type")}
                      />
                    </FlexBox>
                    <FlexBox style={{ width: "80%" }}>
                      <FormCustomerAutoComplete
                        name="customer"
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search...",
                        )}
                        merchantType={merchantType}
                        onChange={(e, v) => {
                          if (v.id) {
                            setCustomer(v);
                            props.setLocationQuery(fp.set("customer_id", v.id));
                            props.setLocationQuery(fp.unset("id"));
                          } else {
                            setCustomer({});
                            props.setLocationQuery(fp.unset("customer_id"));
                            props.setLocationQuery(fp.unset("id"));
                          }
                        }}
                        validate={createObjectIdValidator(
                          getLocalisationMessage(
                            "select_customer",
                            "Select Customer",
                          ),
                        )}
                      />
                    </FlexBox>
                  </FlexBox>
                </FormContainer>
              </CardContent>
            </Paper>
          </FlexBox>

          <FlexBox direction="column" flex={true}>
            {Boolean(!batchId && customerId > 0) && (
              <AdminOrderUploadList
                removeId={removeId}
                clearId={clearId}
                customerId={customerId}
                filter={props.filter}
                getOrderUploadList={() => getOrderUploadList(customerId)}
                onOrderUploadClick={id =>
                  props.setLocationQuery(fp.set("id", id))
                }
                onClearUploadClick={() =>
                  props.setLocationQuery(fp.set("clear", customerId))
                }
                onRemoveUploadClick={id =>
                  props.setLocationQuery(fp.set("remove", id))
                }
                onOrderCreateUploadClick={() =>
                  props.setLocationQuery(fp.set("upload", true))
                }
              />
            )}

            {!draftId && Boolean(batchId > 0 && customerId > 0) && (
              <AdminOrderUploadDetails
                filter={props.filter}
                batchId={batchId}
                openPublishDialog={openPublishDialog}
                create={true}
                createOrderHref={createOrderHref}
                id={batchId}
                onPublishClick={() =>
                  props.setLocationQuery(fp.set("publish", true))
                }
                onBackClick={() =>
                  props.setLocationQuery({ customer_id: customerId })
                }
                onDraftSelect={id => setDraftId(id)}
              />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminUploadOrdersV2Container);
