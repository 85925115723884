import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FlexBox, { DIRECTION_COLUMN } from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate, formatDateTimeToUrl } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const startTime = startOfToday();
const endTime = endOfToday();

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    wrapContent: {
      "@media (max-width: 998px)": {
        flexWrap: "wrap",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& > div": { justifyContent: "flex-start", width: "100%" },
      },
    },
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        return {
          fromDateTime: safeParseDate(x.getValue("from_date_time")),
          toDateTime: safeParseDate(x.getValue("to_date_time")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) => {
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),
          });
        }),
      );
      props.onDismiss();
    };

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "OrderFilterDateForm", enableReinitialize: true }),
  formValues({
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
  }),
  pureComponent(fp.pick(["initialValues", "fromDateTime", "toDateTime"])),
);

OrderFilterDateForm.propTypes = {
  classes: PropTypes.object,

  onReset: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,

  onDismiss: PropTypes.func,
  onFilterChange: PropTypes.func,
  change: PropTypes.func,

  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderFilterDateForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <Card>
        <CardContent>
          <FlexBox flex={true} direction="column">
            <FlexBox
              flex={true}
              className={classes.wrapContent}
              direction={DIRECTION_COLUMN}
            >
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="fromDateTime"
                    hintText={getLocalisationMessage("from_date", "From Date")}
                    initialTime={startTime}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTimeField
                    fullWidth={true}
                    name="fromDateTime"
                    hintText={getLocalisationMessage("from_time", "From Time")}
                  />
                </FlexBox>

                {Boolean(props.fromDateTime) && (
                  <FlexBox>
                    <IconButton
                      onClick={() => props.change("fromDateTime", null)}
                    >
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="toDateTime"
                    hintText={getLocalisationMessage("to_date", "To Date")}
                    initialTime={endTime}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTimeField
                    fullWidth={true}
                    name="toDateTime"
                    hintText={getLocalisationMessage("to_time", "To Time")}
                  />
                </FlexBox>

                {Boolean(props.toDateTime) && (
                  <FlexBox>
                    <IconButton
                      onClick={() => props.change("toDateTime", null)}
                    >
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox justify="flex-end">
            <Button onClick={props.onReset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
            <Button onClick={props.onDismiss}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
            <Button type="submit">
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(OrderFilterDateForm);
