import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { DATE_TIME_SHORT_FORMAT } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FormDateField from "../form/FormDateField";
import { endOfDay, startOfDay } from "date-fns";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "DriverDashboardFilterForm",
    enableReinitialize: true,
  }),
);

DriverDashboardFilterForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
};

function DriverDashboardFilterForm(props) {
  const { getLocalisationMessage, change } = props;

  return (
    <FlexBox
      align="center"
      container={16}
      style={{ gap: 14, width: "70%" }}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <FlexBox flex={true}>
        <FormWarehouseAutoComplete
          fullWidth={true}
          name="warehouse"
          hintText={props.getLocalisationMessage(
            "type_to_search",
            "Type to search ...",
          )}
          disabled={true}
          label={getLocalisationMessage("Warehouse", "Warehouse")}
        />
      </FlexBox>
      <FlexBox flex={true}>
        <FormDriverAutoComplete
          fullWidth={true}
          name="driver"
          hintText={props.getLocalisationMessage(
            "type_to_search",
            "Type to search ...",
          )}
          label={getLocalisationMessage("choose_driver", " Choose driver")}
        />
      </FlexBox>
      <FlexBox flex={true}>
        <FormDateField
          fullWidth={true}
          ampmInClock={true}
          formatValue={DATE_TIME_SHORT_FORMAT}
          hintText={getLocalisationMessage("from_date")}
          name="fromDateTime"
          onChange={v => change("fromDateTime", startOfDay(v))}
        />
      </FlexBox>
      <FlexBox flex={true}>
        <FormDateField
          fullWidth={true}
          formatValue={DATE_TIME_SHORT_FORMAT}
          hintText={getLocalisationMessage("to_date")}
          name="toDateTime"
          onChange={v => change("toDateTime", endOfDay(v))}
        />
      </FlexBox>
      <FlexBox flex={true}>
        <CustomButton
          variant={CONTAINED}
          color={SECONDARY}
          style={{ backgroundColor: "#3F51B5" }}
          type="submit"
          size="large"
          fullWidth={true}
        >
          {getLocalisationMessage("apply", "Apply")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverDashboardFilterForm);
