import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { LOCKER_POST_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { ACTIVE } from "../../constants/OverallStatus";
import LockerPostForm from "../../components/admin/LockerPostForm";
import { createLockerPost } from "../../api/admin/AdminLockerpostApi";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({ setLocation: PropTypes.func }),
);

AdminLockerpostCreate.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  router: PropTypes.object,
};

function AdminLockerpostCreate(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "add_new_locker_post",
        "Add new Locker Post",
      )}
    >
      <LockerPostForm
        router={props.router}
        initialValues={{ status: ACTIVE }}
        onSubmit={values =>
          createLockerPost(toSnakeCase(values)).catch(ResponseError.throw)
        }
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.setLocation(LOCKER_POST_LIST_URL);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_created",
              "Successfully created",
            ),
          );
        }}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminLockerpostCreate);
