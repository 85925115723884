import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { Button, Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import AdminOrderChargeEditDialogWrapper from "./AdminOrderChargeEditDialogWrapper";
import AdminOrderMultiBoxUpdateDialogWrapper from "./AdminOrderMultiBoxUpdateDialogWrapper";
import AdminOrderDriverSuggestionDialogWrapper from "./AdminOrderDriverSuggestionDialogWrapper";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  mapOrderCODHistoryToActivitiasV2,
  mapOrderNotesAndHistoryToActivities,
} from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getUser } from "../../reducers/ProfileReducer";
import { marketplaceGenerateTaxInvoiceEnabled } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ReasonTypes from "../../constants/ReasonTypes";
import { URGENT } from "../../constants/CourierTypes";
import { ROLE_FINANCE_USER } from "../../constants/AdminRoleTypes";
import InquiriesTypes from "../../constants/InquiriesTypes";
import NoteTypeCategory from "../../constants/NoteTypeCategory";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import {
  CUSTOMER_ITEM_URL,
  DRIVER_LIST_URL,
  SUPPLIER_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  createOrderNote,
  getOrderActivities,
  getOrderCODHistory,
  getOrderLastSyncDate,
  orderSync,
  uploadOrderSignature,
} from "../../api/admin/AdminOrderApi";
import { getCustomer } from "../../api/admin/AdminCustomerApi";
import { getSupplier } from "../../api/admin/AdminSupplierApi";
import { getDriverLocation } from "../../api/shared/DriverApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import OrderNoteDialog from "../../components/orders-core/OrderNoteDialog";
import OrderActivitiesTimeline from "../../components/orders-core/OrderActivitiesTimeline";
import UpdateOrderContactDialog from "../../components/orders-core/UpdateOrderContactDialog";
import OrderDetailsCODHistory from "../../components/order-details-dialog/OrderDetailsCODHistory";
import {
  BRIEF_TAB,
  COD_HISTORY_TAB,
  COURIER_TAB,
  CUSTOMER_TAB,
  HISTORY_TAB,
  MAP_TAB,
  SIGNATURE_TAB,
} from "../../components/order-details-dialog/OrderDetailsDialogTabs";
import OrderDetailsDialogCourier from "../../components/order-details-dialog/OrderDetailsDialogCourier";
import OrderDetailsDialogCustomer from "../../components/order-details-dialog/OrderDetailsDialogCustomer";
import OrderDetailsDialogSignature from "../../components/order-details-dialog/OrderDetailsDialogSignature";
import { getOrder } from "../../api/v2/admin/AdminOrderApi";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import OrderDetailsDialog from "../../components/order-details-dialog/v2/OrderDetailsDialog";
import OrderDetailsDialogMap from "../../components/order-details-dialog/v2/OrderDetailsDialogMap";
import { hasRole } from "../../helpers/RoleUtils";
import OrderDetailsDialogBriefV2 from "../../components/order-details-dialog/v2/OrderDetailsDialogBriefV2";

const DELIVERY_TO = "delivery_signature";
const SENDER_FROM = "sender_signature";

const enhancer = compose(
  renderIf(props => props.orderId > 0),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage,
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
        isGenerateTaxInvoiceEnabled:
          marketplaceGenerateTaxInvoiceEnabled(state) &&
          hasRole(userRoles, ROLE_FINANCE_USER),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    container: {
      minHeight: "300px",
      maxHeight: "480px",
      overflowY: "auto",
      margin: "0 6px 6px",
    },
    buttonRight: { float: "right" },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onShowAddNote,
          stream: onShowAddNoteStream,
        } = createEventHandler();
        const {
          handler: onShowSubDialog,
          stream: onShowSubDialogStream,
        } = createEventHandler();
        const {
          handler: onShowChargeEdit,
          stream: onShowChargeEditStream,
        } = createEventHandler();
        const {
          handler: onEditMultiBoxCount,
          stream: onEditMultiBoxCountStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();
        const {
          handler: onActivitiesRequestRefresh,
          stream: onActivitiesRequestRefreshStream,
        } = createEventHandler();

        const showAddNoteStream = onShowAddNoteStream.startWith(false);

        const orderResponseStream = propsStream
          .distinctUntilKeyChanged("orderId")
          .switchMap(props =>
            getOrder(props.orderId)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const getOrderCODHistoryStream = propsStream
          .filter(props => props.tab === COD_HISTORY_TAB)
          .distinctUntilChanged("orderId")
          .switchMap(props =>
            getOrderCODHistory(props.orderId)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", mapOrderCODHistoryToActivitiasV2),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const getOrderActivitiesResponseStream = propsStream
          .filter(props => props.tab === HISTORY_TAB)
          .distinctUntilKeyChanged("orderId")
          .switchMap(props =>
            getOrderActivities(props.orderId)
              .repeatWhen(() =>
                Observable.merge(
                  onRequestRefresh,
                  onActivitiesRequestRefreshStream,
                ),
              )
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", mapOrderNotesAndHistoryToActivities),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            showAddNoteStream,
            orderResponseStream,
            getOrderActivitiesResponseStream,
            onShowSubDialogStream.startWith(null),
            onShowChargeEditStream.startWith(false),
            onEditMultiBoxCountStream.startWith(false),
            getOrderCODHistoryStream,
            (
              props,
              showAddNote,
              orderResponse,
              orderActivities,
              uploadType,
              showChargeEdit,
              editMultiBoxCount,
              CODHistory,
            ) => ({
              ...props,
              uploadType,
              showAddNote,
              onShowAddNote,
              showChargeEdit,
              editMultiBoxCount,
              onShowSubDialog,
              onRequestRefresh,
              onShowChargeEdit,
              onEditMultiBoxCount,
              onActivitiesRequestRefresh,
              activitiesCODHistory: CODHistory.get("payload"),
              order: orderResponse.get("payload"),
              isLoading: orderResponse.get("pending"),
              activities: orderActivities.get("payload"),
              isLoadingActivities: orderActivities.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onLastSyncRequestRefresh,
          stream: onLastSyncRequestRefreshStream,
        } = createEventHandler();

        const getOrderLastSyncDateStream = propsStream
          .filter(props => props.order.getIn(["supplier", "id"]))
          .distinctUntilKeyChanged("order", isEqualData)
          .switchMap(props =>
            getOrderLastSyncDate(
              props.order.get("id"),
              props.order.getIn(["supplier", "id"]),
            )
              .repeatWhen(() => onLastSyncRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  payload: response.getIn(["payload", "data"], null),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          getOrderLastSyncDateStream,
          (props, getOrderLastSyncDateResponse) => ({
            ...props,
            onLastSyncRequestRefresh,
            lastSyncDate: getOrderLastSyncDateResponse.get("payload"),
          }),
        );
      },
    ),
  ),
  withState("submitting", "onSubmitting", false),
);

AdminFinanceOrderDetailsDialogWrapperV2.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  activities: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,

  showAddNote: PropTypes.bool,
  onShowAddNote: PropTypes.func,
  onRequestRefresh: PropTypes.func,

  onShowChargeEdit: PropTypes.func,
  showChargeEdit: PropTypes.bool,
  onShowSubDialog: PropTypes.func,
  setLocationQuery: PropTypes.func,
  uploadType: PropTypes.string,
  lastSyncDate: PropTypes.string,
  location: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onActivitiesRequestRefresh: PropTypes.func,
  createDriverSuggestHref: PropTypes.func,
  onLastSyncRequestRefresh: PropTypes.func,

  orderId: PropTypes.number,
  onRequestClose: PropTypes.func.isRequired,

  onTabChange: PropTypes.func.isRequired,
  tab: PropTypes.oneOf([
    BRIEF_TAB,
    HISTORY_TAB,
    CUSTOMER_TAB,
    COURIER_TAB,
    MAP_TAB,
    SIGNATURE_TAB,
    COD_HISTORY_TAB,
  ]),

  onEditClick: PropTypes.func,

  submitting: PropTypes.bool,
  onSubmitting: PropTypes.func,
  isLoadingActivities: PropTypes.bool,

  isAdminViewer: PropTypes.bool,
  editMultiBoxCount: PropTypes.bool,
  isGenerateTaxInvoiceEnabled: PropTypes.bool,
  onEditMultiBoxCount: PropTypes.func,
  activitiesCODHistory: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

AdminFinanceOrderDetailsDialogWrapperV2.defaultProps = { tab: BRIEF_TAB };

function AdminFinanceOrderDetailsDialogWrapperV2(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <OrderDetailsDialog
      open={true}
      onRequestClose={props.onRequestClose}
      tab={props.tab}
      order={props.order}
      isLoading={props.isLoading}
      withoutMap={true}
      withCODHistory={true}
      withTaxInvoice={props.isGenerateTaxInvoiceEnabled}
      onTabChange={props.onTabChange}
      onEditClick={props.onEditClick}
      lastSyncDate={props.lastSyncDate}
      onEditCostsClick={() => props.onShowChargeEdit(true)}
      onShowSuggestedDriverLink={true}
      // createDriverSuggestHref={props.createDriverSuggestHref}
      getDriverUrl={id => updateQuery(DRIVER_LIST_URL, { view: id })}
      getSupplierUrl={id => updateQuery(SUPPLIER_LIST_URL, { view: id })}
      getReverseUrl={id => updateQuery(props.location, fp.set("view_v2", id))}
      getCustomerUrl={id => CUSTOMER_ITEM_URL + id}
    >
      <PageLoading isLoading={props.submitting || props.isLoadingActivities} />

      <AdminOrderChargeEditDialogWrapper
        open={props.showChargeEdit}
        orderId={props.orderId}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.onShowChargeEdit(false);
        }}
      />

      <AdminOrderMultiBoxUpdateDialogWrapper
        open={props.editMultiBoxCount}
        orderId={props.orderId}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.onEditMultiBoxCount(false);
        }}
      />

      {props.order.getIn(["package", "courier_type"]) === URGENT && (
        <AdminOrderDriverSuggestionDialogWrapper
          open={Boolean(props.location.query.driver_view)}
          location={props.location}
          orderId={props.orderId}
          onRequestClose={() => {
            props.onRequestRefresh();
            props.setLocationQuery(fp.flow(fp.unset("driver_view")));
          }}
          onSubmitSuccess={() => {
            props.onRequestRefresh();
            props.setLocationQuery(fp.unset("driver_view"));
            props.showSuccessMessage(
              getLocalisationMessage(
                "driver_has_been_assigned_successfully",
                "Driver has been assigned successfully",
              ),
            );
          }}
          onSubmitFail={error => {
            props.onRequestRefresh();
            props.showErrorMessage(error.errors.status);
          }}
        />
      )}

      {Boolean(props.tab === BRIEF_TAB) && (
        <OrderDetailsDialogBriefV2
          order={props.order}
          showEditMultiBoxLink={!props.isAdminViewer}
          onEditMultiBoxCount={() => props.onEditMultiBoxCount(true)}
        />
      )}

      {Boolean(props.tab === CUSTOMER_TAB) && (
        <OrderDetailsDialogCustomer
          getCustomer={getCustomer}
          customerId={props.order.getIn(["customer", "id"])}
        />
      )}

      {Boolean(
        props.tab === COURIER_TAB && props.order.getIn(["supplier", "id"]),
      ) && (
        <OrderDetailsDialogCourier
          getSupplier={getSupplier}
          supplierId={props.order.getIn(["supplier", "id"])}
        />
      )}

      {Boolean(props.tab === HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <Button
              className={classes.buttonRight}
              target="_blank"
              disabled={Boolean(!props.order.get("tracking_url"))}
              component="a"
              href={props.order.get("tracking_url")}
            >
              {getLocalisationMessage(
                "track_with_supplier",
                "Track with Supplier",
              )}
            </Button>

            {Boolean(!props.isAdminViewer) && (
              <Button
                className={classes.buttonRight}
                onClick={() => props.onShowAddNote(true)}
              >
                {getLocalisationMessage("add_note", "Add Note")}
              </Button>
            )}

            {props.order.get("shipment_id") && (
              <Button
                className={classes.buttonRight}
                onClick={() => {
                  props.onSubmitting(true);

                  orderSync(props.order.get("id"))
                    .then(() => {
                      props.onActivitiesRequestRefresh();
                      props.onSubmitting(false);
                    })
                    .catch(error => {
                      props.showErrorMessage(error);
                      props.onSubmitting(false);
                    });
                }}
              >
                {getLocalisationMessage("sync", "Sync")}
              </Button>
            )}

            <OrderActivitiesTimeline
              showDriver={true}
              showSupplier={true}
              showWarehouse={true}
              driverItemUrl={id =>
                updateQuery(DRIVER_LIST_URL, fp.set("view", id))
              }
              driverItemLocationUrl={id =>
                updateQuery(
                  DRIVER_LIST_URL,
                  fp.flow(fp.set("view", id), fp.set("view_tab", "map")),
                )
              }
              activities={props.activities}
            />

            <OrderNoteDialog
              open={props.showAddNote}
              reasonTypes={ReasonTypes}
              inquiriesTypes={InquiriesTypes}
              privacyTypes={AdminPrivacyTypes}
              noteTypeCategory={NoteTypeCategory}
              withRequiresAttention={true}
              withScheduledForCancellation={true}
              onRequestClose={() => props.onShowAddNote(false)}
              initialValues={{
                requiresAttention: Boolean(
                  props.order.get("requires_attention"),
                ),
                scheduledForCancellation: Boolean(
                  props.order.get("scheduled_for_cancellation"),
                ),
              }}
              onSubmit={values =>
                createOrderNote(props.orderId, toSnakeCase(values)).catch(
                  ResponseError.throw,
                )
              }
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.onShowAddNote(false);
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_note_created",
                    "Successfully Note Created",
                  ),
                );
              }}
              onSubmitFail={() => props.showErrorMessage()}
            />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === COD_HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <OrderDetailsCODHistory activities={props.activitiesCODHistory} />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === MAP_TAB) && (
        <OrderDetailsDialogMap
          order={props.order}
          getDriverLocation={getDriverLocation}
        />
      )}

      {Boolean(props.tab === SIGNATURE_TAB) && (
        <FlexBox container={8} flex={true} className={classes.container}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                photoId={props.order.get("sender_photo_id")}
                signatureId={props.order.get("sender_signature_id")}
                onUpload={() => props.onShowSubDialog(SENDER_FROM)}
                header={getLocalisationMessage(
                  "proof_of_pickup",
                  "Proof of Pickup",
                )}
              />
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                header={getLocalisationMessage(
                  "proof_of_delivery",
                  "Proof of Delivery",
                )}
                photoId={props.order.get("delivered_to_photo_id")}
                signatureId={props.order.get("delivery_signature_id")}
                onUpload={() => props.onShowSubDialog(DELIVERY_TO)}
              />

              <UpdateOrderContactDialog
                onRequestClose={() => props.onShowSubDialog(null)}
                open={Boolean(
                  props.uploadType === DELIVERY_TO ||
                    props.uploadType === SENDER_FROM,
                )}
                title={
                  props.uploadType === DELIVERY_TO
                    ? getLocalisationMessage(
                        "update-recipient-info",
                        "Update Recipient Info",
                      )
                    : getLocalisationMessage(
                        "update_sender_info",
                        "Update Sender Info",
                      )
                }
                initialValues={
                  props.uploadType === DELIVERY_TO
                    ? {
                        type: DELIVERY_TO,
                        orderId: props.orderId,
                        name: props.order.get("delivered_to"),
                        phone: props.order.get("delivered_to_phone"),
                        photoId: props.order.get("delivered_to_photo_id"),
                        signatureId: props.order.get("delivery_signature_id"),
                      }
                    : {
                        type: SENDER_FROM,
                        orderId: props.orderId,
                        name: props.order.get("sender_from"),
                        phone: props.order.get("sender_phone"),
                        photoId: props.order.get("sender_photo_id"),
                        signatureId: props.order.get("sender_signature_id"),
                      }
                }
                onSubmit={values =>
                  uploadOrderSignature(
                    props.orderId,
                    toSnakeCase(values),
                  ).catch(ResponseError.throw)
                }
                onSubmitSuccess={() => {
                  props.onRequestRefresh();
                  props.onShowSubDialog(null);
                }}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </OrderDetailsDialog>
  );
}

export default enhancer(AdminFinanceOrderDetailsDialogWrapperV2);
