import { Observable } from "rxjs";
import { List } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import FormSelectField from "./FormSelectField";
import { isEqualData } from "../../helpers/DataUtils";
import { getOrderedCities } from "../../api/shared/CountryV2Api";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .distinctUntilKeyChanged(isEqualData)
      .switchMap(() => getOrderedCities().catch(() => Observable.of(List())))
      .distinctUntilChanged(isEqualData)
      .map(
        fp.flow(
          list =>
            list
              .toOrderedMap()
              .mapEntries(([, v]) => [v.get("id"), v.get("name")]),
          map => ({
            options: map.keySeq().toList(),
            formatOption: id => map.get(id),
          }),
        ),
      );

    return propsStream.combineLatest(
      responseStream,
      ({ optional, domestic, ...props }, response) => ({
        ...props,
        formatOption: response.formatOption,
        disabled: response.options.isEmpty() || props.disabled,
        options: optional ? response.options.unshift(null) : response.options,
      }),
    );
  }),
);

const FormCitySelectFieldV2 = enhancer(FormSelectField);

FormCitySelectFieldV2.propTypes = {
  name: PropTypes.string.isRequired,

  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,

  maxHeight: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,

  optional: PropTypes.bool,

  validate: PropTypes.func,

  formatInput: PropTypes.func,
  formatOption: PropTypes.func,

  renderOptionLeftIcon: PropTypes.func,
  renderOptionRightIcon: PropTypes.func,
};

FormCitySelectFieldV2.defaultProps = {
  domestic: true,
};

export default FormCitySelectFieldV2;
