import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import PropTypes from "prop-types";
import { IconButton, makeStyles } from "@material-ui/core";
import _ from "lodash";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import TableListHeader from "../../wrappers/admin/TableListHeader";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import { Add, DeleteOutline, Refresh } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import fp from "lodash/fp";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import { COLUMN } from "../../components/orders-core/MUITableReport";
import {
  createInternationalSettingsRegion,
  deleteInternationalSettingsRegion,
  getInternationalSettingsRegionItem,
  getInternationalSettingsRegionList,
} from "../../api/admin/AdminInternationalReportsApi";
import MUITableRegionList from "../../components/orders-core/MUITableRegionList";
import UpdateRegionSettingsDialog from "../../components/order-create-wizard-new/international-reports/UpdateRegionSettingsDialog";
import NewRegionSettingsDialog from "../../components/order-create-wizard-new/international-reports/NewRegionSettingsDialog";
import NewCountrySettingsDialog from "../../components/order-create-wizard-new/international-reports/NewCountrySettingsDialog";

const DEFAULT_LIST = { list: [], total: 0 };

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func,
  }),
);

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  tableHeader: {
    flex: "1 1 auto",
    gap: 16,
    padding: 8,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  chipTextField: {
    padding: 0,
    "& MuiChip-root": {
      margin: 0,
    },
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const AdminActManagementContainer = props => {
  const { getLocalisationMessage } = props;

  const classes = useStyles();

  const [list, setList] = useState(DEFAULT_LIST);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [childDeleteId, setChildDeleteId] = useState(0);
  const [createCity, setCreateCity] = useState(0);
  const [open, setOpen] = useState(false);
  const [refreshChild, setRefreshChild] = useState(false);
  const [id, setId] = useState(0);
  const [childId, setChildId] = useState(0);

  const [filter, setFilter] = useState({
    refresh: false,
    page: 0,
    size: 20,
    level_id: 1,
  });
  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });

  const refreshChildList = () => setRefreshChild(!refreshChild);

  const onDelete = (removeId, child) => {
    deleteInternationalSettingsRegion(removeId)
      .then(() => {
        setDeleteId(0);
        props.showSuccessMessage(
          props.getLocalisationMessage(
            "successfully_removed",
            "Successfully Removed",
          ),
        );
        if (child) {
          refreshChildList();
        } else {
          refreshList();
        }
      })
      .catch(error => {
        props.showErrorMessage(error);
        setDeleteId(0);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    getInternationalSettingsRegionList(filter)
      .then(res => {
        setIsLoading(false);
        setList(fp.flow(fp.defaultTo(DEFAULT_LIST), fp.get("data"))(res));
      })
      .catch(error => {
        props.showErrorMessage(error);
        setIsLoading(false);
      });
  }, [filter, props.isRefresh]);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("countries", "Countries")}
      className={classes.root}
    >
      <NewCountrySettingsDialog
        open={open}
        title={getLocalisationMessage("new_country", "New Country")}
        getItemApi={getInternationalSettingsRegionItem}
        refreshList={refreshList}
        createApi={createInternationalSettingsRegion}
        onRequestClose={() => {
          setOpen(false);
          setId(null);
        }}
      />

      <NewRegionSettingsDialog
        open={createCity > 0}
        parentId={createCity}
        title={getLocalisationMessage("new_city", "New City")}
        getItemApi={getInternationalSettingsRegionItem}
        refreshList={refreshList}
        createApi={createInternationalSettingsRegion}
        onRequestClose={() => {
          setCreateCity(null);
        }}
      />

      <UpdateRegionSettingsDialog
        open={id > 0}
        id={id}
        withCode={true}
        title={getLocalisationMessage("update_country", "Update Country")}
        refreshList={refreshList}
        onRequestClose={() => {
          setId(0);
        }}
      />

      <UpdateRegionSettingsDialog
        open={childId > 0}
        id={childId}
        withCode={true}
        title={getLocalisationMessage("update_city", "Update City")}
        refreshList={refreshChildList}
        onRequestClose={() => {
          setChildId(0);
        }}
      />

      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <ConfirmDialog
        open={childDeleteId > 0}
        onRequestClose={() => {
          setChildDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(childDeleteId, true);
          setChildDeleteId(0);
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <FlexBox
        style={{ backgroundColor: "white", height: "100%" }}
        direction="column"
      >
        <TableListHeader
          buttons={
            <FlexBox
              align={ALIGN_CENTER}
              justify={JUSTIFY_END}
              className={classes.tableHeader}
            >
              <CustomButton
                size="small"
                startIcon={<Add />}
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px" }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                {getLocalisationMessage("create_country", "Create Country")}
              </CustomButton>

              <FlexBox>
                <IconButton
                  className={isLoading && classes.refresh}
                  tooltip={getLocalisationMessage("refresh", "Refresh")}
                  onClick={() => refreshList()}
                >
                  <Refresh />
                </IconButton>
              </FlexBox>
            </FlexBox>
          }
        />

        <MUITableRegionList
          refreshChild={refreshChild}
          setId={setId}
          setChildId={setChildId}
          filter={filter}
          setDeleteId={setChildDeleteId}
          isLoading={isLoading}
          list={fp.get("list", list)}
          total={fp.get("total", list)}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          columns={[
            {
              type: COLUMN,
              name: "id",
              label: getLocalisationMessage("ID"),
              width: 230,
            },
            {
              type: COLUMN,
              name: "name",
              width: 230,
              label: getLocalisationMessage("name"),
              render: ({ row }) => _.get(row, `name`, ""),
            },
            {
              type: COLUMN,
              name: "code",
              width: 230,
              label: getLocalisationMessage("code"),
              render: ({ row }) => _.get(row, `code`, ""),
            },
            {
              type: COLUMN,
              name: "impc_code",
              width: 230,
              label: getLocalisationMessage("impc_code"),
              render: ({ row }) => _.get(row, `impc_code`, ""),
            },
            {
              type: COLUMN,
              name: "impc_name",
              width: 230,
              label: getLocalisationMessage("impc_name"),
              render: ({ row }) => _.get(row, `impc_name`, ""),
            },
            {
              type: COLUMN,
              name: "organization_code",
              width: 230,
              label: getLocalisationMessage("organization_code"),
              render: ({ row }) => _.get(row, `organization_code`, ""),
            },
            {
              type: COLUMN,
              name: "organization_name",
              width: 230,
              label: getLocalisationMessage("organization_name"),
              render: ({ row }) => _.get(row, `organization_name`, ""),
            },
            {
              type: COLUMN,
              name: "action",
              width: 330,
              label: getLocalisationMessage("action", "Action"),
              // eslint-disable-next-line  react/prop-types
              render: ({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <CustomButton
                    size="small"
                    startIcon={<Add />}
                    variant={CONTAINED}
                    color={SECONDARY}
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      setCreateCity(_.get(row, "id", 0));
                    }}
                  >
                    {getLocalisationMessage("create_city", "Create City")}
                  </CustomButton>

                  <IconButton
                    onClick={() => {
                      setDeleteId(_.get(row, "id", 0));
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </div>
              ),
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
};

AdminActManagementContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  isRefresh: PropTypes.bool,
};

export default enhancer(AdminActManagementContainer);
