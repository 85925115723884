import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import { formatText } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import OverallStatus from "../../constants/OverallStatus";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    content: { width: "500px" },
    row: { padding: "5px 0" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    td: {
      alignSelf: "center",
    },
  }),
  reduxForm({
    form: "KpiSettingsDialogForm",
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      status: "inactive",
    },
  }),
);

KpiSettingsDialogForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function KpiSettingsDialogForm(props) {
  const { i18n } = props;

  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onClose={props.handleSubmit}
      PaperProps={{
        style: {
          width: "500px",
        },
      }}
    >
      <DialogTitle>{i18n.get("kpi_settings", "Kpi Settings")}</DialogTitle>
      <DialogContent>
        <FlexBox flex={true} direction="column">
          <FlexBox flex={true}>
            <FormSelectField
              name="status"
              options={OverallStatus}
              formatOption={x => i18n.get(x, formatText(x))}
              fullWidth={true}
              label={i18n.get("status", "Status")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              name="email"
              label={i18n.get("email", "Email")}
              fullWidth={true}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormTextField
              name="password"
              label={i18n.get("password", "Password")}
              fullWidth={true}
            />
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleSubmit}>
          {i18n.get("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(KpiSettingsDialogForm);
