import React from "react";
import { Map, OrderedSet } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import PdfAttributeDetailForm from "./PdfAttributeDetailForm";
import { renderIf } from "../../helpers/HOCUtils";

const titleTypes = OrderedSet.of(
  "default",
  "field_col_type",
  "field_row_type",
  "field_no_title",
);
const getFormattedBorders = attribute => {
  let borderTop = false;
  let borderRight = false;
  let borderBottom = false;
  let borderLeft = false;
  (attribute.get("borderStyle") || "").split(" ").forEach(item => {
    switch (item) {
      case "b-t":
        borderTop = true;
        break;
      case "b-r":
        borderRight = true;
        break;
      case "b-b":
        borderBottom = true;
        break;
      case "b-l":
        borderLeft = true;
        break;
      default:
        break;
    }
  });
  return { borderTop, borderRight, borderBottom, borderLeft };
};

const enhancer = compose(renderIf("open"));

PdfAttributeDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  saveAttributeItem: PropTypes.func,
  attribute: PropTypes.instanceOf(Map),
};

function PdfAttributeDetailDialog(props) {
  return (
    <PdfAttributeDetailForm
      initialValues={{
        name: props.attribute.get("name"),
        viewStyle: props.attribute.get("viewStyle") || "default",
        ...getFormattedBorders(props.attribute),
      }}
      titleTypes={titleTypes}
      attribute={props.attribute}
      onDismiss={props.onRequestClose}
      saveAttributeItem={props.saveAttributeItem}
    />
  );
}

export default enhancer(PdfAttributeDetailDialog);
