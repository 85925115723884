import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import { getByBatchIdSimpleOrder } from "../../api/admin/AdminSimpleOrderApi";
import { getValue } from "../../helpers/DataUtils";
import "../../../client/theme/fonts/blogger-sans/loading.css";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles({
  dialogTitle: {
    padding: "7px 13px",
    fontSize: "20px",
    fontWeight: 500,
  },
});

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
);

SimpleOrderItemProgressDialog.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  batchId: PropTypes.number,
  orderIds: PropTypes.array,
};

function SimpleOrderItemProgressDialog(props) {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [proceed, setProceed] = useState(0);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (props.batchId) {
      getByBatchIdSimpleOrder(props.batchId, props.orderIds.toString())
        .then(res => {
          if (res && res.data) {
            setTotal(getValue(res, "data.total"));
            setProceed(getValue(res, "data.proceed"));
          }
        })
        .catch(error => props.showErrorMessage(error));
    }
  }, [props.batchId, refresh]);

  let refreshList;
  useEffect(() => {
    if (proceed < total)
      refreshList = setInterval(() => setRefresh(prev => !prev), 3000);
    return () => clearInterval(refreshList);
  }, [props.batchId, proceed, total]);

  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          width: "800px",
          height: "300px",
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {total > 0 && total === proceed ? (
          <Typography variant="h6">
            {props.i18n.get("upload_completed", "Upload completed")}
          </Typography>
        ) : (
          <Typography variant="h6">{`${props.i18n.get(
            "loading",
            "Loading",
          )}...`}</Typography>
        )}
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {total > 0 && total === proceed ? (
          <CheckCircle
            style={{
              color: "#27AE60",
              width: "300px",
              height: "150px",
            }}
          />
        ) : (
          <div
            className={
              total === proceed ? "success-bar" : "loading-bar animate"
            }
          >
            <span className="span" />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        {total === proceed ? (
          <FlexBox style={{ gap: 16 }}>
            <CustomButton color={SECONDARY} onClick={props.onSubmit}>
              {props.i18n.get("create_orders", "Create Orders")}
            </CustomButton>
          </FlexBox>
        ) : (
          <FlexBox />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(SimpleOrderItemProgressDialog);
