import React, { useEffect } from "react";
import { Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapProps,
  withContext,
  withHandlers,
} from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormAutoComplete from "../form/FormAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { toJS } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { isEnableEventDateAtBatchUpdate } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COLLECTED,
  CUSTOMER_NOT_PAID,
  PAID_TO_CUSTOMER,
  WITH_WING,
} from "../../constants/AdminCODCodes";
import {
  ACCEPTED,
  ASSIGNED_TO_COURIER,
  COMPLETED,
  DISPATCHED,
  IN_TRANSIT,
  ON_HIS_WAY,
  OUT_FOR_DELIVERY,
  OUT_FOR_RETURN,
  PREPARED_FOR_TRANSIT,
  RETURNED_TO_ORIGIN,
} from "../../constants/OrderStatusCodes";
import transitModeOptions from "../../constants/TransitModeTypes";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import { MIXED } from "../../helpers/OrderOutboundSortingHelper";
import TransportationType from "../../constants/TransportationType";
import FlexBox from "../ui-core/FlexBox";

export const INSURED_BAG = "insured_bag";
export const LETTER_BAG = "letter_bag";
export const PERIODIC_NEWS_BAG = "periodic_news_bag";
export const INTERNATIONAL_SHIPMENT_BAG = "international_shipment_bag";
export const INSURED_PACKAGES = "insured_packages";
export const FRAGILE_PARCELS = "fragile_parcels";
export const ONE_STEP = "one_step";
export const PARCEL = "parcel";
export const POST_PACKET = "post_packet";
export const COURIER_SERVICE = "courier_service";
export const COURIER_SERVICE_WITHOUT_BAG = "courier_service_without_bag";
export const ONE_STEP_WITHOUT_BAG = "one_step_without_bag";

export const categoriesConstants = Set.of(
  INSURED_BAG,
  LETTER_BAG,
  POST_PACKET,
  PARCEL,
  ONE_STEP,
  COURIER_SERVICE,
  COURIER_SERVICE_WITHOUT_BAG,
  ONE_STEP_WITHOUT_BAG,
);

const formatOrderStatus = (status, getLocalisationMessage) => {
  switch (status) {
    default:
      return formatOrderStatusCodeForLocalisation(
        status,
        getLocalisationMessage,
      );
  }
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isEnableEventDateAtBatchUpdate: isEnableEventDateAtBatchUpdate(state),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
    chip: { margin: "4px" },
    moreInfo: { marginTop: "5px", marginLeft: "10px" },
    form: {
      paddingTop: 15,
      "&> div": {
        marginBottom: 15,
      },
    },
    dialogHeaderContainer: {
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
    },
    dialogHeaderTitle: {
      flexGrow: 1,
    },
    dialogHeaderToggleContainer: {
      padding: "10px 5px 10px 20px",
      borderRadius: 1000,
      backgroundColor: "#DBE1E6",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  withHandlers({
    onSubmit: props => ({
      driver,
      supplier,
      warehouse,
      supplierDriverOnly,
      useOrderNumber,
      orderBarcodes,
      ...restValues
    }) => {
      const values = { ...restValues };

      if (isValidObjectId(driver)) {
        values.driver = driver;
      }

      if (isValidObjectId(warehouse)) {
        values.warehouse = warehouse;
      }

      if (isValidObjectId(supplier)) {
        values.supplier = supplier;
      }

      if (useOrderNumber) {
        values.orderNumbers = orderBarcodes;
      } else {
        values.orderBarcodes = orderBarcodes;
      }

      if (values.codCode) {
        values.paid =
          values.codCode === COLLECTED ||
          values.codCode === WITH_WING ||
          values.codCode === CUSTOMER_NOT_PAID ||
          values.codCode === PAID_TO_CUSTOMER;

        values.paidToWing =
          values.codCode === WITH_WING ||
          values.codCode === CUSTOMER_NOT_PAID ||
          values.codCode === PAID_TO_CUSTOMER;

        values.paidToCustomer = values.codCode === PAID_TO_CUSTOMER;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "CreateOrderRegistryDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderBarcodes:
        fp.isEmpty(values.orderBarcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_orders")) ||
          "Add Orders"),
      orderStatus:
        !toJS(props.statusCodes).some(v => v === values.orderStatus) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_status")) ||
          "Select Status"),
      category:
        fp.isEmpty(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      transportationType:
        fp.isEmpty(values.transportationType) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        (values.orderStatus !== COMPLETED ||
          values.orderStatus !== RETURNED_TO_ORIGIN) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        (values.orderStatus !== COMPLETED ||
          values.orderStatus !== RETURNED_TO_ORIGIN) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
    }),
  }),
  formValues({
    supplier: "supplier",
    orderStatus: "orderStatus",
    transitMode: "transitMode",
    orderBarcodes: "orderBarcodes",
    supplierDriverOnly: "supplierDriverOnly",
    batchType: "batchType",
    onlyBarcode: "onlyBarcode",
    batch_ids: "batch_ids",
    to_postcode: "to_postcode",
    to_jurisdiction: "to_jurisdiction",
    next_postcode: "next_postcode",
    next_jurisdiction: "next_jurisdiction",
    category: "category",
    isDomestic: "isDomestic",
    innerShipmentType: "innerShipmentType",
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,

      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  mapProps(props => ({
    ...props,
    statusCodes: props.statusCodes.delete(OUT_FOR_DELIVERY),
  })),
);

CreateOrderRegistryDialog.propTypes = {
  classes: PropTypes.object,
  to_postcode: PropTypes.object,
  to_jurisdiction: PropTypes.object,
  next_postcode: PropTypes.object,
  next_jurisdiction: PropTypes.object,
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  supplier: PropTypes.object,
  orderStatus: PropTypes.string,
  isDomestic: PropTypes.bool,
  supplierDriverOnly: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  codCodes: PropTypes.instanceOf(Set),
  statusCodes: PropTypes.instanceOf(Set).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  innerShipmentType: PropTypes.string,
  change: PropTypes.func,
};

CreateOrderRegistryDialog.defaultProps = {
  isSorting: false,
  onlyBarcode: false,
  isSupplierTransitOrdersVisibility: true,
  showSupplierDriverOnly: true,
  transitModeOptions,
};

function CreateOrderRegistryDialog(props) {
  const { classes, getLocalisationMessage, orderStatus } = props;

  useEffect(() => {
    if (
      orderStatus &&
      (orderStatus === IN_TRANSIT || orderStatus === PREPARED_FOR_TRANSIT) &&
      props.innerShipmentType === "ONE_STEP"
    ) {
      props.change("category", ONE_STEP);
    }
  }, [orderStatus, props.innerShipmentType]);

  return (
    <Dialog
      open={props.open}
      maxWidth="auto"
      PaperProps={{
        style: {
          minWidth: 600,
          minHeight: 200,
        },
      }}
      onClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <div className={classes.dialogHeaderContainer}>
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage("batch_update", "Batch Update")}
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        {props.isLoading ? (
          <FlexBox justify="center" align="center">
            <CircularProgress size={60} color="secondary" />
          </FlexBox>
        ) : (
          <form className={classes.form}>
            <FormChipInput
              name="orderBarcodes"
              focus={true}
              fullWidth={true}
              addOnBlur={true}
              label={getLocalisationMessage("barcodes", "Barcodes")}
            />

            <FormAutoComplete
              name="orderStatus"
              fullWidth={true}
              label={getLocalisationMessage("status", "Status")}
              options={props.statusCodes}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
              formatOption={x => formatOrderStatus(x, getLocalisationMessage)}
            />

            <FormCourierTypeSelectField
              hintText={getLocalisationMessage(
                "what_is_included_in",
                "What is included in?",
              )}
              label={getLocalisationMessage(
                "what_is_included_in",
                "What is included in?",
              )}
              name="innerShipmentType"
              fullWidth={true}
              additionalOption={{
                code: MIXED,
                name: getLocalisationMessage(fp.toLower(MIXED)),
              }}
            />

            {Boolean(
              orderStatus === COMPLETED ||
                orderStatus === OUT_FOR_RETURN ||
                orderStatus === DISPATCHED ||
                orderStatus === ON_HIS_WAY ||
                orderStatus === ACCEPTED ||
                orderStatus === ASSIGNED_TO_COURIER,
            ) && (
              <FormDriverAutoComplete
                name="driver"
                fullWidth={true}
                label={getLocalisationMessage("driver", "Driver")}
                supplierId={
                  props.supplierDriverOnly
                    ? getObjectId(props.supplier)
                    : undefined
                }
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            )}

            <FormWarehouseAutoComplete
              name="warehouse"
              label={getLocalisationMessage("next_warehouse", "Next Warehouse")}
              postcodeIndexes={
                fp.get("name", props.next_postcode) && props.isDomestic
                  ? [fp.get("name", props.next_postcode)]
                  : null
              }
              jurisdictionIds={
                !fp.get("name", props.next_postcode) &&
                fp.get("id", props.next_jurisdiction) &&
                props.isDomestic
                  ? [fp.get("id", props.next_jurisdiction)]
                  : null
              }
              fullWidth={true}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />

            <FormTextField
              name="note"
              rows={4}
              rowsMax={40}
              multiLine={true}
              fullWidth={true}
              label={getLocalisationMessage("note_content", "Note Content")}
            />

            {orderStatus &&
              (orderStatus === IN_TRANSIT ||
                orderStatus === PREPARED_FOR_TRANSIT) && (
                <FormAutoComplete
                  name="category"
                  fullWidth={true}
                  label={getLocalisationMessage("category", "Category")}
                  options={categoriesConstants}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                  formatOption={x => getLocalisationMessage(x)}
                />
              )}

            {(orderStatus === IN_TRANSIT ||
              orderStatus === PREPARED_FOR_TRANSIT) && (
              <FormWarehouseAutoComplete
                name="to_warehouse"
                label={getLocalisationMessage(
                  "destination_warehouse",
                  "Destination Warehouse",
                )}
                postcodeIndexes={
                  fp.get("name", props.to_postcode) && props.isDomestic
                    ? [fp.get("name", props.to_postcode)]
                    : null
                }
                jurisdictionIds={
                  !fp.get("name", props.to_postcode) &&
                  fp.get("id", props.to_jurisdiction) &&
                  props.isDomestic
                    ? [fp.get("id", props.to_jurisdiction)]
                    : null
                }
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            )}

            {COMPLETED === orderStatus && (
              <FormSelectField
                name="codCode"
                autoWidth={true}
                fullWidth={true}
                options={props.codCodes}
                label={getLocalisationMessage("cod_status", "Cod Status")}
                formatOption={x => getLocalisationMessage(x) || formatText(x)}
              />
            )}

            <FormSelectField
              name="transportationType"
              fullWidth={true}
              options={TransportationType}
              formatOption={x =>
                getLocalisationMessage(x.toLowerCase(), formatText(x))
              }
              label={getLocalisationMessage(
                "transportation_type",
                "Transportation Type",
              )}
            />

            <FormTextField
              type="number"
              name="weight"
              fullWidth={true}
              label={getLocalisationMessage("weight_kg", "Weight (kg)")}
            />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {getLocalisationMessage("cancel", "Cancel")}
        </Button>

        <Button primary={true} onClick={props.handleSubmit}>
          {getLocalisationMessage("ok", "Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CreateOrderRegistryDialog);
