import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import BadAddressesFilterForm from "../../components/bad-address-core/BadAddressesFilterForm";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({
    modal: {
      maxWidth: "800px",
      minWidth: "800px",
      minHeight: "auto",
    },
  }),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

AdminBadAddressesFilterWrapper.propTypes = {
  state: PropTypes.object,
  classes: PropTypes.object,
  setState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminBadAddressesFilterWrapper(props) {
  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage("filter", "Filter")}
      onRequestClose={props.onRequestClose}
      paperClassName={props.classes.modal}
    >
      <FlexBox container={8} direction="column">
        <BadAddressesFilterForm
          filter={props.filter}
          onDismiss={props.onRequestClose}
          onFilterChange={props.onFilterChange}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          getCachedServiceType={getCachedServiceType}
          getServiceTypePredictions={getServiceTypePredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminBadAddressesFilterWrapper);
