import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessages } from "../../reducers/LocalizationReducer";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import CustomizableOtpSettingsDialogForm from "./CustomizableOtpSettingsDialogForm";

const valueSelector = formValueSelector("MarketplaceSettingsForm");

const enhancer = compose(
  connect((state, props) => ({
    i18n: getMessages(state),
    field: valueSelector(state, `${props.name}`),
  })),
  reduxForm({
    form: "MarketplaceSettingsForm",
    enableReinitialize: true,
  }),
  withState("state", "setState", {
    isOpenDialog: false,
  }),
  useSheet({
    textCenter: { textAlign: "center" },
    actionTitle: { width: "200px" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    alignSelf: { alignSelf: "center" },
  }),
);

CustomizableIOtpSettingsForm.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  change: PropTypes.func,
  field: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  i18n: PropTypes.instanceOf(Map),
};

function CustomizableIOtpSettingsForm(props) {
  return (
    <FlexBox container={8} flex={true}>
      {props.state.isOpenDialog && (
        <CustomizableOtpSettingsDialogForm
          initialValues={toCamelCase(props.field)}
          type={props.type}
          onSubmit={values => {
            props.change(props.name, toSnakeCase(values));
            props.setState(fp.set("isOpenDialog", false));
          }}
        />
      )}

      <FlexBox gutter={8} flex={true} direction="column">
        <Button onClick={() => props.setState(fp.set("isOpenDialog", true))}>
          {props.i18n.get("open", "Open")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CustomizableIOtpSettingsForm);
