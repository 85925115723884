import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormCheckbox from "../form/FormCheckbox";
import FormDateField from "../form/FormDateField";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    container: { padding: "5px 15px", backgroundColor: "#fff" },
    checkbox: { whiteSpace: "nowrap" },
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  reduxForm({
    enableReinitialize: true,
    form: "OrdersSettlementFilterForm",
  }),
  formValues({ fromDate: "fromDate", toDate: "toDate" }),
  pureComponent(fp.pick(["fromDate", "toDate"])),
);

OrdersSettlementFilterForm.propTypes = {
  classes: PropTypes.object,

  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),

  initialValues: PropTypes.object,
  onChange: PropTypes.func,

  getCachedCustomer: PropTypes.func.isRequired,
  getCustomerPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrdersSettlementFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <div className={classes.container}>
      <form autoComplete="off" onSubmit={props.handleSubmit}>
        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            <FormCustomerAutoComplete
              name="customer"
              fullWidth={true}
              openOnFocus={true}
              placeholder={getLocalisationMessage("customer", "Customer")}
            />
          </FlexBox>

          <FlexBox flex={true} gutter={8}>
            <FlexBox>
              <FormDateField
                name="fromDate"
                fullWidth={true}
                placeholder={getLocalisationMessage("from_date", "From Date")}
              />
            </FlexBox>

            {Boolean(props.fromDate && props.toDate) && (
              <FlexBox>
                <IconButton onClick={() => props.change("fromDate", null)}>
                  <NavigationCancel />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox flex={true} gutter={8}>
            <FlexBox>
              <FormDateField
                name="toDate"
                fullWidth={true}
                placeholder={getLocalisationMessage("to_date", "To Date")}
              />
            </FlexBox>

            {Boolean(props.fromDate && props.toDate) && (
              <FlexBox>
                <IconButton onClick={() => props.change("toDate", null)}>
                  <NavigationCancel />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox flex={true} align="center">
            <FormCheckbox
              name="onlyWithWing"
              label={getLocalisationMessage(
                "show_with_wing_only",
                "Show With Wing Only",
              )}
              className={classes.checkbox}
            />
          </FlexBox>

          <FlexBox flex={true} align="center">
            <FormCheckbox
              name="onlyWithSupplier"
              className={classes.checkbox}
              label={getLocalisationMessage(
                "show_with_supplier_only",
                "Show With Supplier Only",
              )}
            />
          </FlexBox>
        </FlexBox>
      </form>
    </div>
  );
}

export default enhancer(OrdersSettlementFilterForm);
