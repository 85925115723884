import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { WAREHOUSE_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import { createWarehouseItem } from "../../api/admin/AdminWarehouseApi";
import AdminWarehouseItemWrapper from "../../wrappers/admin/AdminWarehouseItemWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdminWarehouseCreate.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminWarehouseCreate(props) {
  return (
    <AdminAppLayout
      title={props.getLocalisationMessage("warehouses", "Warehouses")}
    >
      <AdminWarehouseItemWrapper
        onSubmit={fp.flow(toSnakeCase, (values) =>
          createWarehouseItem(values).catch(ResponseError.throw),
        )}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully saved",
            ),
          );
          props.setLocation(WAREHOUSE_LIST_URL);
        }}
        onSubmitFail={props.showErrorMessage}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminWarehouseCreate);
