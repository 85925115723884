import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({ content: { maxWidth: "320px" } }),
);

InfoDialog.propTypes = {
  sheet: PropTypes.object,
  classes: PropTypes.object,

  contentWidth: PropTypes.oneOf(["sm", "md"]),

  modal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,

  contentClassName: PropTypes.string,
  dismissButtonLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmButtonLabel: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

InfoDialog.defaultProps = {
  contentWidth: "sm",
};

function InfoDialog({
  sheet,
  classes,
  contentClassName,
  onConfirm,
  dismissButtonLabel,
  confirmButtonLabel,
  ...props
}) {
  return (
    <Dialog
      open={props.open}
      contentClassName={cx(
        props.contentWidth === "sm" && classes.content,
        contentClassName,
      )}
      onClose={props.onRequestClose}
    >
      <DialogContent
        style={{
          maxWidth: "320px",
        }}
      >
        {props.children}
      </DialogContent>
      <DialogActions>
        <div>
          {props.onRequestClose && (
            <Button
              label={
                dismissButtonLabel ||
                props.getLocalisationMessage("dismiss", "Dismiss")
              }
              onClick={props.onRequestClose}
            />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(InfoDialog);
