import React from "react";
import { OrderedMap } from "immutable";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Radio } from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";

const radioMap = OrderedMap()
  .set("USD", {
    label: "US dollars(USD)",
  })
  .set("AED", {
    label: "Local currency(AED)",
  });

SubscriptionCurrencyChoose.propTypes = {
  value: PropTypes.string,
  onCheck: PropTypes.func,
};

function SubscriptionCurrencyChoose({ value, onCheck }) {
  return (
    <FlexBox direction="row" flex={4} justify="space-between">
      {radioMap.map((radioValue, key) => (
        <Radio
          key={key}
          value={key}
          label={radioValue.label}
          checked={fp.eq(value, key)}
          onCheck={onCheck}
        />
      ))}
    </FlexBox>
  );
}

export default SubscriptionCurrencyChoose;
