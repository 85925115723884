import React, { useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { createAdditionalService } from "../../api/shared/OrderApi";
import AdditionalServiceForm from "../../components/orders-core/AdditionalServiceForm";
import { ADDITIONAL_SERVICES_SETTINGS_URL } from "../../constants/AdminPathConstants";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdditionalServiceAddWrapper.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  params: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
  setLocation: PropTypes.func,
};

function AdditionalServiceAddWrapper({
  getLocalisationMessage,
  onSubmitFail,
  params,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  setLocation,
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage("additional_services")} - ${params.id}`}
    >
      <AdditionalServiceForm
        edit={false}
        serviceId={params.id}
        isLoading={isLoading}
        onSubmit={(values) => {
          setIsLoading(true);
          const data = toSnakeCase(values);
          createAdditionalService(data)
            .then((res) => {
              if (res.status === "success") {
                setIsLoading(false);
                showSuccessMessage1(
                  getLocalisationMessage("successfully_created"),
                );
                setLocation(ADDITIONAL_SERVICES_SETTINGS_URL);
              } else {
                setIsLoading(false);
                showErrorMessage1(res.message);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              if (onSubmitFail) {
                onSubmitFail(error);
              } else {
                showErrorMessage1(error);
              }
            });
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdditionalServiceAddWrapper);
