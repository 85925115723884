import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS, OrderedMap, OrderedSet, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Badge,
  MenuItem,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import getWidth from "dom-helpers/query/width";
import { indigo } from "@material-ui/core/colors";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import MenuButtonMore from "../ui-core/MenuButtonMore";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData, isEqualDataKeys } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  mergeFilterToFilter,
  unmergeValuesFromFilter,
} from "../../helpers/FilterHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { baseFilter as baseOrderFilter } from "../../helpers/OrderFilterMapper";
import { parserOrderSmartFilter } from "../../helpers/OrderSmartFiltersHelper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { isMarketplaceBusinessTypeFoodDelivery } from "../../reducers/MarketplaceReducer";

const baseFilter = fromJS({
  page: 0,
  size: 1,
  simple: true,
  order_by: null,
  order_by_direction: null,
});

const ignoredFields = OrderedSet.of(
  "page",
  "size",
  "search",
  "order_by",
  "search_type",
  "order_by_direction",
  "status",
  "tab",
);

const getTabName = fp.flow(
  fp.split("?"),
  fp.last,
  fp.split("&"),
  fp.map(fp.split("=")),
  (x) => {
    let tab;

    fp.forEach((v) => {
      if (fp.first(v) === "tab") {
        tab = fp.last(v);
      }
    })(x);

    return tab || "all";
  },
  decodeURI,
);

const generateNewFilter = (props, currentTab) => {
  const { tab } = props.location.query;
  const tabFilter = props.filters.find(
    (i) => i.getValue("tab") === currentTab.get("value"),
  );
  const queryFilter = new DataListFilter().setValueMap({
    ...currentTab.get("filter").toJS(),
    tab,
  });
  return mergeFilterToFilter(
    new DataListFilter().setValueMap(
      tabFilter || { tab: currentTab.get("value") },
    ),
    queryFilter,
    baseOrderFilter,
    ignoredFields,
  );
};

const enhancer = compose(
  connect((state) => ({
    i18n: getMessages(state),
    isFoodDelivery: isMarketplaceBusinessTypeFoodDelivery(state),
  })),
  useSheet({
    tabs: {
      width: "100%",
      // color: "transparent",
    },
    loader: {
      height: "48px",
      backgroundColor: "transparent",
      margin: "auto",
    },
    hidden: {
      top: "-9999px",
      left: "-9999px",
      position: "absolute",
      visibility: "hidden",
    },
    buttonStyle: {
      backgroundColor: "white",
      color: "black",
      borderBottom: "1px solid #eee",
      whiteSpace: "nowrap",
      paddingRight: ".5rem",
      "& > span": {
        "&>div> span": {
          backgroundColor: indigo[500],
          color: "white",
        },
      },
    },
    active: {
      borderBottom: `3px solid ${indigo[500]}`,
    },
  }),
  withRouter,
  mapPropsStream((propsStream) =>
    propsStream.distinctUntilChanged(isEqualData).map((props) => ({
      ...props,
      tabBaseFilters: OrderedMap().withMutations((map) => {
        props.filterList
          .filter((i) => i.get("is_tab"))
          .sort((a, b) => {
            const aOrder = a.get("sort_order");
            const bOrder = b.get("sort_order");

            if (aOrder > bOrder) {
              return 1;
            }

            if (aOrder < bOrder) {
              return -1;
            }

            return 0;
          })
          .forEach((i) => {
            map.set(i.get("name"), i.get("filter"));
          });
      }),
    })),
  ),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const buttonNodes = createEventHandler();
        const containerNode = createEventHandler();

        const showButtonsStream = containerNode.stream
          .combineLatest(
            buttonNodes.stream
              .scan((acc, [idx, x]) => {
                if (!x) {
                  delete acc[idx];
                } else {
                  acc[idx] = getWidth(x);
                }
                return acc;
              }, {})
              .distinctUntilChanged(isEqualData),
            propsStream
              .map(fp.get("size.width"))
              .distinctUntilChanged()
              .debounceTime(100),
            (container, buttonWidths) => {
              const containerWidth = !container ? 0 : getWidth(container);
              const showButtons = {};

              if (containerWidth > 0) {
                let totalWidth = 0;

                fp.keys(buttonWidths).forEach((i) => {
                  totalWidth += buttonWidths[i];

                  if (totalWidth < containerWidth) {
                    showButtons[i] = true;
                  }
                });
              }

              return showButtons;
            },
          )
          .distinctUntilChanged(isEqualData)
          .startWith({});

        return propsStream.combineLatest(
          showButtonsStream,
          (props, showButtons) => ({
            ...props,
            showButtons,
            buttonNodeRef: buttonNodes.handler,
            containerNodeRef: containerNode.handler,
          }),
        );
      },
      (propsStream) => {
        const tabsStream = propsStream
          .distinctUntilChanged(
            isEqualDataKeys(["filter", "basePathname", "tabBaseFilters"]),
          )
          .map((props) => {
            const filters = props.tabBaseFilters.map((x, idx) => {
              const filter = props.filter.setValueMap(
                parserOrderSmartFilter(x.set("tab", fp.snakeCase(idx))),
              );

              let queryFilter = props.filter;

              const currentFilter = props.tabBaseFilters
                .filter(
                  (i, value) =>
                    fp.snakeCase(value) ===
                    getTabName(props.router.location.search),
                )
                .first();

              if (
                fp.snakeCase(idx) !== getTabName(props.router.location.search)
              ) {
                queryFilter = unmergeValuesFromFilter(
                  props.filter,
                  currentFilter,
                  x,
                );
              }

              return mergeFilterToFilter(filter, queryFilter);
            });

            return {
              filters,
              batchGetOrderListCount: props.batchGetOrderListCount,

              value: getTabName(props.router.location.search),

              tabs: filters.map((filter, label) => {
                const href = props.router.createHref({
                  pathname: props.basePathname,
                  query: filter.getDefinedValues(),
                });

                const value = getTabName(href);

                return Map({
                  label: props.i18n.get(value, label),
                  value,
                  href,
                  filter,
                });
              }),
            };
          })
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(tabsStream, (props, tabResponse) => ({
          ...props,
          tabs: tabResponse.tabs,
          filters: tabResponse.filters,
          value: tabResponse.value,
          batchGetOrderListCount: tabResponse.batchGetOrderListCount,
        }));
      },
    ),
  ),
  mapPropsStream((propsStream) => {
    const tabCountsStream = propsStream
      .map(fp.pick(["filters", "getOrderList", "batchGetOrderListCount"]))
      .distinctUntilChanged(isEqualData)
      .switchMap((props) =>
        !props.batchGetOrderListCount
          ? Observable.of(Map())
          : props.batchGetOrderListCount(
              props.filters.map((x) => ({
                filter: x.setValueMap(baseFilter),
                getOrderList: props.getOrderList,
              })),
            ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(tabCountsStream, (props, tabCounts) => ({
        ...props,
        value: props.value,
        tabs: props.tabs.map((tab, label) =>
          tab.set("count", tabCounts.get(label)),
        ),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

RadarOrderFilterTabs.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  batchGetOrderListCount: PropTypes.func,

  filterList: PropTypes.oneOfType([OrderedMap, List]),
  tabs: PropTypes.oneOfType([OrderedMap, List]),
  filters: PropTypes.instanceOf(OrderedMap),

  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  isLoading: PropTypes.bool,
  onFilterClick: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),

  showButtons: PropTypes.object,
  buttonNodeRef: PropTypes.func,
  containerNodeRef: PropTypes.func,
  onCustomFilterClick: PropTypes.func,
  onOrderRefreshRequest: PropTypes.func,
  showCustomFilter: PropTypes.bool,
  newOrdersCount: PropTypes.number,
  isFoodDelivery: PropTypes.bool,
};

RadarOrderFilterTabs.defaultProps = {
  showCustomFilter: false,
};

function RadarOrderFilterTabs(props) {
  const { classes, i18n } = props;

  if (props.isLoading)
    return (
      <FlexBox flex={true}>
        <FlexBox
          align="center"
          justify={JUSTIFY_CENTER}
          className={classes.loader}
        >
          <CircularProgress color="#2E3359" size={24} thickness={2} />
        </FlexBox>
      </FlexBox>
    );

  return (
    <FlexBox flex={true}>
      <FlexBox
        flex={true}
        element={
          <span style={{ fontSize: ".1px" }} ref={props.containerNodeRef} />
        }
      >
        <FlexBox className={classes.tabs}>
          {props.tabs.map((x, key) => (
            <div
              key={key}
              ref={(ref) => props.buttonNodeRef([key, ref])}
              className={cx(!props.showButtons[key] && classes.hidden)}
            >
              <Button
                className={cx(classes.buttonStyle, {
                  [classes.active]: x.get("value") === props.location.query.tab,
                })}
                onClick={() => {
                  const mergedFilters = generateNewFilter(props, x);
                  props.onFilterClick(mergedFilters);
                }}
              >
                <TabBadge label={x.get("label")} badge={x.get("count")} />
              </Button>
            </div>
          ))}
        </FlexBox>
      </FlexBox>
      <FlexBox>
        {fp.toFinite(props.newOrdersCount) > 0 ? (
          <Badge
            badgeContent={props.newOrdersCount}
            secondary={true}
            badgeStyle={{
              backgroundColor: "red",
              color: "white",
              right: 20,
              top: 20,
            }}
            style={{ marginTop: -20, marginRight: -20 }}
          >
            <IconButton
              onClick={props.onOrderRefreshRequest}
              tooltip={
                props.newOrdersCount > 0
                  ? `${i18n.get("you_have", `You have`)} ${
                      props.newOrdersCount
                    } ${fp.lowerCase(i18n.get("new_orders"))}`
                  : undefined
              }
            >
              <Refresh />
            </IconButton>
          </Badge>
        ) : (
          <IconButton
            onClick={props.onOrderRefreshRequest}
            tooltip={
              props.newOrdersCount > 0
                ? `${i18n.get("you_have", `You have`)} ${
                    props.newOrdersCount
                  } ${fp.lowerCase(i18n.get("new_orders"))}`
                : undefined
            }
          >
            <Refresh />
          </IconButton>
        )}

        <MenuButtonMore>
          {props.tabs
            .map((x, key) => {
              if (props.showButtons[key]) return null;
              return (
                <MenuItem
                  key={key}
                  rightIcon={
                    <Badge
                      badgeContent={
                        !fp.isUndefined(x.get("count")) ? x.get("count") : ""
                      }
                      badgeStyle={{
                        color: "white",
                        backgroundColor: !fp.isUndefined(x.get("count"))
                          ? indigo[500]
                          : "transparent",
                      }}
                    />
                  }
                  onClick={() =>
                    props.onFilterClick(generateNewFilter(props, x))
                  }
                >
                  {i18n.get(x.get("value"), x.get("label"))}
                </MenuItem>
              );
            })
            .toList()}

          {props.showCustomFilter && (
            <MenuItem onClick={props.onCustomFilterClick}>
              {i18n.get("customize_filters", "Customize Filters")}
            </MenuItem>
          )}
        </MenuButtonMore>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(RadarOrderFilterTabs);
