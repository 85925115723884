import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toCallTimingsFilter } from "../../helpers/CallTimingsFilterMapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  clearFinanceSettlementQueue,
  updateFinanceSettlementQueue,
} from "../../reducers/FinanceSettlementReducer";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import { getCallTimingsSettings } from "../../api/admin/AdminSettingsApi";
import AdminCallTimingEditDialogWrapper from "../../wrappers/admin/AdminCallTimingEditDialogWrapper";
import AdminCallTimingCreateDialogWrapper from "../../wrappers/admin/AdminCallTimingCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import CallTimingsList from "../../components/call-step-settings/CallTimingsList";
import CallStepSettingsFilterForm from "../../components/call-step-settings/CallStepSettingsFilterForm";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  useSheet({
    orders: {
      flex: "1 1 0%",
      display: "flex",
      marginTop: "12px",
      "& > div": { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    },
    appBarRightAction: {
      marginTop: "8px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
    appBarRightActionButton: {
      color: "#fff",
      top: "-7px",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      clearFinanceSettlementQueue,
      updateFinanceSettlementQueue,
    },
  ),
  getContext({
    setLocationQuery: PropTypes.func,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toCallTimingsFilter))
      .distinctUntilChanged(isEqualData);

    const formInitialValuesStream = filterStream
      .map((filter: DataListFilter) => ({
        courierType: filter.getValue("courierType"),
        addressType: _.toLower(filter.getValue("addressType")),
        callTimingPermissionType: _.toLower(
          filter.getValue("callTimingPermissionType"),
        ),
      }))
      .distinctUntilChanged(isEqualData);

    const responseStream = filterStream
      .switchMap(filter =>
        getCallTimingsSettings(filter.setValue("use_solr", true))
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error =>
            Observable.of({
              error: error.reasonMessage || error.message,
            }),
          ),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              isLoading: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        responseStream,
        formInitialValuesStream,
        (props, filter, response, formInitialValues) => ({
          ...props,
          filter,
          formInitialValues,
          onRequestRefresh,
          list: response.get("list"),
          total: response.get("total"),
          listError: response.get("error"),
          isLoading: response.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminCallStepSettingsList.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  listError: PropTypes.string,
  onRequestRefresh: PropTypes.func,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  formInitialValues: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function AdminCallStepSettingsList(props) {
  const {
    sheet: { classes },
    location,
    i18n,
  } = props;

  return (
    <AdminAppLayout
      title={i18n.get("call_step_settings", "Call Step Settings")}
    >
      <AdminCallTimingCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminCallTimingEditDialogWrapper
        callId={fp.toFinite(location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
      />

      <Card>
        <CardContent>
          <CallStepSettingsFilterForm
            getCachedCustomer={getCachedCustomer}
            getCustomerPredictions={getCustomerPredictions}
            initialValues={props.formInitialValues}
            onChange={(values, dispatch, formProps) => {
              if (formProps.dirty) {
                const nextFilter = props.filter.setValueMap({
                  page: 0,
                  addressType: _.toUpper(values.addressType),
                  courierType: _.toUpper(values.courierType),
                  callTimingPermissionType: _.toUpper(
                    values.callTimingPermissionType,
                  ),
                });

                if (!props.filter.equals(nextFilter)) {
                  props.setLocationQueryFilter(nextFilter);
                }
              }
            }}
          />
        </CardContent>
      </Card>

      <Card className={classes.orders}>
        <CallTimingsList
          list={props.list}
          selectable={props.filter.getValue("customer_id") > 0}
          totalCount={props.total}
          isLoading={props.isLoading}
          filter={props.filter}
          maxSearchItems={Infinity}
          createOrderHref={id => updateQuery(location, fp.set("view", id))}
          onFilterChange={filter => props.setLocationQueryFilter(filter)}
          cardActionIcons={
            <div>
              <Link to={updateQuery(location, fp.set("create", true))}>
                <Tooltip
                  title={i18n.get("create_new_order", "Create New Order")}
                >
                  <IconButton>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
          }
        />
      </Card>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCallStepSettingsList);
