import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { validateReferenceForLocalisation } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({ content: { maxWidth: "320px" } }),

  reduxForm({
    form: "UpdateCODWithdrawReferenceDialog",
    validate: (values, props) => ({
      reference: validateReferenceForLocalisation(
        values.reference,
        props.getLocalisationMessage,
      ),
    }),
  }),
);

UpdateCODWithdrawReferenceDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function UpdateCODWithdrawReferenceDialog(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogContent
        style={{
          maxWidth: "320px",
        }}
      >
        <form autoComplete="off">
          <FormTextField
            name="reference"
            fullWidth={true}
            label={getLocalisationMessage("payment_ref", "Payment ref #")}
          />

          <FormDateField
            name="bankPaymentDate"
            label={getLocalisationMessage(
              "bank_payment_date",
              "Bank Payment Date",
            )}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(UpdateCODWithdrawReferenceDialog);
