import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import PopoverOverlay from "../ui-core/PopoverOverlay";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText, formatDateTime } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { toActivityHistoryFilter } from "../../helpers/ActivityHistoryFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import ActivityHistoryStatus, {
  SMS,
  EMAIL,
  WHATSAPP,
} from "../../constants/ActivityHistoryStatus";
import { getSmsLog } from "../../api/admin/AdminSMSLogApi";
import AdminActivityHistoryFilterWrapper from "../../wrappers/admin/AdminActivityHistoryFilterWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import ChipTextField from "../../components/deprecated/ChipTextField";
import ComingSoonContainer from "../../components/activity-history-core/ComingSoonContainer";
import ActivityHistoryTableTabs from "../../components/activity-history-core/ActivityHistoryTableTabs";
import { primary1 } from "../../../shared/theme/main-theme";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { IconButton } from "@material-ui/core";
import { lightBlack, white } from "../../../shared/theme/depricatedMUIColors";

const styles = {
  hint: { bottom: "11px" },
  input: { marginBottom: "20px" },
  chipContainer: {
    maxHeight: "56px",
    float: "left",
    overflowX: "hidden",
    overflowY: "auto",
  },
};

const SMS_LOG_FILTER_DIALOG_HASH = "#SLFDH";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  useSheet({
    container: {
      backgroundColor: "#F1F1F1",
      height: "100%",
      backgroundPosition: "center",
      backgroundSize: "cover",
      position: "relative",
      color: "black",
      fontSize: "25px",
    },
    wrapper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
    search: { height: "60px" },
    root: {
      height: "64px",
      padding: "0 14px",
      position: "relative",
      backgroundColor: white,
      borderBottom: `1px solid ${grey[300]}`,
      "& svg": { color: lightBlack },
    },
  }),
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toActivityHistoryFilter))
      .distinctUntilChanged(isEqualData);

    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const smsLogListStream = filterStream
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter.getValue("logType") !== EMAIL
          ? getSmsLog(filter)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream)
          : Observable.of({}),
      )
      .map(response => fromJS(response))
      .map(response =>
        Map({
          pending: response.get("pending", false),
          total: response.getIn(["payload", "data", "total"], 0),
          list: response.getIn(["payload", "data", "list"], List()),
        }),
      )
      .distinctUntilChanged(isEqualData);

    const chipsStream = filterStream
      .map(filter => filter && filter.getSearch())
      .distinctUntilChanged()
      .map(parseString)
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      filterStream,
      smsLogListStream,
      chipsStream,
      (props, filter, smsLogList, chips) => ({
        ...props,
        onRequestRefresh,
        filter,
        chips,
        smsLogList: smsLogList.get("list"),
        isLoading: smsLogList.get("pending"),
        total: smsLogList.get("total"),
      }),
    );
  }),
);

AdminActivityHistoryListContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  total: PropTypes.number,
  smsLogList: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  chips: PropTypes.array,
};

function AdminActivityHistoryListContainer(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <AdminAppLayout
      slug="activity_history"
      title={getLocalisationMessage("activity_history", "Activity History")}
    >
      <PageLoading isLoading={props.isLoading} />

      <Redirect
        when={!ActivityHistoryStatus.has(props.location.query.logType)}
        to={updateQuery(
          props.location,
          fp.set("logType", ActivityHistoryStatus.first()),
        )}
      />

      <ActivityHistoryTableTabs location={props.location} />

      <AdminActivityHistoryFilterWrapper
        open={props.location.hash === SMS_LOG_FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />
      {props.smsLogList && props.smsLogList.size > 0 ? (
        <DataList
          overscanRowCount={4}
          totalCount={props.total}
          rowCount={props.smsLogList.size}
          filter={props.filter}
          isLoading={props.isLoading}
          list={props.smsLogList}
          onFilterChange={props.setLocationQueryFilter}
          rowGetter={options => props.smsLogList.get(options.index)}
          cardActionIcons={
            <div>
              <IconButton
                tooltip={props.getLocalisationMessage(
                  "activity_history_filter",
                  "Activity History Filter",
                )}
                containerElement={
                  <Link
                    to={updateHash(props.location, SMS_LOG_FILTER_DIALOG_HASH)}
                  />
                }
              >
                <FilterList />
              </IconButton>
            </div>
          }
        >
          <DataListColumn
            width={30}
            label={getLocalisationMessage("id", "id")}
            dataKey="id"
            disableSort={true}
            justifyContent="center"
            cellRenderer={row => (
              <div style={{ color: primary1 }}>{row.cellData.get("id")}</div>
            )}
          />
          {props.filter.getValue("logType") === WHATSAPP && (
            <DataListColumn
              width={30}
              label={getLocalisationMessage("order_number", "Order Number")}
              dataKey="order_code"
              disableSort={true}
              justifyContent="center"
              cellRenderer={row => (
                <div style={{ color: primary1 }}>
                  {row.cellData.get("order_code") ||
                    getLocalisationMessage("na", "N/A")}
                </div>
              )}
            />
          )}
          <DataListColumn
            width={30}
            label={getLocalisationMessage("phone", "Phone")}
            dataKey="phone"
            disableSort={true}
            justifyContent="center"
            cellRenderer={row => row.cellData.get("phone")}
          />
          <DataListColumn
            width={50}
            label={getLocalisationMessage("provider", "Provider")}
            dataKey="provider"
            disableSort={true}
            justifyContent="center"
            cellRenderer={row =>
              props.filter.getValue("logType") === SMS
                ? row.cellData.get("provider") ||
                  getLocalisationMessage("na", "N/A")
                : row.cellData.get("whats_app_provider") ||
                  getLocalisationMessage("na", "N/A")
            }
          />
          <DataListColumn
            label={getLocalisationMessage("status", "Status")}
            width={100}
            cellRenderer={row =>
              getLocalisationMessage(
                row.cellData.get("status").toLowerCase(),
                formatText(row.cellData.get("status")),
              ) || getLocalisationMessage("na", "N/A")
            }
            dataKey="status"
            disableSort={true}
          />
          <DataListColumn
            label={getLocalisationMessage("message", "Message")}
            width={100}
            cellRenderer={row => (
              <PopoverOverlay
                width={350}
                content={
                  row.cellData.get("error_message") ||
                  getLocalisationMessage("na", "N/A")
                }
              >
                <span>
                  {row.cellData.get("error_message") ||
                    getLocalisationMessage("na", "N/A")}
                </span>
              </PopoverOverlay>
            )}
            dataKey="error_message"
            disableSort={true}
          />
          <DataListColumn
            label={getLocalisationMessage("content", "Content")}
            width={300}
            cellRenderer={row => (
              <PopoverOverlay
                width={350}
                content={
                  row.cellData.get("content") ||
                  getLocalisationMessage("na", "N/A")
                }
              >
                <span>
                  {row.cellData.get("content") ||
                    getLocalisationMessage("na", "N/A")}
                </span>
              </PopoverOverlay>
            )}
            dataKey="content"
            disableSort={true}
          />
          <DataListColumn
            label={getLocalisationMessage("date_time", "Date Time")}
            width={100}
            cellRenderer={row =>
              formatDateTime(row.cellData.get("date_time")) ||
              getLocalisationMessage("na", "N/A")
            }
            dataKey="date_time"
            disableSort={true}
          />
        </DataList>
      ) : props.filter.getValue("logType") === EMAIL ? (
        <ComingSoonContainer />
      ) : (
        !props.isLoading && (
          <div>
            {props.filter && (
              <FlexBox
                className={classes.root}
                wrap="reverse"
                justify="flex-end"
              >
                <FlexBox className={classes.search} flex={true} align="center">
                  <ChipTextField
                    fullWidth={true}
                    addOnBlur={false}
                    clearOnBlur={false}
                    hintStyle={styles.hint}
                    inputStyle={styles.input}
                    value={props.chips}
                    hintText={getLocalisationMessage("search", "Search")}
                    chipContainerStyle={styles.chipContainer}
                    onChange={value =>
                      props.setLocationQueryFilter(
                        props.filter.setSearch(stringifyArray(value)),
                      )
                    }
                  />
                  <IconButton
                    style={{
                      marginRight: 20,
                    }}
                    tooltip={props.getLocalisationMessage(
                      "activity_history_filter",
                      "Activity History Filter",
                    )}
                    containerElement={
                      <Link
                        to={updateHash(
                          props.location,
                          SMS_LOG_FILTER_DIALOG_HASH,
                        )}
                      />
                    }
                  >
                    <FilterList />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            )}
            <div className={classes.wrapper}>
              <h1>{`${getLocalisationMessage(
                "no_activity",
                "No Activity",
              )}!!!`}</h1>
              <hr />
            </div>
          </div>
        )
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminActivityHistoryListContainer);
