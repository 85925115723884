import { useEffect } from "react";
import { useNavigationHooks } from "../hooks/core-hooks/useNavigationHooks";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";

const RoleStatusType = OrderedSet.of("ACTIVE", "INACTIVE");

export const usePermissionForm = props => {
  const navigation = useNavigationHooks(props.router);

  useEffect(() => {
    props.fetchPermissionList();
    const roleId = fp.get("location.query.role_id", props);
    if (roleId) {
      props.fetchRoleById(roleId);
    }
  }, []);

  return { navigation, statusTypes: RoleStatusType };
};
