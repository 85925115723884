import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  CardActions,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FormCountryV2AutoComplete from "../form/FormCountryV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue, isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { validateString, isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CompanyTypes from "../../constants/CompanyTypes";
import marketplaceStatuses from "../../constants/MarketplaceStatusConstants";
import { getZones } from "../../api/shared/MarketplaceApi";

const FORM = "MarketplaceEditForm";
const valueSelector = formValueSelector(FORM);

const enhancer = compose(
  connect(state => ({
    countryCode: getValue(valueSelector(state, "country"), "description"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
    padding: { paddingTop: 10 },
    card: { width: "100%" },
    map: { flex: "1 1 0%", zIndex: 0 },
    listView: { paddingTop: 0, paddingBottom: 0 },
    imageContainer: {
      paddingLeft: "15px",
      " & img": {
        width: "100%",
        maxWidth: "300px",
      },
    },
  }),
  withContext(
    {
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("marketplace", isEqualData)
      .map(props => {
        const supplier = props.marketplace.get("supplier", Map()) || Map();
        const country = props.marketplace.get("country", Map()) || Map();

        return {
          name: props.marketplace.get("name", ""),
          reason: props.marketplace.get("reason", ""),
          type: props.marketplace.get("type", ""),
          business_type: props.marketplace.get("business_type", ""),
          country: country.toJS(),
          zone: { name: props.marketplace.getIn(["zone", "name"], null) },
          supplier: supplier.toJS(),
        };
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        initialValues,
        companyTypes: OrderedSet.of().withMutations(node => {
          CompanyTypes.forEach(item => {
            node.add(item.value);
          });
        }),
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: FORM,
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("enter_name", "Enter name")
        : null,
      country:
        !isValidObjectId(values.country) &&
        props.getLocalisationMessage(
          "please_select_country",
          "Please select country",
        ),
      zone:
        !getValue(values.zone, "name") &&
        props.getLocalisationMessage(
          "please_select_zone",
          "Please select zone",
        ),
    }),
  }),

  mapPropsStream(
    pipeStreams(
      propsStream => {
        const zoneStream = propsStream
          .distinctUntilKeyChanged("countryCode", isEqualData)
          .filter(props => props.countryCode)
          .distinctUntilChanged(isEqualData)
          .switchMap(props =>
            getZones(props.countryCode).catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response => fromJS(response).getIn(["payload", "data"], List()))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(zoneStream, (props, zones) => ({
            ...props,
            zones,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilKeyChanged("zones", isEqualData)
            .filter(props => !props.zones.isEmpty() && props.zones.size === 1)
            .distinctUntilChanged(isEqualData)
            .do(props => {
              const item = props.zones.get(0);

              if (item) {
                props.change("zone", item);
              }
            }),
        ).startWith(null);

        return propsStream
          .combineLatest(sideEffectsStream, fp.identity)
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

MarketplaceEditForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  zones: PropTypes.instanceOf(List),
  companyTypes: PropTypes.instanceOf(List),
  marketplace: PropTypes.instanceOf(Map),
  onSubmit: PropTypes.func,

  getCachedCountry: PropTypes.func,
  getCountryPredictions: PropTypes.func,
  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function MarketplaceEditForm(props) {
  const {
    zones,
    classes,
    getLocalisationMessage,
    marketplace,
    companyTypes,
  } = props;

  const logoURL = marketplace.get("logo_url", null);

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <Card className={classes.card}>
        <CardContent>
          <FlexBox container={8} flex={true}>
            <FlexBox flex={true} direction="column" container={8}>
              <FormTextField
                name="name"
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "marketplace_name",
                  "Marketplace Name",
                )} *`}
              />

              <FormCountryV2AutoComplete
                readOnly={false}
                fullWidth={true}
                openOnFocus={true}
                name="country"
                label={`${getLocalisationMessage("country", "Country")} *`}
                placeholder={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />

              <FormSelectField
                name="zone"
                fullWidth={true}
                label={getLocalisationMessage("select_zone", "Select Zone")}
                formatOption={item => item.get("name")}
                compareOptions={isEqualDataIn("name")}
                options={zones}
                disabled={zones.isEmpty()}
              />

              <FormSupplierAutoComplete
                name="supplier"
                fullWidth={true}
                label={getLocalisationMessage(
                  "select_supplier",
                  "Select Supplier",
                )}
                disabled={true}
                placeholder={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />

              <FlexBox justify="flex-end">
                <CardActions>
                  {props.dirty ? (
                    <Button onClick={props.reset}>
                      {getLocalisationMessage("reset", "Reset")}
                    </Button>
                  ) : (
                    Boolean(props.onDismiss) && (
                      <Button onClick={props.onDismiss}>
                        {getLocalisationMessage("dismiss", "Dismiss")}
                      </Button>
                    )
                  )}

                  <Button onClick={props.handleSubmit}>
                    {getLocalisationMessage("submit", "Submit")}
                  </Button>
                </CardActions>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} container={8} direction="column">
              <FormSelectField
                name="type"
                autoWidth={true}
                fullWidth={true}
                label={getLocalisationMessage("select_type", "Select Type")}
                formatOption={x => getLocalisationMessage(x) || formatText(x)}
                options={marketplaceStatuses}
              />

              <FormSelectField
                name="business_type"
                fullWidth={true}
                floatingLabelText={getLocalisationMessage(
                  "select_business_type",
                  "Select Business Type",
                )}
                formatOption={x => getLocalisationMessage(x, formatText(x))}
                options={companyTypes}
              />

              <FormTextField
                name="reason"
                fullWidth={true}
                multiLine={true}
                rows={3}
                rowsMax={3}
                label={getLocalisationMessage("reason", "Reason")}
              />

              <ListSubheader>
                {getLocalisationMessage(
                  "default_account_details",
                  "Default Account Details",
                )}
              </ListSubheader>
              <FlexBox flex={true}>
                <FlexBox flex={true}>
                  <ListItemText
                    className={classes.listView}
                    disabled={true}
                    primary={getLocalisationMessage("login", "Login")}
                    secondary={marketplace.getIn(["default_user", "login"])}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <ListItemText
                    className={classes.listView}
                    disabled={true}
                    primary={getLocalisationMessage("full_name", "Full name")}
                    secondary={`${marketplace.getIn(
                      ["default_user", "first_name"],
                      "",
                    )} ${marketplace.getIn(["default_user", "last_name"], "")}`}
                  />
                </FlexBox>
              </FlexBox>

              <ListSubheader>
                {getLocalisationMessage("logo", "Logo")}
              </ListSubheader>
              <div className={classes.imageContainer}>
                {logoURL && (
                  <img
                    alt={getLocalisationMessage("contact", "Contact")}
                    src={logoURL}
                  />
                )}
              </div>
            </FlexBox>
          </FlexBox>
        </CardContent>
      </Card>
    </form>
  );
}

export default enhancer(MarketplaceEditForm);
