import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import AreaMultiAliasField from "../area/AreaMultiAliasField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { createNotEmptyValidator } from "../../helpers/FormUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatText } from "../../helpers/FormatUtils";
import { normalizeSlug } from "../../helpers/NormalizeUtils";
import { parseToMultipolygonLeaflet } from "../../helpers/MapPolygonUtils";
import { getMarketplace } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedCountry,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";

const enhancer = compose(
  connect(state => ({
    marketplace: getMarketplace(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    root: { padding: "0 24px", position: "relative" },
    CircularProgressContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
    CircularProgress: {
      boxShadow: "none",
      position: "relative",
    },
  }),
  reduxForm({
    form: "NewMapAreasForm",
    enableReinitialize: true,
  }),
  formValues({
    name: "name",
    importCode: "import_code",
    custom: "custom",
  }),
  withContext(
    {
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
    },
    fp.always({
      getCachedCountry,
      getCountryPredictions,
    }),
  ),
  mapPropsStream(propsStream =>
    propsStream
      .combineLatest(
        propsStream
          .map(fp.pick(["areaList", "editItem", "createItem", "editPolygons"]))
          .distinctUntilChanged(isEqualData)
          .map(({ areaList, editPolygons, editItem, createItem }) => {
            if (createItem && editPolygons.size > 0) {
              return true;
            }

            if (!createItem) {
              const currentPolygon = areaList
                .find(x => x.get("id") === editItem)
                .get("polygon");

              if (
                editItem > 0 &&
                editPolygons.size > 0 &&
                !isEqualData(editPolygons, currentPolygon)
              ) {
                return true;
              }
            }

            return false;
          }),
        (props, changedPolygon) => ({
          ...props,

          changedPolygon,
        }),
      )
      .distinctUntilChanged(isEqualData),
  ),
  pureComponent(
    fp.pick(["pristine", "submitting", "initialValues", "changedPolygon"]),
  ),
);

NewMapAreasItemForm.propTypes = {
  classes: PropTypes.object,

  initialValues: PropTypes.object,

  handleSubmit: PropTypes.func,

  name: PropTypes.string,
  custom: PropTypes.bool,
  importCode: PropTypes.string,
  change: PropTypes.func,

  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  changedPolygon: PropTypes.bool,

  onDelete: PropTypes.func,
  onDismiss: PropTypes.func,
  onSave: PropTypes.func,

  isAdminArea: PropTypes.bool,
  createItem: PropTypes.bool,
  editItem: PropTypes.number,

  ariaList: PropTypes.instanceOf(Immutable.List),

  editPolygons: PropTypes.instanceOf(Immutable.List),
  getLocalisationMessage: PropTypes.func,
  marketplace: PropTypes.instanceOf(Immutable.Map),
};

function NewMapAreasItemForm({ classes, getLocalisationMessage, ...props }) {
  return (
    <FlexBox
      gutter={8}
      direction="column"
      className={classes.root}
      element={
        <form
          onSubmit={props.handleSubmit(values =>
            props.onSave(
              toSnakeCase({
                zindex: 0,
                id: values.id,
                name: values.name,
                code: values.code,
                category: values.category,
                country_id: values.country.id,
                importCode: values.importCode,
                aliases: values.aliases,
                polygon_edited: values.polygonEdited,
                polygon: parseToMultipolygonLeaflet(props.editPolygons.toJS()),
              }),
            ),
          )}
        />
      }
    >
      <FlexBox>
        <FormTextField
          name="name"
          disabled={props.createItem ? false : !props.custom}
          fullWidth={true}
          label={`${getLocalisationMessage("name", "Name")} *`}
          validate={createNotEmptyValidator(
            getLocalisationMessage("enter_name", "Enter Name"),
          )}
        />
      </FlexBox>
      <FlexBox>
        <FormCountryAutoComplete
          name="country"
          disabled={true}
          fullWidth={true}
          label={`${getLocalisationMessage("country", "Country")} *`}
          validate={createNotEmptyValidator(
            getLocalisationMessage("select_country", "Select Country"),
          )}
          hintText={getLocalisationMessage(
            "type_to_search",
            "Type to Search ...",
          )}
        />
      </FlexBox>
      {props.isAdminArea && (
        <FlexBox>
          <FormSelectField
            name="category"
            fullWidth={true}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            label={`${getLocalisationMessage("category", "Category")} *`}
            options={Immutable.OrderedSet(["CITY", "COUNTRY"])}
            validate={createNotEmptyValidator(
              getLocalisationMessage("select_category", "Select Category"),
            )}
          />
        </FlexBox>
      )}
      <FlexBox>
        <FormTextField
          fullWidth={true}
          name="importCode"
          label={`${getLocalisationMessage("import_code", "Import Code")} *`}
          validate={createNotEmptyValidator(
            getLocalisationMessage("enter_import_code", "Enter Import Code"),
          )}
          onChange={({ target }) =>
            props.change("code", normalizeSlug(target.value))
          }
        />
      </FlexBox>
      <FlexBox>
        <FormTextField
          fullWidth={true}
          name="code"
          label={getLocalisationMessage("code", "Code")}
          disabled={true}
        />
      </FlexBox>
      <FlexBox>
        <AreaMultiAliasField name="aliases" />
      </FlexBox>
      <FlexBox justify="flex-end">
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            {Boolean(props.initialValues && props.initialValues.id) && (
              <Button
                onClick={() => props.onDelete(props.initialValues.id)}
                type="button"
                secondary={true}
              >
                {getLocalisationMessage("delete", "Delete")}
              </Button>
            )}
          </FlexBox>
          <FlexBox>
            <Button onClick={props.onDismiss} type="button">
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
          </FlexBox>
          <FlexBox>
            <Button
              type="submit"
              disabled={
                props.editPolygons.size === 0 ||
                (props.pristine && !props.changedPolygon)
              }
            >
              {getLocalisationMessage("save", "Save")}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      {props.submitting && (
        <FlexBox
          align="center"
          justify="center"
          className={classes.CircularProgressContainer}
        >
          <CircularProgress
            className={classes.CircularProgress}
            top={0}
            left={0}
            status="loading"
          />
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(NewMapAreasItemForm);
