import { Observable } from "rxjs";
import React from "react";
import sprintf from "sprintf";
import { Map, Set, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withTheme } from "@material-ui/core/styles";
import AdminMarketplaceSettingsContainer from "./AdminMarketplaceSettingsContainer";
import { isEqualData } from "../../../helpers/DataUtils";
import ResponseError from "../../../helpers/ResponseError";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  SETTINGS_MARKETPLACES_URL,
  SETTINGS_MARKETPLACE_ITEM_LOGO_URL,
  SETTINGS_MARKETPLACE_ITEM_DETAILS_URL,
  SETTINGS_MARKETPLACE_ITEM_SETTINGS_URL,
} from "../../../constants/AdminPathConstants";
import {
  LOGO,
  GENERAL,
  SETTINGS,
} from "../../../constants/MarketplaceItemTabs";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedCountry,
  getCountryPredictions,
} from "../../../api/shared/CountryV2Api";
import {
  updateMarketplace,
  getMarketplacesItem,
  uploadMarketplaceLogo,
} from "../../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import Redirect from "../../../components/router/Redirect";
import FlexBox from "../../../components/ui-core/FlexBox";
import RoutingTabs from "../../../components/deprecated/RoutingTabs";
import MarketplaceEditForm from "../../../components/marketplaces-core/MarketplaceEditForm";
import MarketplaceLogoForm from "../../../components/marketplaces-core/MarketplaceLogoForm";
import { responsive } from "../../../../shared/theme/jss-responsive";

const tabTypes = Set.of(GENERAL, SETTINGS, LOGO);

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    appBarRightAction: { height: "100%", paddingBottom: "8px" },
    appBarRightActionButton: {
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  mapPropsStream(propsStream => {
    const {
      stream: onRequestRefreshStream,
      handler: onRequestRefresh,
    } = createEventHandler();

    const marketplaceStream = propsStream
      .map(fp.flow(fp.get("params.marketplaceId"), fp.toFinite))
      .distinctUntilChanged()
      .switchMap(id =>
        getMarketplacesItem(id)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      );

    return propsStream
      .combineLatest(marketplaceStream, (props, marketplace) => ({
        ...props,
        onRequestRefresh,
        marketplace: marketplace.get("payload"),
        isLoading: marketplace.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminMarketplaceItem.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  params: PropTypes.object,
  isLoading: PropTypes.bool,
  marketplace: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminMarketplaceItem(props) {
  const { params, classes, getLocalisationMessage } = props;

  const detailsUrl = sprintf(
    SETTINGS_MARKETPLACE_ITEM_DETAILS_URL,
    params.marketplaceId,
  );

  return (
    <AdminAppLayout
      title={
        props.isLoading
          ? getLocalisationMessage("loading", "Loading...")
          : props.marketplace.get("name")
      }
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Link to={SETTINGS_MARKETPLACES_URL}>
            <Button labelStyle={{ color: props.theme.palette.appBarTextColor }}>
              {getLocalisationMessage("go_to_list", "Go To List")}
            </Button>
          </Link>
        </FlexBox>
      }
    >
      <Redirect when={!tabTypes.has(params.tab)} to={detailsUrl} />

      <RoutingTabs
        width={300}
        mergeQuery={false}
        omitQueryParams={["page"]}
        tabs={[
          {
            label: getLocalisationMessage("details", "Details"),
            value: detailsUrl,
          },
          {
            label: getLocalisationMessage("settings", "Settings"),
            value: sprintf(
              SETTINGS_MARKETPLACE_ITEM_SETTINGS_URL,
              params.marketplaceId,
            ),
          },
          {
            label: getLocalisationMessage("logo", "Logo"),
            value: sprintf(
              SETTINGS_MARKETPLACE_ITEM_LOGO_URL,
              params.marketplaceId,
            ),
          },
        ]}
      />

      {params.tab === GENERAL && (
        <MarketplaceEditForm
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          marketplace={props.marketplace}
          onSubmit={values =>
            updateMarketplace({
              ...values,
              id: props.marketplace.get("id"),
            }).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
            props.onRequestRefresh();
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      {params.tab === SETTINGS && (
        <AdminMarketplaceSettingsContainer
          item={props.marketplace.get("setting")}
          marketplaceId={props.marketplace.get("id")}
        />
      )}

      {params.tab === LOGO && (
        <MarketplaceLogoForm
          marketplace={props.marketplace}
          marketplaceId={props.marketplace.get("id")}
          onSubmit={fp.flow(
            values =>
              uploadMarketplaceLogo(props.marketplace.get("id"), values),
            request => request.toPromise().catch(ResponseError.throw),
          )}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
            props.onRequestRefresh();
          }}
          onSubmitFail={error => {
            props.showErrorMessage(error);
          }}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminMarketplaceItem);
