import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { formatLanguage, Languages } from "../../constants/LocaleTypes";
import FormSelectField from "../form/FormSelectField";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: {
      "& > div": { display: "flex", flexDirection: "column", flex: "1 1 0%" },
    },
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        languageCode: props.filter.getValue("languageCode"),
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            languageCode: null,
          });
          if (!fp.isEmpty(values.languageCode)) {
            filter.setValue(
              "languageCode",
              stringifyArray(values.languageCode),
            );
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "SampleItemsFilterForm", enableReinitialize: true }),
);

SampleItemsFilterForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  includeSuppliers: PropTypes.bool,
  dirty: PropTypes.bool,
  onDismiss: PropTypes.func,
  onFilterChange: PropTypes.func,
  reset: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function SampleItemsFilterForm(props) {
  const { classes } = props;

  return (
    <FlexBox
      container={16}
      element={<form onSubmit={props.handleSubmit} />}
      flex={true}
    >
      <FlexBox
        gutter={8}
        flex={true}
        element={<Card className={classes.card} />}
        direction="column"
      >
        <FlexBox element={<CardContent />} justify="center">
          <FlexBox direction="column">
            <FormSelectField
              name="languageCode"
              options={Languages}
              formatOption={value =>
                formatLanguage(value, props.getLocalisationMessage)
              }
              label={props.getLocalisationMessage("language", "Language")}
              fullWidth={true}
            />
          </FlexBox>
        </FlexBox>
        <FlexBox element={<CardActions />}>
          <FlexBox style={{ marginTop: "1rem" }} justify="flex-end">
            <Button
              onClick={() => {
                props.change("languageCode", null);
              }}
            >
              {props.getLocalisationMessage("clear", "Clear")}
            </Button>
            {props.dirty ? (
              <Button onClick={props.reset}>
                {props.getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {props.getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}

            <Button type="submit">
              {props.getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SampleItemsFilterForm);
