import { isFuture, isTomorrow } from "date-fns";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import { RuleList } from "../helpers/Rule";
import {
  RTO,
  ON_HOLD,
  FUTURE_DELIVERY_FUTURE,
  FUTURE_DELIVERY_TOMORROW,
  FUTURE_DELIVERY_UNSORTED,
} from "../constants/OrderSortingReturnField";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const RESET_TASK = "ORDER_SORTING_RETURN/RESET_TASK";
const REMOVE_TASK = "ORDER_SORTING_RETURN/REMOVE_TASK";
const UPDATE_TASK = "ORDER_SORTING_RETURN/UPDATE_TASK";

const ADD_ORDER_NUMBERS = "ORDER_SORTING_RETURN/ADD_ORDER_NUMBERS";
const REMOVE_ORDER_NUMBERS = "ORDER_SORTING_RETURN/REMOVE_ORDER_NUMBERS";

const FETCH_ORDER_NUMBERS = "ORDER_SORTING_RETURN/FETCH_ORDER_NUMBERS";
const FULFILL_ORDER_NUMBERS = "ORDER_SORTING_RETURN/FULFILL_ORDER_NUMBERS";

const ORDERS_ASSIGNED = "ORDER_SORTING_RETURN/ORDERS_ASSIGNED";
const ORDERS_NOT_ASSIGNED = "ORDER_SORTING_RETURN/ORDERS_NOT_ASSIGNED";

const CHANGE_ORDERS_BIN = "ORDER_SORTING_RETURN/CHANGE_ORDERS_BIN";
const REGROUP_BINS_BY_RULES = "ORDER_SORTING_RETURN/REGROUP_BINS_BY_RULES";

const isOrderSortingAction = fp.startsWith("ORDER_SORTING_RETURN");

const castToMap = x => (Map.isMap(x) ? x : Map());
const castToSet = x => (Set.isSet(x) ? x : Set());

export const FAILED_BIN = "Failed";
export const LOADING_BIN = "Loading";
export const UNSORTED_BIN = "Unsorted";

const getFutureDeliveryType = fp.flow(
  val => new Date(val),
  val =>
    isTomorrow(val)
      ? FUTURE_DELIVERY_TOMORROW
      : isFuture(val)
      ? FUTURE_DELIVERY_FUTURE
      : FUTURE_DELIVERY_UNSORTED,
);

const orderToRuleMatcher = order => ({
  attempt: order.getIn(["delivery_attempt_count"]),
  delivery_type: getFutureDeliveryType(order.getIn(["last_status_date"])),
  status: order.getIn(["status"]),
  warehouse: { id: order.getIn(["destination_warehouse", "id"]) },
});

const createBinName = rule => {
  if (!rule) {
    return UNSORTED_BIN;
  }

  const groupBy = rule.get("group_by");

  if (groupBy) {
    const nameChunks = [];

    if (groupBy.contains(ON_HOLD)) {
      nameChunks.push(ON_HOLD);
    }

    if (groupBy.contains(RTO)) {
      nameChunks.push(RTO);
    }

    if (groupBy.contains(FUTURE_DELIVERY_TOMORROW)) {
      nameChunks.push(FUTURE_DELIVERY_TOMORROW);
    }
    if (groupBy.contains(FUTURE_DELIVERY_FUTURE)) {
      nameChunks.push(FUTURE_DELIVERY_FUTURE);
    }
    if (groupBy.contains(FUTURE_DELIVERY_UNSORTED)) {
      nameChunks.push(FUTURE_DELIVERY_UNSORTED);
    }

    if (nameChunks.length > 0) {
      return nameChunks.join(" - ");
    }
  }

  return rule.get("name");
};

const fulfillMissedOrderBins = state => {
  const binRules = state.getIn(["task", "binRules"]);
  const binRuleList = new RuleList(binRules.toJS());

  const orders = state.get("orders");
  const taskBins = state.getIn(["task", "orderBins"]);
  const taskOrders = state.getIn(["task", "orderNumbers"]);

  return state.withMutations(nextState => {
    taskOrders.forEach(number => {
      if (taskBins.has(number)) {
        return;
      }

      const order = orders.get(number);

      if (order) {
        if (order.get("payload")) {
          const ruleCode = binRuleList.process(
            orderToRuleMatcher(order.get("payload")),
          );

          nextState.setIn(
            ["task", "orderBins", number],
            createBinName(binRules.get(ruleCode), order.get("payload")),
          );
        } else if (order.get("failed")) {
          nextState.setIn(["task", "orderBins", number], FAILED_BIN);
        }
      } else {
        nextState.setIn(["task", "orderBins", number], LOADING_BIN);
      }
    });
  });
};

const cleanupState = fp.flow(castToMap, initialState =>
  initialState.withMutations(state => {
    state.update("orders", castToMap);

    state.update(
      "task",
      fp.flow(
        castToMap,
        x => x.update("activeOrder", fp.trim),
        x => x.update("orderBins", castToMap),
        x => x.update("binRules", castToMap),
        x => x.update("counter", castToSet),
        x => x.update("orderNumbers", castToSet),
        x => x.update("selectedOrders", castToSet),
        x =>
          x.update("warehouse", w =>
            Map.isMap(w) && fp.isFinite(w.get("id")) ? w : null,
          ),
      ),
    );
  }),
);

const compactTask = task => {
  const taskOrders = task.get("orderNumbers");

  return task.withMutations(nextTask => {
    nextTask.update("selectedOrders", x => x.intersect(taskOrders));
    nextTask.update("orderBins", x => x.filter((v, k) => taskOrders.has(k)));
    nextTask.update("activeOrder", x =>
      taskOrders.has(x) ? x : taskOrders.last(),
    );
  });
};

const selector = injectReducer(
  "orderReturnV2.1",
  (initialState = cleanupState(), action) => {
    const state = isOrderSortingAction(action.type)
      ? cleanupState(initialState)
      : initialState;

    switch (action.type) {
      case RESET_TASK: {
        const taskOrders = state.getIn(["task", "orderNumbers"]);

        return state.withMutations(nextState => {
          nextState.update("task", compactTask);

          nextState.update("orders", x =>
            x.filter((v, k) => taskOrders.has(k)),
          );
        });
      }

      case REMOVE_TASK: {
        return cleanupState(
          Map({
            task: Map({
              binRules: state.getIn(["task", "binRules"]),
              warehouse: state.getIn(["task", "warehouse"]),
            }),
          }),
        );
      }

      case UPDATE_TASK:
        return state.withMutations(nextState => {
          nextState.set("task", action.payload);

          nextState.update(cleanupState);
          nextState.update("task", compactTask);
        });

      case ADD_ORDER_NUMBERS: {
        const { payload } = action;

        return state.withMutations(nextState => {
          nextState.update(
            "task",
            fp.flow(
              task =>
                task
                  .set("activeOrder", payload.last())
                  .update("counter", x => x.merge(payload))
                  .update("orderNumbers", x => x.merge(payload)),
              compactTask,
            ),
          );
        });
      }

      case REMOVE_ORDER_NUMBERS:
        return state.withMutations(nextState => {
          nextState.updateIn(["task", "orderNumbers"], x =>
            x.subtract(action.payload),
          );

          nextState.update("task", compactTask);
        });

      case FETCH_ORDER_NUMBERS: {
        const {
          meta: { numbers },
        } = action;

        return state.withMutations(nextState => {
          numbers.forEach(number => {
            nextState.updateIn(["orders", number], Map({ number }), x =>
              x.merge({ failed: false }),
            );
          });
        });
      }
      case FULFILL_ORDER_NUMBERS: {
        const { payload } = action;

        return state.withMutations(nextState => {
          nextState.update("orders", x => x.mergeDeep(payload));
          nextState.update(fulfillMissedOrderBins);
        });
      }

      case CHANGE_ORDERS_BIN: {
        const {
          meta: { numbers },
          payload,
        } = action;
        const taskOrders = state.getIn(["task", "orderNumbers"]);

        return state.withMutations(nextState => {
          numbers.forEach(number => {
            if (taskOrders.has(number)) {
              nextState.setIn(["task", "orderBins", number], payload);
            }
          });
        });
      }

      case REGROUP_BINS_BY_RULES:
        return state.withMutations(nextState => {
          nextState.updateIn(["task", "orderBins"], x => x.clear());
          nextState.update(fulfillMissedOrderBins);
        });

      default:
        return state;
    }
  },
);

export const getOrderSortingTask = state => selector(state).get("task");
export const getOrderSortingOrders = state => selector(state).get("orders");

export const resetSortingTask = () => ({ type: RESET_TASK });
export const removeSortingTask = () => ({ type: REMOVE_TASK });

export const updateSortingTask = updater => (dispatch, getState) => {
  dispatch({
    type: UPDATE_TASK,
    payload: updater(getOrderSortingTask(getState())),
  });
};

export const fetchOrderSortingTaskOrders = numbers => ({
  meta: { numbers },
  type: FETCH_ORDER_NUMBERS,
});
export const fulfillOrderSortingTaskOrders = orders => ({
  payload: orders,
  type: FULFILL_ORDER_NUMBERS,
});

export const orderSortingTaskOrdersAssigned = numbers => ({
  payload: numbers,
  type: ORDERS_ASSIGNED,
});

export const orderSortingTaskOrdersNotAssigned = numbers => ({
  payload: numbers,
  type: ORDERS_NOT_ASSIGNED,
});

export const addSortingTaskOrderNumbers = numbers => ({
  payload: numbers,
  type: ADD_ORDER_NUMBERS,
});

export const removeSortingTaskOrders = numbers => ({
  payload: numbers,
  type: REMOVE_ORDER_NUMBERS,
});

export const changeSortingTaskOrdersBin = (bin, numbers) => ({
  payload: bin,
  meta: { numbers },
  type: CHANGE_ORDERS_BIN,
});

export const regroupSortingTaskBinsByRules = () => ({
  type: REGROUP_BINS_BY_RULES,
});
