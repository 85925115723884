import React from "react";
import FormTextField from "./FormTextField";
import PropTypes from "prop-types";

const FormTextFieldND = props => (
  <FormTextField
    {...props}
    InputProps={props.InputProps}
    InputLabelProps={props.InputLabelProps}
  />
);

FormTextFieldND.propTypes = {
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  parseOnBlur: PropTypes.func,
  name: PropTypes.string.isRequired,

  focus: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,

  fullWidth: PropTypes.bool,
  multiLine: PropTypes.bool,
  ignoreRtl: PropTypes.bool,
  floatingLabelStyle: PropTypes.object,
  hintStyle: PropTypes.object,

  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  label: PropTypes.node,
  helperText: PropTypes.node,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
};

FormTextFieldND.defaultProps = {
  variant: "outlined",
  InputProps: {
    style: {
      height: 45,
    },
  },
  InputLabelProps: {
    style: {
      marginTop: -4,
    },
  },
};

export default FormTextFieldND;
