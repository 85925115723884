import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { HOLIDAY } from "../../constants/HolidayTypes";
import { saveHolidaySettings } from "../../api/admin/AdminSettingsApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import HolidaySettingsForm from "../../components/settings-core/HolidaySettingsForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px" },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

AdminHolidaySettingsEditDialogWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  initialValues: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminHolidaySettingsEditDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("edit_a_holiday", "Edit A Holiday")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <HolidaySettingsForm
        initialValues={
          props.initialValues
            ? {
                ...toCamelCase(props.initialValues),
                country: props.initialValues.get("country"),
                endDate: new Date(props.initialValues.get("end_date")),
                startDate: new Date(props.initialValues.get("start_date")),
              }
            : null
        }
        onSubmit={({ country, ...values }) =>
          saveHolidaySettings({
            ...toSnakeCase(values),
            day_off_type: HOLIDAY,
            country_id: country.get("id"),
          }).catch(ResponseError.throw)
        }
        onDismiss={props.onRequestClose}
        onSubmitSuccess={() => {
          props.onRequestClose();
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_holiday_settings_updated",
              "Successfully Holiday Settings Updated",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminHolidaySettingsEditDialogWrapper);
