import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getFileUrl2 } from "../../api/shared/FileApi";
import _ from "lodash";
import pencil from "./assets/qalam.png";
import { TransitionUp } from "../dialog/TransitionUp";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import FormTextField from "../form/FormTextField";
import FormFileUploadFieldCustom from "../form/FormFileUploadFieldCustom";
import { Check, CheckCircle, Close, Warning } from "@material-ui/icons";
import { renderIf } from "../../helpers/HOCUtils";
import codNote from "../order-details-dialog/assets/codNote.png";
import imgNotFound from "./assets/vector.png";
import {
  cardType,
  money,
  serviceId,
  urlAVVAL,
} from "../orders-core/AdminPostForm";
import { CARD, CASH } from "../../constants/OrderPaymentTypes";
import FormToggle from "../form/FormToggle";
import {
  createNotFalsyValidator,
  createOtpValidator,
} from "../../helpers/FormUtils";
import { getAVVALUserId } from "../../reducers/ProfileReducer";
import { green, orange, red } from "@material-ui/core/colors";
import FormSelectField from "../form/FormSelectField";
import { verificationOrderOTP } from "../../api/admin/AdminOrderApi";
import { getValue } from "../../helpers/DataUtils";

const useStyles = makeStyles({
  img: {
    margin: "32px 70px",
    width: "140px",
    height: "73px",
    objectFit: "contain",
  },
  codImg: {
    padding: "1rem",
    textAlign: "center",
    backgroundImage: `url(${codNote})`,
    minHeight: "150px",
    width: "100%",
    objectFit: "contain",
    color: "#EB5757",
    backgroundRepeat: "no-repeat",
  },
  blur: {
    filter: "blur(4px)",
  },
  redShadow: {
    boxShadow: "0px 0px 15px red",
    borderRadius: "20px",
  },
});
const enhancer = compose(
  renderIf("open"),
  connect(state => ({
    userIdAVVAL: getAVVALUserId(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "IssuanceForm",
    enableReinitialize: true,
    validate: ({ name, otpCode, otpMessage, withoutOtp }, props) => ({
      name: !name
        ? props.getLocalisationMessage("enter_name", "Enter Name")
        : null,
      otpCode:
        !withoutOtp && !otpCode
          ? props.getLocalisationMessage("enter_otp_code", "Enter Otp Code")
          : otpCode && otpCode.length !== 6
          ? props.getLocalisationMessage("please_enter_6_digit_sms_code")
          : otpMessage || "",
    }),
  }),
  formValues({
    photoId: "photoId",
    signatureId: "signatureId",
    paymentType: "paymentType",
    withoutOtp: "withoutOtp",
    otpCode: "otpCode",
  }),
);

const OrderDetailsDialogCustoms = ({
  close,
  getLocalisationMessage,
  handleSubmit,
  open,
  photoId,
  signatureId,
  isHave,
  chargeItem,
  chargeItemCustomFeePercentage,
  isLoading,
  showOtpSettings,
  withoutOtp,
  paymentType = CASH,
  errorOtpData,
  otpCode,
  setErrorOtpError,
  change,
  userIdAVVAL,
  orderId,
}) => {
  const classes = useStyles();
  const [isVerify, setIsVerify] = useState(!isHave);
  const [isBlur, setIsBlur] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(imgNotFound);
  const [signatureUrl, setSignatureUrl] = useState(pencil);
  const [isLoadingConnectingAVVAL, setIsLoadingConnectingAVVAL] = useState(
    false,
  );
  const [isConnectedAVVAL, setIsConnectedAVVAL] = useState(false);
  useEffect(() => {
    if (photoId) {
      getFileUrl2(photoId).then(res => setPhotoUrl(_.get(res, "data")));
    }
  }, [photoId]);
  useEffect(() => {
    if (signatureId) {
      getFileUrl2(signatureId).then(res => setSignatureUrl(_.get(res, "data")));
    }
  }, [signatureId]);
  const changePaymentType = event => {
    change("paymentType", event.target.value);
    change("cardType", null);
    change("pptId", null);
  };

  useEffect(() => {
    if (userIdAVVAL) {
      setIsLoadingConnectingAVVAL(true);
      fetch(urlAVVAL, {
        method: "POST",
        body: JSON.stringify({
          method: "get-info",
          userId: userIdAVVAL,
          serviceId,
          request: {
            login: "123",
          },
        }),
      })
        .then(() => {
          setIsConnectedAVVAL(true);
        })
        .catch(() => setIsConnectedAVVAL(false))
        .finally(() => setIsLoadingConnectingAVVAL(false));
    }
  }, [userIdAVVAL]);

  useEffect(() => {
    if (otpCode && otpCode.length === 6) {
      verificationOrderOTP({
        order_id: orderId,
        otp: otpCode,
      })
        .then(res => {
          if (!getValue(res, "data")) {
            change(
              "otpMessage",
              getLocalisationMessage(
                "make_sure_you_entered_correct_sms_code",
                "Make sure you entered correct SMS code.",
              ),
            );
          } else {
            change("otpMessage", null);
          }
        })
        .catch(() => {});
    }
  }, [otpCode]);

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      fullWidth={true}
      maxWidth="lg"
    >
      <form autoComplete={false}>
        <DialogTitle
          className={isBlur && classes.blur}
          style={{ position: "relative" }}
        >
          <h4 style={{ textAlign: "center" }}>
            {getLocalisationMessage("issuance")}
          </h4>
          <IconButton
            style={{ position: "absolute", right: "1rem", top: "1rem" }}
            onClick={() => close()}
            size="medium"
          >
            <Close fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FlexBox>
            <FlexBox
              className={isBlur && classes.blur}
              direction="column"
              style={{ width: "63%" }}
            >
              <h6 style={{ marginBottom: "1rem" }}>
                {getLocalisationMessage("download_files")}
              </h6>
              <FlexBox>
                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ margin: "0 .5rem" }}
                >
                  <small>{getLocalisationMessage("order_photo")}</small>
                  <FlexBox
                    flex={true}
                    style={{
                      width: "300px",
                      height: "166px",
                      backgroundColor: "rgba(149, 165, 177, 0.31)",
                      padding: ".5rem",
                    }}
                  >
                    <FlexBox
                      flex={true}
                      style={{
                        border: "1px dashed #546E7A",
                      }}
                    >
                      <FlexBox
                        align={ALIGN_CENTER}
                        direction="column"
                        flex={true}
                        style={{ margin: "1.5rem auto" }}
                      >
                        <img
                          className={classes.img}
                          alt={getLocalisationMessage("signature", "Signature")}
                          src={photoUrl}
                        />
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    direction="column"
                    style={{ width: "300px", padding: "0 .5rem" }}
                    align={ALIGN_CENTER}
                  >
                    <span>{getLocalisationMessage("download_from_pc")}</span>
                    <FormFileUploadFieldCustom
                      fullWidth={true}
                      name="photoId"
                      label={getLocalisationMessage("upload", "UPLOAD")}
                      accept="image/*"
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  flex={true}
                  direction="column"
                  style={{
                    margin: "0 .5rem",
                    borderRight: "1px solid #D1DAE1",
                  }}
                >
                  <small>{getLocalisationMessage("signature")}</small>
                  <FlexBox
                    flex={true}
                    style={{
                      width: "300px",
                      height: "166px",
                      backgroundColor: "rgba(149, 165, 177, 0.31)",
                      padding: ".5rem",
                    }}
                  >
                    <FlexBox
                      flex={true}
                      style={{
                        border: "1px dashed #546E7A",
                      }}
                    >
                      <FlexBox
                        align={ALIGN_CENTER}
                        direction="column"
                        flex={true}
                        style={{ margin: "1.5rem auto" }}
                      >
                        <img
                          className={classes.img}
                          alt={getLocalisationMessage("signature")}
                          src={signatureUrl}
                        />
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    direction="column"
                    style={{ width: "300px", padding: "0 .5rem" }}
                    align={ALIGN_CENTER}
                  >
                    <span>{getLocalisationMessage("poluchi_podpis")}</span>
                    <FormFileUploadFieldCustom
                      fullWidth={true}
                      name="signatureId"
                      label={getLocalisationMessage("upload", "UPLOAD")}
                      accept="image/*"
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            {showOtpSettings && (
              <FlexBox
                direction="column"
                style={{ width: "32%", paddingLeft: "3rem" }}
              >
                <FlexBox direction="column">
                  <FlexBox
                    className={isBlur && classes.blur}
                    direction="column"
                    flex={true}
                    justify={JUSTIFY_CENTER}
                  >
                    <FlexBox style={{ marginBottom: "1.5rem" }}>
                      <FormTextField
                        name="otpCode"
                        fullWidth={true}
                        label={
                          withoutOtp
                            ? getLocalisationMessage(
                                "please_enter_6_digit_sms_code",
                                "Please enter 6-digit SMS code:",
                              )
                            : `${getLocalisationMessage(
                                "please_enter_6_digit_sms_code",
                                "Please enter 6-digit SMS code:",
                              )} *`
                        }
                        validate={createOtpValidator(errorOtpData)}
                        type="number"
                        onInput={e => {
                          // eslint-disable-next-line radix
                          e.target.value = e.target.value
                            .toString()
                            .slice(0, 6);
                        }}
                        onChange={() => {
                          setErrorOtpError(null);
                        }}
                        disabled={Boolean(withoutOtp)}
                      />
                    </FlexBox>
                    <FlexBox>
                      <FormToggle
                        name="withoutOtp"
                        label={getLocalisationMessage(
                          "process_without_sms_code",
                          "Process without SMS code",
                        )}
                        onChange={() => {
                          change("otpCode", null);
                          change("otpMessage", null);
                          setErrorOtpError(null);
                        }}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <FlexBox direction="column" style={{ gap: 16 }}>
                  {withoutOtp && (
                    <h6 className={isBlur && classes.blur}>
                      {getLocalisationMessage("recipient_info")}
                    </h6>
                  )}
                  {withoutOtp && (
                    <FlexBox
                      className={isBlur && classes.blur}
                      direction="column"
                      flex={true}
                      justify={JUSTIFY_CENTER}
                    >
                      <FlexBox style={{ marginBottom: "1.5rem" }}>
                        <FormTextField
                          sty={true}
                          name="name"
                          fullWidth={true}
                          label={getLocalisationMessage(
                            "recipient_name",
                            "recipient_name",
                          )}
                        />
                      </FlexBox>
                      <FlexBox>
                        <FormTextField
                          name="passportId"
                          fullWidth={true}
                          label={getLocalisationMessage("passport_id")}
                        />
                      </FlexBox>
                    </FlexBox>
                  )}
                  {isHave && (
                    <FlexBox style={{ marginTop: "1rem" }} direction="column">
                      <FlexBox direction="column" className={classes.codImg}>
                        <h5 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                          Bojxona xizmati tolovi
                        </h5>
                        <h3>{`${money(chargeItem)} ${getLocalisationMessage(
                          "som",
                          "so'm",
                        )}`}</h3>
                      </FlexBox>
                      <FlexBox direction="column" className={classes.codImg}>
                        <h5 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                          To'lovlarni qabul qilish xizmati
                        </h5>
                        <h3>{`${money(
                          chargeItemCustomFeePercentage,
                        )} ${getLocalisationMessage("som", "so'm")}`}</h3>
                      </FlexBox>
                      <FormControl
                        style={{ marginTop: "1rem" }}
                        component="fieldset"
                      >
                        <span>
                          {getLocalisationMessage("choose_payment_type")}
                        </span>
                        <RadioGroup
                          style={{ flexDirection: "row" }}
                          aria-label="gender"
                          name="paymentType"
                          value={paymentType}
                          onChange={changePaymentType}
                        >
                          <FormControlLabel
                            value={CASH}
                            control={<Radio />}
                            label={getLocalisationMessage(CASH)}
                          />
                          <FormControlLabel
                            value={CARD}
                            control={<Radio />}
                            label={getLocalisationMessage(CARD)}
                          />
                        </RadioGroup>
                      </FormControl>
                      {paymentType === CARD && (
                        <FlexBox flex={true} style={{ gap: 16 }}>
                          <FormSelectField
                            autoWidth={true}
                            fullWidth={true}
                            name="cardType"
                            label={`${getLocalisationMessage("card_type")} *`}
                            options={cardType}
                            formatOption={x =>
                              x === "PRIVATE"
                                ? getLocalisationMessage("personal")
                                : getLocalisationMessage("corporate")
                            }
                            validate={createNotFalsyValidator(
                              getLocalisationMessage("this_field_is_required"),
                            )}
                          />
                          <FormTextField
                            fullWidth={true}
                            name="pptId"
                            type="number"
                            onFocus={event => {
                              event.target.select();
                            }}
                            onInput={e => {
                              // eslint-disable-next-line radix
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 20);
                            }}
                            label={`${getLocalisationMessage(
                              "ppt_id",
                              "PPT ID",
                            )} *`}
                            variant={OUTLINED}
                            validate={createNotFalsyValidator(
                              getLocalisationMessage("this_field_is_required"),
                            )}
                          />
                        </FlexBox>
                      )}
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            )}

            {!showOtpSettings && (
              <FlexBox
                direction="column"
                style={{ width: "32%", paddingLeft: "3rem" }}
              >
                <h6 className={isBlur && classes.blur}>
                  {getLocalisationMessage("recipient_info")}
                </h6>
                <FlexBox
                  className={isBlur && classes.blur}
                  direction="column"
                  flex={true}
                  justify={JUSTIFY_CENTER}
                >
                  <FlexBox style={{ marginBottom: "1.5rem" }}>
                    <FormTextField
                      sty={true}
                      name="name"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "recipient_name",
                        "recipient_name",
                      )}
                    />
                  </FlexBox>
                  <FlexBox>
                    <FormTextField
                      name="passportId"
                      fullWidth={true}
                      label={getLocalisationMessage("passport_id")}
                    />
                  </FlexBox>
                </FlexBox>
                {isHave && (
                  <FlexBox style={{ marginTop: "1rem" }} direction="column">
                    <FlexBox direction="column" className={classes.codImg}>
                      <h5 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                        Bojxona xizmati tolovi
                      </h5>
                      <h3>{`${money(chargeItem)} ${getLocalisationMessage(
                        "som",
                        "so'm",
                      )}`}</h3>
                    </FlexBox>
                    <FlexBox direction="column" className={classes.codImg}>
                      <h5 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                        To'lovlarni qabul qilish xizmati
                      </h5>
                      <h3>{`${money(
                        chargeItemCustomFeePercentage,
                      )} ${getLocalisationMessage("som", "so'm")}`}</h3>
                    </FlexBox>
                    <FormControl
                      style={{ marginTop: "1rem" }}
                      component="fieldset"
                    >
                      <span>
                        {getLocalisationMessage("choose_payment_type")}
                      </span>
                      <RadioGroup
                        style={{ flexDirection: "row" }}
                        aria-label="gender"
                        name="paymentType"
                        value={paymentType}
                        onChange={changePaymentType}
                      >
                        <FormControlLabel
                          value={CASH}
                          control={<Radio />}
                          label={getLocalisationMessage(CASH)}
                        />
                        <FormControlLabel
                          value={CARD}
                          control={<Radio />}
                          label={getLocalisationMessage(CARD)}
                        />
                      </RadioGroup>
                    </FormControl>

                    {paymentType === CARD && (
                      <FlexBox flex={true} style={{ gap: 16 }}>
                        <FormSelectField
                          autoWidth={true}
                          fullWidth={true}
                          name="cardType"
                          label={`${getLocalisationMessage("card_type")} *`}
                          options={cardType}
                          formatOption={x =>
                            x === "PRIVATE"
                              ? getLocalisationMessage("personal")
                              : getLocalisationMessage("corporate")
                          }
                          validate={createNotFalsyValidator(
                            getLocalisationMessage("this_field_is_required"),
                          )}
                        />
                        <FormTextField
                          fullWidth={true}
                          name="pptId"
                          type="number"
                          onFocus={event => {
                            event.target.select();
                          }}
                          onInput={e => {
                            // eslint-disable-next-line radix
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 20);
                          }}
                          label={`${getLocalisationMessage(
                            "ppt_id",
                            "PPT ID",
                          )} *`}
                          variant={OUTLINED}
                          validate={createNotFalsyValidator(
                            getLocalisationMessage("this_field_is_required"),
                          )}
                        />
                      </FlexBox>
                    )}
                  </FlexBox>
                )}
              </FlexBox>
            )}
          </FlexBox>
        </DialogContent>
        <DialogActions style={{ margin: "1rem 0" }}>
          <FlexBox
            direction="column"
            flex={true}
            justify={JUSTIFY_CENTER}
            align={ALIGN_CENTER}
          >
            {isHave && (
              <FlexBox
                style={{ marginBottom: "0.5rem" }}
                justify={JUSTIFY_CENTER}
                flex={true}
              >
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                      color: isLoadingConnectingAVVAL
                        ? orange[500]
                        : isConnectedAVVAL
                        ? green[500]
                        : red[500],
                    }}
                  >
                    {getLocalisationMessage(
                      !userIdAVVAL
                        ? "user_profile_is_not_connected_with_avval_profile"
                        : isLoadingConnectingAVVAL
                        ? "connecting_to_avval"
                        : isConnectedAVVAL
                        ? "user_is_connected_to_avval"
                        : "user_is_not_connected_to_avval",
                    )}
                  </span>
                  {isLoadingConnectingAVVAL ? (
                    <CircularProgress
                      size={20}
                      style={{ color: orange[500] }}
                    />
                  ) : isConnectedAVVAL ? (
                    <CheckCircle style={{ color: green[500] }} />
                  ) : (
                    <Warning style={{ color: red[500] }} />
                  )}
                </h5>
              </FlexBox>
            )}
            <FlexBox flex={true} justify={JUSTIFY_CENTER} align={ALIGN_CENTER}>
              {isHave && (
                <FormControlLabel
                  className={isBlur && classes.redShadow}
                  control={
                    <Checkbox
                      checked={isVerify}
                      onChange={(e, v) => {
                        setIsVerify(v);
                        setIsBlur(!v);
                      }}
                    />
                  }
                  label={`${getLocalisationMessage("verify_cod")} .`}
                />
              )}
              <CustomButton
                disabled={isLoading}
                size="small"
                style={{ padding: "10px 6rem" }}
                variant={CONTAINED}
                color={SECONDARY}
                endIcon={
                  isLoading ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Check />
                  )
                }
                onClick={() => {
                  if (isVerify) {
                    setIsBlur(false);
                    handleSubmit();
                  } else {
                    setIsBlur(true);
                  }
                }}
              >
                {getLocalisationMessage("finish")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      </form>
    </Dialog>
  );
};
OrderDetailsDialogCustoms.propTypes = {
  close: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  open: PropTypes.bool,
  photoId: PropTypes.number,
  signatureId: PropTypes.number,
  handleSubmit: PropTypes.func,
  isHave: PropTypes.func,
  chargeItem: PropTypes.string,
  chargeItemCustomFeePercentage: PropTypes.string,
  isLoading: PropTypes.bool,
  showOtpSettings: PropTypes.bool,
  withoutOtp: PropTypes.bool,
  paymentType: PropTypes.string,
  otpCode: PropTypes.string,
  orderId: PropTypes.string,
  errorOtpData: PropTypes.string,
  userIdAVVAL: PropTypes.string,
  change: PropTypes.func,
  setErrorOtpError: PropTypes.func,
};

export default enhancer(OrderDetailsDialogCustoms);
