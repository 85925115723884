import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Add as ContentAdd } from "@material-ui/icons";
import { isEqualData } from "../../../helpers/DataUtils";
import { pipeStreams } from "../../../helpers/StreamUtils";
import ResponseError from "../../../helpers/ResponseError";
import DataListFilter from "../../../helpers/DataListFilter";
import { toDataListFilter } from "../../../helpers/DataListFilterMapper";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  deleteServiceType,
  getServiceTypesList,
} from "../../../api/admin/AdminServiceTypesApi";
import FormDialog from "../../../components/form/FormDialog";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import ServiceTypesList from "../../../components/service-types-core/ServiceTypesList";
import AdminServiceTypesEditDialogWrapper from "../../../wrappers/admin/service-types/AdminServiceTypesEditDialogWrapper";
import AdminServiceTypesCreateDialogWrapper from "../../../wrappers/admin/service-types/AdminServiceTypesCreateDialogWrapper";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getServiceTypesList(props.filter.setValue("use_solr", true))
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response => fromJS(response))
          .map(response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminServiceTypesListContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
};

function AdminServiceTypesListContainer(props) {
  const { getLocalisationMessage } = props;
  const removeId = fp.toFinite(props.location.query.remove);

  return (
    <AdminAppLayout
      slug="service_types"
      title={getLocalisationMessage("service_types", "Service Types")}
    >
      <FormDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset("remove"))}
        onSubmit={() => deleteServiceType(removeId).catch(ResponseError.throw)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("remove"));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_service_type",
          "Are you sure you want to remove this Service Type?",
        )}
      </FormDialog>

      <AdminServiceTypesCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminServiceTypesEditDialogWrapper
        itemId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
      />

      <ServiceTypesList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        location={props.location}
        cardActionIcons={
          <div>
            <Tooltip
              title={getLocalisationMessage(
                "create_new_service_type",
                "Create New Service Type",
              )}
            >
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <ContentAdd />
              </IconButton>
            </Tooltip>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminServiceTypesListContainer);
