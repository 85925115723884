import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncAssignSupplier } from "../../api/admin/AdminOrderApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import BatchAssignSupplierDialog from "../../components/orders-core/BatchAssignSupplierDialog";

AdminBatchAssignSupplierDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  disableOrders: PropTypes.bool,
  disableSupplier: PropTypes.bool,

  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

export default function AdminBatchAssignSupplierDialogWrapper(props) {
  return (
    <BatchAssignSupplierDialog
      open={props.open}
      disableOrders={props.disableOrders}
      disableSupplier={props.disableSupplier}
      onRequestClose={props.onRequestClose}
      initialValues={props.initialValues}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncAssignSupplier(values).catch(ResponseError.throw),
      )}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
    />
  );
}
