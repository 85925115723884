import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { grey, red } from "@material-ui/core/colors";

export const EDIT = "edit";
export const SAVE = "save";
export const DATE = "date";
export const COLUMN = "column";
export const RENDER = "render";
export const ACTION = "action";

const EnhancedTableHead = (props) => (
  <TableHead>
    <TableRow>
      {props.headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "right" : "left"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={false}
        >
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
};

const useStyles = makeStyles({
  container: {
    maxHeight: 320,
  },
  errorRow: {
    backgroundColor: red[100],
  },
  loadingRow: {
    transition: "none 0s ease 0s",
    "background-image":
      "-webkit-linear-gradient(135deg, rgba(255,255,255,0.125) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.125) 50%, rgba(255,255,255,0.125) 75%, transparent 75%, transparent)",
    backgroundImage:
      "linear-gradient(-45deg, rgba(255,255,255,0.125) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.125) 50%, rgba(255,255,255,0.125) 75%, transparent 75%, transparent)",
    backgroundSize: "35px 35px",
    boxShadow: "inset 0px -1px 2px rgba(0,0,0,0.1)",
    backgroundColor: grey[400],
    "-webkit-animation": "cssProgressActive 2s linear infinite",
    animation: "cssProgressActive 2s linear infinite",
  },
});

function HybridOrdersSimpleTable({
  columns,
  getLocalisationMessage,
  list,
  size,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const renderOrderStatus = (row) => {
    if (row.has("error")) {
      return (
        <span className={classes.errorText}>{row.get("message", "")}</span>
      );
    }

    return <div>{getLocalisationMessage("loading")}</div>;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={10}
              headCells={columns}
            />
            <TableBody>
              {list.map((row, index) => {
                if (!row.has("id")) {
                  const isError = !!row.get("error", false);

                  return (
                    <TableRow
                      hover={false}
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={
                        isError ? classes.errorRow : classes.loadingRow
                      }
                    >
                      <TableCell padding="normal">
                        {columns[0].render(row)}
                      </TableCell>
                      <TableCell padding="normal" colSpan={4} align="center">
                        {renderOrderStatus(row)}
                      </TableCell>
                      <TableCell padding="normal" align="right">
                        {columns[columns.length - 1].render(row)}
                      </TableCell>
                    </TableRow>
                  );
                }

                return (
                  <TableRow
                    hover={true}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {columns.map((item, i) => {
                      if (item.type === RENDER) {
                        return (
                          <TableCell
                            key={i}
                            padding="normal"
                            align={item.align}
                          >
                            {item.render(row)}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={i} padding="normal">
                          {row[item.name]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

HybridOrdersSimpleTable.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  columns: PropTypes.array,
  size: PropTypes.oneOf(["small", "medium"]),
};

export default enhancer(HybridOrdersSimpleTable);
