import React from "react";
import { List, fromJS } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LinkButton from "../ui-core/LinkButton";
import DataList, { DataListColumn } from "../data-list/DataList";
import { formatDate, FULL_DATE_URL_FORMAT } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const WORKED_YES = "YES";
const WORKED_NO = "NO";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    result: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      lineHeight: "55px",
      fontWeight: "bold",
      transition: ".255s ease",
      backgroundColor: "transparent",
      "&:hover": {
        textDecoration: "none",
      },
    },
    resultYes: {
      color: "#119c11",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#4ffd00",
      },
    },
    resultNo: {
      color: "#a72323",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#ff3b3b",
      },
    },
    resultContainer: { padding: 0 },
    row: {
      backgroundColor: "transparent",
      "&:hover": { backgroundColor: "transparent" },
    },
  }),
);

DriversAttendanceTable.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  onFilterChange: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  days: PropTypes.instanceOf(List),
  onClickCell: PropTypes.func,
};

function DriversAttendanceTable(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.total}
      list={props.list}
      rowCount={props.list.size}
      overscanRowCount={8}
      getRowClassName={() => classes.row}
      rowGetter={options => props.list.get(options.index)}
      filter={props.filter}
      onFilterChange={props.onFilterChange}
    >
      {props.days.size > 0 && (
        <DataListColumn
          width={150}
          label={getLocalisationMessage("driver_name", "Driver Name")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => row.cellData.getIn(["driver", "name"])}
        />
      )}

      {props.days.map(item => (
        <DataListColumn
          key={item}
          width={94}
          label={formatDate(item, FULL_DATE_URL_FORMAT) || item}
          dataKey="worked"
          disableSort={true}
          justifyContent="center"
          className={classes.resultContainer}
          cellRenderer={row => {
            const worked = row.cellData.getIn(["period", item, "worked"]);

            return (
              <LinkButton
                onClick={() =>
                  props.onClickCell(
                    fromJS({
                      checkInIds: row.cellData.getIn([
                        "period",
                        item,
                        "check_in_ids",
                      ]),
                      worked: row.cellData.getIn(["period", item, "worked"]),
                    }),
                  )
                }
                className={cx(
                  classes.result,
                  { [classes.resultNo]: worked === WORKED_NO },
                  { [classes.resultYes]: worked === WORKED_YES },
                )}
              >
                {getLocalisationMessage(worked, worked)}
              </LinkButton>
            );
          }}
        />
      ))}
    </DataList>
  );
}

export default enhancer(DriversAttendanceTable);
