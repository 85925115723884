import { Observable } from "rxjs";
/* eslint-disable no-param-reassign */
import React from "react";
import _ from "lodash";
import { Map, List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  withContext,
  withHandlers,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, formValueSelector } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Add as ContentAdd } from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormMarketplaceAutoComplete from "../form/FormMarketplaceAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import GridStack from "../gridstack/GridStack";
import GridStackItem from "../gridstack/GridStackItem";
import { withPropsSubscription } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getDefaultAttributes } from "../../api/admin/AdminPdfSettingsApi";
import PdfAttributeDetailDialog from "../../components/pdf/PdfAttributeDetailDialog";

const valueSelector = formValueSelector("AdminPdfTemplateForm");

const uniqueId = () => {
  const id = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${id()}-${id()}`;
};

const enhancer = compose(
  useSheet({
    grid: {
      marginTop: "20px",
      display: "grid",
      gridGap: "10px",
      gridTemplateColumns: "auto auto auto",
    },
    field: {
      border: "1px dashed #000000",
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        color: "#40a9ff",
        border: "1px dashed #40a9ff",
        transition: "all 0.3s ease",
      },
    },
    fieldName: {
      padding: "5px",
      height: "35px",
    },
    addBtn: {
      width: "35px",
      height: "35px",
      padding: "0",
    },
    pdfPaper: {
      width: "21cm",
      height: "29.7cm",
      display: "block",
      margin: "0 auto",
      marginBottom: "0.5cm",
      padding: "24px",
      boxShadow: "0 0 0.5cm rgba(0,0,0,0.5)",
    },
  }),
  withState("state", "setState", {
    attribute: Map(),
    attributes: List(),
    showItemModal: false,
  }),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }
      if (!props.state.attributes.size) {
        return props.onSubmitFail("Please add pdf attributes");
        // return null
      }
      return props.onSubmit({
        ...values,
        attributes: props.state.attributes.toJS(),
      });
    },
    onAddAttribute: props => attribute =>
      props.setState(
        fp.set(
          "attributes",
          props.state.attributes.push(
            attribute.delete("id").set("uniqueId", uniqueId()),
          ),
        ),
      ),
    onChangeAttribute: props => changedAttributes => {
      const oldAttributes = [...props.state.attributes.toJS()];
      changedAttributes.forEach(changedItem => {
        oldAttributes.forEach(oldItem => {
          if (oldItem.uniqueId === changedItem.id) {
            oldItem.x = changedItem.x;
            oldItem.y = changedItem.y;
            oldItem.width = changedItem.width;
            oldItem.height = changedItem.height;
            oldItem.min_height = changedItem.minHeight;
            oldItem.min_width = changedItem.minWidth;
            oldItem.max_width = changedItem.maxWidth;
            oldItem.max_height = changedItem.maxHeight;
          }
        });
      });
      props.setState(fp.set("attributes", fromJS(oldAttributes)));
    },
    onDeleteAttribute: props => id => {
      const oldAttributes = [...props.state.attributes.toJS()].filter(
        item => item.uniqueId !== id,
      );
      props.setState(fp.set("attributes", fromJS(oldAttributes)));
    },
    showAttributeDetail: props => attribute => {
      props.setState(values => ({ ...values, showItemModal: true, attribute }));
    },
    closeAttributeDetail: props => () => {
      props.setState(values => ({
        ...values,
        showItemModal: false,
        attribute: Map(),
      }));
    },
    saveAttributeDetail: props => attribute => {
      const oldAttributes = props.state.attributes.toJS().map(item => {
        if (item.uniqueId === attribute.uniqueId) {
          return { ...attribute };
        }
        return { ...item };
      });
      props.setState(values => ({
        ...values,
        showItemModal: false,
        attribute: Map(),
        attributes: fromJS(oldAttributes),
      }));
    },
  }),
  withContext(
    {
      types: PropTypes.instanceOf(List),
      orientationCodes: PropTypes.instanceOf(OrderedSet),
      getMarketplaceCache: PropTypes.func,
      getMarketplacePredictions: PropTypes.func,
    },
    props => ({
      types: props.types,
      orientationCodes: props.orientationCodes,
      getMarketplaceCache: props.getMarketplaceCache,
      getMarketplacePredictions: props.getMarketplacePredictions,
    }),
  ),
  reduxForm({
    enableReinitialize: true,
    form: "AdminPdfTemplateForm",
    validate: values => ({
      name: !values.name && "Enter name",
      templateType: !values.templateType && "Select Template Type",
      marketplaceId:
        !isValidObjectId(values.marketplaceId) && "Select Marketplace",
    }),
  }),
  withPropsSubscription(
    ({ initialValues: a }, { initialValues: b }) => !_.isEqual(a, b),
    ({ initialValues: value, setState }) => {
      if (value && value.attributes) {
        setState(
          fp.set(
            "attributes",
            fromJS(
              value.attributes.map(item => ({
                ...item,
                uniqueId: uniqueId(),
              })),
            ),
          ),
        );
      }
    },
  ),
  connect(state => ({
    values: valueSelector(state, "templateType", "marketplaceId"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const defaultAttributeStream = propsStream
      .map(props =>
        props.values && props.values.templateType
          ? props.values.templateType
          : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(templateType =>
        templateType
          ? getDefaultAttributes({ templateType }).catch(error =>
              Observable.of({ error }),
            )
          : Observable.of({}),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], List()),
            }),
        ),
      )
      .startWith(List());
    // .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(defaultAttributeStream, (props, attributesResponse) => ({
        ...props,
        defaultAttributes: attributesResponse.get("payload"),
        isLoading: attributesResponse.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
  formValues({ marketplaceId: "marketplaceId" }),
);

AdminPdfTemplateForm.propTypes = {
  classes: PropTypes.object,

  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  getMarketplace: PropTypes.func,

  types: PropTypes.instanceOf(List),
  state: PropTypes.object,
  defaultAttributes: PropTypes.instanceOf(List),
  orientationCodes: PropTypes.instanceOf(OrderedSet),

  setState: PropTypes.func,
  onAddAttribute: PropTypes.func,
  onChangeAttribute: PropTypes.func,
  onDeleteAttribute: PropTypes.func,
  showAttributeDetail: PropTypes.func,
  closeAttributeDetail: PropTypes.func,
  saveAttributeDetail: PropTypes.func,
  getMarketplaceCache: PropTypes.func,
  getMarketplacePredictions: PropTypes.func,
};

function AdminPdfTemplateForm(props) {
  const {
    types,
    state,
    classes,
    onAddAttribute,
    orientationCodes,
    onChangeAttribute,
    onDeleteAttribute,
    defaultAttributes,
    showAttributeDetail,
    saveAttributeDetail,
    closeAttributeDetail,
    getLocalisationMessage,
  } = props;
  return (
    <form autoComplete="off" onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true}>
                  <FormMarketplaceAutoComplete
                    name="marketplaceId"
                    fullWidth={true}
                    label="Marketplace"
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="templateType"
                    fullWidth={true}
                    autoWidth={true}
                    options={types}
                    formatOption={formatText}
                    label="Template Type"
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true}>
                  <FormTextField name="name" fullWidth={true} label="Name" />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="orientation"
                    fullWidth={true}
                    autoWidth={true}
                    options={orientationCodes}
                    formatOption={formatText}
                    label="Orientation"
                  />
                </FlexBox>
              </FlexBox>
              <div className={classes.grid}>
                {defaultAttributes &&
                  defaultAttributes.map(item => (
                    <div key={item.get("id")} className={classes.field}>
                      <span className={classes.fieldName}>
                        {item.get("name")}
                      </span>
                      <IconButton
                        className={classes.addBtn}
                        onClick={() => onAddAttribute(item)}
                      >
                        <ContentAdd />
                      </IconButton>
                    </div>
                  ))}
              </div>
            </CardContent>
            <CardActions>
              <Button variant="text" type="submit">
                {getLocalisationMessage("submit", "Submit")}
              </Button>
            </CardActions>
          </Card>
        </FlexBox>
        <FlexBox flex={true} direction="column">
          <Card>
            <div>
              <div className={classes.pdfPaper}>
                <GridStack onChange={items => onChangeAttribute(items)}>
                  {state.attributes.size &&
                    state.attributes.map((field, idx) => (
                      <GridStackItem
                        key={idx}
                        item={field}
                        autoPosition={!field.get("id")}
                        x={field.get("x")}
                        y={field.get("y")}
                        id={field.get("uniqueId")}
                        width={field.get("width")}
                        height={field.get("height")}
                        minHeight={field.get("min_height")}
                        minWidth={field.get("min_width")}
                        maxWidth={field.get("max_width")}
                        maxHeight={field.get("max_height")}
                        handleDelete={onDeleteAttribute}
                        handleDetail={() => showAttributeDetail(field)}
                      />
                    ))}
                </GridStack>
              </div>
            </div>
          </Card>
        </FlexBox>
      </FlexBox>
      <PdfAttributeDetailDialog
        open={state.showItemModal}
        attribute={state.attribute}
        saveAttributeItem={saveAttributeDetail}
        onRequestClose={closeAttributeDetail}
      />
    </form>
  );
}

export default enhancer(AdminPdfTemplateForm);
