import { Observable } from "rxjs";
import React from "react";
import { Map, Set, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData, mapResponseData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  confirmAsGoodAddress,
  updateBadAddressItem,
} from "../../api/admin/AdminAddressesApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import { getLocationDetails } from "../../api/customer/CustomerApiV2";
import TextWithLink from "../../components/router/TextWithLink";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import PageLoading from "../../components/ui-core/PageLoading";
import UpdateBadAddressForm from "../../components/bad-address-core/UpdateBadAddressForm";

const enhancer = compose(
  withTheme,
  renderIf(props => props.orderId > 0 && props.addressId > 0),
  useSheet({
    paper: {
      width: "850px",
      maxWidth: "850px",
      minWidth: "850px",
    },
    rightContent: { paddingTop: "12px" },
    textLink: { color: props => props.theme.palette.appBarTextColor },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const orderResponseStream = propsStream
          .distinctUntilKeyChanged("orderId")
          .switchMap(props =>
            props
              .getLocation(props.addressId, props.orderId)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(orderResponseStream, (props, orderResponse) => ({
            ...props,
            onRequestRefresh,
            order: orderResponse.get("payload"),
            isLoading: orderResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const oldAddressDetailsStream = propsStream
          .filter(props => !props.isLoading)
          .distinctUntilKeyChanged("order", isEqualData)
          .map(props =>
            props.order.get("lat", null) && props.order.get("lon", null)
              ? new DataListFilter({
                  lat: props.order.get("lat"),
                  lon: props.order.get("lon"),
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getLocationDetails(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(mapResponseData)
          .startWith(Map())
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            oldAddressDetailsStream,
            (props, oldAddressDetails) => ({
              ...props,
              oldAddressDetails: oldAddressDetails.get("payload"),
              isOldAddressLoading: oldAddressDetails.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const initialValuesStream = propsStream
          .filter(props => props.order && props.oldAddressDetails)
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const street = props.order.getIn(
              ["address_details", "street"],
              null,
            );
            const building = props.order.getIn(
              ["address_details", "building"],
              null,
            );
            const apartment = props.order.getIn(
              ["address_details", "apartment"],
              null,
            );
            const region = props.order.getIn(
              ["address_details", "region"],
              null,
            );
            const landmark = props.order.getIn(
              ["address_details", "landmark"],
              null,
            );
            const location = {
              lat: props.order.get("lat"),
              lng: props.order.get("lon"),
            };
            let neighborhood = null;
            let neighborhoodTxt = "";
            if (props.oldAddressDetails.get("neighborhood")) {
              if (props.oldAddressDetails.get("neighborhood"))
                neighborhood = props.oldAddressDetails
                  .get("neighborhood")
                  .toJS();
              else
                neighborhoodTxt =
                  props.oldAddressDetails.getIn(["neighborhood", "name"]) || "";
            }

            const city = props.oldAddressDetails.get("city");

            return fromJS({
              neighborhood,
              street,
              building,
              apartment,
              location,
              landmark,
              city,
              pickup: props.order.get("pickup"),
              neighborhood_txt: neighborhoodTxt,
              bad_neighborhood: region,
              bad_street: street,
              bad_building: building,
              bad_apartment: apartment,
              bad_location: location,
              bad_landmark: landmark,
              bad_city: city,
              bad_address: props.order.get("address", null),
            });
          })

          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(initialValuesStream, (props, initialValues) => ({
            ...props,
            initialValues,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
  withState(
    "state",
    "setState",
    Map({
      resolve: false,
      subBatchId: null,
      updateValues: null,
      resolvedOrders: Set(),
    }),
  ),
);

AdminBadAddressEditDialogWrapper.propTypes = {
  classes: PropTypes.object,
  initialValues: PropTypes.object,
  setState: PropTypes.func,
  state: PropTypes.instanceOf(Map),

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocation: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  addressId: PropTypes.number.isRequired,
  order: PropTypes.instanceOf(Map),
  oldAddressDetails: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func.isRequired,

  createOrderHref: PropTypes.func,
  onShowOrdersClick: PropTypes.func,
  isOldAddressLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminBadAddressEditDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("update_address", "Update Address")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
      rightIcon={
        <div className={classes.rightContent}>
          <TextWithLink
            className={classes.textLink}
            to={props.createOrderHref(
              props.order.getIn(["order", "id"]),
              props.order.get("version"),
            )}
          >
            {getLocalisationMessage("order_id", "Order ID")}:{" "}
            {props.order.getIn(["order", "code"])}
          </TextWithLink>
        </div>
      }
    >
      <PageLoading isLoading={props.isOldAddressLoading || props.isLoading} />

      <FlexBox direction="column">
        {props.oldAddressDetails && (
          <UpdateBadAddressForm
            initialValues={props.initialValues.toJS()}
            details={props.order}
            oldAddressDetails={props.oldAddressDetails}
            isOldAddressLoading={props.isOldAddressLoading}
            getCachedPostcode={getCachedPostcode}
            getPostcodePredictions={getPostcodePredictions}
            onVerifyAddress={() =>
              Promise.resolve(
                confirmAsGoodAddress(props.addressId, props.orderId).catch(
                  ResponseError.throw,
                ),
              )
                .then(() => {
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "address_is_successfully_updated",
                      "Address is successfully Updated",
                    ),
                  );
                  props.onRequestClose();
                })
                .catch(error => {
                  props.showErrorMessage(error);
                })
            }
            onSubmit={values =>
              updateBadAddressItem(
                props.addressId,
                props.orderId,
                values,
              ).catch(ResponseError.throw)
            }
            onSubmitFail={error => {
              props.showErrorMessage(error);
            }}
            onSubmitSuccess={() => {
              props.showSuccessMessage(
                getLocalisationMessage(
                  "address_is_successfully_updated",
                  "Address is successfully Updated",
                ),
              );
              props.onRequestClose();
            }}
          />
        )}
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminBadAddressEditDialogWrapper);
