import React from "react";
import { Map } from "immutable";
import PropTypes from "prop-types";
import DataListFilter from "../../helpers/DataListFilter";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import FlexBox from "../../components/ui-core/FlexBox";
import PackagePricelistFormNew from "../../components/package-pricelist-new/PackagePricelistFormNew";

AdminPackageNewFilterWrapper.propTypes = {
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  marketplace: PropTypes.instanceOf(Map),
};

function AdminPackageNewFilterWrapper(props) {
  return (
    <FlexBox container={8} direction="column">
      <PackagePricelistFormNew
        filter={props.filter}
        marketplace={props.marketplace}
        onFilterChange={props.onFilterChange}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedCountry={getCachedCountry}
        getCountryPredictions={getCountryPredictions}
        getCachedCity={getCachedCity}
        getCityPredictions={getCityPredictions}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
      />
    </FlexBox>
  );
}

export default AdminPackageNewFilterWrapper;
