import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PageLoading from "../../ui-core/PageLoading";
import FlexBox, { JUSTIFY_END } from "../../ui-core/FlexBox";
import FormTextField, { OUTLINED, SECONDARY } from "../../form/FormTextField";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import FormInternationalRegionAutocomplete from "../../form/FormInternationalRegionAutocomplete";
import {
  getCacheRegion,
  getInternationalRegionChoose,
} from "../../../api/admin/AdminInternationalReportsApi";
import fp from "lodash/fp";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { makeStyles } from "@material-ui/core";

const getValues = getFormValues("TariffForm");

const useStyles = makeStyles({
  textField: {
    "@media (max-width: 2559px)": {
      "& .MuiFormControl-root": {
        "& .MuiInputLabel-root": {
          wordWrap: "break-all",
          whiteSpace: "normal",
          overflowWrap: "anywhere",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "12px",
          width: "95%",
          lineHeight: 1.2,
          transform: "translate(12px, 5px) scale(1)",
        },
        "& .MuiInputLabel-shrink": {
          whiteSpace: "nowrap",
          fontSize: "14px",
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
    "@media (min-width: 2559px)": {
      "& .MuiFormControl-root": {
        "& .MuiInputLabel-root": {
          fontSize: "13px",
          width: "95%",
        },
      },
    },
  },
});

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "TariffForm",
    enableReinitialize: true,
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

const TariffForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  change,
  edit,
  values,
}) => {
  const classes = useStyles();
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <PageLoading isLoading={submitting} />
      <FlexBox container={8} direction="column" style={{ gap: 13 }}>
        <FlexBox flex={true} style={{ gap: 13 }}>
          <FlexBox flex={true}>
            <FormInternationalRegionAutocomplete
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="country"
              levelId={1}
              fullWidth={true}
              label={getLocalisationMessage("country", "Country")}
            />
          </FlexBox>
          <FlexBox flex={true} className={classes.textField}>
            <FormTextField
              name="inboundPricePerItem"
              type="number"
              fullWidth={true}
              label={getLocalisationMessage(
                "inbound_price_per_item",
                "Inbound price per item",
              )}
            />
          </FlexBox>
          <FlexBox flex={true} className={classes.textField}>
            <FormTextField
              name="inboundPricePerKg"
              type="number"
              fullWidth={true}
              label={getLocalisationMessage(
                "inbound_price_per_kg",
                "Inbound price per kg",
              )}
            />
          </FlexBox>
          <FlexBox flex={true} className={classes.textField}>
            <FormTextField
              name="outboundAviaPricePerItem"
              type="number"
              fullWidth={true}
              label={getLocalisationMessage(
                "outbound_avia_price_per_item",
                "Outbound avia price per item",
              )}
            />
          </FlexBox>
          <FlexBox flex={true} className={classes.textField}>
            <FormTextField
              name="outboundAviaPricePerKg"
              type="number"
              fullWidth={true}
              label={getLocalisationMessage(
                "outbound_avia_price_per_kg",
                "Outbound avia price per kg",
              )}
            />
          </FlexBox>
          <FlexBox flex={true} className={classes.textField}>
            <FormTextField
              name="outboundGroundPricePerItem"
              type="number"
              fullWidth={true}
              label={getLocalisationMessage(
                "outbound_ground_price_per_item",
                "Outbound ground price per item",
              )}
            />
          </FlexBox>
          <FlexBox flex={true} className={classes.textField}>
            <FormTextField
              name="outboundGroundPricePerKg"
              type="number"
              fullWidth={true}
              label={getLocalisationMessage(
                "outbound_ground_price_per_kg",
                "Outbound ground price per kg",
              )}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} />

          <FlexBox
            style={{ marginBottom: "1rem", gap: 16 }}
            flex={true}
            justify={JUSTIFY_END}
          >
            <CustomButton
              onClick={() =>
                fp.keys(values).forEach(key => {
                  change(key, null);
                })
              }
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("discard", "Discard")}
            </CustomButton>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              type="submit"
              onClick={handleSubmit}
            >
              {!edit
                ? getLocalisationMessage("add", "Add")
                : getLocalisationMessage("save", "Save")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
};
TariffForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  edit: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  values: PropTypes.object,
  change: PropTypes.func,
};
export default enhancer(TariffForm);
