import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FormTextField from "../form/FormTextField";
import { Check } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import Box from "@material-ui/core/Box";
import { getAsyncBarcode } from "../../api/admin/AdminOrderApi";
import { getValue } from "../../helpers/DataUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import FormChipInput from "../form/FormChipInput";

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit({
        ...values,
      });
    },
  }),
  reduxForm({
    form: "CustomsFeeCreateDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      charge:
        !values.charge &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  formValues({
    barcode: "barcode",
    orderId: "orderId",
    percentage: "percentage",
  }),
);

CustomsFeeCreateDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,

  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
  isLoadingRTOPrice: PropTypes.bool,
  barcode: PropTypes.array,
  orderId: PropTypes.number,
  percentage: PropTypes.number,
};

function CustomsFeeCreateDialog(props) {
  const { getLocalisationMessage, classes, isLoadingRTOPrice } = props;

  const [order, setOrder] = useState({});

  useEffect(() => {
    setOrder({});
    props.change("orderId", null);
    if (props.barcode && props.barcode.length > 0) {
      getAsyncBarcode(props.barcode).then(res => {
        if (res && res.data) {
          setOrder(res.data);
          props.change("orderId", getValue(res, "data.id"));
        }
      });
    } else {
      props.change("orderId", null);
      setOrder({});
    }
  }, [props.barcode]);

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <FlexBox justify="space-between">
          {getLocalisationMessage("customs_fee", "Customs Fee")}

          <Box style={{ marginLeft: 15, width: 150 }}>
            {formatOrderStatusCodeForLocalisation(
              getValue(order, "status"),
              props.getLocalisationMessage,
            )}
          </Box>
        </FlexBox>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          <FlexBox container={8} direction="column" style={{ gap: 13 }}>
            <FlexBox flex={true}>
              <FormChipInput
                focus={true}
                fullWidth={true}
                size="medium"
                maxItems={1}
                name="barcode"
                label={getLocalisationMessage("barcode")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                name="charge"
                type="number"
                size="medium"
                focus={true}
                fullWidth={true}
                label={`${getLocalisationMessage("amount", "Amount")} *`}
                onFocus={event => {
                  event.target.select();
                }}
                onChange={(event, value) => {
                  props.change(
                    "total",
                    props.percentage
                      ? ((props.percentage + 100) * value) / 100
                      : value,
                  );
                }}
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                name="percentage"
                size="medium"
                disabled={true}
                fullWidth={true}
                label={getLocalisationMessage("percentage")}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                name="total"
                size="medium"
                disabled={true}
                fullWidth={true}
                label={getLocalisationMessage("Total")}
              />
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>

      <DialogActions style={{ margin: "1rem 0" }}>
        <FlexBox flex={true} justify="flex-end" style={{ gap: 16 }}>
          <CustomButton
            color={SECONDARY}
            variant={OUTLINED}
            onClick={props.close}
            primary={true}
          >
            {getLocalisationMessage("dismiss")}
          </CustomButton>

          <CustomButton
            disabled={!props.orderId}
            size="small"
            style={{ padding: "5px 2rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            endIcon={
              isLoadingRTOPrice ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <Check />
              )
            }
            onClick={props.handleSubmit}
          >
            {getLocalisationMessage("submit")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CustomsFeeCreateDialog);
