import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import ModalPaper from "../../ui-core/ModalPaper";
import NewSettingsForm from "./NewSettingsForm";
import { toCamelCase, toSnakeCase } from "../../../helpers/CaseMapper";
import { getValue } from "../../../helpers/DataUtils";

const enhancer = compose(
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px", minHeight: 100 },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

UpdateSettingsDialog.propTypes = {
  classes: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  updateApi: PropTypes.func,
  refreshList: PropTypes.func,
  getItemApi: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
};

function UpdateSettingsDialog(props) {
  const { classes, getLocalisationMessage } = props;
  const [data, setData] = useState({});

  useEffect(() => {
    if (props.id) {
      props.getItemApi(props.id).then(res => {
        if (res && res.data) {
          setData(getValue(res, "data", {}));
        }
      });
    }
  }, [props.id]);

  return (
    <ModalPaper
      open={props.open}
      title={props.title}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <NewSettingsForm
        edit={true}
        initialValues={toCamelCase(data)}
        onSubmit={values => {
          props
            .updateApi(props.id, toSnakeCase(values))
            .then(res => {
              if (res && res.status === "success") {
                props.onRequestClose();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_updated",
                    "Successfully Updated",
                  ),
                );
                props.refreshList();
              }
            })
            .catch(error => props.showErrorMessage(error));
        }}
        onDismiss={props.onRequestClose}
      />
    </ModalPaper>
  );
}

export default enhancer(UpdateSettingsDialog);
