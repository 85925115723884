import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import SubscriptionCheckoutForm from "./SubscriptionCheckoutForm";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { responsive } from "../../../shared/theme/jss-responsive";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    dialogRoot: {
      marginBottom: 5,
    },
    buttonRight: { float: "right" },
    paper: {
      minWidth: "600px",
      maxWidth: "600px",
      minHeight: "auto",
      [responsive("$xs or $sm")]: { minWidth: "100%" },
    },
  }),
);

class SubscriptionCheckoutDialog extends React.Component {
  constructor() {
    super();
    this.state = { stripe: null };
  }

  static propTypes = {
    open: PropTypes.bool,
    publicKey: PropTypes.string,
    clientSecret: PropTypes.string,
    classes: PropTypes.object,
    closeDialog: PropTypes.func.isRequired,
    // customerStripeId: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitFail: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    subscribedPlan: PropTypes.instanceOf(Map),
    filter: PropTypes.instanceOf(Map),
    selectedPlan: PropTypes.object,
    i18n: PropTypes.instanceOf(Map),
  };

  componentDidMount() {
    if (window.Stripe) {
      // eslint-disable-next-line react/no-did-mount-set-state,new-cap
      this.setState({ stripe: window.Stripe(this.props.publicKey) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        // eslint-disable-next-line new-cap
        this.setState({ stripe: window.Stripe(this.props.publicKey) });
      });
    }
  }

  render() {
    const { i18n } = this.props;

    return (
      <ModalPaper
        open={this.props.open}
        onRequestClose={this.props.closeDialog}
        paperClassName={this.props.classes.paper}
        title={i18n.get(
          "enter_your_billing_address",
          "Enter Your Billing Details",
        )}
      >
        <FlexBox align="center" justify="center" direction="column">
          <StripeProvider stripe={this.state.stripe}>
            <Elements>
              <SubscriptionCheckoutForm
                i18n={i18n}
                stripe={this.state.stripe}
                clientSecret={this.props.clientSecret}
                // customerStripeId={this.props.customerStripeId}
                onSubmit={this.props.onSubmit}
                onRequestClose={this.props.closeDialog}
              />
            </Elements>
          </StripeProvider>
        </FlexBox>
      </ModalPaper>
    );
  }
}

export default enhancer(SubscriptionCheckoutDialog);
