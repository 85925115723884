import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormCustomerChips from "../form/FormCustomerChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FlexBox, { JUSTIFY_END } from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import {
  formatText,
  safeParseDate,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseString,
  parseIntString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import AccuracyStatusCodes from "../../constants/AccuracyStatusCodes";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";

const styles = {
  toggle: {
    thumbSwitchedStyle: { backgroundColor: "#fdd000" },
    trackSwitchedStyle: { backgroundColor: "#e3bf16" },
  },
  chipAutoComplete: {
    chipContainer: {
      maxHeight: "96px",
      overflowY: "auto",
    },
  },
};

const startTime = startOfToday();
const endTime = endOfToday();
const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      statusMessages: getStatusLocalisation(state),
    };
  }),
  useSheet({
    ListSubheader: { flex: "1 1 0%", paddingLeft: "0px" },
    includeButton: { paddingBottom: "12px" },
    toggle: {
      marginTop: "13px",
      whiteSpace: "nowrap",
      "& label": { color: "#FFF" },
    },
    paddingTop: {
      paddingTop: "14px",
    },
    wrapContent: {
      "@media (max-width: 998px)": {
        flexWrap: "wrap",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& > div": { justifyContent: "flex-start", width: "100%" },
      },
    },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        return {
          courierTypes: parseString(x.getValue("courier_types")),
          fromDateTime: safeParseDate(x.getValue("from_date_time")),
          toDateTime: safeParseDate(x.getValue("to_date_time")),
          customerIds: idsToObjectArray(x.getValue("customer_ids")),
          status: parseString(fp.toLower(x.getValue("status"))),
          accuracyStatuses: parseString(
            fp.toLower(x.getValue("accuracyStatuses")),
          ),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            status: stringifyArray(values.status),
            customer_ids: stringifyArray(values.customerIds.map(getObjectId)),
            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),
            courier_types: stringifyArray(values.courierTypes),
            accuracyStatuses: stringifyArray(values.accuracyStatuses),
          });
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "BadAddressesFilterForm", enableReinitialize: true }),
  formValues({
    status: "status",

    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
  }),
  pureComponent(fp.pick(["dirty", "initialValues"])),
);

BadAddressesFilterForm.propTypes = {
  classes: PropTypes.object,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,

  onDismiss: PropTypes.func,

  getSupplierPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  statusMessages: PropTypes.instanceOf(Map),
};

function BadAddressesFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <Card>
        <CardContent>
          <FlexBox flex={true}>
            <FlexBox flex={true}>
              <FormChipAutoComplete
                name="status"
                fullWidth={true}
                options={OrderStatusCodes}
                formatOption={value =>
                  formatOrderStatusCodeForLocalisation(
                    value,
                    getLocalisationMessage,
                  )
                }
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
                label={getLocalisationMessage("order_status", "Order Status")}
                chipContainerStyle={styles.chipAutoComplete.chipContainer}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormCourierTypeChips
                name="courierTypes"
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
                label={getLocalisationMessage("service_type", "Service Type")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormCustomerChips
                name="customerIds"
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
                label={getLocalisationMessage(
                  "include_customers",
                  "Include Customers",
                )}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox flex={true} direction="column">
            <ListSubheader className={classes.ListSubheader}>
              {getLocalisationMessage("created_date", "Created Date")}
            </ListSubheader>

            <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="fromDateTime"
                    hintText={getLocalisationMessage("from_date", "From Date")}
                    initialTime={startTime}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTimeField
                    fullWidth={true}
                    name="fromDateTime"
                    hintText={getLocalisationMessage("from_time", "From Time")}
                  />
                </FlexBox>

                {Boolean(props.fromDateTime) && (
                  <FlexBox>
                    <IconButton
                      onClick={() => props.change("fromDateTime", null)}
                    >
                      <NavigationCancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="toDateTime"
                    hintText={getLocalisationMessage("to_date", "To Date")}
                    initialTime={endTime}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTimeField
                    fullWidth={true}
                    name="toDateTime"
                    hintText={getLocalisationMessage("to_time", "To Time")}
                  />
                </FlexBox>

                {Boolean(props.toDateTime) && (
                  <FlexBox>
                    <IconButton
                      onClick={() => props.change("toDateTime", null)}
                    >
                      <NavigationCancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox flex={true}>
            <FormChipAutoComplete
              name="accuracyStatuses"
              fullWidth={true}
              options={AccuracyStatusCodes}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              label={getLocalisationMessage(
                "accuracy_status",
                "Accuracy Status",
              )}
              formatOption={value =>
                formatText(getLocalisationMessage(value, value))
              }
            />
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            {props.dirty ? (
              <Button onClick={props.reset}>
                {getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}
            <Button type="submit">
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(BadAddressesFilterForm);
