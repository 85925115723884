import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { saveCallTimingsSettings } from "../../api/admin/AdminSettingsApi";
import CallTimingsForm from "../../components/call-step-settings/CallTimingsForm";

const enhancer = compose(
  renderIf("open"),

  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({ container: { maxWidth: "400px" } }),
);

AdminCallTimingCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  open: PropTypes.bool.isRequired,
  entityId: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function AdminCallTimingCreateDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      open={props.open}
      contentClassName={classes.container}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
        },
      }}
    >
      <DialogTitle>
        {getLocalisationMessage("create_call_timing", "Create Call Timing")}
      </DialogTitle>
      <DialogContent>
        <CallTimingsForm
          initialValues={{ customerId: props.entityId ? props.entityId : null }}
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            saveCallTimingsSettings(toSnakeCase(values)).catch(
              ResponseError.throw,
            )
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_call_timing_created",
                "Successfully Call Timing Created",
              ),
            );
            props.onRequestClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(AdminCallTimingCreateDialogWrapper);
