import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const VENUE_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s`;
const CREATE_VENUE_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/venue`;
const VENUE_LIST_URL = `${SHOP_API_ROOT_URL}/admin/venue`;

export type VenueItemRequest = {
  id: number,
  name: string,
  description: string,
  merchant_id: number,
  status: string,
  photos: string[],
  business_hours: {
    id: number,
    sunday: {
      close_time: number,
      open_time: number,
    },
    monday: {
      close_time: number,
      open_time: number,
    },
    tuesday: {
      close_time: number,
      open_time: number,
    },
    wednesday: {
      close_time: number,
      open_time: number,
    },
    thursday: {
      close_time: number,
      open_time: number,
    },
    friday: {
      close_time: number,
      open_time: number,
    },
    saturday: {
      close_time: number,
      open_time: number,
    },
  },
  lat: number,
  lon: number,
};

export const createVenueItem = (body: VenueItemRequest) =>
  api.post(CREATE_VENUE_ITEM_URL, { body });
export const updateVenueItem = (id, body: VenueItemRequest) =>
  api.put(sprintf(VENUE_ITEM_URL, id), { body });
export const getVenueItem = id => api.getStream(sprintf(VENUE_ITEM_URL, id));

export const getVenueList = (request: DataListFilter) => {
  const { categoryId, ...params } = request.getDefinedValues();

  return api.getStream(VENUE_LIST_URL, {
    params: { ...params, category_id: categoryId },
  });
};

export const removeVenue = ids =>
  api.delete(VENUE_LIST_URL, { params: { ids } });
