import { Observable } from "rxjs";
import React from "react";
import { differenceInMinutes, startOfToday } from "date-fns";
import Immutable, { fromJS, List, OrderedMap, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { Avatar, IconButton } from "@material-ui/core";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FitBounds,
  InfoWindow,
  Polyline,
  ZoomControl,
} from "react-google-map-components";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatDateTimeToUrl,
  formatDateToUrl,
  formatText,
} from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { mergeFilterToFilter } from "../../helpers/FilterHelper";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceMapCenter,
  isMarketplaceBusinessTypeFoodDelivery,
} from "../../reducers/MarketplaceReducer";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ACTIVE } from "../../constants/OverallStatus";
import { ROLE_RADAR_VIEWER } from "../../constants/AdminRoleTypes";
import {
  batchAsyncUpdateOrder,
  getOrderList,
  getRadarOrderSuggestedDrivers,
} from "../../api/admin/AdminOrderApi";
import OrderPin from "../../components/maps/OrderPin";
import {
  getDriver,
  getDriverList,
  getDriverLocationItem,
  getDriverOrdersLocation,
  updateBoundaryByOrder,
} from "../../api/admin/AdminDriverApi";
import GoogleMapWrapper from "../../components/maps/GoogleMapWrapper";
import { SidebarMenu } from "../../components/admin/AdminAppLayout";
import FlexBox, { JUSTIFY_END } from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import AppLayoutDrawer from "../../components/app-layout/AppLayoutDrawer";
import DriversRadarOrdersList from "../../components/order-drivers-radar/DriversRadarOrdersList";
import DriversRadarDriversList from "../../components/order-drivers-radar/DriversRadarDriversList";
import OrderDriversRadarSearch from "../../components/order-drivers-radar/OrderDriversRadarSearch";
import { deleteFoodOrder, getOrder } from "../../api/v2/admin/AdminOrderApi";
import { sockJSHOC } from "../../helpers/sockJSHOC";
import {
  ADMIN_DRIVER_LOCATION_QUERY_NEW,
  ADMIN_RADAR_ORDER_COUNT,
} from "../../../shared/constants/DriverLocationChannelConstants";
import getWidth from "dom-helpers/query/width";
import DriverRadarTimeLine from "../../components/order-drivers-radar/DriverRadarTimeLine";
import iconLiveStreamingOff from "../../components/order-drivers-radar/assets/live-streaming-off.svg";
import iconLiveStreamingOn from "../../components/order-drivers-radar/assets/live-streaming-on.svg";
import iconDriverHistory from "../../components/order-drivers-radar/assets/time.svg";
import DriverRadarVehicles from "../../components/drivers-radar/DriverRadarVehicle2";
import VehicleMarker from "../../components/maps/VehicleMarker2";
import {
  toDriverListMapper,
  toSuggestedDriversFilter,
} from "../../helpers/RadarFilterMapper";
import { getDriverDetailedAlert } from "../../api/admin/AdminAlertsApi";
import querystring from "querystring";
import { ALL_TAB } from "../../components/drivers-radar/DriverAlertHistoryTabs";
import DriverOrderLocationsMapV2 from "../../components/order-drivers-radar/DriverOrderLocationsMapV2";
import OrderDriverLocationsMap from "../../components/order-drivers-radar/OrderDriverLocationsMap";
import { shouldOrderBeAssigned } from "../../helpers/OrderHelper";
import { OrderSettingsApi } from "../../api/shared/OrderSettingsApi";
import { sprintf } from "sprintf";
import { Link } from "react-router";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import ListingIcon from "../../components/icons/ListingIcon";
import { isMapKeyAvailable } from "../../../shared/reducers/AppReducer";
import LeafletMapWrapper from "../../components/maps-leaflet/LeafletMapWrapper";
import {
  FitBounds as LFitBounds,
  InfoWindow as LInfoWindow,
  Polyline as LPolyline,
} from "react-leflet-map-components";
import LVehicleMarker from "../../components/maps-leaflet/LVehicleMarker";
import DriverOrderLocationsMapLeaflet from "../../components/order-drivers-radar/DriverOrderLocationsMapLeaflet";
import Redirect from "../../components/router/Redirect";
import ResponseError from "../../helpers/ResponseError";
import FormDialog from "../../components/form/FormDialog";
import { CANCELLED } from "../../constants/OrderStatusCodes";
import AdminOrderFilterDateWrapper from "../../wrappers/admin/AdminOrderFilterDateWrapper";
import DriversRadarDriversListLive from "../../components/order-drivers-radar/DriversRadarDriversListLive";
import OrderDriverLocationsMapLeaflet from "../../components/order-drivers-radar/OrderDriverLocationsMapLeaflet";

const ORDER_LIST_ITEM_HEIGHT = 161;
const DRIVER_LIST_ITEM_HEIGHT = 72;

const getOrderDropoffLatLng = order => ({
  lat: order.getIn(["recipient_data", "lat"]),
  lng: order.getIn(["recipient_data", "lon"]),
});
const getOrderPickupLatLng = order => ({
  lat: order.getIn(["sender_data", "lat"]),
  lng: order.getIn(["sender_data", "lon"]),
});
const getOrderDropoffLocation = order => ({
  lat: order.getIn(["locations", 1, "lat"]),
  lng: order.getIn(["locations", 1, "lon"]),
});

const isDeliveryModeEnabled = mode => !fp.isUndefined(mode) && mode !== "false";
const isLiveStreamingEnabled = mode =>
  !fp.isUndefined(mode) && mode !== "false";

const getSliceTime = fp.flow(
  fp.get("query.sliceTime"),
  fp.toFinite,
  x => x || 5,
);

const onOrderItemSelect = (orderId, index, props) => {
  props.onOrderItemDetailed(orderId);

  props.onSuggestedDrivers({
    orderId,
    filter: props.filter.setValueMap({
      status: null,
    }),
  });
  props.setLocationQuery(
    fp.flow(
      fp.unset("filterItem"),
      fp.unset("filterDate"),
      fp.unset("historyItem"),
      fp.unset("driverItem"),
      fp.set("orderItem", orderId),
      fp.set("driver_tab", "all"),
    ),
  );
  props.setState(
    fp.flow(
      fp.set("listPosition", index * ORDER_LIST_ITEM_HEIGHT),
      fp.set("scrollingList", index > 0),
    ),
  );
};

const onDriverItemSelect = (driverId, index, props) => {
  props.onSelectedDriver(driverId);
  props.onDriverOrders({
    driverId,
    filter: props.orderFilter,
    isFoodDelivery: props.isFoodDelivery,
  });
  if (isDeliveryModeEnabled(props.location.query.is_delivery_mode)) {
    props.setLocationQuery(
      fp.flow(
        fp.unset("filterItem"),
        fp.unset("filterDate"),
        fp.unset("historyItem"),
        fp.set("driverItem", driverId),
      ),
    );
  } else {
    props.setLocationQuery(
      fp.flow(
        fp.unset("filterItem"),
        fp.unset("filterDate"),
        fp.unset("historyItem"),
        fp.unset("orderItem"),
        fp.set("driverItem", driverId),
      ),
    );
  }

  if (index < 0) {
    const idx = props.driversList.findIndex(
      x => String(x.get("driver_id")) === driverId,
    );

    if (idx) {
      props.setState(
        fp.flow(
          fp.set("driverListPosition", idx * DRIVER_LIST_ITEM_HEIGHT),
          fp.set("driverScrollingList", idx > 0),
        ),
      );
    }
  } else {
    props.setState(
      fp.flow(
        fp.set("driverListPosition", index * DRIVER_LIST_ITEM_HEIGHT),
        fp.set("driverScrollingList", index > 0),
      ),
    );
  }
};

const enhancer = compose(
  useSheet({
    container: {
      height: "100%",
      padding: 0,
      overflow: "hidden",
    },
    root: { position: "relative" },
    topLeftWrapper: {
      position: "absolute",
      width: "470px",
      paddingLeft: "20px",
      paddingTop: "15px",
      zIndex: 3,
      boxSizing: "content-box",
      // zIndex: 0,
    },
    topRightWrapper: {
      position: "absolute",
      zIndex: 3,
      right: "20px",
      top: "15px",
    },
    topLinePaper: {
      minHeight: "56px",
      paddingLeft: "15px",
      marginLeft: 0,
    },
    leftWrapper: {
      position: "absolute",
      top: 15,
      left: 20,
      zIndex: 2,
      right: 0,
      width: "470px",
      height: "calc(100% - 30px)",
    },
    rightWrapper: {
      position: "absolute",
      top: 15,
      right: 20,
      zIndex: 2,
      width: "500px",
      height: "calc(100% - 30px)",
    },
    drawer: {
      "& > div": {
        top: 0,
      },
    },
    mainList: {
      zIndex: 1,
      height: "100%",
      paddingTop: "70px",
    },
    driversList: {
      zIndex: 1,
      height: "100%",
      paddingTop: "70px",
    },
    driverLocationLoader: {
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      position: "absolute",
    },
    liveStreamingIconWrapper: {
      background: "white",
      padding: "1rem",
      marginRight: 15,
    },
    liveStreaming: {
      width: "100%",
      height: "100%",
      borderRadius: "initial",
      cursor: "pointer",
    },
    map: { flex: "1 1 0%", zIndex: 0 },
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("authorities");
      return {
        // userCountry: getMarketplaceCountry(state),
        defaultCenter: getMarketplaceMapCenter(state),
        isRadarViewer: userRoles && userRoles.includes(ROLE_RADAR_VIEWER),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isFoodDelivery: isMarketplaceBusinessTypeFoodDelivery(state),
        isMapKeyAvailable: isMapKeyAvailable(state),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withState("state", "setState", {
    listPosition: 0,
    showDateFilter: false,
    scrollingList: false,
    driverListPosition: 0,
    driverScrollingList: false,
    ordersCount: 0,
    locationsSubscription: undefined,
    orderCountSubscription: undefined,
  }),
  sockJSHOC(),
  mapPropsStream(
    pipeStreams(
      // -------------- Initial Filter -----------
      propsStream => {
        const mapViewTypeStream = propsStream
          .map(
            fp.flow(
              fp.get("location.query"),
              fp.pick(["is_delivery_mode", "driverItem", "orderItem"]),
            ),
          )
          .startWith({})
          .distinctUntilChanged(isEqualData);

        const initialFilterStream = propsStream
          .take(1)
          .map(fp.flow(fp.get("location.query"), toDriverListMapper))
          .withLatestFrom(propsStream)
          .do(([filter, { location, setLocationQuery }]) => {
            if (fp.isUndefined(location.query.is_delivery_mode)) {
              const status = filter.getValue("driver_status", null);
              setLocationQuery(fp.set("is_delivery_mode", false));
              if (!status) {
                setLocationQuery(fp.set("driver_status", fp.toUpper(ACTIVE)));
              }
            }

            const driverItem = fp.toFinite(location.query.driverItem);
            if (driverItem > 0) {
              setLocationQuery(fp.set("tab", ALL_TAB));
            }

            setLocationQuery(fp.set("use_solr", true));
          })
          .startWith(null);

        return propsStream
          .combineLatest(
            mapViewTypeStream,
            initialFilterStream,
            (props, mapViewType) => ({
              ...props,
              isDeliveryMode: Boolean(fp.get("is_delivery_mode", mapViewType)),
              driverItem: fp.toFinite(fp.get("driverItem", mapViewType)),
              orderItem: fp.toFinite(fp.get("orderItem", mapViewType)),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // -------------- Initial Filter -----------

      // ------------------ Order based Map View ---------------
      propsStream => {
        const {
          stream: onOrderRequestRefreshStream,
          handler: onOrderRequestRefresh,
        } = createEventHandler();
        const {
          handler: onOrderItemDetailed,
          stream: onOrderItemDetailedStream,
        } = createEventHandler();
        const {
          handler: onOrderDetailRefresh,
          stream: onOrderDetailRefreshStream,
        } = createEventHandler();
        const {
          handler: onDriverOrders,
          stream: onDriverOrdersStream,
        } = createEventHandler();
        const {
          handler: onRefreshDriverOrders,
          stream: onRefreshDriverOrdersStream,
        } = createEventHandler();

        // ------------------ Order Tab Filters ---------------
        const requestRefresh = createEventHandler();
        const settingsApi = new OrderSettingsApi();

        const filtersListStream = settingsApi
          .getCustomFilters()
          .repeatWhen(() => requestRefresh.stream);

        // ------------------ Order Filter ---------------
        const orderFilterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toOrderFilter))
          .distinctUntilChanged(isEqualData);

        // ------------------ Order Listing ---------------
        const orderResponseStream = orderFilterStream
          .withLatestFrom(propsStream)
          .filter(([, props]) =>
            isDeliveryModeEnabled(props.location.query.is_delivery_mode),
          )
          .switchMap(([filter]) =>
            getOrderList(
              filter.setValueMap({
                simple: false,
              }),
            )
              .repeatWhen(() => onOrderRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  error: response.get("error", null),
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const orderDetailedResponseStream = propsStream
          .take(1)
          .map(fp.get("location.query.orderItem"))
          .distinctUntilChanged()
          .merge(onOrderItemDetailedStream)
          .filter(fp.toFinite)
          .distinctUntilChanged(isEqualData)
          .switchMap(id =>
            getOrder(id)
              .repeatWhen(() => onOrderDetailRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .let(mapObjectResponseStream)
          .distinctUntilChanged(isEqualData);

        const driverOrdersResponseStream = propsStream
          .take(1)
          .map(
            fp.pick([
              "location.query.driverItem",
              "orderFilter",
              "isFoodDelivery",
            ]),
          )
          .distinctUntilChanged(isEqualData)
          .merge(onDriverOrdersStream)
          .filter(props => fp.isFinite(props.driverId))
          .distinctUntilChanged(isEqualData)
          .switchMap(({ filter, isFoodDelivery, driverId }) =>
            getOrderList(
              filter.setValueMap(
                isFoodDelivery
                  ? {
                      driver_ids: driverId,
                      order_by: "pickup_time",
                    }
                  : {
                      driver_ids: driverId,
                      order_by: "pickup_time",
                      from_date_time: null,
                      to_date_time: null,
                    },
              ),
            )
              .repeatWhen(() => onRefreshDriverOrdersStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            orderFilterStream,
            orderResponseStream,
            orderDetailedResponseStream,
            driverOrdersResponseStream,
            filtersListStream,
            (
              props,
              orderFilter,
              orderResponse,
              orderDetailedResponse,
              driverOrdersResponse,
              filterListResponse,
            ) => ({
              ...props,
              orderFilter,

              ordersList: orderResponse.get("list"),
              ordersError: orderResponse.get("error"),
              ordersTotal: orderResponse.get("total"),
              isOrdersLoading: orderResponse.get("isLoading"),

              orderItemDetails: orderDetailedResponse.get(
                "payload",
                OrderedMap({}),
              ),
              orderItemDetailsLoading: orderDetailedResponse.get(
                "pending",
                false,
              ),

              driverOrders: driverOrdersResponse.get("list"),
              driverOrdersTotal: driverOrdersResponse.get("total"),
              isDriverOrdersLoading: driverOrdersResponse.get("isLoading"),

              filters: filterListResponse.get("payload"),
              isLoadingFilters: filterListResponse.get("pending"),
              onRequestRefreshFilters: requestRefresh.handler,

              onOrderRequestRefresh,
              onOrderDetailRefresh,
              onOrderItemDetailed,
              onDriverOrders,
              onRefreshDriverOrders,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // ------------------ Order based Map View ---------------

      // ------------------ Driver based Map View ---------------
      propsStream => {
        const {
          stream: onDriverRequestRefreshStream,
          handler: onDriverRequestRefresh,
        } = createEventHandler();
        const {
          handler: onSelectedDriver,
          stream: onSelectedDriverStream,
        } = createEventHandler();
        const {
          handler: onSelectedDriverRefresh,
          stream: onSelectedDriverRefreshStream,
        } = createEventHandler();
        const {
          handler: onChangeBounds,
          stream: onChangeBoundsStream,
        } = createEventHandler();

        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDriverListMapper))
          .distinctUntilChanged(isEqualData);

        const suggestedDriversFilterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toSuggestedDriversFilter))
          .distinctUntilChanged(isEqualData);

        // ------------------ Driver Listing ---------------

        const driverResponseStream = filterStream
          .filter(
            filter =>
              !isDeliveryModeEnabled(filter.getValue("is_delivery_mode")),
          )
          .map(filter =>
            filter.setValueMap({
              status: filter.getValue("driver_status"),
            }),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getDriverList(
              filter.setValueMap({
                use_solr: false,
                duty_status: filter.getValue("duty_status")
                  ? filter.getValue("duty_status")
                  : null,
                driver_availability: filter.getValue("driver_availability")
                  ? filter.getValue("driver_availability")
                  : "ALL",
              }),
            )
              .repeatWhen(() => onDriverRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  error: response.get("error", null),
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const driverDetailedResponseStream = propsStream
          .take(1)
          .map(fp.get("location.query.driverItem"))
          .merge(onSelectedDriverStream)
          .filter(fp.toFinite)
          .distinctUntilChanged(isEqualData)
          .switchMap(id =>
            getDriver(id)
              .repeatWhen(() => onSelectedDriverRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  payload: response.getIn(["payload", "data"], Immutable.Map()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const driverLastAlertStream = propsStream
          .take(1)
          .map(fp.get("location.query.driverItem"))
          .merge(onSelectedDriverStream)
          .filter(fp.toFinite)
          .distinctUntilChanged(isEqualData)
          .switchMap(id =>
            getDriverDetailedAlert(id)
              .repeatWhen(() => onSelectedDriverRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  payload: response.getIn(["payload", "data"], Immutable.Map()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const driverLocationsItemStream = propsStream
          .map(() => {
            const parsed = querystring.parse(window.location.search);
            return {
              historyItem: parsed.historyItem,
              filterDate: parsed.filterDate,
            };
          })
          .distinctUntilChanged(isEqualData)
          .filter(({ historyItem, filterDate }) => historyItem && filterDate)
          .switchMap(({ historyItem, filterDate }) =>
            getDriverLocationItem(
              historyItem,
              new DataListFilter({
                date: formatDateToUrl(filterDate || new Date()),
              }),
            ).catch(error => Observable.of({ error })),
          )
          .startWith({
            payload: {
              count: 0,
              paths: [[]],
              locations: [],
            },
          })
          .combineLatest(propsStream, (data, props) => [data, props])
          .map(([data, props]) =>
            fp.flow(
              fp.update("pending", Boolean),
              fp.update(
                "payload",
                fp.flow(
                  fp.get("data"),
                  fp.toArray,
                  x =>
                    x.sort((a, b) => {
                      const aDate = new Date(a.date).getTime();
                      const bDate = new Date(b.date).getTime();

                      if (aDate > bDate) {
                        return 1;
                      }
                      if (aDate < bDate) {
                        return -1;
                      }

                      return 0;
                    }),
                  x => {
                    const coordinates = {
                      count: 0,
                      paths: [[]],
                      locations: [],
                    };

                    let index = 0;
                    let lastDate;
                    const sliceTime = getSliceTime(props.location);

                    x.forEach(i => {
                      if (lastDate !== i.date) {
                        if (
                          lastDate &&
                          differenceInMinutes(i.date, lastDate) >= sliceTime
                        ) {
                          coordinates.paths.push([]);
                          index += 1;
                        }

                        lastDate = i.date;

                        coordinates.locations.push({
                          ...i,
                          lng: i.lon,
                        });

                        coordinates.paths[index].push({
                          ...i,
                          lng: i.lon,
                        });
                      }
                    });

                    return coordinates;
                  },
                ),
              ),
              Immutable.fromJS,
            )(data),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            filterStream,
            suggestedDriversFilterStream,
            driverResponseStream,
            driverDetailedResponseStream,
            driverLastAlertStream,
            driverLocationsItemStream,
            onChangeBoundsStream.map(fp.get("bounds")).startWith({}),
            (
              props,
              filter,
              suggestedDriversFilter,
              driverResponse,
              driverDetailedResponse,
              driverLastAlertResponse,
              driverItemResponse,
              bounds,
            ) => ({
              ...props,
              bounds,
              filter,
              suggestedDriversFilter,

              driversList: driverResponse.get("list"),
              driversError: driverResponse.get("error"),
              driversTotal: driverResponse.get("total"),
              isDriversLoading: driverResponse.get("isLoading"),

              driverDetails: driverDetailedResponse.get("payload"),
              driverLastAlert: driverLastAlertResponse.get("payload"),
              driverDetailsLoading: driverDetailedResponse.get("pending"),
              isDriverLastAlertLoading: driverLastAlertResponse.get("pending"),
              onChangeBounds,
              onDriverRequestRefresh,
              onSelectedDriver,
              onSelectedDriverRefresh,
              isLoadingDriver: driverItemResponse.get("pending"),
              driverHistoryLocations: driverItemResponse.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },

      // -------------- Bounds to JSON -----------
      propsStream => {
        const boundsToJSONStream = propsStream
          .distinctUntilKeyChanged("bounds")
          .filter(({ bounds }) => !fp.isEmpty(bounds))
          .map(({ bounds }) => {
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            const type = fp.isFunction(northEast.lat);

            return Immutable.fromJS({
              north_east: {
                lat: type ? northEast.lat() : northEast.lat,
                lon: type ? northEast.lng() : northEast.lng,
              },
              south_west: {
                lat: type ? southWest.lat() : southWest.lat,
                lon: type ? southWest.lng() : southWest.lng,
              },
            });
          })
          .startWith(Immutable.Map())
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(boundsToJSONStream, (props, rectBounds) => ({
            ...props,
            rectBounds,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // -------------- Bounds to JSON -----------

      propsStream => {
        const {
          handler: onSuggestedDrivers,
          stream: onSuggestedDriversStream,
        } = createEventHandler();
        const {
          handler: onRefreshSuggestedDrivers,
          stream: onRefreshSuggestedDriversStream,
        } = createEventHandler();

        const suggestedDriversResponseStream = propsStream
          .take(1)
          .map(fp.pick(["location.query.orderItem", "suggestedDriversFilter"]))
          .distinctUntilChanged(isEqualData)
          .map(props => ({
            orderId: fp.toFinite(fp.get("location.query.orderItem", props)),
            filter: props.suggestedDriversFilter,
          }))
          .merge(onSuggestedDriversStream)
          .filter(props => props.orderId > 0)
          .distinctUntilChanged(isEqualData)
          .switchMap(({ orderId, filter }) =>
            getRadarOrderSuggestedDrivers(
              orderId,
              filter.setValueMap({
                use_solr: false,
                status: filter.getValue("driver_status"),
              }),
            )
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRefreshSuggestedDriversStream),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            suggestedDriversResponseStream,
            (props, suggestedDriversResponse) => ({
              ...props,
              suggestedDrivers: suggestedDriversResponse.get("list"),
              suggestedDriversTotal: suggestedDriversResponse.get("total"),
              isSuggestedDriversLoading: suggestedDriversResponse.get(
                "isLoading",
              ),

              onSuggestedDrivers,
              onRefreshSuggestedDrivers,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },

      // // ------------------ Driver based Map View ---------------

      // ----------- Subscribing Locations -------
      propsStream => {
        const {
          handler: onCustomUpdateDriver,
          stream: onCustomUpdateDriverStream,
        } = createEventHandler();

        const subscribingLocationStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            ({ socketClient, state: { locationsSubscription } }) =>
              socketClient.connected && fp.isUndefined(locationsSubscription),
          )
          .switchMap(
            ({ socketClient, setState }) =>
              new Observable(observer => {
                const locationsSubscription = socketClient.subscribe(
                  ADMIN_DRIVER_LOCATION_QUERY_NEW,
                  ({ body }) => {
                    observer.next(JSON.parse(body));
                  },
                );
                setState(state => ({ ...state, locationsSubscription }));
              }),
          )
          .merge(onCustomUpdateDriverStream)
          .map(update => Immutable.fromJS(update))
          .startWith(Immutable.Map());

        return propsStream
          .combineLatest(subscribingLocationStream, (props, updatedDriver) => ({
            ...props,

            onCustomUpdateDriver,
            updatedDriver,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ----------- Subscribing Locations -------

      // ------------- Saving Locations ----------
      propsStream => {
        const {
          handler: onSaveLocations,
          stream: onSaveLocationsStream,
        } = createEventHandler();

        return propsStream
          .combineLatest(
            onSaveLocationsStream
              .distinctUntilChanged(isEqualData)
              .startWith(Immutable.OrderedMap()),
            (props, savedLocations) => ({
              ...props,

              savedLocations,
              onSaveLocations,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // ------------- Saving Locations ----------

      propsStream => {
        const {
          handler: onChangeTime,
          stream: onChangeTimeStream,
        } = createEventHandler();
        const {
          handler: onUpdateBoundary,
          stream: onUpdateBoundaryStream,
        } = createEventHandler();

        const driverLocationsStream = propsStream
          .distinctUntilKeyChanged("updatedDriver", isEqualData)
          .filter(({ updatedDriver }) => updatedDriver.size > 0)
          .map(({ updatedDriver, savedLocations, onSaveLocations }) => {
            const newLocations = Immutable.OrderedMap().withMutations(map => {
              updatedDriver.forEach(item => {
                map.merge(savedLocations);
                map.setIn([item.get("driver_id"), "location"], item);
              });
            });

            onSaveLocations(newLocations);
            return newLocations;
          })

          .filter(locations => locations.size > 0)
          .withLatestFrom(propsStream)
          .combineLatest(propsStream, ([locations], props) => [
            locations,
            props,
          ])
          .map(([locations, { bounds, filter }]) => {
            const supplierId = fp.toFinite(filter.get("supplier_id"));

            return Immutable.OrderedMap().withMutations(map => {
              locations.forEach((x, idx) => {
                if (
                  !fp.isEmpty(bounds) &&
                  bounds.contains({
                    lat: x.getIn(["location", "lat"]),
                    lng: x.getIn(["location", "lon"]),
                  })
                ) {
                  if (supplierId > 0) {
                    if (x.getIn(["driver", "supplier_id"]) === supplierId) {
                      map.set(idx, x);
                    }
                  } else {
                    map.set(idx, x);
                  }
                }
              });
            });
          })
          .distinctUntilChanged(isEqualData)
          .startWith(Immutable.OrderedMap())
          .map(locations => locations.slice(0, 100));

        const sliceDriverItemStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            props =>
              !props.isLoadingDriver &&
              props.location.query.driverItem &&
              props.location.query.historyItem,
          )
          .map(() => 0)
          .distinctUntilChanged(isEqualData)
          .merge(onChangeTimeStream)
          .withLatestFrom(
            propsStream.map(fp.pick(["driverHistoryLocations", "location"])),
          )
          .map(([index, { driverHistoryLocations, location }]) => {
            let x = 0;
            let lastDate;
            const sliceTime = getSliceTime(location);
            const list = [[]];
            const locations = driverHistoryLocations
              .get("locations")
              .slice(0, index + 1);
            locations.forEach(i => {
              if (lastDate !== i.get("date")) {
                if (
                  lastDate &&
                  differenceInMinutes(i.get("date"), lastDate) >= sliceTime
                ) {
                  list.push([]);
                  x += 1;
                }
                lastDate = i.get("date");
                list[x].push({
                  ...i.toJS(),
                  lng: i.get("lon"),
                });
              }
            });
            return Immutable.fromJS(list.filter(item => !fp.isEmpty(item)));
          })
          .startWith(Immutable.List());

        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilChanged(isEqualData)
            .filter(
              props =>
                props.location.query.filterItem &&
                !props.isLoadingDriver &&
                props.driverItem.size === 0 &&
                props.driverLastAlert.size > 0 &&
                !props.isDriverLastAlertLoading,
            )
            .take(1)
            .do(props =>
              props.showErrorMessage(
                props.getLocalisationMessage(
                  "driver_locations_not_found",
                  "Driver Locations Not Found!",
                ),
              ),
            ),
          propsStream
            .distinctUntilKeyChanged("driversList")
            .filter(
              props =>
                !props.isLoadingAlertsList &&
                props.location.query.driverItem &&
                props.driversList.size > 0,
            )
            .do(props => {
              const idx = props.driversList.findIndex(
                x =>
                  String(x.get("driver_id")) ===
                  props.location.query.driverItem,
              );

              if (idx > 0) {
                props.setState(
                  fp.flow(
                    fp.set("listPosition", idx * DRIVER_LIST_ITEM_HEIGHT),
                    fp.set("scrollingList", true),
                  ),
                );
              }
            }),
          propsStream
            .distinctUntilChanged(
              ({ rectBounds: aRectBounds }, { rectBounds: bRectBounds }) => {
                const aSize = aRectBounds ? aRectBounds.size : 0;
                const bSize = bRectBounds ? bRectBounds.size : 0;
                return aSize === bSize && bSize > 0;
              },
            )
            .map(fp.pick(["rectBounds", "orderItem"]))
            .merge(
              onUpdateBoundaryStream
                .withLatestFrom(propsStream)
                .map(
                  fp.flow(fp.get("1"), fp.pick(["rectBounds", "orderItem"])),
                ),
            )
            .map(({ rectBounds, orderItem }) => ({
              ...rectBounds.toJS(),
              orderId: orderItem || null,
            }))
            .filter(props => props.north_east && props.south_west)
            .switchMap(data => updateBoundaryByOrder(data)),
        ).startWith(null);

        const marketplaceStream = getMarketplace()
          .takeLast(1)
          .catch(() => Observable.of({}))
          .map(
            fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS),
          );

        return propsStream
          .combineLatest(
            // ordersCountStream,
            driverLocationsStream,
            sliceDriverItemStream,
            marketplaceStream,
            sideEffectsStream,
            (props, driverLocations, sliceDriverItem, marketplace) => ({
              ...props,
              onChangeTime,
              driverLocations,
              sliceDriverItem,
              marketplace,
              onUpdateBoundary,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },

      propsStream => {
        const driverItemInfoStream = propsStream
          .filter(
            ({ location, driverLocations }) =>
              fp.toFinite(location.query.driverItem) > 0 &&
              driverLocations.size > 0 &&
              driverLocations.has(fp.toFinite(location.query.driverItem)),
          )
          .map(({ driverDetails, driverLocations, location }) =>
            fromJS({
              status: formatText(driverDetails.get("duty_status")),
              fullName: driverDetails.get("full_name"),
              location: {
                lat: driverLocations
                  .get(fp.toFinite(location.query.driverItem))
                  .getIn(["location", "lat"]),
                lng: driverLocations
                  .get(fp.toFinite(location.query.driverItem))
                  .getIn(["location", "lon"]),
              },
            }),
          )
          .startWith(fromJS({}))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          driverItemInfoStream,
          (props, driverInfo) => ({
            ...props,
            driverInfo,
          }),
        );
      },

      // ----------- Subscribing OrderCount -------

      propsStream => {
        const subscribingOrderCountStream = propsStream

          .distinctUntilChanged(isEqualData)
          .filter(
            ({
              socketClient,
              state: { orderCountSubscription },
              marketplace,
            }) =>
              socketClient.connected &&
              fp.isUndefined(orderCountSubscription) &&
              !fp.has("marketplace_id", marketplace),
          )
          .switchMap(
            ({ socketClient, setState, marketplace }) =>
              new Observable(observer => {
                const orderCountSubscription = socketClient.subscribe(
                  sprintf(
                    ADMIN_RADAR_ORDER_COUNT,
                    marketplace.get("marketplace_id"),
                  ),
                  () => {
                    observer.next(Number(1));
                  },
                );
                setState(state => ({ ...state, orderCountSubscription }));
              }),
          )
          .withLatestFrom(propsStream)
          .do(([count, props]) => {
            props.setState(state => ({
              ...state,
              ordersCount: state.ordersCount + count,
            }));
          })
          .startWith(0);

        return propsStream
          .combineLatest(subscribingOrderCountStream, (props, orderCount) => ({
            ...props,
            orderCount,
          }))
          .distinctUntilChanged(isEqualData);
      },

      // ----------- Subscribing OrderCount -------

      // ---------- Getting Driver Location ------
      propsStream => {
        const {
          handler: onSetMapCenter,
          stream: onSetMapCenterStream,
        } = createEventHandler();

        const driverLocationStream = onSetMapCenterStream
          .distinctUntilChanged(isEqualData)
          .filter(fp.flow(fp.toFinite, x => x > 0))
          .switchMap(driverId =>
            getDriverOrdersLocation(driverId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.get("payload.data"),
              x => x || [],
              x => {
                const driverLocation = x.find(l => Boolean(l.driver_id)) || {};

                const orderLocationsRoute = List().withMutations(location => {
                  x.forEach(y => {
                    if (fp.toLower(y.status) === "completed") {
                      location.push({
                        ...y,
                        lng: y.lon,
                      });
                    } else if (
                      fp.toLower(fp.get("status", location.last())) ===
                      "completed"
                    ) {
                      location.push({
                        ...driverLocation,
                        lng: driverLocation.lon,
                      });
                    } else {
                      location.push({
                        ...y,
                        lng: y.lon,
                      });
                    }
                  });
                });

                return {
                  driverLocation: {
                    ...driverLocation,
                    lng: driverLocation.lon,
                  },
                  list: x.map(y => ({
                    ...y,
                    lng: y.lon,
                  })),
                  orderLocationsRoute,
                };
              },
              Immutable.fromJS,
            ),
          )
          .filter(
            list => list.get("list").size > 0 && list.get("driverLocation"),
          )
          .startWith(
            Immutable.fromJS({
              driverLocation: Immutable.Map(),
              list: Immutable.List(),
              orderLocationsRoute: Immutable.List(),
            }),
          )
          .distinctUntilChanged(isEqualData);

        const sideEffectsStream = Observable.merge(
          propsStream
            .map(fp.get("location.query.driverItem"))
            .distinctUntilChanged(isEqualData)
            .filter(Boolean)
            .do(driverId => onSetMapCenter(driverId)),
        ).startWith(null);

        return propsStream
          .combineLatest(
            driverLocationStream,
            sideEffectsStream,
            (props, locations) => ({
              ...props,

              driverLocation:
                locations.get("driverLocation").size > 0
                  ? locations.get("driverLocation").toJS()
                  : null,
              driverOrdersLocation: locations.get("list"),
              orderLocationsRoute: locations.get("orderLocationsRoute"),

              onSetMapCenter,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // ---------- Getting Driver Location ------

      propsStream => {
        const containerNode = createEventHandler();
        const leftWrapperNode = createEventHandler();

        const topContainerWidthStream = containerNode.stream
          .combineLatest(leftWrapperNode.stream, (container, leftNode) => {
            const containerWidth = !container ? 0 : getWidth(container);
            const leftNodeWidth = !leftNode ? 0 : getWidth(leftNode);

            if (containerWidth > 0 && leftNodeWidth > 0) {
              return `${containerWidth - leftNodeWidth - 20}px`;
            }

            return "auto";
          })
          .distinctUntilChanged(isEqualData)
          .startWith("auto");

        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilKeyChanged("ordersList")
            .filter(
              props =>
                !props.isOrdersLoading &&
                props.location.query.orderItem &&
                props.ordersList.size > 0,
            )
            .do(props => {
              const idx = props.ordersList.findIndex(
                x => String(x.get("id")) === props.location.query.orderItem,
              );

              if (idx > 0) {
                props.setState(
                  fp.flow(
                    fp.set("listPosition", idx * ORDER_LIST_ITEM_HEIGHT),
                    fp.set("scrollingList", true),
                  ),
                );
              }
            }),
          propsStream
            .distinctUntilKeyChanged("driversList")
            .filter(
              props =>
                !props.isDriversLoading &&
                props.location.query.driverItem &&
                props.orderFilter &&
                props.driversList.size > 0,
            )
            .do(props => {
              const idx = props.driversList.findIndex(
                x =>
                  String(x.get("driver_id")) ===
                  props.location.query.driverItem,
              );

              if (idx > -1) {
                props.onDriverOrders({
                  driverId: props.driverItem,
                  filter: props.orderFilter,
                  isFoodDelivery: props.isFoodDelivery,
                });
              }
            }),
        ).startWith(null);

        return propsStream.combineLatest(
          topContainerWidthStream,
          sideEffectsStream,
          (props, topContainerWidth) => ({
            ...props,
            topContainerWidth,
            topLeftContainerRef: leftWrapperNode.handler,
            containerRef: containerNode.handler,
          }),
        );
      },

      // ------------- Setting Map Center --------
      propsStream => {
        const mapCenterStream = propsStream
          .distinctUntilKeyChanged("driverLocation", isEqualData)
          .map(({ driverLocation, defaultCenter }) => {
            if (driverLocation) {
              return driverLocation;
            }

            return defaultCenter;
          });

        return propsStream
          .combineLatest(mapCenterStream, (props, mapCenter) => ({
            ...props,

            mapCenter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ------------- Setting Map Center --------
    ),
  ),
  pureComponent(
    fp.pick([
      "state",
      "filter",
      "orderFilter",
      "suggestedDriversFilter",
      "location",
      "driverItem",
      "isLoading",
      "mapCenter",
      "marketplace",
      "ordersList",
      "orderItemDetails",
      "driversList",
      "ordersTotal",
      "driversTotal",
      "isOrdersLoading",
      "isDriversLoading",
      "driverDetails",
      "driverLastAlert",
      "driverDetailsLoading",
      "isDriverLastAlertLoading",
      "onOrderRequestRefresh",
      "onDriverRequestRefresh",
      "onSelectedDriver",
      "onSelectedDriverRefresh",
      "driverLocations",
      "driverOrders",
      "driverOrdersTotal",
      "isDriverOrdersLoading",
      "onDriverOrders",
      "onRefreshDriverOrders",
      "sliceDriverItem",
      "suggestedDrivers",
      "suggestedDriversTotal",
      "isSuggestedDriversLoading",
      "onSuggestedDrivers",
      "onRefreshSuggestedDrivers",
      "isLoadingAlertsList",
      "alertsListTotal",
      "topLeftContainerRef",
      "onSetMapCenter",
      "driverOrdersLocation",
      "orderLocationsRoute",
      "driverHistoryLocations",
      "ordersCount",
      "filters",
      "isLoadingFilters",
      "onRequestRefreshFilters",
      "driverInfo",
      "showDateFilter",
    ]),
  ),
);

AdminNewRadarContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  setState: PropTypes.func,
  state: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  orderItem: PropTypes.number,
  driverItem: PropTypes.number,
  isDeliveryMode: PropTypes.bool,
  ordersList: PropTypes.instanceOf(Immutable.List),
  ordersTotal: PropTypes.number,
  isOrdersLoading: PropTypes.bool,
  orderItemDetails: PropTypes.instanceOf(Immutable.Map),
  orderItemDetailsLoading: PropTypes.bool,
  onOrderItemDetailed: PropTypes.func,
  driversList: PropTypes.instanceOf(Immutable.List),
  driversTotal: PropTypes.number,
  isDriversLoading: PropTypes.bool,
  driverOrders: PropTypes.instanceOf(Immutable.List),
  driverOrdersTotal: PropTypes.number,
  isDriverOrdersLoading: PropTypes.bool,
  suggestedDrivers: PropTypes.instanceOf(Immutable.List),
  suggestedDriversTotal: PropTypes.number,
  isSuggestedDriversLoading: PropTypes.bool,
  driverDetails: PropTypes.instanceOf(Immutable.Map),
  driverDetailsLoading: PropTypes.bool,
  onSelectedDriver: PropTypes.func,
  onDriverOrders: PropTypes.func,
  groupStatistics: PropTypes.instanceOf(Immutable.Map),
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  ordersCount: PropTypes.number,
  isDriverLastAlertLoading: PropTypes.bool,
  driverLocations: PropTypes.instanceOf(Immutable.OrderedMap),
  onSuggestedDrivers: PropTypes.func,
  driverLastAlert: PropTypes.instanceOf(Immutable.Map),
  onChangeTime: PropTypes.func,
  sliceDriverItem: PropTypes.instanceOf(Immutable.List),
  filter: PropTypes.instanceOf(DataListFilter),
  orderFilter: PropTypes.instanceOf(DataListFilter),
  suggestedDriversFilter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  onChangeBounds: PropTypes.func,
  onUpdateBoundary: PropTypes.func,
  topLeftContainerRef: PropTypes.func,
  containerRef: PropTypes.func,
  topContainerWidth: PropTypes.string,
  mapCenter: PropTypes.object,
  onSetMapCenter: PropTypes.func,
  onOrderRequestRefresh: PropTypes.func,
  driverLocation: PropTypes.object,
  driverHistoryLocations: PropTypes.instanceOf(Immutable.Map),
  filters: PropTypes.instanceOf(List),
  isLoadingFilters: PropTypes.bool,
  onRequestRefreshFilters: PropTypes.func,
  onOrderDetailRefresh: PropTypes.func,
  onDriverRequestRefresh: PropTypes.func,
  onRefreshDriverOrders: PropTypes.func,
  onRefreshSuggestedDrivers: PropTypes.func,
  driverInfo: PropTypes.instanceOf(Immutable.Map),
  isFoodDelivery: PropTypes.bool,
  isMapKeyAvailable: PropTypes.bool,
};

function AdminNewRadarContainer(props) {
  const { classes, state, location } = props;

  const pickupLocation = getOrderPickupLatLng(props.orderItemDetails);
  const dropoffLocation = getOrderDropoffLatLng(props.orderItemDetails);
  const isLiveStream = isLiveStreamingEnabled(
    fp.get("query.is_live_streaming", location),
  );

  const orderLocations = [];
  const orderIds = [];
  if (
    isDeliveryModeEnabled(props.location.query.is_delivery_mode) &&
    !props.isOrdersLoading &&
    !props.orderItem
  ) {
    props.ordersList.forEach(order => {
      const dropoff = getOrderDropoffLocation(order);
      if (isValidCoordinates(dropoff)) {
        orderIds.push(order.get("id"));
        orderLocations.push(dropoff);
      }
    });
  }
  return (
    <FlexBox
      container={8}
      className={classes.container}
      element={<div ref={props.containerRef} />}
    >
      <PageLoading
        isLoading={props.isOrdersLoading && !props.orderItemDetailsLoading}
      />

      <Redirect
        when={!("from_date_time" in location.query) && props.isDeliveryMode}
        to={updateQuery(
          location,
          fp.set("from_date_time", formatDateTimeToUrl(startOfToday())),
        )}
      />

      {state.showDateFilter && (
        <AdminOrderFilterDateWrapper
          open={true}
          filter={props.orderFilter}
          onFilterChange={filter => {
            props.setLocationQuery(
              filter
                .setValueMap({
                  is_delivery_mode: true,
                  size: props.orderFilter.getSize(),
                })
                .getDefinedValues(),
            );
          }}
          onOrderRefreshRequest={() => {
            props.onOrderRequestRefresh();
            props.onRequestRefreshFilters();
            props.setState(fp.set("ordersCount", 0));
          }}
          onClickDateFilter={() =>
            props.setState(fp.set("showDateFilter", false))
          }
        />
      )}
      <FormDialog
        open={"deleteItem" in location.query}
        onRequestClose={() => props.setLocationQuery(fp.unset("deleteItem"))}
        onSubmit={() =>
          props.isFoodDelivery
            ? deleteFoodOrder(
                fp.toInteger(fp.get("location.query.deleteItem", props)),
              ).catch(ResponseError.throw)
            : batchAsyncUpdateOrder({
                order_numbers: [
                  fp.toInteger(fp.get("location.query.deleteItem", props)),
                ],
                order_status: CANCELLED,
              }).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.onOrderRequestRefresh();
          props.setLocationQuery(fp.unset("deleteItem"));
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "order_successfully_cancelled",
              "Order Successfully Cancelled",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      >
        {props.getLocalisationMessage(
          "are_you_sure_you_want_to_cancel_this_order",
          "Are you sure you want to cancel this order?",
        )}
      </FormDialog>
      <FlexBox direction="column" flex={true} className={classes.root}>
        <FlexBox
          className={classes.topLeftWrapper}
          element={<div ref={props.topLeftContainerRef} />}
        >
          <OrderDriversRadarSearch
            isFoodDelivery={props.isFoodDelivery}
            filter={props.filter}
            marketplace={props.marketplace}
            groupStatistics={props.groupStatistics}
            menuLayoutDrawer={({ visibleMenu, toggleMenu }) => (
              <AppLayoutDrawer
                open={visibleMenu}
                className={classes.drawer}
                onRequestChange={() => toggleMenu()}
              >
                <SidebarMenu />
              </AppLayoutDrawer>
            )}
            onChangeDeliveryMode={filter => {
              props.setLocationQueryFilter(filter);
              props.setLocationQuery(
                fp.flow(
                  fp.unset("orderItem"),
                  fp.unset("driverItem"),
                  fp.unset("historyItem"),
                  fp.unset("status"),
                  fp.unset("filterDate"),
                  fp.unset("driver_ids"),
                  fp.unset("driver_page"),
                  fp.unset("driver_status"),
                  fp.unset("driver_availability"),
                  fp.unset("duty_status"),
                  fp.unset("driver_tab"),
                  fp.unset("on_duty"),
                  fp.unset("order_by"),
                  fp.unset("tab"),
                  fp.unset("search"),
                ),
              );
              props.setState(fp.set("ordersCount", 0));
            }}
            onSubmit={values =>
              props.setLocationQueryFilter(
                props.filter.setValueMap({
                  page: 0,
                  status: null,
                  tab: null,
                  current_alerts: null,
                  current_orders: null,
                  search: values.search,
                }),
              )
            }
            onClickDateFilter={() =>
              props.setState(fp.set("showDateFilter", true))
            }
          />
        </FlexBox>
        {!isDeliveryModeEnabled(props.location.query.is_delivery_mode) ? (
          <FlexBox
            className={classes.topRightWrapper}
            style={{
              width:
                !isLiveStream &&
                fp.toFinite(location.query.driverItem) > 0 &&
                fp.toFinite(location.query.historyItem) > 0
                  ? props.topContainerWidth
                  : "auto",
            }}
          >
            <FlexBox
              flex={true}
              className={classes.topLinePaper}
              // element={<Paper />}
            >
              {props.isFoodDelivery && (
                <IconButton
                  containerElement={<Link to={updateQuery(ORDER_LIST_URL)} />}
                  style={{
                    height: 56,
                    display: "flex",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <ListingIcon />
                </IconButton>
              )}

              {!isLiveStream &&
                location.query.driverItem > 0 &&
                location.query.historyItem > 0 && (
                  <DriverRadarTimeLine
                    driverItem={fp.toFinite(location.query.driverItem)}
                    initialValues={{
                      date: new Date(location.query.filterDate),
                    }}
                    onChangeDate={({ date }) =>
                      props.setLocationQuery(
                        fp.set("filterDate", formatDateToUrl(date)),
                      )
                    }
                    locations={
                      location.query.historyItem > 0
                        ? props.driverHistoryLocations
                        : Immutable.Map({ locations: new Immutable.Map() })
                    }
                    onChangeTime={props.onChangeTime}
                  />
                )}

              {!isLiveStream &&
                location.query.driverItem > 0 &&
                !location.query.historyItem && (
                  <div className={classes.liveStreamingIconWrapper}>
                    <Avatar
                      backgroundColor="transparent"
                      className={classes.liveStreaming}
                      src={iconDriverHistory}
                      onClick={() => {
                        props.setLocationQuery(
                          fp.flow(
                            fp.set("historyItem", location.query.driverItem),
                            fp.set("filterDate", formatDateToUrl(new Date())),
                          ),
                        );
                      }}
                    />
                  </div>
                )}

              <div className={classes.liveStreamingIconWrapper}>
                <Avatar
                  backgroundColor="transparent"
                  className={classes.liveStreaming}
                  src={
                    isLiveStream ? iconLiveStreamingOn : iconLiveStreamingOff
                  }
                  onClick={() => {
                    props.setLocationQuery(
                      fp.flow(
                        fp.unset("historyItem"),
                        fp.unset("filterDate"),
                        fp.set("is_live_streaming", !isLiveStream),
                      ),
                    );
                  }}
                />
              </div>
            </FlexBox>
          </FlexBox>
        ) : (
          <FlexBox className={classes.topRightWrapper}>
            <FlexBox
              flex={true}
              className={classes.topLinePaper}
              // element={<Paper />}
            >
              {props.isFoodDelivery && (
                <IconButton
                  containerElement={
                    <Link to={updateQuery(ORDER_LIST_URL, { is_uae: true })} />
                  }
                  style={{
                    height: 56,
                    display: "flex",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <ListingIcon />
                </IconButton>
              )}
              <div className={classes.liveStreamingIconWrapper}>
                <Avatar
                  backgroundColor="transparent"
                  className={classes.liveStreaming}
                  src={
                    isLiveStream ? iconLiveStreamingOn : iconLiveStreamingOff
                  }
                  onClick={() => {
                    props.setLocationQuery(
                      fp.flow(fp.set("is_live_streaming", !isLiveStream)),
                    );
                  }}
                />
              </div>
            </FlexBox>
          </FlexBox>
        )}
        <FlexBox flex={true}>
          <FlexBox
            className={classes.leftWrapper}
            flex={true}
            direction="column"
          >
            <FlexBox flex={true} className={classes.mainList}>
              {props.filter.getBoolValue("is_delivery_mode") ? (
                <DriversRadarOrdersList
                  onFilterClick={filter => {
                    props.setLocationQuery(
                      filter
                        .setValueMap({
                          is_delivery_mode: true,
                          size: props.orderFilter.getSize(),
                        })
                        .getDefinedValues(),
                    );
                  }}
                  newOrdersCount={state.ordersCount}
                  listPosition={state.listPosition}
                  scrollingList={state.scrollingList}
                  orders={props.ordersList}
                  showCustomFilter={true}
                  isOrdersLoading={props.isOrdersLoading}
                  orderItemDetails={props.orderItemDetails}
                  orderItemDetailsLoading={props.orderItemDetailsLoading}
                  orderFilter={props.orderFilter}
                  onOrderRefreshRequest={() => {
                    props.onOrderRequestRefresh();
                    props.onRequestRefreshFilters();
                    props.setState(fp.set("ordersCount", 0));
                  }}
                  total={props.ordersTotal}
                  orderItem={fp.toFinite(location.query.orderItem)}
                  onScrollingDone={() =>
                    props.setState(fp.set("scrollingList", false))
                  }
                  onChangePage={page =>
                    props.setLocationQueryFilter(
                      props.orderFilter.setPage(page),
                    )
                  }
                  onItemCloseClick={() =>
                    props.setLocationQuery(fp.flow(fp.unset("orderItem")))
                  }
                  onChangePageSize={pageSize =>
                    props.setLocationQueryFilter(
                      props.orderFilter.setPageSize(pageSize),
                    )
                  }
                  onItemClick={(id, index) => {
                    onOrderItemSelect(id, index, props);
                  }}
                  onItemCancel={id =>
                    props.setLocationQuery(fp.flow(fp.set("deleteItem", id)))
                  }
                  filters={props.filters}
                  onRequestRefresh={props.onRequestRefreshFilters}
                  isLoadingFilters={props.isLoadingFilters}
                  getOrderList={getOrderList}
                  showDateFilter={state.showDateFilter}
                  onClickDateFilter={() =>
                    props.setState(fp.set("showDateFilter", false))
                  }
                />
              ) : (
                <DriversRadarDriversList
                  onFilterClick={filter => {
                    props.setLocationQuery(
                      filter
                        .setValueMap({
                          is_delivery_mode: false,
                          status: null,
                          driver_page: 0,
                          size: props.filter.getSize(),
                        })
                        .getDefinedValues(),
                    );
                  }}
                  listPosition={state.driverListPosition}
                  scrollingList={state.driverScrollingList}
                  filter={props.filter.setValueMap({
                    status: fp.get("location.query.driver_status", props),
                    driver_ids: null,
                  })}
                  list={props.driversList}
                  total={props.driversTotal}
                  isLoading={props.isDriversLoading}
                  liveDriverLocations={props.driverLocations}
                  driverItem={props.driverItem}
                  driverDetails={props.driverDetails}
                  driverLastAlert={props.driverLastAlert}
                  driverDetailsLoading={
                    props.driverDetailsLoading || props.isDriverLastAlertLoading
                  }
                  onScrollingDone={() =>
                    props.setState(fp.set("driverScrollingList", false))
                  }
                  onChangePage={page => {
                    props.setLocationQueryFilter(
                      props.filter.setValue("driver_page", page),
                    );
                  }}
                  onChangePageSize={pageSize =>
                    props.setLocationQueryFilter(
                      props.filter.setPageSize(pageSize),
                    )
                  }
                  onListRequestRefresh={() => {
                    props.onDriverRequestRefresh();
                  }}
                  onItemClick={(id, index) => {
                    onDriverItemSelect(id, index, props);
                  }}
                  getDriverList={getDriverList}
                />
              )}
            </FlexBox>
          </FlexBox>

          {Boolean(props.orderItem || props.driverItem) && (
            <FlexBox
              className={classes.rightWrapper}
              flex={true}
              direction="column"
              justify={JUSTIFY_END}
            >
              <FlexBox flex={true} className={classes.mainList}>
                {location.query.is_delivery_mode !== "false" &&
                  props.orderItemDetails &&
                  shouldOrderBeAssigned(props.orderItemDetails) &&
                  !isLiveStream && (
                    <DriversRadarDriversList
                      depends={true}
                      onFilterClick={filter => {
                        const mergedFilter = filter.setValueMap({
                          is_delivery_mode: true,
                          orderItem: props.orderItem,
                        });

                        props.onSuggestedDrivers({
                          orderId: location.query.orderItem,
                          filter: mergedFilter,
                        });
                        props.setLocationQuery(
                          mergedFilter
                            .setValue(
                              "is_live_streaming",
                              location.query.is_live_streaming,
                            )
                            .getDefinedValues(),
                        );
                      }}
                      listPosition={state.driverListPosition}
                      scrollingList={state.driverScrollingList}
                      filter={props.suggestedDriversFilter}
                      list={props.suggestedDrivers || fromJS([])}
                      total={props.suggestedDriversTotal}
                      isLoading={props.isSuggestedDriversLoading}
                      driverItem={fp.toFinite(location.query.driverItem)}
                      orderItem={fp.toFinite(location.query.orderItem)}
                      orderNumber={props.orderItemDetails.get("order_number")}
                      driverDetails={props.driverDetails}
                      driverDetailsLoading={props.driverDetailsLoading}
                      onScrollingDone={() =>
                        props.setState(fp.set("driverScrollingList", false))
                      }
                      onChangePage={page =>
                        props.setLocationQueryFilter(props.filter.setPage(page))
                      }
                      onCloseSelectedDriverClick={() => {
                        props.setLocationQuery(fp.flow(fp.unset("driverItem")));
                      }}
                      onAssignDriver={() => {
                        props.onOrderDetailRefresh(
                          fp.toFinite(location.query.orderItem),
                        );
                        props.onOrderRequestRefresh();
                        props.onRequestRefreshFilters();
                        props.setState(fp.set("ordersCount", 0));
                      }}
                      onChangePageSize={pageSize =>
                        props.setLocationQueryFilter(
                          props.filter.setPageSize(pageSize),
                        )
                      }
                      onItemClick={(id, index) => {
                        props.onSelectedDriver(id);
                        props.onSetMapCenter(id);
                        props.setLocationQuery(
                          fp.flow(
                            fp.unset("filterItem"),
                            fp.unset("filterDate"),
                            fp.unset("historyItem"),
                            fp.set("driverItem", id),
                          ),
                        );
                        props.setState(
                          fp.flow(
                            fp.set(
                              "driverListPosition",
                              index * DRIVER_LIST_ITEM_HEIGHT,
                            ),
                            fp.set("driverScrollingList", index > 0),
                          ),
                        );
                      }}
                      onListRequestRefresh={() => {
                        props.onRefreshSuggestedDrivers();
                      }}
                      getRadarOrderSuggestedDrivers={
                        getRadarOrderSuggestedDrivers
                      }
                    />
                  )}
                {location.query.is_delivery_mode !== "false" &&
                  props.orderItemDetails &&
                  shouldOrderBeAssigned(props.orderItemDetails) &&
                  isLiveStream && (
                    <DriversRadarDriversListLive
                      onFilterClick={filter => {
                        props.setLocationQuery(
                          filter
                            .setValueMap({
                              is_delivery_mode: false,
                              status: null,
                              driver_page: 0,
                              size: props.filter.getSize(),
                            })
                            .getDefinedValues(),
                        );
                      }}
                      onDriverClick={driverId => {
                        props.setLocationQuery(
                          fp.flow(
                            fp.unset("filterItem"),
                            fp.unset("filterDate"),
                            fp.unset("historyItem"),
                            fp.set("driverItem", driverId),
                          ),
                        );
                        props.onSelectedDriver(driverId);
                      }}
                      listPosition={state.driverListPosition}
                      scrollingList={state.driverScrollingList}
                      filter={props.filter.setValueMap({
                        status: fp.get("location.query.driver_status", props),
                        driver_ids: null,
                      })}
                      list={props.driversList}
                      total={props.driversTotal}
                      isLoading={props.isDriversLoading}
                      liveDriverLocations={props.driverLocations}
                      driverItem={props.driverItem}
                      driverDetails={props.driverDetails}
                      driverLastAlert={props.driverLastAlert}
                      driverDetailsLoading={
                        props.driverDetailsLoading ||
                        props.isDriverLastAlertLoading
                      }
                      orderNumber={props.orderItemDetails.get("order_number")}
                      onScrollingDone={() =>
                        props.setState(fp.set("driverScrollingList", false))
                      }
                      onChangePage={page => {
                        props.setLocationQueryFilter(
                          props.filter.setValue("driver_page", page),
                        );
                      }}
                      onChangePageSize={pageSize =>
                        props.setLocationQueryFilter(
                          props.filter.setPageSize(pageSize),
                        )
                      }
                      onAssignDriver={() => {
                        props.onOrderDetailRefresh(
                          fp.toFinite(location.query.orderItem),
                        );
                        props.onOrderRequestRefresh();
                        props.onRequestRefreshFilters();
                        props.setState(fp.set("ordersCount", 0));
                      }}
                      onListRequestRefresh={() => {
                        props.onDriverRequestRefresh();
                      }}
                      onItemClick={(id, index) => {
                        props.onSelectedDriver(id);
                        props.onSetMapCenter(id);
                        props.setLocationQuery(
                          fp.flow(
                            fp.unset("filterItem"),
                            fp.unset("filterDate"),
                            fp.unset("historyItem"),
                            fp.set("driverItem", id),
                          ),
                        );
                        props.setState(
                          fp.flow(
                            fp.set(
                              "driverListPosition",
                              index * DRIVER_LIST_ITEM_HEIGHT,
                            ),
                            fp.set("driverScrollingList", index > 0),
                          ),
                        );
                      }}
                      getDriverList={getDriverList}
                    />
                  )}

                {location.query.is_delivery_mode === "false" && (
                  <DriversRadarOrdersList
                    depends={true}
                    onFilterClick={filter => {
                      let mergedFilter = mergeFilterToFilter(
                        filter,
                        props.orderFilter,
                        toOrderFilter,
                        OrderedSet.of(
                          "page",
                          "size",
                          "search",
                          "order_by",
                          "search_type",
                          "order_by_direction",
                          "status",
                          "tab",
                        ),
                      );

                      if (filter.getValue("tab") === ALL_TAB) {
                        mergedFilter = mergedFilter.setValue("status", null);
                      }
                      props.onDriverOrders({
                        driverId: props.driverItem,
                        filter: mergedFilter.setPage(0),
                        isFoodDelivery: props.isFoodDelivery,
                      });

                      props.setLocationQuery(
                        mergedFilter
                          .setValueMap(props.filter)
                          .setValue("driverItem", props.driverItem)
                          .getDefinedValues(),
                      );
                    }}
                    listPosition={state.listPosition}
                    scrollingList={state.scrollingList}
                    orders={props.driverOrders}
                    isOrdersLoading={props.isDriverOrdersLoading}
                    orderItemDetails={props.orderItemDetails}
                    orderItemDetailsLoading={props.orderItemDetailsLoading}
                    orderFilter={props.orderFilter.setValueMap({
                      driver_ids: props.driverItem,
                    })}
                    total={props.driverOrdersTotal}
                    orderItem={fp.toFinite(location.query.orderItem)}
                    onScrollingDone={() =>
                      props.setState(fp.set("scrollingList", false))
                    }
                    onChangePage={page => {
                      props.onDriverOrders({
                        driverId: props.driverItem,
                        filter: props.orderFilter.setPage(page),
                        isFoodDelivery: props.isFoodDelivery,
                      });
                      props.setLocationQueryFilter(
                        props.orderFilter.setPage(page),
                      );
                    }}
                    onChangePageSize={pageSize => {
                      props.onDriverOrders({
                        driverId: props.driverItem,
                        filter: props.orderFilter.setPageSize(pageSize),
                        isFoodDelivery: props.isFoodDelivery,
                      });
                      props.setLocationQueryFilter(
                        props.orderFilter.setPageSize(pageSize),
                      );
                    }}
                    onItemClick={(id, index) => {
                      props.onOrderItemDetailed(id);
                      props.setLocationQuery(
                        fp.flow(
                          fp.unset("filterItem"),
                          fp.unset("filterDate"),
                          fp.unset("historyItem"),
                          fp.set("orderItem", id),
                        ),
                      );
                      props.setState(
                        fp.flow(
                          fp.set(
                            "listPosition",
                            index * ORDER_LIST_ITEM_HEIGHT,
                          ),
                          fp.set("scrollingList", index > 0),
                        ),
                      );
                    }}
                    onOrderRefreshRequest={() => {
                      props.onRefreshDriverOrders();
                    }}
                    onItemCloseClick={() =>
                      props.setLocationQuery(fp.flow(fp.unset("orderItem")))
                    }
                    isFoodDelivery={props.isFoodDelivery}
                    getOrderList={getOrderList}
                  />
                )}
              </FlexBox>
            </FlexBox>
          )}
          <FlexBox flex={true}>
            <FlexBox flex={true} direction="column">
              <FlexBox flex={true}>
                {props.isMapKeyAvailable ? (
                  <GoogleMapWrapper
                    zoom={15}
                    minZoom={4}
                    hasZoomControl={false}
                    onDragEnd={props.onUpdateBoundary}
                    onZoomChanged={props.onUpdateBoundary}
                    onBoundsChanged={props.onChangeBounds}
                    mapCenter={props.mapCenter}
                    className={classes.map}
                  >
                    {Boolean(
                      props.driverHistoryLocations.get("paths").size > 0 &&
                        location.query.historyItem,
                    ) &&
                      props.driverHistoryLocations
                        .get("paths")
                        .map((x, idx) => (
                          <Polyline
                            key={idx}
                            path={x.toJS()}
                            strokeColor="#000055"
                          />
                        ))}
                    {Boolean(
                      props.sliceDriverItem.size > 0 &&
                        location.query.historyItem,
                    ) &&
                      props.sliceDriverItem.map((x, idx) => (
                        <Polyline
                          key={idx}
                          path={x.toJS()}
                          strokeColor="#0099FF"
                        />
                      ))}

                    {Boolean(
                      location.query.historyItem &&
                        props.sliceDriverItem.size > 0,
                    ) && (
                      <VehicleMarker
                        withMotion={false}
                        rotation={props.sliceDriverItem
                          .last()
                          .last()
                          .get("angle")}
                        position={{
                          lat: props.sliceDriverItem
                            .last()
                            .last()
                            .get("lat"),
                          lng: props.sliceDriverItem
                            .last()
                            .last()
                            .get("lng"),
                        }}
                      />
                    )}

                    <ZoomControl position="RIGHT_BOTTOM" />
                    {fp.toFinite(location.query.orderItem) > 0 &&
                      isValidCoordinates(pickupLocation) &&
                      isValidCoordinates(dropoffLocation) && (
                        <OrderDriverLocationsMap
                          isLiveStream={isLiveStream}
                          orderId={props.orderItem}
                          order={props.orderItemDetails}
                          liveDriverLocations={props.driverLocations}
                          driverItem={props.driverItem}
                          onDriverClick={driverId => {
                            // onDriverItemSelect(driverId, -1, props)
                            props.setLocationQuery(
                              fp.flow(
                                fp.unset("filterItem"),
                                fp.unset("filterDate"),
                                fp.unset("historyItem"),
                                fp.set("driverItem", driverId),
                              ),
                            );
                            props.onSelectedDriver(driverId);
                          }}
                        />
                      )}
                    {isLiveStream &&
                      fp.toFinite(location.query.driverItem) > 0 &&
                      props.driverLocations.size > 0 &&
                      location.query.is_delivery_mode === "true" &&
                      props.driverInfo.has("location") && (
                        <InfoWindow
                          open={true}
                          position={{
                            lat: props.driverInfo.getIn(["location", "lat"]),
                            lng: props.driverInfo.getIn(["location", "lng"]),
                          }}
                          onCloseClick={() => {
                            props.setLocationQuery(
                              fp.flow(
                                fp.unset("filterItem"),
                                fp.unset("filterDate"),
                                fp.unset("historyItem"),
                                fp.unset("driverItem"),
                              ),
                            );
                          }}
                        >
                          <FlexBox>
                            <FlexBox flex={true} direction="column">
                              <strong>
                                FullName: {props.driverInfo.get("fullName")}
                              </strong>
                              <strong>
                                Status:{" "}
                                {props.driverInfo.get("status") || "N/A"}
                              </strong>
                            </FlexBox>
                          </FlexBox>
                        </InfoWindow>
                      )}
                    {isDeliveryModeEnabled(
                      props.location.query.is_delivery_mode,
                    ) &&
                      !fp.isEmpty(orderLocations) && (
                        <div>
                          {orderLocations.map((orderLatLng, idx) => (
                            <OrderPin
                              key={idx}
                              size={30}
                              position={orderLatLng}
                              onClick={() => {
                                onOrderItemSelect(orderIds[idx], idx, props);
                              }}
                            />
                          ))}
                          <FitBounds latLngBounds={orderLocations} />
                        </div>
                      )}

                    {!isDeliveryModeEnabled(
                      props.location.query.is_delivery_mode,
                    ) &&
                      location.query.driverItem &&
                      !location.query.historyItem &&
                      props.driverOrders && (
                        <div>
                          <DriverOrderLocationsMapV2
                            driverId={location.query.driverItem}
                            orders={props.driverOrders}
                            driverLocation={fromJS(props.driverLocation)}
                            liveDriverLocations={props.driverLocations}
                            onOrderClick={(orderId, index) => {
                              props.onOrderItemDetailed(orderId);
                              props.setLocationQuery(
                                fp.flow(
                                  fp.unset("filterItem"),
                                  fp.unset("filterDate"),
                                  fp.unset("historyItem"),
                                  fp.set("orderItem", orderId),
                                ),
                              );
                              props.setState(
                                fp.flow(
                                  fp.set(
                                    "listPosition",
                                    index * ORDER_LIST_ITEM_HEIGHT,
                                  ),
                                  fp.set("scrollingList", index > -1),
                                ),
                              );
                            }}
                          />
                        </div>
                      )}
                  </GoogleMapWrapper>
                ) : (
                  <LeafletMapWrapper
                    className={classes.map}
                    mapCenter={props.mapCenter}
                    onDragEnd={props.onUpdateBoundary}
                    onZoomChanged={props.onUpdateBoundary}
                    onCenterChanged={props.onChangeBounds}
                    zoom={15}
                    minZoom={4}
                    hasZoomControl={true}
                    position="bottomright"
                  >
                    {Boolean(
                      props.driverHistoryLocations.get("paths").size > 0 &&
                        location.query.historyItem,
                    ) &&
                      props.driverHistoryLocations
                        .get("paths")
                        .map((x, idx) => (
                          <LPolyline
                            key={idx}
                            path={x.toJS()}
                            weight={3}
                            opacity={1}
                            color="#000055"
                          />
                        ))}
                    {Boolean(
                      props.sliceDriverItem.size > 0 &&
                        location.query.historyItem &&
                        !isLiveStream,
                    ) &&
                      props.sliceDriverItem.map((x, idx) => (
                        <LPolyline
                          key={idx}
                          path={x.toJS()}
                          weight={3}
                          opacity={1}
                          color="#0099FF"
                        />
                      ))}

                    {Boolean(
                      location.query.historyItem &&
                        props.sliceDriverItem.size > 0,
                    ) && (
                      <LVehicleMarker
                        withMotion={false}
                        rotation={props.sliceDriverItem
                          .last()
                          .last()
                          .get("angle")}
                        position={{
                          lat: props.sliceDriverItem
                            .last()
                            .last()
                            .get("lat"),
                          lng: props.sliceDriverItem
                            .last()
                            .last()
                            .get("lng"),
                        }}
                      />
                    )}

                    {!props.driverItem && (
                      <DriverRadarVehicles
                        mapType="leaflet_map"
                        onClick={driverId =>
                          onDriverItemSelect(driverId, -1, props)
                        }
                        locations={props.driverLocations}
                      />
                    )}

                    {fp.toFinite(location.query.orderItem) > 0 &&
                      isValidCoordinates(pickupLocation) &&
                      isValidCoordinates(dropoffLocation) && (
                        <OrderDriverLocationsMapLeaflet
                          isLiveStream={isLiveStream}
                          orderId={props.orderItem}
                          order={props.orderItemDetails}
                          driverItem={props.driverItem}
                          liveDriverLocations={props.driverLocations}
                          onDriverClick={driverId => {
                            // onDriverItemSelect(driverId, -1, props)
                            props.setLocationQuery(
                              fp.flow(
                                fp.unset("filterItem"),
                                fp.unset("filterDate"),
                                fp.unset("historyItem"),
                                fp.set("driverItem", driverId),
                              ),
                            );
                            props.onSelectedDriver(driverId);
                          }}
                        />
                      )}

                    {fp.toFinite(location.query.driverItem) > 0 &&
                      props.driverLocations.size > 0 &&
                      location.query.is_delivery_mode === "true" &&
                      props.driverInfo.has("location") && (
                        <LInfoWindow
                          open={true}
                          position={{
                            lat: props.driverInfo.getIn(["location", "lat"]),
                            lng: props.driverInfo.getIn(["location", "lng"]),
                          }}
                          onCloseClick={() => {
                            props.setLocationQuery(
                              fp.flow(
                                fp.unset("filterItem"),
                                fp.unset("filterDate"),
                                fp.unset("historyItem"),
                                fp.unset("driverItem"),
                              ),
                            );
                          }}
                        >
                          <FlexBox>
                            <FlexBox flex={true} direction="column">
                              <strong>
                                FullName: {props.driverInfo.get("fullName")}
                              </strong>
                              <strong>
                                Status:{" "}
                                {props.driverInfo.get("status") || "N/A"}
                              </strong>
                            </FlexBox>
                          </FlexBox>
                        </LInfoWindow>
                      )}

                    {isDeliveryModeEnabled(
                      props.location.query.is_delivery_mode,
                    ) &&
                      !fp.isEmpty(orderLocations) && (
                        <div>
                          {orderLocations.map((orderLatLng, idx) => (
                            <OrderPin
                              mapType="leaflet_map"
                              key={idx}
                              size={30}
                              position={orderLatLng}
                              onClick={() => {
                                onOrderItemSelect(orderIds[idx], idx, props);
                              }}
                            />
                          ))}
                          <LFitBounds latLngBounds={orderLocations} />
                        </div>
                      )}

                    {!isDeliveryModeEnabled(
                      props.location.query.is_delivery_mode,
                    ) &&
                      location.query.driverItem &&
                      !location.query.historyItem &&
                      props.driverOrders && (
                        <div>
                          <DriverOrderLocationsMapLeaflet
                            driverId={fp.toFinite(location.query.driverItem)}
                            orders={props.driverOrders}
                            driverLocation={fromJS(props.driverLocation)}
                            liveDriverLocations={props.driverLocations}
                            onOrderClick={(orderId, index) => {
                              props.onOrderItemDetailed(orderId);
                              props.setLocationQuery(
                                fp.flow(
                                  fp.unset("filterItem"),
                                  fp.unset("filterDate"),
                                  fp.unset("historyItem"),
                                  fp.set("orderItem", orderId),
                                ),
                              );
                              props.setState(
                                fp.flow(
                                  fp.set(
                                    "listPosition",
                                    index * ORDER_LIST_ITEM_HEIGHT,
                                  ),
                                  fp.set("scrollingList", index > -1),
                                ),
                              );
                            }}
                          />
                        </div>
                      )}
                  </LeafletMapWrapper>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AdminNewRadarContainer);
