import { OrderedSet } from "immutable";

export const GMAIL = "GMAIL";
export const YANDEX = "YANDEX";
export const MICROSOFT = "MICROSOFT";
export const OTHERS = "OTHERS";

export const EMAIL_PARAMETERS = {
  GMAIL: {
    host: "smtp.gmail.com",
    protocol: "smtp",
    mailSmtpSslTrust: "*",
    port: 587,
    info:
      "In order to send emails from this email you need to allow permission for it from your own gmail account. There are instructions on the link . You should allow for  Less secure app access which is mentioned in this link.",
    link: "https://support.google.com/accounts/answer/6010255?hl=en",
  },
  YANDEX: {
    host: "smtp.yandex.com",
    protocol: "smtp",
    mailSmtpSslTrust: "*",
    port: 587,
    info:
      "In order to send emails from this email you need to allow permission for it from your own yandex account. There are instructions on the link . You should create application password (all details and instructions can be found through the link) and use the generated application password as a password in the form.",
    link:
      "https://yandex.com/support/passport/authorization/app-passwords.html",
  },
  MICROSOFT: {
    host: "smtp.office365.com",
    protocol: "smtp",
    mailSmtpSslTrust: "*",
    port: 587,
    info: "",
    link: "",
  },
  OTHERS: {
    host: "",
    protocol: "",
    mailSmtpSslTrust: "",
    port: "",
    info:
      "In order to send emails from this email, you might need to allow permission for it from your own mail provider account(try using the documentation of your mail provider). Also, make sure to use SMTP as protocol, hence our server supports SMTP.",
    link: "",
  },
};

export default OrderedSet.of(GMAIL, YANDEX, MICROSOFT, OTHERS);
