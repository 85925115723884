import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import AdminOrderChargeEditDialogWrapper from "./AdminOrderChargeEditDialogWrapper";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  mapOrderCODHistoryToActivitiasV2,
  mapOrderNotesAndHistoryToActivities,
} from "../../helpers/OrderHelper";
import ResponseError from "../../helpers/ResponseError";
import { getUser } from "../../reducers/ProfileReducer";
import { marketplaceGenerateTaxInvoiceEnabled } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ROLE_FINANCE_USER } from "../../constants/AdminRoleTypes";
import {
  CUSTOMER_ITEM_URL,
  DRIVER_LIST_URL,
  SUPPLIER_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  getOrder,
  getOrderActivities,
  getOrderCODHistory,
  uploadOrderSignature,
} from "../../api/admin/AdminOrderApi";
import { getCustomer } from "../../api/admin/AdminCustomerApi";
import { getSupplier } from "../../api/admin/AdminSupplierApi";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderActivitiesTimeline from "../../components/orders-core/OrderActivitiesTimeline";
import UpdateOrderContactDialog from "../../components/orders-core/UpdateOrderContactDialog";
import OrderDetailsDialog from "../../components/order-details-dialog/OrderDetailsDialog";
import OrderDetailsCODHistory from "../../components/order-details-dialog/OrderDetailsCODHistory";
import {
  BRIEF_TAB,
  COD_HISTORY_TAB,
  COURIER_TAB,
  CUSTOMER_TAB,
  HISTORY_TAB,
  SIGNATURE_TAB,
} from "../../components/order-details-dialog/OrderDetailsDialogTabs";
import OrderDetailsDialogCourier from "../../components/order-details-dialog/OrderDetailsDialogCourier";
import OrderDetailsDialogCustomer from "../../components/order-details-dialog/OrderDetailsDialogCustomer";
import OrderDetailsDialogSignature from "../../components/order-details-dialog/OrderDetailsDialogSignature";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { hasRole } from "../../helpers/RoleUtils";
import OrderDetailsDialogBriefV2 from "../../components/order-details-dialog/v2/OrderDetailsDialogBriefV2";

const DELIVERY_TO = "delivery_signature";
const SENDER_FROM = "sender_signature";

const enhancer = compose(
  renderIf((props) => props.orderId > 0),
  useSheet({
    container: {
      minHeight: "300px",
      maxHeight: "480px",
      overflowY: "auto",
      margin: "0 6px 6px",
    },
    buttonRight: { float: "right" },
  }),
  connect((state) => {
    const userRoles = getUser(state).get("roles") || [];
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      isGenerateTaxInvoiceEnabled:
        marketplaceGenerateTaxInvoiceEnabled(state) &&
        hasRole(userRoles, ROLE_FINANCE_USER),
    };
  }),
  mapPropsStream((propsStream) => {
    const { handler: onShowSubDialog, stream: onShowSubDialogStream } =
      createEventHandler();
    const { handler: onShowChargeEdit, stream: onShowChargeEditStream } =
      createEventHandler();
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const orderResponseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap((props) =>
        getOrder(props.orderId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const getOrderCODHistoryStream = propsStream
      .filter((props) => props.tab === COD_HISTORY_TAB)
      .distinctUntilChanged("orderId")
      .switchMap((props) =>
        getOrderCODHistory(props.orderId)
          .catch((error) => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", mapOrderCODHistoryToActivitiasV2),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const getOrderActivitiesResponseStream = propsStream
      .filter((props) => props.tab === HISTORY_TAB)
      .distinctUntilKeyChanged("orderId")
      .switchMap((props) =>
        getOrderActivities(props.orderId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", mapOrderNotesAndHistoryToActivities),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        orderResponseStream,
        getOrderActivitiesResponseStream,
        onShowSubDialogStream.startWith(null),
        onShowChargeEditStream.startWith(false),
        getOrderCODHistoryStream,
        (
          props,
          orderResponse,
          orderActivities,
          uploadType,
          showChargeEdit,
          CODHistory,
        ) => ({
          ...props,
          uploadType,
          showChargeEdit,
          onShowSubDialog,
          onRequestRefresh,
          onShowChargeEdit,
          activitiesCODHistory: CODHistory.get("payload"),
          order: orderResponse.get("payload"),
          isLoading: orderResponse.get("pending"),
          activities: orderActivities.get("payload"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminFinanceOrderDetailsDialogWrapper.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  activities: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  isGenerateTaxInvoiceEnabled: PropTypes.bool,

  onRequestRefresh: PropTypes.func,

  onShowChargeEdit: PropTypes.func,
  showChargeEdit: PropTypes.bool,
  onShowSubDialog: PropTypes.func,
  uploadType: PropTypes.string,

  orderId: PropTypes.number,
  onRequestClose: PropTypes.func.isRequired,

  onTabChange: PropTypes.func.isRequired,
  tab: PropTypes.oneOf([
    BRIEF_TAB,
    HISTORY_TAB,
    CUSTOMER_TAB,
    COURIER_TAB,
    COD_HISTORY_TAB,
    SIGNATURE_TAB,
  ]),

  onEditClick: PropTypes.func,
  activitiesCODHistory: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

AdminFinanceOrderDetailsDialogWrapper.defaultProps = { tab: BRIEF_TAB };

function AdminFinanceOrderDetailsDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <OrderDetailsDialog
      open={true}
      onRequestClose={props.onRequestClose}
      tab={props.tab}
      order={props.order}
      withoutMap={true}
      withCODHistory={true}
      withTaxInvoice={props.isGenerateTaxInvoiceEnabled}
      isLoading={props.isLoading}
      onTabChange={props.onTabChange}
      onEditClick={props.onEditClick}
      onEditCostsClick={() => props.onShowChargeEdit(true)}
      getDriverUrl={(id) => updateQuery(DRIVER_LIST_URL, { view: id })}
      getSupplierUrl={(id) => updateQuery(SUPPLIER_LIST_URL, { view: id })}
      getCustomerUrl={(id) => CUSTOMER_ITEM_URL + id}
    >
      <AdminOrderChargeEditDialogWrapper
        open={props.showChargeEdit}
        orderId={props.orderId}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.onShowChargeEdit(false);
        }}
      />

      {Boolean(props.tab === BRIEF_TAB) && (
        <OrderDetailsDialogBriefV2 order={props.order} />
      )}

      {Boolean(props.tab === CUSTOMER_TAB) && (
        <OrderDetailsDialogCustomer
          getCustomer={getCustomer}
          customerId={props.order.getIn(["customer", "id"])}
        />
      )}

      {Boolean(
        props.tab === COURIER_TAB && props.order.getIn(["supplier", "id"]),
      ) && (
        <OrderDetailsDialogCourier
          getSupplier={getSupplier}
          supplierId={props.order.getIn(["supplier", "id"])}
        />
      )}

      {Boolean(props.tab === HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <OrderActivitiesTimeline
              showDriver={true}
              showSupplier={true}
              showWarehouse={true}
              driverItemUrl={(id) =>
                updateQuery(DRIVER_LIST_URL, fp.set("view", id))
              }
              driverItemLocationUrl={(id) =>
                updateQuery(
                  DRIVER_LIST_URL,
                  fp.flow(fp.set("view", id), fp.set("view_tab", "map")),
                )
              }
              activities={props.activities}
            />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === COD_HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <OrderDetailsCODHistory activities={props.activitiesCODHistory} />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === SIGNATURE_TAB) && (
        <FlexBox container={8} flex={true} className={classes.container}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                photoId={props.order.get("sender_photo_id")}
                signatureId={props.order.get("sender_signature_id")}
                onUpload={() => props.onShowSubDialog(SENDER_FROM)}
                header={getLocalisationMessage(
                  "proof_of_pickup",
                  "Proof of Pickup",
                )}
              />
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                header={getLocalisationMessage(
                  "proof_of_delivery",
                  "Proof of Delivery",
                )}
                photoId={props.order.get("delivered_to_photo_id")}
                signatureId={props.order.get("delivery_signature_id")}
                onUpload={() => props.onShowSubDialog(DELIVERY_TO)}
              />

              <UpdateOrderContactDialog
                onRequestClose={() => props.onShowSubDialog(null)}
                open={Boolean(
                  props.uploadType === DELIVERY_TO ||
                    props.uploadType === SENDER_FROM,
                )}
                title={
                  props.uploadType === DELIVERY_TO
                    ? getLocalisationMessage(
                        "update_recipient_info",
                        "Update Recipient Info",
                      )
                    : getLocalisationMessage(
                        "update_sender_info",
                        "Update Sender Info",
                      )
                }
                initialValues={
                  props.uploadType === DELIVERY_TO
                    ? {
                        type: DELIVERY_TO,
                        orderId: props.orderId,
                        name: props.order.get("delivered_to"),
                        phone: props.order.get("delivered_to_phone"),
                        photoId: props.order.get("delivered_to_photo_id"),
                        signatureId: props.order.get("delivery_signature_id"),
                      }
                    : {
                        type: SENDER_FROM,
                        orderId: props.orderId,
                        name: props.order.get("sender_from"),
                        phone: props.order.get("sender_phone"),
                        photoId: props.order.get("sender_photo_id"),
                        signatureId: props.order.get("sender_signature_id"),
                      }
                }
                onSubmit={(values) =>
                  uploadOrderSignature(
                    props.orderId,
                    toSnakeCase(values),
                  ).catch(ResponseError.throw)
                }
                onSubmitSuccess={() => {
                  props.onRequestRefresh();
                  props.onShowSubDialog(null);
                }}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </OrderDetailsDialog>
  );
}

export default enhancer(AdminFinanceOrderDetailsDialogWrapper);
