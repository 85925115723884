import React, { useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import FlexBox from "../../components/ui-core/FlexBox";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import SimpleAutocomplete from "../../components/form/SimpleAutocomplete";
import { getJMById, getJMList2 } from "../../api/shared/CountryV2Api";
import { withRouter } from "react-router";
import _ from "lodash";

const enhancer = compose(
  withRouter,
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "500px",
      minHeight: "auto",
    },
  }),
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

PostcodeListFilter.propTypes = {
  classes: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

function PostcodeListFilter(props) {
  const { classes, getLocalisationMessage } = props;
  const [jurisdictionId, setJurisdictionId] = useState(
    _.get(props, "location.query.jurisdiction_id"),
  );
  return (
    <ModalPaper
      open={props.open}
      title={getLocalisationMessage("filter", "Filter")}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox align="center" container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <SimpleAutocomplete
                  initialId={jurisdictionId}
                  getById={getJMById}
                  chooseAPI={getJMList2}
                  onChange={(v) => setJurisdictionId(v.id)}
                  fullWidth={true}
                  label={getLocalisationMessage("jurisdiction")}
                />
              </CardContent>
              <CardActions>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} justify="flex-end">
                    <Button onClick={() => props.onRequestClose()}>
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </Button>
                    <Button
                      onClick={() => {
                        props.onFilterChange(jurisdictionId);
                        props.onRequestClose();
                      }}
                    >
                      {getLocalisationMessage("submit", "Submit")}
                    </Button>
                  </FlexBox>
                </FlexBox>
              </CardActions>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(PostcodeListFilter);
