import React from "react";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { List } from "immutable";
import MultiLineCell from "../data-list/MultiLineCell";
import DataList, { DataListColumn } from "../data-list/DataList";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import DataListFilter from "../../helpers/DataListFilter";
import { formatText } from "../../helpers/FormatUtils";
import useSheet from "react-jss";
import { green, grey, red } from "@material-ui/core/colors";
import fp from "lodash/fp";
import { isEqualData } from "../../helpers/DataUtils";

const UNVERIFIED = "Unverified";
const UNKNOWN = "Unknown";
const VERIFIED = "Verified";

const getClassName = (status, classes) => {
  if (status === UNKNOWN) return classes.unknown;

  return status === UNVERIFIED ? classes.unverified : classes.verified;
};

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    unverified: {
      color: grey[500],
    },
    unknown: {
      color: red[500],
    },
    verified: {
      color: green[500],
    },
  }),
  withState("filter", "onChangeFilter", new DataListFilter()),
  mapPropsStream((propsStream) => {
    const initialListStream = propsStream
      .map(fp.pick(["list", "filter"]))
      .distinctUntilChanged(isEqualData)
      .map((props) => {
        const page = props.filter.getPage();
        const size = props.filter.getSize();
        const total = props.list.size;

        const pageCount = Math.ceil(total / size);

        return pageCount >= page + 1
          ? props.list.slice(page * size, page * size + size)
          : props.list;
      })
      .startWith(List());

    return propsStream.combineLatest(initialListStream, (props, orders) => ({
      ...props,

      orders,
    }));
  }),
);

OrderSortingVerifyBinOrdersTableWrapper.propTypes = {
  classes: PropTypes.object,
  list: PropTypes.instanceOf(List).isRequired,
  orders: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
  onChangeFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
};

function OrderSortingVerifyBinOrdersTableWrapper(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <div>
      <DataList
        filter={props.filter}
        onFilterChange={(filter) => props.onChangeFilter(filter)}
        selectedRowCount={0}
        totalCount={props.list.size}
        rowCount={props.orders.size}
        list={props.orders}
        isLoading={false}
        overscanRowCount={10}
        withHeader={false}
        rowGetter={(options) => props.orders.get(options.index)}
      >
        <DataListColumn
          width={130}
          label={getLocalisationMessage("number")}
          dataKey="number"
          disableSort={true}
          cellRenderer={(row) => (
            <MultiLineCell
              firstLine={
                <div
                  className={getClassName(
                    row.cellData.get("status", UNVERIFIED),
                    classes,
                  )}
                >
                  <strong>{row.cellData.get("number")}</strong>
                </div>
              }
            />
          )}
        />

        <DataListColumn
          width={130}
          label={getLocalisationMessage("status")}
          dataKey="status"
          cellRenderer={(row) => (
            <MultiLineCell
              firstLine={
                <div
                  className={getClassName(
                    row.cellData.get("status", UNVERIFIED),
                    classes,
                  )}
                >
                  {getLocalisationMessage(
                    row.cellData.get("status"),
                    formatText(row.cellData.get("status")),
                  )}
                </div>
              }
            />
          )}
        />

        <DataListColumn
          width={130}
          label={getLocalisationMessage("action")}
          dataKey="action"
          cellRenderer={(row) => (
            <div
              className={getClassName(
                row.cellData.get("status", UNVERIFIED),
                classes,
              )}
            >
              {row.cellData.get("status") === VERIFIED
                ? getLocalisationMessage("ready_for_action", "Ready for Action")
                : getLocalisationMessage(
                    "moving_to_not_found",
                    "Перемещается в Не найденные",
                  )}
            </div>
          )}
        />
      </DataList>
    </div>
  );
}

export default enhancer(OrderSortingVerifyBinOrdersTableWrapper);
