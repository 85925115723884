import fp from "lodash/fp";
import DataListFilter from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toMarketplaceFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    country_id: types.ID,
    size: types.ID,
    page: types.ID,
    search: types.string,
    marketplace_types: queryTypes.stringSeq,
  }),
);
