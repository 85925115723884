import React, { useCallback, useEffect, useState } from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, CardActions, IconButton, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Archive as ContentArchive,
  Close as NavigationClose,
} from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import OrderSortingRuleDialog from "./OrderSortingRuleDialog";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import { renderIf } from "../../helpers/HOCUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import OfflineRuleList from "../rules/OfflineRuleList";
import {
  createRule,
  deleteRuleItem,
  getRuleItem,
  getSortingRules,
  reorderSortingRules,
  syncShipment,
  updateRule,
} from "../../api/v2/admin/AdminOrderSortingApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getValue } from "../../helpers/DataUtils";

const enhancer = compose(
  connect(
    state => ({
      userWarehouseId: getUserWarehouseId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withTheme,
  renderIf("open"),
  useSheet({
    root: {
      maxWidth: "500px",
      minWidth: "500px",
    },
    scrollWrapper: { overflowY: "auto" },
    icons: {
      color: props => props.theme.palette.alternateTextColor,
    },
  }),
  reduxForm({
    form: "OfflineOrderSortingRuleListDialog",
    enableReinitialize: true,
  }),
);

OfflineOrderSortingRuleListDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getCachedSupplier: PropTypes.func.isRequired,
  getSupplierPredictions: PropTypes.func.isRequired,
  getCachedWarehouse: PropTypes.func.isRequired,
  getWarehousePredictions: PropTypes.func.isRequired,
  getCachedPostcode: PropTypes.func.isRequired,
  getPostcodePredictions: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  refreshOrderList: PropTypes.func,
  setIsOrderLoading: PropTypes.func,
  onBeanUnFilter: PropTypes.func,
};

function OfflineOrderSortingRuleListDialog(props) {
  const { classes, getLocalisationMessage } = props;
  const [binRules, setBinRules] = useState([]);
  const [edit, setEdit] = useState(null);
  const [create, setCreate] = useState(null);
  const [bin, setBin] = useState({});
  const [changeRules, setChangeRules] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getSortingRules({ size: 1000 })
      .then(res => {
        if (res) {
          setBinRules(getValue(res, "data.list", []));
          setIsLoading(false);
        }
      })
      .catch(error => {
        props.showErrorMessage(error);
        setIsLoading(false);
      });
  }, [changeRules]);

  useEffect(() => {
    if (fp.isNumber(edit)) {
      getRuleItem(edit).then(res => {
        if (res && res.status === "success") {
          setBin(getValue(res, "data"));
        }
      });
    }
  }, [edit]);

  const handleSubmit = useCallback(changeableRules => {
    const ids = changeableRules.map(item => item.id);
    reorderSortingRules(ids)
      .then(res => {
        if (res && res.status === "success") {
          setBinRules([]);
          setChangeRules(prev => !prev);
          props.onBeanUnFilter();
        }
      })
      .catch(error => {
        props.showErrorMessage(error);
      });
  }, []);

  const onDelete = useCallback(id => {
    deleteRuleItem(id)
      .then(res => {
        if (res && res.status === "success") {
          props.showSuccessMessage(
            getLocalisationMessage("rule_deleted", "Rule Deleted"),
          );

          setBinRules([]);
          props.onBeanUnFilter();
          setChangeRules(prev => !prev);
        }
      })
      .catch(error => {
        props.showErrorMessage(error);
      });
  }, []);

  if (create || fp.isNumber(edit)) {
    return (
      <OrderSortingRuleDialog
        open={true}
        onRequestClose={() => {
          if (create) {
            setCreate(false);
          } else {
            setEdit(null);
          }
        }}
        initialValues={create ? {} : toCamelCase(bin)}
        onSubmit={fp.flow(toSnakeCase, rule => {
          if (create) {
            createRule(rule)
              .then(res => {
                if (res && res.status === "success") {
                  props.showSuccessMessage(
                    getLocalisationMessage("rule_created", "Rule Created"),
                  );

                  setBinRules([]);
                  setChangeRules(prev => !prev);
                  if (create) {
                    setCreate(false);
                  } else if (fp.isNumber(edit)) {
                    setEdit(null);
                  }
                  props.setIsOrderLoading(true);
                  syncShipment()
                    .then(r => {
                      if (r && r.status === "success") {
                        props.refreshOrderList();
                        props.setIsOrderLoading(false);
                      }
                    })
                    .catch(() => {
                      props.refreshOrderList();
                      props.setIsOrderLoading(false);
                    });
                }
              })
              .catch(error => {
                props.showErrorMessage(error);
              });
          } else {
            updateRule(edit, rule)
              .then(res => {
                if (res && res.status === "success") {
                  props.showSuccessMessage(
                    getLocalisationMessage("rule_updated", "Rule updated"),
                  );

                  setBinRules([]);
                  setChangeRules(prev => !prev);
                  if (create) {
                    setCreate(false);
                  } else {
                    setEdit(null);
                  }

                  props.setIsOrderLoading(true);
                  syncShipment()
                    .then(r => {
                      if (r && r.status === "success") {
                        props.refreshOrderList();
                        props.setIsOrderLoading(false);
                      }
                    })
                    .catch(() => {
                      props.refreshOrderList();
                      props.setIsOrderLoading(false);
                    });
                }
              })
              .catch(error => {
                props.showErrorMessage(error);
              });
          }
        })}
        getCachedSupplier={props.getCachedSupplier}
        getSupplierPredictions={props.getSupplierPredictions}
        getCachedWarehouse={props.getCachedWarehouse}
        getWarehousePredictions={props.getWarehousePredictions}
        getCachedPostcode={props.getCachedPostcode}
        getPostcodePredictions={props.getPostcodePredictions}
      />
    );
  }

  return (
    <ModalPaper
      title={getLocalisationMessage(
        "order_sorting_rules",
        "Order Sorting Rules",
      )}
      open={props.open}
      paperClassName={classes.root}
      leftIcon={
        <IconButton
          color="inherit"
          disableTouchRipple={true}
          style={{ cursor: "default" }}
          className={classes.icons}
        >
          <ContentArchive />
        </IconButton>
      }
      rightIcon={
        <IconButton
          color="inherit"
          onClick={props.onRequestClose}
          className={classes.icons}
        >
          <NavigationClose />
        </IconButton>
      }
      onRequestClose={props.onRequestClose}
    >
      <FlexBox flex={true} element={<Paper />} direction="column">
        {!isLoading ? (
          <FlexBox
            flex={true}
            direction="column"
            className={classes.scrollWrapper}
          >
            <OfflineRuleList
              ruleList={binRules}
              onChange={x => {
                handleSubmit(x);
              }}
              onEditClick={x => setEdit(x)}
              onDeleteClick={x => onDelete(x)}
            />
          </FlexBox>
        ) : (
          <FlexBox
            flex={true}
            align="center"
            justify="center"
            style={{ width: 100, height: 100, margin: "50px auto" }}
          >
            <CircularProgress color="secondary" size={80} />
          </FlexBox>
        )}

        {!isLoading && (
          <FlexBox element={<CardActions />} justify="flex-end">
            <Button onClick={() => setCreate(true)}>
              {getLocalisationMessage("add", "Add")}
            </Button>
          </FlexBox>
        )}
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(OfflineOrderSortingRuleListDialog);
