import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getUserId, getUserWarehouse } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { clearHybridOrders } from "../../reducers/HybridOrdersReducer";
import PropTypes from "prop-types";
import { Chip, IconButton, makeStyles } from "@material-ui/core";
import _ from "lodash";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import TableListHeader from "../../wrappers/admin/TableListHeader";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import {
  Add,
  CheckCircle,
  CloudDownloadOutlined,
  DeleteOutline,
  FilterList,
  Refresh,
  ReplyAll,
  Search,
} from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import LocalActFilterDialog from "../../wrappers/admin/LocalActFilterDialog";
import AdminReplyActDialog from "../../wrappers/admin/AdminReplyActDialog";
import { ACT_MANAGEMENT_LOCAL_CREATE_URL } from "../../constants/AdminPathConstants";
import {
  ACT_PDF_URL,
  completeActStatus,
  deleteAct,
  getActList,
  getAsyncActItem,
} from "../../api/admin/AdminActManagementApi";
import fp from "lodash/fp";
import { formatDateTimeToUrl, formatText } from "../../helpers/FormatUtils";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import { getPDF } from "../../helpers/FormUtils";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { getPushLog } from "../../reducers/PushNotificationReducer";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { endOfDay, startOfDay } from "date-fns";
import MUITableActList from "../../components/act-management/MUITableActList";
import { COLUMN } from "../../components/orders-core/MUITableReport";
import {
  colorActStatus,
  COMPLETED,
  formatActStatus,
} from "../../constants/ActStatusTypes";
import { blue, green, orange, red } from "@material-ui/core/colors";

const DEFAULT_LIST = { list: [], total: 0 };

const enhancer = compose(
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      userId: getUserId(state),
      isRefresh: getPushLog(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, clearHybridOrders },
  ),
  getContext({
    setLocation: PropTypes.func,
  }),
);

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  tableHeader: {
    flex: "1 1 auto",
    gap: 16,
    padding: 8,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  chipTextField: {
    padding: 0,
    "& MuiChip-root": {
      margin: 0,
    },
  },
  statusError: {
    color: "#fff",
    backgroundColor: red[500],
  },
  statusSuccess: {
    color: "#fff",
    backgroundColor: green[500],
  },
  statusWarning: {
    color: "#fff",
    backgroundColor: orange[500],
  },
  statusInfo: {
    color: "#fff",
    backgroundColor: blue[500],
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const AdminActManagementContainer = props => {
  const { getLocalisationMessage, setLocation } = props;

  const classes = useStyles();

  const [children, setChildren] = useState([]);
  const [list, setList] = useState(DEFAULT_LIST);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [replyId, setReplyId] = useState(0);
  const [completeItem, setCompleteItem] = useState({});
  const [actPDFId, setPDFId] = useState(0);
  // const [childReplies, setChildReplies] = useState([]);

  const [filter, setFilter] = useState({
    refresh: false,
    search: null,
    from_date_time: null,
    to_date_time: null,
    from_warehouse_id: null,
    to_warehouse_id: null,
    page: 0,
    size: 20,
  });
  const [actPDF, setActPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ACT_PDF_URL,
  });

  const fetchChildren = async parentId => {
    await getAsyncActItem(parentId)
      .then(res => {
        const replies = _.get(res, "data.replies", []);

        const tempChildren = { ...children };

        tempChildren[parentId] = replies;

        setChildren(tempChildren);
      })
      .catch(error => {
        showErrorMessage(error);
      });
  };

  const handleChangePage = (event, page) => setFilter({ ...filter, page });

  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  const onDeleteAct = item => {
    deleteAct(item.id)
      .then(() => {
        setDeleteItem({});
        props.showSuccessMessage(
          props.getLocalisationMessage(
            "successfully_removed",
            "Successfully Removed",
          ),
        );
        refreshOrderList();

        if (item.parent_id) {
          fetchChildren(item.parent_id);
        }
      })
      .catch(error => {
        props.showErrorMessage(error);
        setDeleteItem({});
      });
  };

  const onCompleteAct = item => {
    completeActStatus(item.id)
      .then(() => {
        setCompleteItem({});
        props.showSuccessMessage(
          props.getLocalisationMessage(
            "successfully_completed",
            "Successfully Completed",
          ),
        );
        refreshOrderList();
        if (item.parent_id) {
          fetchChildren(item.parent_id);
        }
      })
      .catch(error => {
        props.showErrorMessage(error);
        setCompleteItem({});
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const request = _.omitBy(filter, v => _.isNil(v) || v === undefined);

    getActList(request)
      .then(res => {
        setIsLoading(false);
        setList(fp.flow(fp.defaultTo(DEFAULT_LIST), fp.get("data"))(res));
      })
      .catch(error => {
        props.showErrorMessage(error);
        setIsLoading(false);
      });
  }, [filter, props.isRefresh]);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("act_management", "Act Management")}
      className={classes.root}
    >
      <LocalActFilterDialog
        open={isOpenFilter}
        filter={filter}
        onFilterChange={values => {
          setFilter(prev => ({
            ...prev,
            from_warehouse_id: fp.get("from_warehouse_id.id", values),
            to_warehouse_id: fp.get("to_warehouse_id.id", values),
            from_date_time: formatDateTimeToUrl(
              startOfDay(fp.get("from_date_time", values)),
            ),
            to_date_time: formatDateTimeToUrl(
              endOfDay(fp.get("to_date_time", values)),
            ),
          }));
          setIsOpenFilter(false);
        }}
        onRequestClose={() => {
          setIsOpenFilter(false);
        }}
      />

      <AdminReplyActDialog
        open={replyId > 0}
        actId={replyId}
        onSubmitSuccess={async parentId => {
          await refreshOrderList();
          await fetchChildren(parentId);
          setReplyId(0);
        }}
        onRequestClose={() => {
          setReplyId(0);
        }}
      />

      <ConfirmDialog
        open={deleteItem.id > 0}
        onRequestClose={() => {
          setDeleteItem({});
        }}
        onConfirm={() => {
          onDeleteAct(deleteItem);
          setDeleteItem({});
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_delete_the_act",
          "Are you sure you want delete the act?",
        )}
      </ConfirmDialog>

      <ConfirmDialog
        open={completeItem.id > 0}
        onRequestClose={() => {
          setCompleteItem({});
        }}
        onConfirm={() => {
          onCompleteAct(completeItem);
          setCompleteItem({});
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_complete_the_act",
          "Are you sure you want complete the act?",
        )}
      </ConfirmDialog>

      <FlexBox
        style={{ backgroundColor: "white", height: "100%" }}
        direction="column"
      >
        <TableListHeader
          buttons={
            <FlexBox
              align={ALIGN_CENTER}
              justify={JUSTIFY_END}
              className={classes.tableHeader}
            >
              <div className={classes.search}>
                <Search
                  fontSize="large"
                  style={{
                    color: "rgba(0, 0, 0, 0.23)",
                    width: 24,
                    height: 24,
                    left: 16,
                    top: 7,
                    position: "absolute",
                  }}
                />
                <ChipTextField
                  style={{
                    padding: "1px 3rem",
                  }}
                  className={classes.chipTextField}
                  fullWidth={true}
                  addOnBlur={false}
                  clearOnBlur={false}
                  value={parseString(filter.search)}
                  disableUnderline={true}
                  placeholder={getLocalisationMessage("type_here_to_search")}
                  onChange={v =>
                    setFilter({ ...filter, search: stringifyArray(v) })
                  }
                />
              </div>

              <CustomButton
                size="small"
                startIcon={<Add />}
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px" }}
                onClick={() => {
                  setLocation(ACT_MANAGEMENT_LOCAL_CREATE_URL);
                }}
              >
                {getLocalisationMessage("create_local_act", "Create Local Act")}
              </CustomButton>

              <FlexBox>
                <IconButton
                  className={isLoading && classes.refresh}
                  tooltip={getLocalisationMessage("refresh", "Refresh")}
                  onClick={() => refreshOrderList()}
                >
                  <Refresh />
                </IconButton>
              </FlexBox>
              <FlexBox>
                <IconButton onClick={() => setIsOpenFilter(true)}>
                  <FilterList />
                </IconButton>
              </FlexBox>
            </FlexBox>
          }
        />

        <MUITableActList
          childItems={children}
          setChildren={setChildren}
          filter={filter}
          activeActId={actPDFId}
          isPrinting={isLoading}
          isLoading={isLoading}
          list={fp.get("list", list)}
          total={fp.get("total", list)}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          fetchChildren={fetchChildren}
          columns={[
            {
              type: COLUMN,
              name: "id",
              label: getLocalisationMessage("ID"),
              width: 230,
            },
            {
              type: COLUMN,
              name: "created_date",
              label: getLocalisationMessage("created_date", "Created Date"),
              width: 210,
              render: ({ row }) => formatDateTimeToUrl(_.get(row, `date`, "")),
            },
            {
              type: COLUMN,
              name: "from_warehouse",
              width: 400,
              label: getLocalisationMessage("from_warehouse", "From Warehouse"),
              render: ({ row }) => _.get(row, `from_warehouse.name`, ""),
            },
            {
              type: COLUMN,
              name: "to_warehouse",
              width: 400,
              label: getLocalisationMessage("to_warehouse", "To Warehouse"),
              render: ({ row }) => _.get(row, `to_warehouse.name`, ""),
            },
            {
              type: COLUMN,
              name: "status",
              width: 175,
              label: getLocalisationMessage("status", "Status"),
              // eslint-disable-next-line  react/prop-types
              render: ({ row }) => {
                const status = _.get(row, `status`, "");

                return (
                  <Chip
                    label={formatActStatus(status, getLocalisationMessage)}
                    className={classes[colorActStatus(status)]}
                  />
                );
              },
            },
            {
              type: COLUMN,
              name: "language",
              width: 125,
              label: getLocalisationMessage("language", "Language"),
              // eslint-disable-next-line  react/prop-types
              render: ({ row }) => {
                const type = _.get(row, `alphabet_type`);

                if (type) {
                  return getLocalisationMessage(type, formatText(type));
                }

                return "";
              },
            },
            {
              type: COLUMN,
              name: "type",
              width: 125,
              label: getLocalisationMessage("type", "Type"),
              // eslint-disable-next-line  react/prop-types
              render: ({ row }) => {
                const type = _.get(row, `type`);

                if (type) {
                  return getLocalisationMessage(type, formatText(type));
                }

                return "";
              },
            },
            {
              type: COLUMN,
              name: "action",
              width: 330,
              label: getLocalisationMessage("action", "Action"),
              // eslint-disable-next-line  react/prop-types
              render: ({ row, parentId, parent }) => {
                const actId = _.get(row, "id");
                const parentStatus = _.get(parent, "status");

                return (
                  <div style={{ textAlign: "right" }}>
                    {_.get(row, "status") !== COMPLETED &&
                      parentStatus !== COMPLETED && (
                        <IconButton
                          onClick={() => {
                            setReplyId(actId);
                          }}
                        >
                          <ReplyAll />
                        </IconButton>
                      )}

                    <IconButton
                      className={
                        _.get(actPDF, IS_PRINTING, false) &&
                        actId === actPDFId &&
                        classes.refresh
                      }
                      onClick={() => {
                        setPDFId(actId);
                        getPDF(
                          actPDF,
                          setActPDF,
                          IS_PRINTING,
                          _.get(row, "id"),
                          props.showErrorMessage,
                        );
                      }}
                    >
                      {_.get(actPDF, IS_PRINTING, false) &&
                      actId === actPDFId ? (
                        <Refresh />
                      ) : (
                        <CloudDownloadOutlined />
                      )}
                    </IconButton>

                    {_.get(row, "status") !== COMPLETED &&
                      parentStatus !== COMPLETED && (
                        <IconButton
                          onClick={() => {
                            setDeleteItem(row);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}

                    {!parentId &&
                      _.get(row, "status") !== COMPLETED &&
                      parentStatus !== COMPLETED && (
                        <IconButton
                          onClick={() => {
                            setCompleteItem(row);
                          }}
                        >
                          <CheckCircle />
                        </IconButton>
                      )}
                  </div>
                );
              },
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
};

AdminActManagementContainer.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  userWarehouse: PropTypes.object,
  userId: PropTypes.number,
  isRefresh: PropTypes.bool,
};

export default enhancer(AdminActManagementContainer);
