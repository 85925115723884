import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ScoreCourierTypes from "../../constants/ScoreCourierTypes";
import {
  getScoreSettings,
  saveScoreSettings,
} from "../../api/admin/AdminSettingsApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import ScoreSettingsForm from "../../components/settings-core/ScoreSettingsForm";
import ScoreCourierTypeForm from "../../components/settings-core/ScoreCourierTypeForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  useSheet({
    root: { alignItems: "center" },
    container: {
      maxWidth: "1300px",
      minWidth: "1000px",
      "& > div": {
        display: "flex",
        flex: "1 1 0%",
      },
    },
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), DataListFilter.create))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const scoreSettingsListResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getScoreSettings(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toArray)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            scoreSettingsListResponseStream,
            (props, scoreSettingsListResponse) => ({
              ...props,
              onRequestRefresh,
              list: scoreSettingsListResponse.get("payload"),
              isLoading: scoreSettingsListResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminScoreSettingsContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  list: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  ratingTypes: PropTypes.instanceOf(List),
  isLoadingRatingTypes: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminScoreSettingsContainer(props) {
  const { classes, location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "score_weight_settings",
        "Score Weight Settings",
      )}
    >
      <PageLoading isLoading={props.isLoading} />

      <Redirect
        when={!ScoreCourierTypes.has(location.query.score_courier_type)}
        to={updateQuery(
          location,
          fp.set("score_courier_type", ScoreCourierTypes.first()),
        )}
      />

      <FlexBox
        container={8}
        flex={true}
        direction="column"
        className={classes.root}
      >
        <FlexBox element={<Card className={classes.container} />} gutter={8}>
          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox>
                <ScoreCourierTypeForm
                  initialValues={{
                    courierType: location.query.score_courier_type,
                  }}
                  onSubmit={({ courierType }) =>
                    props.setLocationQuery(
                      fp.set("score_courier_type", courierType),
                    )
                  }
                />
              </FlexBox>

              <FlexBox flex={true}>
                <ScoreSettingsForm
                  initialValues={{ scores: toCamelCase(props.list) }}
                  onSubmit={({ scores }) =>
                    saveScoreSettings(toSnakeCase(scores)).catch(
                      ResponseError.throw,
                    )
                  }
                  onSubmitSuccess={() => {
                    props.showSuccessMessage(
                      getLocalisationMessage(
                        "successfully_saved",
                        "Successfully Saved",
                      ),
                    );
                    props.onRequestRefresh();
                  }}
                  onSubmitFail={props.showErrorMessage}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminScoreSettingsContainer);
