import { Observable } from "rxjs";
import React from "react";
import { toLower, isPlainObject } from "lodash";
import Immutable, { fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import {
  ListSubheader,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Motion } from "react-motion";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import spring from "react-motion/lib/spring";
import MapAreasSearchForm from "./MapAreasSearchForm";
import NewMapAreasItemForm from "./NewMapAreasItemForm";
import FlexBox from "../ui-core/FlexBox";
import Checkbox from "../deprecated/Checkbox";
import SortableList, { SortableListItem } from "../sortable-list/SortableList";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase } from "../../helpers/CaseMapper";
import DataListFilter from "../../helpers/DataListFilter";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { getAreaAliases } from "../../api/shared/AreasApi";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  useSheet({
    root: {
      top: 0,
      left: 0,
      bottom: 0,
      display: "flex",
      position: "absolute",
      pointerEvents: "none",
      flexDirection: "column",
      overflowY: "hidden",
    },
    rootLtr: {
      left: 0,
      extend: "root",
    },
    rootRtl: {
      right: 0,
      extend: "root",
    },
    container: {
      pointerEvents: "all",
      backgroundColor: "white",
      overflow: "hidden",
    },
    addButton: { marginRight: "12px" },
    CircularProgress: {
      boxShadow: "none",
      position: "relative",
    },
    filterContainer: { margin: "12px" },
    listContainer: { overflowY: "auto", overflowX: "hidden" },
  }),
  withState("listFilter", "onChangeListFilter", ""),
  mapPropsStream(propsStream => {
    const {
      handler: onChangeFilter,
      stream: onChangeFilterStream,
    } = createEventHandler();

    const listStream = propsStream
      .distinctUntilKeyChanged("areaList")
      .merge(onChangeFilterStream)
      .withLatestFrom(propsStream)
      .map(([filter, props]) =>
        isPlainObject(filter)
          ? props.areaList
          : props.areaList.filter(
              x => toLower(x.get("name")).indexOf(toLower(filter)) >= 0,
            ),
      )
      .startWith(null);

    const aliasStream = propsStream
      .map(fp.pick(["editItem"]))
      .filter(props => props.editItem > 0)
      .distinctUntilChanged(isEqualData)
      .switchMap(props =>
        getAreaAliases([props.editItem]).catch(error =>
          Observable.of({ error }),
        ),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              payload: response.getIn(["payload", "data"], Immutable.List()),
            }),
        ),
      )
      .startWith(Immutable.List());

    return propsStream
      .combineLatest(listStream, aliasStream, (props, list, aliases) => ({
        ...props,
        list,
        aliases: aliases.get("payload"),
        onChangeFilter,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "isActiveTask",
      "filter",
      "list",
      "aliases",
      "areaList",
      "editItem",
      "listFilter",
      "createItem",
      "countryCode",
      "editPolygons",
      "selectedItems",
      "areaListLoading",
    ]),
  ),
);

NewMapAreasList.propTypes = {
  isActiveTask: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),

  onAddItem: PropTypes.func,
  onSwitchItem: PropTypes.func,

  onEditArea: PropTypes.func,
  onDeleteArea: PropTypes.func,
  onCreateArea: PropTypes.func,

  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,

  onHoverArea: PropTypes.func,

  classes: PropTypes.object,

  countryCode: PropTypes.string,

  onSetCoordinates: PropTypes.func,

  editItem: PropTypes.number,
  createItem: PropTypes.bool,
  isAdminArea: PropTypes.bool,
  selectedItems: PropTypes.instanceOf(Immutable.List),
  aliases: PropTypes.instanceOf(Immutable.List),

  areaListLoading: PropTypes.bool,
  list: PropTypes.instanceOf(Immutable.List),
  areaList: PropTypes.instanceOf(Immutable.List),
  editPolygons: PropTypes.instanceOf(Immutable.List),

  onSortList: PropTypes.func,
  onCloseForm: PropTypes.func,

  onSelectAllClick: PropTypes.func,

  allSelected: PropTypes.bool,
  isRTL: PropTypes.bool,
  polygonEdited: PropTypes.bool,

  listFilter: PropTypes.string,
  onChangeFilter: PropTypes.func,
  onChangeListFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

NewMapAreasList.defaultProps = {
  isAdminArea: false,
};

function NewMapAreasList({ classes, ...props }) {
  const { isActiveTask, getLocalisationMessage, aliases } = props;
  const items = [];

  if (props.createItem) {
    items.push(
      <SortableListItem
        key={0}
        open={true}
        primaryText={getLocalisationMessage("new_area", "New Area")}
        rightIcon={<Checkbox value={true} disabled={true} onChange={fp.noop} />}
        nestedItems={[
          <NewMapAreasItemForm
            key={0}
            areaList={props.areaList}
            isAdminArea={props.isAdminArea}
            editItem={props.editItem}
            onSave={props.onCreateArea}
            createItem={props.createItem}
            onDismiss={props.onCloseForm}
            onSubmitFail={props.onSubmitFail}
            editPolygons={props.editPolygons}
            onSubmitSuccess={props.onSubmitSuccess}
            initialValues={{
              category: "CITY",
              country: {
                id: props.filter.getIntegerValue("countryId"),
              },
            }}
          />,
        ]}
      />,
    );
  } else {
    items.push(
      props.list
        .map((item, index) => {
          const itemId = item.get("id");
          const openItem = props.editItem === itemId;

          if (Boolean(props.editItem) && !openItem) {
            return null;
          }

          if (item.get("category") === "COUNTRY" && !props.isAdminArea) {
            return null;
          }

          return (
            <SortableListItem
              key={index}
              open={openItem}
              primary={item.get("name")}
              onMouseLeave={() => props.onHoverArea(null)}
              onMouseEnter={() => props.onHoverArea(itemId)}
              rightIcon={
                <Checkbox
                  disabled={Boolean(props.editItem)}
                  value={props.selectedItems.indexOf(itemId) >= 0}
                  onClick={() => props.onSwitchItem(itemId)}
                />
              }
              nestedItems={
                props.editItem
                  ? [
                      <NewMapAreasItemForm
                        key={0}
                        isAdminArea={props.isAdminArea}
                        areaList={props.areaList}
                        editItem={props.editItem}
                        onSave={props.onEditArea}
                        createItem={props.createItem}
                        onDelete={props.onDeleteArea}
                        onDismiss={props.onCloseForm}
                        onSubmitFail={props.onSubmitFail}
                        editPolygons={props.editPolygons}
                        // onSubmitSuccess={props.onSubmitSuccess}
                        initialValues={{
                          ...toCamelCase(item.toJS()),
                          aliases: aliases.toJS(),
                          editPolygons: props.editPolygons,
                          polygonEdited: props.polygonEdited,
                          country: {
                            id: item.get("country_id"),
                          },
                        }}
                      />,
                    ]
                  : undefined
              }
            />
          );
        })
        .filter(Boolean)
        .toArray(),
    );
  }

  return (
    <FlexBox
      className={props.isRTL ? classes.rootRtl : classes.rootLtr}
      style={{ zIndex: 999 }}
      container={8}
      flex={true}
    >
      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} direction="column">
          <Motion
            defaultStyle={{ width: 400 }}
            style={{ width: spring(isActiveTask ? 0 : 400) }}
          >
            {({ width }) => (
              <FlexBox
                flex={true}
                direction="column"
                className={classes.container}
                style={{
                  width: `${width}px`,
                }}
              >
                <FlexBox direction="column" flex={true}>
                  <FlexBox flex="none" className={classes.filterContainer}>
                    <FlexBox gutter={16} flex={true}>
                      <FlexBox flex={true}>
                        <TextField
                          name="filter"
                          fullWidth={true}
                          value={props.listFilter}
                          autoComplete="off"
                          placeholder={getLocalisationMessage(
                            "filter_by_name",
                            "Filter by name",
                          )}
                          onChange={({ target }) => {
                            props.onChangeFilter(target.value);
                            props.onChangeListFilter(target.value);
                          }}
                        />
                      </FlexBox>
                      {props.list.size !== props.areaList.size && (
                        <FlexBox align="center" justify="center">
                          <IconButton
                            onClick={() => {
                              props.onChangeFilter("");
                              props.onChangeListFilter("");
                            }}
                          >
                            <NavigationCancel />
                          </IconButton>
                        </FlexBox>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <FlexBox
                    align="center"
                    flex="none"
                    element={<ListSubheader />}
                  >
                    <FlexBox flex={true}>
                      {getLocalisationMessage("areas", "Areas")}
                    </FlexBox>
                    <FlexBox
                      element={
                        <Button
                          onClick={props.onAddItem}
                          disabled={
                            Boolean(props.editItem) || props.areaListLoading
                          }
                        >
                          {" "}
                          {getLocalisationMessage("add", "Add")}{" "}
                        </Button>
                      }
                      className={classes.addButton}
                    />
                    <FlexBox>
                      <Checkbox
                        disabled={
                          Boolean(props.editItem) || props.areaListLoading
                        }
                        onClick={props.onSelectAllClick}
                        value={props.allSelected}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox
                    style={{ height: "400px" }}
                    flex={true}
                    direction="column"
                  >
                    {props.areaListLoading && (
                      <FlexBox align="center" justify="center">
                        <CircularProgress
                          top={0}
                          left={0}
                          variant="indeterminate"
                          className={classes.CircularProgress}
                        />
                      </FlexBox>
                    )}

                    {!props.areaListLoading && (
                      <div className={classes.listContainer}>
                        <SortableList
                          onSortEnd={({ oldIndex, newIndex }) =>
                            oldIndex !== newIndex
                              ? props.onSortList(
                                  props.list
                                    .splice(oldIndex, 1)
                                    .splice(
                                      newIndex,
                                      0,
                                      props.list.get(oldIndex),
                                    )
                                    .map((x, idx) => ({
                                      id: x.get("id"),
                                      z_index: idx,
                                    }))
                                    .toJS(),
                                )
                              : null
                          }
                        >
                          {items}
                        </SortableList>
                      </div>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </Motion>
        </FlexBox>

        <FlexBox direction="column">
          <MapAreasSearchForm
            countryCode={props.countryCode}
            onSubmit={({ location }) =>
              props.onSetCoordinates({ lat: location.lat, lng: location.lng })
            }
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NewMapAreasList);
