import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import { withRouter } from "react-router";
import SubscriptionCheckboxLabel from "./SubscriptionCheckboxLabel";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
  useSheet({
    root: {
      margin: "10px 15px 15px",
      padding: 15,
      backgroundColor: "#F7F7F8",
      boxShadow: "0 3px 9px rgba(0,0,0,0.16)",
      border: "2px solid #10132D",
      borderRadius: 5,
    },
    title: {
      fontSize: 14,
      fontWeight: "normal",
    },
  }),
  withRouter,
);
SubscriptionAddOnPlanGroupWrap.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  plans: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  groupField: PropTypes.string.isRequired,
  setLocationQuery: PropTypes.func,
};

function SubscriptionAddOnPlanGroupWrap(props) {
  const { classes, groupField, location } = props;
  const getSelectedFields = fp.flow(
    fp.get(["query", groupField]),
    fp.castArray,
    fp.compact,
  );
  const selectedFields = getSelectedFields(location);

  return (
    <FlexBox className={classes.root} direction="row">
      <SubscriptionCheckboxLabel
        title={props.title}
        description={props.description}
        price="$500"
      />
      <FlexBox wrap={true} direction="row" justify="space-between">
        {props.plans.map(item => (
          <div key={item.code} style={{ marginBottom: 15, width: "25%" }}>
            <Checkbox
              checked={fp.includes(item.code, selectedFields)}
              onCheck={() =>
                props.setLocationQuery(
                  fp.set(groupField, fp.xor(selectedFields, [item.code])),
                )
              }
              label={
                <SubscriptionCheckboxLabel
                  title={item.title}
                  description="Track your parcel and get Real-time updates"
                  price="$500"
                />
              }
            />
          </div>
        ))}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SubscriptionAddOnPlanGroupWrap);
