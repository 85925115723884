import React from "react";
import { Map } from "immutable";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import { getOrder, updateOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getPaymentMethods,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import { getTimeSlots } from "../../api/customer/CustomerTimeSlotApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderForm from "../../components/orders-core/OrderForm";

const enhancer = compose(
  renderIf(props => props.orderId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const requestRefresh = createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        getOrder(props.orderId)
          .let(mapObjectResponseStream)
          .repeatWhen(() => requestRefresh.stream),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        item: response.get("payload"),
        isLoading: response.get("pending"),
        onRequestRefresh: requestRefresh.handler,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminOrderEditDialogWrapper.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,

  orderId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderEditDialogWrapper(props) {
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={
        props.item.get("id") > 0 &&
        `${props.getLocalisationMessage(
          "edit_order",
          "Edit Order",
        )} #${props.item.get("order_number")}`
      }
    >
      <FlexBox direction="column">
        <OrderForm
          orderStatusCodes={OrderStatusCodes}
          order={props.item}
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            updateOrder(props.item.get("id"), values).catch(ResponseError.throw)
          }
          onSubmitFail={error => {
            if (props.onSubmitFail) {
              props.onSubmitFail(error);
            } else {
              props.showErrorMessage(error);
            }
          }}
          onSubmitSuccess={() => {
            props.onRequestRefresh();

            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_order_edited",
                "Successfully Order Edited",
              ),
            );
          }}
          getTimeSlots={getTimeSlots}
          getPaymentMethods={getPaymentMethods}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedSupplier={getCachedSupplier}
          getCustomerPredictions={getCustomerPredictions}
          getCachedCustomer={getCachedCustomer}
          getSupplierPredictions={getSupplierPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderEditDialogWrapper);
