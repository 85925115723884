import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FlexBox from "../ui-core/FlexBox";
import DriversRadarNewDateForm from "./DriversRadarNewDateForm";
import DriversRadarNewTimeSlider from "./DriversRadarNewTimeSlider";

const enhancer = compose(
  useSheet({
    root: {
      width: "36px",
      height: "36px",
      position: "relative",
    },
    activeOrders: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      marginTop: "1px",
      zIndex: 3,
    },
    activeProgress: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
    },
    circle: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1,
    },
  }),
);

DriverRadarTimeLine.propTypes = {
  onChangeDate: PropTypes.func,
  onChangeTime: PropTypes.func,
  initialValues: PropTypes.object,
  locations: PropTypes.object,
};

function DriverRadarTimeLine(props) {
  return (
    <FlexBox style={{ background: "white" }} flex={true}>
      <FlexBox>
        <DriversRadarNewDateForm
          initialValues={props.initialValues}
          onChange={props.onChangeDate}
        />
      </FlexBox>

      <FlexBox flex={true}>
        <DriversRadarNewTimeSlider
          locations={props.locations}
          onChange={props.onChangeTime}
        />
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverRadarTimeLine);
