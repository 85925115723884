import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
  getSupplierFinanceSettings,
  saveSupplierFinanceSettings,
} from "../../api/admin/AdminSupplierApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import SupplierFinanceSettingsForm from "../../components/suppliers-core/SupplierFinanceSettingsForm";

const enhancer = compose(
  useSheet({
    container: { width: "820px" },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const supplierSettingsListRequest = propsStream
      .distinctUntilKeyChanged("location")
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getSupplierFinanceSettings()
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toArray)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        supplierSettingsListRequest,
        (props, supplierSettingsList) => ({
          ...props,
          onRequestRefresh,
          isLoading: supplierSettingsList.get("pending"),
          supplierSettingsList: supplierSettingsList.get("payload"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierFinanceSettingsContainer.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  supplierSettingsList: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSupplierFinanceSettingsContainer(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "supplier_finance_settings",
        "Supplier Finance Settings",
      )}
    >
      <PageLoading isLoading={props.isLoading} />

      <FlexBox container={8} align="center" flex={true} direction="column">
        <FlexBox element={<Card />} gutter={8}>
          <FlexBox
            flex={true}
            element={<CardContent className={classes.container} />}
          >
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <SupplierFinanceSettingsForm
                  getCachedSupplier={getCachedSupplier}
                  getSupplierPredictions={getSupplierPredictions}
                  location={props.location}
                  onSubmit={({ financeSettings }) =>
                    saveSupplierFinanceSettings(
                      toSnakeCase(financeSettings),
                    ).catch(ResponseError.throw)
                  }
                  onSubmitSuccess={() => {
                    props.showSuccessMessage(
                      getLocalisationMessage(
                        "successfully_updated",
                        "Successfully Updated",
                      ),
                    );
                    props.onRequestRefresh();
                  }}
                  onSubmitFail={props.showErrorMessage}
                  initialValues={{
                    financeSettings: toCamelCase(props.supplierSettingsList),
                  }}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminSupplierFinanceSettingsContainer);
