import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";
import sprintf from "sprintf";

const SAVE_SIMPLE_DRAFTS_ORDER_URL = `${API_ROOT_URL}/admin/simple_draft_orders`;
const GET_BATCH_SIMPLE_ORDER_URL = `${API_V2_ROOT_URL}/admin/orders/progress_order_validation/%s`;
const CREATE_BATCH_SIMPLE_ORDER_URL = `${API_ROOT_URL}/admin/simple_orders`;
const SAMPLE_ORDER_LIST = `${API_ROOT_URL}/admin/simple_orders_list`;
const UPDATE_SAMPLE_DRAFT_ORDERS = `${API_ROOT_URL}/admin/simple_draft_orders/%s`;
const SINGLE_SIMPLE_ORDER_CRETE_URL = `${API_V2_ROOT_URL}/admin/simple/order`;

export const createSimpleOrder = body =>
  api.post(SAVE_SIMPLE_DRAFTS_ORDER_URL, { body });

export const createSingleSimpleOrder = body =>
  api.post(SINGLE_SIMPLE_ORDER_CRETE_URL, { body });

export const updateSimpleOrder = (batchId, body) =>
  api.put(sprintf(UPDATE_SAMPLE_DRAFT_ORDERS, batchId), { body });

export const getByBatchIdSimpleOrder = (batchId, orderIds) =>
  api.get(sprintf(GET_BATCH_SIMPLE_ORDER_URL, batchId), {
    params: {
      id: batchId,
      page: 0,
      size: 1000,
      item_ids: orderIds,
    },
  });

export const getProgressValidation = (batchId, page, size) =>
  api.get(sprintf(GET_BATCH_SIMPLE_ORDER_URL, batchId), {
    params: {
      id: batchId,
      page,
      size,
    },
  });

export const createByBatchIdSimpleOrder = batchId =>
  api.post(CREATE_BATCH_SIMPLE_ORDER_URL, {
    params: {
      batch_id: batchId,
    },
  });

export const getSampleOrderList = (page, size) =>
  api.get(SAMPLE_ORDER_LIST, { params: { page, size } });
