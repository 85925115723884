import React from "react";
import { fromJS, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DRIVER_LIST_URL } from "../../constants/AdminPathConstants";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const valueSelector = formValueSelector("WarehouseItemDriversForm");

const enhancer = compose(
  reduxForm({
    form: "WarehouseItemDriversForm",
    enableReinitialize: true,
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  useSheet({
    container: { flex: "1 1 0%", padding: "6px", display: "flex" },
  }),
  connect(state => ({
    values: valueSelector(state, "driver", "drivers"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const {
      handler: onAddDriver,
      stream: onAddDriverStream,
    } = createEventHandler();
    const {
      handler: onRemoveDriver,
      stream: onRemoveDriverStream,
    } = createEventHandler();

    const addDriverStream = onAddDriverStream
      .withLatestFrom(propsStream)
      .map(fp.flow(([, props]) => props, fp.pick(["change", "values"])))
      .filter(props => isValidObjectId(props.values.driver))
      .do(({ values, change }) => {
        change(
          "drivers",
          values.drivers.set(values.driver.id, fromJS(values.driver)),
        );
        change("driver", null);
      })
      .startWith(Map());

    const removeDriverStream = onRemoveDriverStream
      .withLatestFrom(propsStream)
      .map(([index, props]) => ({
        index,
        change: props.change,
        values: props.values,
      }))
      .do(props =>
        props.change("drivers", props.values.drivers.delete(props.index)),
      )
      .startWith(null);

    return propsStream
      .combineLatest(addDriverStream, removeDriverStream, props => ({
        ...props,
        onAddDriver,
        onRemoveDriver,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

WarehouseItemDriversForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  values: PropTypes.object,
  onAddDriver: PropTypes.func,
  reset: PropTypes.func,
  onRemoveDriver: PropTypes.func,
  getCachedDriver: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function WarehouseItemDriversForm(props) {
  const { values, classes, getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <Paper className={classes.container}>
      <FlexBox container={8} flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          {props.getCachedDriver && (
            <FlexBox>
              <FlexBox gutter={8}>
                <FlexBox>
                  <form autoComplete="off">
                    <FormDriverAutoComplete
                      name="driver"
                      label={getLocalisationMessage("driver", "Driver")}
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to Search",
                      )}
                      style={{ width: 200 }}
                    />
                  </form>
                </FlexBox>

                <FlexBox align="center">
                  <Button onClick={props.onAddDriver} disabled={!values.driver}>
                    {" "}
                    {getLocalisationMessage("add_driver", "Add Driver")}{" "}
                  </Button>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          )}

          <FlexBox flex={true}>
            {values.drivers && (
              <DataList
                overscanRowCount={4}
                rowCount={values.drivers.toList().size}
                isLoading={props.submitting}
                totalCount={values.drivers.toList().size}
                rowGetter={row => values.drivers.toList().get(row.index)}
              >
                <DataListColumn
                  width={120}
                  label={getLocalisationMessage("id", "ID")}
                  dataKey="id"
                  disableSort={true}
                  justifyContent="center"
                  cellRenderer={row => (
                    <Link
                      target="_blank"
                      to={updateQuery(
                        DRIVER_LIST_URL,
                        fp.set("view", row.cellData.get("id")),
                      )}
                    >
                      {row.cellData.get("id")}
                    </Link>
                  )}
                />

                <DataListColumn
                  width={120}
                  label={getLocalisationMessage("name", "Name")}
                  dataKey="name"
                  disableSort={true}
                  cellRenderer={row => row.cellData.get("name") || NA}
                />

                <DataListColumn
                  width={120}
                  label={getLocalisationMessage("description", "Description")}
                  dataKey="description"
                  disableSort={true}
                  cellRenderer={row => row.cellData.get("description") || NA}
                />

                <DataListColumn
                  width={120}
                  label={getLocalisationMessage("phone", "Phone")}
                  dataKey="phone"
                  justifyContent="center"
                  disableSort={true}
                  cellRenderer={row => row.cellData.get("phone") || NA}
                />

                <DataListColumn
                  width={60}
                  dataKey="phone"
                  label={getLocalisationMessage("action", "Action")}
                  disableSort={true}
                  justifyContent="center"
                  cellRenderer={row => (
                    <Button
                      onClick={() =>
                        props.onRemoveDriver(row.cellData.get("id"))
                      }
                    >
                      {getLocalisationMessage("remove", "Remove")}
                    </Button>
                  )}
                />
              </DataList>
            )}
          </FlexBox>
          <FlexBox>
            <Button onClick={props.handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Paper>
  );
}

export default enhancer(WarehouseItemDriversForm);
