import { OrderedSet } from "immutable";

export const ALL = "ALL";
export const LINEAR_PRICE = "LINEAR_PRICE";
export const BUCKET_PRICE = "BUCKET_PRICE";

export const priceTypesForFilter = OrderedSet.of(
  ALL,
  LINEAR_PRICE,
  BUCKET_PRICE,
);

export default OrderedSet.of(LINEAR_PRICE, BUCKET_PRICE);
