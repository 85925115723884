import React from "react";
import PropTypes from "prop-types";
import DataListFilter from "../../../helpers/DataListFilter";
import { ROUTE } from "../../../constants/OrderType";
import AdminOfflineBatchUpdateContainer from "./AdminOfflineBatchUpdateContainer";

const AdminOfflineOrderOutboundRoutesContainerWrapper = props => (
  <AdminOfflineBatchUpdateContainer
    {...props}
    tab={ROUTE}
    filter={
      new DataListFilter({
        page: 0,
        size: 50,
        type: ROUTE,
      })
    }
  />
);
AdminOfflineOrderOutboundRoutesContainerWrapper.propTypes = {
  onTabChange: PropTypes.func,
  tabCount: PropTypes.string,
};
export default AdminOfflineOrderOutboundRoutesContainerWrapper;
