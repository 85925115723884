import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, getContext } from "recompose";
import PropTypes from "prop-types";
import { CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import SubscriptionSignUpFormNew from "../SubscriptionSignUpFormNew";
import { getValue } from "../../../helpers/DataUtils";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import ResponseError from "../../../helpers/ResponseError";
import { getMessages } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { registerDmsCustomer } from "../../../api/admin/AdminSubscriptionApi";
import CenteredCard from "../../../components/deprecated/CenteredCard";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func.isRequired }),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withState("state", "setState", {
    showSuccessPage: false,
    newMarketplaceURL: undefined,
    seconds: 5,
  }),
  useSheet({
    signIn: {
      width: "100%",
      color: "white",
      backgroundColor: "#f39555",
      "&:hover": { backgroundColor: "#ea6b44" },
    },
    successPageCard: {
      minWidth: "400px",
      maxWidth: "400px",
      textAlign: "center",
    },
  }),
);

AdminSubscriptionSignUpContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),

  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,

  showSuccessPage: PropTypes.bool,
  newMarketplaceURL: PropTypes.string,
};

function AdminSubscriptionSignUpContainer(props) {
  const { classes, i18n } = props;
  const { showSuccessPage, newMarketplaceURL, seconds } = props.state;
  const interval = () =>
    setInterval(
      () =>
        seconds > 0
          ? props.setState(fp.set("seconds", seconds - 1))
          : clearInterval(interval),
      1000,
    );
  const signUpForm = (
    <SubscriptionSignUpFormNew
      initialValues={{
        companyType: false,
      }}
      onSubmit={values =>
        registerDmsCustomer(toSnakeCase(values)).catch(ResponseError.throw)
      }
      onSubmitSuccess={res => {
        props.showSuccessMessage(
          i18n.get("successfully_registered", "Successfully Registered"),
        );
        const domain = getValue(res, "data.setting.settings.domain", false);
        if (fp.isString(domain)) {
          props.setState(
            fp.set(
              "newMarketplaceURL",
              `https://${domain}?jwt=${getValue(res, "data.id_token", false)}`,
            ),
          );
        }
        props.setState(fp.set("showSuccessPage", true));
      }}
      onSubmitFail={props.showErrorMessage}
    />
  );

  const successPage = (
    <CenteredCard withLogo={true} cardClassName={classes.successPageCard}>
      <CardContent style={{ paddingBottom: "40px" }}>
        <br />
        <h4>
          <strong>
            {i18n.get("thank_you_for_subscribing", "Thank You For Subscribing")}
          </strong>
        </h4>
        <br />
        <h6 style={{ marginBottom: "10px" }}>
          <strong>
            {i18n.get("just_one_more_step", "Just one more step...")}
          </strong>
        </h6>
        <p>
          <strong>
            {i18n.get(
              "check_your_e_mail_to_confirm_your_subscription",
              "Check your e-mail to confirm your subscription.",
            )}
          </strong>
        </p>
        <p
          style={{
            borderTop: "1px solid #ccc",
            paddingTop: "30px",
            margin: "0",
          }}
        >
          <strong>
            After <span style={{ color: "red" }}>{seconds}</span> seconds you
            will be redirect to Dashboard
          </strong>
        </p>
      </CardContent>
      {showSuccessPage && <div style={{ display: "none" }}>{interval()}</div>}
      {seconds === 0 && (
        <div style={{ display: "none" }}>
          {(window.location.href = newMarketplaceURL)}
        </div>
      )}
    </CenteredCard>
  );

  return showSuccessPage ? successPage : signUpForm;
}

export default enhancer(AdminSubscriptionSignUpContainer);
