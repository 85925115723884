import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import VehicleMarker from "../maps/VehicleMarker2";
import { pureComponent } from "../../helpers/HOCUtils";
import LVehicleMarker from "../maps-leaflet/LVehicleMarker";

const enhancer = compose(pureComponent(fp.pick(["locations"])));

DriverRadarVehicles2.propTypes = {
  onClick: PropTypes.func,
  mapType: PropTypes.oneOf(["google_map", "leaflet_map"]),
  locations: PropTypes.instanceOf(Immutable.OrderedMap),
};

DriverRadarVehicles2.defaultProps = {
  mapType: "google_map",
};

function DriverRadarVehicles2({ mapType, locations, onClick }) {
  const list = locations
    .map((item, idx) => {
      const driverId = item.getIn(["location", "driver_id"]);

      if (mapType === "leaflet_map") {
        return (
          <LVehicleMarker
            withMotion={false}
            busy={item.getIn(["location", "busy"], "default")}
            key={idx}
            driverId={driverId}
            onClick={() => onClick(driverId)}
            rotation={item.getIn(["location", "angle"])}
            position={{
              lat: item.getIn(["location", "lat"]),
              lng: item.getIn(["location", "lon"]),
            }}
          />
        );
      }

      return (
        <VehicleMarker
          busy={item.getIn(["location", "busy"], "default")}
          key={idx}
          driverId={driverId}
          onClick={() => onClick(driverId)}
          rotation={item.getIn(["location", "angle"])}
          position={{
            lat: item.getIn(["location", "lat"]),
            lng: item.getIn(["location", "lon"]),
          }}
        />
      );
    })
    .toArray();

  return <div>{list}</div>;
}

export default enhancer(DriverRadarVehicles2);
