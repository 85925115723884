import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedJurisdictionLevel,
  getJurisdictionLevelPredictions,
} from "../../api/admin/AdminJurisdictionsApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });

const LevelAutocomplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getCachedJurisdictionLevel(value.id)
            .map(item => ({ id: value.id, name: item.get("name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .map(v => ({ name: v.input.value.name }))
    .distinctUntilChanged(isEqualData)
    .withLatestFrom(propsStream)
    .map(([, props]) => props)
    .switchMap(({ input: { value } }) =>
      getJurisdictionLevelPredictions(baseFilter.setSearch(value && value.name))
        .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
        .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS());

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    ({ readOnly, ...props }, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        input={{ ...props.input, value }}
        readOnly={readOnly}
        disabled={props.disabled}
      />
    ),
  );
});

FormJurisdictionLevelAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,
  formatOption: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
  variant: PropTypes.oneOf(["", "outlined", "filled"]),
};

FormJurisdictionLevelAutoComplete.defaultProps = {
  maxSearchResults: 10,
  variant: "outlined",
};

export default function FormJurisdictionLevelAutoComplete(props) {
  return <Field {...props} component={LevelAutocomplete} />;
}
