import { Set } from "immutable";

export const COMPLETED = "COMPLETED";
export const PAYMENT_CONFIRMATION = "PAYMENT_CONFIRMATION";
export const PENDING = "PENDING";
export const APPROVED = "APPROVED";
export const REJECTED = "REJECTED";

export default Set([
  REJECTED,
  APPROVED,
  PENDING,
  PAYMENT_CONFIRMATION,
  COMPLETED,
]);
