import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { List } from "immutable";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { blue } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import { DeleteOutline, ExpandLess, ExpandMore } from "@material-ui/icons";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import TablePagination from "@material-ui/core/TablePagination";
import { LinearProgress } from "@material-ui/core";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import { getInternationalSettingsRegionList } from "../../api/admin/AdminInternationalReportsApi";

const useRowStyles = makeStyles({
  root: {
    borderBottom: "1px solid #ccc",
  },
  childTable: {
    width: "100%",
    borderBottom: "1px solid #ccc",
  },
  childRow: {
    width: "100",
    background: "rgba(0,0,0, 0.1)",
  },
  link: {
    color: "#9c0008",
    textDecoration: "none",
    cursor: "pointer",
  },
  container: {
    height: "calc(100vh - 12.3rem)",
  },
  badge: {
    width: 20,
    height: 20,
    marginRight: 10,

    "& > span": {
      top: "50%",
      color: "white",
      backgroundColor: blue[500],
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

function RenderEnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell, index) => (
          <TableCell
            key={index}
            style={{ width: _.get(headCell, "width") || "auto" }}
            align={props.headCells.length - 1 === index ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

RenderEnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
};

function Row(props) {
  const { row, setId, setChildId, columns, setDeleteId, refreshChild } = props;
  const [open, setOpen] = useState(null);
  const classes = useRowStyles();
  const [rowChild, setRowChild] = useState([]);
  const [isLoadingChild, setIsLoadingChild] = useState(false);

  useEffect(() => {
    setIsLoadingChild(true);
    if (open) {
      getInternationalSettingsRegionList({
        parent_id: open,
        level_id: 2,
        page: 0,
        size: 1000,
      })
        .then(res => {
          setIsLoadingChild(false);
          const child = _.get(res, "data.list", []);
          setRowChild(child);
        })
        .catch(() => setIsLoadingChild(false));
    }
  }, [refreshChild, open]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {_.get(row, "id") &&
          columns.map(column => {
            if (_.get(column, "name") === "id") {
              return (
                <TableCell
                  style={{ width: _.get(column, "width") }}
                  component="td"
                  scope="row"
                  align="center"
                >
                  <FlexBox align={ALIGN_CENTER}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <strong
                      style={{
                        color: "#9c0008",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => setId(row.id)}
                    >
                      {_.get(row, "id")}
                    </strong>{" "}
                    {_.get(row, "child_count", 0) > 0 && (
                      <div>
                        {_.get(row, "id") && open === _.get(row, "id") ? (
                          <IconButton onClick={() => setOpen(null)}>
                            <ExpandLess />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <ExpandMore
                              onClick={() => setOpen(_.get(row, "id"))}
                            />
                          </IconButton>
                        )}
                      </div>
                    )}
                  </FlexBox>
                </TableCell>
              );
            }

            return (
              <TableCell
                style={{ width: _.get(column, "width") || "auto" }}
                align="left"
              >
                {_.get(column, "render", null) ? column.render({ row }) : ""}
              </TableCell>
            );
          })}
      </TableRow>
      <TableRow className={classes.childRow}>
        <TableCell
          style={{
            padding: 0,
            width: "100%",
          }}
          colSpan={8}
        >
          <Collapse
            in={open === _.get(row, "id")}
            timeout="auto"
            unmountOnExit={true}
          >
            <Table
              size="small"
              aria-label="purchases"
              className={classes.childTable}
            >
              {isLoadingChild ? (
                <LinearProgress
                  style={{ position: "absolute", width: "100%" }}
                  mode="indeterminate"
                  color="secondary"
                />
              ) : (
                <React.Fragment>
                  <TableBody>
                    {rowChild.map(item => (
                      <TableRow key={item.id}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ width: 230 }}
                        >
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <strong
                            style={{
                              color: "#9c0008",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => setChildId(item.id)}
                          >
                            {_.get(item, "id")}
                          </strong>{" "}
                        </TableCell>
                        <TableCell align="left" style={{ width: 230 }}>
                          {item.name}
                        </TableCell>
                        <TableCell align="left" style={{ width: 230 }}>
                          {item.code || "  "}
                        </TableCell>
                        <TableCell align="left" style={{ width: 230 }}>
                          {item.impc_code || "  "}
                        </TableCell>
                        <TableCell align="left" style={{ width: 230 }}>
                          {item.impc_name || "  "}
                        </TableCell>
                        <TableCell align="left" style={{ width: 230 }}>
                          {item.organization_code || "  "}
                        </TableCell>
                        <TableCell align="left" style={{ width: 230 }}>
                          {item.organization_name || "  "}
                        </TableCell>
                        <TableCell align="center" style={{ width: 330 }}>
                          <FlexBox justify="center">
                            <FlexBox style={{ width: 120 }} />
                            <FlexBox justify="flex-end">
                              <IconButton
                                onClick={() => {
                                  setDeleteId(_.get(item, "id", 0));
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </FlexBox>
                          </FlexBox>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </React.Fragment>
              )}
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.object,
  setChildId: PropTypes.func,
  setId: PropTypes.func,
  setDeleteId: PropTypes.func,
  columns: PropTypes.array,
  refreshChild: PropTypes.bool,
};

MUITableRegionList.propTypes = {
  withoutPagination: PropTypes.bool,
  columns: PropTypes.array,
  setChildId: PropTypes.string,
  list: PropTypes.instanceOf(List),
  page: PropTypes.number,
  total: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  setId: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setDeleteId: PropTypes.func,
  isLoading: PropTypes.bool,
  refreshChild: PropTypes.bool,
};

function MUITableRegionList(props) {
  const {
    list,
    page,
    total,
    columns,
    setChildId,
    rowsPerPage,
    handleChangePage,
    withoutPagination,
    getLocalisationMessage,
    setDeleteId,
    handleChangeRowsPerPage,
    setId,
    isLoading,
    refreshChild,
  } = props;
  const classes = useRowStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="collapsible table">
        <RenderEnhancedTableHead headCells={columns} />

        {isLoading ? (
          <LinearProgress
            style={{ position: "absolute", width: "100%" }}
            mode="indeterminate"
            color="secondary"
          />
        ) : (
          <TableBody>
            {list.map((row, index) => (
              <Row
                key={index}
                row={row}
                setId={setId}
                columns={columns}
                setChildId={setChildId}
                setDeleteId={setDeleteId}
                refreshChild={refreshChild}
              />
            ))}
          </TableBody>
        )}

        {!withoutPagination && (
          <TablePagination
            style={{
              position: "fixed",
              background: "white",
              bottom: 0,
              width: "100vw",
              borderTop: "1px solid rgba(0, 0, 0, 0.3)",
            }}
            labelRowsPerPage={getLocalisationMessage("rows_per_page")}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
        )}
      </Table>
    </TableContainer>
  );
}

export default enhancer(MUITableRegionList);
