import { Observable } from "rxjs";
import React from "react";
import { fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import NavTabs from "../ui-core/NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { RETURN, PICK_UP, DROP_OFF } from "../../constants/OrderJobsTypes";

const baseFilter = fromJS({
  page: 0,
  size: 1,
  order_by: null,
  order_by_direction: null,
  use_solr: true,
});

const ALL_TAB = "All";
const RETURN_TAB = "Return";
const PICK_UP_TAB = "Pick Up";
const DROP_OFF_TAB = "Delivery";

const tabBaseFilters = OrderedMap()
  .set(ALL_TAB, fromJS({ page: 0 }))
  .set(
    PICK_UP_TAB,
    fromJS({
      page: 0,
      job_type: PICK_UP,
    }),
  )
  .set(
    DROP_OFF_TAB,
    fromJS({
      page: 0,
      job_type: DROP_OFF,
    }),
  )
  .set(
    RETURN_TAB,
    fromJS({
      page: 0,
      job_type: RETURN,
    }),
  );

const enhancer = compose(
  mapPropsStream(propsStream => {
    const valueStream = propsStream
      .map(props => ({
        pathname: props.basePathname,
        query: props.filter.setPage(0).getDefinedValues(),
      }))
      .distinctUntilChanged(isEqualData);

    const tabsQueryStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props =>
        tabBaseFilters.map(tabFilter => props.filter.setValueMap(tabFilter)),
      )
      .distinctUntilChanged(isEqualData);

    const tabCountsStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        batchGetOrderJobsCount: props.batchGetOrderJobsCount,
        filters: tabBaseFilters.map(tabFilter =>
          props.filter.setValueMap(tabFilter).setValueMap(baseFilter),
        ),
      }))
      .distinctUntilKeyChanged("filters", isEqualData)
      .switchMap(
        ({ filters, batchGetOrderJobsCount }) =>
          batchGetOrderJobsCount
            ? batchGetOrderJobsCount(filters)
            : Observable.of(fromJS({})),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        valueStream,
        tabsQueryStream,
        tabCountsStream,
        (props, value, tabsQuery, tabCounts) => ({
          ...props,
          value,
          width: tabsQuery.size * 176,
          tabs: tabsQuery
            .map((filter: DataListFilter, tab) => ({
              label: <TabBadge label={tab} badge={tabCounts.get(tab)} />,
              value: {
                pathname: props.basePathname,
                query: filter.getDefinedValues(),
              },
            }))
            .toArray(),
        }),
      )
      .map(fp.omit(["filter", "basePathname", "batchGetOrderJobsCount"]))
      .distinctUntilChanged(isEqualData);
  }),
);

const OrderJobsTabs = enhancer(NavTabs);

OrderJobsTabs.propTypes = {
  batchGetOrderJobsCount: PropTypes.func,
  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
};

export default OrderJobsTabs;
