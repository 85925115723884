import React from "react";
import { List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
  Add as ContentAdd,
  FilterList as ContentFilterList,
} from "@material-ui/icons";
import DataListFilter from "../../helpers/DataListFilter";
import { mapOrderListPropsStream } from "../../helpers/OrderListHelper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  CUSTOMER_ITEM_URL,
  JOBS_URL,
} from "../../constants/AdminPathConstants";
import { getOrderList } from "../../api/admin/AdminOrderApi";
import AdminJobFilterWrapper from "../../wrappers/admin/AdminJobFilterWrapper";
import AdminMergeJobsDialogWrapper from "../../wrappers/admin/AdminMergeJobsDialogWrapper";
import AdminOrderJobEditDialogWrapper from "../../wrappers/admin/AdminOrderJobEditDialogWrapper";
import AdminBatchOrderNoteDialogWrapper from "../../wrappers/admin/AdminBatchOrderNoteDialogWrapper";
import AdminBatchOrderSizeDialogWrapper from "../../wrappers/admin/AdminBatchOrderSizeDialogWrapper";
import AdminBatchUpdateJobDialogWrapper from "../../wrappers/admin/AdminBatchUpdateJobDialogWrapper";
import AdminOrderJobCreateDialogWrapper from "../../wrappers/admin/AdminOrderJobCreateDialogWrapper";
import AdminOrderJobDetailsDialogWrapper from "../../wrappers/admin/AdminOrderJobDetailsDialogWrapper";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import AdminOverweightUploadDialogWrapper from "../../wrappers/admin/AdminOverweightUploadDialogWrapper";
import AdminBatchRescheduleOrderDialogWrapper from "../../wrappers/admin/AdminBatchRescheduleOrderDialogWrapper";
import AdminBatchUpdateOrderPickupDriverDialogWrapper from "../../wrappers/admin/AdminBatchUpdateOrderPickupDriverDialogWrapper";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import ButtonForm from "../../components/ui-core/ButtonForm";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import JobList from "../../components/orders-core/JobList";
import OrderListJobTabs from "../../components/orders-core/OrderListJobTabs";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import {
  CREATE_ORDER_CSV_URL,
  CREATE_ORDER_JOB_MANIFEST_URL,
} from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";

const ORDER_FILTER_DIALOG_HASH = "#OFD";
const BATCH_UPDATE_PICKUP_DRIVER_HASH = "#BUPDH";
const BATCH_ORDERS_UPDATE_DIALOG_HASH = "#BOUDH";
const MERGE_JOBS_DIALOG_HASH = "#MJDH";
const UPLOAD_OVERWEIGHT_CSV_DIALOG_HASH = "#UOCDH";
const BATCH_ORDER_SIZE_UPDATE_DIALOG_HASH = "#BOSUDH";
const BATCH_ORDERS_RESCHEDULE_DIALOG_HASH = "#BORDH";
const BATCH_CREATE_ORDER_NOTES_DIALOG_HASH = "#BCONDH";

const enhancer = compose(
  useSheet({
    pageFab: { bottom: "64px" },
    appBarRightAction: { height: "100%" },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      marginLeft: "12px",
    },
    filterToggle: { marginTop: "12px" },
    appBarRightActionButton: {
      color: "#fff",
      [responsive("$xs or $sm")]: { display: "none" },
    },
    menuButton: {
      width: "100%",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    mapOrderListPropsStream(
      getOrderList,
      (
        props,
        filter,
        response,
        onRowSelect,
        selectedItems,
        onRequestRefresh,
        batchGetOrderListCount,
      ) => ({
        ...props,
        filter,
        onRowSelect,
        selectedItems,
        onRequestRefresh,
        batchGetOrderListCount,
        list: response.get("list"),
        total: response.get("total"),
        error: response.get("error"),
        isLoading: response.get("isLoading"),
      }),
    ),
  ),
);

AdminOrderJobList.propTypes = {
  classes: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  error: PropTypes.instanceOf(Error),
  onRequestRefresh: PropTypes.func,

  batchGetOrderListCount: PropTypes.func,

  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  getLocalisationMessage: PropTypes.func,
  isAdminViewer: PropTypes.bool,
};

function AdminOrderJobList(props) {
  const { classes, location, getLocalisationMessage } = props;
  const isDomestic = props.filter.getBoolValue("is_uae");
  const useSolr = props.filter.getBoolValue("use_solr");
  const orderNumbers = props.selectedItems.toOrderedSet();
  const selectedIds = props.selectedItems.keySeq().toArray();
  const orderNumbersArray = orderNumbers.toArray();

  const searchTypes = OrderedMap()
    .set(
      "order_number",
      getLocalisationMessage("order_numbers", "Order Numbers"),
    )
    .set("pickup,delivery", getLocalisationMessage("all_types", "All Types"))
    .set("pickup", getLocalisationMessage("pick_up_types", "Pick Up Types"))
    .set(
      "delivery",
      getLocalisationMessage("delivery_types", "Delivery Types"),
    );

  return (
    <AdminAppLayout
      slug="jobs"
      title={getLocalisationMessage("jobs", "Jobs")}
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            value={!isDomestic}
            label={getLocalisationMessage("international", "International")}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("is_uae", !value),
              )
            }
          />

          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </FlexBox>
      }
    >
      <Redirect
        when={!searchTypes.has(location.query.types)}
        to={updateQuery(location, fp.set("types", "pickup,delivery"))}
      />

      <OrderListErrorNotification
        error={props.error}
        onFixSizeError={() =>
          props.setLocationQueryFilter(props.filter.setPageSize(0))
        }
      />

      <AdminOverweightUploadDialogWrapper
        open={location.hash === UPLOAD_OVERWEIGHT_CSV_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <AdminBatchUpdateOrderPickupDriverDialogWrapper
        open={location.hash === BATCH_UPDATE_PICKUP_DRIVER_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ orderNumbers: orderNumbersArray }}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_assigned_pickup_drivers",
              "Successfully Assigned Pickup Drivers",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <AdminBatchOrderNoteDialogWrapper
        open={location.hash === BATCH_CREATE_ORDER_NOTES_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ orderNumbers: orderNumbersArray }}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated_order_notes",
              "Successfully Updated Order Notes",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <AdminBatchUpdatesItemDialogWrapper
        batchId={fp.toFinite(location.query.batch_id)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("batch_id"));
        }}
        onShowOrdersClick={numbers =>
          props.setLocationQuery({ search: numbers.join(",") })
        }
      />

      {location.hash === BATCH_ORDERS_UPDATE_DIALOG_HASH && (
        <AdminBatchUpdateJobDialogWrapper
          open={true}
          initialValues={{ orderNumbers: orderNumbersArray }}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={response => {
            props.replaceLocationHash(null);
            props.setLocationQuery(fp.set("batch_id", response.data.id));
          }}
        />
      )}

      {location.hash === MERGE_JOBS_DIALOG_HASH && (
        <AdminMergeJobsDialogWrapper
          open={true}
          initialValues={{ orderNumbers: orderNumbersArray }}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmitSuccess={response => {
            props.replaceLocationHash(null);
            props.setLocationQuery(fp.set("batch_id", response.data.id));
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      <AdminOrderJobDetailsDialogWrapper
        tab={location.query.view_tab}
        location={location}
        orderId={fp.toFinite(location.query.view_job)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestRefresh={props.onRequestRefresh}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view_job", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view_job"),
              fp.set("edit", location.query.view_job),
            ),
          )
        }
      />

      <AdminOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(fp.unset("view"), fp.set("edit", location.query.view)),
          )
        }
        location={location}
      />

      <AdminOrderJobCreateDialogWrapper
        open={location.query.create === "true"}
        initialValues={{ orderNumbers: orderNumbersArray }}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminOrderJobEditDialogWrapper
        orderId={fp.toFinite(location.query.edit)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(
            fp.flow(fp.unset("edit"), fp.set("view_job", location.query.edit)),
          );
        }}
      />

      <AdminJobFilterWrapper
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        open={location.hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <AdminBatchOrderSizeDialogWrapper
        open={location.hash === BATCH_ORDER_SIZE_UPDATE_DIALOG_HASH}
        initialValues={{ orderNumbers: orderNumbersArray }}
        onRequestClose={() => props.replaceLocationHash(null)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated_orders_size",
              "Successfully Updated Orders Size",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <AdminBatchRescheduleOrderDialogWrapper
        initialValues={{ orderNumbers: orderNumbersArray }}
        open={location.hash === BATCH_ORDERS_RESCHEDULE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_rescheduled_orders",
              "Successfully Rescheduled Orders",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderListJobTabs
        filter={props.filter}
        basePathname={JOBS_URL}
        batchGetOrderListCount={props.batchGetOrderListCount}
      />

      <JobList
        chipTextHint={getLocalisationMessage(
          "search_max_orders",
          "Search Max Orders",
        )}
        onRowSelect={props.onRowSelect}
        selectedItems={props.selectedItems}
        maxSearchItems={
          props.filter.getValue("search_type") === "order_number" ? Infinity : 1
        }
        filter={props.filter}
        searchTypes={searchTypes}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
        createOrderHref={id => updateQuery(location, fp.set("view_job", id))}
        createCustomerHref={id => CUSTOMER_ITEM_URL + id}
        onStatusClick={item => {
          props.onRowSelect(
            OrderedMap().set(item.get("id"), item.get("order_number")),
          );
          props.replaceLocationHash(BATCH_ORDERS_UPDATE_DIALOG_HASH);
        }}
        onEstimatedDeliveryDateClick={item => {
          props.onRowSelect(
            OrderedMap().set(item.get("id"), item.get("order_number")),
          );
          props.replaceLocationHash(BATCH_ORDERS_RESCHEDULE_DIALOG_HASH);
        }}
        altHeader={
          <div>
            <ButtonForm
              url={CREATE_ORDER_JOB_MANIFEST_URL}
              label={getLocalisationMessage("print_manifest", "Print Manifest")}
              params={{
                ids: selectedIds,
              }}
            />
          </div>
        }
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Link to={updateQuery(location, fp.set("create", true))}>
                <Tooltip
                  title={getLocalisationMessage(
                    "create_new_job",
                    "Create New Job",
                  )}
                >
                  <IconButton>
                    <ContentAdd />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
            <Link to={updateHash(location, ORDER_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={getLocalisationMessage("filter_jobs", "Filter Jobs")}
              >
                <IconButton>
                  <ContentFilterList />
                </IconButton>
              </Tooltip>
            </Link>

            {!props.isAdminViewer && (
              <MenuButtonMore>
                <MenuItem
                  onClick={() =>
                    props.replaceLocationHash(BATCH_ORDERS_UPDATE_DIALOG_HASH)
                  }
                >
                  {getLocalisationMessage("batch_update", "Batch Update")}
                </MenuItem>

                <MenuItem
                  onClick={() =>
                    props.replaceLocationHash(MERGE_JOBS_DIALOG_HASH)
                  }
                >
                  {" "}
                  {getLocalisationMessage("merge_jobs", "Merge Jobs")}{" "}
                </MenuItem>

                <MenuItemForm
                  url={CREATE_ORDER_JOB_MANIFEST_URL}
                  params={{
                    order_numbers: orderNumbersArray,
                  }}
                  primaryText={getLocalisationMessage(
                    "print_manifest",
                    "Print Manifest",
                  )}
                />
                <MenuItem
                  onClick={() =>
                    props.replaceLocationHash(
                      BATCH_ORDERS_RESCHEDULE_DIALOG_HASH,
                    )
                  }
                >
                  {" "}
                  {getLocalisationMessage("reschedule", "Reschedule")}{" "}
                </MenuItem>
                <Link
                  to={updateQuery(
                    CREATE_ORDER_CSV_URL,
                    props.filter.getDefinedValues(),
                  )}
                >
                  <MenuItem button={true} target="_blank" component="a">
                    {" "}
                    {getLocalisationMessage(
                      "download_csv",
                      "Download CSV",
                    )}{" "}
                  </MenuItem>
                </Link>
              </MenuButtonMore>
            )}
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderJobList);
