import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const computeSum = fp.flow(fp.map(fp.toFinite), fp.sum);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    columnName: { width: "200px" },
    grandRow: { fontWeight: "bold" },
    reportTable: {
      "& th": { fontWeight: "bold", color: "#000000", textAlign: "center" },
      "& tr": {
        "& td:not(:first-child)": { textAlign: "center" },
      },
    },
  }),
);

ShipmentsPendingReport.propTypes = {
  reportType: PropTypes.string,
  onChangeFilter: PropTypes.func,
  classes: PropTypes.object,
  showTable: PropTypes.bool,
  reportList: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ShipmentsPendingReport(props) {
  const { classes, getLocalisationMessage } = props;

  const report =
    props.showTable &&
    Map().withMutations(map => {
      if (props.reportList) {
        props.reportList.delete("all").forEach((item, key) => {
          item.delete("all").forEach(item2 => {
            item2.delete("all").forEach((item3, key3) => {
              if (key3 !== "all") {
                map.updateIn([key, key3], 0, status => status + item3);
              }
            });
          });
        });
      }
    });

  const total =
    Boolean(report && report.size > 0) &&
    Map().withMutations(map => {
      report.forEach(item => {
        item.forEach((item2, key) => {
          map.update(key, 0, value => value + item2);
        });
      });
    });

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          {props.showTable && (
            <FlexBox>
              <Table className={classes.reportTable}>
                <TableHead adjustForCheckbox={false} displaySelectAll={false}>
                  <TableRow>
                    <TableCell className={classes.columnName} />
                    <TableCell>
                      {getLocalisationMessage("delivery_capital", "DELIVERY")}{" "}
                      <br />{" "}
                      {getLocalisationMessage("failed_capital", "FAILED")}
                    </TableCell>
                    <TableCell>
                      {getLocalisationMessage("in_capital", "IN")} <br />{" "}
                      {getLocalisationMessage("sorting_capital", "SORTING")}
                      <br />{" "}
                      {getLocalisationMessage("facility_capital", "FACILITY")}
                    </TableCell>
                    <TableCell>
                      {getLocalisationMessage("in_capital", "IN")} <br />{" "}
                      {getLocalisationMessage("transit_capital", "TRANSIT")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage("on_hold_capital", "ON HOLD")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage("out_for_capital", "OUT FOR")}{" "}
                      <br />{" "}
                      {getLocalisationMessage("delivery_capital", "DELIVERY")}
                    </TableCell>
                    <TableCell>
                      {getLocalisationMessage("picked_capital", "PICKED")}{" "}
                      <br /> {getLocalisationMessage("up_capital", "UP")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage("to_be_capital", "TO BE")} <br />{" "}
                      {getLocalisationMessage("returned_capital", "RETURNED")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage("pending_capital", "PENDING")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage("delivered_capital", "DELIVERED")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage("rto", "RTO")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody displayRowCheckbox={false} showRowHover={true}>
                  {report
                    .map((item, key) => (
                      <TableRow key={key}>
                        <TableCell className={classes.columnName}>
                          {key}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("DELIVERY_FAILED"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("IN_SORTING_FACILITY"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("IN_TRANSIT"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("ON_HOLD"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("OUT_FOR_DELIVERY"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("PICKED_UP"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("TO_BE_RETURNED"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(
                            computeSum([
                              item.get("DELIVERY_FAILED"),
                              item.get("IN_SORTING_FACILITY"),
                              item.get("IN_TRANSIT"),
                              item.get("ON_HOLD"),
                              item.get("OUT_FOR_DELIVERY"),
                              item.get("PICKED_UP"),
                              item.get("TO_BE_RETURNED"),
                            ]),
                          )}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("COMPLETED"))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(item.get("RETURNED_TO_ORIGIN"))}
                        </TableCell>
                      </TableRow>
                    ))
                    .toArray()}

                  <TableRow className={classes.grandRow}>
                    <TableCell>
                      {getLocalisationMessage("grand_total", "Grand Total")}
                    </TableCell>
                    <TableCell>
                      {fp.toFinite(total.get("DELIVERY_FAILED"))}
                    </TableCell>
                    <TableCell>
                      {fp.toFinite(total.get("IN_SORTING_FACILITY"))}
                    </TableCell>
                    <TableCell>
                      {fp.toFinite(total.get("IN_TRANSIT"))}
                    </TableCell>
                    <TableCell>{fp.toFinite(total.get("ON_HOLD"))}</TableCell>
                    <TableCell>
                      {fp.toFinite(total.get("OUT_FOR_DELIVERY"))}
                    </TableCell>
                    <TableCell>{fp.toFinite(total.get("PICKED_UP"))}</TableCell>
                    <TableCell>
                      {fp.toFinite(total.get("TO_BE_RETURNED"))}
                    </TableCell>
                    <TableCell>
                      {fp.toFinite(
                        computeSum([
                          total.get("DELIVERY_FAILED"),
                          total.get("IN_SORTING_FACILITY"),
                          total.get("IN_TRANSIT"),
                          total.get("ON_HOLD"),
                          total.get("OUT_FOR_DELIVERY"),
                          total.get("PICKED_UP"),
                          total.get("TO_BE_RETURNED"),
                        ]),
                      )}
                    </TableCell>
                    <TableCell>{fp.toFinite(total.get("COMPLETED"))}</TableCell>
                    <TableCell>
                      {fp.toFinite(total.get("RETURNED_TO_ORIGIN"))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ShipmentsPendingReport);
