import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { formatText } from "../../helpers/FormatUtils";
import {
  getMessages,
  getCurrentLanguage,
} from "../../reducers/LocalizationReducer";
import { RUSSIAN_LANGUAGE } from "../../constants/LocaleTypes";
import OrderSizeCodes from "../../constants/OrderSizeCodes";

const valueSelector = formValueSelector("BatchRescheduleOrderDialog");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  reduxForm({ form: "BatchOrderSizeDialog", enableReinitialize: true }),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
    orderNumbers: valueSelector(state, "orderNumbers"),
    currentLanguage: getCurrentLanguage(state),
  })),
);

const title = (currentLanguage, ordersNumber) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Установить размер (заказов-${ordersNumber})`;

    default:
      return `Set size for ${ordersNumber} orders `;
  }
};

BatchOrderSizeDialog.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  orderNumbers: PropTypes.array,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,

  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
  currentLanguage: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function BatchOrderSizeDialog(props) {
  const { i18n, currentLanguage, classes } = props;

  return (
    <Dialog open={props.open} maxWidth="auto">
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {title(currentLanguage, fp.size(props.orderNumbers))}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          <FormChipInput
            name="orderNumbers"
            focus={true}
            fullWidth={true}
            addOnBlur={true}
            label={i18n.get("orders_numbers", "Order numbers")}
          />

          <FormSelectField
            name="size"
            fullWidth={true}
            label={i18n.get("size", "Size")}
            options={OrderSizeCodes}
            formatOption={x => i18n.get(x, formatText(x)).toLocaleLowerCase()}
          />
        </form>
      </DialogContent>
      <DialogActions>
        {props.dirty ? (
          <Button onClick={props.reset} primary={true}>
            {i18n.get("reset", "Reset")}
          </Button>
        ) : (
          Boolean(props.onRequestClose) && (
            <Button onClick={props.onRequestClose} primary={true}>
              {i18n.get("dismiss", "Dismiss")}
            </Button>
          )
        )}

        <Button onClick={props.handleSubmit} primary={true}>
          {i18n.get("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchOrderSizeDialog);
