import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toHolidaySettingsFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    use_solr: types.boolean,

    cod_status: queryTypes.stringSeq,
    payment_type: queryTypes.stringSeq,
    status: queryTypes.stringSeq,
    order_size: queryTypes.stringSeq,

    marketplaceIds: queryTypes.IDSeq,

    year: types.ID,
    countryId: types.ID,
    marketplaceId: types.ID,

    dayOffType: types.string,
  }),
);
