import fp from "lodash/fp";
import { Map } from "immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const UPDATE_TASK = "ORDER_CUSTOMS_INBOUND_SORTING/UPDATE_TASK";
const isOrderSortingAction = fp.startsWith("ORDER_CUSTOMS_INBOUND_SORTING");

const castToMap = (x) => (Map.isMap(x) ? x : Map());

const cleanupState = fp.flow(castToMap, (initialState) =>
  initialState.withMutations((state) => {
    state.update(
      "task",
      fp.flow(castToMap, (x) =>
        x
          .update("activeOrder", fp.trim)
          .update("warehouse", (w) =>
            Map.isMap(w) && fp.isFinite(w.get("id")) ? w : null,
          ),
      ),
    );
  }),
);

const selector = injectReducer(
  "order-customs-inbound-sorting@1.0.0",
  (initialState = cleanupState(), action) => {
    const state = isOrderSortingAction(action.type)
      ? cleanupState(initialState)
      : initialState;

    switch (action.type) {
      case UPDATE_TASK:
        return state.withMutations((nextState) => {
          nextState.set("task", action.payload);

          nextState.update(cleanupState);
        });

      default:
        return state;
    }
  },
);

export const getOrderCustomsSortingTask = (state) =>
  selector(state).get("task");

export const updateCustomsSortingTask = (updater) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_TASK,
    payload: updater(getOrderCustomsSortingTask(getState())),
  });
};
