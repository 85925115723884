import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FlexBox from "../../components/ui-core/FlexBox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, TextField } from "@material-ui/core";
import { getValue } from "../../helpers/DataUtils";
import { DELIVERY, RETURNING } from "../../constants/FlowTypes";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import Text from "../../components/ui-core/Text";
import _ from "lodash";
import { red } from "@material-ui/core/colors";
import {
  DEFECT,
  DIDNT_APPEAR_ON_NOTICE,
  finalStatuses,
  INCOMPLETE_ADDRESS,
  NOT_AT_HOME,
  ORGANIZATION_WITH_GIVEN_ADDRESS_NOT_FOUND,
  RECEIVER_DEAD,
  RECEIVER_NOT_LIVES_THERE,
  RECEIVER_REFUSE,
  TRY_PERFORM,
} from "../../constants/OrderStatusCodes";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { CheckCircle } from "@material-ui/icons";
import MUITable, {
  ACTION_BY_INDEX,
  INDEX,
  RENDER,
} from "../../components/orders-core/MUITable";
import ScannerTextField from "../../components/deprecated/ScannerTextField";
import fp from "lodash/fp";
import { cleanupStringArray } from "../../helpers/FormatUtils";
// eslint-disable-next-line import/no-internal-modules
import Skeleton from "@material-ui/lab/Skeleton";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import ReasonComponentButtons from "./ReasonComponentButtons";
import Typography from "@material-ui/core/Typography";
import PageLoading from "../../components/ui-core/PageLoading";

const parseOrderNumbers = fp.flow(
  fp.trim,
  x => x.replace(/\W/g, " "),
  // fp.split(" "),
  cleanupStringArray,
);

const useStyles = makeStyles({
  search: {
    flex: "1 1 auto",
  },
  batchDetails: {
    fontWeight: 500,
    fontSize: 18,
    "@media (max-width: 1440px)": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  details: {
    fontWeight: 500,
    fontSize: 24,
  },
  detailsRow: {
    marginBottom: 10,
  },
  detailsLabel: {
    lineHeight: "21px",
    fontWeight: "400",
    fontSize: 18,
    color: "rgba(38, 50, 56, 0.7)",
    "@media (max-width: 1440px)": {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  detailsValue: {
    fontSize: 18,
    lineHeight: "21px",
    "@media (max-width: 1440px)": {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  orderErrorStatus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > div": {
      fontSize: "18px",
      lineHeight: "1.2",
      fontWeight: "bold",
      color: red[400],
    },
  },
  orderStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > span": {
      fontWeight: "bold",
      color: red[400],
      textTransform: "uppercase",
    },

    "@media (max-width: 1440px)": {
      fontSize: "16px",
      lineHeight: "18px",
      marginBottom: "10px",
      "& > strong": {
        fontSize: "16px",
        display: "block",
      },
      "& > span": {
        fontWeight: "bold",
        color: red[400],
        textTransform: "uppercase",
      },
    },
  },
  orderLoading: {
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
  },
  "@media (min-width: 998px)": {
    details: { flexDirection: "column" },
  },
  "@media (max-width: 991px)": {
    content: { flex: 1 },
  },
  reason: {
    gap: 16,
    overflow: "auto",
    "& .MuiButton-root": {
      height: 42,
    },
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      gap: 10,
    },
    "@media (max-width: 1440px)": {
      gap: 12,
      overflow: "auto",
      "& .MuiButton-outlinedSizeSmall": {
        fontSize: "0.65rem !important",
      },
      "& .MuiButton-root": {
        height: 30,
      },
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        gap: 10,
      },
    },
  },
  finalError: {
    color: "#f44336",
    fontSize: 16,
    "@media (max-width: 1440px)": {
      fontSize: 14,
    },
  },
  list: {
    "& .MuiCardContent-root": {
      height: "80vh !important",
    },
  },
});

const reasons = [
  RECEIVER_DEAD,
  INCOMPLETE_ADDRESS,
  RECEIVER_REFUSE,
  NOT_AT_HOME,
  DIDNT_APPEAR_ON_NOTICE,
  DEFECT,
  TRY_PERFORM,
  RECEIVER_NOT_LIVES_THERE,
  ORGANIZATION_WITH_GIVEN_ADDRESS_NOT_FOUND,
];

const getShipmentCity = (orderInfo, flow, from = true) => {
  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "from_city")
      : _.get(orderInfo, "to_city");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "to_city")
    : _.get(orderInfo, "from_city");
};

const getShipmentPostcode = (orderInfo, flow, from = true) => {
  const { type } = orderInfo;

  if (type) {
    if (from) {
      return _.get(orderInfo, "from_postcode.name", "");
    }
    return _.get(orderInfo, "to_postcode.name", "");
  }

  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "from_post_code.name", "")
      : _.get(orderInfo, "to_post_code.name", "");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "to_post_code.name", "")
    : _.get(orderInfo, "from_post_code.name", "");
};

function HybridReturnCreate(props) {
  const classes = useStyles();
  const { getLocalisationMessage, order, isOrderLoading } = props;
  const [selectedReason, setSelectedReason] = useState(null);
  const [isSearching, setIsSearching] = useState(true);
  const [searchText, setSearchText] = useState("");
  const ref = useRef(null);
  const stateRef = useRef(null);
  const [orders, setOrders] = useState([]);
  const [isClose, setIsClose] = useState(false);

  const tempFlow =
    order.flow === null
      ? DELIVERY
      : order.flow === DELIVERY
      ? DELIVERY
      : RETURNING;

  const fromPostcode = getShipmentPostcode(order, tempFlow);
  const toPostcode = getShipmentPostcode(order, tempFlow, false);

  const fromCity = order.type
    ? _.get(order, "from_jurisdiction.name", "")
    : getShipmentCity(order, tempFlow);
  const toCity = order.type
    ? _.get(order, "to_jurisdiction.name", "")
    : getShipmentCity(order, tempFlow, false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (selectedReason && ref && ref.current && stateRef && stateRef.current) {
      ref.current.value = "";
      stateRef.current.state.stateValue.value = "";
      setTimeout(() => {
        ref.current.value = "";
        stateRef.current.state.stateValue.value = "";
        ref.current.focus();
      }, 1);
    }
  }, [selectedReason]);

  useEffect(() => {
    if (props.orderNumber && props.orderNumber.length > 0) {
      if (order && order.id) {
        if (
          !order.redirected &&
          order.flow !== RETURNING &&
          !finalStatuses.includes(order.status)
        ) {
          if (ref && ref.current) {
            setTimeout(() => {
              ref.current.blur();
            }, 1);
            setIsSearching(false);
          }
        }
      }
    }
  }, [props.orderNumber, order]);

  useEffect(() => {
    if (orders) {
      if (searchText) {
        const newArray = [];
        orders.forEach(item => {
          const isItemIncludesSearchText = item.barcode
            .toLowerCase()
            .includes(searchText.toLowerCase());
          if (isItemIncludesSearchText) {
            const foundItem = {
              ...item,
            };
            newArray.push(foundItem);
          }
        });
        setSearchResults(newArray);
      } else {
        setSearchResults(orders);
      }
    }
  }, [searchText, orders]);

  return (
    <FlexBox direction="row" style={{ gap: 16, height: "100%" }}>
      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "NO")}
        open={isClose}
        onConfirm={props.onClose}
        onRequestClose={() => setIsClose(false)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_exit",
          "Are you sure you want to exit?",
        )}
      </ConfirmDialog>
      <PageLoading isLoading={isOrderLoading} />
      <Card style={{ height: "100%", width: "100%" }}>
        <CardContent style={{ padding: 20, height: "100%", overflowY: "auto" }}>
          <FlexBox style={{ gap: 15 }} direction="column">
            <ScannerTextField
              fullWidth={true}
              inputRef={ref}
              ref={stateRef}
              hintText={getLocalisationMessage("add_orders", "Add Orders")}
              className={classes.input}
              id="OrderHybridFormScannerTextField"
              onFocus={() => {
                setIsSearching(true);
              }}
              onBlur={() => {
                setIsSearching(false);
              }}
              onChange={value => {
                const orderNumbers = value.split(",");
                props.setOrderNumber(parseOrderNumbers(orderNumbers[0]));
                setSelectedReason("");
                if (ref && ref.current && stateRef && stateRef.current) {
                  ref.current.value = "";
                  stateRef.current.state.stateValue.value = "";
                }

                setIsSearching(false);
              }}
              size="small"
              clearAfterEnter={true}
            />

            <div
              style={{
                borderBottom: props.error ? "none" : "1px solid #EEEEEE",
              }}
            >
              {props.error ? (
                <FlexBox
                  style={{ color: red[800] }}
                  align="center"
                  justify="center"
                >
                  {getLocalisationMessage("order_not_found")}
                </FlexBox>
              ) : !isValidObjectId(order) && !isOrderLoading ? (
                <FlexBox flex={true} direction="column">
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <Text
                      element="h3"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <FlexBox direction="row">
                        <strong>
                          {" "}
                          {getLocalisationMessage("details", "Details")}:{" "}
                        </strong>
                        <span style={{ width: "100%" }}>
                          <Skeleton width="90%" />{" "}
                        </span>
                      </FlexBox>
                      <FlexBox />
                    </Text>
                  </FlexBox>
                  <FlexBox />
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "shipment_type",
                          "Shipment Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("status", "Status")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>

                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "from_postcode",
                          "From Postcode",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("to_postcode", "To Postcode")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "from_jurisdiction",
                              "From Jurisdiction",
                            )
                          : getLocalisationMessage("from_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "to_jurisdiction",
                              "To Jurisdiction",
                            )
                          : getLocalisationMessage("to_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("weight")}:&nbsp;
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "transportation_type",
                          "Transportation Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              ) : (
                <FlexBox flex={true} direction="column">
                  <FlexBox className={classes.batchDetails}>
                    {order.barcode}
                  </FlexBox>
                  <FlexBox flex={true} wrap={true}>
                    <Text
                      element="h3"
                      className={classes.orderStatus}
                      style={{ justifyContent: "space-between" }}
                    >
                      <FlexBox
                        style={
                          finalStatuses.includes(order.status) &&
                          order.redirected &&
                          order.flow === RETURNING
                            ? { gap: 10, width: "60%" }
                            : { gap: 10, width: "80%" }
                        }
                      >
                        <strong>
                          {getLocalisationMessage("details", "Details")}
                        </strong>
                        <FlexBox align="center" flex={true}>
                          {finalStatuses.includes(order.status) && (
                            <Typography
                              component="h3"
                              className={classes.finalError}
                            >
                              -{" "}
                              {getLocalisationMessage(
                                "changes_cannot_be+made_because_the_delivery_process_has_already_been_completed",
                                "Changes cannot be made because the delivery process has already been completed",
                              )}
                            </Typography>
                          )}
                        </FlexBox>
                      </FlexBox>

                      <FlexBox
                        style={
                          finalStatuses.includes(order.status) &&
                          order.redirected &&
                          order.flow === RETURNING
                            ? { gap: 10, width: "40%", marginLeft: 15 }
                            : { gap: 10, marginLeft: 15 }
                        }
                      >
                        {order.redirected && (
                          <strong
                            style={{
                              border: ".25rem solid #f44336",
                              color: "#f44336",
                              padding: ".2rem 1rem",
                              textTransform: "uppercase",
                            }}
                          >
                            {getLocalisationMessage("redirect_order")}
                          </strong>
                        )}
                        {order.flow === RETURNING && (
                          <strong
                            style={{
                              border: ".25rem solid rgb(255, 152, 0)",
                              color: "rgb(255, 152, 0)",
                              padding: ".2rem 1rem",
                              textTransform: "uppercase",
                            }}
                          >
                            {getLocalisationMessage("return")}
                          </strong>
                        )}
                      </FlexBox>
                    </Text>
                  </FlexBox>
                  <FlexBox />
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "shipment_type",
                          "Shipment Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {getValue(order, "service_type.name") &&
                            getLocalisationMessage(
                              getValue(
                                order,
                                "service_type.name",
                              ).toLocaleLowerCase(),
                              getValue(order, "service_type.name"),
                            )}
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("status", "Status")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {formatOrderStatusCodeForLocalisation(
                            order.status,
                            getLocalisationMessage,
                          )}
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>

                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "from_postcode",
                          "From Postcode",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{fromPostcode}</strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("to_postcode", "To Postcode")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{toPostcode}</strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "from_jurisdiction",
                              "From Jurisdiction",
                            )
                          : getLocalisationMessage("from_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{fromCity}</strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "to_jurisdiction",
                              "To Jurisdiction",
                            )
                          : getLocalisationMessage("to_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{toCity}</strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("weight")}:&nbsp;
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {order.weight} {getLocalisationMessage("kg")}
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "transportation_type",
                          "Transportation Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {getValue(order, "transportation_type") &&
                            getLocalisationMessage(
                              getValue(
                                order,
                                "transportation_type",
                              ).toLocaleLowerCase(),
                              getValue(order, "transportation_type"),
                            )}
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              )}
            </div>

            {order &&
              order.id &&
              !finalStatuses.includes(order.status) &&
              props.orderNumber && props.orderNumber.length > 0 && (
                <FlexBox flex={true} direction="column" style={{ gap: 15 }}>
                  <Text className={classes.detailsLabel}>
                    {getLocalisationMessage("enter_reason")}
                  </Text>

                  <ReasonComponentButtons
                    classes={classes}
                    reasons={reasons}
                    isSearching={isSearching}
                    getLocalisationMessage={getLocalisationMessage}
                    selectedReason={selectedReason}
                    orderNumber={props.orderNumber}
                    onSetReason={reason => {
                      setSelectedReason(reason);
                      const orderIds = orders.map(item => item.id);
                      if (order && order.id && !orderIds.includes(order.id)) {
                        setOrders(prev => [
                          {
                            ...order,
                            status: reason,
                          },
                          ...prev,
                        ]);
                      }
                      props.setOrder({});

                      props.setOrderNumber(prev => {
                        const idx = prev.findIndex(
                          i => i === props.orderNumber[0],
                        );
                        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
                      });
                      ref.current.value = "";
                      stateRef.current.state.stateValue.value = "";
                      props.setOrderLoading(false);
                    }}
                  />
                </FlexBox>
              )}
          </FlexBox>
        </CardContent>
      </Card>
      <Card
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <CardContent style={{ padding: 20, height: "100%" }}>
          <FlexBox
            style={{ gap: 15, height: "100%" }}
            direction="column"
            justify="space-between"
          >
            <div className={classes.search}>
              <TextField
                name="search"
                value={searchText}
                label={getLocalisationMessage("search", "Search")}
                onChange={e => setSearchText(e.target.value)}
                variant="outlined"
                fullWidth={true}
                size="small"
                type="search"
              />
            </div>

            <MUITable
              list={searchResults}
              withoutPagination={true}
              columns={[
                {
                  type: INDEX,
                  label: getLocalisationMessage("#"),
                },
                {
                  type: RENDER,
                  name: "barcode",
                  render: row => _.get(row, `barcode`, ""),
                  label: getLocalisationMessage("orders"),
                },
                {
                  type: RENDER,
                  name: "jurisdiction",
                  label: getLocalisationMessage("jurisdiction"),
                  render: row => _.get(row, `from_post_code.name`, ""),
                },
                {
                  type: RENDER,
                  name: "status",
                  label: getLocalisationMessage("status"),
                  render: row =>
                    formatOrderStatusCodeForLocalisation(
                      row.status,
                      getLocalisationMessage,
                    ),
                },
                {
                  type: ACTION_BY_INDEX,
                  name: "remove",
                  label: getLocalisationMessage("remove"),
                  dispatch: id => {
                    setOrders(prev => {
                      const idx = prev.findIndex(i => i.id === id);
                      return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
                    });

                    if (ref && ref.current) {
                      ref.current.focus();
                    }
                  },
                },
              ]}
            />

            <FlexBox justify="flex-end" style={{ gap: 16, paddingRight: 16 }}>
              <CustomButton
                variant={OUTLINED}
                color={SECONDARY}
                onClick={() => setIsClose(true)}
              >
                {getLocalisationMessage("dismiss", "Dismiss")}
              </CustomButton>
              <CustomButton
                disabled={!(orders && orders.length > 0)}
                variant={CONTAINED}
                color={SECONDARY}
                onClick={() => props.onSubmit(orders)}
                startIcon={<CheckCircle />}
              >
                {getLocalisationMessage("confirm", "Confirm")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </CardContent>
      </Card>
    </FlexBox>
  );
}

HybridReturnCreate.propTypes = {
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  setOrderLoading: PropTypes.func,
  onClose: PropTypes.func,
  setOrderNumber: PropTypes.func,
  onSubmit: PropTypes.func,
  setOrder: PropTypes.func,
  order: PropTypes.object,
  orderNumber: PropTypes.array,
  isOrderLoading: PropTypes.bool,
  error: PropTypes.bool,
};

const mapStateToProps = state => ({
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

export default connect(mapStateToProps, mapDispatchToProps)(HybridReturnCreate);
