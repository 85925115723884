import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const MENU_VENUE_MENU_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/menu`;
const MENU_BATCH_DELETE_URL = `${SHOP_API_ROOT_URL}/admin/menu`;

export const getVenueMenuList = (venueId, request: DataListFilter) =>
  api.getStream(sprintf(MENU_VENUE_MENU_URL, venueId), {
    params: request.getDefinedValues(),
  });

export const removeMenu = ids =>
  api.delete(MENU_BATCH_DELETE_URL, { params: { ids } });
