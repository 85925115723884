import fp from "lodash/fp";
import DataListFilter from "./DataListFilter";
import { types, mapSchema } from "../../shared/helpers/ObjectMapper";

export const toDefaultPackagePricelistFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    supplier_id: types.ID,
    customer_id: types.ID,
    from_country_id: types.ID,
    to_country_id: types.ID,
    from_city_id: types.ID,
    to_city_id: types.ID,
  }),
);
