import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getSupplierUserList } from "../../api/admin/AdminSupplierUserApi";
import AdminSupplierEditDialogWrapper from "../../wrappers/admin/AdminSupplierEditDialogWrapper";
import AdminSupplierCreateDialogWrapper from "../../wrappers/admin/AdminSupplierCreateDialogWrapper";
import AdminSupplierUserEditDialogWrapper from "../../wrappers/admin/AdminSupplierUserEditDialogWrapper";
import AdminSupplierUserCreateDialogWrapper from "../../wrappers/admin/AdminSupplierUserCreateDialogWrapper";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_SUPPLIER_USER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const NA = "N/A";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    appBarRightAction: {
      marginTop: "5px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func,
  }),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map((query) => new DataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .switchMap((filter) =>
        getSupplierUserList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .map(
        fp.flow(
          (response) => fromJS(response),
          (response) =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      );

    return propsStream
      .combineLatest(
        listResponseStream,
        filterStream,
        (props, listResponse, filter) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: listResponse.get("list"),
          total: listResponse.get("total"),
          isLoading: listResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierUserList.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
};

function AdminSupplierUserList(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
  } = props;
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      slug="courier_users"
      title={getLocalisationMessage("courier_users", "Courier Users")}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={props.getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={(value) =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <AdminSupplierUserCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
        onSupplierClick={(id) =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminSupplierUserEditDialogWrapper
        supplierUserId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
        onSupplierClick={(id) =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminSupplierCreateDialogWrapper
        open={props.location.query.create_supplier === "true"}
        onRequestClose={() =>
          props.setLocationQuery(fp.unset("create_supplier"))
        }
      />

      <AdminSupplierEditDialogWrapper
        supplierId={fp.toFinite(props.location.query.view_supplier)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_supplier"))}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={(filter) => props.setLocationQueryFilter(filter)}
        overscanRowCount={7}
        rowCount={props.list.size}
        rowGetter={(options) => props.list.get(options.index)}
        cardActionIcons={
          <div>
            <Tooltip
              title={props.getLocalisationMessage(
                "create_new_supplier_user",
                "Create New Supplier User",
              )}
            >
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_SUPPLIER_USER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {props.getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={96}
          label={props.getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={(row) => (
            <strong>
              <Link
                to={updateQuery(
                  props.location,
                  fp.set("view", row.cellData.get("id")),
                )}
              >
                {row.cellData.get("user_id")}
              </Link>
            </strong>
          )}
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          cellRenderer={(row) => (
            <MultiLineCell
              fallBackValue={NA}
              firstLine={row.cellData.get("first_name")}
              secondLine={row.cellData.get("last_name")}
            />
          )}
        />
        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("login", "Login")}
          dataKey="login"
          disableSort={true}
          cellRenderer={(row) => row.cellData.get("login")}
        />
        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("email", "Email")}
          dataKey="email"
          disableSort={true}
          cellRenderer={(row) => row.cellData.get("email")}
        />
        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          disableSort={true}
          cellRenderer={(row) => row.cellData.get("phone", NA)}
        />
        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("courier_name", "Courier Name")}
          dataKey="supplier_name"
          disableSort={true}
          cellRenderer={(row) => row.cellData.getIn(["supplier", "name"], NA)}
        />
        <DataListColumn
          width={96}
          justifyContent="center"
          label={props.getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          cellRenderer={(row) => {
            const status = row.cellData.get("status");
            return getLocalisationMessage(status) || formatText(status);
          }}
        />
        <DataListColumn
          width={96}
          justifyContent="center"
          label={props.getLocalisationMessage("join_date", "Joined Date")}
          dataKey="created_date"
          disableSort={true}
          cellRenderer={(row) => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminSupplierUserList);
