import React from "react";
import Immutable, { Map } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { IconButton, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { Assignment, Delete } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import OrderStatusInfo from "./OrderStatusInfo";
import UserAvatar from "../avatars/UserAvatar";
import FlexBox, {
  ALIGN_CENTER,
  FLEX_NONE,
  JUSTIFY_END,
  JUSTIFY_SPACE_BETWEEN,
} from "../ui-core/FlexBox";
import { isEqualData, isEqualDataKeys } from "../../helpers/DataUtils";
import {
  getMarketplace,
  isMarketplaceBusinessTypeFoodDelivery,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { orderGroupStatuses } from "../../api/shared/OrderSettingsApi";
import fp from "lodash/fp";
import { Motion, spring } from "react-motion";
import { Collapse } from "react-collapse";
import OrderBriefInfo from "./OrderBriefInfo";
import OrderBriefInfoFood from "./OrderBriefInfoFood";
import { pureComponent } from "../../helpers/HOCUtils";
import { isOrderCapableForCancel } from "../../helpers/OrderHelper";
import OrderDateInfo from "./OrderDateInfo";

const enhancer = compose(
  useSheet({
    root: {
      padding: "15px 20px",
      borderBottom: "1px solid #eee",
      cursor: "pointer",
      "-ms-user-select": "none",
      "-moz-user-select": "none",
      "-webkit-user-select": "none",
      userSelect: "none",
      position: "relative",
      fontFamily: "Blogger Sans",
    },
    rootHover: {
      "&:hover": {
        backgroundColor: "aliceblue",
      },
    },
    driverIcon: {
      marginRight: "15px",
      flexBasis: "40px",
      minWidth: "40px",
    },
    deleteButton: {
      padding: "8px",
      width: "40px",
      height: "40px",
      backgroundColor: grey[800],
      borderRadius: "50%",
    },
    deleteIcon: {
      color: grey[800],
      width: "20px",
      height: "20px",
    },
    deleteIconWhite: {
      color: "#fff",
      width: "20px",
      height: "20px",
    },
    progressBar: {
      marginTop: "18px",
      marginBottom: "18px",
    },
    address: {
      fontWeight: "bold",
      height: "50px",
      overflow: "hidden",
    },
    activeRoot: {
      color: "white",
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplace: getMarketplace(state),
    isFoodDelivery: isMarketplaceBusinessTypeFoodDelivery(state),
  })),
  withState("state", "setState", { collapsed: false }),
  mapPropsStream((propsStream) => {
    const statusStream = propsStream
      .distinctUntilChanged(isEqualDataKeys(["item"]))
      .map((props) => {
        const groupedStatus = orderGroupStatuses.find((group) => {
          const parentStatus = group
            .get("statuses")
            .find((status) => status === props.item.get("status"));
          return parentStatus || false;
        });

        let percentage = 0;
        let status = props.item.get("status");
        let color = "";

        if (groupedStatus) {
          status = groupedStatus.get("name");
          percentage = groupedStatus.get("percentage", 0);
          color = groupedStatus.get("color", "");
        }

        return new Map({
          status,
          percentage,
          color,
        });
      })
      .startWith(Map())
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(statusStream, (props, groupedStatus) => ({
      ...props,
      groupedStatus,
    }));
  }),
  pureComponent(fp.pick(["orderItem", "item", "orderDetails"])),
);

const NA = "N/A";

NewRadarOrderItem.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  orderItem: PropTypes.number,
  orderDetails: PropTypes.instanceOf(Immutable.Map),
  item: PropTypes.instanceOf(Immutable.Map).isRequired,
  onItemClick: PropTypes.func,
  onItemCancel: PropTypes.func,
  onItemCloseClick: PropTypes.func,
  groupedStatus: PropTypes.instanceOf(Map),
  isFoodDelivery: PropTypes.bool,
};

function NewRadarOrderItem(props) {
  const { classes, item, orderItem, state, groupedStatus } = props;

  const activeOrder = item.get("id") === orderItem;
  const backgroundColor = activeOrder
    ? groupedStatus.get("color")
    : "transparent";

  return (
    <FlexBox
      flex={FLEX_NONE}
      container={8}
      className={cx(classes.root, {
        [classes.activeRoot]: activeOrder,
        [classes.rootHover]: !activeOrder,
      })}
      style={{ backgroundColor }}
      direction="column"
    >
      <FlexBox
        align={ALIGN_CENTER}
        onClick={() => props.onItemClick(item.get("id"))}
      >
        <FlexBox flex={4}>
          <Assignment
            style={{ marginRight: "10px" }}
            color={activeOrder ? "white" : grey[800]}
          />{" "}
          {item.get("order_number", NA)}
        </FlexBox>
        <FlexBox flex={true} justify={JUSTIFY_END}>
          <OrderStatusInfo
            color={groupedStatus.get("color")}
            isActiveOrder={activeOrder}
            status={item.get("status")}
          />

          {item.getIn(["driver", "id"]) && (
            <UserAvatar
              className={classes.driverIcon}
              name={item.getIn(["driver", "name"])}
            />
          )}
          {isOrderCapableForCancel(item.get("status")) && (
            <FlexBox>
              <IconButton
                onClick={() => props.onItemCancel(item.get("id"))}
                className={classes.deleteButton}
                style={{
                  marginRight: 10,
                }}
              >
                <Delete className={classes.deleteIcon} />
              </IconButton>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={() => props.onItemClick(item.get("id"))}>
        <LinearProgress
          className={classes.progressBar}
          color="secondary"
          // color={
          //   activeOrder ? "#ffffff" : groupedStatus.get("color", "#4CAF50")
          // }
          variant="determinate"
          value={groupedStatus.get("percentage", 0)}
        />
      </div>

      {!activeOrder && (
        <FlexBox
          onClick={() => props.onItemClick(item.get("id"))}
          className={classes.addressLine}
          justify={JUSTIFY_SPACE_BETWEEN}
        >
          <FlexBox flex={true} wrap={true} className={classes.address}>
            {item.getIn(["locations", 1, "address"], NA)}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            <OrderDateInfo item={item} isActiveOrder={activeOrder} />
          </FlexBox>
        </FlexBox>
      )}

      {activeOrder && props.orderDetails && (
        <Collapse
          isOpened={activeOrder}
          onRest={() =>
            activeOrder && props.setState(fp.set("collapsed", true))
          }
        >
          <FlexBox flex={true} direction="column">
            <Motion
              defaultStyle={{ opacity: 0 }}
              style={{ opacity: spring(state.collapsed ? 1 : 0) }}
            >
              {({ opacity }) =>
                props.isFoodDelivery ? (
                  <OrderBriefInfoFood
                    style={{ opacity }}
                    order={props.orderDetails}
                    onItemCloseClick={props.onItemCloseClick}
                  />
                ) : (
                  <OrderBriefInfo
                    style={{ opacity }}
                    order={props.orderDetails}
                    onItemCloseClick={props.onItemCloseClick}
                  />
                )
              }
            </Motion>
          </FlexBox>
        </Collapse>
      )}
    </FlexBox>
  );
}

export default enhancer(NewRadarOrderItem);
