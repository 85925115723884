import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { solveExceptions } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderExceptionsForm from "../../components/orders-core/OrderExceptionsForm";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  renderIf("open"),
  useSheet({
    card: {
      "& > div": {
        flex: "1 1 0%",
        display: "flex",
      },
    },
    paper: {
      minWidth: "700px",
      maxWidth: "700px",
    },
  }),
);

AdminOrderExceptionsCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderExceptionsCreateDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      title={props.getLocalisationMessage("new_exception", "New Exception")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8}>
        <FlexBox
          gutter={8}
          flex={true}
          style={{ padding: "1rem" }}
          element={<Card className={classes.card} />}
        >
          <FlexBox flex={true} element={<CardContent />}>
            <OrderExceptionsForm
              onSubmit={values =>
                solveExceptions(toSnakeCase(values)).catch(ResponseError.throw)
              }
              onSubmitSuccess={() => {
                props.onRequestClose();
                props.showSuccessMessage(
                  props.getLocalisationMessage(
                    "successfully_save",
                    "Successfully Save",
                  ),
                );
              }}
              onSubmitFail={error => {
                props.showErrorMessage(error);

                if (error.responseCode === "finance") {
                  props.onRequestClose();
                }
              }}
              onDismiss={props.onRequestClose}
              getSupplierPredictions={getSupplierPredictions}
              getCachedSupplier={getCachedSupplier}
              getDriverPredictions={getDriverPredictions}
              getCachedDriver={getCachedDriver}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderExceptionsCreateDialogWrapper);
