import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../../../client/components/ui-core/FlexBox";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { fade } from "../../helpers/FormatUtils";

const enhancer = compose(
  connect((state) => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    root: { margin: "6px" },
    title: { fontSize: "14px", lineHeight: "17px", marginBottom: "3px" },
    text: { textTransform: "uppercase" },
    leftAvatar: {
      borderRadius: "50%",
      position: "relative",
      backgroundColor: fade("#546E7A", 0.1),
      marginRight: (props) => (props.isRTL ? "0" : "12px"),
      marginLeft: (props) => (!props.isRTL ? "0" : "12px"),
      "&>div ": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "&>div > img": {
        width: "1.5rem",
        height: "1.5rem",
        borderRadius: 0,
      },
    },
    textBlock: { paddingTop: 0, lineHeight: "17px", fontWeight: "bold" },
  }),
);

RadarBriefItem.propTypes = {
  avatar: PropTypes.node,
  classes: PropTypes.object,
  text: PropTypes.node,
  title: PropTypes.node.isRequired,
  isRTL: PropTypes.bool,
};

function RadarBriefItem(props) {
  const { classes } = props;

  return (
    <FlexBox className={classes.root}>
      {props.avatar && <div className={classes.leftAvatar}>{props.avatar}</div>}

      <FlexBox className={classes.textBlock} direction="column">
        <span className={classes.title}>{props.title}</span>
        <span className={classes.text}>{props.text}</span>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(RadarBriefItem);
