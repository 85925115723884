import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { FilterList as ContentFilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toCODTransferFilter } from "../../helpers/CODTransferFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CODTransferStatus, { PENDING } from "../../constants/CODTransferStatus";
import {
  CUSTOMER_ITEM_URL,
  FINANCE_WITHDRAW_ITEM_URL,
} from "../../constants/AdminPathConstants";
import {
  deleteCODWithdraw,
  getCODWithdrawList,
  uploadReferenceTemplate,
  getCODWithdrawStatusCount,
} from "../../api/admin/AdminFinanceApi";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import CODWithdrawTable from "../../components/finance-core/CODWithdrawTable";
import CODWithdrawTableTabs from "../../components/finance-core/CODWithdrawTableTabs";
import DeleteCODWithdrawDialog from "../../components/finance-core/DeleteCODWithdrawDialog";
import CODWithdrawListFilterDialog from "../../components/finance-core/CODWithdrawListFilterDialog";
import UploadWithdrawableReferenceTemplate from "../../components/finance-core/UploadWithdrawableReferenceTemplate";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_FINANCE_PENDING_WITHDRAW_URL,
  CREATE_WITHDRAWABLE_REFERENCE_TEMPLATE_URL,
  CREATE_COD_WITHDRAW_CASH_COLLECTION_REPORT_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

const createCustomerHref = id => CUSTOMER_ITEM_URL + id;
const createCODWithdrawHref = id => FINANCE_WITHDRAW_ITEM_URL + id;

const FILTER_DIALOG_HASH = "#FDH";
const UPLOAD_REFERENCE_TEMPLATE_HASH = "#URTH";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "5px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    replaceLocationHash: PropTypes.func,
    setLocationQuery: PropTypes.func,
    setLocationQueryFilter: PropTypes.func,
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toCODTransferFilter))
      .distinctUntilChanged(isEqualData);

    const statusCountsStream = filterStream
      .map((filter: DataListFilter) =>
        filter.setValueMap({
          page: null,
          status: null,
          order_by: null,
          order_by_direction: null,
        }),
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        getCODWithdrawStatusCount(filter)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          fp.get("payload.data"),
          fp.keyBy("cod_transfer_status"),
          fp.mapValues("count"),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const responseStream = filterStream
      .switchMap(filter =>
        getCODWithdrawList(filter)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        responseStream,
        statusCountsStream,
        (props, filter, response, statusCounts) => ({
          ...props,
          filter,
          statusCounts,
          onRequestRefresh,
          list: response.get("payload"),
          count: response.get("total"),
          isLoading: response.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminCODWithdrawListContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  statusCounts: PropTypes.instanceOf(Map),
  list: PropTypes.instanceOf(List),
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCODWithdrawListContainer(props) {
  const { location, classes, getLocalisationMessage } = props;
  const removeId = fp.toFinite(location.query.remove);
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      slug="customer_cod_transfers"
      title={getLocalisationMessage(
        "customer_cod_transfers",
        "Customer Cod Transfers",
      )}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      <Redirect
        when={!CODTransferStatus.has(location.query.status)}
        to={updateQuery(location, fp.set("status", CODTransferStatus.first()))}
      />

      <CODWithdrawListFilterDialog
        open={location.hash === FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
      />

      <DeleteCODWithdrawDialog
        open={removeId > 0}
        onSubmit={() => deleteCODWithdraw(removeId).catch(ResponseError.throw)}
        onRequestClose={() => props.setLocationQuery(fp.omit(["remove"]))}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );

          props.onRequestRefresh();
          props.setLocationQuery(fp.omit(["remove"]));
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
          props.setLocationQuery(fp.omit(["remove"]));
        }}
      />

      {location.hash === UPLOAD_REFERENCE_TEMPLATE_HASH && (
        <UploadWithdrawableReferenceTemplate
          open={true}
          onSubmit={fp.flow(
            values => uploadReferenceTemplate(values.file),
            request => request.toPromise().catch(ResponseError.throw),
          )}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_uploaded",
                "Successfully Uploaded",
              ),
            );

            props.onRequestRefresh();
            props.replaceLocationHash(null);
          }}
          onSubmitFail={error => {
            props.showErrorMessage(error);
            props.replaceLocationHash(null);
          }}
        />
      )}

      <CODWithdrawTableTabs
        location={location}
        statusCounts={props.statusCounts}
      />

      <CODWithdrawTable
        createCustomerHref={createCustomerHref}
        createCODWithdrawHref={createCODWithdrawHref}
        list={props.list}
        count={props.count}
        isLoading={props.isLoading}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        onRemoveClick={id => props.setLocationQuery(fp.set("remove", id))}
        cardActionIcons={
          <div>
            <Tooltip title={getLocalisationMessage("filter", "Filter")}>
              <IconButton
                onClick={() => props.replaceLocationHash(FILTER_DIALOG_HASH)}
              >
                <ContentFilterList />
              </IconButton>
            </Tooltip>

            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_WITHDRAWABLE_REFERENCE_TEMPLATE_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage(
                  "download_reference_template",
                  "Download Reference Template",
                )}
              </MenuItem>

              {props.filter.getValue("status") === PENDING && (
                <MenuItem
                  target="_blank"
                  component="a"
                  href={updateQuery(CREATE_FINANCE_PENDING_WITHDRAW_URL)}
                >
                  {getLocalisationMessage(
                    "downland_approval_report",
                    "Download Approval Report",
                  )}
                </MenuItem>
              )}

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(UPLOAD_REFERENCE_TEMPLATE_HASH)
                }
              >
                {getLocalisationMessage(
                  "upload_bank_reference",
                  "Upload Bank Reference",
                )}
              </MenuItem>

              {Boolean(
                props.filter.getValue("from_date") &&
                  props.filter.getValue("to_date"),
              ) && (
                <MenuItem
                  target="_blank"
                  component="a"
                  href={updateQuery(
                    CREATE_COD_WITHDRAW_CASH_COLLECTION_REPORT_URL,
                    props.filter.getDefinedValues(),
                  )}
                >
                  {getLocalisationMessage(
                    "cash_collection_report",
                    "Cash Collection Report",
                  )}
                </MenuItem>
              )}
            </MenuButtonMore>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminCODWithdrawListContainer);
