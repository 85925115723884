import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import {
  ArrowDownward,
  ArrowUpward,
  Done,
  GetApp,
  HourglassEmpty,
  KeyboardArrowLeft,
  Print,
} from "@material-ui/icons";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import {
  FORM16_URL,
  FORM24_URL,
  getBatchOrderUpdateItem2,
} from "../../api/admin/AdminBatchApi";
import PageLoading from "../../components/ui-core/PageLoading";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import {
  ButtonGroup,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getPDF } from "../../helpers/FormUtils";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { formatDateTimeToUrl, formatTime } from "../../helpers/FormatUtils";
import { getValue } from "../../helpers/DataUtils";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles({
  step: {
    padding: "10px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",

    backgroundColor: "cream",
  },

  vStepper: {
    position: "relative",
    /*   visibility: visible, */
  },

  //  step.circle
  circle: {
    backgroundColor: "white",
    border: "3px solid gray",
    borderRadius: "100%",
    width: "20px" /* +6 for border */,
    height: "20px",
    display: "inline-block",
  },
  //  step.line
  line: {
    top: "20px",
    left: "9px",
    /*   height: 120px, */
    height: "100%",

    position: "absolute",
    borderLeft: "3px solid gray",
  },

  // step.completed .
  completedcircle: {
    visibility: "visible",
    backgroundColor: "rgb(6,150,215)",
    borderColor: "rgb(6,150,215)",
  },

  // step.completed .
  completedline: {
    borderLeft: "3px solid rgb(6,150,215)",
  },

  // step.active .
  activecircle: {
    visibility: "visible",
    borderColor: "rgb(6,150,215)",
  },

  // step:last-child .
  lastline: {
    borderLeft: "3px solid white",
    zIndex: "-1", //* behind the circle to completely hide */
  },

  content: {
    marginLeft: "20px",
    display: "flex",
    alignItems: "center",
  },
});
const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

RouteItem.propTypes = {
  params: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  router: PropTypes.object,
  showErrorMessage1: PropTypes.func,
};

function RouteItem({
  getLocalisationMessage,
  params,
  router,
  showErrorMessage1,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [route, setRoute] = useState({});
  const [form16, setForm16] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM16_URL,
  });
  const [form24, setForm24] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM24_URL,
  });
  useEffect(
    () =>
      getBatchOrderUpdateItem2(params.id)
        .then((res) => setRoute(res.data))
        .catch((error) => showErrorMessage1(error))
        .finally(() => setIsLoading(false)),
    [],
  );
  return (
    <AdminAppLayout
      title={`${getLocalisationMessage("route", "Route")} - ${params.id}`}
    >
      <FlexBox
        style={{ background: "white", padding: "2rem" }}
        direction="column"
        align={ALIGN_CENTER}
      >
        <PageLoading isLoading={isLoading} />
        <FlexBox
          style={{
            width: "100%",
            fontSize: "1.2rem",
            justifyContent: "space-evenly",
            marginBottom: "2rem",
          }}
        >
          <FlexBox>
            <span>{getLocalisationMessage("date")} :&nbsp;</span>
            <strong>{formatDateTimeToUrl(route.created_date, "-")}</strong>
          </FlexBox>
          <FlexBox>
            <span>{getLocalisationMessage("route_name")} :&nbsp;</span>
            <strong>{route.name}</strong>
          </FlexBox>
          <FlexBox>
            <span>{getLocalisationMessage("status")} :&nbsp;</span>
            <strong>
              {getLocalisationMessage(
                route.completed
                  ? getLocalisationMessage("completed")
                  : route.status,
              )}
            </strong>
          </FlexBox>
        </FlexBox>
        <FlexBox
          style={{
            width: "100%",
            fontSize: "1.2rem",
            justifyContent: "space-evenly",
            marginBottom: "2rem",
          }}
        >
          <FlexBox>
            <span>{getLocalisationMessage("courier")} :&nbsp;</span>
            <strong>
              {getLocalisationMessage(getValue(route, "driver.name"))}
            </strong>
          </FlexBox>
          <FlexBox>
            <span>{getLocalisationMessage("vehicle")} :&nbsp;</span>
            <strong>
              {getLocalisationMessage(getValue(route, "vehicle.name"))}
            </strong>
          </FlexBox>
          <FlexBox>
            <span>{getLocalisationMessage("transportation_type")} :&nbsp;</span>
            <strong>
              {getLocalisationMessage(getValue(route, "transportation_type"))}
            </strong>
          </FlexBox>
        </FlexBox>

        <FlexBox direction="column">
          {2 === 3 &&
            route.route_points &&
            route.route_points.map((v, i) => (
              <div key={i} className={`${classes.step}`}>
                <div className={`${classes.vStepper}`}>
                  <div
                    className={`${classes.circle} ${
                      v.processed && classes.completedcircle
                    }`}
                  />
                  <div
                    className={`${classes.line} ${
                      v.processed && classes.completedline
                    } ${
                      route.route_points &&
                      route.route_points.length - 1 === i &&
                      classes.lastline
                    }`}
                  />
                </div>

                <div className={`${classes.content}`}>
                  <span style={{ marginRight: "1rem" }}>
                    {v.warehouse.name}
                  </span>
                  {v.processed ? (
                    <Done color="secondary" />
                  ) : (
                    <HourglassEmpty style={{ color: "orange" }} />
                  )}
                </div>
              </div>
            ))}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{getLocalisationMessage("number")}</TableCell>
                  <TableCell>{getLocalisationMessage("status")}</TableCell>
                  <TableCell>{getLocalisationMessage("warehouse")}</TableCell>
                  <TableCell align="center">
                    {getLocalisationMessage("time_of_arrival_actual")}
                  </TableCell>
                  <TableCell align="center">
                    {getLocalisationMessage("time_of_arrival_planned")}
                  </TableCell>
                  <TableCell align="center">
                    {getLocalisationMessage("time_of_departure_actual")}
                  </TableCell>
                  <TableCell align="center">
                    {getLocalisationMessage("time_of_departure_planned")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {route.route_points &&
                  route.route_points.map((v, i) => (
                    <TableRow key={v.name}>
                      <TableCell component="th" scope="v">
                        {i + 1}
                      </TableCell>
                      <TableCell align="center">
                        {v.processed ? (
                          <Done color="secondary" />
                        ) : (
                          <HourglassEmpty style={{ color: "orange" }} />
                        )}
                      </TableCell>
                      <TableCell align="center">{v.warehouse.name}</TableCell>
                      <TableCell align="center">
                        {v.time_of_arrival_actual ? (
                          v.time_of_arrival_actual >
                          v.time_of_arrival_planned ? (
                            <ArrowUpward style={{ color: red[500] }} />
                          ) : (
                            <ArrowDownward style={{ color: green[500] }} />
                          )
                        ) : null}
                        {formatTime(v.time_of_arrival_actual, "-")}
                      </TableCell>
                      <TableCell align="center">
                        {formatTime(v.time_of_arrival_planned, "-")}
                      </TableCell>
                      <TableCell align="center">
                        {v.time_of_departure_actual ? (
                          v.time_of_departure_actual >
                          v.time_of_departure_planned ? (
                            <ArrowUpward style={{ color: red[500] }} />
                          ) : (
                            <ArrowDownward style={{ color: green[500] }} />
                          )
                        ) : null}
                        {formatTime(v.time_of_departure_actual, "-")}
                      </TableCell>
                      <TableCell align="center">
                        {formatTime(v.time_of_departure_planned, "-")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FlexBox>
        <FlexBox style={{ marginTop: "2rem" }}>
          <CustomButton
            style={{ marginRight: "1rem" }}
            onClick={() => router.goBack()}
            startIcon={<KeyboardArrowLeft />}
            variant={OUTLINED}
            color={SECONDARY}
          >
            {getLocalisationMessage("back", "Back")}
          </CustomButton>
          <ButtonGroup
            variant={OUTLINED}
            style={{ marginRight: "1rem" }}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF(
                  form16,
                  setForm16,
                  IS_PRINTING,
                  route.id,
                  showErrorMessage1,
                )
              }
              endIcon={
                form16[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("route_nakladnoy")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  form16,
                  setForm16,
                  IS_DOWNLOADING,
                  route.id,
                  showErrorMessage1,
                )
              }
            >
              {form16[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
          <ButtonGroup
            variant={OUTLINED}
            style={{ marginRight: "1rem" }}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF(
                  form24,
                  setForm24,
                  IS_PRINTING,
                  route.id,
                  showErrorMessage1,
                )
              }
              endIcon={
                form24[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("forma24")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  form24,
                  setForm24,
                  IS_DOWNLOADING,
                  route.id,
                  showErrorMessage1,
                )
              }
            >
              {form24[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(RouteItem);
