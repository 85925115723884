import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

//
// Order Upload
//

const ORDER_UPLOAD_LIST_URL = `${API_ROOT_URL}/admin/orders/imported_list`;
const UPLOAD_DOMESTIC_ORDERS_URL = `${API_ROOT_URL}/admin/orders/upload_csv`;
const UPLOAD_DOMESTIC_ORDERS_URL_V2 = `${API_V2_ROOT_URL}/admin/orders/upload_csv`;

const DOMESTIC_ORDERS_UPLOAD_URL = `${API_V2_ROOT_URL}/admin/orders/progress_order_validation/%s`;
const UPLOAD_INTERNATIONAL_ORDERS_URL = `${API_ROOT_URL}/admin/orders/upload_international_csv`;

const UPLOAD_INTERNATIONAL_ORDERS_URL_V2 = `${API_V2_ROOT_URL}/admin/orders/upload_international_csv`;

const OLD_PUBLISH_ORDER_UPLOAD_URL = `${API_ROOT_URL}/admin/orders/create_orders`;

const NEW_PUBLISH_ORDER_UPLOAD_URL = `${API_ROOT_URL}/admin/orders/create_orders_new`;

const PUBLISH_ORDER_UPLOAD_URL_V2 = `${API_V2_ROOT_URL}/admin/orders/create_orders`;

const ORDER_UPLOAD_PUBLISH_PROGRESS_URL = `${API_ROOT_URL}/admin/orders/progress_create_order`;

const ORDER_UPLOAD_PUBLISH_PROGRESS_URL_V2 = `${API_V2_ROOT_URL}/admin/orders/progress_create_order`;

const ORDER_UPLOAD_RERUN_UPLOAD_URL = `${API_ROOT_URL}/admin/orders/rerun_create_orders`;
const ORDER_UPLOAD_RERUN_UPLOAD_V2_URL = `${API_V2_ROOT_URL}/admin/orders/rerun_create_orders`;

const ORDER_DELETE_URL = `${API_ROOT_URL}/admin/orders/imported_list_delete`;
const ORDER_CLEAR_URL = `${API_ROOT_URL}/admin/orders/imported_list_clear`;

export const deleteOrderUploadList = id =>
  api.delete(ORDER_DELETE_URL, { params: { id } });

export const clearOrderUploadList = customerId =>
  api.delete(ORDER_CLEAR_URL, { params: { customer_id: customerId } });

export const publishOrderUpload = (batchId: number, keepOrder) =>
  api.post(
    keepOrder ? OLD_PUBLISH_ORDER_UPLOAD_URL : NEW_PUBLISH_ORDER_UPLOAD_URL,
    { params: { batch_id: batchId } },
  );

export const publishOrderUploadV2 = (batchId: number) =>
  api.post(PUBLISH_ORDER_UPLOAD_URL_V2, { params: { batch_id: batchId } });

export const reRunOrderUpload = (batchId: number) =>
  api.post(ORDER_UPLOAD_RERUN_UPLOAD_URL, { params: { batch_id: batchId } });

export const reRunOrderUploadV2 = (batchId: number) =>
  api.post(ORDER_UPLOAD_RERUN_UPLOAD_V2_URL, { params: { batch_id: batchId } });

export const getOrderUploadPublishProgress = (batchId: number) =>
  api.getStream(ORDER_UPLOAD_PUBLISH_PROGRESS_URL, {
    params: { batch_id: batchId },
  });

export const getOrderUploadPublishProgressV2 = (batchId: number) =>
  api.getStream(ORDER_UPLOAD_PUBLISH_PROGRESS_URL_V2, {
    params: { batch_id: batchId },
  });

export const getSimpleOrderUploadPublishProgressV2 = (
  batchId: number,
  orderIds: [],
) =>
  api.get(ORDER_UPLOAD_PUBLISH_PROGRESS_URL_V2, {
    params: { batch_id: batchId, item_ids: orderIds },
  });

export const getOrderUploadList = customerId =>
  api.getStream(ORDER_UPLOAD_LIST_URL, { params: { customer_id: customerId } });
export const getOrderUploadList2 = params =>
  api.get(ORDER_UPLOAD_LIST_URL, { params });

export const uploadDomesticOrders = (customerId, file: File) =>
  api.postStream(UPLOAD_DOMESTIC_ORDERS_URL, {
    file,
    params: { customer_id: customerId },
  });

export const uploadDomesticOrdersV2 = (customerId, file: File) =>
  api.postStream(UPLOAD_DOMESTIC_ORDERS_URL_V2, {
    file,
    params: { customer_id: customerId },
  });

export const getOrderUpload = (batchId: number, request: DataListFilter) =>
  api.getStream(sprintf(DOMESTIC_ORDERS_UPLOAD_URL, batchId), {
    params: request.getDefinedValues(),
  });

export const uploadInternationalOrders = (customerId, file: File) =>
  api.postStream(UPLOAD_INTERNATIONAL_ORDERS_URL, {
    file,
    params: { customer_id: customerId },
  });

export const uploadInternationalOrdersV2 = (customerId, file: File) =>
  api.postStream(UPLOAD_INTERNATIONAL_ORDERS_URL_V2, {
    file,
    params: { customer_id: customerId },
  });

//
// Draft Order
//

const DRAFT_ORDER_ITEM_URL = `${API_ROOT_URL}/admin/orders/draft_order`;
const DRAFT_ORDER_LIST_URL = `${API_ROOT_URL}/admin/orders/draft_orders_list`;
const DRAFT_ORDER_V2_LIST_URL = `${API_V2_ROOT_URL}/admin/orders/draft_orders_list/%s`;
const UPDATE_DRAFT_ORDER_URL = `${API_ROOT_URL}/admin/orders/update_draft_orders`;
const UPDATE_DRAFT_ORDER_V2_URL = `${API_V2_ROOT_URL}/admin/orders/update_draft_orders`;
const UPDATE_DRAFT_BARCODE = `${API_V2_ROOT_URL}/admin/orders/update_draft_order_barcode`;

export const getDraftOrder = (draftId: number) =>
  api.getStream(DRAFT_ORDER_ITEM_URL, { params: { id: draftId } });
export const getDraftOrderList = (batchId: number) =>
  api.getStream(DRAFT_ORDER_LIST_URL, { params: { batch_id: batchId } });

export const getDraftOrderListV2 = (batchId: number, request: DataListFilter) =>
  api.getStream(sprintf(DRAFT_ORDER_V2_LIST_URL, batchId), {
    params: request.getDefinedValues(),
  });

export type DraftOrderField = {
  content: string,
  failed_field: { message: string },
};

export type DraftOrder = {
  id: number,
  status: "VALID" | "NOT_VALID",
  amount: DraftOrderField,

  service_type: DraftOrderField,
  special_instructions: DraftOrderField,
  value: DraftOrderField,
  weight_category: DraftOrderField,

  not_available: DraftOrderField,
  parcel_details: DraftOrderField,
  payment_done: DraftOrderField,
  payment_type: DraftOrderField,
  public_charge: DraftOrderField,

  reference: DraftOrderField,

  recipient_area: DraftOrderField,
  recipient_city: DraftOrderField,
  recipient_country: DraftOrderField,
  recipient_landmark: DraftOrderField,
  recipient_name: DraftOrderField,
  recipient_office: DraftOrderField,
  recipient_phone_code: DraftOrderField,
  recipient_phone_number: DraftOrderField,
  recipient_street: DraftOrderField,

  sender_area: DraftOrderField,
  sender_city: DraftOrderField,
  sender_country: DraftOrderField,
  sender_landmark: DraftOrderField,
  sender_name: DraftOrderField,
  sender_office: DraftOrderField,
  sender_phone_code: DraftOrderField,
  sender_phone_number: DraftOrderField,
  sender_street: DraftOrderField,
};

export const updateDraftOrder = (order: DraftOrder) =>
  api.post(UPDATE_DRAFT_ORDER_URL, { body: order });

export const updateDraftOrderV2 = (order: DraftOrder) =>
  api.post(UPDATE_DRAFT_ORDER_V2_URL, { body: order });

export const updateDraftBarcode = (id, barcode) =>
  api.post(UPDATE_DRAFT_BARCODE, { body: { id, barcode } });
