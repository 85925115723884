import { Observable } from "rxjs";
import React from "react";
import { Map, List, OrderedMap, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Tab, Tabs, CircularProgress, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { indigo } from "@material-ui/core/colors";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData, isEqualDataKeys } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { mergeFilterToFilter } from "../../helpers/FilterHelper";
import { defaultDriverOrdersFilter } from "../../helpers/RadarFilterMapper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { Refresh } from "@material-ui/icons";
import { isMarketplaceBusinessTypeFoodDelivery } from "../../reducers/MarketplaceReducer";

const styles = {
  tabsInkBar: {
    zIndex: 2,
    height: "4px",
    marginTop: "-4px",
    backgroundColor: indigo[500],
  },
};

const baseFilter = {
  page: 0,
  size: 1,
};

const getTabName = fp.flow(
  fp.split("?"),
  fp.last,
  fp.split("&"),
  fp.map(fp.split("=")),
  (x) => {
    let tab;

    fp.forEach((v) => {
      if (fp.first(v) === "tab") {
        tab = fp.last(v);
      }
    })(x);

    return tab || "all";
  },
  decodeURI,
);

const enhancer = compose(
  connect((state) => ({
    i18n: getMessages(state),
    isFoodDelivery: isMarketplaceBusinessTypeFoodDelivery(state),
  })),
  useSheet({
    tabs: {
      width: "100%",
    },
    loader: {
      height: "48px",
      backgroundColor: "transparent",
      margin: "auto",
    },
    hidden: {
      top: "-9999px",
      left: "-9999px",
      position: "absolute",
      visibility: "hidden",
    },
    buttonStyle: {
      backgroundColor: "white",
      color: "black",
      borderBottom: "1px solid #eee",
      "& > div > div": {
        position: "relative",
        paddingRight: "10px",
        "& > div": {
          "& span": {
            position: "absolute",
            top: "10px",
            backgroundColor: indigo[500],
            color: "white",
          },
        },
      },
    },
    active: {
      borderBottom: `3px solid ${indigo[500]}`,
    },
  }),
  withRouter,
  mapPropsStream(
    pipeStreams((propsStream) => {
      const tabsStream = propsStream
        .distinctUntilChanged(
          isEqualDataKeys([
            "filter",
            "basePathname",
            "batchGetDriverOrdersCount",
          ]),
        )
        .map((props) => {
          const filters = defaultDriverOrdersFilter.map((x) => {
            const filter = new DataListFilter(x.get("filter").toJS());
            const queryFilter = props.filter.setValueMap({
              tab: fp.snakeCase(x.get("name")),
            });

            const mergedFilter = mergeFilterToFilter(
              filter,
              queryFilter,
              toOrderFilter,
              OrderedSet.of("status"),
            );

            if (fp.snakeCase(x.get("name")) === "all") {
              return mergedFilter.setValue("status", null);
            }

            return mergedFilter;
          });

          let activeTab = null;
          filters.forEach((x) => {
            if (
              fp.snakeCase(x.getValue("tab")) ===
              (props.router.location.query.tab || "all")
            ) {
              activeTab = x;
            }
          });

          return {
            filters,
            activeTab,

            batchGetDriverOrdersCount: props.batchGetDriverOrdersCount,
            value: getTabName(props.router.location.search),

            tabs: filters.map((filter) => {
              const href = props.router.createHref({
                pathname: props.basePathname,
                query: filter.getDefinedValues(),
              });
              const value = getTabName(href);

              return Map({
                label: props.i18n.get(value, filter.get("label")),
                value,
                href,
                filter,
              });
            }),
          };
        })
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(tabsStream, (props, tabResponse) => ({
        ...props,
        tabs: tabResponse.tabs,
        filters: tabResponse.filters,
        value: tabResponse.value,
        activeTab: tabResponse.activeTab,
        batchGetDriverOrdersCount: tabResponse.batchGetDriverOrdersCount,
      }));
    }),
  ),
  mapPropsStream((propsStream) => {
    const requestRefresh = createEventHandler();

    const tabCountsStream = propsStream
      .map(
        fp.pick([
          "filters",
          "batchGetDriverOrdersCount",
          "getOrderList",
          "isFoodDelivery",
        ]),
      )
      .distinctUntilChanged(isEqualData)
      .switchMap((props) =>
        !props.batchGetDriverOrdersCount
          ? Observable.of(Map())
          : props
              .batchGetDriverOrdersCount(
                props.filters.map((x) => ({
                  filter: x.setValueMap(
                    props.isFoodDelivery
                      ? baseFilter
                      : {
                          ...baseFilter,
                          from_date_time: null,
                          to_date_time: null,
                        },
                  ),
                  getOrderList: props.getOrderList,
                })),
              )
              .repeatWhen(() => requestRefresh.stream),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(tabCountsStream, (props, tabCounts) => ({
        ...props,
        value: props.value,
        tabs: props.tabs.map((tab, label) =>
          tab.set("count", tabCounts.get(label)),
        ),
        onRequestDriverOrderFilterRefresh: requestRefresh.handler,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

RadarDriverOrderFilterTabs.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,

  filterList: PropTypes.instanceOf(OrderedMap),
  tabs: PropTypes.oneOfType([
    PropTypes.instanceOf(OrderedMap),
    PropTypes.instanceOf(List),
  ]),
  filters: PropTypes.instanceOf(List),

  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  activeTab: PropTypes.instanceOf(DataListFilter),

  orderItem: PropTypes.number,
  isLoading: PropTypes.bool,
  onFilterClick: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),

  showButtons: PropTypes.object,
  buttonNodeRef: PropTypes.func,
  containerNodeRef: PropTypes.func,
  batchGetDriverOrdersCount: PropTypes.func,
  onOrderRefreshRequest: PropTypes.func,
  onRequestDriverOrderFilterRefresh: PropTypes.func,
  getOrderList: PropTypes.func,
  isFoodDelivery: PropTypes.bool,
};

function RadarDriverOrderFilterTabs(props) {
  const { classes } = props;

  if (props.isLoading)
    return (
      <FlexBox flex={true}>
        <FlexBox
          align="center"
          justify={JUSTIFY_CENTER}
          className={classes.loader}
        >
          <CircularProgress color="#2E3359" size={24} thickness={2} />
        </FlexBox>
      </FlexBox>
    );

  return (
    <FlexBox flex={true}>
      <FlexBox flex={true}>
        <Tabs
          className={classes.tabs}
          inkBarStyle={styles.tabsInkBar}
          onChange={(e, v) => props.onFilterClick(v)}
          value={props.activeTab}
        >
          {props.tabs
            .map((x, key) => (
              <Tab
                key={key}
                value={x.get("filter")}
                label={
                  <TabBadge label={x.get("label")} badge={x.get("count")} />
                }
                className={classes.buttonStyle}
                buttonStyle={{
                  lineHeight: "24px",
                }}
              />
            ))
            .toList()}
        </Tabs>
        <IconButton
          onClick={() => {
            props.onRequestDriverOrderFilterRefresh();
            props.onOrderRefreshRequest();
          }}
        >
          <Refresh />
        </IconButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(RadarDriverOrderFilterTabs);
