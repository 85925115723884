import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import {
  FieldArray,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Add, Close, Delete, DoneAll } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import PackageRuleTreeBranch from "./PackageRuleTreeBranch";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_END,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import PageFab from "../ui-core/PageFab";
import PageLoading from "../ui-core/PageLoading";
import { isEmpty, isEqualData, toArray, toJS } from "../../helpers/DataUtils";
import { safeParseDate, shortTimeToDate } from "../../helpers/DateUtils";
import {
  createNotFalsyValidator,
  createNotNilValidator,
  createObjectIdValidator,
  getObjectId,
} from "../../helpers/FormUtils";
import {
  formatText,
  formatTime,
  parseInteger,
  parseOnlyPositiveFloat,
} from "../../helpers/FormatUtils";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import { CUSTOM_PACKAGE } from "../../constants/RuleType";
import MarginTypes from "../../constants/MarginTypes";
import { REGULAR } from "../../constants/LogisticTypes";
import { ONLY_KG, ONLY_KM } from "../../constants/MatchModeTypes";
import {
  CITY_FROM,
  CITY_TO,
  COD,
  COMPANY,
  COST,
  COUNTRY_FROM,
  COUNTRY_TO,
  COURIER_TYPE,
  CREATED_DATE,
  CREATED_TIME,
  CURRENT_WAREHOUSE,
  CUSTOMER,
  DESTINATION_WAREHOUSE,
  HAS_FETCH_ITEMS,
  IS_RETURN,
  ITEM_VALUE,
  JURISDICTION_FROM,
  JURISDICTION_TO,
  LIMIT,
  MERCHANT,
  PARCEL_SIZE,
  PICKUP_WAREHOUSE,
  POSTCODE_FROM,
  POSTCODE_TO,
  PRICE,
  RATING,
  RECIPIENT_ADDRESS_TYPE,
  RTO_PRICE,
  SELLER_ID,
  SELLER_NAME,
  SENDER_ADDRESS_TYPE,
  SENDER_NAME,
  TIME_SLOT_DROP_OFF,
  TIME_SLOT_PICK_UP,
} from "../../constants/RuleConditionField";
import {
  LOCKER_POST,
  lockerPostType,
  SIMPLE,
  TERRESTRIAL,
  transportationTypeForPackageRule,
} from "../../constants/TransportationType";
import UpperOverallStatus, { ACTIVE } from "../../constants/UpperOverallStatus";
import packagePriceTypes, {
  BUCKET_PRICE,
  LINEAR_PRICE,
} from "../../constants/PackageRulesPriceType";
import { AND, OR } from "../../constants/RuleConditionalOperator";
import { getPackageRuleMatchModes } from "../../api/admin/AdminPackageRulesApi";
import { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { ALL, MerchantTypeForPackage } from "../../constants/ClientType";
import FormJurisdictionLevelAutoComplete from "../form/FormJurisdictionLevelAutoComplete";
import FormCheckbox from "../form/FormCheckbox";
import { ACTION, COLUMN, RENDER } from "../orders-core/MUITable";
import OperatorsMUITable from "../orders-core/OperatorsMUITable";
import DeliveryType from "../../constants/DeliveryType";
import FormOperatorAutoComplete from "../form/FormOperatorAutoComplete";
import FormCustomToggle from "../form/FormCustomToggle";

const valueSelector = formValueSelector("PackageRuleTree");

export function formatTransportationTypesLocalised(
  code,
  i18n,
  notAvailableValue = "N/A",
) {
  switch (code) {
    case SIMPLE:
      return (i18n && i18n.get("simple_type", "Simple")) || "Simple";

    default:
      return (i18n && i18n.get(code)) || formatText(code) || notAvailableValue;
  }
}

const PackageBucketPricesBranch = ({ classes, ...props }) => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((suppliers, index, row) => (
        <FlexBox flex={true} key={index}>
          <div className={classes.addPriceRow}>
            <FlexBox gutter={16} flex={true} direction="column">
              <FlexBox className={classes.flexWrap} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get("base_price", "Base Price")} *`}
                    name={`${suppliers}.base`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_base_price", "Enter Base Price"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    className={classes.matchMode}
                    name={`${suppliers}.match_mode`}
                    fullWidth={true}
                    label={props.i18n.get("match_mode", "Match mode")}
                    options={props.matchMode}
                    disabled={MarginTypes.isEmpty()}
                    formatOption={x =>
                      props.i18n.get(x.toLowerCase(), formatText(x))
                    }
                    validate={createNotNilValidator(
                      props.i18n.get("select_match_mode", "Select Match mode"),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={
                      row.get(index).match_mode === ONLY_KM
                        ? `${props.i18n.get("kg_included", "Kg Included")} *`
                        : `${props.i18n.get(
                            "weight_from_kg",
                            "Weight from (KG)",
                          )} *`
                    }
                    name={`${suppliers}.kg_included`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_weight", "Enter Weight"),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("kg", "kg")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                {row.get(index).match_mode === ONLY_KM || (
                  <FlexBox flex={true} direction="column">
                    <FormTextField
                      fullWidth={true}
                      parseOnBlur={parseOnlyPositiveFloat}
                      label={`${props.i18n.get(
                        "weight_to_kg",
                        "Weight to (KG)",
                      )} *`}
                      name={`${suppliers}.kg_max`}
                      validate={createNotNilValidator(
                        props.i18n.get("enter_weight", "Enter Weight"),
                      )}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {props.i18n.get("kg", "kg")}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FlexBox>
                )}

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={
                      row.get(index).match_mode === ONLY_KG
                        ? `${props.i18n.get("km_included", "Km Included")} *`
                        : `${props.i18n.get(
                            "kilometer_from_km",
                            "Kilometer from (KM)",
                          )} *`
                    }
                    name={`${suppliers}.km_included`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_km_included", "Enter KM Included"),
                    )}
                  />
                </FlexBox>
                {row.get(index).match_mode === ONLY_KG || (
                  <FlexBox flex={true} direction="column">
                    <FormTextField
                      fullWidth={true}
                      parseOnBlur={parseInteger}
                      label={`${props.i18n.get(
                        "kilometer_to_km",
                        "Kilometer to (KM)",
                      )} *`}
                      name={`${suppliers}.km_max`}
                      validate={createNotNilValidator(
                        props.i18n.get(
                          "enter_km_included",
                          "Enter KM Included",
                        ),
                      )}
                    />
                  </FlexBox>
                )}

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "price_per_kg",
                      "Price per KG",
                    )} *`}
                    name={`${suppliers}.per_kg`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_price_per_kg",
                        "Enter Price per KG",
                      ),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("kg", "kg")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    type="number"
                    label={`${props.i18n.get("importance", "Importance")} *`}
                    name={`${suppliers}.weight`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_weight", "Enter Weight"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "weight_precision",
                      "Weight precision",
                    )} *`}
                    name={`${suppliers}.weight_precision`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_weight", "Enter Weight"),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("g", "g")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "округления_веса",
                      "округления веса",
                    )} *`}
                    name={`${suppliers}.margin_of_error`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_weight", "Enter Weight"),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("g", "g")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "price_per_km",
                      "Price per KM",
                    )} *`}
                    name={`${suppliers}.per_km`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_price_per_km",
                        "Enter Price per KM",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "base_rto_price",
                      "Base RTO Price",
                    )} *`}
                    name={`${suppliers}.rto_base`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_base_rto_price",
                        "Enter Base RTO Price",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "rto_price_per_kg",
                      "RTO Price per KG",
                    )} *`}
                    name={`${suppliers}.rto_per_kg`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_rto_price_per_kg",
                        "Enter RTO Price per KG",
                      ),
                    )}
                  />
                </FlexBox>
                <FlexBox
                  className={classes.removeWrap}
                  direction="column"
                  justify="center"
                >
                  <IconButton onClick={() => props.fields.remove(index)}>
                    <Close color={red[500]} />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </div>
        </FlexBox>
      ))}
    </FlexBox>
    <FlexBox justify="center">
      <Button
        endIcon={<Add />}
        variant={CONTAINED}
        color={SECONDARY}
        className={classes.addPriceButton}
        onClick={() => props.fields.push({ weight: 1, match_mode: ONLY_KG })}
      >
        {props.i18n.get("add_prices", "Add Prices")}
      </Button>
    </FlexBox>
  </FlexBox>
);

PackageBucketPricesBranch.propTypes = {
  fields: PropTypes.object,
  classes: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
  matchMode: PropTypes.instanceOf(List),
  theme: PropTypes.object,
};

const PackageLinearPricesBranch = ({ classes, ...props }) => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((suppliers, index) => (
        <FlexBox flex={true} key={index}>
          <div className={classes.addPriceRow}>
            <FlexBox gutter={16} flex={true} direction="column">
              <FlexBox className={classes.flexWrap} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get("base_price", "Base Price")} *`}
                    name={`${suppliers}.base`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_base_price", "Enter Base Price"),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get("kg_included", "KG Included")} *`}
                    name={`${suppliers}.kg_included`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_kg_included", "Enter KG Included"),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("kg", "kg")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={`${props.i18n.get("km_included", "KM Included")} *`}
                    name={`${suppliers}.km_included`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_km_included", "Enter KM Included"),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "price_per_kg",
                      "Price per KG",
                    )} *`}
                    name={`${suppliers}.per_kg`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_price_per_kg",
                        "Enter Price per KG",
                      ),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("kg", "kg")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    type="number"
                    label={`${props.i18n.get("importance", "Importance")} *`}
                    name={`${suppliers}.weight`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_weight", "Enter Weight"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "weight_precision",
                      "Weight precision",
                    )} *`}
                    name={`${suppliers}.weight_precision`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_weight", "Enter Weight"),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("g", "g")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "округления_веса",
                      "округления веса",
                    )} *`}
                    name={`${suppliers}.margin_of_error`}
                    validate={createNotNilValidator(
                      props.i18n.get("enter_weight", "Enter Weight"),
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.i18n.get("g", "g")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "price_per_km",
                      "Price per KM",
                    )} *`}
                    name={`${suppliers}.per_km`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_price_per_km",
                        "Enter Price per KM",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "base_rto_price",
                      "Base RTO Price",
                    )} *`}
                    name={`${suppliers}.rto_base`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_base_rto_price",
                        "Enter Base RTO Price",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={`${props.i18n.get(
                      "rto_price_per_kg",
                      "RTO Price per KG",
                    )} *`}
                    name={`${suppliers}.rto_per_kg`}
                    validate={createNotNilValidator(
                      props.i18n.get(
                        "enter_rto_price_per_kg",
                        "Enter RTO Price per KG",
                      ),
                    )}
                  />
                </FlexBox>
                <FlexBox
                  className={classes.removeWrap}
                  direction="column"
                  justify="center"
                >
                  <IconButton onClick={() => props.fields.remove(index)}>
                    <Close color={red[500]} />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </div>
        </FlexBox>
      ))}
    </FlexBox>

    {props.fields && props.fields.length < 1 && (
      <FlexBox justify="center">
        <Button
          endIcon={<Add />}
          variant={CONTAINED}
          color={SECONDARY}
          className={classes.addPriceButton}
          onClick={() =>
            props.fields.length < 1 && props.fields.push({ weight: 1 })
          }
        >
          {props.i18n.get("add_prices", "Add Prices")}
        </Button>
      </FlexBox>
    )}
  </FlexBox>
);

PackageLinearPricesBranch.propTypes = {
  fields: PropTypes.object,
  classes: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
  theme: PropTypes.object,
};

export const isObjectField = field => {
  switch (field) {
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case COUNTRY_FROM:
    case COUNTRY_TO:
    case CITY_TO:
    case CITY_FROM:
    case POSTCODE_FROM:
    case POSTCODE_TO:
    case JURISDICTION_FROM:
    case JURISDICTION_TO:
      return true;

    default:
      return false;
  }
};

export const isNameField = field => {
  switch (field) {
    case SELLER_ID:
    case SELLER_NAME:
    case SENDER_NAME:
    case COURIER_TYPE:
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case MERCHANT:
    case HAS_FETCH_ITEMS:
    case IS_RETURN:
    case COD:
    case COST:
    case PRICE:
    case RATING:
    case PARCEL_SIZE:
    case RTO_PRICE:
    case RECIPIENT_ADDRESS_TYPE:
    case SENDER_ADDRESS_TYPE:
      return true;

    default:
      return false;
  }
};

export const isDateField = field => {
  switch (field) {
    case CREATED_DATE:
      return true;

    default:
      return false;
  }
};

export const isTimeField = field => {
  switch (field) {
    case CREATED_TIME:
      return true;

    default:
      return false;
  }
};

export const getFieldFieldKey = field => {
  switch (field) {
    case LIMIT:
    case CITY_FROM:
    case CITY_TO:
    case COUNTRY_FROM:
    case COUNTRY_TO:
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case ITEM_VALUE:
    case POSTCODE_FROM:
    case POSTCODE_TO:
    case JURISDICTION_FROM:
    case JURISDICTION_TO:
      return "whole_number_value";

    case COD:
    case COST:
    case PRICE:
    case RATING:
    case RTO_PRICE:
      return "fraction_number_value";

    case MERCHANT:
    case HAS_FETCH_ITEMS:
    case IS_RETURN:
      return "boolean_value";

    case CREATED_DATE:
      return "date_value";

    default:
      return "string_value";
  }
};

const getFieldValue = item => {
  const value = item[`${item.field}-value`];

  return isObjectField(item.field) ? value.id : value;
};
const useStyles = makeStyles({
  matchMode: { "&>div>div>div:nth-child(2)": { overflow: "visible" } },
  parentRule: {
    " & > h6": {
      fontWeight: "bold",
    },
    " & > p": {
      marginBottom: 0,
    },
  },
  addPriceRow: {
    width: "100%",
    // height: "100px",
    marginTop: "10px",
    " & > div>div>div>div>label": {
      fontSize: "13px",
      width: "80%",
    },
    " & > div>div>div>div>div": { width: "90%" },
  },
  addPriceButton: {
    marginTop: "10px",
    color: "white",
  },
  removeWrap: {
    maxWidth: "2.425rem",
    maxHeight: "2.425rem",
  },
  fullWidth: {
    flex: "0 1 auto",
    maxWidth: "68%",
    textTransform: "capitalize",
  },
  dots: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    flex: 1,
    padding: "0 .5rem",
  },
  flexWrap: {
    flexWrap: "wrap",
    " & > div": { margin: ".5rem 0" },
    "@media (min-width:320px)": {
      " & > div": { flex: "1 0 13%" },
    },
    "@media (min-width:1920px)": {
      " & > div": { flex: "1 0 6.6%" },
    },
    "@media (min-width:2400px)": {
      " & > div": { flex: "1 1 0%" },
    },
  },
});
const enhancer = compose(
  withContext(
    {
      getWarehousePolygons: PropTypes.func.isRequired,
      getNeighborhoodPolygons: PropTypes.func.isRequired,

      getCachedRule: PropTypes.func.isRequired,
      getRulePredictions: PropTypes.func.isRequired,
      getChildRulePredictions: PropTypes.func.isRequired,
      getCachedCustomer: PropTypes.func.isRequired,
      getCustomerPredictions: PropTypes.func.isRequired,
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
      getCachedDriver: PropTypes.func.isRequired,
      getDriverPredictions: PropTypes.func.isRequired,
      getCachedTimeSlot: PropTypes.func.isRequired,
      getTimeSlotPredictions: PropTypes.func.isRequired,
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
      getCachedUser: PropTypes.func.isRequired,
      getOperatorPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getWarehousePolygons: props.getWarehousePolygons,
      getNeighborhoodPolygons: props.getNeighborhoodPolygons,

      getCachedRule: props.getCachedRule,
      getRulePredictions: props.getRulePredictions,
      getChildRulePredictions: props.getChildRulePredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedTimeSlot: props.getCachedTimeSlot,
      getTimeSlotPredictions: props.getTimeSlotPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
      getCachedUser: props.getCachedUser,
      getOperatorPredictions: props.getOperatorPredictions,
    }),
  ),
  withTheme,
  connect(state => ({
    marketplaceId: getMarketplaceId(state),
    i18n: getMessages(state),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("tree", isEqualData)
      .map(props => {
        const { tree } = props;

        if (!tree) {
          return {
            ...props.initialValues,
            priority: 0,
            ruleStatus: ACTIVE,
            ruleType: CUSTOM_PACKAGE,
            priceType: LINEAR_PRICE,
            logisticType: REGULAR,
            transportation_type: TERRESTRIAL,
            notification_fee: 0,
          };
        }

        const mapFromCondition = group => {
          const isLeaf = Boolean(
            group &&
              group.get("conditions") &&
              !group.get("conditions").isEmpty(),
          );
          const isBranch = Boolean(
            group &&
              group.get("condition_groups") &&
              !group.get("condition_groups").isEmpty(),
          );

          const item = { isLeaf: false, expanded: true, leaf: [], branch: [] };

          if (isLeaf) {
            item.isLeaf = true;
            item.overAny =
              group.getIn(["conditions", 0, "conditional_operator"]) !== AND;

            group
              .get("conditions")
              .sortBy(v => v.get("priority"))
              .forEach(condition => {
                const field = condition.get("field");
                const value = condition.get(getFieldFieldKey(field));

                item.leaf.push({
                  field,
                  comparationOperator: condition.get("comparison_operator"),
                  [`${field}-value`]: isObjectField(field)
                    ? { id: value }
                    : isTimeField(field)
                    ? shortTimeToDate(value)
                    : isDateField(field)
                    ? safeParseDate(value)
                    : isNameField(field)
                    ? value
                    : _.toUpper(value),
                });
              });
          }

          if (isBranch) {
            item.overAny =
              group.getIn(["condition_groups", 0, "conditional_operator"]) !==
              AND;

            group
              .get("condition_groups")
              .sortBy(v => v.get("priority"))
              .forEach(childGroup => {
                item.branch.push(mapFromCondition(childGroup));
              });
          }

          return item;
        };

        const mapLinearPrices = fp.flow(
          toArray,
          fp.map(item => ({
            id: item.id,
            base: item.base,
            per_kg: item.per_kg,
            weight: item.weight || 1,
            rto_base: tree.get("logistic_type") === REGULAR ? item.rto_base : 0,
            rto_per_kg:
              tree.get("logistic_type") === REGULAR ? item.rto_per_kg : 0,
            kg_included: item.kg_included,
            per_km: item.per_km,
            km_included: item.km_included,
            margin_type: item.margin_type,
            margin: item.margin,
            notification_fee: item.notification_fee || 0,
            customs_fee: item.customs_fee || 0,
            forwarding_fee: item.forwarding_fee || 0,
            cod_fee_percentage: item.cod_fee_percentage || 0,
            weight_precision: item.weight_precision || 0,
            margin_of_error: item.margin_of_error || 0,
          })),
        );

        const mapBucketPrices = fp.flow(
          toArray,
          fp.map(item => ({
            id: item.id,
            base: item.base,
            per_kg: item.per_kg,
            weight: item.weight || 1,
            rto_base: tree.get("logistic_type") === REGULAR ? item.rto_base : 0,
            rto_per_kg:
              tree.get("logistic_type") === REGULAR ? item.rto_per_kg : 0,
            kg_included: item.kg_included,
            per_km: item.per_km,
            km_included: item.km_included,
            km_max: item.km_max,
            kg_max: item.kg_max,
            margin_type: item.margin_type,
            margin: item.margin,
            match_mode: item.match_mode || ONLY_KG,
            notification_fee: item.notification_fee || 0,
            customs_fee: item.customs_fee || 0,
            forwarding_fee: item.forwarding_fee || 0,
            cod_fee_percentage: item.cod_fee_percentage || 0,
            weight_precision: item.weight_precision || 0,
            margin_of_error: item.margin_of_error || 0,
          })),
        );

        const priceType = tree.get("linear") ? LINEAR_PRICE : BUCKET_PRICE;
        const packageLinearPrices = mapLinearPrices(tree.get("package_prices"));
        const packageBucketPrices = mapBucketPrices(tree.get("package_prices"));
        const clientType = tree.get("client_type");
        return {
          id: tree.get("id"),
          name: tree.get("name"),
          priority: tree.get("priority"),
          ruleType: tree.get("rule_type"),
          ruleStatus: tree.get("rule_status"),
          description: tree.get("description"),
          description_ru: tree.get("description_ru"),
          description_en: tree.get("description_en"),
          courierType: tree.get("courier_type"),
          vehicleType: tree.get("vehicle_type"),
          orderRules: mapFromCondition(tree.get("condition_group")),
          supplier: { id: tree.get("supplier_id") },
          clientType: clientType || ALL,
          pay_with_stamp: tree.get("pay_with_stamp"),
          logisticType: tree.get("logistic_type"),
          priceType,
          packageLinearPrices,
          packageBucketPrices,
          transportation_type: tree.get("transportation_type"),
          lockerPostType: tree.get("locker_post_type"),
          delivery_type: tree.get("delivery_type"),
          customs_fee: tree.get("customs_fee"),
          forwarding_fee: tree.get("forwarding_fee"),
          cod_fee_percentage: tree.get("cod_fee_percentage"),
          notification_fee: tree.get("notification_fee"),
          with_notification: tree.get("with_notification"),
          operators: toJS(tree.get("operators")),
          is_private: tree.get("is_private"),
          within_jurisdiction_level: toJS(
            tree.get("within_jurisdiction_level"),
          ),
          within_jurisdiction: tree.get("within_jurisdiction"),
        };
      });

    const matchModeStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getPackageRuleMatchModes().catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(response => fromJS(response).getIn(["payload", "data"], List()));

    const handleSubmit = fp.curry((props, values) => {
      const mapToCondition = (item, priority = 0, overAny = false) => {
        const group = { priority, conditional_operator: overAny ? OR : AND };

        if (item.isLeaf) {
          group.conditions = _.map(item.leaf, (v, k) => ({
            priority: k,
            field: v.field,
            comparison_operator: v.comparationOperator,
            conditional_operator: item.overAny ? OR : AND,
            [getFieldFieldKey(v.field)]: isTimeField(v.field)
              ? formatTime(getFieldValue(v))
              : isNameField(v.field)
              ? getFieldValue(v)
              : _.toLower(getFieldValue(v)),
          }));
        } else {
          group.condition_groups = _.map(item.branch, (v, k) =>
            mapToCondition(v, k, item.overAny),
          );
        }

        return group;
      };

      const mapLinearPrices = fp.flow(
        fp.toArray,
        fp.map(item => ({
          id: item.id || 0,
          base: item.base,
          per_kg: item.per_kg,
          weight: item.weight || 1,
          rto_base: values.logisticType === REGULAR ? item.rto_base : 0,
          rto_per_kg: values.logisticType === REGULAR ? item.rto_per_kg : 0,
          kg_included: item.kg_included,
          per_km: item.per_km,
          km_included: item.km_included,
          margin_type: item.margin_type,
          margin: item.margin,
          notification_fee: item.notification_fee,
          customs_fee: item.customs_fee,
          forwarding_fee: item.forwarding_fee,
          cod_fee_percentage: item.cod_fee_percentage,
          weight_precision: item.weight_precision,
          margin_of_error: item.margin_of_error,
        })),
      );

      const mapBucketPrices = fp.flow(
        fp.toArray,
        fp.map(item => ({
          id: item.id || 0,
          base: item.base,
          per_kg: item.per_kg,
          weight: item.weight || 1,
          rto_base: values.logisticType === REGULAR ? item.rto_base : 0,
          rto_per_kg: values.logisticType === REGULAR ? item.rto_per_kg : 0,
          kg_included: item.kg_included,
          per_km: item.per_km,
          kg_max: item.kg_max,
          km_included: item.km_included,
          km_max: item.km_max,
          margin_type: item.margin_type,
          margin: item.margin,
          match_mode: item.match_mode || ONLY_KG,
          notification_fee: item.notification_fee,
          customs_fee: item.customs_fee,
          forwarding_fee: item.forwarding_fee,
          cod_fee_percentage: item.cod_fee_percentage,
          weight_precision: item.weight_precision,
          margin_of_error: item.margin_of_error,
        })),
      );

      const linear = values.priceType === LINEAR_PRICE;

      if (
        !values.within_jurisdiction &&
        isEmpty(values.orderRules.leaf) &&
        isEmpty(values.orderRules.branch) &&
        values.ruleStatus === ACTIVE
      ) {
        throw new SubmissionError({
          _error: props.i18n.get(
            "please_add_condition_rule",
            "Please add condition rule!",
          ),
        });
      }

      const rule = {
        id: values.id,
        name: values.name,
        rule_type: values.ruleType,
        rule_status: values.ruleStatus,
        description: values.description,
        description_ru: values.description_ru,
        description_en: values.description_en,
        assignment_type: values.assignmentType,
        priority: fp.toInteger(values.priority),
        package_prices: linear
          ? mapLinearPrices(values.packageLinearPrices)
          : mapBucketPrices(values.packageBucketPrices),
        courier_type: values.courierType,
        vehicle_type: values.vehicleType,
        client_type: values.clientType !== ALL && values.clientType,
        pay_with_stamp: values.pay_with_stamp,
        logistic_type: values.logisticType,
        locker_post_type: values.lockerPostType,
        supplier_id: getObjectId(values.supplier),
        condition_group: mapToCondition(values.orderRules),
        linear,
        transportation_type: values.transportation_type,
        delivery_type: values.delivery_type,
        customs_fee: values.customs_fee,
        forwarding_fee: values.forwarding_fee,
        cod_fee_percentage: values.cod_fee_percentage,
        notification_fee: values.notification_fee,
        with_notification: values.with_notification,
        operators: values.operators,
        is_private: values.is_private,
        within_jurisdiction_level: values.within_jurisdiction_level,
        within_jurisdiction: values.within_jurisdiction,
      };

      return props.onSubmit(rule);
    });

    return propsStream
      .combineLatest(
        initialValuesStream,
        matchModeStream,
        (props, initialValues, matchMode) => ({
          ...props,
          initialValues,
          matchMode,
          onSubmit: handleSubmit(props),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "PackageRuleTree",
    enableReinitialize: true,
    destroyOnUnmount: !module.hot,
    forceUnregisterOnUnmount: false,
    validate: (values, { i18n }) => ({
      lockerPostType:
        values.transportation_type === LOCKER_POST &&
        !values.lockerPostType &&
        i18n.get("this_field_is_required"),
    }),
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "ruleType",
      "packagePrices",
      "priceType",
      "logisticType",
      "within_jurisdiction",
      "transportation_type",
      "with_notification",
      "clientType",
      "operators",
      "is_private",
    ),
  })),
);

PackageRuleTree.propTypes = {
  values: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  matchMode: PropTypes.instanceOf(List),
  i18n: PropTypes.instanceOf(Map),
  theme: PropTypes.object,
  change: PropTypes.func,
};

function PackageRuleTree(props) {
  const classes = useStyles();
  const { values, i18n, matchMode, change } = props;
  const { operators = [] } = values;
  // const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(20);
  const deleteAdditionalServiceDispatch = id => {
    // deleteAdditionalService(id)
    //   .then(() => {
    //     showSuccessMessage1(
    //       i18n.get(
    //         "successfully_deleted",
    //         "Successfully deleted",
    //       ),
    //     );
    change(
      "operators",
      operators.filter(v => v.id !== id),
    );
    // setTotal(total - 1);
    // })
    // .catch((error) => showErrorMessage1(error));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <FlexBox
      container={8}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <PageFab type="submit" autoHide={false}>
        <DoneAll />
      </PageFab>

      <FlexBox direction="column" gutter={16}>
        <FlexBox flex="none" direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={16} style={{ marginBottom: ".5rem" }}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="name"
                    fullWidth={true}
                    label={`${i18n.get("name_1", "Name")} *`}
                    validate={createNotFalsyValidator(
                      i18n.get("enter_rule_name", "Enter Rule Name"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="description"
                    fullWidth={true}
                    label={i18n.get("description_uz")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="description_ru"
                    fullWidth={true}
                    label={i18n.get("description_ru")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="description_en"
                    fullWidth={true}
                    label={i18n.get("description_en")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    autoWidth={true}
                    fullWidth={true}
                    name="ruleStatus"
                    label={i18n.get("status", "Status")}
                    formatOption={x => i18n.get(x.toLowerCase(), formatText(x))}
                    options={UpperOverallStatus}
                    validate={createNotFalsyValidator("Select Status")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSupplierAutoComplete
                    name="supplier"
                    label={i18n.get("supplier", "Supplier")}
                    fullWidth={true}
                    hintText={i18n.get(
                      "type_here_to_search",
                      "Type to search ...",
                    )}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={16} style={{ marginBottom: ".5rem" }}>
                <FlexBox flex={true}>
                  <FormCourierTypeSelectField
                    fullWidth={true}
                    name="courierType"
                    label={`${i18n.get("service_type", "Service Type")} *`}
                    validate={createNotFalsyValidator(
                      i18n.get("enter_service_type", "Enter Service Type"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="priceType"
                    fullWidth={true}
                    options={packagePriceTypes}
                    formatOption={x => i18n.get(x.toLowerCase(), formatText(x))}
                    label={i18n.get("price_type", "Price Type")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="clientType"
                    fullWidth={true}
                    label={`${props.i18n.get("client_type", "Client Type")}`}
                    options={MerchantTypeForPackage}
                    formatOption={x => i18n.get(x)}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormCheckbox
                    // disabled={values.clientType === CLIENT_MERCHANT}
                    name="pay_with_stamp"
                    label={i18n.get("paid_with_stamps")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="priority"
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={i18n.get("priority")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormJurisdictionLevelAutoComplete
                    fullWidth={true}
                    name="within_jurisdiction_level"
                    label={i18n.get("jurisdiction_level")}
                    validate={
                      values.within_jurisdiction &&
                      createObjectIdValidator(
                        i18n.get("this_field_is_required"),
                      )
                    }
                    formatOption={option =>
                      i18n.get(fp.toLower(option.name), formatText(option.name))
                    }
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormCheckbox
                    name="within_jurisdiction"
                    label={i18n.get("within_jurisdiction")}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={16} style={{ marginBottom: ".5rem" }}>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="transportation_type"
                    fullWidth={true}
                    options={transportationTypeForPackageRule}
                    formatOption={x =>
                      formatTransportationTypesLocalised(x, i18n)
                    }
                    label={i18n.get("transportation_type")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="delivery_type"
                    fullWidth={true}
                    options={DeliveryType}
                    formatOption={x => i18n.get(x)}
                    label={i18n.get("delivery_type")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={props.i18n.get("customs_fee")}
                    name="customs_fee"
                    validate={createNotNilValidator(
                      props.i18n.get("this_field_is_required"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={props.i18n.get("forwarding_fee")}
                    name="forwarding_fee"
                    validate={createNotNilValidator(
                      props.i18n.get("this_field_is_required"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={props.i18n.get("cod_fee")}
                    name="cod_fee_percentage"
                    validate={createNotNilValidator(
                      props.i18n.get("this_field_is_required"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    disabled={!values.with_notification}
                    name="notification_fee"
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                    label={props.i18n.get("notification_fee")}
                    validate={createNotNilValidator(
                      props.i18n.get("this_field_is_required"),
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormCheckbox
                    name="with_notification"
                    label={i18n.get("with_notification")}
                    onChange={() => {
                      props.change("notification_fee", 0);
                    }}
                  />
                </FlexBox>
              </FlexBox>
              {values.transportation_type === LOCKER_POST && (
                <FlexBox gutter={16} style={{ marginBottom: ".5rem" }}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="lockerPostType"
                      fullWidth={true}
                      options={lockerPostType}
                      formatOption={x =>
                        i18n.get(x.toLowerCase(), formatText(x))
                      }
                      label={`${i18n.get(
                        "locker_post_type",
                        "Locker Post Type",
                      )} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true} />
                  <FlexBox flex={true} />
                  <FlexBox flex={true} />
                  <FlexBox flex={true} />
                  <FlexBox flex={true} />
                  <FlexBox flex={true} />
                </FlexBox>
              )}
              {values.priceType === LINEAR_PRICE ? (
                <FieldArray
                  name="packageLinearPrices"
                  props={{
                    classes,
                    i18n: props.i18n,
                    theme: props.theme,
                  }}
                  component={PackageLinearPricesBranch}
                />
              ) : (
                <FieldArray
                  name="packageBucketPrices"
                  props={{
                    matchMode,
                    classes,
                    i18n: props.i18n,
                    theme: props.theme,
                  }}
                  component={PackageBucketPricesBranch}
                />
              )}
              <FlexBox
                justify={JUSTIFY_END}
                flex={true}
                style={{
                  minWidth: 330,
                  flex: "0 0 auto",
                  paddingRight: ".5rem",
                }}
              >
                <FlexBox align={ALIGN_CENTER}>
                  <FormCustomToggle
                    disabled={false}
                    name="is_private"
                    leftLabel={i18n.get("public")}
                    rightLabel={i18n.get("private")}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
        {Boolean(values.ruleType) && (
          <FlexBox
            flex={true}
            style={{ paddingBottom: "128px", marginLeft: "-1rem" }}
            justify={JUSTIFY_SPACE_AROUND}
          >
            <PackageRuleTreeBranch
              needsAValidation={!values.within_jurisdiction}
              root={true}
              name="orderRules"
            />
            {values.is_private && (
              <FlexBox
                direction="column"
                flex={true}
                style={{
                  maxWidth: "40%",
                }}
              >
                <Card>
                  <CardContent>
                    <FlexBox flex={true}>
                      <FormOperatorAutoComplete
                        onChange={v => {
                          if (
                            v &&
                            v.id
                            // !operators.some((item) => item.id === v.id)
                          ) {
                            if (operators && operators.length > 0) {
                              if (!operators.find(x => v.id === x.id)) {
                                change("operators", [...operators, v]);
                              }
                            } else change("operators", [v]);
                          }
                        }}
                        fullWidth={true}
                        name="operator"
                        label={i18n.get("operator")}
                      />
                    </FlexBox>
                    {operators && operators.length > 0 && (
                      <OperatorsMUITable
                        title={`${i18n.get("operators_count")}(${
                          operators.length
                        })`}
                        size="small"
                        list={operators}
                        total={operators.length}
                        page={page}
                        rowsPerPage={size}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        withCheckbox={false}
                        withFooter={false}
                        columns={[
                          {
                            type: COLUMN,
                            name: "name",
                            label: i18n.get("name"),
                          },
                          {
                            name: "warehouse",
                            label: i18n.get("warehouse"),
                            type: RENDER,
                            render: row => _.get(row, `warehouse.name`, "-"),
                          },
                          {
                            type: ACTION,
                            label: i18n.get("delete"),
                            dispatch: deleteAdditionalServiceDispatch,
                            endIcon: <Delete />,
                          },
                        ]}
                      />
                    )}
                  </CardContent>
                </Card>
              </FlexBox>
            )}
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(PackageRuleTree);
