export const PROVIDER_TYPE = "provider_type";
export const PROVIDER_KEY_TYPE = "provider_key_type";

export const PLACE_API_KEY = "place_api_key";
export const MAP_KEY = "map_key";
export const IOS_MAP_KEY = "ios_map_key";
export const ANDROID_MAP_KEY = "android_map_key";
export const ACTIVE = "is_primary";
export const DISTANCE_MATRIX_KEY = "distance_matrix_key";
export const GEO_CODING_API_KEY = "geo_coding_api_key";

export const GOOGLE_MAP = "Google Map";
export const YANDEX_MAP = "Yandex Map";
export const TWOGIS_MAP = "2GIS Map";
