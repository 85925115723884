import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Dialog, CircularProgress, DialogContent } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { postPaymentDataUrl } from "../../api/shared/PaymentApi";
import { primary1 } from "../../../shared/theme/main-theme";

const getPublicKey = fp.get("additional_data.PUBLIC_KEY");
const getSessionId = fp.get("additional_data.SESSION_ID");
const enhancer = compose(
  useSheet({
    dialogPaper: {
      width: 150,
      margin: "0 auto",
    },
    loader: {
      marginBottom: 10,
      marginTop: 20,
    },
  }),
  // eslint-disable-next-line no-undef
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage },
  ),
  mapPropsStream(propsStream => {
    const sessionIdStream = propsStream
      .distinctUntilKeyChanged("params")
      .first()
      .switchMap(props =>
        postPaymentDataUrl(props.params)
          .filter(fp.flow(fp.get("payload.data"), Boolean))
          .map(fp.get("payload.data"))
          .do(res => {
            // eslint-disable-next-line new-cap,no-undef
            Stripe(getPublicKey(res)).redirectToCheckout({
              sessionId: getSessionId(res),
            });
          })
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fromJS)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(Map());

    return propsStream.combineLatest(sessionIdStream, props => ({
      ...props,
    }));
  }),
);

SubscriptionStripePaymentDialog.propTypes = {
  classes: PropTypes.object,

  sessionId: PropTypes.string,
  publicKey: PropTypes.string,
  params: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function SubscriptionStripePaymentDialog(props) {
  const { classes, i18n } = props;

  return (
    <Dialog
      open={props.open}
      className={classes.dialogPaper}
      onClose={props.onRequestClose}
    >
      <DialogContent className={classes.dialogPaper}>
        <FlexBox align="center" justify="center" direction="column">
          <CircularProgress
            className={classes.loader}
            color={primary1}
            size={24}
            thickness={2}
          />
          <p>{i18n.get("please_wait", "Please Wait ")}.</p>
        </FlexBox>
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(SubscriptionStripePaymentDialog);
