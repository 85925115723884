import React from "react";
import { Map, fromJS } from "immutable";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getCurrentLanguage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { Card } from "@material-ui/core";
import PageLoading from "../ui-core/PageLoading";
import {
  addJurisdictions,
  getJurisdictions as getSavedJurisdictions,
} from "../../reducers/JurisdictionManagementReducer";
import fp from "lodash/fp";
import { isEqualData } from "../../helpers/DataUtils";
import { getJurisdictions } from "../../api/admin/AdminJurisdictionsApi";
import { Observable } from "rxjs";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toJurisdictionFilter } from "../../helpers/JurisdictionFilterMapper";
import JurisdictionTreeView, {
  JurisdictionActionType,
} from "./JurisdictionTreeView";
import JMItemFormWrapper from "./JMItemFormWrapper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    width: "420px",
    paddingTop: "64px",
    height: "100%",
  },
  list: {
    overflow: "auto",
  },
}));

const enhancer = compose(
  connect(
    state => ({
      language: getCurrentLanguage(state),
      marketplaceId: getMarketplaceId(state),
      jurisdictions: getSavedJurisdictions(state),
    }),
    { addJurisdictions },
  ),
  mapPropsStream(propsStream => {
    const {
      stream: onJurisdictionsRequestStream,
      handler: onJurisdictionsRequestRefresh,
    } = createEventHandler();

    const jurisdictionFilterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toJurisdictionFilter))
      .distinctUntilChanged(isEqualData);

    const jurisdictionsRequestStream = jurisdictionFilterStream
      .take(1)
      .distinctUntilChanged()
      .merge(onJurisdictionsRequestStream)
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getJurisdictions(new DataListFilter())
          .repeatWhen(() => onJurisdictionsRequestStream)
          .catch(error => Observable.of({ error })),
      )
      .startWith({})
      .let(mapObjectResponseStream)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        jurisdictionFilterStream,
        jurisdictionsRequestStream,
        (props, filter) => ({
          ...props,
          filter,
          onJurisdictionsRequestRefresh,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

JMAreaList.propTypes = {
  search: PropTypes.string,
  onNodeSelected: PropTypes.func,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onDismiss: PropTypes.func,
  activeNode: PropTypes.instanceOf(Map),
};

JMAreaList.defaultProps = {
  isEdit: false,
  isCreate: false,
};

function JMAreaList(props) {
  const classes = useStyles();

  return (
    <FlexBox container={16} className={classes.root}>
      <PageLoading isLoading={false} />

      <FlexBox flex={true} element={<Card />}>
        <FlexBox direction="column" flex={true} className={classes.list}>
          <JurisdictionTreeView
            search={props.search}
            onEdit={item => props.onEdit(fromJS(item))}
            onCreate={() => props.onCreate()}
            onSelect={value => {
              props.onNodeSelected(value ? fromJS(value) : null);
            }}
            formForJurisdictionLevel={(child, done) => (
              <JMItemFormWrapper
                isCreate={true}
                initialValues={{
                  parentId: child.id,
                  countryId: props.activeNode.get("country_id"),
                }}
                onRefreshRequest={() => {
                  props.onDismiss();
                  done(JurisdictionActionType.create);
                }}
              />
            )}
            formForJurisdictionEdit={(child, done) => (
              <JMItemFormWrapper
                isEdit={true}
                initialValues={child}
                onRefreshRequest={response => {
                  props.onNodeSelected(response ? fromJS(response) : null);
                  done(JurisdictionActionType.edit);
                }}
              />
            )}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(JMAreaList);
