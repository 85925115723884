import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
  Add as ContentAdd,
  FilterList as ContentFilterList,
} from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatText } from "../../helpers/FormatUtils";
import { formatShippingTypeLocalisation } from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter, {
  getListFilterSchema,
} from "../../helpers/DataListFilter";
import { isReverseLogisticEnabled } from "../../reducers/MarketplaceReducer";
import { getMessage, getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  PACKAGE_RULE_ITEM_URL,
  PACKAGE_RULE_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  clonePackageRules,
  deletePackageRule,
  getPackageRuleList,
} from "../../api/admin/AdminPackageRulesApi";
import AdminPackageRuleFilterWrapper from "../../wrappers/admin/AdminPackageRuleFilterWrapper";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import TextWithLink from "../../components/router/TextWithLink";
import Text, { MUTED } from "../../components/ui-core/Text";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import PopoverOverlay from "../../components/ui-core/PopoverOverlay";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import CloneRulesDialog from "../../components/settings-core/CloneRulesDialog";
import PackageRuleUploadDialog from "../../components/package-rule-tree/PackageRuleUploadDialog";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../../shared/helpers/ObjectMapper";
import { CREATE_PACKAGE_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import MenuItemForm from "../../components/ui-core/MenuItemForm";

const FILTER_DIALOG_HASH = "#FDH";
const PACKAGE_UPLOAD_RULES_DIALOG_HASH = "#PURDH";

export const toPackageRuleFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    service_types: queryTypes.stringSeq,
    transportation_type: queryTypes.stringSeq,
    supplier_ids: queryTypes.IDSeq,
    rule_status: types.string,
    price_type: types.string,
    rule_types: queryTypes.stringSeq,
    logistic_type: types.string,
  }),
);
const enhancer = compose(
  connect(
    (state) => ({
      i18n: getMessages(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      reverseLogisticEnabled: isReverseLogisticEnabled(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) =>
        propsStream.combineLatest(
          propsStream
            .map(fp.flow(fp.get("location.query"), toPackageRuleFilter))
            .distinctUntilChanged(isEqualData),
          (props, filter) => ({ ...props, filter }),
        ),
      (propsStream) => {
        const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
          createEventHandler();

        const responseStream = propsStream
          .pluck("filter")
          .distinctUntilChanged(isEqualData)
          .switchMap((filter) =>
            getPackageRuleList(filter)
              .catch((error) => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  error: response.get("error", null),
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(responseStream, (props, response) => ({
          ...props,
          onRequestRefresh,
          list: response.get("list"),
          total: response.get("total"),
          isFetching: response.get("pending"),
        }));
      },
    ),
  ),
);

AdminPackageRuleListContainer.propTypes = {
  location: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,

  total: PropTypes.number,
  isFetching: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),

  i18n: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  reverseLogisticEnabled: PropTypes.bool,
};

function AdminPackageRuleListContainer(props) {
  const { i18n, location, getLocalisationMessage, reverseLogisticEnabled } =
    props;
  const NA = getLocalisationMessage("na", "N/A");

  const removeId = fp.toFinite(props.location.query.remove);
  const cloneId = fp.toFinite(props.location.query.clone);

  return (
    <AdminAppLayout
      slug="package_rule"
      title={getLocalisationMessage("package_rule", "Package Rule")}
    >
      <FormDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset("remove"))}
        onSubmit={() => deletePackageRule(removeId).catch(ResponseError.throw)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("remove"));
          props.showSuccessMessage(
            i18n.get("successfully_removed", "Successfully Removed"),
          );
        }}
        onSubmitFail={(error) => props.showErrorMessage(error)}
      >
        {i18n.get(
          "are_you_sure_you_want_to_remove_this_rule",
          "Are you sure you want to remove this rule?",
        )}
      </FormDialog>
      <PackageRuleUploadDialog
        onComplete={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        getLocalisationMessage={getLocalisationMessage}
        open={location.hash === PACKAGE_UPLOAD_RULES_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <CloneRulesDialog
        ruleId={cloneId}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        onRequestClose={() => props.setLocationQuery(fp.unset("clone"))}
        onSubmit={(values) => clonePackageRules(cloneId, toSnakeCase(values))}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.showSuccessMessage(
            i18n.get("successfully_cloned", "Successfully Cloned"),
          );
        }}
        onSubmitFail={(error) => props.showErrorMessage(error)}
      />

      <AdminPackageRuleFilterWrapper
        open={location.hash === FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={(filter) => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />

      <DataList
        overscanRowCount={10}
        totalCount={props.total}
        rowCount={props.list.size}
        isLoading={props.isFetching}
        filter={props.filter}
        onFilterChange={props.setLocationQueryFilter}
        rowGetter={(options) => props.list.get(options.index)}
        cardActionIcons={
          <div>
            <Link to={PACKAGE_RULE_CREATE_URL}>
              <Tooltip title={getLocalisationMessage("add", "Add")}>
                <IconButton>
                  <ContentAdd />
                </IconButton>
              </Tooltip>
            </Link>
            <Link to={updateHash(location, FILTER_DIALOG_HASH)}>
              <Tooltip title={getLocalisationMessage("filter", "Filter")}>
                <IconButton>
                  <ContentFilterList />
                </IconButton>
              </Tooltip>
            </Link>

            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                onClick={() =>
                  props.replaceLocationHash(PACKAGE_UPLOAD_RULES_DIALOG_HASH)
                }
              >
                {props.getLocalisationMessage(
                  "upload_package_rules",
                  "Upload Package Rules",
                )}
              </MenuItem>
              <MenuItemForm
                primaryText={props.getLocalisationMessage(
                  "download_csv",
                  "Download CSV",
                )}
                url={CREATE_PACKAGE_CSV_URL}
                params={{
                  rule_type: "CUSTOM_PACKAGE",
                  rule_types: "CUSTOM_PACKAGE",
                }}
              />
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          label={i18n.get("id", "ID")}
          dataKey="id"
          justifyContent="center"
          cellRenderer={(row) => (
            <TextWithLink
              to={`${PACKAGE_RULE_ITEM_URL}${row.cellData.get("id")}`}
            >
              {row.cellData.get("id")}
            </TextWithLink>
          )}
        />

        <DataListColumn
          label={i18n.get("name_1", "Name")}
          width={240}
          dataKey="name"
          justifyContent="center"
          cellRenderer={(row) => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              <span style={{ fontSize: "1rem" }}>
                {row.cellData.get("name")}
              </span>
            </Text>
          )}
        />
        <DataListColumn
          label={i18n.get("price_type", "Price Type")}
          width={120}
          dataKey="price_type"
          justifyContent="center"
          cellRenderer={(row) => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {props.getLocalisationMessage(
                row.cellData.get("price_type"),
                formatText(row.cellData.get("price_type")),
              )}
            </Text>
          )}
        />

        {reverseLogisticEnabled && (
          <DataListColumn
            label={i18n.get("shipping_type", "Shipping Type")}
            width={120}
            dataKey="logistic_type"
            justifyContent="center"
            cellRenderer={(row) => (
              <Text fallbackValue={NA} fallbackType={MUTED}>
                {formatShippingTypeLocalisation(
                  row.cellData.get("logistic_type"),
                  props.getLocalisationMessage,
                )}
              </Text>
            )}
          />
        )}

        <DataListColumn
          label={i18n.get("client_type", "Client Type")}
          width={120}
          dataKey="client_type"
          disableSort={true}
          justifyContent="center"
          cellRenderer={(row) => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {i18n.get(
                row.cellData.get("client_type"),
                formatText(row.cellData.get("client_type")),
              )}
            </Text>
          )}
        />

        <DataListColumn
          label={i18n.get("priority", "Priority")}
          dataKey="priority"
          justifyContent="center"
          cellRenderer={(row) => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {row.cellData.get("priority")}
            </Text>
          )}
        />

        <DataListColumn
          label={i18n.get("service_type", "Service Type")}
          width={120}
          dataKey="service_type"
          justifyContent="center"
          cellRenderer={(row) => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {row.cellData.get("service_type")}
            </Text>
          )}
        />
        <DataListColumn
          label={i18n.get("customers", "Customers")}
          width={120}
          dataKey="customer_names"
          justifyContent="center"
          cellRenderer={(row) => (
            <PopoverOverlay
              width={350}
              header="Customers"
              content={row.cellData.get("customer_names") || NA}
            >
              <span>{row.cellData.get("customer_names")}</span>
            </PopoverOverlay>
          )}
        />

        <DataListColumn
          label={i18n.get("customers_count", "Customers Count")}
          dataKey="customer_count"
          justifyContent="center"
          cellRenderer={(row) => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {row.cellData.get("customer_count")}
            </Text>
          )}
        />

        <DataListColumn
          label={i18n.get("status", "Status")}
          width={120}
          dataKey="status"
          justifyContent="center"
          cellRenderer={(row) => {
            const status = row.cellData.get("rule_status");
            return (
              <Text fallbackValue={NA} fallbackType={MUTED}>
                {status
                  ? i18n.get(status.toLowerCase(), formatText(status))
                  : NA}
              </Text>
            );
          }}
        />

        <DataListColumn
          label={i18n.get("action", "Action")}
          width={200}
          dataKey="action"
          disableSort={true}
          justifyContent="center"
          cellRenderer={(row) => (
            <div>
              <Link
                to={updateQuery(props.location, {
                  remove: row.cellData.get("id"),
                })}
              >
                <Button>{i18n.get("remove", "Remove")}</Button>
              </Link>
              <Link
                to={updateQuery(props.location, {
                  clone: row.cellData.get("id"),
                })}
              >
                <Button>{i18n.get("clone", "Clone")}</Button>
              </Link>
            </div>
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminPackageRuleListContainer);
