import React from "react";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import { Card, CardContent, Fab } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import {
  getMarketplace,
  getMarketplaceId,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import fp from "lodash/fp";
import {
  isValidCoordinates,
  isValidObjectId,
  validateString,
} from "../../helpers/ValidateUtils";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import {
  KeyboardArrowLeft,
  MyLocation as MapsMyLocation,
  Save,
} from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import FormSelectField from "../form/FormSelectField";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import OverallStatus from "../../constants/OverallStatus";
import { formatText } from "../../helpers/FormatUtils";
import {
  FitBounds,
  Marker as LMarker,
  PanTo as LPanTo,
} from "react-leflet-map-components";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import Geolocation from "../maps/Geolocation";
import { primary1 } from "../../../shared/theme/main-theme";
import Text from "../ui-core/Text";

const valueSelector = formValueSelector("LockerPostForm");

const getLatLng = fp.pick(["lat", "lon"]);

const enhancer = compose(
  useSheet({
    map: { height: "100%" },
    mapContainer: { position: "relative" },
    uploadButton: {
      zIndex: 1,
      top: "10px",
      left: "20px",
      position: "absolute",
    },
    saveButton: {
      zIndex: 1,
      top: "10px",
      left: "180px",
      position: "absolute",
    },
    customControl: {
      zIndex: 1000,
      top: "10px",
      left: "10px",
      position: "absolute",
    },
    listCard: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    root: {
      padding: 10,
      overflow: "hidden",
      height: "100%",
    },
    point: {
      position: "absolute",
      padding: 40,
      right: 0,
      bottom: 0,
      zIndex: 1000,
    },
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
    }),
  ),
  connect(
    state => {
      const country = getMarketplace(state).get("country");
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        countryId: country && country.get("id"),
        marketplaceId: getMarketplaceId(state),
        mapProvider: getMapProvider(state),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  reduxForm({
    enableReinitialize: true,
    form: "LockerPostForm",
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      nameUz: validateString(values.nameUz)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      nameRu: validateString(values.nameRu)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      nameEn: validateString(values.nameEn)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      address: validateString(values.address)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      lockerPostId: validateString(values.lockerPostId)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      lat: validateString(values.lat)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      lon: validateString(values.lon)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      status: validateString(values.status)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      size: validateString(values.size)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      smallCount: validateString(values.smallCount)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      mediumCount: validateString(values.mediumCount)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      largeCount: validateString(values.largeCount)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
      jurisdiction: fp.isNil(values.jurisdiction)
        ? props.getLocalisationMessage(
            "jurisdiction_is_required",
            "Jurisdiction is required",
          )
        : null,
      warehouse: !isValidObjectId(values.warehouse)
        ? props.getLocalisationMessage(
            "this_field_is_required",
            "This field is required",
          )
        : null,
    }),
  }),

  connect(state => ({
    values: valueSelector(state, "lat", "lon"),
  })),
);

LockerPostForm.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  onAddPolygon: PropTypes.func,
  onEditPolygon: PropTypes.func,
  onRemovePolygon: PropTypes.func,

  removed: PropTypes.array,
  onRemove: PropTypes.func,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onUploadFile: PropTypes.func,
  uploadNeighborhoodKML: PropTypes.func,
  change: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  jurisdictionPolygon: PropTypes.array,
  router: PropTypes.object,
  values: PropTypes.object,
};

function LockerPostForm(props) {
  const { classes, getLocalisationMessage, router } = props;

  return (
    <form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      style={{ height: "100%" }}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox flex={true} className={classes.root} style={{ gap: 16 }}>
        <FlexBox style={{ width: 450 }}>
          <Card className={classes.listCard}>
            <CardContent style={{ height: "100%" }}>
              <FlexBox
                justify="space-between"
                direction="column"
                style={{ height: "100%" }}
              >
                <FlexBox
                  flex={true}
                  style={{
                    height: "100%",
                    overflowY: "auto",
                    padding: "0px 15px",
                  }}
                  direction="column"
                >
                  <FormTextField
                    name="lockerPostId"
                    fullWidth={true}
                    margin="normal"
                    label={`${getLocalisationMessage(
                      "locker_post_id",
                      "Locker post ID",
                    )} *`}
                  />
                  <FormTextField
                    name="name"
                    fullWidth={true}
                    margin="normal"
                    label={`${getLocalisationMessage("name_1", "Name")} *`}
                  />
                  <FormTextField
                    name="nameUz"
                    margin="normal"
                    label={`${getLocalisationMessage("name_uz")} *`}
                    fullWidth={true}
                  />
                  <FormTextField
                    name="nameRu"
                    margin="normal"
                    label={`${getLocalisationMessage("name_ru")} *`}
                    fullWidth={true}
                  />
                  <FormTextField
                    name="nameEn"
                    margin="normal"
                    label={`${getLocalisationMessage("name_eng")} *`}
                    fullWidth={true}
                  />

                  <FormWarehouseAutoComplete
                    hintText={getLocalisationMessage("warehouse", "Warehouse")}
                    label={`${getLocalisationMessage(
                      "warehouse",
                      "warehouse",
                    )} *`}
                    name="warehouse"
                    fullWidth={true}
                    margin="normal"
                  />
                  <FormJMAutoComplete
                    fullWidth={true}
                    margin="normal"
                    name="jurisdiction"
                    label={`${getLocalisationMessage(
                      "jurisdiction",
                      "Jurisdiction",
                    )} *`}
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{option.name}</span>
                        <span
                          style={{
                            fontSize: ".8rem",
                            fontStyle: "italic",
                          }}
                        >
                          {option.value.hierarchy.map((item, index, arr) =>
                            index === arr.length - 1
                              ? item.name
                              : `${item.name} > `,
                          )}
                        </span>
                      </FlexBox>
                    )}
                  />
                  <FormTextField
                    margin="normal"
                    name="address"
                    data-cy="senderAddress"
                    fullWidth={true}
                    withSuccessIcon={true}
                    label={`${getLocalisationMessage("address", "Address")} *`}
                  />
                  <FormTextField
                    name="lat"
                    margin="normal"
                    label={`${getLocalisationMessage("lat")} *`}
                    fullWidth={true}
                  />
                  <FormTextField
                    name="lon"
                    margin="normal"
                    label={`${getLocalisationMessage("lon")} *`}
                    fullWidth={true}
                  />
                  <FormSelectField
                    name="status"
                    style={{ marginTop: "16px" }}
                    fullWidth={true}
                    options={OverallStatus}
                    formatOption={option =>
                      getLocalisationMessage(option, formatText(option))
                    }
                    label={`${getLocalisationMessage("status", "Status")} *`}
                  />
                  <FlexBox direction="row" align="center">
                    <Text style={{ width: 170 }} element="h5">
                      {getLocalisationMessage("small")}
                    </Text>
                    <FormTextField
                      name="smallCount"
                      margin="normal"
                      label={`${getLocalisationMessage("count")} *`}
                      fullWidth={true}
                    />
                  </FlexBox>
                  <FlexBox direction="row" align="center">
                    <Text style={{ width: 170 }} element="h5">
                      {getLocalisationMessage("medium")}
                    </Text>
                    <FormTextField
                      name="mediumCount"
                      margin="normal"
                      label={`${getLocalisationMessage("count")} *`}
                      fullWidth={true}
                    />
                  </FlexBox>
                  <FlexBox direction="row" align="center">
                    <Text style={{ width: 170 }} element="h5">
                      {getLocalisationMessage("large")}
                    </Text>
                    <FormTextField
                      name="largeCount"
                      margin="normal"
                      label={`${getLocalisationMessage("count")} *`}
                      fullWidth={true}
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  justify="flex-end"
                  align="flex-end"
                  style={{ marginTop: 15 }}
                >
                  <CustomButton
                    style={{ marginRight: "1rem" }}
                    onClick={() => router.goBack()}
                    startIcon={<KeyboardArrowLeft />}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("back", "Back")}
                  </CustomButton>
                  <CustomButton
                    variant={CONTAINED}
                    color={SECONDARY}
                    endIcon={<Save />}
                    type="submit"
                  >
                    {getLocalisationMessage("save", "Save")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox flex={true}>
          <Card className={classes.listCard}>
            <CardContent style={{ height: "100%", paddingBottom: 10 }}>
              <FlexBox style={{ gap: 16, height: "100%" }} direction="column">
                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ height: "100%" }}
                >
                  <LeafletMapWrapper
                    data-cy="search_geo"
                    className={classes.map}
                    mapCenter={getLatLng(props.values)}
                    hasZoomControl={true}
                    position="topleft"
                    onClick={x => {
                      props.change("lat", x.latlng.lat);
                      props.change("lon", x.latlng.lng);
                    }}
                  >
                    {isValidCoordinates({
                      lat: Number(props.values.lat),
                      lng: Number(props.values.lon),
                    }) && (
                      <div>
                        <LPanTo
                          latLng={{
                            lat: Number(props.values.lat),
                            lng: Number(props.values.lon),
                          }}
                        />
                        <LMarker
                          position={{
                            lat: Number(props.values.lat),
                            lng: Number(props.values.lon),
                          }}
                        />
                        <FitBounds
                          latLngBounds={[
                            {
                              lat: Number(props.values.lat),
                              lng: Number(props.values.lon),
                            },
                          ]}
                        />
                      </div>
                    )}
                  </LeafletMapWrapper>

                  <div className={classes.point}>
                    <Geolocation
                      onSuccess={({ coords: { latitude, longitude } }) => {
                        props.change("lat", latitude);
                        props.change("lon", longitude);
                      }}
                      render={({ getCurrentPosition }) => (
                        <div>
                          <Fab
                            data-cy="geo_find_me"
                            size="small"
                            onClick={getCurrentPosition}
                            style={{ backgroundColor: "#fff" }}
                          >
                            <MapsMyLocation style={{ fill: primary1 }} />
                          </Fab>
                        </div>
                      )}
                    />
                  </div>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(LockerPostForm);
