import React from "react";
import { Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, FieldArray, formValueSelector } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormTimeField from "../form/FormTimeField";
import FormSelectField from "../form/FormSelectField";
import FormFileUploadField from "../form/FormFileUploadField";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import {
  formatText,
  parseFloat,
  parseInteger,
} from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import PackageTypes from "../../constants/PackageTypes";
import PromoBenefit, { FREE_ORDER } from "../../constants/PromoBenefit";
import OverallStatus from "../../constants/OverallStatus";
import PromoCondition from "../../constants/PromoCondition";
import { PUBLIC, PRIVATE } from "../../constants/NotePrivacyTypes";

const valueSelector = formValueSelector("AdminPromoForm");

const isPublic = Set.of(PUBLIC, PRIVATE);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    root: {
      margin: "0 auto",
      width: "480px",
      minWidth: "320px",
      maxWidth: "480px",
      paddingBottom: "64px",
    },

    image: { maxWidth: "100%", marginBottom: "24px" },
  }),
  withContext(
    {
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    props => ({
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
    }),
  ),
  reduxForm({
    form: "AdminPromoForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      packageType:
        !values.packageType &&
        props.getLocalisationMessage(
          "select_package_type",
          "Select Package Type",
        ),
    }),
  }),
  connect(state => ({
    values: valueSelector(state, "image", "benefitType", "benefitValue"),
  })),
  mapPropsStream(propsStream => {
    const sideEffectsStream = propsStream
      .distinctUntilChanged(isEqualDataIn(["values", "benefitType"]))
      .filter(
        props =>
          props.values.benefitType === FREE_ORDER &&
          props.values.benefitValue !== null,
      )
      .do(props => props.change("benefitValue", null))
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

PromoFormConditions.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function PromoFormConditions(props) {
  return (
    <Card>
      {props.fields.length > 0 && (
        <CardContent>
          {props.fields.map((condition, index) => (
            <FlexBox key={index} gutter={8}>
              <FlexBox flex={true}>
                <FormSelectField
                  name={`${condition}.type`}
                  autoWidth={true}
                  fullWidth={true}
                  hintText={props.getLocalisationMessage("type", "Type")}
                  options={PromoCondition}
                  formatOption={x =>
                    props.getLocalisationMessage(x, formatText(x))
                  }
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  fullWidth={true}
                  placeholder={props.getLocalisationMessage(
                    "minimum_price",
                    "Minimum Price",
                  )}
                  parseOnBlur={parseFloat}
                  name={`${condition}.minPrice`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  fullWidth={true}
                  placeholder={props.getLocalisationMessage(
                    "maximum_price",
                    "Maximum Price",
                  )}
                  parseOnBlur={parseFloat}
                  name={`${condition}.maxPrice`}
                />
              </FlexBox>

              <FlexBox align="center">
                <Button onClick={() => props.fields.remove(index)}>
                  {props.getLocalisationMessage("remove", "Remove")}
                </Button>
              </FlexBox>
            </FlexBox>
          ))}
        </CardContent>
      )}

      <CardActions>
        <Button onClick={() => props.fields.push({})}>
          {props.getLocalisationMessage("add_condition", "Add Condition")}
        </Button>
      </CardActions>
    </Card>
  );
}

PromoForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function PromoForm(props) {
  const { classes, values, getLocalisationMessage } = props;

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox gutter={8} direction="column">
        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FormTextField
                name="privateName"
                fullWidth={true}
                label={getLocalisationMessage("campaign_name", "Campaign Name")}
              />
              <FormTextField
                name="code"
                fullWidth={true}
                label={props.getLocalisationMessage("promo_code", "Promo Code")}
              />
              <FormTextField
                name="title"
                fullWidth={true}
                label={props.getLocalisationMessage(
                  "public_title",
                  "Public Title",
                )}
              />
              <FormTextField
                name="subtitle"
                fullWidth={true}
                label={props.getLocalisationMessage(
                  "public_subtitle",
                  "Public Subtitle",
                )}
              />

              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="benefitType"
                    autoWidth={true}
                    fullWidth={true}
                    options={PromoBenefit}
                    formatOption={x =>
                      props.getLocalisationMessage(x) || formatText(x)
                    }
                    label={props.getLocalisationMessage(
                      "benefit_type",
                      "Benefit Type",
                    )}
                  />
                </FlexBox>

                {values.benefitType !== FREE_ORDER && (
                  <FlexBox flex={true} direction="column">
                    <FormTextField
                      name="benefitValue"
                      fullWidth={true}
                      parseOnBlur={parseFloat}
                      label={props.getLocalisationMessage(
                        "benefit_value",
                        "Benefit Value",
                      )}
                    />
                  </FlexBox>
                )}
              </FlexBox>

              <FormSelectField
                name="status"
                autoWidth={true}
                fullWidth={true}
                options={OverallStatus}
                formatOption={x =>
                  props.getLocalisationMessage(x) || formatText(x)
                }
                label={props.getLocalisationMessage("status", "Status")}
              />

              <FormSelectField
                name="isPublic"
                autoWidth={true}
                fullWidth={true}
                options={isPublic}
                formatOption={x =>
                  props.getLocalisationMessage(x) || formatText(x)
                }
                label={props.getLocalisationMessage(
                  "privacy_type",
                  "Privacy Type",
                )}
              />

              <FormSelectField
                name="packageType"
                autoWidth={true}
                fullWidth={true}
                options={PackageTypes}
                formatOption={x =>
                  props.getLocalisationMessage(x) || formatText(x)
                }
                label={`${props.getLocalisationMessage(
                  "package_type",
                  "Package Type",
                )} *`}
              />

              {Boolean(values.image) && (
                <div>
                  <img
                    alt={props.getLocalisationMessage(
                      "promo_campaign",
                      "Promo Campaign",
                    )}
                    src={values.image}
                    className={classes.image}
                  />

                  <FlexBox justify="flex-end">
                    <Button onClick={() => props.change("image", null)}>
                      {props.getLocalisationMessage(
                        "remove_image",
                        "Remove Image",
                      )}
                    </Button>
                  </FlexBox>
                </div>
              )}

              <FormFileUploadField
                name="image"
                fullWidth={true}
                accept="image/*"
              />
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    name="validFrom"
                    fullWidth={true}
                    label={props.getLocalisationMessage(
                      "valid_from_date",
                      "Valid From Date",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTimeField
                    name="validFrom"
                    fullWidth={true}
                    label={props.getLocalisationMessage(
                      "valid_from_time",
                      "Valid From Time",
                    )}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    name="validTo"
                    fullWidth={true}
                    label={props.getLocalisationMessage(
                      "valid_to_date",
                      "Valid To Date",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTimeField
                    name="validTo"
                    fullWidth={true}
                    label={props.getLocalisationMessage(
                      "valid_to_time",
                      "Valid To Time",
                    )}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="useCount"
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    placeholder={props.getLocalisationMessage(
                      "1_for_infinity",
                      "-1 for infinity",
                    )}
                    label={props.getLocalisationMessage(
                      "use_count",
                      "Use Count",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="personalUseCount"
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    placeholder={props.getLocalisationMessage(
                      "1_for_infinity",
                      "-1 for infinity",
                    )}
                    label={props.getLocalisationMessage(
                      "personal_use_count",
                      "Personal Use Count",
                    )}
                  />
                </FlexBox>
              </FlexBox>

              <FormCourierTypeChips
                name="courierTypes"
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
                label={getLocalisationMessage("service_type", "Service Type")}
              />
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <FieldArray
            name="conditions"
            component={PromoFormConditions}
            getLocalisationMessage={getLocalisationMessage}
          />
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardActions>
              <FlexBox justify="flex-end">
                {props.dirty ? (
                  <Button onClick={props.reset}>
                    {props.getLocalisationMessage("reset", "Reset")}
                  </Button>
                ) : (
                  Boolean(props.onDismiss) && (
                    <Button onClick={props.onDismiss}>
                      {props.getLocalisationMessage("dismiss", "Dismiss")}
                    </Button>
                  )
                )}

                <Button type="submit">
                  {props.getLocalisationMessage("submit", "Submit")}
                </Button>
              </FlexBox>
            </CardActions>
          </Card>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(PromoForm);
