import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import {
  ERROR,
  LOADING,
  NOT_STARTED,
  SUCCESS,
} from "../../components/orders-core/AdminPostForm";
import {
  getAdditionalService,
  updateAdditionalService,
} from "../../api/shared/OrderApi";
import _ from "lodash";
import AdditionalServiceForm from "../../components/orders-core/AdditionalServiceForm";
import { ADDITIONAL_SERVICES_SETTINGS_URL } from "../../constants/AdminPathConstants";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdditionalServiceEditWrapper.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  params: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
  setLocation: PropTypes.func,
};

function AdditionalServiceEditWrapper({
  getLocalisationMessage,
  onSubmitFail,
  params,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  setLocation,
}) {
  const [service, setService] = useState({});
  const [isLoading, setIsLoading] = useState(NOT_STARTED);

  useEffect(
    () =>
      getAdditionalService(params.id)
        .then((res) => {
          setService(_.get(res, "data"));
        })
        .catch((error) => showErrorMessage1(error)),
    [params.id],
  );

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage("additional_services")} - ${params.id}`}
    >
      <AdditionalServiceForm
        edit={true}
        initialValues={{ ...service }}
        serviceId={params.id}
        isLoading={isLoading}
        service={service}
        onSubmit={(values) => {
          setIsLoading(LOADING);
          const data = toSnakeCase(values);
          updateAdditionalService(params.id, data)
            .then((res) => {
              if (res.status === "success") {
                setIsLoading(SUCCESS);
                showSuccessMessage1(
                  getLocalisationMessage(
                    "successfully_updated",
                    "Successfully Updated",
                  ),
                );
                setLocation(ADDITIONAL_SERVICES_SETTINGS_URL);
              } else {
                setIsLoading(ERROR);
                showErrorMessage1(res.message);
              }
            })
            .catch((error) => {
              setIsLoading(ERROR);
              if (onSubmitFail) {
                onSubmitFail(error);
              } else {
                showErrorMessage1(error);
              }
            });
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdditionalServiceEditWrapper);
