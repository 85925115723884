import React from "react";
import { OrderedSet } from "immutable";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import FormSelectField from "../form/FormSelectField";
import FormVenueMenuAutoComplete from "../form/FormVenueMenuAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { formatText } from "../../helpers/FormatUtils";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import {
  getCachedVenueMenu,
  getVenueMenuPredictions,
} from "../../api/admin/AdminShopMenuApi";

const FILTER_CODES = OrderedSet.of(ACTIVE, INACTIVE);

const enhancer = compose(
  reduxForm({
    form: "ProductListFilterDialog",
    enableReinitialize: true,
  }),
  withContext(
    {
      getCachedVenueMenu: PropTypes.func,
      getVenueMenuPredictions: PropTypes.func,
    },
    () => ({ getCachedVenueMenu, getVenueMenuPredictions }),
  ),
);

ProductListFilterDialog.propTypes = {
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
};

function ProductListFilterDialog(props) {
  return (
    <Dialog
      open={props.open}
      modal={false}
      onClose={props.onRequestClose}
      autoScrollBodyContent={false}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <form>
          <FlexBox container={8}>
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox flex={true}>
                <FormVenueMenuAutoComplete
                  name="menu"
                  label="Menu"
                  fullWidth={true}
                  hintText="Choose Menu"
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormSelectField
                  name="status"
                  label="Status"
                  hintText="Status"
                  options={FILTER_CODES}
                  formatOption={formatText}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            primary={true}
            onClick={() => {
              props.change("menu", null);
              props.change("status", null);
            }}
          >
            Clear
          </Button>
          <Button primary={true} onClick={props.onRequestClose}>
            Dismiss
          </Button>
          <Button primary={true} onClick={props.handleSubmit}>
            Submit
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ProductListFilterDialog);
