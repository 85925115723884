import React from "react";
import fp from "lodash/fp";
import { Map } from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { CardContent, CardHeader } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { isEqualData } from "../../helpers/DataUtils";
import Text from "../ui-core/Text";
import LinkButton from "../ui-core/LinkButton";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";

const useStyles = makeStyles(() => ({
  details: { flexDirection: "column", marginBottom: 8 },
  detailsRow: {
    marginBottom: 20,
  },
  detailsLabel: {
    lineHeight: "26px",
    fontWeight: "400",
  },
  detailsValue: {
    lineHeight: "26px",
  },
  orderStatus: {
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > span": {
      fontWeight: "bold",
      color: red[400],
      textTransform: "uppercase",
    },
  },
  "@media (min-width: 998px)": {
    details: { flexDirection: "column" },
  },
}));

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream((propsStream) => {
    const stateStream = propsStream
      .map(fp.pick(["activeOrder", "queueOrders"]))
      .distinctUntilChanged(isEqualData)
      .map((props) => {
        const queueOrder = props.queueOrders.get(props.orderNumber);

        const state = {};

        if (queueOrder) {
          state.order = queueOrder.get("info");
          state.failed = queueOrder.get("failed");
        }

        // if (!state.failed && !state.order) {
        //   state.order = props.allOrders.get(props.orderNumber);
        // }

        // if (state.order) {
        //   const promiseDate = safeParseDate(state.order.get("promise_date"));
        //
        //   state.addressType = state.order.get("address_type");
        // }

        return state;
      });

    return propsStream.combineLatest(stateStream, (props, state) => ({
      ...props,
      ...state,
    }));
  }),
  pureComponent(fp.pick(["activeOrder", "queueOrders"])),
);

const orderDetailsSection = (props) => {
  const classes = useStyles();
  const { getLocalisationMessage, queueOrders, activeOrder } = props;

  if (!activeOrder) return <div />;

  const order = queueOrders.get(activeOrder);

  if (!order || (!order.get("info") && !order.get("failed")))
    return (
      <Text element="h3" type="align-center" className={classes.orderStatus}>
        <strong>{props.activeOrder}</strong> {getLocalisationMessage("loading")}
        {"..."}
      </Text>
    );

  if (order.get("failed"))
    return (
      <Text element="h3" type="align-center" className={classes.orderStatus}>
        <strong>{props.activeOrder}</strong>{" "}
        {getLocalisationMessage("loading_failed")}. (
        <LinkButton onClick={props.onReloadClick}>
          {getLocalisationMessage("reload")}
        </LinkButton>{" "}
        /{" "}
        <LinkButton onClick={props.onRemoveClick}>
          {getLocalisationMessage("remove")}
        </LinkButton>
        )
      </Text>
    );

  const orderInfo = order.get("info", Map());

  const type = orderInfo.get("type", null)
    ? orderInfo.get("type").toLowerCase()
    : "Shipment";

  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true}>
        <Text element="h3" type="align-center" className={classes.orderStatus}>
          <strong>
            {getLocalisationMessage("details", "Details")} - {props.activeOrder}{" "}
          </strong>
        </Text>
      </FlexBox>
      <FlexBox flex={true} className={classes.detailsRow}>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("type", "Type")}:
          </h5>
          <h5 className={classes.detailsValue}>
            <strong>{getLocalisationMessage(type)}</strong>
          </h5>
        </FlexBox>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("status", "Status")}:
          </h5>
          <h5 className={classes.detailsValue}>
            <strong>
              {formatOrderStatusCodeForLocalisation(
                orderInfo.get("status"),
                getLocalisationMessage,
              )}
            </strong>
          </h5>
        </FlexBox>
      </FlexBox>
      <FlexBox flex={true} className={classes.detailsRow}>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("from_warehouse", "From Warehouse")}:
          </h5>
          <h5 className={classes.detailsValue}>
            <strong>{orderInfo.getIn(["from_warehouse", "name"])}</strong>
          </h5>
        </FlexBox>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("to_warehouse", "To Warehouse")}:
          </h5>
          <h5 className={classes.detailsValue}>
            <strong>{orderInfo.getIn(["to_warehouse", "name"])}</strong>
          </h5>
        </FlexBox>
      </FlexBox>
      <FlexBox flex={true} className={classes.detailsRow}>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("from_postcode", "From Postcode")}:
          </h5>
          <h5 className={classes.detailsValue}>
            <strong>{orderInfo.getIn(["from_post_code", "name"])}</strong>
          </h5>
        </FlexBox>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("to_postcode", "To Postcode")}:
          </h5>
          <h5 className={classes.detailsValue}>
            <strong>{orderInfo.getIn(["ro_post_code", "name"])}</strong>
          </h5>
        </FlexBox>
      </FlexBox>
      <FlexBox flex={true} className={classes.detailsRow}>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("from_city", "To City")}:
          </h5>
          {orderInfo.get("from_city") && (
            <h5 className={classes.detailsValue}>
              <strong>{orderInfo.get("from_city")}</strong>
            </h5>
          )}
        </FlexBox>
        <FlexBox flex={true} direction="column">
          <h5 className={classes.detailsLabel}>
            {getLocalisationMessage("to_city", "To City")}:
          </h5>
          {orderInfo.get("to_city") && (
            <h5 className={classes.detailsValue}>
              <strong>{orderInfo.get("to_city")}</strong>
            </h5>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

OrderSortingBinDetailsCard.propTypes = {
  activeOrder: PropTypes.string,
  queueOrders: PropTypes.instanceOf(Map),

  failed: PropTypes.bool,
  order: PropTypes.instanceOf(Map),

  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderSortingBinDetailsCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.details}>
      <CardHeader
        title={
          <h5>
            {props.getLocalisationMessage("batch_details", "Batch Details")}
          </h5>
        }
      />

      <CardContent>{orderDetailsSection(props)}</CardContent>
    </div>
  );
}

export default enhancer(OrderSortingBinDetailsCard);
