import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import {
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import MUITable, {
  CUSTOM_ACTION,
  DIALOG,
  RENDER,
} from "../../components/orders-core/MUITable";
import _ from "lodash";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import {
  Add,
  ArrowBackIos,
  DeleteForever,
  Edit,
  GetApp,
  Print,
  Refresh,
  Search,
} from "@material-ui/icons";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import {
  closeDispatch,
  createReceptacle,
  deleteReceptacleItem,
  DISPATCH_BILL_PDF_URL,
  DISPATCH_CN65_PDF_URL,
  DISPATCH_CNDM_PDF_URL,
  DISPATCH_CNDMS_PDF_URL,
  DISPATCH_CNDS_PDF_URL,
  DISPATCH_TRIALNOTE_PDF_URL,
  getDispatchItem,
  reOpenReceptacle,
} from "../../api/admin/AdminIPSApi";
import { getValue } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { compose, getContext } from "recompose";
import {
  LETTERS_URL,
  RECEPTACLE_ITEM_URL,
} from "../../constants/AdminPathConstants";
import sprintf from "sprintf";
import ReceptacleItemDialog from "../../components/batch-updates-dialog/ReceptacleItemDialog";
import { getIPSPDF } from "../../helpers/FormUtils";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { formatMailCategory } from "../../components/order-create-wizard-new/ips/NewDispatchForm";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },

  rootTitle: {
    minHeight: "35px",
    padding: "12px 0 2px 20px",
  },
  title: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: 500,
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
});

function AdminDispatchItemList(props) {
  const { getLocalisationMessage } = props;

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [list, setList] = useState([]);
  const [dispatch, setDispatch] = useState({});

  const [filter, setFilter] = useState({
    refresh: false,
  });

  const dispatchId = getValue(props.params, "id");

  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  useEffect(() => {
    if (dispatchId) {
      setIsLoading(true);

      getDispatchItem(dispatchId)
        .then(res => {
          if (res && res.data) {
            setList(getValue(res, "data.receptacles", []));
            setDispatch(getValue(res, "data", {}));
            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  }, [filter]);

  const [bill, setBill] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_BILL_PDF_URL,
  });
  const [cn65, setCn65] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_CN65_PDF_URL,
  });

  const [cndm, setCndm] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_CNDM_PDF_URL,
  });

  const [cndms, setCndms] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_CNDMS_PDF_URL,
  });

  const [cnds, setCnds] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_CNDS_PDF_URL,
  });

  const [remove, setRemove] = useState(null);

  const [trialNote, setTrialNote] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_TRIALNOTE_PDF_URL,
  });

  return (
    <AdminAppLayout title={getLocalisationMessage("dispatch", "Dispatch")}>
      <ReceptacleItemDialog
        open={Boolean(id)}
        id={id}
        dispatchStatus={getValue(dispatch, "status") === "DP_CLOSE"}
        onRequestClose={() => setId(null)}
      />

      <ConfirmDialog
        onRequestClose={() => setRemove(null)}
        open={Boolean(remove)}
        confirmButtonLabel={getLocalisationMessage(`ok`, `OK`)}
        dismissButtonLabel={getLocalisationMessage(`cancel`, `Cancel`)}
        onConfirm={() =>
          deleteReceptacleItem(remove)
            .then(() => {
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_deleted",
                  "Successfully deleted",
                ),
              );
              setRemove(null);
              refreshOrderList();
            })
            .catch(error => props.showErrorMessage(error))
        }
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_receptacle",
          "Are you sure you want to remove this receptacle?",
        )}
      </ConfirmDialog>

      <FlexBox
        className={classes.rootTitle}
        justify="space-between"
        align="center"
      >
        <FlexBox direction="row" style={{ gap: 13 }} align="center">
          <IconButton
            style={{ width: 40, height: 40 }}
            onClick={() => props.setLocation(LETTERS_URL)}
          >
            <ArrowBackIos style={{ width: 20, height: 20 }} />
          </IconButton>

          <Typography variant="h2" className={classes.title}>
            {getValue(dispatch, "dispatch_id_string", "")}
          </Typography>
        </FlexBox>

        <FlexBox align="center">
          {getValue(dispatch, "status") !== "DP_CLOSE" && (
            <CustomButton
              onClick={() => {
                closeDispatch(dispatchId)
                  .then(res => {
                    if (res && res.status === "success") {
                      props.showSuccessMessage(
                        getLocalisationMessage(
                          "dispatch_closed",
                          "Dispatch Closed",
                        ),
                      );
                      refreshOrderList();
                    }
                  })
                  .catch(error => props.showErrorMessage(error));
              }}
              variant={CONTAINED}
              color={SECONDARY}
              style={{ borderRadius: "20px", width: 150 }}
            >
              {getLocalisationMessage("close_dispatch", "Close Dispatch")}
            </CustomButton>
          )}
        </FlexBox>
      </FlexBox>
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <CardContent>
          <FlexBox gutter={8} justify="center" align="center">
            <FlexBox
              direction="column"
              flex={true}
              align="center"
              justify="center"
            >
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage(
                  "origin_office",
                  "Origin Office",
                )}
                secondary={getValue(dispatch, "dispatch_id.origin_office")}
              />
            </FlexBox>

            <FlexBox
              direction="column"
              flex={true}
              align="center"
              justify="center"
            >
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage(
                  "destination_office",
                  "Destination Office",
                )}
                secondary={getValue(dispatch, "dispatch_id.destination_office")}
              />
            </FlexBox>

            <FlexBox
              direction="column"
              flex={true}
              align="center"
              justify="center"
            >
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage(
                  "mail_category",
                  "Mail category",
                )}
                secondary={formatMailCategory(
                  getValue(dispatch, "dispatch_id.mail_category"),
                  getLocalisationMessage,
                )}
              />
            </FlexBox>
            <FlexBox
              direction="column"
              flex={true}
              align="center"
              justify="center"
            >
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage(
                  "mail_sub_class",
                  "Mail Subclass",
                )}
                secondary={getValue(dispatch, "dispatch_id.mail_sub_class")}
              />
            </FlexBox>

            <FlexBox
              direction="column"
              flex={true}
              align="center"
              justify="center"
            >
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage("status", "Status")}
                secondary={getLocalisationMessage(
                  getValue(dispatch, "status"),
                  formatText(getValue(dispatch, "status")),
                )}
              />
            </FlexBox>

            <FlexBox
              direction="column"
              flex={true}
              align="center"
              justify="center"
            >
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage("weight", "Weight")}
                secondary={getValue(dispatch, "weight")}
              />
            </FlexBox>
          </FlexBox>

          {getValue(dispatch, "status") === "DP_CLOSE" && (
            <FlexBox
              style={{ gap: 13, marginBottom: 25 }}
              justify="flex-end"
              align="center"
            >
              <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                <CustomButton
                  data-cy="receipt_print"
                  onClick={() =>
                    getIPSPDF(
                      bill,
                      setBill,
                      IS_PRINTING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    bill[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage("cn_31", "CN 31")}
                </CustomButton>
                <CustomButton
                  data-cy="receipt_download"
                  onClick={() =>
                    getIPSPDF(
                      bill,
                      setBill,
                      IS_DOWNLOADING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                >
                  {bill[IS_DOWNLOADING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>

              <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                <CustomButton
                  data-cy="receipt_print"
                  onClick={() =>
                    getIPSPDF(
                      cn65,
                      setCn65,
                      IS_PRINTING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    cn65[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage("cn65", "CN65")}
                </CustomButton>
                <CustomButton
                  data-cy="receipt_download"
                  onClick={() =>
                    getIPSPDF(
                      cn65,
                      setCn65,
                      IS_DOWNLOADING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                >
                  {cn65[IS_DOWNLOADING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>

              <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                <CustomButton
                  data-cy="receipt_print"
                  onClick={() =>
                    getIPSPDF(
                      cndm,
                      setCndm,
                      IS_PRINTING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    cndm[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage("cndm", "CN DM")}
                </CustomButton>
                <CustomButton
                  data-cy="receipt_download"
                  onClick={() =>
                    getIPSPDF(
                      cndm,
                      setCndm,
                      IS_DOWNLOADING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                >
                  {cndm[IS_DOWNLOADING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>

              <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                <CustomButton
                  data-cy="receipt_print"
                  onClick={() =>
                    getIPSPDF(
                      cndms,
                      setCndms,
                      IS_PRINTING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    cndms[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage("cndms", "CNDMS")}
                </CustomButton>
                <CustomButton
                  data-cy="receipt_download"
                  onClick={() =>
                    getIPSPDF(
                      cndms,
                      setCndms,
                      IS_DOWNLOADING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                >
                  {cndms[IS_DOWNLOADING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>

              <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                <CustomButton
                  data-cy="receipt_print"
                  onClick={() =>
                    getIPSPDF(
                      cnds,
                      setCnds,
                      IS_PRINTING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    cnds[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage("cnds", "CNDS")}
                </CustomButton>
                <CustomButton
                  data-cy="receipt_download"
                  onClick={() =>
                    getIPSPDF(
                      cnds,
                      setCnds,
                      IS_DOWNLOADING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                >
                  {cnds[IS_DOWNLOADING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>

              <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                <CustomButton
                  data-cy="receipt_print"
                  onClick={() =>
                    getIPSPDF(
                      trialNote,
                      setTrialNote,
                      IS_PRINTING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    trialNote[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {getLocalisationMessage("trial_note", "Trial Note")}
                </CustomButton>
                <CustomButton
                  data-cy="receipt_download"
                  onClick={() =>
                    getIPSPDF(
                      trialNote,
                      setTrialNote,
                      IS_DOWNLOADING,
                      getValue(dispatch, "id"),
                      props.showErrorMessage,
                    )
                  }
                >
                  {trialNote[IS_DOWNLOADING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <GetApp />
                  )}
                </CustomButton>
              </ButtonGroup>
            </FlexBox>
          )}
        </CardContent>
      </Card>
      <MUITable
        filter={filter}
        isLoading={isLoading}
        list={list}
        setId={setId}
        withoutPagination={true}
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_SPACE_AROUND}
            style={{ padding: ".25rem 1rem", gap: 10 }}
          >
            <React.Fragment>
              <div className={classes.search}>
                <Search
                  fontSize="large"
                  style={{
                    color: "rgba(0, 0, 0, 0.23)",
                    top: ".5rem",
                    left: ".5rem",
                    position: "absolute",
                  }}
                />
                <ChipTextField
                  style={{
                    padding: ".5rem 1rem 0 3rem",
                  }}
                  fullWidth={true}
                  addOnBlur={false}
                  clearOnBlur={false}
                  value={parseString(filter.search)}
                  disableUnderline={true}
                  placeholder={getLocalisationMessage("type_here_to_search")}
                  onChange={v =>
                    setFilter({ ...filter, search: stringifyArray(v) })
                  }
                />
              </div>
            </React.Fragment>
            <FlexBox style={{ minWidth: 350 }} align={ALIGN_CENTER}>
              {getValue(dispatch, "status") !== "DP_CLOSE" && (
                <CustomButton
                  onClick={() => {
                    createReceptacle({
                      dispatch_id: dispatchId,
                    })
                      .then(r => {
                        if (r && r.data) {
                          props.showSuccessMessage(
                            getLocalisationMessage(
                              "receptacle_created",
                              "Receptacle Created",
                            ),
                          );
                          props.setLocation(
                            sprintf(RECEPTACLE_ITEM_URL, r.data.id, dispatchId),
                          );
                        }
                      })
                      .catch(error => props.showErrorMessage(error));
                  }}
                  startIcon={<Add />}
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{ borderRadius: "20px", marginRight: 10 }}
                >
                  {getLocalisationMessage(
                    "create_new_receptacle",
                    "Create New Receptacle",
                  )}
                </CustomButton>
              )}

              <IconButton
                className={isLoading && classes.refresh}
                tooltip={getLocalisationMessage("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
            </FlexBox>
          </FlexBox>
        }
        columns={[
          {
            type: DIALOG,
            name: "identifier",
            label: getLocalisationMessage("identifier", "Identifier"),
            align: "center",
            keyName: "receptacle_id_string",
          },
          {
            type: RENDER,
            name: "destination_office",
            label: getLocalisationMessage(
              "destination_office",
              "Destination Office",
            ),
            render: row => _.get(row, `receptacle_id.destination_office`, ""),
          },
          {
            type: RENDER,
            name: "mail_category",
            label: getLocalisationMessage("mail_category", "Mail category"),
            render: row =>
              formatMailCategory(
                _.get(row, `receptacle_id.mail_category`, ""),
                getLocalisationMessage,
              ),
          },
          {
            type: RENDER,
            name: "mail_subclass",
            label: getLocalisationMessage("mail_sub_class", "Mail Subclass"),
            render: row => _.get(row, `receptacle_id.mail_sub_class`, ""),
          },
          {
            type: RENDER,
            name: "status",
            label: getLocalisationMessage("status", "Status"),
            render: row =>
              getLocalisationMessage(
                _.get(row, `status`, ""),
                formatText(_.get(row, `status`, "")),
              ),
          },
          {
            type: RENDER,
            name: "status",
            label: getLocalisationMessage("postal_status", "Postal Status"),
            render: row => formatText(_.get(row, `postal_status`, "")),
          },
          {
            type: RENDER,
            name: "weight",
            label: getLocalisationMessage("weight", "weight"),
            render: row =>
              _.get(row, `weight`) || getLocalisationMessage("na", "N/A"),
          },
          {
            type: CUSTOM_ACTION,
            label: getLocalisationMessage("action", "Action"),
            render: row =>
              getValue(dispatch, "status") !== "DP_CLOSE" && (
                <FlexBox>
                  <IconButton
                    onClick={() =>
                      _.get(row, `status`) === "RC_CLOSE"
                        ? reOpenReceptacle(_.get(row, "id"))
                            .then(() => {
                              props.setLocation(
                                sprintf(
                                  RECEPTACLE_ITEM_URL,
                                  _.get(row, `id`),
                                  dispatchId,
                                ),
                              );
                            })
                            .catch(error => props.showErrorMessage(error))
                        : props.setLocation(
                            sprintf(
                              RECEPTACLE_ITEM_URL,
                              _.get(row, `id`),
                              dispatchId,
                            ),
                          )
                    }
                  >
                    <Edit />
                  </IconButton>

                  <IconButton onClick={() => setRemove(_.get(row, `id`))}>
                    <DeleteForever style={{ color: "#F44336" }} />
                  </IconButton>
                </FlexBox>
              ),
          },
        ]}
      />
    </AdminAppLayout>
  );
}

AdminDispatchItemList.propTypes = {
  getLocalisationMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  params: PropTypes.object,
};

const mapStateToProps = state => ({
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

export default enhancer(AdminDispatchItemList);
