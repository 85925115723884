import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { marketplaceDefaultOrderCreationEnabled } from "../../reducers/MarketplaceReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import AdminWarehouseForm from "../../components/admin/AdminWarehouseForm";
import { getPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import {
  getWarehousePredictions,
  getCachedWarehouse,
} from "../../api/admin/AdminWarehouseApi";
// import AdminWarehouseFormWithoutMap from "../../components/admin/AdminWarehouseFormWithuotMap";

const enhancer = compose(
  withContext(
    {
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    () => ({
      getPostcodePredictions,
    }),
  ),
);
// connect(state => ({
//   isDefaultOrderCreation: marketplaceDefaultOrderCreationEnabled(state),
// })),

AdminWarehouseItemWrapper.propTypes = {
  isDefaultOrderCreation: PropTypes.bool,
};

function AdminWarehouseItemWrapper(props) {
  return (
    <AdminWarehouseForm
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getWarehousePredictions={getWarehousePredictions}
      getCachedWarehouse={getCachedWarehouse}
      {...props}
    />
  );
}

export default enhancer(AdminWarehouseItemWrapper);
