import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { OrderedSet } from "immutable";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import { Button, CardActions, CardContent, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import {
  MATCHES_FN,
  MATCHES_FN_AND_NOT_MATCHES_FN,
  NOT_MATCHES_FN,
  OVER_EVERY_FN,
  OVER_SOME_FN,
} from "../../helpers/Rule";
import { pureComponent, renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatText,
  parseOnlyPositiveInteger,
} from "../../helpers/FormatUtils";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COUNT,
  COURIER_TYPE,
  JURISDICTION_TO,
  RULE_NAME,
  TO_POSTCODE,
  TO_REGION,
  TRANSPORTATION_TYPE,
  VALUED,
} from "../../constants/OrderSortingField";
import { makeStyles } from "@material-ui/core/styles";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { orderStatusSorting } from "../../constants/OrderStatusCodes";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import CountriesType, { DEFAULT } from "../../constants/CountriesType";
import TransportationTypes from "../../constants/TransportationType";
import FormCheckbox from "../form/FormCheckbox";
import useSheet from "react-jss";

const OrderSortingField = OrderedSet.of(
  TO_POSTCODE,
  JURISDICTION_TO,
  RULE_NAME,
  COURIER_TYPE,
  COUNT,
  TRANSPORTATION_TYPE,
  VALUED,
  TO_REGION,
);

const pickId = fp.pick(["id"]);
const getId = fp.get("id");
const countriesTypeOptions = OrderedSet.of(DEFAULT).merge(CountriesType);
const transportationTypes = OrderedSet.of(null).merge(TransportationTypes);
const getValues = getFormValues("OrderSortingRuleDialog");

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(1),
  },
  verticalMargin: {
    marginBottom: theme.spacing(1),
  },
  noMargin: { marginTop: 0 },
  field: {
    "&>.MuiTextField-root": {
      "&>label": {
        width: 120,
      },
    },
  },
}));

const formatOrderStatus = (status, getLocalisationMessage) => {
  switch (status) {
    default:
      return formatOrderStatusCodeForLocalisation(
        status,
        getLocalisationMessage,
      );
  }
};

OrderRuleMatch.propTypes = {
  countryId: PropTypes.number,
  fields: PropTypes.object,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function OrderRuleMatch({ fields, getLocalisationMessage }) {
  const value = `${fields.name}[0].value`;

  const classes = useStyles();

  return (
    <FlexBox direction="column" flex={true}>
      <FlexBox flex={true} gutter={8}>
        <FlexBox flex={true} style={{ paddingTop: 20 }}>
          <FormAutoComplete
            name={`${value}.status`}
            fullWidth={true}
            label={getLocalisationMessage("status", "Status")}
            options={orderStatusSorting}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type To Search...",
            )}
            formatOption={x => formatOrderStatus(x, getLocalisationMessage)}
          />
        </FlexBox>

        <FlexBox flex={true} style={{ paddingTop: 20 }}>
          <FormCourierTypeSelectField
            fullWidth={true}
            name={`${value}.courierType`}
            label={getLocalisationMessage("service_type", "Service Type")}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormJMAutoComplete
            fullWidth={true}
            name={`${value}.toJurisdiction`}
            label={getLocalisationMessage("jurisdiction_to", "Jurisdiction To")}
            margin="normal"
            renderOption={option => (
              <FlexBox direction="column">
                <span>{option.name}</span>
                <span
                  style={{
                    fontSize: ".8rem",
                    fontStyle: "italic",
                  }}
                >
                  {option.value.hierarchy.map((item, index, arr) =>
                    index === arr.length - 1 ? item.name : `${item.name} > `,
                  )}
                </span>
              </FlexBox>
            )}
            className={classes.field}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormTextField
            margin="normal"
            fullWidth={true}
            name={`${value}.toPostcode`}
            label={getLocalisationMessage("postcode", "Postcode")}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormWarehouseAutoComplete
            fullWidth={true}
            margin="normal"
            parseOption={pickId}
            autoSelectOneOption={false}
            name={`${value}.warehouse`}
            label={getLocalisationMessage("warehouse", "Warehouse")}
            hintText={getLocalisationMessage(
              "type_here_to_search",
              "Type to search ...",
            )}
            className={classes.field}
          />
        </FlexBox>

        <FlexBox flex={true} style={{ paddingTop: 20 }}>
          <FormSelectField
            fullWidth={true}
            options={countriesTypeOptions}
            name={`${value}.countriesType`}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            label={getLocalisationMessage("countries_type", "Countries Type")}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormSupplierAutoComplete
            fullWidth={true}
            margin="normal"
            parseOption={pickId}
            autoSelectOneOption={false}
            name={`${value}.supplier`}
            label={getLocalisationMessage("supplier", "Supplier")}
            hintText={getLocalisationMessage(
              "type_here_to_search",
              "Type to search ...",
            )}
          />
        </FlexBox>

        <FlexBox flex={true} style={{ paddingTop: 20 }}>
          <FormSelectField
            fullWidth={true}
            options={transportationTypes}
            name={`${value}.transportationType`}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            label={getLocalisationMessage(
              "transportation_type",
              "Transportation Type",
            )}
          />
        </FlexBox>

        <FlexBox>
          <FormCheckbox
            name={`${value}.valuable`}
            label={getLocalisationMessage("valuable", "Valuable")}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

OrderRulePredicateArray.propTypes = {
  fields: PropTypes.object,
  countryId: PropTypes.number,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function OrderRulePredicateArray({
  fields,
  getLocalisationMessage,
  countryId,
  values,
}) {
  return (
    <FlexBox flex={true} direction="column" style={{ marginLeft: "16px" }}>
      {fields.map((name, index) => (
        <OrderRulePredicate
          key={index}
          name={name}
          countryId={countryId}
          values={values}
          getLocalisationMessage={getLocalisationMessage}
          onRemoveClick={() => fields.remove(index)}
          onCopyClick={() => fields.insert(index + 1, fields.get(index))}
        />
      ))}

      <div style={{ marginTop: 10 }}>
        <Button onClick={() => fields.push({})}>
          {getLocalisationMessage("add_argument", "Add Argument")}
        </Button>
      </div>
    </FlexBox>
  );
}

OrderRulePredicateArgs.propTypes = {
  root: PropTypes.string,
  input: PropTypes.object,
  values: PropTypes.object,
  countryId: PropTypes.number,
  getLocalisationMessage: PropTypes.func,
};

function OrderRulePredicateArgs({
  root,
  input,
  getLocalisationMessage,
  countryId,
  values,
}) {
  const name = `${root}.${input.value}.args`;

  switch (input.value) {
    case OVER_SOME_FN:
    case OVER_EVERY_FN:
      return (
        <FieldArray
          name={name}
          props={{ getLocalisationMessage, countryId, values }}
          component={OrderRulePredicateArray}
        />
      );

    case MATCHES_FN:
    case NOT_MATCHES_FN:
      return (
        <FieldArray
          name={name}
          component={OrderRuleMatch}
          props={{ getLocalisationMessage, countryId, values }}
        />
      );

    default:
      return null;
  }
}

OrderRulePredicate.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  countryId: PropTypes.number,
  onCopyClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function OrderRulePredicate({ countryId, values, ...props }) {
  const classes = useStyles();
  return (
    <div>
      <FlexBox direction="column" style={{ marginTop: 10 }}>
        <FlexBox align="center">
          <FormSelectField
            options={MATCHES_FN_AND_NOT_MATCHES_FN}
            name={`${props.name}.fn`}
            formatOption={x => props.getLocalisationMessage(x, formatText(x))}
            label={props.getLocalisationMessage("function", "Function")}
          />

          {props.onCopyClick && (
            <Button onClick={props.onCopyClick} className={classes.formControl}>
              {props.getLocalisationMessage("copy", "Copy")}
            </Button>
          )}

          {props.onRemoveClick && (
            <Button
              onClick={props.onRemoveClick}
              className={classes.formControl}
            >
              {props.getLocalisationMessage("remove", "Remove")}
            </Button>
          )}
        </FlexBox>

        <FlexBox>
          <Field
            root={props.name}
            name={`${props.name}.fn`}
            props={{
              getLocalisationMessage: props.getLocalisationMessage,
              countryId,
              values,
            }}
            component={OrderRulePredicateArgs}
          />
        </FlexBox>
      </FlexBox>
    </div>
  );
}

const enhancer = compose(
  renderIf("open"),
  withContext(
    {
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  connect(state => ({
    marketplaceCountry: getMarketplaceCountry(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(
        fp.flow(fp.get("initialValues"), fp.toPlainObject, initial => {
          const mapArgs = fp.map(mapPredicate);

          function mapPredicate(predicate) {
            if (!predicate) {
              return {};
            }

            return !predicate.fn
              ? { value: predicate.value }
              : {
                  fn: predicate.fn,
                  [predicate.fn]: {
                    args: mapArgs(predicate.args),
                  },
                };
          }

          return {
            ...initial,
            predicate: mapPredicate(initial.predicate),
          };
        }),
      );

    const onSubmit = (values, dispatch, props) => {
      const mapArgs = fp.flow(fp.get("args"), fp.map(mapPredicate));

      function mapPredicate(predicate) {
        if (!fp.isUndefined(predicate.value)) {
          const {
            toJurisdiction,
            supplier,
            countriesType,
            ...value
          } = predicate.value;

          if (getId(toJurisdiction)) {
            value.toJurisdiction = pickId(toJurisdiction);
          }

          if (countriesType && countriesType !== DEFAULT) {
            value.countriesType = countriesType;
          }

          return { value };
        }

        if (predicate.fn) {
          return {
            fn: predicate.fn,
            args: mapArgs(predicate[predicate.fn]),
          };
        }

        return {};
      }

      return props.actualOnSubmit({
        ...values,
        predicate: mapPredicate(values.predicate),
      });
    };

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,

        actualOnSubmit: props.onSubmit,
        onSubmit: props.onSubmit && onSubmit,
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: !module.hot,
    form: "OrderSortingRuleDialog",
    validate: (values, props) => ({
      name:
        fp.isEmpty(values.name) &&
        props.getLocalisationMessage("enter_rule_name", "Enter rule name"),
      groupBy:
        fp.isEmpty(values.groupBy) &&
        props.getLocalisationMessage("this_field_is_required"),
      code:
        fp.isEmpty(values.code) &&
        props.getLocalisationMessage("enter_rule_code", "Enter rule code"),
    }),
  }),
  connect(state => ({
    values: getValues(state),
  })),
  pureComponent(fp.pick(["submitting", "values"])),
  useSheet({
    modal: {
      maxWidth: "1400px",
      minWidth: "1400px",
      minHeight: "auto",
    },
  }),
);

OrderSortingRuleDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  marketplaceCountry: PropTypes.object,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
};

function OrderSortingRuleDialog(props) {
  const { getLocalisationMessage, values } = props;

  const classes = useStyles();

  const groupBy = fp.get("groupBy", values);
  const countryId = props.marketplaceCountry.get("id", null);

  return (
    <ModalPaper
      open={props.open}
      paperClassName={props.classes.modal}
      onRequestClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column" flex="none">
        <FlexBox gutter={8} direction="column">
          <FlexBox direction="column" container={16}>
            <Paper>
              <CardContent>
                <FlexBox gutter={16} style={{ alignItems: "flex-start" }}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="code"
                      fullWidth={true}
                      label={getLocalisationMessage("rule_code", "Rule Code")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="name"
                      fullWidth={true}
                      label={getLocalisationMessage("rule_name", "Rule Name")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormChipAutoComplete
                      name="groupBy"
                      fullWidth={true}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                      options={OrderSortingField}
                      label={getLocalisationMessage("group_by", "Group By")}
                    />
                  </FlexBox>

                  {fp.includes("count", groupBy) && (
                    <FlexBox flex={true}>
                      <FormTextField
                        name="count"
                        parseOnBlur={parseOnlyPositiveInteger}
                        fullWidth={true}
                        label={getLocalisationMessage("count", "Count")}
                      />
                    </FlexBox>
                  )}

                  <FlexBox flex={true} direction="column">
                    <FormWarehouseAutoComplete
                      fullWidth={true}
                      className={classes.verticalMargin}
                      parseOption={pickId}
                      name="nextWarehouse"
                      label={getLocalisationMessage(
                        "next_warehouse",
                        "Next Warehouse",
                      )}
                      hintText={getLocalisationMessage(
                        "type_here_to_search",
                        "Type to search ...",
                      )}
                    />

                    <FormWarehouseAutoComplete
                      fullWidth={true}
                      className={classes.verticalMargin}
                      parseOption={pickId}
                      name="toWarehouse"
                      label={getLocalisationMessage(
                        "destination_warehouse",
                        "Destination Warehouse",
                      )}
                      hintText={getLocalisationMessage(
                        "type_here_to_search",
                        "Type to search ...",
                      )}
                    />

                    <FormAutoComplete
                      name="status"
                      fullWidth={true}
                      className={classes.verticalMargin}
                      label={getLocalisationMessage("status", "Status")}
                      options={orderStatusSorting}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type To Search...",
                      )}
                      formatOption={x =>
                        formatOrderStatus(x, getLocalisationMessage)
                      }
                    />

                    <FormTextField
                      name="postcode"
                      fullWidth={true}
                      label={getLocalisationMessage("postcode", "Postcode")}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Paper>
          </FlexBox>

          <FlexBox direction="column">
            <Paper>
              <CardContent>
                <OrderRulePredicate
                  name="predicate"
                  getLocalisationMessage={getLocalisationMessage}
                  countryId={countryId}
                  values={props.values}
                />
              </CardContent>
            </Paper>
          </FlexBox>

          <FlexBox direction="column">
            <Paper>
              <CardActions>
                <FlexBox justify="flex-end">
                  <Button onClick={props.onRequestClose}>
                    {getLocalisationMessage("dismiss", "Dismiss")}
                  </Button>
                  <Button onClick={props.handleSubmit}>
                    {getLocalisationMessage("submit", "Submit")}
                  </Button>
                </FlexBox>
              </CardActions>
            </Paper>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(OrderSortingRuleDialog);
