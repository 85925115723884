import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toMapAreasListFilter = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),

    areaId: types.ID,
    countryId: types.ID,
    marketplaceId: types.ID,

    marketplaceIds: queryTypes.IDSeq,

    toDate: types.string,
    fromDate: types.string,
  }),
);
