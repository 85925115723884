import { Observable } from "rxjs";
import React from "react";
import { List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/TimeSlotListFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  removeTimeSlotAvailability,
  getTimeSlotAvailabilityList,
} from "../../api/admin/AdminTimeSlotAvailabilityApi";
import AdminTimeSlotAvailabilityFilterDialog from "../../wrappers/admin/AdminTimeSlotAvailabilityFilterDialog";
import AdminTimeSlotAvailabilityEditDialogWrapper from "../../wrappers/admin/AdminTimeSlotAvailabilityEditDialogWrapper";
import AdminTimeslotAvailabilityCreateDialogWrapper from "../../wrappers/admin/AdminTimeslotAvailabilityCreateDialogWrapper";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DeleteTimeslotDialog from "../../components/deprecated/DeleteTimeslotDialog";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const TIMESLOT_FILTER_DIALOG_HASH = "#TSFDH";

const enhancer = compose(
  useSheet({
    paddingLeft: { paddingLeft: "8px" },
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { showSuccessMessage, showErrorMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getTimeSlotAvailabilityList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminTimeslotAvailabilityList.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  setLocationQuery: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  removeTimeSlotAvailability: PropTypes.func,
  replaceLocationHash: PropTypes.func,

  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminTimeslotAvailabilityList(props) {
  const { classes, location, getLocalisationMessage } = props;
  const removingId = fp.toFinite(location.query.remove);
  const useSolr = props.filter.getBoolValue("use_solr");
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <AdminAppLayout
      slug="timeslot_availability"
      title={getLocalisationMessage(
        "timeslot_availability",
        "Timeslot Availability",
      )}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      <DeleteTimeslotDialog
        open={removingId > 0}
        onRequestClose={() => props.setLocationQuery(fp.omit(["remove"]))}
        onSubmit={() => removeTimeSlotAvailability(removingId)}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );

          props.onRequestRefresh(props.filter);
          props.setLocationQuery(fp.unset(["remove"]));
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);

          props.onRequestRefresh(props.filter);
          props.setLocationQuery(fp.unset(["remove"]));
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_timeslot_availability",
          "Are you sure you want to remove timeslot availability?",
        )}
      </DeleteTimeslotDialog>

      <AdminTimeSlotAvailabilityFilterDialog
        open={location.hash === TIMESLOT_FILTER_DIALOG_HASH}
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <AdminTimeslotAvailabilityCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminTimeSlotAvailabilityEditDialogWrapper
        timeslotAvailabilityId={fp.toFinite(location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Tooltip
                title={getLocalisationMessage(
                  "create_new_timeslot_availability",
                  "Create New Timeslot Availability",
                )}
              >
                <IconButton
                  onClick={() => props.setLocationQuery(fp.set("create", true))}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            )}

            <Link to={updateHash(location, TIMESLOT_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={getLocalisationMessage(
                  "filter_timeslots",
                  "Filter Timeslots",
                )}
              >
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>
          </div>
        }
      >
        <DataListColumn
          width={96}
          label={getLocalisationMessage("timeslot", "Timeslot")}
          dataKey="timeslot"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              {!props.isAdminViewer ? (
                <Link
                  to={updateQuery(
                    location,
                    fp.set("view", row.cellData.get("id")),
                  )}
                >
                  {getLocalisationMessage(
                    row.cellData.getIn(["timeslot", "name"]),
                    row.cellData.getIn(["timeslot", "name"]),
                  )}
                </Link>
              ) : (
                getLocalisationMessage(
                  row.cellData.getIn(["timeslot", "name"]),
                  row.cellData.getIn(["timeslot", "name"]),
                )
              )}
            </strong>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("supplier", "Supplier")}
          dataKey="supplier"
          disableSort={true}
          cellRenderer={row => (
            <div>{row.cellData.getIn(["supplier", "name"]) || NA}</div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("neighborhood", "Neighborhood")}
          dataKey="neighborhood"
          disableSort={true}
          cellRenderer={row => (
            <div>{row.cellData.getIn(["neighborhood", "name"]) || NA}</div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("cutoff_time", "Cutoff Time")}
          dataKey="start_time"
          disableSort={true}
          cellRenderer={row => (
            <div>{row.cellData.get("cutoff_time") || NA}</div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("service_type", "Service Type")}
          dataKey="courier_type"
          disableSort={true}
          cellRenderer={row => (
            <div>
              {getLocalisationMessage(
                row.cellData.get("courier_type"),
                row.cellData.get("courier_type"),
              ) || NA}
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("capacity", "Capacity")}
          dataKey="capacity"
          disableSort={true}
          cellRenderer={row => <div>{row.cellData.get("capacity") || NA}</div>}
        />

        {!props.isAdminViewer && (
          <DataListColumn
            width={128}
            label={getLocalisationMessage("action", "Action")}
            dataKey="type"
            disableSort={true}
            cellRenderer={row => (
              <Button
                onClick={() =>
                  props.setLocationQuery(
                    fp.set("remove", row.cellData.get("id")),
                  )
                }
              >
                {getLocalisationMessage("remove", "Remove")}
              </Button>
            )}
          />
        )}
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminTimeslotAvailabilityList);
