import React from "react";
import Immutable from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import fp from "lodash/fp";
import PolylineWrapper from "../maps/PolylineWrapper";
import VehicleMarker from "../maps/VehicleMarker2";
import { FitBounds } from "react-google-map-components";
import OrderMarker from "../maps/OrderMarker";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { shouldOrderBeAssigned } from "../../helpers/OrderHelper";

const getOrderPickupLatLng = order => ({
  lat: order.getIn(["sender_data", "lat"]),
  lng: order.getIn(["sender_data", "lon"]),
});
const getOrderDropoffLatLng = order => ({
  lat: order.getIn(["recipient_data", "lat"]),
  lng: order.getIn(["recipient_data", "lon"]),
});

const enhancer = compose();

OrderDriverLocationsMap.propTypes = {
  orderId: PropTypes.number,
  isLiveStream: PropTypes.bool,
  order: PropTypes.instanceOf(Immutable.Map),
  completeRoute: PropTypes.instanceOf(Immutable.List),
  inCompleteRoute: PropTypes.instanceOf(Immutable.List),
  liveDriverLocations: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.instanceOf(Immutable.OrderedMap),
  ]),
  onDriverClick: PropTypes.func,
  driverItem: PropTypes.number,
};

function OrderDriverLocationsMap(props) {
  const { order, isLiveStream } = props;
  const pickupLocation = getOrderPickupLatLng(props.order);
  const dropoffLocation = getOrderDropoffLatLng(props.order);

  return (
    <div>
      {isLiveStream &&
        props.liveDriverLocations.map((x, idx) => {
          if (shouldOrderBeAssigned(order)) {
            return (
              <VehicleMarker
                key={idx}
                onClick={() =>
                  props.onDriverClick(x.getIn(["location", "driver_id"]))
                }
                rotation={x.getIn(["location", "angle"])}
                position={{
                  lat: x.getIn(["location", "lat"]),
                  lng: x.getIn(["location", "lon"]),
                }}
              />
            );
          } else if (
            fp.toFinite(x.getIn(["location", "driver_id"])) ===
            fp.toFinite(order.getIn(["driver", "id"]))
          ) {
            return (
              <VehicleMarker
                onClick={() =>
                  props.onDriverClick(x.getIn(["location", "driver_id"]))
                }
                key={idx}
                rotation={x.getIn(["location", "angle"])}
                position={{
                  lat: x.getIn(["location", "lat"]),
                  lng: x.getIn(["location", "lon"]),
                }}
              />
            );
          }

          return null;
        })}

      {isValidCoordinates(pickupLocation) &&
        isValidCoordinates(dropoffLocation) && (
          <div>
            <FitBounds latLngBounds={[pickupLocation, dropoffLocation]} />
            <OrderMarker size={60} position={pickupLocation} pickup={false} />
            <OrderMarker size={60} position={dropoffLocation} />
            {isLiveStream &&
              props.liveDriverLocations.get(props.driverItem) &&
              props.driverItem && (
                <PolylineWrapper
                  strokeColor="#e93333"
                  strokeWeight={4}
                  strokeOpacity={1}
                  origin={{
                    lat: props.liveDriverLocations.getIn([
                      props.driverItem,
                      "location",
                      "lat",
                    ]),
                    lng: props.liveDriverLocations.getIn([
                      props.driverItem,
                      "location",
                      "lon",
                    ]),
                  }}
                  destination={{
                    lat: pickupLocation.lat,
                    lng: pickupLocation.lng,
                  }}
                />
              )}
            <PolylineWrapper
              strokeColor="#000055"
              strokeWeight={4}
              strokeOpacity={1}
              origin={pickupLocation}
              destination={dropoffLocation}
            />
          </div>
        )}
    </div>
  );
}

export default enhancer(OrderDriverLocationsMap);
