import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import FlexBox from "../../components/ui-core/FlexBox";
import { ArrowBackIos, Save } from "@material-ui/icons";
import FormTextField from "../../components/form/FormTextFieldND";
import { Field, reduxForm } from "redux-form";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { connect } from "react-redux";
import {
  createRole,
  fetchPermissionList,
  fetchRoleById,
  updateRole,
} from "../../actions/AdminPermissionsManagementActions";
import {
  getPermissionList,
  getRole,
  getRoleLoading,
  isPermissionLoading,
} from "../../reducers/AdminPermissionManagementReducer";
import { usePermissionForm } from "../../hooks/usePermissionForm";
import fp from "lodash/fp";
import { difference, range } from "lodash";
import FormSelectField from "../../components/form/FormSelectField";
import { formatText } from "../../helpers/FormatUtils";
import PropTypes from "prop-types";
import PageLoading from "../../components/ui-core/PageLoading";
import { SECONDARY } from "../../components/form/FormTextField";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "10px 20px",
  },
  breadCrumb: {
    fontSize: 11,
    textTransform: "uppercase",
    color: "#777",
  },
  title: {
    color: "#555",
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 4,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  topPaper: {
    width: "100%",
    marginTop: 10,
  },
  paper: {
    width: "100%",
    marginTop: 20,
  },
  buttons: {
    width: 200,
  },
  dialogContent: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  ul: {
    marginLeft: 10,
    paddingLeft: 10,
    border: "1px solid black",
  },
  li: {
    p: {
      border: "1px solid black",
    },
  },
});

const COL_COUNT = 7;
const GROUP = "GROUP";
const FEATURE_GROUP = "FEATURE_GROUP";

const isGroupPermissionChecked = (children, checkedPermissions) =>
  difference(children, checkedPermissions).length === 0;

const mapPermissionChidren = (chilren, { onChange, value = [] }) =>
  chilren
    .map((child, index) => {
      if (child.get("type") === "GROUP" && child.get("children")) {
        return (
          <div style={{ marginTop: 10 }} key={index}>
            <FormControlLabel
              name={`checkbox${index}`}
              control={
                <Checkbox
                  checked={value.includes(child.get("code"))}
                  onChange={(_, checked) => {
                    const reduxValues = [...value];
                    if (checked) {
                      reduxValues.push(child.get("code"));
                    } else {
                      reduxValues.splice(
                        reduxValues.indexOf(child.get("code")),
                        1,
                      );
                    }
                    onChange(reduxValues);
                  }}
                  name="permissionGroup"
                />
              }
              label={child.get("name")}
            />
            <div style={{ paddingLeft: 20 }}>
              {/* {renderChildren(child.get("children"))} */}
            </div>
          </div>
        );
      }
      return (
        <div style={{ marginTop: 10 }} key={index}>
          <FormControlLabel
            name={`checkbox${index}`}
            control={
              <Checkbox
                checked={value.includes(child.get("code"))}
                onChange={(_, checked) => {
                  const reduxValues = [...value];
                  if (checked) {
                    reduxValues.push(child.get("code"));
                  } else {
                    reduxValues.splice(
                      reduxValues.indexOf(child.get("code")),
                      1,
                    );
                  }
                  onChange(reduxValues);
                }}
                name="permissionGroup"
              />
            }
            label={child.get("name")}
          />
        </div>
      );
    })
    .toJS();

const mapPermissionChildren = (children, { onChange, value }) => {
  const ROW_COUNT = Math.ceil(children.size / COL_COUNT);

  const mappedChildren = mapPermissionChidren(children, { onChange, value });
  const result = [];

  range(Math.min(children.size, COL_COUNT)).forEach(() => {
    if (mappedChildren.length) {
      result.push(
        <FlexBox flex="true" direction="column">
          {mappedChildren.splice(0, ROW_COUNT + 1)}
        </FlexBox>,
      );
    } else {
      result.push(<FlexBox flex="true" direction="column" />);
    }
  });

  return <FlexBox direction="row">{result}</FlexBox>;
};

const mapPermission =
  (classes, { input: { onChange, value = [] }, meta: { error, touched } }) =>
  (list) =>
    list.map((permission, index) => (
      <Card className={classes.paper}>
        <CardHeader
          title={
            <FormControlLabel
              name={`checkbox${index}`}
              style={{ fontSize: 16, fontWeight: "bold" }}
              control={
                <Checkbox
                  checked={isGroupPermissionChecked(
                    permission
                      .get("children")
                      .map((child) => child.get("code"))
                      .toJS(),
                    value,
                  )}
                  indeterminate={
                    !isGroupPermissionChecked(
                      permission
                        .get("children")
                        .map((child) => child.get("code"))
                        .toJS(),
                      value,
                    )
                  }
                  onChange={(_, checked) => {
                    const children = permission
                      .get("children")
                      .map((child) => child.get("code"))
                      .toJS();
                    const reduxValues = [...value];
                    if (checked) {
                      children.forEach(
                        (child) =>
                          !reduxValues.includes(child) &&
                          reduxValues.push(child),
                      );
                    } else {
                      children.forEach(
                        (child) =>
                          reduxValues.includes(child) &&
                          reduxValues.splice(reduxValues.indexOf(child), 1),
                      );
                    }
                    onChange(reduxValues);
                  }}
                  name={`permissionGroup${index}`}
                />
              }
              label={
                <Typography variant="h6">
                  {permission.get("name")}
                  {touched && !index && error ? (
                    <span style={{ fontSize: 11, marginTop: 8, color: "red" }}>
                      {" "}
                      ({error}){" "}
                    </span>
                  ) : null}
                </Typography>
              }
            />
          }
          titleTypographyProps={{ className: classes.cardTitle }}
          style={{ height: 45 }}
        />
        <Divider variant="fullWidth" />
        <CardContent>
          {mapPermissionChildren(permission.get("children"), {
            onChange,
            value,
          })}
        </CardContent>
      </Card>
    ));

const AdminPermissionsForm = (props) => {
  const [groupState, setgroupState] = React.useState(false);

  const handleChange = () => setgroupState(!groupState);
  const classes = useStyles();
  const { navigation, statusTypes } = usePermissionForm(props);

  return (
    <form onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.isLoading} />
      <AdminAppLayout title="Permissions Form">
        <div className={classes.root}>
          <div className={classes.breadCrumb}> Settings </div>
          <FlexBox className={classes.title} direction="row" align="center">
            <FlexBox flex={true}> Roles </FlexBox>
            <Button
              color="secondary"
              startIcon={<ArrowBackIos />}
              variant="outlined"
              className={classes.buttons}
              onClick={navigation.goBack}
            >
              {" "}
              DISCARD{" "}
            </Button>
            <Button
              color="secondary"
              startIcon={<Save />}
              variant="contained"
              style={{ marginLeft: 10, color: "white" }}
              className={classes.buttons}
              type="submit"
            >
              {" "}
              SAVE{" "}
            </Button>
          </FlexBox>
          <Card className={classes.topPaper}>
            <CardHeader
              title="Details"
              titleTypographyProps={{ className: classes.cardTitle }}
              style={{ height: 45 }}
            />
            <Divider variant="fullWidth" />
            <CardContent>
              <FlexBox direction="row" gutter={16} align="top">
                <FlexBox flex={true}>
                  <FormTextField
                    name="name"
                    fullWidth={true}
                    placeholder="Name"
                    label="Name"
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="description"
                    fullWidth={true}
                    placeholder="Description"
                    label="Description"
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="status"
                    label="Status"
                    fullWidth={true}
                    options={statusTypes}
                    formatOption={formatText}
                    variant="outlined"
                  />
                </FlexBox>
                <FlexBox>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={groupState}
                        onChange={handleChange}
                        color={SECONDARY}
                        name="checkedB"
                      />
                    }
                    label="group"
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
          {groupState ? (
            <Field
              name="permissions"
              component={(valueProps) =>
                fp.flow(
                  fp.get("permissionGroupList"),
                  mapPermission(classes, valueProps),
                )(props)
              }
            />
          ) : (
            <Field
              name="permissions"
              component={(valueProps) =>
                fp.flow(
                  fp.get("permissionSimpleList"),
                  mapPermission(classes, valueProps),
                )(props)
              }
            />
          )}
        </div>
      </AdminAppLayout>
    </form>
  );
};

AdminPermissionsForm.propTypes = {
  isGroupPermissionChecked: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  reset: PropTypes.func,
};

export default connect(
  (state, props) => ({
    isLoading: getRoleLoading(state),
    permissionSimpleList: getPermissionList(state).filter(
      (v) => v.get("type") === GROUP,
    ),
    permissionGroupList: getPermissionList(state).filter(
      (v) => v.get("type") === FEATURE_GROUP,
    ),
    role: getRole(state),
    isPermissionLoading: isPermissionLoading(state),
    initialValues: fp.get("location.query.role_id", props)
      ? {
          name: getRole(state).get("name") || "",
          description: getRole(state).get("description") || "",
          status: getRole(state).get("status") || "",
          permissions: getRole(state).get("permissions")
            ? getRole(state).get("permissions").toJS()
            : [],
        }
      : {
          name: "",
          description: "",
          status: "",
          permissions: [],
        },
  }),
  { fetchPermissionList, createRole, updateRole, fetchRoleById },
)(
  reduxForm({
    enableReinitialize: true,
    form: "AdminPermissionsForm",
    validate: (values) => ({
      name: !values.name && "Введите название роли",
      status: !values.status && "Выберите Статус",
      permissions: !values.permissions.length && "Выберите хотя бы один роль!",
    }),
    onSubmit: (config, _, props) => {
      const roleId = fp.get("location.query.role_id", props);
      if (roleId) {
        props.updateRole(roleId, config, props.router.goBack);
      } else {
        props.createRole(config, props.router.goBack);
      }
    },
    initialValues: {
      name: "",
      description: "",
      status: "",
      permissions: [],
    },
  })(AdminPermissionsForm),
);
