import React, { useState } from "react";
import { List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { CardContent, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { pureComponent } from "../../../helpers/HOCUtils";
import { isEqualData } from "../../../helpers/DataUtils";
import ResponseError from "../../../helpers/ResponseError";
import {
  estimateBinCategory,
  FAILED_BIN,
  getBinByJurisdiction,
  getDestinationPostcode,
  getSingleDestinationJurisdiction,
  NOTFOUND_BIN,
  TYPE_BATCH,
  UNASSIGNED_BIN,
  UNPAID_BIN,
  UNSORTED_BIN,
} from "../../../helpers/OrderOutboundSortingHelper";
import { OrderSortingDB } from "../../../firebase/OrderSortingDB";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  ASSIGNED_TO_COURIER,
  IN_SORTING_FACILITY,
  ON_HOLD,
  UNASSIGNED,
} from "../../../constants/OrderStatusCodes";
import AdminBatchUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import FormDialog from "../../../components/form/FormDialog";
import FlexBox from "../../../components/ui-core/FlexBox";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import ConfirmDialog from "../../../components/deprecated/ConfirmDialog";
import OrderSortingChips from "../../../components/order-outbound-sorting/OrderSortingChips";
import OrderSortingTable from "../../../components/order-outbound-sorting/OrderSortingTable";
import OrderSortingVerifyOrdersDialog from "../../../components/order-outbound-sorting/OrderSortingVerifyOrdersDialog";
import AdminOrderDetailsDialogWrapperV2 from "../../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import { getUser, getUserPermissions } from "../../../reducers/ProfileReducer";
import { hasRole } from "../../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../../constants/AdminRoleTypes";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import { RETURNING } from "../../../constants/FlowTypes";
import { formatText } from "../../../helpers/FormatUtils";
import { COURIER_TYPE } from "../../../constants/OrderSortingField";
import {
  CONSOLIDATED,
  CONTAINER,
  SHIPMENT,
  SIMPLE,
} from "../../../constants/OrderType";
import OrderSortingTypeTabs from "../../../components/order-outbound-sorting/OrderSortingTypeTabs";
import {
  ORDER_OUTBOUND_CONSOLIDATED_FIREBASE_VIEW_URL,
  ORDER_OUTBOUND_CONTAINER_FIREBASE_VIEW_URL,
  ORDER_OUTBOUND_REGISTRY_SORTING_FIREBASE_VIEW_URL,
  ORDER_OUTBOUND_ROUTES_FIREBASE_VIEW_URL,
  ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_URL,
} from "../../../constants/AdminPathConstants";
import AdminOutboundOrderSortingBatchUpdateDialogWrapper from "../../../wrappers/admin/AdminOutboundOrderSortingBatchUpdateDialogWrapper";
import { ableToCreateRegistry } from "../../../helpers/OrderSortingHelper";
import {
  batchAsyncUpdateOrder,
  updateRecipientPostcode,
  updateRegistryStatusSorting,
} from "../../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import OrderOutboundSortingVerifyOrdersDialogWrapper from "../../../components/order-outbound-sorting/OrderOutboundSortingVerifyOrdersDialogWrapper";
import OrderSortingRedirectOrderDialog from "../../../components/order-outbound-sorting/OrderSortingRedirectOrderDialog";
import { updateSortingVerifiedOrders } from "../../../reducers/OrderOutboundSortingReducer";
import BatchRedirectOrdersFormDialogWrapper from "../../../wrappers/admin/BatchRedirectOrdersFormDialogWrapper";
import BatchRTOOrdersFormDialogWrapper from "../../../wrappers/admin/BatchRTOOrdersFormDialogWrapper";
import BatchAssignOrdersToDriverFormDialogWrapper from "../../../wrappers/admin/BatchAssignOrdersToDriverFormDialogWrapper";
import BatchCanceledUpdateDialogWrapper from "../../../wrappers/admin/BatchCanceledUpdateDialogWrapper";
import AdminBatchCanceledUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchCanceledUpdatesItemDialogWrapper";
import BatchReturningUpdateDialogWrapper from "../../../wrappers/admin/BatchReturningUpdateDialogWrapper";

const SORTED_GROUP = "Sorted";
const UNSORTED_GROUP = "Unsorted";
const PROCESSED_GROUP = "Processed";

function getOrderGroup(taskWarehouseId, order) {
  if (order.get("info")) {
    // const status = order.getIn(["info", "status"]);
    const flow = order.getIn(["info", "flow"]);
    const warehouseId = order.getIn(["info", "warehouse", "id"]);
    const inWarehouse = warehouseId === taskWarehouseId;

    if (flow === RETURNING) return RETURNING;

    if (
      inWarehouse &&
      (status === ASSIGNED_TO_COURIER ||
        status === ON_HOLD ||
        status === IN_SORTING_FACILITY)
    ) {
      return "Sorted";
    }

    if (status === UNASSIGNED || status === ASSIGNED_TO_COURIER) {
      return "Unsorted";
    }

    return "Processed";
  }

  return null;
}

function formatPromptMessage(
  orderSize,
  getLocalisationMessage,
  defaultMessage = "",
) {
  if (!orderSize) {
    return defaultMessage;
  }
  const messageHead = getLocalisationMessage("regroup_orders_prompt");
  if (!messageHead) {
    return defaultMessage;
  }
  let messageTail = null;
  switch (orderSize) {
    case 1:
      messageTail = getLocalisationMessage("orders_1");
      break;
    case 0:
    case 2:
    case 3:
    case 4:
    case 5:
      messageTail = getLocalisationMessage("orders_2");
      break;
    default:
      messageTail = getLocalisationMessage("orders_3");
  }
  return `${messageHead} ${orderSize} ${messageTail}`;
}

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        permissions: getUserPermissions(state),
        isOperator: hasRole(userRoles, ROLE_OPERATOR),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    { updateSortingVerifiedOrders },
  ),
  useSheet({
    chipsCard: { maxWidth: "100vw", paddingTop: 0, paddingBottom: 0 },
    tableCard: { paddingTop: 0 },
  }),
  withState("state", "setState", {
    transitWarehouse: null,
    transitStatus: null,
    selectedBin: null,
    redirectingOrder: null,
    doNotRemoveOrders: false,
    showVerifyBinDialog: false,
    showBatchRedirected: { open: false, is_redirected: false },
  }),
  withState("activeBin", "setActiveBin", Map()),
  mapPropsStream(
    /**
     * 1. Generate sets of selected order, driver and warehouse ids.
     * 2. Count failed orders.
     * 3. Combine and sort order bins.
     */
    propsStream => {
      const statsStream = propsStream
        .map(props => ({
          orders: props.orders,
          binRules: props.binRules,
          warehouseId: props.warehouseId,
          selectedOrders: props.task.get("selectedOrders"),
        }))
        .distinctUntilChanged(isEqualData)
        .map(props => {
          let bins = Map().asMutable();
          const groups = Map().asMutable();

          let selectedOrderWeight = 0;

          const ids = Set().asMutable();
          const driverIds = Set().asMutable();
          const warehouseIds = Set().asMutable();
          const courierTypes = Map().asMutable();
          const selectedBins = Set().asMutable();

          const orderIdPath = ["info", "id"];
          // const statusPath = ["info", "status"];
          const driverIdPath = ["info", "driver", "id"];
          const warehouseIdPath = ["info", "destination_warehouse", "id"];
          const courierTypeKeyPath = ["info", "package", "courier_type"];
          const courierTypePath = ["info", "package", "courier_type_name"];
          // const categoryTypePath = ["info", "category"]; TODO revert back if orders courier_type issue happens
          const categoryTypePath = ["info", "inner_shipment_type"];
          const orderWeightPath = ["info", "weight"];

          props.orders.forEach((order, orderNumber) => {
            const orderId = order.getIn(orderIdPath);
            const loaded = orderId > 0;
            const code = order.get("code");
            const selected = props.selectedOrders.has(orderNumber);
            const orderCourierType =
              order.get("type") === TYPE_BATCH
                ? formatText(order.getIn(categoryTypePath))
                : order.getIn(courierTypePath);
            const orderCourierTypeKey =
              order.get("type") === TYPE_BATCH
                ? order.getIn(categoryTypePath)
                : order.getIn(courierTypeKeyPath);

            if (loaded) {
              if (!courierTypes.has(orderCourierTypeKey)) {
                courierTypes.set(
                  orderCourierTypeKey,
                  Map({
                    key: orderCourierTypeKey,
                    label: orderCourierType,
                    orders: Set(),
                  }),
                );
              }

              courierTypes.updateIn([orderCourierTypeKey, "orders"], x =>
                x.add(orderNumber),
              );
            }

            // const bin = order.get("bin")
            //   ? order.get("bin")
            //   : !loaded && order.get("failed")
            //   ? FAILED_BIN
            //   : UNASSIGNED_BIN;

            const bin =
              order.get("paid", null) === false
                ? UNPAID_BIN
                : order.get("bin")
                ? order.get("bin")
                : !loaded && order.get("failed")
                ? FAILED_BIN
                : UNASSIGNED_BIN;

            const generatedBinsByCount = getBinByJurisdiction({
              order,
              initialBins: bins,
              initialBin: bin,
              rule: props.binRules.get(code),
            });

            if (generatedBinsByCount) {
              bins = generatedBinsByCount.asMutable();
            } else {
              if (!bins.has(bin)) {
                const groupBy = props.binRules.getIn(
                  [code, "group_by"],
                  List(),
                );

                bins.set(
                  bin,
                  Map({
                    code,
                    label: bin,
                    postcode: props.binRules.getIn([code, "postcode"], null),
                    nextPostcode: groupBy.includes("to_postcode")
                      ? getDestinationPostcode(order, "info")
                      : null,
                    nextJurisdiction: groupBy.includes("to_jurisdiction")
                      ? getSingleDestinationJurisdiction(
                          order.get("info", Map()),
                        )
                      : null,
                    category: groupBy.includes(fp.toLower(COURIER_TYPE))
                      ? estimateBinCategory(order, "info")
                      : null,
                    selected: 0,
                    orders: Set(),
                    level: -1,
                    courierTypes: Set(),
                  }),
                );
              }

              bins.updateIn([bin, "orders"], x => x.add(orderNumber));
              if (selected) {
                bins.updateIn([bin, "selected"], fp.add(1));
                if (!selectedBins.has(bin)) {
                  selectedBins.add(bin);
                }
              }

              bins.updateIn([bin, "courierTypes"], x =>
                x.add(orderCourierTypeKey),
              );
            }

            const group = getOrderGroup(props.warehouseId, order);

            if (group) {
              if (!groups.has(group)) {
                groups.set(
                  group,
                  Map({ label: group, selected: 0, orders: Set() }),
                );
              }

              groups.updateIn([group, "orders"], x => x.add(orderNumber));

              if (selected) {
                groups.updateIn([group, "selected"], fp.add(1));
              }
            }

            if (loaded) {
              if (selected) {
                ids.add(orderId);
                selectedOrderWeight +=
                  fp.toNumber(order.getIn(orderWeightPath, 0)) * 10;

                const driverId = order.getIn(driverIdPath);

                if (driverId > 0) {
                  driverIds.add(driverId);
                }

                const warehouseId = order.getIn(warehouseIdPath);

                if (warehouseId > 0) {
                  warehouseIds.add(warehouseId);
                }
              }
            }
          });

          // Filter Bins and generate Final Bins by removed if special bin orders counts not enough for limit
          const finalBins = Map().withMutations(finalBin => {
            let assignedOrders = Set();
            bins
              .sort((a, b) => {
                const aLevel = a.get("level");
                const bLevel = b.get("level");

                if (aLevel > bLevel) return 1;

                if (aLevel === bLevel) return 0;

                return -1;
              })
              .forEach(x => {
                const level = x.get("level");
                const orders = x.get("orders", Set());
                const unassignedOrders = orders.subtract(assignedOrders);

                const selectedOrders = unassignedOrders.filter(orderNumber =>
                  props.selectedOrders.has(orderNumber),
                );
                const bin = x.set("selected", selectedOrders.size);
                if (level > 0) {
                  const code = bin.get("code");
                  const rule = props.binRules.get(code);
                  const divisionCount = rule.get("count", 0);

                  if (
                    !bin.get("country", false) &&
                    unassignedOrders.size >= divisionCount
                  ) {
                    finalBin.set(bin.get("label"), bin);
                    finalBin.setIn(
                      [bin.get("label"), "orders"],
                      unassignedOrders,
                    );
                  }

                  // Check If the Country Level orders fewer than division Count if so mark as a regular bin name bin.get("country", false) &&
                  if (unassignedOrders.size > 0) {
                    if (unassignedOrders.size >= divisionCount) {
                      finalBin.set(bin.get("label"), bin);
                      finalBin.setIn(
                        [bin.get("label"), "orders"],
                        unassignedOrders,
                      );
                    } else {
                      const regularBin = finalBin.get(
                        bin.get("mainLabel"),
                        null,
                      );
                      if (regularBin) {
                        const regularBinOrders = finalBin.getIn([
                          bin.get("mainLabel"),
                          "orders",
                        ]);
                        finalBin.setIn(
                          [bin.get("mainLabel"), "orders"],
                          regularBinOrders.merge(unassignedOrders),
                        );
                      } else {
                        finalBin.set(
                          bin.get("mainLabel"),
                          Map({
                            label: bin.get("mainLabel"),
                            postcode: bin.get("postcode"),
                            selected: 0,
                            orders: unassignedOrders,
                            category: bin.get("category", null),
                            level: -1,
                            courierTypes: bin.get("courierTypes", Set()),
                            code,
                          }),
                        );
                      }

                      // assignedOrders = assignedOrders.merge(unassignedOrders);

                      // Recalculate Selected orders
                      const regularBinOrders = finalBin.getIn([
                        bin.get("mainLabel"),
                        "orders",
                      ]);
                      const reSelectedOrders = regularBinOrders.filter(
                        orderNumber => props.selectedOrders.has(orderNumber),
                      );
                      finalBin.setIn(
                        [bin.get("mainLabel"), "selected"],
                        reSelectedOrders.size,
                      );
                    }
                  }
                } else {
                  finalBin.set(bin.get("label"), bin);
                  finalBin.setIn(
                    [bin.get("label"), "orders"],
                    unassignedOrders,
                  );
                }

                assignedOrders = assignedOrders.merge(unassignedOrders);
              });
          });

          return Map({
            selectedOrderWeight: selectedOrderWeight / 10,
            selectedIds: ids.asImmutable(),
            selectedDriverIds: driverIds.asImmutable(),
            selectedWarehouseIds: warehouseIds.asImmutable(),
            selectedBins: selectedBins.asImmutable(),
            courierTypes: courierTypes.asImmutable(),

            groups: groups
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultGroups = [
                  UNSORTED_GROUP,
                  SORTED_GROUP,
                  PROCESSED_GROUP,
                ];

                for (let i = 0; i < defaultGroups.length; i++) {
                  const bin = defaultGroups[i];

                  if (aName === bin) {
                    return -1;
                  }

                  if (bName === bin) {
                    return 1;
                  }
                }

                return 0;
              }),
            bins: finalBins
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultBins = [
                  UNASSIGNED_BIN,
                  FAILED_BIN,
                  UNSORTED_BIN,
                  NOTFOUND_BIN,
                ];

                for (let i = 0; i < defaultBins.length; i++) {
                  const bin = defaultBins[i];

                  if (aName === bin) {
                    return 1;
                  }

                  if (bName === bin) {
                    return -1;
                  }
                }

                return aName.localeCompare(bName);
              }),
          });
        })
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
    },
  ),
  pureComponent(
    fp.pick(["location", "task", "stats", "orders", "state", "bagsCount"]),
  ),
);

AdminOrderOutboundSortingTableContainer.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  activeBin: PropTypes.object,
  isOperator: PropTypes.bool,
  stats: PropTypes.instanceOf(Map),
  binRules: PropTypes.instanceOf(Map),
  location: PropTypes.object.isRequired,
  task: PropTypes.instanceOf(Map).isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
  registries: PropTypes.instanceOf(Map),
  warehouseId: PropTypes.number.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  updateSortingTask: PropTypes.func.isRequired,
  setState: PropTypes.func,
  setActiveBin: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  updateSortingVerifiedOrders: PropTypes.func,
  permissions: PropTypes.array,
};

function AdminOrderOutboundSortingTableContainer(props) {
  const [orderId, setOrderId] = useState(false);
  const [tempBatchId, setBatchId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRTO, setIsOpenRTO] = useState(false);
  const [isAssignDriver, setIsAssignDriver] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isBulkReturning, setIsBulkReturning] = useState(false);
  const {
    getLocalisationMessage,
    classes,
    task,
    stats,
    orders,
    state,
    activeBin,
    location: { query },
  } = props;
  const db = new OrderSortingDB(props.warehouseId);

  const selectedOrders = task.get("selectedOrders");

  return (
    <FlexBox flex={true}>
      {query.remove_after_update === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => {
            db.batchReloadOrders(selectedOrders.toArray())
              .toPromise()
              .catch(props.showErrorMessage);

            props.setLocationQuery(fp.unset("remove_after_update"));
          }}
          onConfirm={() =>
            props.setLocationQuery(
              fp.flow(
                fp.set("remove_selected", true),
                fp.unset("remove_after_update"),
              ),
            )
          }
        >
          {getLocalisationMessage(
            "you_want_to_remove_updated_orders",
            "You want to remove updated orders?",
          )}
        </ConfirmDialog>
      )}

      {query.remove_selected === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmit={() =>
            db
              .batchRemoveOrders(selectedOrders.toArray())
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage("delete_prompt") ||
            `Are you sure you want to remove ${selectedOrders.size} orders from\n queue ?`}
        </FormDialog>
      )}

      {fp.get("redirectingOrder", state) &&
        orders.get(fp.get("redirectingOrder", state), null) && (
          <OrderSortingRedirectOrderDialog
            open={true}
            order={orders.get(fp.get("redirectingOrder", state), null)}
            onSubmit={({ barcode, index }) =>
              updateRecipientPostcode({
                index: fp.get("name", index),
                barcodes: [barcode],
                is_redirected: true,
              }).catch(ResponseError.throw)
            }
            onSubmitFail={props.showErrorMessage}
            onSubmitSuccess={() => {
              const orderBarcode = fp.get("redirectingOrder", state);
              db.batchReloadOrders([orderBarcode])
                .toPromise()
                .then(() => {
                  db.batchUpdateOrderBins([orderBarcode], null).toPromise();
                  props.setState(fp.unset("redirectingOrder"));
                });
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_updated",
                  "Successfully Updated",
                ),
              );
            }}
            onRequestClose={() => props.setState(fp.unset("redirectingOrder"))}
          />
        )}

      {fp.get("showBatchRedirected.open", state) && (
        <BatchRedirectOrdersFormDialogWrapper
          open={true}
          initialValues={{
            orderBarcodes: selectedOrders.toArray(),
            is_redirected: fp.get("showBatchRedirected.is_redirected", state),
          }}
          onRequestClose={() => {
            props.setState(
              fp.set("showBatchRedirected", {
                open: false,
                is_redirected: false,
              }),
            );
          }}
          onSubmitSuccess={() => {
            db.batchReloadOrders(selectedOrders.toArray())
              .toPromise()
              .then(() => {
                db.batchUpdateOrderBins(
                  selectedOrders.toArray(),
                  null,
                ).toPromise();
                props.setState(
                  fp.set("showBatchRedirected", {
                    open: false,
                    is_redirected: false,
                  }),
                );
              });
          }}
        />
      )}

      <BatchRTOOrdersFormDialogWrapper
        open={isOpenRTO}
        initialValues={{
          orderBarcodes: selectedOrders.toArray(),
          warehouse: { id: props.warehouseId },
        }}
        onRequestClose={() => {
          setIsOpenRTO(false);
          props.setState(fp.set("doNotRemoveOrders", false));
        }}
        onSubmitSuccess={response => {
          const batchId = fp.get("data.id", response);
          if (batchId) {
            props.setState(fp.set("doNotRemoveOrders", true));
            props.setLocationQuery(fp.set("batch_id", batchId));
            setIsOpenRTO(false);
          }
        }}
      />

      <BatchAssignOrdersToDriverFormDialogWrapper
        open={isAssignDriver}
        initialValues={{
          orderBarcodes: selectedOrders.toArray(),
          warehouse: { id: props.warehouseId },
        }}
        onRequestClose={() => {
          setIsAssignDriver(false);
        }}
        onSubmitSuccess={response => {
          const batchId = fp.get("data.id", response);
          if (batchId) {
            props.setLocationQuery(fp.set("batch_id", batchId));
            setIsAssignDriver(false);
          }
        }}
      />

      <BatchCanceledUpdateDialogWrapper
        open={isCanceled}
        initialValues={{
          orderBarcodes: selectedOrders.toArray(),
          warehouse: { id: props.warehouseId },
        }}
        onRequestClose={() => {
          setIsCanceled(false);
        }}
        setIsCanceled={setIsCanceled}
        onSubmitSuccess={response => {
          const batchId = fp.get("data.id", response);
          if (batchId) {
            props.setLocationQuery(fp.set("canceled_batch_id", batchId));
            setIsCanceled(false);
          }
        }}
      />

      <BatchReturningUpdateDialogWrapper
        open={isBulkReturning}
        warehouseId={props.warehouseId}
        orders={orders}
        activeBin={activeBin}
        selectedItems={selectedOrders}
        isBulkReturning={true}
        onRequestClose={() => {
          setIsBulkReturning(false);
        }}
        onSubmitSuccess={response => {
          const batchId = fp.get("data.id", response);
          if (batchId) {
            props.setLocationQuery(fp.set("batch_id", batchId));

            setIsBulkReturning(false);
          }
        }}
      />

      {state.showVerifyBinDialog && (
        <OrderOutboundSortingVerifyOrdersDialogWrapper
          isLoading={isLoading}
          open={activeBin.get("selectedBin", null)}
          orders={orders}
          bins={stats.get("bins")}
          warehouse={props.warehouseId}
          activeBin={activeBin}
          selectedItems={selectedOrders}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          onSubmit={({
            values,
            registries,
            items,
            unverified,
            bags,
            shipments,
            indexedOrders,
          }) => {
            setIsLoading(true);
            const subtractItems = unverified.merge(registries).merge(bags);
            const bin = props.activeBin.get("selectedBin", null);

            props.updateSortingVerifiedOrders(x => x.clear());

            if (unverified.size > 0) {
              props.updateSortingTask(x =>
                x.update("selectedOrders", selected =>
                  selected.subtract(subtractItems.toArray()),
                ),
              );

              // unverified shipments moved to Notfound Bin, then including them, registries and bags removed from selected orders
              // db.batchUpdateOrderBins(unverified.toArray(), NOTFOUND_BIN)
              //   .toPromise()
              //   .catch(ResponseError.throw)
              //   .then(() => {
              //     props.updateSortingTask((x) =>
              //       x.update("selectedOrders", (selected) =>
              //         selected.subtract(subtractItems.toArray()),
              //       ),
              //     );
              //   });
            }

            if (items.size > 0) {
              Promise.resolve(
                updateRegistryStatusSorting({ items: items.toJS() }).catch(
                  ResponseError.throw,
                ),
              ).then(() => {
                if (registries.size > 0) {
                  db.batchMoveRegistriesFromOrdersToShipmentsPanel(registries)
                    .toPromise()
                    .catch(ResponseError.throw)
                    .then(() => {
                      props.updateSortingTask(x =>
                        x.update("selectedOrders", selected =>
                          selected.subtract(subtractItems.toArray()),
                        ),
                      );
                    });
                }

                if (bags.size > 0) {
                  db.batchRemoveOrders(bags)
                    .toPromise()
                    .catch(ResponseError.throw)
                    .then(() => {
                      props.updateSortingTask(x =>
                        x.update("selectedOrders", selected =>
                          selected.subtract(subtractItems.toArray()),
                        ),
                      );
                    });
                }
              });
            }

            function batchUpdateOrder(input) {
              return batchAsyncUpdateOrder(toSnakeCase(input))
                .catch(ResponseError.throw)
                .then(response => {
                  const batchId = fp.get("data.id", response);
                  const barcode = fp.get("data.barcode", response);

                  if (batchId) {
                    const courierTypes = activeBin.getIn(
                      ["selectedBin", "courierTypes"],
                      Set(),
                    );
                    props.setActiveBin(Map());

                    if (ableToCreateRegistry(courierTypes)) {
                      const data = {};
                      data[`${barcode}/number`] = barcode;

                      db.batchUpdateRegistries(data)
                        .toPromise()
                        .catch(ResponseError.throw)
                        .then(() => {
                          props.updateSortingTask(x =>
                            x.update("selectedOrders", selected =>
                              selected.subtract(subtractItems.toArray()),
                            ),
                          );
                        });
                    }
                  }

                  return response;
                })
                .then(response => {
                  props.updateSortingTask(x =>
                    x.update("selectedOrders", selected =>
                      selected.subtract(subtractItems.toArray()),
                    ),
                  );
                  return response;
                })
                .then(response => {
                  const batchId = fp.get("data.id", response);
                  if (batchId) {
                    props.setLocationQuery(
                      fp.flow(
                        fp.unset("batch_update"),
                        fp.unset("batch_update_status"),
                        fp.unset("verify_bin_orders"),
                        fp.set("batch_id", batchId),
                      ),
                    );
                  }
                })
                .finally(() => {
                  props.setState(fp.set("showVerifyBinDialog", false));
                  setIsLoading(false);
                });
            }

            if (
              indexedOrders &&
              indexedOrders.size > 0 &&
              bin.get("nextPostcode", null)
            ) {
              return updateRecipientPostcode({
                index: bin.get("nextPostcode"),
                barcodes: indexedOrders.toArray(),
              })
                .catch(ResponseError.throw)
                .then(() => {
                  if (shipments.size > 0) {
                    return batchUpdateOrder(values);
                  }
                  return null;
                });
            }

            if (shipments.size > 0) {
              return batchUpdateOrder(values);
            }

            return Promise.resolve(
              props.updateSortingTask(x =>
                x.update("selectedOrders", selected =>
                  selected.subtract(subtractItems.toArray()),
                ),
              ),
            ).then(() => {
              setIsLoading(false);
              props.setState(fp.set("showVerifyBinDialog", false));
              props.setLocationQuery(
                fp.flow(
                  fp.unset("batch_update"),
                  fp.unset("batch_update_status"),
                  fp.unset("verify_bin_orders"),
                ),
              );
            });
          }}
          onConfirmUnknownOrder={(order, bin) => {
            const values = {};
            const orderNumber = order.get("barcode");

            values[`${orderNumber}/failed`] = null;
            values[`${orderNumber}/bin`] = bin.get("label");
            values[`${orderNumber}/code`] = bin.get("code");
            values[`${orderNumber}/info`] = order.toJS();
            values[`${orderNumber}/number`] = orderNumber;
            values[`${orderNumber}/scanned_as_box`] = false;
            values[`${orderNumber}/hash`] = order.hashCode();
            values[`${orderNumber}/hash_time`] = Date.now();

            db.batchUpdateOrders(values)
              .toPromise()
              .catch(ResponseError.throw)
              .then(() => {
                props.updateSortingTask(x =>
                  x.update("selectedOrders", selected =>
                    selected.add(orderNumber),
                  ),
                );

                // if (!found) {
                //   db.addTask(order.get("barcode"), {
                //     in_sorting_warehouse: props.warehouseId,
                //   }).toPromise();
                // }
                //
                // if (bin.get("nextPostcode", null)) {
                //   db.addTask(order.get("barcode"), {
                //     update_postcode: {
                //       index: bin.get("nextPostcode"),
                //       barcodes: [order.get("barcode")],
                //     },
                //   }).toPromise();
                // }
              });
          }}
          onRequestClose={() => {
            props.updateSortingVerifiedOrders(x => x.clear());
            props.setLocationQuery(
              fp.set("confirm_close_verify_selected", true),
            );
          }}
        />
      )}

      {query.verify_selected === "true" && (
        <OrderSortingVerifyOrdersDialog
          selectedOrders={selectedOrders}
          onSelectOrders={numbers => {
            props.setLocationQuery(fp.unset("verify_selected"));
            props.updateSortingTask(x => x.set("selectedOrders", numbers));
          }}
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.set("confirm_close_verify_selected", true),
            )
          }
        />
      )}

      {query.confirm_close_verify_selected === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("confirm_close_verify_selected"))
          }
          onConfirm={() => {
            props.setState(fp.set("showVerifyBinDialog", false));
            props.setLocationQuery(
              fp.flow(
                fp.unset("verify_selected"),
                fp.unset("verify_bin_orders"),
                fp.unset("confirm_close_verify_selected"),
              ),
            );
          }}
          title={
            getLocalisationMessage(
              "are_you_sure_you_want_to_close_order_verification_dialog",
            ) || "Are you sure you want to close order verification dialog?"
          }
        >
          <p>
            {getLocalisationMessage("all_order_scanned_verification_removed")} (
            <em>{getLocalisationMessage("not_from_sorting_list")}</em>)
          </p>
        </ConfirmDialog>
      )}

      {query.regroup_bins === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmit={() =>
            db
              .batchUpdateOrderBins(selectedOrders.toArray(), null)
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {formatPromptMessage(
            selectedOrders.size,
            getLocalisationMessage,
            `Are you sure you want to regroup ${selectedOrders.size} orders?`,
          )}
        </FormDialog>
      )}

      {query.batch_update === "true" && (
        <AdminOutboundOrderSortingBatchUpdateDialogWrapper
          open={true}
          orders={orders}
          activeBin={activeBin}
          isSorting={true}
          selectedItems={selectedOrders}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_update_status"),
                fp.unset("batch_update"),
              ),
            )
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={response => {
            const batchId = fp.get("data.id", response);
            const barcode = fp.get("data.barcode", response);
            const courierTypes = activeBin.getIn(
              ["selectedBin", "courierTypes"],
              Set(),
            );

            props.setActiveBin(Map());

            if (ableToCreateRegistry(courierTypes)) {
              const values = {};
              values[`${barcode}/number`] = barcode;

              db.batchUpdateRegistries(values)
                .toPromise()
                .catch(ResponseError.throw);
            }

            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_update"),
                fp.unset("batch_update_status"),
                fp.set("batch_id", batchId),
              ),
            );
          }}
        />
      )}

      {fp.toFinite(query.batch_id) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(query.batch_id)}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("batch_id"));

            if (selectedOrders.size > 0) {
              // Auto remove orders after batch update
              // props.setLocationQuery(fp.set("remove_after_update", true));
              if (fp.get("doNotRemoveOrders", state)) {
                db.batchReloadOrders(selectedOrders.toArray())
                  .toPromise()
                  .then(() => {
                    db.batchUpdateOrderBins(
                      selectedOrders.toArray(),
                      null,
                    ).toPromise();
                    props.setState(fp.set("doNotRemoveOrders", false));
                  });
              } else {
                db.batchRemoveOrders(selectedOrders.toArray())
                  .toPromise()
                  .catch(ResponseError.throw);
              }
            }
          }}
        />
      )}

      {fp.toFinite(query.canceled_batch_id) > 0 && (
        <AdminBatchCanceledUpdatesItemDialogWrapper
          batchId={fp.toFinite(query.canceled_batch_id)}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("canceled_batch_id"));

            if (selectedOrders.size > 0) {
              // Auto remove orders after batch update
              // props.setLocationQuery(fp.set("remove_after_update", true));
              if (fp.get("doNotRemoveOrders", state)) {
                db.batchReloadOrders(selectedOrders.toArray())
                  .toPromise()
                  .then(() => {
                    db.batchUpdateOrderBins(
                      selectedOrders.toArray(),
                      null,
                    ).toPromise();
                    props.setState(fp.set("doNotRemoveOrders", false));
                  });
              } else {
                db.batchRemoveOrders(selectedOrders.toArray())
                  .toPromise()
                  .catch(ResponseError.throw);
              }
            }
          }}
        />
      )}

      <AdminOrderDetailsDialogWrapperV2
        setBatchId={setBatchId}
        orderId={orderId}
        setOrderId={id => setOrderId(id)}
        onRequestClose={() => setOrderId(false)}
      />
      <AdminBatchUpdatesItemDialogWrapper
        batchId={tempBatchId}
        refreshBatchList={() => {}}
        onRequestClose={() => setBatchId(false)}
      />
      {Boolean(
        query.set_supplier !== "true" &&
          query.batch_update !== "true" &&
          query.verify_selected !== "true",
      ) && (
        <FlexBox
          style={{
            overflow: "hidden",
            background: "white",
            borderRadius: 4,
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          }}
          flex={true}
          direction="column"
        >
          <CardContent className={classes.chipsCard}>
            <OrderSortingTypeTabs
              orderType={SHIPMENT}
              orders={orders}
              registries={props.registries}
              onTabChange={actualTab => {
                props.setLocation(
                  actualTab === SHIPMENT
                    ? ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_URL
                    : actualTab === SIMPLE
                    ? ORDER_OUTBOUND_REGISTRY_SORTING_FIREBASE_VIEW_URL
                    : actualTab === CONSOLIDATED
                    ? ORDER_OUTBOUND_CONSOLIDATED_FIREBASE_VIEW_URL
                    : actualTab === CONTAINER
                    ? ORDER_OUTBOUND_CONTAINER_FIREBASE_VIEW_URL
                    : ORDER_OUTBOUND_ROUTES_FIREBASE_VIEW_URL,
                );
              }}
            />
            <OrderSortingChips
              groups={stats.get("groups")}
              filterChips={stats.get("bins")}
              filterTabs={stats.get("courierTypes")}
              onSelectOrders={(orderNumbers, chip) => {
                const params = Map().asMutable();

                params.set("selectedBin", chip);
                params.set("binPostcode", chip.get("bin_postcode", null));
                params.set(
                  "binJurisdiction",
                  chip.get("bin_jurisdiction", null),
                );
                params.set("group", chip.get("group", null));

                const ruleCode = chip.get("code", null);

                props.setState(fp.set("selectedBin", chip));

                if (ruleCode) {
                  const warehouseId = props.binRules.getIn(
                    [ruleCode, "warehouse", "id"],
                    null,
                  );
                  const destinationWarehouseId = props.binRules.getIn(
                    [ruleCode, "to_warehouse", "id"],
                    null,
                  );
                  const status = props.binRules.getIn(
                    [ruleCode, "status"],
                    null,
                  );

                  params.set("destinationWarehouse", destinationWarehouseId);
                  params.set("transitWarehouse", warehouseId);
                  params.set("transitStatus", status);
                }

                props.setActiveBin(params.asImmutable());

                props.updateSortingTask(x =>
                  x.update("selectedOrders", () => orderNumbers),
                );
              }}
              onDeselectOrders={orderNumbers => {
                props.setState(fp.set("selectedBin", null));
                if (selectedOrders.size <= orderNumbers.size) {
                  props.setActiveBin(Map());
                }

                props.updateSortingTask(x =>
                  x.update("selectedOrders", () => []),
                );
              }}
            />
          </CardContent>
          <CardContent style={{ height: "100%" }} className={classes.tableCard}>
            <OrderSortingTable
              orders={orders}
              setBatchId={id => setBatchId(id)}
              showBatchUpdate={props.isOperator}
              showAllButton={false}
              onOrderClick={x => setOrderId(x)}
              onRedirectClick={x =>
                props.setState(fp.set("redirectingOrder", x))
              }
              selectedOrders={selectedOrders}
              onOrdersSelect={numbers =>
                props.updateSortingTask(x => x.set("selectedOrders", numbers))
              }
              onReloadClick={orderNumber =>
                db
                  .reloadOrder(orderNumber)
                  .toPromise()
                  .catch(props.showErrorMessage)
              }
              onRemoveClick={orderNumber =>
                db
                  .removeOrder(orderNumber)
                  .toPromise()
                  .catch(props.showErrorMessage)
              }
              onBatchStatusUpdate={() => {
                props.setLocationQuery(
                  fp.flow(
                    fp.set("batch_update", true),
                    fp.set(
                      "batch_update_status",
                      fp.get("transitStatus", state),
                    ),
                  ),
                );
              }}
              cardActionIcons={
                <FlexBox align="center">
                  <CustomButton
                    style={{
                      flex: "1 1 auto",
                      margin: "0 .5rem",
                    }}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => setIsAssignDriver(true)}
                  >
                    {getLocalisationMessage(
                      "assign_to_driver",
                      "Assign To Driver",
                    )}
                  </CustomButton>

                  {props.permissions.includes(
                    "CAN_BATCH_UPDATE_AS_DELIVERED",
                  ) && (
                    <CustomButton
                      style={{
                        flex: "1 1 auto",
                        margin: "0 .5rem",
                      }}
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => setIsCanceled(true)}
                    >
                      {getLocalisationMessage(
                        "bulk_update_shipments",
                        "Bulk update shipments",
                      )}
                    </CustomButton>
                  )}

                  {props.permissions.includes(
                    "OUTBOUND_BATCH_UPDATE_BUTTON",
                  ) && (
                    <CustomButton
                      style={{
                        flex: "1 1 auto",
                        margin: "0 .5rem",
                      }}
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => setIsBulkReturning(true)}
                    >
                      {getLocalisationMessage(
                        "bulk_returning_orders",
                        "Bulk returning orders",
                      )}
                    </CustomButton>
                  )}

                  {selectedOrders.size > 0 && (
                    <FlexBox align="center" direction="row">
                      {activeBin.get("selectedBin", null) && !props.isOperator && (
                        <CustomButton
                          style={{
                            flex: "1 1 auto",
                            margin: "0 .5rem",
                          }}
                          variant={OUTLINED}
                          color={SECONDARY}
                          onClick={() =>
                            props.setState(fp.set("showVerifyBinDialog", true))
                          }
                        >
                          {getLocalisationMessage(
                            "prepared_for_transit_button",
                          )}
                        </CustomButton>
                      )}

                      <MenuButtonMore desktop={false}>
                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(fp.set("regroup_bins", true))
                          }
                        >
                          {getLocalisationMessage("regroup_bins")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(
                              fp.set("remove_selected", true),
                            )
                          }
                        >
                          {getLocalisationMessage("remove")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            db
                              .batchReloadOrders(selectedOrders.toArray())
                              .toPromise()
                              .catch(props.showErrorMessage)
                          }
                        >
                          {getLocalisationMessage("batch_reload")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            props.setLocationQuery(
                              fp.flow(
                                fp.unset("batch_update_status"),
                                fp.unset("batch_update"),
                              ),
                            );
                            setIsOpenRTO(true);
                          }}
                        >
                          {getLocalisationMessage(
                            "batch_rto_orders",
                            "Batch RTO Orders",
                          )}
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            props.setState(
                              fp.set("showBatchRedirected", {
                                open: true,
                                is_redirected: true,
                              }),
                            )
                          }
                        >
                          {getLocalisationMessage(
                            "batch_redirect_orders",
                            "Batch Redirect Orders",
                          )}
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            props.setState(
                              fp.set("showBatchRedirected", {
                                open: true,
                                is_redirected: false,
                              }),
                            )
                          }
                        >
                          {getLocalisationMessage(
                            "batch_correction_orders",
                            "Batch Correction Orders",
                          )}
                        </MenuItem>

                        <MenuItem onClick={() => setIsAssignDriver(true)}>
                          {getLocalisationMessage("assign_to_driver")}
                        </MenuItem>
                      </MenuButtonMore>
                    </FlexBox>
                  )}
                </FlexBox>
              }
            />
          </CardContent>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(AdminOrderOutboundSortingTableContainer);
