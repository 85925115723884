import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { validateString } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({ padding: { paddingTop: 10 } }),
  reduxForm({
    form: "BankForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("enter_name", "Enter name")
        : null,
    }),
  }),
);

BankForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function BankForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={getLocalisationMessage("bank_name", "Bank Name")}
                />

                <FormTextField
                  name="branch"
                  fullWidth={true}
                  label={getLocalisationMessage("bank_branch", "Bank Branch")}
                />

                <FormTextField
                  name="address"
                  fullWidth={true}
                  label={getLocalisationMessage("bank_address", "Bank Address")}
                />

                <FlexBox flex={true} className={classes.padding}>
                  <FormCheckbox
                    name="default"
                    label={getLocalisationMessage("is_default", "Is Default")}
                  />
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <CardActions>
        <FlexBox justify="flex-end">
          {props.dirty ? (
            <Button
              label={getLocalisationMessage("reset", "Reset")}
              onClick={props.reset}
            />
          ) : (
            Boolean(props.onDismiss) && (
              <Button
                label={getLocalisationMessage("dismiss", "Dismiss")}
                onClick={props.onDismiss}
              />
            )
          )}

          <Button
            label={getLocalisationMessage("submit", "Submit")}
            onClick={props.handleSubmit}
          />
        </FlexBox>
      </CardActions>
    </form>
  );
}

export default enhancer(BankForm);
