import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import PageLoading from "../ui-core/PageLoading";
import ExtendElement from "../ui-core/ExtendElement";

const enhancer = reduxForm({ form: "FormContainer" });

FormContainer.propTypes = {
  form: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  children: PropTypes.node,

  validate: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

FormContainer.defaultProps = { element: "div" };

function FormContainer(props) {
  return (
    <ExtendElement element={props.element}>
      <PageLoading isLoading={props.submitting} />

      <form id={props.form} onSubmit={props.handleSubmit}>
        {props.children}
      </form>
    </ExtendElement>
  );
}

export default enhancer(FormContainer);
