import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "WeekendSettingsForm",
    enableReinitialize: true,
  }),
);

WeekendSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function WeekendSettingsForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox
      element={<form onSubmit={props.handleSubmit} />}
      gutter={8}
      flex={true}
      direction="column"
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox>
        <FormCheckbox
          name="monday"
          label={getLocalisationMessage("monday", "Monday")}
        />
      </FlexBox>

      <FlexBox>
        <FormCheckbox
          name="tuesday"
          label={getLocalisationMessage("tuesday", "Tuesday")}
        />
      </FlexBox>

      <FlexBox>
        <FormCheckbox
          name="wednesday"
          label={getLocalisationMessage("wednesday", "Wednesday")}
        />
      </FlexBox>

      <FlexBox>
        <FormCheckbox
          name="thursday"
          label={getLocalisationMessage("thursday", "Thursday")}
        />
      </FlexBox>

      <FlexBox>
        <FormCheckbox
          name="friday"
          label={getLocalisationMessage("friday", "Friday")}
        />
      </FlexBox>

      <FlexBox>
        <FormCheckbox
          name="saturday"
          label={getLocalisationMessage("saturday", "Saturday")}
        />
      </FlexBox>

      <FlexBox>
        <FormCheckbox
          name="sunday"
          label={getLocalisationMessage("sunday", "Sunday")}
        />
      </FlexBox>

      <FlexBox>
        <Button type="submit">
          {getLocalisationMessage("apply", "Apply")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(WeekendSettingsForm);
