import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../../helpers/HOCUtils";
import ResponseError from "../../../helpers/ResponseError";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedCountry,
  getCountryPredictions,
} from "../../../api/shared/CountryV2Api";
import { createMarketplace } from "../../../api/shared/MarketplaceApi";
import FlexBox from "../../../components/ui-core/FlexBox";
import ModalPaper from "../../../components/ui-core/ModalPaper";
import MarketplaceForm from "../../../components/marketplaces-core/MarketplaceForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: { minWidth: "800px", maxWidth: "800px" },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminMarketplaceCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function AdminMarketplaceCreateDialogWrapper(props) {
  const { classes } = props;
  return (
    <ModalPaper
      title={props.getLocalisationMessage(
        "create_marketplace",
        "Create Marketplace",
      )}
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <FlexBox container={8} direction="column">
        <MarketplaceForm
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            createMarketplace(values).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminMarketplaceCreateDialogWrapper);
