import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import FlexBox from "../ui-core/FlexBox";
import bgImage from "../../assets/subscription/bg.svg";

const enhancer = compose(
  useSheet({
    root: {
      width: "100%",
      "&.selected": {
        position: "relative",
        "& > h6": {
          backgroundColor: "#10132D",
          color: "white",
        },
        "& > .purchasedH6": {
          backgroundColor: "rgb(156, 0, 8)",
          color: "white",
        },
      },
      "&.disabled": {
        opacity: 0.6,
      },
    },
    title: {
      minHeight: 48,
      backgroundColor: "#E6E7E9",
      color: "#000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    purchased: {
      backgroundColor: "rgb(156, 0, 8)",
      color: "white",
    },
    imageBox: {
      backgroundColor: "white",
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: "repeat-x",
      backgroundPosition: "0 100%",
      height: 114,
      padding: "0 5px",
      position: "relative",
      animationName: "priceAnimation",
      animationIterationCount: "infinite",
      animationDuration: `10s`,
      animationTimingFunction: "linear",
      animationPlayState: "paused",
      ".selected &": { animationPlayState: "running" },
      "&.purchased": { animationPlayState: "paused", filter: "grayscale(1)" },
    },
    image: {
      maxWidth: "100%",
      animation: "priceCarAnimation 1s linear 0s infinite normal",
      animationPlayState: "paused",
      ".selected &": { animationPlayState: "running" },
      "&.purchased": { animationPlayState: "paused" },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: -3,
      left: 0,
      right: 0,
      backgroundColor: "rgba(255,255,255,0.6)",
    },
    aroundOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: "inset 0 0 3px rgba(0,0,0,0.3)",
    },
    border: {
      backgroundColor: "#10132D",
      height: 3,
    },
  }),
  getContext({
    selectedValue: PropTypes.string.isRequired,
  }),
);
SubscriptionPlanTabItem.propTypes = {
  label: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string,
  classes: PropTypes.object,
  subscribedPlan: PropTypes.object,
};

function SubscriptionPlanTabItem({
  label,
  classes,
  image,
  selectedValue,
  value,
  disabled,
  subscribedPlan,
}) {
  const selected = fp.eq(value, selectedValue);
  const isPurchased = value === subscribedPlan.code;
  return (
    <FlexBox
      direction="column"
      className={cx(classes.root, {
        selected,
        disabled,
      })}
    >
      <h6
        className={`${classes.title} ${isPurchased &&
          classes.purchased} ${isPurchased && "purchasedH6"}`}
      >
        {label}
      </h6>
      <FlexBox
        className={`${classes.imageBox} ${isPurchased && "purchased"}`}
        align="flex-end"
        justify="center"
      >
        {(image && (
          <img
            src={image}
            alt={label}
            className={`${classes.image} ${isPurchased && "purchased"}`}
          />
        )) || (
          <div className={`${classes.image} ${isPurchased && "purchased"}`} />
        )}
        {selected ? null : <div className={classes.overlay} />}
      </FlexBox>
      <div className={classes.border} />
      {selected ? <div className={classes.aroundOverlay} /> : null}
    </FlexBox>
  );
}

export default enhancer(SubscriptionPlanTabItem);
