import React from "react";
import { List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withContext,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import Audio from "../ui-core/Audio";
import ScannerTextField from "../deprecated/ScannerTextField";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import ErrorSound from "../../assets/voices/error.mp3";
import SuccessSound from "../../assets/voices/success.wav";
import BeepSound from "../../assets/voices/beep.wav";
import { withTheme } from "@material-ui/core/styles";
import OrderSortingVerifyBinOrdersTableWrapper from "./OrderSortingVerifyBinOrdersTableWrapper";
import OrderSortingVerifyBinConfirmDialog from "./OrderSortingVerifyBinConfirmDialog";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import Tabs from "../ui-core/Tabs";
import FlexBox, { JUSTIFY_END, JUSTIFY_SPACE_AROUND } from "../ui-core/FlexBox";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import {
  isConsolidatedBag,
  MIXED,
} from "../../helpers/OrderOutboundSortingHelper";
import OrderStatusCodes, {
  ACCEPTED,
  DISPATCHED,
  IN_TRANSIT,
  ON_HIS_WAY,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import { categoriesConstants } from "../orders-core/BatchUpdateOrderDialog2";
import FormTextField from "../form/FormTextField";
import { formValues, reduxForm } from "redux-form";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import FormSelectField from "../form/FormSelectField";
import TransportationType from "../../constants/TransportationType";
import { formatText } from "../../helpers/FormatUtils";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import { Done } from "@material-ui/icons";
import { isParcelServiceType } from "../../helpers/OrderSortingHelper";

const UNVERIFIED = "Unverified";
const VERIFIED = "Verified";
const UNKNOWN = "Unknown";

const statusesWithDriver = Set.of(ACCEPTED, ON_HIS_WAY, DISPATCHED);

const enhancer = compose(
  connect(state => ({
    hasUserPermission: hasUserPermission(
      state,
      "ADMIN_SORTING_OUTBOUND_WITHOUT_VERIFICATION",
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("selectedBin"),
  useSheet({
    chip: { margin: "4px" },
    chipDanger: { backgroundColor: danger1 },
    modal: { width: "800px", minWidth: 800 },
    scannerField: { marginBottom: "10px" },
  }),
  withState(
    "state",
    "setState",
    Map({
      tab: UNVERIFIED,
      inputRef: null,
    }),
  ),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const { handler: refInput, stream: refStream } = createEventHandler();
    const {
      handler: onSetFocus,
      stream: onSetFocusStream,
    } = createEventHandler();

    const sideEffectsStream = mergeSideEffectStreams(
      onSetFocusStream
        .withLatestFrom(refStream)
        .delay(1000)
        .do(([, input]) => {
          input.focus();
        }),
    ).startWith(null);

    return propsStream.combineLatest(sideEffectsStream, props => ({
      ...props,

      onSetFocus,
      refInput,
    }));
  }),
  withHandlers({
    onSubmit: ({ orders, ...props }) => ({
      scannedOrders,
      barcodes,
      unverified,
      unknown,
      allOrders,
      verifiedOrders,
      onlyBarcode,
      indexedOrders,
      currentOrder,
      ...values
    }) => {
      const shipments = Set().asMutable();
      const registries = Set().asMutable();
      const bags = Set().asMutable();
      const items = Set().asMutable();

      scannedOrders.forEach(item => {
        if (orders.getIn([item, "type"], null) === "batch") {
          if (
            isConsolidatedBag(orders.getIn([item, "info", "type"], null)) ||
            isParcelServiceType(
              orders.getIn([item, "info", "inner_shipment_type"], null),
            )
          ) {
            bags.add(item);
          } else {
            registries.add(item);
          }
        } else {
          shipments.add(item);
        }
      });

      if (bags.size > 0) {
        items.add(
          new Map({
            barcodes: bags.toArray(),
            status: IN_TRANSIT,
            warehouse: { id: fp.get("warehouse.id", values) },
          }),
        );
      }

      if (registries.size > 0) {
        items.add(
          new Map({
            barcodes: registries.toArray(),
            status: PREPARED_FOR_TRANSIT,
            warehouse: { id: fp.get("warehouse.id", values) },
          }),
        );
      }

      props.onSubmit({
        unverified,
        registries,
        bags,
        items,
        shipments,
        indexedOrders,
        values: {
          ...values,
          orderBarcodes: shipments.toArray(),
          privacy: PUBLIC,
        },
      });
    },
  }),
  withTheme,
  reduxForm({
    form: "CreateRegistryFormDialog",
    validate: (values, props) => ({
      barcodes:
        fp.isEmpty(values.barcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_barcodes")) ||
          "Add Barcodes"),
      orderStatus:
        !values.orderStatus &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("select_status", "Select Status"),
      category:
        fp.isEmpty(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      transportationType:
        fp.isEmpty(values.transportationType) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        props.getLocalisationMessage(
          "select_destination_warehouse",
          "Select Destination Warehouse",
        ),
    }),
  }),
  formValues({
    barcodes: "barcodes",
    orderStatus: "orderStatus",
    weight: "weight",
    category: "category",
    next_postcode: "next_postcode",
    next_jurisdiction: "next_jurisdiction",
    to_postcode: "to_postcode",
    to_jurisdiction: "to_jurisdiction",
    supplier: "supplier",
    scannedOrders: "scannedOrders",
    currentOrder: "currentOrder",
    unknown: "unknown",
    unverified: "unverified",
    allOrders: "allOrders",
    verifiedOrders: "verifiedOrders",
    indexedOrders: "indexedOrders",
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = propsStream
      .map(fp.pick(["scannedOrders", "selectedOrders"]))
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .do(([orders, props]) => {
        const scannedOrders = orders.scannedOrders || Set();
        const unknown = scannedOrders.subtract(orders.selectedOrders);
        const unverified = orders.selectedOrders.subtract(scannedOrders);

        const allOrders = List().withMutations(list => {
          unverified.forEach(number => {
            list.push(new Map({ number, status: UNVERIFIED }));
          });

          unknown.forEach(number => {
            list.push(new Map({ number, status: UNKNOWN }));
          });

          return list;
        });

        const weight = scannedOrders.reduce((acc, orderNumber) => {
          const orderWeight = props.orders.getIn(
            [orderNumber, "info", "weight"],
            0,
          );
          return acc + parseFloat(orderWeight);
        }, 0);

        const verifiedOrders = List().withMutations(list => {
          scannedOrders.forEach(number => {
            list.push(new Map({ number, status: VERIFIED }));
          });

          return list;
        });

        props.change("unknown", unknown);
        props.change("unverified", unverified);
        props.change("allOrders", allOrders);
        props.change("verifiedOrders", verifiedOrders);
        props.change("weight", Math.round(weight * 100) / 100);
      })
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
  withHandlers({
    onScanOrder: props => orderNumber => {
      let scannedOrders = props.scannedOrders || Set();

      scannedOrders = scannedOrders.add(fp.trim(orderNumber));

      props.change("scannedOrders", scannedOrders);
      props.change("currentOrder", orderNumber);
      props.onUpdateScannedOrders(scannedOrders);
    },
    onVerifyAllOrders: props => () => {
      props.change("scannedOrders", props.selectedOrders);
      props.onUpdateScannedOrders(props.selectedOrders);
    },
  }),
);

OrderOutboundSortingVerifyOrdersDialog.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,

  warehouseId: PropTypes.number,

  orderStatus: PropTypes.string,

  unknown: PropTypes.instanceOf(Set).isRequired,
  unverified: PropTypes.instanceOf(Set).isRequired,
  allOrders: PropTypes.instanceOf(List),
  verifiedOrders: PropTypes.instanceOf(List),

  setState: PropTypes.func,
  state: PropTypes.instanceOf(Map),
  supplier: PropTypes.instanceOf(Map),
  currentOrder: PropTypes.string,

  open: PropTypes.bool.isRequired,
  hasUserPermission: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Set).isRequired,
  scannedOrders: PropTypes.instanceOf(Set),
  indexedOrders: PropTypes.instanceOf(Set),
  orders: PropTypes.instanceOf(Map),

  bins: PropTypes.instanceOf(Map),
  selectedBin: PropTypes.instanceOf(Map),

  change: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onScanOrder: PropTypes.func,
  onConfirmUnknownOrder: PropTypes.func,
  onVerifyAllOrders: PropTypes.func,
  onUpdateScannedOrders: PropTypes.func,

  onSetFocus: PropTypes.func,
  refInput: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

function OrderOutboundSortingVerifyOrdersDialog(props) {
  const { classes, getLocalisationMessage, orderStatus } = props;
  const tab = props.state.get("tab", UNVERIFIED);
  const unknownCount = props.unknown ? props.unknown.size : 0;
  const unverifiedCount = props.unverified ? props.unverified.size : 0;
  const totalCount = props.selectedOrders ? props.selectedOrders.size : 0;
  const allOrdersCount = props.allOrders ? props.allOrders.size : 0;
  const verifiedCount = totalCount - unverifiedCount;
  const blockView = unknownCount > 0;
  const showDriverField = statusesWithDriver.has(orderStatus);
  const indexedOrders = props.indexedOrders || Set();

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: "1200px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${getLocalisationMessage(
              "verify_orders",
              "Verify Orders",
            )} - ${props.selectedBin && props.selectedBin.get("label", "")}`}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            {`${getLocalisationMessage(
              "verified",
              "Verified",
            )}: ${verifiedCount}, ${getLocalisationMessage(
              "total",
              "Total",
            )}: ${totalCount}`}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <form className={classes.form}>
          <FlexBox flex={true}>
            <FlexBox flex={9} direction="column">
              <FlexBox flex={true}>
                <ScannerTextField
                  className={classes.scannerField}
                  focus={true}
                  autoFocus={true}
                  fullWidth={true}
                  hintText={getLocalisationMessage(
                    "verify_orders",
                    "Verify Orders",
                  )}
                  disabled={blockView}
                  onChange={props.onScanOrder}
                  inputRef={props.refInput}
                />
                {props.hasUserPermission && (
                  <FlexBox
                    flex="none"
                    style={{ paddingLeft: 10, height: 45, paddingTop: 5 }}
                  >
                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      onClick={props.onVerifyAllOrders}
                    >
                      {getLocalisationMessage("verify_all", "Verify All")}
                    </CustomButton>
                  </FlexBox>
                )}
              </FlexBox>

              {Boolean(unknownCount === 0 && unverifiedCount === 0) && (
                <ListSubheader>
                  {getLocalisationMessage(
                    "all_orders_were_verified",
                    "All Orders Were Verified",
                  )}
                  <Audio key="success" play={true} src={SuccessSound} />
                </ListSubheader>
              )}

              {unknownCount > 0 && (
                <div>
                  <OrderSortingVerifyBinConfirmDialog
                    open={blockView}
                    bins={props.bins}
                    warehouseId={props.warehouseId}
                    orders={props.orders}
                    selectedBin={props.selectedBin}
                    scannedOrder={props.unknown.keySeq().get(0)}
                    onRequestClose={orderNumber => {
                      const scannedOrders = props.scannedOrders.subtract([
                        orderNumber,
                      ]);
                      props.change("scannedOrders", scannedOrders);
                      props.onUpdateScannedOrders(scannedOrders);
                      props.onSetFocus(orderNumber);
                    }}
                    onConfirm={order => {
                      props.onConfirmUnknownOrder(order, props.selectedBin);
                      props.change(
                        "indexedOrders",
                        indexedOrders.add(order.get("barcode")),
                      );
                      props.onSetFocus(order.get("barcode"));
                    }}
                  >
                    <Audio play={true} src={ErrorSound} />
                  </OrderSortingVerifyBinConfirmDialog>
                </div>
              )}

              {unknownCount === 0 && props.currentOrder && (
                <Audio play={true} key={props.currentOrder} src={BeepSound} />
              )}

              <Tabs
                value={tab}
                width="auto"
                onChange={(e, v) =>
                  props.setState(s => s.update("tab", () => v))
                }
                tabs={[
                  {
                    label: `${getLocalisationMessage(
                      "unverified_orders",
                      "Unverified Orders",
                    )} (${allOrdersCount})`,
                    value: UNVERIFIED,
                  },
                  {
                    label: `${getLocalisationMessage(
                      "verified_orders",
                      "Verified Orders",
                    )} (${
                      props.verifiedOrders ? props.verifiedOrders.size : 0
                    })`,
                    value: VERIFIED,
                  },
                ]}
              />

              {tab === UNVERIFIED && (
                <OrderSortingVerifyBinOrdersTableWrapper
                  list={props.allOrders || List()}
                />
              )}

              {tab === VERIFIED && (
                <OrderSortingVerifyBinOrdersTableWrapper
                  list={props.verifiedOrders || List()}
                />
              )}
            </FlexBox>
            <FlexBox
              flex={3}
              direction="column"
              justify={JUSTIFY_SPACE_AROUND}
              style={{ paddingLeft: 15 }}
            >
              <FlexBox direction="column">
                <FormAutoComplete
                  name="orderStatus"
                  fullWidth={true}
                  label={getLocalisationMessage("status", "Status")}
                  options={OrderStatusCodes}
                  margin="dense"
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                  formatOption={x =>
                    formatOrderStatusCodeForLocalisation(
                      x,
                      getLocalisationMessage,
                    )
                  }
                />

                {(orderStatus === IN_TRANSIT ||
                  orderStatus === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    name="warehouse"
                    label={getLocalisationMessage("next_warehouse")}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("next_postcode.name", props)
                        ? [fp.get("next_postcode.name", props)]
                        : null
                    }
                    jurisdictionIds={
                      !fp.get("next_postcode.name", props) &&
                      fp.get("next_jurisdiction.id", props)
                        ? [fp.get("next_jurisdiction.id", props)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                {/* {(orderStatus === IN_TRANSIT || */}
                {/*  orderStatus === PREPARED_FOR_TRANSIT || */}
                {/*  orderStatus === ASSIGNED_TO_COURIER) && ( */}
                {/*  <FormSupplierAutoComplete */}
                {/*    name="supplier" */}
                {/*    fullWidth={true} */}
                {/*    margin="dense" */}
                {/*    label={getLocalisationMessage("supplier", "Supplier")} */}
                {/*    hintText={getLocalisationMessage( */}
                {/*      "type_to_search", */}
                {/*      "Type To Search...", */}
                {/*    )} */}
                {/*  /> */}
                {/* )} */}

                {props.supplier && showDriverField && (
                  <FormDriverAutoComplete
                    name="driver"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("driver", "Driver")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <div style={{ marginBottom: 8, marginTop: 8 }}>
                  <FormCourierTypeSelectField
                    hintText={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    label={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    name="innerShipmentType"
                    fullWidth={true}
                    additionalOption={{
                      code: MIXED,
                      name: getLocalisationMessage(fp.toLower(MIXED)),
                    }}
                  />
                </div>

                {(orderStatus === IN_TRANSIT ||
                  orderStatus === PREPARED_FOR_TRANSIT) && (
                  <FormAutoComplete
                    name="category"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("category", "Category")}
                    options={categoriesConstants}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                    formatOption={x => getLocalisationMessage(x)}
                  />
                )}

                {(orderStatus === IN_TRANSIT ||
                  orderStatus === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    disableP7={false}
                    name="to_warehouse"
                    label={getLocalisationMessage(
                      "destination_warehouse",
                      "Destination Warehouse",
                    )}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("to_postcode.name", props)
                        ? [fp.get("to_postcode.name", props)]
                        : null
                    }
                    jurisdictionIds={
                      !fp.get("to_postcode.name", props) &&
                      fp.get("to_jurisdiction.id", props)
                        ? [fp.get("to_jurisdiction.id", props)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <div style={{ paddingTop: 8 }}>
                  <FormSelectField
                    name="transportationType"
                    fullWidth={true}
                    options={TransportationType}
                    formatOption={x =>
                      getLocalisationMessage(x.toLowerCase(), formatText(x))
                    }
                    label={getLocalisationMessage(
                      "transportation_type",
                      "Transportation Type",
                    )}
                  />
                </div>

                <FormTextField
                  type="number"
                  margin="dense"
                  name="weight"
                  fullWidth={true}
                  label={getLocalisationMessage("weight_kg", "Weight (kg)")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <CustomButton
            disabled={props.isLoading || props.scannedOrders.isEmpty()}
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() => props.handleSubmit()}
            endIcon={
              props.isLoading ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <Done size={20} />
              )
            }
          >
            {props.isLoading
              ? getLocalisationMessage("loading")
              : getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderOutboundSortingVerifyOrdersDialog);
