import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: "ACTIVE" });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const enhancer = compose(
  getContext({
    getCachedVenueMenu: PropTypes.func,
    getVenueMenuPredictions: PropTypes.func,
  }),
);

const VenueMenuAutoComplete = enhancer(
  componentFromStream(propsStream => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ getCachedVenueMenu, input: { value } }) =>
        value && value.id && !value.name && getCachedVenueMenu
          ? getCachedVenueMenu(value.id)
              .map(item => ({ id: value.id, name: item.get("name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value.name"))
      .switchMap(({ getVenueMenuPredictions, input: { value } }) =>
        getVenueMenuPredictions
          ? getVenueMenuPredictions(baseFilter.setSearch(value && value.name))
              .map(fp.flow(fp.get("payload.data.list"), fp.toArray, fromJS))
              .catch(() => Observable.of(List()))
          : Observable.of(List()),
      )
      .distinctUntilChanged(isEqualData)
      .map(v => v.toJS());

    return propsStream.combineLatest(
      valueStream,
      optionsStream,
      (
        { readOnly, getCachedVenueMenu, getVenueMenuPredictions, ...props },
        value,
        options,
      ) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={formatOption}
          input={{ ...props.input, value }}
          readOnly={!getCachedVenueMenu || !getVenueMenuPredictions || readOnly}
        />
      ),
    );
  }),
);

FormVenueMenuAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormVenueMenuAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormVenueMenuAutoComplete(props) {
  return <Field {...props} component={VenueMenuAutoComplete} />;
}
