import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  saveCallTimingsSettings,
  getCallTimingSettingsItem,
} from "../../api/admin/AdminSettingsApi";
import FlexBox from "../../components/ui-core/FlexBox";
import CallTimingsForm from "../../components/call-step-settings/CallTimingsForm";

const enhancer = compose(
  renderIf(props => props.callId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({ container: { maxWidth: "400px" } }),
  mapPropsStream(propsStream => {
    const requestRefresh = createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("callId")
      .switchMap(props =>
        getCallTimingSettingsItem(props.callId)
          .let(mapObjectResponseStream)
          .repeatWhen(() => requestRefresh.stream),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        item: response.get("payload"),
        isLoading: response.get("pending"),
        onRequestRefresh: requestRefresh.handler,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminCallTimingEditDialogWrapper.propTypes = {
  classes: PropTypes.object,
  callId: PropTypes.number.isRequired,
  entityId: PropTypes.string,
  showErrorMessage: PropTypes.func,
  item: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function AdminCallTimingEditDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  const initialValues = {
    id: props.item.get("id"),
    address_type: props.item.get("address_type"),
    courier_type: props.item.get("courier_type"),
    call_timing_permission_type: props.item.get("call_timing_permission_type"),
    startTime: new Date(`1970-01-01 ${props.item.get("start_time")}`),
    endTime: new Date(`1970-01-01 ${props.item.get("end_time")}`),
    customerId: props.entityId ? props.entityId : null,
  };

  return (
    <Dialog
      open={true}
      contentClassName={classes.container}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
        },
      }}
    >
      <DialogTitle>
        {getLocalisationMessage("edit_call_timing", "Edit Call Timing")}
      </DialogTitle>
      <DialogContent>
        <FlexBox direction="column">
          <CallTimingsForm
            initialValues={toCamelCase(initialValues)}
            onDismiss={props.onRequestClose}
            onSubmit={values =>
              saveCallTimingsSettings(toSnakeCase(values)).catch(
                ResponseError.throw,
              )
            }
            onSubmitFail={props.showErrorMessage}
            onSubmitSuccess={() => {
              props.onRequestClose();
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_call_timing_updated",
                  "Successfully Call Timing Updated",
                ),
              );
            }}
          />
        </FlexBox>
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(AdminCallTimingEditDialogWrapper);
