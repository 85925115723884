import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { BUSINESS, RESIDENTIAL } from "../../constants/AddressType";
import {
  CALL,
  DO_NOT_CALL,
  CALL_ANY_TIME,
} from "../../constants/CallTimingPermissionTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: { padding: "5px 15px", backgroundColor: "#fff" },
    checkbox: { whiteSpace: "nowrap" },
    classSelect: {
      width: 320,
    },
  }),
  reduxForm({
    enableReinitialize: true,
    form: "CallStepSettingsFilterForm",
  }),
  formValues({ fromDate: "fromDate", toDate: "toDate" }),
  pureComponent(fp.pick(["fromDate", "toDate"])),
);

const DEFAULT = null;

// const EventCourierTypes = OrderedSet.of(
//   DEFAULT,
//   SAVER,
//   NEXT_DAY,
//   SAME_DAY,
//   URGENT,
// );

const EventAddressTypes = OrderedSet.of(DEFAULT, RESIDENTIAL, BUSINESS);

const EventCallTimingPermissionTypes = OrderedSet.of(
  DEFAULT,
  CALL,
  DO_NOT_CALL,
  CALL_ANY_TIME,
);

CallStepSettingsFilterForm.propTypes = {
  classes: PropTypes.object,

  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),

  initialValues: PropTypes.object,
  onChange: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function CallStepSettingsFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <div className={classes.container}>
      <form autoComplete="off" onSubmit={props.handleSubmit}>
        <FlexBox gutter={8} justify="flex-start">
          <FlexBox>
            <FormCourierTypeSelectField
              className={classes.classSelect}
              fullWidth={true}
              optional={true}
              name="courierType"
              label={getLocalisationMessage("courier_type", "Courier Type")}
            />
          </FlexBox>

          <FlexBox>
            <FormSelectField
              name="addressType"
              className={classes.classSelect}
              label={getLocalisationMessage("address_type", "Address Type")}
              fullWidth={true}
              options={EventAddressTypes}
              formatOption={x => getLocalisationMessage(x, formatText(x))}
            />
          </FlexBox>

          <FlexBox>
            <FormSelectField
              name="callTimingPermissionType"
              label={getLocalisationMessage(
                "call_timing_permission_type",
                "Call Timing Permission Type",
              )}
              className={classes.classSelect}
              fullWidth={true}
              options={EventCallTimingPermissionTypes}
              formatOption={x => getLocalisationMessage(x, formatText(x))}
            />
          </FlexBox>
        </FlexBox>
      </form>
    </div>
  );
}

export default enhancer(CallStepSettingsFilterForm);
