import React, { useState } from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../../helpers/HOCUtils";
import {
  getCurrentLanguage,
  getMessage,
} from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import ModalPaper from "../../ui-core/ModalPaper";
import NewDispatchForm from "./NewDispatchForm";
import {
  createDispatch,
  createReceptacle,
} from "../../../api/admin/AdminIPSApi";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import ConfirmDialog from "../../deprecated/ConfirmDialog";
import FlexBox from "../../ui-core/FlexBox";
import sprintf from "sprintf";
import { RECEPTACLE_ITEM_URL } from "../../../constants/AdminPathConstants";
import { getValue } from "../../../helpers/DataUtils";
import {
  RUSSIAN_LANGUAGE,
  UZBEK_LANGUAGE,
} from "../../../constants/LocaleTypes";

export const formatConfirmLocalisation = (currentLanguage, dispatchNumber) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return (
        <span>
          Депеша <span style={{ fontWeight: "bold" }}>{dispatchNumber}</span>{" "}
          открыта и имеет те же характеристики.
        </span>
      );
    case UZBEK_LANGUAGE:
      return (
        <span>
          {" "}
          <span style={{ fontWeight: "bold" }}>{dispatchNumber}</span>{" "}
          jo'natmasi ochiq va bir xil xususiyatlarga ega.
        </span>
      );
    default:
      return (
        <span>
          Dispatch <span style={{ fontWeight: "bold" }}>{dispatchNumber}</span>{" "}
          is open and has the same characteristics.
        </span>
      );
  }
};

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px" },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        currentLanguage: getCurrentLanguage(state),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

NewDispatchDialog.propTypes = {
  classes: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  refreshOrderList: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

function NewDispatchDialog(props) {
  const { classes, getLocalisationMessage } = props;
  const [confirm, setConfirm] = useState(false);
  const [dispatchValues, setDispatchValues] = useState({});

  return confirm ? (
    <ConfirmDialog
      confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
      dismissButtonLabel={getLocalisationMessage("no", "No")}
      title={`${getLocalisationMessage("are_you_sure", "Are you sure")}`}
      onConfirm={() => {
        createDispatch({
          ...toSnakeCase(dispatchValues),
          destination_office: dispatchValues.destinationOffice.name,
          origin_office: dispatchValues.originOffice.name,
        })
          .then(res => {
            if (res && res.status === "success") {
              setConfirm(false);
              props.onRequestClose();
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_created",
                  "Successfully Created",
                ),
              );
              props.refreshOrderList();
              createReceptacle({
                dispatch_id: getValue(res, "data.dispatch_id_string"),
              })
                .then(r => {
                  if (r && r.data) {
                    props.showSuccessMessage(
                      getLocalisationMessage(
                        "receptacle_created",
                        "Receptacle Created",
                      ),
                    );
                    props.setLocation(
                      sprintf(
                        RECEPTACLE_ITEM_URL,
                        r.data.id,
                        getValue(res, "data.dispatch_id_string"),
                      ),
                    );
                  }
                })
                .catch(error => props.showErrorMessage(error));
            }
          })
          .catch(error => props.showErrorMessage(error));
      }}
      open={confirm}
      onRequestClose={() => setConfirm(false)}
    >
      <FlexBox direction="column" style={{ gap: 13 }}>
        {formatConfirmLocalisation(
          props.currentLanguage,
          dispatchValues.dispatchId,
        )}
        <span>
          {getLocalisationMessage(
            "do_you_still_want_to_create_a_new_dispatch",
            "Do you still want to create a new dispatch?",
          )}
        </span>
      </FlexBox>
    </ConfirmDialog>
  ) : (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("new_dispatch", "New Dispatch")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <NewDispatchForm
        initialValues={dispatchValues}
        onClick={values => {
          setDispatchValues(values);
          setConfirm(true);
        }}
        onDismiss={props.onRequestClose}
        onSubmitSuccess={() => {
          props.onRequestClose();
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_holiday_settings_created",
              "Successfully Holiday Settings Created",
            ),
          );
          props.refreshOrderList();
        }}
        onSubmitFail={props.showErrorMessage}
        showSuccessMessage={props.showSuccessMessage}
        showErrorMessage={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(NewDispatchDialog);
