import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormCheckbox from "../form/FormCheckbox";
import FormChipInput from "../form/FormChipInput";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import {
  getMessage,
  getCurrentLanguage,
} from "../../reducers/LocalizationReducer";
import { RUSSIAN_LANGUAGE } from "../../constants/LocaleTypes";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    currentLanguage: getCurrentLanguage(state),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: (props) => props.theme.palette.appBarTextColor,
      backgroundColor: (props) => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  reduxForm({ form: "BatchAssignSupplierDialog" }),
  formValues("orderNumbers"),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    (props) => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
);

const title = (currentLanguage, ordersNumber) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Назначить курьерскую компанию (заказов - ${ordersNumber})`;

    default:
      return `Assign ${ordersNumber} orders `;
  }
};

BatchAssignSupplierDialog.propTypes = {
  classes: PropTypes.object,
  orderNumbers: PropTypes.array,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  disableOrders: PropTypes.bool,
  disableSupplier: PropTypes.bool,

  getCachedSupplier: PropTypes.func.isRequired,
  getSupplierPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  currentLanguage: PropTypes.string,
  theme: PropTypes.object,
};

function BatchAssignSupplierDialog(props) {
  const { classes, getLocalisationMessage, currentLanguage } = props;

  return (
    <Dialog open={props.open} maxWidth="auto">
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {title(currentLanguage, fp.size(props.orderNumbers))}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <FormChipInput
          focus={true}
          fullWidth={true}
          addOnBlur={true}
          name="orderNumbers"
          disabled={props.disableOrders}
          label={getLocalisationMessage("orders_numbers", "Orders Numbers")}
        />

        <FormSupplierAutoComplete
          name="supplier"
          fullWidth={true}
          label={getLocalisationMessage("supplier", "Supplier")}
          disabled={props.disableSupplier}
          hintText={getLocalisationMessage(
            "type_to_search",
            "Type To Search...",
          )}
        />

        <FormCheckbox
          name="resendShipment"
          label={getLocalisationMessage("resend_shipment", "Resend Shipment")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose} primary={true}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button onClick={props.handleSubmit} primary={true}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchAssignSupplierDialog);
