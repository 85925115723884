import React from "react";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  renderIf("open"),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  useSheet({
    content: {
      minWidth: "320px",
      maxWidth: "400px",
    },
  }),
  reduxForm({
    form: "AssignDriverToWarehouseDialog",
    validate: values => ({
      warehouse: !isValidObjectId(values.warehouse) && "Select Warehouse",
    }),
  }),
);

AssignDriverToWarehouseDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getCachedWarehouse: PropTypes.func.isRequired,
  getWarehousePredictions: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function AssignDriverToWarehouseDialog(props) {
  const { classes, i18n } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      fullWidth={true}
      maxWidth="xs"
      contentClassName={classes.content}
    >
      <DialogContent>
        <PageLoading isLoading={props.submitting} />

        <form autoComplete="off">
          <FormWarehouseAutoComplete
            name="warehouse"
            fullWidth={true}
            hintText={i18n.get("type_to_search", "Type to Search")}
            label={i18n.get("warehouse", "Warehouse")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {i18n.get("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {i18n.get("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(AssignDriverToWarehouseDialog);
