import React, { useState } from "react";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import LocalActForm from "../../components/act-management/LocalActForm";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { LATIN } from "../../constants/AlphabetType";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { createActURL } from "../../api/admin/AdminActManagementApi";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ACT_MANAGEMENT_URL } from "../../constants/AdminPathConstants";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";

const useStyles = makeStyles({});

const enhancer = compose(
  connect(
    state => ({
      userWarehouseId: getUserWarehouseId(state),

      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

ActManagementLocalCreateContainer.propTypes = {
  getLocalisationMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  userWarehouseId: PropTypes.string.isRequired,
};

function ActManagementLocalCreateContainer(props) {
  const { getLocalisationMessage } = props;

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("create_local_act", "Create Local Act")}
      className={classes.root}
    >
      <FlexBox direction="column" flex={true} justify="space-between">
        <LocalActForm
          initialValues={{
            alphabetType: LATIN,
            fromWarehouse: {
              id: props.userWarehouseId,
            },
            date: formatDateTimeToUrl(new Date()),
          }}
          isLoading={isLoading}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          onSubmit={values => {
            setIsLoading(true);
            createActURL(toSnakeCase(values))
              .then(() => {
                props.showSuccessMessage("Successfully Updated");
                props.setLocation(updateQuery(ACT_MANAGEMENT_URL));

                setTimeout(() => setIsLoading(false), 300);
              })
              .catch(error => {
                props.showErrorMessage(error);
                setTimeout(() => setIsLoading(false), 300);
              });
          }}
        />
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(ActManagementLocalCreateContainer);
