import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

//
// Finance Orders
//

const COD_TOTAL_COUNT_URL = `${API_ROOT_URL}/admin/cod/total_counts`;
const ORDER_COD_STATUS_URL = `${API_ROOT_URL}/admin/order/cod_status`;
const WITHDRAWABLE_ORDERS_URL = `${API_ROOT_URL}/admin/withdrawable/orders`;
const UPLOAD_REFERENCE_TEMPLATE = `${API_ROOT_URL}/admin/withdrawable/upload_reference_template`;

export const getCODTotalCount = (request: DataListFilter) =>
  api.getStream(COD_TOTAL_COUNT_URL, { params: request.getDefinedValues() });

export const getWithdrawableOrders = (request: DataListFilter) =>
  api.getStream(WITHDRAWABLE_ORDERS_URL, {
    params: request.getDefinedValues(),
  });

export const batchUpdateCODStatus = (data, getLocalisationMessage) =>
  api.put(ORDER_COD_STATUS_URL, { body: data }).catch(error => {
    const orderNumbers = error.response;

    if (fp.isArray(orderNumbers)) {
      // eslint-disable-next-line no-param-reassign
      error.response = {
        orderNumbers: `${getLocalisationMessage(
          "could_not_update",
          "Could not update",
        )} ${orderNumbers.join(", ")} ${getLocalisationMessage(
          "orders_4",
          "orders",
        )}`,
      };
    }

    throw error;
  });

//
// Customer Transactions
//

const TRANSACTION_LIST_URL = `${API_ROOT_URL}/admin/customer/%s/transactions`;
const TRANSACTION_ITEM_URL = `${API_ROOT_URL}/admin/customer/%s/transaction/%s/details`;
const ENDING_BALANCE_URL = `${API_ROOT_URL}/admin/customer/%s/transaction/ending_balance`;

export const getTransactionList = (
  customerId: number,
  request: DataListFilter,
) =>
  api.getStream(sprintf(TRANSACTION_LIST_URL, customerId), {
    params: request.getDefinedValues(),
  });

export const getTransactionItem = (customerId: number, id: number) =>
  api.getStream(sprintf(TRANSACTION_ITEM_URL, customerId, id));

export const getEndingBalance = (customerId: number) =>
  api.getStream(sprintf(ENDING_BALANCE_URL, customerId));

//
// COD Transfer
//

const COD_TRANSFER_TOTAL_URL = `${API_ROOT_URL}/admin/codtransfer/calculate_total`;
const COD_TRANSFER_STATUS_COUNT_URL = `${API_ROOT_URL}/admin/codtransfer/status_counts`;

const COD_TRANSFER_URL = `${API_ROOT_URL}/admin/codtransfer`;
const COD_TRANSFER_SAAS_URL = `${API_ROOT_URL}/admin/saas_codtransfer`;

const COD_TRANSFER_LIST_URL = `${API_ROOT_URL}/admin/codtransfers/`;
const COD_TRANSFER_ITEM_URL = `${API_ROOT_URL}/admin/codtransfer/%s`;
const COD_TRANSFER_ITEM_ORDERS_URL = `${API_ROOT_URL}/admin/codtransfer/%s/orders`;
const COD_TRANSFER_ITEM_STATUS_URL = `${API_ROOT_URL}/admin/codtransfer/%s/status`;
const COD_TRANSFER_ITEM_REFERENCE_URL = `${API_ROOT_URL}/admin/codtransfer/%s/reference`;

export const getCODTransferList = (request: DataListFilter) =>
  api.getStream(COD_TRANSFER_LIST_URL, { params: request.getDefinedValues() });

export const getCODTransferStatusCount = (request: DataListFilter) =>
  api.getStream(COD_TRANSFER_STATUS_COUNT_URL, {
    params: request.getDefinedValues(),
  });

export const getCODTransfer = (id: number) =>
  api.getStream(sprintf(COD_TRANSFER_ITEM_URL, id));

export const deleteCODTransferItem = (id: number) =>
  api.delete(sprintf(COD_TRANSFER_ITEM_URL, id));

export const getCODTransferOrders = (id: number, request: DataListFilter) =>
  api.getStream(sprintf(COD_TRANSFER_ITEM_ORDERS_URL, id), {
    params: request.getDefinedValues(),
  });

export const updateCODTransferStatus = (id: number, body) =>
  api.put(sprintf(COD_TRANSFER_ITEM_STATUS_URL, id), { body });

export const updateCODTransferReference = (id: number, body) =>
  api.put(sprintf(COD_TRANSFER_ITEM_REFERENCE_URL, id), {
    body,
  });

export const calculateCodTransferTotal = body =>
  api.post(COD_TRANSFER_TOTAL_URL, { body });

export const createCODTransfer = (request, getLocalisationMessage) =>
  api.post(COD_TRANSFER_URL, { body: request }).catch(error => {
    const orderNumbers = error.response;

    if (fp.isArray(orderNumbers)) {
      // eslint-disable-next-line no-param-reassign
      error.response = {
        orderNumbers: `${getLocalisationMessage(
          "could_not_update",
          "Could not update",
        )} ${orderNumbers.join(", ")} ${getLocalisationMessage(
          "orders_4",
          "orders",
        )}`,
      };
    }

    throw error;
  });

export const createCODSaaSTransfer = (request, getLocalisationMessage) =>
  api.post(COD_TRANSFER_SAAS_URL, { body: request }).catch(error => {
    const orderNumbers = error.response;

    if (fp.isArray(orderNumbers)) {
      // eslint-disable-next-line no-param-reassign
      error.response = {
        orderNumbers: `${getLocalisationMessage(
          "could_not_update",
          "Could not update",
        )} ${orderNumbers.join(", ")} ${getLocalisationMessage(
          "orders_4",
          "orders",
        )}`,
      };
    }

    throw error;
  });

//
// COD Withdraw
//

const COD_WITHDRAW_TOTAL_URL = `${API_ROOT_URL}/admin/codwithdraw/calculate_total`;
const COD_WITHDRAW_URL = `${API_ROOT_URL}/admin/codwithdraw`;
const COD_WITHDRAW_STATUS_COUNT_URL = `${API_ROOT_URL}/admin/codwithdraw/status_counts`;

const COD_WITHDRAW_ITEM_URL = `${API_ROOT_URL}/admin/codwithdraw/%s`;
const COD_WITHDRAW_ITEM_ORDERS_URL = `${API_ROOT_URL}/admin/codwithdraw/%s/orders`;
const COD_WITHDRAW_ITEM_STATUS_URL = `${API_ROOT_URL}/admin/codwithdraw/%s/status`;
const COD_WITHDRAW_ITEM_REFERENCE_URL = `${API_ROOT_URL}/admin/codwithdraw/%s/reference`;

const COD_WITHDRAW_LIST_URL = `${API_ROOT_URL}/admin/codwithdraws/`;

export const calculateCodWithdrawTotal = body =>
  api.post(COD_WITHDRAW_TOTAL_URL, { body });

export const deleteCODWithdraw = id =>
  api.delete(sprintf(COD_WITHDRAW_ITEM_URL, id));

export const getCODWithdrawList = (request: DataListFilter) =>
  api.getStream(COD_WITHDRAW_LIST_URL, { params: request.getDefinedValues() });

export const getCODWithdrawStatusCount = (request: DataListFilter) =>
  api.getStream(COD_WITHDRAW_STATUS_COUNT_URL, {
    params: request.getDefinedValues(),
  });

export const getCODWithdraw = (id: number) =>
  api.getStream(sprintf(COD_WITHDRAW_ITEM_URL, id));

export const getCODWithdrawOrders = (id: number, request: DataListFilter) =>
  api.getStream(sprintf(COD_WITHDRAW_ITEM_ORDERS_URL, id), {
    params: request.getDefinedValues(),
  });

export const updateCODWithdrawStatus = (id: number, body) =>
  api.put(sprintf(COD_WITHDRAW_ITEM_STATUS_URL, id), {
    body,
  });

export const updateCODWithdrawReference = (id: number, body) =>
  api.put(sprintf(COD_WITHDRAW_ITEM_REFERENCE_URL, id), {
    body,
  });

export const createCODWithdraw = (request, i18n) =>
  api.post(COD_WITHDRAW_URL, { body: request, timeout: 25000 }).catch(error => {
    const orderNumbers = error.response;

    if (fp.isArray(orderNumbers)) {
      // eslint-disable-next-line no-param-reassign
      error.response = {
        orderNumbers: `${i18n.get(
          "could_not_update",
          "Could not update",
        )} ${orderNumbers.join(", ")} ${i18n.get("orders_4", "orders")}`,
      };
    }

    throw error;
  });

const CASHER_CHOOSE_URL = `${API_ROOT_URL}/admin/cashier/choose`;
const CASHIER_USER_CHOOSE_URL = `${API_ROOT_URL}/admin/cashier/users/choose`;
const GET_TRANSFER_TYPES_URL = `${API_ROOT_URL}/admin/transfer_types`;
const CASHIER_LIST_URL = `${API_ROOT_URL}/admin/cashiers`;
const ADMIN_CASHIER_ITEM_URL = `${API_ROOT_URL}/admin/cashier/%s`;
const ADMIN_CASHIER_URL = `${API_ROOT_URL}/admin/cashier`;
const USER_ITEM_URL = `${API_ROOT_URL}/admin/user/%s`;

export const getTransferTypes = () => api.getStream(GET_TRANSFER_TYPES_URL);

export const createCashier = values =>
  api.post(ADMIN_CASHIER_URL, { body: values });

export const getCashierItem = id =>
  api.getStream(sprintf(ADMIN_CASHIER_ITEM_URL, id));

export const updateCashier = values =>
  api.put(ADMIN_CASHIER_URL, { body: values });

export const getCashier = (request: DataListFilter) =>
  api.getStream(CASHER_CHOOSE_URL, { params: request.getDefinedValues() });

export const getCashiersList = (request: DataListFilter) =>
  api.getStream(CASHIER_LIST_URL, { params: request.getDefinedValues() });

export const uploadReferenceTemplate = (file: File) =>
  api.postStream(UPLOAD_REFERENCE_TEMPLATE, { file });

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(USER_ITEM_URL, id)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(CASHIER_USER_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getCachedCashierUser = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getCashierUserPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

// Bank Deposits

const COMPANY_CUSTOMERS = `${API_ROOT_URL}/admin/company/%s/customers`;
const BANK_DEPOSIT_ORDER = `${API_ROOT_URL}/admin/bank_deposit/orders`;
const BANK_DEPOSIT_LIST = `${API_ROOT_URL}/admin/bank_deposits`;
const BANK_DEPOSIT_CREATE_URL = `${API_ROOT_URL}/admin/bank_deposit`;
const BANK_DEPOSIT_ITEM_URL = `${API_ROOT_URL}/admin/bank_deposit/%s`;
const BANK_DEPOSIT_ITEM_ORDER_URL = `${API_ROOT_URL}/admin/bank_deposit/%s/orders`;
const BANK_DEPOSIT_ITEM_STATUS_UPDATE_URL = `${API_ROOT_URL}/admin/bank_deposit/%s/status`;
const BANK_DEPOSIT_CALCULATE_TOTAL = `${API_ROOT_URL}/admin/bank_deposit/calculate_total`;
const BANK_DEPOSIT_STATUS_COUNT_URL = `${API_ROOT_URL}/admin/bank_deposit/status_counts`;

export const getCompanyCustomers = (companyId: Number) =>
  api.getStream(sprintf(COMPANY_CUSTOMERS, companyId));

export const getBankDepositOrdersList = (request: DataListFilter) =>
  api.getStream(BANK_DEPOSIT_ORDER, { params: request.getDefinedValues() });

export const getBankDepositsList = (request: DataListFilter) =>
  api.getStream(BANK_DEPOSIT_LIST, { params: request.getDefinedValues() });

export const createBankDeposit = request =>
  api.post(BANK_DEPOSIT_CREATE_URL, { body: request });

export const calculateBankDepositTotal = body =>
  api.post(BANK_DEPOSIT_CALCULATE_TOTAL, { body });

export const getBankDepositItem = (id: Number) =>
  api.getStream(sprintf(BANK_DEPOSIT_ITEM_URL, id));

export const getBankDepositItemOrders = (id: number, request: DataListFilter) =>
  api.getStream(sprintf(BANK_DEPOSIT_ITEM_ORDER_URL, id), {
    params: request.getDefinedValues(),
  });

export const updateBankDepositStatus = (id: number, body) =>
  api.put(sprintf(BANK_DEPOSIT_ITEM_STATUS_UPDATE_URL, id), {
    body,
  });

export const getBankDepositStatusCount = (request: DataListFilter) =>
  api.getStream(BANK_DEPOSIT_STATUS_COUNT_URL, {
    params: request.getDefinedValues(),
  });
