import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import {
  Table,
  Dialog,
  TableRow,
  TableBody,
  Button,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormCityChips from "../form/FormCityV2Chips";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { createDefaultPackageNewPricelistItem } from "../../actions/AdminPackageActions";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { formatText } from "../../helpers/FormatUtils";
import { formatCourierType } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { parseIntString } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import MarginTypes, { PERCENT } from "../../constants/MarginTypes";
import { URGENT } from "../../constants/CourierTypes";

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);
const getValues = getFormValues("DefaultPackageEditDialogNew");

const enhancer = compose(
  renderIf("item"),
  useSheet({
    bottom: {
      paddingBottom: 10,
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      formValues: getValues(state),
    }),
    // ({ form }) => ({
    //   formValues: _.get(form, "DefaultPackageEditDialogNew.values"),
    // }),
    { createDefaultPackageNewPricelistItem },
  ),
  withContext(
    {
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => () => {
      const { item, filter, formValues } = props;
      const fromCityAll = fp.get("fromCityAll", formValues);
      const toCityAll = fp.get("toCityAll", formValues);
      const courierType = item.get("courier_type");
      const vehicleType = item.get("vehicle_type");
      const fromCountryId = filter.getIntegerValue("from_country_id");
      const toCountryId = filter.getIntegerValue("to_country_id");
      const fromCityIds = formValues.fromCityIds.map(getObjectId);
      const toCityIds = formValues.toCityIds.map(getObjectId);
      const supplierId = filter.getIntegerValue("supplier_id");
      const customerId = filter.getIntegerValue("customer_id");
      const getPackagePrice = fp.flow(
        fp.omit(["fromCityAll", "toCityAll", "fromCityIds", "toCityIds"]),
        toSnakeCase,
        fp.set("supplier_id", supplierId),
        fp.set("customer_id", customerId || null),
      );

      const response = {
        courier_type: courierType,
        vehicle_type: vehicleType,
        from_city_ids: fromCityAll ? null : fromCityIds,
        to_city_ids: toCityAll ? null : toCityIds,
        from_country_id: fromCountryId,
        to_country_id: toCountryId,
        package_price: getPackagePrice(formValues),
      };

      return props
        .createDefaultPackageNewPricelistItem(response)
        .then(props.onSubmitSuccess)
        .catch(props.onSubmitFail);
    },
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("item", isEqualData)
      .map(props => {
        const fromCityIds = idsToObjectArray(
          props.filter.getValue("from_city_id"),
        );
        const toCityIds = idsToObjectArray(props.filter.getValue("to_city_id"));
        const prices = toCamelCase(props.item.get("package_price"));
        const marginType = props.item.getIn(
          ["package_price", "margin_type"],
          PERCENT,
        );

        return { fromCityIds, toCityIds, marginType, ...prices };
      });

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({ ...props, initialValues }),
    );
  }),
  reduxForm({ enableReinitialize: true, form: "DefaultPackageEditDialogNew" }),
);

DefaultPackageEditDialogNew.propTypes = {
  classes: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  item: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function DefaultPackageEditDialogNew(props) {
  const { classes, filter, getLocalisationMessage } = props;
  const courierType = props.item.get("courier_type");
  const vehicleType = props.item.get("vehicle_type");
  const isUrgent = courierType === URGENT;
  const fromCountryId = filter.getIntegerValue("from_country_id");
  const toCountryId = filter.getIntegerValue("to_country_id");
  const fromCityAll = fp.get(["formValues", "fromCityAll"], props);
  const toCityAll = fp.get(["formValues", "toCityAll"], props);

  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onRequestClose={props.onRequestClose}
      title={`${formatCourierType(courierType)} / ${formatText(vehicleType)}`}
      actions={
        <div>
          <Button onClick={props.onRequestClose}>
            {" "}
            {getLocalisationMessage("dismiss", "Dismiss")}{" "}
          </Button>

          {props.dirty && (
            <Button onClick={props.reset}>
              {" "}
              {getLocalisationMessage("reset", "Reset")}{" "}
            </Button>
          )}

          <Button disabled={props.submitting} onClick={props.handleSubmit}>
            {" "}
            {getLocalisationMessage("submit", "Submit")}{" "}
          </Button>
        </div>
      }
    >
      <PageLoading isLoading={props.submitting} />

      <br />

      <form>
        <Table>
          <TableBody displayRowCheckbox={false}>
            <TableRow selectable={false}>
              <TableCell className={classes.bottom}>
                {!fromCityAll && (
                  <FormCityChips
                    name="fromCityIds"
                    fullWidth={true}
                    label={getLocalisationMessage("from_cities", "From cities")}
                    countryId={fromCountryId}
                  />
                )}
                <FormCheckbox
                  name="fromCityAll"
                  label={getLocalisationMessage(
                    "select_all_form_city",
                    "Select all form city",
                  )}
                />
              </TableCell>

              <TableCell className={classes.bottom}>
                {!toCityAll && (
                  <FormCityChips
                    name="toCityIds"
                    fullWidth={true}
                    label={getLocalisationMessage("to_cities", "To cities")}
                    countryId={toCountryId}
                  />
                )}
                <FormCheckbox
                  name="toCityAll"
                  label={getLocalisationMessage(
                    "select_all_to_city",
                    "Select all to city",
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow selectable={false}>
              <TableCell>{getLocalisationMessage("price", "Price")}</TableCell>

              <TableCell>
                <FormTextField name="base" fullWidth={true} />
              </TableCell>
            </TableRow>

            <TableRow selectable={false}>
              <TableCell>
                {getLocalisationMessage("price_for_per_kg", "Price for per Kg")}
              </TableCell>

              <TableCell>
                <FormTextField name="perKg" fullWidth={true} />
              </TableCell>
            </TableRow>

            <TableRow selectable={false}>
              <TableCell>
                {getLocalisationMessage("rto_charge", "RTO Charge")}
              </TableCell>

              <TableCell>
                <FormTextField name="rtoBase" fullWidth={true} />
              </TableCell>
            </TableRow>

            <TableRow selectable={false}>
              <TableCell>
                {getLocalisationMessage(
                  "rto_charge_for_per_kg",
                  "RTO Charge for per kg",
                )}
              </TableCell>

              <TableCell>
                <FormTextField name="rtoPerKg" fullWidth={true} />
              </TableCell>
            </TableRow>

            {isUrgent && (
              <TableRow selectable={false}>
                <TableCell>
                  {getLocalisationMessage("charge_per_km", "Charge per KM")}
                </TableCell>

                <TableCell>
                  <FormTextField name="perKm" fullWidth={true} />
                </TableCell>
              </TableRow>
            )}

            <TableRow selectable={false}>
              <TableCell>
                {getLocalisationMessage("kg_included", "KG Included")}
              </TableCell>

              <TableCell>
                <FormTextField name="kgIncluded" fullWidth={true} />
              </TableCell>
            </TableRow>

            {isUrgent && (
              <TableRow selectable={false}>
                <TableCell>
                  {getLocalisationMessage("km_included", "KM Included")}
                </TableCell>

                <TableCell>
                  <FormTextField name="kmIncluded" fullWidth={true} />
                </TableCell>
              </TableRow>
            )}

            <TableRow selectable={false}>
              <TableCell>
                {getLocalisationMessage("margin_type", "Margin Type")}
              </TableCell>

              <TableCell>
                <FormSelectField
                  name="marginType"
                  autoWidth={true}
                  fullWidth={true}
                  label={getLocalisationMessage("margin_type", "Margin Type")}
                  options={MarginTypes}
                  formatOption={formatText}
                />
              </TableCell>
            </TableRow>

            <TableRow selectable={false}>
              <TableCell>
                {getLocalisationMessage("margin", "Margin")}
              </TableCell>

              <TableCell>
                <FormTextField name="margin" fullWidth={true} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </form>
    </Dialog>
  );
}

export default enhancer(DefaultPackageEditDialogNew);
