import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getSupplierContactsList } from "../../api/admin/AdminSupplierContactsApi";
import AdminSupplierContactsFilterWrapper from "../../wrappers/admin/AdminSupplierContactsFilterWrapper";
import AdminSupplierContactEditDialogWrapper from "../../wrappers/admin/AdminSupplierContactEditDialogWrapper";
import AdminSupplierContactCreateDialogWrapper from "../../wrappers/admin/AdminSupplierContactCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Text, { MUTED } from "../../components/ui-core/Text";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_SUPPLIER_CONTACTS_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const SUPPLIER_FILTER_DIALOG_HASH = "#SFDH";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map(query => new DataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter]) =>
        getSupplierContactsList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .startWith({})
      .map(response => fromJS(response))
      .map(response =>
        Map({
          pending: response.get("pending", false),
          total: response.getIn(["payload", "data", "total"], 0),
          list: response.getIn(["payload", "data"], List()),
        }),
      );

    return propsStream
      .combineLatest(
        filterStream,
        listResponseStream,
        (props, filter, listResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: listResponse.get("list"),
          total: listResponse.get("total"),
          isLoading: listResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierContactsList.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),

  isAdminViewer: PropTypes.bool,
};

function AdminSupplierContactsList(props) {
  const { location, getLocalisationMessage } = props;
  const EmptyContent = (
    <Text type={MUTED}>{getLocalisationMessage("na", "N/A")}</Text>
  );

  return (
    <AdminAppLayout
      title={getLocalisationMessage("supplier_contacts", "Supplier Contacts")}
    >
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <AdminSupplierContactCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminSupplierContactEditDialogWrapper
        supplierContactId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view"))}
      />

      <AdminSupplierContactsFilterWrapper
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        open={location.hash === SUPPLIER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <IconButton
                tooltip={getLocalisationMessage(
                  "create_new_supplier_contact",
                  "Create New Supplier Contact",
                )}
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <Add />
              </IconButton>
            )}

            <IconButton
              tooltip={getLocalisationMessage(
                "filter_contacts",
                "Filter Contacts",
              )}
              containerElement={
                <Link to={updateHash(location, SUPPLIER_FILTER_DIALOG_HASH)} />
              }
            >
              <FilterList />
            </IconButton>

            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_SUPPLIER_CONTACTS_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage(
                  "download_contacts_csv",
                  "Download Contacts CSV",
                )}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={96}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              {!props.isAdminViewer ? (
                <Link
                  to={updateQuery(
                    props.location,
                    fp.set("view", row.cellData.get("id")),
                  )}
                >
                  {row.cellData.get("id")}
                </Link>
              ) : (
                row.cellData.get("id")
              )}
            </strong>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("courier_name", "Courier Name")}
          dataKey="supplier_name"
          disableSort={true}
          cellRenderer={row => (
            <div>
              {row.cellData.getIn(["supplier", "name"]) || EmptyContent}
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("contact_role", "Contact Role")}
          dataKey="contact_role"
          disableSort={true}
          cellRenderer={row => (
            <div>
              {getLocalisationMessage(
                row.cellData.get("contact_role"),
                formatText(row.cellData.get("contact_role")),
              ) || EmptyContent}
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("supplier_contact", "Supplier Contact")}
          disableSort={true}
          dataKey="contact_name"
          cellRenderer={row => (
            <MultiLineCell
              fallBackValue={EmptyContent}
              firstLine={row.cellData.get("first_name")}
              secondLine={row.cellData.get("last_name")}
            />
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("contacts", "Contacts")}
          disableSort={true}
          dataKey="supplier_contact"
          cellRenderer={row => (
            <MultiLineCell
              fallBackValue={EmptyContent}
              firstLine={row.cellData.get("email")}
              secondLine={row.cellData.get("phone_number")}
            />
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          cellRenderer={row => (
            <div>
              {getLocalisationMessage(row.cellData.get("status")) ||
                formatText(row.cellData.get("status")) ||
                EmptyContent}
            </div>
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminSupplierContactsList);
