import { compose, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import useSheet from "react-jss";
import { pureComponent } from "../../helpers/HOCUtils";
import fp from "lodash/fp";
import { cleanupStringArray } from "../../helpers/FormatUtils";
import FlexBox from "../ui-core/FlexBox";
import ScannerTextField from "../deprecated/ScannerTextField";
import React from "react";
import PropTypes from "prop-types";

const parseOrderNumbers = fp.flow(
  fp.trim,
  (x) => x.replace(/\W/g, " "),
  cleanupStringArray,
);

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    hintText: { fontSize: "14px", marginTop: "5px" },
    input: { "& input": { fontSize: "20px" } },
    scanner: { flexDirection: "column", width: "100%" },
  }),
  withHandlers({
    handleSubmit: (props) => (value) => {
      const orderNumbers = value.split(",");
      props.onSubmit(parseOrderNumbers(orderNumbers[0]));
    },
  }),
  pureComponent(fp.pick(["focusInput"])),
);

OrderCustomsScanForm.propTypes = {
  classes: PropTypes.object,

  handleSubmit: PropTypes.func,
  focusInput: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCustomsScanForm(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <FlexBox gutter={8} className={classes.scanner}>
      <FlexBox flex={true} direction="column">
        <ScannerTextField
          fullWidth={true}
          variant="outlined"
          label={getLocalisationMessage("add_orders", "Add orders")}
          focus={props.focusInput}
          className={classes.input}
          id="OrderCustomsScanFormTextField"
          onChange={props.handleSubmit}
        />
        <span className={classes.hintText}>
          {getLocalisationMessage(
            "scan_the_shipment_barcode",
            "Scan the shipment barcode",
          )}
        </span>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderCustomsScanForm);
