import React from "react";
import _ from "lodash";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import MarketplaceSettingsDialogForm from "./MarketplaceMenuSettingsDialogForm";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("MarketplaceSettingsForm");

const enhancer = compose(
  connect((state, props) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    field: valueSelector(state, `${props.name}`),
  })),
  reduxForm({
    form: "MarketplaceSettingsForm",
    enableReinitialize: true,
  }),
  withState("state", "setState", {
    isOpenDialog: false,
  }),
  useSheet({
    textCenter: { textAlign: "center" },
    actionTitle: { width: "200px" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    alignSelf: { alignSelf: "center" },
  }),
);

MarketplaceMenuSettingsForm.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  change: PropTypes.func,
  field: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function MarketplaceMenuSettingsForm(props) {
  const { getLocalisationMessage } = props;
  const selectedMenuSize = props.field
    ? fromJS(_.filter(props.field, { active: true })).size
    : 0;

  return (
    <FlexBox container={8} flex={true}>
      {props.state.isOpenDialog && (
        <MarketplaceSettingsDialogForm
          initialValues={props.field}
          type={props.type}
          onSubmit={values => {
            props.change(props.name, values);
            props.setState(fp.set("isOpenDialog", false));
          }}
        />
      )}

      <FlexBox
        gutter={8}
        flex={true}
        direction="column"
        className={props.classes.alignSelf}
      >
        {`${getLocalisationMessage(
          "total",
          "Total",
        )} ${selectedMenuSize} ${getLocalisationMessage(
          "items_selected",
          "items selected",
        )}`}
      </FlexBox>
      <FlexBox gutter={8} flex={true} direction="column">
        <Button onClick={() => props.setState(fp.set("isOpenDialog", true))}>
          {getLocalisationMessage("add_edit_menu", "Add/Edit Menu")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(MarketplaceMenuSettingsForm);
