import { Observable } from "rxjs";
import React from "react";
import sprintf from "sprintf";
import { fromJS, List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  fetchWarehouseDrivers,
  updateWarehouse,
  uploadWarehouseKML,
} from "../../actions/AdminWarehouseActions";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  getMarketplaceCountry,
  getMarketplaceId,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  WAREHOUSE_ITEM_DETAILS_URL,
  WAREHOUSE_ITEM_DRIVERS_URL,
  WAREHOUSE_ITEM_OPERATORS_URL,
} from "../../constants/AdminPathConstants";
import AdminWarehouseItemTabs from "../../constants/AdminWarehouseItemTabs";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getWarehouse,
  updateWarehouseDrivers,
  updateWarehouseItem,
} from "../../api/admin/AdminWarehouseApi";
import { getUserList } from "../../api/admin/AdminUserApi";
import AdminWarehouseItemWrapper from "../../wrappers/admin/AdminWarehouseItemWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import RoutingTabs from "../../components/deprecated/RoutingTabs";
import WarehouseItemDriversForm from "../../components/warehouse-core/WarehouseItemDriversForm";
import WarehouseItemOperatorsList from "../../components/warehouse-core/WarehouseItemOperatorsList";
import DataListFilter from "../../helpers/DataListFilter";

const enhancer = compose(
  connect(
    state => ({
      baseCountry: getMarketplaceCountry(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      marketplaceId: getMarketplaceId(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      updateWarehouse,
      uploadWarehouseKML,
      fetchWarehouseDrivers,
    },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const idStream = propsStream
      .pluck("params")
      .map(fp.flow(fp.get("warehouseId"), fp.toNumber))
      .distinctUntilChanged(isEqualData);

    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map(query => new DataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const warehouseStream = idStream
      .switchMap(id =>
        getWarehouse(id)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(response => fromJS(response).getIn(["payload", "data"], Map()))
      .startWith(Map());

    const warehouseDriversStream = idStream
      .withLatestFrom(propsStream)
      .switchMap(([id, props]) =>
        props
          .fetchWarehouseDrivers(id)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(response => fromJS(response).getIn(["payload", "data"], Map()))
      .startWith(Map());

    const operatorsStream = filterStream
      .withLatestFrom(idStream)
      .switchMap(([filter, id]) =>
        getUserList(filter.setValue("warehouse_id", id))
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      );

    return propsStream
      .combineLatest(
        idStream,
        warehouseStream,
        warehouseDriversStream,
        operatorsStream,
        filterStream,
        (props, id, warehouse, warehouseDrivers, operators, filter) => ({
          ...props,
          id,
          warehouse,
          warehouseDrivers,
          operators: operators.get("list"),
          isLoading: operators.get("pending"),
          total: operators.get("total"),
          onRequestRefresh,
          filter,
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminWarehouseItem.propTypes = {
  id: PropTypes.number,
  router: PropTypes.object,
  params: PropTypes.object,
  warehouse: PropTypes.instanceOf(Map),
  warehouseDrivers: PropTypes.instanceOf(Map),

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  onRequestRefreshOperator: PropTypes.func,
  uploadWarehouseKML: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  operators: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
};

function AdminWarehouseItem(props) {
  const { params, getLocalisationMessage } = props;
  const detailsTab = sprintf(WAREHOUSE_ITEM_DETAILS_URL, props.id);
  const driversTab = sprintf(WAREHOUSE_ITEM_DRIVERS_URL, props.id);
  const operatorsTab = sprintf(WAREHOUSE_ITEM_OPERATORS_URL, props.id);
  const tabs = [
    {
      label: getLocalisationMessage("details", "Details"),
      value: detailsTab,
    },
    {
      label: getLocalisationMessage("drivers", "Drivers"),
      value: driversTab,
    },
    {
      label: getLocalisationMessage("warehouse_operators", "Operators"),
      value: operatorsTab,
    },
  ];
  return (
    <AdminAppLayout
      title={
        `${getLocalisationMessage(
          "warehouse",
          "Warehouse",
        )} ${props.warehouse && props.warehouse.get("name")}` ||
        getLocalisationMessage("details", "Details")
      }
    >
      <Redirect
        to={detailsTab}
        when={!AdminWarehouseItemTabs.has(params.tab)}
      />

      <RoutingTabs width={400} tabs={tabs} />

      {Boolean(props.warehouse && props.router.isActive(detailsTab)) && (
        <AdminWarehouseItemWrapper
          warehouseId={props.warehouse.get("id")}
          onSubmit={fp.flow(toSnakeCase, values =>
            updateWarehouseItem(props.id, values).catch(ResponseError.throw),
          )}
          initialValues={toCamelCase(props.warehouse)}
          onSubmitFail={props.showErrorMessage}
          showErrorMessage={props.showErrorMessage}
          showSuccessMessage={props.showSuccessMessage}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
            props.onRequestRefresh();
          }}
          onUploadFile={props.onRequestRefresh}
          uploadWarehouseKML={file => props.uploadWarehouseKML(props.id, file)}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
        />
      )}

      {Boolean(props.warehouse && props.router.isActive(operatorsTab)) && (
        <WarehouseItemOperatorsList
          isLoading={props.isLoading}
          total={props.total}
          filter={props.filter}
          onFilterChange={filter => {
            props.setLocationQueryFilter(filter);
          }}
          list={props.operators}
        />
      )}

      {Boolean(props.warehouseDrivers && props.router.isActive(driversTab)) && (
        <WarehouseItemDriversForm
          initialValues={{
            drivers: props.warehouseDrivers.get("drivers")
              ? OrderedMap().withMutations(map => {
                  props.warehouseDrivers.get("drivers").forEach(item => {
                    map.set(item.get("id"), item);
                  });
                })
              : new OrderedMap(),
          }}
          onSubmit={values =>
            updateWarehouseDrivers(
              props.id,
              props.warehouseDrivers
                .set("drivers", values.drivers.toArray())
                .toJS(),
            ).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
            props.onRequestRefresh();
          }}
          onSubmitfail={props.showErrorMessage}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminWarehouseItem);
