import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const VENUE_CITY_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/city/%s`;
const VENUE_CITY_LIST_URL = `${SHOP_API_ROOT_URL}/admin/city/list`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(VENUE_CITY_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(VENUE_CITY_LIST_URL, { params: request.getDefinedValues() }),
});

export const getCachedVenueCity = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getVenueCityPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);
