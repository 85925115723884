import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import ActivityHistoryFilterForm from "../../components/activity-history-core/ActivityHistoryFilterForm";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({
    modal: {
      maxWidth: "800px",
      minWidth: "800px",
      minHeight: "auto",
    },
  }),
);

AdminActivityHistoryFilterWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminActivityHistoryFilterWrapper(props) {
  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage(
        "activity_history_filter",
        "Activity History Filter",
      )}
      onRequestClose={props.onRequestClose}
      paperClassName={props.classes.modal}
    >
      <ActivityHistoryFilterForm
        filter={props.filter}
        onDismiss={props.onRequestClose}
        onFilterChange={props.onFilterChange}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminActivityHistoryFilterWrapper);
