import React from "react";
import { OrderedSet } from "immutable";
import useSheet from "react-jss";
import { pure, compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormImageListUpload from "../form/FormImageListUpload";
import FormVenueMenuAutoComplete from "../form/FormVenueMenuAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";

const enhancer = compose(
  useSheet({
    attachments: { paddingTop: "12px" },
  }),
  reduxForm({
    form: "AdminVenueProductsForm",
    enableReinitialize: true,
  }),
  withContext(
    {
      getCachedVenueMenu: PropTypes.func,
      getVenueMenuPredictions: PropTypes.func,
    },
    props => ({
      getCachedVenueMenu: props.getCachedVenueMenu,
      getVenueMenuPredictions: props.getVenueMenuPredictions,
    }),
  ),
  pure,
);

const statusOptions = OrderedSet.of(ACTIVE, INACTIVE);

AdminVenueProductsForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDismissClick: PropTypes.func.isRequired,
  getCachedVenueMenu: PropTypes.func.isRequired,
  getVenueMenuPredictions: PropTypes.func.isRequired,
};

function AdminVenueProductsForm(props) {
  const { classes } = props;

  return (
    <Card>
      <CardContent>
        <PageLoading isLoading={props.submitting} />

        <form autoComplete="off" onSubmit={props.handleSubmit}>
          <FlexBox container={8} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <FormTextField name="name" fullWidth={true} label="Name" />

                <FormVenueMenuAutoComplete
                  name="menu"
                  fullWidth={true}
                  label="Select Menu"
                />

                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="cost"
                      label="Cost"
                      type="number"
                      fullWidth={true}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="price"
                      label="Price"
                      type="number"
                      fullWidth={true}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="oldPrice"
                      label="Old Price"
                      type="number"
                      fullWidth={true}
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="discount"
                      label="Discount"
                      type="number"
                      fullWidth={true}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="weight"
                      label="Weight"
                      type="number"
                      fullWidth={true}
                    />
                  </FlexBox>
                </FlexBox>
                <FormSelectField
                  name="status"
                  autoWidth={true}
                  fullWidth={true}
                  label="Status"
                  options={statusOptions}
                  formatOption={formatText}
                />
                <FormTextField
                  name="description"
                  rows={4}
                  multiLine={true}
                  label="Description"
                  fullWidth={true}
                />
              </FlexBox>
              <FlexBox
                flex={true}
                direction="column"
                className={classes.attachments}
              >
                <FormImageListUpload name="photos" label="Attachments" />
              </FlexBox>
            </FlexBox>
            <FlexBox gutter={8} flex={true}>
              <CardActions>
                <Button variant="text" onClick={props.onDismissClick}>
                  Dismiss
                </Button>
                <Button variant="text" type="submit">
                  Save
                </Button>
              </CardActions>
            </FlexBox>
          </FlexBox>
        </form>
      </CardContent>
    </Card>
  );
}

export default enhancer(AdminVenueProductsForm);
