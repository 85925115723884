import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getProductCategoryTypeItem,
  updateProductCategoryType,
} from "../../api/shared/ProductCategoryApi";
import { getValue } from "../../helpers/DataUtils";
import ModalPaper from "../ui-core/ModalPaper";
import ProductCategoryForm from "./ProductCategoryForm";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";

const enhancer = compose(
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px", minHeight: 100 },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

UpdateProductCategoryDialog.propTypes = {
  classes: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  refreshList: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
};

function UpdateProductCategoryDialog(props) {
  const { classes, getLocalisationMessage } = props;
  const [data, setData] = useState({});

  useEffect(() => {
    if (props.id) {
      getProductCategoryTypeItem(props.id).then(res => {
        if (res && res.data) {
          setData(getValue(res, "data", {}));
        }
      });
    }
  }, [props.id]);

  return (
    <ModalPaper
      open={props.open}
      title={props.title}
      paperClassName={classes.paper}
    >
      <ProductCategoryForm
        edit={true}
        initialValues={toCamelCase(data)}
        onSubmit={values => {
          updateProductCategoryType(props.id, toSnakeCase(values))
            .then(res => {
              if (res && res.status === "success") {
                props.onRequestClose();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_updated",
                    "Successfully Updated",
                  ),
                );
                setData({});
                props.refreshList();
              }
            })
            .catch(error => props.showErrorMessage(error));
        }}
        onDismiss={() => {
          props.onRequestClose();
          setData({});
        }}
      />
    </ModalPaper>
  );
}

export default enhancer(UpdateProductCategoryDialog);
