import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  withState,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../../helpers/CaseMapper";
import { pipeStreams } from "../../../helpers/StreamUtils";
import ResponseError from "../../../helpers/ResponseError";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { SETTINGS_PRICING_PLANS_URL } from "../../../constants/AdminPathConstants";
import {
  deletePricingPlan,
  updatePricingPlan,
  getPricingPlanItem,
} from "../../../api/admin/AdminSubscriptionApi";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import PageLoading from "../../../components/ui-core/PageLoading";
import PricingPlanForm from "../../../components/subscription-core/PricingPlanForm";
import { updateQuery } from "../../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withState("isDeleteLoading", "setIsLoading", false),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const IdStream = propsStream.map(
          fp.flow(fp.get("params.id"), fp.toFinite),
        );

        return propsStream
          .combineLatest(IdStream, (props, pricingPlanId) => ({
            ...props,
            pricingPlanId,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const pricingPlanItemResponseStream = propsStream
          .distinctUntilKeyChanged("pricingPlanId")
          .switchMap(props =>
            getPricingPlanItem(props.pricingPlanId)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.toPlainObject),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            pricingPlanItemResponseStream,
            (props, pricingPlanItemResponse) => ({
              ...props,
              onRequestRefresh,
              pricingPlan: pricingPlanItemResponse.getIn(["payload", "data"]),
              isLoading: pricingPlanItemResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminPricingPlanItemContainer.propTypes = {
  pricingPlanId: PropTypes.number,
  onRequestRefresh: PropTypes.func,
  pricingPlan: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  location: PropTypes.object,
  isDeleteLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setLocation: PropTypes.func,
};

function AdminPricingPlanItemContainer(props) {
  const { getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={
        props.pricingPlan
          ? `${getLocalisationMessage(
              "pricing_plan_item",
              "Pricing Plan Item",
            )}  - ${props.pricingPlan.get("title", "")}`
          : getLocalisationMessage("pricing_plan_item", "Pricing Plan Item")
      }
    >
      <PageLoading isLoading={props.isLoading || props.isDeleteLoading} />
      <PricingPlanForm
        onDelete={() => {
          props.setIsLoading(true);
          Promise.resolve(
            deletePricingPlan(props.pricingPlanId).catch(ResponseError.throw),
          )
            .then(response => {
              props.setIsLoading(false);
              return response;
            })
            .then(() => {
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_deleted",
                  "Successfully deleted",
                ),
              );
              return props.setLocation(updateQuery(SETTINGS_PRICING_PLANS_URL));
            })
            .catch(error => props.showErrorMessage(error));
        }}
        onSubmit={values =>
          updatePricingPlan(props.pricingPlanId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() =>
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          )
        }
        onSubmitFail={props.showErrorMessage}
        initialValues={{
          ...toCamelCase(props.pricingPlan),
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPricingPlanItemContainer);
