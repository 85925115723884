import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@material-ui/core";
import { connect } from "react-redux";
import TabBadge from "../deprecated/TabBadge";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  CONSOLIDATED,
  CONTAINER,
  ORDER,
  ROUTE,
  SIMPLE,
} from "../../constants/OrderType";
import fp from "lodash/fp";

const tabTypes = [ORDER, SIMPLE, CONSOLIDATED, CONTAINER, ROUTE];

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    tabs: {
      margin: "0 -1rem",
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: "1px solid #eee",
    },
  }),
);

OfflineOrderSortingTabs.propTypes = {
  classes: PropTypes.object,
  tabCount: PropTypes.object,
  buttons: PropTypes.element,
  getLocalisationMessage: PropTypes.func,
  onTabChange: PropTypes.func,
  tabValue: PropTypes.oneOf(tabTypes),
};

OfflineOrderSortingTabs.defaultProps = {
  bagsCount: 0,
};

function OfflineOrderSortingTabs(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <React.Fragment>
      {props.tabValue && (
        <Tabs
          variant="fullWidth"
          className={classes.tabs}
          onChange={(e, v) => props.onTabChange(v)}
          value={props.tabValue}
        >
          {tabTypes.map(type => {
            const subLabel =
              type === ORDER
                ? "shipments_in_stock"
                : type === SIMPLE
                ? "shipments_are_ready_for_dispatch"
                : type === CONSOLIDATED
                ? "shipments_are_ready_to_ship"
                : type === CONTAINER
                ? "bagging_preparation"
                : "sent_bags";

            return (
              <Tab
                key={type}
                value={type}
                label={
                  <TabBadge
                    label={getLocalisationMessage(fp.toLower(type))}
                    subLabel={getLocalisationMessage(subLabel)}
                    badge={props.tabCount[type]}
                  />
                }
                style={{
                  lineHeight: "24px",
                }}
              />
            );
          })}
        </Tabs>
      )}
      <div className={classes.root}>{props.buttons}</div>
    </React.Fragment>
  );
}

export default enhancer(OfflineOrderSortingTabs);
