import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
      },
    },
  }),
  reduxForm({ form: "MergeJobDialog" }),
  formValues("orderNumbers"),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
);

MergeJobDialog.propTypes = {
  classes: PropTypes.object,
  orderNumbers: PropTypes.array,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  disableOrders: PropTypes.bool,
  disableSupplier: PropTypes.bool,

  getCachedSupplier: PropTypes.func.isRequired,
  getSupplierPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  theme: PropTypes.object,
};

function MergeJobDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      className={classes.rootStyle}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle style={{ color: props.theme.palette.appBarTextColor }}>
        {`${getLocalisationMessage("merge", "Merge")} ${fp.size(
          props.orderNumbers,
        )} ${getLocalisationMessage("jobs", "Jobs")}.`}
      </DialogTitle>
      <DialogContent>
        <FormChipInput
          focus={true}
          fullWidth={true}
          addOnBlur={true}
          name="orderNumbers"
          disabled={props.disableOrders}
          label={getLocalisationMessage("job_id", "Job ID")}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose} primary={true}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit} primary={true}>
            {getLocalisationMessage("merge", "Merge")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(MergeJobDialog);
