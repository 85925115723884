import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";
import {
  getShipmentsBarcodes,
  HANDOVER_PDF_URL,
  saveOrderShiftHandover,
} from "../../api/v2/admin/AdminOrderSortingApi";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../reducers/NotificationsReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import ShiftingHandoverVerifyDialog from "./ShiftingHandoverVerifyDialog";
import { getUserId } from "../../reducers/ProfileReducer";
import { IS_DOWNLOADING, IS_PRINTING } from "../orders-core/AdminPostForm";
import { getShiftPDF } from "../../helpers/FormUtils";
import _ from "lodash";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
      userId: getUserId(state),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
);

AdminShiftingHandoverVerifyDialogWrapper.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  open: PropTypes.bool,

  onRequestClose: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  userId: PropTypes.number,
};

function AdminShiftingHandoverVerifyDialogWrapper(props) {
  const { userId } = props;
  const [barcodes, setBarcodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [handoverPDF, setHandoverPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: HANDOVER_PDF_URL,
  });

  useEffect(() => {
    setIsLoading(true);
    getShipmentsBarcodes()
      .then(res => {
        if (res && res.length > 0) {
          setBarcodes(res);
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading());
  }, [props.open]);

  return (
    <ShiftingHandoverVerifyDialog
      open={props.open}
      barcodes={barcodes}
      isLoadingSubmit={isLoadingSubmit}
      isLoading={isLoading}
      initialValues={{
        verifiedOrderBarcodes: [],
        fromOperator: {
          id: userId,
        },
      }}
      onSubmit={fp.flow(toSnakeCase, values => {
        setIsLoadingSubmit(true);
        return saveOrderShiftHandover(values).catch(ResponseError.throw);
      })}
      onSubmitSuccess={response => {
        setTimeout(() => {
          setIsLoadingSubmit(false);
          props.showSuccessMessage(
            props.i18n.get("successfully_updated", "Successfully Updated"),
          );
          props.onSubmitSuccess(response);
          setBarcodes([]);
        }, 1000);

        getShiftPDF(
          handoverPDF,
          setHandoverPDF,
          IS_PRINTING,
          _.get(response, "data.id"),
          props.showErrorMessage,
        );
      }}
      onSubmitFail={err => {
        props.showErrorMessage(err);
        setIsLoadingSubmit(false);
      }}
      onRequestClose={props.onRequestClose}
    />
  );
}

export default enhancer(AdminShiftingHandoverVerifyDialogWrapper);
