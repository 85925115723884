import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import PriceWrapper from "../ui-core/PriceWrapper";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { isValidDate, isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_COD_WITHDRAW_RECONCILE_STATEMENT_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { withTheme } from "@material-ui/core/styles";

const isValidTransferAmount = fp.overSome([
  fp.isEmpty,
  fp.flow(fp.toNumber, fp.overEvery([fp.isFinite, fp.lte(0)])),
]);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withTheme,
  useSheet({
    getStatement: { float: "right" },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  reduxForm({
    form: "CreateBankDepositDialog",
    validate: (values, props) => ({
      amount:
        !isValidTransferAmount(values.amount) &&
        props.getLocalisationMessage(
          "transfer_amount_must_be_a_positive_finite_number",
          "Transfer amount must be a positive finite number.",
        ),
      depositDate:
        !isValidDate(values.depositDate) &&
        props.getLocalisationMessage(
          "select_deposit_date",
          "Select Deposit Date",
        ),
      processDate:
        !isValidDate(values.processDate) &&
        props.getLocalisationMessage(
          "select_process_date",
          "Select Process Date",
        ),
      note:
        fp.isEmpty(values.note) &&
        props.getLocalisationMessage("add_note", "Add Note"),
      status:
        fp.isEmpty(values.status) &&
        props.getLocalisationMessage("select_status", "Select Status"),
      orderNumbers:
        fp.isEmpty(values.orderNumbers) &&
        props.getLocalisationMessage("add_orders", "Add Orders"),
      customer:
        props.getCachedCustomer &&
        !isValidObjectId(values.customer) &&
        props.getLocalisationMessage("select_customer", "Select Customer"),
    }),
  }),
  formValues({
    orderNumbers: "orderNumbers",
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const totalAmountStream = propsStream
          .map(props => ({
            values: {
              orderNumbers: fp.toArray(props.orderNumbers),
            },
            calculateBankDepositTotal: props.calculateBankDepositTotal,
          }))
          .distinctUntilKeyChanged("values", isEqualData)
          .switchMap(props =>
            !props.values.orderNumbers.length
              ? Observable.of(null)
              : props
                  .calculateBankDepositTotal({
                    order_numbers: props.values.orderNumbers,
                  })
                  .catch(() => Observable.of(null)),
          )
          .map(fp.flow(fp.get("data"), fromJS))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          totalAmountStream,
          (props, totalAmount) => ({ ...props, totalAmount }),
        );
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilKeyChanged("totalAmount", isEqualData)
            .delay(200)
            .filter(props => props.totalAmount)
            .do(props => {
              props.change("amount", props.totalAmount.get("total"));
            }),
        )
          .switchMapTo(Observable.never())
          .startWith(null);

        return propsStream.combineLatest(sideEffectsStream, fp.identity);
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "customer",
      "submitting",
      "processDate",
      "totalAmount",
      "orderNumbers",
    ]),
  ),
);

CreateBankDepositDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  customer: PropTypes.object,
  orderNumbers: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  totalAmount: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CreateBankDepositDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      modal={true}
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle className={classes.dialogTitle}>
        {props.orderNumbers.length}{" "}
        {getLocalisationMessage(
          "orders_for_transfer_approval_total_amount",
          "orders for transfer approval. Total Amount",
        )}
        :{" "}
        {props.totalAmount ? (
          <PriceWrapper
            price={props.totalAmount.get("total")}
            code={props.totalAmount.getIn(["currency", "code"])}
          />
        ) : (
          getLocalisationMessage("calculating", "Calculating")
        )}
        {Boolean(
          isValidObjectId(props.customer) && props.orderNumbers.length > 0,
        ) && (
          <Button
            target="_blank"
            component="a"
            className={classes.getStatement}
            href={updateQuery(CREATE_COD_WITHDRAW_RECONCILE_STATEMENT_URL, {
              customer_id: props.customer.id,
              orderNumbers: props.orderNumbers.join(","),
            })}
          >
            {" "}
            {getLocalisationMessage("get_statement", "Get Statement")}{" "}
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <FlexBox container={8}>
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox>
                <FormChipInput
                  name="orderNumbers"
                  focus={true}
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "enter_order_numbers",
                    "Enter Order Numbers",
                  )} *`}
                />
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormDateField
                        name="depositDate"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "deposit_date",
                          "Deposit Date",
                        )} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="amount"
                        disabled={true}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "deposit_amount",
                          "Deposit Amount",
                        )} *`}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox>
                <FormTextField
                  name="note"
                  fullWidth={true}
                  multiLine={true}
                  label={`${getLocalisationMessage("note", "Note")} *`}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {" "}
          {getLocalisationMessage("dismiss", "Dismiss")}{" "}
        </Button>
        <Button onClick={props.handleSubmit}>
          {" "}
          {getLocalisationMessage("submit", "Submit")}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CreateBankDepositDialog);
