import { Observable } from "rxjs";
import React from "react";
import { Map, Set, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, Button } from "@material-ui/core";
import { connect } from "react-redux";
import {
  ExpandLess as NavigationExpandLess,
  ExpandMore as NavigationExpandMore,
} from "@material-ui/icons";
import OrderList from "../orders-core/OrderList";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderStatusCodes, {
  COMPLETED,
  PICKED_UP,
} from "../../constants/OrderStatusCodes";
import { getOrderList } from "../../api/admin/AdminOrderApi";
import AvatarWithName from "../../components/avatars/AvatarWithName";

const driverOrdersFilter = new DataListFilter({
  size: 200,
  page: 0,
  is_uae: true,
  driver_ids: null,
  status: fp.without([COMPLETED, PICKED_UP], OrderStatusCodes.toArray()),
  simple: true,
  search_type: "order_number",
});
const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: { position: "relative" },
    header: { position: "relative", overflow: "hidden" },
    driverBox: { float: "left" },
    toggleButton: {
      padding: "8px 14px",
      margin: "6px 0",
      float: "right",
      height: "auto",
      lineHeight: "auto",
    },
  }),
  withState("visibleOrders", "toggleOrders", false),
  withState("filter", "onFilterChange", driverOrdersFilter),
  mapPropsStream(propsStream => {
    const orderStream = propsStream
      .map(fp.get("order"))
      .filter(order => order.hasIn(["payload", "driver"]))
      .map(order => order.getIn(["payload", "driver", "id"]))
      .map(driverId => ({
        driver_ids: driverId,
        page: 0,
      }))
      .distinctUntilChanged(isEqualData)
      .map(val => driverOrdersFilter.setValueMap(val));

    const filterStream = propsStream
      .map(fp.get("filter"))
      .filter(filter => filter.hasIn(["values", "driver_ids"]))
      .distinctUntilChanged(isEqualData);

    const mergedStream = Observable.merge(orderStream, filterStream)
      .filter(x => Boolean(x.getValues().driver_ids))
      .distinctUntilChanged(isEqualData);

    const responseStream = mergedStream
      .switchMap(filter => getOrderList(filter).catch(() => Observable.of({})))
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const sideEffectsStream = propsStream
      .map(fp.get("orderNumbers"))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        mergedStream,
        responseStream,
        sideEffectsStream,
        (props, filter, response, numbers) => {
          const list = response
            .get("list")
            .filter(
              listItem => !numbers.contains(listItem.get("order_number")),
            );

          return {
            ...props,
            filter,
            list,
            total: list.size,
            isLoading: response.get("pending"),
          };
        },
      )
      .distinctUntilChanged();
  }),
);

OrderDriverOrders.propTypes = {
  isLoading: PropTypes.bool,
  visibleOrders: PropTypes.bool,
  total: PropTypes.number,
  order: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  list: PropTypes.instanceOf(List),
  orderNumbers: PropTypes.instanceOf(Set),
  taskOrders: PropTypes.instanceOf(List),
  getOrderList: PropTypes.func,
  ordersOnMap: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  onOrderIdsChange: PropTypes.func,
  orderIds: PropTypes.instanceOf(Set),
  onFilterClick: PropTypes.func,
  toggleOrders: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};
OrderDriverOrders.defaultProps = {
  filter: driverOrdersFilter,
};

function OrderDriverOrders(props) {
  const { order, classes, getLocalisationMessage } = props;
  const driver = order.getIn(["payload", "driver"]);
  const hasDriver = order.hasIn(["payload", "driver", "id"]);

  return (
    (hasDriver && (
      <Card className={classes.card}>
        <CardContent className={classes.header}>
          <div className={classes.driverBox}>
            <AvatarWithName name={driver.get("name")}>
              {driver.get("name")}
            </AvatarWithName>
          </div>
          <Button
            onClick={() => props.toggleOrders(val => !val)}
            className={classes.toggleButton}
          >
            <React.Fragment>
              {getLocalisationMessage("pending_order", "Pending order")} (
              {props.total})
              {props.visibleOrders ? (
                <NavigationExpandLess />
              ) : (
                <NavigationExpandMore />
              )}
            </React.Fragment>
          </Button>
        </CardContent>
        {props.visibleOrders && (
          <CardContent>
            <OrderList
              list={props.list || List()}
              totalCount={props.total || 0}
              isLoading={props.isLoading}
              filter={props.filter}
              withFooter={false}
              maxSearchItems={0}
              onFilterChange={filter => props.onFilterChange(() => filter)}
            />
          </CardContent>
        )}
      </Card>
    )) ||
    null
  );
}

export default enhancer(OrderDriverOrders);
