import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITable, { INDEX, RENDER } from "../../components/orders-core/MUITable";
import _ from "lodash";
import { getProgressValidation } from "../../api/admin/AdminSimpleOrderApi";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { SAMPLE_ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import { ArrowBackIos } from "@material-ui/icons";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

AdminSimpleOrderItemList.propTypes = {
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  params: PropTypes.object,
};

function AdminSimpleOrderItemList(props) {
  const { getLocalisationMessage } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  useEffect(() => {
    setIsLoading(true);
    getProgressValidation(props.params.id, page, size)
      .then(res => {
        setIsLoading(false);
        setList(_.get(res, "data.drafts"));
      })
      .catch(error => {
        setIsLoading(false);
        props.showErrorMessage(error);
      });
  }, [props.params, page, size]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createdTotal = list.filter(
    item => item.status === "VALID" && item.is_created === "CREATED",
  );

  return (
    <AdminAppLayout
      title={getLocalisationMessage("created_orders", "Created Orders")}
    >
      <MUITable
        isLoading={isLoading}
        title={
          <Typography
            variant="h5"
            style={{
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "28px",
              textTransform: "capitalize",
            }}
          >
            <IconButton
              onClick={() => props.setLocation(SAMPLE_ORDER_LIST_URL)}
            >
              <ArrowBackIos />
            </IconButton>

            {getLocalisationMessage("created_orders", "Created Orders")}
          </Typography>
        }
        list={createdTotal}
        total={createdTotal.length}
        page={page}
        rowsPerPage={size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        columns={[
          {
            type: INDEX,
            label: "#",
            alignItems: "center",
          },
          {
            type: RENDER,
            name: "service_type",
            render: row => (
              <MultiLineCell firstLine={_.get(row, `barcode.content`, "")} />
            ),
            label: getLocalisationMessage("uploaded_by", "Upload By"),
          },
          {
            type: RENDER,
            name: "service_type",
            render: row => _.get(row, `service_type.content`, ""),
            label: getLocalisationMessage("service_type", "Service Type"),
          },
          {
            type: RENDER,
            name: "sender_jurisdiction",
            render: row => _.get(row, `sender_jurisdiction.content`, ""),
            label: getLocalisationMessage(
              "sender_jurisdiction",
              "Sender Jurisdiction",
            ),
          },
          {
            type: RENDER,
            name: "sender_postcode",
            render: row => _.get(row, `sender_postcode.content`, ""),
            label: getLocalisationMessage("sender_index", "Sender Index"),
          },

          {
            type: RENDER,
            name: "recipient_jurisdiction",
            render: row => _.get(row, `recipient_jurisdiction.content`, ""),
            label: getLocalisationMessage(
              "recipient_jurisdiction",
              "Recipient Jurisdiction",
            ),
          },
          {
            type: RENDER,
            name: "recipient_postcode",
            render: row => _.get(row, `recipient_postcode.content`, ""),
            label: getLocalisationMessage("recipient_index", "Recipient Index"),
          },
        ]}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSimpleOrderItemList);
