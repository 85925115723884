import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-google-map-components";
import {
  Marker as LMarker,
  MarkerIcon as LMarkerIcon,
} from "react-leflet-map-components";
import PickupMarker from "./assets/pickup-marker.svg";
import DropoffMarker from "./assets/dropoff-marker.svg";

const enhancer = compose();

OrderMarker.propTypes = {
  size: PropTypes.number,
  pickup: PropTypes.bool,
  mapType: PropTypes.oneOf(["google_map", "leaflet_map"]),
  position: PropTypes.object.isRequired,
};

OrderMarker.defaultProps = {
  size: 32,
  pickup: true,
  mapType: "google_map",
};

function OrderMarker(props) {
  const { mapType, size, pickup, ...markerProps } = props;

  if (mapType === "leaflet_map") {
    return (
      <LMarker
        {...markerProps}
        icon={
          <LMarkerIcon
            size={{ width: 60, height: 60 }}
            scaledSize={{ width: size, height: size }}
            url={pickup ? PickupMarker : DropoffMarker}
            anchor={{ y: size, x: size / 2 }}
            origin={{ x: size, y: size / 2 }}
          />
        }
      />
    );
  }

  return (
    <Marker
      {...markerProps}
      icon={
        <MarkerIcon
          anchor={{ y: size, x: size / 2 }}
          size={{ width: 60, height: 60 }}
          scaledSize={{ width: size, height: size }}
          url={pickup ? PickupMarker : DropoffMarker}
        />
      }
    />
  );
}

export default enhancer(OrderMarker);
