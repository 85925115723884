import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { PENDING } from "../../constants/CODTransferStatus";
import {
  getCODTransfer,
  getCODTransferOrders,
  updateCODTransferStatus,
  updateCODTransferReference,
} from "../../api/admin/AdminFinanceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import OrderList from "../../components/orders-core/OrderList";
import CODTransferDetails from "../../components/finance-core/CODTransferDetails";
import UpdateCODReferenceDialog from "../../components/finance-core/UpdateCODReferenceDialog";
import UpdateCODTransferStatusDialog from "../../components/finance-core/UpdateCODTransferStatusDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_COD_TRANSFER_STATEMENT_URL } from "../../../shared/constants/FileProxyControllerConstants";

const UPDATE_STATUS_DIALOG_HASH = "#USDH";
const UPDATE_REFERENCE_DIALOG_HASH = "#URDH";

const enhancer = compose(
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withTheme,
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const idStream = propsStream
      .map(fp.flow(fp.get("params.id"), fp.toFinite))
      .distinctUntilChanged();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), DataListFilter.create))
      .distinctUntilChanged(isEqualData);

    const itemResponseStream = idStream
      .switchMap(id =>
        getCODTransfer(id)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const ordersResponseStream = idStream
      .combineLatest(filterStream)
      .switchMap(([id, filter]) =>
        getCODTransferOrders(id, filter)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        itemResponseStream,
        ordersResponseStream,
        (props, filter, itemResponse, ordersResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          item: itemResponse.get("payload"),
          isLoading: itemResponse.get("pending"),
          orders: ordersResponse.get("list"),
          ordersTotal: ordersResponse.get("total"),
          ordersLoading: ordersResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminCODTransferItemContainer.propTypes = {
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  ordersTotal: PropTypes.number,
  ordersLoading: PropTypes.bool,
  orders: PropTypes.instanceOf(List),
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

function AdminCODTransferItemContainer(props) {
  const { item, location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage(
        "transfer_request",
        "Transfer Request",
      )} #${props.item.get("id")}`}
      appBarRightAction={
        <FlexBox style={{ paddingTop: "8px" }}>
          <Button
            target="_blank"
            component="a"
            href={updateQuery(CREATE_COD_TRANSFER_STATEMENT_URL, {
              id: item.get("id"),
            })}
            style={{ color: props.theme.palette.appBarTextColor }}
          >
            {getLocalisationMessage("cod_statement", "COD Statement")}
          </Button>
        </FlexBox>
      }
    >
      <PageLoading isLoading={props.isLoading} />

      <UpdateCODTransferStatusDialog
        open={location.hash === UPDATE_STATUS_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ status: item.get("status") }}
        onSubmit={fp.flow(toSnakeCase, values =>
          updateCODTransferStatus(item.get("id"), values).catch(
            ResponseError.throw,
          ),
        )}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated",
              "Successfully Updated",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <UpdateCODReferenceDialog
        open={location.hash === UPDATE_REFERENCE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ reference: item.get("reference") }}
        onSubmit={fp.flow(toSnakeCase, values =>
          updateCODTransferReference(item.get("id"), values).catch(
            ResponseError.throw,
          ),
        )}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        onSubmitFailure={() => props.onRequestRefresh()}
      />

      <FlexBox flex={true} direction="column" gutter={8}>
        {!props.isLoading && (
          <FlexBox direction="column">
            <CODTransferDetails
              item={item}
              onEditStatusClick={() =>
                props.replaceLocationHash(UPDATE_STATUS_DIALOG_HASH)
              }
              onEditRefClick={
                item.get("status") === PENDING
                  ? null
                  : () =>
                      props.replaceLocationHash(UPDATE_REFERENCE_DIALOG_HASH)
              }
            />
          </FlexBox>
        )}

        <FlexBox flex={true} direction="column">
          <FlexBox flex={true} element={<Paper />}>
            <OrderList
              withShipmentNumber={false}
              withReferenceId={false}
              withCODAmount={true}
              withAmount={false}
              withCustomer={false}
              withWarehouse={false}
              withLocation={false}
              withService={false}
              maxSearchItems={0}
              withAttempts={false}
              withPickupTime={false}
              withLastDriver={false}
              withCreatedTime={false}
              withEstDeliveryTime={false}
              list={props.orders}
              totalCount={props.ordersTotal}
              isLoading={props.ordersLoading}
              filter={props.filter}
              onFilterChange={filter => props.setLocationQueryFilter(filter)}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCODTransferItemContainer);
