import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import BatchOrderCanceledUpdate from "../../components/orders-core/BatchOrderCanceledUpdate";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";
import { getSortingBatchUpdateItem } from "../../api/v2/admin/AdminOrderSortingApi";
import { renderIf } from "../../helpers/HOCUtils";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

OfflineBatchCanceledUpdateDialogWrapper.propTypes = {
  open: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setIsCanceled: PropTypes.func,
  filter: PropTypes.object,
  userWarehouseId: PropTypes.number,
  selectedOrderBarcodes: PropTypes.array,
  excludeSelectedItems: PropTypes.array,
  i18n: PropTypes.instanceOf(Map),
};

function OfflineBatchCanceledUpdateDialogWrapper({
  selectedOrderBarcodes,
  userWarehouseId,
  setIsCanceled,
  ...props
}) {
  const [barcodes, setBarcodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      if (
        props.filter.service_type_id ||
        props.filter.bean ||
        selectedOrderBarcodes.length
      ) {
        setIsLoading(true);
        getSortingBatchUpdateItem({
          ...props.filter,
          type: "ORDER",
          barcodes: selectedOrderBarcodes,
        })
          .then(res => {
            const orderBarcodes = [];
            res.items.map(item => {
              orderBarcodes.push(item.barcode);
              return item;
            });
            setBarcodes(orderBarcodes);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      }
    }
  }, [props.filter, selectedOrderBarcodes]);

  return (
    <BatchOrderCanceledUpdate
      open={props.open}
      isLoading={isLoading}
      initialValues={{
        orderBarcodes: barcodes,
        warehouse: {
          id: userWarehouseId,
        },
        privacy: PUBLIC,
        supplier: { id: 1 },
      }}
      setIsCanceled={setIsCanceled}
      onRequestClose={() => {
        props.onRequestClose();
      }}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
    />
  );
}

export default enhancer(OfflineBatchCanceledUpdateDialogWrapper);
