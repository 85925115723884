import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import ScannerTextField from "../deprecated/ScannerTextField";
import { cleanupStringArray } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const parseOrderNumbers = fp.flow(fp.words, cleanupStringArray);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    checkbox: { whiteSpace: "nowrap" },
    input: { "& input": { fontSize: "32px" } },
  }),
  withState("state", "setState", { assignToWarehouse: false }),
);

OrderReturnScanner.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  focusInput: PropTypes.bool.isRequired,
  onPrefetchOrdersClick: PropTypes.func,
  onRemoveAllOrdersClick: PropTypes.func,
  onReloadAllOrdersClick: PropTypes.func,

  getLocalisationMessage: PropTypes.func,
};

function OrderReturnScanner(props) {
  const { state, classes, getLocalisationMessage } = props;

  return (
    <FlexBox flex={true}>
      <ScannerTextField
        fullWidth={true}
        hintText={getLocalisationMessage("add_orders", "Add Orders")}
        focus={props.focusInput}
        className={classes.input}
        id="OrderSortingFormScannerTextField"
        onChange={value =>
          props.onSubmit({
            orderNumbers: parseOrderNumbers(value),
            assignToWarehouse: state.assignToWarehouse,
          })
        }
      />
    </FlexBox>
  );
}

export default enhancer(OrderReturnScanner);
