import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormTimeField from "./FormTimeField";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose();

FormOpenCloseTime.propTypes = {
  openTimeField: PropTypes.string,
  closeTimeField: PropTypes.string,
  labelText: PropTypes.string,
};

function FormOpenCloseTime(props) {
  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true} align="center">
        <h6>{props.labelText}</h6>
      </FlexBox>
      <FlexBox flex={true}>
        <FormTimeField
          name={props.openTimeField}
          fullWidth={true}
          label="Open Time"
        />
      </FlexBox>
      <FlexBox flex={true}>
        <FormTimeField
          name={props.closeTimeField}
          fullWidth={true}
          label="Close Time"
        />
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(FormOpenCloseTime);
