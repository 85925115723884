import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Search as ActionSearch } from "@material-ui/icons";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    root: {
      marginTop: 0,
      padding: "20px 20px",
      pointerEvents: "all",
      backgroundColor: "white",
    },
    locationContainer: {
      paddingLeft: "8px",
      bottom: "8px",
    },
  }),
  reduxForm({
    enableReinitialize: true,
    form: "MapAreasSearchForm",
  }),
);

MapAreasSearchForm.propTypes = {
  classes: PropTypes.object,

  countryCode: PropTypes.string,

  pristine: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function MapAreasSearchForm({ classes, getLocalisationMessage, ...props }) {
  return (
    <FlexBox
      gutter={8}
      element={<form onSubmit={props.handleSubmit} />}
      className={classes.root}
    >
      <FlexBox className={classes.locationContainer}>
        <FormGeoAutoComplete
          name="location"
          countryCode={props.countryCode}
          hintText={getLocalisationMessage(
            "type_to_search_3_letters",
            "Type to Search (3 letters minimum)",
          )}
        />
      </FlexBox>

      <FlexBox>
        <IconButton disabled={props.pristine} type="submit">
          <ActionSearch />
        </IconButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(MapAreasSearchForm);
