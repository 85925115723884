import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { updateRecipientPostcode } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import BatchRedirectOrdersDialog from "../../components/orders-core/BatchRedirectOrdersDialog";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

BatchRedirectOrdersFormDialogWrapper.propTypes = {
  open: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function BatchRedirectOrdersFormDialogWrapper(props) {
  return (
    <BatchRedirectOrdersDialog
      open={props.open}
      initialValues={props.initialValues}
      onRequestClose={props.onRequestClose}
      onSubmit={values =>
        updateRecipientPostcode(values).catch(ResponseError.throw)
      }
      onSubmitFail={response => {
        props.showErrorMessage(response);
        props.onRequestClose();
      }}
      onSubmitSuccess={() => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess();
      }}
    />
  );
}

export default enhancer(BatchRedirectOrdersFormDialogWrapper);
