import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues, formValueSelector } from "redux-form";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import InfoIcon from "../icons/InfoIcon";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import emailPorts from "../../constants/emailPorts";
import EmailTypes, {
  OTHERS,
  MICROSOFT,
  EMAIL_PARAMETERS,
} from "../../constants/EmailTypes";
import { testEmail, deleteEmail } from "../../api/admin/AdminEmailApi";

const styles = {
  infoTooltip: {
    position: "absolute",
    right: "-5%",
  },
};
const getValues = getFormValues("EmailForm");
const valueSelector = formValueSelector("EmailForm");

const enhancer = compose(
  useSheet({
    root: { width: "640px", maxWidth: "640px", marginBottom: "20px" },
    cardHead: { paddingBottom: "0" },
    cardBody: {
      paddingTop: "0",
      position: "relative",
      maxWidth: "95%",
    },
    status: { textAlign: "center", paddingTop: "10px" },
    statusInactive: { color: "#9C0008", marginLeft: "8px" },
    warningIcon: {
      color: "white",
      borderRadius: "50%",
      backgroundColor: "#9C0008",
      paddingRight: "7px",
      paddingLeft: "7px",
      fontSize: "16px",
      fontWeight: "bolder",
    },

    infoIcon: {
      width: "15px",
    },
  }),
  withHandlers({
    onSubmit: props => ({ properties, mailSmtpSslTrust, ...values }) =>
      props.onSubmit({
        ...toSnakeCase(values),
        properties: {
          "mail.smtp.ssl.trust": mailSmtpSslTrust,
        },
      }),
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  reduxForm({
    form: "EmailForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name:
        fp.isEmpty(values.name) &&
        props.getLocalisationMessage(
          "field_value_cannot_be_empty",
          "Field value cannot be empty",
        ),
      host:
        fp.isEmpty(values.host) &&
        props.getLocalisationMessage(
          "field_value_cannot_be_empty",
          "Field value cannot be empty",
        ),
      fromEmail:
        fp.isEmpty(values.fromEmail) &&
        props.getLocalisationMessage(
          "field_value_cannot_be_empty",
          "Field value cannot be empty",
        ),
      password:
        fp.isEmpty(values.password) &&
        props.getLocalisationMessage(
          "field_value_cannot_be_empty",
          "Field value cannot be empty",
        ),
      mailSmtpSslTrust:
        fp.isEmpty(values.mailSmtpSslTrust) &&
        props.getLocalisationMessage(
          "field_value_cannot_be_empty",
          "Field value cannot be empty",
        ),
    }),
  }),
  connect(state => ({
    values: getValues(state),
    emailType: valueSelector(state, "emailType"),
  })),
  withState("isLoading", "setIsLoading", false),
  mapPropsStream(
    pipeStreams(propsStream => {
      const sideEffectsStream = Observable.merge(
        propsStream
          .filter(props => props.emailType)
          .map(fp.pick(["emailType"]))
          .distinctUntilChanged(isEqualData)
          .withLatestFrom(propsStream)
          .do(([emailType, props]) => {
            props.change("host", EMAIL_PARAMETERS[emailType.emailType].host);
            props.change(
              "protocol",
              EMAIL_PARAMETERS[emailType.emailType].protocol,
            );
            props.change(
              "mailSmtpSslTrust",
              EMAIL_PARAMETERS[emailType.emailType].mailSmtpSslTrust,
            );
            props.change("port", EMAIL_PARAMETERS[emailType.emailType].port);
          }),
      )
        .mapTo(null)
        .startWith(null)
        .distinctUntilChanged();

      return propsStream.combineLatest(sideEffectsStream, fp.identity);
    }),
  ),
);

EmailForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.any,
  classes: PropTypes.any,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  values: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function EmailForm(props) {
  const { getLocalisationMessage, classes } = props;
  const { verified, id } = props.values;
  const { emailType } = props.values;
  return (
    <FlexBox className={classes.root} direction="column" flex="none">
      <PageLoading isLoading={props.submitting || props.isLoading} />
      <FlexBox gutter={8} direction="column">
        {!verified && id && (
          <FlexBox direction="column">
            <Card className={classes.status}>
              <div>
                <span className={classes.warningIcon}>!</span>
                <span className={classes.statusInactive}>
                  {getLocalisationMessage(
                    "",
                    "Please check your inbox and verify provided email",
                  )}
                </span>
              </div>
              <CardContent className={classes.cardBody}>
                <FormCheckbox
                  style={{ display: "none" }}
                  fullWidth={true}
                  name="verified"
                />
              </CardContent>
            </Card>
          </FlexBox>
        )}
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage("email_type", "Email Type")}
            />
            <CardContent className={classes.cardBody}>
              <FormSelectField
                name="emailType"
                fullWidth={true}
                options={EmailTypes}
                formatOption={value =>
                  formatText(getLocalisationMessage(value, value))
                }
              />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "mail_provider_of_the_email",
                  "Mail provider of the email",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        {emailType && emailType !== MICROSOFT && (
          <FlexBox direction="column">
            <Card>
              <CardHeader className={classes.cardHead} />
              <CardContent className={classes.cardBody}>
                <span>{EMAIL_PARAMETERS[emailType].info}</span>
                {emailType !== OTHERS && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "10px" }}
                    href={EMAIL_PARAMETERS[emailType].link}
                  >
                    read more ...
                  </a>
                )}
              </CardContent>
            </Card>
          </FlexBox>
        )}
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage("company_name", "Company name")}
            />
            <CardContent className={classes.cardBody}>
              <FormTextField fullWidth={true} name="name" />
              <TooltipOverlay
                label={getLocalisationMessage("company_name", "Company name")}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage("from_email", "From Email")}
            />
            <CardContent className={classes.cardBody}>
              <FormTextField fullWidth={true} name="fromEmail" />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "email_address_that_is_being_configured",
                  "Email address that is being configured",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage("password", "Password")}
            />
            <CardContent className={classes.cardBody}>
              <FormTextField type="password" fullWidth={true} name="password" />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "email_password_of_which_being_configured",
                  "Email password of which being configured",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage("host", "Host")}
            />
            <CardContent className={classes.cardBody}>
              <FormTextField fullWidth={true} name="host" />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "host_of_email_with_protocol_prefixed_with_dot(.),_example:_smtp.gmail.com",
                  "Host of email with protocol prefixed with dot(.), example: smtp.gmail.com",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage("protocol", "Protocol")}
            />
            <CardContent className={classes.cardBody}>
              <FormTextField fullWidth={true} name="protocol" />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "protocol_for_sending_emails_(our_system_supports_smtp)",
                  "Protocol for sending emails (Our system supports smtp)",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage(
                "hosts_of_receiver_emails",
                "Hosts of receiver emails",
              )}
            />
            <CardContent className={classes.cardBody}>
              <FormTextField fullWidth={true} name="mailSmtpSslTrust" />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "comma_separated_list_of_recipient_hosts",
                  "Comma separated list of recipient hosts",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox direction="column">
          <Card>
            <CardHeader
              className={classes.cardHead}
              subheader={getLocalisationMessage("port", "Port")}
            />
            <CardContent className={classes.cardBody}>
              <FormSelectField
                name="port"
                fullWidth={true}
                options={emailPorts}
              />
              <TooltipOverlay
                label={getLocalisationMessage(
                  "port_of_smtp_protocol",
                  "Port of smtp protocol",
                )}
                style={styles.infoTooltip}
              >
                <IconButton aria-label="delete">
                  <InfoIcon className={props.classes.infoIcon} />
                </IconButton>
              </TooltipOverlay>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox style={{ marginTop: "10px" }} justify="space-between">
          <FlexBox justify="flex-end">
            <Card>
              <Button
                onClick={() => {
                  props.setIsLoading(true);
                  Promise.resolve(deleteEmail())
                    .then(response => {
                      props.setIsLoading(false);
                      return response;
                    })
                    .then(() =>
                      props.showSuccessMessage(
                        getLocalisationMessage(
                          "deleted_email",
                          "Email deleted",
                        ),
                      ),
                    )
                    .catch(() =>
                      props.showErrorMessage(
                        getLocalisationMessage("error", "Error"),
                      ),
                    );
                }}
              >
                {getLocalisationMessage("delete", "Delete")}
              </Button>
            </Card>
          </FlexBox>
          <FlexBox justify="flex-end">
            <Card>
              <Button
                onClick={() => {
                  props.setIsLoading(true);
                  Promise.resolve(
                    testEmail({
                      ...toSnakeCase(props.values),
                      properties: {
                        "mail.smtp.ssl.trust": props.values.mailSmtpSslTrust,
                      },
                    }),
                  )
                    .then(response => {
                      props.setIsLoading(false);
                      return response;
                    })
                    .then(props.onSubmitSuccess)
                    .catch(props.onSubmitFail);
                }}
              >
                {getLocalisationMessage("test_connection", "Test Connection")}
              </Button>
            </Card>
            <Card style={{ marginLeft: "20px" }}>
              <Button type="submit" onClick={props.handleSubmit}>
                {getLocalisationMessage("submit", "Submit")}
              </Button>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(EmailForm);
