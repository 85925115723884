import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { LOCKER_POST_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import LockerPostForm from "../../components/admin/LockerPostForm";
import {
  getLockerPostItem,
  updateLockerPost,
} from "../../api/admin/AdminLockerpostApi";
import { getValue } from "../../helpers/DataUtils";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({ setLocation: PropTypes.func }),
);

AdminLockerPostItem.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  router: PropTypes.object,
  location: PropTypes.object,
  params: PropTypes.object,
};

function AdminLockerPostItem(props) {
  const { getLocalisationMessage } = props;
  const [lockerPost, setLockerPost] = useState({});
  const lockerPostId = getValue(props.params, "id", null);

  useEffect(() => {
    if (lockerPostId) {
      getLockerPostItem(lockerPostId)
        .then(res => {
          if (res && res.data) {
            setLockerPost(res.data);
          }
        })
        .catch(() => setLockerPost({}));
    }
  }, [lockerPostId]);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("edit_locker_post", "Edit Locker Post")}
    >
      <LockerPostForm
        router={props.router}
        initialValues={toCamelCase(lockerPost)}
        onSubmit={values =>
          updateLockerPost(lockerPostId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.setLocation(LOCKER_POST_LIST_URL);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated",
              "Successfully updated",
            ),
          );
        }}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminLockerPostItem);
