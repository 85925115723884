import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createEmail, getActiveEmail } from "../../api/admin/AdminEmailApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import EmailForm from "../../components/settings-core/EmailForm";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const marketplaceEmailStream = propsStream
      .first()
      .switchMap(() =>
        getActiveEmail().catch(error => Observable.of({ error })),
      )
      .filter(response => response.payload)
      .map(
        fp.flow(
          response => fromJS(response),
          response => response.get("payload", Map()),
          response => response.get("data", Map()),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(marketplaceEmailStream, (props, emailSettings) => ({
        ...props,
        emailSettings,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

EmailSettingsWrapper.propTypes = {
  emailSettings: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function EmailSettingsWrapper(props) {
  const { emailSettings } = props;

  return (
    <FlexBox flex={true}>
      <PageLoading isLoading={!emailSettings} />

      {emailSettings && (
        <EmailForm
          initialValues={{
            ...toCamelCase(emailSettings),
            id: emailSettings.get("id"),
            mailSmtpSslTrust: emailSettings.getIn(
              ["properties", "mail.smtp.ssl.trust"],
              "",
            ),
          }}
          onSubmit={values =>
            createEmail({ ...values, active: false }).catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_please_check_your_email_inbox",
                "Successfully, please check your email inbox",
              ),
            )
          }
          onSubmitFail={props.showErrorMessage}
        />
      )}
    </FlexBox>
  );
}

export default enhancer(EmailSettingsWrapper);
