import { OrderedSet } from "immutable";

export const SMS = "SMS_LOG";
export const WHATSAPP = "WHATSAPP_LOG";
export const EMAIL = "EMAIL_LOG";
export const NEW = "NEW";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const ALL = null;

export default OrderedSet.of(SMS, WHATSAPP, EMAIL);

export const ActivityHistoryLogStatus = OrderedSet.of(ALL, NEW, SUCCESS, ERROR);
