import React from "react";
import { Map, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Paper, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FormCitySelectField from "../form/FormCitySelectField";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import ZoneSelectToolMap from "../zone-select-tool/ZoneSelectToolMap";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { createNotFalsyValidator } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { ORDER_RULE } from "../../constants/RuleType";
import {
  NEIGHBORHOOD_TO,
  CURRENT_WAREHOUSE,
  NEIGHBORHOOD_FROM,
  DESTINATION_WAREHOUSE,
} from "../../constants/RuleConditionField";
import RuleComparationOperator from "../../constants/RuleComparationOperator";

const orderRuleFields = OrderedSet.of(
  CURRENT_WAREHOUSE,
  DESTINATION_WAREHOUSE,
  NEIGHBORHOOD_FROM,
  NEIGHBORHOOD_TO,
);

const supplierRuleFields = OrderedSet.of(NEIGHBORHOOD_FROM, NEIGHBORHOOD_TO);

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  renderIf("open"),
  useSheet({ map: { height: "600px" } }),
  getContext({
    getWarehousePolygons: PropTypes.func.isRequired,
    getNeighborhoodPolygons: PropTypes.func.isRequired,
  }),
  reduxForm({
    enableReinitialize: true,
    form: "PackageRuleTreeAddMultipleDialog",
    initialValues: { value: [] },
  }),
  formValues({
    field: "field",
    value: "value",
    cityId: "cityId",
  }),
  mapPropsStream(propsStream => {
    const selectedPolygonIDsStream = propsStream
      .filter(
        props =>
          (props.field === NEIGHBORHOOD_TO ||
            props.field === NEIGHBORHOOD_FROM) &&
          props.cityId,
      )
      .distinctUntilKeyChanged("cityId", isEqualData)
      .map(props => {
        const { selectedLeafs, field } = props;
        const items = [];
        if (fp.size(selectedLeafs)) {
          selectedLeafs.forEach(value => {
            if (value.field === field) {
              const fieldName = `${field}-value`;
              const valueObject = fromJS(value);
              const neighbourhoodId = valueObject.getIn([fieldName, "id"], 0);
              if (neighbourhoodId > 0) items.push(neighbourhoodId);
            }
          });
        }

        return items;
      })
      .distinctUntilChanged(isEqualData)
      .startWith([]);

    return propsStream
      .combineLatest(selectedPolygonIDsStream, (props, selectedPolygonIDs) => ({
        ...props,
        selectedPolygonIDs,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

PackageRuleTreeAddMultipleDialog.propTypes = {
  classes: PropTypes.object,

  value: PropTypes.array,
  field: PropTypes.string,
  cityId: PropTypes.number,

  reset: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  getWarehousePolygons: PropTypes.func,
  getNeighborhoodPolygons: PropTypes.func,

  ruleType: PropTypes.string.isRequired,

  open: PropTypes.bool,
  onRequestClose: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  selectedLeafs: PropTypes.array,
  selectedPolygonIDs: PropTypes.array,

  i18n: PropTypes.instanceOf(Map),
};

function PackageRuleTreeAddMultipleDialog(props) {
  const { classes, i18n } = props;

  return (
    <ModalPaper open={props.open} onRequestClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={16} element={<Paper />}>
        <CardContent>
          <FlexBox gutter={8}>
            <FlexBox align="center">
              <FormSelectField
                name="field"
                maxHeight={320}
                hintText={i18n.get("field", "Field")}
                formatOption={x =>
                  props.i18n.get(x.toLowerCase(), formatText(x))
                }
                validate={createNotFalsyValidator(
                  i18n.get("select_field", "Select Field"),
                )}
                options={
                  props.ruleType === ORDER_RULE
                    ? orderRuleFields
                    : supplierRuleFields
                }
              />
            </FlexBox>

            {(props.field === NEIGHBORHOOD_FROM ||
              props.field === NEIGHBORHOOD_TO) && (
              <FlexBox align="center">
                <FormCitySelectField
                  name="cityId"
                  domestic={true}
                  autoWidth={true}
                  hintText={i18n.get("select_city", "Select City")}
                />
              </FlexBox>
            )}

            {Boolean(props.field) && (
              <FlexBox align="center">
                <FormSelectField
                  maxHeight={320}
                  hintText={i18n.get("compare_by", "Compare By")}
                  name="comparationOperator"
                  formatOption={x =>
                    props.i18n.get(x.toLowerCase(), formatText(x))
                  }
                  options={RuleComparationOperator}
                  validate={createNotFalsyValidator(
                    i18n.get("select_comparator", "Select Comparator"),
                  )}
                />
              </FlexBox>
            )}
          </FlexBox>
        </CardContent>
      </FlexBox>

      {Boolean(
        props.cityId &&
          (props.field === NEIGHBORHOOD_FROM ||
            props.field === NEIGHBORHOOD_TO),
      ) && (
        <ZoneSelectToolMap
          key={props.cityId}
          className={classes.map}
          value={props.value}
          onReset={props.reset}
          onSubmit={props.handleSubmit}
          onChange={x => props.change("value", x)}
          selectedPolygonIDs={props.selectedPolygonIDs}
          getPolygons={() => props.getNeighborhoodPolygons(props.cityId)}
        />
      )}

      {Boolean(
        props.field === CURRENT_WAREHOUSE ||
          props.field === DESTINATION_WAREHOUSE,
      ) && (
        <ZoneSelectToolMap
          className={classes.map}
          value={props.value}
          onReset={props.reset}
          onSubmit={props.handleSubmit}
          onChange={x => props.change("value", x)}
          getPolygons={props.getWarehousePolygons}
        />
      )}
    </ModalPaper>
  );
}

export default enhancer(PackageRuleTreeAddMultipleDialog);
