import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { Paper, IconButton, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import {
  LastPage,
  FirstPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import RadarDriverTabs from "./RadarDriverTabs";
import NewRadarDriverItem from "./NewRadarDriverItem";
import Text, { MUTED } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import ReactAutoScroll from "../drivers-radar/ScrollToPosition";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import TextField from "../deprecated/TextField";
import RadarSuggestedDriverTabs from "./RadarSuggestedDriverTabs";
import NewRadarSuggestedDriverItem from "./NewRadarSuggestedDriverItem";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import ResponseError from "../../helpers/ResponseError";
import NewRadarSelectedDriverItem from "./NewRadarSelectedDriverItem";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
  withState("isSubmitLoading", "setIsLoading", false),
  useSheet({
    container: {
      width: "470px",
      position: "relative",
    },
    pages: {
      fontSize: "13px",
      color: "#6f6a6a",
      fontFamily: "Blogger Sans",
    },
    headerContainer: {
      padding: "0",
      fontSize: "16px",
      flex: 0,
      fontFamily: "Blogger Sans",
    },
    listContainer: {
      padding: 0,
      fontSize: "16px",
      overflowY: "auto",
      overflowX: "hidden",
      fontFamily: "Blogger Sans",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgb(162 162 162 / 87%)",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgb(125 125 125 / 87%)",
      },
    },
    sizeInput: {
      fontSize: "12px",
      maxWidth: "48px",
      marginLeft: "8px",
      marginRight: "8px",
      borderBottom: "1px solid #eee",
      overflow: "hidden",
      "& input": { textAlign: "right" },
      "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button": {
        margin: 0,
        WebkitAppearance: "none",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const pagesCountStream = propsStream
      .map(fp.pick(["list", "filter", "total", "driverItem"]))
      .distinctUntilChanged(isEqualData)
      .map(({ list, filter, total }) => {
        const listSize = list && list.size;
        const page = filter.getValue("driver_page") || 0;
        const pageSize = filter.getSize() || 50;

        if (!total) {
          return "";
        }

        if (page === 0 && listSize < total) {
          return `1-${pageSize} of ${formatNumber(total)}`;
        } else if (listSize === total) {
          return `1-${listSize} of ${listSize}`;
        }

        return `${page * pageSize + 1}-${page * pageSize +
          listSize} of ${formatNumber(total)}`;
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(pagesCountStream, (props, pagesCount) => ({
        ...props,
        pagesCount,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "list",
      "driverItem",
      "listPosition",
      "driverDetailsLoading",
      "driverDetails",
      "filter",
      "isLoading",
      "scrollingList",
      "isSubmitLoading",
    ]),
  ),
);

class DriversRadarDriversList extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  static propTypes = {
    classes: PropTypes.object,
    depends: PropTypes.bool,

    onItemClick: PropTypes.func,
    onSortClick: PropTypes.func,
    onFilterClick: PropTypes.func,
    onScrollingDone: PropTypes.func,
    onItemCloseClick: PropTypes.func,
    onItemFilterClick: PropTypes.func,
    onAlertStatusClick: PropTypes.func,
    onAssignDriver: PropTypes.func,

    total: PropTypes.number,
    list: PropTypes.instanceOf(Immutable.List),
    driverDetails: PropTypes.instanceOf(Immutable.Map),
    driverLastAlert: PropTypes.instanceOf(Immutable.Map),
    filter: PropTypes.instanceOf(DataListFilter),
    isLoading: PropTypes.bool,

    pagesCount: PropTypes.string,
    ordersCount: PropTypes.number,
    driverItem: PropTypes.number,
    orderItem: PropTypes.number,

    onChangePage: PropTypes.func,
    onChangePageSize: PropTypes.func,
    setIsLoading: PropTypes.func,
    isSubmitLoading: PropTypes.bool,

    orderNumber: PropTypes.string,
    listPosition: PropTypes.number,
    scrollingList: PropTypes.bool,
    getLocalisationMessage: PropTypes.func,
    onListRequestRefresh: PropTypes.func,
    getRadarOrderSuggestedDrivers: PropTypes.func,
    getDriverList: PropTypes.func,
    onCloseSelectedDriverClick: PropTypes.func,
    driverDetailsLoading: PropTypes.bool,
  };

  render() {
    const { classes, filter, getLocalisationMessage } = this.props;
    const page = fp.toFinite(filter.getValue("driver_page")) || 0;
    const disabledFirstButtons =
      page === 0 || this.props.total === this.props.list.size;
    const disabledLastButtons =
      page * this.props.filter.getSize() >= this.props.total ||
      this.props.total === this.props.list.size;

    return (
      <ReactAutoScroll
        targetPosition={this.props.listPosition}
        easeType="linear"
        speed={30}
        updateInterval={40}
        onScrollingDone={this.props.onScrollingDone}
        scrollTargetRef={this.scrollList}
        isEnabled={this.props.scrollingList}
      >
        <FlexBox className={classes.container}>
          <div className={classes.arrow} />

          <FlexBox flex={true} direction="column" element={<Paper />}>
            <FlexBox flex={true} className={classes.headerContainer}>
              {this.props.depends ? (
                <RadarSuggestedDriverTabs
                  onFilterClick={this.props.onFilterClick}
                  filter={this.props.filter}
                  orderItem={this.props.orderItem}
                  onListRequestRefresh={this.props.onListRequestRefresh}
                  getRadarOrderSuggestedDrivers={
                    this.props.getRadarOrderSuggestedDrivers
                  }
                />
              ) : (
                <RadarDriverTabs
                  onFilterClick={this.props.onFilterClick}
                  filter={this.props.filter}
                  onListRequestRefresh={this.props.onListRequestRefresh}
                  getDriverList={this.props.getDriverList}
                />
              )}
            </FlexBox>

            <FlexBox
              flex={true}
              direction="column"
              className={classes.listContainer}
              element={
                <div
                  ref={x => {
                    this.scrollList = x;
                  }}
                />
              }
            >
              {this.props.isLoading && this.props.isSubmitLoading && (
                <LinearProgress />
              )}

              {Boolean(!this.props.isLoading && this.props.list.size === 0) && (
                <div>
                  {this.props.driverItem && this.props.driverDetails ? (
                    <NewRadarSelectedDriverItem
                      item={this.props.driverDetails}
                      key={this.props.driverItem}
                      isLoading={this.props.driverDetailsLoading}
                      onCloseClick={() =>
                        this.props.onCloseSelectedDriverClick()
                      }
                      onItemClick={x => {
                        this.props.onItemClick(x, 0);
                      }}
                      onAlertStatusClick={id => {
                        this.props.setIsLoading(true);
                        Promise.resolve(
                          batchAsyncUpdateOrder({
                            order_numbers: [this.props.orderNumber],
                            driver: { id },
                            order_status: "accepted",
                          }).catch(ResponseError.throw),
                        )
                          .then(response => {
                            this.props.setIsLoading(false);
                            return response;
                          })
                          .then(() => {
                            this.props.onAssignDriver();
                          })
                          // eslint-disable-next-line react/prop-types
                          .catch(this.props.onSubmitFail);
                      }}
                      onAlertStatusDispatchClick={id => {
                        this.props.setIsLoading(true);
                        Promise.resolve(
                          batchAsyncUpdateOrder({
                            order_numbers: [this.props.orderNumber],
                            driver: { id },
                            order_status: "dispatched",
                          }).catch(ResponseError.throw),
                        )
                          .then(response => {
                            this.props.setIsLoading(false);
                            return response;
                          })
                          .then(() => {
                            this.props.onAssignDriver();
                          })
                          // eslint-disable-next-line react/prop-types
                          .catch(this.props.onSubmitFail);
                      }}
                      itemOpen={true}
                    />
                  ) : (
                    <FlexBox justify="center">
                      <Text type={MUTED}>
                        {getLocalisationMessage(
                          "drivers_not_found",
                          "Drivers Not Found!",
                        )}
                      </Text>
                    </FlexBox>
                  )}
                </div>
              )}

              {!this.props.isLoading &&
                this.props.list.map((item, idx) =>
                  this.props.depends ? (
                    <NewRadarSuggestedDriverItem
                      item={item}
                      key={idx}
                      onItemClick={x => {
                        this.props.onItemClick(x, idx);
                      }}
                      isLoading={this.props.driverDetailsLoading}
                      onCloseClick={() => {}}
                      onFilterClick={() => {}}
                      onAlertStatusClick={id => {
                        this.props.setIsLoading(true);
                        Promise.resolve(
                          batchAsyncUpdateOrder({
                            order_numbers: [this.props.orderNumber],
                            driver: { id },
                            order_status: "accepted",
                          }).catch(ResponseError.throw),
                        )
                          .then(response => {
                            this.props.setIsLoading(false);
                            return response;
                          })
                          .then(() => {
                            this.props.onAssignDriver();
                          })
                          // eslint-disable-next-line react/prop-types
                          .catch(this.props.onSubmitFail);
                      }}
                      onAlertStatusDispatchClick={id => {
                        this.props.setIsLoading(true);
                        Promise.resolve(
                          batchAsyncUpdateOrder({
                            order_numbers: [this.props.orderNumber],
                            driver: { id },
                            order_status: "dispatched",
                          }).catch(ResponseError.throw),
                        )
                          .then(response => {
                            this.props.setIsLoading(false);
                            return response;
                          })
                          .then(() => {
                            this.props.onAssignDriver();
                          })
                          // eslint-disable-next-line react/prop-types
                          .catch(this.props.onSubmitFail);
                      }}
                      itemOpen={this.props.driverItem === item.get("driver_id")}
                    />
                  ) : (
                    <NewRadarDriverItem
                      item={item}
                      key={idx}
                      onItemClick={x => {
                        this.props.onItemClick(x, idx);
                      }}
                      isLoading={this.props.driverDetailsLoading}
                      driverDetails={this.props.driverDetails}
                      driverLastAlert={this.props.driverLastAlert}
                      onCloseClick={() => {}}
                      onFilterClick={() => {}}
                      onAlertStatusClick={() => {}}
                      itemOpen={this.props.driverItem === item.get("driver_id")}
                    />
                  ),
                )}
            </FlexBox>

            {Boolean(
              !this.props.depends &&
                !this.props.isLoading &&
                this.props.list.size > 0,
            ) && (
              <FlexBox>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox>
                    <IconButton
                      disabled={disabledFirstButtons}
                      onClick={() => this.props.onChangePage(0)}
                    >
                      <FirstPage />
                    </IconButton>
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledFirstButtons}
                      onClick={() => this.props.onChangePage(page - 1)}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </FlexBox>

                  <FlexBox
                    flex={true}
                    align="center"
                    justify="center"
                    className={classes.pages}
                  >
                    <TextField
                      id="driver-radar-size"
                      type="number"
                      value={this.props.filter.getSize()}
                      debounceTime={5000}
                      debounceChange={true}
                      className={classes.sizeInput}
                      parse={fp.flow(fp.toFinite, fp.clamp(1, 200))}
                      onBlur={x => this.props.onChangePageSize(x)}
                      onChange={x => this.props.onChangePageSize(x)}
                    />
                    {this.props.pagesCount}
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledLastButtons}
                      onClick={() => this.props.onChangePage(page + 1)}
                    >
                      <ChevronRight />
                    </IconButton>
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledLastButtons}
                      onClick={() =>
                        this.props.onChangePage(
                          Math.ceil(this.props.total / 50 - 1),
                        )
                      }
                    >
                      <LastPage />
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </ReactAutoScroll>
    );
  }
}

export default enhancer(DriversRadarDriversList);
