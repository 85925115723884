import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toBadAddressFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    is_uae: types.booleanOr(true),
    use_solr: types.booleanOr(true),
    badAddress: types.booleanOr(true),

    status: queryTypes.stringSeq,
    customer_ids: queryTypes.IDSeq,
    from_date_time: queryTypes.dateTime,
    to_date_time: queryTypes.dateTime,
    search: queryTypes.stringSeq,
    accuracyStatuses: queryTypes.stringSeq,
    courier_types: queryTypes.stringSeq,
  }),
);
