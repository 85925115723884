import React from "react";
import { Map, List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import {
  Card,
  Table,
  CardContent,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import PackagePriceListItemNew from "./PackagePriceListItemNew";
import { isEqualData } from "../../helpers/DataUtils";
import { formatCourierType } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  SAVER,
  URGENT,
  NEXT_DAY,
  SAME_DAY,
} from "../../constants/CourierTypes";
import { VAN, BIKE, SEDAN } from "../../constants/VehicleTypes";
import AdminPackageFilterWrapper from "../../wrappers/admin/AdminPackageNewFilterWrapper";

const valueSelector = formValueSelector("PackagePricelistFormNew");
const getValue = (name, formValue) => fp.defaultTo(0, fp.get(name, formValue));

// noinspection JSAnnotator
const enhancer = compose(
  useSheet({
    root: {
      "& > div": {
        marginBottom: 20,
      },
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    formValues: valueSelector(
      state,
      "supplierId",
      "customerId",
      "fromCountryId",
      "toCountryId",
      "fromCityId",
      "toCityId",
    ),
  })),
  mapPropsStream(propsStream => {
    const tableVisibleStream = propsStream.map(
      ({ filter, formValues }) =>
        getValue("supplierId.id", formValues) ===
          filter.getIntegerValue("supplier_id") &&
        getValue("customerId.id", formValues) ===
          filter.getIntegerValue("customer_id") &&
        getValue("fromCountryId.id", formValues) ===
          filter.getIntegerValue("from_country_id") &&
        getValue("toCountryId.id", formValues) ===
          filter.getIntegerValue("to_country_id") &&
        getValue("fromCityId.id", formValues) ===
          filter.getIntegerValue("from_city_id") &&
        getValue("toCityId.id", formValues) ===
          filter.getIntegerValue("to_city_id"),
    );

    const tableItemsStream = propsStream
      .pluck("priceList")
      .map(list => list || List())
      .distinctUntilChanged(isEqualData)
      .map(list =>
        Map().withMutations(map => {
          const items = Map().asMutable();

          list.reverse().forEach(item => {
            const courierType = item.get("courier_type");
            const vehicleType = item.get("vehicle_type");

            items.setIn([courierType, vehicleType], item);
          });

          map.set("items", items.asImmutable());
          map.set("vehicleTypes", OrderedSet([BIKE, SEDAN, VAN]));
          map.set(
            "courierTypes",
            OrderedSet([SAVER, NEXT_DAY, SAME_DAY, URGENT]),
          );
        }),
      );

    return propsStream
      .combineLatest(
        tableItemsStream,
        tableVisibleStream,
        (props, tableItems, tableVisible) => ({
          ...props,
          tableVisible,
          items: tableItems.get("items"),
          vehicleTypes: tableItems.get("vehicleTypes"),
          courierTypes: tableItems.get("courierTypes"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

DefaultPackagePricelistNew.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(DataListFilter),
  vehicleTypes: PropTypes.instanceOf(OrderedSet),
  courierTypes: PropTypes.instanceOf(OrderedSet),
  priceList: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  marketplace: PropTypes.instanceOf(Map),
  tableVisible: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function DefaultPackagePricelistNew(props) {
  const { classes, filter, getLocalisationMessage } = props;
  const supplierId = filter.getValue("supplier_id");
  const fromCountryId = filter.getValue("from_country_id");
  const toCountryId = filter.getValue("to_country_id");
  const fromCityId = filter.getValue("from_city_id");
  const toCityId = filter.getValue("to_city_id");
  const isDomestic = fromCountryId === toCountryId;

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <AdminPackageFilterWrapper
            filter={props.filter}
            marketplace={props.marketplace}
            onFilterChange={props.onFilterChange}
          />
        </CardContent>
      </Card>
      {props.tableVisible &&
        supplierId &&
        (!isDomestic || (fromCityId && toCityId)) && (
          <Card>
            <CardContent>
              <Table>
                <TableHead displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                    {props.courierTypes
                      .map(courierType => (
                        <TableCell key={courierType}>
                          {formatCourierType(
                            courierType,
                            getLocalisationMessage,
                          )}
                        </TableCell>
                      ))
                      .toArray()}
                  </TableRow>
                </TableHead>
                <TableBody displayRowCheckbox={false}>
                  <TableRow selectable={false}>
                    {props.courierTypes
                      .map(courierType => {
                        const item = props.items.getIn([
                          fp.toUpper(courierType),
                          SEDAN,
                        ]);
                        const defaultItem = Map().withMutations(pkg => {
                          pkg.set("courier_type", fp.toUpper(courierType));
                          pkg.set("vehicle_type", SEDAN);
                        });

                        return (
                          <TableCell key={courierType}>
                            <PackagePriceListItemNew
                              item={item}
                              onClick={() =>
                                props.onItemClick(item || defaultItem)
                              }
                            />
                          </TableCell>
                        );
                      })
                      .toArray()}
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
    </div>
  );
}

export default enhancer(DefaultPackagePricelistNew);
