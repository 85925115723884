import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  getMarketplaceReceiveOrderFromWing,
  isCustomMarketplace,
} from "../../reducers/MarketplaceReducer";
import AdminCODCodes from "../../constants/AdminCODCodes";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import CreateOrderRegistryDialog from "../../components/order-outbound-sorting/CreateOrderRegistryDialog";
import { getSortingBatchUpdateItem } from "../../api/v2/admin/AdminOrderSortingApi";
import { getValue } from "../../helpers/DataUtils";
import {
  getRegistryStatusByShipmentType,
  isRegistryBag,
} from "../../helpers/OrderOutboundSortingHelper";
import { OrderedSet } from "immutable";
import {
  IN_TRANSIT,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import { renderIf } from "../../helpers/HOCUtils";

const OrderStatusCodes = OrderedSet.of(PREPARED_FOR_TRANSIT, IN_TRANSIT);

const enhancer = compose(
  renderIf("open"),
  connect(state => ({
    isMarketplaceCustom: isCustomMarketplace(state),
    isMarketplaceReceiveOrderFromWing: getMarketplaceReceiveOrderFromWing(
      state,
    ),
    defaultBatchUpdateStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
  })),
);

AdminOfflineOutboundOrderSortingBatchUpdateDialogWrapper.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  filter: PropTypes.object,
  isMarketplaceCustom: PropTypes.bool,
  list: PropTypes.any,
  selectedItems: PropTypes.any,
  isSorting: PropTypes.bool,
  selectedOrderBarcodes: PropTypes.array,
};

function AdminOfflineOutboundOrderSortingBatchUpdateDialogWrapper(props) {
  const [batchItem, setBatchItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      props.filter.service_type_id ||
      props.filter.bean ||
      props.selectedOrderBarcodes.length
    ) {
      setIsLoading(true);
      getSortingBatchUpdateItem({
        ...props.filter,
        type: "ORDER",
        barcodes: props.selectedOrderBarcodes,
      })
        .then(res => {
          const orderBarcodes = [];
          const courierServices = [];
          res.items.map(item => {
            orderBarcodes.push(item.barcode);
            courierServices.push(getValue(item, "service_type.code"));
            return item;
          });

          const bag = isRegistryBag(courierServices);
          const orderStatus = getRegistryStatusByShipmentType(
            courierServices,
            getValue(props.filter, "bean.status") || PREPARED_FOR_TRANSIT,
          );

          setBatchItem({
            category: res.category,
            orderBarcodes,
            orderStatus,
            innerShipmentType: res.inner_shipment_type,
            transportationType: res.transportation_type,
            weight: res.weight,
            to_warehouse:
              getValue(props.filter, "bean.to_warehouse") ||
              getValue(res, "to_warehouse"),
            warehouse:
              getValue(props.filter, "bean.next_warehouse") ||
              getValue(res, "next_warehouse"),
            bag,
          });

          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [props.filter, props.selectedOrderBarcodes]);

  return (
    <CreateOrderRegistryDialog
      open={props.open}
      isLoading={isLoading}
      onRequestClose={() => {
        props.onRequestClose();
        setBatchItem({});
      }}
      codCodes={AdminCODCodes}
      statusCodes={OrderStatusCodes}
      withInTransitToSupplier={true}
      privacyTypes={AdminPrivacyTypes}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      getPostcodePredictions={getPostcodePredictions}
      initialValues={{
        ...batchItem,
        onlyBarcode: true,
        privacy: PUBLIC,
      }}
      selectedItems={props.selectedItems}
      list={props.list}
      isSorting={props.isSorting}
      onSubmit={fp.flow(toSnakeCase, values => {
        const request = { ...values, privacy: PUBLIC };

        return batchAsyncUpdateOrder(
          fp.omit(["batch_ids", "is_barcode", "only_barcode"], request),
        ).catch(ResponseError.throw);
      })}
      onSubmitSuccess={response => {
        props.onSubmitSuccess(response);
        setBatchItem({});
      }}
      onSubmitFail={props.onSubmitFail}
    />
  );
}

export default enhancer(
  AdminOfflineOutboundOrderSortingBatchUpdateDialogWrapper,
);
