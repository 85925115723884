import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { getTagList } from "../../api/admin/AdminTagApi";
import AdminTagEditDialogWrapper from "../../wrappers/admin/AdminTagEditDialogWrapper";
import AdminTagCreateDialogWrapper from "../../wrappers/admin/AdminTagCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import TagList from "../../components/tag-core/TagList";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map(query => toDataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .map(filter => filter.setValue("use_solr", true))
      .switchMap(filter =>
        getTagList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .startWith({})
      .map(response => fromJS(response))
      .map(response =>
        Map({
          pending: response.get("pending", false),
          total: response.getIn(["payload", "data", "total"], 0),
          list: response.getIn(["payload", "data", "list"], List()),
        }),
      );

    return propsStream
      .combineLatest(
        filterStream,
        listResponseStream,
        (props, filter, listResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: listResponse.get("list"),
          total: listResponse.get("total"),
          isLoading: listResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminTagListContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  i18n: PropTypes.instanceOf(Map),
};

function AdminTagListContainer(props) {
  const { i18n } = props;

  return (
    <AdminAppLayout slug="tags" title={i18n.get("tags", "Tags")}>
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <AdminTagCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminTagEditDialogWrapper
        tagId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
      />

      <TagList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        location={props.location}
        cardActionIcons={
          <div>
            <Tooltip title={i18n.get("create_new_tag", "Create New Tag")}>
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminTagListContainer);
