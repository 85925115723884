import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import KpiDialogForm from "./KpiSettingsDialogForm";
import FlexBox from "../ui-core/FlexBox";
import { formatText } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";

const valueSelector = formValueSelector("MarketplaceSettingsForm");

const enhancer = compose(
  connect((state, props) => ({
    i18n: getMessages(state),
    field: valueSelector(state, `${props.name}`),
  })),
  reduxForm({
    form: "MarketplaceSettingsForm",
    enableReinitialize: true,
  }),
  withState("state", "setState", {
    isOpenDialog: false,
  }),
  useSheet({
    textCenter: { textAlign: "center" },
    actionTitle: { width: "200px" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    alignSelf: { alignSelf: "center" },
  }),
);

KpiSettingsForm.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  change: PropTypes.func,
  field: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  i18n: PropTypes.instanceOf(Map),
};

function KpiSettingsForm(props) {
  return (
    <FlexBox container={8} flex={true}>
      {props.state.isOpenDialog && (
        <KpiDialogForm
          initialValues={props.field}
          onSubmit={values => {
            props.change(props.name, values);
            props.setState(fp.set("isOpenDialog", false));
          }}
        />
      )}
      <FlexBox
        gutter={8}
        flex={true}
        direction="column"
        className={props.classes.alignSelf}
      >
        {`${props.i18n.get("kpi_is", "Kpi is")} ${formatText(
          props.field && fromJS(props.field).get("status") === ACTIVE
            ? props.i18n.get("active", ACTIVE)
            : props.i18n.get("inactive", INACTIVE),
        )}`}
      </FlexBox>

      <FlexBox gutter={8} flex={true} direction="column">
        <Button onClick={() => props.setState(fp.set("isOpenDialog", true))}>
          {props.i18n.get("add_edit_kpi_info", "Add/Edit Kpi Info")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(KpiSettingsForm);
