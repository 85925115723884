import { Observable } from "rxjs";
import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import { Map, List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList as ContentFilterList } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDriversDashboardFilter } from "../../helpers/DriversDashboardFilterMapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { getDriversDashboard } from "../../api/admin/AdminDriverApi";
import AdminDriversDashboardFilterWrapper from "../../wrappers/admin/AdminDriversDashboardFilterWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import { status12, status13 } from "../../../shared/theme/main-theme";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_DRIVERS_DASHBOARD_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const DRIVERS_DASHBOARD_FILTER_DIALOG_HASH = "#DDFDH";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  withTheme,
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  useSheet({
    header: {
      // backgroundColor: "#9c0008",
    },
    headerCellContainer: {
      color: "white",
      textTransform: "uppercase",
      fontWeight: "500",
      fontSize: "11px",
      "&:before": {
        content: '""',
        position: "absolute",
        zIndex: 0,
        backgroundColor: "inherit",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      "& > *": {
        position: "relative",
      },
    },
    badgeStyle: {
      color: "#000",
      minWidth: "24px",
      fontSize: "12px",
      padding: "0 4px",
      textAlign: "center",
      borderRadius: "50px",
      backgroundColor: "#fff",
      display: "inline-block",
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDriversDashboardFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getDriversDashboard(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                  all_attempted_count: response.getIn(
                    ["payload", "data", "all_attempted_count"],
                    0,
                  ),
                  all_cod_deposited: response.getIn(
                    ["payload", "data", "all_cod_deposited"],
                    0,
                  ),
                  all_cod_pending: response.getIn(
                    ["payload", "data", "all_cod_pending"],
                    0,
                  ),
                  all_cod_received: response.getIn(
                    ["payload", "data", "all_cod_received"],
                    0,
                  ),
                  all_delivered_count: response.getIn(
                    ["payload", "data", "all_delivered_count"],
                    0,
                  ),
                  all_dispatched_count: response.getIn(
                    ["payload", "data", "all_dispatched_count"],
                    0,
                  ),
                  all_rate: response.getIn(["payload", "data", "all_rate"], 0),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("isLoading"),
              all_attempted_count: listResponse.get("all_attempted_count"),
              all_cod_deposited: listResponse.get("all_cod_deposited"),
              all_cod_pending: listResponse.get("all_cod_pending"),
              all_cod_received: listResponse.get("all_cod_received"),
              all_delivered_count: listResponse.get("all_delivered_count"),
              all_dispatched_count: listResponse.get("all_dispatched_count"),
              all_rate: listResponse.get("all_rate"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminDriversDashboard.propTypes = {
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  i18n: PropTypes.instanceOf(Map),
  setLocationQueryFilter: PropTypes.func,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  theme: PropTypes.object,
  classes: PropTypes.object,

  all_attempted_count: PropTypes.number,
  all_cod_deposited: PropTypes.number,
  all_cod_pending: PropTypes.number,
  all_cod_received: PropTypes.number,
  all_delivered_count: PropTypes.number,
  all_dispatched_count: PropTypes.number,
  all_rate: PropTypes.number,
};

function driversDashboardCSVParams(filterValues) {
  const values = {
    from_date_time: filterValues.from_date_time,
    to_date_time: filterValues.to_date_time,
    supplier_ids: filterValues.supplier_ids,
    search: filterValues.search,
  };
  if (
    filterValues.order_by != null &&
    filterValues.order_by_direction != null
  ) {
    values.order_by = filterValues.order_by;
    values.order_by_direction = filterValues.order_by_direction;
  }
  return values;
}

function AdminDriversDashboard(props) {
  const { location, i18n, classes } = props;
  return (
    <AdminAppLayout
      slug="drivers_dashboard"
      title={i18n.get("drivers_dashboard", "Drivers Dashboard")}
    >
      <Redirect
        when={!("from_date_time" in location.query)}
        to={updateQuery(
          location,
          fp.set("from_date_time", formatDateTimeToUrl(startOfToday())),
        )}
      />
      <Redirect
        when={!("to_date_time" in location.query)}
        to={updateQuery(
          location,
          fp.set("to_date_time", formatDateTimeToUrl(endOfToday())),
        )}
      />

      <AdminDriversDashboardFilterWrapper
        open={location.hash === DRIVERS_DASHBOARD_FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />
      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        overscanRowCount={6}
        rowCount={props.list.size}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <div>
            <Link
              to={updateHash(location, DRIVERS_DASHBOARD_FILTER_DIALOG_HASH)}
            >
              <Tooltip title={i18n.get("filter", "Filter")}>
                <IconButton>
                  <ContentFilterList />
                </IconButton>
              </Tooltip>
            </Link>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_DRIVERS_DASHBOARD_CSV_URL,
                  driversDashboardCSVParams(props.filter.getValues()),
                )}
              >
                {i18n.get("download_csv", "Download CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("first_name", "First Name")}</div>
            </div>
          }
          dataKey="first_name"
          disableSort={false}
          cellRenderer={row => row.cellData.get("first_name") || i18n.get("na")}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("last_name", "Last Name")}</div>
            </div>
          }
          dataKey="last_name"
          disableSort={false}
          cellRenderer={row => row.cellData.get("last_name") || i18n.get("na")}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("courier_name", "Courier Name")}</div>
            </div>
          }
          dataKey="supplier_name"
          disableSort={false}
          cellRenderer={row =>
            row.cellData.get("supplier_name") || i18n.get("na")
          }
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("dispatched", "Dispatched")} </div>
              <div className={classes.badgeStyle}>
                {props.all_dispatched_count}
              </div>
            </div>
          }
          dataKey="dispatched_count"
          justifyContent="center"
          disableSort={false}
          cellRenderer={row => row.cellData.get("dispatched_count")}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("attempted", "Attempted")} </div>
              <div className={classes.badgeStyle}>
                {props.all_attempted_count}
              </div>
            </div>
          }
          dataKey="attempted_count"
          justifyContent="center"
          disableSort={false}
          cellRenderer={row => row.cellData.get("attempted_count")}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("delivered", "Delivered")} </div>
              <div className={classes.badgeStyle}>
                {props.all_delivered_count}
              </div>
            </div>
          }
          dataKey="delivered_count"
          justifyContent="center"
          disableSort={false}
          cellRenderer={row => row.cellData.get("delivered_count")}
          headerClassName={classes.header}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("delivery_rate", "Delivery Rate")} </div>
              <div className={classes.badgeStyle}>{props.all_rate}%</div>
            </div>
          }
          dataKey="rate"
          justifyContent="center"
          disableSort={false}
          cellRenderer={row => `${row.cellData.get("rate")}%`}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("cod_received", "COD Received")} </div>
              <div className={classes.badgeStyle}>
                <PriceWrapper price={props.all_cod_received} />
              </div>
            </div>
          }
          dataKey="cod_received"
          justifyContent="center"
          disableSort={false}
          cellRenderer={row => (
            <div style={{ fontWeight: "bold" }}>
              <PriceWrapper price={row.cellData.get("cod_received")} />
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("cod_deposited", "COD Deposited")} </div>
              <div className={classes.badgeStyle}>
                <PriceWrapper price={props.all_cod_deposited} />
              </div>
            </div>
          }
          dataKey="cod_deposited"
          justifyContent="center"
          disableSort={false}
          cellRenderer={row => (
            <div style={{ color: status13, fontWeight: "bold" }}>
              <PriceWrapper price={row.cellData.get("cod_deposited")} />
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={
            <div
              className={classes.headerCellContainer}
              style={{ backgroundColor: props.theme.palette.primary1Color }}
            >
              <div>{i18n.get("cod_pending", "COD Pending")} </div>
              <div className={classes.badgeStyle}>
                <PriceWrapper price={props.all_cod_pending} />
              </div>
            </div>
          }
          dataKey="cod_pending"
          justifyContent="center"
          disableSort={false}
          cellRenderer={row => (
            <div style={{ color: status12, fontWeight: "bold" }}>
              <PriceWrapper price={row.cellData.get("cod_pending")} />
            </div>
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDriversDashboard);
