import React, { useEffect, useState } from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  CardActions,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../../ui-core/FlexBox";
import PageLoading from "../../ui-core/PageLoading";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  getMailCategories,
  getMailSubClass,
  validateDispatch,
} from "../../../api/admin/AdminIPSApi";
import FormSelectField from "../../form/FormSelectField";
import FormIPSCountryAutoComplete from "../../form/FormIPSCountryAutoComplete";
import CustomButton, { CONTAINED, SECONDARY } from "../../ui-core/CustomButton";
import { Add, CheckCircle } from "@material-ui/icons";
import FormTextField from "../../form/FormTextField";
import FormDateField from "../../form/FormDateField";
import { getValue, isShallowEqual } from "../../../helpers/DataUtils";
import {
  isValidObjectId,
  validateString,
} from "../../../helpers/ValidateUtils";
import fp from "lodash/fp";
import { addDays } from "date-fns";
import { orange } from "@material-ui/core/colors";

export const formatMailCategory = (category, getLocalisationMessage) => {
  switch (category) {
    case "A":
      return getLocalisationMessage("a_air", "A Air");

    case "B":
      return getLocalisationMessage("b_s_a_l", "B S.A.L");

    case "C":
      return getLocalisationMessage("c_surface", "C Surface");

    case "D":
      return getLocalisationMessage("d_prio_by_surface", "D Prio by surface");

    default:
      return "-";
  }
};

const getValues = getFormValues("NewDispatchForm");

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const handleSubmit = props => (values, change) => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values, change);
    };

    return propsStream
      .combineLatest(props => ({
        ...props,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    form: "NewDispatchForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      mailCategory: validateString(values.mailCategory)
        ? props.getLocalisationMessage(
            "please_select_mail_category",
            "Please select mail category",
          )
        : null,
      destinationOffice:
        !isValidObjectId(values.destinationOffice) &&
        props.getLocalisationMessage(
          "destination_office_is_required",
          "Destination Office is required",
        ),
      originOffice:
        !isValidObjectId(values.originOffice) &&
        props.getLocalisationMessage(
          "origin_office_is_required",
          "Origin Office is required",
        ),
      mailSubClass: validateString(values.mailSubClass)
        ? props.getLocalisationMessage(
            "please_select_mail_sub_class",
            "Please select mail sub class",
          )
        : null,
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

NewDispatchForm.propTypes = {
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  onClick: PropTypes.func,
  valid: PropTypes.bool,
};

function NewDispatchForm(props) {
  const { getLocalisationMessage, values } = props;
  const [mailCategories, setMailCategories] = useState([]);
  const [mailSubClass, setMailSubClass] = useState([]);
  const [isLoadingMailCategories, setIsLoadingMailCategories] = useState(false);
  const [isLoadingMailSubClass, setIsLoadingMailSubClass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoadingMailCategories(true);
    getMailCategories()
      .then(r => {
        if (r && r.data) {
          setMailCategories(r.data);
          setIsLoadingMailCategories(false);
        }
      })
      .catch(() => setIsLoadingMailCategories(false));
  }, []);

  useEffect(() => {
    setIsLoadingMailSubClass(true);
    getMailSubClass()
      .then(r => {
        if (r && r.data) {
          setMailSubClass(r.data);
          setIsLoadingMailSubClass(false);
        }
      })
      .catch(() => setIsLoadingMailSubClass(false));
  }, []);

  return (
    <FlexBox container={8} element={<form onSubmit={props.handleSubmit} />}>
      <FlexBox gutter={8} flex={true} direction="column">
        <Card>
          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox flex={true} gutter={16} direction="column">
              <PageLoading isLoading={props.submitting} />

              <FlexBox flex={true}>
                <FormIPSCountryAutoComplete
                  name="originOffice"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "origin_office",
                    "Origin Office",
                  )} *`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormIPSCountryAutoComplete
                  name="destinationOffice"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "destination_office",
                    "Destination Office",
                  )} *`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormSelectField
                  name="mailCategory"
                  loading={isLoadingMailCategories}
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "mail_category",
                    "Mail Category",
                  )} *`}
                  formatOption={x =>
                    formatMailCategory(x, getLocalisationMessage)
                  }
                  options={mailCategories}
                  disabled={!mailCategories.length}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormSelectField
                  name="mailSubClass"
                  loading={isLoadingMailSubClass}
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "mail_sub_class",
                    "Mail Sub Class",
                  )} *`}
                  formatOption={x => x}
                  options={mailSubClass}
                  disabled={!mailSubClass.length}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox element={<CardActions />} justify="flex-end">
            <CustomButton
              type={props.valid ? "button" : "submit"}
              variant={CONTAINED}
              color={SECONDARY}
              disabled={isLoading}
              startIcon={
                isLoading ? (
                  <CircularProgress size={20} style={{ color: orange[500] }} />
                ) : (
                  <CheckCircle size={20} />
                )
              }
              onClick={() => {
                if (props.valid) {
                  setIsLoading(true);
                  validateDispatch({
                    destination_office: values.destinationOffice.name,
                    mail_category: values.mailCategory,
                    mail_sub_class: values.mailSubClass,
                    origin_office: values.originOffice.name,
                  })
                    .then(r => {
                      if (r && r.status === "success") {
                        props.change(
                          "earliestDepartureDate",
                          addDays(
                            new Date(
                              getValue(r, "data.earliest_departure_date"),
                            ),
                            1,
                          ),
                        );
                        props.change(
                          "dispatchId",
                          getValue(r, "data.dispatch_id_string"),
                        );
                        props.change(
                          "closureDate",
                          new Date(getValue(r, "data.closure_date")),
                        );
                        setIsLoading(false);
                      }
                    })
                    .catch(error => {
                      props.showErrorMessage(error);
                      setIsLoading(false);
                    });
                }
              }}
            >
              {getLocalisationMessage("validate", "Validate")}
            </CustomButton>
          </FlexBox>

          <FlexBox flex={true} element={<CardContent />}>
            <Divider style={{ width: "100%", height: 1 }} />
          </FlexBox>

          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox flex={true} gutter={8} direction="column">
              <FlexBox flex={true} style={{ gap: 13 }}>
                <FormTextField
                  name="dispatchId"
                  fullWidth={true}
                  label={getLocalisationMessage("dispatch_no", "Dispatch NO")}
                  disabled={true}
                />

                <FormTextField
                  name="firstLeg"
                  fullWidth={true}
                  label={getLocalisationMessage("first_leg", "First Leg")}
                  disabled={true}
                />
              </FlexBox>

              <FlexBox flex={true} style={{ gap: 13 }}>
                <FormDateField
                  name="earliestDepartureDate"
                  fullWidth={true}
                  label={getLocalisationMessage(
                    "departure_date_time",
                    "Departure date-time",
                  )}
                  disabled={true}
                />

                <FormDateField
                  name="closureDate"
                  fullWidth={true}
                  label={getLocalisationMessage(
                    "closing_date_time",
                    "Closing date-time",
                  )}
                  disabled={true}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox element={<CardActions />} justify="flex-end">
            <Button onClick={props.onDismiss}>
              {getLocalisationMessage("cancel", "Cancel")}
            </Button>

            <CustomButton
              startIcon={<Add />}
              variant={CONTAINED}
              onClick={() => props.onClick(values)}
              color={SECONDARY}
              disabled={!values.dispatchId}
            >
              {getLocalisationMessage("ok", "Ok")}
            </CustomButton>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NewDispatchForm);
