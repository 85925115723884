import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({ form: "DeleteCODWithdrawDialog" }),
);

DeleteCODWithdrawDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function DeleteCODWithdrawDialog(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog
      modal={true}
      open={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogContent>
        <form autoComplete="off">
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_cod_withdraw_request",
            "Are you sure you want to remove cod withdraw request?",
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {" "}
          {getLocalisationMessage("dismiss", "Dismiss")}{" "}
        </Button>
        <Button onClick={props.handleSubmit}>
          {" "}
          {getLocalisationMessage("remove", "Remove")}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(DeleteCODWithdrawDialog);
