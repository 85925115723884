import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { pure, compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { ListSubheader, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormFileUploadField from "../form/FormFileUploadField";
import FormVenueCategoryAutoComplete from "../form/FormVenueCategoryAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText, parseInteger } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import ImagePlaceholder from "../../assets/placeholder.png";

const valueSelector = formValueSelector("ShopMenuForm");

const enhancer = compose(
  useSheet({
    photo: {
      maxWidth: "100%",
      height: "200px",
    },
    container: { margin: "16px" },
  }),
  reduxForm({
    form: "ShopMenuForm",
    enableReinitialize: true,
    validate: values => ({
      name: !values.name && "Enter Name",
      status: !values.status && "Select Status",
      sorder: fp.isNil(values.sorder) && "Enter Sort Order",
      parent: !isValidObjectId(values.parent) && "Select Parent Menu",
    }),
  }),
  connect(state => ({
    image: valueSelector(state, "image"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  pure,
);

const shopMenuOptions = OrderedSet.of(ACTIVE, INACTIVE);

ShopMenuForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  image: PropTypes.string,
  onRequestClose: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function ShopMenuForm(props) {
  const { classes } = props;

  return (
    <form autoComplete="off" className={classes.container}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true} direction="column">
                  <FlexBox>
                    <FormTextField
                      name="name"
                      label={props.getLocalisationMessage("name", "Name")}
                      fullWidth={true}
                    />
                  </FlexBox>
                  <FlexBox>
                    <FormVenueCategoryAutoComplete
                      name="parent"
                      fullWidth={true}
                      label={props.getLocalisationMessage(
                        "select_parent_menu",
                        "Select Parent Menu",
                      )}
                    />
                  </FlexBox>
                  <FlexBox>
                    <FormSelectField
                      name="status"
                      autoWidth={true}
                      fullWidth={true}
                      label={props.getLocalisationMessage("status", "Status")}
                      options={shopMenuOptions}
                      formatOption={x =>
                        props.getLocalisationMessage(x) || formatText(x)
                      }
                    />
                  </FlexBox>
                  <FlexBox>
                    <FormTextField
                      name="sorder"
                      label={props.getLocalisationMessage(
                        "sort_order",
                        "Sort Order",
                      )}
                      fullWidth={true}
                      parseOnBlur={parseInteger}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true} direction="column">
                  <FlexBox>
                    <ListSubheader>
                      {props.getLocalisationMessage("photo", "Photo")}
                    </ListSubheader>
                  </FlexBox>
                  <FlexBox justify="center">
                    <img
                      className={classes.photo}
                      alt={props.getLocalisationMessage("menu", "Menu")}
                      src={props.image ? props.image : ImagePlaceholder}
                    />
                  </FlexBox>
                  <FlexBox justify="center">
                    <FormFileUploadField
                      fullWidth={true}
                      name="image"
                      label={props.getLocalisationMessage(
                        "upload_image",
                        "Upload Image",
                      )}
                      accept="image/*"
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} justify="flex-end">
                <Button onClick={props.onRequestClose}>
                  {props.getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
                <Button onClick={props.handleSubmit}>
                  {props.getLocalisationMessage("submit", "Submit")}
                </Button>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(ShopMenuForm);
