import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SAMPLE_ITEMS_CREATE_URL } from "../../constants/AdminPathConstants";
import {
  getSampleItems,
  deleteSampleItem,
} from "../../api/shared/SampleItemsApi";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import SampleItemsList from "../../components/sample-items-core/SampleItemsList";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getSampleItems(props.filter.setValue("use_solr", true))
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response => fromJS(response))
          .map(response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminSampleItemsListContainer.propTypes = {
  sheet: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  location: PropTypes.object,
};

function AdminSampleItemsListContainer(props) {
  const { getLocalisationMessage } = props;
  const removeId = fp.toFinite(props.location.query.remove);

  return (
    <AdminAppLayout
      slug="sample_items"
      title={getLocalisationMessage("sample_items", "Sample Items")}
    >
      <FormDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset("remove"))}
        onSubmit={() => deleteSampleItem(removeId).catch(ResponseError.throw)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("remove"));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_sample_item",
          "Are you sure you want to remove this Sample Item?",
        )}
      </FormDialog>

      <SampleItemsList
        sampleItemsForSettings={false}
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        location={props.location}
        cardActionIcons={
          <Tooltip
            title={getLocalisationMessage(
              "create_sample_item",
              "Create Sample Item",
            )}
          >
            <Link to={updateQuery(SAMPLE_ITEMS_CREATE_URL)}>
              <IconButton>
                <Add />
              </IconButton>
            </Link>
          </Tooltip>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSampleItemsListContainer);
