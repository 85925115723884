import React from "react";
import { Set } from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({
    enableReinitialize: true,
    initialValues: { manual: false },
    form: "OrderSortingChangeBinDialog",
    validate: (values, props) => ({
      name:
        !values.name &&
        props.getLocalisationMessage(
          "bin_name_is_required",
          "Bin name is Required",
        ),
    }),
  }),
  formValues("manual"),
  mapPropsStream((propsStream) => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .distinctUntilKeyChanged("manual")
        .do((props) => props.change("name", "")),
    );

    const optionsStream = propsStream
      .distinctUntilKeyChanged("orderBins", isEqualData)
      .map((props) =>
        props.orderBins
          .map((x) => x.get("label"))
          .toSet()
          .sortBy(),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .merge(sideEffectsStream)
      .combineLatest(optionsStream, (props, options) => ({
        ...props,
        options,
      }));
  }),
  withTheme,
);

OrderSortingChangeBinDialog.propTypes = {
  manual: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  options: PropTypes.instanceOf(Set),
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Set).isRequired,
  getLocalisationMessage: PropTypes.func,
  theme: PropTypes.object,
};

function OrderSortingChangeBinDialog(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog
      open={props.open}
      form="OrderSortingChangeBinDialog"
      onClose={props.onRequestClose}
      validate={(values) => ({
        name: !values.name && getLocalisationMessage("bin_name_is_required"),
      })}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
          minWidth: 400,
        }}
      >
        {`${getLocalisationMessage("move", "Move")} ${
          props.selectedOrders.size
        } ${getLocalisationMessage("orders_to", "orders to")}`}
      </DialogTitle>
      <DialogContent>
        {props.manual ? (
          <FormTextField
            name="name"
            fullWidth={true}
            label={getLocalisationMessage("enter_bin_name", "Enter Bin Name")}
          />
        ) : (
          <FormSelectField
            name="name"
            fullWidth={true}
            options={props.options}
            label={getLocalisationMessage("select_bin", "Select Bin")}
            formatOption={(x) => getLocalisationMessage(x, formatText(x))}
          />
        )}

        <FormCheckbox
          name="manual"
          label={getLocalisationMessage("add_new", "Add New")}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderSortingChangeBinDialog);
