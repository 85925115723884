import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { IconButton, CircularProgress, Fab } from "@material-ui/core";
import { connect } from "react-redux";
import { Add, Cancel } from "@material-ui/icons";
import { isEqualData, mapResponseData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { getFileUrlArray, uploadVoiceFile } from "../../api/shared/FileApi";
import { muted2 } from "../../../shared/theme/main-theme";
import AudioPlayer from "material-ui-audio-player";
import clsx from "classnames";

const enhancer = compose(
  useSheet({
    root: {
      padding: "12px",
      position: "relative",
      marginBottom: "24px",
      border: `1px dashed ${muted2}`,
      width: "100%",
      backgroundColor: "#efefef",
      "& .error": {
        border: `1px dashed red`,
      },
    },
    label: {
      color: "rgba(0, 0, 0, 0.54)",
      userSelect: "none",
      fontSize: "12px",
      marginTop: "-6px",
      marginLeft: "-6px",
      marginBottom: "-6px",
    },
    button: {
      right: "12px",
      bottom: "-20px",
      position: "absolute",
      backgroundColor: "#ffc107",
      color: "white",
      "& input": { display: "none" },
    },

    buttonRtl: { right: "initial", left: "12px", extend: "button" },

    imageRow: {},
    imageRowScroll: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      overflowY: "hidden",
    },

    image: { maxHeight: "100%", maxWidth: "100%", userSelect: "none" },
    imageItem: {
      marginTop: 20,
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "center",
      minHeight: "64px",
      //   maxHeight: "64px",
      //   minWidth: "64px",
      //   maxWidth: "128px",
      marginLeft: "12px",
      position: "relative",
      width: "80%",
    },

    imageButton: {
      padding: 0,
      width: "24px",
      height: "24px",

      top: "-12px",
      right: "-12px",
      position: "absolute",

      "&:after": {
        zIndex: -1,
        top: "7px",
        left: "7px",
        right: "7px",
        bottom: "7px",
        content: '""',
        position: "absolute",
        backgroundColor: "#fff",
      },

      "& svg": { fill: "#ff2b2b" },
    },

    imageProgress: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  mapPropsStream(propsStream => {
    const { handler: onUpload, stream: onUploadStream } = createEventHandler();
    let ids = [];
    const imageListStream = onUploadStream
      .map(fp.flow(fp.get("target.files"), fp.map(fp.identity)))
      .mergeMap(files => Observable.from(files))
      .mergeMap(
        file =>
          uploadVoiceFile(file)
            .retryWhen(error => error.delay(300))
            .map(
              fp.flow(r =>
                fromJS(r).update("payload", p =>
                  p ? p.getIn(["data", "id"]) : p,
                ),
              ),
            ),
        (file, response) => [file, response],
      )
      .withLatestFrom(propsStream)
      .do(([[, response], props]) => {
        if (response.get("payload")) {
          ids = response.get("payload");
          if (props.fields.length) {
            props.fields.removeAll();
          }
          props.fields.push(response.get("payload"));
        }
      })
      .map(fp.nth(0))
      .startWith(OrderedMap())
      .scan((acc, [file, response]) =>
        response.get("pending") ? acc.set(file, response) : acc.delete(file),
      )
      .map(item => item.toList())
      .distinctUntilChanged(isEqualData);

    const getPhotoUrlStream = propsStream
      .pluck("fields")

      .filter(fields => fp.size(fields.getAll()) > 0)
      .distinctUntilChanged(isEqualData)
      .switchMap(fields =>
        getFileUrlArray(
          new DataListFilter({
            attachment_ids: stringifyArray(ids),
          }),
        )
          .catch(error => Observable.of({ error }))
          .do(response => {
            if (
              response.payload &&
              !fp.isEqual(fields.getAll().sort(), response.payload.data.sort())
            ) {
              if (fields.length) {
                fields.removeAll();
              }
              if (response.payload.data.length > 0) {
                fields.push(response.payload.data[0]);
              }
            }
          }),
      )
      .startWith({})
      .map(mapResponseData);

    return propsStream
      .combineLatest(
        imageListStream,
        getPhotoUrlStream,
        (props, imageList, imageUrlList) => ({
          ...props,
          imageList,
          imageUrlList: imageUrlList.get("payload"),
          onUpload,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

const FormVoiceUploadComponent = enhancer(
  ({
    classes,
    imageList,
    fields,
    imageUrlList,
    onUpload,
    isRTL,
    ...custom
  }) => (
    <div className={clsx(classes.root)}>
      <div className={classes.label}>{custom.label}</div>

      <div className={classes.imageRow}>
        <div className={classes.imageRowScroll}>
          {_.map(fields.getAll(), (url, index) => (
            <div key={index} className={classes.imageItem}>
              <IconButton
                className={classes.imageButton}
                onClick={() => fields.remove(index)}
              >
                <Cancel />
              </IconButton>
              {imageList && (
                <AudioPlayer
                  key={index}
                  src={imageUrlList ? imageUrlList.get(index) : null}
                  variation="secondary"
                  rounded={true}
                  download={true}
                  preload="auto"
                  width="100%"
                />
              )}
            </div>
          ))}

          {imageList
            .map((item, index) => (
              <div key={index} className={classes.imageItem}>
                <CircularProgress />
              </div>
            ))
            .toArray()}
        </div>
      </div>
      <input
        id="file-upload-component"
        type="file"
        multiple={false}
        accept="audio/*"
        onChange={onUpload}
        style={{ display: "none" }}
      />
      <label htmlFor="file-upload-component">
        <Fab
          size="small"
          mini={true}
          className={isRTL ? classes.buttonRtl : classes.button}
          component="span"
        >
          <Add />
        </Fab>
      </label>
    </div>
  ),
);

FormVoiceUploadComponent.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default function FormVoiceUpload(props) {
  return <FieldArray {...props} component={FormVoiceUploadComponent} />;
}
