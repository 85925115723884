import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { formatDriverName } from "../../helpers/DriverHelper";
import ResponseError from "../../helpers/ResponseError";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getDriver,
  updateDriver,
  getDriverFinanceSettings,
} from "../../api/admin/AdminDriverApi";
import {
  getVehicle,
  updateVehicle,
  getCachedVehicle,
  getVehiclePredictions,
} from "../../api/admin/AdminVehicleApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import { getDriverLocation } from "../../api/shared/DriverApi";
import Tabs from "../../components/ui-core/Tabs";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriverForm from "../../components/drivers-core/DriverForm";
import DriverLocationMap from "../../components/drivers-core/DriverLocationMap";
import { ROLE_SUPER_ADMIN } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";
import { getCachedUserRoles } from "../../api/admin/AdminUserApi";
import _ from "lodash";

const MAP_TAB = "map";
const DETAILS_TAB = "details";

const enhancer = compose(
  renderIf(props => props.driverId > 0),
  connect(
    state => ({
      roles: getUser(state).get("roles") || [],
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const driverFinanceResponseStream = propsStream
      .distinctUntilKeyChanged("driverId")
      .switchMap(props =>
        getDriverFinanceSettings(props.driverId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const driverResponseStream = propsStream
      .distinctUntilKeyChanged("driverId")
      .switchMap(props =>
        getDriver(props.driverId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const isSuperAdminStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props => hasRole(props.roles, ROLE_SUPER_ADMIN))
      .distinctUntilChanged(isEqualData);

    const userRolesResponseStream = getCachedUserRoles()
      .catch(() => Observable.of(OrderedSet()))
      .map(list => list.toOrderedSet());

    return propsStream
      .combineLatest(
        driverResponseStream,
        driverFinanceResponseStream,
        isSuperAdminStream,
        userRolesResponseStream,
        (
          props,
          driverResponse,
          driverFinanceResponse,
          isSuperAdmin,
          userRoles,
        ) => ({
          ...props,
          isSuperAdmin,
          onRequestRefresh,
          driver: driverResponse.get("payload"),
          isLoading: driverResponse.get("pending"),
          driverFinance: driverFinanceResponse.get("payload"),
          isLoadingFinance: driverFinanceResponse.get("pending"),
          userRoles,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminDriverEditDialogWrapper.propTypes = {
  tab: PropTypes.oneOf([DETAILS_TAB, MAP_TAB]),
  onTabChange: PropTypes.func.isRequired,
  driver: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  driverId: PropTypes.number,
  driverFinance: PropTypes.instanceOf(Map),
  isLoadingFinance: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,

  isSuperAdmin: PropTypes.bool,

  onVehicleClick: PropTypes.func,
  onCreateVehicleClick: PropTypes.func,
  onSupplierClick: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,

  getLocalisationMessage: PropTypes.func,
  userRoles: PropTypes.instanceOf(OrderedSet),
};

AdminDriverEditDialogWrapper.defaultProps = {
  tab: DETAILS_TAB,
};

function AdminDriverEditDialogWrapper(props) {
  const { getLocalisationMessage } = props;
  const tabs = [
    {
      label: getLocalisationMessage("details", "Details"),
      value: DETAILS_TAB,
    },
    {
      label: getLocalisationMessage("map", "Map"),
      value: MAP_TAB,
    },
  ];

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading || props.isLoadingFinance}
      onRequestClose={props.onRequestClose}
      title={`${getLocalisationMessage("driver") ||
        "Driver"} - ${formatDriverName(
        props.driver,
        getLocalisationMessage("not_found", "Not Found"),
      )}`}
    >
      <FlexBox container={16} direction="column">
        <FlexBox gutter={16} direction="column">
          <FlexBox flex="none" direction="column">
            <Tabs
              width={300}
              tabs={tabs}
              value={props.tab}
              onChange={(e, v) => props.onTabChange(v)}
            />
          </FlexBox>

          <FlexBox flex="none" direction="column">
            {props.tab === DETAILS_TAB && (
              <DriverForm
                isEdit={true}
                disableEmail={false}
                onDismiss={props.onRequestClose}
                initialValues={toCamelCase(props.driver)}
                onSubmit={values => {
                  const roles = values.roles.map(role => {
                    let found;
                    if (_.isString(role)) {
                      found = props.userRoles.find(
                        uRole => uRole.get("name") === role,
                      );
                    } else {
                      found = props.userRoles.find(
                        uRole => uRole.get("name") === role.name,
                      );
                    }
                    return found ? found.toJS() : null;
                  });
                  return updateDriver(
                    props.driverId,
                    toSnakeCase({ ...values, roles: roles.filter(Boolean) }),
                  ).catch(ResponseError.throw);
                }}
                onSubmitSuccess={() => {
                  props.onRequestClose();
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_updated",
                      "Successfully Updated",
                    ),
                  );
                }}
                onSubmitFail={props.showErrorMessage}
                getCachedSupplier={getCachedSupplier}
                getSupplierPredictions={getSupplierPredictions}
                getCachedVehicle={getCachedVehicle}
                getVehiclePredictions={getVehiclePredictions}
                onVehicleClick={props.onVehicleClick}
                onCreateVehicleClick={props.onCreateVehicleClick}
                onSupplierClick={props.onSupplierClick}
                onCreateSupplierClick={props.onCreateSupplierClick}
                getVehicle={getVehicle}
                updateVehicle={updateVehicle}
                userRoles={props.userRoles.map(item => item.get("name"))}
              />
            )}

            {props.tab === MAP_TAB && (
              <DriverLocationMap
                driverId={props.driverId}
                getDriverLocation={getDriverLocation}
              />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminDriverEditDialogWrapper);
