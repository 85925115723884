import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormCityAutoComplete from "../form/FormCityV2AutoComplete";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),

  useSheet({
    card: {
      minWidth: 600,
      "& > div": {
        flex: "1 1 0%",
        display: "flex",
        flexDirection: "column",
      },
    },
  }),
  reduxForm({
    form: "MapAreasFilterForm",
  }),
  withContext(
    {
      getCachedCity: PropTypes.func.isRequired,
      getCachedCountry: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
    },
    fp.always({
      getCachedCity,
      getCachedCountry,
      getCityPredictions,
      getCountryPredictions,
    }),
  ),
  formValues({
    city: "city",
    country: "country",
  }),
);

MapAreasFilterForm.propTypes = {
  city: PropTypes.object,
  country: PropTypes.object,
  classes: PropTypes.object,
  onDismiss: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function MapAreasFilterForm({
  country,
  classes,
  handleSubmit,
  city,
  change,
  onDismiss,
  i18n,
}) {
  return (
    <FlexBox className={classes.card} element={<Card />} container={8}>
      <FlexBox
        element={<form onSubmit={handleSubmit} />}
        id="MapAreasFilterForm"
        gutter={16}
        direction="column"
        flex={true}
      >
        <FlexBox element={<CardContent />}>
          <FlexBox flex={true} gutter={8} container={16}>
            <FlexBox flex={true}>
              <FormCountryAutoComplete
                fullWidth={true}
                label={i18n.get("country", "Country")}
                name="country"
                hintText={i18n.get("type_to_search", "Type to search ...")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormCityAutoComplete
                fullWidth={true}
                label={i18n.get("city", "City")}
                countryId={country ? country.id : undefined}
                name="city"
                hintText={i18n.get("type_to_search", "Type to search ...")}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox element={<CardActions />} justify="flex-end">
          {Boolean(city || country) && (
            <Button
              type="button"
              onClick={() => {
                change("city", null);
                change("country", null);
              }}
            >
              {i18n.get("clear", "Clear")}
            </Button>
          )}

          {onDismiss && (
            <Button type="button" onClick={onDismiss}>
              {i18n.get("dismiss", "Dismiss")}
            </Button>
          )}

          <Button type="submit" disabled={!country || !country.id}>
            {i18n.get("filter", "Filter")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(MapAreasFilterForm);
