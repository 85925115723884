import React, { useEffect, useState } from "react";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Add, Close, KeyboardArrowLeft, Save } from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ROUTE_TEMPLATES_URL } from "../../constants/AdminPathConstants";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import TransportationType from "../../constants/TransportationType";
import FormSelectField from "../form/FormSelectField";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import DayOfWeekWithShortName from "../../constants/DayOfWeekWithShortName";
import OverallStatus from "../../constants/OverallStatus";
import FormTimeField from "../form/FormTimeField";

const useStyles = makeStyles({
  numberOfRow: {
    minWidth: "2rem",
    height: "2rem",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".5rem",
    position: "relative",
    overflow: "visible",
    backgroundColor: "#4DB666",
  },
  before: {
    "&:before": {
      content: '""',
      top: "30px",
      left: "15px",
      width: "0",
      height: "50px",
      bottom: "10px",
      borderLeft: "1px dashed #5d6165",
      position: "absolute",
    },
  },
});
const Points = (props) => {
  const { getLocalisationMessage, fields, classes } = props;
  if (fields.length === 0) fields.push({ warehouse: "" });
  return (
    <FlexBox flex={true} direction="column">
      {fields.map((item, index) => (
        <FlexBox
          align={ALIGN_CENTER}
          key={index}
          style={{ marginBottom: ".5rem" }}
          flex={true}
          gutter={16}
        >
          <span
            className={`${classes.numberOfRow} ${
              fields.length > index + 1 && classes.before
            }`}
          >
            {index + 1}
          </span>
          <FlexBox flex={true}>
            <FormWarehouseAutoComplete
              name={`${item}.warehouse`}
              margin="normal"
              fullWidth={true}
              label={getLocalisationMessage("warehouse", "Warehouse")}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTimeField
              margin="normal"
              name={`${item}.time_of_arrival`}
              fullWidth={true}
              label={getLocalisationMessage("coming_time")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTimeField
              margin="normal"
              name={`${item}.time_of_departure`}
              fullWidth={true}
              label={getLocalisationMessage("out_time")}
            />
          </FlexBox>
          <FlexBox>
            <IconButton
              style={{ marginTop: ".5rem", marginLeft: ".5rem" }}
              size="small"
              onClick={() => fields.remove(index)}
            >
              <Close color="error" />
            </IconButton>
          </FlexBox>
        </FlexBox>
      ))}
      <FlexBox style={{ marginTop: ".5rem" }}>
        <CustomButton
          variant={OUTLINED}
          onClick={() =>
            fields.push({
              warehouse: "",
            })
          }
          endIcon={<Add />}
        >
          {getLocalisationMessage("add_dot")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
};
Points.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
};

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const handleSubmit = (props) => (values) => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values);
    };

    return propsStream.combineLatest((props) => ({
      ...props,
      onSubmit: handleSubmit(props),
    }));
  }),
  reduxForm({
    enableReinitialize: true,
    form: "RouteTemplateForm",
    validate: (values, { getLocalisationMessage }) => ({
      name: !values.name && getLocalisationMessage("this_field_is_required"),
    }),
  }),
  getContext({ setLocation: PropTypes.func.isRequired }),
  connect((state) => ({
    values: getFormValues("RouteTemplateForm")(state),
  })),
);

RouteTemplateForm.propTypes = {
  setLocation: PropTypes.func,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  isLoading: PropTypes.string,
  values: PropTypes.object,
  change: PropTypes.func,
};

function RouteTemplateForm({
  getLocalisationMessage,
  handleSubmit,
  isLoading,
  setLocation,
  values,
  change,
}) {
  const classes = useStyles();
  const [weekdays, setWeekdays] = useState([]);
  useEffect(
    () => values && values.weekdays && setWeekdays(values.weekdays),
    [values],
  );
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <PageLoading isLoading={isLoading} />
          <FlexBox direction="column" style={{ margin: "0 3rem" }}>
            <FlexBox gutter={16} justify={JUSTIFY_CENTER}>
              <FlexBox
                flex={true}
                gutter={16}
                style={{ maxWidth: "70%" }}
                direction="column"
              >
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="name"
                      fullWidth={true}
                      label={getLocalisationMessage("name")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="status"
                      fullWidth={true}
                      label={getLocalisationMessage("status", "Status")}
                      options={OverallStatus}
                      formatOption={(option) =>
                        formatText(getLocalisationMessage(option, option))
                      }
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="description"
                      fullWidth={true}
                      label={getLocalisationMessage("description")}
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="transportation_type"
                      fullWidth={true}
                      options={TransportationType}
                      formatOption={(x) =>
                        getLocalisationMessage(x.toLowerCase(), formatText(x))
                      }
                      label={getLocalisationMessage(
                        "transportation_type",
                        "Transportation Type",
                      )}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    {DayOfWeekWithShortName.map((day) => (
                      <FlexBox flex={true}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={weekdays.some((v) => v === day.value)}
                              onChange={(e, v) => {
                                if (v) {
                                  if (values.weekdays)
                                    change("weekdays", [
                                      ...values.weekdays,
                                      day.value,
                                    ]);
                                  else change("weekdays", [day.value]);
                                } else
                                  change(
                                    "weekdays",
                                    values.weekdays.filter(
                                      (i) => i !== day.value,
                                    ),
                                  );
                              }}
                            />
                          }
                          label={getLocalisationMessage(day.shortName)}
                        />
                      </FlexBox>
                    ))}
                  </FlexBox>
                </FlexBox>
                <FlexBox flex={true}>
                  <FieldArray
                    name="points"
                    props={{
                      getLocalisationMessage,
                      classes,
                    }}
                    component={Points}
                  />
                </FlexBox>
                <FlexBox gutter={16} flex={true} justify={JUSTIFY_CENTER}>
                  <FlexBox>
                    <CustomButton
                      onClick={() => setLocation(ROUTE_TEMPLATES_URL)}
                      startIcon={<KeyboardArrowLeft />}
                      variant={CONTAINED}
                      color={SECONDARY}
                    >
                      {getLocalisationMessage("back", "Back")}
                    </CustomButton>
                  </FlexBox>
                  <FlexBox>
                    <CustomButton
                      type="submit"
                      color={SECONDARY}
                      variant={CONTAINED}
                      endIcon={<Save />}
                    >
                      {getLocalisationMessage("save")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              {2 === 3 && (
                <FlexBox style={{ minWidth: "45%" }} flex={true}>
                  <LeafletMapWrapper
                    style={{ width: "100%" }}
                    mapCenter={{
                      lat: 41.311081,
                      lng: 69.240562,
                    }}
                  />
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
        </CardContent>
      </Card>
    </form>
  );
}

export default enhancer(RouteTemplateForm);
