import { OrderedSet } from "immutable";
import {
  DEFECT,
  DIDNT_APPEAR_ON_NOTICE,
  INCOMPLETE_ADDRESS,
  ISSUED_TO_RECIPIENT,
  NOT_AT_HOME,
  ORGANIZATION_WITH_GIVEN_ADDRESS_NOT_FOUND,
  READY_FOR_ISSUE,
  RECEIVER_DEAD,
  RECEIVER_NOT_LIVES_THERE,
  RECEIVER_REFUSE,
  RETENTION_PERIOD_HAS_EXPIRED,
  RETURNED_FROM_LOCKER_POST,
  RETURNING_TO_ORIGIN,
  TRY_PERFORM,
} from "./OrderStatusCodes";

// Types
export const OTHER = "other";
export const UPDATED_BY_MISTAKE = "updated_by_mistake";
export const WITHOUT_PICKUP_HISTORY = "without_pickup_history";

export const ExceptionsTypes = OrderedSet.of(UPDATED_BY_MISTAKE, OTHER);

// Order Statuses
export const CANCELLED = "cancelled";
export const COMPLETED = "completed";
export const RTO = "returned_to_origin";
export const PICKED_UP = "picked_up";
export const RETURNED_TO_ORIGIN = "returned_to_origin";

export const ExceptionsOrderStatuses = OrderedSet.of(
  COMPLETED,
  ISSUED_TO_RECIPIENT,
  RETURNING_TO_ORIGIN,
  // CANCELLED,
  RETURNED_TO_ORIGIN,
  PICKED_UP,
  RECEIVER_DEAD,
  RECEIVER_NOT_LIVES_THERE,
  INCOMPLETE_ADDRESS,
  RECEIVER_REFUSE,
  NOT_AT_HOME,
  DIDNT_APPEAR_ON_NOTICE,
  DEFECT,
  TRY_PERFORM,
  READY_FOR_ISSUE,
  RETENTION_PERIOD_HAS_EXPIRED,
  ORGANIZATION_WITH_GIVEN_ADDRESS_NOT_FOUND,
  RETURNED_FROM_LOCKER_POST,
);

// Exception Statuses
export const RESOLVED = "resolved";
export const UNRESOLVED = "unresolved";
export const REQUIRES_ANALYSIS = "requires_analysis";

export const ExceptionsStatuses = OrderedSet.of(
  RESOLVED,
  UNRESOLVED,
  REQUIRES_ANALYSIS,
);
