import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withContext,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../../helpers/DataUtils";
import { getMarketplaceId } from "../../../reducers/MarketplaceReducer";
import { getMessages } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../../api/admin/AdminServiceTypesApi";
import {
  getMarketplaceSettings,
  getPublicSettings,
  updateMarketplaceSettings,
} from "../../../api/shared/MarketplaceApi";
import PageLoading from "../../../components/ui-core/PageLoading";
import MarketplaceSettingsForm from "../../../components/marketplaces-core/MarketplaceSettingsForm";
import { fetchMarketplace } from "../../../actions/MarketplaceActions";

const enhancer = compose(
  connect(
    state => ({
      marketplaceId: getMarketplaceId(state),
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage, fetchMarketplace },
  ),
  withContext(
    {
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    () => ({
      getCachedServiceType,
      getServiceTypePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const {
      stream: onRequestRefreshStream,
      handler: onRequestRefresh,
    } = createEventHandler();

    const settingsFieldsStream = propsStream
      .first()
      .switchMap(() =>
        getPublicSettings()
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], List()),
            }),
        ),
      );

    const marketplaceSettingsStream = propsStream
      .first()
      .switchMap(props =>
        getMarketplaceSettings(props.marketplaceId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );

    return propsStream
      .combineLatest(
        settingsFieldsStream,
        marketplaceSettingsStream,
        (props, settings, marketplaceSettings) => ({
          ...props,
          onRequestRefresh,
          settings: settings.get("payload"),
          isSettingsLoading: settings.get("pending"),
          marketplaceSettings: marketplaceSettings.get("payload"),
          isMarketplaceSettingsLoading: marketplaceSettings.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminMarketplaceDetailsAllSettingsContainer.propTypes = {
  settings: PropTypes.instanceOf(List),
  marketplaceSettings: PropTypes.instanceOf(Map),
  isMarketplaceSettingsLoading: PropTypes.bool,
  isSettingsLoading: PropTypes.bool,
  marketplaceId: PropTypes.number,
  fetchMarketplace: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function AdminMarketplaceDetailsAllSettingsContainer(props) {
  return (
    <div>
      <PageLoading
        isLoading={
          props.isMarketplaceSettingsLoading || props.isSettingsLoading
        }
      />

      <MarketplaceSettingsForm
        item={props.marketplaceSettings}
        settings={props.settings}
        marketplaceId={props.marketplaceId}
        onSubmit={settings =>
          updateMarketplaceSettings({
            marketplace_id: props.marketplaceId,
            settings,
          })
            .then(response => {
              if (response && response.status === "success") {
                props.showSuccessMessage(
                  props.i18n.get("successfully_saved", "Successfully Saved"),
                );
                props.fetchMarketplace();
                props.onRequestRefresh();
              }
            })
            .catch(error => props.showErrorMessage(error))
        }
      />
    </div>
  );
}

export default enhancer(AdminMarketplaceDetailsAllSettingsContainer);
