import React, { useEffect, useState } from "react";
import { List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { GetApp, Print } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import NavigationPrompt from "../router/NavigationPrompt";
import Text, { DANGER, MUTED, PRIMARY, SUCCESS } from "../ui-core/Text";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import DataList, { DataListColumn } from "../data-list/DataList";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { IS_DOWNLOADING, IS_PRINTING } from "../orders-core/AdminPostForm";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getPDFHybrid } from "../../helpers/FormUtils";
import { HYBRID_SIMPLE_URL } from "../../api/admin/AdminBatchApi";
import { formValues, reduxForm } from "redux-form";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import { formatOrderMessage } from "./BatchUpdatesItemDialog";
import { formatErrorMessage } from "../../helpers/FormatUtils";

const NSA = "NSA";

const enhancer = compose(
  connect(
    state => ({
      hasUserAddPermission: hasUserPermission(state, "BATCH_ITEM_ADD"),
      hasUserDeletePermission: hasUserPermission(state, "BATCH_ITEM_REMOVE"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
      },
    },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  withState("state", "setState", {
    selectedRows: Set(),
    requestedClose: false,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const requestRefresh = createEventHandler();

        const responseStream = propsStream
          .distinctUntilKeyChanged("batchId")
          .switchMap(props =>
            props
              .getBatchOrderUpdateItem(props.batchId)
              .let(mapObjectResponseStream)
              .repeatWhen(() => requestRefresh.stream),
          )
          .scan((prev, next) =>
            next.get("pending") === false
              ? next
              : next.set("payload", prev.get("payload")),
          )
          .distinctUntilChanged(isEqualData)
          .map(response =>
            response.update("payload", item =>
              item.isEmpty() ? item : item.update("children", List),
            ),
          );

        return propsStream.combineLatest(responseStream, (props, response) => ({
          ...props,
          item: response.get("payload"),
          errorMessage:
            response.get("error") && formatErrorMessage(response.get("error")),
          isLoading: response.get("pending"),
          onRequestRefresh: requestRefresh.handler,
        }));
      },
      propsStream => {
        const stateStream = propsStream
          .map(fp.pick(["state", "item"]))
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const { item, state } = props;
            const empty = item.isEmpty();

            const selectedRows = empty
              ? Set()
              : state.selectedRows.filter(x =>
                  Boolean(
                    item.get("children").filter(l => l.get("id") === x).size,
                  ),
                );

            const selectedFailedRows = selectedRows.filter(x =>
              item.hasIn(["failed_order_numbers", x]),
            );

            const selectedSuccessRows = selectedRows.filter(x =>
              item.hasIn(["success_order_numbers", x]),
            );

            const nsaOrders = empty
              ? Set()
              : item
                  .get("children")
                  .filter(x => x.get("status") === NSA)
                  .keySeq()
                  .toSet();

            const orderNumberList = empty
              ? List()
              : item.get("children").toList();

            return {
              ...state,

              nsaOrders,
              orderNumberList,

              selectedRows,
              selectedFailedRows,
              selectedSuccessRows,
            };
          });

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          state,
        }));
      },
    ),
  ),
  reduxForm({ form: "HybredBatchUpdatesItemDialog", enableReinitialize: true }),
  formValues({ items: "items" }),
);

HybredBatchUpdatesItemDialog.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,

  batchId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  errorMessage: PropTypes.string,
  isCustoms: PropTypes.bool,
  isHybrid: PropTypes.bool,
};

function HybredBatchUpdatesItemDialog(props) {
  const {
    item,
    state,
    classes,
    getLocalisationMessage,
    isLoading,
    errorMessage,
  } = props;
  const [stateHybridSimple, setStateHybridSimple] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: HYBRID_SIMPLE_URL,
  });
  const children = item.isEmpty() ? [] : item.get("children");
  const totalCount = children.size || 0;
  const failedList = children
    .filter(
      l =>
        item.get("asm_pro_status") === "FAILED" || l.get("status") === "FAILED",
    )
    .map(l => l.get("id"));
  const failedCount = failedList.size || 0;
  const succeedList = children
    .filter(
      l =>
        item.get("asm_pro_status") !== "FAILED" &&
        l.get("status") === "SUCCESS",
    )
    .map(l => l.get("id"));
  const succeedCount = succeedList.size || 0;
  const pendingCount = children.filter(l => l.get("status") === "PENDING").size;
  const pendingValue = ((succeedCount + failedCount) * 100) / totalCount;
  let refreshBatch;
  useEffect(() => {
    if (!isLoading && succeedCount + failedCount < totalCount)
      refreshBatch = setInterval(() => props.onRequestRefresh(), 1000);
    return () => clearInterval(refreshBatch);
  }, [isLoading]);

  useEffect(() => {
    if (errorMessage) {
      props.showErrorMessage(errorMessage);
    }
  }, [errorMessage]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={true}
      modal={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      bodyClassName={classes.body}
      contentClassName={classes.contentStyle}
      className={classes.rootStyle}
    >
      <form>
        <DialogTitle
          style={{ color: props.theme.palette.appBarTextColor }}
          className={classes.dialogTitle}
        >
          <FlexBox align="center" direction="row">
            <FlexBox flex={true} gutter={8}>
              {`${getLocalisationMessage("batch_update", "Batch Update")} #${
                props.batchId
              }`}
            </FlexBox>
          </FlexBox>
        </DialogTitle>
        <NavigationPrompt
          when={pendingCount > 0 && !state.requestedClose}
          message={getLocalisationMessage(
            "there_are_pending_updates",
            "There are pending updates, are you sure you want to exit?",
          )}
        />

        <ConfirmDialog
          modal={true}
          confirmButtonLabel={getLocalisationMessage("exit", "Exit")}
          dismissButtonLabel={getLocalisationMessage("go_back", "Go Back")}
          onConfirm={() => props.onRequestClose(item)}
          open={pendingCount > 0 && state.requestedClose}
          onRequestClose={() => props.setState(fp.set("requestedClose", false))}
        >
          {getLocalisationMessage("there_are_pending_updates")}
        </ConfirmDialog>
        <DialogContent>
          <FlexBox container={24} flex={true} direction="column">
            <FlexBox justify="space-between" style={{ gap: 8 }}>
              <FlexBox
                direction="column"
                flex={true}
                style={{ minWidth: "180px" }}
              >
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("created_by", "Created By")}
                  secondary={item.getIn(
                    ["user", "name"],
                    item.getIn(["user", "description"]),
                  )}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("type", "Type")}
                  secondary={getLocalisationMessage(item.get("type"))}
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("succeed_short", "Succeed")}
                  secondary={
                    <Text type={SUCCESS} element="div">
                      {succeedCount}{" "}
                      {formatOrderMessage(succeedCount, getLocalisationMessage)}
                    </Text>
                  }
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("failed_short", "Failed")}
                  secondary={
                    <Text type={DANGER} element="div">
                      {failedCount}{" "}
                      {formatOrderMessage(failedCount, getLocalisationMessage)}
                    </Text>
                  }
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("total", "Total")}
                  secondary={
                    <Text type={PRIMARY} element="div">
                      {totalCount}{" "}
                      {formatOrderMessage(totalCount, getLocalisationMessage)}
                    </Text>
                  }
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ButtonGroup size="small" variant={CONTAINED} color={SECONDARY}>
                  <CustomButton
                    style={{ minWidth: "180px" }}
                    data-cy="receipt_print"
                    onClick={() =>
                      getPDFHybrid(
                        stateHybridSimple,
                        setStateHybridSimple,
                        IS_PRINTING,
                        props.batchId,
                        props.showErrorMessage,
                        props.isHybrid,
                      )
                    }
                    endIcon={
                      stateHybridSimple[IS_PRINTING] ? (
                        <CircularProgress size={20} color="white" />
                      ) : (
                        <Print />
                      )
                    }
                  >
                    {getLocalisationMessage("simple")}
                  </CustomButton>
                  <CustomButton
                    data-cy="receipt_download"
                    onClick={() =>
                      getPDFHybrid(
                        stateHybridSimple,
                        setStateHybridSimple,
                        IS_DOWNLOADING,
                        props.batchId,
                        props.showErrorMessage,
                        props.isHybrid,
                      )
                    }
                  >
                    {stateHybridSimple[IS_DOWNLOADING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <GetApp />
                    )}
                  </CustomButton>
                </ButtonGroup>
              </FlexBox>
            </FlexBox>

            {pendingCount > 0 && (
              <FlexBox flex={true} align={ALIGN_CENTER} direction="column">
                <CircularProgress color="secondary" />
              </FlexBox>
            )}
            {pendingCount > 0 && (
              <LinearProgress
                color="secondary"
                variant="determinate"
                value={pendingValue}
                style={{ height: ".5rem" }}
              />
            )}
            <DataList
              isLoading={false}
              overscanRowCount={10}
              rowCount={totalCount}
              totalCount={totalCount}
              rowGetter={row => state.orderNumberList.get(row.index)}
            >
              <DataListColumn
                label="#"
                width={30}
                dataKey="index"
                disableSort={true}
                cellRenderer={row => row.rowIndex + 1}
              />

              <DataListColumn
                disableSort={true}
                label={getLocalisationMessage("barcode")}
                dataKey="order_number"
                cellRenderer={row =>
                  row.cellData && row.cellData.get("barcode")
                }
              />

              <DataListColumn
                disableSort={true}
                label={getLocalisationMessage("order_status")}
                dataKey="order_status"
                cellRenderer={row =>
                  row.cellData && row.cellData.get("order_status")
                    ? formatOrderStatusCodeForLocalisation(
                        row.cellData.get("order_status"),
                        getLocalisationMessage,
                      )
                    : getLocalisationMessage("na")
                }
              />

              <DataListColumn
                disableSort={true}
                label={getLocalisationMessage("status", "Status")}
                dataKey="status"
                cellRenderer={row =>
                  succeedList.contains(
                    row.cellData && row.cellData.get("id"),
                  ) ? (
                    <Text type={SUCCESS}>
                      {getLocalisationMessage("success", "Success")}
                    </Text>
                  ) : failedList.contains(
                      row.cellData && row.cellData.get("id"),
                    ) ? (
                    <Text type={DANGER}>
                      {getLocalisationMessage("failed", "Failed")}
                    </Text>
                  ) : (
                    <Text type={MUTED}>
                      {getLocalisationMessage("pending", "Pending")}
                    </Text>
                  )
                }
              />
            </DataList>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button
            primary={true}
            onClick={() => {
              if (pendingCount > 0) {
                props.setState(fp.set("requestedClose", true));
              } else {
                props.onRequestClose(item);
              }
            }}
          >
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default enhancer(HybredBatchUpdatesItemDialog);
