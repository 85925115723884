import { Map, Set } from "immutable";
import fp from "lodash/fp";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const UPDATE_TASK = "ORDER_INBOUND_SORTING/UPDATE_TASK";

const ADD_ORDER_NUMBERS = "ORDER_INBOUND_SORTING/ADD_ORDER_NUMBERS";
const ADD_ORDER_BARCODES = "ORDER_INBOUND_SORTING/ADD_ORDER_BARCODES";

const CACHE_ORDERS = "ORDER_INBOUND_SORTING/CACHE_ORDERS";
const CLEAR_ORDERS = "ORDER_INBOUND_SORTING/CLEAR_ORDERS";
const REMOVE_ORDERS = "ORDER_INBOUND_SORTING/REMOVE_ORDERS";

const ADD_BATCH_ROOT_ORDER = "ORDER_INBOUND_SORTING/ADD_BATCH_ROOT_ORDER";
const ADD_BATCH_ACTIVE_ORDER = "ORDER_INBOUND_SORTING/ADD_BATCH_ACTIVE_ORDER";
const ADD_BATCH_PARENT_ORDER = "ORDER_INBOUND_SORTING/ADD_BATCH_PARENT_ORDER";
const CLEAR_BATCH_ORDERS = "ORDER_INBOUND_SORTING/CLEAR_BATCH_ORDERS";

const isOrderSortingAction = fp.startsWith("ORDER_INBOUND_SORTING");

const castToMap = (x) => (Map.isMap(x) ? x : Map());
const castToSet = (x) => (Set.isSet(x) ? x : Set());

const cleanupState = fp.flow(castToMap, (initialState) =>
  initialState.withMutations((state) => {
    state.set("omit", Set.of("orders"));
    state.update("orders", castToMap);

    state.update(
      "task",
      fp.flow(castToMap, (x) =>
        x
          .update("filter", castToMap)
          .update("autoAssign", (b) => (fp.isUndefined(b) ? true : Boolean(b)))
          .update("inProgress", Boolean)
          .update("disableWarningWarehouse", (b) =>
            fp.isUndefined(b) ? true : Boolean(b),
          )
          .update("activeOrder", fp.trim)
          .update("cursorOrder", fp.trim)
          .update("activeNodes", castToSet)
          .update("selectedOrders", castToSet)
          .update("warehouse", (w) =>
            Map.isMap(w) && fp.isFinite(w.get("id")) ? w : null,
          ),
      ),
    );
  }),
);

const selector = injectReducer(
  "order-inbound-sorting@1.0.0",
  (initialState = cleanupState(), action) => {
    const state = isOrderSortingAction(action.type)
      ? cleanupState(initialState)
      : initialState;

    switch (action.type) {
      case UPDATE_TASK:
        return state.withMutations((nextState) => {
          nextState.set("task", action.payload);

          nextState.update(cleanupState);
        });

      case ADD_ORDER_NUMBERS: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeOrder"], payload.last());

          payload.forEach((number) => {
            nextState.updateIn(["task", "counter"], (x) => x.add(number));
          });
        });
      }

      case ADD_ORDER_BARCODES: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          payload.forEach((number) => {
            nextState.updateIn(["task", "boxCounter"], (x) => x.add(number));
          });
        });
      }

      case CACHE_ORDERS: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          payload.forEach((order) => {
            nextState.setIn(["orders", order.get("order_number")], order);
          });
        });
      }

      case REMOVE_ORDERS: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          if (payload.has(state.getIn(["task", "activeOrder"]))) {
            nextState.setIn(["task", "activeOrder"], null);
          }

          payload.forEach((number) => {
            nextState.updateIn(["task", "counter"], (x) => x.delete(number));
            nextState.updateIn(["task", "selectedOrders"], (x) =>
              x.delete(number),
            );
          });
        });
      }

      case CLEAR_ORDERS: {
        return state.withMutations((nextState) => {
          nextState.update("orders", (x) => x.clear());
          nextState.setIn(["task", "activeOrder"], null);
          nextState.updateIn(["task", "selectedOrders"], (x) => x.clear());
        });
      }

      // BIN Validation Actions

      case ADD_BATCH_ROOT_ORDER: {
        const { payload } = action;
        return state.withMutations((nextState) => {
          nextState.updateIn(["task", "activeNodes"], (x) => x.add(payload));
        });
      }

      case ADD_BATCH_PARENT_ORDER: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeBatchParentOrder"], payload);
        });
      }

      case ADD_BATCH_ACTIVE_ORDER: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeBatchActiveOrderNumber"], payload);
        });
      }

      case CLEAR_BATCH_ORDERS: {
        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeBatchRootOrder"], null);
          nextState.setIn(["task", "activeBatchParentOrder"], null);
          nextState.setIn(["task", "activeBatchActiveOrderNumber"], null);
          nextState.setIn(["task", "activeNodes"], Set());
        });
      }

      default:
        return state;
    }
  },
);

export const getOrderSortingTask = (state) => selector(state).get("task");
export const getOrderSortingOrders = (state) =>
  selector(state).get("orders", Map());
export const getOrderSortingTaskBatchRootOrder = (state) =>
  selector(state).getIn(["task", "activeBatchRootOrder"], null);

export const updateSortingTask = (updater) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_TASK,
    payload: updater(getOrderSortingTask(getState())),
  });
};

export const cacheOrderSortingOrders = (orders) => ({
  payload: orders,
  type: CACHE_ORDERS,
});

export const removeOrderSortingOrders = (orders) => ({
  payload: orders,
  type: REMOVE_ORDERS,
});

export const clearOrderSortingOrders = () => ({
  type: CLEAR_ORDERS,
});

export const addSortingTaskOrderNumbers = (numbers) => ({
  payload: numbers,
  type: ADD_ORDER_NUMBERS,
});

export const addSortingTaskOrderBarcodes = (numbers) => ({
  payload: numbers,
  type: ADD_ORDER_BARCODES,
});

// BIN Validation functions
export const addBatchSortingTaskOrderNumber = (numbers) => (dispatch) => {
  // const activeRootOrder = getOrderSortingTaskBatchRootOrder(getState());

  dispatch({
    payload: numbers.first(),
    type: ADD_BATCH_ACTIVE_ORDER,
  });

  // if (!activeRootOrder) {
  //   dispatch({
  //     payload: numbers.first(),
  //     type: ADD_BATCH_ROOT_ORDER,
  //   });
  // }
};

export const updateCursorOrder = (number) => (dispatch) => {
  dispatch({
    payload: number,
    type: ADD_BATCH_ROOT_ORDER,
  });
};

export const addBatchSortingTaskParentOrderNumber = (numbers) => ({
  payload: numbers.first(),
  type: ADD_BATCH_PARENT_ORDER,
});

export const clearOrderSortingBatchOrders = () => ({
  type: CLEAR_BATCH_ORDERS,
});
