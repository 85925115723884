import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Text, { MUTED, PRIMARY } from "../ui-core/Text";

const enhancer = compose(
  useSheet({
    primaryText: { fontSize: "14px" },
  }),
);

ProgressNumberCell.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  unit: PropTypes.string,
  valueWithUnit: PropTypes.bool,
};

function ProgressNumberCell(props) {
  const { classes } = props;

  if (!fp.isEmpty(props.unit) && props.valueWithUnit) {
    return (
      <div>
        <Text className={classes.primaryText} type={PRIMARY}>{`${props.value}${
          props.unit
        }`}</Text>
        <Text type={MUTED}>/{`${props.total}${props.unit}`}</Text>
      </div>
    );
  } else if (!fp.isEmpty(props.unit)) {
    return (
      <div>
        <Text className={classes.primaryText} type={PRIMARY}>
          {props.value}
        </Text>
        <Text type={MUTED}>/{`${props.total}${props.unit}`}</Text>
      </div>
    );
  }

  return (
    <div>
      <Text className={classes.primaryText} type={PRIMARY}>
        {props.value}
      </Text>/<Text type={MUTED}>{props.total}</Text>
    </div>
  );
}

export default enhancer(ProgressNumberCell);
