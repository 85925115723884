import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toBankDepositFilterMapper = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    is_uae: types.booleanOr(true),
    use_solr: types.boolean,
    to_date: queryTypes.date,
    from_date: queryTypes.date,
    customer_ids: queryTypes.IDSeq,
  }),
);
