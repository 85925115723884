import { fromJS } from "immutable";
import fp from "lodash/fp";
import WingApi from "../helpers/WingApi";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const FINANCIAL_REPORT_LIST_URL = `${API_ROOT_URL}/report/financial_report`;

export function loadFinanceReports(request) {
  return (dispatch, getState, api: WingApi) =>
    api
      .getStream(FINANCIAL_REPORT_LIST_URL, { params: request })
      .map(fp.update("payload", fp.flow(fp.get("data"), fromJS)));
}
