import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SampleItemsFilterForm from "../../components/orders-core/SampleItemsFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "400px",
      minHeight: "auto",
    },
  }),
);

AdminSampleItemsFilterWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function AdminSampleItemsFilterWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage(
        "sample_item_filter",
        "Sample Item Filter",
      )}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <SampleItemsFilterForm
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        onDismiss={props.onRequestClose}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminSampleItemsFilterWrapper);
