import React, { useEffect, useState } from "react";
import { compose, getContext, mapPropsStream } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { SAMPLE_ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import {
  ArrowBackIos,
  Cancel,
  Check,
  CheckCircle,
  Save,
} from "@material-ui/icons";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { Map, OrderedSet } from "immutable";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import FormJMAutoComplete from "../../components/form/FormJMAutoComplete";
import PostcodeAutoCompleteWithJM from "../../components/form/PostcodeAutoCompleteWithJM";
import FormTextField from "../../components/form/FormTextField";
import FormSelectField from "../../components/form/FormSelectField";
import { formatText } from "../../helpers/FormatUtils";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import {
  BANDEROL,
  LETTER,
  PARCEL,
  SMALL_PACKETS,
} from "../../helpers/OrderOutboundSortingHelper";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import {
  showErrorMessage,
  showSuccessMessage,
  SUCCESS_MESSAGE,
} from "../../reducers/NotificationsReducer";
import {
  isShallowEqual,
  kirilToLatinByKeyboardPlace,
} from "../../helpers/DataUtils";
import {
  createObjectIdValidator,
  createRegisteredBarcodeValidator,
} from "../../helpers/FormUtils";
import { api } from "../../api/shared/BaseApi";
import { BARCODE_VALIDATION_URL } from "../../components/orders-core/AdminPostForm";
import fp from "lodash/fp";

const serviceType = OrderedSet.of(LETTER, PARCEL, SMALL_PACKETS, BANDEROL);

const OrderItems = props => {
  const { i18n } = props;

  const [senderJurisdiction, setSenderJurisdiction] = useState([]);
  const [jurisdiction, setJurisdiction] = useState([]);

  useEffect(() => {
    if (
      props.notValid &&
      props.fields.getAll() &&
      props.fields.getAll().length > 0
    ) {
      const tempFields = [];
      const tempSenderFields = [];
      // eslint-disable-next-line array-callback-return
      props.fields.getAll().map((field, index) => {
        tempFields[index] = field.recipient_data.jurisdiction;
        tempSenderFields[index] = field.sender_data.jurisdiction;
      });

      setSenderJurisdiction([...tempSenderFields]);
      setJurisdiction([...tempFields]);
    }
  }, [props.fields]);

  // eslint-disable-next-line prefer-destructuring
  const registered =
    props.fields.getAll() && props.fields.getAll().length > 0
      ? props.fields.getAll()[0].registered
      : false;

  const [barcodeErrorMessage, setBarcodeErrorMessage] = useState([]);
  const [barcode, setBarcode] = useState(null);
  const [findIndex, setFindIndex] = useState(null);
  const [findBarcode, setFindBarcode] = useState(null);

  useEffect(() => {
    if (props.errors && !barcodeErrorMessage.length) {
      const tempErrors = props.errors.map(error => error.barcode);
      setBarcodeErrorMessage([...tempErrors]);
    }
  }, [props.errors]);

  useEffect(() => {
    if (!registered && barcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(barcode);
        const tempBarcode =
          convertedValue !== barcode ? convertedValue : barcode;

        if (props.barcodes.includes(tempBarcode)) {
          const tempBarcodeErrorMessage = [...barcodeErrorMessage];
          tempBarcodeErrorMessage[findIndex] = i18n.get(
            "you_used_this_barcode",
            "You used this barcode",
          );

          setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
        } else {
          api
            .get(BARCODE_VALIDATION_URL, {
              params: { barcode: tempBarcode },
            })
            .then(res => {
              if (res.status !== SUCCESS_MESSAGE && res.code === "DUPLICATE") {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = res.message;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              } else {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = null;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              }
            })
            .catch(error => {
              props.showErrorMessage(error);
            })
            .finally(
              () =>
                convertedValue !== barcode &&
                props.change(findBarcode, convertedValue),
            );
        }
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [barcode, findIndex]);

  useEffect(() => {
    if (registered && barcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(barcode);
        const tempBarcode =
          convertedValue !== barcode ? convertedValue : barcode;

        if (props.barcodes.includes(tempBarcode)) {
          const tempBarcodeErrorMessage = [...barcodeErrorMessage];
          tempBarcodeErrorMessage[findIndex] = i18n.get(
            "you_used_this_barcode",
            "You used this barcode",
          );

          setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
        } else {
          api
            .get(BARCODE_VALIDATION_URL, {
              params: { barcode: tempBarcode },
            })
            .then(res => {
              if (res.status !== SUCCESS_MESSAGE) {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = res.message;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              } else {
                const tempBarcodeErrorMessage = [...barcodeErrorMessage];
                tempBarcodeErrorMessage[findIndex] = null;

                setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
              }
            })
            .catch(error => {
              props.showErrorMessage(error);
            })
            .finally(
              () =>
                convertedValue !== barcode &&
                props.change(findBarcode, convertedValue),
            );
        }
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [barcode, findIndex]);

  return (
    <FlexBox
      flex={true}
      direction="column"
      style={{ maxHeight: props.maxHeight, overflow: "auto" }}
    >
      <FlexBox flex={true} direction="column" style={{ gap: 16 }}>
        {props.fields.map((field, fieldIndex) => (
          <FlexBox
            style={{
              gap: 24,
              padding: 16,
              border: "1px dashed rgba(0, 0, 0, 0.1)",
              borderRadius: 10,
            }}
            key={fieldIndex}
          >
            <FlexBox align={ALIGN_CENTER}>
              <FlexBox
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: props.notValid ? "#EB5757" : "#27AE60",
                  borderRadius: 20,
                }}
                align={ALIGN_CENTER}
                justify={ALIGN_CENTER}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#FFFFFF",
                  }}
                >
                  {fieldIndex + 1}
                </span>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                disabled={!props.notValid}
                name={`${field}.barcode`}
                label={`${i18n.get("barcode", "Barcode")} *`}
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                        <path
                          fill="currentColor"
                          d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                onChange={(e, value) => {
                  setBarcode(value);
                  setFindIndex(fieldIndex);
                  setFindBarcode(`${field}.barcode`);
                }}
                validate={createRegisteredBarcodeValidator(
                  barcodeErrorMessage[fieldIndex],
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            {registered && (
              <FlexBox flex={true}>
                <FormSelectField
                  disabled={!props.notValid}
                  fullWidth={true}
                  name={`${field}.service_type.code`}
                  options={serviceType}
                  formatOption={option =>
                    i18n.get(option.toLowerCase(), formatText(option))
                  }
                  label={i18n.get("service_type")}
                />
              </FlexBox>
            )}
            <FlexBox flex={true}>
              <FormJMAutoComplete
                disabled={!props.notValid}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                hintText={i18n.get("type_to_search_hint")}
                fullWidth={true}
                name={`${field}.sender_data.jurisdiction`}
                label={i18n.get("sender_jurisdiction", "Sender Jurisdiction")}
                onChange={value => {
                  const tempJur = [...senderJurisdiction];
                  tempJur[fieldIndex] = { ...value };
                  setSenderJurisdiction(tempJur);
                }}
                immediatelyShowError={true}
                validate={createObjectIdValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <PostcodeAutoCompleteWithJM
                disabled={!props.notValid}
                name={`${field}.sender_data.postcode`}
                fullWidth={true}
                jurisdictionId={
                  senderJurisdiction[fieldIndex]
                    ? senderJurisdiction[fieldIndex].id
                    : null
                }
                jurisdiction={senderJurisdiction[fieldIndex]}
                label={i18n.get("sender_index", "Sender Index")}
                hintText={i18n.get("type_to_search", "Type To Search...")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                disabled={!props.notValid}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                hintText={i18n.get("type_to_search_hint")}
                fullWidth={true}
                name={`${field}.recipient_data.jurisdiction`}
                label={i18n.get(
                  "recipient_jurisdiction",
                  "Recipient Jurisdiction",
                )}
                onChange={value => {
                  const tempJur = [...jurisdiction];
                  tempJur[fieldIndex] = { ...value };
                  setJurisdiction(tempJur);
                }}
                immediatelyShowError={true}
                validate={createObjectIdValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <PostcodeAutoCompleteWithJM
                disabled={!props.notValid}
                name={`${field}.recipient_data.postcode`}
                fullWidth={true}
                jurisdictionId={
                  jurisdiction[fieldIndex] ? jurisdiction[fieldIndex].id : null
                }
                jurisdiction={jurisdiction[fieldIndex]}
                label={i18n.get("recipient_index", "Recipient Index")}
                hintText={i18n.get("type_to_search", "Type To Search...")}
              />
            </FlexBox>

            {props.notValid ? (
              <FlexBox align={ALIGN_CENTER} />
            ) : (
              <FlexBox align={ALIGN_CENTER}>
                <IconButton size="small">
                  <Check style={{ color: "#27AE60" }} />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

OrderItems.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  fields: PropTypes.array,
  notValid: PropTypes.bool,
  maxHeight: PropTypes.number,
  barcodes: PropTypes.array,
  errors: PropTypes.array,
  showErrorMessage: PropTypes.func,
  change: PropTypes.func,
};

const styles = makeStyles({
  root: {
    padding: "16px 24px",
    gap: 16,
    height: "100%",
  },
  title: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    textTransform: "capitalize",
  },
  cardTitle: {
    borderBottom: "1px solid #EEEEEE",
  },
  listIconSuccess: {
    width: 20,
    height: 20,
    color: "rgba(76, 175, 80)",
  },
  listIconFailed: {
    width: 20,
    height: 20,
    color: "rgba(235, 87, 87)",
  },
  saveButton: {
    "& .MuiButton-containedSecondary": {
      backgroundColor: "#27AE60",
    },
  },
});

const getValues = getFormValues("AdminSimpleOrderItem");

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values.notCreatedOrders);
    };
    return propsStream
      .combineLatest(props => ({
        ...props,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdminSimpleOrderItem",
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
        notCreatedOrders: values.notCreatedOrders || [],
      },
    })),
  ),
);

AdminSimpleOrderItem.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.number,
  created: PropTypes.number,
  total: PropTypes.number,
  failed: PropTypes.number,
  values: PropTypes.object,
  errors: PropTypes.array,
};

function AdminSimpleOrderItem(props) {
  const { i18n } = props;
  const classes = styles();

  return (
    <FlexBox
      className={classes.root}
      direction="column"
      justify="space-between"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <Typography variant="h5" className={classes.title}>
        <IconButton onClick={() => props.setLocation(SAMPLE_ORDER_LIST_URL)}>
          <ArrowBackIos />
        </IconButton>

        {`${i18n.get("batch", "Batch")}: #${props.id}`}
      </Typography>
      <Card style={{ overflow: "auto", height: "100%" }}>
        <CardContent
          style={{
            borderBottom: "1px solid #EEEEEE",
            padding: "16px 24px",
          }}
        >
          <FlexBox>
            <FlexBox
              style={{
                gap: 24,
                background: "#F1F5F9",
                padding: "12px 20px",
                borderRadius: 4,
                border: "1px solid #CFE7FF",
              }}
              align="center"
            >
              <FlexBox align="center" style={{ gap: 10 }}>
                <Typography
                  style={{ fontSize: 18, fontWeight: 400, color: "#263238" }}
                >
                  {`${i18n.get("total", "Total")}:`}
                </Typography>
                <Typography
                  style={{ fontSize: 18, fontWeight: 700, color: "#263238" }}
                >
                  {props.total}
                </Typography>

                <Typography
                  style={{ fontSize: 18, fontWeight: 400, color: "#263238" }}
                >
                  {i18n.get("orders", "orders")}
                </Typography>
              </FlexBox>
              <Divider
                style={{
                  width: 21,
                  border: "1px solid #CFE7FF",
                  transform: "rotate(90deg)",
                  height: 0,
                }}
              />
              <FlexBox align="center" style={{ gap: 10 }}>
                <CheckCircle className={classes.listIconSuccess} />
                <Typography
                  style={{ fontSize: 18, fontWeight: 400, color: "#263238" }}
                >
                  {`${i18n.get("created", "Created")}: `}
                </Typography>
                <Typography
                  style={{ fontSize: 18, fontWeight: 700, color: "#263238" }}
                >
                  {`${props.created}/${props.total}`}
                </Typography>
              </FlexBox>
              <FlexBox align="center" style={{ gap: 10 }}>
                <Cancel className={classes.listIconFailed} />
                <Typography
                  style={{ fontSize: 18, fontWeight: 400, color: "#263238" }}
                >
                  {`${i18n.get("failed", "Failed")}: `}
                </Typography>
                <Typography
                  style={{ fontSize: 18, fontWeight: 700, color: "#263238" }}
                >
                  {`${props.failed}/${props.total}`}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>
        <CardContent>
          <FlexBox style={{ gap: 24 }} direction="column">
            {props.failed > 0 && (
              <React.Fragment>
                <FlexBox justify="space-between" align="center">
                  <FlexBox
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    {i18n.get(
                      "uncompleted_orders",
                      "Uncompleted orders (please complete)",
                    )}
                  </FlexBox>
                  <FlexBox>
                    <CustomButton
                      type="submit"
                      variant={CONTAINED}
                      color={SECONDARY}
                      style={{ width: 130, height: 40 }}
                      startIcon={<Save />}
                    >
                      {i18n.get("save", "Save")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>

                <FieldArray
                  name="notCreatedOrders"
                  component={OrderItems}
                  props={{
                    i18n,
                    notValid: true,
                    change: props.change,
                    showErrorMessage: props.showErrorMessage,
                    maxHeight:
                      props.created > 0 && props.failed > 0 ? 269 : "auto",
                    barcodes:
                      props.values.notCreatedOrders &&
                      props.values.notCreatedOrders.length > 0
                        ? props.values.notCreatedOrders.map(
                            order => order.barcode,
                          )
                        : [],
                    errors: props.errors,
                  }}
                />
              </React.Fragment>
            )}

            {props.created > 0 && (
              <React.Fragment>
                <FlexBox
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {i18n.get("completed_orders", "Completed orders")}
                </FlexBox>

                <FieldArray
                  name="createdOrders"
                  component={OrderItems}
                  props={{
                    i18n,
                    change: props.change,
                    showErrorMessage: props.showErrorMessage,
                    barcodes: [],
                  }}
                />
              </React.Fragment>
            )}
          </FlexBox>
        </CardContent>
      </Card>
    </FlexBox>
  );
}

export default enhancer(AdminSimpleOrderItem);
