import React from "react";
import { addYears, addMonths } from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { isEmpty } from "../../helpers/DataUtils";
import { formatDate, MONTH_YEAR_FORMAT } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const getPlanTitle = fp.flow(fp.get("title"), fp.toUpper);
const getPlanShipmentCount = fp.flow(
  fp.get("selectedIncludes"),
  fp.get("shipment_count"),
);
const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    dialogRoot: {
      marginBottom: 5,
    },
    buttonRight: { float: "right" },
  }),
  withState("disableAutoRenewal", "setDisable", false),
);
SubscriptionBuyConfirmDialog.propTypes = {
  classes: PropTypes.object,
  disableAutoRenewal: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  selectedPlan: PropTypes.object,
  filter: PropTypes.instanceOf(Map),
  subscribedPlan: PropTypes.instanceOf(Map),
  setDisable: PropTypes.func,
  handleConfirm: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function SubscriptionBuyConfirmDialog(props) {
  const { i18n } = props;
  return (
    <ConfirmDialog
      open={true}
      onRequestClose={() => props.closeDialog()}
      onConfirm={() => {
        props.handleConfirm({ renew: props.disableAutoRenewal });
      }}
    >
      <FlexBox direction="column" style={{ paddingBottom: 5 }}>
        <h4 style={{ marginBottom: 10 }}>
          {i18n.get("confirm_purpose", "Confirm Purpose")}
        </h4>
        <p>
          {`${i18n.get(
            "you_are_about_to_purchase",
            "You are about to purchase",
          )} ${getPlanTitle(props.selectedPlan)} ${i18n.get(
            "package_with",
            "package with",
          )} ${getPlanShipmentCount(props.selectedPlan)} ${i18n.get(
            "shipments_per",
            "shipments per",
          )} ${" "}
          ${i18n.get(
            fp.toLower(props.filter.get("period_type").replace(/ly$/i, "")),
            fp.toLower(props.filter.get("period_type").replace(/ly$/i, "")),
          )}.
         ${i18n.get(
           "your_subscription_starts",
           "Your subscription starts",
         )} ${" "}`}
          <strong>
            {isEmpty(props.subscribedPlan)
              ? formatDate(new Date(), MONTH_YEAR_FORMAT)
              : formatDate(
                  new Date(props.subscribedPlan.get("end")),
                  MONTH_YEAR_FORMAT,
                )}
          </strong>{" "}
          {i18n.get("and_it_will_end", "and it will end")}{" "}
          <strong>
            {isEmpty(props.subscribedPlan)
              ? props.filter.get("period_type") === "YEARLY"
                ? formatDate(addYears(new Date(), 1), MONTH_YEAR_FORMAT)
                : formatDate(addMonths(new Date(), 1), MONTH_YEAR_FORMAT)
              : props.filter.get("period_type") === "MONTHLY"
              ? formatDate(
                  addMonths(new Date(props.subscribedPlan.get("end")), 1),
                  MONTH_YEAR_FORMAT,
                )
              : formatDate(
                  addYears(new Date(props.subscribedPlan.get("end")), 1),
                  MONTH_YEAR_FORMAT,
                )}
          </strong>
          .
        </p>
        {false && (
          <Checkbox
            label={i18n.get("disable_auto_renewal", "Disable Auto Renewal")}
            checked={props.disableAutoRenewal}
            onCheck={(event, checked) => props.setDisable(checked)}
          />
        )}
      </FlexBox>
    </ConfirmDialog>
  );
}

export default enhancer(SubscriptionBuyConfirmDialog);
