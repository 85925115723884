import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getUserCountByRole } from "../../api/admin/AdminDashboardNewApi";
import { getValue } from "../../helpers/DataUtils";
import DashboardUserRoleFilterForm from "./DashboardUserRoleFilterForm";
import CustomDoughnutChart from "../charts/CustomDoughnutChart";

import Driver from "../charts/assests/car-driver.svg";
import Others from "../charts/assests/others-icon.svg";
import Operator from "../charts/assests/operator.svg";
import OperatorSorter from "../charts/assests/sorter-operator.svg";
import Sorter from "../charts/assests/sorter.svg";
import { formatText } from "../../helpers/FormatUtils";

const customLegendIcon = label => {
  switch (label) {
    case "driver":
      return <img src={Driver} alt="delete" height={32} width={32} />;

    case "operator":
      return <img src={Operator} alt="delete" height={32} width={32} />;

    case "sorter":
      return <img src={Sorter} alt="delete" height={32} width={32} />;

    case "operator_sorter":
      return <img src={OperatorSorter} alt="delete" height={32} width={32} />;

    default:
      return <img src={Others} alt="delete" height={32} width={32} />;
  }
};

const legendLabelLocalisation = (label, getLocalisationMessage) => {
  switch (label) {
    default:
      return getLocalisationMessage(label, formatText(label));
  }
};

export const formatRoleColor = code => {
  switch (code) {
    case "driver":
      return "#0000FF";
    case "operator":
      return "#008000";
    case "others":
      return "#FFA500";
    case "sorter":
      return "#FFC0CB";
    case "operator_sorter":
      return "#800080";

    default:
      return "#FFA500";
  }
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

const useStyles = makeStyles(() => ({
  total: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "@media (min-width: 1700px)": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  },
}));

AdminUserCountByRoleChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
};

function AdminUserCountByRoleChartWrapper(props) {
  const [chartData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const orderRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = useState([]);

  const [filter, setFilter] = useState({
    jurisdiction_id: props.filter.jurisdiction_id,
  });

  useEffect(() => {
    setFilter({
      jurisdiction_id: props.filter.jurisdiction_id,
    });
  }, [props.filter]);

  const styles = useStyles();

  useEffect(() => {
    setIsLoading(true);
    getUserCountByRole(filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          setActiveIndex([]);

          setData(
            barData.map(item => ({
              ...item,
              color: formatRoleColor(item.label),
            })),
          );
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setActiveIndex([]);
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData([]);
      });
  }, [props.filter, filter]);

  return (
    <Card
      style={{
        width: "100%",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
        paddingBottom: "26px",
      }}
      ref={orderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,

            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox flex={true} style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "user_count_by_role",
              "User count by role",
            )}
          </FlexBox>
          <DashboardUserRoleFilterForm
            initialValues={{
              jurisdiction: {
                id: filter.jurisdiction_id,
              },
              warehouse: {
                id: filter.warehouse_id,
              },
            }}
            onSubmit={v =>
              setFilter({
                jurisdiction_id: getValue(v, "jurisdiction.id"),
                warehouse_id: getValue(v, "warehouse.id"),
              })
            }
          />
        </FlexBox>
      </FlexBox>

      {isLoading && orderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(orderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}

      {!isLoading && (
        <div className="relative" style={{ position: "relative" }}>
          <CustomDoughnutChart
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            legendLabelLocalisation={legendLabelLocalisation}
            getLocalisationMessage={props.getLocalisationMessage}
            customLegendIcon={customLegendIcon}
            filter={filter}
            data={{
              labels: chartData.map(doughnut => doughnut.label),
              datasets: [
                {
                  data: chartData.map(doughnut => doughnut.value),
                  backgroundColor: chartData.map(doughnut => doughnut.color),
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              ],
            }}
            position="bottom"
          />
          <div className={styles.total}>
            <h4
              style={{
                fontSize: 48,
                fontWeight: 700,
              }}
            >
              {new Intl.NumberFormat("ru-RU").format(
                chartData
                  .filter((item, index) => !activeIndex.includes(index))
                  .reduce((acc, item) => acc + item.value, 0),
              )}{" "}
            </h4>

            <h6
              style={{
                fontSize: 24,
                fontWeight: 400,
                marginTop: 13,
              }}
            >
              {props.getLocalisationMessage("in_total", "In Total")}
            </h6>
          </div>
        </div>
      )}
    </Card>
  );
}

export default enhancer(AdminUserCountByRoleChartWrapper);
