import { Observable } from "rxjs";
import React from "react";
import { Map, Set, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDriverLiabilityFilterMapper } from "../../helpers/DriverLiabiltyFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getDriverLiabilityQueue,
  clearDriverLiabilityQueue,
  updateDriverLiabilityQueue,
} from "../../reducers/DriverLiabilityReducer";
import {
  COD_PENDING,
  OPEN_SHIPMENTS,
} from "../../constants/DriverLiabilityTypes";
import DriverLiabiltyStatus, {
  ALL,
} from "../../constants/DriverLiabiltyStatus";
import {
  getStatusTotal,
  getCachedDriver,
  getDriverLiability,
  getDriverPredictions,
  getCashierLiabilityTotals,
  getCalculateLiabilityTotal,
  getCashierOpenLiabilityTotals,
  updateCashierOrderUpdatePsfCodCharge,
} from "../../api/admin/AdminDriverApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import DriverLiabilityStatusTabs from "../../components/ui-core/DriverLiabilityStatusTabs";
import DriverLiabilityFilterForm from "../../components/driver-liability/DriverLiabilityFilterForm";
import DriverLiabilityCODPendingTotal from "../../components/driver-liability/DriverLiabilityCODPendingTotal";
import BatchUpdateLiabilityTotalDialog from "../../components/driver-liability/BatchUpdateLiabilityTotalDialog";
import DriverLiabilityOpenShipmentTotal from "../../components/driver-liability/DriverLiabilityOpenShipmentTotal";
import DriverLiabilityOpenShipmentsTable from "../../components/driver-liability/DriverLiabilityOpenShipmentsTable";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_DRIVER_LIABILITY_REPORT_URL } from "../../../shared/constants/FileProxyControllerConstants";
import DriverLiabilityCODPendingTable from "../../components/driver-liability/DriverLiabilityCODPendingTable";

const BATCH_UPDATE_LIABILITY_TOTAL_DIALOG_HASH = "#BULTDH";

const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        selectedItems: getDriverLiabilityQueue(state),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      clearDriverLiabilityQueue,
      updateDriverLiabilityQueue,
    },
  ),
  useSheet({
    card: { "& > div": { display: "flex", flex: 1 } },
    totals: { textAlign: "center", padding: "12px 0" },
    withoutMargin: { margin: 0 },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const filterStream = propsStream
          .map(
            fp.flow(
              fp.get("location.query"),
              fp.assign({ size: 100 }),
              toDriverLiabilityFilterMapper,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const initialValuesStream = filterStream
          .first()
          .map((filter: DataListFilter) => ({
            driver: { id: filter.getNumberValue("driver_id") },
            type: filter.getValue("eventType") || COD_PENDING,
          }))
          .distinctUntilChanged(isEqualData);

        const { stream: onRowSelectStream, handler: onRowSelect } =
          createEventHandler();

        const { stream: onRequestRefreshStream, handler: onRequestRefresh } =
          createEventHandler();

        const statusCountsStream = filterStream
          .map((filter: DataListFilter) =>
            filter.setValueMap({
              page: null,
              status: null,
              order_by: null,
              order_by_direction: null,
              orderStatus: null,
              eventType: null,
            }),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap((filter) =>
            filter.getValue("driver_id")
              ? getStatusTotal(filter.getValue("driver_id"), filter)
                  .catch((error) => Observable.of({ error }))
                  .repeatWhen(() => onRequestRefreshStream)
              : Observable.of({}),
          )
          .map(
            fp.flow(
              fp.get("payload.data"),
              fp.keyBy("status"),
              fp.mapValues("totals"),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const driverLiabilityPendingStream = filterStream
          .switchMap((filter) =>
            filter.getValue("driver_id")
              ? getDriverLiability(filter.getValue("driver_id"), filter)
                  .catch((error) => Observable.of({ error }))
                  .repeatWhen(() => onRequestRefreshStream)
              : Observable.of({}),
          )
          .startWith({})
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  payload: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const driverLiabilityTotalsStream = filterStream
          .map((filter: DataListFilter) =>
            filter.setValueMap({
              orderStatus: null,
              eventType: null,
            }),
          )
          .switchMap((filter) =>
            filter.getValue("driver_id")
              ? getCashierLiabilityTotals(
                  filter.getValue("driver_id"),
                  filter.setValue("driver_id", null),
                )
                  .catch((error) => Observable.of({ error }))
                  .repeatWhen(() => onRequestRefreshStream)
              : Observable.of({}),
          )
          .startWith({})
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  pending: response.get("pending", false),
                  payload: response.getIn(["payload", "data"], Map()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const sideEffectStream = Observable.merge(
          propsStream.take(1).do((props) => props.clearDriverLiabilityQueue()),
          propsStream
            .distinctUntilChanged(
              isEqualDataIn(["location", "query", "driver_id"]),
            )
            .skip(1)
            .do((props) => {
              onRowSelect(Set());
              props.clearDriverLiabilityQueue();
            }),
          onRowSelectStream
            .distinctUntilChanged(isEqualData)
            .withLatestFrom(propsStream)
            .do(([numbers, props]) =>
              props.updateDriverLiabilityQueue(numbers),
            ),
        ).startWith(null);

        return propsStream
          .combineLatest(
            filterStream,
            initialValuesStream,
            driverLiabilityPendingStream,
            driverLiabilityTotalsStream,
            statusCountsStream,
            sideEffectStream,
            (
              props,
              filter,
              initialValues,
              driverPendingLiability,
              driverLiabilityTotals,
              statusCounts,
            ) => ({
              ...props,
              filter,
              initialValues,
              statusCounts,
              onRowSelect,
              onRequestRefresh,
              isLoadingLiability: driverPendingLiability.get("pending"),
              liabilityList: driverPendingLiability.get("payload"),
              liabilityListTotal: driverPendingLiability.get("total"),
              isLoadingLiabilityTotals: driverLiabilityTotals.get("pending"),
              liabilityTotals: driverLiabilityTotals.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      (propsStream) =>
        propsStream
          .combineLatest(
            propsStream
              .map((props) => props.filter.getNumberValue("driver_id"))
              .startWith(0),
            (props, driverId) => ({
              ...props,
              driverId,
            }),
          )
          .distinctUntilChanged(isEqualData),
      (propsStream) => {
        const driverLiabilityOpenStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            (props) =>
              props.driverId > 0 &&
              props.filter.getValue("eventType") === OPEN_SHIPMENTS,
          )
          .switchMap((props) =>
            getDriverLiability(
              props.driverId,
              props.filter
                .setValue("search", null)
                .setValue("status", null)
                .setValue("orderStatus", null),
            ).catch((error) => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  payload: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          driverLiabilityOpenStream,
          (props, driverOpenLiability) => ({
            ...props,
            isLoadingOpenLiability: driverOpenLiability.get("pending"),
            liabilityOpenList: driverOpenLiability.get("payload"),
            liabilityListOpenTotal: driverOpenLiability.get("total"),
          }),
        );
      },
    ),
  ),
);

AdminDriverLiabilityContainer.propTypes = {
  classes: PropTypes.object,
  driverId: PropTypes.number,
  isLoadingLiability: PropTypes.bool,
  isLoadingOpenLiability: PropTypes.bool,
  isLoadingLiabilityOpenTotals: PropTypes.bool,
  isLoadingLiabilityTotals: PropTypes.bool,
  setLocationQueryFilter: PropTypes.func,
  liabilityList: PropTypes.instanceOf(List),
  liabilityOpenList: PropTypes.instanceOf(List),
  liabilityListTotal: PropTypes.number,
  liabilityListOpenTotal: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  location: PropTypes.object,
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Set),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  clearDriverLiabilityQueue: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  liabilityTotals: PropTypes.instanceOf(Map),
  statusCounts: PropTypes.instanceOf(Map),
};

function AdminDriverLiabilityContainer(props) {
  const { classes, getLocalisationMessage } = props;

  const orderNumbersArray = props.selectedItems.toArray();

  return (
    <AdminAppLayout
      slug="driver_liability"
      title={getLocalisationMessage(
        "check_driver_liability",
        "Check Driver Liability",
      )}
    >
      <BatchUpdateLiabilityTotalDialog
        getCalculateLiabilityTotal={getCalculateLiabilityTotal}
        open={props.location.hash === BATCH_UPDATE_LIABILITY_TOTAL_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        orderNumbers={orderNumbersArray}
        driverId={props.driverId}
        onSubmit={({ updateCodAsWithCashier, updatePsfAsWithCashier }) =>
          updateCashierOrderUpdatePsfCodCharge({
            order_numbers: orderNumbersArray,
            update_cod_as_with_cashier: updateCodAsWithCashier,
            update_psf_as_with_cashier: updatePsfAsWithCashier,
            driver_id: props.driverId,
          })
            .then((message) => {
              props.onRequestRefresh();
              props.showSuccessMessage(message);
              props.replaceLocationHash(null);
              props.clearDriverLiabilityQueue();
            })
            .catch(props.showErrorMessage)
        }
      />

      <Redirect
        when={!DriverLiabiltyStatus.has(props.location.query.orderStatus)}
        to={updateQuery(
          props.location,
          fp.set("orderStatus", DriverLiabiltyStatus.first()),
        )}
      />

      <FlexBox
        container={8}
        flex={true}
        element={<Card className={classes.card} />}
      >
        <FlexBox
          gutter={8}
          flex={true}
          direction="column"
          element={<CardContent />}
        >
          <FlexBox>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <DriverLiabilityFilterForm
                  getCachedDriver={getCachedDriver}
                  getDriverPredictions={getDriverPredictions}
                  initialValues={props.initialValues}
                  onChange={(values) =>
                    props.setLocationQueryFilter(
                      props.filter
                        .setValueMap({
                          driver_id: getObjectId(values.driver),
                          eventType: values.type,
                        })
                        .setPage(0),
                    )
                  }
                />
              </FlexBox>
              <FlexBox align="center">
                <Button
                  component="a"
                  target="_blank"
                  disabled={props.driverId === 0}
                  href={updateQuery(CREATE_DRIVER_LIABILITY_REPORT_URL, {
                    driver_id: props.driverId,
                    eventType:
                      props.filter.getValue("eventType") === COD_PENDING
                        ? COD_PENDING
                        : null,
                    orderStatus:
                      props.filter.getValue("eventType") === COD_PENDING
                        ? ALL
                        : null,
                  })}
                >
                  {getLocalisationMessage("export_to_csv", "Export to CSV")}
                </Button>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true} direction="column">
              {Boolean(props.driverId > 0) && (
                <FlexBox>
                  {Boolean(
                    props.filter.getValue("eventType") === COD_PENDING,
                  ) && (
                    <DriverLiabilityCODPendingTotal
                      liabilityTotals={props.liabilityTotals}
                    />
                  )}

                  {Boolean(
                    props.filter.getValue("eventType") === OPEN_SHIPMENTS,
                  ) && (
                    <DriverLiabilityOpenShipmentTotal
                      getCashierOpenLiabilityTotals={
                        getCashierOpenLiabilityTotals
                      }
                      driverId={props.driverId}
                    />
                  )}
                </FlexBox>
              )}

              {props.filter.getValue("eventType") === COD_PENDING && (
                <DriverLiabilityStatusTabs
                  location={props.location}
                  statusCounts={props.statusCounts}
                />
              )}

              {props.filter.getValue("eventType") === COD_PENDING && (
                <FlexBox flex={true}>
                  <DriverLiabilityCODPendingTable
                    onRowSelect={props.onRowSelect}
                    selectedItems={props.selectedItems}
                    altHeader={
                      <FlexBox>
                        <Button
                          onClick={() =>
                            props.replaceLocationHash(
                              BATCH_UPDATE_LIABILITY_TOTAL_DIALOG_HASH,
                            )
                          }
                        >
                          {" "}
                          {getLocalisationMessage("update", "Update")}{" "}
                        </Button>
                      </FlexBox>
                    }
                    isLoading={
                      props.isLoadingLiability || props.isLoadingLiabilityTotals
                    }
                    list={props.driverId > 0 ? props.liabilityList : new List()}
                    total={props.driverId > 0 ? props.liabilityListTotal : 0}
                    filter={props.filter}
                    onFilterChange={props.setLocationQueryFilter}
                  />
                </FlexBox>
              )}

              {props.filter.getValue("eventType") === OPEN_SHIPMENTS && (
                <FlexBox flex={true}>
                  <DriverLiabilityOpenShipmentsTable
                    isLoading={
                      props.isLoadingOpenLiability ||
                      props.isLoadingLiabilityOpenTotals
                    }
                    list={
                      props.driverId > 0 ? props.liabilityOpenList : new List()
                    }
                    total={
                      props.driverId > 0 ? props.liabilityListOpenTotal : 0
                    }
                    filter={props.filter.setValue("status", null)}
                    onFilterChange={props.setLocationQueryFilter}
                  />
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDriverLiabilityContainer);
