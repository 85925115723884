import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LinkButton from "../ui-core/LinkButton";
import PriceWrapper from "../ui-core/PriceWrapper";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { URGENT } from "../../constants/CourierTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    defaultValue: {
      color: "#888",
      fontSize: "80%",
    },
  }),
);

PackagePriceListItemNew.propTypes = {
  sheet: PropTypes.object,
  item: PropTypes.instanceOf(Map),
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function PackagePriceListItemNew(props) {
  const { sheet: { classes }, getLocalisationMessage } = props;

  if (!props.item) {
    return (
      <LinkButton onClick={props.onClick}>
        {getLocalisationMessage("na", "N/A")}{" "}
      </LinkButton>
    );
  }

  const packagePrice = props.item.get("package_price");
  const courierType = fp.toLower(
    props.item.getIn(["package_type", "courier_type"]),
  );

  return (
    <LinkButton onClick={props.onClick}>
      {courierType === URGENT ? (
        <span>
          <span>
            <PriceWrapper price={packagePrice.get("base")} />
          </span>
          <br />
          <span className={classes.defaultValue}>
            {formatNumber(packagePrice.get("km_included"))}{" "}
            {getLocalisationMessage("km_included", "km included")}
          </span>
          <br />
          <span className={classes.defaultValue}>
            {formatNumber(packagePrice.get("per_km"))}{" "}
            {getLocalisationMessage("per_km", "per km")}
          </span>
        </span>
      ) : (
        <span>
          <span>
            <PriceWrapper price={packagePrice.get("base")} />
          </span>
          <br />
          <span className={classes.defaultValue}>
            {formatNumber(packagePrice.get("per_kg"))}{" "}
            {getLocalisationMessage("per_kg", "per kg")}
          </span>
          <br />
          <span className={classes.defaultValue}>
            {formatNumber(packagePrice.get("rto_base"))}{" "}
            {getLocalisationMessage("for_rto", "for RTO")}
          </span>
          <br />
          <span className={classes.defaultValue}>
            {formatNumber(packagePrice.get("rto_per_kg"))}{" "}
            {getLocalisationMessage("for_rto_per_kg", "for RTO per kg")}
          </span>
        </span>
      )}
    </LinkButton>
  );
}

export default enhancer(PackagePriceListItemNew);
