import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SHOP_MENU_LIST_URL } from "../../constants/AdminPathConstants";
import { getMenuItem, updateMenuItem } from "../../api/admin/AdminShopMenuApi";
import {
  getCachedVenueCategory,
  getVenueCategoryPredictions,
} from "../../api/admin/AdminVenueCategoryApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminShopMenuForm from "../../components/admin/AdminShopMenuForm";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func }),
  connect(null, { showErrorMessage, showSuccessMessage }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const menuIdStream = propsStream.map(
          fp.flow(fp.get("params.id"), fp.toFinite),
        );

        return propsStream
          .combineLatest(menuIdStream, (props, menuId) => ({
            ...props,
            menuId,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const menuItemResponseStream = propsStream
          .distinctUntilKeyChanged("menuId")
          .switchMap(props =>
            getMenuItem(props.menuId).catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(menuItemResponseStream, (props, menuItemResponse) => ({
            ...props,
            menuItem: menuItemResponse.get("payload"),
            isLoading: menuItemResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminShopMenuItem.propTypes = {
  isLoading: PropTypes.bool,
  menuId: PropTypes.number,
  menuItem: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
};

function AdminShopMenuItem(props) {
  return (
    <AdminAppLayout
      title={
        props.isLoading
          ? "Category"
          : `Menu - ${props.menuItem.get("name", "")}`
      }
    >
      <PageLoading isLoading={props.isLoading} />
      <AdminShopMenuForm
        onSubmit={values =>
          updateMenuItem(props.menuId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.setLocation(SHOP_MENU_LIST_URL);
        }}
        onSubmitFail={props.showErrorMessage}
        onDismissClick={() => props.setLocation(SHOP_MENU_LIST_URL)}
        initialValues={{
          ...toCamelCase(props.menuItem),
          status: _.lowerCase(props.menuItem.get("status")),
        }}
        getCachedVenueCategory={getCachedVenueCategory}
        getVenueCategoryPredictions={getVenueCategoryPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminShopMenuItem);
