import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Tab, Tabs, CircularProgress, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { indigo } from "@material-ui/core/colors";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData, isEqualDataKeys } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { mergeFilterToFilter } from "../../helpers/FilterHelper";
import { defaultAlertTabFilters } from "../../helpers/AlertsHelpers";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Refresh } from "@material-ui/icons";

const styles = {
  tabsInkBar: {
    zIndex: 2,
    height: "4px",
    marginTop: "-4px",
    backgroundColor: indigo[500],
  },
};

const baseFilter = fromJS({
  page: 0,
  size: 1,
  use_solr: false,
});

const getTabName = fp.flow(
  fp.split("?"),
  fp.last,
  fp.split("&"),
  fp.map(fp.split("=")),
  (x) => {
    let tab;

    fp.forEach((v) => {
      if (fp.first(v) === "driver_availability") {
        tab = fp.last(v);
      }
    })(x);

    return tab || "ALL";
  },
  decodeURI,
);

const enhancer = compose(
  connect((state) => ({
    i18n: getMessages(state),
  })),
  useSheet({
    tabs: {
      width: "100%",
    },
    loader: {
      height: "48px",
      backgroundColor: "transparent",
      margin: "auto",
    },
    hidden: {
      top: "-9999px",
      left: "-9999px",
      position: "absolute",
      visibility: "hidden",
    },
    buttonStyle: {
      backgroundColor: "white",
      color: "black",
      borderBottom: "1px solid #eee",
      "& > div > div": {
        position: "relative",
        paddingRight: "10px",
        "& > div": {
          "& span": {
            position: "absolute",
            top: "10px",
            backgroundColor: indigo[500],
            color: "white",
          },
        },
      },
    },
    active: {
      borderBottom: `3px solid ${indigo[500]}`,
    },
  }),
  withRouter,
  mapPropsStream(
    pipeStreams((propsStream) => {
      const tabsStream = propsStream
        .distinctUntilChanged(isEqualDataKeys(["filter", "basePathname"]))
        .map((props) => {
          const filters = defaultAlertTabFilters.map((x) => {
            const filter = new DataListFilter(x.get("filter").toJS());
            const queryFilter = props.filter.setValue(
              "driver_availability",
              x.get("name"),
            );

            return mergeFilterToFilter(
              filter,
              queryFilter.setValueMap({
                driver_availability: x.get("name"),
                duty_status: null,
                status: "ACTIVE",
              }),
            );
          });

          let activeTab = null;
          filters.forEach((x) => {
            if (
              x.getValue("driver_availability") ===
              (props.router.location.query.driver_availability || "ALL")
            ) {
              activeTab = x;
            }
          });

          return {
            filters,
            activeTab,
            batchGetAlertListCount: props.batchGetAlertListCount,

            value: getTabName(props.router.location.search),

            tabs: filters.map((filter) => {
              const href = props.router.createHref({
                pathname: props.basePathname,
                query: filter.getDefinedValues(),
              });

              const value = getTabName(href);
              return Map({
                label: props.i18n.get(fp.lowerCase(value), fp.snakeCase(value)),
                value,
                href,
                filter,
              });
            }),
          };
        })
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(tabsStream, (props, tabResponse) => ({
        ...props,
        tabs: tabResponse.tabs,
        filters: tabResponse.filters,
        value: tabResponse.value,
        activeTab: tabResponse.activeTab,
        batchGetAlertListCount: tabResponse.batchGetAlertListCount,
      }));
    }),
  ),
  mapPropsStream((propsStream) => {
    const tabCountsStream = propsStream
      .map(fp.pick(["filters", "getDriverList", "batchGetAlertListCount"]))
      .distinctUntilChanged(isEqualData)
      .switchMap((props) =>
        !props.getDriverList && !props.batchGetAlertListCount
          ? Observable.of(Map())
          : props.batchGetAlertListCount(
              props.filters.map((x) => ({
                getDriverList: props.getDriverList,
                filter: x.setValueMap(baseFilter),
              })),
            ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(tabCountsStream, (props, tabCounts) => ({
        ...props,
        value: props.value,
        tabs: props.tabs.map((tab, label) =>
          tab.set("count", tabCounts.get(label)),
        ),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

RadarDriverFilterTabs.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  batchGetOrderListCount: PropTypes.func,

  filterList: PropTypes.instanceOf(OrderedMap),
  tabs: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.instanceOf(OrderedMap),
  ]),
  filters: PropTypes.instanceOf(List),

  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  activeTab: PropTypes.instanceOf(DataListFilter),

  isLoading: PropTypes.bool,
  onFilterClick: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),

  showButtons: PropTypes.object,
  buttonNodeRef: PropTypes.func,
  containerNodeRef: PropTypes.func,
  batchGetAlertListCount: PropTypes.func,
  onListRequestRefresh: PropTypes.func,
};

function RadarDriverFilterTabs(props) {
  const { classes } = props;

  if (props.isLoading)
    return (
      <FlexBox flex={true}>
        <FlexBox
          align="center"
          justify={JUSTIFY_CENTER}
          className={classes.loader}
        >
          <CircularProgress color="#2E3359" size={24} thickness={2} />
        </FlexBox>
      </FlexBox>
    );

  return (
    <FlexBox flex={true}>
      <FlexBox flex={true}>
        <Tabs
          className={classes.tabs}
          inkBarStyle={styles.tabsInkBar}
          onChange={(e, v) => props.onFilterClick(v)}
          value={props.activeTab}
        >
          {props.tabs
            .map((x, key) => (
              <Tab
                key={key}
                value={x.get("filter")}
                label={
                  <TabBadge label={x.get("label")} badge={x.get("count")} />
                }
                className={classes.buttonStyle}
                buttonStyle={{
                  lineHeight: "24px",
                }}
              />
            ))
            .toList()}
        </Tabs>
        <IconButton onClick={props.onListRequestRefresh}>
          <Refresh />
        </IconButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(RadarDriverFilterTabs);
