import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UploadVehicleForm from "./UploadVehicleForm";
import ModalPaper from "../ui-core/ModalPaper";
import { renderIf } from "../../helpers/HOCUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { uploadVehicle } from "../../api/admin/AdminVehicleApi";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "400px",
      minHeight: "auto",
    },
  }),
  withState("submitting", "onSubmitting", false),
);

UploadVehicleDialog.propTypes = {
  classes: PropTypes.object,
  onRequestClose: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  submitting: PropTypes.bool,
  onSubmitting: PropTypes.func,
};

function UploadVehicleDialog(props) {
  const { i18n, classes } = props;
  return (
    <ModalPaper
      open={true}
      title={i18n.get("upload_vehicles", "Upload Vehicles")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <UploadVehicleForm
        loading={props.submitting}
        onDismiss={props.onRequestClose}
        onSubmit={fp.flow(
          values => {
            props.onSubmitting(true);
            return uploadVehicle(values.file);
          },
          request => request.toPromise().catch(ResponseError.throw),
        )}
        onSubmitFail={error => {
          props.onSubmitting(false);
          props.showErrorMessage(error);
        }}
        onSubmitSuccess={() => {
          props.onSubmitting(false);
          props.showSuccessMessage(
            i18n.get(
              "vehicles_uploaded_successfully",
              "Vehicles Uploaded Successfully!",
            ),
          );
          return props.onRequestClose();
        }}
      />
    </ModalPaper>
  );
}

export default enhancer(UploadVehicleDialog);
