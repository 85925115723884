import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { List } from "immutable";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { blue } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import TablePagination from "@material-ui/core/TablePagination";
import { LinearProgress } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";

const useRowStyles = makeStyles({
  root: {
    borderBottom: "1px solid #ccc",
  },
  childTable: {
    width: "100%",
    borderBottom: "1px solid #ccc",
  },
  childRow: {
    background: "rgba(0,0,0, 0.1)",
  },
  container: {
    height: "calc(100vh - 12.3rem)",
  },
  badge: {
    width: 20,
    height: 20,
    marginRight: 10,

    "& > span": {
      top: "50%",
      color: "white",
      backgroundColor: blue[500],
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

function RenderEnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={index === 0 ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

RenderEnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
};

function Row(props) {
  const {
    row,
    parent,
    getLocalisationMessage,
    columns,
    childItems,
    fetchChildren,
    parentId,
  } = props;
  const [open, onToggle] = React.useState(false);
  const classes = useRowStyles();

  const rowId = _.get(row, "id");
  const handleToggle = id => {
    if (open === false) fetchChildren(id);

    onToggle(!open);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {columns.map(column => {
          if (_.get(column, "name") === "id") {
            return (
              <TableCell
                style={{ width: _.get(column, "width") }}
                component="td"
                scope="row"
              >
                <FlexBox align={ALIGN_CENTER}>
                  <strong>{parentId || _.get(row, "id")}</strong>{" "}
                  {_.get(row, "count_of_replies", 0) > 0 && (
                    <div>
                      <Badge
                        color="primary"
                        badgeContent={_.get(row, "count_of_replies", 0)}
                        className={classes.badge}
                      />
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleToggle(_.get(row, "id"))}
                      >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </div>
                  )}
                </FlexBox>
              </TableCell>
            );
          }

          return (
            <TableCell
              style={{ width: _.get(column, "width") || "auto" }}
              align="center"
            >
              {_.get(column, "render", null)
                ? column.render({ row, parentId, parent })
                : ""}
            </TableCell>
          );
        })}
      </TableRow>

      {_.get(row, "count_of_replies", 0) > 0 && (
        <TableRow className={classes.childRow}>
          <TableCell style={{ padding: 0 }} colSpan={columns.length}>
            <Collapse in={open} timeout="auto" unmountOnExit={true}>
              <Box margin={0}>
                <Table
                  size="small"
                  aria-label="purchases"
                  className={classes.childTable}
                >
                  <TableBody>
                    {childItems[rowId] ? (
                      childItems[rowId].map((reply, index) => (
                        <Row
                          parentId={rowId}
                          parent={row}
                          columns={columns}
                          key={index}
                          row={reply}
                          childItems={childItems}
                          getLocalisationMessage={getLocalisationMessage}
                          loadingChilds={false}
                          fetchChildren={fetchChildren}
                        />
                      ))
                    ) : (
                      <LinearProgress
                        style={{ position: "absolute", width: "100%" }}
                        mode="indeterminate"
                        color="secondary"
                      />
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

Row.propTypes = {
  columns: PropTypes.array,
  childItems: PropTypes.array,
  row: PropTypes.object,
  parent: PropTypes.object,
  parentId: PropTypes.number,
  fetchChildren: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

MUITableActList.propTypes = {
  withoutPagination: PropTypes.bool,
  columns: PropTypes.array,
  childItems: PropTypes.array,
  list: PropTypes.instanceOf(List),
  page: PropTypes.number,
  total: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  fetchChildren: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function MUITableActList(props) {
  const {
    list,
    page,
    total,
    columns,
    childItems,
    rowsPerPage,
    fetchChildren,
    handleChangePage,
    withoutPagination,
    getLocalisationMessage,
    handleChangeRowsPerPage,
  } = props;
  const classes = useRowStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="collapsible table">
        <RenderEnhancedTableHead headCells={columns} />

        <TableBody>
          {list.map((row, index) => (
            <Row
              columns={columns}
              key={index}
              row={row}
              childItems={childItems}
              getLocalisationMessage={getLocalisationMessage}
              loadingChilds={false}
              fetchChildren={fetchChildren}
            />
          ))}
        </TableBody>

        {!withoutPagination && (
          <TablePagination
            style={{
              position: "fixed",
              background: "white",
              bottom: 0,
              width: "100vw",
              borderTop: "1px solid rgba(0, 0, 0, 0.3)",
            }}
            labelRowsPerPage={getLocalisationMessage("rows_per_page")}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
        )}
      </Table>
    </TableContainer>
  );
}

export default enhancer(MUITableActList);
