import React from "react";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = getContext({
  getCachedPostcode: PropTypes.func.isRequired,
  getPostcodePredictions: PropTypes.func.isRequired,
});

FormNeighborhoodChips.propTypes = {
  getCachedPostcode: PropTypes.func,
  getPostcodePredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  includeUnknownNeighborhood: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
};

FormNeighborhoodChips.defaultPropTypes = {
  includeUnknownNeighborhood: false,
};

function FormNeighborhoodChips({
  getCachedPostcode,
  getPostcodePredictions,
  includeUnknownNeighborhood,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      includeUnknown={includeUnknownNeighborhood}
      getValue={getCachedPostcode}
      getPredictions={searchText =>
        getPostcodePredictions(
          baseFilter
            .setSearch(searchText)
            .setValue(
              "include_unknown_neighborhood",
              includeUnknownNeighborhood,
            ),
        )
      }
    />
  );
}

export default enhancer(FormNeighborhoodChips);
