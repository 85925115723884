import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const MENU_CREATE_URL = `${SHOP_API_ROOT_URL}/admin/menu`;
const MENU_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/menu/%s`;
const MENU_LIST_URL = `${SHOP_API_ROOT_URL}/admin/menu/list`;
const MENU_BATCH_DELETE_URL = `${SHOP_API_ROOT_URL}/admin/menu`;

export type MenuItemRequest = {
  count: number,
  parent: {},
  id: number,
  name: string,
  sorder: number,
  status: string,
  image: string,
};

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(MENU_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(MENU_LIST_URL, { params: request.getDefinedValues() }),
});

export const getMenuItem = id => itemCache.refresh(id);
export const getCachedVenueMenu = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getVenueMenuPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getMenuList = (request: DataListFilter) =>
  api.getStream(MENU_LIST_URL, { params: request.getDefinedValues() });
export const createMenuItem = (body: MenuItemRequest) =>
  api.post(MENU_CREATE_URL, { body });
export const updateMenuItem = (id, body: MenuItemRequest) =>
  api.put(sprintf(MENU_ITEM_URL, id), { body });
export const removeMenu = ids =>
  api.delete(MENU_BATCH_DELETE_URL, { params: { ids } });
