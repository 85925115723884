import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { List, Map, Set } from "immutable";
import { makeStyles } from "@material-ui/core/styles";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withHandlers,
} from "recompose";
import { pureComponent } from "../../helpers/HOCUtils";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FirebaseOfflineDialog from "../../components/firebase/FirebaseOfflineDialog";
import FlexBox from "../../components/ui-core/FlexBox";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getOrderCustomsSortingTask,
  updateCustomsSortingTask,
} from "../../reducers/OrderCustomsSortingReducer";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import {
  getUserWarehouse,
  getUserWarehouseId,
  getUserWarehousesIds,
} from "../../reducers/ProfileReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { Observable } from "rxjs";
import fp from "lodash/fp";
import { validateUserWarehouse } from "../../helpers/OrderSortingHelper";
import FormWarehouseDialog from "../../components/form/FormWarehouseDialog";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { GlobalContext } from "../shared/ClientApp";
import { Card, CardContent, IconButton } from "@material-ui/core";
import OrderCustomsScanForm from "../../components/order-customs-sorting/OrderCustomsScanForm";
import OrderCustomsSortingTable from "../../components/order-customs-sorting/OrderCustomsSortingTable";
import { CONTAINED, SECONDARY } from "../../components/ui-core/CustomButton";
import { mergeSideEffectStreams, pipeStreams } from "../../helpers/StreamUtils";
import { OrderCustomsOutboundSortingDB } from "../../firebase/OrderCustomsOutboundSortingDB";
import {
  batchAsyncUpdateOrder,
  getOrderObject,
} from "../../api/admin/AdminOrderApi";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isExpiredOrderRecord } from "../../helpers/OrderOutboundSortingHelper";
import { captureException } from "../../helpers/ErrorTracker";
import DataListFilter from "../../helpers/DataListFilter";
import FormDialog from "../../components/form/FormDialog";
import ResponseError from "../../helpers/ResponseError";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { SENT_TO_CUSTOMS } from "../../constants/OrderStatusCodes";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ORDER_CUSTOMS_LIST_VIEW_URL } from "../../constants/AdminPathConstants";
import ConfirmationButton from "../../components/ui-core/ConfirmationButton";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  scanner: {
    marginBottom: 8,
    overflow: "visible",
  },
  content: {
    display: "flex",
    flex: "1 1 100%",
  },
  scannerRow: {
    paddingTop: "17px",
  },
  finishButtonWrapper: {
    alignItems: "flex-start",
  },
  finishButton: {
    width: "100%",
  },
  orders: {
    minHeight: 600,
  },
}));

const baseFilter = new DataListFilter({
  size: 200,
  is_uae: true,
  simple: true,
  use_solr: true,
  include_dw: true,
  search_type: "order_number",
});

const enhancer = compose(
  connect(
    (state) => {
      const task = getOrderCustomsSortingTask(state);
      const userWarehouse = toJS(getUserWarehouse(state) || Map());

      return {
        userWarehouse,
        warehouseId: task.getIn(["warehouse", "id"]),
        userWarehouseId: getUserWarehouseId(state),
        userWarehousesIds: getUserWarehousesIds(state),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      updateCustomsSortingTask,
    },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  withHandlers({
    scanBarcodes:
      (props) =>
      ({ orderNumbers }) =>
        getOrderObject(baseFilter.setValue("barcodes", orderNumbers[0]))
          .takeLast(1)
          .let(mapObjectResponseStream)
          .map((x) => x.getIn(["payload"]))
          .switchMap((data) => {
            const db = new OrderCustomsOutboundSortingDB(props.warehouseId);
            const orders = data.get("orders", List());
            const numbers = Set(orderNumbers);

            const values = {};
            const notLoaded = numbers.asMutable();

            orders.forEach((order) => {
              const orderNumber = order.get("barcode");

              notLoaded.delete(orderNumber);

              if (numbers.has(orderNumber)) {
                values[`${orderNumber}/failed`] = null;
                values[`${orderNumber}/info`] = order.toJS();
                values[`${orderNumber}/hash`] = order.hashCode();
                values[`${orderNumber}/hash_time`] = Date.now();
                values[`${orderNumber}/scanned_time`] = Date.now();
              }
            });

            notLoaded.forEach((orderNumber) => {
              values[`${orderNumber}/failed`] = true;
            });

            // Add the Order to the Firebase
            return db.batchUpdateOrders(values);
          }),

    reloadOrders: (props) => (orderNumbers) => {
      const numbers = Set(orderNumbers);

      return getOrderObject(baseFilter.setValue("barcodes", numbers.join(",")))
        .takeLast(1)
        .let(mapObjectResponseStream)
        .map((x) => x.getIn(["payload"]))
        .switchMap((data) => {
          const orders = data.get("orders", List());

          const db = new OrderCustomsOutboundSortingDB(props.warehouseId);

          const values = {};
          const notLoaded = numbers.asMutable();

          orders.forEach((order) => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values[`${orderNumber}/failed`] = null;
              values[`${orderNumber}/info`] = order.toJS();
              values[`${orderNumber}/hash`] = order.hashCode();
              values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          notLoaded.forEach((orderNumber) => {
            values[`${orderNumber}/failed`] = true;
          });

          return db.batchUpdateOrders(values);
        });
    },
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const dbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .map((props) => new OrderCustomsOutboundSortingDB(props.warehouseId));

        const initialState = {
          tasks: Map(),
          orders: Map(),
        };

        const stateStream = dbStream
          .switchMap((db: OrderCustomsOutboundSortingDB) =>
            !db.warehouseId
              ? Observable.of(initialState)
              : Observable.combineLatest(
                  db.getTasks(),
                  db.getOrders(),
                  (tasks, orders) => ({
                    tasks,
                    orders,
                  }),
                ).startWith(initialState),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          ...state,
        }));
      },

      (propsStream) => {
        const { handler: onOrderSubmit, stream: onOrderSubmitStream } =
          createEventHandler();

        const sideEffects = mergeSideEffectStreams(
          propsStream
            .filter((props) => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) => {
              const db = new OrderCustomsOutboundSortingDB(props.warehouseId);

              return mergeSideEffectStreams(
                onOrderSubmitStream.mergeMap(({ orderNumbers }) => {
                  const orderValues = {};

                  orderNumbers.forEach((orderNumber) => {
                    orderValues[`${orderNumber}/number`] = orderNumber;
                    orderValues[`${orderNumber}/scanned`] = true;
                  });

                  return Observable.merge(db.batchUpdateOrders(orderValues));
                }),
                onOrderSubmitStream.switchMap((request) =>
                  props
                    .scanBarcodes(request)
                    .take(1)
                    .switchMap(() => Observable.of({})),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffects).map((props) => ({
          ...props,
          onOrderSubmit,
        }));
      },

      (propsStream) => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter((props) => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) => {
              const db = new OrderCustomsOutboundSortingDB(props.warehouseId);

              const addStream = db.getOrderAddStream();
              const changeStream = db.getOrderChangeStream();

              const addOrChangeStream = Observable.merge(
                addStream,
                changeStream,
              );

              return mergeSideEffectStreams(
                Observable.of(null).expand(() =>
                  addOrChangeStream
                    .delay(10 * 1000)
                    .filter((x) => !x.get("failed") && isExpiredOrderRecord(x))
                    .bufferTime(1000, null, 100)
                    .filter((x) => x.length > 0)
                    .take(1)
                    .switchMap((buffer) =>
                      props.reloadOrders(buffer.map((x) => x.get("number"))),
                    )
                    .catch((error) => {
                      captureException(error);

                      return Observable.of(null);
                    })
                    .delay(1000),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream);
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "userWarehouse",
      "userWarehouseId",
      "userWarehousesIds",
      "warehouseId",
      "location",
      "state",
      "task",
      "scannedBarcode",
      "orders",
    ]),
  ),
);

AdminCustomsOutboundContainer.propTypes = {
  warehouseId: PropTypes.number,
  userWarehouseId: PropTypes.number,

  location: PropTypes.object,
  userWarehouse: PropTypes.object,

  userWarehousesIds: PropTypes.array,

  task: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),

  setLocation: PropTypes.func.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  onOrderSubmit: PropTypes.func.isRequired,
  updateCustomsSortingTask: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  updateSortingTask: PropTypes.func,
};

function AdminCustomsOutboundContainer(props) {
  const { setUW } = useContext(GlobalContext);
  const {
    getLocalisationMessage,
    orders,
    location: { query },
  } = props;
  const classes = useStyles();

  const [removeOrder, setRemoveOrder] = useState(null);

  if (!validateUserWarehouse(props.warehouseId, props.userWarehousesIds)) {
    return (
      <FormWarehouseDialog
        open={true}
        isDisabled={!fp.size(props.userWarehousesIds) > 1}
        initialValues={{ warehouse: props.userWarehouse }}
        includeWarehouses={props.userWarehousesIds}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        onSubmit={(values) => {
          setUW(fp.get("warehouse.id", values));
          props.updateCustomsSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  const db = new OrderCustomsOutboundSortingDB(props.warehouseId);

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "customs_inbound_sorting",
        "Customs Inbound Sorting",
      )}
    >
      <FirebaseOfflineDialog />

      {removeOrder && (
        <FormDialog
          open={true}
          onRequestClose={() => setRemoveOrder(null)}
          onSubmit={() =>
            db.removeOrder(removeOrder).toPromise().catch(ResponseError.throw)
          }
          onSubmitSuccess={() => setRemoveOrder(null)}
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_the_order_from_queue",
            "Are you sure you want to remove the order from queue?",
          )}
        </FormDialog>
      )}

      {fp.toFinite(query.view) > 0 && (
        <AdminOrderDetailsDialogWrapperV2
          tab={query.view_order_tab}
          orderId={fp.toFinite(query.view)}
          onTabChange={(x) =>
            props.setLocationQuery(fp.set("view_order_tab", x))
          }
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(fp.unset("view"), fp.unset("view_order_tab")),
            )
          }
          location={props.location}
        />
      )}

      {fp.toFinite(query.batch_id) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(query.batch_id)}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("batch_id"));
            props.setLocation(updateQuery(ORDER_CUSTOMS_LIST_VIEW_URL));
          }}
        />
      )}

      <FlexBox flex={true} direction="column" className={classes.root}>
        <FlexBox element={<Card />} className={classes.scanner}>
          <CardContent className={classes.content}>
            <FlexBox flex={true}>
              <FlexBox>
                <div>
                  <IconButton
                    onClick={(event) => {
                      event.preventDefault();
                      return props.setLocation(
                        updateQuery(ORDER_CUSTOMS_LIST_VIEW_URL),
                      );
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                </div>
              </FlexBox>
              <FlexBox flex={true}>
                <OrderCustomsScanForm
                  focusInput={false}
                  onSubmit={(x) => {
                    props.onOrderSubmit({ orderNumbers: x });
                  }}
                />
              </FlexBox>
              <FlexBox flex={true} className={classes.scannerRow}>
                <FlexBox flex={true}>
                  {getLocalisationMessage("total_orders", "Total Orders")}{" "}
                  {orders.size}
                </FlexBox>
                <FlexBox flex={true} className={classes.finishButtonWrapper}>
                  {orders.size > 0 && (
                    <ConfirmationButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      size="large"
                      fullWidth={true}
                      buttonClass={classes.finishButton}
                      buttonLabel={getLocalisationMessage(
                        "finish_and_send_to_customs_authority",
                        "Finish and Send to Customs Authority",
                      )}
                      onConfirm={() => {
                        const request = {
                          order_status: SENT_TO_CUSTOMS,
                          warehouse: { id: props.warehouseId },
                          order_barcodes: orders.keySeq().toArray(),
                          privacy: PUBLIC,
                        };

                        return batchAsyncUpdateOrder(request)
                          .catch((error) => props.showErrorMessage(error))
                          .then((response) => {
                            const batchId = fp.get("data.id", response);
                            if (batchId) {
                              // TODO the orders from the list
                              props.setLocationQuery(
                                fp.flow(fp.set("batch_id", batchId)),
                              );

                              return db
                                .clearOrders()
                                .toPromise()
                                .catch(ResponseError.throw);
                            }

                            return null;
                          });
                      }}
                    >
                      {getLocalisationMessage(
                        "please_note_that_you_can_not_change_this_after_confirmation",
                        "Please note that You can not change this after confirmation",
                      )}
                    </ConfirmationButton>
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </CardContent>
        </FlexBox>

        <FlexBox element={<Card />} flex={true} className={classes.orders}>
          <OrderCustomsSortingTable
            orders={orders}
            showAllButton={false}
            onOrderClick={(x) => props.setLocationQuery(fp.set("view", x))}
            selectedOrders={Map()}
            onOrdersSelect={(numbers) =>
              props.updateSortingTask((x) =>
                x.set("selectedRegistries", numbers),
              )
            }
            onRemoveClick={(x) => setRemoveOrder(x)}
            onReloadClick={(x) => {
              db.batchReloadOrders([x])
                .toPromise()
                .catch(props.showErrorMessage);
            }}
            cardActionIcons={<FlexBox align="center" />}
          />
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomsOutboundContainer);
