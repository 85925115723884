import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { connect } from "react-redux";
import { Cancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatText, formatDateToUrl } from "../../helpers/FormatUtils";
import { isValidDate } from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ALL,
  NEW,
  ERROR,
  SUCCESS,
  ActivityHistoryLogStatus,
} from "../../constants/ActivityHistoryStatus";
import { Card, CardActions, CardHeader, IconButton } from "@material-ui/core";
import CustomButton from "../ui-core/CustomButton";

const formatActivityHistoryLogStatus = (code, getLocalisationMessage) => {
  switch (code) {
    case NEW:
      return getLocalisationMessage("new", "New");

    case SUCCESS:
      return getLocalisationMessage("success", "Success");

    case ERROR:
      return getLocalisationMessage("error", "Error");

    case ALL:
      return getLocalisationMessage("all", "All");

    default:
      return (
        getLocalisationMessage && getLocalisationMessage(code, formatText(code))
      );
  }
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;
        return {
          fromDate: safeParseDate(x.getValue("from_date")),
          toDate: safeParseDate(x.getValue("to_date")),
          smsLogStatus: x.getValue("smsLogStatus"),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            from_date: formatDateToUrl(values.fromDate),
            to_date: formatDateToUrl(values.toDate),
            smsLogStatus: values.smsLogStatus,
          });
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "ActivityHistoryFilterForm",
    enableReinitialize: true,
  }),
  formValues({
    fromDate: "fromDate",
    toDate: "toDate",
  }),
  pureComponent(fp.pick(["dirty"])),
);

ActivityHistoryFilterForm.propTypes = {
  change: PropTypes.func,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onDismiss: PropTypes.func,
  values: PropTypes.object,
  onFilterChange: PropTypes.func,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  fromDate: PropTypes.any,
  toDate: PropTypes.any,

  getLocalisationMessage: PropTypes.func,
};

function ActivityHistoryFilterForm(props) {
  const {
    getLocalisationMessage,
    handleSubmit,
    dirty,
    onDismiss,
    reset,
  } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardHeader>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      fullWidth={true}
                      name="fromDate"
                      hintText={getLocalisationMessage(
                        "from_date",
                        "From Date",
                      )}
                    />
                  </FlexBox>

                  {Boolean(isValidDate(props.fromDate)) && (
                    <FlexBox align="center">
                      <IconButton
                        onClick={() => props.change("fromDate", null)}
                      >
                        <Cancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="toDate"
                    hintText={getLocalisationMessage("to_date", "To Date")}
                  />
                </FlexBox>

                {Boolean(isValidDate(props.toDate)) && (
                  <FlexBox align="center">
                    <IconButton onClick={() => props.change("toDate", null)}>
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormSelectField
                      name="smsLogStatus"
                      floatingLabelText={getLocalisationMessage(
                        "activity_history_status",
                        "Activity History Status",
                      )}
                      options={ActivityHistoryLogStatus}
                      formatOption={option =>
                        formatActivityHistoryLogStatus(
                          option,
                          getLocalisationMessage,
                        )
                      }
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </CardHeader>
          <CardActions>
            <FlexBox justify="flex-end">
              {dirty ? (
                <CustomButton
                  label={getLocalisationMessage("reset", "Reset")}
                  onClick={reset}
                />
              ) : (
                Boolean(onDismiss) && (
                  <CustomButton
                    label={getLocalisationMessage("dismiss", "Dismiss")}
                    onClick={onDismiss}
                  />
                )
              )}
              <CustomButton
                label={getLocalisationMessage("clear", "Clear")}
                onClick={() => {
                  fp.keys(props.initialValues).forEach(key => {
                    props.change(key, null);
                  });
                }}
              />
              <CustomButton
                label={getLocalisationMessage("submit", "Submit")}
                type="submit"
              />
            </FlexBox>
          </CardActions>
        </Card>
      </form>
    </div>
  );
}

export default enhancer(ActivityHistoryFilterForm);
