import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";

const VOICE_LIST_URL = `${API_V2_ROOT_URL}/dashboard/order/voice-sorting`;
const VOICE_CREATE_URL = `${API_V2_ROOT_URL}/dashboard/order/voice-sorting`;
const VOICE_LOOKUP_URL = `${API_V2_ROOT_URL}/dashboard/order/voice-sorting/choose`;
const VOICE_ITEM_URL = `${API_V2_ROOT_URL}/dashboard/order/voice-sorting/%s`;
const VOICE_ITEM_REMOVE = `${API_V2_ROOT_URL}/dashboard/order/voice-sorting/%s`;
import { fromJS } from "immutable";

// const ADDRESS_LIST_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/address`;
// const ADDRESS_CREATE_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/address`;
// const ADDRESS_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/address/%s`;

const voiceCache = new ObservableCache({
  loader: id =>
    api.get(sprintf(VOICE_ITEM_URL, id)).then(fp.flow(fp.get("data"), fromJS)),
});

export const getCachedVoice = id => voiceCache.get(id);

export const getVoicePredictions = (request: DataListFilter) =>
  api.getStream(VOICE_LOOKUP_URL, { params: request.getDefinedValues() });

export const getVoiceList = (filter: DataListFilter) =>
  api.getStream(VOICE_LIST_URL, {
    params: fp.pick(["page", "size", "search"], filter.getDefinedValues()),
  });

export const getVoiceLookup = search =>
  api.getStream(VOICE_LOOKUP_URL, { params: { search } });

export const removeVoice = voiceId =>
  api.delete(sprintf(VOICE_ITEM_REMOVE, voiceId));

export const createVoice = voice =>
  api.putStream(VOICE_CREATE_URL, { body: voice });
