import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { fade } from "../../helpers/FormatUtils";

const enhancer = compose(
  useSheet({
    info: {
      marginRight: "15px",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      paddingLeft: "8px",
      paddingRight: "8px",
      borderRadius: "1px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "1.3",
      textAlign: "center",
      minHeight: "40px",
    },
    active: {
      color: "#ffffff",
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderStatusInfo.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.string,
  color: PropTypes.string,
  isActiveOrder: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function OrderStatusInfo(props) {
  const { classes, isActiveOrder } = props;

  const backgroundColor = isActiveOrder
    ? fade(props.color, 0.1)
    : fade(props.color, 0.1);
  const color = isActiveOrder ? "#fff" : props.color;

  return (
    <div
      className={cx(classes.info, {
        [classes.activeRoot]: isActiveOrder,
      })}
      style={{ backgroundColor, color }}
    >
      {formatOrderStatusCodeForLocalisation(
        props.status,
        props.getLocalisationMessage,
      )}
    </div>
  );
}

export default enhancer(OrderStatusInfo);
