import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import MarketplaceTypeComponent from "./MarketplaceTypeComponent";
import DataList, { DataListColumn } from "../data-list/DataList";
import { wordsTo, formatDate, formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { SETTINGS_MARKETPLACE_ITEM_URL } from "../../constants/AdminPathConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
  }),
);

MarketplacesList.propTypes = {
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  totalCount: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  overscanRowCount: PropTypes.number,
  rowCount: PropTypes.number,
  rowGetter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func,
};

MarketplacesList.defaultProps = {
  overscanRowCount: 6,
};

function MarketplacesList(props) {
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      filter={props.filter}
      list={props.list}
      onFilterChange={props.onFilterChange}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.rowCount}
      rowGetter={props.rowGetter}
      withFooter={true}
      cardActionIcons={props.cardActionIcons}
    >
      <DataListColumn
        width={96}
        label={getLocalisationMessage("marketplace_id", "Marketplace Id")}
        dataKey="marketplace_id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <strong>
            <Link
              to={updateQuery(
                `${SETTINGS_MARKETPLACE_ITEM_URL}${row.cellData.get(
                  "marketplace_id",
                )}`,
              )}
            >
              {row.cellData.get("marketplace_id")}
            </Link>
          </strong>
        )}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("marketplace_name", "Marketplace Name")}
        dataKey="name"
        disableSort={true}
        cellRenderer={row => <div>{row.cellData.get("name") || NA}</div>}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("country", "Country")}
        dataKey="country"
        disableSort={true}
        cellRenderer={row => (
          <div>{row.cellData.getIn(["country", "name"]) || NA}</div>
        )}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("currency", "Currency")}
        disableSort={true}
        dataKey="currency"
        cellRenderer={row => (
          <div>{row.cellData.getIn(["currency", "name"]) || NA}</div>
        )}
      />

      <DataListColumn
        label={getLocalisationMessage("created_date", "Created Date")}
        width={128}
        cellRenderer={row => (
          <div>{formatDate(row.cellData.get("created_date")) || NA}</div>
        )}
        dataKey="created_date"
        disableSort={true}
      />

      <DataListColumn
        label={getLocalisationMessage("source", "Source")}
        width={128}
        cellRenderer={row => <div>{row.cellData.get("source") || NA}</div>}
        dataKey="source"
        disableSort={true}
      />

      <DataListColumn
        label={getLocalisationMessage("business_type", "Business Type")}
        width={128}
        cellRenderer={row => (
          <div>{formatText(row.cellData.get("business_type")) || NA}</div>
        )}
        dataKey="business_type"
        disableSort={true}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("status", "Status")}
        disableSort={true}
        dataKey="type"
        cellRenderer={row => (
          <MarketplaceTypeComponent
            type={
              getLocalisationMessage(wordsTo(row.cellData.get("type"))) ||
              row.cellData.get("type")
            }
          />
        )}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("stripe_status", "Stripe Status")}
        disableSort={true}
        dataKey="stripe_status"
        cellRenderer={row =>
          getLocalisationMessage(
            row.cellData.get("stripe_status"),
            formatText(row.cellData.get("stripe_status")),
          ) || getLocalisationMessage("N/A")
        }
      />
    </DataList>
  );
}

export default enhancer(MarketplacesList);
