import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import {
  Divider,
  Menu,
  ListItem,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { withTheme } from "@material-ui/core/styles";
import { formatDate, MONTH_YEAR_FORMAT } from "../../../helpers/FormatUtils";
import { pipeStreams } from "../../../helpers/StreamUtils";
import ResponseError from "../../../helpers/ResponseError";
import {
  getMarketplaceId,
  getMarketplaceSubscription,
} from "../../../reducers/MarketplaceReducer";
import { getMessages } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { cardTypes } from "../../../constants/FindLogoForCardTypes";
import { CANCELED } from "../../../constants/SubscriptionStatusTypes";
import {
  getActivePlan,
  setupStripeIntent,
  getSubscriptionPrices,
  reactivateSubscription,
  unsubscribePricingPlan,
  getStripePaymentMethods,
  setAsDefaultPaymentMethod,
  detachPaymentMethodToCustomer,
  getMarketplacePurchaseHistory,
} from "../../../api/shared/PricePlanApi";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import Price from "../../../components/ui-core/Price";
import FlexBox, {
  ALIGN_END,
  JUSTIFY_CENTER,
} from "../../../components/ui-core/FlexBox";
import PageLoading from "../../../components/ui-core/PageLoading";
import DataList, {
  DataListColumn,
} from "../../../components/data-list/DataList";
import MultiLineCell from "../../../components/data-list/MultiLineCell";
import SubscriptionCheckoutDialog from "../../../components/subscription-core/SubscriptionCheckoutDialog";
import SubscriptionCancelConfirmDialog from "../../../components/subscription-core/SubscriptionCancelConfirmDialog";
import { updateQuery } from "../../../../shared/helpers/UrlUtils";

const getPaymentMethodId = fp.flow(fp.get("paymentMethod.id"));
const NA = "N/A";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  withTheme,
  withState("state", "setState", {
    userKey: "",
    publicKey: "",
    isSubmitLoading: false,
    showModal: false,
    cards: [],
    showTarif: false,
    subscription_plan: false,
    prices: false,
    reactivate: false,
  }),
  connect(
    state => ({
      i18n: getMessages(state),
      // customerStripeId: getMarketplaceStripeId(state),
      marketplaceId: getMarketplaceId(state),
      subscription: getMarketplaceSubscription(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    root: { paddingTop: "5px" },
    cardBox: {
      padding: "15px 5px",
    },
    itemBox: {
      marginBottom: 15,
      "& > h5": {
        marginBottom: 15,
      },
    },
    listStyle: { padding: "12px 16px 10px 90px" },
    listRoot: { width: "100%", padding: 0, border: "1px solid #eee" },
    multiLineItem1: { fontSize: "17px", maxWidth: "250px", lineHeight: "20px" },
    multiLineItem2: { fontSize: "13px" },
    multiLineItem2Root: { whiteSpace: "normal", maxWidth: "250px" },
    cardChip: {
      display: "inline-block",
      marginLeft: "5px",
      "& > span": {
        lineHeight: "20px",
      },
    },
    iconMenuItem: {
      listStyle: "none",
    },

    // TODO need to clean

    layout: {
      background: "#ffffff",
      height: "220%",
    },
    wrapper: {
      margin: "0 auto",
      width: "90%",
    },
    wrapper2: {
      margin: "50px 234px",
    },
    bigBox: {
      margin: "30px 0",
    },
    firstFlexBox: {},
    firstCard: {
      marginTop: "15px",
      display: "flex",
      flexDirection: "row",
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "rgb(255, 255, 255)",
      boxSizing: " border-box",
      boxShadow:
        " rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
      borderRadius: "4px",
    },
    firstCardFlex: {
      marginTop: "15px",
      display: "flex",
      flexDirection: "row",
      color: "rgba(0, 0, 0, 0.87)",
      justifyContent: "center",
      width: "100%",
      padding: "50px",
    },
    billing_history: {
      backgroundColor: "rgb(255, 255, 255)",
      boxShadow:
        " rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
      borderRadius: "4px",
      marginTop: "15px",
      display: "flex",
      flexDirection: "row",
      color: "rgba(0, 0, 0, 0.87)",
      justifyContent: "center",
      width: "100%",
      padding: "50px",
    },
    padding30: {
      padding: "30px",
    },
    firstCardSpan: {
      fontSize: "14px",
      color: "#777777",
    },
    firstSmallBox: {
      borderRight: "1px solid #E0E0E0",
      textAlign: "center",
      flexBasis: "33%",
    },
    firstSmallLastBox: {
      textAlign: "center",
      flexBasis: "33%",
    },
    firstH1: {
      fontSize: 24,
      color: "#2E3456",
    },
    status: {
      fontSize: 24,
      color: "rgb(156, 0, 8)",
    },
    statusDivFlex: {
      display: "flex",
      justifyContent: "center",
    },
    statusDiv: {
      padding: "20px 30px",
      textAlign: "center",
      backgroundColor: "rgb(255, 255, 255)",
      boxSizing: " border-box",
      boxShadow:
        " rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
      borderRadius: "4px",
    },
    calendar: {
      fontSize: 16,
    },
    firstSmallBoxSecondSpan: {
      color: "#2E3456",
      display: "block",
      fontSize: 16,
    },
    secondBoxSpan: {
      fontSize: 18,
      color: "#777777",
      display: "block",
    },
    thirdBoxSpan: {
      fontSize: 15,
      color: "#777777",
    },
    thirdH1: {
      fontSize: 20,
      color: "#2E3456",
    },
    cancelledStatus: {
      fontSize: 20,
      color: "rgb(156, 0, 8)",
    },
    addCardDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    cardInput: {
      marginTop: 30,
      padding: 10,
      width: "100%",
      border: "1px solid #ccc",
    },
    userNameWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
    },
    userName: {
      fontWeight: "bold",
      color: "#777777",
      fontSize: 16,
      padding: "5px 0",
    },
    badge: {
      background: "#F0F0F0",
      padding: "5px 12px",
      color: "#777777",
      fontSize: 12,
      fontWeight: "bold",
      textTransform: "uppercase",
      borderRadius: 4,
      marginLeft: 15,
      boxShadow:
        " rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    },
    cardExpire: {
      color: "#777777",
      fontSize: 14,
    },
    tableHeaderStyle: {
      background: "#F2F2F2",
      "&>tr>th": {
        color: "#777777",
        fontWeight: "bold",
        fontSize: 18,
      },
    },
    tableFirstColumn: {
      color: "#777777",
      fontSize: 18,
    },
    tableSecondColumn: {
      color: "#333333",
      fontSize: 18,
    },
    pdf: {
      color: "orange",
      textTransform: "uppercase",
    },
    table: {
      border: "1px solid #E0E0E0",
      borderRadius: 4,
    },
    tableWrapp: {
      marginTop: 15,
    },
    addCardWrap: {
      width: "100%",
    },
    emptySpan: {
      fontSize: 20,
      textAlign: "center",
      padding: 20,
      margin: 0,
    },
    cardLogo: {
      width: 65,
      height: 40,
      margin: 0,
      top: 18,
      objectFit: "contain",
      boxShadow:
        " rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    },
    toggleWrap: {
      textAlign: "center",
      backgroundColor: "rgb(255, 255, 255)",
      boxShadow:
        " rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
      borderRadius: "4px",
    },
    tarifWrap: {
      overflow: "hidden",
      transition: "all 0.4s ease",
      padding: "0 30px",
    },
    closeTarif: {
      height: 0,
    },
    openTarif: {
      height: 382,
    },
    boxShadow: {
      backgroundImage: "linear-gradient(white, #e8e8e8)",
    },
    reactivateDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    reactivateBtn: {
      marginLeft: 10,
    },
    cancelWrap: {
      display: "flex",
      marginBottom: 50,
      justifyContent: "flex-end",
    },
    column: {
      fontSize: 14,
    },
  }),
  mapPropsStream(
    pipeStreams(propsStream => {
      const {
        handler: onRequestRefresh,
        stream: onRequestRefreshStream,
      } = createEventHandler();

      const getSubscriptionPricesStream = propsStream
        .filter(v => v.state.showTarif && !v.state.prices)
        .switchMap(props =>
          getSubscriptionPrices(
            props.state.subscription_plan.stripe_subscription_id,
          )
            .repeatWhen(() => onRequestRefreshStream)
            .catch(error => Observable.of({ error })),
        )
        .filter(v => !v.pending && v.payload.data)
        .withLatestFrom(propsStream)
        .do(([value, props]) => {
          const a = value.payload.data.tiers;
          const b = [];
          for (let i = 0; i < a.length; i++) {
            if (i === 0) {
              b.push({
                quantity: `If the total quantity is 0 to ${a[i].up_to}`,
                price_per_unit: `US $ ${a[i].unit_amount / 100}`,
              });
            } else if (i === a.length - 1) {
              b.push({
                quantity: `If the total quantity is ${a[i - 1].up_to +
                  1} or more`,
                price_per_unit: `US $ ${a[i].unit_amount / 100}`,
              });
            } else {
              b.push({
                quantity: `If the total quantity is ${a[i - 1].up_to + 1} to ${
                  a[i].up_to
                }`,
                price_per_unit: `US $ ${a[i].unit_amount / 100}`,
              });
            }
          }
          props.setState(fp.set("prices", fromJS(b)));
        })
        .startWith({});

      const getPlansHistoryStream = propsStream
        .map(fp.pick(["marketplaceId"]))
        .distinctUntilKeyChanged("marketplaceId")
        .switchMap(props =>
          getMarketplacePurchaseHistory(props.marketplaceId)
            .repeatWhen(() => onRequestRefreshStream)
            .catch(error => Observable.of({ error })),
        )
        .map(
          fp.flow(
            fp.update("pending", Boolean),
            fp.update("payload", fp.flow(fp.get("data"), fromJS)),
            Map,
          ),
        );

      const subscribedPlanStream = propsStream
        .map(fp.pick(["marketplaceId"]))
        .distinctUntilKeyChanged("marketplaceId")
        .switchMap(() =>
          getActivePlan()
            .repeatWhen(() => onRequestRefreshStream)
            .catch(error => Observable.of({ error })),
        )
        .filter(v => !v.pending && v.payload.status)
        .withLatestFrom(propsStream)
        .do(([value, props]) =>
          props.setState(
            fp.set(
              "subscription_plan",
              value.payload.data ? value.payload.data : { status: CANCELED },
            ),
          ),
        );

      const stripePaymentMethodsStream = propsStream
        .first()
        .switchMap(() =>
          getStripePaymentMethods()
            .repeatWhen(() => onRequestRefreshStream)
            .catch(error => Observable.of({ error })),
        )
        .filter(v => !v.pending && v.payload.data)
        .withLatestFrom(propsStream)
        .do(([value, props]) =>
          props.setState(fp.set("cards", value.payload.data)),
        );

      return propsStream.combineLatest(
        getPlansHistoryStream,
        subscribedPlanStream,
        stripePaymentMethodsStream,
        getSubscriptionPricesStream,
        (props, paymentHistory, subscribedPlan) => ({
          ...props,
          onRequestRefresh,
          isLoading:
            subscribedPlan[0].pending &&
            stripePaymentMethodsStream[0].pending &&
            paymentHistory.get("pending"),
          paymentHistoryList: paymentHistory.getIn(["payload", "list"], List()),
          paymentHistoryTotal: paymentHistory.getIn(["payload", "total"], 0),
        }),
      );
    }),
  ),
);

AdminSubscriptionPlansContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  classes: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  paymentHistoryList: PropTypes.instanceOf(List),
};

function AdminSubscriptionPlansContainer(props) {
  const { i18n, classes, state } = props;
  return (
    <AdminAppLayout title={i18n.get("billing", "Billing")} slug="billing">
      <PageLoading isLoading={props.isLoading} />

      <FlexBox className={classes.root} align="flex-start" direction="column">
        {!fp.isEmpty(state.userKey) && !fp.isEmpty(state.publicKey) && (
          // !fp.isEmpty(props.customerStripeId) &&
          <SubscriptionCheckoutDialog
            open={true}
            i18n={i18n}
            clientSecret={state.userKey || "userKey"}
            publicKey={state.publicKey || "publicKey"}
            // customerStripeId={props.customerStripeId || "customerStripeId"}
            onSubmit={(stripe, element) => {
              props.setState(fp.set("isSubmitLoading", true));
              stripe
                .createPaymentMethod({
                  type: "card",
                  card: element,
                })
                .then(result => {
                  const paymentMethod = getPaymentMethodId(result);
                  if (!fp.isEmpty(paymentMethod)) {
                    Promise.resolve(
                      setAsDefaultPaymentMethod({
                        stripe_id: paymentMethod,
                      }).catch(ResponseError.throw),
                    )
                      .catch(error => {
                        props.showErrorMessage(error);
                      })
                      .then(res => {
                        props.setState(
                          fp.set("cards", [
                            ...state.cards.map(card => ({
                              ...card,
                              primary: false,
                            })),
                            res.data,
                          ]),
                        );
                        props.setState(fp.set("userKey", ""));
                        props.setState(fp.set("publicKey", ""));
                        props.setState(fp.set("isSubmitLoading", false));
                        if (state.reactivate) {
                          Promise.resolve(
                            reactivateSubscription().catch(error =>
                              props.showErrorMessage(error),
                            ),
                          )
                            .catch(error => {
                              props.showErrorMessage(error);
                            })
                            .then(response => {
                              props.setState(
                                fp.set(
                                  "subscription_plan",
                                  response.payload.data,
                                ),
                              );
                              props.showSuccessMessage(
                                i18n.get(
                                  "subscription_successfully_activated",
                                  "Subscription successfully activated",
                                ),
                              );
                            });
                          props.setState(fp.set("reactivate", false));
                        } else {
                          props.showSuccessMessage(
                            i18n.get(
                              "new_card_has_been_added_successfully",
                              "New Card has been added successfully",
                            ),
                          );
                        }
                      });
                  } else {
                    props.showErrorMessage(
                      result.error && result.error.message,
                    );
                  }
                });
            }}
            closeDialog={() => {
              props.setState(fp.set("userKey", ""));
              props.setState(fp.set("publicKey", ""));
            }}
          />
        )}

        <FlexBox
          flex={true}
          direction="column"
          style={{ minHeight: "initial", marginBottom: "10px" }}
        >
          <FlexBox direction="column" className={classes.wrapper}>
            {((state.subscription_plan &&
              state.subscription_plan.status === CANCELED) ||
              !state.subscription_plan.stripe_status) && (
              <div className={classes.statusDivFlex}>
                <div className={classes.statusDiv}>
                  <div className={classes.reactivateDiv}>
                    <strong>
                      {i18n.get(
                        "click_here_to_activate_subscription",
                        "Click here to activate subscription",
                      )}
                    </strong>
                    <span style={{ fontSize: "20px", marginLeft: ".25rem" }}>
                      {" "}
                      &#8680;{" "}
                    </span>
                    <Button
                      className={classes.reactivateBtn}
                      mini={true}
                      onClick={() => {
                        if (state.cards && state.cards.length > 0) {
                          Promise.resolve(
                            reactivateSubscription().catch(error =>
                              props.showErrorMessage(error),
                            ),
                          )
                            .catch(error => {
                              props.showErrorMessage(error);
                            })
                            .then(res => {
                              props.setState(
                                fp.set("subscription_plan", res.data),
                              );
                              props.showSuccessMessage(
                                props.i18n.get(
                                  "subscription_successfully_reactivated",
                                  "Subscription successfully reactivated",
                                ),
                              );
                            });
                        } else {
                          Promise.resolve(setupStripeIntent())
                            .catch(error => {
                              props.showErrorMessage(error);
                            })
                            .then(response => {
                              props.setState(fp.set("reactivate", true));
                              props.setState(
                                fp.set("publicKey", response.data.public_key),
                              );
                              props.setState(
                                fp.set("userKey", response.data.client_secret),
                              );
                            });
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="black"
                        width="36px"
                        height="36px"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" />
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {state.subscription_plan &&
              state.subscription_plan.stripe_subscription_id &&
              state.subscription_plan.status !== CANCELED && (
                <div className={classes.bigBox}>
                  <span className={classes.firstH1}>
                    {i18n.get("price", "Price")} :{" "}
                    {state.subscription_plan.name}
                  </span>
                  <div className={classes.toggleWrap}>
                    <div className={classes.firstCardFlex}>
                      <div className={classes.firstSmallBox}>
                        <span className={classes.firstCardSpan}>
                          {i18n.get(
                            "cost_for_billing_period",
                            "Cost for Billing period",
                          )}
                        </span>
                        <span className={classes.firstSmallBoxSecondSpan}>
                          ${state.subscription_plan.total_price_per_period || 0}
                        </span>
                      </div>
                      <div className={classes.firstSmallBox}>
                        <span className={classes.firstCardSpan}>
                          {i18n.get("monthly_orders", "Monthly Orders")}
                        </span>
                        <span className={classes.firstSmallBoxSecondSpan}>
                          {state.subscription_plan.orders_created || 0}
                        </span>
                      </div>
                      <div className={classes.firstSmallBox}>
                        <span className={classes.firstCardSpan}>
                          {i18n.get("cost_per_order", "Cost per Order")}
                        </span>
                        <span className={classes.firstSmallBoxSecondSpan}>
                          ${state.subscription_plan.price_per_order || 0}
                        </span>
                      </div>
                      <div className={classes.firstSmallLastBox}>
                        <span className={classes.firstCardSpan}>
                          {i18n.get("billing_cycle", "Billing Cycle")}
                        </span>
                        <span
                          className={`${classes.calendar} ${classes.firstSmallBoxSecondSpan}`}
                        >
                          {formatDate(
                            new Date(state.subscription_plan.start_date),
                            MONTH_YEAR_FORMAT,
                          )}{" "}
                          -{" "}
                          {formatDate(
                            new Date(state.subscription_plan.end_date),
                            MONTH_YEAR_FORMAT,
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`${classes.tarifWrap} ${
                        state.showTarif ? classes.openTarif : classes.closeTarif
                      }`}
                    >
                      {state.prices ? (
                        <div className={classes.tableWrapp}>
                          <DataList
                            isLoading={false}
                            list={state.prices}
                            rowCount={state.prices.size}
                            overscanRowCount={state.prices.size}
                            rowGetter={options =>
                              state.prices.get(options.index)
                            }
                          >
                            <DataListColumn
                              className={classes.column}
                              headerClassName={classes.column}
                              width={120}
                              label={i18n.get("quantity", "Quantity")}
                              dataKey="start"
                              cellRenderer={row => row.cellData.get("quantity")}
                            />
                            <DataListColumn
                              className={classes.column}
                              headerClassName={classes.column}
                              width={120}
                              label={i18n.get(
                                "price_per_unit",
                                "Price per unit",
                              )}
                              dataKey="plan"
                              cellRenderer={row =>
                                row.cellData.get("price_per_unit")
                              }
                            />
                          </DataList>
                        </div>
                      ) : (
                        <PageLoading isLoading={state.showTarif} />
                      )}
                    </div>
                    <FlexBox
                      className={classes.boxShadow}
                      align={ALIGN_END}
                      justify={JUSTIFY_CENTER}
                    >
                      <Button
                        onClick={() =>
                          props.setState(fp.set("showTarif", !state.showTarif))
                        }
                        endIcon={
                          state.showTarif ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )
                        }
                      >
                        {!state.showTarif
                          ? i18n.get("show_prices", "Show prices")
                          : i18n.get("hide_prices", "Hide prices")}
                      </Button>
                    </FlexBox>
                  </div>
                </div>
              )}
            <div className={classes.bigBox}>
              <span className={classes.firstH1}>
                {i18n.get("billing_info", "Billing info")}
              </span>
              <span className={classes.secondBoxSpan}>
                {i18n.get(
                  "please_enter_your_preferred_payment_method_below_you_can_use_a_credit_debit_card",
                  "Please enter your preferred payment method below. You can use a credit / debit card.",
                )}
              </span>
            </div>
            <div className={classes.bigBox}>
              <span className={classes.thirdH1}>
                {i18n.get("credit_debit_card", "Credit / Debit Card")}
              </span>
              <div className={`${classes.firstCard} ${classes.padding30}`}>
                <div className={classes.addCardWrap}>
                  <span className={classes.thirdH1}>
                    {i18n.get("credit_or_debit_cards", "Credit or debit cards")}
                  </span>
                  <div className={classes.addCardDiv}>
                    <span className={classes.thirdBoxSpan}>
                      {i18n.get(
                        "cards_will_be_charged_at_the_end_of_the_billing_period_only_all_major_credit_debit_cards_accepted",
                        "Cards will be charged at the end of the billing period only. All major credit / debit cards accepted",
                      )}
                    </span>
                    <Button
                      onClick={() => {
                        Promise.resolve(setupStripeIntent())
                          .catch(error => {
                            props.showErrorMessage(error);
                          })
                          .then(response => {
                            props.setState(
                              fp.set("publicKey", response.data.public_key),
                            );
                            props.setState(
                              fp.set("userKey", response.data.client_secret),
                            );
                          });
                      }}
                    >
                      {i18n.get("add_card", "Add Card")}
                    </Button>
                  </div>
                  {state.cards && state.cards.length > 0 ? (
                    state.cards.map(item => {
                      const stripeId = item.stripe_id;
                      return (
                        <div key={stripeId}>
                          <ListItem
                            disabled={true}
                            className={classes.listStyle}
                            leftIcon={
                              <img
                                className={classes.cardLogo}
                                src={
                                  cardTypes[
                                    fp.camelCase(item.brand).toLowerCase()
                                  ] || cardTypes.undefinedcard
                                }
                                alt="Card Type"
                              />
                            }
                            rightIconButton={
                              !item.primary ? (
                                <Menu
                                  style={{ marginTop: "15px" }}
                                  iconButtonElement={
                                    <IconButton touch={true}>
                                      <Add color={grey[400]} />
                                    </IconButton>
                                  }
                                >
                                  <MenuItem
                                    className={classes.iconMenuItem}
                                    onClick={() => {
                                      Promise.resolve(
                                        setAsDefaultPaymentMethod({
                                          stripe_id: stripeId,
                                        }).catch(error =>
                                          props.showErrorMessage(error),
                                        ),
                                      ).then(() => {
                                        props.setState(
                                          fp.set(
                                            "cards",
                                            state.cards.map(card =>
                                              card.stripe_id === stripeId
                                                ? { ...card, primary: true }
                                                : {
                                                    ...card,
                                                    primary: false,
                                                  },
                                            ),
                                          ),
                                        );
                                        props.showSuccessMessage(
                                          i18n.get(
                                            "default_payment_method_updated_successfully",
                                            "Default Payment Method Updated Successfully",
                                          ),
                                        );
                                      });
                                    }}
                                  >
                                    {i18n.get(
                                      "make_a_default",
                                      "Make a Default",
                                    )}
                                  </MenuItem>

                                  <MenuItem
                                    className={classes.iconMenuItem}
                                    onClick={() => {
                                      Promise.resolve(
                                        detachPaymentMethodToCustomer({
                                          stripe_id: stripeId,
                                        }).catch(error =>
                                          props.showErrorMessage(error),
                                        ),
                                      ).then(() => {
                                        props.setState(
                                          fp.set(
                                            "cards",
                                            state.cards.filter(
                                              i => i.stripe_id !== stripeId,
                                            ),
                                          ),
                                        );
                                        props.showSuccessMessage(
                                          i18n.get(
                                            "payment_method_deleted",
                                            "Payment Method Deleted",
                                          ),
                                        );
                                      });
                                    }}
                                  >
                                    {i18n.get("delete_card", "Delete Card")}
                                  </MenuItem>
                                </Menu>
                              ) : null
                            }
                          >
                            <MultiLineCell
                              firstLine={
                                <div className={classes.userNameWrapper}>
                                  <span className={classes.userName}>
                                    {fp.toUpper(item.brand)}{" "}
                                    {`xxxx-${item.card_number}`}
                                  </span>
                                  {item.primary && (
                                    <span className={classes.badge}>
                                      {i18n.get("default", "Default")}
                                    </span>
                                  )}
                                </div>
                              }
                              secondLine={
                                <span className={classes.cardExpire}>
                                  {i18n.get("expires", "Expires")}
                                  {" : "}
                                  {item.expire_date}
                                </span>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    })
                  ) : (
                    <div className={classes.cardInput}>
                      <p className={classes.emptySpan}>
                        {i18n.get(
                          "you_have_not_credit_or_debit_cards",
                          "You have not credit or debit cards",
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.bigBox}>
              <span className={classes.firstH1}>
                {i18n.get("billing_history", "Billing history")}
              </span>
              {props.paymentHistoryList.size > 0 ? (
                <div className={classes.tableWrapp}>
                  <DataList
                    isLoading={false}
                    list={props.paymentHistoryList}
                    rowCount={props.paymentHistoryList.size}
                    overscanRowCount={props.paymentHistoryList.size}
                    rowGetter={options =>
                      props.paymentHistoryList.get(options.index)
                    }
                  >
                    <DataListColumn
                      width={120}
                      label={i18n.get("plan", "Plan")}
                      dataKey="plan"
                      cellRenderer={row =>
                        row.cellData.getIn(
                          ["marketplace_plan", "plan", "title"],
                          NA,
                        )
                      }
                    />
                    <DataListColumn
                      width={120}
                      label={i18n.get("start_date", "Start Date")}
                      dataKey="start"
                      cellRenderer={row =>
                        formatDate(row.cellData.get("start"), MONTH_YEAR_FORMAT)
                      }
                    />
                    <DataListColumn
                      width={120}
                      label={i18n.get("end_date", "End Date")}
                      dataKey="end"
                      cellRenderer={row =>
                        formatDate(row.cellData.get("end"), MONTH_YEAR_FORMAT)
                      }
                    />
                    <DataListColumn
                      width={120}
                      label={i18n.get("status", "Status")}
                      dataKey="status"
                      cellRenderer={row =>
                        i18n
                          .get(
                            row.cellData.get("status").toLowerCase(),
                            row.cellData.get("status"),
                          )
                          .toUpperCase()
                      }
                    />
                    <DataListColumn
                      width={120}
                      label={i18n.get("renew", "Is renew")}
                      dataKey="renew"
                      cellRenderer={row =>
                        row.cellData.get("renew")
                          ? i18n.get("yes")
                          : i18n.get("no")
                      }
                    />

                    <DataListColumn
                      width={100}
                      label={i18n.get("period", "Period")}
                      dataKey="plan_period_type"
                      cellRenderer={row =>
                        i18n.get(
                          row.cellData
                            .getIn(["marketplace_plan", "plan_period_type"], NA)
                            .toLowerCase(),
                          row.cellData.getIn(
                            ["marketplace_plan", "plan_period_type"],
                            NA,
                          ),
                        )
                      }
                    />

                    <DataListColumn
                      width={120}
                      label={i18n.get("total", "Total")}
                      dataKey="total"
                      cellRenderer={row => (
                        <div>
                          $
                          <Price
                            price={row.cellData.get("total") || 0}
                            code="USD"
                          />
                        </div>
                      )}
                    />

                    <DataListColumn
                      width={240}
                      label={i18n.get("invoice", "Invoice")}
                      dataKey="invoice"
                      cellRenderer={row => (
                        <Button
                          component="a"
                          target="_blank"
                          href={updateQuery(row.cellData.get("pdf_link", ""))}
                        >
                          {i18n.get("print_invoice", "Print Invoice")}
                        </Button>
                      )}
                    />
                  </DataList>
                </div>
              ) : (
                <div className={classes.billing_history}>
                  <div className={classes.cardInput}>
                    <p className={classes.emptySpan}>
                      {i18n.get(
                        "your_billing_history_is_empty",
                        "Your billing history is empty",
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {false &&
              state.subscription_plan &&
              state.subscription_plan.status !== CANCELED &&
              state.subscription_plan.stripe_status && (
                <div className={classes.cancelWrap}>
                  <Button
                    onClick={() => props.setState(fp.set("cancelDialog", true))}
                  >
                    {i18n.get("cancel_subscription", "Cancel Subscription")}
                  </Button>
                  {state.cancelDialog && (
                    <SubscriptionCancelConfirmDialog
                      closeDialog={() =>
                        props.setState(fp.set("cancelDialog", false))
                      }
                      subscribedPlan={state.subscription_plan}
                      onConfirm={() =>
                        Promise.resolve(
                          unsubscribePricingPlan().catch(ResponseError.throw),
                        )
                          .catch(error => {
                            props.showErrorMessage(error);
                          })
                          .then(res => {
                            props.setState(
                              fp.set("subscription_plan", res.data),
                            );
                            props.setState(fp.set("cancelDialog", false));
                            props.showSuccessMessage(
                              i18n.get(
                                "subscription_successfully_cancelled",
                                "Subscription successfully cancelled",
                              ),
                            );
                          })
                      }
                    />
                  )}
                </div>
              )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminSubscriptionPlansContainer);
