import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const PROMO_URL = `${API_ROOT_URL}/admin/promo`;
const PROMO_LIST_URL = `${API_ROOT_URL}/admin/promos`;
const PROMO_ITEM_URL = `${API_ROOT_URL}/admin/promo/%s`;

export const getPromoList = (request: DataListFilter) =>
  api.getStream(PROMO_LIST_URL, { params: request.getDefinedValues() });

export const getPromo = id => api.getStream(sprintf(PROMO_ITEM_URL, id));

export const createPromo = values => api.post(PROMO_URL, { body: values });
export const updatePromo = (id, values) =>
  api.put(sprintf(PROMO_ITEM_URL, id), { body: values });
