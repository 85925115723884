import React from "react";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, FieldArray } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormToggle from "../form/FormToggle";
import FormTextField from "../form/FormTextField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { createObjectIdValidator } from "../../helpers/FormUtils";
import { parseInteger } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "SupplierFinanceSettingsForm",
    enableReinitialize: true,
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  useSheet({
    textCenter: { textAlign: "center" },
    actionTitle: { width: "200px", marginLeft: "2rem" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    timeWork: { "& > input": { textAlign: "right" } },
  }),
);

const FinanceSettingsComponent = props => (
  <FlexBox direction="column" gutter={8} flex={true}>
    <FlexBox>
      <FlexBox gutter={8} flex={true}>
        <FlexBox justify="center" flex={true}>
          {props.getLocalisationMessage("supplier", "Supplier")}
        </FlexBox>
        <FlexBox justify="center" className={props.classes.actionTitle}>
          {props.getLocalisationMessage("action", "Action")}
        </FlexBox>
        <FlexBox
          justify="center"
          flex={true}
          className={props.classes.textCenter}
        >
          {props.getLocalisationMessage(
            "stop_order_assignment_if_cod_overdue_hrs",
            "Stop order assignment if COD overdue (hrs)",
          )}
        </FlexBox>
        <FlexBox
          justify="center"
          flex={true}
          className={props.classes.textCenter}
        >
          {props.getLocalisationMessage("cod_force_update", "COD Force Update")}
        </FlexBox>
      </FlexBox>
    </FlexBox>
    <FlexBox direction="column">
      {props.fields.map((settings, index) => (
        <FlexBox gutter={8} key={index} flex={true} align="center">
          <FlexBox style={{ marginLeft: "2rem" }} flex={true}>
            <FormSupplierAutoComplete
              name={`${settings}.supplier`}
              fullWidth={true}
              validate={createObjectIdValidator(
                props.getLocalisationMessage(
                  "select_supplier",
                  "Select Supplier",
                ),
              )}
              hintText={props.getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />
          </FlexBox>
          <FlexBox flex={true} justify="center">
            <FormToggle
              withLabel={false}
              trueValue={ACTIVE}
              falseValue={INACTIVE}
              className={props.classes.toggle}
              name={`${settings}.status`}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              fullWidth={true}
              parseOnBlur={parseInteger}
              className={props.classes.timeWork}
              name={`${settings}.timeLimit`}
            />
          </FlexBox>
          <FlexBox flex={true} justify="center">
            <FormToggle
              withLabel={false}
              className={props.classes.toggle}
              name={`${settings}.forceUpdateCod`}
            />
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>
    <FlexBox flex={true} justify={JUSTIFY_CENTER}>
      <Button onClick={() => props.fields.push({})}>
        {props.getLocalisationMessage("add_supplier", "Add Supplier")}
      </Button>
    </FlexBox>
  </FlexBox>
);

FinanceSettingsComponent.propTypes = {
  fields: PropTypes.object,
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

SupplierFinanceSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  location: PropTypes.object,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierFinanceSettingsForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox
      container={8}
      flex={true}
      element={<form onSubmit={props.handleSubmit} />}
    >
      <FlexBox gutter={8} flex={true} direction="column">
        <FlexBox justify="flex-end">
          <Button type="submit" disabled={props.pristine || props.invalid}>
            {getLocalisationMessage("apply", "Apply")}
          </Button>
        </FlexBox>
        <FlexBox flex={true}>
          <PageLoading isLoading={props.submitting} />

          <FieldArray
            name="financeSettings"
            props={{
              ...props,
              classes: props.classes,
              location: props.location,
            }}
            component={FinanceSettingsComponent}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SupplierFinanceSettingsForm);
