import React from "react";
import useSheet from "react-jss";
import { Map } from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";

import fp from "lodash/fp";
import { isEqualData } from "../../helpers/DataUtils";
import { getOrderList } from "../../api/admin/AdminOrderApi";
import DataListFilter from "../../helpers/DataListFilter";
import { mapListResponseStream } from "../../helpers/ApiUtils";
import FlexBox, { JUSTIFY_CENTER, JUSTIFY_END } from "../ui-core/FlexBox";
import { withTheme } from "@material-ui/core/styles";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import cx from "classnames";
import { red } from "@material-ui/core/colors";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";

const baseFilter = new DataListFilter({
  size: 1,
  simple: true,
  use_solr: true,
  include_dw: true,
  search_type: "order_number",
});

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    detailsRow: {
      marginBottom: 20,
    },
    detailsLabel: {
      lineHeight: "14px",
      fontWeight: "400",
      fontSize: "12px",
    },
    detailsValue: {
      lineHeight: "26px",
    },
    alert: {
      color: red[500],
      fontSize: "22px",
      fontWeight: "bold",
    },
  }),
  mapPropsStream((propsStream) => {
    const unknownOrderStream = propsStream
      .map(fp.get("scannedOrder"))
      .distinctUntilChanged(isEqualData)
      .switchMap((orderNumber) =>
        getOrderList(baseFilter.setSearch(orderNumber)),
      )
      .let(mapListResponseStream);

    return propsStream.combineLatest(
      unknownOrderStream,
      unknownOrderStream
        .withLatestFrom(propsStream)
        .do(([response, props]) => {
          if (
            !response.get("pending") &&
            !response.getIn(["payload", "list", 0])
          ) {
            setTimeout(() => props.onRequestClose(props.scannedOrder), 1000);
          }
        })
        .startWith(null),
      (props, unknownOrder) => ({
        ...props,

        unknownOrder: unknownOrder.getIn(["payload", "list", 0]),
        unknownOrderCount: unknownOrder.getIn(["payload", "total"]),
        isLoading: unknownOrder.get("pending"),
      }),
    );
  }),
  withTheme,
);

OrderSortingVerifyBinConfirmDialog.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,

  onRequestClose: PropTypes.func,
  children: PropTypes.node,

  scannedOrder: PropTypes.string,
  unknownOrder: PropTypes.instanceOf(Map),
  selectedBin: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),

  onConfirm: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  theme: PropTypes.object,
};

function OrderSortingVerifyBinConfirmDialog(props) {
  const { getLocalisationMessage, classes, onConfirm, theme } = props;

  const expectedBin = props.orders.getIn([props.scannedOrder, "bin"], null);

  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      PaperProps={{
        style: {
          width: "780px",
        },
      }}
      onKeyPress={(event) => {
        if (fp.get("code", event) === "Enter") {
          props.onConfirm(props.unknownOrder);
        }
      }}
    >
      <DialogTitle
        style={{
          color: theme.palette.appBarTextColor,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${props.scannedOrder} ${
              props.isLoading
                ? getLocalisationMessage("loading", "Loading...")
                : ""
            }`}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            {getLocalisationMessage("current_bin", "Current Bin")}
            {": "}
            {props.selectedBin && props.selectedBin.get("label")}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      {!props.isLoading && (
        <DialogContent>
          {props.unknownOrder ? (
            <FlexBox flex={true} direction="column">
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("barcode", "Barcode")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {props.unknownOrder.get("barcode")}
                  </h6>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("status", "Status")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {formatOrderStatusCodeForLocalisation(
                      props.unknownOrder.get("status"),
                      getLocalisationMessage,
                    )}
                  </h6>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("jurisdiction", "Jurisdiction")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {props.unknownOrder.getIn(["to_jurisdiction", "name"], "")}
                  </h6>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage("postcode", "Postcode")}:
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {props.unknownOrder.getIn(["to_postcode", "name"], "")}
                  </h6>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true} className={classes.detailsRow}>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage(
                      "current_warehouse",
                      "Current Warehouse",
                    )}
                    :
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {props.unknownOrder.getIn(["warehouse", "name"], "")}
                  </h6>
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <h6 className={classes.detailsLabel}>
                    {getLocalisationMessage(
                      "destination_warehouse",
                      "Destination Warehouse",
                    )}
                    :
                  </h6>
                  <h6 className={classes.detailsValue}>
                    {props.unknownOrder.getIn(
                      ["destination_warehouse", "name"],
                      "",
                    )}
                  </h6>
                </FlexBox>
              </FlexBox>

              <FlexBox flex={true}>
                {expectedBin ? (
                  <FlexBox flex={true} direction="column">
                    <h6 className={classes.detailsLabel}>
                      {getLocalisationMessage(
                        "current_bin_of_the_order",
                        "Current bin of the Order",
                      )}
                      :
                    </h6>
                    <h6 className={cx(classes.detailsValue, classes.alert)}>
                      {expectedBin}
                    </h6>
                  </FlexBox>
                ) : (
                  <FlexBox flex={true} direction="column">
                    <h6 className={cx(classes.detailsValue, classes.alert)}>
                      {getLocalisationMessage(
                        "according_to_system_the_order_is_not_exists_at_current_warehouse",
                        "According to system the order is not exists at current warehouse",
                      )}
                    </h6>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox flex={true} justify={JUSTIFY_CENTER}>
              {getLocalisationMessage("order_not_found", "Order not found")}
            </FlexBox>
          )}

          {props.children}
        </DialogContent>
      )}

      <DialogActions>
        {props.onRequestClose && (
          <Button onClick={() => props.onRequestClose(props.scannedOrder)}>
            {props.getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        )}

        {onConfirm && props.unknownOrder && (
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() => onConfirm(props.unknownOrder)}
          >
            {props.getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderSortingVerifyBinConfirmDialog);
