import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { FirebaseSDK } from "./FirebaseSDK";
import { getInitialState } from "../helpers/InitialState";
import { getTokenUserId } from "../../shared/reducers/AuthReducer";

const basePath = "order_customs_inbound_sorting";
const jobPath = `${basePath}/job`;

const uid = getTokenUserId(getInitialState());

const mapResponse = fp.flow(fp.method("val"), fp.toPlainObject, fromJS);
const validateOrder = (x) => {
  const value = x.val();

  return Boolean(value && value.number === x.key);
};

export class OrderCustomsInboundSortingDB {
  constructor(warehouseId = null) {
    this.db = new FirebaseSDK();
    this.warehouseId = warehouseId;
  }

  getPath(...args) {
    return [jobPath, this.warehouseId, uid, ...args].join("/");
  }

  //
  // Orders
  //

  getOrdersPath(...args) {
    return this.getPath("orders", ...args);
  }

  clearOrders() {
    return this.db.update(this.getPath(), { tasks: null, orders: null });
  }

  reloadOrder(orderNumber = null) {
    return this.batchReloadOrders([orderNumber]);
  }

  batchReloadOrders(orderNumbers) {
    const values = orderNumbers.reduce((acc, x) => {
      acc[`${x}/hash`] = null;
      acc[`${x}/failed`] = null;

      return acc;
    }, {});

    return this.db.update(this.getOrdersPath(), values);
  }

  removeOrder(orderNumber) {
    return this.batchRemoveOrders([orderNumber]);
  }

  batchUpdateOrders(values) {
    return this.db.update(this.getOrdersPath(), values);
  }

  updateOrder(orderNumber, updater) {
    return this.db.transaction(this.getOrdersPath(orderNumber), updater);
  }

  batchRemoveOrders(orderNumbers) {
    const values = orderNumbers.reduce((acc, number) => {
      acc[number] = null;

      return acc;
    }, {});

    return this.batchUpdateOrders(values);
  }

  getOrders() {
    return this.db
      .get(this.getOrdersPath())
      .map(
        fp.flow(mapResponse, (response) =>
          response.filter((x, key) => Map.isMap(x) && x.get("number") === key),
        ),
      );
  }

  getOrder(orderNumber = null) {
    return this.db.get(this.getOrdersPath(orderNumber)).map(mapResponse);
  }

  getOrderAddStream() {
    return this.db
      .onChildAdd(this.getOrdersPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getOrderChangeStream() {
    return this.db
      .onChildChanged(this.getOrdersPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getOrderRemoveStream() {
    return this.db
      .onChildRemoved(this.getOrdersPath())
      .filter(validateOrder)
      .map(mapResponse);
  }

  getCorruptedOrderStream() {
    const path = this.getOrdersPath();

    return this.db
      .onChildAdd(path)
      .merge(this.db.onChildChanged(path))
      .filter((x) => {
        const value = x.val();

        return Boolean(!value || value.number !== x.key);
      });
  }

  //
  // Tasks
  //

  getTasksPath(...args) {
    return this.getPath("tasks", uid, ...args);
  }

  getTask(taskId = null) {
    return this.db.get(this.getTasksPath(taskId));
  }

  getTasks() {
    return this.db.get(this.getTasksPath()).map(mapResponse);
  }

  getTaskAddStream() {
    return this.db.onChildAdd(this.getTasksPath());
  }

  addTask(orderNumber, task) {
    return this.db.push(this.getTasksPath(), { task, number: orderNumber });
  }

  updateTask(taskId = null, values = null) {
    return this.db.update(this.getTasksPath(taskId), values);
  }

  removeTask(taskId = null) {
    return this.db.set(this.getTasksPath(taskId), null);
  }

  retryTask(taskId = null) {
    return this.getTask(taskId)
      .take(1)
      .takeWhile((snapshot) => snapshot.exists())
      .switchMap((response) => {
        const payload = response.val();

        return this.removeTask(taskId).concat(
          this.addTask(payload.number, payload.task),
        );
      });
  }
}
