import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CREATE_PRODUCT_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/product`;
const PRODUCT_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/product/%s`;
const PRODUCT_LIST_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/product`;

export type ProductItemRequest = {
  old_price: number,
  id: number,
  menu: {
    count: number,
    parent: {},
    id: number,
    name: string,
    sorder: number,
    status: string,
    image: string,
  },
  cost: number,
  price: number,
  discount: number,
  weight: number,
  name: string,
  description: string,
  photos: string[],
  status: string,
  created_date: string,
};

export const getProductItem = (venueId, id) =>
  api.getStream(sprintf(PRODUCT_ITEM_URL, venueId, id));
export const createProductItem = (venueId, body: ProductItemRequest) =>
  api.post(sprintf(CREATE_PRODUCT_ITEM_URL, venueId), { body });
export const updateProductItem = (venueId, id, body: ProductItemRequest) =>
  api.put(sprintf(PRODUCT_ITEM_URL, venueId, id), { body });

export const getProdustList = (venueId, request: DataListFilter) =>
  api.getStream(sprintf(PRODUCT_LIST_URL, venueId), {
    params: request.getDefinedValues(),
  });

export const removeProduct = ids =>
  api.delete(PRODUCT_LIST_URL, { params: { ids } });
