import React from "react";
import { Collection, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Text, { DANGER, MUTED } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatDateDistanceToNow,
  formatText,
  formatWeight,
} from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import MultiLineCell from "../data-list/MultiLineCell";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";

const searchTextToSet = fp.flow(fp.trim, fp.split(","), fp.compact, Set);

OrderFieldCell.propTypes = {
  children: PropTypes.node,
  failed: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func,
};

const NA = "N/A";

function OrderFieldCell(props) {
  return props.fetched ? (
    <Text fallbackType={MUTED} fallbackValue="-">
      {props.children}
    </Text>
  ) : props.failed ? (
    <Text type={DANGER}>
      {props.getLocalisationMessage("failed", "Failed")}
    </Text>
  ) : (
    <Text type={MUTED}>
      {props.getLocalisationMessage("loading", "Loading...")}
    </Text>
  );
}

const enhancer = compose(
  connect((state) => {
    const statusMessages = getStatusLocalisation(state);

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      statusMessages,
    };
  }),
  useSheet({
    root: {
      display: "flex",
      height: `${64 + 52 + 56 * 10}px`,
    },
    btn: {
      flex: "1 1 auto",
      margin: "0 .5rem",
    },
  }),
  withState("state", "setState", {
    showSelected: false,
    filter: new DataListFilter(),
  }),
  mapPropsStream((propsStream) => {
    const orderListStream = propsStream
      .map((props) => ({
        orders: props.orders.toList(),
        filter: props.state.filter,
        showSelected: props.state.showSelected,
        selectedOrders: props.selectedOrders,
      }))
      .distinctUntilChanged(isEqualData)
      .map((props) => {
        const { orders, selectedOrders, filter, showSelected } = props;
        const hasSelected = selectedOrders.size > 0;

        const orderBy = fp.toPath(filter.getOrderBy());
        const orderByAsc = filter.isOrderByAsc();
        const searchBy = searchTextToSet(filter.getSearch());

        const shouldFilterSelected = hasSelected && showSelected;
        const shouldFilterBySearch = searchBy.size > 0;

        const orderList =
          !shouldFilterSelected && !shouldFilterBySearch
            ? orders
            : orders.filter((x) => {
                if (shouldFilterBySearch) {
                  return searchBy.has(x.get("number"));
                }

                if (shouldFilterSelected) {
                  return selectedOrders.has(x.get("number"));
                }

                return true;
              });

        const shouldSortByField = orderBy.length > 0;
        const shouldSortBySelection = hasSelected && !showSelected;

        return orderList.sort((a, b) => {
          if (shouldSortBySelection) {
            const aSelected = selectedOrders.has(a.get("number"));
            const bSelected = selectedOrders.has(b.get("number"));

            if (aSelected !== bSelected) {
              return aSelected ? -1 : 1;
            }
          }

          if (shouldSortByField) {
            const aValue = fp.trim(a.getIn(orderBy));
            const bValue = fp.trim(b.getIn(orderBy));

            return aValue.localeCompare(bValue) * (orderByAsc ? 1 : -1);
          }

          const aFailed = Boolean(a.get("failed"));
          const bFailed = Boolean(b.get("failed"));

          const aFound = a.has("hash");
          const bFound = b.has("hash");

          const aLoading = !aFound && !aFailed;
          const bLoading = !bFound && !bFailed;

          if (aLoading !== bLoading) {
            return aLoading ? -1 : 1;
          }

          if (aFailed !== bFailed) {
            return aFailed ? -1 : 1;
          }

          if (aFound && bFound) {
            return b.get("hash_time") - a.get("hash_time");
          }

          return 0;
        });
      })
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(orderListStream, (props, orderList) => ({
      ...props,
      orderList,
    }));
  }),
  pureComponent(fp.pick(["state", "orderList", "selectedOrders"])),
);

OrderSortingTable.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,
  orderList: PropTypes.instanceOf(List),

  headerActions: PropTypes.node,
  cardActionIcons: PropTypes.node,
  showBatchUpdate: PropTypes.bool,
  showAllButton: PropTypes.bool,

  onOrdersSelect: PropTypes.func.isRequired,

  onOrderClick: PropTypes.func.isRequired,
  onCancelRegistry: PropTypes.func,
  onReloadClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onBatchStatusUpdate: PropTypes.func.isRequired,

  orders: PropTypes.instanceOf(Collection).isRequired,
  selectedOrders: PropTypes.instanceOf(Set).isRequired,
  getLocalisationMessage: PropTypes.func,
  statusMessages: PropTypes.instanceOf(Map),
};

OrderSortingTable.defaultProps = {
  showAllButton: true,
  showBatchUpdate: false,
};

function OrderSortingTable(props) {
  const { state, classes, selectedOrders, orderList, getLocalisationMessage } =
    props;

  return (
    <div className={classes.root}>
      <DataList
        isLoading={false}
        withFooter={false}
        withHeader={true}
        overscanRowCount={10}
        maxSearchItems={Infinity}
        filter={state.filter}
        onFilterChange={(x) => props.setState(fp.set("filter", x))}
        rowCount={orderList.size}
        totalCount={orderList.size}
        rowGetter={(row) => orderList.get(row.index)}
        selectedRowCount={selectedOrders.size}
        cardActionIcons={props.cardActionIcons}
        altHeader={
          <FlexBox align="center">
            {selectedOrders.size > 0 && (
              <div>
                {props.showBatchUpdate && (
                  <CustomButton
                    className={classes.btn}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => props.onBatchStatusUpdate()}
                  >
                    {getLocalisationMessage("prepared_for_transit_button")}
                  </CustomButton>
                )}
                {props.showAllButton && (
                  <CustomButton
                    className={classes.btn}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() =>
                      props.setState(
                        fp.set("showSelected", !state.showSelected),
                      )
                    }
                  >
                    {state.showSelected
                      ? getLocalisationMessage("show_all")
                      : getLocalisationMessage("show_selected")}
                  </CustomButton>
                )}
              </div>
            )}

            {props.headerActions}
          </FlexBox>
        }
      >
        {orderList.size > 0 && (
          <DataListCheckbox
            allRowsSelected={() => selectedOrders.size === orderList.size}
            rowSelected={(row) =>
              selectedOrders.has(row.cellData.get("number"))
            }
            onRowSelect={(row) => {
              if (row.selected) {
                props.setState(fp.set("filter", state.filter.setSearch(null)));
              }

              props.onOrdersSelect(
                row.selected
                  ? selectedOrders.add(row.cellData.get("number"))
                  : selectedOrders.delete(row.cellData.get("number")),
              );
            }}
            onAllRowsSelect={(selected) => {
              if (selected) {
                props.setState(fp.set("filter", state.filter.setSearch(null)));
              }

              props.onOrdersSelect(
                !selected
                  ? Set()
                  : orderList.map((x) => x.get("number")).toSet(),
              );
            }}
          />
        )}

        <DataListColumn
          width={100}
          disableSort={true}
          label={getLocalisationMessage("barcode", "Barcode")}
          cellRenderer={(row) => (
            <LinkButton
              onClick={() =>
                props.onOrderClick(row.cellData.getIn(["info", "id"]))
              }
            >
              {row.cellData.getIn(["info", "barcode"])}
            </LinkButton>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("type")}
          dataKey="info.type"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {getLocalisationMessage(row.cellData.getIn(["info", "type"]))}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("warehouse")}
          dataKey="info.warehouse.name"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {row.cellData.getIn(["info", "warehouse", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("destination_warehouse")}
          dataKey="info.to_warehouse.name"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {row.cellData.getIn(["info", "to_warehouse", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("from_city", "From City")}
          dataKey="info.package.from_jurisdiction"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={
                  row.cellData.getIn(["info", "from_jurisdiction", "name"]) ||
                  NA
                }
                secondLine={
                  row.cellData.getIn(["info", "from_postcode", "name"]) || NA
                }
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("to_city", "To City")}
          dataKey="info.package.to_jurisdiction"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={
                  row.cellData.getIn(["info", "to_jurisdiction", "name"]) || NA
                }
                secondLine={
                  row.cellData.getIn(["info", "to_postcode", "name"]) || NA
                }
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("weight")}
          dataKey="info.weight"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {formatWeight(row.cellData.getIn(["info", "weight"]))}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("created_time")}
          dataKey="info.created_time"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <DateTimeCell
                date={row.cellData.getIn(["info", "created_date"])}
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("flow", "Flow")}
          dataKey="info.flow"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {getLocalisationMessage(
                formatText(row.cellData.getIn(["info", "flow"])),
              )}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("status")}
          dataKey="info.status"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={
                  <span
                    style={{
                      color: "#FF9800",
                    }}
                  >
                    {formatOrderStatusCodeForLocalisation(
                      row.cellData.getIn(["info", "status"]),
                      getLocalisationMessage,
                    )}
                  </span>
                }
                secondLine={formatDateDistanceToNow(
                  row.cellData.getIn(["info", "last_status_date"]),
                  "N/A",
                  getLocalisationMessage,
                )}
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("action")}
          justifyContent="center"
          cellRenderer={(row) =>
            row.cellData.get("info") ? (
              <div>
                <LinkButton
                  onClick={() =>
                    props.onCancelRegistry(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("cancel_registry")}
                </LinkButton>
              </div>
            ) : row.cellData.get("failed") ? (
              <div>
                <LinkButton
                  onClick={() =>
                    props.onReloadClick(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("reload")}
                </LinkButton>{" "}
                /{" "}
                <LinkButton
                  onClick={() =>
                    props.onRemoveClick(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("remove")}
                </LinkButton>
              </div>
            ) : (
              <Text type={MUTED}>{getLocalisationMessage("Loading")}</Text>
            )
          }
        />
      </DataList>
    </div>
  );
}

export default enhancer(OrderSortingTable);
