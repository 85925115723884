import React, { useEffect, useState } from "react";
import {
  CONSOLIDATED,
  CONTAINER,
  ORDER,
  ROUTE,
  SIMPLE,
} from "../../../constants/OrderType";
import AdminOrderOfflineOutboundSortingContainer from "./AdminOrderOfflineOutboundSortingContainer";
import { totalSortingShipment } from "../../../api/v2/admin/AdminOrderSortingApi";
import AdminOrderOfflineOutboundRegistrySortingContainer from "./AdminOrderOfflineOutboundRegistrySortingContainer";
import AdminOfflineOrderOutboundConsolidatedContainerWrapper from "./AdminOfflineOrderOutboundConsolidatedContainerWrapper";
import AdminOfflineOrderOutboundContainerContainerWrapper from "./AdminOfflineOrderOutboundContainerContainerWrapper";
import AdminOfflineOrderOutboundRoutesContainerWrapper from "./AdminOfflineOrderOutboundRoutesContainerWrapper";
import PropTypes from "prop-types";
import { compose, getContext } from "recompose";
import fp from "lodash/fp";
import { getValue } from "../../../helpers/DataUtils";

function AdminOfflineOutboundWrapper({ location, setLocationQuery }) {
  const savedFilter = JSON.parse(localStorage.getItem("filter", {}));

  const [tab, setTab] = useState(getValue(location, "query.tab") || ORDER);
  const [refreshTab, setRefreshTab] = useState(null);
  const onTabChange = value => {
    setLocationQuery(fp.set("tab", value));
    setTab(value);
    setRefreshTab(prev => !prev);
  };

  const [tabCount, setTabCount] = useState({
    [ORDER]: 0,
    [ROUTE]: 0,
    [CONTAINER]: 0,
    [SIMPLE]: 0,
    [CONSOLIDATED]: 0,
  });

  useEffect(() => {
    totalSortingShipment().then(res => {
      if (res) {
        setTabCount(prev => ({
          ...prev,
          ...res,
        }));
      }
    });
  }, [refreshTab]);

  return tab === ORDER ? (
    <AdminOrderOfflineOutboundSortingContainer
      onTabChange={onTabChange}
      savedFilter={savedFilter}
      tabCount={tabCount}
      setRefreshTab={setRefreshTab}
      location={location}
    />
  ) : tab === SIMPLE ? (
    <AdminOrderOfflineOutboundRegistrySortingContainer
      onTabChange={onTabChange}
      tabCount={tabCount}
      setRefreshTab={setRefreshTab}
      location={location}
    />
  ) : tab === CONSOLIDATED ? (
    <AdminOfflineOrderOutboundConsolidatedContainerWrapper
      onTabChange={onTabChange}
      tabCount={tabCount}
      setRefreshTab={setRefreshTab}
      location={location}
    />
  ) : tab === CONTAINER ? (
    <AdminOfflineOrderOutboundContainerContainerWrapper
      onTabChange={onTabChange}
      tabCount={tabCount}
      setRefreshTab={setRefreshTab}
      location={location}
    />
  ) : (
    <AdminOfflineOrderOutboundRoutesContainerWrapper
      onTabChange={onTabChange}
      tabCount={tabCount}
      setRefreshTab={setRefreshTab}
      location={location}
    />
  );
}

AdminOfflineOutboundWrapper.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
};

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

export default enhancer(AdminOfflineOutboundWrapper);
