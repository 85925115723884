import React, { useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import AppTitle from "../../components/deprecated/AppTitle";
import DashboardFilterForm from "../../components/dashboard-core/DashbordFilterForm";
import { formatDateTimeToUrl, safeParseDate } from "../../helpers/FormatUtils";
import { endOfToday, startOfToday, subDays } from "date-fns";
import AdminOrderCountByRegionChartWrapper from "../../components/dashboard-core/AdminOrderCountByRegionChartWrapper";
import { getValue } from "../../helpers/DataUtils";
import AdminOrderCountByServiceTypeChartWrapper from "../../components/dashboard-core/AdminOrderCountByServiceTypeChartWrapper";
import { getUser, getUserWarehouseId } from "../../reducers/ProfileReducer";
import AdminOrderCountByMonthChartWrapper from "../../components/dashboard-core/AdminOrderCountByMonthChartWrapper";
import AdminOrderTotalCountByServiceTypeChartWrapper from "../../components/dashboard-core/AdminOrderTotalCountByServiceTypeChartWrapper";
import {
  getOrderCountByJurisdictionIn,
  getOrderCountByJurisdictionOut,
  getOrderCountByPaymentType,
  getOrderCountByPaymentTypeCOD,
  getOrderCountByServiceType,
  getOrderWeightByServiceType,
  getOrderWeightCategoryByServiceType,
} from "../../api/admin/AdminDashboardNewApi";
import AdminOrderCountByPaymentTypeChartWrapper from "../../components/dashboard-core/AdminOrderCountByPaymentTypeChartWrapper";
import AdminOrderCreatedChargeItemsChartWrapper from "../../components/dashboard-core/AdminOrderCreatedChargeItemsChartWrapper";
import AdminOrderWeightChartWrapper from "../../components/dashboard-core/AdminOrderWeightChartWrapper";
import AdminUserCountByRoleChartWrapper from "../../components/dashboard-core/AdminUserCountByRoleChartWrapper";
import AdminOrderCountByDeviceChartWrapper from "../../components/dashboard-core/AdminOrderCountByDeviceChartWrapper";
import AdminOrderCountByDeliveredTypeChartWrapper from "../../components/dashboard-core/AdminOrderCountByDeliveredTypeChartWrapper";
import AdminOrderCountByStatusChartWrapper from "../../components/dashboard-core/AdminOrderCountByStatusChartWrapper";
import AdminOrderCountBySortingStatusChartWrapper from "../../components/dashboard-core/AdminOrderCountBySortingStatusChartWrapper";
import AdminOrderCountByJurisdictionChartWrapper from "../../components/dashboard-core/AdminOrderCountByJurisdictionChartWrapper";
import AdminOrderCountByDeliveredChartWrapper from "../../components/dashboard-core/AdminOrderCountByDeliveredChartWrapper";

const enhancer = compose(
  connect(
    state => ({
      jurisdictionId: getUser(state).getIn([
        "warehouse",
        "jurisdiction",
        "parent",
        "id",
      ]),
      warehouseId: getUserWarehouseId(state),
      currentLanguage: getCurrentLanguage(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
);

AdminDashboardContainer.propTypes = {
  getLocalisationMessage: PropTypes.func,
  jurisdictionId: PropTypes.number,
  currentLanguage: PropTypes.string,
  warehouseId: PropTypes.string,
};

function AdminDashboardContainer(props) {
  const { getLocalisationMessage, warehouseId } = props;

  const date = startOfToday();
  const date2 = endOfToday();

  const [filter, setFilter] = useState({
    from_date_time: formatDateTimeToUrl(subDays(date, 1)),
    to_date_time: formatDateTimeToUrl(subDays(date2, 1)),
    jurisdiction_id: props.jurisdictionId,
  });

  return (
    <AdminAppLayout
      slug="dashboard"
      title={getLocalisationMessage("dashboard", "Dashboard")}
    >
      <div style={{ marginTop: 10 }}>
        <AppTitle title={getLocalisationMessage("dashboard", "Dashboard")}>
          <DashboardFilterForm
            initialValues={{
              jurisdiction: {
                id: filter.jurisdiction_id,
              },
              fromDateTime: safeParseDate(filter.from_date_time),
              toDateTime: safeParseDate(filter.to_date_time),
            }}
            onSubmit={v =>
              setFilter({
                jurisdiction_id: getValue(v, "jurisdiction.id"),
                from_date_time: formatDateTimeToUrl(v.fromDateTime),
                to_date_time: formatDateTimeToUrl(v.toDateTime),
              })
            }
          />
        </AppTitle>
      </div>
      <FlexBox
        container={24}
        flex="none"
        direction="column"
        style={{ gap: 16 }}
      >
        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByMonthChartWrapper />
          </FlexBox>

          <FlexBox flex={true} container={8}>
            <AdminOrderTotalCountByServiceTypeChartWrapper />
          </FlexBox>
        </FlexBox>
        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByServiceTypeChartWrapper
              api={getOrderCountByServiceType}
              title={props.getLocalisationMessage(
                "order_count_by_shipment_type",
                "Order Count by Shipment Type",
              )}
              filter={filter}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByRegionChartWrapper filter={filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByPaymentTypeChartWrapper
              subTitle={props.getLocalisationMessage("in_total", "In Total")}
              api={getOrderCountByPaymentType}
              title={props.getLocalisationMessage(
                "order_create_by_payment_type_type",
                "Order create by payment type",
              )}
              filter={filter}
            />
          </FlexBox>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByPaymentTypeChartWrapper
              subTitle={props.getLocalisationMessage("cod", "COD")}
              api={getOrderCountByPaymentTypeCOD}
              title={props.getLocalisationMessage("cod", "COD")}
              filter={filter}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCreatedChargeItemsChartWrapper filter={filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderWeightChartWrapper
              api={getOrderWeightByServiceType}
              filter={filter}
              weight={true}
              title={props.getLocalisationMessage(
                "total_weight_by_shipment_type_kg",
                "Total weight by shipment type (kg)",
              )}
            />
          </FlexBox>
          <FlexBox flex={true} container={8}>
            <AdminOrderWeightChartWrapper
              title={props.getLocalisationMessage(
                "order_count_by_weight_range",
                "Order count by weight range",
              )}
              api={getOrderWeightCategoryByServiceType}
              filter={filter}
            />
          </FlexBox>
        </FlexBox>
        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminUserCountByRoleChartWrapper
              warehouseId={warehouseId}
              filter={filter}
            />
          </FlexBox>

          <FlexBox flex={true} container={8}>
            <AdminOrderCountByDeviceChartWrapper filter={filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByDeliveredTypeChartWrapper filter={filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByDeliveredChartWrapper filter={filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByStatusChartWrapper filter={filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountBySortingStatusChartWrapper filter={filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByJurisdictionChartWrapper
              api={getOrderCountByJurisdictionIn}
              filter={filter}
              currentLanguage={props.currentLanguage}
            />
          </FlexBox>
          <FlexBox flex={true} container={8}>
            <AdminOrderCountByJurisdictionChartWrapper
              api={getOrderCountByJurisdictionOut}
              filter={filter}
              out={true}
              currentLanguage={props.currentLanguage}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDashboardContainer);
