import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { ObservableCache } from "../../helpers/loading-cache";
import fp from "lodash/fp";
import { fromJS } from "immutable";
import DataListFilter from "../../helpers/DataListFilter";

const INTERNATIONAL_SETTINGS_FORMAT_URL = `${API_ROOT_URL}/admin/international/settings/format`;
const INTERNATIONAL_SETTINGS_FORMAT_ITEM_URL = `${API_ROOT_URL}/admin/international/settings/format/%s`;

const INTERNATIONAL_SETTINGS_SUBCLASS_URL = `${API_ROOT_URL}/admin/international/settings/subclass`;
const INTERNATIONAL_SETTINGS_SUBCLASS_ITEM_URL = `${API_ROOT_URL}/admin/international/settings/subclass/%s`;

const INTERNATIONAL_SETTINGS_TYPE_URL = `${API_ROOT_URL}/admin/international/settings/type`;
const INTERNATIONAL_SETTINGS_TYPE_ITEM_URL = `${API_ROOT_URL}/admin/international/settings/type/%s`;

const INTERNATIONAL_SETTINGS_REGION_URL = `${API_ROOT_URL}/admin/international/settings/region`;
const INTERNATIONAL_SETTINGS_REGION_ITEM_URL = `${API_ROOT_URL}/admin/international/settings/region/%s`;
const INTERNATIONAL_SETTINGS_REGION_ITEM_WITH_CODE_URL = `${API_ROOT_URL}/admin/international/settings/region_by_code/%s`;

const INTERNATIONAL_SETTINGS_REGION_CHOOSE = `${API_ROOT_URL}/admin/international/settings/region/choose`;
const INTERNATIONAL_SETTINGS_TYPE_CHOOSE = `${API_ROOT_URL}/admin/international/settings/type/choose`;
const INTERNATIONAL_SETTINGS_SUBCLASS_CHOOSE = `${API_ROOT_URL}/admin/international/settings/subclass/choose`;
const INTERNATIONAL_SETTINGS_FORMAT_CHOOSE = `${API_ROOT_URL}/admin/international/settings/format/choose`;

// formats

export const getInternationalSettingsFormatList = params =>
  api.get(INTERNATIONAL_SETTINGS_FORMAT_URL, { params });

export const createInternationalSettingsFormat = body =>
  api.post(INTERNATIONAL_SETTINGS_FORMAT_URL, { body });

export const updateInternationalSettingsFormat = (id, body) =>
  api.put(sprintf(INTERNATIONAL_SETTINGS_FORMAT_ITEM_URL, id), { body });

export const deleteInternationalSettingsFormat = id =>
  api.delete(sprintf(INTERNATIONAL_SETTINGS_FORMAT_ITEM_URL, id));

export const getInternationalSettingsFormatItem = id =>
  api.get(sprintf(INTERNATIONAL_SETTINGS_FORMAT_ITEM_URL, id));

const formatCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(INTERNATIONAL_SETTINGS_FORMAT_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getFormatCache = id => formatCache.get(id);

export const getInternationalFormatChoose = (request: DataListFilter) =>
  api.getStream(INTERNATIONAL_SETTINGS_FORMAT_CHOOSE, {
    params: request.getDefinedValues(),
  });

// subclasses

export const getInternationalSettingsSubclassesList = params =>
  api.get(INTERNATIONAL_SETTINGS_SUBCLASS_URL, { params });

export const createInternationalSettingsSubclasses = body =>
  api.post(INTERNATIONAL_SETTINGS_SUBCLASS_URL, { body });

export const updateInternationalSettingsSubclasses = (id, body) =>
  api.put(sprintf(INTERNATIONAL_SETTINGS_SUBCLASS_ITEM_URL, id), { body });

export const deleteInternationalSettingsSubclasses = id =>
  api.delete(sprintf(INTERNATIONAL_SETTINGS_SUBCLASS_ITEM_URL, id));

export const getInternationalSettingsSubclassesItem = id =>
  api.get(sprintf(INTERNATIONAL_SETTINGS_SUBCLASS_ITEM_URL, id));

const subclasesCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(INTERNATIONAL_SETTINGS_FORMAT_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getSubclassesCache = id => subclasesCache.get(id);

export const getInternationalSubclassesChoose = (request: DataListFilter) =>
  api.getStream(INTERNATIONAL_SETTINGS_SUBCLASS_CHOOSE, {
    params: request.getDefinedValues(),
  });

// types

export const getInternationalSettingsTypeList = params =>
  api.get(INTERNATIONAL_SETTINGS_TYPE_URL, { params });

export const createInternationalSettingsType = body =>
  api.post(INTERNATIONAL_SETTINGS_TYPE_URL, { body });

export const updateInternationalSettingsType = (id, body) =>
  api.put(sprintf(INTERNATIONAL_SETTINGS_TYPE_ITEM_URL, id), { body });

export const deleteInternationalSettingsType = id =>
  api.delete(sprintf(INTERNATIONAL_SETTINGS_TYPE_ITEM_URL, id));

export const getInternationalSettingsTypeItem = id =>
  api.get(sprintf(INTERNATIONAL_SETTINGS_TYPE_ITEM_URL, id));

const typesCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(INTERNATIONAL_SETTINGS_TYPE_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getTypeCache = id => typesCache.get(id);

export const getInternationalTypeChoose = (request: DataListFilter) =>
  api.getStream(INTERNATIONAL_SETTINGS_TYPE_CHOOSE, {
    params: request.getDefinedValues(),
  });

// region

export const getInternationalSettingsRegionList = params =>
  api.get(INTERNATIONAL_SETTINGS_REGION_URL, { params });

export const createInternationalSettingsRegion = body =>
  api.post(INTERNATIONAL_SETTINGS_REGION_URL, { body });

export const updateInternationalSettingsRegion = (id, body) =>
  api.put(sprintf(INTERNATIONAL_SETTINGS_REGION_ITEM_URL, id), { body });

export const deleteInternationalSettingsRegion = id =>
  api.delete(sprintf(INTERNATIONAL_SETTINGS_REGION_ITEM_URL, id));

export const getInternationalSettingsRegionItem = id =>
  api.get(sprintf(INTERNATIONAL_SETTINGS_REGION_ITEM_URL, id));

const regionCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(INTERNATIONAL_SETTINGS_REGION_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getCacheRegion = id => regionCache.get(id);
export const getCacheRegionCode = code =>
  api.get(sprintf(INTERNATIONAL_SETTINGS_REGION_ITEM_WITH_CODE_URL, code));

export const getInternationalRegionChoose = (request: DataListFilter) =>
  api.getStream(INTERNATIONAL_SETTINGS_REGION_CHOOSE, {
    params: request.getDefinedValues(),
  });

// letters

const INTERNATIONAL_LETTER_URL = `${API_ROOT_URL}/admin/international/letter`;
const INTERNATIONAL_LETTER_ITEM_URL = `${API_ROOT_URL}/admin/international/letter/%s`;

export const getInternationalLetterList = params =>
  api.get(INTERNATIONAL_LETTER_URL, { params });

export const getInternationalLetterItem = id =>
  api.get(sprintf(INTERNATIONAL_LETTER_ITEM_URL, id));

export const createInternationalLetter = body =>
  api.post(INTERNATIONAL_LETTER_URL, { body });

export const updateInternationalLetter = (id, body) =>
  api.put(sprintf(INTERNATIONAL_LETTER_ITEM_URL, id), { body });

export const deleteInternationalLetter = id =>
  api.delete(sprintf(INTERNATIONAL_LETTER_ITEM_URL, id));

// parcel

const INTERNATIONAL_PARCEL_URL = `${API_ROOT_URL}/admin/international/parcel`;
const INTERNATIONAL_PARCEL_ITEM_URL = `${API_ROOT_URL}/admin/international/parcel/%s`;

const INTERNATIONAL_EMPTY_BAGS_URL = `${API_ROOT_URL}/admin/international/empty_bag`;
const INTERNATIONAL_EMPTY_BAGS_ITEM_URL = `${API_ROOT_URL}/admin/international/empty_bag/%s`;

export const getInternationalParcelList = params =>
  api.get(INTERNATIONAL_PARCEL_URL, { params });

export const getInternationalParcelItem = id =>
  api.get(sprintf(INTERNATIONAL_PARCEL_ITEM_URL, id));

export const createInternationalParcel = body =>
  api.post(INTERNATIONAL_PARCEL_URL, { body });

export const updateInternationalParcel = (id, body) =>
  api.put(sprintf(INTERNATIONAL_PARCEL_ITEM_URL, id), { body });

export const deleteInternationalParcel = id =>
  api.delete(sprintf(INTERNATIONAL_PARCEL_ITEM_URL, id));

export const getInternationalEmptyBagsList = params =>
  api.get(INTERNATIONAL_EMPTY_BAGS_URL, { params });

export const getInternationalEmptyBagsItem = id =>
  api.get(sprintf(INTERNATIONAL_EMPTY_BAGS_ITEM_URL, id));

export const createInternationalEmptyBags = body =>
  api.post(INTERNATIONAL_EMPTY_BAGS_URL, { body });

export const updateInternationalEmptyBags = (id, body) =>
  api.put(sprintf(INTERNATIONAL_EMPTY_BAGS_ITEM_URL, id), { body });

export const deleteInternationalEmptyBags = id =>
  api.delete(sprintf(INTERNATIONAL_EMPTY_BAGS_ITEM_URL, id));

// tariff

const INTERNATIONAL_IN_TARIFF_URL = `${API_ROOT_URL}/admin/international/tariff/in`;
const INTERNATIONAL_IN_TARIFF_ITEM_URL = `${API_ROOT_URL}/admin/international/tariff/in/%s`;
const INTERNATIONAL_IN_TARIFF_CALCULATE_URL = `${API_ROOT_URL}/admin/international/tariff/calculate`;

export const getInternationalInTariffList = params =>
  api.get(INTERNATIONAL_IN_TARIFF_URL, { params });

export const getInternationalInTariffCalculate = params =>
  api.get(INTERNATIONAL_IN_TARIFF_CALCULATE_URL, { params });

export const getInternationalInTariffItem = id =>
  api.get(sprintf(INTERNATIONAL_IN_TARIFF_ITEM_URL, id));

export const createInternationalInTariff = body =>
  api.post(INTERNATIONAL_IN_TARIFF_URL, { body });

export const updateInternationalInTariff = (id, body) =>
  api.put(sprintf(INTERNATIONAL_IN_TARIFF_ITEM_URL, id), { body });

export const deleteInternationalInTariff = id =>
  api.delete(sprintf(INTERNATIONAL_IN_TARIFF_ITEM_URL, id));

const INTERNATIONAL_OUT_TARIFF_URL = `${API_ROOT_URL}/admin/international/tariff`;
const INTERNATIONAL_OUT_TARIFF_ITEM_URL = `${API_ROOT_URL}/admin/international/tariff/%s`;

export const getInternationalOutTariffList = params =>
  api.get(INTERNATIONAL_OUT_TARIFF_URL, { params });

export const getInternationalOutTariffItem = id =>
  api.get(sprintf(INTERNATIONAL_OUT_TARIFF_ITEM_URL, id));

export const createInternationalOutTariff = body =>
  api.post(INTERNATIONAL_OUT_TARIFF_URL, { body });

export const updateInternationalOutTariff = (id, body) =>
  api.put(sprintf(INTERNATIONAL_OUT_TARIFF_ITEM_URL, id), { body });

export const deleteInternationalOutTariff = id =>
  api.delete(sprintf(INTERNATIONAL_OUT_TARIFF_ITEM_URL, id));
