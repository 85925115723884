import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    averageRow: { fontWeight: "bold" },
    reportTable: {
      "& th": { fontWeight: "bold", color: "#000000", textAlign: "center" },
      "& td": { textAlign: "center" },
    },
  }),
);

PendingCODPerDriverReport.propTypes = {
  classes: PropTypes.object,
  showTable: PropTypes.bool,
  reportList: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function PendingCODPerDriverReport(props) {
  const { classes, getLocalisationMessage } = props;
  const tableRow = [];

  if (props.showTable) {
    props.reportList.forEach((item, key) => {
      tableRow.push(
        <TableRow key={key}>
          <TableCell>
            {fp.isEmpty(key) ? getLocalisationMessage("na", "N/A") : key}
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>,
      );

      item.forEach((item2, index) => {
        tableRow.push(
          <TableRow key={`${key}, child, ${index}`}>
            <TableCell />
            <TableCell>
              {fp.isEmpty(index) ? getLocalisationMessage("na", "N/A") : index}
            </TableCell>
            <TableCell>{formatNumber(item2.get("open_orders"))}</TableCell>
            <TableCell>
              {formatNumber(item2.get("cod_of_open_orders"))}
            </TableCell>
            <TableCell>{formatNumber(item2.get("unpaid_cod"))}</TableCell>
          </TableRow>,
        );
      });
    });
  }

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          {props.showTable && (
            <FlexBox>
              <Table className={classes.reportTable}>
                <TableHead displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                    <TableCell>
                      {getLocalisationMessage("courier_name", "Courier name")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage("driver_name", "Driver name")}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage(
                        "count_of_open_orders",
                        "Count of open orders",
                      )}
                    </TableCell>
                    ,
                    <TableCell>
                      {getLocalisationMessage(
                        "sum_of_cod_for_open",
                        "Sum of COD for Open",
                      )}{" "}
                      <br /> {getLocalisationMessage("orderov", "orders")}
                    </TableCell>
                    <TableCell>
                      {getLocalisationMessage(
                        "unpaid_cod_for_delivered",
                        "Unpaid COD for delivered",
                      )}{" "}
                      <br /> {getLocalisationMessage("orders", "orders")}{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody selectable={false} displayRowCheckbox={false}>
                  {tableRow}
                </TableBody>
              </Table>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(PendingCODPerDriverReport);
