import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  CardContent,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    margin: { marginTop: "175px" },
    rightAlign: { textAlign: "right" },
    headerColumnStyle: {
      textAlign: "center",
      color: "black",
      fontWeight: "bold",
      fontSize: "14px",
      paddingRight: "36px",
    },
    footerColumnStyle: {
      textAlign: "right",
      color: "black",
      fontWeight: "bold",
      fontSize: "14px",
      paddingRight: "34px",
    },
    tableHeader: {
      top: "152px",
      position: "fixed",
      backgroundColor: "#fff",
      width: "100%",

      tr: {
        width: "100%",
      },
    },
    headerWidth: { width: "17%" },
    footerBack: {
      backgroundColor: "#e0e0e0",
      "& td": {
        maxWidth: "150px",
        overflow: "hidden",
        paddingLeft: "4px",
        paddingRight: "4px",
      },
    },
    table: {
      "& td, & th": {
        maxWidth: "150px",
        overflow: "hidden",
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      "& td:first-child, & th:first-child": { paddingLeft: "20px" },
    },
  }),
);

AdminFinanceReportTable.propTypes = {
  sheet: PropTypes.object,
  reportList: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminFinanceReportTable(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
  } = props;

  const grandTotal = props.reportList.get(
    getLocalisationMessage("grand_total", "Grand Total"),
  );

  const reportList = props.reportList.delete(
    getLocalisationMessage("grand_total", "Grand Total"),
  );

  return (
    <Card>
      <CardContent>
        <Table
          fixedHeader={false}
          fixedFooter={true}
          className={classes.margin}
        >
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow className={classes.table}>
              <TableCell className={classes.headerWidth} />,
              <TableCell className={classes.headerColumnStyle}>
                {getLocalisationMessage("number_of_orders", "Number of orders")}
                <br />{" "}
                {getLocalisationMessage("open_aging_more", "open (Aging more")}
                <br /> {getLocalisationMessage("than_7_days", "than 7 days)")}
              </TableCell>
              ,
              <TableCell className={classes.headerColumnStyle}>
                {getLocalisationMessage("number_of_cod", "Number of COD")}
                <br />{" "}
                {getLocalisationMessage(
                  "open_orders_aging",
                  "open orders (Aging",
                )}
                <br />
                {getLocalisationMessage(
                  "more_than_7_days",
                  "more than 7 days)",
                )}
              </TableCell>
              ,
              <TableCell className={classes.headerColumnStyle}>
                {getLocalisationMessage(
                  "amount_of_cod_for",
                  "Amount of COD for",
                )}
                <br />{" "}
                {getLocalisationMessage("open_cod_orders", "open cod orders")}
                <br />{" "}
                {getLocalisationMessage(
                  "aging_more_than_7",
                  "(Aging more than 7",
                )}
                <br /> {getLocalisationMessage("days)", "days)")}
              </TableCell>
              ,
              <TableCell className={classes.headerColumnStyle}>
                {getLocalisationMessage("number_of_open", "Number of open")}
                <br />{" "}
                {getLocalisationMessage("orders_created", "orders (Created")}
                <br />{" "}
                {getLocalisationMessage("within_7_days", "within 7 days)")}
              </TableCell>
              ,
              <TableCell className={classes.headerColumnStyle}>
                {getLocalisationMessage(
                  "amount_of_cod_for",
                  "Amount of COD for",
                )}
                <br />{" "}
                {getLocalisationMessage("open_cod_orders", "open cod orders")}
                <br />{" "}
                {getLocalisationMessage(
                  "created_within_7",
                  "(Created within 7",
                )}
                <br /> {getLocalisationMessage("days)", "days)")}
              </TableCell>
              ,
              <TableCell className={classes.headerColumnStyle}>
                {getLocalisationMessage(
                  "amount_of_cod_for",
                  "Amount of COD for",
                )}
                <br />{" "}
                {getLocalisationMessage(
                  "delivered_cod_orders",
                  "delivered cod orders",
                )}
                <br />{" "}
                {getLocalisationMessage(
                  "delivered_within_7",
                  "(Delivered within 7",
                )}
                <br /> {getLocalisationMessage("days)", "days)")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            {reportList
              .map((item, key) => (
                <TableRow className={classes.table} key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell className={classes.rightAlign}>
                    {formatNumber(item.get("first"))}
                  </TableCell>
                  <TableCell className={classes.rightAlign}>
                    {formatNumber(item.get("second"))}
                  </TableCell>
                  <TableCell className={classes.rightAlign}>
                    {formatNumber(item.get("third"))}
                  </TableCell>
                  <TableCell className={classes.rightAlign}>
                    {formatNumber(item.get("forth"))}
                  </TableCell>
                  <TableCell className={classes.rightAlign}>
                    {formatNumber(item.get("fifth"))}
                  </TableCell>
                  <TableCell className={classes.rightAlign}>
                    {formatNumber(item.get("sixth"))}
                  </TableCell>
                </TableRow>
              ))
              .toArray()}
            <TableRow className={classes.footerBack} key="total">
              <TableCell className={classes.footerColumnStyle}>
                {getLocalisationMessage("grand_total", "Grand Total")}
              </TableCell>
              <TableCell className={classes.footerColumnStyle}>
                {formatNumber(grandTotal.get("first"))}
              </TableCell>
              <TableCell className={classes.footerColumnStyle}>
                {formatNumber(grandTotal.get("second"))}
              </TableCell>
              <TableCell className={classes.footerColumnStyle}>
                {formatNumber(grandTotal.get("third"))}
              </TableCell>
              <TableCell className={classes.footerColumnStyle}>
                {formatNumber(grandTotal.get("forth"))}
              </TableCell>
              <TableCell className={classes.footerColumnStyle}>
                {formatNumber(grandTotal.get("fifth"))}
              </TableCell>
              <TableCell className={classes.footerColumnStyle}>
                {formatNumber(grandTotal.get("sixth"))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default enhancer(AdminFinanceReportTable);
