import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createCityItem } from "../../api/admin/AdminShopCityApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import ShopCityForm from "../../components/shop-core/ShopCityForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    content: {
      maxWidth: "400px",
      minWidth: "400px",
      backgroundColor: "#fff",
      minHeight: "auto",
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
);

AdminShopCityCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminShopCityCreateDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.content}
      title={props.getLocalisationMessage("create_city", "Create City")}
    >
      <ShopCityForm
        onRequestClose={props.onRequestClose}
        onSubmit={values =>
          createCityItem(
            toSnakeCase({
              ...values,
              code: _.snakeCase(values.name),
            }),
          ).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully saved",
            ),
          );
          props.onRequestClose();
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminShopCityCreateDialogWrapper);
