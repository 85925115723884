import React from "react";
import _ from "lodash";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedBank,
  getBankPredictions,
  createReconcileSettings,
  uploadBankReconcileSettingsFile,
  getBankReconcileSettingsListBeta,
} from "../../api/admin/AdminBankApi";
import Tabs from "../../components/ui-core/Tabs";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import BankAndTypeForm from "../../components/bank-core/BankAndTypeForm";
import BankSettingsUploadForm from "../../components/bank-core/BankSettingsUploadForm";
import BankReconcileSettingsForm from "../../components/bank-core/BankReconcileSettingsForm";
import {
  toJSON,
  fromJSON,
  toBase64,
  fromBase64,
} from "../../../shared/helpers/DataSerializer";

const BANK_TYPE = "bank";
const UPLOAD_REPORT = "upload";
const CREATE_SETTINGS = "settings";

const tabs = [BANK_TYPE, UPLOAD_REPORT, CREATE_SETTINGS];

const valueSelector = formValueSelector("BankReconcileSettingsCreateForm");

const enhancer = compose(
  withState("isLoading", "setIsLoading", false),
  useSheet({
    paper: { minWidth: "600px", maxWidth: "600px" },
    maxWidth: { width: "100%" },
  }),
  connect(
    state => ({
      values: valueSelector(
        state,
        "bank",
        "type",
        "value_column_id",
        "merge_column_id",
        "reference_column_id",
        "parse_key",
        "allowed_difference",
        "include_values",
        "isParseKeyEnabled",
      ),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("data", isEqualData)
      .filter(props => !fp.isEmpty(props.data))
      .map(props => fromJSON(fromBase64(props.data)))
      .distinctUntilChanged(isEqualData)
      .startWith(null);

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues: initialValues
          ? {
              ...initialValues,
              merge_column_id: fp.toString(initialValues.merge_column_id),
              value_column_id: fp.toString(initialValues.value_column_id),
              transaction_date_column_id: fp.toString(
                initialValues.transaction_date_column_id,
              ),
              reference_column_id: fp.toString(
                initialValues.reference_column_id,
              ),
              include_values: parseString(initialValues.include_values),
              isParseKeyEnabled: !fp.isEmpty(initialValues.parse_key),
            }
          : null,
      }),
    );
  }),
  withState("submitting", "onSubmitting", false),
);

AdminBankReconcileSettingsCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  location: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  tab: PropTypes.oneOf(tabs),
  onTabChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  setLocationQuery: PropTypes.func,
  data: PropTypes.string,
  setLocationQueryFilter: PropTypes.func,

  submitting: PropTypes.bool,
  onSubmitting: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

AdminBankReconcileSettingsCreateDialogWrapper.defaultProps = {
  tab: BANK_TYPE,
};

function AdminBankReconcileSettingsCreateDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <div className={classes.maxWidth}>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <Tabs
          width={300}
          value={props.tab}
          onChange={(e, v) => props.onTabChange(v)}
          tabs={[
            {
              label: getLocalisationMessage("bank_&_type", "Bank & Type"),
              value: BANK_TYPE,
            },
            {
              label: getLocalisationMessage("upload", "Upload"),
              value: UPLOAD_REPORT,
            },
            {
              label: getLocalisationMessage(
                "create_settings",
                "Create Settings",
              ),
              value: CREATE_SETTINGS,
            },
          ]}
        />
      </FlexBox>

      <FlexBox flex="none" direction="column">
        {props.tab === BANK_TYPE && (
          <div>
            <BankAndTypeForm
              initialValues={{
                bank: { id: props.filter.getValue("bankId") },
                type: props.filter.getValue("type"),
              }}
              getCachedBank={getCachedBank}
              getBankPredictions={getBankPredictions}
              onSubmit={values => {
                props.onSubmitting(true);
                return new Promise((resolve, reject) => {
                  getBankReconcileSettingsListBeta(
                    new DataListFilter({
                      bankId: getValue(values.bank, "id"),
                      type: values.type,
                    }),
                  )
                    .catch(ResponseError.throw)
                    .then(response => {
                      const id = fp.get("data.id")(response);

                      if (id) {
                        const data = fp.get("data")(response);

                        props.setLocationQueryFilter(
                          props.filter.setValueMap({
                            bankId: getValue(values.bank, "id"),
                            type: values.type,
                            settingId: response.data.id,
                            data: toBase64(toJSON(data)),
                          }),
                        );

                        resolve(response);
                      } else {
                        props.setLocationQueryFilter(
                          props.filter.setValueMap({
                            bankId: getValue(values.bank, "id"),
                            type: values.type,
                          }),
                        );
                      }

                      reject(new Error({ status: null }));
                    });
                });
              }}
              onSubmitSuccess={() => {
                props.onSubmitting(false);
                props.onTabChange(CREATE_SETTINGS);
              }}
              onSubmitFail={() => {
                props.onSubmitting(false);
                props.onTabChange(UPLOAD_REPORT);
              }}
            />
          </div>
        )}

        {props.tab === UPLOAD_REPORT && (
          <BankSettingsUploadForm
            tab={props.tab}
            onClick={() => props.onTabChange(CREATE_SETTINGS)}
            onSubmit={fp.flow(
              values => {
                props.onSubmitting(true);
                return uploadBankReconcileSettingsFile(
                  {
                    ...values,
                    bankId: props.filter.getValue("bankId"),
                    type: props.filter.getValue("type"),
                  },
                  values.uploadFile,
                );
              },
              request => request.toPromise().catch(ResponseError.throw),
            )}
            onSubmitSuccess={response => {
              const id = fp.get("payload.data.id")(response);

              props.onSubmitting(false);

              if (id) {
                const data = fp.get("payload.data")(response);

                props.setLocationQueryFilter(
                  props.filter.setValueMap({
                    settingId: id,
                    data: toBase64(toJSON(data)),
                  }),
                );

                props.onSubmitSuccess(
                  getLocalisationMessage(
                    "the_file_successfully_uploaded",
                    "The File Successfully uploaded",
                  ),
                );

                return props.onTabChange(CREATE_SETTINGS);
              }

              return null;
            }}
            onSubmitFail={error => {
              props.onSubmitting(false);
              return props.onSubmitFail(error);
            }}
          />
        )}

        {props.tab === CREATE_SETTINGS && (
          <BankReconcileSettingsForm
            initialValues={props.initialValues}
            onSubmit={values => {
              props.onSubmitting(true);
              const isParseKeyEnabled = Boolean(values.isParseKeyEnabled);
              const data = _.omit(values, ["isParseKeyEnabled", "id"]);
              return createReconcileSettings({
                ...data,
                bank: { id: props.filter.getValue("bankId") },
                parse_key: isParseKeyEnabled ? values.parse_key : null,
                include_values: stringifyArray(values.include_values),
              }).catch(ResponseError.throw);
            }}
            onSubmitFail={error => {
              props.onSubmitting(false);
              return props.onSubmitFail(error);
            }}
            onSubmitSuccess={() => {
              props.onSubmitting(false);
              return props.onSubmitSuccess(
                getLocalisationMessage(
                  "successfully_saved",
                  "Successfully saved",
                ),
              );
            }}
          />
        )}
      </FlexBox>
    </div>
  );
}

export default enhancer(AdminBankReconcileSettingsCreateDialogWrapper);
