import React from "react";
import { compose, mapPropsStream, withHandlers, withState } from "recompose";
import Immutable, { fromJS, Map } from "immutable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getCurrentLanguage } from "../../reducers/LocalizationReducer";
import fp from "lodash/fp";
import { isEqualData } from "../../helpers/DataUtils";
import { changeLngLatToLatLng, parseMultipolygonLeaflet } from "../../helpers/MapPolygonUtils";
import { DataPolygon, DrawingControl } from "react-leflet-map-components";
import { formValueSelector, getFormValues, reduxForm } from "redux-form";
import { Observable } from "rxjs";
import JurisdictionMapFitBounds from "../jurisdiction-tree/JurisdictionMapFitBounds";
import { getJurisdictionItem } from "../../api/admin/AdminJurisdictionsApi";
import PageLoading from "../ui-core/PageLoading";
import { makeStyles } from "@material-ui/core/styles";

const getValues = getFormValues("JMItemForm");
const getFormValue = formValueSelector("JMItemForm");

const useStyles = makeStyles(() => ({
  map: { flex: "1 1 0%", zIndex: 1 },
}));

const enhancer = compose(
  connect(state => ({
    language: getCurrentLanguage(state),
    marketplaceId: getMarketplaceId(state),
    formValues: getValues(state),
    currentEditingArea: getFormValue(state, "currentEditingArea"),
  })),
  mapPropsStream(propsStream => {
    const regionPolygonStream = propsStream
      .map(fp.pick(["currentRegion"]))
      .distinctUntilChanged(isEqualData)
      .map(props =>
        props.currentRegion ? props.currentRegion.get("id", null) : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(id =>
        id
          ? getJurisdictionItem(id).catch(error => Observable.of({ error }))
          : Observable.of({}),
      )
      .startWith({})
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );

    const currentRegionParentStream = propsStream
      .map(fp.pick(["currentRegion"]))
      .distinctUntilChanged(isEqualData)
      .map(props =>
        props.currentRegion ? props.currentRegion.get("parent_id", null) : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(parentId =>
        parentId
          ? getJurisdictionItem(parentId).catch(error =>
              Observable.of({ error }),
            )
          : Observable.of({}),
      )
      .map(response => fromJS(response).getIn(["payload", "data"], Map()))
      .startWith(Map());

    return propsStream
      .combineLatest(
        regionPolygonStream,
        currentRegionParentStream,
        (props, currentPolygon, currentRegionParentArea) => ({
          currentPolygon: parseMultipolygonLeaflet(
            currentPolygon.getIn(["payload", "polygon"], []),
          ),
          isCurrentPolygonLoading: currentPolygon.get("pending"),
          currentRegionParentArea,
          ...props,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
  withState("removed", "onRemove", []),
  reduxForm({
    form: "JMItemForm",
    enableReinitialize: true,
  }),
  withHandlers({
    onAddPolygon: props => values => {
      const polygons = props.currentEditingArea || Immutable.List();
      const list = changeLngLatToLatLng(values.geometry.coordinates);
      props.change("polygonEdited", true);
      props.change(
        "currentEditingArea",
        polygons.push(Immutable.fromJS(list[0])),
      );
    },
    onEditPolygon: props => values => {
      const polygons = props.currentEditingArea || Immutable.List();
      const list = changeLngLatToLatLng(values.polygon.geometry.coordinates);
      props.change("polygonEdited", true);
      props.change(
        "currentEditingArea",
        polygons.set(values.idx, Immutable.fromJS(list[0])),
      );
    },
    onRemovePolygon: props => removalIndexes => {
      const polygons = props.currentEditingArea || Immutable.List();
      const newPolygons = polygons.filter(
        (value, index) => removalIndexes.indexOf(index) === -1,
      );
      props.change("polygonEdited", true);
      props.change("currentEditingArea", newPolygons);
    },
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream
        .map(fp.pick(["isEdit", "currentPolygon"]))
        .distinctUntilChanged(isEqualData)
        .filter(props => props.isEdit && props.currentPolygon)
        .withLatestFrom(propsStream)
        .do(([req, props]) => {
          props.change(
            "currentEditingArea",
            Immutable.fromJS(req.currentPolygon),
          );
        }),
    ).startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

JMAreasMap.propTypes = {
  language: PropTypes.string,

  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,

  removed: PropTypes.array,
  formValues: PropTypes.object,
  mapCenter: PropTypes.object,
  currentRegion: PropTypes.instanceOf(Map),
  currentRegionParentArea: PropTypes.instanceOf(Map),
  currentPolygon: PropTypes.array,
  isCurrentPolygonLoading: PropTypes.bool,
  currentEditingArea: PropTypes.instanceOf(Immutable.List),

  onRemove: PropTypes.func,
  onAddPolygon: PropTypes.func,
  onEditPolygon: PropTypes.func,
  onRemovePolygon: PropTypes.func,
};

JMAreasMap.defaultProps = {
  isEdit: false,
  isCreate: false,
};

function JMAreasMap(props) {
  const classes = useStyles();
  return (
    <LeafletMapWrapper className={classes.map} zoom={7}>
      <PageLoading isLoading={props.isCurrentPolygonLoading} />

      <DrawingControl
        position="topright"
        lang={props.language}
        allowSelfIntersection={true}
        snapMiddle={false}
        drawingModes={
          props.isEdit || props.isCreate
            ? ["drawPolygon", "editMode", "dragMode", "removalMode"]
            : []
        }
        onDrawComplete={({ shape, layer }) => {
          if (shape === "Polygon") {
            props.onAddPolygon(layer.toGeoJSON());
          }
        }}
        onGlobalRemoveModeToggled={({ enabled }) => {
          if (!enabled && props.removed.length > 0) {
            props.onRemovePolygon(props.removed);
            props.onRemove([]);
          }
        }}
      />

      {props.currentEditingArea &&
        props.currentEditingArea.map((x, idx) => (
          <DataPolygon
            key={`region-area-${idx}`}
            editable={
              props.currentRegion.get("parent_id", null) &&
              (props.isEdit || props.isCreate)
            }
            geometry={[x.toJS()]}
            stroke={true}
            weight={2}
            opacity={1}
            fill={true}
            fillOpacity={0.25}
            fillColor="#FF0C0C"
            color="#FF0C0C"
            onUpdate={({ shape, layer }) => {
              if (shape === "Polygon") {
                props.onEditPolygon({ idx, polygon: layer.toGeoJSON() });
              }
            }}
            onRemove={() => {
              const removedItems = props.removed;
              removedItems.push(idx);
              props.onRemove(fp.uniq(removedItems));
            }}
            onDragEnd={({ layer, shape }) => {
              if (shape === "Polygon") {
                props.onEditPolygon({ idx, polygon: layer.toGeoJSON() });
              }
            }}
          />
        ))}

      {props.isCreate && props.currentPolygon && props.currentPolygon.map((i, index) => (
        <DataPolygon
          key={`region-static-area-create-${index}`}
          editable={false}
          geometry={[i]}
          stroke={true}
          weight={2}
          opacity={1}
          fill={false}
          fillOpacity={0.25}
          fillColor="#000000"
          color="#000000"
        />
      ))}

      {props.currentRegionParentArea &&
        props.currentRegionParentArea.get("polygon", null) && (
          <DataPolygon
            key="region-parent-static-area-create"
            editable={false}
            geometry={parseMultipolygonLeaflet(
              props.currentRegionParentArea.get("polygon", []),
            )}
            stroke={true}
            weight={2}
            opacity={1}
            fill={false}
            fillOpacity={0}
            fillColor="#000000"
            color="#000000"
          />
        )}

      {!(props.isCreate || props.isEdit) && props.currentPolygon && props.currentPolygon.map((i, index) => (
        <DataPolygon
          key={`region-static-area-${index}`}
          editable={false}
          geometry={[i]}
          stroke={true}
          weight={2}
          opacity={1}
          fill={true}
          fillOpacity={0.25}
          fillColor="#4CAF50"
          color="#4CAF50"
        />
      ))}

      <JurisdictionMapFitBounds polygon={props.currentPolygon || []}/>
    </LeafletMapWrapper>
  );
}

export default enhancer(JMAreasMap);
