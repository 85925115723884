import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { mapSchema, types } from "../../shared/helpers/ObjectMapper";
import { ALL } from "../constants/AdminWarehouseFilterTypes";

export const warehouseFilterMapper = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),
    status: types.stringOr(ALL),
    jurisdiction_id: types.ID,
    parent_id: types.ID,
    postcode_id: types.ID,
  }),
);
