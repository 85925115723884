import React from "react";
import { OrderedSet } from "immutable";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { PanTo } from "react-google-map-components";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormCountryAutoComplete from "../form/FormCountryAutoComplete";
import FormVenueCityAutoComplete from "../form/FormVenueCityAutoComplete";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue } from "../../helpers/DataUtils";
import { formatText, parsePhone } from "../../helpers/FormatUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import { connect } from "react-redux";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { PanTo as LeafletPanTo } from "react-leflet-map-components";
import LeafletBrandMarker from "../maps/osm/BrandMaker";

const enhancer = compose(
  connect(state => ({
    mapProvider: getMapProvider(state),
  })),
  withContext(
    {
      getCachedVenueCity: PropTypes.func,
      getVenueCityPredictions: PropTypes.func,
    },
    props => ({
      getCachedVenueCity: props.getCachedVenueCity,
      getVenueCityPredictions: props.getVenueCityPredictions,
    }),
  ),
  reduxForm({ form: "AdminVenueAddressForm", enableReinitialize: true }),
  formValues("location", "country"),
);

const statusOptions = OrderedSet.of(ACTIVE, INACTIVE);

AdminVenueAddressForm.propTypes = {
  country: PropTypes.object,
  location: PropTypes.object,

  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  onDismissClick: PropTypes.func.isRequired,

  getCachedVenueCity: PropTypes.func.isRequired,
  getVenueCityPredictions: PropTypes.func.isRequired,
  mapProvider: PropTypes.string,
};

function AdminVenueAddressForm(props) {
  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <Card>
      <CardContent>
        <PageLoading isLoading={props.submitting} />
        <form autoComplete="off" onSubmit={props.handleSubmit}>
          <FlexBox container={8} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <FormTextField
                  name="contactName"
                  label="Contact Name"
                  fullWidth={true}
                />
                <FlexBox gutter={8} flex={true}>
                  {Boolean(!getValue(props.initialValues, "id")) && (
                    <FlexBox flex={true}>
                      <FormCountryAutoComplete
                        fullWidth={true}
                        name="country"
                        label="Country"
                      />
                    </FlexBox>
                  )}
                  <FlexBox flex={true}>
                    <FormGeoAutoComplete
                      fullWidth={true}
                      name="location"
                      countryCode={getValue(props.country, "code", "AE")}
                      label="Address"
                    />
                  </FlexBox>
                </FlexBox>
                <FormVenueCityAutoComplete
                  name="city"
                  fullWidth={true}
                  label="Select City"
                />
                <FormTextField name="street" label="Street" fullWidth={true} />
                <FormTextField
                  name="building"
                  label="Building"
                  fullWidth={true}
                />
                <FormTextField name="floor" label="Floor" fullWidth={true} />
                <FormTextField
                  name="apartment"
                  label="Apartment"
                  fullWidth={true}
                />
              </FlexBox>

              <FlexBox flex={true} direction="column">
                {isGoogleMapProvider ? (
                  <GoogleMapWrapper
                    onClick={event =>
                      props.change("location", {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                      })
                    }
                  >
                    {isValidCoordinates(props.location) && (
                      <div>
                        <PanTo latLng={props.location} />

                        <BrandMarker position={props.location} />
                      </div>
                    )}
                  </GoogleMapWrapper>
                ) : (
                  <LeafletMapWrapper
                    onClick={event =>
                      props.change("location", {
                        lat: event.latlng.lat,
                        lng: event.latlng.lng,
                      })
                    }
                  >
                    {isValidCoordinates(props.location) && (
                      <div>
                        <LeafletPanTo latLng={props.location} />
                        <LeafletBrandMarker position={props.location} />
                      </div>
                    )}
                  </LeafletMapWrapper>
                )}
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true} direction="column">
                  <FlexBox flex={true}>
                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true}>
                        <FormTextField
                          name="mobile"
                          label="Mobile"
                          fullWidth={true}
                          parse={parsePhone}
                        />
                      </FlexBox>
                      <FlexBox flex={true}>
                        <FormTextField
                          name="landline"
                          label="Landline"
                          fullWidth={true}
                        />
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} direction="column">
                    <FormCheckbox name="primary" label="Is Primary?" />
                    <FormCheckbox
                      name="deliverWithoutCall"
                      label="Deliver Without Call"
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <FormSelectField
                  name="status"
                  autoWidth={true}
                  fullWidth={true}
                  label="Status"
                  options={statusOptions}
                  formatOption={formatText}
                />
                <FormTextField
                  rows={4}
                  multiLine={true}
                  name="note"
                  label="Note"
                  fullWidth={true}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <CardActions>
            <Button
              variant="text"
              label="Dismiss"
              onClick={props.onDismissClick}
            />
            <Button variant="text" label="Save" type="submit" />
          </CardActions>
        </form>
      </CardContent>
    </Card>
  );
}

export default enhancer(AdminVenueAddressForm);
