import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import ReactAutoScroll from "../drivers-radar/ScrollToPosition";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import NewRadarSuggestedDriverItem from "./NewRadarSuggestedDriverItem";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import ResponseError from "../../helpers/ResponseError";
import { isEqualData } from "../../helpers/DataUtils";
import RadarLiveDriverFilterTabs from "./RadarLiveDriverFilterTabs";
import Text, { MUTED } from "../ui-core/Text";

export const ALL_TAB = "all_tab";
export const BUSY_TAB = "busy_tab";
export const AVAILABLE_TAB = "available_tab";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
  useSheet({
    container: {
      width: "470px",
      position: "relative",
    },
    pages: {
      fontSize: "13px",
      color: "#6f6a6a",
      fontFamily: "Blogger Sans",
    },
    headerContainer: {
      padding: "0",
      fontSize: "16px",
      fontFamily: "Blogger Sans",
    },
    listContainer: {
      padding: 0,
      fontSize: "16px",
      overflowY: "auto",
      overflowX: "hidden",
      fontFamily: "Blogger Sans",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgb(162 162 162 / 87%)",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgb(125 125 125 / 87%)",
      },
    },
    sizeInput: {
      fontSize: "12px",
      maxWidth: "48px",
      marginLeft: "8px",
      marginRight: "8px",
      borderBottom: "1px solid #eee",
      overflow: "hidden",
      "& input": { textAlign: "right" },
      "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button": {
        margin: 0,
        WebkitAppearance: "none",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const liveDriverLocationsStream = propsStream
      .map(fp.pick(["liveDriverLocations"]))
      .distinctUntilChanged(isEqualData)
      .map(({ liveDriverLocations }) => {
        const allDrivers = new Immutable.OrderedMap().asMutable();
        const busyDrivers = new Immutable.OrderedMap().asMutable();
        const availableDrivers = new Immutable.OrderedMap().asMutable();

        liveDriverLocations.forEach(item => {
          const location = item.get("location");

          if (location.get("busy")) {
            busyDrivers.set(location.get("driver_id"), location);
          } else {
            availableDrivers.set(location.get("driver_id"), location);
          }
          allDrivers.set(location.get("driver_id"), location);
        });

        return Immutable.Map({
          all: allDrivers.asImmutable(),
          busy: busyDrivers.asImmutable(),
          available: availableDrivers.asImmutable(),
        });
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        liveDriverLocationsStream,
        (props, liveDriverLocations) => ({
          ...props,
          liveDriverLocations,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "list",
      "driverItem",
      "listPosition",
      "driverDetailsLoading",
      "driverDetails",
      "isLoading",
      "scrollingList",
      "liveDriverLocations",
      "state",
    ]),
  ),
);

class DriversRadarDriversListLive extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      activeTab: ALL_TAB,
    };
  }

  static propTypes = {
    classes: PropTypes.object,
    depends: PropTypes.bool,

    onItemClick: PropTypes.func,
    onScrollingDone: PropTypes.func,
    onItemCloseClick: PropTypes.func,
    onAlertStatusClick: PropTypes.func,
    onAssignDriver: PropTypes.func,

    list: PropTypes.instanceOf(Immutable.List),
    driverDetails: PropTypes.instanceOf(Immutable.Map),
    driverLastAlert: PropTypes.instanceOf(Immutable.Map),
    isLoading: PropTypes.bool,

    driverItem: PropTypes.number,
    orderItem: PropTypes.number,

    orderNumber: PropTypes.string,
    listPosition: PropTypes.number,
    scrollingList: PropTypes.bool,
    getLocalisationMessage: PropTypes.func,
    onListRequestRefresh: PropTypes.func,
    getRadarOrderSuggestedDrivers: PropTypes.func,
    onCloseSelectedDriverClick: PropTypes.func,
    driverDetailsLoading: PropTypes.bool,

    liveDriverLocations: PropTypes.instanceOf(Immutable.Map),
  };

  onChangeTab = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { classes, getLocalisationMessage } = this.props;

    return (
      <ReactAutoScroll
        targetPosition={this.props.listPosition}
        easeType="linear"
        speed={30}
        updateInterval={40}
        onScrollingDone={this.props.onScrollingDone}
        scrollTargetRef={this.scrollList}
        isEnabled={this.props.scrollingList}
      >
        <FlexBox className={classes.container}>
          <div className={classes.arrow} />

          <FlexBox flex={true} direction="column" element={<Paper />}>
            <FlexBox>
              <FlexBox
                gutter={8}
                flex={true}
                className={classes.headerContainer}
              >
                <RadarLiveDriverFilterTabs
                  driversList={this.props.liveDriverLocations}
                  onChangeTab={this.onChangeTab}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox
              flex={true}
              direction="column"
              className={classes.listContainer}
              element={
                <div
                  ref={x => {
                    this.scrollList = x;
                  }}
                />
              }
            >
              {this.props.isLoading && this.state.isLoading && (
                <LinearProgress />
              )}

              {!this.props.isLoading &&
                this.props.liveDriverLocations.get(
                  this.state.activeTab === AVAILABLE_TAB
                    ? "available"
                    : this.state.activeTab === BUSY_TAB
                    ? "busy"
                    : "all",
                ).size === 0 && (
                  <FlexBox justify="center">
                    <Text type={MUTED}>
                      {getLocalisationMessage(
                        "drivers_not_found",
                        "Drivers Not Found!",
                      )}
                    </Text>
                  </FlexBox>
                )}

              {!this.props.isLoading &&
                this.props.liveDriverLocations
                  .get(
                    this.state.activeTab === AVAILABLE_TAB
                      ? "available"
                      : this.state.activeTab === BUSY_TAB
                      ? "busy"
                      : "all",
                  )
                  .map((item, idx) => (
                    <NewRadarSuggestedDriverItem
                      item={item}
                      key={idx}
                      onItemClick={x => {
                        this.props.onItemClick(x, idx);
                      }}
                      isLoading={this.props.driverDetailsLoading}
                      onCloseClick={() => {}}
                      onAlertStatusClick={id => {
                        this.setState({ isLoading: true });
                        Promise.resolve(
                          batchAsyncUpdateOrder({
                            order_numbers: [this.props.orderNumber],
                            driver: { id },
                            order_status: "accepted",
                          }).catch(ResponseError.throw),
                        )
                          .then(response => {
                            this.setState({ isLoading: false });
                            return response;
                          })
                          .then(() => {
                            this.props.onAssignDriver();
                          })
                          // eslint-disable-next-line react/prop-types
                          .catch(this.props.onSubmitFail);
                      }}
                      onAlertStatusDispatchClick={id => {
                        this.setState({ isLoading: true });
                        Promise.resolve(
                          batchAsyncUpdateOrder({
                            order_numbers: [this.props.orderNumber],
                            driver: { id },
                            order_status: "dispatched",
                          }).catch(ResponseError.throw),
                        )
                          .then(response => {
                            this.setState({ isLoading: false });
                            return response;
                          })
                          .then(() => {
                            this.props.onAssignDriver();
                          })
                          // eslint-disable-next-line react/prop-types
                          .catch(this.props.onSubmitFail);
                      }}
                      itemOpen={this.props.driverItem === item.get("driver_id")}
                    />
                  ))}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </ReactAutoScroll>
    );
  }
}

export default enhancer(DriversRadarDriversListLive);
