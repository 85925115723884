import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createVenueCategoryItem } from "../../api/admin/AdminVenueCategoryApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import ShopCategoryForm from "../../components/shop-core/ShopCategoryForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    content: {
      maxWidth: "1000px",
      minWidth: "1000px",
      backgroundColor: "#fff",
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
);

AdminShopCategoryCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminShopCategoryCreateDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      onRequestClose={props.onRequestClose}
      title={props.getLocalisationMessage("create_category", "Create Category")}
      paperClassName={classes.content}
    >
      <ShopCategoryForm
        onRequestClose={props.onRequestClose}
        onSubmit={values =>
          createVenueCategoryItem(toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_saved",
              "Successfully Saved",
            ),
          );
          props.onRequestClose();
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminShopCategoryCreateDialogWrapper);
