import React from "react";
import { Set, List } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { formatText } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import TextWithLink from "../../../client/components/router/TextWithLink";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

BankDepositItemOrderList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  altHeader: PropTypes.node,
  selectable: PropTypes.bool,
  onRowSelect: PropTypes.func,
  selectedNumbers: PropTypes.instanceOf(Set),
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  createOrderHref: PropTypes.func,
  createCustomerHref: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

BankDepositItemOrderList.defaultProps = {
  maxSearchItems: 0,
  createCustomerHref: fp.noop,
};

function BankDepositItemOrderList(props) {
  const currentNumbers = props.selectable
    ? props.list.map(item => item.get("order_number")).toSet()
    : Set();
  const { getLocalisationMessage } = props;

  return (
    <DataList
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      maxSearchItems={props.maxSearchItems}
      totalCount={props.totalCount}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      overscanRowCount={10}
      rowGetter={options => props.list.get(options.index)}
      altHeader={props.altHeader}
      selectedRowCount={props.selectable ? props.selectedNumbers.size : 0}
    >
      {props.selectable && (
        <DataListCheckbox
          allRowsSelected={() =>
            currentNumbers.size > 0 &&
            props.selectedNumbers.isSuperset(currentNumbers)
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.selectedNumbers.union(currentNumbers)
                : props.selectedNumbers.subtract(currentNumbers),
            )
          }
          rowSelected={row =>
            props.selectedNumbers.has(row.cellData.get("order_number"))
          }
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedNumbers.add(row.cellData.get("order_number"))
                : props.selectedNumbers.delete(
                    row.cellData.get("order_number"),
                  ),
            )
          }
        />
      )}
      <DataListColumn
        width={104}
        disableSort={true}
        label={getLocalisationMessage("order_number", "Order Number")}
        dataKey="order_number"
        justifyContent="end"
        cellRenderer={row => (
          <strong>
            <TextWithLink
              to={props.createOrderHref(row.cellData.get("order_id"))}
            >
              {row.cellData.get("order_number")}
            </TextWithLink>
          </strong>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("completed_date", "Completed Date")}
        dataKey="completed_date"
        justifyContent="center"
        cellRenderer={row => (
          <DateTimeCell date={row.cellData.get("completed_date")} />
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("customer", "Customer")}
        dataKey="customer_name"
        justifyContent="center"
        cellRenderer={row => (
          <TextWithLink
            to={props.createCustomerHref(
              row.cellData.getIn(["customer", "id"]),
            )}
          >
            {row.cellData.getIn(["customer", "name"])}
          </TextWithLink>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("payer", "Payer")}
        dataKey="payer"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {getLocalisationMessage(
              row.cellData.get("payer"),
              formatText(row.cellData.get("payer")),
            )}
          </div>
        )}
      />

      <DataListColumn
        width={64}
        disableSort={true}
        label={getLocalisationMessage("cod", "COD")}
        dataKey="cod"
        justifyContent="end"
        cellRenderer={row => (
          <PriceWrapper
            price={row.cellData.get("cod_charge")}
            withCode={false}
          />
        )}
      />

      <DataListColumn
        width={96}
        disableSort={true}
        label={getLocalisationMessage("status", "Status")}
        dataKey="status"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {formatOrderStatusCodeForLocalisation(
              row.cellData.get("cod_status"),
              getLocalisationMessage,
            )}
          </div>
        )}
      />
    </DataList>
  );
}

export default enhancer(BankDepositItemOrderList);
