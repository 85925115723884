import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import reactHtmlParser from "react-html-parser";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  UZBEK_LANGUAGE,
  ARABIC_LANGUAGE,
  FRENCH_LANGUAGE,
  RUSSIAN_LANGUAGE,
  SERBIAN_LANGUAGE,
  SPANISH_LANGUAGE,
  TURKISH_LANGUAGE,
  MONGOLIAN_LANGUAGE,
} from "../../constants/LocaleTypes";
import {
  COD_TERMS,
  PUBLIC_TERMS,
  PUBLIC_PROHIBITS,
} from "../../constants/TermsSettingConstants";
import { getDefaultPublicTerms } from "../../api/admin/AdminTermsApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";

export const formatLanguage18 = (code, i18n) => {
  switch (code) {
    case RUSSIAN_LANGUAGE:
      return i18n.get("russian", "Russian");
    case UZBEK_LANGUAGE:
      return i18n.get("uzbek", "Uzbek");
    case TURKISH_LANGUAGE:
      return i18n.get("turkish", "Turkish");
    case FRENCH_LANGUAGE:
      return i18n.get("french", "French");
    case SERBIAN_LANGUAGE:
      return i18n.get("serbian", "Serbian");
    case MONGOLIAN_LANGUAGE:
      return i18n.get("mongolian", "Mongolian");
    case SPANISH_LANGUAGE:
      return i18n.get("spanish", "Spanish");
    case ARABIC_LANGUAGE:
      return i18n.get("arabic", "Arabic");
    default:
      return i18n.get("english", "English");
  }
};

function formatTermsSettings(code, i18n) {
  switch (code) {
    case PUBLIC_TERMS:
      return i18n.get("public_terms", "Public Terms");
    case PUBLIC_PROHIBITS:
      return i18n.get("public_prohibits", "Public Prohibits");
    case COD_TERMS:
      return i18n.get("cod_terms", "COD Terms");
    default:
      return i18n.get("public_terms", "Public Terms");
  }
}

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    root: {},
    cardBox: {
      padding: "15px 5px",
    },
    itemBox: {
      width: "50%",
      marginBottom: 15,
      "& > h5": {
        marginBottom: 7,
      },
    },
  }),
  mapPropsStream(propsStream => {
    const defaultPublicTerms = propsStream
      .first()
      .switchMap(() =>
        getDefaultPublicTerms().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );
    return propsStream.combineLatest(
      defaultPublicTerms,
      (props, defaultTerms) => ({
        ...props,
        isLoading: defaultTerms.get("pending"),
        defaultTerms: defaultTerms.get("payload"),
      }),
    );
  }),
);

AdminDefaultPublicTermsContainer.propTypes = {
  isLoading: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
  defaultTerms: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
};

function AdminDefaultPublicTermsContainer(props) {
  const { i18n, defaultTerms, classes } = props;

  return (
    <div>
      <PageLoading isLoading={props.isLoading} />

      <Card>
        <CardContent>
          <FlexBox gutter={16} flex={true}>
            <FlexBox
              gutter={8}
              flex={true}
              className={classes.itemBox}
              direction="column"
            >
              <h5>{i18n.get("terms_name", "Terms Name")}</h5>
              <p>{defaultTerms.get("name")}</p>
            </FlexBox>
            <FlexBox
              gutter={8}
              flex={true}
              className={classes.itemBox}
              direction="column"
            >
              <h5>{i18n.get("terms_type", "Terms Type")}</h5>
              <p>{formatTermsSettings(defaultTerms.get("terms_type"), i18n)}</p>
            </FlexBox>
            <FlexBox
              gutter={8}
              flex={true}
              className={classes.itemBox}
              direction="column"
            >
              <h5>{i18n.get("terms_language", "Terms Language")}</h5>
              <p>{formatLanguage18(defaultTerms.get("language_code"), i18n)}</p>
            </FlexBox>
          </FlexBox>
          <FlexBox gutter={16} flex={true} direction="column">
            <h5>{i18n.get("terms", "Terms")}</h5>
            <div>{reactHtmlParser(defaultTerms.get("terms"))}</div>
          </FlexBox>
        </CardContent>
      </Card>
    </div>
  );
}

export default enhancer(AdminDefaultPublicTermsContainer);
