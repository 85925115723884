import React from "react";
import { Map, List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Chip, Card } from "@material-ui/core";
import { connect } from "react-redux";
import Audio from "../ui-core/Audio";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import { getMessage } from "../../reducers/LocalizationReducer";

import Beep from "../../assets/voices/beep.wav";
import Error from "../../assets/voices/error.mp3";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

function OrderResultCard(props) {
  const { order, filterChips, getLocalisationMessage } = props;
  const lastChip = filterChips && filterChips.last();

  return (
    <FlexBox key={order.get("number")}>
      {order.get("failed") ? (
        <Card
          title={
            <span>
              <Audio src={Error} play={true} />
              {getLocalisationMessage("order", "Order")}{" "}
              <strong>{order.get("number")}</strong>{" "}
              {getLocalisationMessage("loading_failed", "loading failed.")} (
              <LinkButton onClick={props.onReloadClick}>
                {getLocalisationMessage("reload", "Reload")}
              </LinkButton>{" "}
              /{" "}
              <LinkButton onClick={props.onRemoveClick}>
                {getLocalisationMessage("remove", "Remove")}
              </LinkButton>
              )
            </span>
          }
        />
      ) : !order.get("payload") ? (
        <Card
          title={
            <span>
              {getLocalisationMessage("order", "Order")}{" "}
              <strong>{order.get("number")}</strong>{" "}
              {getLocalisationMessage("loading", "loading.")}
            </span>
          }
        />
      ) : (
        <div>
          <Audio src={Beep} play={true} />

          <Chip
            label={`${" "}
            ${getLocalisationMessage(
              lastChip.get("label").toLocaleLowerCase(),
              lastChip.get("label"),
            )}${" "}
              (1)`}
          />
        </div>
      )}
    </FlexBox>
  );
}

OrderResultCard.propTypes = {
  onReloadClick: PropTypes.func,
  onRemoveClick: PropTypes.func,

  order: PropTypes.instanceOf(Map).isRequired,
  filterChips: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
};
export default enhancer(OrderResultCard);
