import fp from "lodash/fp";
import { Set, OrderedMap, Map } from "immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const ADD_ORDER = "HYBRID_ORDERS/ADD_ORDER";
const UPDATE_ORDER = "HYBRID_ORDERS/UPDATE_ORDER";
const CLEAR_ORDERS = "HYBRID_ORDERS/CLEAR_ORDERS";
const REMOVE_ORDERS = "HYBRID_ORDERS/REMOVE_ORDERS";

const isHybridOrdersAction = fp.startsWith("HYBRID_ORDERS");

const castToOrderedMap = (x) => (OrderedMap.isOrderedMap(x) ? x : OrderedMap());

const cleanupState = fp.flow(castToOrderedMap, (initialState) =>
  initialState.withMutations((state) => {
    state.set("omit", Set.of("orders"));
    state.update("orders", castToOrderedMap);
  }),
);

const selector = injectReducer(
  "order-hybrid-orders@1.0.0",
  (initialState = cleanupState(), action) => {
    const state = isHybridOrdersAction(action.type)
      ? cleanupState(initialState)
      : initialState;

    switch (action.type) {
      case ADD_ORDER: {
        const { barcode } = action;
        return state.withMutations((nextState) => {
          nextState.setIn(["orders", barcode], Map({ barcode }));
        });
      }

      case UPDATE_ORDER: {
        const { payload, barcode } = action;
        return state.withMutations((nextState) => {
          nextState.setIn(["orders", barcode], payload);
        });
      }

      case CLEAR_ORDERS: {
        return state.withMutations((nextState) => {
          nextState.update("orders", (x) => x.clear());
        });
      }

      case REMOVE_ORDERS: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          payload.forEach((number) => {
            nextState.update("orders", (x) => x.delete(number));
          });
        });
      }

      default:
        return state;
    }
  },
);

export const getHybridOrders = (state) =>
  selector(state).get("orders", OrderedMap());

export const addHybridOrder =
  (barcode, order = Map()) =>
  (dispatch) => {
    dispatch({
      barcode,
      payload: order,
      type: ADD_ORDER,
    });
  };

export const updateHybridOrder =
  (barcode, order = Map()) =>
  (dispatch) => {
    dispatch({
      barcode,
      payload: order,
      type: UPDATE_ORDER,
    });
  };

export const deleteHybridOrder = (orderNumber) => (dispatch) => {
  dispatch({
    payload: [orderNumber],
    type: REMOVE_ORDERS,
  });
};

export const clearHybridOrders = () => (dispatch) => {
  dispatch({ type: CLEAR_ORDERS });
};
