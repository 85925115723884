import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { PDF_SETTINGS_URL } from "../../constants/AdminPathConstants";
import {
  getPdfTemplate,
  getTemplateTypes,
  deletePdfTemplate,
  updateHtmlPdfTemplate,
} from "../../api/admin/AdminPdfSettingsApi";
import {
  getMarketplaceCache,
  getMarketplacePredictions,
} from "../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminHtmlPdfTemplateForm from "../../components/admin/AdminHtmlPdfTemplateForm";
import FlexBox from "../../components/ui-core/FlexBox";
import { responsive } from "../../../shared/theme/jss-responsive";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  useSheet({
    appBarRightAction: { height: "100%" },
    appBarRightActionButton: {
      marginTop: "8px",
      color: "#fff",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  getContext({ setLocation: PropTypes.func }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const pdfTemplateIdStream = propsStream.map(
          fp.flow(fp.get("params.id"), fp.toFinite),
        );

        return propsStream
          .combineLatest(pdfTemplateIdStream, (props, pdfTemplateId) => ({
            ...props,
            pdfTemplateId,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const templateItemResponseStream = propsStream
          .distinctUntilKeyChanged("pdfTemplateId")
          .switchMap(props =>
            getPdfTemplate({ id: props.pdfTemplateId })
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const typeResponseStream = propsStream
          .first()
          .switchMap(() =>
            getTemplateTypes()
              .map(fp.flow(fp.get("payload.data"), List))
              .catch(error => Observable.of({ error })),
          )
          .startWith(List());

        return propsStream
          .combineLatest(
            templateItemResponseStream,
            typeResponseStream,
            (props, templateItemResponse, types) => ({
              ...props,
              onRequestRefresh,
              types,
              templateItem: templateItemResponse.get("payload"),
              isLoading: templateItemResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminHtmlPdfTemplateItem.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  pdfTemplateId: PropTypes.number,
  templateItem: PropTypes.instanceOf(Map),
  types: PropTypes.instanceOf(List),
};

function AdminHtmlPdfTemplateItem(props) {
  const { classes, types, getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={props.templateItem.get("name")}
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Link to={PDF_SETTINGS_URL}>
            <Button className={classes.appBarRightActionButton}>
              {getLocalisationMessage("go_to_list", "Go To List")}
            </Button>
          </Link>
        </FlexBox>
      }
    >
      <AdminHtmlPdfTemplateForm
        types={types}
        getMarketplaceCache={getMarketplaceCache}
        getMarketplacePredictions={getMarketplacePredictions}
        initialValues={{
          ...toCamelCase(props.templateItem),
          marketplaceId: {
            id: props.templateItem.get("marketplace_id"),
            name: props.templateItem.get("marketplace_name"),
          },
        }}
        onSubmit={({ marketplaceId, ...values }) =>
          updateHtmlPdfTemplate({
            id: props.pdfTemplateId,
            ...toSnakeCase(values),
            marketplace_id: _.get(marketplaceId, "id"),
            marketplace_name: _.get(marketplaceId, "name"),
          }).catch(ResponseError.throw)
        }
        onDelete={id =>
          deletePdfTemplate({ id })
            .then(() => {
              props.setLocation(PDF_SETTINGS_URL);
              props.showSuccessMessage(
                props.getLocalisationMessage(
                  "successfully_deleted",
                  "Successfully Deleted!",
                ),
              );
            })
            .catch(props.showErrorMessage)
        }
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.setLocation(PDF_SETTINGS_URL);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_created",
              "Successfully created",
            ),
          );
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminHtmlPdfTemplateItem);
