import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getCityItem, updateCityItem } from "../../api/admin/AdminShopCityApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import ShopCityForm from "../../components/shop-core/ShopCityForm";

const enhancer = compose(
  renderIf(props => props.cityId > 0),
  useSheet({
    content: {
      maxWidth: "400px",
      minWidth: "400px",
      backgroundColor: "#fff",
      minHeight: "auto",
    },
  }),
  connect(null, { showSuccessMessage, showErrorMessage }),
  mapPropsStream(propsStream => {
    const cityItemResponseStream = propsStream
      .distinctUntilKeyChanged("cityId")
      .switchMap(props =>
        getCityItem(props.cityId).catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      cityItemResponseStream,
      (props, cityItemResponse) => ({
        ...props,
        isLoading: cityItemResponse.get("pending"),
        cityItem: cityItemResponse.get("payload"),
      }),
    );
  }),
);

AdminShopCityCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  cityId: PropTypes.number,
  onRequestClose: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  cityItem: PropTypes.instanceOf(Map),
};

function AdminShopCityCreateDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.content}
      title="Edit City"
    >
      <ShopCityForm
        onRequestClose={props.onRequestClose}
        onSubmit={({ name }) =>
          updateCityItem(
            props.cityId,
            toSnakeCase({
              name,
              code: _.snakeCase(name),
            }),
          ).catch(ResponseError.throw)
        }
        initialValues={toCamelCase(props.cityItem)}
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully saved");
          props.onRequestClose();
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminShopCityCreateDialogWrapper);
