import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({ content: { maxWidth: "320px" } }),
  reduxForm({ form: "DeleteTimeslotDialog" }),
);

DeleteTimeslotDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function DeleteTimeslotDialog(props) {
  const { classes } = props;

  return (
    <Dialog
      maxWidth="md"
      open={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      contentClassName={classes.content}
    >
      <DialogContent>
        <PageLoading isLoading={props.submitting} />

        <form autoComplete="off">{props.children}</form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {props.getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {props.getLocalisationMessage("remove", "Remove")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(DeleteTimeslotDialog);
