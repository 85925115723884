import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { validateString } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({ padding: { paddingTop: 10 } }),
  reduxForm({
    form: "TagForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("enter_name", "Enter name")
        : null,
    }),
  }),
);

TagForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function TagForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={`${getLocalisationMessage("tag_name", "Tag Name")} *`}
                />

                <FormTextField
                  name="description"
                  multiLine={true}
                  fullWidth={true}
                  label={getLocalisationMessage("description", "Description")}
                />
              </CardContent>
              <FlexBox justify="flex-end">
                <CardActions
                  style={{
                    paddingTop: "30px",
                  }}
                >
                  {props.dirty ? (
                    <Button onClick={props.reset}>
                      {getLocalisationMessage("reset", "Reset")}
                    </Button>
                  ) : (
                    Boolean(props.onDismiss) && (
                      <Button onClick={props.onDismiss}>
                        {getLocalisationMessage("dismiss", "Dismiss")}
                      </Button>
                    )
                  )}

                  <Button onClick={props.handleSubmit}>
                    {getLocalisationMessage("submit", "Submit")}
                  </Button>
                </CardActions>
              </FlexBox>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(TagForm);
