import { Observable } from "rxjs";
import React from "react";
import { List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add as ContentAdd } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  removeTimeSlot,
  getTimeSlotList,
} from "../../api/admin/AdminTimeSlotApi";
import AdminTimeSlotEditDialogWrapper from "../../wrappers/admin/AdminTimeSlotEditDialogWrapper";
import AdminTimeslotCreateDialogWrapper from "../../wrappers/admin/AdminTimeslotCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DeleteTimeslotDialog from "../../components/deprecated/DeleteTimeslotDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const NA = "N/A";

const enhancer = compose(
  useSheet({
    paddingLeft: { paddingLeft: "8px" },
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func,
    setLocationQueryFilter: PropTypes.func,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getTimeSlotList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminTimeSlotList.propTypes = {
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  setLocationQuery: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,

  isAdminViewer: PropTypes.bool,
};

function AdminTimeSlotList(props) {
  const { getLocalisationMessage } = props;
  const removingId = fp.toFinite(props.location.query.remove);

  return (
    <AdminAppLayout
      slug="timeslots"
      title={getLocalisationMessage("timeslots", "Timeslots")}
    >
      <DeleteTimeslotDialog
        open={removingId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset(["remove"]))}
        onSubmit={() => removeTimeSlot(removingId)}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset(["remove"]));
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
          props.setLocationQuery(fp.unset(["remove"]));
        }}
      >
        {props.getLocalisationMessage(
          "are_you_sure_you_want_to_remove_timeslot",
          "Are you sure you want to remove timeslot?",
        )}
      </DeleteTimeslotDialog>

      <AdminTimeslotCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminTimeSlotEditDialogWrapper
        timeslotId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Tooltip
                title={props.getLocalisationMessage(
                  "create_new_timeslot",
                  "Create New Timeslot",
                )}
              >
                <IconButton
                  onClick={() => props.setLocationQuery(fp.set("create", true))}
                >
                  <ContentAdd />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      >
        <DataListColumn
          width={96}
          label={props.getLocalisationMessage("sort_order", "Sort Order")}
          dataKey="sort_order"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>{row.cellData.get("sort_order") || NA}</div>
          )}
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          cellRenderer={row => (
            <strong>
              {!props.isAdminViewer ? (
                <Link
                  to={updateQuery(
                    props.location,
                    fp.set("view", row.cellData.get("id")),
                  )}
                >
                  {row.cellData.get("name")}
                </Link>
              ) : (
                row.cellData.get("name")
              )}
            </strong>
          )}
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("description", "Description")}
          dataKey="description"
          disableSort={true}
          cellRenderer={row => (
            <div>{row.cellData.get("description") || NA}</div>
          )}
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("start_time", "Start Time")}
          dataKey="start_time"
          disableSort={true}
          cellRenderer={row => <div>{row.cellData.get("start_time")}</div>}
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("end_time", "End Time")}
          dataKey="end_time"
          disableSort={true}
          cellRenderer={row => <div>{row.cellData.get("end_time")}</div>}
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("type", "Type")}
          dataKey="type"
          disableSort={true}
          cellRenderer={row => (
            <div>
              {getLocalisationMessage(
                row.cellData.get("type"),
                formatText(row.cellData.get("type")),
              )}
            </div>
          )}
        />

        {!props.isAdminViewer && (
          <DataListColumn
            width={128}
            label={props.getLocalisationMessage("action", "Action")}
            dataKey="type"
            disableSort={true}
            cellRenderer={row => (
              <Button
                onClick={() =>
                  props.setLocationQuery(
                    fp.set("remove", row.cellData.get("id")),
                  )
                }
              >
                {props.getLocalisationMessage("remove", "Remove")}
              </Button>
            )}
          />
        )}
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminTimeSlotList);
