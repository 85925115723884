import React from "react";
import { List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import DataList, { DataListColumn } from "../data-list/DataList";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { accent4, primary1 } from "../../../shared/theme/main-theme";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const NA = "N/A";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
    buttonUpdate: {
      backgroundColor: primary1,
      marginRight: "10px",
      color: "#fff",
      "&:hover": { backgroundColor: accent4 },
    },
  }),
);

ServiceTypesList.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
  location: PropTypes.object,
  totalCount: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  overscanRowCount: PropTypes.number,
  rowCount: PropTypes.number,
  rowGetter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func,
};

ServiceTypesList.defaultProps = {
  overscanRowCount: 6,
};

function ServiceTypesList(props) {
  const { location, getLocalisationMessage } = props;
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      filter={props.filter}
      list={props.list}
      searchTypes={props.searchTypes}
      onFilterChange={props.onFilterChange}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.rowCount}
      rowGetter={props.rowGetter}
      cardActionIcons={props.cardActionIcons}
    >
      <DataListColumn
        width={96}
        label={getLocalisationMessage("service_type", "Service Type")}
        dataKey="service_type"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <strong>
            <Link
              to={updateQuery(location, fp.set("view", row.cellData.get("id")))}
            >
              {row.cellData.get("name")}
            </Link>
          </strong>
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("code", "Code")}
        disableSort={true}
        dataKey="code"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("code", NA)}</div>}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("sort_order", "Sort Order")}
        disableSort={true}
        dataKey="sorder"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("sorder", NA)}</div>}
      />

      <DataListColumn
        width={150}
        label={getLocalisationMessage("description", "Description")}
        disableSort={true}
        dataKey="description"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("description", NA)}</div>}
      />

      <DataListColumn
        width={20}
        label={getLocalisationMessage("actions", "Action")}
        disableSort={false}
        dataKey="action2"
        justifyContent="center"
        cellRenderer={row => (
          <Link
            to={updateQuery(props.location, {
              remove: row.cellData.get("id"),
            })}
          >
            <Button>{getLocalisationMessage("remove", "Remove")}</Button>
          </Link>
        )}
      />
    </DataList>
  );
}

export default enhancer(ServiceTypesList);
