import React from "react";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { batchSetOrderSize } from "../../api/admin/AdminOrderApi";
import BatchOrderSizeDialog from "../../components/orders-core/BatchOrderSizeDialog";

AdminBatchOrderSizeDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  messages: PropTypes.instanceOf(Map),
};

export default function AdminBatchOrderSizeDialogWrapper(props) {
  return (
    <BatchOrderSizeDialog
      messages={props.messages}
      open={props.open}
      onRequestClose={props.onRequestClose}
      initialValues={props.initialValues}
      onSubmit={values =>
        batchSetOrderSize(toSnakeCase(values)).catch(ResponseError.throw)
      }
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}
