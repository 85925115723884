import React from "react";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";

const enhancer = getContext({
  getCachedServiceTypeById: PropTypes.func.isRequired,
  getServiceTypePredictions: PropTypes.func.isRequired,
});

FormServiceTypeChipsV2.propTypes = {
  getCachedServiceTypeById: PropTypes.func,
  getServiceTypePredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

function FormServiceTypeChipsV2({
  getCachedServiceTypeById,
  getServiceTypePredictions,
  filter,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={getCachedServiceTypeById}
      getPredictions={() => getServiceTypePredictions()}
    />
  );
}

export default enhancer(FormServiceTypeChipsV2);
