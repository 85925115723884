import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { TERMS_SETTINGS_URL } from "../../constants/AdminPathConstants";
import { createTermsSettings } from "../../api/admin/AdminTermsApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import TermsSettingsForm from "../../components/terms-settings/TermsSettingForm";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func.isRequired }),
  useSheet({
    paper: { minWidth: "900px", maxWidth: "1000px" },
  }),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
);

AdminTermsCreateDialogWrapper.propTypes = {
  terms: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  setLocation: PropTypes.func,
};

function AdminTermsCreateDialogWrapper(props) {
  const { i18n } = props;

  return (
    <AdminAppLayout title={i18n.get("create_new_terms", "Create New Terms")}>
      <TermsSettingsForm
        onSubmit={values =>
          createTermsSettings(toSnakeCase(values)).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            i18n.get("created_terms_settings", "Created Terms Settings"),
          );
          props.setLocation(TERMS_SETTINGS_URL);
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminTermsCreateDialogWrapper);
