import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import { transportationTypeForPackageRule } from "../../constants/TransportationType";
import { formatTransportationTypesLocalised } from "../order-details-dialog/v2/OrderDetailsDialogBriefV2";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    content: { width: "500px" },
    row: { padding: "5px 0" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    td: {
      alignSelf: "center",
    },
  }),
  reduxForm({
    form: "CustomizableOtpSettingsDialogForm",
    enableReinitialize: true,
  }),
);

CustomizableOtpSettingsDialogForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  type: PropTypes.string.isRequired,
};

function CustomizableOtpSettingsDialogForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onClose={props.handleSubmit}
      contentClassName={props.classes.content}
      PaperProps={{
        style: {
          minWidth: 400,
          maxWidth: 600,
        },
      }}
    >
      <DialogTitle>
        {getLocalisationMessage("otp_settings", "OTP Settings")}
      </DialogTitle>
      <DialogContent>
        <FlexBox direction="column" style={{ gap: 16 }}>
          <FlexBox flex={true}>
            <FormCourierTypeChips
              fullWidth={true}
              label={getLocalisationMessage("service_types")}
              name="serviceTypes"
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormChipAutoComplete
              fullWidth={true}
              options={transportationTypeForPackageRule}
              label={getLocalisationMessage("transportation_types")}
              formatOption={option =>
                formatTransportationTypesLocalised(
                  option,
                  getLocalisationMessage,
                )
              }
              name="transportationTypes"
            />
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CustomizableOtpSettingsDialogForm);
