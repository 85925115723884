import React from "react";
import { List, OrderedMap, Set } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import DataList, { DataListColumn } from "../data-list/DataList";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { accent4, primary1 } from "../../../shared/theme/main-theme";
import { formatText, formatWeight } from "../../helpers/FormatUtils";
import MultiLineCell from "../data-list/MultiLineCell";
import fp from "lodash/fp";
import { toJS } from "../../helpers/DataUtils";

const NA = "N/A";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
    buttonUpdate: {
      backgroundColor: primary1,
      marginRight: "10px",
      color: "#fff",
      "&:hover": { backgroundColor: accent4 },
    },
  }),
);

const getOrderJurisdictionHierarchy = fp.flow(
  fp.defaultTo(Set),
  toJS,
  fp.map((item) => fp.get("name", item)),
  fp.reverse,
  fp.join(", "),
);

CustomsOrdersList.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
  location: PropTypes.object,
  totalCount: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  overscanRowCount: PropTypes.number,
  rowCount: PropTypes.number,
  rowGetter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func,
  onClickOrder: PropTypes.func,
};

CustomsOrdersList.defaultProps = {
  overscanRowCount: 6,
};

function CustomsOrdersList(props) {
  const { getLocalisationMessage } = props;
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      filter={props.filter}
      list={props.list}
      searchTypes={props.searchTypes}
      onFilterChange={props.onFilterChange}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.rowCount}
      rowGetter={props.rowGetter}
      cardActionIcons={props.cardActionIcons}
    >
      <DataListColumn
        width={96}
        label={getLocalisationMessage(
          "id_group_of_orders",
          "ID (Group of Orders)",
        )}
        dataKey="barcode"
        disableSort={true}
        justifyContent="center"
        cellRenderer={(row) => (
          <strong>
            <Link onClick={() => props.onClickOrder(row.cellData.get("id"))}>
              {row.cellData.get("barcode")}
            </Link>
          </strong>
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("type", "Type")}
        disableSort={true}
        dataKey="type"
        justifyContent="center"
        cellRenderer={(row) =>
          getLocalisationMessage(
            row.cellData.getIn(["service_type", "name"]),
          ) || NA
        }
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("from", "From")}
        disableSort={true}
        dataKey="from_jurisdiction"
        justifyContent="center"
        cellRenderer={(row) => (
          <MultiLineCell
            firstLine={`${row.cellData.getIn(
              ["from_jurisdiction", "name"],
              "",
            )}`}
            secondLine={`${getOrderJurisdictionHierarchy(
              row.cellData.getIn(["from_jurisdiction", "hierarchy"], Set()),
            )}`}
          />
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("to", "To")}
        disableSort={true}
        dataKey="to_jurisdiction"
        justifyContent="center"
        cellRenderer={(row) => (
          <MultiLineCell
            firstLine={`${row.cellData.getIn(["to_jurisdiction", "name"], "")}`}
            secondLine={`${getOrderJurisdictionHierarchy(
              row.cellData.getIn(["to_jurisdiction", "hierarchy"], Set()),
            )}`}
          />
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("weight", "Weight")}
        disableSort={true}
        dataKey="weight"
        justifyContent="center"
        cellRenderer={(row) => (
          <div> {formatWeight(row.cellData.get("weight", 0))}</div>
        )}
      />

      <DataListColumn
        width={150}
        label={getLocalisationMessage("status", "Status")}
        disableSort={true}
        dataKey="status"
        justifyContent="center"
        cellRenderer={(row) => {
          const status = row.cellData.get("status");
          return getLocalisationMessage(status, formatText(status));
        }}
      />
    </DataList>
  );
}

export default enhancer(CustomsOrdersList);
