import React from "react";
import { Set, List } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, Button, Select } from "@material-ui/core";
import { connect } from "react-redux";
import Text, { MUTED, DANGER } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../data-list/DataList";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import OrderSizeCodes from "../../constants/OrderSizeCodes";

const searchTextToSet = fp.flow(fp.trim, fp.split(","), fp.compact, Set);

OrderFieldCell.propTypes = {
  children: PropTypes.node,
  failed: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderFieldCell(props) {
  const { getLocalisationMessage } = props;
  return props.failed ? (
    <Text type={getLocalisationMessage(DANGER) || DANGER}>
      {getLocalisationMessage("failed") || "Failed"}
    </Text>
  ) : props.fetching ? (
    <Text type={getLocalisationMessage(MUTED) || MUTED}>
      {getLocalisationMessage("loading") || "Loading..."}
    </Text>
  ) : (
    <Text
      fallbackType={getLocalisationMessage(MUTED) || MUTED}
      fallbackValue="-"
    >
      {props.children}
    </Text>
  );
}

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  withState("state", "setState", {
    showSelected: false,
    filter: new DataListFilter(),
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onShowSelected,
          stream: onShowSelectedStream,
        } = createEventHandler();

        const showSelectedStream = onShowSelectedStream
          .map(Boolean)
          .startWith(false)
          .distinctUntilChanged();

        return propsStream.combineLatest(
          showSelectedStream,
          (props, showSelected) => ({
            ...props,
            showSelected,
            onShowSelected,
          }),
        );
      },
      propsStream => {
        const orderListStream = propsStream
          .map(
            fp.flow(
              fp.update("orders", x => x.reverse()),
              fp.update("state", fp.pick(["filter", "showSelected"])),
              fp.pick(["state", "selectedOrders", "orders"]),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const {
              selectedOrders,
              state: { filter, showSelected },
            } = props;
            const hasSelected = selectedOrders.size > 0;

            const orderBy = fp.toPath(filter.getOrderBy());
            const orderByAsc = filter.isOrderByAsc();
            const searchBy = searchTextToSet(filter.getSearch());

            const shouldFilterSelected = hasSelected && showSelected;
            const shouldFilterBySearch = searchBy.size > 0;

            const orderList =
              !shouldFilterSelected && !shouldFilterBySearch
                ? props.orders
                : props.orders.filter(x => {
                    if (shouldFilterBySearch) {
                      return searchBy.has(x.get("number"));
                    }

                    if (shouldFilterSelected) {
                      return selectedOrders.has(x.get("number"));
                    }

                    return true;
                  });

            const shouldSortByField = orderBy.length > 0;
            const shouldSortBySelection = hasSelected && !showSelected;

            return !shouldSortByField && !shouldSortBySelection
              ? orderList
              : orderList.sort((a, b) => {
                  if (shouldSortBySelection) {
                    const aSelected = selectedOrders.has(a.get("number"));
                    const bSelected = selectedOrders.has(b.get("number"));

                    if (aSelected !== bSelected) {
                      return aSelected ? -1 : 1;
                    }
                  }

                  if (shouldSortByField) {
                    const aValue = fp.trim(a.getIn(orderBy));
                    const bValue = fp.trim(b.getIn(orderBy));

                    return aValue.localeCompare(bValue) * (orderByAsc ? 1 : -1);
                  }

                  return 0;
                });
          })
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(orderListStream, (props, orderList) => ({
            ...props,
            orderList,
          }));
      },
    ),
  ),
);

OrderReturnTable.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  orderList: PropTypes.instanceOf(List),

  headerActions: PropTypes.node,

  onSizeChange: PropTypes.func.isRequired,
  onOrdersSelect: PropTypes.func.isRequired,
  onReloadClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,

  orders: PropTypes.instanceOf(List).isRequired,
  selectedOrders: PropTypes.instanceOf(Set).isRequired,

  getLocalisationMessage: PropTypes.func,
  isRTL: PropTypes.bool,
};

function OrderReturnTable(props) {
  const {
    state,
    selectedOrders,
    orderList,
    getLocalisationMessage,
    isRTL,
  } = props;

  return (
    <div>
      <DataList
        isLoading={false}
        withFooter={false}
        overscanRowCount={10}
        maxSearchItems={Infinity}
        filter={state.filter}
        onFilterChange={x => props.setState(fp.set("filter", x))}
        rowCount={orderList.size}
        totalCount={orderList.size}
        rowGetter={row => orderList.get(row.index)}
        selectedRowCount={selectedOrders.size}
        altHeader={
          <FlexBox align="center">
            {selectedOrders.size > 0 && (
              <Button
                onClick={() =>
                  props.setState(fp.set("showSelected", !state.showSelected))
                }
              >
                {" "}
                {state.showSelected
                  ? getLocalisationMessage("show_all", "Show All")
                  : getLocalisationMessage(
                      "show_selected",
                      "Show Selected",
                    )}{" "}
              </Button>
            )}

            {props.headerActions}
          </FlexBox>
        }
      >
        {orderList.size > 0 && (
          <DataListCheckbox
            allRowsSelected={() => selectedOrders.size === orderList.size}
            rowSelected={row => selectedOrders.has(row.cellData.get("number"))}
            onRowSelect={row => {
              if (row.selected) {
                props.setState(fp.set("filter", state.filter.setSearch(null)));
              }

              props.onOrdersSelect(
                row.selected
                  ? selectedOrders.add(row.cellData.get("number"))
                  : selectedOrders.delete(row.cellData.get("number")),
              );
            }}
            onAllRowsSelect={selected => {
              if (selected) {
                props.setState(fp.set("filter", state.filter.setSearch(null)));
              }

              props.onOrdersSelect(
                !selected ? Set() : orderList.map(x => x.get("number")).toSet(),
              );
            }}
          />
        )}

        <DataListColumn
          width={120}
          disableSort={true}
          label={getLocalisationMessage("number", "Number")}
          dataKey="order_number"
          cellRenderer={row => row.cellData.get("number")}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("supplier", "Supplier")}
          dataKey="payload.supplier.name"
          justifyContent="center"
          cellRenderer={row => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={row.cellData.get("failed")}
              fetching={!row.cellData.get("payload")}
            >
              {row.cellData.getIn(["payload", "supplier", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("with_1", "With")}
          dataKey="payload.driver.name"
          justifyContent="center"
          cellRenderer={row => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={row.cellData.get("failed")}
              fetching={!row.cellData.get("payload")}
            >
              {row.cellData.getIn(["payload", "driver", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("in_1", "In")}
          dataKey="payload.warehouse.name"
          justifyContent="center"
          cellRenderer={row => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={row.cellData.get("failed")}
              fetching={!row.cellData.get("payload")}
            >
              {row.cellData.getIn(["payload", "warehouse", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("city", "City")}
          dataKey="payload.package.to_city"
          justifyContent="center"
          cellRenderer={row => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={row.cellData.get("failed")}
              fetching={!row.cellData.get("payload")}
            >
              {row.cellData.getIn(["payload", "package", "to_city"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("near", "Near")}
          justifyContent="center"
          dataKey="payload.destination_warehouse.name"
          cellRenderer={row => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={row.cellData.get("failed")}
              fetching={!row.cellData.get("payload")}
            >
              {row.cellData.getIn(["payload", "destination_warehouse", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("size", "Size")}
          dataKey="payload.size"
          cellRenderer={row =>
            row.cellData.get("failed") ? (
              <div>
                <LinkButton
                  onClick={() =>
                    props.onReloadClick(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("reload", "Reload")}
                </LinkButton>{" "}
                /{" "}
                <LinkButton
                  onClick={() =>
                    props.onRemoveClick(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("remove", "Remove")}
                </LinkButton>
              </div>
            ) : !row.cellData.get("payload") ? (
              <Text type={getLocalisationMessage(MUTED) || MUTED}>
                {getLocalisationMessage("loading", "Loading...")}
              </Text>
            ) : (
              <Select
                hintText={getLocalisationMessage("size", "Size")}
                autoWidth={true}
                fullWidth={true}
                value={row.cellData.getIn(["payload", "size"])}
                onChange={event =>
                  props.onSizeChange(
                    row.cellData.get("number"),
                    event.target.value,
                  )
                }
                menuItemStyle={{ textAlign: isRTL ? "right" : "left" }}
                iconStyle={{
                  left: !isRTL ? "auto" : "0",
                  right: isRTL ? "auto" : "0",
                }}
                labelStyle={{
                  paddingLeft: !isRTL ? "0" : "56px",
                  paddingRight: isRTL ? "0" : "56px",
                  textAlign: isRTL ? "right" : "left",
                }}
                floatingLabelStyle={{
                  right: isRTL ? "0" : "auto",
                }}
              >
                {OrderSizeCodes.map(sizeCode => (
                  <MenuItem key={sizeCode} value={sizeCode}>
                    {getLocalisationMessage(
                      sizeCode,
                      formatText(sizeCode),
                    ).toLocaleUpperCase()}
                  </MenuItem>
                )).toArray()}
              </Select>
            )
          }
        />
      </DataList>
    </div>
  );
}

export default enhancer(OrderReturnTable);
