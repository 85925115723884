import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FlexBox from "../../components/ui-core/FlexBox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import { getValue } from "../../helpers/DataUtils";
import { DELIVERY, RETURNING } from "../../constants/FlowTypes";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import Text from "../../components/ui-core/Text";
import _ from "lodash";
import { red } from "@material-ui/core/colors";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { CheckCircle } from "@material-ui/icons";
import MUITable, {
  ACTION_BY_INDEX,
  INDEX,
  RENDER,
} from "../../components/orders-core/MUITable";
import ScannerTextField from "../../components/deprecated/ScannerTextField";
import fp from "lodash/fp";
import { cleanupStringArray } from "../../helpers/FormatUtils";
import {
  finalStatuses,
  ISSUED_TO_RECIPIENT,
} from "../../constants/OrderStatusCodes";
// eslint-disable-next-line import/no-internal-modules
import Skeleton from "@material-ui/lab/Skeleton";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import Typography from "@material-ui/core/Typography";

const parseOrderNumbers = fp.flow(
  fp.trim,
  x => x.replace(/\W/g, " "),
  // fp.split(" "),
  cleanupStringArray,
);

const useStyles = makeStyles({
  search: {
    flex: "1 1 auto",
  },
  batchDetails: {
    fontWeight: 500,
    fontSize: 18,
  },
  details: {
    fontWeight: 500,
    fontSize: 24,
  },
  detailsRow: {
    marginBottom: 20,
  },
  detailsLabel: {
    lineHeight: "21px",
    fontWeight: "400",
    fontSize: 18,
    color: "rgba(38, 50, 56, 0.7)",
    "@media (max-width: 991px)": {
      fontSize: 18,
      lineHeight: "21px",
    },
  },
  detailsValue: {
    fontSize: 18,
    lineHeight: "21px",
    "@media (max-width: 991px)": {
      fontSize: 18,
      lineHeight: "21px",
    },
  },
  orderErrorStatus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > div": {
      fontSize: "18px",
      lineHeight: "1.2",
      fontWeight: "bold",
      color: red[400],
    },
  },
  orderStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
    "& > span": {
      fontWeight: "bold",
      color: red[400],
      textTransform: "uppercase",
    },
  },
  orderLoading: {
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "15px",
    "& > strong": {
      fontSize: "24px",
      display: "block",
    },
  },
  "@media (min-width: 998px)": {
    details: { flexDirection: "column" },
  },
  "@media (max-width: 991px)": {
    content: { flex: 1 },
  },
  reason: {
    gap: 16,
    maxHeight: "250px",
    overflow: "auto",
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      gap: 10,
    },
  },
  list: {
    "& .MuiCardContent-root": {
      height: "80vh !important",
    },
  },
});

const getShipmentCity = (orderInfo, flow, from = true) => {
  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "from_city")
      : _.get(orderInfo, "to_city");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "to_city")
    : _.get(orderInfo, "from_city");
};

const getShipmentPostcode = (orderInfo, flow, from = true) => {
  const { type } = orderInfo;

  if (type) {
    if (from) {
      return _.get(orderInfo, "from_postcode.name", "");
    }
    return _.get(orderInfo, "to_postcode.name", "");
  }

  if (from) {
    return flow === DELIVERY
      ? _.get(orderInfo, "from_post_code.name", "")
      : _.get(orderInfo, "to_post_code.name", "");
  }

  return flow === DELIVERY
    ? _.get(orderInfo, "to_post_code.name", "")
    : _.get(orderInfo, "from_post_code.name", "");
};

function HybridIIssuedCreate(props) {
  const classes = useStyles();
  const {
    getLocalisationMessage,
    order,
    isOrderLoading,
    isLoadingSubmit,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [ordersIssued, setOrdersIssued] = useState([]);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const [isClose, setIsClose] = useState(false);

  const tempFlow =
    order.flow === null
      ? DELIVERY
      : order.flow === DELIVERY
      ? DELIVERY
      : RETURNING;

  const fromPostcode = getShipmentPostcode(order, tempFlow);
  const toPostcode = getShipmentPostcode(order, tempFlow, false);

  const fromCity = order.type
    ? _.get(order, "from_jurisdiction.name", "")
    : getShipmentCity(order, tempFlow);
  const toCity = order.type
    ? _.get(order, "to_jurisdiction.name", "")
    : getShipmentCity(order, tempFlow, false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (order && order.barcode) {
      if (!finalStatuses.includes(order.status)) {
        const orderBarcodes = ordersIssued.map(item => item.barcode);
        if (!orderBarcodes.includes(order.barcode)) {
          setOrdersIssued(prev => [
            {
              ...order,
              status: ISSUED_TO_RECIPIENT,
            },
            ...prev,
          ]);
        }
      }
    }
  }, [order, ordersIssued]);

  useEffect(() => {
    if (ordersIssued) {
      if (searchText) {
        const newArray = [];
        ordersIssued.forEach(item => {
          const isItemIncludesSearchText = item.barcode
            .toLowerCase()
            .includes(searchText.toLowerCase());
          if (isItemIncludesSearchText) {
            const foundItem = {
              ...item,
            };
            newArray.push(foundItem);
          }
        });
        setSearchResults(newArray);
      } else {
        setSearchResults(ordersIssued);
      }
    }
  }, [searchText, ordersIssued]);

  return (
    <FlexBox direction="row" style={{ gap: 16, height: "100%", }}>
      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "No")}
        open={isClose}
        onConfirm={props.onClose}
        onRequestClose={() => setIsClose(false)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_exit",
          "Are you sure you want to exit?",
        )}
      </ConfirmDialog>
      <Card style={{ height: "100%", width: "100%" }}>
        <CardContent style={{ padding: 20 }}>
          <FlexBox style={{ gap: 30 }} direction="column">
            <ScannerTextField
              fullWidth={true}
              hintText={getLocalisationMessage("add_orders", "Add Orders")}
              focus={true}
              ref={ref}
              inputRef={inputRef}
              className={classes.input}
              id="OrderHybridFormScannerTextField"
              onChange={value => {
                const orderNumbers = value.split(",");
                props.setOrderNumber(parseOrderNumbers(orderNumbers[0]));

                if (ref && ref.current) {
                  ref.current.state.stateValue.value = "";
                }

                if (inputRef && inputRef.current) {
                  inputRef.current.value = "";
                  inputRef.current.focus();
                }
              }}
              size="small"
              clearAfterEnter={true}
            />

            <div
              style={{
                borderBottom: props.error ? "none" : "1px solid #EEEEEE",
              }}
            >
              {props.error ? (
                <FlexBox
                  style={{ color: red[800] }}
                  align="center"
                  justify="center"
                >
                  {getLocalisationMessage("order_not_found")}
                </FlexBox>
              ) : _.isEmpty(order) && !isOrderLoading ? (
                <FlexBox flex={true} direction="column">
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <Text
                      element="h3"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <FlexBox direction="row">
                        <strong>
                          {" "}
                          {getLocalisationMessage("details", "Details")}:{" "}
                        </strong>
                      </FlexBox>
                      <FlexBox />
                    </Text>
                  </FlexBox>
                  <FlexBox />
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "shipment_type",
                          "Shipment Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("status", "Status")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>

                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "from_postcode",
                          "From Postcode",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("to_postcode", "To Postcode")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "from_jurisdiction",
                              "From Jurisdiction",
                            )
                          : getLocalisationMessage("from_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "to_jurisdiction",
                              "To Jurisdiction",
                            )
                          : getLocalisationMessage("to_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("weight")}:&nbsp;
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "transportation_type",
                          "Transportation Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          <Skeleton width="80%" />
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              ) : (
                <FlexBox flex={true} direction="column">
                  <FlexBox className={classes.batchDetails}>
                    {order.barcode}
                  </FlexBox>
                  <FlexBox flex={true} wrap={true}>
                    <Text
                      element="h3"
                      className={classes.orderStatus}
                      style={{ justifyContent: "space-between" }}
                    >
                      <FlexBox style={{ gap: 10 }}>
                        <strong>
                          {getLocalisationMessage("details", "Details")}
                        </strong>
                        <FlexBox align="center" flex={true}>
                          {finalStatuses.includes(order.status) && (
                            <Typography
                              component="h3"
                              style={{ color: "#f44336", fontSize: 16 }}
                            >
                              -{" "}
                              {getLocalisationMessage(
                                "changes_cannot_be+made_because_the_delivery_process_has_already_been_completed",
                                "Changes cannot be made because the delivery process has already been completed",
                              )}
                            </Typography>
                          )}
                        </FlexBox>
                      </FlexBox>

                      <FlexBox>
                        {order.redirected && (
                          <strong
                            style={{
                              margin: "0 .5rem",
                              border: ".25rem solid #f44336",
                              color: "#f44336",
                              padding: ".25rem 2rem",
                              textTransform: "uppercase",
                            }}
                          >
                            {getLocalisationMessage("redirect_order")}
                          </strong>
                        )}
                        {order.flow === RETURNING && (
                          <strong
                            style={{
                              border: ".25rem solid rgb(255, 152, 0)",
                              margin: "0 .5rem",
                              color: "rgb(255, 152, 0)",
                              padding: ".25rem 2rem",
                              textTransform: "uppercase",
                            }}
                          >
                            {getLocalisationMessage("return")}
                          </strong>
                        )}
                      </FlexBox>
                    </Text>
                  </FlexBox>
                  <FlexBox />
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "shipment_type",
                          "Shipment Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {getValue(order, "service_type.name") &&
                            getLocalisationMessage(
                              getValue(
                                order,
                                "service_type.name",
                              ).toLocaleLowerCase(),
                              getValue(order, "service_type.name"),
                            )}
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("status", "Status")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {formatOrderStatusCodeForLocalisation(
                            order.status,
                            getLocalisationMessage,
                          )}
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>

                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "from_postcode",
                          "From Postcode",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{fromPostcode}</strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("to_postcode", "To Postcode")}:
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{toPostcode}</strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "from_jurisdiction",
                              "From Jurisdiction",
                            )
                          : getLocalisationMessage("from_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{fromCity}</strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {order.type
                          ? getLocalisationMessage(
                              "to_jurisdiction",
                              "To Jurisdiction",
                            )
                          : getLocalisationMessage("to_city")}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>{toCity}</strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox flex={true} className={classes.detailsRow}>
                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage("weight")}:&nbsp;
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {order.weight} {getLocalisationMessage("kg")}
                        </strong>
                      </h5>
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <h5 className={classes.detailsLabel}>
                        {getLocalisationMessage(
                          "transportation_type",
                          "Transportation Type",
                        )}
                        :
                      </h5>
                      <h5 className={classes.detailsValue}>
                        <strong>
                          {getValue(order, "transportation_type") &&
                            getLocalisationMessage(
                              getValue(
                                order,
                                "transportation_type",
                              ).toLocaleLowerCase(),
                              getValue(order, "transportation_type"),
                            )}
                        </strong>
                      </h5>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              )}
            </div>
          </FlexBox>
        </CardContent>
      </Card>
      <Card
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <CardContent style={{ padding: 20, height: "100%" }}>
          <FlexBox
            style={{ gap: 30, height: "100%" }}
            direction="column"
            justify="space-between"
          >
            <FlexBox style={{ gap: 10 }}>
              <div className={classes.search}>
                <TextField
                  name="search"
                  value={searchText}
                  label={getLocalisationMessage("search", "Search")}
                  onChange={e => setSearchText(e.target.value)}
                  variant="outlined"
                  fullWidth={true}
                  size="small"
                  type="search"
                />
              </div>

              <TextField
                defaultValue={getLocalisationMessage(ISSUED_TO_RECIPIENT)}
                disabled={true}
                label={getLocalisationMessage("status")}
                variant="outlined"
                size="small"
              />
            </FlexBox>

            <MUITable
              list={searchResults}
              withoutPagination={true}
              columns={[
                {
                  type: INDEX,
                  label: getLocalisationMessage("#"),
                },
                {
                  type: RENDER,
                  name: "barcode",
                  render: row => _.get(row, `barcode`, ""),
                  label: getLocalisationMessage("orders"),
                },
                {
                  type: RENDER,
                  name: "jurisdiction",
                  label: getLocalisationMessage("jurisdiction"),
                  render: row => _.get(row, `from_post_code.name`, ""),
                },
                {
                  type: RENDER,
                  name: "status",
                  label: getLocalisationMessage("status"),
                  render: row =>
                    formatOrderStatusCodeForLocalisation(
                      row.status,
                      getLocalisationMessage,
                    ),
                },
                {
                  type: ACTION_BY_INDEX,
                  name: "remove",
                  label: getLocalisationMessage("remove"),
                  dispatch: id => {
                    props.setOrder({});

                    setOrdersIssued(prev => {
                      const idx = prev.findIndex(i => i.id === id);
                      return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
                    });

                    if (inputRef && inputRef.current) {
                      inputRef.current.focus();
                    }
                  },
                },
              ]}
            />

            <FlexBox justify="flex-end" style={{ gap: 16, paddingRight: 16 }}>
              <CustomButton
                variant={OUTLINED}
                color={SECONDARY}
                onClick={() => setIsClose(true)}
              >
                {getLocalisationMessage("dismiss", "Dismiss")}
              </CustomButton>
              <CustomButton
                disabled={!(ordersIssued && ordersIssued.length > 0)}
                variant={CONTAINED}
                color={SECONDARY}
                onClick={() => props.onSubmit(ordersIssued)}
                startIcon={
                  isLoadingSubmit ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <CheckCircle />
                  )
                }
              >
                {getLocalisationMessage("confirm", "Confirm")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </CardContent>
      </Card>
    </FlexBox>
  );
}

HybridIIssuedCreate.propTypes = {
  getLocalisationMessage: PropTypes.func,
  onClose: PropTypes.func,
  setOrderNumber: PropTypes.func,
  onSubmit: PropTypes.func,
  setOrder: PropTypes.func,
  order: PropTypes.object,
  orderNumber: PropTypes.array,
  isOrderLoading: PropTypes.bool,
  error: PropTypes.bool,
  isLoadingSubmit: PropTypes.bool,
};

const mapStateToProps = state => ({
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HybridIIssuedCreate);
