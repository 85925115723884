import React, { useEffect, useState } from "react";
import { List, Map } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import AdminOrderMultiBoxUpdateDialogWrapper from "./AdminOrderMultiBoxUpdateDialogWrapper";
import { getValue, isEqualData, toJS } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { isMainAdminForProofOfPayment } from "../../helpers/isMainAdmin";
import { mapOrderNotesAndHistoryToActivities } from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceEnableAssignDriver,
  marketplaceDefaultOrderCreationEnabled,
  marketplaceGenerateTaxInvoiceEnabled,
} from "../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ROLE_FINANCE_USER } from "../../constants/AdminRoleTypes";
import { SENDER } from "../../constants/OrderPayerTypes";
import { CARD } from "../../constants/OrderPaymentTypes";
import {
  CUSTOMER_ITEM_URL,
  DRIVER_LIST_URL,
  SUPPLIER_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  COD,
  SERVICE,
  SERVICE_ADDED,
  TOTAL,
} from "../../constants/OrderChargeItemTypes";
import {
  getOrderActivities2,
  updateOrderIssuance,
  uploadOrderProofOfPayment,
  uploadOrderSignature,
} from "../../api/admin/AdminOrderApi";
import { getCustomer } from "../../api/admin/AdminCustomerApi";
import { getDriverLocation } from "../../api/shared/DriverApi";
import FlexBox, {
  JUSTIFY_CENTER,
  JUSTIFY_END,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import OrderActivitiesTimeline from "../../components/orders-core/OrderActivitiesTimeline";
import UpdateOrderContactDialog from "../../components/orders-core/UpdateOrderContactDialog";
import {
  BRIEF_TAB,
  CUSTOMER_TAB,
  HISTORY_TAB,
  INVENTORY,
  ISSUANCE,
  MAP_TAB,
  PROOF_OF_PAYMENT,
  SERVICES,
  SIGNATURE_TAB,
} from "../../components/order-details-dialog/OrderDetailsDialogTabs";
import OrderDetailsDialogCustomer from "../../components/order-details-dialog/OrderDetailsDialogCustomer";
import OrderDetailsDialogSignature from "../../components/order-details-dialog/OrderDetailsDialogSignature";
import OrderDetailsDialogProofOfPaymentSender from "../../components/order-details-dialog/OrderDetailsDialogProofOfPaymentSender";
import OrderDetailsDialogProofOfPaymentRecipient from "../../components/order-details-dialog/OrderDetailsDialogProofOfPaymentRecipient";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import OrderDetailsDialogMap from "../../components/order-details-dialog/v2/OrderDetailsDialogMap";
import { hasRole } from "../../helpers/RoleUtils";
import OrderDetailsDialogBriefV2 from "../../components/order-details-dialog/v2/OrderDetailsDialogBriefV2";
import { money } from "../../components/orders-core/AdminPostForm";
import { LocalAtmOutlined } from "@material-ui/icons";
import OrderDetailsDialogIssuance from "../../components/order-details-dialog/OrderDetailsDialogIssuance";
import OrderDetailsDialogForOrderList from "../../components/order-details-dialog/v2/OrderDetailsDialogForOrderList";

const DELIVERY_TO = "delivery_signature";
const SENDER_FROM = "sender_signature";
const useStyles = makeStyles({
  container: {
    minHeight: "300px",
    maxHeight: "480px",
    overflowY: "auto",
    margin: "0 6px 6px",
  },
  buttonRight: { float: props => (props.isRTL ? "left" : "right") },
});
const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        enableAssignDriverForSettings: getMarketplaceEnableAssignDriver(state),
        isMainAdminForProofOfPayment: isMainAdminForProofOfPayment(userRoles),
        isDefaultOrderCreation: marketplaceDefaultOrderCreationEnabled(state),
        isGenerateTaxInvoiceEnabled:
          marketplaceGenerateTaxInvoiceEnabled(state) &&
          hasRole(userRoles, ROLE_FINANCE_USER),
        getLocalisationMessage,
        isRTL: getIsRTL(state),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    pipeStreams(propsStream => {
      const {
        handler: onShowSubDialog,
        stream: onShowSubDialogStream,
      } = createEventHandler();

      const onSubmit = values => {
        const chargeItem = {
          charge_items: [
            {
              id: values.chargeItemId,
              receipt_id: values.photoId,
              receipt_number: values.receiptNumber,
            },
          ],
          payment_type: CARD,
        };
        uploadOrderProofOfPayment(
          values.orderId,
          toSnakeCase(chargeItem),
        ).catch(ResponseError.throw);
      };

      return propsStream
        .combineLatest(
          onShowSubDialogStream.startWith(null),
          (props, uploadType) => ({
            ...props,
            uploadType,
            onShowSubDialog,
            onSubmit,
          }),
        )
        .distinctUntilChanged(isEqualData);
    }),
  ),
  withState("submitting", "onSubmitting", false),
);

AdminOrderDetailsDialogWrapperForOrderList.propTypes = {
  setIsOpenDispatch: PropTypes.func,
  setIsOpenRedirect: PropTypes.func,
  setIsOpenRTO: PropTypes.func,
  order: PropTypes.instanceOf(Map),
  isDefaultOrderCreation: PropTypes.bool,
  onShowSubDialog: PropTypes.func,
  uploadType: PropTypes.string,
  lastSyncDate: PropTypes.string,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  createDriverSuggestHref: PropTypes.func,
  orderId: PropTypes.number,
  onRequestClose: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  submitting: PropTypes.bool,
  isGenerateTaxInvoiceEnabled: PropTypes.bool,
  isMainAdminForProofOfPayment: PropTypes.bool,
  editMultiBoxCount: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  setOrderId: PropTypes.func,
  refreshOrderList: PropTypes.func,
  setIsOpenBrief: PropTypes.func,
  isOpen: PropTypes.func,
  refreshOrderData: PropTypes.func,
  setIsOpenIssuance: PropTypes.func,
  setIsOpenRTOPrice: PropTypes.func,
  setBatchId: PropTypes.func,
  isRefreshOrderData: PropTypes.bool,
};

function AdminOrderDetailsDialogWrapperForOrderList(props) {
  const classes = useStyles();
  const [activities, setActivities] = useState(List());
  const [tab, setTab] = useState(BRIEF_TAB);
  useEffect(() => {
    if (tab === HISTORY_TAB && props.orderId)
      getOrderActivities2(props.orderId)
        .then(([notes, history]) => {
          const data = mapOrderNotesAndHistoryToActivities({ notes, history });
          setActivities(data);
        })
        .catch(err => {
          props.showErrorMessage(err);
        });
  }, [props.orderId, props.isRefreshOrderData, tab]);
  const { getLocalisationMessage, order, orderId } = props;
  const isProofOfPaymentSender = order.get("payer") === SENDER;
  let chargeItemForProofOfPaymentSender = {
    receipt_id: null,
    id: null,
    receipt_number: null,
    charge: null,
  };
  let chargeItemForProofOfPaymentRecipient = {
    receipt_id: null,
    id: null,
    receipt_number: null,
    charge: null,
  };
  const chargeItems = order.get("charge_items");
  if (chargeItems)
    chargeItems.toJS().forEach(item => {
      if (isProofOfPaymentSender) {
        if (item.charge_type === SERVICE)
          chargeItemForProofOfPaymentSender = item;
        else if (item.charge_type === COD)
          chargeItemForProofOfPaymentRecipient = item;
      } else if (item.charge_type === TOTAL)
        chargeItemForProofOfPaymentRecipient = item;
    });

  return (
    <OrderDetailsDialogForOrderList
      setIsOpenBrief={props.setIsOpenBrief}
      setIsOpenRTOPrice={props.setIsOpenRTOPrice}
      refreshOrderList={props.refreshOrderList}
      open={props.isOpen}
      onRequestClose={() => {
        props.onRequestClose();
        setTab(BRIEF_TAB);
      }}
      onRequestRefresh={props.refreshOrderData}
      tab={tab}
      setTab={setTab}
      order={order}
      isLoading={order.isEmpty()}
      onTabChange={v => setTab(v)}
      onEditClick={props.onEditClick}
      lastSyncDate={props.lastSyncDate}
      withoutMap={props.isDefaultOrderCreation}
      onShowSuggestedDriverLink={true}
      createDriverSuggestHref={props.createDriverSuggestHref}
      getDriverUrl={id => updateQuery(DRIVER_LIST_URL, { view: id })}
      getSupplierUrl={id => updateQuery(SUPPLIER_LIST_URL, { view: id })}
      getCustomerUrl={id => CUSTOMER_ITEM_URL + id}
      withTaxInvoice={props.isGenerateTaxInvoiceEnabled}
      getReverseUrl={id => props.setOrderId(id)}
      setIsOpenIssuance={props.setIsOpenIssuance}
      setIsOpenDispatch={props.setIsOpenDispatch}
      setIsOpenRedirect={props.setIsOpenRedirect}
      setIsOpenRTO={props.setIsOpenRTO}
    >
      <PageLoading isLoading={props.submitting} />
      <AdminOrderMultiBoxUpdateDialogWrapper
        open={props.editMultiBoxCount}
        orderId={orderId}
        onRequestClose={() => props.refreshOrderData()}
      />

      {Boolean(tab === BRIEF_TAB) && (
        <OrderDetailsDialogBriefV2 order={order} />
      )}
      {Boolean(tab === SERVICES) && (
        <Card style={{ padding: "1.5rem", margin: "1.5rem" }}>
          <FlexBox justify={JUSTIFY_CENTER}>
            <span style={{ fontSize: "1.5rem" }}>
              {getLocalisationMessage("services")}
            </span>
          </FlexBox>
          <FlexBox
            style={{ marginBottom: "10px" }}
            justify={JUSTIFY_SPACE_AROUND}
          >
            <span style={{ width: "65%" }}>
              {getLocalisationMessage("nomination")}
            </span>
            <span style={{ width: "10%" }}>
              {getLocalisationMessage("cost")}
            </span>
            <span style={{ width: "10%" }}>
              {getLocalisationMessage("payment_type")}
            </span>
            <span style={{ padding: "0 .5rem", width: "10%" }}>
              {getLocalisationMessage("status")}
            </span>
          </FlexBox>
          {order.get("charge_items") &&
            order.get("charge_items").size > 0 &&
            order
              .get("charge_items")
              .filter(v => v.get("charge_type") === SERVICE_ADDED)
              .map(v => toJS(v))
              .map(v => (
                <FlexBox
                  style={{
                    marginBottom: "10px",
                    color: "rgba(38, 50, 56, 0.7)",
                  }}
                  justify={JUSTIFY_SPACE_AROUND}
                >
                  <span style={{ width: "65%" }}>{v.name}</span>
                  <span style={{ width: "10%" }}>
                    {money(v.charge) + getLocalisationMessage("som", "so'm")}
                  </span>
                  <span style={{ width: "10%" }}>
                    {getLocalisationMessage(v.payment_type || "na")}
                  </span>
                  {v.paid ? (
                    <span
                      style={{
                        backgroundColor: "rgba(33, 192, 4, 0.1)",
                        padding: "0 .5rem",
                        borderRadius: "4px",
                        color: "#21C004",
                        width: "10%",
                        height: "max-content",
                      }}
                    >
                      {getLocalisationMessage("paid", "Paid")}
                    </span>
                  ) : (
                    <span
                      style={{
                        backgroundColor: "rgba(255, 152, 0, 0.1)",
                        padding: "0 .5rem",
                        borderRadius: "4px",
                        color: "#FF9800",
                        width: "10%",
                        height: "max-content",
                      }}
                    >
                      {getLocalisationMessage("not_paid", "Not paid")}
                    </span>
                  )}
                </FlexBox>
              ))}
          <FlexBox
            style={{ marginTop: "1.5rem" }}
            justify={JUSTIFY_SPACE_AROUND}
          >
            <FlexBox direction="column">
              <FlexBox>
                <LocalAtmOutlined />
                <span style={{ marginLeft: ".5rem" }}>
                  {getLocalisationMessage("payment_type", "payment type")}
                </span>
              </FlexBox>
              <span
                style={{
                  fontWeight: "500",
                  color: "rgba(38, 50, 56, 0.7)",
                  marginLeft: "2rem",
                }}
              >
                {getLocalisationMessage(order.get("payment_type"))}
              </span>
            </FlexBox>
            <FlexBox>
              <FlexBox direction="column">
                <span>{getLocalisationMessage("total")} :</span>
                <span>
                  {`${
                    order.get("charge_items") &&
                    order.get("charge_items").size > 0
                      ? money(
                          order
                            .get("charge_items")
                            .filter(v => v.get("charge_type") === TOTAL)
                            .reduce(
                              (acc, item) =>
                                acc + parseFloat(item.get("charge")),
                              0,
                            ),
                        )
                      : 0
                  } ${getLocalisationMessage("som", "so'm")}`}
                </span>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </Card>
      )}

      {Boolean(tab === CUSTOMER_TAB) && (
        <OrderDetailsDialogCustomer
          getCustomer={getCustomer}
          customerId={order.getIn(["customer", "id"])}
        />
      )}

      {Boolean(tab === HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <OrderActivitiesTimeline
              setBatchId={props.setBatchId}
              showDriver={true}
              showSupplier={true}
              showWarehouse={true}
              driverItemUrl={id =>
                updateQuery(DRIVER_LIST_URL, fp.set("view", id))
              }
              driverItemLocationUrl={id =>
                updateQuery(
                  DRIVER_LIST_URL,
                  fp.flow(fp.set("view", id), fp.set("view_tab", "map")),
                )
              }
              activities={activities}
            />
          </CardContent>
        </Card>
      )}

      {Boolean(tab === MAP_TAB) && (
        <OrderDetailsDialogMap
          order={order}
          getDriverLocation={getDriverLocation}
        />
      )}

      {Boolean(tab === SIGNATURE_TAB) && (
        <FlexBox container={8} flex={true} className={classes.container}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                photoId={order.get("actual_sender_id_card_id")}
                signatureId={order.get("actual_sender_signature_id")}
                onUpload={() => props.onShowSubDialog(SENDER_FROM)}
                header={getLocalisationMessage(
                  "proof_of_pickup",
                  "Proof of Pickup",
                )}
              />
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                header={getLocalisationMessage(
                  "proof_of_delivery",
                  "Proof of Delivery",
                )}
                verifiedWithFaceId={order.get("verified_with_face_id")}
                photoId={order.get("actual_recipient_id_card_id")}
                signatureId={order.get("actual_recipient_signature_id")}
                onUpload={() => props.onShowSubDialog(DELIVERY_TO)}
              />

              <UpdateOrderContactDialog
                onRequestClose={() => props.onShowSubDialog(null)}
                open={Boolean(
                  props.uploadType === DELIVERY_TO ||
                    props.uploadType === SENDER_FROM,
                )}
                title={
                  props.uploadType === DELIVERY_TO
                    ? getLocalisationMessage(
                        "update_recipient_info",
                        "Update Recipient Info",
                      )
                    : getLocalisationMessage(
                        "update_sender_info",
                        "Update Sender Info",
                      )
                }
                initialValues={
                  props.uploadType === DELIVERY_TO
                    ? {
                        type: DELIVERY_TO,
                        orderId,
                        name: order.get("actual_recipient_name"),
                        phone: order.get("actual_recipient_phone"),
                        photoId: order.get("actual_recipient_id_card_id"),
                        signatureId: order.get("actual_recipient_signature_id"),
                      }
                    : {
                        type: SENDER_FROM,
                        orderId,
                        name: order.get("actual_sender_name"),
                        phone: order.get("actual_sender_phone"),
                        photoId: order.get("actual_sender_id_card_id"),
                        signatureId: order.get("actual_sender_signature_id"),
                      }
                }
                onSubmit={values =>
                  uploadOrderSignature(orderId, toSnakeCase(values)).catch(
                    ResponseError.throw,
                  )
                }
                onSubmitSuccess={() => {
                  props.refreshOrderData();
                  props.onShowSubDialog(null);
                }}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      {props.isMainAdminForProofOfPayment && Boolean(tab === PROOF_OF_PAYMENT) && (
        <FlexBox container={8} flex={true} className={classes.container}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogProofOfPaymentSender
                photoId={chargeItemForProofOfPaymentSender.receipt_id}
                header={getLocalisationMessage(
                  "paid_by_sender",
                  "Paid by Sender",
                )}
                initialValues={{
                  orderId: order.get("id"),
                  chargeItemId: chargeItemForProofOfPaymentSender.id,
                  receiptNumber:
                    chargeItemForProofOfPaymentSender.receipt_number,
                  photoId: chargeItemForProofOfPaymentSender.receipt_id,
                  amount: chargeItemForProofOfPaymentSender.charge,
                }}
                onSubmit={props.onSubmit}
                onSubmitSuccess={() =>
                  setTimeout(() => props.refreshOrderData(), 500)
                }
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogProofOfPaymentRecipient
                header={getLocalisationMessage(
                  "paid_by_recipient",
                  "Paid by Recipient",
                )}
                photoId={chargeItemForProofOfPaymentRecipient.receipt_id}
                initialValues={{
                  orderId: order.get("id"),
                  chargeItemId: chargeItemForProofOfPaymentRecipient.id,
                  receiptNumber:
                    chargeItemForProofOfPaymentRecipient.receipt_number,
                  photoId: chargeItemForProofOfPaymentRecipient.receipt_id,
                  amount: chargeItemForProofOfPaymentRecipient.charge,
                }}
                onSubmit={props.onSubmit}
                onSubmitSuccess={() =>
                  setTimeout(() => props.refreshOrderData(), 500)
                }
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      {Boolean(tab === ISSUANCE) && (
        <FlexBox container={8} flex={true} className={classes.container}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogIssuance
                initialValues={{
                  name: order.getIn(["recipient_data", "name"]),
                }}
                header={getLocalisationMessage("recipient", "Recipient")}
                orderId={order.get("id")}
                onSubmit={values =>
                  updateOrderIssuance(orderId, toSnakeCase(values))
                    .catch(ResponseError.throw)
                    .then(res => {
                      props.showSuccessMessage(res.status);
                      setTab(SIGNATURE_TAB);
                    })
                }
                onSubmitSuccess={() => {
                  props.refreshOrderData();
                }}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      {Boolean(tab === INVENTORY) && (
        <Card style={{ padding: "1.5rem", margin: "1.5rem" }}>
          {order.get("content_items") && order.get("content_items").size > 0 && (
            <FlexBox justify={JUSTIFY_CENTER}>
              <span style={{ fontSize: "1.5rem" }}>
                {getLocalisationMessage("inventory")}
              </span>
            </FlexBox>
          )}
          {order.get("content_items") && order.get("content_items").size > 0 && (
            <FlexBox
              style={{ marginBottom: "10px" }}
              justify={JUSTIFY_SPACE_AROUND}
            >
              <span style={{ width: "35%" }}>
                {getLocalisationMessage("nomination", "Nomination")}
              </span>
              <span style={{ width: "30%" }}>
                {getLocalisationMessage("hs_tariff_number", "HS tariff number")}
              </span>
              <span style={{ width: "10%" }}>
                {getLocalisationMessage("weight_kg", "Weight (kg)")}
              </span>
              <span style={{ width: "10%" }}>
                {getLocalisationMessage("count")}
              </span>
              <span style={{ padding: "0 .5rem", width: "15%" }}>
                {getLocalisationMessage("currency", "Currency")}
              </span>
            </FlexBox>
          )}
          {order.get("content_items") &&
            order.get("content_items").size > 0 &&
            order
              .get("content_items")
              .map(v => toJS(v))
              .map(v => (
                <FlexBox
                  style={{
                    marginBottom: "10px",
                    color: "rgba(38, 50, 56, 0.7)",
                  }}
                  justify={JUSTIFY_SPACE_AROUND}
                >
                  <span style={{ width: "35%" }}>{v.name}</span>
                  <span style={{ width: "30%" }}>{v.tariff_code}</span>
                  <span style={{ width: "10%" }}>{v.net_weight}</span>
                  <span style={{ width: "10%" }}>{v.qty}</span>
                  <span style={{ width: "15%" }}>
                    {`${money(v.price)} ${getValue(v, "currency.name")}`}
                  </span>
                </FlexBox>
              ))}

          {order.get("product_categories") &&
            order.get("product_categories").size > 0 && (
              <FlexBox justify={JUSTIFY_CENTER}>
                <span style={{ fontSize: "1.5rem" }}>
                  {getLocalisationMessage(
                    "product_categories",
                    "Product Categories",
                  )}
                </span>
              </FlexBox>
            )}
          {order.get("product_categories") &&
            order.get("product_categories").size > 0 && (
              <FlexBox
                style={{ marginBottom: "10px" }}
                justify={JUSTIFY_SPACE_AROUND}
              >
                <span style={{ width: "35%" }}>
                  {getLocalisationMessage("name", "name")}
                </span>
                <span style={{ width: "10%" }}>
                  {getLocalisationMessage("weight_g", "Weight (g)")}
                </span>
                <span style={{ width: "10%" }}>
                  {getLocalisationMessage("count")}
                </span>
              </FlexBox>
            )}
          {order.get("product_categories") &&
            order.get("product_categories").size > 0 &&
            order
              .get("product_categories")
              .map(v => toJS(v))
              .map(v => (
                <FlexBox
                  style={{
                    marginBottom: "10px",
                    color: "rgba(38, 50, 56, 0.7)",
                  }}
                  justify={JUSTIFY_SPACE_AROUND}
                >
                  <span style={{ width: "35%" }}>
                    {getValue(v, "category_type.id") === 1
                      ? getValue(v, "other_name", " ")
                      : getValue(v, "category_type.name")}
                  </span>
                  <span style={{ width: "10%" }}>{v.weight}</span>
                  <span style={{ width: "10%" }}>{v.count}</span>
                </FlexBox>
              ))}

          {order.get("content_items") && order.get("content_items").size > 0 && (
            <FlexBox style={{ marginTop: "1.5rem" }} justify={JUSTIFY_END}>
              <FlexBox style={{ marginRight: "5rem" }}>
                <span>
                  {getLocalisationMessage("total")}:{" "}
                  {`  ${
                    order.get("content_items") &&
                    order.get("content_items").size > 0
                      ? money(
                          order
                            .get("content_items")
                            .reduce((acc, item) => acc + item.get("price"), 0),
                        )
                      : 0
                  } ${
                    order.get("content_items") &&
                    order.get("content_items").size > 0
                      ? order
                          .get("content_items")
                          .first()
                          .getIn(["currency", "name"])
                      : getLocalisationMessage("som", "So'm")
                  }`}
                </span>
              </FlexBox>
            </FlexBox>
          )}
        </Card>
      )}
    </OrderDetailsDialogForOrderList>
  );
}

export default enhancer(AdminOrderDetailsDialogWrapperForOrderList);
