import _ from "lodash";
import fp from "lodash/fp";
import { getValue } from "./DataUtils";

export const integerInputRangeValidator = fp.flow(
  (message, min, max) => value =>
    fp.isFinite(value) && value >= fp.toFinite(min) && value <= fp.toFinite(max)
      ? null
      : message,
);

export const createSettingsSelectValidator = fp.memoize(values => value => {
  if (!value) return "Select Value";

  const count = _.filter(values, c => {
    const field = getValue(c, "fieldOption");
    return field && field.get("key") === value.get("key");
  });

  return count.length > 1 ? "Settings Dublicated" : null;
});
