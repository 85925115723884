import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Card, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  ORDER_TRACK_URL,
  CUSTOMER_ITEM_URL,
} from "../../constants/AdminPathConstants";
import {
  getCODWithdraw,
  getCODWithdrawOrders,
  updateCODWithdrawStatus,
  updateCODWithdrawReference,
} from "../../api/admin/AdminFinanceApi";
import { getAttachmentUrl } from "../../api/shared/FileApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import CODWithdrawDetails from "../../components/finance-core/CODWithdrawDetails";
import CODWithdrawOrderList from "../../components/finance-core/CODWithdrawOrderList";
import UpdateCODWithdrawStatusDialog from "../../components/finance-core/UpdateCODWithdrawStatusDialog";
import UpdateCODWithdrawReferenceDialog from "../../components/finance-core/UpdateCODWithdrawReferenceDialog";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_COD_WITHDRAW_STATEMENT_URL,
  ADMIN_COD_WITHDRAW_DETAILED_STATEMENT_URL,
} from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const UPDATE_STATUS_DIALOG_HASH = "#USDH";
const UPDATE_REFERENCE_DIALOG_HASH = "#URDH";

const ROLE_FINANCE_APPROVER = "ROLE_FINANCE_APPROVER";
const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";

const enhancer = compose(
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  withTheme,
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isFinanceAdmin:
          hasRole(userRoles, ROLE_FINANCE_ADMIN) ||
          hasRole(userRoles, ROLE_FINANCE_APPROVER),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    root: { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    orders: {
      flex: "1 1 0%",
      marginTop: "12px",
      display: "flex",
      "& > div": { flex: "1 1 0%", display: "flex" },
    },
    appBarRightAction: {
      marginTop: "8px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionButton: {
      color: props => props.theme.palette.appBarTextColor,
      top: "-7px",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const idStream = propsStream
      .map(fp.flow(fp.get("params.id"), fp.toFinite))
      .distinctUntilChanged();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), DataListFilter.create))
      .distinctUntilChanged(isEqualData);

    const itemResponseStream = idStream
      .switchMap(id =>
        getCODWithdraw(id)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const ordersResponseStream = idStream
      .combineLatest(filterStream)
      .switchMap(([id, filter]) =>
        getCODWithdrawOrders(id, filter)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(
        filterStream,
        itemResponseStream,
        ordersResponseStream,
        (props, filter, itemResponse, ordersResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          item: itemResponse.get("payload"),
          isLoading: itemResponse.get("pending"),
          orders: ordersResponse.get("payload"),
          ordersTotal: ordersResponse.get("total"),
          ordersLoading: ordersResponse.get("pending"),
        }),
      );
  }),
);

AdminCODWithdrawItemContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  ordersTotal: PropTypes.number,
  ordersLoading: PropTypes.bool,
  orders: PropTypes.instanceOf(List),
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  isFinanceAdmin: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminCODWithdrawItemContainer(props) {
  const { item, classes, location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage(
        "withdraw_request",
        "Withdraw Request",
      )} #${props.item.get("id")}`}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          {props.isFinanceAdmin && (
            <Button
              target="_blank"
              component="a"
              className={classes.appBarRightActionButton}
              href={updateQuery(ADMIN_COD_WITHDRAW_DETAILED_STATEMENT_URL, {
                id: item.get("id"),
              })}
            >
              {getLocalisationMessage(
                "detailed_cod_statement",
                "Detailed COD Statement",
              )}
            </Button>
          )}

          {item.get("cod_statement_id") ? (
            <Button
              className={classes.appBarRightActionButton}
              onClick={() => {
                Promise.resolve(
                  getAttachmentUrl(item.get("cod_statement_id")),
                ).then(response => {
                  window.location.href = response.data;
                });
              }}
            >
              {getLocalisationMessage("cod_statement", "COD Statement")}
            </Button>
          ) : (
            <Button
              target="_blank"
              component="a"
              className={classes.appBarRightActionButton}
              href={updateQuery(CREATE_COD_WITHDRAW_STATEMENT_URL, {
                id: item.get("id"),
              })}
            >
              {getLocalisationMessage("cod_statement", "COD Statement")}
            </Button>
          )}
        </div>
      }
    >
      <PageLoading isLoading={props.isLoading} />

      <UpdateCODWithdrawStatusDialog
        open={location.hash === UPDATE_STATUS_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ status: item.get("status") }}
        onSubmit={fp.flow(toSnakeCase, values =>
          updateCODWithdrawStatus(item.get("id"), values).catch(
            ResponseError.throw,
          ),
        )}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated",
              "Successfully Updated",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <UpdateCODWithdrawReferenceDialog
        open={location.hash === UPDATE_REFERENCE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ reference: item.get("reference") }}
        onSubmit={fp.flow(toSnakeCase, values =>
          updateCODWithdrawReference(item.get("id"), values).catch(
            ResponseError.throw,
          ),
        )}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated",
              "Successfully Updated",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <div className={classes.root}>
        {!props.isLoading && (
          <CODWithdrawDetails
            item={item}
            onEditStatusClick={() =>
              props.replaceLocationHash(UPDATE_STATUS_DIALOG_HASH)
            }
            onEditReferenceClick={() =>
              props.replaceLocationHash(UPDATE_REFERENCE_DIALOG_HASH)
            }
          />
        )}

        <Card className={classes.orders}>
          <CODWithdrawOrderList
            list={props.orders}
            totalCount={props.ordersTotal}
            isLoading={props.ordersLoading}
            filter={props.filter}
            onFilterChange={filter => props.setLocationQueryFilter(filter)}
            createCustomerHref={id => CUSTOMER_ITEM_URL + id}
            createOrderHref={id => updateQuery(ORDER_TRACK_URL, { view: id })}
          />
        </Card>
      </div>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCODWithdrawItemContainer);
