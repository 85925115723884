import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import AdminMarketplaceDetailsSettingsContainer from "./AdminMarketplaceDetailsSettingsContainer";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

AdminMarketplaceDetailsContainer.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
};

function AdminMarketplaceDetailsContainer(props) {
  const { location } = props;

  return (
    <AdminMarketplaceDetailsSettingsContainer
      tab={location.query.view_tab}
      onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
    />
  );
}

export default enhancer(AdminMarketplaceDetailsContainer);
