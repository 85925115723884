import React from "react";
import Immutable from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  INTERNATIONAL_SETTINGS_COUNTRIES_URL,
  INTERNATIONAL_SETTINGS_FORMAT_URL,
  INTERNATIONAL_SETTINGS_SUB_CLASSES_URL,
  INTERNATIONAL_SETTINGS_TYPES_URL,
} from "../../constants/AdminPathConstants";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InternationalSettingsItem from "../../components/settings-core/InternationalSettingsItem";

const useStyles = makeStyles({
  flexRoot: {
    flexWrap: "wrap",
    padding: 16,
    gap: 13,
  },
  flexChild: {
    width: "calc(24.5%)",
  },
});
const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdminInternationalSettingsContainer.propTypes = {
  setLocation: PropTypes.func,
  i18n: PropTypes.instanceOf(Immutable.Map),
};

function AdminInternationalSettingsContainer({ i18n, setLocation }) {
  const { flexChild, flexRoot } = useStyles();

  return (
    <AdminAppLayout
      slug="settings_dashboard"
      title={i18n.get("settings", "Settings")}
    >
      <FlexBox flex={true} direction="column" style={{ padding: 16 }}>
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ padding: "0 15px 10px 15px" }}
        >
          {i18n.get("settings", "Settings")}
        </Typography>
        <FlexBox gutter={8} className={flexRoot}>
          <FlexBox className={flexChild}>
            <InternationalSettingsItem
              title={i18n.get("add_countries", "Add countries")}
              onClick={() => setLocation(INTERNATIONAL_SETTINGS_COUNTRIES_URL)}
            />
          </FlexBox>
          <FlexBox className={flexChild}>
            <InternationalSettingsItem
              title={i18n.get("add_format", "Add format")}
              onClick={() => setLocation(INTERNATIONAL_SETTINGS_FORMAT_URL)}
            />
          </FlexBox>
          <FlexBox className={flexChild}>
            <InternationalSettingsItem
              title={i18n.get("add_subclasses", "Add Subclasses")}
              onClick={() =>
                setLocation(INTERNATIONAL_SETTINGS_SUB_CLASSES_URL)
              }
            />
          </FlexBox>
          <FlexBox className={flexChild}>
            <InternationalSettingsItem
              title={i18n.get("add_types", "Add Types")}
              onClick={() => setLocation(INTERNATIONAL_SETTINGS_TYPES_URL)}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminInternationalSettingsContainer);
