import { Observable } from "rxjs";
import React from "react";
import { subDays, endOfDay, startOfDay, differenceInMinutes } from "date-fns";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Polyline, ZoomControl } from "react-google-map-components";
import { Route } from "react-leflet-map-components";
import {
  mapListResponseStream,
  mapObjectResponseStream,
} from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { sockJSHOC } from "../../helpers/sockJSHOC";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatDateToUrl,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { toAlertsListFilter } from "../../helpers/AlertsHelpers";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMarketplaceMapCenter } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { ALERTED, DELETED } from "../../constants/AlertStatuses";
import { ROLE_RADAR_VIEWER } from "../../constants/AdminRoleTypes";
import { ACTIVE } from "../../constants/UpperOverallStatus";
import {
  getAlertsList,
  getDriverDetailedAlert,
  getDriverGroupStatistics,
} from "../../api/admin/AdminAlertsApi";
import {
  updateBoundary,
  getDriverLocationItem,
  getDriverOrdersLocation,
} from "../../api/admin/AdminDriverApi";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import AdminDriverAlertHistoryWrapper from "../../wrappers/admin/AdminDriverAlertHistoryWrapper";
import AdminDriversRadarFilterWrapper from "../../wrappers/admin/AdminDriversRadarFilterWrapper";
import PinMarker from "../../components/maps/PinMarker";
import VehicleMarker from "../../components/maps/VehicleMarker";
import GoogleMapWrapper from "../../components/maps/GoogleMapWrapper";
import { SidebarMenu } from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import AppLayoutDrawer from "../../components/app-layout/AppLayoutDrawer";
import DriversRadarSearch from "../../components/drivers-radar/DriversRadarSearch";
import DriverRadarVehicles from "../../components/drivers-radar/DriverRadarVehicles";
import DriversRadarDateForm from "../../components/drivers-radar/DriversRadarDateForm";
import DriversRadarAlertsList from "../../components/drivers-radar/DriversRadarAlertsList";
import DriversRadarTimeSlider from "../../components/drivers-radar/DriversRadarTimeSlider";
import { ADMIN_DRIVER_LOCATION_QUERY } from "../../../shared/constants/DriverLocationChannelConstants";
import { DRIVER_LOCATION_API } from "../../../server/api/shared/DriverApi";
import LeafletMapWrapper from "../../components/maps-leaflet/LeafletMapWrapper";
import VehicleIcon from "../../components/maps/assets/vehicle_icon.svg";
import PinCompleted from "../../components/maps/assets/pin_completed.png";
import PinIncomplete from "../../components/maps/assets/pin_incomplete.png";
import EmptyIcon from "../../components/maps/assets/empty.svg";
// import OSMVehicleMarker from '../../components/maps/osm/VehicleMarker';
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { OPEN_STREET_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import LeafletDriverRadarVehicles from "../../components/maps/osm/DriverRadarVehicles";
import { hasRole } from "../../helpers/RoleUtils";

const ALERTS_FILTER_DIALOG_HASH = "#AFDH";

const getSliceTime = fp.flow(
  fp.get("query.sliceTime"),
  fp.toFinite,
  x => x || 5,
);

const enhancer = compose(
  useSheet({
    container: {
      height: "100%",
    },
    root: { position: "relative" },
    tools: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      right: 0,
      minWidth: "1325px",
    },

    drawer: {
      "& > div": {
        top: 0,
      },
    },
    driversList: {
      top: 0,
      // left: 0,
      zIndex: 1,
      height: "100%",
      paddingTop: "77px",
      position: "absolute",
    },
    driverLocationLoader: {
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      position: "absolute",
    },
    map: { flex: "1 1 0%", zIndex: 0 },
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      return {
        defaultCenter: getMarketplaceMapCenter(state),
        isRadarViewer: hasRole(userRoles, ROLE_RADAR_VIEWER),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        mapProvider: getMapProvider(state),
      };
    },
    { showErrorMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withState("state", "setState", {
    listPosition: 0,
    scrollingList: false,
    updatingDriver: false,
    alertHistoryFilter: new DataListFilter({
      alert_statuses: [ALERTED, DELETED].join(","),
    }),
    subscription: null,
    driverItemLocationsFilter: new DataListFilter(),
  }),
  sockJSHOC(DRIVER_LOCATION_API),
  mapPropsStream(
    pipeStreams(
      // -------------- Initial Filter -----------
      propsStream => {
        const initialFilterStream = propsStream
          .take(1)
          .map(fp.flow(fp.get("location.query"), toAlertsListFilter))
          .withLatestFrom(propsStream)
          .do(([filter, { setLocationQuery }]) => {
            const status = filter.getValue("status", null);
            const toDateTimeFilter = filter.getValue("to_date_time", null);
            const fromDateTimeFilter = filter.getValue("from_date_time", null);

            let toDateTime = toDateTimeFilter;

            if (!status) {
              setLocationQuery(fp.set("status", ACTIVE));
            }

            if (!toDateTime) {
              toDateTime = formatDateTimeToUrl(endOfDay(new Date()));

              setLocationQuery(fp.set("to_date_time", toDateTime));
            }

            if (!fromDateTimeFilter) {
              setLocationQuery(
                fp.set(
                  "from_date_time",
                  formatDateTimeToUrl(
                    startOfDay(subDays(new Date(toDateTime), 6)),
                  ),
                ),
              );
            }
          })
          .startWith(null);

        return propsStream
          .combineLatest(initialFilterStream, props => props)
          .distinctUntilChanged(isEqualData);
      },
      // -------------- Initial Filter -----------

      // ------------------ Filter ---------------
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toAlertsListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ------------------ Filter ---------------

      // ---------- Driver Group Statistics ------
      propsStream => {
        const {
          handler: onRefreshStatistic,
          stream: onRefreshStatisticStream,
        } = createEventHandler();

        const driversGroupStatisticsStream = propsStream
          .take(1)
          .switchMap(({ filter }) =>
            getDriverGroupStatistics({
              to_date_time: filter.getValue("to_date_time"),
              from_date_time: filter.getValue("from_date_time"),
            })
              .repeatWhen(() => onRefreshStatisticStream)
              .let(mapObjectResponseStream)
              .scan((prev, next) =>
                next.get("pending") === false
                  ? next
                  : next.set("payload", prev.get("payload")),
              ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            driversGroupStatisticsStream,
            (props, driversGroupStatistics) => ({
              ...props,
              onRefreshStatistic,
              groupStatistics: driversGroupStatistics.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // ---------- Driver Group Statistics ------

      propsStream => {
        const {
          handler: onDriverDetailedAlert,
          stream: onDriverDetailedAlertStream,
        } = createEventHandler();
        const {
          handler: onAlertDetailRefresh,
          stream: onAlertDetailRefreshStream,
        } = createEventHandler();
        const {
          handler: onChangeBounds,
          stream: onChangeBoundsStream,
        } = createEventHandler();

        const alertsListResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getAlertsList(props.filter)
              .repeatWhen(() => onAlertDetailRefreshStream)
              .let(mapListResponseStream),
          )
          .distinctUntilChanged(isEqualData);

        const driverDetailedResponseStream = propsStream
          .take(1)
          .map(fp.get("location.query.alertItem"))
          .merge(onDriverDetailedAlertStream)
          .filter(fp.toFinite)
          .distinctUntilChanged(isEqualData)
          .switchMap(id =>
            getDriverDetailedAlert(id)
              .repeatWhen(() => onAlertDetailRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .startWith({})
          .let(mapObjectResponseStream)
          .distinctUntilChanged(isEqualData);

        const driverLocationsItemStream = propsStream
          .map(
            fp.flow(
              fp.get("location.query"),
              fp.pick(["filterDate", "filterItem", "historyItem"]),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .filter(({ filterItem, historyItem }) => filterItem || historyItem)
          .switchMap(({ filterItem, filterDate, historyItem }) =>
            getDriverLocationItem(
              historyItem || filterItem,
              new DataListFilter({
                date: formatDateToUrl(historyItem ? new Date() : filterDate),
              }),
            ).catch(error => Observable.of({ error })),
          )
          .startWith({
            payload: {
              count: 0,
              paths: [[]],
              locations: [],
            },
          })
          .combineLatest(propsStream, (data, props) => [data, props])
          .map(([data, props]) =>
            fp.flow(
              fp.update("pending", Boolean),
              fp.update(
                "payload",
                fp.flow(
                  fp.get("data"),
                  fp.toArray,
                  x =>
                    x.sort((a, b) => {
                      const aDate = new Date(a.date).getTime();
                      const bDate = new Date(b.date).getTime();

                      if (aDate > bDate) {
                        return 1;
                      }
                      if (aDate < bDate) {
                        return -1;
                      }

                      return 0;
                    }),
                  x => {
                    const coordinates = {
                      count: 0,
                      paths: [[]],
                      locations: [],
                    };

                    let index = 0;
                    let lastDate;
                    const sliceTime = getSliceTime(props.location);

                    x.forEach(i => {
                      if (lastDate !== i.date) {
                        if (
                          lastDate &&
                          differenceInMinutes(i.date, lastDate) >= sliceTime
                        ) {
                          coordinates.paths.push([]);
                          index += 1;
                        }

                        lastDate = i.date;

                        coordinates.locations.push({
                          ...i,
                          lng: i.lon,
                        });

                        coordinates.paths[index].push({
                          ...i,
                          lng: i.lon,
                        });
                      }
                    });

                    return coordinates;
                  },
                ),
              ),
              Immutable.fromJS,
            )(data),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            alertsListResponseStream,
            driverDetailedResponseStream,
            driverLocationsItemStream,
            onChangeBoundsStream.map(fp.get("bounds")).startWith({}),
            (
              props,
              alertsListResponse,
              driverDetailedResponse,
              driverItemResponse,
              bounds,
            ) => ({
              ...props,
              bounds,
              onDriverDetailedAlert,
              onAlertDetailRefresh,
              isLoadingAlertsList: alertsListResponse.get("pending"),
              alertsList: alertsListResponse.getIn(["payload", "list"]),
              alertsListTotal: alertsListResponse.getIn(["payload", "total"]),
              driverDetailedAlert: driverDetailedResponse.get("payload"),
              isLoadingDriverDetailedAlert: driverDetailedResponse.get(
                "pending",
              ),
              onChangeBounds,
              isLoadingDriver: driverItemResponse.get("pending"),
              driverItem: driverItemResponse.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },

      // -------------- Bounds to JSON -----------
      propsStream => {
        const boundsToJSONStream = propsStream
          .distinctUntilKeyChanged("bounds")
          .filter(({ bounds }) => !fp.isEmpty(bounds))
          .map(({ bounds }) => {
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();

            return Immutable.fromJS({
              north_east: {
                lat: northEast.lat(),
                lon: northEast.lng(),
              },
              south_west: {
                lat: southWest.lat(),
                lon: southWest.lng(),
              },
            });
          })
          .startWith(Immutable.Map())
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(boundsToJSONStream, (props, rectBounds) => ({
            ...props,

            rectBounds,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // -------------- Bounds to JSON -----------

      // ----------- Subscribing Locations -------
      propsStream => {
        const {
          handler: onCustomUpdateDriver,
          stream: onCustomUpdateDriverStream,
        } = createEventHandler();

        const subscribingLocationStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            ({ socketClient }) =>
              socketClient.connected && fp.isEmpty(socketClient.subscriptions),
          )
          .switchMap(
            ({ socketClient, setState }) =>
              new Observable(observer => {
                const subscription = socketClient.subscribe(
                  ADMIN_DRIVER_LOCATION_QUERY,
                  ({ body }) => {
                    observer.next(JSON.parse(body));
                  },
                );

                setState(state => ({ ...state, subscription }));
              }),
          )
          .merge(onCustomUpdateDriverStream)
          .map(update => Immutable.fromJS(update))
          .startWith(Immutable.Map());

        return propsStream
          .combineLatest(subscribingLocationStream, (props, updatedDriver) => ({
            ...props,

            onCustomUpdateDriver,
            updatedDriver,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ----------- Subscribing Locations -------

      // ------------- Saving Locations ----------
      propsStream => {
        const {
          handler: onSaveLocations,
          stream: onSaveLocationsStream,
        } = createEventHandler();

        return propsStream
          .combineLatest(
            onSaveLocationsStream
              .distinctUntilChanged(isEqualData)
              .startWith(Immutable.OrderedMap()),
            (props, savedLocations) => ({
              ...props,

              savedLocations,
              onSaveLocations,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // ------------- Saving Locations ----------

      propsStream => {
        const {
          handler: onChangeTime,
          stream: onChangeTimeStream,
        } = createEventHandler();
        const {
          handler: onUpdateBoundary,
          stream: onUpdateBoundaryStream,
        } = createEventHandler();

        const driverLocationsStream = propsStream
          .distinctUntilKeyChanged("updatedDriver", isEqualData)
          .filter(({ updatedDriver }) => updatedDriver.size > 0)
          .map(({ updatedDriver, savedLocations, onSaveLocations }) => {
            const newLocations = Immutable.OrderedMap().withMutations(map => {
              map.merge(savedLocations);

              map.setIn(
                [updatedDriver.get("driver_id"), "location"],
                updatedDriver,
              );
            });

            onSaveLocations(newLocations);

            return newLocations;
          })
          .filter(locations => locations.size > 0)
          .withLatestFrom(propsStream)
          .combineLatest(propsStream, ([locations], props) => [
            locations,
            props,
          ])
          .map(([locations, { bounds, filter }]) => {
            const supplierId = fp.toFinite(filter.get("supplier_id"));

            return Immutable.OrderedMap().withMutations(map => {
              locations.forEach((x, idx) => {
                if (
                  !fp.isEmpty(bounds) &&
                  bounds.contains({
                    lat: x.getIn(["location", "lat"]),
                    lng: x.getIn(["location", "lon"]),
                  })
                ) {
                  if (supplierId > 0) {
                    if (x.getIn(["driver", "supplier_id"]) === supplierId) {
                      map.set(idx, x);
                    }
                  } else {
                    map.set(idx, x);
                  }
                }
              });
            });
          })
          .distinctUntilChanged(isEqualData)
          .startWith(Immutable.OrderedMap())
          .map(locations => locations.slice(0, 100));

        const ordersCountStream = propsStream
          .distinctUntilKeyChanged("alertsList")
          .filter(
            props => !props.isLoadingAlertsList && props.alertsList.size > 0,
          )
          .map(props =>
            props.alertsList.reduce((acc, x) => x.get("orders") + acc, 0),
          )
          .distinctUntilChanged(isEqualData)
          .startWith(0);

        const sliceDriverItemStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            props =>
              !props.isLoadingDriver &&
              props.driverItem &&
              props.location.query.filterItem,
          )
          .map(() => 0)
          .distinctUntilChanged(isEqualData)
          .merge(onChangeTimeStream)
          .withLatestFrom(propsStream.map(fp.pick(["driverItem", "location"])))
          .map(([index, { driverItem, location }]) => {
            let x = 0;
            let lastDate;
            const sliceTime = getSliceTime(location);

            const list = [[]];

            const locations = driverItem.get("locations").slice(0, index + 1);

            locations.forEach(i => {
              if (lastDate !== i.get("date")) {
                if (
                  lastDate &&
                  differenceInMinutes(i.get("date"), lastDate) >= sliceTime
                ) {
                  list.push([]);
                  x += 1;
                }

                lastDate = i.get("date");

                list[x].push({
                  ...i.toJS(),
                  lng: i.get("lon"),
                });
              }
            });

            return Immutable.fromJS(list.filter(item => !fp.isEmpty(item)));
          })
          .startWith(Immutable.List());

        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilKeyChanged("isRadarViewer")
            .filter(props => !props.isRadarViewer)
            .distinctUntilChanged(isEqualData)
            .do(props =>
              props.showErrorMessage(
                props.getLocalisationMessage(
                  "you_dont_have_access_to_live_track_drivers_your_administrator_can_add_the_permission",
                  "You don't have access to live track drivers. Your administrator can add the permission",
                ),
                100000,
              ),
            ),
          propsStream
            .distinctUntilChanged(isEqualData)
            .filter(
              props =>
                props.location.query.filterItem &&
                !props.isLoadingDriver &&
                props.driverItem.size === 0 &&
                props.driverDetailedAlert.size > 0 &&
                !props.isLoadingDriverDetailedAlert,
            )
            .take(1)
            .do(props =>
              props.showErrorMessage(
                props.getLocalisationMessage(
                  "driver_locations_not_found",
                  "Driver Locations Not Found!",
                ),
              ),
            ),
          propsStream
            .distinctUntilKeyChanged("alertsList")
            .filter(
              props =>
                !props.isLoadingAlertsList &&
                props.location.query.alertItem &&
                props.alertsList.size > 0,
            )
            .do(props => {
              const idx = props.alertsList.findIndex(
                x =>
                  String(x.get("driver_id")) === props.location.query.alertItem,
              );

              if (idx > 0) {
                props.setState(
                  fp.flow(
                    fp.set("listPosition", idx * 72),
                    fp.set("scrollingList", true),
                  ),
                );
              }
            }),
          propsStream
            .distinctUntilChanged(
              ({ rectBounds: aRectBounds }, { rectBounds: bRectBounds }) => {
                const aSize = aRectBounds ? aRectBounds.size : 0;
                const bSize = bRectBounds ? bRectBounds.size : 0;

                return aSize === bSize && bSize > 0;
              },
            )
            .map(({ rectBounds }) => rectBounds)
            .merge(
              onUpdateBoundaryStream
                .withLatestFrom(propsStream)
                .map(fp.get("1.rectBounds")),
            )
            .filter(x => x.size > 0)
            .switchMap(rectBounds => updateBoundary(rectBounds.toJS())),
        ).startWith(null);

        const marketplaceStream = getMarketplace()
          .takeLast(1)
          .catch(() => Observable.of({}))
          .map(
            fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS),
          );

        return propsStream
          .combineLatest(
            ordersCountStream,
            driverLocationsStream,
            sliceDriverItemStream,
            marketplaceStream,
            sideEffectsStream,
            (
              props,
              ordersCount,
              driverLocations,
              sliceDriverItem,
              marketplace,
            ) => ({
              ...props,
              marketplace,
              ordersCount,
              onChangeTime,
              driverLocations,
              sliceDriverItem,
              onUpdateBoundary,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },

      // ---------- Getting Driver Location ------
      propsStream => {
        const {
          handler: onSetMapCenter,
          stream: onSetMapCenterStream,
        } = createEventHandler();

        const driverLocationStream = onSetMapCenterStream
          .distinctUntilChanged(isEqualData)
          .filter(fp.flow(fp.toFinite, x => x > 0))
          .switchMap(driverId =>
            getDriverOrdersLocation(driverId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.get("payload.data"),
              x => x || [],
              x => {
                const driverLocation = x.find(l => Boolean(l.driver_id)) || {};

                return {
                  driverLocation: {
                    ...driverLocation,
                    lng: driverLocation.lon,
                  },
                  list: x.map(y => ({
                    ...y,
                    lng: y.lon,
                  })),
                };
              },
              Immutable.fromJS,
            ),
          )
          .filter(
            list => list.get("list").size > 0 && list.get("driverLocation"),
          )
          .startWith(
            Immutable.fromJS({
              driverLocation: Immutable.Map(),
              list: Immutable.List(),
            }),
          )
          .distinctUntilChanged(isEqualData);

        const sideEffectsStream = Observable.merge(
          propsStream
            .map(fp.get("location.query.alertItem"))
            .distinctUntilChanged(isEqualData)
            .filter(Boolean)
            .do(driverId => onSetMapCenter(driverId)),
        ).startWith(null);

        return propsStream
          .combineLatest(
            driverLocationStream,
            sideEffectsStream,
            (props, locations) => ({
              ...props,

              driverLocation:
                locations.get("driverLocation").size > 0
                  ? locations.get("driverLocation").toJS()
                  : null,
              ordersLocation: locations.get("list"),

              onSetMapCenter,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      // ---------- Getting Driver Location ------

      // ------------- Setting Map Center --------
      propsStream => {
        const mapCenterStream = propsStream
          .distinctUntilKeyChanged("driverLocation", isEqualData)
          .map(({ driverLocation, defaultCenter }) => {
            if (driverLocation) {
              return driverLocation;
            }

            return defaultCenter;
          });

        return propsStream
          .combineLatest(mapCenterStream, (props, mapCenter) => ({
            ...props,

            mapCenter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      // ------------- Setting Map Center --------
    ),
  ),
  pureComponent(
    fp.pick([
      "state",
      "filter",
      "location",
      "isLoading",
      "mapCenter",
      "alertsList",
      "driverItem",
      "marketplace",
      "ordersCount",
      "driverLocation",
      "ordersLocation",
      "alertsListTotal",
      "groupStatistics",
      "driverLocations",
      "isLoadingDriver",
      "sliceDriverItem",
      "driverDetailedAlert",
      "isLoadingAlertsList",
      "isLoadingDriverDetailedAlert",
      "mapProvider",
    ]),
  ),
);

AdminRadarContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,

  isLoadingAlertsList: PropTypes.bool,
  groupStatistics: PropTypes.instanceOf(Immutable.Map),

  alertsList: PropTypes.instanceOf(Immutable.List),
  alertsListTotal: PropTypes.number,

  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  marketplace: PropTypes.instanceOf(Immutable.Map),
  ordersCount: PropTypes.number,

  isLoadingDriverDetailedAlert: PropTypes.bool,

  setState: PropTypes.func,
  state: PropTypes.object,

  driverLocations: PropTypes.instanceOf(Immutable.OrderedMap),

  onDriverDetailedAlert: PropTypes.func,

  driverDetailedAlert: PropTypes.instanceOf(Immutable.Map),

  isLoadingDriver: PropTypes.bool,
  onChangeTime: PropTypes.func,
  sliceDriverItem: PropTypes.instanceOf(Immutable.List),
  driverItem: PropTypes.instanceOf(Immutable.Map),

  onRefreshStatistic: PropTypes.func,
  onAlertDetailRefresh: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,

  onChangeBounds: PropTypes.func,
  onUpdateBoundary: PropTypes.func,

  mapCenter: PropTypes.object,
  onSetMapCenter: PropTypes.func,

  driverLocation: PropTypes.object,
  ordersLocation: PropTypes.instanceOf(Immutable.List),
  mapProvider: PropTypes.string,
};

const prepareDriverItemLocation = locations =>
  locations.size > 0
    ? Immutable.Map({
        origin: Immutable.Map({
          lat: locations.get(0).get("lat"),
          lng: locations.get(0).get("lon"),
          icon: Immutable.Map({
            url: EmptyIcon,
            options: Immutable.Map({
              anchor: Immutable.Map({
                x: 15,
                y: 30,
              }),
              size: Immutable.Map({
                width: 30,
                height: 30,
              }),
            }),
          }),
        }),
        destination: Immutable.Map({
          lat: locations.get(locations.size - 1).get("lat"),
          lng: locations.get(locations.size - 1).get("lon"),
          icon: Immutable.Map({
            url: EmptyIcon,
            options: Immutable.Map({
              anchor: Immutable.Map({
                x: 15,
                y: 30,
              }),
              size: Immutable.Map({
                width: 30,
                height: 30,
              }),
            }),
          }),
        }),
        path: locations
          .map((v, i) => {
            if (i !== 0 && i !== locations.size - 1) {
              return Immutable.Map({
                lat: v.get("lat"),
                lng: v.get("lon"),
                icon: Immutable.Map({
                  url: EmptyIcon,
                  options: Immutable.Map({
                    anchor: Immutable.Map({
                      x: 15,
                      y: 30,
                    }),
                    size: Immutable.Map({
                      width: 30,
                      height: 30,
                    }),
                  }),
                }),
              });
            }
            return null;
          })
          .filter(Boolean),
      })
    : null;

const prepareLeafletOrderLocationsData = locations =>
  locations.size > 0
    ? Immutable.Map({
        origin: Immutable.Map({
          lat: locations.get(0).get("lat"),
          lng: locations.get(0).get("lon"),
          icon: Immutable.Map({
            url: VehicleIcon,
            options: Immutable.Map({
              anchor: Immutable.Map({
                x: 15,
                y: 30,
              }),
              size: Immutable.Map({
                width: 30,
                height: 30,
              }),
            }),
          }),
        }),
        destination: Immutable.Map({
          lat: locations.get(locations.size - 1).get("lat"),
          lng: locations.get(locations.size - 1).get("lon"),
          icon: Immutable.Map({
            url:
              locations.get(locations.size - 1).get("status") === "COMPLETED"
                ? PinCompleted
                : PinIncomplete,
            options: Immutable.Map({
              anchor: Immutable.Map({
                x: 15,
                y: 30,
              }),
              size: Immutable.Map({
                width: 30,
                height: 30,
              }),
            }),
          }),
        }),
        path: locations
          .map((v, i) => {
            if (i !== 0 && i !== locations.size - 1) {
              return Immutable.Map({
                lat: v.get("lat"),
                lng: v.get("lon"),
                icon: Immutable.Map({
                  url:
                    v.get("status") === "COMPLETED"
                      ? PinCompleted
                      : PinIncomplete,
                  options: Immutable.Map({
                    anchor: Immutable.Map({
                      x: 15,
                      y: 30,
                    }),
                    size: Immutable.Map({
                      width: 30,
                      height: 30,
                    }),
                  }),
                }),
              });
            }
            return null;
          })
          .filter(Boolean),
      })
    : null;

const prepareLeafletSliceDriverItemData = locations =>
  locations.size > 0
    ? Immutable.Map({
        origin: Immutable.Map({
          lat: locations.get(0).get("lat"),
          lng: locations.get(0).get("lon"),
          icon: Immutable.Map({
            url: EmptyIcon,
            options: Immutable.Map({
              anchor: Immutable.Map({
                x: 15,
                y: 30,
              }),
              size: Immutable.Map({
                width: 30,
                height: 30,
              }),
            }),
          }),
        }),
        destination: Immutable.Map({
          lat: locations.get(locations.size - 1).get("lat"),
          lng: locations.get(locations.size - 1).get("lon"),
          icon: Immutable.Map({
            url: EmptyIcon,
            options: Immutable.Map({
              anchor: Immutable.Map({
                x: 15,
                y: 30,
              }),
              size: Immutable.Map({
                width: 30,
                height: 30,
              }),
            }),
          }),
        }),
        path: locations
          .map((v, i) => {
            if (i !== 0 && i !== locations.size - 1) {
              return Immutable.Map({
                lat: v.get("lat"),
                lng: v.get("lon"),
                icon: Immutable.Map({
                  url: EmptyIcon,
                  options: Immutable.Map({
                    anchor: Immutable.Map({
                      x: 15,
                      y: 30,
                    }),
                    size: Immutable.Map({
                      width: 30,
                      height: 30,
                    }),
                  }),
                }),
              });
            }
            return null;
          })
          .filter(Boolean),
      })
    : null;

function AdminRadarContainer(props) {
  const { classes, location, state, ordersLocation } = props;

  const orderLocationsMap = prepareLeafletOrderLocationsData(ordersLocation);
  const sliceDriverItemData = prepareLeafletSliceDriverItemData(
    props.sliceDriverItem.size > 0
      ? props.sliceDriverItem.get(0)
      : Immutable.List(),
  );
  const driverItemLocation = prepareDriverItemLocation(
    props.driverItem.get("paths").size > 0
      ? props.driverItem.get("paths")
      : Immutable.List(),
  );
  const isOSM = props.mapProvider === OPEN_STREET_MAP_PROVIDER;
  return (
    <FlexBox container={8} className={classes.container}>
      <PageLoading
        isLoading={props.isLoadingDriver && !props.isLoadingDriverDetailedAlert}
      />

      <AdminDriversRadarFilterWrapper
        filter={props.filter}
        open={location.hash === ALERTS_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter.setPage(0));
          props.replaceLocationHash(null);
        }}
      />

      <AdminDriverAlertHistoryWrapper
        driverId={fp.toFinite(location.query.alert_history)}
        onRequestClose={() => {
          props.setLocationQuery(
            fp.flow(fp.unset("alert_history"), fp.unset("alerts_tab")),
          );
        }}
        filter={state.alertHistoryFilter}
        tab={location.query.alerts_tab}
        onTabChange={filter => {
          props.setLocationQuery(
            fp.set("alerts_tab", filter.getValue("alerts_tab")),
          );
          props.setState(
            fp.set("alertHistoryFilter", filter.setValue("alerts_tab", null)),
          );
        }}
      />

      <FlexBox gutter={8} flex={true} className={classes.root}>
        <FlexBox className={classes.tools} flex={true}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox>
              <DriversRadarSearch
                filter={props.filter}
                marketplace={props.marketplace}
                groupStatistics={props.groupStatistics}
                menuLayoutDrawer={({ visibleMenu, toggleMenu }) => (
                  <AppLayoutDrawer
                    open={visibleMenu}
                    className={classes.drawer}
                    onRequestChange={() => toggleMenu()}
                  >
                    <SidebarMenu />
                  </AppLayoutDrawer>
                )}
                onChangePage={filter => {
                  props.onRefreshStatistic();
                  props.setLocationQueryFilter(filter);
                  props.setLocationQuery(
                    fp.flow(
                      fp.unset("alertItem"),
                      fp.unset("filterItem"),
                      fp.unset("filterDate"),
                    ),
                  );
                  props.setState(
                    fp.flow(
                      fp.set("listPosition", 0),
                      fp.set("scrollingList", false),
                    ),
                  );
                }}
                onSubmit={values =>
                  props.setLocationQueryFilter(
                    props.filter.setValueMap({
                      page: 0,
                      status: null,
                      current_alerts: null,
                      current_orders: null,
                      search: values.search,
                    }),
                  )
                }
              />
            </FlexBox>

            {Boolean(
              !props.isLoadingDriverDetailedAlert &&
                fp.toFinite(location.query.alertItem) > 0 &&
                fp.toFinite(location.query.filterItem) > 0,
            ) && (
              <FlexBox direction="column" flex={true}>
                {props.driverItem.size > 0 && (
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox>
                      <DriversRadarDateForm
                        initialValues={{
                          date: new Date(location.query.filterDate),
                        }}
                        onChange={({ date }) =>
                          props.setLocationQuery(
                            fp.set("filterDate", formatDateToUrl(date)),
                          )
                        }
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <DriversRadarTimeSlider
                        locations={props.driverItem}
                        onChange={props.onChangeTime}
                      />
                    </FlexBox>
                  </FlexBox>
                )}
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>

        <FlexBox className={classes.driversList}>
          <DriversRadarAlertsList
            listPosition={state.listPosition}
            scrollingList={state.scrollingList}
            onChangePage={page =>
              props.setLocationQueryFilter(props.filter.setPage(page))
            }
            filter={props.filter}
            total={props.alertsListTotal}
            isLoadingList={props.isLoadingAlertsList}
            alertsList={props.alertsList}
            ordersCount={props.ordersCount}
            alertItem={fp.toFinite(location.query.alertItem)}
            alertDetailed={props.driverDetailedAlert}
            onFilterClick={() => {
              props.setLocationQuery(
                fp.flow(
                  fp.unset("alertItem"),
                  fp.unset("filterItem"),
                  fp.unset("filterDate"),
                  fp.unset("historyItem"),
                ),
              );
              props.replaceLocationHash(ALERTS_FILTER_DIALOG_HASH);
            }}
            onSortClick={value => {
              props.setLocationQuery(
                fp.flow(
                  fp.unset("alertItem"),
                  fp.unset("filterItem"),
                  fp.unset("filterDate"),
                  fp.unset("historyItem"),
                ),
              );
              props.setLocationQueryFilter(
                props.filter.setValueMap({ order_by: value, page: 0 }),
              );
            }}
            isLoadingAlert={props.isLoadingDriverDetailedAlert}
            onAlertStatusClick={item =>
              props.setLocationQuery(
                fp.set("alert_history", item.get("driver_id")),
              )
            }
            onItemCloseClick={() => {
              props.setLocationQuery(
                fp.flow(
                  fp.unset("alertItem"),
                  fp.unset("filterItem"),
                  fp.unset("filterDate"),
                  fp.unset("historyItem"),
                  fp.unset("search"),
                ),
              );
            }}
            onScrollingDone={() =>
              props.setState(fp.set("scrollingList", false))
            }
            onItemClick={(id, index) => {
              props.onDriverDetailedAlert(id);
              props.setLocationQuery(
                fp.flow(
                  fp.unset("filterItem"),
                  fp.unset("filterDate"),
                  fp.unset("historyItem"),
                  fp.set("alertItem", id),
                ),
              );
              props.setState(
                fp.flow(
                  fp.set("listPosition", index * 72),
                  fp.set("scrollingList", index > 0),
                ),
              );
            }}
            onItemFilterClick={(id, index) => {
              props.onSetMapCenter(id);

              props.onDriverDetailedAlert(id);
              props.setLocationQuery(
                fp.flow(
                  fp.unset("historyItem"),
                  fp.set("filterItem", id),
                  fp.update("filterDate", x =>
                    formatDateToUrl(x || new Date()),
                  ),
                ),
              );
              props.setState(
                fp.flow(
                  fp.set("listPosition", index * 72),
                  fp.set("scrollingList", index > 0),
                ),
              );

              if (id !== location.query.alertItem) {
                props.setLocationQuery(fp.set("alertItem", id));
              }
            }}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FlexBox flex={true} direction="column">
            <FlexBox flex={true}>
              {isOSM ? (
                <LeafletMapWrapper
                  zoom={15}
                  minZoom={10}
                  onDragEnd={props.onUpdateBoundary}
                  onZoomChanged={props.onUpdateBoundary}
                  onBoundsChanged={props.onChangeBounds}
                  mapCenter={props.mapCenter}
                  className={classes.map}
                >
                  {Boolean(
                    props.driverItem.get("paths").size > 0 &&
                      (location.query.filterItem || location.query.historyItem),
                  ) && (
                    <Route
                      path={driverItemLocation.get("path").toJS() || []}
                      origin={driverItemLocation.get("origin").toJS()}
                      destination={driverItemLocation.get("destination").toJS()}
                      strokeWeight={3}
                      strokeColor={
                        location.query.historyItem ? "#0099FF" : "#000000"
                      }
                      strokeOpacity={location.query.historyItem ? 1 : 0.3}
                    />
                  )}

                  {Boolean(
                    props.sliceDriverItem.size > 0 &&
                      location.query.filterItem &&
                      !location.query.historyItem,
                  ) && (
                    <Route
                      path={sliceDriverItemData.get("path").toJS() || []}
                      origin={sliceDriverItemData.get("origin").toJS()}
                      destination={sliceDriverItemData
                        .get("destination")
                        .toJS()}
                      strokeOpacity={1}
                      strokeWeight={3}
                      strokeColor="#0099FF"
                    />
                  )}

                  {orderLocationsMap &&
                    !location.query.filterItem &&
                    location.query.alertItem &&
                    !location.query.historyItem && (
                      <Route
                        path={orderLocationsMap.get("path").toJS()}
                        origin={orderLocationsMap.get("origin").toJS()}
                        destination={orderLocationsMap
                          .get("destination")
                          .toJS()}
                        strokeOpacity={1}
                        strokeWeight={3}
                        strokeColor={
                          location.query.historyItem ? "#0099FF" : "#000000"
                        }
                      />
                    )}

                  {!location.query.filterItem &&
                    !location.query.alertItem &&
                    props.driverLocations && (
                      <LeafletDriverRadarVehicles
                        onClick={id => {
                          props.setLocationQueryFilter(
                            props.filter.setValue("search", id),
                          );

                          props.onDriverDetailedAlert(id);
                          props.setLocationQuery(
                            fp.flow(
                              fp.unset("filterItem"),
                              fp.unset("filterDate"),
                              fp.set("alertItem", id),
                              fp.set("historyItem", id),
                            ),
                          );
                        }}
                        locations={props.driverLocations}
                      />
                    )}
                </LeafletMapWrapper>
              ) : (
                <GoogleMapWrapper
                  zoom={15}
                  minZoom={10}
                  onDragEnd={props.onUpdateBoundary}
                  onZoomChanged={props.onUpdateBoundary}
                  onBoundsChanged={props.onChangeBounds}
                  mapCenter={props.mapCenter}
                  className={classes.map}
                >
                  {Boolean(
                    props.driverItem.get("paths").size > 0 &&
                      (location.query.filterItem || location.query.historyItem),
                  ) &&
                    props.driverItem
                      .get("paths")
                      .map((x, idx) => (
                        <Polyline
                          key={idx}
                          path={x.toJS()}
                          strokeOpacity={location.query.historyItem ? 1 : 0.3}
                          strokeColor={
                            location.query.historyItem ? "#0099FF" : "#000000"
                          }
                        />
                      ))}

                  {Boolean(
                    props.sliceDriverItem.size > 0 &&
                      location.query.filterItem &&
                      !location.query.historyItem,
                  ) &&
                    props.sliceDriverItem.map((x, idx) => (
                      <Polyline
                        key={idx}
                        path={x.toJS()}
                        strokeColor="#0099FF"
                      />
                    ))}

                  {Boolean(
                    props.sliceDriverItem.size > 0 && location.query.filterItem,
                  ) && (
                    <VehicleMarker
                      withMotion={false}
                      busy={props.sliceDriverItem
                        .last()
                        .last()
                        .get("busy", "default")}
                      rotation={props.sliceDriverItem
                        .last()
                        .last()
                        .get("angle")}
                      position={{
                        lat: props.sliceDriverItem
                          .last()
                          .last()
                          .get("lat"),
                        lng: props.sliceDriverItem
                          .last()
                          .last()
                          .get("lng"),
                      }}
                    />
                  )}

                  {!location.query.filterItem &&
                    location.query.alertItem &&
                    props.ordersLocation.map((x, idx) => {
                      if (fp.toFinite(x.get("driver_id")) > 0) {
                        return (
                          <VehicleMarker
                            key={idx}
                            rotation={x.get("angle")}
                            position={{
                              lat: x.get("lat"),
                              lng: x.get("lon"),
                            }}
                          />
                        );
                      }

                      return (
                        <PinMarker
                          key={idx}
                          complete={x.get("status") === "COMPLETED"}
                          position={{
                            lat: x.get("lat"),
                            lng: x.get("lng"),
                          }}
                        />
                      );
                    })}

                  {!location.query.filterItem &&
                    location.query.alertItem &&
                    !location.query.historyItem && (
                      <Polyline path={props.ordersLocation.toJS()} />
                    )}

                  {!location.query.filterItem &&
                    !location.query.alertItem &&
                    props.driverLocations && (
                      <DriverRadarVehicles
                        onClick={id => {
                          props.setLocationQueryFilter(
                            props.filter.setValue("search", id),
                          );

                          props.onDriverDetailedAlert(id);
                          props.setLocationQuery(
                            fp.flow(
                              fp.unset("filterItem"),
                              fp.unset("filterDate"),
                              fp.set("alertItem", id),
                              fp.set("historyItem", id),
                            ),
                          );
                        }}
                        locations={props.driverLocations}
                      />
                    )}

                  <ZoomControl position="RIGHT_BOTTOM" />
                </GoogleMapWrapper>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AdminRadarContainer);
