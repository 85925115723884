import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@material-ui/core";
import { connect } from "react-redux";
import TabBadge from "../deprecated/TabBadge";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderType, {
  CONSOLIDATED,
  CONTAINER,
  ROUTE,
  SHIPMENT,
  SIMPLE,
} from "../../constants/OrderType";
import { toLower } from "lodash";
import { getBatchTotals } from "../../api/admin/AdminBatchApi";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    tabs: {
      margin: "0 -1rem",
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: "1px solid #eee",
    },
  }),
);

OrderSortingTypeTabs.propTypes = {
  classes: PropTypes.object,
  buttons: PropTypes.element,
  orders: PropTypes.instanceOf(Map),
  registries: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
  onTabChange: PropTypes.func,
  orderType: PropTypes.oneOf([SHIPMENT, SIMPLE, CONSOLIDATED, ROUTE]),
};

OrderSortingTypeTabs.defaultProps = {
  bagsCount: 0,
};

function OrderSortingTypeTabs(props) {
  const { classes, getLocalisationMessage, orders, registries } = props;
  const ordersCount = orders ? orders.size : 0;
  const registriesCount = registries ? registries.size : 0;

  const [totals, setTotals] = useState([]);

  useEffect(() => {
    getBatchTotals().then(res => setTotals(res));
  }, []);

  return (
    <React.Fragment>
      {props.orderType && (
        <Tabs
          variant="fullWidth"
          className={classes.tabs}
          onChange={(e, v) => props.onTabChange(v)}
          value={props.orderType}
        >
          {OrderType.map(type => {
            const subLabel =
              type === SHIPMENT
                ? "shipments_in_stock"
                : type === SIMPLE
                ? "shipments_are_ready_for_dispatch"
                : type === CONSOLIDATED
                ? "shipments_are_ready_to_ship"
                : type === CONTAINER
                ? "bagging_preparation"
                : "sent_bags";

            return (
              <Tab
                key={type}
                value={type}
                label={
                  <TabBadge
                    label={getLocalisationMessage(toLower(type))}
                    badge={
                      type === SHIPMENT
                        ? ordersCount
                        : type === SIMPLE
                        ? registriesCount
                        : totals[toLower(type)]
                    }
                    subLabel={getLocalisationMessage(subLabel)}
                  />
                }
                style={{
                  lineHeight: "24px",
                }}
              />
            );
          }).toList()}
        </Tabs>
      )}
      <div className={classes.root}>{props.buttons}</div>
    </React.Fragment>
  );
}

export default enhancer(OrderSortingTypeTabs);
