import React, { useState } from "react";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { createOrder } from "../../api/v2/admin/AdminOrderApi";
import { getSenderPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import AdminPostForm, {
  ERROR,
  LOADING,
  NOT_STARTED,
  SUCCESS,
} from "../../components/orders-core/AdminPostForm";
import { isEqualData } from "../../helpers/DataUtils";
import { Observable } from "rxjs";
import {
  getUserJurisdiction,
  getUserWarehouseName,
} from "../../reducers/ProfileReducer";
import DataListFilter from "../../helpers/DataListFilter";
import fp from "lodash/fp";

const getLastWord = words => {
  const n = words.toString().split(" ");
  return n.length > 0 ? n[n.length - 1] : "";
};
const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      userJurisdiction: getUserJurisdiction(state),
      userWarehouseName: getUserWarehouseName(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const senderPostcodeOptionsStream = propsStream
      .first()
      .map(
        props =>
          props.userJurisdiction &&
          props.userWarehouseName &&
          new DataListFilter({
            jurisdiction_id: props.userJurisdiction.get("id"),
            search: getLastWord(props.userWarehouseName),
          }),
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter
          ? getSenderPostcodePredictions(filter).catch(error =>
              Observable.of({ error }),
            )
          : Observable.of([]),
      )
      .map(v => v.data || [])
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        senderPostcodeOptionsStream,
        (props, senderPostcodeOptions) => ({
          ...props,
          senderPostcodeOptions,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminPostCreate.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
  senderPostcodeOptions: PropTypes.array,
  userJurisdiction: PropTypes.object,
  userWarehouseName: PropTypes.string,
};

function AdminPostCreate(props) {
  const { getLocalisationMessage } = props;
  const [isLoading, setIsLoading] = useState(NOT_STARTED);
  const [resMessage, setResMessage] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [printReceipt, setPrintReceipt] = useState(true);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("create_order", "Create Order")}
    >
      <AdminPostForm
        printReceipt={printReceipt}
        setPrintReceipt={setPrintReceipt}
        setIsLoading={setIsLoading}
        setResMessage={setResMessage}
        orderId={orderId}
        isLoading={isLoading}
        resMessage={resMessage}
        userJurisdiction={props.userJurisdiction}
        userWarehouseName={props.userWarehouseName}
        senderPostcode={
          props.senderPostcodeOptions.length === 1 &&
          props.senderPostcodeOptions[0]
        }
        onSubmit={values => {
          setIsLoading(LOADING);
          createOrder(values)
            .then(res => {
              if (res.status === "success") {
                setOrderId(fp.get("data.id", res));
                setIsLoading(SUCCESS);
                setResMessage(
                  getLocalisationMessage(
                    "successfully_created",
                    "Successfully Created",
                  ),
                );
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_created",
                    "Successfully Created",
                  ),
                );
              } else {
                setIsLoading(ERROR);
                setResMessage(res.message);
                props.showErrorMessage(res.message);
              }
            })
            .catch(error => {
              setIsLoading(ERROR);
              setResMessage(error.reasonMessage);
              if (props.onSubmitFail) {
                props.onSubmitFail(error);
              } else {
                props.showErrorMessage(error);
              }
            });
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPostCreate);
