import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormToggle from "../form/FormToggle";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import adminMenuOptions from "../../constants/AdminMarketplaceMenuConstants";
import customerMenuOptions from "../../constants/CustomerMarketplaceMenuConstants";
import supplierMenuOptions from "../../constants/SupplierMarketplaceMenuConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    content: { width: "600px" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    td: {
      alignSelf: "center",
    },
  }),
  reduxForm({
    form: "MarketplaceSettingsMenuForm",
    enableReinitialize: true,
  }),
);

MarketplaceSettingsDialogForm.propTypes = {
  classes: PropTypes.object,

  payer: PropTypes.string,
  codCharge: PropTypes.string,

  handleSubmit: PropTypes.func,

  type: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function MarketplaceSettingsDialogForm(props) {
  const { classes, getLocalisationMessage } = props;

  const menuOptions =
    props.type === "LEFT_MENU_FOR_ADMIN_ACCOUNT"
      ? adminMenuOptions
      : props.type === "LEFT_MENU_FOR_SUPPLIER"
      ? supplierMenuOptions
      : customerMenuOptions;

  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onClose={props.handleSubmit}
      PaperProps={{
        style: {
          width: "600px",
        },
      }}
    >
      <DialogTitle>
        {getLocalisationMessage("select_menu", "Select Menu")}
      </DialogTitle>
      <DialogContent>
        {menuOptions.map(item => (
          <FlexBox key={item.slug}>
            <FlexBox flex={true} className={classes.td}>
              {_.startCase(item.slug)}
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                name={`${item.slug}.name`}
                hintText={getLocalisationMessage("custom_name", "Custom Name")}
              />
            </FlexBox>
            <FlexBox flex={true} className={classes.td}>
              <FormToggle
                className={props.classes.toggle}
                name={`${item.slug}.active`}
              />
            </FlexBox>
          </FlexBox>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(MarketplaceSettingsDialogForm);
