import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ADDRESS_LIST_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/address`;
const ADDRESS_CREATE_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/address`;
const ADDRESS_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/venue/%s/address/%s`;

export type AddressItemRequest = {
  id: number,
  owner_id: number,
  city: {
    id: number,
    name: string,
    code: string,
  },
  lat: number,
  lon: number,
  street: string,
  building: string,
  floor: string,
  apartment: string,
  location_type: string,
  preferred_delivery_time: string,
  mobile: string,
  landline: string,
  deliver_without_call: boolean,
  primary: boolean,
  venue_address: boolean,
  note: string,
  status: string,
  contact_name: string,
  address: string,
};

export const getAddressItem = (venueId, id) =>
  api.getStream(sprintf(ADDRESS_ITEM_URL, venueId, id));
export const createAddressItem = (venueId, body: AddressItemRequest) =>
  api.post(sprintf(ADDRESS_CREATE_URL, venueId), { body });
export const updateAddressItem = (venueId, id, body: AddressItemRequest) =>
  api.put(sprintf(ADDRESS_ITEM_URL, venueId, id), { body });

export const getAddressList = (venueId, request: DataListFilter) =>
  api.getStream(sprintf(ADDRESS_LIST_URL, venueId), {
    params: request.getDefinedValues(),
  });

export const removeAddress = (venueId, addressId) =>
  api.delete(sprintf(ADDRESS_LIST_URL, venueId), { params: { addressId } });
