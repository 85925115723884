import React, { useState } from "react";
import { compose, mapPropsStream, withContext, withHandlers } from "recompose";
import { formValues, reduxForm } from "redux-form";
import {
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  makeStyles,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CustomButton, {
  CONTAINED,
  PRIMARY,
  SECONDARY,
} from "../ui-core/CustomButton";
import { connect } from "react-redux";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import FlexBox, { JUSTIFY_END, WRAP } from "../ui-core/FlexBox";
import { CYRILLIC, LATIN } from "../../constants/AlphabetType";
import { LOCAL } from "../../constants/GlobalisationType";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormDateField from "../form/FormDateField";
import fp from "lodash/fp";
import {
  cleanupStringArray,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";
import FormChipInput from "../form/FormChipInput";
import FormTextField from "../form/FormTextField";
import FormUserAutoComplete from "../form/FormUserAutoComplete";
import { Observable } from "rxjs";
import { List, Set } from "immutable";
import { isEqualData } from "../../helpers/DataUtils";
import { getActReasonList } from "../../api/admin/AdminActManagementApi";
import { pipeStreams } from "../../helpers/StreamUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import {
  ENGLISH_LANGUAGE,
  RUSSIAN_LANGUAGE,
  UZBEK_LANGUAGE,
} from "../../constants/LocaleTypes";
import cx from "classnames";
import { Check } from "@material-ui/icons";

const parseOrderNumbers = fp.flow(
  fp.trim,
  x => x.replace(/\W/g, " "),
  cleanupStringArray,
);

const formatMistakeItem = (item, lang = ENGLISH_LANGUAGE) => {
  let name = "name_en";

  if (lang === RUSSIAN_LANGUAGE) {
    name = "name_ru";
  } else if (lang === UZBEK_LANGUAGE) {
    name = "name_uz";
  }

  return fp.get(name, item);
};

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      currentLanguage: getCurrentLanguage(state),
    }),
    { showErrorMessage },
  ),
  withContext(
    {
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream(
    pipeStreams(propsStream => {
      const actReasonListStream = propsStream
        .first()
        .switchMap(() =>
          getActReasonList({ act_type: "LOCAL" })
            .map(fp.flow(fp.get("payload.data"), List))
            .catch(() => Observable.of([])),
        )
        .startWith(List());

      return propsStream
        .combineLatest(actReasonListStream, (props, actReasonList) => ({
          ...props,
          actReasonList,
        }))
        .distinctUntilChanged(isEqualData);
    }),
  ),
  withHandlers({
    onSubmit: props => ({ date, ...values }) => {
      if (!props.onSubmit) {
        return null;
      }

      // eslint-disable-next-line no-param-reassign
      values.type = LOCAL;

      // eslint-disable-next-line no-param-reassign
      values.alphabetType = LATIN;

      // eslint-disable-next-line no-param-reassign
      values.date = formatDateTimeToUrl(date);

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "LocalActForm",
    validate: (values, props) => ({
      // alphabetType:
      //   !values.alphabetType &&
      //   props.getLocalisationMessage(
      //     "alphabet_type_is_required",
      //     "Alphabet Type is required",
      //   ),
      actReasonIds:
        (!values.actReasonIds || values.actReasonIds.size === 0) &&
        props.getLocalisationMessage(
          "choose_at_least_one_option",
          "Choose at least one option",
        ),
      batchBarcode:
        !values.batchBarcode &&
        (!values.barcodes || values.barcodes.length === 0) &&
        props.getLocalisationMessage(
          "scan_batch_or_shipment",
          "Scan Batch or Shipment",
        ),
      barcodes:
        !values.batchBarcode &&
        (!values.barcodes || values.barcodes.length === 0) &&
        props.getLocalisationMessage(
          "scan_batch_or_shipment",
          "Scan Batch or Shipment",
        ),
      fromWarehouse:
        !isValidObjectId(values.fromWarehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      toWarehouse:
        !isValidObjectId(values.toWarehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      date:
        !values.date &&
        props.getLocalisationMessage("choose_date", "Choose Date"),
      director:
        !isValidObjectId(values.director) &&
        props.getLocalisationMessage("select_user", "Select User"),
      operator:
        !isValidObjectId(values.operator) &&
        props.getLocalisationMessage("select_user", "Select User"),
      secondOperator:
        !isValidObjectId(values.secondOperator) &&
        props.getLocalisationMessage("select_user", "Select User"),
    }),
  }),
  formValues("alphabetType", "batchBarcode", "barcodes", "actReasonIds"),
  withHandlers({
    onScanBatchId: props => barcode => {
      const batchBarcode = barcode.split(",");
      props.change("batchBarcode", parseOrderNumbers(batchBarcode[0]));
    },
    onToggleMistake: props => (e, mistake) => {
      const actReasonIds = (props.actReasonIds || Set()).asMutable();

      if (e.target.checked) {
        actReasonIds.add(mistake);
      } else {
        actReasonIds.delete(mistake);
      }

      props.change("actReasonIds", actReasonIds.asImmutable());
    },
  }),
);

const useStyles = makeStyles(() => ({
  alphabetButton: {
    borderRadius: "20px",
    marginRight: "20px",
  },
  input: { "& input": { fontSize: "20px" } },
  form: {},
  root: { width: "100%" },
  container: { width: "100%" },
  fieldsBox: { padding: "16px 0" },
  padding: { padding: "8px 0" },
  rolesList: {
    padding: "0 4px",
    "&.error": {
      border: "1px solid #f44336",
      borderRadius: "4px",
    },
  },
  helperText: {
    color: "#f44336",
  },
}));

function LocalActForm(props) {
  const {
    change,
    isLoading,
    alphabetType,
    actReasonIds,
    actReasonList,
    currentLanguage,
    onToggleMistake,
    getLocalisationMessage,
  } = props;

  const classes = useStyles();
  const [showError, setShowError] = useState(false);

  return (
    <FlexBox container={8} flex="none" className={classes.root}>
      <FlexBox
        flex="none"
        direction="column"
        element={
          <Card className={classes.container} onSubmit={props.handleSubmit} />
        }
        gutter={8}
      >
        <FlexBox
          flex={true}
          direction="column"
          element={<CardContent style={{ padding: "1rem" }} />}
        >
          <FlexBox className={classes.padding}>
            <CustomButton
              className={classes.alphabetButton}
              variant={CONTAINED}
              color={alphabetType === LATIN ? SECONDARY : PRIMARY}
              onClick={() => {
                change("alphabetType", LATIN);
              }}
            >
              {getLocalisationMessage("latin", "Latin")}
            </CustomButton>

            {false && (
              <CustomButton
                variant={CONTAINED}
                color={alphabetType === CYRILLIC ? SECONDARY : PRIMARY}
                className={classes.alphabetButton}
                onClick={() => {
                  change("alphabetType", CYRILLIC);
                }}
              >
                {getLocalisationMessage("cyrillic", "Cyrillic")}
              </CustomButton>
            )}
          </FlexBox>

          <h3 className={classes.fieldsBox}>
            {getLocalisationMessage("act", "Act")}
          </h3>

          <FlexBox gutter={8} flex={true} className={classes.fieldsBox}>
            <FlexBox flex={true}>
              <FormTextField
                name="batchBarcode"
                fullWidth={true}
                size="medium"
                label={`${getLocalisationMessage(
                  "batch_number",
                  "Batch Barcode",
                )} *`}
                hintText={getLocalisationMessage(
                  "batch_number",
                  "Batch Barcode",
                )}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormChipInput
                name="barcodes"
                fullWidth={true}
                addOnBlur={true}
                className={classes.input}
                label={getLocalisationMessage("barcode", "Barcode")}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox gutter={8} flex={true} style={{ paddingBottom: "16px" }}>
            <FlexBox className={classes.fieldsBox} flex={true}>
              <FormDateField
                name="date"
                fullWidth={true}
                label={`${getLocalisationMessage("date", "Date")} *`}
                hintText={`${getLocalisationMessage("date", "Date")} *`}
              />
            </FlexBox>

            <FlexBox className={classes.fieldsBox} flex={true}>
              <FormWarehouseAutoComplete
                fullWidth={true}
                name="fromWarehouse"
                label={`${props.getLocalisationMessage(
                  "from_warehouse_1",
                  "From Warehouse",
                )} *`}
                hintText={props.getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>

            <FlexBox className={classes.fieldsBox} flex={true}>
              <FormWarehouseAutoComplete
                fullWidth={true}
                name="toWarehouse"
                label={`${props.getLocalisationMessage(
                  "to_warehouse_1",
                  "To Warehouse",
                )} *`}
                hintText={props.getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox className={classes.fieldsBox} flex={true}>
            <h3>
              {`${props.getLocalisationMessage(
                "type_of_mistake",
                "Type of Mistake",
              )} *`}
            </h3>
          </FlexBox>

          <FlexBox
            flex={true}
            wrap={WRAP}
            className={cx(classes.rolesList, {
              error: showError,
            })}
          >
            <FormTextField
              name="actReasonIds"
              hidden={true}
              onShowError={e => setShowError(e)}
            />
            {actReasonList &&
              actReasonList
                .map(item => {
                  const itemId = fp.get("id", item);

                  const hasAdded =
                    actReasonIds && actReasonIds.includes(itemId);

                  return (
                    <FlexBox flex={6} md={2} key={itemId}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hasAdded}
                            value={hasAdded}
                            onChange={e => onToggleMistake(e, itemId)}
                          />
                        }
                        label={formatMistakeItem(item, currentLanguage)}
                      />
                    </FlexBox>
                  );
                })
                .toArray()}
          </FlexBox>

          {showError && (
            <p className={classes.helperText}>
              {getLocalisationMessage(
                "choose_at_least_one_option",
                "Choose at least one option",
              )}
            </p>
          )}

          <FlexBox className={classes.padding}>
            <FormTextField
              name="description"
              fullWidth={true}
              multiLine={true}
              rows={4}
              label={getLocalisationMessage("description_1", "Description")}
            />
          </FlexBox>

          <FlexBox className={classes.padding} gutter={8} flex={true}>
            <FlexBox className={classes.fieldsBox} flex={true}>
              <FormUserAutoComplete
                name="director"
                label={`${getLocalisationMessage("department_head")} *`}
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>

            <FlexBox className={classes.fieldsBox} flex={true}>
              <FormUserAutoComplete
                name="operator"
                fullWidth={true}
                label={`${getLocalisationMessage("operator")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>

            <FlexBox className={classes.fieldsBox} flex={true}>
              <FormUserAutoComplete
                name="secondOperator"
                fullWidth={true}
                label={`${getLocalisationMessage("operator")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>

            <FlexBox className={classes.fieldsBox} flex={true}>
              <FormUserAutoComplete
                name="security"
                label={`${getLocalisationMessage(
                  "responsible_for_mail_security",
                  "Responsible for mail security",
                )}`}
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox
          flex="none"
          element={<CardActions style={{ padding: "1rem" }} />}
        >
          <FlexBox
            flex={true}
            justify={JUSTIFY_END}
            style={{ textAlign: "right" }}
          >
            <CustomButton
              onClick={props.handleSubmit}
              variant={CONTAINED}
              color={SECONDARY}
              disabled={isLoading}
              endIcon={
                isLoading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <Check />
                )
              }
            >
              {props.getLocalisationMessage("submit", "Submit")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

LocalActForm.propTypes = {
  alphabetType: PropTypes.oneOf([LATIN, CYRILLIC]),
  onScanBatchId: PropTypes.func.isRequired,
  onCheckActMistakes: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  onToggleMistake: PropTypes.func,
  change: PropTypes.func,
  barcodes: PropTypes.array,
  batchBarcode: PropTypes.string,
  actReasonList: PropTypes.instanceOf(List),
  actReasonIds: PropTypes.array,
  currentLanguage: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default enhancer(LocalActForm);
