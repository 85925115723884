import { Observable } from "rxjs";
import React from "react";
import { Set, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Marker, ZoomControl } from "react-google-map-components";
import {
  Marker as LMarker,
  DataPolygon as LDataPolygon,
} from "react-leflet-map-components";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseMultipolygon } from "../../helpers/MapPolygonUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getCoordinatesOfNeighborhoods } from "../../api/admin/AdminPostcodesApi";
import GoogleMapWrapper from "../../components/maps/GoogleMapWrapper";
import DataPolygonWrapper from "../../components/maps/DataPolygonWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import LeafletMapWrapper from "../../components/maps-leaflet/LeafletMapWrapper";
import NeighborhoodsCoordinatesForm from "../../components/neighborhoods-core/NeighborhoodsCoordinatesForm";
import NeighborhoodsCoordinatesList from "../../components/neighborhoods-core/NeighborhoodsCoordinatesList";
import {
  getMapProvider,
  isMapKeyAvailable,
} from "../../../shared/reducers/AppReducer";

import { MAP_PROVIDERS } from "../../../shared/constants/MapsControllerConstants";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      mapProvider: getMapProvider(state),
      isMapKeyAvailable: isMapKeyAvailable(state),
      getLocalisationMessage,
    };
  }),
  useSheet({
    root: { position: "relative" },
    map: { flex: "1 1 0%", zIndex: 0 },
    tools: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1000,
      width: "500px",
      height: "100%",
    },
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  mapPropsStream(propsStream => {
    const coordinatesOfNeighborhoodsStream = propsStream
      .map(props => ({
        ...props,
        filter: new DataListFilter({
          search: props.location.query.search,
        }),
      }))
      .distinctUntilKeyChanged("filter")
      .filter(props => Boolean(props.filter.getValue("search")))
      .switchMap(props =>
        getCoordinatesOfNeighborhoods(props.filter).catch(error =>
          Observable.of({ error }),
        ),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(
              fp.get("data"),
              fp.update(
                "neighborhods",
                fp.flow(fp.toArray, neighborhods =>
                  neighborhods.map(item => ({
                    ...item,
                    polygon: parseMultipolygon(item.wkt),
                  })),
                ),
              ),
              fp.update(
                "points",
                fp.flow(fp.toArray, points =>
                  points.map(i => ({
                    lat: i.lat,
                    lng: i.lon,
                  })),
                ),
              ),
            ),
          ),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        coordinatesOfNeighborhoodsStream,
        (props, coordinatesOfNeighborhoods) => ({
          ...props,
          isLoading: coordinatesOfNeighborhoods.get("pending"),
          neighborhoods: coordinatesOfNeighborhoods
            .getIn(["payload", "neighborhods"])
            .toJS(),
          points: coordinatesOfNeighborhoods
            .getIn(["payload", "points"])
            .toJS(),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
  withState("state", "setState", { selectedIds: Set() }),
  pureComponent(fp.pick(["state", "points", "isLoading", "neighborhoods"])),
);

AdminPostcodesCoordinatesContainer.propTypes = {
  classes: PropTypes.object,

  isLoading: PropTypes.bool,

  state: PropTypes.object,
  setState: PropTypes.func,

  points: PropTypes.array,
  neighborhoods: PropTypes.array,

  mapProvider: PropTypes.oneOf(MAP_PROVIDERS),
  isMapKeyAvailable: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminPostcodesCoordinatesContainer(props) {
  const { classes, state, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="coordinates_of_NBHD"
      title={getLocalisationMessage(
        "coordinates_of_neighborhoods",
        "Coordinates of Neighborhoods",
      )}
    >
      <PageLoading isLoading={props.isLoading} />

      <FlexBox flex={true} className={classes.root}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox className={classes.tools}>
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox>
                <NeighborhoodsCoordinatesForm
                  onSubmit={({ neighborhood }) => {
                    props.setLocationQuery(fp.set("search", neighborhood));
                    props.setState(fp.set("selectedIds", Set()));
                  }}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <NeighborhoodsCoordinatesList
                  list={fromJS(props.neighborhoods)}
                  selectedIds={state.selectedIds}
                  onSelectIds={props.setState}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            {props.isMapKeyAvailable ? (
              <GoogleMapWrapper style={{ flex: 1 }}>
                <ZoomControl position="RIGHT_BOTTOM" />

                {props.points.map((x, idx) => (
                  <Marker key={idx} position={x} />
                ))}

                {props.neighborhoods.map((x, idx) => (
                  <DataPolygonWrapper
                    key={idx}
                    label={x.name}
                    geometry={x.polygon}
                    onClick={() =>
                      state.selectedIds.includes(x.id)
                        ? props.setState(
                            fp.set(
                              "selectedIds",
                              state.selectedIds.delete(x.id),
                            ),
                          )
                        : props.setState(
                            fp.set("selectedIds", state.selectedIds.add(x.id)),
                          )
                    }
                    fillColor={
                      state.selectedIds.includes(x.id) ? "#00FF00" : "#000000"
                    }
                  />
                ))}
              </GoogleMapWrapper>
            ) : (
              <LeafletMapWrapper zoom={9}>
                {props.points.map((x, idx) => (
                  <LMarker key={idx} position={x} />
                ))}

                {props.neighborhoods.map((x, idx) => (
                  <LDataPolygon
                    key={idx}
                    label={x.name}
                    geometry={x.polygon}
                    onClick={() =>
                      state.selectedIds.includes(x.id)
                        ? props.setState(
                            fp.set(
                              "selectedIds",
                              state.selectedIds.delete(x.id),
                            ),
                          )
                        : props.setState(
                            fp.set("selectedIds", state.selectedIds.add(x.id)),
                          )
                    }
                    fillColor={
                      state.selectedIds.includes(x.id) ? "#00FF00" : "#000000"
                    }
                  />
                ))}
              </LeafletMapWrapper>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminPostcodesCoordinatesContainer);
