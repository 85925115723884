import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const GET_VENUE_CATEGORY_LIST_URL = `${SHOP_API_ROOT_URL}/admin/category`;
const GET_VENUE_CATEGORY_CREATE_URL = `${SHOP_API_ROOT_URL}/admin/category`;
const GET_VENUE_CATEGORY_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/category/%s`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(GET_VENUE_CATEGORY_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(GET_VENUE_CATEGORY_LIST_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getVenueCategoryItem = id => itemCache.refresh(id);
export const getCachedVenueCategory = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getVenueCategoryPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export type VenueCategoryItemRequest = {
  count: number,
  parent: {},
  id: number,
  name: string,
  sorder: number,
  status: string,
  image: string,
};

export const createVenueCategoryItem = (body: VenueCategoryItemRequest) =>
  api.post(GET_VENUE_CATEGORY_CREATE_URL, { body });
export const updateVenueCategoryItem = (id, body: VenueCategoryItemRequest) =>
  api.put(sprintf(GET_VENUE_CATEGORY_ITEM_URL, id), { body });
export const getVenueCategoryList = request =>
  api.getStream(GET_VENUE_CATEGORY_LIST_URL, {
    params: request.getDefinedValues(),
  });
export const removeVenueCategories = ids =>
  api.delete(GET_VENUE_CATEGORY_LIST_URL, { params: { ids } });
