import { Observable } from "rxjs";
import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEmpty } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withState("isLoading", "setIsLoading", false),
  getContext({
    getWhatsappProviderFields: PropTypes.func.isRequired,
  }),
  useSheet({
    overall: { height: "64px" },
    error: { color: "red" },
    providerBox: { padding: 0, background: "rgba(0,0,0,0.001)" },
    providersList: { padding: 2, maxHeight: 450 },
    title: { fontSize: 15, color: "#666", marginBottom: 5 },
    fieldsBox: { padding: "0 15px" },
    providerButton: {
      padding: "15px",
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.2)",
      marginBottom: 3,
      borderRadius: 4,
    },
    activeMenu: { background: "rgba(0, 151, 136,0.2)" },
    saveButton: { background: "rgba(0,0,0,0.1)", height: 45, marginTop: 15 },
    noProvider: {
      color: "rgba(200,0,0,0.6)",
      fontSize: 16,
      textAlign: "center",
    },
  }),
  reduxForm({
    form: "WhatsappProviderSettingsForm",
    enableReinitialize: true,
  }),
  formValues("provider"),
  mapPropsStream(propsStream => {
    const fieldsStream = propsStream
      .filter(fp.flow(fp.get("provider"), fp.negate(isEmpty)))
      .map(fp.pick(["provider", "getWhatsappProviderFields", "setIsLoading"]))
      .distinctUntilKeyChanged("provider")
      .switchMap(props => {
        props.setIsLoading(true);

        return props
          .getWhatsappProviderFields({ whatsAppProvider: props.provider })
          .catch(error => {
            props.setIsLoading(false);
            return Observable.of({ error });
          });
      })
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.get("data")),
          Map,
        ),
      )
      .startWith(Map());

    return propsStream.combineLatest(fieldsStream, (props, fields) => ({
      ...props,
      fields: fields.get("payload", []),
      isLoading: fields.get("pending", false),
    }));
  }),
);

WhatsappProviderSettingsForm.propTypes = {
  classes: PropTypes.object,
  fields: PropTypes.array,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  provider: PropTypes.string,
  providers: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function WhatsappProviderSettingsForm(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <FlexBox
      gutter={16}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox flex={true} gutter={8}>
        <FlexBox
          style={{ width: 200 }}
          className={classes.providerBox}
          direction="column"
        >
          <FlexBox
            flex="grow"
            className={classes.providersList}
            direction="column"
          >
            <FlexBox flex={true}>
              <FormSelectField
                name="provider"
                fullWidth={true}
                label={getLocalisationMessage("provider", "Provider")}
                hintText={getLocalisationMessage(
                  "select_provider",
                  "Select Provider",
                )}
                onChange={() => props.change("properties", {})}
                options={props.providers.map(fp.get("text"))}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox className={classes.fieldsBox} flex={true}>
          {!isEmpty(props.provider) && !isEmpty(props.fields) ? (
            <FlexBox
              direction="column"
              style={{ width: "100%" }}
              justify="space-between"
            >
              <FlexBox direction="column">
                <h4 className={classes.title}>
                  {getLocalisationMessage("provider_config", "Provider Config")}
                </h4>
                {props.fields.map((propField, index) => (
                  <FormTextField
                    key={index}
                    required={true}
                    name={`properties.${propField.name}`}
                    fullWidth={true}
                    label={props.getLocalisationMessage(
                      propField.name,
                      propField.description,
                    )}
                  />
                ))}
                <FormCheckbox
                  name="active"
                  label={getLocalisationMessage("active", "Active")}
                />
                <Button type="submit" className={classes.saveButton}>
                  {getLocalisationMessage("save", "Save")}
                </Button>
              </FlexBox>
            </FlexBox>
          ) : props.isLoading ? (
            <PageLoading isLoading={true} />
          ) : (
            <FlexBox
              justify="center"
              align="center"
              flex={true}
              className={classes.noProvider}
            >
              {getLocalisationMessage(
                "please_select_provider",
                "Please, select provider",
              )}
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(WhatsappProviderSettingsForm);
