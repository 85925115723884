import React from "react";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  withTheme,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormRatingBar from "../form/FormRatingBarV2";
import FormTextField from "../form/FormTextField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import FeedbackEmotions from "./FeedbackEmotionsv2";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    bodyDialog: { padding: "0" },
    comment: {
      margin: "0 12px",
      "& .MuiSvgIcon-root": {
        width: "38px",
        height: "38px",
      },
    },
    contentDialog: { width: "300px" },
    progress: { textAlign: "center", width: "88px" },
    title: { fontFamily: "Blogger Sans", padding: "16px" },
    actions: { "& button": { fontFamily: "Blogger Sans" } },
  }),
  reduxForm({
    form: "FeedbackDialogV2",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderNumber:
        !values.orderNumber &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  formValues("score"),
);

FeedbackDialogV2.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  score: PropTypes.number,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

function FeedbackDialogV2(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      modal={true}
      actionsContainerClassName={classes.actions}
      PaperProps={{
        style: {
          width: 500,
        },
      }}
      open={props.open}
      onClose={props.onRequestClose}
    >
      <form onSubmit={props.handleSubmit}>
        <DialogTitle
          style={{
            backgroundColor: props.theme.palette.primary1Color,
            color: props.theme.palette.appBarTextColor,
          }}
          className={classes.title}
        >
          {getLocalisationMessage("feedback", "Feedback")}
        </DialogTitle>
        <DialogContent>
          <PageLoading isLoading={props.submitting} />

          <FeedbackEmotions emotion={props.score} />

          <FlexBox style={{ gap: 13 }} flex={true} direction="column">
            <div className={classes.comment}>
              <FormRatingBar name="score" />
            </div>

            <FormTextField
              name="orderNumber"
              fullWidth={true}
              label={`${getLocalisationMessage("barcode", "Barcode")} *`}
            />

            <FormTextField
              name="note"
              fullWidth={true}
              rows={4}
              multiLine={true}
              label={getLocalisationMessage("add_comment", "Add Comment")}
            />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <div>
            <Button disabled={props.submitting} onClick={props.onRequestClose}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
            <Button type="submit">
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default enhancer(FeedbackDialogV2);
