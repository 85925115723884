import { Observable } from "rxjs";
import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getWhatsappProvider,
  saveWhatsappProvider,
  getWhatsappProviderList,
  getWhatsappProviderFields,
} from "../../api/admin/AdminWhatsappApi";
import FlexBox from "../../components/ui-core/FlexBox";
import WhatsappProviderSettingsForm from "../../components/settings-core/WhatsappProviderSettingsForm";

const messengerType = "WHATSAPP";

const getStringifyProperties = fp.flow(
  fp.get("properties"),
  fp.defaultTo("{}"),
  JSON.parse,
);
const enhancer = compose(
  useSheet({
    root: { alignItems: "center" },
    container: {
      maxWidth: "1000px",
      minWidth: "800px",
      "& > div": {
        display: "flex",
        flex: "1 1 0%",
      },
    },
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  withContext(
    {
      getWhatsappProviderFields: PropTypes.func,
    },
    () => ({
      getWhatsappProviderFields,
    }),
  ),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), DataListFilter.create))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const providerListStream = propsStream
          .first()
          .switchMap(() =>
            getWhatsappProviderList()
              .map(fp.flow(fp.get("payload.data"), List))
              .catch(error => Observable.of({ error })),
          )
          .startWith(List());

        const providerStream = propsStream
          .first()
          .switchMap(() =>
            getWhatsappProvider(messengerType)
              .map(fp.flow(fp.get("payload.data"), Map))
              .catch(error => Observable.of({ error })),
          )
          .startWith(Map());

        return propsStream
          .combineLatest(
            providerStream,
            providerListStream,
            (props, activeProvider, providerList) => ({
              ...props,
              activeProvider,
              providerList,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

WhatsappProviderWrapper.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  providerList: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  activeProvider: PropTypes.instanceOf(Map),
  isLoadingRatingTypes: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function WhatsappProviderWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  const configuredProvider = props.activeProvider.toJS() || {};
  return (
    <FlexBox
      container={8}
      flex={true}
      direction="column"
      className={classes.root}
    >
      <FlexBox element={<Card className={classes.container} />} gutter={8}>
        <FlexBox flex={true} element={<CardContent />}>
          <FlexBox gutter={8} flex={true} direction="column">
            <WhatsappProviderSettingsForm
              providers={props.providerList}
              initialValues={{
                active: true,
                provider: null,
                ...configuredProvider,
                properties: getStringifyProperties(configuredProvider),
              }}
              onSubmit={values => {
                saveWhatsappProvider(messengerType, {
                  ...values,
                  properties: JSON.stringify(values.properties),
                }).catch(ResponseError.throw);
              }}
              onSubmitSuccess={() => {
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_saved",
                    "Successfully Saved",
                  ),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(WhatsappProviderWrapper);
