import React from "react";
import { formatDistanceStrict } from "date-fns";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import DescriptionTerm from "../ui-core/DescriptionTerm";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    description: {
      margin: 0,
      color: "#fefefe",
      fontSize: "13px",
    },
    descriptionTitle: {
      fontSize: "11px",
      color: "rgba(255, 255, 255, 0.54)",
    },
    descriptionContainer: {
      margin: "12px 0",
      lineHeight: "16px",
    },
    root: {
      minWidth: "450px",
    },
  }),
);

DriverAlertInfo.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
  driverLastAlert: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func,
};

function DriverAlertInfo(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox style={props.style} className={classes.root}>
      <FlexBox gutter={8} flex={true} className={classes.descriptionContainer}>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage("current_order", "Current Order:")}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {props.driverLastAlert.get("last_order_number") ||
              getLocalisationMessage("na", "N/A")}
          </DescriptionTerm>
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage("dispatched_point", "Dispatched:")}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {props.driverLastAlert.get("last_dispatched_date")
              ? `${formatDistanceStrict(
                  props.driverLastAlert.get("last_dispatched_date"),
                  new Date(),
                )} ${getLocalisationMessage("ago", "ago")}`
              : getLocalisationMessage("na", "N/A")}
          </DescriptionTerm>
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage(
              "location_update",
              "Location Update:",
            )}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {props.driverLastAlert.get("last_location_update_date")
              ? `${formatDistanceStrict(
                  props.driverLastAlert.get("last_location_update_date"),
                  new Date(),
                )} ${getLocalisationMessage("ago", "ago")}`
              : getLocalisationMessage("na", "N/A")}
          </DescriptionTerm>
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage("status_update", "Status Update:")}
            contentClassName={classes.description}
            titleClassName={classes.descriptionTitle}
          >
            {props.driverLastAlert.get("last_status_update_date")
              ? `${formatDistanceStrict(
                  props.driverLastAlert.get("last_status_update_date"),
                  new Date(),
                )} ${getLocalisationMessage("ago", "ago")}`
              : getLocalisationMessage("na", "N/A")}
          </DescriptionTerm>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverAlertInfo);
