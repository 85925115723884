import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { startOfToday } from "date-fns";
import OrderFilterDateForm from "../../components/orders-core/OrderFilterDateForm";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  renderIf("open"),
  useSheet({
    modal: {
      maxWidth: "400px",
      minWidth: "400px",
      minHeight: "auto",
    },
  }),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

AdminOrderFilterDateWrapper.propTypes = {
  state: PropTypes.object,
  classes: PropTypes.object,
  setState: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  onClickDateFilter: PropTypes.func,
  onOrderRefreshRequest: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
};

function AdminOrderFilterDateWrapper(props) {
  return (
    <ModalPaper
      open={true}
      title={props.getLocalisationMessage("filter_by_date", "Filter by Date")}
      onRequestClose={props.onClickDateFilter}
      paperClassName={props.classes.modal}
    >
      <FlexBox container={8} direction="column">
        <OrderFilterDateForm
          filter={props.filter}
          onDismiss={props.onClickDateFilter}
          onFilterChange={props.onFilterChange}
          initialValues={{
            from_date_time: formatDateTimeToUrl(startOfToday()),
          }}
          onReset={() =>
            props.setLocationQueryFilter(
              props.filter.setValueMap({
                from_date_time: formatDateTimeToUrl(startOfToday()),
                to_date_time: null,
              }),
            )
          }
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderFilterDateWrapper);
