import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FormBankAutoComplete from "../form/FormBankAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import BankReconcileTypes from "../../constants/BankReconcileTypes";

const valueSelector = formValueSelector("BankAndTypeForm");

const enhancer = compose(
  reduxForm({
    form: "BankAndTypeForm",
    enableReinitialize: true,
  }),
  connect(state => ({
    values: valueSelector(state, "bank", "type"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedBank: PropTypes.func,
      getBankPredictions: PropTypes.func,
    },
    props => ({
      getCachedBank: props.getCachedBank,
      getBankPredictions: props.getBankPredictions,
    }),
  ),
);

BankAndTypeForm.propTypes = {
  getBankPredictions: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function BankAndTypeForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <Card>
      <CardContent>
        <FlexBox gutter={8} flex={true}>
          {props.getBankPredictions && (
            <FlexBox flex={true}>
              <FormBankAutoComplete
                name="bank"
                fullWidth={true}
                placeholder={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
                label={getLocalisationMessage("bank", "Bank")}
              />
            </FlexBox>
          )}

          <FlexBox flex={true}>
            <FormSelectField
              name="type"
              autoWidth={true}
              fullWidth={true}
              formatOption={value =>
                formatText(getLocalisationMessage(value, value))
              }
              label={getLocalisationMessage("type", "Type")}
              options={BankReconcileTypes}
            />
          </FlexBox>
        </FlexBox>
      </CardContent>
      <FlexBox justify="flex-end">
        <CardActions>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </Card>
  );
}

export default enhancer(BankAndTypeForm);
