import React from "react";
import { List, OrderedMap } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import DataList, { DataListColumn } from "../data-list/DataList";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { accent4, primary1 } from "../../../shared/theme/main-theme";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ORDER_CUSTOMS_ORDERS_LIST_VIEW_URL } from "../../constants/AdminPathConstants";
import sprintf from "sprintf";
import DateTimeCell from "../data-list/DateTimeCell";
import { formatText } from "../../helpers/FormatUtils";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
    buttonUpdate: {
      backgroundColor: primary1,
      marginRight: "10px",
      color: "#fff",
      "&:hover": { backgroundColor: accent4 },
    },
  }),
);

CustomsList.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
  location: PropTypes.object,
  totalCount: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  overscanRowCount: PropTypes.number,
  rowCount: PropTypes.number,
  rowGetter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func,
};

CustomsList.defaultProps = {
  overscanRowCount: 6,
};

function CustomsList(props) {
  const { getLocalisationMessage } = props;
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      filter={props.filter}
      list={props.list}
      searchTypes={props.searchTypes}
      onFilterChange={props.onFilterChange}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.rowCount}
      rowGetter={props.rowGetter}
      cardActionIcons={props.cardActionIcons}
    >
      <DataListColumn
        width={96}
        label={getLocalisationMessage(
          "id_group_of_orders",
          "ID (Group of Orders)",
        )}
        dataKey="barcode"
        disableSort={true}
        justifyContent="center"
        cellRenderer={(row) => (
          <strong>
            <Link
              to={updateQuery(
                sprintf(
                  ORDER_CUSTOMS_ORDERS_LIST_VIEW_URL,
                  row.cellData.get("id"),
                ),
              )}
            >
              {row.cellData.get("barcode")}
            </Link>
          </strong>
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("group_type", "Group Type")}
        disableSort={true}
        dataKey="type"
        justifyContent="center"
        cellRenderer={(row) =>
          getLocalisationMessage(
            row.cellData.get("type"),
            formatText(row.cellData.get("type")),
          )
        }
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("created_date", "Created Date")}
        disableSort={true}
        dataKey="created_date"
        justifyContent="center"
        cellRenderer={(row) => (
          <DateTimeCell date={row.cellData.get("created_date")} />
        )}
      />

      <DataListColumn
        width={150}
        label={getLocalisationMessage("status", "Status")}
        disableSort={true}
        dataKey="status"
        justifyContent="center"
        cellRenderer={(row) => {
          const status = row.cellData.get("status");
          return getLocalisationMessage(status, formatText(status));
        }}
      />
    </DataList>
  );
}

export default enhancer(CustomsList);
