import { OrderedSet } from "immutable";

export const DOOR_TO_DOOR = "door_to_door";
export const DOOR_TO_OFFICE = "door_to_office";
export const OFFICE_TO_DOOR = "office_to_door";
export const OFFICE_TO_OFFICE = "office_to_office";

export default OrderedSet.of(
  DOOR_TO_DOOR,
  DOOR_TO_OFFICE,
  OFFICE_TO_DOOR,
  OFFICE_TO_OFFICE,
);
