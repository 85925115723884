import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: { margin: "0 16px" },
  }),
  reduxForm({
    form: "ShopCityForm",
    enableReinitialize: true,
    validate: values => ({
      name: !values.name && "Enter Name",
    }),
  }),
);

ShopCityForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onRequestClose: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function ShopCityForm(props) {
  const { classes } = props;

  return (
    <form
      id="ShopCityForm"
      onSubmit={props.handleSubmit}
      className={classes.container}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true}>
            <FormTextField
              name="name"
              fullWidth={true}
              label={props.getLocalisationMessage("name", "Name")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} justify="flex-end" align="center">
                <Button onClick={props.onRequestClose}>
                  {props.getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
                <Button onClick={props.handleSubmit}>
                  {props.getLocalisationMessage("submit", "Submit")}
                </Button>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
}

export default enhancer(ShopCityForm);
