import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import { Cached, FlightLand, FlightTakeoff } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import CustomButton, { DEFAULT, SECONDARY } from "../ui-core/CustomButton";
import { COLUMN, INDEX } from "../orders-core/MUITable";
import {
  getInternationalOrderCountByFromCountry,
  getInternationalOrderCountByToCountry,
} from "../../api/admin/AdminDashboardNewApi";
import MuiTableInternationalTotal from "../orders-core/MuiTableInternationalTotal";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminInternationOrderCountByCountryChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
  title: PropTypes.string,
};

function AdminInternationOrderCountByCountryChartWrapper(props) {
  const [chartData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const orderRef = React.useRef(null);
  const [incoming, setIncoming] = useState(false);
  const [outgoing, setOutgoing] = useState(true);
  const [step, setStep] = useState(1);

  const apis = incoming
    ? getInternationalOrderCountByFromCountry
    : getInternationalOrderCountByToCountry;

  useEffect(() => {
    setIsLoading(true);
    apis({
      ...props.filter,
      step,
    })
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          setData(barData);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData([]);
      });
  }, [props.filter, incoming, outgoing, step]);

  return (
    <Card
      style={{
        width: "100%",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
        padding: 20,
      }}
      ref={orderRef}
    >
      <FlexBox direction="row" style={{ marginBottom: "10px" }}>
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,

            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "top_countries_by_order_count",
              "Top countries by Order count",
            )}
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13 }}>
            <CustomButton
              color={incoming ? SECONDARY : DEFAULT}
              fullWidth={true}
              startIcon={<FlightLand />}
              onClick={() => {
                setOutgoing(false);
                setIncoming(true);
              }}
            >
              {props.getLocalisationMessage("incoming", "Incoming")}
            </CustomButton>
            <CustomButton
              color={outgoing ? SECONDARY : DEFAULT}
              fullWidth={true}
              startIcon={<FlightTakeoff />}
              onClick={() => {
                setIncoming(false);
                setOutgoing(true);
              }}
            >
              {props.getLocalisationMessage("outgoing", "Outgoing")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      {isLoading && orderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(orderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}

      <MuiTableInternationalTotal
        isLoading={isLoading}
        withoutHeader={true}
        list={chartData}
        columns={[
          {
            type: INDEX,
            name: "label",
          },
          {
            type: COLUMN,
            name: "value",
          },
        ]}
      />

      {!isLoading && chartData.length >= step * 10 && (
        <CustomButton
          style={{ marginTop: 10 }}
          color={SECONDARY}
          fullWidth={true}
          startIcon={<Cached />}
          onClick={() => {
            setStep(prev => prev + 1);
          }}
        >
          {props.getLocalisationMessage("load_more", "Load more")}
        </CustomButton>
      )}
    </Card>
  );
}

export default enhancer(AdminInternationOrderCountByCountryChartWrapper);
