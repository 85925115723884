import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";
import PropTypes from "prop-types";

import AudioPlayer from "material-ui-audio-player";

const VoiceViewDialog = props => (
  <Dialog open={true} onClose={props.onRequestClose} fullWidth={true}>
    <DialogTitle> Voice View Dialog </DialogTitle>
    <DialogContent>
      <FlexBox direction="column" flex={true} gutter={8}>
        <FlexBox style={{ textAlign: "center" }}>
          Audio Voice - {props.item.get("title") || "Без названия"}
        </FlexBox>
        <FlexBox style={{ padding: 20 }}>
          <AudioPlayer
            src={props.item.get("url")}
            variation="secondary"
            rounded={true}
            download={true}
            preload="auto"
            width="100%"
          />
        </FlexBox>
      </FlexBox>
    </DialogContent>
    <DialogActions>
      <div>
        <Button onClick={props.onRequestClose}> Close </Button>
      </div>
    </DialogActions>
  </Dialog>
);

VoiceViewDialog.propTypes = {
  onRequestClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  item: PropTypes.object,
};

export default VoiceViewDialog;
