import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { withMapInstance } from "react-leflet-map-components";
import { isEqualData } from "../../helpers/DataUtils";
import fp from "lodash/fp";

const enhancer = compose(
  withMapInstance(),
  mapPropsStream(propsStream => {
    const getBoundsStream = propsStream
      .map(fp.pick(["polygon", "maps", "map"]))
      .distinctUntilChanged()
      .filter(props =>
        Boolean(props.polygon && props.polygon.length && props.maps),
      )
      .do(props => {
        const geoJson = props.maps.polygon(props.polygon);
        props.map.fitBounds(geoJson.getBounds());
      })
      .startWith(null);

    return propsStream
      .combineLatest(getBoundsStream, props => ({
        ...props,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

JurisdictionMapFitBounds.propTypes = {
  maps: PropTypes.object.isRequired,
  polygon: PropTypes.array,
};

function JurisdictionMapFitBounds() {
  return null;
}

export default enhancer(JurisdictionMapFitBounds);
