import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import { PROMO_LIST_URL } from "../../constants/AdminPathConstants";
import { createPromo } from "../../api/admin/AdminPromoApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PromoForm from "../../components/promos-core/PromoForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({ setLocation: PropTypes.func.isRquired }),
);

AdminPromoCreate.propTypes = {
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminPromoCreate(props) {
  return (
    <AdminAppLayout
      title={props.getLocalisationMessage("create_promo", "Create Promo")}
    >
      <PromoForm
        getCachedServiceType={getCachedServiceType}
        getServiceTypePredictions={getServiceTypePredictions}
        onDismiss={() => props.setLocation(PROMO_LIST_URL)}
        onSubmit={fp.flow(toSnakeCase, values =>
          createPromo(values).catch(ResponseError.throw),
        )}
        onSubmitSuccess={() => props.setLocation(PROMO_LIST_URL)}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPromoCreate);
