import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import { createSupplierContact } from "../../api/admin/AdminSupplierContactsApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SupplierContactForm from "../../components/suppliers-contacts-core/SupplierContactForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    modal: {
      maxWidth: "600px",
      minWidth: "600px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

AdminSupplierContactCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
};

function AdminSupplierContactCreateDialogWrapper(props) {
  return (
    <ModalPaper
      title={props.getLocalisationMessage(
        "create_supplier_contact",
        "Create Supplier Contact",
      )}
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={props.classes.modal}
    >
      <FlexBox container={8} direction="column">
        <SupplierContactForm
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            createSupplierContact(toSnakeCase(values)).catch(
              ResponseError.throw,
            )
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminSupplierContactCreateDialogWrapper);
