import React from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  CardActions,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Add } from "@material-ui/icons";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import FormTextField, { SECONDARY } from "../form/FormTextField";
import CustomButton, { CONTAINED } from "../ui-core/CustomButton";
import PageLoading from "../ui-core/PageLoading";
import FormChipInput from "../form/FormChipInput";
import { validateString } from "../../helpers/ValidateUtils";

const useStyles = makeStyles(() => ({
  input: { "& input": { fontSize: "20px" } },
}));

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values);
    };

    return propsStream.combineLatest(props => ({
      ...props,
      onSubmit: handleSubmit(props),
    }));
  }),
  reduxForm({
    form: "ProductCategoryForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: validateString(
        values.name,
        props.getLocalisationMessage("this_field_is_required"),
      ),
    }),
  }),
);

ProductCategoryForm.propTypes = {
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  edit: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ProductCategoryForm(props) {
  const { getLocalisationMessage } = props;
  const classes = useStyles();

  return (
    <FlexBox flex={true} element={<form onSubmit={props.handleSubmit} />}>
      <FlexBox flex={true} direction="column">
        <Card>
          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox flex={true} gutter={16} direction="column">
              <PageLoading isLoading={props.submitting} />

              <FlexBox flex={true}>
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={`${getLocalisationMessage("name", "Name")} *`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="nameRu"
                  fullWidth={true}
                  label={getLocalisationMessage("name_ru", "Name RU")}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="description"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "description",
                    "description",
                  )}`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormChipInput
                  name="alias"
                  fullWidth={true}
                  addOnBlur={true}
                  className={classes.input}
                  label={getLocalisationMessage("alias", "alias")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox element={<CardActions />} justify="flex-end">
            <Button onClick={props.onDismiss}>
              {getLocalisationMessage("cancel", "Cancel")}
            </Button>

            <CustomButton
              startIcon={<Add />}
              variant={CONTAINED}
              type="submit"
              color={SECONDARY}
            >
              {props.edit
                ? getLocalisationMessage("update", "Update")
                : getLocalisationMessage("create", "Create")}
            </CustomButton>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ProductCategoryForm);
