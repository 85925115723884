import { Observable } from "rxjs";
import _, { isEmpty } from "lodash";
// eslint-disable-next-line import/no-internal-modules
import { bufferCount, concatMap, delay, switchMap } from "rxjs/operators";
import React, { useContext } from "react";
import { fromJS, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  lifecycle,
  mapPropsStream,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router";
import { Autorenew, FilterList as FilterListIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { loadAllValues, mapObjectResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { mergeSideEffectStreams, pipeStreams } from "../../helpers/StreamUtils";
import { captureException } from "../../helpers/ErrorTracker";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import {
  createOrderSortingBinCreator,
  isExpiredOrderRecord,
  TYPE_BATCH,
  TYPE_SHIPMENT,
} from "../../helpers/OrderOutboundSortingHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  addSortingTaskOrderBarcodes,
  addSortingTaskOrderNumbers,
  cacheOrderSortingOrders,
  clearOrderSortingOrders,
  getOrderSortingOrders,
  getOrderSortingTask,
  removeOrderSortingOrders,
  updateSortingTask,
  updateSortingVerifiedOrders,
} from "../../reducers/OrderOutboundSortingReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  IN_SORTING_FACILITY,
  MISROUTED,
  READY_FOR_DELIVERY,
  RETURNING_TO_ORIGIN,
} from "../../constants/OrderStatusCodes";
import {
  batchUpdateOrderWarehouse,
  getOrderList,
  getOrderObject,
  updateRecipientPostcode,
} from "../../api/admin/AdminOrderApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/shared/CountryV2Api";
import AdminOrderFilterWrapper from "../../wrappers/admin/AdminOrderFilterWrapper";
import FormDialog from "../../components/form/FormDialog";
import FormWarehouseDialog from "../../components/form/FormWarehouseDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import NavigationPrompt from "../../components/router/NavigationPrompt";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import Notification from "../../components/notifications/Notification";
import OrderSortingRuleListDialog from "../../components/order-outbound-sorting/OrderSortingRuleListDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { getTokenUserId } from "../../../shared/reducers/AuthReducer";
import CustomButton from "../../components/ui-core/CustomButton";
import {
  getUserWarehouse,
  getUserWarehouseId,
  getUserWarehousesIds,
} from "../../reducers/ProfileReducer";
import AdminOrderOutboundSortingTableContainer from "./AdminOrderOutboundSortingTableContainer";
import OrderSortingExportRulesDialog from "../../components/order-outbound-sorting/OrderSortingExportRulesDialog";
import OrderSortingImportRulesDialog from "../../components/order-outbound-sorting/OrderSortingImportRulesDialog";
import { validateUserWarehouse } from "../../helpers/OrderSortingHelper";
import { fetchMongoToken, MONGO_DB_URL } from "../../realtimeDb/MongoDBSDK";
import io from "socket.io-client";
import {
  loadingSubject,
  OrderSortingDB as RethinkOrderSortingDb,
} from "../../realtimeDb/OrderSortingDB";

import { GlobalContext } from "../shared/ClientApp";
import Cookies from "js-cookie";

const baseFilter = new DataListFilter({
  size: 200,
  simple: true,
  use_solr: true,
  include_dw: true,
  search_type: "order_number",
});

const basePrefetchFilter = baseFilter.setValueMap({
  status: [
    IN_SORTING_FACILITY,
    MISROUTED,
    READY_FOR_DELIVERY,
    RETURNING_TO_ORIGIN,
  ],
});

const getAllOrdersByFilter = loadAllValues(getOrderList);

let socketConnection = false;

const enhancer = compose(
  withTheme,
  useSheet({
    counter: { display: "none" },
    mobile: { fontSize: "12px", lineHeight: "20px" },
    sortingStatsHeader: {
      display: "block",
      lineHeight: "10px",
      marginTop: "15px",
    },
    mobileCounter: { display: "initial" },
    cacheServer: {
      display: "block",
      fontSize: "14px",
      paddingTop: "5px",
    },
    "@media (min-width: 998px)": {
      counter: { display: "flex" },
      mobileCounter: { display: "none" },
      cacheServer: {
        fontSize: "18px",
      },
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const task = getOrderSortingTask(state);
      const userWarehouse = toJS(getUserWarehouse(state));

      return {
        task,
        userWarehouse,
        userWarehouseId: getUserWarehouseId(state),
        userWarehousesIds: getUserWarehousesIds(state),
        userId: getTokenUserId(state),
        allOrders: getOrderSortingOrders(state),
        warehouseId: task.getIn(["warehouse", "id"]),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,

      updateSortingTask,

      cacheOrderSortingOrders,
      clearOrderSortingOrders,
      removeOrderSortingOrders,
      addSortingTaskOrderNumbers,
      addSortingTaskOrderBarcodes,
      updateSortingVerifiedOrders,
    },
  ),
  withState("state", "setState", {
    pendingTasks: Set(),
    prefetchPending: false,
    binGenerationPending: false,
    fetchedOrders: 0,
    socketListening: false,
  }),
  withState("socket", "setSocket", null),
  withState("rethinkDb", "setRethinkDb", null),
  withState("socketLoading", "setSocketLoading", null),
  withState("stateOrders", "setStateOrders", undefined),
  lifecycle({
    componentDidMount() {
      this.props.setSocket(undefined);
      this.props.setRethinkDb(undefined);
      socketConnection = false;
    },
    componentWillUnmount() {
      if (this.props && this.props.socket) {
        this.props.socket.disconnect();
      }
      this.props.setSocket(undefined);
      if (this.props.rethinkDb) this.props.rethinkDb.clearCache();
      this.props.setRethinkDb(undefined);
      socketConnection = false;
      Cookies.remove("mongo_token");
    },
  }),
  withHandlers({
    searchOrderObjectsRethinkDb: props => orderNumbers => {
      const numbers = Set(orderNumbers);

      return getOrderObject(baseFilter.setValue("barcodes", numbers.join(",")))
        .takeLast(1)
        .let(mapObjectResponseStream)
        .map(x => x.getIn(["payload"]))
        .switchMap(data => {
          const orders = data.get("orders", List());
          const batches = data.get("batches", List());

          const values = [];
          const notLoaded = numbers.asMutable();

          orders.forEach(order => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values.push({
                number: orderNumber,
                type: TYPE_SHIPMENT,
                failed: null,
                info: order.toJS(),
                hash: order.hashCode(),
                hash_time: Date.now(),
              });
              // values[`${orderNumber}/number`] = orderNumber;
              // values[`${orderNumber}/type`] = TYPE_SHIPMENT;
              // values[`${orderNumber}/failed`] = null;
              // values[`${orderNumber}/info`] = order.toJS();
              // values[`${orderNumber}/hash`] = order.hashCode();
              // values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          batches.forEach(order => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values.push({
                number: orderNumber,
                type: TYPE_BATCH,
                failed: null,
                info: order.toJS(),
                hash: order.hashCode(),
                hash_time: Date.now(),
              });
              // values[`${orderNumber}/number`] = orderNumber;
              // values[`${orderNumber}/type`] = TYPE_BATCH;
              // values[`${orderNumber}/failed`] = null;
              // values[`${orderNumber}/info`] = order.toJS();
              // values[`${orderNumber}/hash`] = order.hashCode();
              // values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          notLoaded.forEach(orderNumber => {
            values.push({
              number: orderNumber,
              failed: true,
            });
            // values[`${orderNumber}/failed`] = true;
          });
          return props.rethinkDb.batchUpdateOrders(values);
        });
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .map(
              fp.pick([
                "warehouseId",
                "userWarehouseIds",
                "updateSortingTask",
                "clearOrderSortingOrders",
              ]),
            )
            .filter(
              props =>
                props.warehouseId > 0 && fp.size(props.userWarehouseIds) > 0,
            )
            // .distinctUntilChanged(isEqualData)
            .do(props => {
              if (
                !validateUserWarehouse(
                  props.warehouseId,
                  props.userWarehouseIds,
                )
              ) {
                props.clearOrderSortingOrders();
                props.updateSortingTask(x => x.clear());
              }
            }),

          propsStream
            .take(1)
            .map(fp.pick(["task", "updateSortingTask"]))
            .distinctUntilChanged(isEqualData)
            .do(props => {
              props.updateSortingTask(x =>
                x.update("selectedOrders", () => []),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream);
      },

      /**
       * Step 1 - Load sorting job details from Firebase.
       */
      propsStream => {
        const rethinkDbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .filter(props => props.warehouseId > 0)
          .filter(props => !props.socket || !props.socket.connected)
          .mergeMap(({ warehouseId }) =>
            Observable.from(fetchMongoToken(warehouseId)),
          )
          .withLatestFrom(propsStream)
          .mergeMap(([token, props]) => {
            let rethinkDB;
            if (!socketConnection) {
              socketConnection = true;
              return new Observable(emit => {
                props.setSocketLoading(true);
                const socket = io.connect(`${MONGO_DB_URL}?token=${token}`, {
                  path: "/websocket/socket.io",
                  autoConnect: true,
                  transports: ["websocket"],
                  reconnect: true,
                  pingInterval: 30000,
                  pingTimeout: 60000,
                });

                socket.on("connect", () => {
                  // eslint-disable-next-line no-console
                  console.log("connected to the socket sorting");
                  rethinkDB = new RethinkOrderSortingDb(socket);
                  props.setSocket(socket);
                  props.setRethinkDb(rethinkDB);
                  emit.next(rethinkDB);
                  props.setSocketLoading(false);
                });
                socket.on("connect_error", () => {
                  // eslint-disable-next-line no-console
                  console.log("socket connection error");
                  if (socketConnection) {
                    socket.connect();
                  }
                });
                socket.on("disconnect", reason => {
                  // eslint-disable-next-line no-console
                  console.log("socket disconnect, ", reason);
                });
              });
            }
            return Observable.of(rethinkDB);
          })
          .startWith(undefined);

        const rethinkDbInitialState = {
          rethinkTasks: [],
          rethinkOrders: Map(),
          rethinkBinRules: null,
          rethinkRegistries: Map(),
        };

        const rethinkStateStream = rethinkDbStream
          .switchMap(rethinkDb =>
            rethinkDb
              ? Observable.combineLatest(
                  rethinkDb.getTasks(),
                  rethinkDb.getOrders(),
                  rethinkDb.getBinRules(),
                  rethinkDb.getRegistries(),
                  propsStream,
                  (tasks, orders, binRules, registries) => ({
                    rethinkTasks: tasks,
                    rethinkOrders: orders,
                    rethinkBinRules: binRules,
                    rethinkRegistries: registries,
                  }),
                ).distinctUntilChanged(isEqualData)
              : Observable.of(rethinkDbInitialState),
          )
          .startWith(rethinkDbInitialState);

        // const stateStream = dbStream
        //   .switchMap((db: OrderSortingDB) =>
        //     !db.warehouseId
        //       ? Observable.of(initialState)
        //       : Observable.combineLatest(
        //         db.getTasks(),
        //         db.getOrders(),
        //         db.getBinRules(),
        //         db.getRegistries(),
        //         (tasks, orders, binRules, registries) => ({
        //           tasks,
        //           orders,
        //           binRules,
        //           registries,
        //         }),
        //       ).startWith(initialState),
        //   )
        //   .distinctUntilChanged(isEqualData);

        const socketListenerStream = loadingSubject
          .asObservable()
          .withLatestFrom(propsStream)
          .do(([loadingState, props]) => {
            props.setState(fp.set("socketListening", loadingState));
          })
          .startWith(undefined);

        return propsStream.combineLatest(
          rethinkStateStream,
          rethinkDbStream,
          socketListenerStream,
          (props, rethinkState) => ({
            ...props,
            ...rethinkState,
          }),
        );
      },
      /**
       * Step 2 - Normalize task values.
       */
      propsStream => {
        // NOTHING TO DO
        const taskStream = propsStream
          .map(fp.pick(["task", "orders", "rethinkOrders"]))
          .distinctUntilChanged(isEqualData)
          .map(props =>
            props.task.withMutations(task => {
              if (props.rethinkOrders.size > 0) {
                task.update(
                  "activeOrder",
                  x => x || props.rethinkOrders.last().get("number"),
                );
              }
              task.update("selectedOrders", selected =>
                selected.filter(x =>
                  props.rethinkOrders
                    .map(item => item.get("number"))
                    .includes(x),
                ),
              );
            }),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(taskStream, (props, task) => ({
          ...props,
          task,
        }));
      },

      /**
       * Step 4 - Handle order task requests.
       *
       * 1. Handle order size change request.
       * 1. Handle failed task retry request.
       */
      // (propsStream) => {
      //   const {
      //     handler: onRetryTaskRequest,
      //     stream: onRetryTaskRequestStream,
      //   } = createEventHandler();
      //
      //   const {
      //     handler: onCancelTaskRequest,
      //     stream: onCancelTaskRequestStream,
      //   } = createEventHandler();
      //
      //   const sideEffectsStream = mergeSideEffectStreams(
      //     propsStream
      //       .filter((props) => props.warehouseId > 0)
      //       .distinctUntilKeyChanged("warehouseId")
      //       .switchMap((props) => {
      //         const db = new OrderSortingDB(props.warehouseId);
      //
      //         return mergeSideEffectStreams(
      //           onRetryTaskRequestStream.mergeMap((taskId) =>
      //             db.retryTask(taskId),
      //           ),
      //           onCancelTaskRequestStream.mergeMap((taskId) =>
      //             db.removeTask(taskId),
      //           ),
      //         );
      //       }),
      //   );
      //
      //   return propsStream.merge(sideEffectsStream).map((props) => ({
      //     ...props,
      //     onRetryTaskRequest,
      //     onCancelTaskRequest,
      //   }));
      // },
      /**
       * Step 5 - Register side effect workers.
       *
       * 1. Sync removed orders in firebase with reducer.
       * 2. Loads orders without hash.
       * 3. Generates bin names for orders without bin names.
       * 4. Execute order tasks.
       * 5. Prefetch orders by filter and repeat every 20 minutes.
       */
      propsStream => {
        const {
          handler: onOrderSync,
          stream: onOrderSyncStream,
        } = createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0 && props.rethinkDb)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              // const db = new OrderSortingDB(props.warehouseId);
              // const addStream = db.getOrderAddStream();
              // const changeStream = db.getOrderChangeStream();

              const removeStream = props.rethinkDb.onOrdersRemoveStream();

              const orderChangeStream = props.rethinkDb
                // .getOrders()
                .onOrdersChangeStream();
              // .do((orders) => { console.log("ttttt get data", orders) })
              // .switch((orders) => Observable.of(orders));

              // .mergeMap((orders) => Observable.of(orders))

              // .switchMap((items) => console.log("orderssss", items) || Observable.of(items));
              // const orderChangeStream = props.rethinkDb.getOrders();

              // const addOrChangeStream = Observable.merge(
              //   addStream,
              //   changeStream,
              // );

              return mergeSideEffectStreams(
                // removeStream
                //   .map((x) => x.get("number"))
                //   .bufferTime(1000, null, 100)
                //   .filter((buffer) => buffer.length > 0)
                //   .do((orders) => {
                //     props.removeOrderSortingOrders(Set(orders));
                //   }),
                removeStream
                  .filter(Boolean)
                  .map(x => x.get("number"))
                  .bufferTime(1000, null, 100)
                  .filter(buffer => buffer.length > 0)
                  .do(orders => {
                    props.removeOrderSortingOrders(Set(orders));
                  }),

                props.rethinkDb
                  .getBinRules()
                  .distinctUntilChanged(isEqualData)
                  .switchMap(rules => {
                    const createBin = createOrderSortingBinCreator(rules);

                    return Observable.of(null).expand(() =>
                      orderChangeStream.switchMap(o =>
                        Observable.from(o)
                          .filter(Boolean)
                          .filter(
                            x =>
                              x.get("info") &&
                              !x.get("bin") &&
                              !isExpiredOrderRecord(x),
                          )
                          .bufferCount(o.size)
                          .take(1)
                          .do(() => {
                            props.setState(fp.set("binGeneration", true));
                          })
                          // .bufferCount(200)
                          // .filter((buffer) => console.log("after buffer", buffer) || buffer.length > 0)
                          // .take(1)
                          // .switchMap((orders) => {
                          //     console.log('test', orders)
                          //     const values = orders.map((order) => {
                          //         const bin = createBin(order.get("info"));
                          //
                          //         return order
                          //             .set("bin", fp.get("bin", bin))
                          //             .set("code", fp.get("code", bin))
                          //             .toJS();
                          //     });
                          //     return props.rethinkDb.batchUpdateOrders(values);
                          // })
                          .switchMap(orders =>
                            Observable.from(orders)
                              .bufferCount(200)
                              .delay(1000)
                              .switchMap(chunkOrders => {
                                const values = chunkOrders.map(order => {
                                  const bin = createBin(order.get("info"));

                                  return order
                                    .set("bin", fp.get("bin", bin))
                                    .set("code", fp.get("code", bin))
                                    .toJS();
                                });
                                return props.rethinkDb.batchUpdateOrders(
                                  values,
                                );
                              }),
                          )
                          .catch(error => {
                            captureException(error);

                            return Observable.of(null);
                          })
                          .do(() => {
                            props.setState(fp.set("binGeneration", false));
                          })
                          .delay(1000),
                      ),
                    );
                  }),

                Observable.of(null).expand(() =>
                  orderChangeStream.switchMap(o =>
                    Observable.from(o)
                      .filter(Boolean)
                      .filter(x => !x.get("failed") && isExpiredOrderRecord(x))
                      // .bufferTime(1000, null, 100)
                      .bufferCount(200)
                      .filter(x => x.length > 0)
                      .take(1)
                      .switchMap(buffer =>
                        props.searchOrderObjectsRethinkDb(
                          buffer.map(x => x.get("number")),
                        ),
                      )
                      .catch(error => {
                        captureException(error);

                        return Observable.of(null);
                      })
                      .delay(1000),
                  ),
                ),

                Observable.of(null).expand(() =>
                  props.rethinkDb
                    .getCorruptedOrderStream()
                    .map(fp.get("key"))
                    .bufferTime(1000, null, 100)
                    .filter(buffer => buffer.length > 0)
                    .take(1)
                    .switchMap(x => props.rethinkDb.batchRemoveOrders(x))
                    .catch(error => {
                      captureException(error);

                      return Observable.of(null);
                    })
                    .delay(1000),
                ),

                props.rethinkDb.getTaskAddStream().mergeMap(response => {
                  // const id = response.key;
                  const payload = response.val();

                  // Remove task if it's corrupted.
                  if (!payload || !payload.task || !payload.number) {
                    // return db.removeTask(id);
                    //   return props.rethinkDb.removeTask("number", order.get("number"));
                    return Observable.of();
                  }

                  const { task, number: orderNumber } = payload;

                  return props.rethinkDb
                    .getOrder(orderNumber)
                    .take(1)
                    .switchMap(order => {
                      let taskStream;

                      if (task.in_sorting_warehouse) {
                        taskStream = Observable.defer(() =>
                          batchUpdateOrderWarehouse({
                            order_barcodes: [orderNumber],
                            order_status: IN_SORTING_FACILITY,
                            warehouse: { id: task.in_sorting_warehouse },
                          }),
                        );
                      }

                      // Remove task if it's order not found or failed to load.
                      if (order.isEmpty() || order.get("failed")) {
                        // return db.removeTask(id);
                        return props.rethinkDb.removeTask(
                          "number",
                          order.get("number"),
                        );
                      }

                      // Retry to execute task after one second if it's order not loaded.
                      if (!order.getIn(["info", "id"])) {
                        return Observable.timer(1000).switchMap(
                          () =>
                            props.rethinkDb.removeTask(
                              "number",
                              order.get("number"),
                            ),
                          // db.retryTask(id),
                        );
                      }

                      if (task.update_postcode) {
                        taskStream = Observable.defer(() =>
                          updateRecipientPostcode(task.update_postcode),
                        );
                      }

                      // If task found - execute it.
                      // If it's succeed remove it and reload order.
                      // If not update it with error message
                      if (taskStream) {
                        return taskStream
                          .switchMap(() =>
                            Observable.merge(
                              props.rethinkDb.removeTask("number", orderNumber),
                              props.rethinkDb.reloadOrder(
                                "number",
                                orderNumber,
                              ),
                            ),
                          )
                          .catch(error =>
                            props.rethinkDb.updateTask(orderNumber, {
                              error: error.message,
                            }),
                          );
                      }

                      // If it's unknown task - remove it.
                      return props.rethinkDb.removeTask("number", orderNumber);
                    });
                }, 5),
              );
            }),

          onOrderSyncStream // sync button
            .withLatestFrom(propsStream)
            .map(([{ filter, rules }, props]) => ({
              ...props,
              filter,
              rules,
            }))
            .filter(props => props.warehouseId > 0 && props.rethinkDb)
            .map(props => ({
              ...props,
              filter:
                props.filter.size > 0
                  ? props.filter
                  : new DataListFilter({
                      warehouse_ids: props.warehouseId,
                    }).setValueMap(basePrefetchFilter),
            }))
            .map(
              fp.update("filter", x =>
                new DataListFilter(x).setValueMap(baseFilter).setPageSize(200),
              ),
            )
            .switchMap(props => {
              // const db = new OrderSortingDB(props.warehouseId);
              props.setState(fp.set("prefetchPending", true));
              return getAllOrdersByFilter(props.filter)
                .map(response => response.get("list"))
                .takeLast(1)
                .do(orders => {
                  props.setState(fp.set("fetchedOrders", orders.size));
                  props.setState(fp.set("prefetchPending", false));
                  props.showSuccessMessage(
                    `Получено ${orders.size} отправлений`,
                  );
                })
                .map(orders => {
                  if (orders.size > 0) {
                    return Observable.from(orders)
                      .pipe(
                        bufferCount(200),
                        concatMap(txn => Observable.of(txn).pipe(delay(500))),
                        switchMap(
                          chunkOrders => {
                            // console.log("rules", rules)
                            const createBin = createOrderSortingBinCreator(
                              props.rules,
                            );
                            const values = [];
                            props.setState(fp.set("binGeneration", true));
                            chunkOrders.forEach(order => {
                              const bin = createBin(order);
                              const orderNumber = fp.trim(order.get("barcode"));
                              values.push({
                                number: orderNumber,
                                failed: null,
                                type: TYPE_SHIPMENT,
                                info: order.toJS(),
                                hash: order.hashCode(),
                                hash_time: Date.now(),
                                bin: fp.get("bin", bin),
                                code: fp.get("code", bin),
                              });
                            });
                            props.setState(fp.set("binGeneration", false));
                            if (values.length) {
                              return props.rethinkDb.batchUpdateOrders(values);
                            }
                            return Observable.of([]);
                          },

                          //   console.log("kkkkk1", chunkOrders)
                          // const values = [];
                          //   const createBin = createOrderSortingBinCreator(rules);
                          // chunkOrders.forEach((order) => {
                          //   const orderNumber = fp.trim(order.get("barcode"));
                          //
                          //   values.push({
                          //     number: orderNumber,
                          //     failed: null,
                          //     type: TYPE_SHIPMENT,
                          //     info: order.toJS(),
                          //     hash: order.hashCode(),
                          //     hash_time: Date.now(),
                          //   });
                          // });
                          //
                          //
                          //
                          // if (values && values.length) {
                          //   return props.rethinkDb.batchUpdateOrders(values);
                          // }
                          //
                          // return Observable.of([]);
                        ),
                      )
                      .subscribe();
                  }
                  return Observable.of([]);
                });
            }),
        );

        return propsStream.merge(sideEffectsStream).map(props => ({
          ...props,
          onOrderSync,
        }));
      },
      /**
       * Step 6 - Generate order stats.
       *
       * 1. Count failed orders.
       */
      propsStream => {
        const {
          handler: onAutoBatchClick,
          stream: onAutoBatchClickStream,
        } = createEventHandler();

        const statsStream = propsStream
          .map(props => ({ orders: props.rethinkOrders }))
          .distinctUntilChanged(isEqualData)
          .map(({ orders }) => {
            const failedOrders = Set().asMutable();
            orders.forEach((order, orderNumber) => {
              if (!order.hasIn(["info", "id"]) && order.get("failed")) {
                failedOrders.add(orderNumber);
              }
            });

            return Map({ failedOrders: failedOrders.size });
          })
          .distinctUntilChanged(isEqualData);

        const sideEffectStream = onAutoBatchClickStream
          .delay(100)
          .withLatestFrom(propsStream)
          .startWith(null);

        return propsStream.combineLatest(
          statsStream,
          sideEffectStream,
          (props, stats) => ({
            ...props,
            stats,
            onAutoBatchClick,
          }),
        );
      },
      /**
       * Step 7 - Collect order task stats.
       */
      propsStream => {
        const taskStatsStream = propsStream
          .distinctUntilKeyChanged("tasks", isEqualData)
          .map(props => {
            const failed = Set().asMutable();
            const pending = Set().asMutable();

            props.rethinkTasks.forEach((task, id) => {
              if (task.get("error")) {
                failed.add(task.set("id", id));
              } else {
                pending.add(task.set("id", id));
              }
            });

            return Map({
              failed: failed.asImmutable(),
              pending: pending.asImmutable(),
            });
          });

        return propsStream.combineLatest(
          taskStatsStream,
          (props, taskStats) => ({ ...props, taskStats }),
        );
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "userWarehousesIds",
      "warehouseId",
      "location",
      "state",
      "task",
      "stats",
      "orders",
      "registries",
      "rethinkBinRules",
      "rethinkOrders",
      "rethinkRegistries",
      "binRules",
      "allOrders",
      "taskStats",
      "socketListening",
    ]),
  ),
);

AdminOrderOutboundSortingContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,

  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,

  userWarehousesIds: PropTypes.array,
  state: PropTypes.object,
  binGeneration: PropTypes.bool,
  warehouseId: PropTypes.number,
  userWarehouseId: PropTypes.number,
  task: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
  rethinkDb: PropTypes.instanceOf(RethinkOrderSortingDb),
  orders: PropTypes.instanceOf(Map),
  rethinkOrders: PropTypes.instanceOf(Map),
  rethinkRegistries: PropTypes.instanceOf(Map),
  rethinkBinRules: PropTypes.instanceOf(Map),
  registries: PropTypes.instanceOf(Map),
  binRules: PropTypes.instanceOf(Map),
  taskStats: PropTypes.instanceOf(Map),
  userWarehouse: PropTypes.object,
  onOrderSync: PropTypes.func,
  updateSortingTask: PropTypes.func,
  clearOrderSortingOrders: PropTypes.func,
  theme: PropTypes.object,
  onAutoBatchClick: PropTypes.func,
  updateSortingVerifiedOrders: PropTypes.func,
  socketLoading: PropTypes.bool,
  socketListening: PropTypes.bool,
};

const dbOrdersMap = orders => {
  const list = (orders || fromJS([])).toJS();
  if (isEmpty(list)) {
    return Map();
  }

  return fromJS(
    (list || []).reduce((acc, item) => {
      acc[`${item.number}`] = item;
      return acc;
    }, {}),
  );
};

function AdminOrderOutboundSortingContainer(props) {
  const { setUW } = useContext(GlobalContext);
  const {
    task,
    state,
    // orders,
    rethinkOrders,
    taskStats,
    getLocalisationMessage,
    location: { query },
  } = props;
  const orders = dbOrdersMap(rethinkOrders);
  if (!validateUserWarehouse(props.warehouseId, props.userWarehousesIds)) {
    return (
      <FormWarehouseDialog
        open={true}
        isDisabled={!fp.size(props.userWarehousesIds) > 1}
        initialValues={{ warehouse: props.userWarehouse }}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        includeWarehouses={props.userWarehousesIds}
        onSubmit={values => {
          setUW(_.get(values, "warehouse.id"));
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  // const db = new OrderSortingDB(props.warehouseId);

  const isLoading = !props.rethinkBinRules;

  // const isLoading = true
  return (
    <AdminAppLayout
      title={`${getLocalisationMessage(
        "order_sorting",
        "Order Sorting",
      )} | ${task.getIn(["warehouse", "name"])}`}
      appBarRightAction={
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          className={props.classes.appBarRightAction}
        >
          {false && Boolean(props.orders.size) && (
            <Button
              startIcon={<Autorenew />}
              color="secondary"
              variant="contained"
              style={{ marginRight: 10, color: "#fff" }}
              onClick={props.onAutoBatchClick}
            >
              Автосортировка
            </Button>
          )}
          <ButtonGroup variant="contained" color="secondary">
            <CustomButton
              disabled={
                state.socketListening ||
                state.prefetchPending ||
                state.binGeneration
              }
              onClick={() => {
                props.onOrderSync({
                  filter: task.get("filter"),
                  rules: props.rethinkBinRules,
                });
                // props.rethinkDb
                //   .clearOrders()
                //   .toPromise()
                //   .then(props.onOrderSync(task.get("filter")));
              }}
            >
              {state.socketListening ||
              state.prefetchPending ||
              state.binGeneration ? (
                <FlexBox>
                  {(state.socketListening || state.prefetchPending) && (
                    <div>Сбор данных...{"  "}</div>
                  )}
                  <CircularProgress size={20} color="secondary" />
                </FlexBox>
              ) : (
                getLocalisationMessage("sync_orders", "Sync Orders")
              )}
            </CustomButton>
            <CustomButton
              size="small"
              onClick={() => props.setLocationQuery(fp.set("prefetch", true))}
            >
              <FilterListIcon />
            </CustomButton>
          </ButtonGroup>
          <MenuButtonMore color={props.theme.palette.appBarTextColor}>
            <MenuItem
              onClick={() =>
                props.setLocationQuery(fp.set("view_rule_list", true))
              }
            >
              {getLocalisationMessage("view_rules", "View Rules")}
            </MenuItem>
            {Boolean(props.rethinkBinRules) && (
              <div>
                <Divider />

                <MenuItem
                  onClick={() => props.setLocationQuery(fp.set("export", true))}
                >
                  {getLocalisationMessage("export_rules", "Export Rules")}
                </MenuItem>
                <MenuItem
                  onClick={() => props.setLocationQuery(fp.set("import", true))}
                >
                  {getLocalisationMessage("import_rules", "Import Rules")}
                </MenuItem>

                <Divider />
              </div>
            )}

            <MenuItem
              onClick={() =>
                props.setLocationQuery(fp.set("view_rule_list", true))
              }
            >
              <Link to="voice-sorting?tab=voice_sorting">
                {getLocalisationMessage("setup_voice")}
              </Link>
            </MenuItem>

            {orders.size > 0 && (
              <MenuItem
                onClick={() =>
                  props.setLocationQuery(fp.set("remove_all", true))
                }
              >
                {getLocalisationMessage("remove_orders", "Remove Orders")}
              </MenuItem>
            )}

            <Divider />

            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("log_out_warehouse", "Log Out Warehouse")}
            </MenuItem>
          </MenuButtonMore>
        </FlexBox>
      }
    >
      <PageLoading isLoading={isLoading} />

      <NavigationPrompt
        when={state.pendingTasks.size > 0}
        message={[
          `${getLocalisationMessage("there_are", "There are ")} ${
            state.pendingTasks.size
          } ${getLocalisationMessage("unfinished_tasks", "unfinished tasks")}`,
          getLocalisationMessage(
            "are_you_sure_you_want_to_exit",
            "Are you sure you want to exit?",
          ),
        ].join(" ")}
      />

      {Boolean(
        !isLoading && orders.size === 0 && query.view_table === "true",
      ) && (
        <Redirect
          when={true}
          to={updateQuery(props.location, fp.unset("view_table"))}
        />
      )}
      {Boolean(props.rethinkBinRules) &&
        (query.view_rule_list === "true" ? (
          <OrderSortingRuleListDialog
            open={query.view_rule_list === "true"}
            onRequestClose={() =>
              props.setLocationQuery(fp.unset("view_rule_list"))
            }
            initialValues={{ rules: props.rethinkBinRules }}
            onSubmit={values =>
              props.rethinkDb
                .setBinRules(values.rules.toJS())
                .toPromise()
                .catch(ResponseError.throw)
            }
            onSubmitSuccess={() =>
              props.setLocationQuery(fp.unset("view_rule_list"))
            }
            onSubmitFail={props.showErrorMessage}
            getCachedSupplier={getCachedSupplier}
            getSupplierPredictions={getSupplierPredictions}
            getCachedWarehouse={getCachedWarehouse}
            getWarehousePredictions={getWarehousePredictions}
            getCachedPostcode={getCachedPostcode}
            getPostcodePredictions={getPostcodePredictions}
          />
        ) : (
          props.rethinkBinRules.isEmpty() && (
            <ConfirmDialog
              open={true}
              confirmButtonLabel={getLocalisationMessage(
                "add_rules",
                "Add Rules",
              )}
              onConfirm={() =>
                props.setLocationQuery(fp.set("view_rule_list", true))
              }
            >
              {getLocalisationMessage(
                "there_are_no_bin_sorting_rules",
                "There Are No Bin Sorting Rules",
              )}
            </ConfirmDialog>
          )
        ))}

      {query.remove_all === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
          onSubmit={() => {
            props.clearOrderSortingOrders();
            props.updateSortingVerifiedOrders(x => x.clear());

            return props.rethinkDb
              .clearOrders()
              .toPromise()
              .catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => props.setLocationQuery(fp.unset("remove_all"))}
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_all_orders_from_queue",
            "Are you sure you want to remove all orders from queue?",
          )}
        </FormDialog>
      )}

      {query.log_out === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingOrders();
            props.updateSortingTask(x => x.clear());
            props.updateSortingVerifiedOrders(x => x.clear());
            props.setLocationQuery(fp.unset("log_out"));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      {query.prefetch === "true" && (
        <AdminOrderFilterWrapper
          open={true}
          showSearch={true}
          filter={basePrefetchFilter
            .setValueMap(task.get("filter"))
            .setValueMap({
              warehouse_ids: props.userWarehouseId,
            })}
          onFilterChange={filter => {
            props.setLocationQuery(fp.unset("prefetch"));
            props.updateSortingTask(x =>
              x.set("filter", Map(filter.getDefinedValues())),
            );
          }}
          onRequestClose={() => props.setLocationQuery(fp.unset("prefetch"))}
        />
      )}

      <Notification
        uid="pending_count"
        open={taskStats.get("pending").size > 0}
      >
        {`${taskStats.get("pending").size} ${getLocalisationMessage(
          "pending_tasks",
          "Pending Tasks",
        )}`}
      </Notification>

      {props.rethinkBinRules && query.export === "true" && (
        <OrderSortingExportRulesDialog
          open={true}
          rules={props.rethinkBinRules}
          onRequestClose={() => props.setLocationQuery(fp.unset("export"))}
        />
      )}

      {query.import === "true" && (
        <OrderSortingImportRulesDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("import"))}
          onSubmit={values => {
            const { data } = values;

            return props.rethinkDb
              .setBinRules(data.bin_rules)
              .toPromise()
              .catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage("rules_imported", "Rules Imported"),
            );
            props.setLocationQuery(fp.unset("import"));
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      <AdminOrderOutboundSortingTableContainer
        task={task}
        orders={orders}
        registries={props.rethinkRegistries}
        location={props.location}
        warehouseId={props.warehouseId}
        setLocationQuery={props.setLocationQuery}
        setLocation={props.setLocation}
        showErrorMessage={props.showErrorMessage}
        showSuccessMessage={props.showSuccessMessage}
        updateSortingTask={props.updateSortingTask}
        binRules={props.rethinkBinRules}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
        rethinkDb={props.rethinkDb}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderOutboundSortingContainer);
