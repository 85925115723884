import React, { useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../../components/ui-core/FlexBox";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import SimpleAutocomplete from "../../components/form/SimpleAutocomplete";
import {
  getCustomerById,
  getCustomersChooseHybrid,
} from "../../api/admin/AdminCustomerApi";
import CustomButton, { SECONDARY } from "../../components/ui-core/CustomButton";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { OUTLINED, STANDARD } from "../../components/form/FormTextField";
import { TransitionUp } from "../../components/dialog/TransitionUp";
import {
  getWarehouseById,
  warehouseChoose,
} from "../../api/admin/AdminWarehouseApi";
import { getOpertatorById, operatorChoose } from "../../api/admin/AdminUserApi";
import {
  DATE_TIME_SHORT_FORMAT,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);
const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "400px",
    maxWidth: "500px",
    minHeight: "auto",
  },
  dialogTitle: {
    color: theme.palette.appBarTextColor,
    backgroundColor: theme.palette.primary1Color,
  },
  dialogHeaderTitle: {
    flexGrow: 1,
  },
  dialogHeaderToggleContainer: {
    padding: "10px 5px 10px 20px",
    borderRadius: 1000,
    backgroundColor: "#DBE1E6",
  },
}));

const HybridListFilter = ({
  defaultFilter,
  getLocalisationMessage,
  onFilterChange,
  onRequestClose,
  open,
}) => {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    user_id: defaultFilter.user_id,
    customer_id: defaultFilter.customer_id,
    from_date_time: defaultFilter.from_date_time,
    to_date_time: defaultFilter.to_date_time,
  });
  const handleDateChange = (key, value) =>
    setFilter({ ...filter, [key]: formatDateTimeToUrl(value) });
  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={onRequestClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogHeaderContainer}>
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage("filter")}
          </div>
        </div>
      </DialogTitle>
      <FlexBox align="center" container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FlexBox gutter={16} flex={true}>
                  <FlexBox flex={true}>
                    <SimpleAutocomplete
                      mappingData={item => ({
                        ...item,
                        name: item.full_name,
                      })}
                      variant={STANDARD}
                      initialId={filter.user_id}
                      getById={getOpertatorById}
                      chooseAPI={operatorChoose}
                      onChange={v => setFilter({ ...filter, user_id: v.id })}
                      fullWidth={true}
                      label={getLocalisationMessage("operator")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <SimpleAutocomplete
                      variant={STANDARD}
                      replaceName="full_name"
                      initialId={filter.customer_id}
                      getById={getCustomerById}
                      chooseAPI={getCustomersChooseHybrid}
                      onChange={v =>
                        setFilter({ ...filter, customer_id: v.id })
                      }
                      fullWidth={true}
                      label={getLocalisationMessage("customer")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <SimpleAutocomplete
                      variant={STANDARD}
                      initialId={filter.to_warehouse_id}
                      onChange={v =>
                        setFilter({ ...filter, to_warehouse_id: v.id })
                      }
                      getById={getWarehouseById}
                      chooseAPI={warehouseChoose}
                      fullWidth={true}
                      label={getLocalisationMessage("to_warehouse")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <KeyboardDateTimePicker
                      fullWidth={true}
                      ampm={false}
                      ampmInClock={true}
                      variant={OUTLINED}
                      format={DATE_TIME_SHORT_FORMAT}
                      id="date-picker-inline"
                      label={getLocalisationMessage("from_date")}
                      value={filter.from_date_time}
                      onChange={v => handleDateChange("from_date_time", v)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      cancelLabel={
                        <CustomButton color={SECONDARY}>Cancel</CustomButton>
                      }
                      okLabel={
                        <CustomButton color={SECONDARY}>OK</CustomButton>
                      }
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <KeyboardDateTimePicker
                      fullWidth={true}
                      ampm={false}
                      ampmInClock={true}
                      format={DATE_TIME_SHORT_FORMAT}
                      id="date-picker-inline"
                      label={getLocalisationMessage("to_date")}
                      value={filter.to_date_time}
                      onChange={v => handleDateChange("to_date_time", v)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      cancelLabel={
                        <CustomButton color={SECONDARY}>Cancel</CustomButton>
                      }
                      okLabel={
                        <CustomButton color={SECONDARY}>OK</CustomButton>
                      }
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
              <CardActions>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} justify="flex-end">
                    <Button onClick={() => onRequestClose()}>
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </Button>
                    <Button
                      onClick={() => {
                        onFilterChange(filter);
                        onRequestClose();
                      }}
                    >
                      {getLocalisationMessage("submit", "Submit")}
                    </Button>
                  </FlexBox>
                </FlexBox>
              </CardActions>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Dialog>
  );
};
HybridListFilter.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  defaultFilter: PropTypes.object,
};

export default enhancer(HybridListFilter);
