import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { AddCircle, Cancel, RemoveCircle } from "@material-ui/icons";
import { red, lightGreen } from "@material-ui/core/colors";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormSelectField from "../form/FormSelectField";
import FormCustomerChips from "../form/FormCustomerChips";
import FormSupplierChips from "../form/FormSupplierChips";
import FormCityFilterAutoComplete from "../form/FromCityFilterAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import {
  formatText,
  safeParseDate,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";
import { isValidDate } from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseIntString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  SAVER,
  URGENT,
  NEXT_DAY,
  SAME_DAY,
  STANDARD,
} from "../../constants/CourierTypes";
import deliveryTypes, {
  PICK_UP,
  DELIVERY,
} from "../../constants/DeliveryTypes";

const styles = {
  chipAutoComplete: {
    chipContainer: {
      maxHeight: "96px",
      overflowY: "auto",
    },
  },
  subheader: { flex: "1 1 0%", paddingLeft: "0px" },
};

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const startTime = startOfToday();
const endTime = endOfToday();

const courierTypeOptions = OrderedSet.of(
  null,
  SAVER,
  URGENT,
  NEXT_DAY,
  SAME_DAY,
  STANDARD,
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    includeButton: { paddingBottom: "12px" },
    toggle: {
      marginTop: "13px",
      whiteSpace: "nowrap",
      "& label": { color: "#FFF" },
    },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        const includeCustomers = Boolean(
          x.getValue("customerIds") || !x.getValue("excludeCustomerIds"),
        );

        const isPickup = x.getBoolValue("is_pick_up") ? PICK_UP : DELIVERY;

        return {
          serviceType: x.getValue("courier_type"),
          cityId: { id: x.getIntegerValue("city_id") || null },
          isPickUp: isPickup,
          supplierIds: idsToObjectArray(x.getValue("supplierIds")),
          fromDateTime: safeParseDate(x.getValue("from_date_time")),
          toDateTime: safeParseDate(x.getValue("to_date_time")),

          includeCustomers,
          customerIds: includeCustomers
            ? idsToObjectArray(x.getValue("customerIds"))
            : idsToObjectArray(x.getValue("excludeCustomerIds")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            is_pick_up: values.isPickUp === PICK_UP,
            city_id: getObjectId(values.cityId) || null,
            customerIds: null,
            includeCustomers: null,
            excludeCustomerIds: null,
            supplierIds: null,
            courier_type: values.serviceType,
            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),
          });

          if (!fp.isEmpty(values.customerIds)) {
            if (values.includeCustomers) {
              filter.setValue(
                "customerIds",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "excludeCustomerIds",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.supplierIds)) {
            filter.setValue(
              "supplierIds",
              stringifyArray(values.supplierIds.map(getObjectId)),
            );
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    enableReinitialize: true,
    form: "NeighborhoodsFilterForm",
  }),
  formValues("toDateTime", "fromDateTime", "includeCustomers"),
);

NeighborhoodsFilterForm.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  includeCustomers: PropTypes.bool,

  toDateTime: PropTypes.instanceOf(Date),
  fromDateTime: PropTypes.instanceOf(Date),

  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getCachedCustomer: PropTypes.func,
  getCustomerPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function NeighborhoodsFilterForm(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <FlexBox container={8} flex="none">
      <FlexBox gutter={8} flex={true} direction="column">
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true} direction="column">
                {Boolean(
                  props.getSupplierPredictions || props.getCustomerPredictions,
                ) && (
                  <FlexBox flex={true}>
                    <FlexBox gutter={8} flex={true} justify="flex-end">
                      {props.getSupplierPredictions && (
                        <FlexBox
                          flex={true}
                          direction="column"
                          justify="flex-end"
                        >
                          <FormSupplierChips
                            name="supplierIds"
                            fullWidth={true}
                            label={getLocalisationMessage(
                              "suppliers",
                              "Suppliers",
                            )}
                          />
                        </FlexBox>
                      )}

                      {props.getCustomerPredictions && (
                        <FlexBox flex={true}>
                          <FlexBox
                            flex={true}
                            direction="column"
                            justify="flex-end"
                          >
                            <FormCustomerChips
                              fullWidth={true}
                              name="customerIds"
                              label={
                                props.includeCustomers
                                  ? getLocalisationMessage("include_customers")
                                  : getLocalisationMessage("exclude_customers")
                              }
                              chipContainerStyle={
                                styles.chipAutoComplete.chipContainer
                              }
                            />
                          </FlexBox>

                          <FlexBox
                            align="flex-end"
                            className={classes.includeButton}
                          >
                            <IconButton
                              onClick={() =>
                                props.change(
                                  "includeCustomers",
                                  !props.includeCustomers,
                                )
                              }
                            >
                              {props.includeCustomers ? (
                                <AddCircle color={lightGreen[600]} />
                              ) : (
                                <RemoveCircle color={red[500]} />
                              )}
                            </IconButton>
                          </FlexBox>
                        </FlexBox>
                      )}
                    </FlexBox>
                  </FlexBox>
                )}

                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormSelectField
                        fullWidth={true}
                        name="serviceType"
                        options={courierTypeOptions}
                        formatOption={x =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                        label={getLocalisationMessage(
                          "service_type",
                          "Service Type",
                        )}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormCityFilterAutoComplete
                        name="cityId"
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_here_to_search",
                          "Type Here To Search",
                        )}
                        label={getLocalisationMessage("city", "City")}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormSelectField
                        fullWidth={true}
                        name="isPickUp"
                        options={deliveryTypes}
                        formatOption={x =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                        label={getLocalisationMessage("options", "Options")}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true} direction="column">
                        <FormDateField
                          fullWidth={true}
                          name="fromDateTime"
                          hintText={getLocalisationMessage(
                            "from_date",
                            "From Date",
                          )}
                          initialTime={startTime}
                        />
                      </FlexBox>

                      <FlexBox flex={true} direction="column">
                        <FormTimeField
                          fullWidth={true}
                          name="fromDateTime"
                          hintText={getLocalisationMessage(
                            "from_time",
                            "From Time",
                          )}
                        />
                      </FlexBox>

                      {Boolean(isValidDate(props.fromDateTime)) && (
                        <FlexBox align="center">
                          <IconButton
                            onClick={() => props.change("fromDateTime", null)}
                          >
                            <Cancel />
                          </IconButton>
                        </FlexBox>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormDateField
                        fullWidth={true}
                        name="toDateTime"
                        hintText={getLocalisationMessage("to_date", "To Date")}
                        initialTime={endTime}
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormTimeField
                        fullWidth={true}
                        name="toDateTime"
                        hintText={getLocalisationMessage("to_time", "To Time")}
                      />
                    </FlexBox>

                    {Boolean(isValidDate(props.toDateTime)) && (
                      <FlexBox align="center">
                        <IconButton
                          onClick={() => props.change("toDateTime", null)}
                        >
                          <Cancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox justify="flex-end">
          <CardActions>
            <Button
              onClick={() => {
                fp.keys(props.initialValues).forEach(key => {
                  props.change(key, null);
                });
              }}
            >
              {getLocalisationMessage("clear", "Clear")}
            </Button>

            {props.dirty ? (
              <Button onClick={props.reset}>
                {getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}
            <Button onClick={props.handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </CardActions>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NeighborhoodsFilterForm);
