import React from "react";
import Immutable from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import ZoneListMap from "../maps/ZoneListMap";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { parseMultipolygon } from "../../helpers/MapPolygonUtils";
import { getMapsStream } from "../../helpers/GoogleMapsHelper";

const enhancer = compose(
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(getMapsStream(), (props, maps) => ({
          ...props,
          maps,
        })),
      propsStream => {
        const zonesStream = propsStream
          .distinctUntilKeyChanged("neighborhoods", isEqualData)
          .map(props => {
            const { maps: { LatLng, LatLngBounds } } = props;

            return props.neighborhoods.map(item => {
              const polygon = parseMultipolygon(item.get("wkt"));

              const center =
                polygon.length === 0
                  ? null
                  : polygon[0]
                      .reduce(
                        (acc, x) => acc.extend(new LatLng(x)),
                        new LatLngBounds(),
                      )
                      .getCenter();

              return item.delete("wkt").merge(
                Immutable.fromJS({
                  polygon,
                  center: { lat: center.lat(), lng: center.lng() },
                }),
              );
            });
          });

        return propsStream.combineLatest(zonesStream, (props, zones) => ({
          ...props,
          zones,
        }));
      },
    ),
  ),
);

NeighborhoodsMap.propTypes = {
  zones: PropTypes.instanceOf(Immutable.List),

  children: PropTypes.node,

  activeId: PropTypes.number,
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,

  neighborhoods: PropTypes.instanceOf(Immutable.List).isRequired,
};

function NeighborhoodsMap(props) {
  return (
    <ZoneListMap
      zones={props.zones}
      activeId={props.activeId}
      onActivate={props.onActivate}
      onDeactivate={props.onDeactivate}
    >
      {props.children}
    </ZoneListMap>
  );
}

export default enhancer(NeighborhoodsMap);
