import axios from "axios";
import Cookies from "js-cookie";
import { Observable } from "rxjs";
import { MONGO_API_HOST } from "../../shared/constants/ApiConstants";

// const MONGO_SERVER_URL = "http://localhost:3050/realtime";
const MONGO_SERVER_URL = `${MONGO_API_HOST}/realtime`;
// const MONGO_SERVER_URL = "https://sorting.pochta.uz/realtime";
// const MONGO_SERVER_URL = "https://dev-sorting.pochta.uz/realtime";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";

export default class MongoApi {
  constructor() {
    this.cookie = Cookies.get("mongo_token");
  }

  request(url: string, { headers, ...options }) {
    return Observable.from(
      axios.request({
        ...options,
        url,
        baseURL: MONGO_SERVER_URL,
        headers: {
          ...headers,
          "x-access-token": this.cookie,
        },
      }),
    );
  }

  get(url, options) {
    return this.request(url, {
      ...options,
      method: GET,
    });
  }

  post(url, options) {
    return this.request(url, {
      ...options,
      method: POST,
    });
  }

  put(url, options) {
    return this.request(url, {
      ...options,
      method: PUT,
    });
  }

  delete(url, options) {
    return this.request(url, {
      ...options,
      method: DELETE,
    });
  }
}
