import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../../helpers/DataUtils";
import { pipeStreams } from "../../../helpers/StreamUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { toMarketplaceFilter } from "../../../helpers/MarketplaceFilterMapper";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { SETTINGS_MARKETPLACES_URL } from "../../../constants/AdminPathConstants";
import { getMarketplacesList } from "../../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import MarketplacesList from "../../../components/marketplaces-core/MarketplacesList";
import MarketplaceListTabs from "../../../components/marketplaces-core/MarketplaceListTabs";
import { updateHash } from "../../../../shared/helpers/UrlUtils";
import AdminMarketplaceFilterWrapper from "../../../wrappers/admin/marketplace/AdminMarketplaceFilterWrapper";
import AdminMarketplaceCreateDialogWrapper from "../../../wrappers/admin/marketplace/AdminMarketplaceCreateDialogWrapper";

const MARKETPLACE_FILTER_DIALOG_HASH = "#MFDH";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toMarketplaceFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getMarketplacesList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response => fromJS(response))
          .map(response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminBankListContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
};

function AdminBankListContainer(props) {
  const { getLocalisationMessage } = props;
  // const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      title={getLocalisationMessage("marketplaces", "Marketplaces")}
      // appBarRightAction={
      //   <div className={classes.appBarRightAction}>
      //     <Toggle
      //       accent={true}
      //       label={getLocalisationMessage("fast_search", "Fast Search")}
      //       value={useSolr}
      //       className={classes.appBarRightActionToggle}
      //       onChange={value =>
      //         props.setLocationQueryFilter(
      //           props.filter.setValue("use_solr", value),
      //         )
      //       }
      //     />
      //   </div>
      // }
    >
      <AdminMarketplaceCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminMarketplaceFilterWrapper
        open={props.location.hash === MARKETPLACE_FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />

      <MarketplaceListTabs
        filter={props.filter}
        basePathname={SETTINGS_MARKETPLACES_URL}
      />

      <MarketplacesList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        location={props.location}
        cardActionIcons={
          <div>
            <Tooltip
              title={getLocalisationMessage(
                "create_new_marketplace",
                "Create New Marketplace",
              )}
            >
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <Add />
              </IconButton>
            </Tooltip>

            <Link
              to={updateHash(props.location, MARKETPLACE_FILTER_DIALOG_HASH)}
            >
              <Tooltip
                title={getLocalisationMessage(
                  "marketplace_filter",
                  "Marketplace Filter",
                )}
              >
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminBankListContainer);
