import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getSMSProviderFields } from "../../api/admin/AdminSMSApi";
import SMSProviderWrapper from "../../wrappers/admin/SMSProviderWrapper";
import EmailSettingsWrapper from "../../wrappers/admin/EmailSettingsWrapper";
import TelegramProviderWrapper from "../../wrappers/admin/TelegramProviderWrapper";
import WhatsappProviderWrapper from "../../wrappers/admin/WhatsappProviderWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Tabs from "../../components/ui-core/Tabs";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";

const SMS_SETTINGS = "sms_settings";
const WHATSAPP = "whatsapp";
const TELEGRAM = "telegram";
export const EMAIL = "email";

const tabs = [SMS_SETTINGS, WHATSAPP, TELEGRAM, EMAIL];

const enhancer = compose(
  useSheet({
    root: { margin: "12px auto" },
    container: {
      maxWidth: "1000px",
      minWidth: "800px",
      "& > div": {
        display: "flex",
        flex: "1 1 0%",
      },
    },
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  withContext(
    {
      getSMSProviderFields: PropTypes.func,
    },
    () => ({
      getSMSProviderFields,
    }),
  ),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    pipeStreams(propsStream => {
      const filterStream = propsStream
        .map(fp.flow(fp.get("location.query"), DataListFilter.create))
        .distinctUntilChanged(isEqualData);

      return propsStream
        .combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }))
        .distinctUntilChanged(isEqualData);
    }),
  ),
);

AdminSMSSettingsContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  providerList: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  activeProvider: PropTypes.instanceOf(Map),
  isLoadingRatingTypes: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  tab: PropTypes.oneOf(tabs),
  onTabChange: PropTypes.func.isRequired,
};
AdminSMSSettingsContainer.defaultProps = {
  tab: SMS_SETTINGS,
};

function AdminSMSSettingsContainer(props) {
  const { classes, getLocalisationMessage, tab } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "communication_settings",
        "Communication Settings",
      )}
    >
      <PageLoading isLoading={props.isLoading || false} />
      <Tabs
        width={300}
        value={tab}
        onChange={(e, v) => props.onTabChange(v)}
        tabs={[
          {
            label: getLocalisationMessage("sms_settings", "SMS Settings"),
            value: SMS_SETTINGS,
          },
          {
            label: getLocalisationMessage("whatsapp", "Whatsapp"),
            value: WHATSAPP,
          },
          // {
          //   label: getLocalisationMessage("telegram", "Telegram"),
          //   value: TELEGRAM,
          // },
          {
            label: getLocalisationMessage("email", "Email"),
            value: EMAIL,
          },
        ]}
      />
      <FlexBox
        container={8}
        flex={true}
        direction="column"
        className={classes.root}
      >
        {tab === SMS_SETTINGS && <SMSProviderWrapper />}
        {tab === WHATSAPP && <WhatsappProviderWrapper />}
        {tab === TELEGRAM && <TelegramProviderWrapper />}
        {tab === EMAIL && <EmailSettingsWrapper />}
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminSMSSettingsContainer);
