import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadFinanceReports } from "../../actions/AdminFinanceReportActions";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { toCommunicationFilter } from "../../helpers/CommunicationFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminFinanceReportForm from "../../components/admin/AdminFinanceReportForm";
import AdminFinanceReportTable from "../../components/admin/AdminFinanceReportTable";

const enhancer = compose(
  getContext({ setLocationQuery: PropTypes.func, router: PropTypes.object }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      state,
    }),
    { loadFinanceReports },
  ),
  mapPropsStream((propsStream: Observable) => {
    const { handler: onSubmit, stream: onSubmitStream } = createEventHandler();

    const loadReportStream = onSubmitStream
      .withLatestFrom(propsStream)
      .switchMap(([values, props]) =>
        props
          .loadFinanceReports({
            date: formatDateToUrl(values.date),
            report_type: values.reportType,
          })
          .catch(() => Observable.of({})),
      )
      .map(response => fromJS(response).get("payload", Map()))
      .startWith(null);

    const filterStream: Observable = propsStream
      .pluck("location", "query")
      .distinctUntilChanged(isEqualData)
      .map(toCommunicationFilter)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        loadReportStream,
        ({ ...props }, filter, reportList) => ({
          ...props,
          filter,
          onSubmit,
          reportList,
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminFinanceReportContainer.propTypes = {
  router: PropTypes.object,
  location: PropTypes.object,
  reportList: PropTypes.instanceOf(Map),
  setLocationQuery: PropTypes.func,
  onFilterTypeChange: PropTypes.func,
  loadFinanceReports: PropTypes.func,
  onSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminFinanceReportContainer(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage("financial_reports", "Financial Reports")}
      style={{ minHeight: "100px" }}
    >
      <AdminFinanceReportForm onSubmit={data => props.onSubmit(data)} />
      {props.reportList && (
        <AdminFinanceReportTable reportList={props.reportList} />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminFinanceReportContainer);
