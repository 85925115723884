import { Observable } from "rxjs";
import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  MAP_KEY,
  IOS_MAP_KEY,
  PLACE_API_KEY,
  ANDROID_MAP_KEY,
  GEO_CODING_API_KEY,
  DISTANCE_MATRIX_KEY,
} from "../../constants/MapSettingsConstants";
import {
  saveMapProvider,
  getMapProviderList,
  getMapProviderTypes,
  getActiveMapProvider,
  changeActiveMapProvider,
  getMapProviderKeysTypes,
} from "../../api/admin/AdminMapApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import MapSettingsForm from "../../components/settings-core/MapSettingsForm";

const enhancer = compose(
  useSheet({
    root: { alignItems: "center" },
    container: {
      maxWidth: "1000px",
      minWidth: "800px",
      "& > div": {
        display: "flex",
        flex: "1 1 0%",
      },
    },
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  withContext(
    {
      getMapProviderList: PropTypes.func,
      getMapProviderTypes: PropTypes.func,
      getMapProviderKeysTypes: PropTypes.func,
      saveMapProvider: PropTypes.func,
      getActiveMapProvider: PropTypes.func,
    },
    () => ({
      getMapProviderList,
      getMapProviderTypes,
      getMapProviderKeysTypes,
      saveMapProvider,
      getActiveMapProvider,
    }),
  ),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), DataListFilter.create))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const providerListStream = propsStream
          .first()
          .switchMap(() =>
            getMapProviderTypes()
              .map(fp.flow(fp.get("payload.data"), List))
              .map(value => value.delete(2)) // There is deleted 2Gis Provider
              .catch(error => Observable.of({ error })),
          )
          .startWith(List());

        return propsStream
          .combineLatest(providerListStream, (props, providerList) => ({
            ...props,
            providerList,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminMapSettingsContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  providerList: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  activeProvider: PropTypes.instanceOf(Map),
  isLoadingRatingTypes: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminMapSettingsContainer(props) {
  const { classes, getLocalisationMessage, providerList } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage("map_settings", "Map Settings")}
    >
      <PageLoading isLoading={props.isLoading || false} />

      <FlexBox
        container={8}
        flex={true}
        direction="column"
        className={classes.root}
      >
        <FlexBox element={<Card className={classes.container} />} gutter={8}>
          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox
              style={{ padding: "1rem" }}
              gutter={8}
              flex={true}
              direction="column"
            >
              <MapSettingsForm
                providers={providerList || {}}
                onSubmit={values => {
                  saveMapProvider({
                    map_geo_provider_type: values.providerType.description,
                    map_geo_provider_keys: [
                      {
                        key: values.placeApiKey,
                        map_geo_provider_key_type: PLACE_API_KEY,
                      },
                      {
                        key: values.mapKey,
                        map_geo_provider_key_type: MAP_KEY,
                      },
                      {
                        key: values.iosMapKey,
                        map_geo_provider_key_type: IOS_MAP_KEY,
                      },
                      {
                        key: values.androidMapKey,
                        map_geo_provider_key_type: ANDROID_MAP_KEY,
                      },
                      {
                        key: values.distanceMatrixKey,
                        map_geo_provider_key_type: DISTANCE_MATRIX_KEY,
                      },
                      {
                        key: values.geoCodingApiKey,
                        map_geo_provider_key_type: GEO_CODING_API_KEY,
                      },
                    ],
                  }).catch(ResponseError.throw);
                  if (values.active)
                    changeActiveMapProvider(
                      values.providerType.description,
                    ).catch(ResponseError.throw);
                }}
                onSubmitSuccess={() => {
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_saved",
                      "Successfully Saved",
                    ),
                  );
                }}
                onSubmitFail={props.showErrorMessage}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminMapSettingsContainer);
