import React from "react";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormFileField from "../form/FormFileField";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import { createFileValidator } from "../../helpers/FormUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import PageLoading from "../../components/ui-core/PageLoading";

const valueSelector = formValueSelector("BankSettingsUploadForm");

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

const enhancer = compose(
  reduxForm({
    form: "BankSettingsUploadForm",
    enableReinitialize: true,
  }),
  connect(state => ({
    values: valueSelector(state, "bank", "type"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withState("submitting", "onSubmitting", false),
);

BankAndTypeForm.propTypes = {
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function BankAndTypeForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <Card>
      <PageLoading isLoading={props.submitting} />
      <CardContent>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormTextField
              type="number"
              fullWidth={true}
              name="headerRow"
              label={getLocalisationMessage("header_row", "Header Row")}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormFileField
              name="uploadFile"
              fullWidth={true}
              accept={CSV_FILE_TYPES}
              label={getLocalisationMessage("select_file", "Select File")}
              validate={createFileValidator(
                getLocalisationMessage(
                  "please_select_file",
                  "Please Select File",
                ),
              )}
            />
          </FlexBox>
        </FlexBox>
      </CardContent>
      <FlexBox justify="flex-end">
        <CardActions>
          <Button type="submit" onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </Card>
  );
}

export default enhancer(BankAndTypeForm);
