import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import ScannerTextField from "../deprecated/ScannerTextField";
import { pureComponent } from "../../helpers/HOCUtils";
import { cleanupStringArray } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const parseOrderNumbers = fp.flow(
  fp.trim,
  x => x.replace(/\W/g, " "),
  // fp.split(" "),
  cleanupStringArray,
);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    checkbox: { whiteSpace: "nowrap" },
    input: { "& input": { fontSize: "20px" } },
    scanner: { flexDirection: "column" },
    "@media (min-width: 998px)": {
      scanner: { flexDirection: "row" },
    },
  }),
  withHandlers({
    handleSubmit: props => value => {
      const orderNumbers = value.split(",");
      props.onSubmit(parseOrderNumbers(orderNumbers[0]));
    },
  }),
  pureComponent(fp.pick(["autoAssign", "focusInput"])),
);

OrderSortingBinValidationForm.propTypes = {
  classes: PropTypes.object,

  autoAssign: PropTypes.bool,
  onAutoAssignChange: PropTypes.func.isRequired,

  handleSubmit: PropTypes.func,
  focusInput: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderSortingBinValidationForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox gutter={8} className={classes.scanner}>
      <FlexBox flex={true}>
        <ScannerTextField
          fullWidth={true}
          variant="outlined"
          label={getLocalisationMessage("add_orders", "Add orders")}
          focus={props.focusInput}
          className={classes.input}
          id="OrderSortingBinValidationFormScannerTextField"
          onChange={props.handleSubmit}
        />
      </FlexBox>

      <FlexBox align="center">
        <FlexBox align="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={props.autoAssign}
                className={classes.checkbox}
                onChange={(e, x) => props.onAutoAssignChange(x)}
              />
            }
            label={getLocalisationMessage(
              "assign_to_warehouse",
              "Assign To Warehouse",
            )}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderSortingBinValidationForm);
