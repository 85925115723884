import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { updateCashier, getCashierItem } from "../../api/admin/AdminFinanceApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CashierForm from "../../components/finance-core/CashierForm";

const enhancer = compose(
  renderIf(props => props.cashierId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    paper: { minWidth: "400px", maxWidth: "400px" },
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("cashierId")
      .switchMap(props =>
        getCashierItem(props.cashierId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        cashier: response.get("payload"),
        isLoading: response.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminCashierEditDialogWrapper.propTypes = {
  classes: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  cashierId: PropTypes.number.isRequired,
  cashier: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  isLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminCashierEditDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      title={`${getLocalisationMessage("cashier", "Cashier")} #${
        props.cashierId
      }`}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox direction="column">
        <CashierForm
          readOnlySelectedFields={true}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          onDismiss={props.onRequestClose}
          initialValues={toCamelCase(props.cashier)}
          onSubmit={values =>
            updateCashier(toSnakeCase(values)).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_updated",
                "Successfully Updated",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminCashierEditDialogWrapper);
