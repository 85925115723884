import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { PDF_SETTINGS_URL } from "../../constants/AdminPathConstants";
import PdfOrientationCodes from "../../constants/PdfOrientationCodes";
import {
  getTemplateTypes,
  createPdfTemplate,
} from "../../api/admin/AdminPdfSettingsApi";
import {
  getMarketplaceCache,
  getMarketplacePredictions,
} from "../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminPdfTemplateForm from "../../components/admin/AdminPdfTemplateForm";
import FlexBox from "../../components/ui-core/FlexBox";
import { responsive } from "../../../shared/theme/jss-responsive";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  useSheet({
    appBarRightAction: { height: "100%" },
    appBarRightActionButton: {
      marginTop: "8px",
      color: "#fff",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  getContext({ setLocation: PropTypes.func }),
  mapPropsStream(
    pipeStreams(propsStream => {
      const typeStream = propsStream
        .first()
        .switchMap(() =>
          getTemplateTypes()
            .map(fp.flow(fp.get("payload.data"), List))
            .catch(error => Observable.of({ error })),
        )
        .startWith(List());

      return propsStream
        .combineLatest(typeStream, (props, types) => ({
          ...props,
          types,
        }))
        .distinctUntilChanged(isEqualData);
    }),
  ),
);

AdminPdfTemplateCreate.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  types: PropTypes.instanceOf(List),
  defaultAttributes: PropTypes.instanceOf(List),
};

function AdminPdfTemplateCreate(props) {
  const { types, classes, getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage("create_new_pdf", "Create New PDF")}
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Link to={PDF_SETTINGS_URL}>
            <Button className={classes.appBarRightActionButton}>
              {getLocalisationMessage("go_to_list", "Go To List")}
            </Button>
          </Link>
        </FlexBox>
      }
    >
      <AdminPdfTemplateForm
        types={types}
        initialValues={{
          orientation: "portrait",
        }}
        getMarketplaceCache={getMarketplaceCache}
        getMarketplacePredictions={getMarketplacePredictions}
        orientationCodes={PdfOrientationCodes}
        onSubmit={({ marketplaceId, ...values }) =>
          createPdfTemplate({
            ...toSnakeCase(values),
            marketplace_id: _.get(marketplaceId, "id"),
            marketplace_name: _.get(marketplaceId, "name"),
          }).catch(ResponseError.throw)
        }
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.setLocation(PDF_SETTINGS_URL);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_created",
              "Successfully created",
            ),
          );
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPdfTemplateCreate);
