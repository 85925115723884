import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import sprintf from "sprintf";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SHOP_ADDRESS_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedVenueCity,
  getVenueCityPredictions,
} from "../../api/admin/AdminVenueCityApi";
import {
  getAddressItem,
  updateAddressItem,
} from "../../api/admin/AdminShopAddressApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminVenueAddressForm from "../../components/admin/AdminVenueAddressForm";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  connect(null, { showErrorMessage, showSuccessMessage }),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const venueIdStream = propsStream.map(
          fp.flow(fp.get("params.venueId"), fp.toFinite),
        );

        const addressIdStream = propsStream.map(
          fp.flow(fp.get("params.addressId"), fp.toFinite),
        );

        return propsStream
          .combineLatest(
            venueIdStream,
            addressIdStream,
            (props, venueId, addressId) => ({
              ...props,
              venueId,
              addressId,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const addressItemResponseStream = propsStream
          .distinctUntilKeyChanged("addressId")
          .switchMap(props =>
            getAddressItem(props.venueId, props.addressId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            addressItemResponseStream,
            (props, addressItemResponse) => ({
              ...props,
              addressItem: addressItemResponse.get("payload"),
              isLoading: addressItemResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminVenueAddressItem.propTypes = {
  venueId: PropTypes.number,
  addressId: PropTypes.number,
  addressItem: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
};

function AdminVenueAddressItem(props) {
  return (
    <AdminAppLayout
      title={`Address - ${props.addressItem.get("contact_name", "")}`}
    >
      <PageLoading isLoading={props.isLoading} />
      <AdminVenueAddressForm
        onDismissClick={() =>
          props.setLocation(sprintf(SHOP_ADDRESS_LIST_URL, props.venueId))
        }
        onSubmit={({ location, ...values }) =>
          updateAddressItem(
            props.venueId,
            props.addressId,
            toSnakeCase({
              ...values,
              venue_address: true,
              locationType: "HOME",
              preferredDeliveryTime: "9:00AM - 1:00PM",
              ownerId: props.venueId,
              address: _.get(location, "address"),
              lat: _.get(location, "lat"),
              lon: _.get(location, "lng"),
            }),
          ).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.setLocation(sprintf(SHOP_ADDRESS_LIST_URL, props.venueId));
        }}
        initialValues={{
          ...toCamelCase(props.addressItem),
          status: _.lowerCase(props.addressItem.get("status")),
          location: {
            lat: props.addressItem.get("lat"),
            lng: props.addressItem.get("lon"),
            address: props.addressItem.get("address"),
          },
        }}
        getCachedVenueCity={getCachedVenueCity}
        getVenueCityPredictions={getVenueCityPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminVenueAddressItem);
