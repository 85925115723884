import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, withState, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { adminFilterReport } from "../../helpers/ReportsHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { toReportListFilter } from "../../helpers/ReportListMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  SCORE_RANKING,
  DRIVER_ATTENDANCE,
  SHIPMENTS_CREATED,
  SHIPMENTS_PENDING,
  SHIPMENTS_DELIVERED,
  PENDING_COD_SHIPMENT,
  COD_RECEIVABLES_AGING,
  DAILY_CALL_PERFORMANCE,
} from "../../constants/OperationalReportTypes";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import { getOperationalReports } from "../../api/admin/AdminReportApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import ScoreRankingReport from "../../components/reports-core/ScoreRankingReport";
import OperationalReportForm from "../../components/reports-core/OperationalReportForm";
import DriverAttendanceReport from "../../components/reports-core/DriverAttendanceReport";
import ShipmentsPendingReport from "../../components/reports-core/ShipmentsPendingReport";
import CODReceivablesAgingReport from "../../components/reports-core/CODReceivablesAgingReport";
import PendingCODPerDriverReport from "../../components/reports-core/PendingCODPerDriverReport";
import DailyCallPerformanceReport from "../../components/reports-core/DailyCallPerformanceReport";
import ShipmentsDeliveredAndCreatedReport from "../../components/reports-core/ShipmentsDeliveredAndCreatedReport";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_DRIVER_REPORT_URL,
  CREATE_DAILY_CALL_REPORT_URL,
  CREATE_RECEIVABNLE_AGING_REPORT_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withState("showBy", "onChangeShowBy", null),
  withState("groupBy", "onChangeGroupBy", null),
  withState("callsBy", "onChangeCallsBy", null),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(
            fp.flow(
              fp.get("location.query"),
              fp.set("size", 200),
              toReportListFilter,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const reportListResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .filter(props => adminFilterReport(props.filter))
          .switchMap(props =>
            getOperationalReports(props.filter).catch(error =>
              Observable.of({ error }),
            ),
          )
          .withLatestFrom(propsStream)
          .map(([data, props]) => ({
            ...data,
            reportType: props.filter.getValue("reportType"),
          }))
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            reportListResponseStream,
            (props, reportListResponse) => ({
              ...props,
              isLoading: reportListResponse.get("pending"),
              reportList: reportListResponse.get("payload"),
              listReportType: reportListResponse.get("reportType"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminOperationalReportContainer.propTypes = {
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  reportList: PropTypes.instanceOf(Map),
  groupBy: PropTypes.string,
  showBy: PropTypes.string,
  onChangeGroupBy: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  onChangeShowBy: PropTypes.func,
  listReportType: PropTypes.string,
  onChangeCallsBy: PropTypes.func,
  callsBy: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminOperationalReportContainer(props) {
  const { location, filter, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "operational_reports",
        "Operational Reports",
      )}
    >
      <PageLoading isLoading={props.isLoading} />

      <FlexBox container={8} flex={true}>
        <FlexBox gutter={16} flex={true} direction="column">
          <FlexBox flex="none">
            <OperationalReportForm
              showDownloadReport={
                (filter.getValue("reportType") === DRIVER_ATTENDANCE ||
                  filter.getValue("reportType") === COD_RECEIVABLES_AGING ||
                  filter.getValue("reportType") === DAILY_CALL_PERFORMANCE) &&
                props.reportList.size > 0 &&
                props.listReportType === filter.getValue("reportType")
              }
              withoutDate={
                filter.getValue("reportType") === COD_RECEIVABLES_AGING ||
                filter.getValue("reportType") === DRIVER_ATTENDANCE ||
                filter.getValue("reportType") === SCORE_RANKING
              }
              getDriverPredictions={getDriverPredictions}
              getCachedDriver={getCachedDriver}
              getCachedSupplier={getCachedSupplier}
              getSupplierPredictions={getSupplierPredictions}
              operationalReportTypes={OrderedSet.of(
                SHIPMENTS_PENDING,
                SHIPMENTS_DELIVERED,
                SHIPMENTS_CREATED,
                PENDING_COD_SHIPMENT,
                DRIVER_ATTENDANCE,
                DAILY_CALL_PERFORMANCE,
                SCORE_RANKING,
                COD_RECEIVABLES_AGING,
              )}
              reportType={filter.getValue("reportType")}
              withSupplier={filter.getValue("reportType") === DRIVER_ATTENDANCE}
              withDriver={filter.getValue("reportType") === DRIVER_ATTENDANCE}
              showFilter={
                filter.getValue("reportType") &&
                (filter.getValue("reportType") !== SHIPMENTS_CREATED &&
                  filter.getValue("reportType") !== SHIPMENTS_DELIVERED)
              }
              downloadReportUrl={
                filter.getValue("reportType") === DRIVER_ATTENDANCE
                  ? updateQuery(CREATE_DRIVER_REPORT_URL, {
                      driver_ids: props.filter.getValue("driver_ids"),
                      supplier_ids: props.filter.getValue("supplier_ids"),
                      from_date: props.filter.getValue("from_date"),
                    })
                  : filter.getValue("reportType") === DAILY_CALL_PERFORMANCE
                    ? updateQuery(CREATE_DAILY_CALL_REPORT_URL, {
                        from_date: props.filter.getValue("from_date"),
                        to_date: props.filter.getValue("to_date"),
                      })
                    : updateQuery(CREATE_RECEIVABNLE_AGING_REPORT_URL, {
                        reportType: COD_RECEIVABLES_AGING,
                      })
              }
              onChangeReportType={reportType => {
                props.setLocationQueryFilter(
                  filter.setValueMap({
                    reportType,
                    from_date: null,
                    to_date: null,
                    group_by: null,
                    filter_by: null,
                    report_by: null,
                    show_by: null,
                    supplier_ids: null,
                    driver_ids: null,
                    only_dedicated: null,
                  }),
                );
              }}
              onChangeReport={() => {
                props.onChangeGroupBy(location.query.group_by);
                props.onChangeShowBy(location.query.show_by);
              }}
              onChangeFilter={values => {
                switch (filter.getValue("reportType")) {
                  case SHIPMENTS_PENDING:
                    props.setLocationQueryFilter(
                      filter.setValueMap({
                        group_by: values.groupBy,
                        filter_by: values.filterBy,
                        to_date: formatDateToUrl(values.toDate),
                        from_date: formatDateToUrl(values.fromDate),
                      }),
                    );
                    break;

                  case PENDING_COD_SHIPMENT:
                    props.setLocationQueryFilter(
                      filter.setValueMap({
                        to_date: formatDateToUrl(values.toDate),
                        from_date: formatDateToUrl(values.fromDate),
                      }),
                    );
                    break;

                  case DRIVER_ATTENDANCE:
                    props.setLocationQueryFilter(
                      filter.setValueMap({
                        driver_ids: !fp.isEmpty(values.driverIds)
                          ? stringifyArray(values.driverIds.map(getObjectId))
                          : null,
                        supplier_ids: !fp.isEmpty(values.supplierIds)
                          ? stringifyArray(values.supplierIds.map(getObjectId))
                          : null,
                      }),
                    );
                    break;

                  case DAILY_CALL_PERFORMANCE:
                    props.onChangeCallsBy(values.callsBy);

                    props.setLocationQueryFilter(
                      filter.setValueMap({
                        to_date: formatDateToUrl(values.toDate),
                        from_date: formatDateToUrl(values.fromDate),
                      }),
                    );
                    break;

                  case SCORE_RANKING:
                    props.setLocationQueryFilter(
                      filter.setValueMap({
                        filter_by: values.filterBy,
                      }),
                    );
                    break;

                  default:
                  // Default Case
                }
              }}
            />
          </FlexBox>

          {filter.getValue("reportType") && (
            <FlexBox flex={true}>
              {filter.getValue("reportType") === SHIPMENTS_PENDING && (
                <ShipmentsPendingReport
                  reportList={props.reportList}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.filter.getValue("from_date") &&
                      props.filter.getValue("to_date") &&
                      props.filter.getValue("group_by") &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                  reportType={filter.getValue("reportType")}
                />
              )}

              {filter.getValue("reportType") === SHIPMENTS_DELIVERED && (
                <ShipmentsDeliveredAndCreatedReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.filter.getValue("from_date") &&
                      props.filter.getValue("to_date") &&
                      props.filter.getValue("group_by") &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                  groupBy={props.groupBy}
                  showBy={props.showBy}
                  onChangeFilter={values =>
                    props.setLocationQueryFilter(
                      filter.setValueMap({
                        show_by: values.showBy,
                        group_by: values.groupBy,
                        filter_by: values.filterBy,
                        report_by: values.reportBy,
                        to_date: formatDateToUrl(values.toDate),
                        from_date: formatDateToUrl(values.fromDate),
                      }),
                    )
                  }
                />
              )}

              {filter.getValue("reportType") === SHIPMENTS_CREATED && (
                <ShipmentsDeliveredAndCreatedReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.filter.getValue("from_date") &&
                      props.filter.getValue("to_date") &&
                      props.filter.getValue("group_by") &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                  groupBy={props.groupBy}
                  showBy={props.showBy}
                  onChangeFilter={values =>
                    props.setLocationQueryFilter(
                      filter.setValueMap({
                        show_by: values.showBy,
                        group_by: values.groupBy,
                        report_by: values.reportBy,
                        to_date: formatDateToUrl(values.toDate),
                        from_date: formatDateToUrl(values.fromDate),
                      }),
                    )
                  }
                />
              )}

              {filter.getValue("reportType") === PENDING_COD_SHIPMENT && (
                <PendingCODPerDriverReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType") &&
                      props.filter.getValue("from_date") &&
                      props.filter.getValue("to_date"),
                  )}
                />
              )}

              {filter.getValue("reportType") === DRIVER_ATTENDANCE && (
                <DriverAttendanceReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  groupBy={props.groupBy}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                  showBy={props.showBy}
                  onChangeMonth={({ date }) => {
                    props.setLocationQueryFilter(
                      filter.setValue("from_date", formatDateToUrl(date)),
                    );
                  }}
                />
              )}

              {filter.getValue("reportType") === DAILY_CALL_PERFORMANCE && (
                <DailyCallPerformanceReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  fromDate={props.filter.getValue("from_date")}
                  toDate={props.filter.getValue("to_date")}
                  callsBy={props.callsBy}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.filter.getValue("from_date") &&
                      props.filter.getValue("to_date") &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                />
              )}

              {filter.getValue("reportType") === SCORE_RANKING && (
                <ScoreRankingReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                />
              )}

              {filter.getValue("reportType") === COD_RECEIVABLES_AGING && (
                <CODReceivablesAgingReport
                  reportList={props.reportList}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                />
              )}
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOperationalReportContainer);
