import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SupplierContactsFilterForm from "../../components/suppliers-contacts-core/SupplierContactsFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    modal: {
      maxWidth: "450px",
      minWidth: "450px",
      minHeight: "auto",
    },
  }),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

AdminSupplierContactsFilterWrapper.propTypes = {
  state: PropTypes.object,
  classes: PropTypes.object,
  setState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
};

function AdminSupplierContactsFilterWrapper(props) {
  const { getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={props.open}
      title={getLocalisationMessage(
        "supplier_contacts_filter",
        "Supplier Contacts Filter",
      )}
      onRequestClose={props.onRequestClose}
      paperClassName={props.classes.modal}
    >
      <FlexBox container={8} direction="column">
        <SupplierContactsFilterForm
          filter={props.filter}
          onDismiss={props.onRequestClose}
          onFilterChange={props.onFilterChange}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminSupplierContactsFilterWrapper);
