import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, FieldArray } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import {
  createPercentValidator,
  createNotEmptyValidator,
} from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import DayOfWeek from "../../constants/DayOfWeek";

const getPercent = scores => {
  let score = 0;

  if (fp.isArray(scores)) {
    scores.forEach(item => {
      score += fp.toFinite(item.weight);
    });
  }

  return score;
};

const checkPercent = percent => percent >= 0 && percent <= 100;

const ScoresData = props => (
  <FlexBox gutter={8} flex={true} direction="column">
    {props.fields.map((scoreData, index) => (
      <FlexBox gutter={8} flex={true} key={index}>
        <FlexBox flex={true} align="center">
          {props.fields.get(index).ratingTypeDisplayName}
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <FormTextField
            name={`${scoreData}.weight`}
            parseOnBlur={fp.toFinite}
            validate={createPercentValidator(
              props.getLocalisationMessage(
                "enter_range_of_numbers",
                "Enter Range of Numbers 0-100",
              ),
            )}
          />
        </FlexBox>

        <FlexBox flex={true} justify="center">
          <FormSelectField
            name={`${scoreData}.dayOfWeekRangeEnd`}
            options={DayOfWeek}
            formatOption={x =>
              props.getLocalisationMessage(x.toLocaleLowerCase(), formatText)
            }
            validate={createNotEmptyValidator(
              props.getLocalisationMessage(
                "select_week_day",
                "Select Week Day",
              ),
            )}
          />
        </FlexBox>
      </FlexBox>
    ))}
  </FlexBox>
);

ScoresData.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    overall: { height: "64px" },
    error: { color: "red" },
  }),
  reduxForm({
    form: "ScoreSettingsForm",
    enableReinitialize: true,
  }),
  formValues("scores"),
);

ScoreSettingsForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  scores: PropTypes.array,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ScoreSettingsForm(props) {
  const { classes, getLocalisationMessage } = props;
  const percent = getPercent(props.scores);
  const percentError = checkPercent(percent);

  return (
    <FlexBox
      gutter={8}
      flex={true}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox>
        <FlexBox flex={true} gutter={8}>
          <FlexBox justify="center" flex={true}>
            {getLocalisationMessage("kpis", "KPIs")}
          </FlexBox>

          <FlexBox justify="center" flex={true}>
            {getLocalisationMessage("weight", "Weight")}
          </FlexBox>

          <FlexBox justify="center" flex={true}>
            {getLocalisationMessage("day_of_week", "Day Of Week")}
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <FieldArray
          getLocalisationMessage={getLocalisationMessage}
          component={ScoresData}
          props="props"
          name="scores"
        />
      </FlexBox>

      <FlexBox className={classes.overall} align="center">
        <FlexBox flex={true}>
          {getLocalisationMessage("overall_score", "Overall Score")}
        </FlexBox>
        <FlexBox flex={true} justify="center">
          {percent} %
        </FlexBox>
        <FlexBox flex={true} className={classes.error}>
          {!percentError &&
            getLocalisationMessage(
              "overall_scores_must_be_range_of_numbers",
              "Overall Scores Must Be Range of Numbers 0-100",
            )}
        </FlexBox>
      </FlexBox>

      <FlexBox>
        <Button disabled={!percentError} type="submit">
          {getLocalisationMessage("save", "Save")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ScoreSettingsForm);
