import { OrderedSet } from "immutable";

export const ON_HOLD = "ON_HOLD";
export const FUTURE_DELIVERY_TOMORROW = "FUTURE_DELIVERY_TOMORROW";
export const FUTURE_DELIVERY_FUTURE = "FUTURE_DELIVERY_FUTURE";
export const FUTURE_DELIVERY_UNSORTED = "FUTURE_DELIVERY_UNSORTED";
export const RTO = "RTO";

export default OrderedSet.of(
  ON_HOLD,
  FUTURE_DELIVERY_TOMORROW,
  FUTURE_DELIVERY_FUTURE,
  FUTURE_DELIVERY_UNSORTED,
  RTO,
);
