import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import FormOperatorAutoComplete from "../form/FormOperatorAutoComplete";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  withContext(
    { getCachedDriver: PropTypes.func, getDriverPredictions: PropTypes.func },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  reduxForm({
    form: "OperatorLiabilityFilterForm",
    enableReinitialize: true,
  }),
);

OperatorLiabilityFilterForm.propTypes = {
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OperatorLiabilityFilterForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox
      style={{ minWidth: "200px", maxWidth: "200px" }}
      element={<form />}
    >
      <FormOperatorAutoComplete
        name="operator"
        fullWidth={true}
        hintText={getLocalisationMessage(
          "type_to_search",
          "Type to search ...",
        )}
        label={getLocalisationMessage("operator", "Operator")}
      />
    </FlexBox>
  );
}

export default enhancer(OperatorLiabilityFilterForm);
