import React, { useContext } from "react";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withHandlers,
  withState,
  lifecycle,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FormWarehouseDialog from "../../components/form/FormWarehouseDialog";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { Map, Set } from "immutable";
import {
  addBatchSortingTaskOrderNumber,
  clearOrderSortingBatchOrders,
  getOrderSortingTask,
  getOrderSortingTaskBatchRootOrder,
  updateCursorOrder,
  updateSortingTask,
} from "../../reducers/OrderInboundSortingReducer";
import { mergeSideEffectStreams, pipeStreams } from "../../helpers/StreamUtils";
import { OrderBinValidationDB as RethinkOrderBinValidationDb } from "../../realtimeDb/OrderBinValidationDB";
import OrderSortingBinValidationForm from "../../components/order-inbound-sorting/OrderSortingBinValidationForm";
import { Card, CardContent, CardHeader, MenuItem } from "@material-ui/core";
import { Observable } from "rxjs";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import FlexBox, {
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import fp from "lodash/fp";
import OrderSortingBinDetailsCard from "../../components/order-inbound-sorting/OrderSortingBinDetailsCard";
import OrderSortingBinHeirarchyCard from "../../components/order-inbound-sorting/OrderSortingBinHeirarchyCard";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import {
  batchBarcodesStatusUpdate,
  getBarcode,
  getBatchChildren,
  updateRegistryStatusSorting,
} from "../../api/admin/AdminOrderApi";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  IN_SORTING_FACILITY,
  // LOST_OR_DAMAGED,
  // MISROUTED,
  // PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import { green, grey, orange, red } from "@material-ui/core/colors";
import { CheckCircle, HourglassEmpty, ReportProblem } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import OrderInboundSortingVerifyOrdersDialog from "../../components/order-inbound-sorting/OrderInboundSortingVerifyOrdersDialog";
import {
  getUserWarehouse,
  getUserWarehouseId,
} from "../../reducers/ProfileReducer";
import { validateUserWarehouse } from "../../helpers/OrderSortingHelper";
import { fetchMongoToken, MONGO_DB_URL } from "../../realtimeDb/MongoDBSDK";
// eslint-disable-next-line import/no-internal-modules
import io from "socket.io-client";
import _ from "lodash";
import { GlobalContext } from "../shared/ClientApp";
import { SUCCESS } from "../../components/orders-core/AdminPostForm";

// import { OrderSortingDB as RethinkOrderSortingDb } from "../../realtimeDb/OrderSortingDB";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  bottomSpace: {
    marginBottom: 8,
    minHeight: 230,
    "@media (max-width: 991px)": {
      minHeight: 350,
    },
  },
  iconsWrapper: {
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  card: {
    "@media (max-width: 991px)": {
      marginBottom: 15,
      flex: "none",
    },
  },
  overallInfo: {
    "@media (max-width: 991px)": {
      flexDirection: "column",
      "& div > span": {
        display: "none",
      },
    },
  },
}));

// eslint-disable-next-line no-unused-vars
export const shouldOpen = (order, warehouseId) => {
  if (order.get("type", null) === "CONTAINER") return true;

  const toWarehouseId = order.getIn(["to_warehouse", "id"]);

  if (toWarehouseId) return toWarehouseId === warehouseId;

  return order.getIn(["warehouse", "id"]) === warehouseId;
};

// const changeCursorOrder = ({
//   db,
//   id,
//   cursor,
//   assignToWarehouse,
//   getLocalisationMessage,
//   ...props
// }) =>
//   Observable.defer(() => db.getBatchQueue())
//     .take(1)
//     .switchMap((queueOrders) => {
//       // let taskStream = db.removeTask(id);
//
//       if (queueOrders.size > 0) {
//         const firstQueue = queueOrders.keySeq().toSet().first();
//         props.updateCursorOrder(firstQueue);
//
//         props.showSuccessMessage(
//           `${cursor} ${getLocalisationMessage(
//             "has_been_finished_moved_to",
//             "has been finished. Moved to",
//           )} ${firstQueue}`,
//         );
//
//         // if (assignToWarehouse) {
//         //   return Observable.merge(
//         //     db.updateBatchStatus(
//         //       cursor,
//         //       {
//         //         warehouse_id: props.warehouseId,
//         //         status: IN_SORTING_FACILITY,
//         //       },
//         //       false,
//         //     ),
//         //     db.removeTask(id),
//         //     db.removeBatchQueue(firstQueue),
//         //   );
//         // }
//         return Observable.merge(
//           db.removeTask(id),
//           db.removeBatchQueue(firstQueue),
//         );
//       }
//
//       props.showSuccessMessage(
//         getLocalisationMessage(
//           "the_validation_process_has_been_finished",
//           "The Validation Process has been finished.",
//         ),
//       );
//       props.updateCursorOrder(null);
//
//       // if (assignToWarehouse) {
//       //   return Observable.merge(
//       //     db.removeTask(id),
//       //     db.updateBatchStatus(
//       //       cursor,
//       //       {
//       //         warehouse_id: props.warehouseId,
//       //         status: IN_SORTING_FACILITY,
//       //       },
//       //       false,
//       //     ),
//       //   );
//       // }
//
//       return db.removeTask(id);
//     });

// const generatedStatus = (order) => {
//   if (fp.get("wrong_location", order)) return MISROUTED;
//
//   if (!fp.get("scanned", order)) return LOST_OR_DAMAGED;
//
//   if (!fp.get("info.type", order)) return IN_SORTING_FACILITY;
//
//   return PREPARED_FOR_TRANSIT;
// };

// const forceCompleteTask = (props) => {
//   const { db, id, task, sortingDB } = props;
//
//   const order = fp.get("forceComplete.details.order", task);
//   const assignToWarehouse = fp.get("forceComplete.assignToWarehouse", task);
//   const statuses = Map().asMutable();
//
//   if (!assignToWarehouse)
//     return changeCursorOrder({
//       ...props,
//       db,
//       id,
//       cursor: order.number,
//       assignToWarehouse,
//     });
//
//   const children = fp.get("children", order);
//   const sortedShipments = {};
//
//   if (children) {
//     children.forEach((child) => {
//       const status = generatedStatus(child);
//
//       if (status) {
//         if (!statuses.has(status)) {
//           statuses.set(
//             status,
//             Map({
//               status,
//               barcodes: Set(),
//               ids: Set(),
//             }),
//           );
//         }
//
//         if (status === IN_SORTING_FACILITY) {
//           sortedShipments[`${child.number}/number`] = fp.get(
//             "info.barcode",
//             child,
//           );
//         }
//
//         statuses.updateIn([status, "barcodes"], (x) => x.add(child.number));
//         statuses.updateIn([status, "ids"], (x) => x.add(child.id));
//       }
//     });
//   }
//
//   const statusesArray = statuses.asMutable().valueSeq().toSet();
//
//   return Observable.defer(() =>
//     statusesArray.size > 0
//       ? batchBarcodesStatusUpdate(statusesArray.toJS()).catch((err) =>
//           Observable.of(err),
//         )
//       : Observable.of(null),
//   )
//     .switchMap((response) => {
//       if (!fp.isError(response) && sortedShipments) {
//         return Observable.merge(sortingDB.batchUpdateOrders(sortedShipments));
//       }
//
//       return Observable.of(null);
//     })
//     .switchMap(() =>
//       changeCursorOrder({
//         ...props,
//         db,
//         id,
//         assignToWarehouse,
//         cursor: order.number,
//       }),
//     )
//     .catch(() => db.removeTask(id));
// };

let socketConnection = false;

const shipmentStatusUpdate = (props) => {
  const { db, id, task } = props;

  // return Observable.defer(() =>
  //   batchUpdateOrderWarehouse(task.shipment_status_update),
  // )
  //   .switchMap(() => db.removeTask(id))
  //   .catch(() => db.removeTask(id));

  return Observable.defer(() =>
    batchBarcodesStatusUpdate([
      {
        status: fp.get("order_status", task.shipment_status_update),
        barcodes: fp.get("order_barcodes", task.shipment_status_update),
      },
    ]),
  )
    .switchMap(() => db.removeTask(id))
    .catch(() => db.removeTask(id));
};

const batchStatusUpdate = (props) => {
  const { db, id, task, orderNumber } = props;

  const body = task.registry_status_update;

  return Observable.defer(() =>
    updateRegistryStatusSorting({
      items: [{ ...body, barcodes: [orderNumber] }],
    }),
  )
    .switchMap(() => Observable.merge(db.removeTask(id)))
    .catch(() => db.removeTask(id));
};

// const getBatchChildrenTask = (props) => {
//   const { db, id, order } = props;
//   return Observable.defer(() => getBatchChildren(order.getIn(["info", "id"])))
//     .switchMap(({ children }) => {
//       const values = {};
//       const parentValue = {};
//       if (children) {
//         // TODO: code should be changed to barcode
//
//         const values = {};
//
//         children.forEach((child) => {
//
//           values[`${child.barcode}/number`] = child.barcode;
//           values[`${child.barcode}/id`] = child.id;
//           values[`${child.barcode}/parent_id`] = order.get("number");
//         });
//         parentValue[`${order.get("number")}/child_count`] = children.length;
//       }
//       return Observable.merge(
//         db.removeTask(id),
//         db.updateBatchOrders(parentValue),
//         db.updateBatchOrders(values),
//       );
//     })
//     .catch((error) => db.updateTask(id, { error: error.message }));
// };

const getBatchChildrenTaskRethink = (props) => {
  const { db, order } = props;
  // console.log("order11111", order)
  return Observable.defer(() => getBatchChildren(order.info.id))
    .switchMap(({ children }) => {
      const values = [];
      // console.log("order11111", order)
      if (children) {
        // TODO: code should be changed to barcode
        children.forEach((child) => {
          values.push({
            number: child.barcode,
            id: child.id,
            parent_id: order.number,
            isSuccess: child.status === SUCCESS,
          });
        });
        order.child_count = children.length;
      }

      return db
        .removeTask("number", order.number)
        .mergeMap(() => db.updateOrder(order.number, order))
        .mergeMap(() => db.updateBatchOrders(values));
    })
    .catch((error) => db.updateTask(order.number, { error: error.message }));
};

const enhancer = compose(
  withTheme,
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  withState("shouldOpenBarcode", "setShouldOpenBarcode", null),
  withState("socket", "setSocket", null),
  withState("rethinkDb", "setRethinkDb", null),
  withState("scannedBarcode", "setScannedBarcode", null),
  lifecycle({
    componentWillUnmount() {
      if (this.props && this.props.socket) {
        this.props.socket.disconnect();
      }
      this.props.setSocket(undefined);
      this.props.setRethinkDb(undefined);
      socketConnection = false;
    },
  }),
  connect(
    (state) => {
      const task = getOrderSortingTask(state);
      const userWarehouse = toJS(getUserWarehouse(state));
      return {
        task,
        userWarehouse,
        userWarehouseId: getUserWarehouseId(state),
        warehouseId: task.getIn(["warehouse", "id"]),
        cursorOrder: getOrderSortingTaskBatchRootOrder(state),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      updateSortingTask,
      addBatchSortingTaskOrderNumber,
      updateCursorOrder,
      clearOrderSortingBatchOrders,
    },
  ),
  withHandlers({
    scanBarcodesRethinkDb:
      (props) =>
      ({ orderNumbers, expected, assignToWarehouse }) =>
        getBarcode(orderNumbers[0])
          .takeLast(1)
          .let(mapObjectResponseStream)
          .map((x) => x.get("payload"))
          .switchMap((order) => {
            // console.log("order", order.toJS())
            const db = props.rethinkDb;
            const orderNumber = orderNumbers[0];
            const values = {
              scanned: true,
              number: orderNumber,
              failed: null,
              info: order.toJS(),
              hash: order.hashCode(),
              hash_time: Date.now(),
            };

            if (!order || !order.has("id")) {
              // values[`${orderNumber}/failed`] = true;
              values.failed = true;
            }

            if (!order.has("type") && order.has("id") && !expected) {
              // values[`${orderNumber}/unexpected`] = true;
              values.unexpected = true;
            }
            // console.log("batch update")
            // Add the Order to the Firebase
            const taskStream = db.updateBatchOrders([values]);
            // Check the order is BATCH
            if (order && order.has("type")) {
              // Check if the batch should be opened
              //   console.log("should open")
              if (!shouldOpen(order, props.warehouseId) && assignToWarehouse) {
                // const unOpenedBatch = {
                //   number: orderNumber
                // };
                // unOpenedBatch[`${orderNumber}/number`] = orderNumber;
                //   console.log("in should open")
                return taskStream.merge(
                  db.updateRegistryStatus(
                    orderNumber,
                    {
                      warehouse: { id: props.warehouseId },
                      status: IN_SORTING_FACILITY,
                    },
                    true,
                  ),
                  // sortingDB.batchUpdateOrders(unOpenedBatch),
                );
              }

              // Get Batch Child Orders
              //   console.log("children")
              // localStorage.setItem("currentOrderNumber", orderNumber);
              return taskStream.merge(db.addBarcodeChildren(orderNumber));
            }

            if (!order.has("type") && order.has("id") && expected) {
              // const sortedShipments = [{
              //     number: orderNumber
              // }]
              const sortedShipments = {
                // number: orderNumber
              };
              sortedShipments[`${orderNumber}/number`] = orderNumber;
              // It is shipment and expected order

              return taskStream.merge(
                db.updateShipmentStatus(orderNumber, {
                  order_numbers: [orderNumber],
                  order_status: IN_SORTING_FACILITY,
                  warehouse: { id: props.warehouseId },
                }),

                db.batchUpdateOrders(sortedShipments),
              );
            }
            // console.log("before common return")
            return taskStream;
          }),
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const {
          handler: onOrderSubmitRethink,
          stream: onOrderSubmitRethinkStream,
        } = createEventHandler();

        const rethinkDbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .filter((props) => props.warehouseId > 0)
          .mergeMap(({ warehouseId }) =>
            Observable.from(fetchMongoToken(warehouseId)),
          )
          .withLatestFrom(propsStream)
          .mergeMap(([token, props]) => {
            let retinkDB;
            if (!socketConnection) {
              socketConnection = true;
              return new Observable((emit) => {
                // if (props.socket && props.socket.connected) {
                //   props.socket.disconnect();
                // }
                props.setSocket(true);
                const socket = io(
                  `${MONGO_DB_URL}?token=${token}&page=inbound`,
                  {
                    path: "/websocket/socket.io",
                    autoConnect: true,
                    transports: ["websocket"],
                    reconnect: true,
                    pingInterval: 30000,
                  },
                );

                socket.on("connect_error", (e) => {
                  // eslint-disable-next-line no-console
                  console.log("connect_error", e);
                  socketConnection = false;
                });
                socket.on("connect", () => {
                  // eslint-disable-next-line no-console
                  console.log("connected to the socket outbound");
                  retinkDB = new RethinkOrderBinValidationDb(socket);
                  props.setSocket(socket);
                  props.setRethinkDb(retinkDB);
                  emit.next(retinkDB);
                });
                socket.on("disconnect", (reason) => {
                  // eslint-disable-next-line no-console
                  console.log("socket disconnect, ", reason);
                });
              });
            }
            return Observable.of(retinkDB);
          })
          .startWith(undefined);

        const sideEffects = propsStream
          .filter((props) => props.rethinkDb && props.warehouseId > 0)
          .distinctUntilKeyChanged("warehouseId")
          .switchMap((props) =>
            mergeSideEffectStreams(
              onOrderSubmitRethinkStream.do((request) => {
                props.addBatchSortingTaskOrderNumber(Set(request.orderNumbers));
              }),
              onOrderSubmitRethinkStream.mergeMap(({ orderNumbers }) => {
                const rethinkDbOrders = orderNumbers.map((orderNumber) => ({
                  number: orderNumber,
                  scanned: true,
                }));

                return props.rethinkDb.updateBatchOrders(rethinkDbOrders);
              }),
              onOrderSubmitRethinkStream.switchMap((request) =>
                props
                  .scanBarcodesRethinkDb(request)
                  .take(1)
                  .switchMap(() =>
                    request.cursorOrder
                      ? props.rethinkDb.checkBatchCompleted(
                          request.cursorOrder,
                          {
                            assignToWarehouse: request.assignToWarehouse,
                          },
                        )
                      : Observable.of({}),
                  ),
              ),
            ),
          )
          .startWith(Map());

        const rethinkDbInitialState = {
          rethinkTasks: [],
          rethinkOrders: Map(),
          rethinkTree: Map(),
          rethinkQueue: Map(),
          rethinkScannedOrders: Map(),
        };

        const rethinkStateStream = rethinkDbStream
          .switchMap((rethinkDb) =>
            rethinkDb
              ? Observable.combineLatest(
                  rethinkDb.getTasks(),
                  rethinkDb.getOrders(),
                  rethinkDb.getBatchTree(),
                  rethinkDb.getBatchQueue(),
                  rethinkDb.getBatchScannedOrders(),
                  (tasks, orders, tree, queue, scannedOrders) => ({
                    rethinkTasks: tasks,
                    rethinkOrders: orders,
                    rethinkTree: tree,
                    rethinkQueue: queue,
                    rethinkScannedOrders: scannedOrders,
                  }),
                ).distinctUntilChanged(isEqualData)
              : Observable.of(rethinkDbInitialState),
          )
          .startWith(rethinkDbInitialState);

        return propsStream.combineLatest(
          // stateStream,
          rethinkStateStream,
          sideEffects,
          rethinkDbStream,
          (props, rethinkState) => ({
            ...props,
            // ...state,
            rethinkOrders: rethinkState.rethinkOrders,
            rethinkTasks: rethinkState.rethinkTasks,
            rethinkScannedOrders: rethinkState.rethinkScannedOrders,
            onOrderSubmitRethink,
          }),
        );
      },

      /**
       * Step 5 - Register side effect workers.
       *
       * 1. Sync removed orders in firebase with reducer.
       * 2. Loads orders without hash.
       * 4. Execute order tasks.
       */
      (propsStream) => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(
              (props) => Boolean(props.rethinkDb) && props.warehouseId > 0,
            )
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) =>
              mergeSideEffectStreams(
                props.rethinkDb.onTaskUpdateStream().mergeMap((response) => {
                  // console.log("ttt", response)
                  const task = (response || []).pop();
                  // console.log("ttt1", task)
                  // console.log("task", task)
                  // Remove task if it's corrupted.
                  if (!task) {
                    return Observable.of();
                  }
                  const orderNumber = task.number;

                  return props.rethinkDb
                    .getBatchOrder(orderNumber)
                    .take(1)
                    .switchMap((orderList) => {
                      // console.log("orderlist", orderList)
                      if (!orderList.length) {
                        return props.rethinkDb.removeTask(
                          "number",
                          orderNumber,
                        );
                      }

                      const order = orderList[0];

                      if (task.children) {
                        return getBatchChildrenTaskRethink({
                          ...props,
                          db: props.rethinkDb,
                          order,
                        });
                      }

                      if (task.registry_status_update) {
                        return batchStatusUpdate({
                          ...props,
                          db: props.rethinkDb,
                          task,
                          orderNumber,
                        });
                      }

                      if (task.shipment_status_update) {
                        return shipmentStatusUpdate({
                          ...props,
                          db: props.rethinkDb,
                          task,
                        });
                      }

                      // console.log("task.forceComplete", task.forceComplete)
                      // if (task.forceComplete) {
                      //   return forceCompleteTask({
                      //     ...props,
                      //     db,
                      //     task,
                      //     sortingDB,
                      //   });
                      // }

                      // If it's unknown task - remove it.
                      return props.rethinkDb.removeTask("number", orderNumber);
                    });
                }, 5),
              ),
            ),
        );

        return propsStream.merge(sideEffectsStream);
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "userWarehouse",
      "userWarehouseId",
      "userWarehousesIds",
      "warehouseId",
      "location",
      "state",
      "task",
      // "cursorOrder",
      "cursorOrder",
      "scannedBarcode",
      "orders",
      "rethinkOrders",
      "rethinkTasks",
      "rethinkTree",
      "rethinkQueue",
      "rethinkScannedOrders",
      "tree",
      "queue",
      "shouldOpenBarcode",
    ]),
  ),
);

AdminInboundSortingContainer.propTypes = {
  warehouseId: PropTypes.number,
  userWarehouseId: PropTypes.number,
  shouldOpenBarcode: PropTypes.string,
  scannedBarcode: PropTypes.string,
  cursorOrder: PropTypes.string,
  // cursorOrder: PropTypes.string,

  userWarehousesIds: PropTypes.array,

  task: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),
  setRethinkDb: PropTypes.func,
  rethinkDb: PropTypes.any,
  rethinkOrders: PropTypes.instanceOf(Map),
  rethinkScannedOrders: PropTypes.instanceOf(Map),
  rethinkTasks: PropTypes.instanceOf(Map),
  rethinkQueue: PropTypes.instanceOf(Map),
  onOrderSubmitRethink: PropTypes.func,
  tree: PropTypes.instanceOf(Map),
  // cursorChildOrders: PropTypes.instanceOf(Map),
  scannedOrders: PropTypes.instanceOf(Map),
  queue: PropTypes.instanceOf(Map),

  onOrderSubmit: PropTypes.func,
  updateSortingTask: PropTypes.func,
  clearOrderSortingBatchOrders: PropTypes.func,

  setLocationQuery: PropTypes.func,
  setShouldOpenBarcode: PropTypes.func,
  setScannedBarcode: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,

  userWarehouse: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object,
};

function AdminInboundSortingContainer(props) {
  const { setUW } = useContext(GlobalContext);
  const classes = useStyles();
  const {
    getLocalisationMessage,
    task,
    location: { query },
    rethinkDb,
  } = props;

  if (!validateUserWarehouse(props.warehouseId, props.userWarehousesIds)) {
    return (
      <FormWarehouseDialog
        open={true}
        isDisabled={!fp.size(props.userWarehousesIds) > 1}
        initialValues={{ warehouse: props.userWarehouse }}
        includeWarehouses={props.userWarehousesIds}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        onSubmit={(values) => {
          setUW(_.get(values, "warehouse.id"));
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  const activeBatchActiveOrderNumber = task.get("activeBatchActiveOrderNumber");
  // const db = new OrderBinValidationDB(props.warehouseId);
  // const orderSortingDB = new OrderSortingDB(props.warehouseId);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("inbound_sorting", "Inbound Sorting")}
      appBarRightAction={
        <FlexBox>
          {props.rethinkOrders.size > 0 && (
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("finish", "Finish")}
            </CustomButton>
          )}

          <MenuButtonMore color={props.theme.palette.appBarTextColor}>
            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("remove_all", true))}
            >
              {getLocalisationMessage("remove_all", "Remove All")}
            </MenuItem>

            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("log_out_warehouse", "Log Out Warehouse")}
            </MenuItem>
          </MenuButtonMore>
        </FlexBox>
      }
    >
      {query.remove_all === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask((t) => t.set("inProgress", false));

            return rethinkDb
              .removeBatchOrders()
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() => props.setLocationQuery(fp.unset("remove_all")));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_all_data",
            "Are you sure you want to remove all data?",
          )}
        </ConfirmDialog>
      )}

      {props.scannedBarcode && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setScannedBarcode(null)}
          onConfirm={() => {
            props.setScannedBarcode(null);

            return props.onOrderSubmitRethink({
              orderNumbers: [props.scannedBarcode],
              expected: props.rethinkOrders.has(props.scannedBarcode),
              assignToWarehouse: task.get("autoAssign"),
            });
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_are_working_on_correct_warehouse",
            "Are you sure you are working on correct Warehouse?",
          )}
        </ConfirmDialog>
      )}

      {props.shouldOpenBarcode && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setShouldOpenBarcode(null)}
          onConfirm={() => {
            localStorage.setItem("currentOrderNumber", props.shouldOpenBarcode);
            return props.rethinkDb
              .addBarcodeChildren(props.shouldOpenBarcode)
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() =>
                props.setLocationQuery(props.setShouldOpenBarcode(null)),
              );
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_open_registry",
            "Are you sure you want to open Registry?",
          )}
        </ConfirmDialog>
      )}

      {query.log_out === "true" && props.rethinkOrders.size === 0 && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask((x) => x.clear());

            return rethinkDb
              .removeBatchOrders()
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() => props.setLocationQuery(fp.unset("log_out")));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      {query.log_out === "true" && props.rethinkOrders.size > 0 && (
        <OrderInboundSortingVerifyOrdersDialog
          open={true}
          orders={props.rethinkOrders}
          warehouseId={props.warehouseId}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={({ statuses, dbOrders }) =>
            batchBarcodesStatusUpdate(statuses)
              .catch(ResponseError.throw)
              .then(() => {
                props.clearOrderSortingBatchOrders();
                props.updateSortingTask((t) => t.set("inProgress", false));
                return rethinkDb
                  .removeBatchOrders()
                  .mergeMap(() => rethinkDb.batchUpdateOrders(dbOrders))
                  .toPromise()
                  .catch(ResponseError.throw)
                  .finally(() => props.setLocationQuery(fp.unset("log_out")));
              })
          }
        />
      )}
      {task.get("inProgress", false) || props.rethinkOrders.size > 0 ? (
        <FlexBox container={8} direction="column" className={classes.root}>
          <FlexBox
            element={<Card />}
            direction="column"
            className={classes.bottomSpace}
          >
            <CardHeader title={<h5>{task.getIn(["warehouse", "name"])} </h5>} />
            <CardContent>
              <FlexBox
                flex={true}
                style={{ marginBottom: "5px", fontSize: 20 }}
              >
                <FlexBox
                  flex={true}
                  className={classes.overallInfo}
                  justify={JUSTIFY_SPACE_AROUND}
                >
                  <div>
                    <strong>
                      {getLocalisationMessage(
                        "scanned_orders",
                        "Scanned Items",
                      )}
                      :
                    </strong>{" "}
                    {props.rethinkScannedOrders &&
                      props.rethinkScannedOrders.size}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                  <div>
                    <strong>
                      {getLocalisationMessage("need_to_scan", "Need to Scan")}:
                    </strong>{" "}
                    {props.rethinkScannedOrders
                      ? props.rethinkOrders.size -
                        props.rethinkScannedOrders.size
                      : props.rethinkOrders.size}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                  <div>
                    <strong>
                      {getLocalisationMessage("total_orders", "Total Items")}
                    </strong>{" "}
                    {props.rethinkOrders.size}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                </FlexBox>
              </FlexBox>

              <OrderSortingBinValidationForm
                focusInput={false}
                onSubmit={(x) => {
                  if (
                    props.warehouseId !== props.userWarehouseId &&
                    !task.get("disableWarningWarehouse")
                  ) {
                    props.setScannedBarcode(x[0]);
                  } else {
                    props.onOrderSubmitRethink({
                      orderNumbers: x,
                      expected: props.rethinkOrders.has(x[0]),
                      assignToWarehouse: task.get("autoAssign"),
                    });
                  }
                }}
                autoAssign={task.get("autoAssign")}
                onAutoAssignChange={(x) =>
                  props.updateSortingTask((t) => t.set("autoAssign", x))
                }
                disableWarningWarehouse={task.get("disableWarningWarehouse")}
                onDisableWarningWarehouse={(x) =>
                  props.updateSortingTask((t) =>
                    t.set("disableWarningWarehouse", x),
                  )
                }
              />

              <FlexBox className={classes.iconsWrapper}>
                <FlexBox>
                  <HourglassEmpty style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("loading", "Loading")}</span>
                </FlexBox>
                <FlexBox>
                  <ReportProblem style={{ color: red[500] }} />
                  <span>{getLocalisationMessage("error", "Error")}</span>
                </FlexBox>
                <FlexBox>
                  <ReportProblem style={{ color: orange[500] }} />
                  <span>{getLocalisationMessage("warning", "Warning")}</span>
                </FlexBox>
                <FlexBox>
                  <CheckCircle style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("success", "Success")}</span>
                </FlexBox>
                <FlexBox>
                  <CheckCircle style={{ color: grey[500] }} />
                  <span>{getLocalisationMessage("inactive", "Inactive")}</span>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </FlexBox>

          <FlexBox flex={true}>
            <FlexBox
              container={8}
              flex={true}
              xsDirection="column"
              lgDirection="row"
              justify={JUSTIFY_SPACE_AROUND}
            >
              <FlexBox
                direction="column"
                justify={JUSTIFY_SPACE_AROUND}
                flex={true}
                lg={6}
                gutter={8}
                className={classes.card}
                element={<Card />}
              >
                <OrderSortingBinDetailsCard
                  activeOrder={activeBatchActiveOrderNumber}
                  queueOrders={props.rethinkOrders}
                  onReloadClick={(orderNumber) => {
                    if (
                      props.warehouseId !== props.userWarehouseId &&
                      !task.get("disableWarningWarehouse")
                    ) {
                      props.setScannedBarcode(orderNumber);
                    } else {
                      props.onOrderSubmitRethink({
                        orderNumbers: [orderNumber],
                        expected: props.rethinkOrders.has(orderNumber),
                        assignToWarehouse: task.get("autoAssign"),
                      });
                    }
                  }}
                />
              </FlexBox>

              <FlexBox
                direction="column"
                flex={true}
                lg={6}
                gutter={8}
                className={classes.card}
                element={<Card />}
              >
                <FlexBox>
                  <OrderSortingBinHeirarchyCard
                    warehouseId={props.warehouseId}
                    onOpenRegistry={(barcode) => {
                      props.setShouldOpenBarcode(barcode);
                    }}
                    // cursorOrder={props.cursorOrder}
                    // cursorChildOrders={props.cursorChildOrders}
                    orders={props.rethinkOrders}
                    // queueOrders={props.rethinkQueue}
                    cursorOrder={activeBatchActiveOrderNumber}
                    pendingOrdersCount={
                      props.rethinkScannedOrders
                        ? props.rethinkOrders.size -
                          props.rethinkScannedOrders.size
                        : props.rethinkOrders.size
                    }
                    // tree={tree}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox container={8} direction="column" className={classes.root}>
          <FlexBox
            element={<Card />}
            direction="column"
            justify="center"
            className={classes.bottomSpace}
          >
            <FlexBox direction="column" align="center">
              <CardHeader
                title={<h5>{task.getIn(["warehouse", "name"])} </h5>}
              />

              <CustomButton
                variant={CONTAINED}
                color={SECONDARY}
                size="large"
                onClick={() =>
                  props.updateSortingTask((t) => t.set("inProgress", true))
                }
              >
                {getLocalisationMessage(
                  "start_inbound_process",
                  "Start Inbound Process",
                )}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminInboundSortingContainer);
