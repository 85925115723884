import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITableReport, {
  COLUMN,
} from "../../components/orders-core/MUITableReport";
import _ from "lodash";
import { getReportWarehouses } from "../../api/shared/RouteTemplateApi";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import FlexBox, { ALIGN_END } from "../../components/ui-core/FlexBox";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import TransportationType from "../../constants/TransportationType";
import { getServiceTypes2 } from "../../api/admin/AdminServiceTypesApi";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { RotateLeft, Search } from "@material-ui/icons";
import { getJMById, getJMListOnlyUZ } from "../../api/shared/CountryV2Api";
import SimpleAutocomplete from "../../components/form/SimpleAutocomplete";
import { JURISDICTION_UZBEKISTAN } from "../../components/orders-core/AdminPostForm";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

const ReportWarehouse = ({
  getLocalisationMessage,
  showErrorMessage: showErrorMessage1,
}) => {
  const [childrens, setChildrens] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [total, setTotal] = useState(0);
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const date2 = new Date();
  date2.setDate(date2.getDate());
  const defaultFilter = {
    refresh: false,
    from_date: formatDateToUrl(date),
    to_date: formatDateToUrl(date2),
    transportationTypes: "",
    serviceTypes: "",
    parentId: JURISDICTION_UZBEKISTAN.id,
  };
  const [filter, setFilter] = useState(defaultFilter);
  useEffect(() => {
    getServiceTypes2()
      .then((res) => {
        setServiceTypes(_.get(res, "data"));
      })
      .catch((error) => {
        showErrorMessage1(error);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getReportWarehouses(filter)
      .then((res) => {
        setIsLoading(false);
        setList(_.get(res, "data.items"));
        setTotal(_.get(res, "data.items.length"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage1(error);
      });
  }, [filter.refresh]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = (event) =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });
  const handleDateChange = (key, value) => {
    setFilter({ ...filter, [key]: formatDateToUrl(value) });
  };
  return (
    <AdminAppLayout title={getLocalisationMessage("report_warehouse")}>
      <FlexBox
        align={ALIGN_END}
        style={{ padding: "1rem", backgroundColor: "white", gap: "1rem" }}
      >
        <FlexBox>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label={getLocalisationMessage("from_date")}
            value={filter.from_date}
            onChange={(v) => handleDateChange("from_date", v)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel={<CustomButton color={SECONDARY}>Cancel</CustomButton>}
            okLabel={<CustomButton color={SECONDARY}>OK</CustomButton>}
          />
        </FlexBox>
        <FlexBox>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label={getLocalisationMessage("to_date")}
            value={filter.to_date}
            onChange={(v) => handleDateChange("to_date", v)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel={<CustomButton color={SECONDARY}>Cancel</CustomButton>}
            okLabel={<CustomButton color={SECONDARY}>OK</CustomButton>}
          />
        </FlexBox>
        <FlexBox style={{ minWidth: "200px" }}>
          <FormControl fullWidth={true} size="small" variant="outlined">
            <InputLabel>
              {getLocalisationMessage("transportation_type")}
            </InputLabel>
            <Select
              label={getLocalisationMessage("transportation_type")}
              value={_.toLower(filter.transportationTypes)}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  transportationTypes: _.toUpper(e.target.value),
                })
              }
            >
              {TransportationType.map((i) => (
                <MenuItem key={i} value={i}>
                  {getLocalisationMessage(i)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FlexBox>
        <FlexBox style={{ minWidth: "200px" }}>
          <FormControl fullWidth={true} size="small" variant="outlined">
            <InputLabel>{getLocalisationMessage("service_type")}</InputLabel>
            <Select
              label={getLocalisationMessage("service_type")}
              value={filter.serviceTypes}
              onChange={(e) =>
                setFilter({ ...filter, serviceTypes: _.get(e, "target.value") })
              }
            >
              {serviceTypes.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {getLocalisationMessage(i.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FlexBox>
        <FlexBox style={{ minWidth: "200px" }}>
          <SimpleAutocomplete
            size="small"
            initialId={filter.parentId}
            onChange={(v) => setFilter({ ...filter, parentId: v.id })}
            getById={getJMById}
            chooseAPI={getJMListOnlyUZ}
            fullWidth={true}
            label={getLocalisationMessage("jurisdiction")}
          />
        </FlexBox>
        <FlexBox>
          <CustomButton
            variant={OUTLINED}
            color={SECONDARY}
            onClick={() =>
              setFilter({
                ...defaultFilter,
                refresh: !filter.refresh,
              })
            }
            startIcon={<RotateLeft />}
          >
            {getLocalisationMessage("reset")}
          </CustomButton>
        </FlexBox>
        <FlexBox>
          <CustomButton
            disabled={isLoading}
            variant={OUTLINED}
            color={SECONDARY}
            onClick={() => {
              setFilter({ ...filter, refresh: !filter.refresh });
              setChildrens({});
            }}
            startIcon={
              isLoading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <Search />
              )
            }
          >
            {getLocalisationMessage("apply_filter")}
          </CustomButton>
        </FlexBox>
      </FlexBox>
      <FlexBox style={{ backgroundColor: "white" }}>
        <MUITableReport
          childrens={childrens}
          setChildrens={setChildrens}
          filter={filter}
          isLoading={isLoading}
          size="small"
          list={list}
          total={total}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          withCheckbox={false}
          withoutPagination={true}
          columns={[
            {
              type: COLUMN,
              name: "name",
              label: getLocalisationMessage("name"),
            },
            {
              type: COLUMN,
              name: "connected_to_the_program",
              label: getLocalisationMessage("connected_to_the_program"),
            },
            {
              type: COLUMN,
              name: "you_work_in_the_program",
              label: getLocalisationMessage("you_work_in_the_program"),
            },
            {
              type: COLUMN,
              name: "created",
              label: getLocalisationMessage("created"),
            },
            {
              type: COLUMN,
              name: "sorted_report_warehouse",
              label: getLocalisationMessage("sorted_report_warehouse"),
            },
            {
              type: COLUMN,
              name: "boss_delivered_in_comp",
              label: getLocalisationMessage("boss_delivered_in_comp"),
            },
            {
              type: COLUMN,
              name: "delivered_by_postman",
              label: getLocalisationMessage("delivered_by_postman"),
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
};
ReportWarehouse.propTypes = {
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  isRouteCreator: PropTypes.bool,
};

export default enhancer(ReportWarehouse);
