import React from "react";
import Immutable from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import fp from "lodash/fp";
import OrderMarker from "../maps/OrderMarker";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { shouldOrderBeAssigned } from "../../helpers/OrderHelper";
import LVehicleMarker from "../maps-leaflet/LVehicleMarker";
import { Route, FitBounds as LFitBounds } from "react-leflet-map-components";
import { connect } from "react-redux";
import { isMarketplaceBusinessTypeFoodDelivery } from "../../reducers/MarketplaceReducer";

const getOrderPickupLatLng = order => ({
  lat: order.getIn(["sender_data", "lat"]),
  lng: order.getIn(["sender_data", "lon"]),
});
const getOrderDropoffLatLng = order => ({
  lat: order.getIn(["recipient_data", "lat"]),
  lng: order.getIn(["recipient_data", "lon"]),
});

const enhancer = compose(
  connect(state => ({
    isFoodDelivery: isMarketplaceBusinessTypeFoodDelivery(state),
  })),
);

OrderDriverLocationsMapLeaflet.propTypes = {
  orderId: PropTypes.number,
  isLiveStream: PropTypes.bool,
  order: PropTypes.instanceOf(Immutable.Map),
  completeRoute: PropTypes.instanceOf(Immutable.List),
  inCompleteRoute: PropTypes.instanceOf(Immutable.List),
  liveDriverLocations: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.instanceOf(Immutable.OrderedMap),
  ]),
  driverItem: PropTypes.number,
  onDriverClick: PropTypes.func,
  isFoodDelivery: PropTypes.bool,
};

function OrderDriverLocationsMapLeaflet(props) {
  const { order, isLiveStream } = props;
  const pickupLocation = getOrderPickupLatLng(props.order);
  const dropoffLocation = getOrderDropoffLatLng(props.order);

  return (
    <div>
      {isLiveStream &&
        props.liveDriverLocations.map((x, idx) => {
          if (shouldOrderBeAssigned(order)) {
            return (
              <LVehicleMarker
                key={idx}
                onClick={() =>
                  props.onDriverClick(x.getIn(["location", "driver_id"]))
                }
                rotation={x.getIn(["location", "angle"])}
                position={{
                  lat: x.getIn(["location", "lat"]),
                  lng: x.getIn(["location", "lon"]),
                }}
              />
            );
          } else if (
            fp.toFinite(x.getIn(["location", "driver_id"])) ===
            fp.toFinite(order.getIn(["driver", "id"]))
          ) {
            return (
              <LVehicleMarker
                onClick={() =>
                  props.onDriverClick(x.getIn(["location", "driver_id"]))
                }
                key={idx}
                rotation={x.getIn(["location", "angle"])}
                position={{
                  lat: x.getIn(["location", "lat"]),
                  lng: x.getIn(["location", "lon"]),
                }}
              />
            );
          }

          return null;
        })}

      {isValidCoordinates(pickupLocation) &&
        isValidCoordinates(dropoffLocation) && (
          <div>
            <LFitBounds latLngBounds={[pickupLocation, dropoffLocation]} />
            <OrderMarker
              mapType="leaflet_map"
              size={60}
              pickup={false}
              position={pickupLocation}
            />
            <OrderMarker
              mapType="leaflet_map"
              size={60}
              position={dropoffLocation}
            />
            {isLiveStream &&
              props.liveDriverLocations.get(props.driverItem) &&
              props.driverItem && (
                <Route
                  strokeColor="#e93333"
                  strokeWeight={4}
                  strokeOpacity={1}
                  showMarkers={false}
                  origin={{
                    lat: props.liveDriverLocations.getIn([
                      props.driverItem,
                      "location",
                      "lat",
                    ]),
                    lng: props.liveDriverLocations.getIn([
                      props.driverItem,
                      "location",
                      "lon",
                    ]),
                  }}
                  destination={{
                    lat: pickupLocation.lat,
                    lng: pickupLocation.lng,
                  }}
                  path={[]}
                />
              )}
            <Route
              strokeColor="#000055"
              strokeWeight={4}
              strokeOpacity={1}
              showMarkers={false}
              origin={{
                lat: pickupLocation.lat,
                lng: pickupLocation.lng,
              }}
              destination={{
                lat: dropoffLocation.lat,
                lng: dropoffLocation.lng,
              }}
              path={[]}
            />
          </div>
        )}
    </div>
  );
}

export default enhancer(OrderDriverLocationsMapLeaflet);
