import React from "react";
import { fromJS, Map, Set, List } from "immutable";
import fp from "lodash/fp";
import _ from "lodash";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { CardContent, CardHeader, Link } from "@material-ui/core";
import { connect } from "react-redux";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  CheckCircle,
  ChevronRight,
  ExpandMore,
  ReportProblem,
  HourglassEmpty,
} from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { arrayToTree } from "performant-array-to-tree";
import { green, grey, orange, red } from "@material-ui/core/colors";
import cx from "classnames";
import { isEqualData } from "../../helpers/DataUtils";

const parseString = fp.flow(fp.trim, fp.split(","), fp.compact);

const useStyles = makeStyles(() => ({
  hierarchy: { flexDirection: "column", marginBottom: 8 },
  root: {
    height: "100%",
    flexGrow: 1,
    width: "100%",
  },
  fontNormal: {
    fontWeight: 400,
    color: "rgba(38, 50, 56, 0.7)",
  },
  fontWeight: {
    fontWeight: 500,
    color: "#000",
  },
  fontSmallSize: {
    fontSize: 12,
  },
  wrongLocation: {
    color: red[500],
  },
  treeItemLabelContent: {
    lineHeight: "28px",
  },
  treeItemLabel: {
    fontSize: 18,
  },
  treeItemLabelAdd: {
    marginLeft: 10,
    fontSize: 12,
    color: "rgba(38, 50, 56, 0.7)",
  },
  treeItemLabelIcon: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: -5,
    fontSize: 18,
  },
  "@media (min-width: 998px)": {
    hierarchy: { flexDirection: "column" },
  },
}));

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} />);

const barcodeStatusIcon = ({ nodes, ...props }) => {
  const classes = useStyles();

  if (fp.get("wrong_location", nodes))
    return (
      <ReportProblem
        className={classes.treeItemLabelIcon}
        style={{ color: red[500] }}
      />
    );

  if (fp.get("duplicated", nodes))
    return (
      <span>
        <ReportProblem
          className={classes.treeItemLabelIcon}
          style={{ color: orange[500] }}
        />{" "}
        <span className={classes.fontSmallSize}>
          {props.getLocalisationMessage("repeated_scan", "Repeated Scan")}.
        </span>
        <Link
          className={classes.treeItemLabelAdd}
          onClick={(e) => {
            e.preventDefault();
            // eslint-disable-next-line no-console
            console.info("I'm a button.");
          }}
        >
          {props.getLocalisationMessage("ignore", "Пропустить")}
        </Link>
      </span>
    );

  if (fp.get("number", nodes) === props.cursorOrder) {
    return (
      <span>
        <HourglassEmpty
          className={classes.treeItemLabelIcon}
          style={{ color: green[500] }}
        />{" "}
        <span className={classes.fontSmallSize}>
          {props.getLocalisationMessage("in_progress", "In Progress")}{" "}
          {`${props.cursorChildOrders.size}/${fp.get("child_count", nodes)}`}
        </span>
        <Link
          className={classes.treeItemLabelAdd}
          style={{ color: red[500] }}
          onClick={(e) => {
            e.preventDefault();
            return props.onBatchCompleteRequest({
              orderNumber: fp.get("number", nodes),
              details: {
                order: nodes,
                children: props.cursorChildOrders.toJS(),
              },
            });
          }}
        >
          {props.getLocalisationMessage("complete_it", "Complete It?")}
        </Link>
      </span>
    );
  }

  const showCursorButton = !props.cursorOrder
    ? props.cursorChildOrders.size > 0
    : props.cursorChildOrders.size > 0 &&
      !props.queueOrders.get(nodes.number) &&
      props.pendingOrdersCount > 0 &&
      nodes.child_count > 0 &&
      nodes.scanned;

  return (
    <span>
      <CheckCircle
        className={classes.treeItemLabelIcon}
        style={{ color: nodes.scanned ? green[500] : grey[500] }}
      />
      {showCursorButton && (
        <Link
          className={classes.treeItemLabelAdd}
          style={{ color: red[500] }}
          onClick={(e) => {
            e.preventDefault();
            return props.onChangeCursor(fp.get("number", nodes));
          }}
        >
          {props.getLocalisationMessage("make_it_cursor", "Make it as Cursor")}
        </Link>
      )}
    </span>
  );
};

const TreeItemLabel = (props) => {
  const classes = useStyles();
  const { nodes } = props;

  return (
    <div className={classes.treeItemLabelContent}>
      <span
        className={cx(
          classes.treeItemLabel,
          fp.get("scanned_parent_id", nodes)
            ? classes.fontNormal
            : classes.fontWeight,
          fp.get("wrong_location", nodes) && classes.wrongLocation,
        )}
      >
        {nodes.number}
      </span>

      {barcodeStatusIcon(props)}
    </div>
  );
};

TreeItemLabel.propTypes = {
  nodes: PropTypes.object,
};

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream((propsStream) => {
    const ordersTreeStream = propsStream
      .map(fp.pick(["orders", "tree"]))
      .distinctUntilChanged(isEqualData)
      .map(({ orders, tree }) => {
        const ordersList = Set().asMutable();

        orders.forEach((order) => {
          const newOrder = order.asMutable();
          const parentBarcode = order.get("parent_id");

          if (parentBarcode && tree.has(parentBarcode)) {
            const scannedOrders = parseString(tree.get(parentBarcode));
            const appearance = _.countBy(
              scannedOrders,
              (x) => x === order.get("number"),
            );

            const { true: count } = appearance;
            if (count > 1) {
              newOrder.set("duplicated", true);
            }
            ordersList.add(newOrder.asImmutable());

            fp.uniq(scannedOrders).forEach((scannedOrder) => {
              const orderItem = orders.get(scannedOrder);
              if (orderItem.get("parent_id") !== parentBarcode) {
                const wrongScannedOrder = orderItem.asMutable();
                wrongScannedOrder.set("parent_id", parentBarcode);
                wrongScannedOrder.set("wrong_location", true);
                ordersList.add(wrongScannedOrder.asImmutable());
              }
            });
          } else if (
            !order.get("scanned_parent_id") ||
            order.get("parent_id") === order.get("scanned_parent_id")
          ) {
            ordersList.add(order);
          }
        });

        const data = arrayToTree(ordersList.asImmutable().toJS(), {
          id: "number",
          parentId: "parent_id",
          dataField: null,
        });
        return fromJS(data);
      })
      .startWith(List());

    return propsStream.combineLatest(ordersTreeStream, (props, ordersTree) => ({
      ...props,
      ordersTree,
    }));
  }),
  pureComponent(
    fp.pick([
      "orders",
      "ordersTree",
      "activeNodes",
      "cursorOrder",
      "onBatchCompleteRequest",
      "pendingOrdersCount",
    ]),
  ),
);

OrderSortingBinHeirarchyCard.propTypes = {
  getLocalisationMessage: PropTypes.func.isRequired,
  orders: PropTypes.instanceOf(Map),
  tree: PropTypes.instanceOf(Map),
  ordersTree: PropTypes.instanceOf(List),
  activeNodes: PropTypes.instanceOf(Set),
  cursorChildOrders: PropTypes.instanceOf(Map),
  queueOrders: PropTypes.instanceOf(Map),
  cursorOrder: PropTypes.string,
  pendingOrdersCount: PropTypes.number,

  onBatchCompleteRequest: PropTypes.func,
  onChangeCursor: PropTypes.func,
};

function OrderSortingBinHeirarchyCard(props) {
  const classes = useStyles();
  const { getLocalisationMessage } = props;

  const activeNodes = props.activeNodes ? props.activeNodes.toArray() : [];

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.number}
      nodeId={nodes.number}
      label={<TreeItemLabel nodes={nodes} {...props} />}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  return (
    <div className={classes.hierarchy}>
      <CardHeader
        title={
          <h5>
            {getLocalisationMessage("batch_hierarchy", "Batch Hierarchy")}
          </h5>
        }
      />

      <CardContent>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMore />}
          expanded={activeNodes}
          defaultExpandIcon={<ChevronRight />}
          // onNodeToggle={(event, nodeIds) => {
          //   // eslint-disable-next-line no-console
          //   console.log(nodeIds);
          // }}
        >
          {props.ordersTree.toJS().map((item) => renderTree(item))}
        </TreeView>
      </CardContent>
    </div>
  );
}

export default enhancer(OrderSortingBinHeirarchyCard);
