import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  DataPolygon,
  Marker as LMarker,
  MarkerIcon,
  PanTo as LPanTo,
} from "react-leflet-map-components";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { POSTCODES_ITEM_URL } from "../../constants/AdminPathConstants";
import Icon from "../maps-leaflet/assets/postcodes.svg";
import JurisdictionMapFitBounds from "../jurisdiction-tree/JurisdictionMapFitBounds";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const areaColorsStream = propsStream
      .map(({ areaList }) =>
        Immutable.OrderedMap().withMutations(map => {
          areaList.forEach(item => {
            const lum = -0.25;
            let hex = String(
              `#${Math.random()
                .toString(16)
                .slice(2, 8)
                .toUpperCase()}`,
            ).replace(/[^0-9a-f]/gi, "");
            if (hex.length < 6) {
              hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            // eslint-disable-next-line no-unused-vars,one-var
            let rgb = "#",
              c,
              i;
            for (i = 0; i < 3; i++) {
              c = parseInt(hex.substr(i * 2, 2), 16);
              c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(
                16,
              );
              rgb += `00${c}`.substr(c.length);
            }
            map.set(item.id, rgb);
          });
        }),
      )
      .startWith(Immutable.OrderedMap());

    return propsStream
      .combineLatest(areaColorsStream, (props, areaColors) => ({
        ...props,
        areaColors,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "areaList",
      "areaColors",
      "selectedItems",
      "areaListLoading",
      "setLocation",
    ]),
  ),
);

LeafletMapPostcodesArea.propTypes = {
  areaColors: PropTypes.instanceOf(Immutable.OrderedMap),

  areaListLoading: PropTypes.bool,
  areaList: PropTypes.array,
  selectedItems: PropTypes.array,
  currentParentPolygon: PropTypes.object,
  setLocation: PropTypes.func,
};

function LeafletMapPostcodesArea(props) {
  return (
    <LeafletMapWrapper zoom={7}>
      {!props.areaListLoading &&
        props.areaList &&
        props.areaList.map(item => {
          const itemId = item.id;

          return (
            /* eslint-disable jsx-a11y/mouse-events-have-key-events */
            <div>
              {isValidCoordinates({ lat: item.lat, lng: item.lon }) && (
                <React.Fragment>
                  <LPanTo latLng={{ lat: item.lat, lng: item.lon }} />
                  <LMarker
                    data-cy="warehouse_marker"
                    position={{ lat: item.lat, lng: item.lon }}
                    title={item.name}
                    icon={
                      <MarkerIcon
                        url={Icon}
                        anchor={{ y: 45, x: 45 / 2 }}
                        origin={{ x: 45, y: 45 / 2 }}
                        size={{ width: 45, height: 45 }}
                        scaledSize={{
                          width: 2 * 45,
                          height: 2 * 45,
                        }}
                      />
                    }
                    onClick={() =>
                      props.setLocation(POSTCODES_ITEM_URL + itemId)
                    }
                  />
                </React.Fragment>
              )}

              {!(props.selectedItems.indexOf(itemId) === -1) &&
                item.polygon &&
                item.polygon.length > 0 && (
                  <DataPolygon
                    key={item.id}
                    label={item.name}
                    editable={false}
                    zIndex={item && item.zindex}
                    geometry={item.polygon}
                    stroke={true}
                    weight={3}
                    opacity={1}
                    fillOpacity={0.25}
                    fillColor={props.areaColors.get(itemId, "#000000")}
                    color={props.areaColors.get(itemId, "#000000")}
                    showPopup={true}
                  />
                )}

              {!(props.selectedItems.indexOf(itemId) === -1) &&
                item.polygon &&
                item.polygon.length > 0 && (
                  <JurisdictionMapFitBounds polygon={item.polygon} />
                )}
            </div>
          );
        })}

      {!(props.selectedItems && props.selectedItems.length > 0) &&
        props.currentParentPolygon &&
        props.currentParentPolygon.polygon && (
          <JurisdictionMapFitBounds
            polygon={props.currentParentPolygon.polygon}
          />
        )}
    </LeafletMapWrapper>
  );
}

export default enhancer(LeafletMapPostcodesArea);
