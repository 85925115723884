import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add as ContentAdd } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getCashiersList } from "../../api/admin/AdminFinanceApi";
import AdminCashierEditDialogWrapper from "../../wrappers/admin/AdminCashierEditDialogWrapper";
import AdminCashierCreateDialogWrapper from "../../wrappers/admin/AdminCashierCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  ROLE_FINANCE_ADMIN,
  ROLE_SUPER_ADMIN,
} from "../../../shared/constants/Authorities";
import { CREATE_CASHIERS_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const NA = "N/A";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(
    (state) => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdmin:
          hasRole(userRoles, ROLE_SUPER_ADMIN) ||
          hasRole(userRoles, ROLE_FINANCE_ADMIN),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      (propsStream) => {
        const { handler: onRowSelect, stream: onRowSelectStream } =
          createEventHandler();
        const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
          createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap((props) =>
            getCashiersList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch((error) => Observable.of({ error })),
          )
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminCashiersList.propTypes = {
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
};

function AdminCashiersList(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="cashiers"
      title={getLocalisationMessage("cashiers", "Cashiers")}
    >
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <AdminCashierCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("create"));
          props.onRequestRefresh();
        }}
      />

      <AdminCashierEditDialogWrapper
        cashierId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("view"));
          props.onRequestRefresh();
        }}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={9}
        rowGetter={(options) => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={(filter) => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            {props.isAdmin && (
              <Link to={updateQuery(props.location, fp.set("create", true))}>
                <Tooltip
                  title={getLocalisationMessage(
                    "add_new_cashier",
                    "Add New Cashier",
                  )}
                >
                  <IconButton>
                    <ContentAdd />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_CASHIERS_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {props.getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={128}
          label={getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          cellRenderer={(row) => (
            <Link
              to={updateQuery(
                location,
                fp.set("view", row.cellData.get("cashier_id")),
              )}
            >
              <MultiLineCell firstLine={row.cellData.get("full_name")} />
            </Link>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("email", "Email")}
          dataKey="email"
          disableSort={true}
          cellRenderer={(row) =>
            row.cellData.get("email") || getLocalisationMessage("na", NA)
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          disableSort={true}
          cellRenderer={(row) =>
            row.cellData.get("phone") || getLocalisationMessage("na", NA)
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("warehouse", "Warehouse")}
          dataKey="warehouse"
          disableSort={true}
          cellRenderer={(row) =>
            row.cellData.getIn(["warehouse", "name"]) ||
            getLocalisationMessage("na", NA)
          }
        />

        <DataListColumn
          width={94}
          justifyContent="center"
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          cellRenderer={(row) => {
            const status = row.cellData.get("status");
            return getLocalisationMessage(status, formatText(status));
          }}
        />

        <DataListColumn
          width={104}
          label={getLocalisationMessage("join_date", "Join Date")}
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={(row) => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCashiersList);
