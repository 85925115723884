import React from "react";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  FAILED_BIN,
  getBinByJurisdiction,
  INCOMPLETE_BIN,
  NOTFOUND_BIN,
  UNASSIGNED_BIN,
  UNSORTED_BIN,
} from "../../helpers/OrderSortingHelper";
import { OrderSortingDB } from "../../firebase/OrderSortingDB";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ASSIGNED_TO_COURIER,
  DISPATCHED,
  IN_DIFFERENT_WAREHOUSE,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  ON_HOLD,
  OUT_FOR_DELIVERY,
  PARTIALLY_IN_SORTING_FACILITY,
  UNASSIGNED,
} from "../../constants/OrderStatusCodes";
import AdminOrderEditDialogWrapper from "../../wrappers/admin/AdminOrderEditDialogWrapper";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import AdminBatchAssignSupplierDialogWrapper from "../../wrappers/admin/AdminBatchAssignSupplierDialogWrapper";
import FormDialog from "../../components/form/FormDialog";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import OrderSortingChips from "../../components/order-multi-shipment-sorting/OrderSortingChips";
import OrderSortingTable from "../../components/order-multi-shipment-sorting/OrderSortingTable";
import OrderSortingChangeBinDialog from "../../components/order-multi-shipment-sorting/OrderSortingChangeBinDialog";
import OrderSortingVerifyOrdersDialog from "../../components/order-multi-shipment-sorting/OrderSortingVerifyOrdersDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_DRIVER_RUNSHEET_URL,
  CREATE_ORDER_AIRWAYBILL_URL,
  CREATE_ORDER_MANIFEST_URL,
} from "../../../shared/constants/FileProxyControllerConstants";
import AdminBatchUpdateOrderDialogWrapper2 from "../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import OrderSortingVerifyBinOrdersDialog from "../../components/order-multi-shipment-sorting/OrderSortingVerifyBinOrdersDialog";
import { getUser } from "../../reducers/ProfileReducer";
import { hasRole } from "../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../constants/AdminRoleTypes";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { RETURNING } from "../../constants/FlowTypes";

const SORTED_GROUP = "Sorted";
const UNSORTED_GROUP = "Unsorted";
const PROCESSED_GROUP = "Processed";

function getOrderGroup(taskWarehouseId, order) {
  if (order.get("info")) {
    // const status = order.getIn(["info", "status"]);
    const flow = order.getIn(["info", "flow"]);
    const warehouseId = order.getIn(["info", "warehouse", "id"]);
    const inWarehouse = warehouseId === taskWarehouseId;

    if (flow === RETURNING) return RETURNING;

    if (
      inWarehouse &&
      (status === ASSIGNED_TO_COURIER ||
        status === ON_HOLD ||
        status === IN_SORTING_FACILITY)
    ) {
      return "Sorted";
    }

    // if (
    //   inWarehouse &&
    //   (status === ASSIGNED_TO_COURIER ||
    //     status === RETURNED_TO_WING ||
    //     status === ON_HOLD ||
    //     status === IN_SORTING_FACILITY)
    // ) {
    //   return "Sorted";
    // }

    if (status === UNASSIGNED || status === ASSIGNED_TO_COURIER) {
      return "Unsorted";
    }

    return "Processed";
  }

  return null;
}

function formatPromptMessage(
  orderSize,
  getLocalisationMessage,
  defaultMessage = "",
) {
  if (!orderSize) {
    return defaultMessage;
  }
  const messageHead = getLocalisationMessage("regroup_orders_prompt");
  if (!messageHead) {
    return defaultMessage;
  }
  let messageTail = null;
  switch (orderSize) {
    case 1:
      messageTail = getLocalisationMessage("orders_1");
      break;
    case 0:
    case 2:
    case 3:
    case 4:
    case 5:
      messageTail = getLocalisationMessage("orders_2");
      break;
    default:
      messageTail = getLocalisationMessage("orders_3");
  }
  return `${messageHead} ${orderSize} ${messageTail}`;
}

const enhancer = compose(
  connect((state) => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      isOperator: hasRole(userRoles, ROLE_OPERATOR),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    };
  }),
  useSheet({
    chipsCard: { paddingTop: 0, paddingBottom: 0 },
    tableCard: { paddingTop: 0 },
  }),
  withState("state", "setState", {
    transitWarehouse: null,
    transitStatus: null,
    selectedBin: null,
  }),
  mapPropsStream(
    /**
     * 1. Generate sets of selected order, driver and warehouse ids.
     * 2. Count failed orders.
     * 3. Combine and sort order bins.
     */
    (propsStream) => {
      const statsStream = propsStream
        .map((props) => ({
          orders: props.orders,
          binRules: props.binRules,
          warehouseId: props.warehouseId,
          localCounter: props.task.get("counter"),
          sharedCounter: props.sharedCounter,
          sharedBoxCounter: props.sharedBoxCounter,
          selectedOrders: props.task.get("selectedOrders"),
        }))
        .distinctUntilChanged(isEqualData)
        .map((props) => {
          let bins = Map().asMutable();
          const groups = Map().asMutable();

          let selectedOrderWeight = 0;

          const ids = Set().asMutable();
          const driverIds = Set().asMutable();
          const warehouseIds = Set().asMutable();
          const courierTypes = Map().asMutable();
          const selectedBins = Set().asMutable();

          const orderIdPath = ["info", "id"];
          const statusPath = ["info", "status"];
          const driverIdPath = ["info", "driver", "id"];
          const warehouseIdPath = ["info", "destination_warehouse", "id"];
          const courierTypeKeyPath = ["info", "package", "courier_type"];
          const courierTypePath = ["info", "package", "courier_type_name"];
          const orderWeightPath = ["info", "weight"];

          props.orders.forEach((order, orderNumber) => {
            const orderId = order.getIn(orderIdPath);
            const loaded = orderId > 0;
            const code = order.get("code");
            const selected = props.selectedOrders.has(orderNumber);
            const orderCourierType = order.getIn(courierTypePath);
            const orderCourierTypeKey = order.getIn(courierTypeKeyPath);

            if (loaded) {
              if (!courierTypes.has(orderCourierTypeKey)) {
                courierTypes.set(
                  orderCourierTypeKey,
                  Map({
                    key: orderCourierTypeKey,
                    label: orderCourierType,
                    orders: Set(),
                  }),
                );
              }

              courierTypes.updateIn([orderCourierTypeKey, "orders"], (x) =>
                x.add(orderNumber),
              );
            }

            const bin = order.get("bin")
              ? order.getIn(statusPath) === IN_DIFFERENT_WAREHOUSE ||
                order.getIn(statusPath) === PARTIALLY_IN_SORTING_FACILITY
                ? INCOMPLETE_BIN
                : order.get("bin")
              : !loaded && order.get("failed")
              ? FAILED_BIN
              : UNASSIGNED_BIN;

            const generatedBinsByCount = getBinByJurisdiction({
              order,
              initialBins: bins,
              initialBin: bin,
              rule: props.binRules.get(code),
            });

            if (generatedBinsByCount) {
              bins = generatedBinsByCount.asMutable();
            } else {
              if (!bins.has(bin)) {
                bins.set(
                  bin,
                  Map({
                    code,
                    postcode: props.binRules.getIn([code, "postcode"], null),
                    label: bin,
                    selected: 0,
                    orders: Set(),
                    level: -1,
                    courierTypes: Set(),
                  }),
                );
              }

              bins.updateIn([bin, "orders"], (x) => x.add(orderNumber));
              if (selected) {
                bins.updateIn([bin, "selected"], fp.add(1));
                if (!selectedBins.has(bin)) {
                  selectedBins.add(bin);
                }
              }

              // if (loaded) {
              //   bins.updateIn([bin, "courierTypes"], x =>
              //     x.add(order.getIn(courierTypePath)),
              //   );
              // }
            }

            const group = getOrderGroup(props.warehouseId, order);

            if (group) {
              if (!groups.has(group)) {
                groups.set(
                  group,
                  Map({ label: group, selected: 0, orders: Set() }),
                );
              }

              groups.updateIn([group, "orders"], (x) => x.add(orderNumber));

              if (selected) {
                groups.updateIn([group, "selected"], fp.add(1));
              }
            }

            if (loaded) {
              if (selected) {
                ids.add(orderId);
                selectedOrderWeight +=
                  fp.toNumber(order.getIn(orderWeightPath, 0)) * 10;

                const driverId = order.getIn(driverIdPath);

                if (driverId > 0) {
                  driverIds.add(driverId);
                }

                const warehouseId = order.getIn(warehouseIdPath);

                if (warehouseId > 0) {
                  warehouseIds.add(warehouseId);
                }
              }
            }
          });

          // Filter Bins and generate Final Bins by removed if special bin orders counts not enough for limit
          const finalBins = Map().withMutations((finalBin) => {
            let assignedOrders = Set();
            bins
              .sort((a, b) => {
                const aLevel = a.get("level");
                const bLevel = b.get("level");

                if (aLevel > bLevel) return 1;

                if (aLevel === bLevel) return 0;

                return -1;
              })
              .forEach((x) => {
                const level = x.get("level");
                const orders = x.get("orders", Set());
                const unassignedOrders = orders.subtract(assignedOrders);

                const selectedOrders = unassignedOrders.filter((orderNumber) =>
                  props.selectedOrders.has(orderNumber),
                );
                const bin = x.set("selected", selectedOrders.size);
                if (level > 0) {
                  const code = bin.get("code");
                  const rule = props.binRules.get(code);
                  const divisionCount = rule.get("count", 0);

                  if (
                    !bin.get("country", false) &&
                    unassignedOrders.size >= divisionCount
                  ) {
                    finalBin.set(bin.get("label"), bin);
                    finalBin.setIn(
                      [bin.get("label"), "orders"],
                      unassignedOrders,
                    );
                  }

                  // Check If the Country Level orders fewer than division Count if so mark as a regular bin name bin.get("country", false) &&
                  if (unassignedOrders.size > 0) {
                    if (unassignedOrders.size >= divisionCount) {
                      finalBin.set(bin.get("label"), bin);
                      finalBin.setIn(
                        [bin.get("label"), "orders"],
                        unassignedOrders,
                      );
                    } else {
                      const regularBin = finalBin.get(
                        bin.get("mainLabel"),
                        null,
                      );
                      if (regularBin) {
                        const regularBinOrders = finalBin.getIn([
                          bin.get("mainLabel"),
                          "orders",
                        ]);
                        finalBin.setIn(
                          [bin.get("mainLabel"), "orders"],
                          regularBinOrders.merge(unassignedOrders),
                        );
                      } else {
                        finalBin.set(
                          bin.get("mainLabel"),
                          Map({
                            label: bin.get("mainLabel"),
                            postcode: bin.get("postcode"),
                            selected: 0,
                            orders: unassignedOrders,
                            level: -1,
                            courierTypes: Set(),
                            code,
                          }),
                        );
                      }

                      // assignedOrders = assignedOrders.merge(unassignedOrders);

                      // Recalculate Selected orders
                      const regularBinOrders = finalBin.getIn([
                        bin.get("mainLabel"),
                        "orders",
                      ]);
                      const reSelectedOrders = regularBinOrders.filter(
                        (orderNumber) => props.selectedOrders.has(orderNumber),
                      );
                      finalBin.setIn(
                        [bin.get("mainLabel"), "selected"],
                        reSelectedOrders.size,
                      );
                    }
                  }
                } else {
                  finalBin.set(bin.get("label"), bin);
                  finalBin.setIn(
                    [bin.get("label"), "orders"],
                    unassignedOrders,
                  );
                }

                assignedOrders = assignedOrders.merge(unassignedOrders);
              });
          });

          return Map({
            selectedOrderWeight: selectedOrderWeight / 10,
            selectedIds: ids.asImmutable(),
            selectedDriverIds: driverIds.asImmutable(),
            selectedWarehouseIds: warehouseIds.asImmutable(),
            selectedBins: selectedBins.asImmutable(),
            courierTypes: courierTypes.asImmutable(),

            groups: groups
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultGroups = [
                  UNSORTED_GROUP,
                  SORTED_GROUP,
                  PROCESSED_GROUP,
                ];

                for (let i = 0; i < defaultGroups.length; i++) {
                  const bin = defaultGroups[i];

                  if (aName === bin) {
                    return -1;
                  }

                  if (bName === bin) {
                    return 1;
                  }
                }

                return 0;
              }),
            bins: finalBins
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultBins = [
                  UNASSIGNED_BIN,
                  FAILED_BIN,
                  UNSORTED_BIN,
                  INCOMPLETE_BIN,
                  NOTFOUND_BIN,
                ];

                for (let i = 0; i < defaultBins.length; i++) {
                  const bin = defaultBins[i];

                  if (aName === bin) {
                    return 1;
                  }

                  if (bName === bin) {
                    return -1;
                  }
                }

                return aName.localeCompare(bName);
              }),
          });
        })
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
    },
  ),
  pureComponent(fp.pick(["location", "task", "stats", "orders", "state"])),
);

AdminOrderSortingUzpostTableContainer.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  isOperator: PropTypes.bool,
  stats: PropTypes.instanceOf(Map),
  binRules: PropTypes.instanceOf(Map),
  location: PropTypes.object.isRequired,
  task: PropTypes.instanceOf(Map).isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
  warehouseId: PropTypes.number.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  updateSortingTask: PropTypes.func.isRequired,
  setState: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderSortingUzpostTableContainer(props) {
  const {
    getLocalisationMessage,
    classes,
    task,
    stats,
    orders,
    state,
    location: { query },
  } = props;
  const db = new OrderSortingDB(props.warehouseId);

  const selectedOrders = task.get("selectedOrders");
  const selectedIds = stats.get("selectedIds").toArray();
  const selectedIdsString = selectedIds.join(",");
  const selectedDriverIdsString = stats.get("selectedDriverIds").join(",");

  return (
    <div>
      {query.remove_after_update === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => {
            db.batchReloadOrders(selectedOrders.toArray())
              .toPromise()
              .catch(props.showErrorMessage);

            props.setLocationQuery(fp.unset("remove_after_update"));
          }}
          onConfirm={() =>
            props.setLocationQuery(
              fp.flow(
                fp.set("remove_selected", true),
                fp.unset("remove_after_update"),
              ),
            )
          }
        >
          {getLocalisationMessage(
            "you_want_to_remove_updated_orders",
            "You want to remove updated orders?",
          )}
        </ConfirmDialog>
      )}

      {query.remove_selected === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmit={() =>
            db
              .batchRemoveOrders(selectedOrders.toArray())
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage("delete_prompt") ||
            `Are you sure you want to remove ${selectedOrders.size} orders from\n queue ?`}
        </FormDialog>
      )}

      {query.verify_bin_orders === "true" && (
        <OrderSortingVerifyBinOrdersDialog
          open={true}
          selectedOrders={selectedOrders}
          selectedBin={state.selectedBin}
          orders={orders}
          bins={stats.get("bins")}
          onConfirmOrders={(numbers) => {
            if (numbers.unverified.size > 0) {
              db.batchUpdateOrderBins(
                numbers.unverified.toArray(),
                NOTFOUND_BIN,
              )
                .toPromise()
                .catch(ResponseError.throw)
                .then(() => {
                  props.updateSortingTask((x) =>
                    x.update("selectedOrders", (selected) =>
                      selected.subtract(numbers.unverified.toArray()),
                    ),
                  );
                })
                .then(() => {
                  props.setLocationQuery(
                    fp.flow(
                      fp.set(
                        "batch_update_status",
                        fp.get("transitStatus", state),
                      ),
                      fp.set("batch_update", true),
                      fp.unset("verify_bin_orders"),
                    ),
                  );
                });
            } else {
              Promise.resolve(
                props.updateSortingTask((x) =>
                  x.update("selectedOrders", (selected) =>
                    selected.subtract(numbers.unverified.toArray()),
                  ),
                ),
              ).then(() => {
                props.setLocationQuery(
                  fp.flow(
                    fp.set(
                      "batch_update_status",
                      fp.get("transitStatus", state),
                    ),
                    fp.set("batch_update", true),
                    fp.unset("verify_bin_orders"),
                  ),
                );
              });
            }
          }}
          onConfirmUnknownOrder={(order, bin, found) => {
            const values = {};
            const orderNumber = order.get("barcode");

            values[`${orderNumber}/failed`] = null;
            values[`${orderNumber}/bin`] = bin.get("label");
            values[`${orderNumber}/code`] = bin.get("code");
            values[`${orderNumber}/info`] = order.toJS();
            values[`${orderNumber}/number`] = orderNumber;
            values[`${orderNumber}/scanned_as_box`] = false;
            values[`${orderNumber}/hash`] = order.hashCode();
            values[`${orderNumber}/hash_time`] = Date.now();

            db.batchUpdateOrders(values)
              .toPromise()
              .catch(ResponseError.throw)
              .then(() => {
                props.updateSortingTask((x) =>
                  x.update("selectedOrders", (selected) =>
                    selected.add(orderNumber),
                  ),
                );

                if (!found) {
                  db.addTask(order.get("barcode"), {
                    in_sorting_warehouse: props.warehouseId,
                  }).toPromise();
                }

                if (bin.get("postcode", null)) {
                  db.addTask(order.get("barcode"), {
                    update_postcode: {
                      index: bin.get("postcode"),
                      barcodes: [order.get("barcode")],
                    },
                  }).toPromise();
                }
              });
          }}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.set("confirm_close_verify_selected", true),
            )
          }
        />
      )}

      {query.verify_selected === "true" && (
        <OrderSortingVerifyOrdersDialog
          selectedOrders={selectedOrders}
          onSelectOrders={(numbers) => {
            props.setLocationQuery(fp.unset("verify_selected"));
            props.updateSortingTask((x) => x.set("selectedOrders", numbers));
          }}
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.set("confirm_close_verify_selected", true),
            )
          }
        />
      )}

      {query.confirm_close_verify_selected === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("confirm_close_verify_selected"))
          }
          onConfirm={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("verify_selected"),
                fp.unset("verify_bin_orders"),
                fp.unset("confirm_close_verify_selected"),
              ),
            )
          }
          title={
            getLocalisationMessage(
              "are_you_sure_you_want_to_close_order_verification_dialog",
            ) || "Are you sure you want to close order verification dialog?"
          }
        >
          <p>
            {getLocalisationMessage("all_order_scanned_verification_removed")} (
            <em>{getLocalisationMessage("not_from_sorting_list")}</em>)
          </p>
        </ConfirmDialog>
      )}

      {query.regroup_bins === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmit={() =>
            db
              .batchUpdateOrderBins(selectedOrders.toArray(), null)
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {formatPromptMessage(
            selectedOrders.size,
            getLocalisationMessage,
            `Are you sure you want to regroup ${selectedOrders.size} orders?`,
          )}
        </FormDialog>
      )}

      {query.change_bin === "true" && (
        <OrderSortingChangeBinDialog
          open={true}
          orderBins={stats.get("bins")}
          selectedOrders={selectedOrders}
          onRequestClose={() => props.setLocationQuery(fp.unset("change_bin"))}
          onSubmit={(values) =>
            db
              .batchUpdateOrderBins(selectedOrders.toArray(), values.name)
              .toPromise()
              .then(() => ({ selectedOrders, bin: values.name }))
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={(request) => {
            const nextBin = stats
              .get("bins")
              .find((x) => x.get("label") === request.bin);

            if (nextBin) {
              const getSupplierId = (x) =>
                orders.getIn([x, "info", "supplier", "id"]);

              const originSuppliers = request.selectedOrders
                .map(getSupplierId)
                .filter(Boolean);

              const destinationSuppliers = nextBin
                .get("orders")
                .map(getSupplierId)
                .filter(Boolean);

              if (
                destinationSuppliers.size === 1 &&
                !destinationSuppliers.equals(originSuppliers)
              ) {
                props.setLocationQuery(
                  fp.flow(
                    fp.set("set_supplier", true),
                    fp.set("set_supplier_id", destinationSuppliers.first()),
                  ),
                );
              }
            }

            props.setLocationQuery(fp.unset("change_bin"));
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      {query.batch_update === "true" && (
        <AdminBatchUpdateOrderDialogWrapper2
          open={true}
          initialValues={{
            orderNumbers: selectedOrders.toArray(),
            orderStatus: query.batch_update_status,
            isBarcode: true,
            driver: {
              id:
                stats.get("selectedDriverIds").size !== 1
                  ? null
                  : stats.get("selectedDriverIds").first(),
            },
            warehouse: {
              id: fp.get("transitWarehouse", state),
            },
            weight:
              Math.round(
                fp.toNumber(stats.get("selectedOrderWeight", 0)) * 100,
              ) / 100,
          }}
          list={orders}
          isSorting={true}
          selectedItems={selectedOrders}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_update_status"),
                fp.unset("batch_update"),
              ),
            )
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={(response) => {
            props.setState(
              fp.flow(
                fp.set("transitWarehouse", null),
                fp.set("transitStatus", null),
              ),
            );
            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_update"),
                fp.unset("batch_update_status"),
                fp.set("batch_id", response.data.id),
              ),
            );
          }}
        />
      )}

      {query.set_supplier === "true" && (
        <AdminBatchAssignSupplierDialogWrapper
          open={query.set_supplier === "true"}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("set_supplier"))
          }
          initialValues={{
            orderNumbers: selectedOrders.toArray(),
            supplier: { id: query.set_supplier_id },
          }}
          onSubmitSuccess={(response) =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("set_supplier"),
                fp.unset("set_supplier_id"),
                fp.set("batch_id", response.data.id),
              ),
            )
          }
          onSubmitFail={props.showErrorMessage}
        />
      )}

      {fp.toFinite(query.batch_id) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(query.batch_id)}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("batch_id"));

            if (selectedOrders.size > 0) {
              // Auto remove orders after batch update
              // props.setLocationQuery(fp.set("remove_after_update", true));
              db.batchRemoveOrders(selectedOrders.toArray())
                .toPromise()
                .catch(ResponseError.throw);
            }
          }}
        />
      )}

      {fp.toFinite(query.view_order) > 0 && (
        <AdminOrderDetailsDialogWrapperV2
          tab={query.view_order_tab}
          orderId={fp.toFinite(query.view_order)}
          onEditClick={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("view_order"),
                fp.set("edit_order", query.view_order),
              ),
            )
          }
          onTabChange={(x) =>
            props.setLocationQuery(fp.set("view_order_tab", x))
          }
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(fp.unset("view_order"), fp.unset("view_order_tab")),
            )
          }
          location={props.location}
        />
      )}

      {fp.toFinite(query.edit_order) > 0 && (
        <AdminOrderEditDialogWrapper
          orderId={fp.toFinite(query.edit_order)}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("edit_order"),
                fp.set("view_order", query.edit_order),
              ),
            )
          }
          onSubmitSuccess={() => {
            props.setLocationQuery(
              fp.flow(
                fp.unset("edit_order"),
                fp.set("view_order", query.edit_order),
              ),
            );
          }}
        />
      )}

      {Boolean(
        query.set_supplier !== "true" &&
          query.batch_update !== "true" &&
          query.verify_selected !== "true",
      ) && (
        <FlexBox direction="column">
          <Card>
            <CardContent className={classes.chipsCard}>
              <OrderSortingChips
                groups={stats.get("groups")}
                filterChips={stats.get("bins")}
                filterTabs={stats.get("courierTypes")}
                onSelectOrders={(orderNumbers, bin) => {
                  const ruleCode = bin.get("code", null);
                  props.setState(fp.set("selectedBin", bin));
                  const warehouseId = props.binRules.getIn(
                    [ruleCode, "warehouse", "id"],
                    null,
                  );
                  const status = props.binRules.getIn(
                    [ruleCode, "status"],
                    null,
                  );
                  if (ruleCode && Boolean(warehouseId || status)) {
                    if (
                      !state.transitWarehouse ||
                      warehouseId === state.transitWarehouse
                    ) {
                      props.setState(fp.set("transitWarehouse", warehouseId));
                    } else {
                      props.setState(fp.set("transitWarehouse", null));
                    }

                    if (
                      !state.transitStatus ||
                      status === state.transitStatus
                    ) {
                      props.setState(fp.set("transitStatus", status));
                    } else {
                      props.setState(fp.set("transitStatus", null));
                    }
                  } else {
                    props.setState(
                      fp.flow(
                        fp.set("transitWarehouse", null),
                        fp.set("transitStatus", null),
                      ),
                    );
                  }
                  props.updateSortingTask((x) =>
                    x.update("selectedOrders", () => orderNumbers),
                  );
                }}
                onDeselectOrders={(orderNumbers) => {
                  props.setState(fp.set("selectedBin", null));
                  if (selectedOrders.size <= orderNumbers.size) {
                    props.setState(
                      fp.flow(
                        fp.set("transitWarehouse", null),
                        fp.set("transitStatus", null),
                      ),
                    );
                  }
                  //
                  // props.updateSortingTask(x =>
                  //   x.update("selectedOrders", selected =>
                  //     selected.subtract(orderNumbers),
                  //   ),
                  // );

                  props.updateSortingTask((x) =>
                    x.update("selectedOrders", () => []),
                  );
                }}
              />
            </CardContent>

            <CardContent className={classes.tableCard}>
              <OrderSortingTable
                orders={orders}
                showBatchUpdate={props.isOperator}
                onOrderClick={(x) =>
                  props.setLocationQuery(fp.set("view_order", x))
                }
                selectedOrders={selectedOrders}
                onOrdersSelect={(numbers) =>
                  props.updateSortingTask((x) =>
                    x.set("selectedOrders", numbers),
                  )
                }
                onReloadClick={(orderNumber) =>
                  db
                    .reloadOrder(orderNumber)
                    .toPromise()
                    .catch(props.showErrorMessage)
                }
                onRemoveClick={(orderNumber) =>
                  db
                    .removeOrder(orderNumber)
                    .toPromise()
                    .catch(props.showErrorMessage)
                }
                onBatchStatusUpdate={() => {
                  props.setLocationQuery(
                    fp.flow(
                      fp.set("batch_update", true),
                      fp.set(
                        "batch_update_status",
                        fp.get("transitStatus", state),
                      ),
                    ),
                  );
                }}
                headerActions={
                  <FlexBox align="center">
                    {state.selectedBin && !props.isOperator && (
                      <CustomButton
                        style={{
                          flex: "1 1 auto",
                          margin: "0 .5rem",
                        }}
                        variant={OUTLINED}
                        color={SECONDARY}
                        onClick={() =>
                          props.setLocationQuery(
                            fp.set("verify_bin_orders", true),
                          )
                        }
                      >
                        {getLocalisationMessage(
                          "verify_sorting",
                          "Verify Sorting",
                        )}
                      </CustomButton>
                    )}

                    <MenuButtonMore desktop={false}>
                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(fp.set("change_bin", true))
                        }
                      >
                        {getLocalisationMessage("change_bin")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(fp.set("regroup_bins", true))
                        }
                      >
                        {getLocalisationMessage("regroup_bins")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(
                            fp.set("remove_selected", true),
                          )
                        }
                      >
                        {getLocalisationMessage("remove")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(
                            fp.set("verify_selected", true),
                          )
                        }
                      >
                        {getLocalisationMessage("verify_orders")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          db
                            .batchReloadOrders(selectedOrders.toArray())
                            .toPromise()
                            .catch(props.showErrorMessage)
                        }
                      >
                        {getLocalisationMessage("batch_reload")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(
                            fp.flow(
                              fp.set("batch_update", true),
                              fp.unset("batch_update_status"),
                            ),
                          )
                        }
                      >
                        {getLocalisationMessage("update")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(
                            fp.flow(
                              fp.set("batch_update", true),
                              fp.set("batch_update_status", IN_TRANSIT),
                            ),
                          )
                        }
                      >
                        {getLocalisationMessage("to_transit")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(
                            fp.flow(
                              fp.set("batch_update", true),
                              fp.set("batch_update_status", DISPATCHED),
                            ),
                          )
                        }
                      >
                        {getLocalisationMessage("dispatch")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(
                            fp.flow(
                              fp.set("batch_update", true),
                              fp.set("batch_update_status", OUT_FOR_DELIVERY),
                            ),
                          )
                        }
                      >
                        {getLocalisationMessage("deliver")}
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          props.setLocationQuery(fp.set("set_supplier", true))
                        }
                      >
                        {getLocalisationMessage("set_supplier")}
                      </MenuItem>

                      <MenuItem
                        target="_blank"
                        component="a"
                        href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
                          ids: selectedIdsString,
                        })}
                      >
                        {getLocalisationMessage("airwaybill")}
                      </MenuItem>

                      <MenuItemForm
                        url={CREATE_ORDER_MANIFEST_URL}
                        params={{
                          ids: selectedIds,
                        }}
                        primaryText={getLocalisationMessage("manifest")}
                      />

                      <MenuItemForm
                        url={CREATE_ORDER_MANIFEST_URL}
                        params={{
                          compact: true,
                          ids: selectedIds,
                        }}
                        primaryText={getLocalisationMessage("compact_manifest")}
                      />

                      {Boolean(selectedDriverIdsString) && (
                        <MenuItem
                          target="_blank"
                          component="a"
                          href={updateQuery(CREATE_DRIVER_RUNSHEET_URL, {
                            ids: selectedDriverIdsString,
                          })}
                        >
                          {getLocalisationMessage("runsheet")}
                        </MenuItem>
                      )}
                    </MenuButtonMore>
                  </FlexBox>
                }
              />
            </CardContent>
          </Card>
        </FlexBox>
      )}
    </div>
  );
}

export default enhancer(AdminOrderSortingUzpostTableContainer);
