import React from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminBankReconcileSettingsWizard from "./AdminBankReconcileSettingsWizard";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    container: { width: "820px" },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

AdminBankReconcileSettingsContainer.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  initialValues: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminBankReconcileSettingsContainer(props) {
  return (
    <AdminAppLayout
      title={props.getLocalisationMessage(
        "bank_reconcile_settings",
        "Bank Reconcile Settings",
      )}
    >
      <AdminBankReconcileSettingsWizard
        initialValues={props.initialValues}
        location={props.location}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminBankReconcileSettingsContainer);
