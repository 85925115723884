import React from "react";
import _ from "lodash";
import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SHOP_ADDRESS_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedVenueCity,
  getVenueCityPredictions,
} from "../../api/admin/AdminVenueCityApi";
import { createAddressItem } from "../../api/admin/AdminShopAddressApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminVenueAddressForm from "../../components/admin/AdminVenueAddressForm";

const enhancer = compose(
  connect(null, { showErrorMessage, showSuccessMessage }),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(propsStream => {
    const venueIdStream = propsStream.map(
      fp.flow(fp.get("params.venueId"), fp.toFinite),
    );

    return propsStream
      .combineLatest(venueIdStream, (props, venueId) => ({ ...props, venueId }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminVenueAddressCreate.propTypes = {
  venueId: PropTypes.number,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
};

function AdminVenueAddressCreate(props) {
  return (
    <AdminAppLayout title="Create Address">
      <AdminVenueAddressForm
        initialValues={{
          location: {},
          country: fromJS({
            name: "United Arab Emirates",
            code: "AE",
          }),
        }}
        onSubmit={({ country, location, ...values }) =>
          createAddressItem(
            props.venueId,
            toSnakeCase({
              ...values,
              venue_address: true,
              locationType: "HOME",
              preferredDeliveryTime: "9:00AM - 1:00PM",
              ownerId: props.venueId,
              address: _.get(location, "address"),
              lat: _.get(location, "lat"),
              lon: _.get(location, "lng"),
            }),
          ).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.setLocation(sprintf(SHOP_ADDRESS_LIST_URL, props.venueId));
        }}
        onSubmitFail={props.showErrorMessage}
        onDismissClick={() =>
          props.setLocation(sprintf(SHOP_ADDRESS_LIST_URL, props.venueId))
        }
        getCachedVenueCity={getCachedVenueCity}
        getVenueCityPredictions={getVenueCityPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminVenueAddressCreate);
