import React from "react";
import { Map, OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  Dialog,
  CardContent,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("PdfAttributeDetailForm");

const exampleFieldViewStyle = values => {
  let className = "";
  if (values.viewStyle === "field_col_type") {
    className += "field_col_type ";
  } else if (values.viewStyle === "field_row_type") {
    className += "field_row_type ";
  }
  return className;
};
const exampleFieldBorderStyle = values => {
  let className = "";
  if (values.borderTop) {
    className += "b-t ";
  }
  if (values.borderRight) {
    className += "b-r ";
  }
  if (values.borderBottom) {
    className += "b-b ";
  }
  if (values.borderLeft) {
    className += "b-l ";
  }
  return className;
};
const exampleFieldStyle = values => {
  let className = exampleFieldBorderStyle(values);
  className += exampleFieldViewStyle(values);
  return className;
};

const enhancer = compose(
  useSheet({
    paper: {
      minWidth: "600px",
      maxWidth: "600px",
      minHeight: "auto",
    },
    dialog: {
      maxWidth: "600px",
    },
  }),
  withHandlers({
    onSubmit: props => values => {
      const item = { ...props.attribute.toJS() };
      item.name = values.name || "";
      item.viewStyle = values.viewStyle || "";
      item.borderStyle = exampleFieldBorderStyle(values);
      props.saveAttributeItem(item);
    },
  }),
  withContext(
    {
      titleTypes: PropTypes.instanceOf(OrderedSet),
    },
    props => ({
      titleTypes: props.titleTypes,
    }),
  ),
  reduxForm({
    form: "PdfAttributeDetailForm",
  }),
  connect(state => ({
    i18n: getMessages(state),
    values: valueSelector(
      state,
      "name",
      "viewStyle",
      "borderTop",
      "borderRight",
      "borderBottom",
      "borderLeft",
    ),
  })),
);

PdfAttributeDetailForm.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  values: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
  attribute: PropTypes.instanceOf(Map),
  titleTypes: PropTypes.instanceOf(OrderedSet),
};

function PdfAttributeDetailForm(props) {
  const {
    i18n,
    values,
    onDismiss,
    submitting,
    titleTypes,
    handleSubmit,
  } = props;
  return (
    <Dialog
      open={true}
      PaperProps={{
        style: {
          minWidth: "600px",
          maxWidth: "600px",
          minHeight: "auto",
        },
      }}
      autoScrollBodyContent={true}
      onClose={onDismiss}
    >
      <DialogTitle>PDF Attribute Detail</DialogTitle>
      <DialogContent>
        <FlexBox
          align="center"
          container={8}
          element={<form autoComplete="off" />}
        >
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <PageLoading isLoading={submitting} />
              <Card>
                <CardContent className="example-field">
                  <div
                    className={`example-content ${exampleFieldStyle(values)}`}
                  >
                    {values.viewStyle !== "field_no_title" && (
                      <div>{values.name}:&nbsp;</div>
                    )}
                    <div>Value</div>
                  </div>
                </CardContent>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <CardContent>
                      <FormTextField
                        name="name"
                        fullWidth={true}
                        label="Name"
                      />
                      <FormSelectField
                        name="viewStyle"
                        fullWidth={true}
                        autoWidth={true}
                        options={titleTypes}
                        formatOption={formatText}
                        label="View Style"
                      />
                    </CardContent>
                  </FlexBox>
                  <FlexBox flex={true} direction="column">
                    <CardContent>
                      <FormCheckbox name="borderTop" label="Border Top" />
                      <FormCheckbox name="borderRight" label="Border Right" />
                      <FormCheckbox name="borderBottom" label="Border Bottom" />
                      <FormCheckbox name="borderLeft" label="Border Left" />
                    </CardContent>
                  </FlexBox>
                </FlexBox>
              </Card>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={handleSubmit}>{i18n.get("save", "Save")}</Button>
          <Button onClick={onDismiss}>{i18n.get("dismiss", "Dismiss")}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(PdfAttributeDetailForm);
