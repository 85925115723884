import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import ResponseError from "../../../helpers/ResponseError";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { ACTIVE } from "../../../constants/OverallStatus";
import { SETTINGS_PRICING_PLANS_CREATE_URL } from "../../../constants/AdminPathConstants";
import { createPricingPlan } from "../../../api/admin/AdminSubscriptionApi";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import PricingPlanForm from "../../../components/subscription-core/PricingPlanForm";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func.isRequired }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminPricingPlanCreateContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminPricingPlanCreateContainer(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "create_pricing_plan",
        "Create Pricing Plan",
      )}
    >
      <PricingPlanForm
        onSubmit={({ country, markLocation, ...values }) =>
          createPricingPlan(toSnakeCase(values)).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
          props.setLocation(SETTINGS_PRICING_PLANS_CREATE_URL);
        }}
        onSubmitFail={props.showErrorMessage}
        initialValues={{
          status: ACTIVE,
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPricingPlanCreateContainer);
