import sprintf from "sprintf";
import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import _ from "lodash";

const ROUTE_TEMPLATE_CRUD_WITH_ID = `${API_ROOT_URL}/admin/route-templates/%s`;
const ROUTE_TEMPLATE_CRUD = `${API_ROOT_URL}/admin/route-templates`;
const ROUTE_TEMPLATE_CHOOSE = `${API_ROOT_URL}/admin/route-templates/choose`;
const ROUTE_CHOOSE = `${API_ROOT_URL}/admin/route/choose`;
const GET_ROUTE_DRIVERS = `${API_ROOT_URL}/admin/route-templates/%s/drivers/choose`;
const GET_ROUTE_VEHICLES = `${API_ROOT_URL}/admin/route-templates/%s/vehicles/choose`;
const ROUTE_ASSIGN = `${API_ROOT_URL}/admin/route/assign`;
const REPORT_WAREHOUSE_URL = `${API_ROOT_URL}/report/admin/report_warehouse`;
const GET_DRIVER_VEHICLES = `${API_ROOT_URL}/drivers/%s/vehicles/choose`;

export const getRouteTemplate = (id) =>
  api.get(sprintf(ROUTE_TEMPLATE_CRUD_WITH_ID, id));
export const getRouteDrivers = (id) =>
  api.get(sprintf(GET_ROUTE_DRIVERS, id), { params: { size: 200 } });
export const getRouteVehicles = (id) =>
  api.get(sprintf(GET_ROUTE_VEHICLES, id), { params: { size: 200 } });
export const getDriverVehicles = (id) =>
  api.get(sprintf(GET_DRIVER_VEHICLES, id), { params: { size: 200 } });

export const updateRouteTemplate = (id, body) =>
  api.put(sprintf(ROUTE_TEMPLATE_CRUD_WITH_ID, id), { body });
export const createRouteTemplate = (body) =>
  api.post(ROUTE_TEMPLATE_CRUD, { body });
export const getRouteTemplates = (page, size) =>
  api.get(ROUTE_TEMPLATE_CRUD, { params: { page, size } });
export const deleteRouteTemplate = (id) =>
  api.delete(sprintf(ROUTE_TEMPLATE_CRUD_WITH_ID, id));
export const getRouteChoose = () =>
  api.get(ROUTE_CHOOSE, { params: { size: 200 } });
export const getRouteTemplatesChoose = () =>
  api.get(ROUTE_TEMPLATE_CHOOSE, { params: { size: 200 } });
export const routeAssign = (values) =>
  api.post(ROUTE_ASSIGN, {
    body: {
      containers: _.get(values, "order_barcodes"),
      route_id: _.get(values, "route.id"),
    },
  });
export const getReportWarehouses = (params) =>
  api.get(REPORT_WAREHOUSE_URL, {
    params,
  });
