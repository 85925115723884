import React from "react";
import { Map, fromJS } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { findPhoneCodeByCountryName } from "../../helpers/FormatUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { RESIDENTIAL } from "../../constants/AddressType";
import { ADDRESS_BOOK_LIST_NEW_URL } from "../../constants/AdminPathConstants";
import { createAddressBook } from "../../api/shared/AdminAddressBookV2Api";
import AdminAddressBookItemWrapper from "../../wrappers/admin/AdminAddressBookItemWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      baseCountry: getMarketplaceCountry(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminAddressBookCreateNew.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  baseCountry: PropTypes.instanceOf(Map),
};

function AdminAddressBookCreateNew(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "create_address_book",
        "Create Address Book",
      )}
    >
      <AdminAddressBookItemWrapper
        initialValues={{
          country: props.baseCountry.toJS(),
          addressType: RESIDENTIAL,
          phoneCode: fromJS({
            code: findPhoneCodeByCountryName(props.baseCountry.get("name")),
          }),
          markLocation: {
            lat: "",
            lng: "",
            country: props.baseCountry.toJS(),
            address: "",
          },
        }}
        onSubmit={values => {
          const data = toSnakeCase(values);
          createAddressBook({
            ...data,
            lat: values.markLocation.lat,
            lon: values.markLocation.lng,
            address: values.address,
            neighborhood: values.neighborhood,
            phone_code: values.phoneCode.get("code"),
            phone: values.phoneCode.get("code") + values.phone,
          })
            .catch(ResponseError.throw)
            .then(res => {
              if (res.status === "success") {
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_saved",
                    "Successfully saved",
                  ),
                );
                props.setLocation(ADDRESS_BOOK_LIST_NEW_URL);
              } else {
                props.showErrorMessage(res.message);
              }
            });
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminAddressBookCreateNew);
