import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { spring, Motion } from "react-motion";
import { Marker, MarkerIcon } from "react-google-map-components";
import { pureComponent } from "../../helpers/HOCUtils";

const vehiclePathUrl = (angle) =>
  `https://shipox.com/wp-content/uploads/2021/02/vehicle.svg?rotation=${angle}`;

const springConfig = { stiffness: 10, damping: 40 };

const DEFAULT = "default";
const enhancer = pureComponent(fp.pick(["rotation", "position"]));

class VehicleMarker2 extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.rotation !== this.props.rotation) {
      const img = document.querySelector(
        `[src="${vehiclePathUrl(prevProps.rotation)}"]`,
      );
      if (img) {
        img.style.transform = `rotate(${this.props.rotation}deg)`;
        img.style.transition = "all 0.3s ease-in-out";
      }
    }
  }

  render() {
    return this.props.withMotion ? (
      <Motion
        style={{
          rotation: spring(this.props.rotation),
          lat: spring(this.props.position.lat, springConfig),
          lng: spring(this.props.position.lng, springConfig),
        }}
      >
        {(x) => (
          <Marker
            onClick={this.props.onClick}
            position={{ lat: x.lat, lng: x.lng }}
            icon={
              <MarkerIcon
                anchor={{ y: this.props.size, x: this.props.size / 2 }}
                size={{ width: 64, height: 64 }}
                scaledSize={{
                  width: 2 * this.props.size,
                  height: 2 * this.props.size,
                }}
                url={vehiclePathUrl(this.props.rotation)}
              />
            }
          />
        )}
      </Motion>
    ) : (
      <Marker
        onClick={this.props.onClick}
        position={this.props.position}
        icon={
          <MarkerIcon
            anchor={{ y: this.props.size / 2, x: this.props.size / 2 }}
            size={{ width: 256, height: 256 }}
            scaledSize={{ width: this.props.size, height: this.props.size }}
            url={vehiclePathUrl(this.props.rotation)}
          />
        }
      />
    );
  }
}

VehicleMarker2.propTypes = {
  onClick: PropTypes.func,
  rotation: PropTypes.number,
  position: PropTypes.object.isRequired,
  size: PropTypes.number,
  withMotion: PropTypes.bool,
  busy: PropTypes.oneOf([true, false, DEFAULT]),
  icon: PropTypes.element,
};

VehicleMarker2.defaultProps = {
  rotation: 0,
  size: 32,
  withMotion: true,
  busy: DEFAULT,
};

export default enhancer(VehicleMarker2);
