import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import DataList, { DataListColumn } from "../data-list/DataList";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
);

TagList.propTypes = {
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  totalCount: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  overscanRowCount: PropTypes.number,
  rowCount: PropTypes.number,
  rowGetter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  cardActionIcons: PropTypes.node,
  i18n: PropTypes.instanceOf(Map),
};

TagList.defaultProps = {
  overscanRowCount: 6,
};

function TagList(props) {
  const { i18n, location } = props;
  const NA = i18n.get("na", "N/A");

  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      filter={props.filter}
      list={props.list}
      onFilterChange={props.onFilterChange}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.rowCount}
      rowGetter={props.rowGetter}
      cardActionIcons={props.cardActionIcons}
    >
      <DataListColumn
        width={96}
        label={i18n.get("id", "ID")}
        dataKey="id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <strong>
            <Link
              to={updateQuery(location, fp.set("view", row.cellData.get("id")))}
            >
              {row.cellData.get("id")}
            </Link>
          </strong>
        )}
      />

      <DataListColumn
        width={128}
        label={i18n.get("tag_name", "Tag Name")}
        dataKey="name"
        disableSort={true}
        cellRenderer={row => <div>{row.cellData.get("name") || NA}</div>}
      />

      <DataListColumn
        width={128}
        label={i18n.get("description", "Description")}
        dataKey="description"
        disableSort={true}
        cellRenderer={row => <div>{row.cellData.get("description") || NA}</div>}
      />
    </DataList>
  );
}

export default enhancer(TagList);
