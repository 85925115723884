import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedSalesUser,
  getSalesUserPredictions,
} from "../../api/admin/AdminUserApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const UserAutoComplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ input: { value }, ...props }) =>
      value && value.id && !value.name
        ? props
            .getCachedSalesUser(value.id)
            .map(item => ({ id: value.id, name: item.get("full_name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value.name"))
    .switchMap(({ input: { value }, ...props }) =>
      props
        .getSalesUserPredictions(baseFilter.setSearch(value && value.name))
        .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
        .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS());

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    (
      { getCachedSalesUser: test, getSalesUserPredictions: test1, ...props },
      value,
      options,
    ) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ),
  );
});

FormSalesRepresentativeUserAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  getCachedSalesUser: PropTypes.func,
  getSalesUserPredictions: PropTypes.func,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormSalesRepresentativeUserAutoComplete.defaultProps = {
  maxSearchResults: 10,

  getCachedSalesUser,
  getSalesUserPredictions,
};

export default function FormSalesRepresentativeUserAutoComplete(props) {
  return <Field {...props} component={UserAutoComplete} />;
}
