import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-google-map-components";
import {
  Marker as LMarker,
  MarkerIcon as LMarkerIcon,
} from "react-leflet-map-components";
import PinMarker from "./assets/pin.svg";

const enhancer = compose();

OrderPin.propTypes = {
  size: PropTypes.number,
  mapType: PropTypes.oneOf(["google_map", "leaflet_map"]),
  position: PropTypes.object.isRequired,
};

OrderPin.defaultProps = {
  size: 32,
  mapType: "google_map",
};

function OrderPin(props) {
  const { size, mapType, ...markerProps } = props;

  if (mapType === "leaflet_map") {
    return (
      <LMarker
        {...markerProps}
        icon={
          <LMarkerIcon
            anchor={{ y: size, x: size / 2 }}
            size={{ width: 60, height: 60 }}
            scaledSize={{ width: size, height: size }}
            url={PinMarker}
            origin={{ x: size, y: size / 2 }}
          />
        }
      />
    );
  }

  return (
    <Marker
      {...markerProps}
      icon={
        <MarkerIcon
          anchor={{ y: size, x: size / 2 }}
          size={{ width: 60, height: 60 }}
          scaledSize={{ width: size, height: size }}
          url={PinMarker}
        />
      }
    />
  );
}

export default enhancer(OrderPin);
