import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import sprintf from "sprintf";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { dateToMinutes, minutesToDate } from "../../helpers/DateUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  SHOP_VENUE_ITEM_URL,
  SHOP_VENUE_LIST_URL,
  SHOP_ADDRESS_LIST_URL,
  SHOP_PRODUCT_LIST_URL,
  SHOP_VENUE_MENU_LIST_URL,
} from "../../constants/AdminPathConstants";
import { getVenueItem, updateVenueItem } from "../../api/admin/AdminVenueApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminVenueForm from "../../components/admin/AdminVenueForm";
import PageLoading from "../../components/ui-core/PageLoading";
import RoutingTabs from "../../components/deprecated/RoutingTabs";

const enhancer = compose(
  connect(null, { showErrorMessage, showSuccessMessage }),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const venueIdStream = propsStream.map(
          fp.flow(fp.get("params.id"), fp.toFinite),
        );

        return propsStream
          .combineLatest(venueIdStream, (props, venueId) => ({
            ...props,
            venueId,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const venueItemResponseStream = propsStream
          .distinctUntilKeyChanged("venueId")
          .switchMap(props =>
            getVenueItem(props.venueId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            venueItemResponseStream,
            (props, venueItemResponse) => ({
              ...props,
              venueItem: venueItemResponse.get("payload"),
              isLoading: venueItemResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminShopVenueItem.propTypes = {
  venueId: PropTypes.number,
  venueItem: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function AdminShopVenueItem(props) {
  const venueHours = props.venueItem.get("business_hours")
    ? toCamelCase(props.venueItem.get("business_hours"))
    : {
        sunday: { openTime: 240, closeTime: 780 },
        monday: { openTime: 240, closeTime: 780 },
        tuesday: { openTime: 240, closeTime: 780 },
        wednesday: { openTime: 240, closeTime: 780 },
        thursday: { openTime: 240, closeTime: 780 },
        friday: { openTime: 240, closeTime: 780 },
        saturday: { openTime: 240, closeTime: 780 },
      };

  return (
    <AdminAppLayout
      title={
        props.isLoading ? "Loading..." : `Item - ${props.venueItem.get("name")}`
      }
    >
      <PageLoading isLoading={props.isLoading} />
      <RoutingTabs
        width={300}
        tabs={[
          {
            label: "Venue Details",
            value: SHOP_VENUE_ITEM_URL + props.venueItem.get("id"),
          },
          {
            label: "Menus",
            value: sprintf(SHOP_VENUE_MENU_LIST_URL, props.venueItem.get("id")),
          },
          {
            label: "Products",
            value: sprintf(SHOP_PRODUCT_LIST_URL, props.venueItem.get("id")),
          },
          {
            label: "Addresses",
            value: sprintf(SHOP_ADDRESS_LIST_URL, props.venueItem.get("id")),
          },
        ]}
        omitQueryParams={["page"]}
      />
      <AdminVenueForm
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        onSubmit={({ location, merchant, businessHours, ...values }) =>
          updateVenueItem(props.venueId, {
            ...toSnakeCase(values),
            lat: _.get(location, "lat"),
            lon: _.get(location, "lng"),
            merchant_id: _.get(merchant, "id"),
            business_hours: {
              sunday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "sunday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "sunday.closeTime"),
                ),
              },
              monday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "monday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "monday.closeTime"),
                ),
              },
              tuesday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "tuesday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "tuesday.closeTime"),
                ),
              },
              wednesday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "wednesday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "wednesday.closeTime"),
                ),
              },
              thursday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "thursday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "thursday.closeTime"),
                ),
              },
              friday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "friday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "friday.closeTime"),
                ),
              },
              saturday: {
                open_time: dateToMinutes(
                  _.get(businessHours, "saturday.openTime"),
                ),
                close_time: dateToMinutes(
                  _.get(businessHours, "saturday.closeTime"),
                ),
              },
            },
          }).catch(ResponseError.throw)
        }
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.setLocation(SHOP_VENUE_LIST_URL);
        }}
        onDismissClick={() => props.setLocation(SHOP_VENUE_LIST_URL)}
        initialValues={{
          ...toCamelCase(props.venueItem),
          merchant: { id: props.venueItem.get("merchant_id") },
          status: _.lowerCase(props.venueItem.get("status")),
          location: {
            lat: props.venueItem.get("lat"),
            lng: props.venueItem.get("lon"),
          },
          businessHours: {
            sunday: {
              openTime: minutesToDate(_.get(venueHours, "sunday.openTime")),
              closeTime: minutesToDate(_.get(venueHours, "sunday.closeTime")),
            },
            monday: {
              openTime: minutesToDate(_.get(venueHours, "monday.openTime")),
              closeTime: minutesToDate(_.get(venueHours, "monday.closeTime")),
            },
            tuesday: {
              openTime: minutesToDate(_.get(venueHours, "tuesday.openTime")),
              closeTime: minutesToDate(_.get(venueHours, "tuesday.closeTime")),
            },
            wednesday: {
              openTime: minutesToDate(_.get(venueHours, "wednesday.openTime")),
              closeTime: minutesToDate(
                _.get(venueHours, "wednesday.closeTime"),
              ),
            },
            thursday: {
              openTime: minutesToDate(_.get(venueHours, "thursday.openTime")),
              closeTime: minutesToDate(_.get(venueHours, "thursday.closeTime")),
            },
            friday: {
              openTime: minutesToDate(_.get(venueHours, "friday.openTime")),
              closeTime: minutesToDate(_.get(venueHours, "friday.closeTime")),
            },
            saturday: {
              openTime: minutesToDate(_.get(venueHours, "saturday.openTime")),
              closeTime: minutesToDate(_.get(venueHours, "saturday.closeTime")),
            },
          },
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminShopVenueItem);
