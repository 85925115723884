import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  isCustomMarketplace,
  getMarketplaceReceiveOrderFromWing,
  getMarketplaceDefaultBatchUpdateStatuses,
} from "../../reducers/MarketplaceReducer";
import AdminCODCodes from "../../constants/AdminCODCodes";
import OrderStatusCodes, {
  OTHER_REASON,
  OUT_FOR_RETURN,
} from "../../constants/OrderStatusCodes";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import BatchUpdateOrderDialog from "../../components/orders-core/BatchUpdateOrderDialog";

const enhancer = compose(
  connect(state => ({
    isMarketplaceCustom: isCustomMarketplace(state),
    isMarketplaceReceiveOrderFromWing: getMarketplaceReceiveOrderFromWing(
      state,
    ),
    defaultBatchUpdateStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
  })),
);

AdminBatchUpdateOrderDialogWrapper.propTypes = {
  open: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  isMarketplaceReceiveOrderFromWing: PropTypes.bool,
  defaultBatchUpdateStatuses: PropTypes.object,
  isMarketplaceCustom: PropTypes.bool,
  list: PropTypes.any,
  selectedItems: PropTypes.any,
  isSorting: PropTypes.bool,
};

function AdminBatchUpdateOrderDialogWrapper(props) {
  let statuses;
  if (
    props.defaultBatchUpdateStatuses &&
    props.defaultBatchUpdateStatuses.size > 0
  ) {
    statuses = props.defaultBatchUpdateStatuses;
  } else if (
    !props.isMarketplaceCustom ||
    !props.isMarketplaceReceiveOrderFromWing
  ) {
    statuses = OrderStatusCodes;
  } else {
    statuses = OrderStatusCodes.delete(OUT_FOR_RETURN);
  }
  return (
    <BatchUpdateOrderDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      codCodes={AdminCODCodes}
      statusCodes={statuses.delete(OTHER_REASON)}
      withInTransitToSupplier={true}
      privacyTypes={AdminPrivacyTypes}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      initialValues={props.initialValues}
      selectedItems={props.selectedItems}
      list={props.list}
      isSorting={props.isSorting}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}

export default enhancer(AdminBatchUpdateOrderDialogWrapper);
