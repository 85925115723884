import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  DISPATCH_RECEPTACLE_LABEL_PDF_URL,
  DISPATCH_RECEPTACLE_SPECIAL_LIST_PDF_URL,
  getReceptacleItem,
} from "../../api/admin/AdminIPSApi";
import { getValue } from "../../helpers/DataUtils";
import { RENDER } from "../orders-core/MUITable";
import _ from "lodash";
import ReceptacleMailItemsTable from "../orders-core/ReceptacleMailItemsTable";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { getIPSPDF } from "../../helpers/FormUtils";
import { IS_DOWNLOADING, IS_PRINTING } from "../orders-core/AdminPostForm";
import { GetApp, Print } from "@material-ui/icons";
import { formatMailCategory } from "../order-create-wizard-new/ips/NewDispatchForm";
import { formatText } from "../../helpers/FormatUtils";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
      },
    },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
);

ReceptacleItemDialog.propTypes = {
  classes: PropTypes.object,

  id: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  open: PropTypes.bool,
  dispatchStatus: PropTypes.bool,
};

function ReceptacleItemDialog(props) {
  const { classes, getLocalisationMessage, id } = props;

  const [receptacle, setReceptacle] = useState({});
  const [mailItems, setMailItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getReceptacleItem(id)
        .then(r => {
          if (r && r.data) {
            setReceptacle(r.data);
            setMailItems(getValue(r, "data.mail_items", []));
            setLoading(false);
          }
        })
        .catch(() => setLoading(false));
    }
  }, [id]);

  const [label, setLabel] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_RECEPTACLE_LABEL_PDF_URL,
  });
  const [specialList, setSpecialList] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DISPATCH_RECEPTACLE_SPECIAL_LIST_PDF_URL,
  });

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={props.open}
      modal={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      bodyClassName={classes.body}
      contentClassName={classes.contentStyle}
      className={classes.rootStyle}
    >
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <FlexBox align="center" direction="row">
          <FlexBox flex={true} gutter={8}>
            {getValue(receptacle, "receptacle_id_string", "")}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <FlexBox style={{ gap: 20 }} direction="column">
          <FlexBox container={24} flex={true} direction="column">
            <FlexBox gutter={8} justify="align" align="center">
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage(
                    "origin_office",
                    "Origin Office",
                  )}
                  secondary={getValue(
                    receptacle,
                    "receptacle_id.origin_office",
                  )}
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage(
                    "destination_office",
                    "Destination Office",
                  )}
                  secondary={getValue(
                    receptacle,
                    "receptacle_id.destination_office",
                  )}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage(
                    "mail_category",
                    "Mail category",
                  )}
                  secondary={formatMailCategory(
                    getValue(receptacle, "receptacle_id.mail_category"),
                    getLocalisationMessage,
                  )}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage(
                    "mail_subclass",
                    "Mail Subclass",
                  )}
                  secondary={getValue(
                    receptacle,
                    "receptacle_id.mail_sub_class",
                  )}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("status", "Status")}
                  secondary={formatText(getValue(receptacle, "status"))}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("identifier", "Identifier")}
                  secondary={getValue(receptacle, "receptacle_id_string", "")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} className={classes.wrapContent} align="center">
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage(
                    "total_weight",
                    "Total Weight",
                  )}
                  secondary={getValue(receptacle, "weight", 0)}
                />
              </FlexBox>
              {props.dispatchStatus &&
                getValue(receptacle, "status") === "RC_CLOSE" && (
                  <FlexBox
                    style={{ gap: 13 }}
                    justify="flex-end"
                    flex={true}
                    align="center"
                  >
                    <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                      <CustomButton
                        data-cy="receipt_print"
                        onClick={() =>
                          getIPSPDF(
                            specialList,
                            setSpecialList,
                            IS_PRINTING,
                            getValue(receptacle, "id"),
                            props.showErrorMessage,
                          )
                        }
                        endIcon={
                          specialList[IS_PRINTING] ? (
                            <CircularProgress size={20} color="white" />
                          ) : (
                            <Print />
                          )
                        }
                      >
                        {getLocalisationMessage("sn_33", " CN 33")}
                      </CustomButton>
                      <CustomButton
                        data-cy="receipt_download"
                        onClick={() =>
                          getIPSPDF(
                            specialList,
                            setSpecialList,
                            IS_DOWNLOADING,
                            getValue(receptacle, "id"),
                            props.showErrorMessage,
                          )
                        }
                      >
                        {specialList[IS_DOWNLOADING] ? (
                          <CircularProgress size={20} color="white" />
                        ) : (
                          <GetApp />
                        )}
                      </CustomButton>
                    </ButtonGroup>

                    <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                      <CustomButton
                        data-cy="receipt_print"
                        onClick={() =>
                          getIPSPDF(
                            label,
                            setLabel,
                            IS_PRINTING,
                            getValue(receptacle, "id"),
                            props.showErrorMessage,
                          )
                        }
                        endIcon={
                          label[IS_PRINTING] ? (
                            <CircularProgress size={20} color="white" />
                          ) : (
                            <Print />
                          )
                        }
                      >
                        {getLocalisationMessage("cn_35", " CN 35")}
                      </CustomButton>
                      <CustomButton
                        data-cy="receipt_download"
                        onClick={() =>
                          getIPSPDF(
                            label,
                            setLabel,
                            IS_DOWNLOADING,
                            getValue(receptacle, "id"),
                            props.showErrorMessage,
                          )
                        }
                      >
                        {label[IS_DOWNLOADING] ? (
                          <CircularProgress size={20} color="white" />
                        ) : (
                          <GetApp />
                        )}
                      </CustomButton>
                    </ButtonGroup>
                  </FlexBox>
                )}
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            <Divider style={{ width: "100%", height: 1 }} />
          </FlexBox>

          <ReceptacleMailItemsTable
            list={mailItems}
            isLoading={loading}
            columns={[
              {
                type: RENDER,
                name: "item_id",
                render: row => _.get(row, `item_id`, ""),
                label: getLocalisationMessage("barcode"),
              },
              {
                type: RENDER,
                name: "destination_country",
                label: getLocalisationMessage(
                  "destination_country",
                  "Destination Country",
                ),
                render: row => _.get(row, `destination_country_code`, "N/A"),
              },
              {
                type: RENDER,
                name: "origin_country",
                label: getLocalisationMessage(
                  "origin_country",
                  "Origin Country",
                ),
                render: row => _.get(row, `origin_country_code`, "N/A"),
              },
              {
                type: RENDER,
                name: "postal_status",
                label: getLocalisationMessage("postal_status", "Postal Status"),
                render: row => _.get(row, `postal_status`, "N/A"),
              },
              {
                type: RENDER,
                name: "value",
                label: getLocalisationMessage("valuable", "Valuable"),
                render: row => _.get(row, `value`, "N/A"),
              },
              {
                type: RENDER,
                name: "weight",
                label: getLocalisationMessage("weight", "Weight"),
                render: row => _.get(row, `weight`, ""),
              },
            ]}
          />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant={OUTLINED}
          color={SECONDARY}
          onClick={() => {
            props.onRequestClose();
            setReceptacle({});
            setMailItems([]);
          }}
        >
          {getLocalisationMessage("close", "Close")}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ReceptacleItemDialog);
