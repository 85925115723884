import React, { useEffect } from "react";
import FlexBox from "../../components/ui-core/FlexBox";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { Check } from "@material-ui/icons";
import PropTypes from "prop-types";

ReasonComponentButtons.propTypes = {
  classes: PropTypes.object,
  reasons: PropTypes.array,
  orderNumber: PropTypes.array,
  isSearching: PropTypes.bool,
  selectedReason: PropTypes.string,
  onSetReason: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function ReasonComponentButtons(props) {
  const {
    classes,
    reasons,
    onSetReason,
    selectedReason,
    getLocalisationMessage,
    isSearching,
  } = props;

  const onKeyPress = event => {
    if (event.code.includes("Numpad") && event.charCode !== 48) {
      onSetReason(reasons[event.key - 1]);
    }
  };

  useEffect(() => {
    if (!isSearching) {
      document.addEventListener("keypress", onKeyPress);
    } else {
      document.removeEventListener("keypress", onKeyPress);
    }

    return () => {
      document.removeEventListener("keypress", onKeyPress);
    };
  }, [isSearching]);
  return (
    <FlexBox direction="row" flex={true} wrap={true} className={classes.reason}>
      {reasons.map((reason, index) => (
        <FlexBox key={index}>
          <CustomButton
            size="small"
            disabled={!(props.orderNumber && props.orderNumber.length > 0)}
            onClick={() => onSetReason(reason)}
            variant={reason === selectedReason ? CONTAINED : OUTLINED}
            color={SECONDARY}
          >
            <span
              style={{
                width: 21,
                height: 20,
                border:
                  reason === selectedReason
                    ? "1px solid white"
                    : "1px solid rgba(25, 118, 210, 0.5)",
                borderRadius: 6,
              }}
            >
              {index + 1}
            </span>
            <span>
              {formatOrderStatusCodeForLocalisation(
                reason,
                getLocalisationMessage,
              )}
            </span>
            {reason === selectedReason ? (
              <Check />
            ) : (
              <span
                style={{
                  width: 21,
                  height: 20,
                }}
              />
            )}
          </CustomButton>
        </FlexBox>
      ))}
    </FlexBox>
  );
}

export default ReasonComponentButtons;
