import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import ModalPaper from "../ui-core/ModalPaper";
import FlexBox from "../ui-core/FlexBox";
import { Close, List, Save } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { IconButton, makeStyles } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import MUITable, { ACTION_WITH_INDEX, INDEX, RENDER } from "./MUITable";
import _ from "lodash";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";

const useStyles = makeStyles({
  root: {
    padding: "16px 20px 20px",
  },
  cardTitle: {
    padding: "18px 24px",
    gap: 8,
    width: 195,
    height: 57,
    background: "rgba(25, 118, 210, 0.1)",
    borderRadius: "4px",
  },
  list: {
    gap: 8,
    width: 80,
    height: 20,
  },
  listIcon: {
    width: 20,
    height: 20,
    color: "#1976D2",
  },
  cardText: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 400,
  },
  listNumber: {
    textTransform: "uppercase",
    fontSize: 18,
    fontWeight: 500,
    width: 11,
    height: 21,
  },
  table: {
    height: "40vh",
    "& .MuiTableContainer-root": {
      height: "40vh",
      overflow: "auto",
    },
  },
  saveButton: {
    "& .MuiButton-containedSecondary": {
      backgroundColor: "#27AE60",
    },
  },
});

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
);

SimpleOrderConfirmDialog.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func,
  change: PropTypes.func,
  onSubmit: PropTypes.func,
  orders: PropTypes.array,
  open: PropTypes.bool,
  orderType: PropTypes.bool,
};

function SimpleOrderConfirmDialog(props) {
  const classes = useStyles();

  return (
    <ModalPaper
      open={props.open}
      title={props.i18n.get("confirm_your_order", "Confirm your Orders")}
      autoscrollbodycontent={false}
      onRequestClose={props.onRequestClose}
      rightIcon={
        <IconButton onClick={props.onRequestClose}>
          <Close />
        </IconButton>
      }
    >
      <Card className={classes.root}>
        <CardContent>
          <FlexBox style={{ gap: 24 }} direction="column">
            <FlexBox
              justify="space-between"
              align="center"
              className={classes.cardTitle}
            >
              <FlexBox className={classes.list}>
                <List className={classes.listIcon} />
                <Typography className={classes.cardText}>
                  {props.i18n.get("total")}
                </Typography>
              </FlexBox>
              <FlexBox className={classes.listNumber}>
                {props.orders.length}
              </FlexBox>
            </FlexBox>
            <FlexBox className={classes.table}>
              <MUITable
                list={props.orders}
                withoutPagination={true}
                columns={[
                  {
                    type: INDEX,
                    label: "#",
                    alignItems: "center",
                  },
                  {
                    type: RENDER,
                    name: "barcode",
                    render: row => _.get(row, `barcode`, ""),
                    label: props.i18n.get("barcode"),
                  },
                  {
                    type: RENDER,
                    name: "service_type",
                    render: row =>
                      props.i18n.get(
                        _.get(row, `service_type.code`, "").toLowerCase(),
                      ),
                    label: props.i18n.get("service_type"),
                  },
                  {
                    type: RENDER,
                    name: "sender_jurisdiction",
                    label: props.i18n.get("sender_jurisdiction"),
                    render: row =>
                      _.get(row, `sender_data.jurisdiction.name`, ""),
                  },
                  {
                    type: RENDER,
                    name: "recipient_jurisdiction",
                    label: props.i18n.get("recipient_jurisdiction"),
                    render: row =>
                      _.get(row, `recipient_data.jurisdiction.name`, ""),
                  },
                  {
                    type: ACTION_WITH_INDEX,
                    name: "remove",
                    label: props.i18n.get("remove"),
                    dispatch: index => {
                      // eslint-disable-next-line no-unused-expressions
                      props.orderType
                        ? props.change("unRegisteredOrders", [
                            ...props.orders.slice(0, index),
                            ...props.orders.slice(index + 1),
                          ])
                        : props.change("orders", [
                            ...props.orders.slice(0, index),
                            ...props.orders.slice(index + 1),
                          ]);
                    },
                  },
                ]}
              />
            </FlexBox>
          </FlexBox>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <FlexBox style={{ gap: 16 }}>
            <CustomButton
              variant={OUTLINED}
              color={SECONDARY}
              onClick={props.onRequestClose}
            >
              {props.i18n.get("dismiss", "Dismiss")}
            </CustomButton>

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              startIcon={<Save />}
              onClick={props.onSubmit}
              disabled={!(props.orders && props.orders.length > 0)}
            >
              {props.i18n.get("save", "Save")}
            </CustomButton>
          </FlexBox>
        </CardActions>
      </Card>
    </ModalPaper>
  );
}

export default enhancer(SimpleOrderConfirmDialog);
