import {
  FETCH_PERMISSION_LIST,
  FETCH_ROLES_LIST,
  FETCH_ROLE_BY_ID,
} from "../reducers/AdminPermissionManagementReducer";
import { API_V2_ROOT_URL } from "../../shared/constants/ApiConstants";

const PERMISSIONS_LIST_URL = `${API_V2_ROOT_URL}/admin/permission`;
const ROLE_LIST_URL = `${API_V2_ROOT_URL}/admin/role`;

export function fetchPermissionList() {
  return (dispatch, getState, api) =>
    dispatch({
      type: FETCH_PERMISSION_LIST,
      payload: api.get(PERMISSIONS_LIST_URL).then(data => ({ data })),
    });
}

export function fetchRoleList(filters) {
  return (dispatch, getState, api) =>
    dispatch({
      type: FETCH_ROLES_LIST,
      payload: api
        .get(ROLE_LIST_URL, { timeout: 10 * 60 * 1000, params: filters })
        .then(data => ({ data })),
    });
}

export function fetchRoleById(id) {
  return (dispatch, getState, api) =>
    dispatch({
      type: FETCH_ROLE_BY_ID,
      payload: api.get(`${ROLE_LIST_URL}/${id}`).then(data => ({ data })),
    });
}

export function deleteRoleById(id, filters) {
  return (dispatch, getState, api) => {
    api
      .deleteStream(`${ROLE_LIST_URL}/${id}`)
      .do(() => dispatch(fetchRoleList(filters)))
      .subscribe();
  };
}

export function createRole(role, goBack) {
  return (dispatch, getState, api) =>
    api.post(ROLE_LIST_URL, { body: role }).then(() => {
      if (goBack) goBack();
    });
}

export function updateRole(id, role, goBack) {
  return (dispatch, getState, api) =>
    api.put(`${ROLE_LIST_URL}/${id}`, { body: role }).then(() => {
      if (goBack) goBack();
    });
}
