import { Observable } from "rxjs";
// eslint-disable-next-line import/no-internal-modules
import { bufferCount, concatMap, delay, switchMap } from "rxjs/operators";
import React from "react";
import { Map, Set, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  CircularProgress,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import {
  loadAllValues,
  mapListResponseStream,
  mapObjectResponseStream,
} from "../../../helpers/ApiUtils";
import { pureComponent } from "../../../helpers/HOCUtils";
import { isEqualData, toJS } from "../../../helpers/DataUtils";
import {
  mergeSideEffectStreams,
  pipeStreams,
} from "../../../helpers/StreamUtils";
import { captureException } from "../../../helpers/ErrorTracker";
import DataListFilter from "../../../helpers/DataListFilter";
import {
  createOrderRegistrySortingBinCreator,
  isExpiredOrderRecord,
  TYPE_SHIPMENT,
} from "../../../helpers/OrderOutboundSortingHelper";
import { OrderSortingDB } from "../../../firebase/OrderSortingDB";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  addSortingTaskOrderBarcodes,
  addSortingTaskOrderNumbers,
  cacheOrderSortingOrders,
  clearOrderSortingOrders,
  getOrderSortingOrders,
  getOrderSortingTask,
  clearOrderSortingRegistries,
  updateSortingTask,
} from "../../../reducers/OrderOutboundSortingReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  IN_SORTING_FACILITY,
  PREPARED_FOR_TRANSIT,
} from "../../../constants/OrderStatusCodes";
import {
  batchUpdateOrderWarehouse,
  getBatchChildren,
  getOrderList,
  getOrderObject,
} from "../../../api/admin/AdminOrderApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import FormWarehouseDialog from "../../../components/form/FormWarehouseDialog";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import NavigationPrompt from "../../../components/router/NavigationPrompt";
import FlexBox from "../../../components/ui-core/FlexBox";
import PageLoading from "../../../components/ui-core/PageLoading";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import FirebaseOfflineDialog from "../../../components/firebase/FirebaseOfflineDialog";
import ConfirmDialog from "../../../components/deprecated/ConfirmDialog";
import Notification from "../../../components/notifications/Notification";
import { getTokenUserId } from "../../../../shared/reducers/AuthReducer";
import CustomButton from "../../../components/ui-core/CustomButton";
import {
  getUserWarehouse,
  getUserWarehousesIds,
} from "../../../reducers/ProfileReducer";
import { get } from "lodash";
import { getBatchOrderUpdateList } from "../../../api/admin/AdminBatchApi";
import AdminOrderOutboundRegistrySortingTableContainer from "./AdminOrderOutboundRegistrySortingTableContainer";
import OrderSortingRuleListDialog from "../../../components/order-outbound-sorting/OrderSortingRuleListDialog";
import ResponseError from "../../../helpers/ResponseError";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../../api/shared/CountryV2Api";
import OrderSortingExportRulesDialog from "../../../components/order-outbound-sorting/OrderSortingExportRulesDialog";
import OrderSortingImportRulesDialog from "../../../components/order-outbound-sorting/OrderSortingImportRulesDialog";
import { validateUserWarehouse } from "../../../helpers/OrderSortingHelper";

const baseFilter = new DataListFilter({
  size: 200,
  page: 0,
  status: PREPARED_FOR_TRANSIT,
  detailed: true,
});

const getAllPreparedRegistries = loadAllValues(getBatchOrderUpdateList);

const shipmentStatusUpdate = (props) => {
  const { db, id, task } = props;

  return Observable.defer(() =>
    batchUpdateOrderWarehouse(task.shipment_status_update),
  )
    .switchMap(() => db.removeRegistryTask(id))
    .catch(() => db.removeRegistryTask(id));
};

const getRegistryChildrenAndAddToWarehouse = (props) => {
  const { db, id, order } = props;
  const orderNumber = order.getIn(["info", "barcode"]);

  return Observable.defer(() => getBatchChildren(order.getIn(["info", "id"])))
    .switchMap(({ children }) => {
      const values = {};
      if (children) {
        children.forEach((child) => {
          values[`${child.barcode}/number`] = child.barcode;
        });
      }

      const childArray = children.map((child) => child.barcode);
      return Observable.merge(
        db.updateShipmentStatus(orderNumber, {
          order_numbers: childArray,
          order_status: IN_SORTING_FACILITY,
          warehouse: { id: props.warehouseId },
        }),
        db.batchUpdateOrders(values),
        db.batchRemoveRegistries([orderNumber]),
        db.removeRegistryTask(id),
      );
    })
    .catch((error) => db.updateTask(id, { error: error.message }));
};

const enhancer = compose(
  withTheme,
  useSheet({
    counter: { display: "none" },
    mobile: { fontSize: "12px", lineHeight: "20px" },
    sortingStatsHeader: {
      display: "block",
      lineHeight: "10px",
      marginTop: "15px",
    },
    mobileCounter: { display: "initial" },
    cacheServer: {
      display: "block",
      fontSize: "14px",
      paddingTop: "5px",
    },
    "@media (min-width: 998px)": {
      counter: { display: "flex" },
      mobileCounter: { display: "none" },
      cacheServer: {
        fontSize: "18px",
      },
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    (state) => {
      const task = getOrderSortingTask(state);
      const userWarehouse = toJS(getUserWarehouse(state));
      return {
        task,
        userWarehouse,
        userWarehouseId: get(userWarehouse, "id"),
        userWarehousesIds: getUserWarehousesIds(state),
        userId: getTokenUserId(state),
        allOrders: getOrderSortingOrders(state),
        warehouseId: task.getIn(["warehouse", "id"]),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,

      updateSortingTask,

      cacheOrderSortingOrders,
      clearOrderSortingOrders,
      clearOrderSortingRegistries,
      addSortingTaskOrderNumbers,
      addSortingTaskOrderBarcodes,
    },
  ),
  withState("state", "setState", {
    pendingTasks: Set(),
    prefetchPending: false,
    binGenerationPending: false,
    fetchedOrders: 0,
  }),
  withHandlers({
    refreshOrders: (props) => (orderNumbers) => {
      const numbers = Set(orderNumbers);

      return getOrderList(baseFilter.setSearch(numbers.join(",")))
        .takeLast(1)
        .let(mapListResponseStream)
        .map((x) => x.getIn(["payload", "list"]))
        .switchMap((list) => {
          const db = new OrderSortingDB(props.warehouseId);

          const values = {};
          const notLoaded = numbers.asMutable();

          list.forEach((order) => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values[`${orderNumber}/number`] = orderNumber;
              values[`${orderNumber}/failed`] = null;
              values[`${orderNumber}/type`] = TYPE_SHIPMENT;
              values[`${orderNumber}/info`] = order.toJS();
              values[`${orderNumber}/hash`] = order.hashCode();
              values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          notLoaded.forEach((orderNumber) => {
            values[`${orderNumber}/failed`] = true;
          });

          return db.batchUpdateOrders(values);
        });
    },

    searchOrderObjects: (props) => (orderNumbers) => {
      const numbers = Set(orderNumbers);

      return getOrderObject(baseFilter.setValue("barcodes", numbers.join(",")))
        .takeLast(1)
        .let(mapObjectResponseStream)
        .map((x) => x.getIn(["payload"]))
        .switchMap((data) => {
          const batches = data.get("batches", List());

          const db = new OrderSortingDB(props.warehouseId);

          const values = {};
          const notLoaded = numbers.asMutable();

          batches.forEach((order) => {
            const orderNumber = order.get("barcode");

            notLoaded.delete(orderNumber);

            if (numbers.has(orderNumber)) {
              values[`${orderNumber}/number`] = orderNumber;
              values[`${orderNumber}/failed`] = null;
              values[`${orderNumber}/info`] = order.toJS();
              values[`${orderNumber}/hash`] = order.hashCode();
              values[`${orderNumber}/hash_time`] = Date.now();
            }
          });

          notLoaded.forEach((orderNumber) => {
            values[`${orderNumber}/failed`] = true;
          });

          return db.batchUpdateRegistries(values);
        });
    },
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .map(
              fp.pick([
                "warehouseId",
                "userWarehouseIds",
                "updateSortingTask",
                "clearOrderSortingRegistries",
              ]),
            )
            .filter(
              (props) =>
                props.warehouseId > 0 && fp.size(props.userWarehouseIds) > 0,
            )
            .do((props) => {
              if (
                !validateUserWarehouse(
                  props.warehouseId,
                  props.userWarehouseIds,
                )
              ) {
                props.clearOrderSortingRegistries();
                props.updateSortingTask((x) => x.clear());
              }
            }),
        );
        return propsStream.merge(sideEffectsStream);
      },

      /**
       * Step 1 - Load sorting job details from Firebase.
       */
      (propsStream) => {
        const dbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .map((props) => new OrderSortingDB(props.warehouseId));

        const initialState = {
          tasks: Map(),
          orders: Map(),
          binRules: null,
          registryBinRules: null,
          shipments: Map(),
        };

        const stateStream = dbStream
          .switchMap((db: OrderSortingDB) =>
            !db.warehouseId
              ? Observable.of(initialState)
              : Observable.combineLatest(
                  Observable.of(Map()),
                  db.getRegistries(),
                  db.getBinRules(),
                  db.getRegistryBinRules(),
                  db.getOrders(),
                  (tasks, orders, binRules, registryBinRules, shipments) => ({
                    tasks,
                    orders,
                    binRules:
                      registryBinRules && registryBinRules.size > 0
                        ? registryBinRules
                        : binRules,
                    registryBinRules,
                    shipments,
                  }),
                ).startWith(initialState),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          ...state,
        }));
      },
      /**
       * Step 2 - Normalize task values.
       */
      (propsStream) => {
        const taskStream = propsStream
          .map(fp.pick(["task", "orders"]))
          .distinctUntilChanged(isEqualData)
          .map((props) =>
            props.task.withMutations((task) => {
              if (task.get("selectedRegistries")) {
                task.update("selectedRegistries", (selectedRegistries) =>
                  selectedRegistries
                    ? selectedRegistries.filter((x) => props.orders.has(x))
                    : [],
                );
              }
            }),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(taskStream, (props, task) => ({
          ...props,
          task,
        }));
      },

      /**
       * Step 4 - Handle order task requests.
       *
       * 1. Handle order size change request.
       * 1. Handle failed task retry request.
       */
      (propsStream) => {
        const {
          handler: onRetryTaskRequest,
          stream: onRetryTaskRequestStream,
        } = createEventHandler();

        const {
          handler: onCancelTaskRequest,
          stream: onCancelTaskRequestStream,
        } = createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter((props) => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) => {
              const db = new OrderSortingDB(props.warehouseId);

              return mergeSideEffectStreams(
                onRetryTaskRequestStream.mergeMap((taskId) =>
                  db.retryTask(taskId),
                ),
                onCancelTaskRequestStream.mergeMap((taskId) =>
                  db.removeTask(taskId),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream).map((props) => ({
          ...props,
          onRetryTaskRequest,
          onCancelTaskRequest,
        }));
      },
      /**
       * Step 5 - Register side effect workers.
       *
       * 1. Sync removed orders in firebase with reducer.
       * 2. Loads orders without hash.
       * 3. Generates bin names for orders without bin names.
       * 4. Execute order tasks.
       * 5. Prefetch orders by filter and repeat every 20 minutes.
       */
      (propsStream) => {
        const { handler: onOrderSync, stream: onOrderSyncStream } =
          createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter((props) => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap((props) => {
              const db = new OrderSortingDB(props.warehouseId);

              const addStream = db.getRegistryAddStream();
              const changeStream = db.getRegistryChangeStream();
              const removeStream = db.getRegistryRemoveStream();

              const addOrChangeStream = Observable.merge(
                addStream,
                changeStream,
              );

              return mergeSideEffectStreams(
                removeStream
                  .map((x) => x.get("number"))
                  .bufferTime(1000, null, 100)
                  .filter((buffer) => buffer.length > 0)
                  .do((orders) => {
                    props.clearOrderSortingRegistries(Set(orders));
                  }),

                Observable.combineLatest(
                  db.getBinRules(),
                  db.getRegistryBinRules(),
                  (rules, registryRules) => ({ rules, registryRules }),
                )
                  .distinctUntilChanged(isEqualData)
                  .switchMap(({ rules, registryRules }) => {
                    const binRules =
                      registryRules.size > 0 ? registryRules : rules;

                    const createBin =
                      createOrderRegistrySortingBinCreator(binRules);

                    return Observable.of(null).expand(() =>
                      addOrChangeStream
                        .filter(
                          (x) =>
                            x.get("info") &&
                            !x.get("bin") &&
                            !isExpiredOrderRecord(x),
                        )
                        .bufferTime(1000, null, 100)
                        .filter((buffer) => buffer.length > 0)
                        .take(1)
                        .switchMap((orders) => {
                          const values = orders.reduce((acc, order) => {
                            const bin = createBin(order.get("info"));

                            acc[`${order.get("number")}/bin`] = fp.get(
                              "bin",
                              bin,
                            );
                            acc[`${order.get("number")}/code`] = fp.get(
                              "code",
                              bin,
                            );
                            acc[`${order.get("number")}/warehouse`] =
                              fp.get("warehouse", bin) || null;

                            return acc;
                          }, {});

                          return db.batchUpdateRegistries(values);
                        })
                        .catch((error) => {
                          captureException(error);

                          return Observable.of(null);
                        })
                        .delay(1000),
                    );
                  }),

                Observable.of(null).expand(() =>
                  addOrChangeStream
                    .filter((x) => !x.get("failed") && isExpiredOrderRecord(x))
                    .bufferTime(1000, null, 100)
                    .filter((x) => x.length > 0)
                    .take(1)
                    .switchMap((buffer) =>
                      props.searchOrderObjects(
                        buffer.map((x) => x.get("number")),
                      ),
                    )
                    .catch((error) => {
                      captureException(error);

                      return Observable.of(null);
                    })
                    .delay(1000),
                ),

                Observable.of(null).expand(() =>
                  db
                    .getCorruptedRegistryStream()
                    .map(fp.get("key"))
                    .bufferTime(1000, null, 100)
                    .filter((buffer) => buffer.length > 0)
                    .take(1)
                    .switchMap((x) => db.batchRemoveRegistries(x))
                    .catch((error) => {
                      captureException(error);

                      return Observable.of(null);
                    })
                    .delay(1000),
                ),

                db.getRegistryTaskAddStream().mergeMap((response) => {
                  const id = response.key;
                  const payload = response.val();

                  // Remove task if it's corrupted.
                  if (!payload || !payload.task || !payload.number) {
                    return db.removeRegistryTask(id);
                  }

                  // eslint-disable-next-line no-unused-vars
                  const { task, number: orderNumber } = payload;

                  return db
                    .getRegistry(orderNumber)
                    .take(1)
                    .switchMap((order) => {
                      let taskStream;

                      // Remove task if it's order not found or failed to load.
                      if (order.isEmpty() || order.get("failed")) {
                        return db.removeRegistryTask(id);
                      }

                      // Retry to execute task after one second if it's order not loaded.
                      if (!order.getIn(["info", "id"])) {
                        return Observable.timer(1000).switchMap(() =>
                          db.retryTask(id),
                        );
                      }

                      if (task.cancel_registry) {
                        return getRegistryChildrenAndAddToWarehouse({
                          ...props,
                          id,
                          db,
                          order,
                        });
                      }

                      if (task.shipment_status_update) {
                        return shipmentStatusUpdate({ ...props, id, db, task });
                      }

                      // If task found - execute it.
                      // If it's succeed remove it and reload order.
                      // If not update it with error message
                      if (taskStream) {
                        return taskStream
                          .switchMap(() =>
                            Observable.merge(
                              db.removeRegistryTask(id),
                              db.reloadOrder(orderNumber),
                            ),
                          )
                          .catch((error) =>
                            db.updateRegistryTask(id, { error: error.message }),
                          );
                      }

                      // If it's unknown task - remove it.
                      return db.removeRegistryTask(id);
                    });
                }, 5),
              );
            }),
          onOrderSyncStream
            .withLatestFrom(propsStream)
            .filter(([, props]) => props.warehouseId > 0)
            .switchMap(([, props]) => {
              const db = new OrderSortingDB(props.warehouseId);
              props.setState(fp.set("prefetchPending", true));

              return getAllPreparedRegistries(baseFilter)
                .map((response) => response.get("list"))
                .takeLast(1)
                .do((orders) => {
                  props.setState(fp.set("fetchedOrders", orders.size));
                  props.showSuccessMessage(
                    `Получено ${orders.size} отправлений`,
                  );
                })
                .map((orders) => {
                  if (orders.size > 0) {
                    return Observable.from(orders)
                      .pipe(
                        bufferCount(50),
                        concatMap((txn) =>
                          Observable.of(txn).pipe(delay(1000)),
                        ),
                        switchMap((chunkOrders) => {
                          const values = {};
                          chunkOrders.forEach((order) => {
                            const orderNumber = fp.trim(order.get("barcode"));
                            values[`${orderNumber}/number`] = orderNumber;
                            values[`${orderNumber}/failed`] = null;
                            values[`${orderNumber}/info`] = order.toJS();
                            values[`${orderNumber}/hash`] = order.hashCode();
                            values[`${orderNumber}/hash_time`] = Date.now();
                          });

                          if (values) {
                            return db.batchUpdateRegistries(values);
                          }

                          return Observable.of({});
                        }),
                      )
                      .subscribe();
                  }
                  return Observable.of({});
                })
                .do(() => {
                  props.setState(fp.set("prefetchPending", false));
                });
            }),
        );

        return propsStream.merge(sideEffectsStream).map((props) => ({
          ...props,
          onOrderSync,
        }));
      },
      /**
       * Step 6 - Generate order stats.
       *
       * 1. Count failed orders.
       */
      (propsStream) => {
        const statsStream = propsStream
          .map((props) => ({ orders: props.orders }))
          .distinctUntilChanged(isEqualData)
          .map(({ orders }) => {
            const failedOrders = Set().asMutable();

            orders.forEach((order, orderNumber) => {
              if (!order.hasIn(["info", "id"]) && order.get("failed")) {
                failedOrders.add(orderNumber);
              }
            });

            return Map({ failedOrders: failedOrders.size });
          })
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(statsStream, (props, stats) => ({
          ...props,
          stats,
        }));
      },
      /**
       * Step 7 - Collect order task stats.
       */
      (propsStream) => {
        const taskStatsStream = propsStream
          .distinctUntilKeyChanged("tasks", isEqualData)
          .map((props) => {
            const failed = Set().asMutable();
            const pending = Set().asMutable();

            props.tasks.forEach((task, id) => {
              if (task.get("error")) {
                failed.add(task.set("id", id));
              } else {
                pending.add(task.set("id", id));
              }
            });

            return Map({
              failed: failed.asImmutable(),
              pending: pending.asImmutable(),
            });
          });

        return propsStream.combineLatest(
          taskStatsStream,
          (props, taskStats) => ({ ...props, taskStats }),
        );
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "location",
      "state",
      "task",
      "stats",
      "orders",
      "shipments",
      "binRules",
      "registryBinRules",
      "allOrders",
      "taskStats",
      "userWarehousesIds",
      "warehouseId",
    ]),
  ),
);

AdminOrderOutboundRegistrySortingContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  state: PropTypes.object,
  binGeneration: PropTypes.bool,
  warehouseId: PropTypes.number,
  task: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,

  orders: PropTypes.instanceOf(Map),
  shipments: PropTypes.instanceOf(Map),
  binRules: PropTypes.instanceOf(Map),
  registryBinRules: PropTypes.instanceOf(Map),
  taskStats: PropTypes.instanceOf(Map),
  userWarehouse: PropTypes.object,
  onOrderSync: PropTypes.func,
  updateSortingTask: PropTypes.func,
  clearOrderSortingRegistries: PropTypes.func,
  theme: PropTypes.object,

  userWarehousesIds: PropTypes.array,
};

function AdminOrderOutboundRegistrySortingContainer(props) {
  const {
    task,
    state,
    orders,
    taskStats,
    getLocalisationMessage,
    location: { query },
  } = props;

  if (!validateUserWarehouse(props.warehouseId, props.userWarehousesIds)) {
    return (
      <FormWarehouseDialog
        open={true}
        isDisabled={!fp.size(props.userWarehousesIds) > 1}
        initialValues={{ warehouse: props.userWarehouse }}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        includeWarehouses={props.userWarehousesIds}
        onSubmit={(values) => {
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  const db = new OrderSortingDB(props.warehouseId);

  const isLoading = !props.binRules;

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage(
        "order_registry_sorting",
        "Order Registry Sorting",
      )} | ${task.getIn(["warehouse", "name"])}`}
      appBarRightAction={
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          className={props.classes.appBarRightAction}
        >
          {false && (
            <ButtonGroup variant="contained" color="secondary">
              <CustomButton
                disabled={
                  state.prefetchPending ||
                  state.binGeneration ||
                  state.fetchedOrders - orders.size > 0
                }
                onClick={() => {
                  db.clearRegistries().toPromise().then(props.onOrderSync());
                }}
              >
                {state.prefetchPending ||
                state.binGeneration ||
                state.fetchedOrders - orders.size > 0 ? (
                  <FlexBox>
                    {state.fetchedOrders - orders.size > 0 && (
                      <div>
                        {getLocalisationMessage("syncing_orders")}{" "}
                        {state.fetchedOrders - orders.size}
                      </div>
                    )}
                    {state.prefetchPending && <div>Сбор данных...{"  "}</div>}
                    <CircularProgress size={20} color="secondary" />
                  </FlexBox>
                ) : (
                  getLocalisationMessage("sync_registries", "Sync Registries")
                )}
              </CustomButton>
            </ButtonGroup>
          )}

          <MenuButtonMore color={props.theme.palette.appBarTextColor}>
            <MenuItem
              onClick={() =>
                props.setLocationQuery(fp.set("view_rule_list", true))
              }
            >
              {getLocalisationMessage("view_rules", "View Rules")}
            </MenuItem>

            {props.registryBinRules && (
              <div>
                <Divider />

                <MenuItem
                  onClick={() => props.setLocationQuery(fp.set("export", true))}
                >
                  {getLocalisationMessage("export_rules", "Export Rules")}
                </MenuItem>
                <MenuItem
                  onClick={() => props.setLocationQuery(fp.set("import", true))}
                >
                  {getLocalisationMessage("import_rules", "Import Rules")}
                </MenuItem>

                <Divider />
              </div>
            )}

            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("log_out_warehouse", "Log Out Warehouse")}
            </MenuItem>
          </MenuButtonMore>
        </FlexBox>
      }
    >
      <PageLoading isLoading={isLoading} />

      <FirebaseOfflineDialog />

      <NavigationPrompt
        when={state.pendingTasks.size > 0}
        message={[
          `${getLocalisationMessage("there_are", "There are ")} ${
            state.pendingTasks.size
          } ${getLocalisationMessage("unfinished_tasks", "unfinished tasks")}`,
          getLocalisationMessage(
            "are_you_sure_you_want_to_exit",
            "Are you sure you want to exit?",
          ),
        ].join(" ")}
      />

      {query.log_out === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingRegistries();
            props.updateSortingTask((x) => x.clear());
            props.setLocationQuery(fp.unset("log_out"));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      <Notification
        uid="pending_count"
        open={taskStats.get("pending").size > 0}
      >
        {`${taskStats.get("pending").size} ${getLocalisationMessage(
          "pending_tasks",
          "Pending Tasks",
        )}`}
      </Notification>

      {props.registryBinRules && query.export === "true" && (
        <OrderSortingExportRulesDialog
          open={true}
          rules={props.registryBinRules}
          onRequestClose={() => props.setLocationQuery(fp.unset("export"))}
        />
      )}

      {query.import === "true" && (
        <OrderSortingImportRulesDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("import"))}
          onSubmit={(values) => {
            const { data } = values;

            return db
              .setRegistryBinRules(data.bin_rules)
              .toPromise()
              .catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              getLocalisationMessage("rules_imported", "Rules Imported"),
            );
            props.setLocationQuery(fp.unset("import"));
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      {query.view_rule_list === "true" && (
        <OrderSortingRuleListDialog
          open={query.view_rule_list === "true"}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("view_rule_list"))
          }
          initialValues={{ rules: props.registryBinRules }}
          onSubmit={(values) =>
            db
              .setRegistryBinRules(values.rules.toJS())
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("view_rule_list"))
          }
          onSubmitFail={props.showErrorMessage}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
        />
      )}

      <AdminOrderOutboundRegistrySortingTableContainer
        task={props.task}
        orders={props.orders}
        shipments={props.shipments}
        location={props.location}
        warehouseId={props.warehouseId}
        setLocationQuery={props.setLocationQuery}
        setLocation={props.setLocation}
        showErrorMessage={props.showErrorMessage}
        updateSortingTask={props.updateSortingTask}
        binRules={props.binRules}
        registryBinRules={props.registryBinRules}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderOutboundRegistrySortingContainer);
