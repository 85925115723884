import React from "react";
import { Map, OrderedSet } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormTimeField from "../form/FormTimeField";
import FormSelectField from "../form/FormSelectField";
import FormCompanyAutoComplete from "../form/FormCompanyAutoComplete";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormTimeSlotAutoComplete from "../form/FormTimeSlotAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormPostcodeIndexAutoComplete from "../form/FormPostcodeIndexAutoComplete";
import FlexBox, { ALIGN_END } from "../ui-core/FlexBox";
import {
  createDateValidator,
  createFiniteValidator,
  createNotFalsyValidator,
  createNotNilValidator,
  createObjectIdValidator,
} from "../../helpers/FormUtils";
import {
  formatText,
  parseFloat,
  parseInteger,
} from "../../helpers/FormatUtils";
import { formatCourierType } from "../../helpers/OrderHelper";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import DayOfWeek from "../../constants/DayOfWeek";
import AddressType from "../../constants/AddressType";
import CourierTypes from "../../constants/CourierTypes";
import OrderSizeCodes from "../../constants/OrderSizeCodes";
import OrderPayerTypes from "../../constants/OrderPayerTypes";
import TriggerConstants from "../../constants/TriggerConstants";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import {
  COD,
  COMPANY,
  COST,
  COURIER_TYPE,
  CREATED_DATE,
  CREATED_TIME,
  CURRENT_WAREHOUSE,
  CUSTOMER,
  DAY_OF_WEEK,
  DESTINATION_WAREHOUSE,
  HAS_FETCH_ITEMS,
  IS_RETURN,
  ITEM_VALUE,
  JURISDICTION_FROM,
  JURISDICTION_TO,
  LIMIT,
  MERCHANT,
  ORDER_TYPE,
  PARCEL_SIZE,
  PAYMENT_DONE_BY,
  PAYMENT_METHOD,
  PICKUP_WAREHOUSE,
  POSTCODE_FROM,
  POSTCODE_TO,
  PRICE,
  RATING,
  RECIPIENT_ADDRESS,
  RECIPIENT_ADDRESS_TYPE,
  RTO_PRICE,
  SELLER_ID,
  SELLER_NAME,
  SENDER_ADDRESS,
  SENDER_ADDRESS_TYPE,
  SENDER_NAME,
  TIME_SLOT_DROP_OFF,
  TIME_SLOT_PICK_UP,
  TRIGGER,
} from "../../constants/RuleConditionField";
import RuleComparationOperator from "../../constants/RuleComparationOperator";
import RuleConditionOrderTypes from "../../constants/RuleConditionOrderTypes";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import { getValue } from "../../helpers/DataUtils";

const valueSelector = formValueSelector("PackageRuleTree");

const orderRuleFields = OrderedSet.of(
  CUSTOMER,
  PICKUP_WAREHOUSE,
  DESTINATION_WAREHOUSE,
  JURISDICTION_FROM,
  JURISDICTION_TO,
  POSTCODE_FROM,
  POSTCODE_TO,
);

const trueFalseOptions = OrderedSet.of(true, false);
const formatTrueFalseOption = x => (x === true ? "Yes" : "No");

const enhancer = compose(
  connect((state, props) => ({
    ruleType: valueSelector(state, "ruleType"),
    field: valueSelector(state, `${props.name}.field`),
    marketplaceCountry: getMarketplaceCountry(state),
    i18n: getMessages(state),
  })),
);

PackageRuleTreeLeaf.propTypes = {
  field: PropTypes.string,
  name: PropTypes.string.isRequired,
  needsAValidation: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
};

function PackageRuleTreeLeaf(props) {
  const fieldName = `${props.name}.${props.field}-value`;
  const { i18n, needsAValidation } = props;

  return (
    <FlexBox gutter={16} flex={true} align={ALIGN_END}>
      <FlexBox
        style={{
          flex: `${props.field ? "1 1 0%" : "0 1 auto"} 1 auto`,
          width: "32%",
        }}
        align="center"
      >
        <FormSelectField
          maxHeight={320}
          label={i18n.get("field", "Field")}
          formatOption={x => props.i18n.get(x.toLowerCase(), formatText(x))}
          name={`${props.name}.field`}
          fullWidth={true}
          validate={
            needsAValidation &&
            createNotFalsyValidator(i18n.get("select_field", "Select Field"))
          }
          options={orderRuleFields}
        />
      </FlexBox>

      {Boolean(props.field) && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            maxHeight={320}
            label={i18n.get("compare_by", "Compare By")}
            formatOption={x => props.i18n.get(x.toLowerCase(), formatText(x))}
            options={RuleComparationOperator}
            name={`${props.name}.comparationOperator`}
            fullWidth={true}
            validate={
              needsAValidation &&
              createNotFalsyValidator(
                i18n.get("select_comparator", "Select Comparator"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === COMPANY && (
        <FlexBox flex={true} align="center">
          <FormCompanyAutoComplete
            label={i18n.get("company", "Company")}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createObjectIdValidator(
                i18n.get("select_company", "Select Company"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === CUSTOMER && (
        <FlexBox flex={true} align="center">
          <FormCustomerAutoComplete
            label={i18n.get("customer", "Customer")}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createObjectIdValidator(
                i18n.get("select_customer", "Select Customer"),
              )
            }
            renderOption={option => (
              <FlexBox direction="column">
                <span>
                  {getValue(option, "value.company_name")
                    ? getValue(option, "value.company_name")
                    : getValue(option, "name")}
                </span>
                {getValue(option, "value.inn", "") && (
                  <span
                    style={{
                      fontSize: ".8rem",
                      fontStyle: "italic",
                    }}
                  >
                    {getValue(option, "value.inn", "")}
                  </span>
                )}
              </FlexBox>
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === PICKUP_WAREHOUSE ||
          props.field === CURRENT_WAREHOUSE ||
          props.field === DESTINATION_WAREHOUSE,
      ) && (
        <FlexBox style={{ minWidth: "400px" }} flex={true} align="center">
          <FormWarehouseAutoComplete
            fullWidth={true}
            label={i18n.get("warehouse", "Warehouse")}
            name={fieldName}
            validate={
              needsAValidation &&
              createObjectIdValidator(
                i18n.get("select_warehouse", "Select Warehouse"),
              )
            }
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === POSTCODE_FROM || props.field === POSTCODE_TO,
      ) && (
        <FlexBox flex={true} align="center">
          <FormPostcodeIndexAutoComplete
            label={i18n.get("postcode_index", "Postcode Index")}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createObjectIdValidator(
                i18n.get("select_postcode", "Select Postcode"),
              )
            }
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === JURISDICTION_FROM || props.field === JURISDICTION_TO,
      ) && (
        <FlexBox flex={true} align="center">
          <FormJMAutoComplete
            fullWidth={true}
            name={fieldName}
            label={i18n.get("jurisdiction", "Jurisdiction")}
            renderOption={option => (
              <FlexBox direction="column">
                <span>{option.name}</span>
                <span
                  style={{
                    fontSize: ".8rem",
                    fontStyle: "italic",
                  }}
                >
                  {option.value.hierarchy.map((item, index, arr) =>
                    index === arr.length - 1 ? item.name : `${item.name} > `,
                  )}
                </span>
              </FlexBox>
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === TIME_SLOT_PICK_UP || props.field === TIME_SLOT_DROP_OFF,
      ) && (
        <FlexBox flex={true} align="center">
          <FormTimeSlotAutoComplete
            label={i18n.get("time_slot", "Time Slot")}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createObjectIdValidator(
                i18n.get("select_time_slot", "Select Time Slot"),
              )
            }
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === COD ||
          props.field === COST ||
          props.field === PRICE ||
          props.field === RTO_PRICE ||
          props.field === RATING,
      ) && (
        <FlexBox flex={true} align="center">
          <FormTextField
            parseOnBlur={parseFloat}
            label={i18n.get("eg_05_10_365", "eg: 0.5, 10, 365")}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createFiniteValidator(i18n.get("add_value", "Add Value"))
            }
          />
        </FlexBox>
      )}

      {props.field === LIMIT && (
        <FlexBox flex={true} align="center">
          <FormTextField
            parseOnBlur={parseInteger}
            label={i18n.get("eg_10_50_100", "eg: 10, 50, 100")}
            validate={
              needsAValidation &&
              createFiniteValidator(i18n.get("add_value", "Add Value"))
            }
            name={fieldName}
            fullWidth={true}
          />
        </FlexBox>
      )}

      {props.field === ITEM_VALUE && (
        <FlexBox flex={true} align="center">
          <FormTextField
            parseOnBlur={parseInteger}
            label={i18n.get("eg_10_50_100", "eg: 10, 50, 100")}
            validate={
              needsAValidation &&
              createFiniteValidator(i18n.get("add_value", "Add Value"))
            }
            name={fieldName}
            fullWidth={true}
          />
        </FlexBox>
      )}

      {props.field === CREATED_DATE && (
        <FlexBox flex={true} align="center">
          <FormDateField
            label={i18n.get("created_date", "Created Date")}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createDateValidator(
                i18n.get("select_created_date", "Select Created Date"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === CREATED_TIME && (
        <FlexBox flex={true} align="center">
          <FormTimeField
            label={i18n.get("created_time", "Created Time")}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createDateValidator(
                i18n.get("select_created_time", "Select Created Time"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === PAYMENT_DONE_BY && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            label={i18n.get("payer", "Payer")}
            options={OrderPayerTypes}
            formatOption={formatText}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createNotFalsyValidator(i18n.get("select_payer", "Select Payer"))
            }
          />
        </FlexBox>
      )}

      {props.field === DAY_OF_WEEK && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            label={i18n.get("day_of_week", "Day Of Week")}
            options={DayOfWeek}
            formatOption={formatText}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createNotFalsyValidator(
                i18n.get("select_day_of_week", "Select Day Of Week"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === TRIGGER && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            label={i18n.get("trigger", "Trigger")}
            options={TriggerConstants}
            formatOption={x => props.i18n.get(x.toLowerCase(), formatText(x))}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createNotFalsyValidator(
                i18n.get("select_trigger", "Select Trigger"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === PAYMENT_METHOD && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            label={i18n.get("payment_method", "Payment Method")}
            options={OrderPaymentTypes}
            formatOption={x => props.i18n.get(x.toLowerCase(), formatText(x))}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createNotFalsyValidator(
                i18n.get("select_payment_method", "Select Payment Method"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === PARCEL_SIZE && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            label={i18n.get("parcel_size", "Parcel Size")}
            options={OrderSizeCodes}
            formatOption={formatText}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createNotFalsyValidator(
                i18n.get("select_parcel_size", "Select Parcel Size"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === COURIER_TYPE && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            label={i18n.get("courier", "Courier")}
            options={CourierTypes}
            formatOption={formatCourierType}
            name={fieldName}
            fullWidth={true}
            validate={
              needsAValidation &&
              createNotFalsyValidator(
                i18n.get("select_courier", "Select Courier"),
              )
            }
          />
        </FlexBox>
      )}

      {props.field === ORDER_TYPE && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            name={fieldName}
            fullWidth={true}
            label={i18n.get("order_type", "Order Type")}
            formatOption={formatText}
            options={RuleConditionOrderTypes}
            validate={
              needsAValidation &&
              createNotFalsyValidator(
                i18n.get("select_order_type", "Select Order Type"),
              )
            }
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === MERCHANT ||
          props.field === HAS_FETCH_ITEMS ||
          props.field === IS_RETURN,
      ) && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            name={fieldName}
            fullWidth={true}
            label={i18n.get("value", "Value")}
            options={trueFalseOptions}
            formatOption={formatTrueFalseOption}
            validate={
              needsAValidation &&
              createNotNilValidator(i18n.get("select_value", "Select Value"))
            }
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === SENDER_ADDRESS_TYPE ||
          props.field === RECIPIENT_ADDRESS_TYPE,
      ) && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            name={fieldName}
            fullWidth={true}
            label={i18n.get("value", "Value")}
            options={AddressType}
            formatOption={formatText}
            validate={
              needsAValidation &&
              createNotNilValidator(i18n.get("select_value", "Select Value"))
            }
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === SELLER_NAME ||
          props.field === SELLER_ID ||
          props.field === SENDER_ADDRESS ||
          props.field === SENDER_NAME ||
          props.field === RECIPIENT_ADDRESS,
      ) && (
        <FlexBox flex={true} align="center">
          <FormTextField
            name={fieldName}
            fullWidth={true}
            label={i18n.get("value", "Value")}
            validate={
              needsAValidation &&
              createNotNilValidator(i18n.get("select_value", "Select Value"))
            }
          />
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(PackageRuleTreeLeaf);
