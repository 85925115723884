import React, { Component } from "react";
import PropTypes from "prop-types";
import times from "../../assets/times-solid.svg";
import dots from "../../assets/ellipsis-v-solid.svg";

// eslint-disable-next-line react/prefer-stateless-function
export default class GridStackItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    item: PropTypes.object,
    handleDetail: PropTypes.func,
    handleDelete: PropTypes.func,
  };
  render() {
    const { id, item, handleDetail, handleDelete } = this.props;
    const border = item.get("borderStyle") || "";
    return (
      <div className={`grid-stack-item-content-body ${border}`}>
        <span>{item.get("name")}</span>
        <div className="grid-stack-item-content-actions">
          <button
            className="grid-stack-item-content-action-btn"
            type="button"
            onClick={handleDetail}
          >
            <img src={dots} alt="dots" height={16} width={16} />
          </button>
          <button
            className="grid-stack-item-content-action-btn"
            type="button"
            onClick={() => handleDelete(id)}
          >
            <img src={times} alt="delete" height={16} width={16} />
          </button>
        </div>
      </div>
    );
  }
}
