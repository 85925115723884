import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriverFilterForm from "../../components/drivers-core/DriverFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "600px",
      maxWidth: "600px",
      minHeight: "auto",
    },
  }),
);

AdminDriverFilterWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function AdminDriverFilterWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={props.open}
      title={getLocalisationMessage("driver_filter", "Driver Filter")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <DriverFilterForm
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        onDismiss={props.onRequestClose}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminDriverFilterWrapper);
