import React, { useEffect, useRef, useState } from "react";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITable, { RENDER } from "../../components/orders-core/MUITable";
import {
  Add,
  FilterList,
  Refresh,
  DeleteForever,
  Search,
  Edit,
} from "@material-ui/icons";
import {
  deleteInternationalOutTariff,
  getInternationalOutTariffList,
} from "../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../helpers/DataUtils";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import _ from "lodash";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import {
  INTERNATIONAL_OUT_TARIFF_CREATE_URL,
  INTERNATIONAL_OUT_TARIFF_ITEM_URL,
} from "../../constants/AdminPathConstants";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  title: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: 500,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

AdminInboundPackageContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  setLocation: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminInboundPackageContainer(props) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
    status: "all",
  });

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  const [deleteId, setDeleteId] = useState(null);

  const classes = useStyles();

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });

  useEffect(() => {
    setIsLoading(true);
    getInternationalOutTariffList(filter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const onDelete = removeId => {
    deleteInternationalOutTariff(removeId)
      .then(() => {
        setDeleteId(0);
        props.showSuccessMessage(
          props.i18n.get("successfully_removed", "Successfully Removed"),
        );
        refreshList();
      })
      .catch(error => {
        props.showErrorMessage(error);
      });
  };

  return (
    <AdminAppLayout title={props.i18n.get("tariffs", "Tariffs")}>
      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {props.i18n.get(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>
      <FlexBox direction="column" style={{ gap: 16 }}>
        <FlexBox
          style={{
            height: "35px",
            padding: "2px 0 2px 20px",
          }}
          justify="space-between"
          align="center"
        >
          <Typography variant="h5" className={classes.title}>
            {props.i18n.get("tariffs", "Tariffs")}
          </Typography>
        </FlexBox>

        <MUITable
          isLoading={isLoading}
          size="small"
          list={list}
          total={total}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          withCheckbox={false}
          headerActions={
            <FlexBox
              flex={true}
              align={ALIGN_CENTER}
              justify={JUSTIFY_SPACE_AROUND}
              style={{ padding: ".25rem 1rem", gap: 16 }}
            >
              <div className={classes.search} ref={ref}>
                <Search
                  fontSize="large"
                  style={{
                    color: "rgba(0, 0, 0, 0.23)",
                    top: ".5rem",
                    left: ".5rem",
                    position: "absolute",
                  }}
                />
                <ChipTextField
                  style={{
                    padding: ".5rem 1rem 0 3rem",
                    maxHeight: divHeight > 86 && "5.8rem",
                    overflow: divHeight > 86 && "auto",
                  }}
                  fullWidth={true}
                  addOnBlur={false}
                  clearOnBlur={false}
                  value={parseString(filter.search)}
                  disableUnderline={true}
                  placeholder={props.i18n.get("type_here_to_search")}
                  onChange={v =>
                    setFilter({ ...filter, search: stringifyArray(v) })
                  }
                />
              </div>

              <FlexBox align={ALIGN_CENTER}>
                <CustomButton
                  onClick={() =>
                    props.setLocation(INTERNATIONAL_OUT_TARIFF_CREATE_URL)
                  }
                  startIcon={<Add />}
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{ borderRadius: "20px", marginRight: 10 }}
                >
                  {props.i18n.get("add_new_tariff", "Add new Tariff")}
                </CustomButton>

                <IconButton
                  className={isLoading && classes.refresh}
                  tooltip={props.i18n.get("refresh", "Refresh")}
                  onClick={() => refreshList()}
                >
                  <Refresh />
                </IconButton>
                <Tooltip title={props.i18n.get("filter", "Filter")}>
                  <IconButton onClick={() => 1}>
                    <FilterList />
                  </IconButton>
                </Tooltip>
              </FlexBox>
            </FlexBox>
          }
          columns={[
            {
              type: RENDER,
              name: "id",
              label: props.i18n.get("id", "id"),
              render: row => _.get(row, `id`, props.i18n.get("na", "N/A")),
            },
            {
              type: RENDER,
              name: "country",
              label: props.i18n.get("country", "country"),
              render: row =>
                _.get(row, `country.name`, props.i18n.get("na", "N/A")),
            },
            {
              type: RENDER,
              name: "inbound_price_item",
              label: props.i18n.get(
                "inbound_price_per_item",
                "Inbound price per item",
              ),
              render: row =>
                `${_.get(row, `inbound_price_per_item`, 0) || 0} SPZ`,
            },
            {
              type: RENDER,
              name: "inbound_price_per_kg",
              label: props.i18n.get(
                "inbound_price_per_kg",
                "Inbound price per kg",
              ),
              render: row =>
                `${_.get(row, `inbound_price_per_kg`, 0) || 0} SPZ`,
            },
            {
              type: RENDER,
              name: "outbound_avia_price_per_item",
              label: props.i18n.get(
                "outbound_avia_price_per_item",
                "Outbound avia price per item",
              ),
              render: row =>
                `${_.get(row, `outbound_avia_price_per_item`, 0) || 0} SPZ`,
            },
            {
              type: RENDER,
              name: "outbound_avia_price_per_kg",
              label: props.i18n.get(
                "outbound_avia_price_per_kg",
                "Outbound avia price per kg",
              ),
              render: row =>
                `${_.get(row, `outbound_avia_price_per_kg`, 0) || 0} SPZ`,
            },
            {
              type: RENDER,
              name: "outbound_ground_price_per_item",
              label: props.i18n.get(
                "outbound_ground_price_per_item",
                "Outbound ground price per item",
              ),
              render: row =>
                `${_.get(row, `outbound_ground_price_per_item`, 0) || 0} SPZ`,
            },
            {
              type: RENDER,
              name: "outbound_ground_price_per_kg",
              label: props.i18n.get(
                "outbound_ground_price_per_kg",
                "Outbound ground price per kg",
              ),
              render: row =>
                `${_.get(row, `outbound_ground_price_per_kg`, 0) || 0} SPZ`,
            },
            {
              type: RENDER,
              name: "action",
              label: props.i18n.get("action", "Action"),
              align: "center",
              render: row => (
                <FlexBox
                  flex={true}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                  style={{ gap: 16 }}
                >
                  <IconButton onClick={() => setDeleteId(_.get(row, "id"))}>
                    <DeleteForever />
                  </IconButton>

                  <IconButton
                    onClick={() =>
                      props.setLocation(
                        INTERNATIONAL_OUT_TARIFF_ITEM_URL + _.get(row, "id"),
                      )
                    }
                  >
                    <Edit />
                  </IconButton>
                </FlexBox>
              ),
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminInboundPackageContainer);
