import React from "react";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { ListSubheader, Button } from "@material-ui/core";
import { CardElement, injectStripe } from "react-stripe-elements";
import FlexBox from "../ui-core/FlexBox";

const styles = {
  root: {
    width: "100%",
  },
  cardContent: {
    cardRoot: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    cardElement: {
      base: {
        fontSize: "18px",
      },
    },
    cardButton: {
      marginTop: "20px",
      marginBottom: "10px",
    },
  },
};

class SubscriptionCheckoutForm extends React.Component {
  static propTypes = {
    i18n: PropTypes.instanceOf(Map),
    stripe: PropTypes.object,
    submitting: PropTypes.bool,
    change: PropTypes.func,
    onSubmit: PropTypes.func,
    clientSecret: PropTypes.string,
    customerStripeId: PropTypes.string,
    handleSubmit: PropTypes.func,
    onRequestClose: PropTypes.func.isRequired,
  };

  handleSubmit = event => {
    event.preventDefault();
    const { onSubmit, stripe } = this.props;
    // eslint-disable-next-line react/prop-types,no-unused-vars
    const element = this.props.elements.getElement("card");
    onSubmit(stripe, element);
  };

  render() {
    const { i18n } = this.props;

    return (
      <FlexBox
        element={<form onSubmit={this.handleSubmit} />}
        container={8}
        style={styles.root}
        direction="column"
      >
        <ListSubheader>
          {i18n.get("card_details", "Card details")}
        </ListSubheader>

        <div style={styles.cardContent.cardRoot}>
          <CardElement
            style={styles.cardContent.cardElement}
            onReady={el => el.focus()}
          />

          <FlexBox
            flex={true}
            justify="flex-end"
            style={styles.cardContent.cardButton}
          >
            <Button type="button" onClick={this.props.onRequestClose}>
              {i18n.get("close", "Close")}
            </Button>
            <Button primary={true} type="submit">
              {i18n.get("submit", "Submit")}
            </Button>
          </FlexBox>
        </div>
      </FlexBox>
    );
  }
}

export default injectStripe(SubscriptionCheckoutForm);
