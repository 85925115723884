import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriversRadarFilterForm from "../../components/drivers-radar/DriversRadarFilterForm";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "560px",
      maxWidth: "560px",
    },
  }),
);

AdminDriversRadarFilterWrapper.propTypes = {
  classes: PropTypes.object,

  filter: PropTypes.instanceOf(DataListFilter),

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminDriversRadarFilterWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("alerts_filter", "Alerts Filter")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <DriversRadarFilterForm
        filter={props.filter}
        onDismiss={props.onRequestClose}
        onFilterChange={props.onFilterChange}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminDriversRadarFilterWrapper);
