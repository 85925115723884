import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { cuteCodeFromPhone } from "../../helpers/FormatUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ADDRESS_BOOK_LIST_NEW_URL } from "../../constants/AdminPathConstants";
import {
  getAddressBook,
  updateAddressBook,
} from "../../api/shared/AdminAddressBookV2Api";
import AdminAddressBookItemWrapper from "../../wrappers/admin/AdminAddressBookItemWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func,
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      baseCountry: getMarketplaceCountry(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const addressBookStream = propsStream
      .distinctUntilChanged(isEqualDataIn(["params", "id"]))
      .switchMap(props =>
        getAddressBook(props.params.id).let(mapObjectResponseStream),
      )
      .distinctUntilChanged(isEqualData)
      .map(x => x.update("payload", toCamelCase));

    return propsStream.combineLatest(
      addressBookStream,
      (props, addressBook) => ({
        ...props,

        isLoading: addressBook.get("pending"),
        initialValues: addressBook.get("payload"),
      }),
    );
  }),
  pureComponent(fp.pick(["isLoading", "initialValues"])),
);

AdminAddressBookItemNew.propTypes = {
  setLocation: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  baseCountry: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminAddressBookItemNew(props) {
  const name = fp.get(["initialValues", "name"]);
  const { getLocalisationMessage, initialValues } = props;

  return (
    <AdminAppLayout
      title={
        initialValues
          ? `${getLocalisationMessage(
              "address_book_item",
              "Address Book Item",
            )} - ${name(props)}`
          : getLocalisationMessage("address_book_item", "Address Book Item")
      }
    >
      <PageLoading isLoading={props.isLoading} />
      <AdminAddressBookItemWrapper
        initialValues={{
          ...initialValues,
          phoneCode: fromJS({ code: initialValues.phoneCode }),
          phone: cuteCodeFromPhone(
            initialValues.phone,
            initialValues.phoneCode,
          ),
          markLocation: {
            lat: initialValues.lat,
            lng: initialValues.lon,
            country: props.baseCountry.toJS(),
            address: initialValues.address,
          },
        }}
        onSubmit={fp.flow(
          ({ markLocation, ...values }) => ({
            ...values,
            lat: markLocation.lat,
            lon: markLocation.lng,
            phone_code: values.phoneCode.get("code"),
            phone: values.phoneCode.get("code") + values.phone,
          }),
          toSnakeCase,
          values =>
            updateAddressBook(values)
              .catch(ResponseError.throw)
              .then(res => {
                if (res.status === "success") {
                  props.showSuccessMessage(
                    props.getLocalisationMessage(
                      "successfully_saved",
                      "Successfully saved",
                    ),
                  );
                  props.setLocation(ADDRESS_BOOK_LIST_NEW_URL);
                } else {
                  props.showErrorMessage(res.message);
                }
              }),
        )}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminAddressBookItemNew);
