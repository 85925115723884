import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Card, CardContent, CardActions, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import CustomPriceWrapper from "../ui-core/CustomPriceWrapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { getMarketplace } from "../../api/shared/MarketplaceApi";

const getPrice = fp.flow(fp.get("selectedIncludes.price"), fp.defaultTo(0));
const enhancer = compose(
  useSheet({
    group: {
      marginBottom: 5,
      textTransform: "uppercase",
    },
    groupTitle: {
      fontWeight: "bold",
      fontSize: 10,
      color: "#707181",
      lineHeight: 1.2,
    },
    priceItem: {
      color: "#10132D",
    },
    priceTotal: {
      fontSize: 14,
      color: "#707181",
    },
    label: {
      fontSize: 12,
    },
    amount: {
      fontSize: 14,
      display: "block",
      textAlign: "right",
    },
    border: {
      height: 5,
      marginBottom: 10,
      borderBottom: "1px dashed #C5C5CC",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
    marketplace: getMarketplace(state),
  })),
);

SubscriptionTotalCheck.propTypes = {
  activePlan: PropTypes.object,
  activeAddons: PropTypes.instanceOf(List),
  i18n: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  handleBuyButton: PropTypes.func.isRequired,
};

function SubscriptionTotalCheck(props) {
  const { classes, activePlan, activeAddons, i18n } = props;
  const planPrice = getPrice(activePlan);
  const addOnsTotal = activeAddons.reduce((sum, addOn) => sum + addOn.price, 0);
  return (
    <Card style={{ boxShadow: "none", margin: 10 }}>
      <CardContent>
        <FlexBox className={classes.priceItem} justify="space-between">
          <span className={classes.label}>
            {i18n.get("plan_price", "Plan price")}
          </span>
          <strong className={classes.amount}>
            <CustomPriceWrapper
              price={planPrice}
              decimalLength={0}
              code={props.filter.get("currency")}
            />
          </strong>
        </FlexBox>
        <div className={classes.border} />
      </CardContent>
      {activeAddons.count() > 0 && (
        <CardContent>
          <FlexBox className={classes.group} justify="space-between">
            <h4 className={classes.groupTitle}>
              {i18n.get("add_on_price_details", "Add-on price details")}
            </h4>
          </FlexBox>
          {activeAddons.map(addOn => (
            <FlexBox
              key={addOn.code}
              className={classes.priceItem}
              justify="space-between"
            >
              <span className={classes.label}>
                {i18n.get(addOn.code, addOn.title)}
              </span>
              <strong className={classes.amount}>${addOn.price}</strong>
            </FlexBox>
          ))}
          <div className={classes.border} />
        </CardContent>
      )}
      <CardContent>
        <FlexBox
          className={cx(classes.priceItem, classes.priceTotal)}
          justify="space-between"
        >
          <span className={classes.label}>
            {i18n.get("grand_total", "Grand total")}:
          </span>
          <strong className={classes.amount}>${planPrice + addOnsTotal}</strong>
        </FlexBox>
      </CardContent>

      <CardActions>
        <Button
          primary={true}
          fullWidth={true}
          onClick={() => props.handleBuyButton()}
        >
          {i18n.get("buy", "BUY")}
        </Button>
      </CardActions>
    </Card>
  );
}

export default enhancer(SubscriptionTotalCheck);
