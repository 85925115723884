import React from "react";
import { usePermissionList } from "../../hooks/usePermissionList";
import {
  makeStyles,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import FlexBox from "../../components/ui-core/FlexBox";
import { Delete, Edit } from "@material-ui/icons";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminPermissionListHeaderWrapper from "../../../client/wrappers/admin/AdminPermissionListHeaderWrapper";
import DataList, {
  DataListColumn,
  // DataListCheckbox,
} from "../../components/data-list/DataList";
import fp from "lodash/fp";
import { Link } from "react-router";

import {
  USERS_PERMISSION_FORM_PATH,
  USERS_PERMISSION_LIST_PATH,
} from "../../constants/AdminPathConstants";
import { connect } from "react-redux";
import {
  fetchRoleList,
  deleteRoleById,
} from "../../actions/AdminPermissionsManagementActions";
import {
  getRoleList,
  getRolesLoading,
} from "../../reducers/AdminPermissionManagementReducer";
import PageLoading from "../../components/ui-core/PageLoading";
import { List } from "immutable";
import PropTypes from "prop-types";
import { getMessage } from "../../reducers/LocalizationReducer";
import DataListFilter from "../../helpers/DataListFilter";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";

const useStyles = makeStyles({
  breadCrumbTitle: {
    color: "#777",
    textTransform: "uppercase",
    fontSize: 11,
  },
  title: {
    color: "#555",
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 4,
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "14px 22px",
  },
  paper: {
    width: "100%",
    height: "calc(100% - 100px)",
    overflow: "auto",
    marginTop: 12,
    display: "flex",
    flexDirection: "column",
  },
  newButton: {
    minWidth: 170,
  },
  table: {
    height: "100%",
  },
});

const AdminPermissionListContainer = props => {
  const classes = useStyles();

  const {
    updateQuery,
    navigation,
    deletingId,
    setDeletingId,
  } = usePermissionList(props);

  const setStatusToQuery = status =>
    fp.merge(fp.path(["router", "location", "query"], props), { status });

  const statusFilters = [
    {
      title: "All",
      value: updateQuery(USERS_PERMISSION_LIST_PATH, setStatusToQuery("ALL")),
    },
    {
      title: "Active",
      value: updateQuery(
        USERS_PERMISSION_LIST_PATH,
        setStatusToQuery("ACTIVE"),
      ),
    },
    {
      title: "Inactive",
      value: updateQuery(
        USERS_PERMISSION_LIST_PATH,
        setStatusToQuery("INACTIVE"),
      ),
    },
  ];

  return (
    <AdminAppLayout title="Permissions List">
      <PageLoading isLoading={props.isLoading} />
      <Dialog title="Внимание!" open={Boolean(deletingId)}>
        <DialogContent style={{ color: "#555 " }}>
          Вы действительно хотите удалить роль?
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              props.deleteRoleById(deletingId);
              setDeletingId(undefined);
            }}
          >
            Да
          </Button>
          <Button
            onClick={() => {
              setDeletingId(undefined);
            }}
          >
            Нет
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.container}>
        <div className={classes.breadCrumbTitle}>Settings</div>
        <FlexBox align="center" direction="row">
          <FlexBox className={classes.title} flex="grow">
            {" "}
            Roles{" "}
          </FlexBox>
          <FlexBox className={classes.newButton}>
            <Link to={USERS_PERMISSION_FORM_PATH}>
              <CustomButton
                fullWidth={true}
                color={SECONDARY}
                variant={CONTAINED}
              >
                + New Role
              </CustomButton>
            </Link>
          </FlexBox>
        </FlexBox>
        <Paper className={classes.paper}>
          <AdminPermissionListHeaderWrapper
            filter={
              new DataListFilter(
                fp.path(["router", "location", "query"], props),
              )
            }
            tabItems={statusFilters}
            tabValue={statusFilters.indexOf(
              statusFilters.find(status =>
                status.value.includes(props.location.query.status),
              ),
            )}
            onFilterChange={filter =>
              navigation.replaceLocationQuery(filter.getDefinedValues())
            }
          />
          <DataList
            className={classes.table}
            rowCount={props.roleList.get("list").size}
            overscanRowCount={10}
            list={props.roleList.get("list")}
            rowGetter={options => props.roleList.get("list").get(options.index)}
            isLoading={props.isLoading}
            totalCount={props.roleList.get("total")}
            withFooter={props.withFooter}
            onFilterChange={filter =>
              navigation.replaceLocationQuery(filter.getDefinedValues())
            }
            filter={
              new DataListFilter(
                fp.path(["router", "location", "query"], props),
              )
            }
          >
            {/* <DataListCheckbox
              rowSelected={row => false}
              allRowsSelected={() => false}
              onRowSelect={() => {}}
              onAllRowsSelect={() => {}}

            /> */}

            <DataListColumn
              width={300}
              label="Name"
              dataKey="name"
              cellRenderer={row => (
                <div>{row.cellData && row.cellData.get("name")}</div>
              )}
            />

            <DataListColumn
              width={100}
              label="Assigned Users"
              dataKey="users"
              cellRenderer={row => (
                <div>{row.cellData && row.cellData.get("users")}</div>
              )}
            />

            <DataListColumn
              label="Status"
              dataKey="status"
              cellRenderer={row => (
                <div>{row.cellData && row.cellData.get("status")}</div>
              )}
            />

            <DataListColumn
              width={100}
              label="Actions"
              cellRenderer={row => (
                <div>
                  <IconButton
                    onClick={() =>
                      navigation.setLocation(
                        updateQuery(USERS_PERMISSION_FORM_PATH, {
                          role_id: row.cellData.get("id"),
                        }),
                        false,
                      )
                    }
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      setDeletingId(row.cellData.get("id"));
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>
              )}
            />
          </DataList>
        </Paper>
      </div>
    </AdminAppLayout>
  );
};

AdminPermissionListContainer.propTypes = {
  isLoading: PropTypes.bool,
  deleteRoleById: PropTypes.func,
  location: PropTypes.object,
  roleList: PropTypes.objectOf(List),
  withFooter: PropTypes.bool,
  filter: PropTypes.objectOf(DataListFilter),
};

AdminPermissionListContainer.defaultProps = {
  withFooter: true,
  filter: new DataListFilter({ page: 0, size: 50, status: "ALL" }),
};

export default connect(
  state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      roleList: getRoleList(state),
      isLoading: getRolesLoading(state),
      getLocalisationMessage,
    };
  },
  { fetchRoleList, deleteRoleById },
)(AdminPermissionListContainer);
