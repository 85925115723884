import React from "react";
import { OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormSelectField from "../form/FormSelectField";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { SUPPLIER_DUE_BALANCE } from "../../constants/FinanceReportType";

const financeReportOptions = OrderedSet.of(SUPPLIER_DUE_BALANCE);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    select: { marginRight: "25px", paddingLeft: "15px" },
    cardText: { overflow: "hidden" },
    filterContainer: {
      display: "flex",
      height: "75px",
      margin: "0 4px",
      backgroundColor: "#fff",
    },
    button: { marginTop: "24px" },
    container: {
      padding: "0",
      left: "0",
      top: "63px",
      position: "fixed",
      width: "100%",
      zIndex: "2",
      borderTop: "15px solid #e8e8e8",
    },
  }),
  reduxForm({ form: "AdminFinanceReportForm" }),
);

AdminFinanceReportForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminFinanceReportForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form
      className={classes.container}
      autoComplete="off"
      onSubmit={props.handleSubmit}
    >
      <div className={classes.filterContainer}>
        <FormSelectField
          name="reportType"
          label={getLocalisationMessage("select_report", "Select Report")}
          autoWidth={true}
          options={financeReportOptions}
          className={classes.select}
          formatOption={x => getLocalisationMessage(x, formatText)}
        />
        <div className={classes.button}>
          <FormDateField
            name="date"
            fullWidth={false}
            hintText={getLocalisationMessage("select_week", "Select Week")}
          />
        </div>
        <div className={classes.button}>
          <Button
            variant="text"
            label={getLocalisationMessage("run_report", "Run Report")}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
}

export default enhancer(AdminFinanceReportForm);
