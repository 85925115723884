import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { red, blue, grey, green, indigo } from "@material-ui/core/colors";
import { formatText } from "../../helpers/FormatUtils";
import {
  DEMO,
  READY,
  FREE_TRIAL,
  NOT_MAPPED,
} from "../../constants/MarketplaceStatusConstants";

const enhancer = compose(
  useSheet({
    bold: { fontWeight: "bold" },
    ready: { color: green[500] },
  }),
);

MarketplaceTypeComponent.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
};

function MarketplaceTypeComponent(props) {
  const { classes } = props;

  let color = grey[500];

  switch (props.type) {
    case READY:
      // eslint-disable-next-line prefer-destructuring
      color = green[500];
      break;
    case NOT_MAPPED:
      // eslint-disable-next-line prefer-destructuring
      color = red[500];
      break;
    case FREE_TRIAL:
      // eslint-disable-next-line prefer-destructuring
      color = blue[500];
      break;
    case DEMO:
      // eslint-disable-next-line prefer-destructuring
      color = indigo[500];
      break;
    default:
      // eslint-disable-next-line prefer-destructuring
      color = grey[500];
      break;
  }

  return (
    <div className={classes.bold} style={{ color }}>
      {formatText(props.type)}
    </div>
  );
}

export default enhancer(MarketplaceTypeComponent);
