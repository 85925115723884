import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminSimpleOrderItem from "./AdminSimpleOrderItem";
import {
  createByBatchIdSimpleOrder,
  getByBatchIdSimpleOrder,
  updateSimpleOrder,
} from "../../api/admin/AdminSimpleOrderApi";
import { getValue } from "../../helpers/DataUtils";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SAMPLE_ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import SimpleOrderItemCreateProgressDialog from "../../components/orders-core/SimpleOrderItemCreateProgressDialog";
import SimpleOrderItemProgressDialog from "../../components/orders-core/SimpleOrderItemProgressDialog";
import { UNREGISTERED } from "../../constants/TransportationType";
import PageLoading from "../../components/ui-core/PageLoading";
import fp from "lodash/fp";
import { toCamelCase } from "../../helpers/CaseMapper";

const mapDraftErrors = fp.flow(
  toCamelCase,
  fp.mapValues("failedField.message"),
  fp.omitBy(fp.isEmpty),
);

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

AdminSimpleOrderItemWrapper.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  params: PropTypes.object,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminSimpleOrderItemWrapper(props) {
  const [total, setTotal] = useState(0);
  const [createdOrders, setCreatedOrders] = useState([]);
  const [notCreatedOrders, setNotCreatedOrders] = useState([]);
  const [openValidate, setOpenValidate] = useState(null);
  const [openCreated, setOpenCreated] = useState(false);
  const [orderIds, setOrderIds] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.params && props.params.id) {
      setIsLoading(true);
      getByBatchIdSimpleOrder(props.params.id)
        .then(res => {
          if (res && res.data) {
            setTotal(getValue(res, "data.total"));
            const drafts = getValue(res, "data.drafts", []);
            const valid = drafts
              .filter(
                draft =>
                  draft.status === "VALID" && draft.is_created === "CREATED",
              )
              .map(order => ({
                id: order.id,
                service_type: {
                  code: order.service_type.code,
                },
                sender_data: {
                  jurisdiction: {
                    id: order.sender_jurisdiction.id,
                    name: order.sender_jurisdiction.content,
                  },
                  customer: {
                    name: order.sender_name.content,
                  },
                  phone: order.sender_phone.content,
                  address: order.sender_address.content,
                  postcode: {
                    id: order.sender_postcode.id,
                    name: order.sender_postcode.content,
                  },
                },
                recipient_data: {
                  jurisdiction: {
                    id: order.recipient_jurisdiction.id,
                    name: order.recipient_jurisdiction.content,
                  },
                  customer: {
                    name: order.recipient_name.content,
                  },
                  phone: order.recipient_phone.content,
                  address: order.recipient_address.content,
                  postcode: {
                    id: order.recipient_postcode.id,
                    name: order.recipient_postcode.content,
                  },
                },
                weight: Number(order.weight.content),
                barcode: order.barcode.content,
                registered: order.transportation_type.code !== UNREGISTERED,
              }));
            const notValid = drafts
              .filter(
                draft =>
                  !(draft.status === "VALID" && draft.is_created === "CREATED"),
              )
              .map(order => ({
                id: order.id,
                service_type: {
                  code: order.service_type.code,
                },
                sender_data: {
                  jurisdiction: {
                    id: order.sender_jurisdiction.id,
                    name: order.sender_jurisdiction.content,
                  },
                  customer: {
                    name: order.sender_name.content,
                  },
                  phone: order.sender_phone.content,
                  address: order.sender_address.content,
                  postcode: {
                    id: order.sender_postcode.id,
                    name: order.sender_postcode.content,
                  },
                },
                recipient_data: {
                  jurisdiction: {
                    id: order.recipient_jurisdiction.id,
                    name: order.recipient_jurisdiction.content,
                  },
                  customer: {
                    name: order.recipient_name.content,
                  },
                  phone: order.recipient_phone.content,
                  address: order.recipient_address.content,
                  postcode: {
                    id: order.recipient_postcode.id,
                    name: order.recipient_postcode.content,
                  },
                },
                weight: Number(order.weight.content),
                barcode: order.barcode.content,
                registered: order.transportation_type.code !== UNREGISTERED,
              }));

            const tempErrors = drafts
              .filter(
                draft =>
                  !(draft.status === "VALID" && draft.is_created === "CREATED"),
              )
              .map(draft => mapDraftErrors(draft));

            setErrors(tempErrors);
            setCreatedOrders(valid);
            setNotCreatedOrders(notValid);
            setIsLoading(false);
          }
        })
        .catch(error => {
          props.showErrorMessage(error);
          setIsLoading(false);
        });
    }
  }, [props.params]);

  return (
    <AdminAppLayout
      title={props.i18n.get("update_simple_order", "Update Simple Order")}
    >
      <PageLoading isLoading={isLoading} />

      {openCreated && props.params.id && (
        <SimpleOrderItemCreateProgressDialog
          orderIds={orderIds}
          batchId={props.params.id}
          open={Boolean(props.params.id)}
          onRequestClose={() => {
            setOpenCreated(false);
          }}
          onSubmit={() => {
            props.setLocation(`${SAMPLE_ORDER_LIST_URL}`);
          }}
          showErrorMessage={props.showErrorMessage}
          showSuccessMessage={props.showSuccessMessage}
        />
      )}

      {openValidate && openValidate && (
        <SimpleOrderItemProgressDialog
          orderIds={orderIds}
          batchId={openValidate}
          open={Boolean(openValidate)}
          onRequestClose={() => {
            setOpenValidate(null);
          }}
          onSubmit={() => {
            if (openValidate) {
              createByBatchIdSimpleOrder(openValidate)
                .then(res => {
                  if (res && res.data) {
                    props.showSuccessMessage(
                      props.i18n.get("successfully_order_created"),
                    );
                  }
                })
                .catch(error => props.showErrorMessage(error));

              setOpenCreated(true);
              setOpenValidate(null);
            }
          }}
          showErrorMessage={props.showErrorMessage}
          showSuccessMessage={props.showSuccessMessage}
        />
      )}

      <AdminSimpleOrderItem
        total={total}
        errors={errors}
        initialValues={{
          notCreatedOrders,
          createdOrders,
        }}
        onSubmit={values => {
          const ids = values.map(item => item.id);
          setOrderIds(ids);
          updateSimpleOrder(props.params.id, values)
            .then(res => {
              setOpenValidate(res.data);
              props.showSuccessMessage(
                props.i18n.get("successfully_updated", "Successfully Updated"),
              );
            })
            .catch(error => props.showErrorMessage(error));
        }}
        created={createdOrders.length}
        failed={notCreatedOrders.length}
        id={props.params.id}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSimpleOrderItemWrapper);
