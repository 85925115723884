/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { CardContent, CardHeader, Link } from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  CheckCircle,
  ChevronRight,
  ExpandMore,
  ReportProblem,
  Refresh,
} from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { arrayToTree } from "performant-array-to-tree";
import { green, grey, red } from "@material-ui/core/colors";
import cx from "classnames";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { replaceMiddleString } from "../../helpers/OrderSortingHelper";
import CustomButton, { CONTAINED } from "../ui-core/CustomButton";
import { SECONDARY } from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";

const useStyles = makeStyles(() => ({
  hierarchy: { flexDirection: "column", width: "100%", marginBottom: 8 },
  root: {
    height: "100%",
    flexGrow: 1,
    width: "100%",
  },
  fontNormal: {
    fontWeight: 400,
    color: "rgba(38, 50, 56, 0.7)",
  },
  fontWeight: {
    fontWeight: 500,
    color: "#000",
  },
  fontSmallSize: {
    fontSize: 12,
  },
  wrongLocation: {
    color: red[500],
  },
  treeItemLabelContent: {
    lineHeight: "28px",
  },
  treeItemLabel: {
    fontSize: 18,
  },
  treeItemLabelAdd: {
    marginLeft: 10,
    fontSize: 12,
    color: "rgba(38, 50, 56, 0.7)",
  },
  treeItemLabelIcon: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: -5,
    fontSize: 18,
  },
  "@media (min-width: 998px)": {
    hierarchy: { flexDirection: "column" },
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} />);

const findParents = (barcode, orders, barcodes = []) => {
  barcodes.push(barcode);
  const order = orders.find(item => item.barcode === barcode);

  if (order) {
    const parentId = getValue(order, "parent_id", null);

    if (parentId) {
      return findParents(parentId, orders, barcodes);
    }
  }

  return barcodes;
};

const barcodeStatusIcon = ({ nodes, ...props }) => {
  const classes = useStyles();

  if (fp.get("error", nodes))
    return (
      <span>
        <ReportProblem
          className={classes.treeItemLabelIcon}
          style={{ color: red[500] }}
        />{" "}
        <span className={classes.fontSmallSize}>
          {props.getLocalisationMessage("not_exists", "Could not find order")}.
        </span>
      </span>
    );

  const type = fp.get("batch.type", nodes);

  const showOpenRegistry = type && !fp.get("opened", nodes);

  if (nodes.retry || nodes.isLoading) {
    return (
      <span>
        <Refresh
          className={classes.refresh}
          style={{ color: green[500], fontWeight: "bold" }}
        />{" "}
        <Link
          className={classes.fontSmallSize}
          style={{ color: red[500], margin: "0 10px", display: "inline-block" }}
          onClick={e => {
            e.preventDefault();
            return props.onRetry(fp.get("barcode", nodes));
          }}
        >
          {props.getLocalisationMessage("retry", "Retry")}.
        </Link>
      </span>
    );
  }

  return (
    <span>
      <CheckCircle
        className={classes.treeItemLabelIcon}
        style={{
          color:
            nodes.scanned === "active" && fp.get("state", nodes) !== "PENDING"
              ? green[500]
              : grey[500],
        }}
      />

      {showOpenRegistry && (
        <Link
          className={classes.treeItemLabelAdd}
          style={{ color: red[500] }}
          onClick={e => {
            e.preventDefault();
            return props.onOpenRegistry(fp.get("barcode", nodes));
          }}
        >
          {type === "CONSOLIDATED"
            ? props.getLocalisationMessage("open_bag", "Open Bag")
            : props.getLocalisationMessage("open_registry", "Open Registry")}
        </Link>
      )}
    </span>
  );
};

const TreeItemLabel = props => {
  const classes = useStyles();
  const { nodes } = props;

  return getValue(nodes, "batch") ? (
    <div className={classes.treeItemLabelContent}>
      <span
        className={cx(
          classes.treeItemLabel,
          fp.get("scanned", nodes) === "active" &&
            fp.get("state", nodes) !== "PENDING"
            ? classes.fontNormal
            : classes.fontWeight,
          (fp.get("error", nodes) ||
            (!fp.get("parent_id", nodes) &&
              !fp.get("batch.type", nodes) &&
              fp.get("batch.warehouse.id", nodes))) &&
            classes.wrongLocation,
        )}
      >
        {(fp.get("scanned", nodes) === "active" &&
          fp.get("state", nodes) !== "PENDING") ||
        fp.get("batch.type", nodes)
          ? nodes.barcode
          : replaceMiddleString(nodes.barcode, 5)}
      </span>

      {barcodeStatusIcon(props)}
    </div>
  ) : (
    <div className={classes.treeItemLabelContent}>
      <span
        className={cx(
          classes.treeItemLabel,
          fp.get("scanned", nodes) === "active" &&
            fp.get("state", nodes) !== "PENDING"
            ? classes.fontNormal
            : classes.fontWeight,
          (fp.get("error", nodes) ||
            (!fp.get("parent_id", nodes) &&
              !fp.get("orders.type", nodes) &&
              fp.get("orders.warehouse.id", nodes))) &&
            classes.wrongLocation,
        )}
      >
        {(fp.get("scanned", nodes) === "active" &&
          fp.get("state", nodes) !== "PENDING") ||
        fp.get("orders.type", nodes)
          ? nodes.barcode
          : replaceMiddleString(nodes.barcode, 5)}
      </span>

      {barcodeStatusIcon(props)}
    </div>
  );
};

TreeItemLabel.propTypes = {
  nodes: PropTypes.object,
};

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const ordersTreeStream = propsStream
      .map(fp.pick(["simpleOrders", "orders", "cursorOrder", "userLogin"]))
      .distinctUntilChanged(isEqualData)
      .map(({ simpleOrders, orders, cursorOrder, userLogin }) => {
        const batches = simpleOrders
          .filter(
            order =>
              getValue(order, "batch.children") &&
              getValue(order, "batch.children").length > 0,
          )
          .map(order => getValue(order, "barcode"));

        function sortOrders(activeOrder, workedUser, scannedOrder) {
          const sortOrdersByAll = orders.sort((a, b) => {
            // Check if the order's batch or child batch contains the activeOrder barcode
            const hasActiveOrderA = containsActiveOrder(a, activeOrder);
            const hasActiveOrderB = containsActiveOrder(b, activeOrder);
            if (hasActiveOrderA && !hasActiveOrderB) return -1;
            if (!hasActiveOrderA && hasActiveOrderB) return 1;

            // Check if the order's batch or child batch contains the workedUser
            const isWorkedUserA = containsWorkedUser(a, workedUser);
            const isWorkedUserB = containsWorkedUser(b, workedUser);
            if (isWorkedUserA && !isWorkedUserB) return -1;
            if (!isWorkedUserA && isWorkedUserB) return 1;

            return 0; // if all other checks are equal
          });

          sortOrdersByAll.forEach(order =>
            sortNestedBatches(order, scannedOrder),
          );

          return sortOrdersByAll;
        }

        function containsActiveOrder(order, activeOrder) {
          if (order.barcode === activeOrder) return true;
          if (order.batch && order.batch.children) {
            return order.batch.children.some(child =>
              containsActiveOrder(child, activeOrder),
            );
          }
          return false;
        }

        function containsWorkedUser(order, workedUser) {
          if (order.created_by === workedUser) return true;
          if (order.batch && order.batch.children) {
            return order.batch.children.some(child =>
              containsWorkedUser(child, workedUser),
            );
          }
          return false;
        }

        function sortNestedBatches(order, scannedOrder) {
          if (order.batch && order.batch.children) {
            // Sort the current level of children based on scanned status
            // eslint-disable-next-line no-param-reassign
            order.batch.children = sortByScannedStatus(
              order.batch.children,
              scannedOrder,
            );

            // Recursively sort children at deeper levels
            order.batch.children.forEach(child =>
              sortNestedBatches(child, scannedOrder),
            );
          }
        }

        function sortByScannedStatus(items, scannedOrder) {
          return items.sort((a, b) => {
            const scannedA = a.scanned === scannedOrder;
            const scannedB = b.scanned === scannedOrder;
            return scannedA === scannedB ? 0 : scannedA ? -1 : 1;
          });
        }

        const tree = arrayToTree(
          sortOrders(cursorOrder, userLogin, "inactive"),
          {
            id: "id",
            parentId: "parent_id",
          },
        );

        return { batches, tree };
      })
      .startWith({});

    return propsStream.combineLatest(ordersTreeStream, (props, response) => ({
      ...props,
      ordersTree: getValue(response, "tree"),
      activeNodes: getValue(response, "batches"),
    }));
  }),
);

OrderOfflineSortingBinHeirarchyCard.propTypes = {
  pendingOrdersCount: PropTypes.number,
  warehouseId: PropTypes.number,
  cursorOrder: PropTypes.string,
  userLogin: PropTypes.string,

  orders: PropTypes.array,
  ordersTree: PropTypes.array,
  activeNodes: PropTypes.array,

  getLocalisationMessage: PropTypes.func.isRequired,
  onBatchCompleteRequest: PropTypes.func,
  onOpenRegistry: PropTypes.func,
  onRetry: PropTypes.func,
  onRetryAll: PropTypes.func,
};

function OrderOfflineSortingBinHeirarchyCard(props) {
  const classes = useStyles();
  const { getLocalisationMessage, activeNodes } = props;

  const renderTree = nodes => (
    <StyledTreeItem
      key={nodes.barcode}
      nodeId={nodes.barcode}
      label={<TreeItemLabel nodes={nodes} {...props} />}
    >
      {Array.isArray(getValue(nodes, "batch.children"))
        ? getValue(nodes, "batch.children").map(node => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  return (
    <div className={classes.hierarchy}>
      <CardHeader
        style={{ width: "100%" }}
        title={
          <FlexBox
            justify="space-between"
            flex={true}
            style={{ width: "100%" }}
          >
            <h5>
              {getLocalisationMessage("batch_hierarchy", "Batch Hierarchy")}
            </h5>

            {false && (
              <FlexBox align="center" justify="flex-end">
                <CustomButton
                  color={SECONDARY}
                  variant={CONTAINED}
                  onClick={props.onRetryAll}
                >
                  {getLocalisationMessage("retry", "Retry")}
                </CustomButton>
              </FlexBox>
            )}
          </FlexBox>
        }
      />

      <CardContent>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMore />}
          expanded={activeNodes}
          defaultExpandIcon={<ChevronRight />}
        >
          {props.ordersTree.map(item => renderTree(item.data))}
        </TreeView>
      </CardContent>
    </div>
  );
}

export default enhancer(OrderOfflineSortingBinHeirarchyCard);
