import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { Link } from "react-router";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { COD, SERVICE } from "../../constants/OrderChargeItemTypes";
import { getJob, getJobOrders } from "../../api/admin/AdminJobsApi";
import AvatarWithName from "../../components/avatars/AvatarWithName";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import FlagMultiLineCell from "../../components/data-list/FlagMultiLineCell";
import OrderStatus from "../../components/orders-core/OrderStatus";

const mapChargeItems = fp.flow(
  chargeItems => (List.isList(chargeItems) ? chargeItems : List()),
  chargeItems =>
    chargeItems
      .toMap()
      .mapEntries(([, item]) => [item.get("charge_type"), item]),
);

const enhancer = compose(
  renderIf(props => props.jobId > 0),
  useSheet({
    card: {
      "& > div": { display: "flex", flexDirection: "column", flex: "1 1 0%" },
    },
    customerCell: { paddingLeft: "60px" },
    paper: {
      minWidth: "1000px",
      maxWidth: "1000px",
      height: "600px",
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const jobItemResponseStream = propsStream
          .distinctUntilKeyChanged("jobId")
          .switchMap(props =>
            getJob(props.jobId).catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(jobItemResponseStream, (props, jobItemResponse) => ({
            ...props,
            jobItem: jobItemResponse.get("payload"),
            isLoadingJob: jobItemResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const ordersResponseStream = propsStream
          .distinctUntilKeyChanged("jobId")
          .switchMap(props =>
            getJobOrders(props.jobId).catch(error => Observable.of({ error })),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data.list"), fp.toArray)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(ordersResponseStream, (props, ordersResponse) => ({
            ...props,
            orderList: ordersResponse.get("payload"),
            isLoadingOrders: ordersResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminOrderJobsItemDialogWrapper.propTypes = {
  classes: PropTypes.object,
  jobItem: PropTypes.instanceOf(Map),
  orderList: PropTypes.instanceOf(List),
  isLoadingJob: PropTypes.bool,
  isLoadingOrders: PropTypes.bool,
  jobId: PropTypes.number,
  onClickDetails: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
};

function AdminOrderJobsItemDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoadingJob}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox
        container={8}
        flex={true}
        direction="column"
        element={<Card className={classes.card} />}
      >
        <FlexBox
          gutter={8}
          direction="column"
          flex={true}
          element={<CardContent />}
        >
          {Boolean(!props.isLoadingJob) && (
            <FlexBox>
              <FlexBox gutter={8} flex={true}>
                <FlexBox align="center" justify="center" flex={true}>
                  <h5>{`Job ID: ${props.jobId}`}</h5>
                </FlexBox>

                <FlexBox flex={true} align="center" justify="center">
                  <AvatarWithName
                    name={props.jobItem.getIn(["customer", "name"])}
                    imagePath={props.jobItem.getIn(["customer", "photo"])}
                  >
                    {props.jobItem.getIn(["customer", "name"])}
                  </AvatarWithName>
                </FlexBox>

                <FlexBox flex={true} align="center" justify="center">
                  <FlagMultiLineCell
                    countryCode={props.jobItem.getIn(["country", "code"])}
                    flagSize={36}
                    firstLine={props.jobItem.getIn(["neighborhood", "name"])}
                    secondLine={props.jobItem.get("address")}
                  />
                </FlexBox>

                <FlexBox align="center" justify="center" flex={true}>
                  <OrderStatus
                    colored={true}
                    status={props.jobItem.get("status")}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          )}

          <FlexBox direction="column" flex={true}>
            <DataList
              isLoading={props.isLoadingOrders}
              list={props.orderList}
              rowCount={props.orderList.size}
              overscanRowCount={6}
              rowGetter={options => props.orderList.get(options.index)}
            >
              <DataListColumn
                width={100}
                label="Order ID"
                dataKey="id"
                disableSort={true}
                justifyContent="center"
                cellRenderer={row => row.cellData.get("id")}
              />

              <DataListColumn
                width={100}
                label="Status"
                dataKey="status"
                disableSort={true}
                justifyContent="center"
                cellRenderer={row => (
                  <OrderStatus
                    colored={true}
                    status={row.cellData.get("status")}
                  />
                )}
              />

              <DataListColumn
                width={100}
                label="Service Charge"
                dataKey="charge_items"
                disableSort={true}
                justifyContent="center"
                cellRenderer={row => {
                  const chargeItems = mapChargeItems(
                    row.cellData.get("charge_items"),
                  );

                  return (
                    <PriceWrapper
                      code={row.cellData.getIn(["currency", "code"])}
                      price={chargeItems.getIn([SERVICE, "charge"], 0)}
                    />
                  );
                }}
              />

              <DataListColumn
                width={100}
                label="Size"
                dataKey="size"
                disableSort={true}
                justifyContent="center"
                cellRenderer={row => formatText(row.cellData.get("size"))}
              />

              <DataListColumn
                width={100}
                label="COD"
                dataKey="package"
                disableSort={true}
                justifyContent="center"
                cellRenderer={row => {
                  const chargeItems = mapChargeItems(
                    row.cellData.get("charge_items"),
                  );

                  return (
                    <PriceWrapper
                      code={row.cellData.getIn(["currency", "code"])}
                      price={chargeItems.getIn([COD, "charge"], 0)}
                    />
                  );
                }}
              />

              <DataListColumn
                width={100}
                label="Details"
                dataKey="id"
                disableSort={true}
                justifyContent="center"
                cellRenderer={row => (
                  <Link to={() => props.onClickDetails(row.cellData.get("id"))}>
                    Details
                  </Link>
                )}
              />
            </DataList>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderJobsItemDialogWrapper);
