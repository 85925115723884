import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SHOP_MENU_LIST_URL } from "../../constants/AdminPathConstants";
import { createMenuItem } from "../../api/admin/AdminShopMenuApi";
import {
  getCachedVenueCategory,
  getVenueCategoryPredictions,
} from "../../api/admin/AdminVenueCategoryApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminShopMenuForm from "../../components/admin/AdminShopMenuForm";

const enhancer = compose(
  connect(null, { showErrorMessage, showSuccessMessage }),
  getContext({ setLocation: PropTypes.func }),
);

AdminShopMenuCreate.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function AdminShopMenuCreate(props) {
  return (
    <AdminAppLayout title="Create Shop Menu">
      <AdminShopMenuForm
        onSubmit={values =>
          createMenuItem(toSnakeCase(values)).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.setLocation(SHOP_MENU_LIST_URL);
        }}
        onSubmitFail={props.showErrorMessage}
        onDismissClick={() => props.setLocation(SHOP_MENU_LIST_URL)}
        getCachedVenueCategory={getCachedVenueCategory}
        getVenueCategoryPredictions={getVenueCategoryPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminShopMenuCreate);
