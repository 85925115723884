import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { formatDate, DATE_TIME_URL_FORMAT } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

/* const getPlanTitle = fp.flow(fp.get("title"), fp.toUpper); */
// const getPlanIncludes = fp.flow(fp.get("includes"), fp.split("/n"), fp.first);
// const getPlanEnd = fp.flow(fp.get("includes"), fp.split("/n"), fp.first);
const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),

  useSheet({
    dialogRoot: {
      marginBottom: 5,
    },
    buttonRight: { float: "right" },
  }),
);
SubscriptionCancelConfirmDialog.propTypes = {
  classes: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  subscribedPlan: PropTypes.any,
  i18n: PropTypes.instanceOf(Map),
};

function SubscriptionCancelConfirmDialog(props) {
  const { subscribedPlan, i18n } = props;
  return (
    <ConfirmDialog
      open={true}
      onConfirm={props.onConfirm}
      confirmButtonLabel={i18n.get(
        "confirm_cancellation",
        "Confirm Cancellation",
      )}
      onRequestClose={() => props.closeDialog()}
    >
      <FlexBox direction="column" style={{ paddingBottom: 5 }}>
        <h4 style={{ marginBottom: 10 }}>
          {i18n.get("cancel_subscription", "Cancel Subscription")}
        </h4>
        <p>
          {i18n.get(
            "are_you_sure_you_want_to_cancel_your",
            "Are you sure you want to cancel your",
          )}{" "}
          {i18n.get(
            "subscription_the_subscription_will_be_cancel_on",
            "subscription? The subscription will be cancel on",
          )}{" "}
          {formatDate(new Date(subscribedPlan.end_date), DATE_TIME_URL_FORMAT)}.
        </p>
      </FlexBox>
    </ConfirmDialog>
  );
}

export default enhancer(SubscriptionCancelConfirmDialog);
