import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues, formValueSelector } from "redux-form";
import { Card, CardContent, CardMedia, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { getValue } from "../../helpers/DataUtils";
import { parsePhone, formatPhoneWithPlus } from "../../helpers/FormatUtils";
import {
  validateString,
  validateEmailLocalisation,
  validatePasswordLocalised,
  validatePhoneNumberForLocalisation,
} from "../../helpers/ValidateUtils";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CompanyTypes, {
  OTHER,
  RETAIL,
  DELIVERY,
  ECOMMERSE,
} from "../../constants/CompanyTypes";
import { getCountrySignUp } from "../../api/shared/CountryApi";
import FormCheckbox from "../../components/form/FormCheckbox";
import FormTextField from "../../components/form/FormTextField";
import FormCountryAutoComplete from "../../components/form/FormCountryAutoComplete";
import FlexBox from "../../components/ui-core/FlexBox";
import CenteredCard2 from "../../components/deprecated/CenteredCard2";
import box1 from "../../assets/box1.svg";
import box2 from "../../assets/box2.svg";
import hideEye from "../../assets/hide.svg";
import showEye from "../../assets/show.svg";
import arrow from "../../assets/arrow.svg";
import others from "../../assets/others.svg";
import retail from "../../assets/retail.svg";
import vector from "../../assets/Vector.svg";

const FORM = "SubscriptionSignUpFormNew";
const valueSelector = formValueSelector(FORM);
const getValues = getFormValues("SubscriptionSignUpFormNew");
const TERMS_AND_CONDITIONS_SITE_URL =
  "https://shipox.com/terms-and-conditions-privacy-policy/";

const enhancer = compose(
  useSheet({
    cardClassName: {
      width: "500px",
      minWidth: "300px",
      maxWidth: "600px",
      padding: 15,
    },
    backBtn: {
      height: 40,
      border: "none",
      borderRadius: "6px",
      marginLeft: 5,
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1);",
    },
    signUp: {
      transition: " all .4s ease",
      height: 40,
      border: "none",
      borderRadius: "6px",
      background: "#2E3456",
      color: "white",
      paddingLeft: 20,
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.32), 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 1px 8px rgba(0, 0, 0, 0.2);",
    },
    image: {
      width: 60,
      height: 60,
      minWidth: 60,
      backgroundColor: "#F15B01",
      transition: "all .4s ease",
    },
    selectedImg: {
      backgroundColor: "#F7F7F7",
    },
    [DELIVERY.value]: {
      maskImage: `url(${box1})`,
    },
    [ECOMMERSE.value]: {
      maskImage: `url(${box2})`,
    },
    [RETAIL.value]: {
      maskImage: `url(${retail})`,
    },
    [OTHER.value]: {
      maskImage: `url(${others})`,
    },

    cardWrap: {
      margin: "5px 0 20px 0",
    },
    card: {
      cursor: "pointer",
      borderRadius: "8px",
      flex: "1 1 0px",
      transition: "all .4s ease",
      margin: "0 5px",
    },
    selectedCard: {
      background: "#F15B01",
    },
    h6: {
      fontSize: "12px",
      textTransform: "uppercase",
      color: "#000000",
      textAlign: "center",
    },
    activeH6: {
      color: "#F7F7F7",
    },
    cardMedia: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
    },
    subdomain: {
      fontSize: 16,
      color: "#F15B01",
      marginLeft: 10,
      textOverflow: "ellipsis",
      maxWidth: 220,
      overflow: "hidden",
    },
    termsSpan: {
      color: "#777ea7",
      width: 183,
      fontSize: 14,
    },
    acceptTerms: {
      width: 20,
      position: "relative",
      "&>div>label": {
        position: "absolute",
        left: 0,
        top: 20,
        width: 200,
      },
    },
    termsLink: {
      fontSize: 14,
    },
    termsWrap: {
      margin: "20px 0 30px 0",
    },
    companyType: {
      marginLeft: 5,
      marginTop: 10,
      marginBottom: 0,
      color: "#263238",
      fontSize: 16,
    },
    eyeWrap: {
      position: "relative",
    },
    eye: {
      cursor: "pointer",
      zIndex: 999,
      position: "absolute",
      top: 20,
      right: 20,
      width: 32,
      height: 32,
    },
    show: {
      background: `url(${showEye}) no-repeat center center`,
      opacity: "0.7",
    },
    hide: {
      background: `url(${hideEye}) no-repeat center center`,
      opacity: "0.7",
    },
    input: {
      margin: "7.5px 0",
      height: 50,
      borderRadius: 6,
      border: "1px solid #AFAFAF",
      "&>div:nth-child(2)": {
        paddingLeft: 10,
      },
      "&>div:nth-child(4)": {
        marginTop: 20,
        marginLeft: 10,
      },
      "&>div>hr": {
        display: "none",
      },
      "&>label": {
        top: 13,
        background: "white",
        marginLeft: 10,
        padding: "0 5px",
      },
      "&>input": {
        marginTop: 0,
        borderRadius: 6,
        padding: "0 10px",
      },
      "&>input:focus": {
        outline: "none",
      },
    },
    off: {
      background: "#777ea7",
    },
  }),
  connect(() => ({}), { showErrorMessage, showSuccessMessage }),
  withState("state", "setState", {
    isFirstPage: true,
    showPsw: false,
    // isDomainValid: false,
  }),
  reduxForm({
    form: FORM,
    validate: values => ({
      firstName: validateString(values.firstName) ? "Enter First Name" : null,
      email: validateEmailLocalisation(values.email),
      phone: validatePhoneNumberForLocalisation(values.phone),
      country: !getValue(values.country, "id") && "Please select country",
      acceptTerms: !values.acceptTerms && "Please check Terms",
      companyName: validateString(values.companyName)
        ? "Enter Company Name"
        : null,
      password: validatePasswordLocalised(values.password),
      // subdomain: !values.subdomain && "Please enter sub-domain",
    }),
  }),
  connect(state => ({
    values: getValues(state),
    countryCode: getValue(valueSelector(state, "country"), "code"),
  })),
  mapPropsStream(propsStream => {
    const getCountryStream = propsStream
      .first()
      .switchMap(() =>
        getCountrySignUp().catch(error => Observable.of({ error })),
      )
      .startWith({ country_calling_code: "+971" });

    return propsStream.combineLatest(
      getCountryStream,
      (props, getCountryResponse) => ({
        ...props,
        // country_code: getCountryResponse.country_code,
        country_calling_code: getCountryResponse.country_calling_code,
      }),
    );
  }),
);

SubscriptionSignUpFormNew.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  country: PropTypes.instanceOf(Map),

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  state: PropTypes.object,
  setState: PropTypes.func,
  values: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  // country_code:PropTypes.string,
  country_calling_code: PropTypes.string,
};

function SubscriptionSignUpFormNew(props) {
  const { classes, state, setState, change, values } = props;
  const isCourierCompany = values.companyType;
  let disableNextStep = true;
  if (
    values.acceptTerms &&
    validatePasswordLocalised(values.password) === null &&
    validatePhoneNumberForLocalisation(values.phone) === null &&
    validateEmailLocalisation(values.email) === null &&
    values.firstName
  ) {
    disableNextStep = false;
  }
  let disableSubmit = true;
  if (
    // state.isDomainValid &&
    validateString(values.companyName) === null &&
    getValue(values.country, "id")
  ) {
    disableSubmit = false;
  }
  return (
    <CenteredCard2
      withLogo={true}
      cardClassName={classes.cardClassName}
      isLoading={props.submitting}
    >
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <form noValidate={true} autoComplete="off" onSubmit={props.handleSubmit}>
        {state.isFirstPage ? (
          <CardContent>
            <FlexBox flex={true}>
              <FlexBox container={8} flex={true}>
                <FormTextField
                  className={classes.input}
                  name="firstName"
                  fullWidth={true}
                  label="First Name"
                />
              </FlexBox>
              <FlexBox container={8} flex={true}>
                <FormTextField
                  className={classes.input}
                  name="lastName"
                  fullWidth={true}
                  label="Last Name"
                />
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true}>
              <FlexBox container={8} flex={true}>
                <FormTextField
                  className={classes.input}
                  name="email"
                  autocomplete="off"
                  parse={fp.trim}
                  fullWidth={true}
                  label="Email"
                />
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true}>
              <FlexBox container={8} flex={true}>
                <FormTextField
                  className={classes.input}
                  name="phone"
                  fullWidth={true}
                  label="Phone number"
                  parse={parsePhone}
                  format={x => formatPhoneWithPlus(x)}
                  hintText={`${props.country_calling_code.slice(1)} xxx xx xx`}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true}>
              <FlexBox className={classes.eyeWrap} container={8} flex={true}>
                <FormTextField
                  className={classes.input}
                  name="password"
                  autocomplete="off"
                  type={state.showPsw ? "text" : "password"}
                  fullWidth={true}
                  label="Password"
                />
                <btn
                  onClick={() => setState(fp.set("showPsw", !state.showPsw))}
                  className={`${classes.eye} ${
                    state.showPsw ? classes.show : classes.hide
                  }`}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox
              className={classes.termsWrap}
              direction="row"
              align="center"
              justify="center"
            >
              <FormCheckbox
                className={classes.acceptTerms}
                name="acceptTerms"
              />
              <span className={classes.termsSpan}>
                I have read and I agree to the
              </span>
              <a
                href={TERMS_AND_CONDITIONS_SITE_URL}
                target="_blank"
                rel="noreferrer"
                className={classes.termsLink}
              >
                Terms
              </a>
            </FlexBox>
            <FlexBox justify="flex-end">
              <Button
                disabled={disableNextStep}
                onClick={() => setState(fp.set("isFirstPage", false))}
                label="Continue"
                className={`${classes.signUp} ${disableNextStep &&
                  classes.off}`}
              >
                <img src={arrow} alt="" />
              </Button>
            </FlexBox>
          </CardContent>
        ) : (
          <CardContent>
            <FlexBox container={8} flex={true}>
              <FormTextField
                className={classes.input}
                name="companyName"
                fullWidth={true}
                label="Company Name"
              />
            </FlexBox>
            <FlexBox container={8} flex={true}>
              <FormCountryAutoComplete
                className={`${classes.input} ${classes.country}`}
                fullWidth={true}
                name="country"
                label="Country"
              />
            </FlexBox>
            {/* <FlexBox direction="row" align="center" container={8} flex={true}> */}
            {/*  <FormTextField */}
            {/*    onBlur={() => */}
            {/*      values.subdomain && */}
            {/*      checkSubDomain(values.subdomain) */}
            {/*        .catch(ResponseError.throw) */}
            {/*        .then(res => { */}
            {/*          props.setState(fp.set("isDomainValid", res.data.valid)); */}
            {/*          if (!res.data.valid) props.showErrorMessage(res.data.msg); */}
            {/*        }) */}
            {/*    } */}
            {/*    className={classes.input} */}
            {/*    name="subdomain" */}
            {/*    style={{ width: "205px" }} */}
            {/*    label="Sub-domain" */}
            {/*  /> */}
            {/*  <span className={classes.subdomain}> */}
            {/*    {values.subdomain ? values.subdomain : "example"}.shipox.com */}
            {/*  </span> */}
            {/* </FlexBox> */}
            <FlexBox direction="column">
              <h6 className={classes.companyType}>Company type</h6>
              <FlexBox
                className={classes.cardWrap}
                container={8}
                flex={true}
                justify="space-between"
              >
                {CompanyTypes.map(item => (
                  <Card
                    key={item.value}
                    className={`${classes.card} ${isCourierCompany ===
                      item.value && classes.selectedCard}`}
                    onClick={() =>
                      isCourierCompany === item.value
                        ? change("companyType", false)
                        : change("companyType", item.value)
                    }
                  >
                    <CardMedia className={classes.cardMedia}>
                      <div
                        className={`${classes.image} ${
                          classes[item.value]
                        } ${isCourierCompany === item.value &&
                          classes.selectedImg}`}
                      />
                      <h6
                        className={`${classes.h6} ${isCourierCompany ===
                          item.value && classes.activeH6}`}
                      >
                        {item.name}
                      </h6>
                    </CardMedia>
                  </Card>
                ))}
              </FlexBox>
            </FlexBox>
            <FlexBox justify="space-between">
              <Button
                onClick={() => setState(fp.set("isFirstPage", true))}
                className={classes.backBtn}
              >
                Previous
              </Button>
              <Button
                disabled={disableSubmit}
                type="submit"
                className={`${classes.signUp} ${disableSubmit && classes.off}`}
              >
                Create Account
                <img src={vector} alt="" />
              </Button>
            </FlexBox>
          </CardContent>
        )}
      </form>
    </CenteredCard2>
  );
}

export default enhancer(SubscriptionSignUpFormNew);
