import React from "react";
import fp from "lodash/fp";
import { compose, getContext, withContext } from "recompose";
import PropTypes from "prop-types";
import { Button, CardContent, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { createObjectIdValidator } from "../../helpers/FormUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getDraftOrder,
  getDraftOrderList,
  getOrderUpload,
  getOrderUploadList,
  getOrderUploadPublishProgress,
  publishOrderUpload,
  reRunOrderUpload,
  updateDraftOrder,
  uploadDomesticOrders,
} from "../../api/admin/AdminOrderUploadApi";
import FormContainer from "../../components/form/FormContainer";
import FormCustomerAutoComplete from "../../components/form/FormCustomerAutoComplete";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderUploadList from "../../components/order-upload/OrderUploadList";
import OrderUploadDialog from "../../components/order-upload/OrderUploadDialog";
import OrderUploadDetails from "../../components/order-upload/OrderUploadDetails";
import OrderUploadEditDialog from "../../components/order-upload/OrderUploadEditDialog";
import OrderUploadPublishDialog from "../../components/order-upload/OrderUploadPublishDialog";

const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return { getLocalisationMessage };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    () => ({ getCachedCustomer, getCustomerPredictions }),
  ),
);

AdminUploadOrdersContainer.propTypes = {
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  onRequestClose: PropTypes.func,
};

function AdminUploadOrdersContainer(props) {
  const { location, getLocalisationMessage } = props;

  const batchId = fp.toFinite(location.query.id);
  const draftId = fp.toFinite(location.query.draft_id);
  const customerId = fp.toFinite(location.query.customer_id);

  const openUploadDialog =
    !batchId && !draftId && Boolean(location.query.upload);
  const openPublishDialog = batchId > 0 && Boolean(location.query.publish);

  return (
    <AdminAppLayout
      slug="upload_orders"
      title={getLocalisationMessage("upload_orders", "Upload Orders")}
    >
      <OrderUploadDialog
        open={openUploadDialog}
        onRequestClose={() => props.setLocationQuery(fp.unset("upload"))}
        onSubmit={fp.flow(
          (values) => uploadDomesticOrders(customerId, values.file),
          (request) => request.toPromise().catch(ResponseError.throw),
        )}
        onSubmitSuccess={fp.flow(fp.get("payload.data"), (id) =>
          props.setLocationQuery(fp.set("id", id)),
        )}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderUploadEditDialog
        draftId={draftId}
        getDraftOrder={getDraftOrder}
        onRequestClose={() => props.setLocationQuery(fp.unset("draft_id"))}
        onSubmit={(draft) => updateDraftOrder(draft).catch(ResponseError.throw)}
        onSubmitSuccess={() => props.setLocationQuery(fp.unset("draft_id"))}
      />

      <OrderUploadPublishDialog
        batchId={batchId}
        open={openPublishDialog}
        getOrderUploadPublishProgress={getOrderUploadPublishProgress}
        onRequestClose={() => props.setLocationQuery(fp.unset("publish"))}
        onRerunButtonClick={() => reRunOrderUpload(batchId)}
        onSubmit={(values) =>
          publishOrderUpload(batchId, values.keepOrder).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "your_orders_placed_successfully",
              "Congratulations! Your Order(s) Placed Successfully",
            ),
          );
          props.onRequestRefresh();
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <FlexBox container={8} direction="column" flex={true}>
        <FlexBox gutter={8} direction="column" flex={true}>
          <FlexBox direction="column">
            <Paper>
              <CardContent>
                <FormContainer
                  enableReinitialize={true}
                  form="AdminUploadOrdersContainerCustomer"
                  initialValues={{ customer: { id: customerId } }}
                  onSubmit={(values) =>
                    props.setLocationQuery(
                      fp.set("customer_id", values.customer.id),
                    )
                  }
                >
                  <FlexBox gutter={8}>
                    <FlexBox flex={true}>
                      <FormCustomerAutoComplete
                        name="customer"
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search...",
                        )}
                        validate={createObjectIdValidator(
                          getLocalisationMessage(
                            "select_customer",
                            "Select Customer",
                          ),
                        )}
                      />
                    </FlexBox>

                    <FlexBox align="center">
                      <Button type="submit">
                        {getLocalisationMessage("submit", "Submit")}
                      </Button>
                    </FlexBox>
                  </FlexBox>
                </FormContainer>
              </CardContent>
            </Paper>
          </FlexBox>

          <FlexBox direction="column" flex={true}>
            {Boolean(!batchId && customerId > 0) && (
              <OrderUploadList
                getOrderUploadList={() => getOrderUploadList(customerId)}
                onOrderUploadClick={(id) =>
                  props.setLocationQuery(fp.set("id", id))
                }
                onOrderCreateUploadClick={() =>
                  props.setLocationQuery(fp.set("upload", true))
                }
              />
            )}

            {Boolean(!draftId && batchId > 0 && customerId > 0) && (
              <OrderUploadDetails
                create={false}
                id={batchId}
                getOrdersUpload={getOrderUpload}
                getDraftOrderList={getDraftOrderList}
                onPublishClick={() =>
                  props.setLocationQuery(fp.set("publish", true))
                }
                onBackClick={() =>
                  props.setLocationQuery({ customer_id: customerId })
                }
                onDraftSelect={(id) =>
                  props.setLocationQuery(fp.set("draft_id", id))
                }
              />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminUploadOrdersContainer);
