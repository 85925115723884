import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import TermsSettingConstants from "../../constants/TermsSettingConstants";
import { formatLanguage, Languages } from "../../constants/LocaleTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;
        return {
          termsType: x.getValue("termsType"),
          languageKey: fp.toLower(x.getValue("languageKey")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            termsType: null,
            languageKey: null,
          });

          if (!fp.isEmpty(values.termsType)) {
            filter.setValue("termsType", values.termsType);
          }

          if (!fp.isEmpty(values.languageKey)) {
            filter.setValue("languageKey", values.languageKey);
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "TermsSettingsFilterForm",
    enableReinitialize: true,
  }),
  pureComponent(fp.pick(["dirty"])),
);

TermsSettingsFilterForm.propTypes = {
  change: PropTypes.func,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onDismiss: PropTypes.func,
  values: PropTypes.object,
  onFilterChange: PropTypes.func,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  termsType: PropTypes.string,
  languageKey: PropTypes.string,

  getLocalisationMessage: PropTypes.func,
};

function TermsSettingsFilterForm(props) {
  const {
    getLocalisationMessage,
    handleSubmit,
    dirty,
    onDismiss,
    reset,
  } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <FlexBox flex={true}>
              <FormSelectField
                name="termsType"
                fullWidth={true}
                options={TermsSettingConstants}
                formatOption={x => getLocalisationMessage(x, formatText(x))}
                label={getLocalisationMessage(
                  "terms_settings_type",
                  "Terms Settings Type",
                )}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormSelectField
                name="languageKey"
                options={Languages}
                formatOption={value =>
                  formatLanguage(value, getLocalisationMessage)
                }
                label={getLocalisationMessage("language", "Language")}
                fullWidth={true}
              />
            </FlexBox>
          </CardContent>
          <CardActions>
            <FlexBox flex={true} justify="flex-end">
              <Button
                onClick={() => {
                  props.change("termsType", null);
                  props.change("languageKey", null);
                }}
              >
                {props.getLocalisationMessage("clear", "Clear")}
              </Button>
              {dirty ? (
                <Button onClick={reset}>
                  {getLocalisationMessage("reset", "Reset")}
                </Button>
              ) : (
                Boolean(onDismiss) && (
                  <Button onClick={onDismiss}>
                    {getLocalisationMessage("dismiss", "Dismiss")}
                  </Button>
                )
              )}
              <Button type="submit">
                {getLocalisationMessage("submit", "Submit")}
              </Button>
            </FlexBox>
          </CardActions>
        </Card>
      </form>
    </div>
  );
}

export default enhancer(TermsSettingsFilterForm);
