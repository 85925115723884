import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import ScoreCourierTypes from "../../constants/ScoreCourierTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "ScoreCourierTypeForm",
    enableReinitialize: true,
  }),
);

ScoreCourierTypeForm.propTypes = {
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ScoreCourierTypeForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox
      gutter={8}
      flex={true}
      element={<form onSubmit={props.handleSubmit} />}
    >
      <FlexBox>
        <FormSelectField
          autoWidth={true}
          name="courierType"
          options={ScoreCourierTypes}
          label={getLocalisationMessage("courier_type", "Courier Type")}
          formatOption={x =>
            getLocalisationMessage(x.toLocaleLowerCase(), formatText)
          }
        />
      </FlexBox>

      <FlexBox align="center">
        <Button type="submit">
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ScoreCourierTypeForm);
