import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FlyTo,
  DataPolygon,
  DrawingControl,
} from "react-leflet-map-components";
import AreaInfoWindow from "../maps-leaflet/AreaInfoWindow";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getCurrentLanguage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    map: { flex: "1 1 0%", zIndex: 0 },
  }),
  connect(state => ({
    language: getCurrentLanguage(state),
    marketplaceId: getMarketplaceId(state),
  })),
  withState("hovered", "onHover", null),
  withState("removed", "onRemove", []),
  mapPropsStream(propsStream => {
    const areaColorsStream = propsStream
      .distinctUntilKeyChanged("areaList", (x, y) => x.size === y.size)
      .filter(({ areaListLoading }) => !areaListLoading)
      .map(({ areaList }) =>
        Immutable.OrderedMap().withMutations(map => {
          areaList.forEach(item => {
            map.set(
              item.get("id"),
              "#000000",
              // editItem > 0 || createItem ? "#FF0000" : "#000000",
            );
          });
        }),
      )
      .startWith(Immutable.OrderedMap());

    return propsStream
      .combineLatest(areaColorsStream, (props, areaColors) => ({
        ...props,
        areaColors,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "marketplaceId",
      "language",
      "marketplaceId",
      "onRemove",
      "hovered",
      "removed",
      "areaList",
      "countryPolygon",
      "editItem",
      "mapCenter",
      "hoveredId",
      "areaColors",
      "isAdminArea",
      "createItem",
      "editPolygons",
      "selectedItems",
      "areaListLoading",
      "isCountryPolygonLoading",
    ]),
  ),
);

LeafletMapAreasMap.propTypes = {
  onHover: PropTypes.func,
  hovered: PropTypes.number,

  onRemove: PropTypes.func,
  removed: PropTypes.array,

  setActiveTask: PropTypes.func,

  classes: PropTypes.object,
  language: PropTypes.string,

  mapCenter: PropTypes.object,
  hoveredId: PropTypes.number,
  marketplaceId: PropTypes.number,

  editPolygons: PropTypes.instanceOf(Immutable.List),

  onEditItem: PropTypes.func,
  onSetRawPolygon: PropTypes.func,
  onRemovePolygon: PropTypes.func,
  onCutRawPolygon: PropTypes.func,
  onEditRawPolygon: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  areaColors: PropTypes.instanceOf(Immutable.OrderedMap),
  onDeleteArea: PropTypes.any,
  editItem: PropTypes.number,
  createItem: PropTypes.bool,
  isAdminArea: PropTypes.bool,
  areaListLoading: PropTypes.bool,
  isCountryPolygonLoading: PropTypes.bool,
  areaList: PropTypes.instanceOf(Immutable.List),
  countryPolygon: PropTypes.instanceOf(Immutable.List),
  selectedItems: PropTypes.instanceOf(Immutable.List),
};

LeafletMapAreasMap.defaultProps = {
  isAdminArea: false,
};

function LeafletMapAreasMap({ classes, ...props }) {
  return (
    <LeafletMapWrapper zoom={7}>
      <DrawingControl
        position="topright"
        lang={props.language}
        allowSelfIntersection={true}
        drawingModes={
          props.editItem || props.createItem
            ? ["drawPolygon", "editMode", "dragMode", "removalMode"]
            : []
        }
        onDrawComplete={({ shape, layer }) => {
          if (shape === "Polygon") {
            props.onSetRawPolygon(layer.toGeoJSON());
          }
        }}
        onGlobalRemoveModeToggled={({ enabled }) => {
          props.setActiveTask(enabled);
          if (!enabled && props.removed.length > 0) {
            props.onRemovePolygon(props.removed);
            props.onRemove([]);
          }
        }}
        onGlobalDrawModeToggled={({ enabled }) => {
          props.setActiveTask(enabled);
        }}
        onGlobalEditModeToggled={({ enabled }) => {
          props.setActiveTask(enabled);
        }}
        onGlobalDragModeToggled={({ enabled }) => {
          props.setActiveTask(enabled);
        }}
      />

      {(props.editItem || props.createItem) &&
        props.editPolygons.map((x, idx) => (
          <DataPolygon
            key={`${idx}-${fp.random(0, 1000)}`}
            editable={true}
            stroke={true}
            weight={3}
            opacity={1}
            fill={true}
            fillOpacity={0.5}
            fillColor="#FF0000"
            color="#FF0000"
            geometry={[x.toJS()]}
            onUpdate={({ shape, layer }) => {
              if (shape === "Polygon") {
                props.onEditRawPolygon({ idx, polygon: layer.toGeoJSON() });
              }
            }}
            onCut={({ shape, layer, originalLayer }) => {
              if (shape === "Cut") {
                props.onCutRawPolygon({
                  idx,
                  polygon: layer.toGeoJSON(),
                  originalPolygon: originalLayer.toGeoJSON(),
                });
              }
            }}
            onRemove={() => {
              const removedItems = props.removed;
              removedItems.push(idx);
              props.onRemove(fp.uniq(removedItems));
            }}
            onDragEnd={({ layer, shape }) => {
              if (shape === "Polygon") {
                props.onEditRawPolygon({ idx, polygon: layer.toGeoJSON() });
              }
            }}
          />
        ))}

      {!props.areaListLoading &&
        props.areaList
          .map(item => {
            const itemId = item.get("id");

            if (
              (props.hoveredId !== itemId &&
                props.selectedItems.indexOf(itemId) === -1) ||
              props.editItem === itemId
            ) {
              return null;
            }

            const areaInfo = (
              <AreaInfoWindow
                label={item.get("name")}
                getLocalisationMessage={props.getLocalisationMessage}
                onEdit={() => {
                  if (!props.createItem && !props.editItem) {
                    props.onHover(null);
                    props.onEditItem(itemId);
                  }
                }}
                onDelete={() => props.onDeleteArea(itemId)}
              />
            );

            return (
              /* eslint-disable jsx-a11y/mouse-events-have-key-events */
              <DataPolygon
                key={itemId}
                label={item.get("name")}
                editable={false}
                zIndex={item.get("zindex")}
                geometry={item.get("polygon", []).toJS()}
                stroke={true}
                weight={3}
                opacity={1}
                fill={item.get("category") !== "COUNTRY"}
                fillOpacity={0.5}
                fillColor={props.areaColors.get(itemId, "#000000")}
                color={props.areaColors.get(itemId, "#000000")}
                // onRightClick={() => {
                //   if (!props.createItem && !props.editItem) {
                //     props.onHover(null);
                //     props.onEditItem(itemId);
                //   }
                // }}
                popupLayer={areaInfo}
              />
            );
          })
          .toArray()}

      {!props.isCountryPolygonLoading &&
        !props.isAdminArea &&
        props.countryPolygon && (
          <DataPolygon
            key="countryItem"
            editable={false}
            geometry={props.countryPolygon.toJS()}
            stroke={true}
            weight={3}
            opacity={1}
            fill={false}
            fillOpacity={0.5}
            fillColor="#000000"
            color="#000000"
          />
        )}

      <FlyTo latLng={props.mapCenter} />
    </LeafletMapWrapper>
  );
}

export default enhancer(LeafletMapAreasMap);
