import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fromJS, List, Map, Set } from "immutable";
import { Chip, makeStyles, Paper, TextField } from "@material-ui/core";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../../components/data-list/DataList";
import fp from "lodash/fp";
import DataListFilter from "../../helpers/DataListFilter";
import { connect } from "react-redux";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminOrderBatchUpdateListHeaderWrapper from "../../wrappers/admin/AdminOrderBatchUpdateListHeaderWrapper";
import FlexBox from "../../components/ui-core/FlexBox";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import { getValue, isEqualData, toJS } from "../../helpers/DataUtils";
import { getBatchOrderUpdateList } from "../../api/admin/AdminBatchApi";
import { getMessage } from "../../reducers/LocalizationReducer";
import LinkButton from "../../components/ui-core/LinkButton";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import AdminBatchUpdateOrderDialogWrapper from "../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import DownloadDynamicCSVDialog from "../../components/csv-core/DownloadDynamicCSVDialog";
import { getDynamicFields } from "../../api/admin/AdminOrderApi";
import { formatDateValuesForDownloadCSV } from "../../helpers/OrderListHelper";
import AdminParseBatchIdsDialogWrapper from "../../wrappers/admin/AdminParseBatchIdsDialogWrapper";
import AdminBatchOrderNoteDialogWrapper from "../../wrappers/admin/AdminBatchOrderNoteDialogWrapper";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { IN_TRANSIT } from "../../constants/OrderStatusCodes";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { Search } from "@material-ui/icons";
import { pipeStreams } from "../../helpers/StreamUtils";
import { toUserFilterMapper } from "../../helpers/UserFilterMapper";
import OrderSortingTypeTabs from "../../components/order-outbound-sorting/OrderSortingTypeTabs";
import {
  ORDER_OUTBOUND_CONSOLIDATED_VIEW_URL,
  ORDER_OUTBOUND_CONTAINER_VIEW_URL,
  ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_URL,
  ORDER_OUTBOUND_ROUTES_VIEW_URL,
  ORDER_OUTBOUND_SORTING_VIEW_URL,
  ROUTE_ITEM_URL,
} from "../../constants/AdminPathConstants";
import RouteDialogFormWrapper from "../../wrappers/admin/RouteDialogFormWrapper";
import {
  CONSOLIDATED,
  CONTAINER,
  ROUTE,
  SHIPMENT,
  SIMPLE,
} from "../../constants/OrderType";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  breadCrumbTitle: {
    color: "#777",
    textTransform: "uppercase",
    fontSize: 11,
  },
  title: {
    color: "#555",
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 4,
  },
  container: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  table: {
    height: "100%",
  },
  longString: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  containerRow: {
    extend: "row",
    background: "#aceb84",
    "&:hover": {
      background: "#aceb84 !important",
    },
  },
  searchField: {
    flex: "1 1 auto",
  },
  chip: {
    cursor: "pointer",
    marginRight: 5,
    marginBottom: 5,
  },
  chipSuccess: {
    cursor: "pointer",
    marginRight: 5,
    marginBottom: 5,
    backgroundColor: green[300],
    "&:hover": {
      backgroundColor: green[500],
    },
  },
});
const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isRouteCreator: hasUserPermission(state, "ADMIN_ROUTE_CREATE"),
      isRouteAssign: hasUserPermission(state, "ADMIN_ROUTE_ASSIGN"),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(
            fp.flow(
              fp.flow(
                fp.get("location.query"),
                fp.assign({ size: 20 }),
                toUserFilterMapper,
              ),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getBatchOrderUpdateList(
              props.outBoundSorting
                ? props.filter.setValueMap({
                    status: IN_TRANSIT,
                    type: props.tab,
                    size: props.tab === CONSOLIDATED ? 200 : 20,
                  })
                : toJS(props.filter).type
                ? props.filter
                    .setValue("include_children", true)
                    .setValue("size", 20)
                : props.filter.setValueMap({
                    include_children: true,
                    type: SIMPLE,
                    size: 20,
                  }),
            )
              .repeatWhen(() => onRequestRefreshStream)
              .map(
                fp.flow(
                  fp.update("pending", Boolean),
                  fp.update("progress", fp.toFinite),
                  fp.update("payload", fp.get("data")),
                  fromJS,
                ),
              ),
          )
          .distinctUntilChanged(isEqualData)
          .startWith(List());

        return propsStream.combineLatest(
          listResponseStream,
          (props, response) => ({
            ...props,
            onRequestRefresh,
            isLoading: response.get("pending"),
            list: response.getIn(["payload", "list"]),
            total: response.getIn(["payload", "total"]),
          }),
        );
      },
      propsStream => {
        const {
          stream: onChangeStream,
          handler: onChange,
        } = createEventHandler();

        const filterChangedStream = onChangeStream
          .distinctUntilChanged(isEqualData)
          .withLatestFrom(propsStream)
          .do(([changedValue, props]) =>
            props.setLocationQueryFilter(
              toUserFilterMapper(
                fp.merge(fp.get("location.query", props), changedValue),
              ),
            ),
          )
          .startWith(Map());

        return propsStream
          .combineLatest(filterChangedStream, props => ({
            ...props,
            onChange,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminOrderBatchUpdateContainer.propTypes = {
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  location: PropTypes.object,
  withFooter: PropTypes.bool,
  filter: PropTypes.objectOf(DataListFilter),
  list: PropTypes.objectOf(List),
  total: PropTypes.number,
  getLocalisationMessage: PropTypes.func,
  setLocationQueryFilter: PropTypes.func.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  outBoundSorting: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isRouteCreator: PropTypes.bool,
  isRouteAssign: PropTypes.bool,
  setLocation: PropTypes.func,
  tab: PropTypes.string,
  orders: PropTypes.instanceOf(Map),
  registries: PropTypes.instanceOf(Map),
  onChange: PropTypes.func,
};

AdminOrderBatchUpdateContainer.defaultProps = {
  withFooter: true,
  filter: new DataListFilter({
    page: 0,
    size: 200,
    type: SIMPLE,
  }),
  list: List(),
};

function AdminOrderBatchUpdateContainer({
  filter,
  getLocalisationMessage,
  isLoading,
  list,
  location,
  onRequestRefresh,
  setLocationQuery,
  setLocationQueryFilter,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  total,
  withFooter,
  outBoundSorting,
  setLocation,
  orders,
  registries,
  isRouteCreator,
  isRouteAssign,
  tab = toJS(filter).type,
  onChange,
}) {
  const classes = useStyles();
  const [batchId, setBatchId] = useState(false);
  const [isOpenCreateRouteDialog, setIsOpenCreateRouteDialog] = useState(false);
  const [isOpenEditRouteDialog, setIsOpenEditRouteDialog] = useState(false);
  const [warehouseBins, setWarehouseBins] = useState([]);
  const [sortedList, setSortedList] = useState(list);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(false);
  const [state, setState] = useState({
    createDialog: false,
    selectedRows: Set(),
    dynamicDownloadCSV: false,
    downloadNoteCSV: false,
    parseBatchIdsDialog: false,
    batchCreateOrderNotesDialog: false,
    batch_item_id: null,
    orderStatus: null,
  });
  const tempList = toJS(state.selectedRows);
  const firstItem = tempList[0];
  const filteredByWarehouse = sortedList.filter(
    v => v.getIn(["to_warehouse", "id"]) === selectedWarehouseId,
  );
  useEffect(() => setSortedList(list), [list]);
  useEffect(
    () =>
      selectedWarehouseId &&
      setState({
        ...state,
        selectedRows: filteredByWarehouse.toSet(),
      }),
    [selectedWarehouseId],
  );

  useEffect(() => {
    if (selectedWarehouseId) {
      const idsArray = toJS(filteredByWarehouse).map(v => v.id);
      setSortedList(
        fromJS([
          ...toJS(sortedList).filter(v => idsArray.find(x => x === v.id)),
          ...toJS(sortedList).filter(v => !idsArray.find(x => x === v.id)),
        ]),
      );
    }
  }, [selectedWarehouseId]);

  useEffect(() => {
    const temp = [];
    toJS(list).forEach(v => {
      const foundIndex = temp.findIndex(
        x => x.id === getValue(v, "to_warehouse.id"),
      );
      if (foundIndex > -1) temp[foundIndex].count += 1;
      else temp.push({ ...v.to_warehouse, count: 1 });
    });
    setWarehouseBins(temp);
  }, [list]);

  const type = fp.get("query.type", location);
  const selectedType = firstItem && firstItem.type;
  const ids =
    state.selectedRows.size > 0
      ? state.selectedRows.toList().map(r => r.get("id"))
      : List();
  const barcodes =
    state.selectedRows.size > 0
      ? state.selectedRows.toList().map(r => r.get("barcode"))
      : List();
  const buttons = ids.size > 0 && (
    <FlexBox style={{ marginRight: "2rem" }}>
      {(outBoundSorting && selectedType === SIMPLE) ||
      selectedType === CONSOLIDATED ? (
        <FlexBox>
          <CustomButton
            fullWidth={true}
            color={SECONDARY}
            style={{ maxWidth: "200px" }}
            variant={CONTAINED}
            onClick={() => {
              setState({
                ...state,
                orderStatus: IN_TRANSIT,
                createDialog: true,
                parentWarehouse: fp.get("next_warehouse", firstItem),
                to_warehouse: fp.get("next_warehouse", firstItem),
              });
            }}
          >
            {getLocalisationMessage("create_container")}
          </CustomButton>
        </FlexBox>
      ) : (
        selectedType === CONTAINER && (
          <FlexBox>
            {isRouteCreator && (
              <CustomButton
                fullWidth={true}
                color={SECONDARY}
                style={{ minWidth: "200px", marginRight: "1rem" }}
                variant={CONTAINED}
                onClick={() => setIsOpenCreateRouteDialog(true)}
              >
                {getLocalisationMessage("create_route")}
              </CustomButton>
            )}
            {isRouteAssign && (
              <CustomButton
                fullWidth={true}
                color={SECONDARY}
                style={{ minWidth: "230px", marginRight: "1rem" }}
                variant={CONTAINED}
                onClick={() => setIsOpenEditRouteDialog(true)}
              >
                {getLocalisationMessage("add_to_route")}
              </CustomButton>
            )}
          </FlexBox>
        )
      )}
    </FlexBox>
  );
  const typeFilters = [
    {
      title: getLocalisationMessage("simple"),
      value: SIMPLE,
    },
    {
      title: getLocalisationMessage("consolidated"),
      value: CONSOLIDATED,
    },
    {
      title: getLocalisationMessage("container"),
      value: CONTAINER,
    },
    {
      title: getLocalisationMessage("route"),
      value: ROUTE,
    },
  ];

  return (
    <AdminAppLayout
      title={getLocalisationMessage("order_batch_update", "order batch update")}
    >
      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        refreshBatchList={onRequestRefresh}
        onRequestClose={() => {
          setIsOpenEditRouteDialog(false);
          setIsOpenCreateRouteDialog(false);
          setBatchId(false);
        }}
        onShowOrdersClick={numbers =>
          setLocationQuery({ search: numbers.join(",") })
        }
      />
      <RouteDialogFormWrapper
        warehouseIds={toJS(state.selectedRows).map(v => v.warehouse.id)}
        open={isOpenCreateRouteDialog || isOpenEditRouteDialog}
        isEdit={isOpenEditRouteDialog}
        initialValues={{
          batchType: "active",
          orderBarcodes: state.selectedRows.size > 0 ? barcodes : List(),
          orderStatus: state.orderStatus,
          privacy: PUBLIC,
          weight:
            Math.round(
              state.selectedRows.reduce(
                (a, b) => a + parseFloat(b.get("weight") || 0),
                0,
              ) * 100,
            ) / 100,
        }}
        onRequestClose={() => {
          setIsOpenCreateRouteDialog(false);
          setIsOpenEditRouteDialog(false);
        }}
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={res => {
          setIsOpenCreateRouteDialog(false);
          setIsOpenEditRouteDialog(false);
          setBatchId(res.data.id);
          setState({ createDialog: false, selectedRows: Set() });
          onRequestRefresh();
        }}
      />
      <AdminBatchUpdateOrderDialogWrapper
        title="create_container"
        isContainer={tab === CONSOLIDATED}
        isSameWarehouses={tempList.every(
          i => fp.get("warehouse.id", i) === fp.get("warehouse.id", firstItem),
        )}
        // isDifferentTypes={isDifferentTypes}
        open={state.createDialog}
        initialValues={{
          batchType: "active",
          orderBarcodes: toJS(barcodes),
          orderStatus: state.orderStatus,
          warehouse: state.parentWarehouse,
          to_warehouse: state.to_warehouse,
          privacy: PUBLIC,
          weight:
            Math.round(
              state.selectedRows.reduce(
                (a, b) => a + parseFloat(b.get("weight") || 0),
                0,
              ) * 100,
            ) / 100,
        }}
        onRequestClose={() =>
          setState({
            selectedRows: Set(),
            createDialog: false,
            orderStatus: null,
          })
        }
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={res => {
          setBatchId(res.data.id);
          setState({ createDialog: false, selectedRows: Set() });
          onRequestRefresh();
        }}
      />

      {state.dynamicDownloadCSV && (
        <DownloadDynamicCSVDialog
          open={true}
          getDynamicFields={getDynamicFields}
          createDownloadCSVHref={columns =>
            formatDateValuesForDownloadCSV(
              filter.setValue("csv_columns", columns),
            ).toJS()
          }
          onRequestClose={() =>
            setState({ ...state, dynamicDownloadCSV: false })
          }
        />
      )}

      {state.parseBatchIdsDialog && (
        <AdminParseBatchIdsDialogWrapper
          open={true}
          onRequestClose={() => {
            setState({
              ...state,
              parseBatchIdsDialog: false,
            });
          }}
          onSubmit={() => {
            setState({
              ...state,
              parseBatchIdsDialog: false,
            });
          }}
          onBatchAssignSupplier={() => {
            setState({
              ...state,
              parseBatchIdsDialog: false,
            });
          }}
        />
      )}

      {state.batchCreateOrderNotesDialog && (
        <AdminBatchOrderNoteDialogWrapper
          open={true}
          onRequestClose={() => {
            setState({
              ...state,
              batchCreateOrderNotesDialog: false,
            });
          }}
          initialValues={{
            orderNumbers:
              state.selectedRows.size > 0
                ? state.selectedRows.toList().map(r => r.get("id"))
                : List(),
          }}
          onSubmitSuccess={() => {
            setState({
              ...state,
              batchCreateOrderNotesDialog: false,
            });
            showSuccessMessage1(
              getLocalisationMessage(
                "successfully_updated_order_notes",
                "Successfully Updated Order Notes",
              ),
            );
          }}
          onSubmitFail={showErrorMessage1}
        />
      )}

      <div className={classes.container}>
        <Paper className={classes.paper}>
          {outBoundSorting ? (
            <React.Fragment>
              <OrderSortingTypeTabs
                orderType={tab}
                registries={registries}
                orders={orders}
                onTabChange={actualTab => {
                  setLocation(
                    actualTab === SHIPMENT
                      ? ORDER_OUTBOUND_SORTING_VIEW_URL
                      : actualTab === SIMPLE
                      ? ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_URL
                      : actualTab === CONSOLIDATED
                      ? ORDER_OUTBOUND_CONSOLIDATED_VIEW_URL
                      : actualTab === CONTAINER
                      ? ORDER_OUTBOUND_CONTAINER_VIEW_URL
                      : ORDER_OUTBOUND_ROUTES_VIEW_URL,
                  );
                }}
              />
              {tab === CONSOLIDATED && (
                <div
                  style={{
                    margin: "1rem",
                    maxHeight: "20vh",
                    overflow: "auto",
                  }}
                >
                  {warehouseBins.map((v, i) => (
                    <Chip
                      key={i}
                      className={`${classes.chip} ${v.id ===
                        selectedWarehouseId && classes.chipSuccess}`}
                      style={{
                        backgroundColor:
                          v.id === selectedWarehouseId && green[300],
                      }}
                      onClick={() => {
                        if (v.id === selectedWarehouseId) {
                          setState({ ...state, selectedRows: Set() });
                          setSelectedWarehouseId(false);
                        } else {
                          setSelectedWarehouseId(v.id);
                        }
                      }}
                      label={`${v.name} (${v.count})`}
                    />
                  ))}
                </div>
              )}
              <FlexBox
                flex={true}
                style={{
                  flex: "0 1 0%",
                  padding: ".5rem 0",
                }}
              >
                <FlexBox flex={true} style={{ margin: "0 1rem" }}>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      startAdornment: <Search />,
                      style: {
                        height: 35,
                        padding: "0 12px",
                      },
                    }}
                    value={fp.get("query.search", location) || ""}
                    fullWidth={true}
                    placeholder={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("search")}
                    onChange={fp.flow(fp.get("target.value"), value =>
                      onChange({ search: value }),
                    )}
                  />
                </FlexBox>
                <FlexBox>{buttons}</FlexBox>
              </FlexBox>
            </React.Fragment>
          ) : (
            <AdminOrderBatchUpdateListHeaderWrapper
              location={location}
              tabItems={typeFilters}
              filter={filter}
              clearSelectedItems={() => setState({ selectedRows: Set() })}
              onFilterChange={setLocationQueryFilter}
              tabValue={type || SIMPLE}
              searchText={fp.get("query.search", location) || ""}
              buttons={buttons}
            />
          )}

          <DataList
            // isDisableOption={outBoundSorting}
            // disableOthersType="type"
            // disableOthersValue={selectedType}
            isLoading={isLoading}
            withHeader={false}
            overscanRowCount={10}
            totalCount={total}
            className={classes.table}
            rowCount={sortedList.size}
            list={sortedList}
            rowGetter={options => sortedList.get(options.index)}
            withFooter={withFooter}
            onFilterChange={f => setLocationQueryFilter(f)}
            filter={filter}
            // getRowClassName={(row) => rowClass(row)}
          >
            {tab !== ROUTE && (
              <DataListCheckbox
                rowSelected={row => state.selectedRows.has(row.cellData)}
                allRowsSelected={() =>
                  state.selectedRows.size > 0 &&
                  state.selectedRows.size === sortedList.size
                }
                onRowSelect={row => {
                  setSelectedWarehouseId(false);
                  setState({
                    ...state,
                    selectedRows: row.selected
                      ? state.selectedRows.add(row.cellData)
                      : state.selectedRows.delete(row.cellData),
                  });
                }}
                onAllRowsSelect={value => {
                  setSelectedWarehouseId(false);
                  setState({
                    selectedRows: value ? sortedList.toSet() : Set(),
                  });
                }}
              />
            )}
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("barcode", "Barcode")}
              dataKey="barcode"
              justifyContent="center"
              cellRenderer={row => (
                <LinkButton
                  onClick={() =>
                    tab === ROUTE
                      ? setLocation(ROUTE_ITEM_URL + row.cellData.get("id"))
                      : setBatchId(row.cellData.get("id"))
                  }
                >
                  {row.cellData.get("barcode")}
                </LinkButton>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("created_date", "Created Date")}
              dataKey="created_date"
              justifyContent="center"
              cellRenderer={row => (
                <DateTimeCell date={row.cellData.get("created_date")} />
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("type", "Type")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>
                  {getLocalisationMessage(
                    tab === CONSOLIDATED &&
                      row.cellData.get("type") !== CONSOLIDATED
                      ? row.cellData.get("inner_shipment_type")
                      : row.cellData.get("type"),
                  )}
                </div>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("from_warehouse", "From Warehouse")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>{row.cellData.getIn(["from_warehouse", "name"])}</div>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("next_warehouse")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>{row.cellData.getIn(["next_warehouse", "name"])}</div>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("to_warehouse", "Warehouse")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>{row.cellData.getIn(["to_warehouse", "name"])}</div>
              )}
            />
          </DataList>
        </Paper>
      </div>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderBatchUpdateContainer);
