import React from "react";
import { fromJS, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { CardContent, CardHeader, Link } from "@material-ui/core";
import { connect } from "react-redux";
import { pureComponent } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  CheckCircle,
  ChevronRight,
  ExpandMore,
  ReportProblem,
  Refresh,
} from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { green, grey, orange, red } from "@material-ui/core/colors";
import cx from "classnames";
import { isEqualData } from "../../helpers/DataUtils";
import { replaceMiddleString } from "../../helpers/OrderSortingHelper";
// eslint-disable-next-line import/no-extraneous-dependencies
import { arrayToTree } from "performant-array-to-tree";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";

const useStyles = makeStyles(() => ({
  hierarchy: { flexDirection: "column", marginBottom: 8 },
  root: {
    height: "100%",
    flexGrow: 1,
    width: "100%",
  },
  fontNormal: {
    fontWeight: 400,
    color: "rgba(38, 50, 56, 0.7)",
  },
  fontWeight: {
    fontWeight: 500,
    color: "#000",
  },
  fontSmallSize: {
    fontSize: 12,
  },
  wrongLocation: {
    color: red[500],
  },
  treeItemLabelContent: {
    lineHeight: "28px",
  },
  treeItemLabel: {
    fontSize: 18,
  },
  treeItemLabelAdd: {
    marginLeft: 10,
    fontSize: 12,
    color: "rgba(38, 50, 56, 0.7)",
  },
  treeItemLabelIcon: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: -5,
    fontSize: 18,
  },
  "@media (min-width: 998px)": {
    hierarchy: { flexDirection: "column", width: "100%" },
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} />);

const barcodeStatusIcon = ({ nodes, ...props }) => {
  const classes = useStyles();

  if (fp.get("failed", nodes))
    return (
      <span>
        <ReportProblem
          className={classes.treeItemLabelIcon}
          style={{ color: red[500] }}
        />{" "}
        <span className={classes.fontSmallSize}>
          {props.getLocalisationMessage("not_exists", "Could not find order")}.
        </span>
      </span>
    );

  if (
    !fp.get("parent_id", nodes) &&
    !fp.get("info.type", nodes) &&
    fp.get("info.warehouse.id", nodes)
  )
    return (
      <span>
        <ReportProblem
          className={classes.treeItemLabelIcon}
          style={{ color: orange[500] }}
        />{" "}
        <span className={classes.fontSmallSize}>
          {props.getLocalisationMessage("misrouted", "Misrouted order")}.
        </span>
      </span>
    );

  const destinationWarehouse = fp.get("info.to_warehouse.id", nodes);
  const type = fp.get("info.type", nodes);
  const children = fp.get("children", nodes);
  const showOpenRegistry =
    nodes.scanned &&
    destinationWarehouse !== props.warehouseId &&
    type &&
    children &&
    children.length === 0;

  if (!fp.get("scanned_time", nodes) && nodes.scanned) {
    return (
      <span>
        <Refresh
          className={classes.refresh}
          style={{ color: green[500], fontWeight: "bold" }}
        />{" "}
        <Link
          className={classes.fontSmallSize}
          style={{ color: red[500], margin: "0 10px", display: "inline-block" }}
          onClick={e => {
            e.preventDefault();
            return props.onRetry(fp.get("number", nodes));
          }}
        >
          {props.getLocalisationMessage("retry", "Retry")}.
        </Link>
      </span>
    );
  }

  return (
    <span>
      <CheckCircle
        className={classes.treeItemLabelIcon}
        style={{ color: nodes.scanned ? green[500] : grey[500] }}
      />

      {showOpenRegistry && (
        <Link
          className={classes.treeItemLabelAdd}
          style={{ color: red[500] }}
          onClick={e => {
            e.preventDefault();
            return props.onOpenRegistry(fp.get("number", nodes));
          }}
        >
          {type === "CONSOLIDATED"
            ? props.getLocalisationMessage("open_bag", "Open Bag")
            : props.getLocalisationMessage("open_registry", "Open Registry")}
        </Link>
      )}
    </span>
  );
};

const TreeItemLabel = props => {
  const classes = useStyles();
  const { nodes } = props;

  return (
    <div className={classes.treeItemLabelContent}>
      <span
        className={cx(
          classes.treeItemLabel,
          fp.get("scanned", nodes) ? classes.fontNormal : classes.fontWeight,
          (fp.get("failed", nodes) ||
            (!fp.get("parent_id", nodes) &&
              !fp.get("info.type", nodes) &&
              fp.get("info.warehouse.id", nodes))) &&
            classes.wrongLocation,
        )}
      >
        {fp.get("scanned", nodes) || fp.get("info.type", nodes)
          ? nodes.number
          : replaceMiddleString(nodes.number, 5)}
      </span>

      {barcodeStatusIcon(props)}
    </div>
  );
};

const findParents = (barcode, orders, barcodes = []) => {
  barcodes.push(barcode);
  const order = orders.get(barcode, null);

  if (order) {
    const parentId = order.get("parent_id", null);

    if (parentId) {
      return findParents(parentId, orders, barcodes);
    }
  }

  return barcodes;
};

TreeItemLabel.propTypes = {
  nodes: PropTypes.object,
};

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const ordersTreeStream = propsStream
      .map(fp.pick(["orders", "cursorOrder"]))
      .distinctUntilChanged(isEqualData)
      .map(({ orders, cursorOrder }) => {
        const activeTree = cursorOrder ? findParents(cursorOrder, orders) : [];
        const batches = orders
          .filter(order => order.get("child_count"))
          .map(order => order.get("number"));

        const tree = arrayToTree(
          orders
            .toList()
            .map(order => {
              const scanned = order.get("scanned", false);
              const scannedTime = order.get("scanned_time", null);

              if (scanned && !scannedTime) {
                return order.set("parent_id", null);
              }

              return order;
            })
            .sort((a, b) => {
              const aBarcode = a.get("number", null);
              const bBarcode = b.get("number", null);
              const aScanned = a.get("scanned", false);
              const bScanned = b.get("scanned", false);
              const aTime = a.get("scanned_time", 0);
              const bTime = b.get("scanned_time", 0);

              if (aTime === 0 || bTime === 0) {
                if (aScanned && aTime === 0) return -1;

                if (bScanned && bTime === 0) return 1;

                if (aTime === 0) return -1;

                if (bTime === 0) return 1;
              }

              if (activeTree.includes(aBarcode)) return -1;

              if (activeTree.includes(bBarcode)) return 1;

              if (aTime > bTime) return -1;

              if (aTime < bTime) return 1;

              return 0;
            })
            .toJS(),
          {
            id: "number",
            parentId: "parent_id",
            dataField: null,
          },
        );

        return fromJS({ batches, tree });
      })
      .startWith(Map());

    return propsStream.combineLatest(ordersTreeStream, (props, response) => ({
      ...props,
      ordersTree: response.get("tree", List()),
      activeNodes: response.get("batches", List()),
    }));
  }),
  pureComponent(
    fp.pick([
      "orders",
      "ordersTree",
      "activeNodes",
      "onBatchCompleteRequest",
      "pendingOrdersCount",
    ]),
  ),
);

OrderSortingBinHeirarchyCard.propTypes = {
  pendingOrdersCount: PropTypes.number,
  warehouseId: PropTypes.number,
  cursorOrder: PropTypes.string,

  orders: PropTypes.instanceOf(Map),
  ordersTree: PropTypes.instanceOf(List),
  activeNodes: PropTypes.instanceOf(Set),

  getLocalisationMessage: PropTypes.func.isRequired,
  onBatchCompleteRequest: PropTypes.func,
  onOpenRegistry: PropTypes.func,
  onRetry: PropTypes.func,
  onRetryAllBarcodes: PropTypes.func,
};

function OrderSortingBinHeirarchyCard(props) {
  const classes = useStyles();
  const { getLocalisationMessage } = props;
  const activeNodes = props.activeNodes ? props.activeNodes.toArray() : [];

  const renderTree = nodes => (
    <StyledTreeItem
      key={nodes.number}
      nodeId={nodes.number}
      label={<TreeItemLabel nodes={nodes} {...props} />}
    >
      {Array.isArray(nodes.children)
        ? nodes.children
            // .filter((v) => v.isSuccess)
            .map(node => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  return (
    <div className={classes.hierarchy}>
      <CardHeader
        title={
          <h5>
            {getLocalisationMessage("batch_hierarchy", "Batch Hierarchy")}
          </h5>
        }
        action={
          <CustomButton
            color={SECONDARY}
            variant={CONTAINED}
            onClick={() => props.onRetryAllBarcodes([])}
          >
            {getLocalisationMessage("retry", "Retry")}
          </CustomButton>
        }
      />

      <CardContent>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMore />}
          expanded={activeNodes}
          defaultExpandIcon={<ChevronRight />}
        >
          {props.ordersTree.toJS().map(item => renderTree(item))}
        </TreeView>
      </CardContent>
    </div>
  );
}

export default enhancer(OrderSortingBinHeirarchyCard);
