import React from "react";
import { OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import { PanTo } from "react-google-map-components";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormOpenCloseTime from "../form/FormOpenCloseTime";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormImageListUpload from "../form/FormImageListUpload";
import FormCountryAutoComplete from "../form/FormCountryAutoComplete";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { PanTo as LeafletPanTo } from "react-leflet-map-components";
import LeafletBrandMaker from "../maps/osm/BrandMaker";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    mapProvider: getMapProvider(state),
  })),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  useSheet({ attachments: { paddingTop: "12px" } }),
  reduxForm({ form: "VenueForm", enableReinitialize: true }),
  formValues("location", "country"),
);

const venueStatusOptions = OrderedSet.of(ACTIVE, INACTIVE);

AdminVenueForm.propTypes = {
  location: PropTypes.object,
  country: PropTypes.object,

  classes: PropTypes.object,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  handleChange: PropTypes.func,
  onDismissClick: PropTypes.func.isRequired,
  getCachedCustomer: PropTypes.func.isRequired,
  getCustomerPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  mapProvider: PropTypes.string,
};

function AdminVenueForm(props) {
  const { classes, getLocalisationMessage } = props;

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <Card>
      <CardContent>
        <PageLoading isLoading={props.submitting} />

        <form autoComplete="off" onSubmit={props.handleSubmit}>
          <FlexBox container={8} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <FormTextField
                  name="name"
                  label={getLocalisationMessage("name", "Name")}
                  fullWidth={true}
                />
                <FlexBox gutter={8} flex={true}>
                  {Boolean(!getValue(props.initialValues, "id")) && (
                    <FlexBox flex={true}>
                      <FormCountryAutoComplete
                        fullWidth={true}
                        name="country"
                        label={getLocalisationMessage("country", "Country")}
                      />
                    </FlexBox>
                  )}
                  <FlexBox flex={true}>
                    <FormGeoAutoComplete
                      name="location"
                      countryCode={getValue(props.country, "code")}
                      fullWidth={true}
                      label={getLocalisationMessage("location", "Location")}
                    />
                  </FlexBox>
                </FlexBox>
                <FormCustomerAutoComplete
                  name="merchant"
                  fullWidth={true}
                  label={getLocalisationMessage(
                    "select_merchant",
                    "Select Merchant",
                  )}
                />
                <FormSelectField
                  name="status"
                  fullWidth={true}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  options={venueStatusOptions}
                  label={getLocalisationMessage("status", "Status")}
                />
                <FormTextField
                  name="description"
                  rows={4}
                  multiLine={true}
                  label={getLocalisationMessage("description", "Description")}
                  fullWidth={true}
                />
              </FlexBox>
              <FlexBox flex={true}>
                {isGoogleMapProvider ? (
                  <GoogleMapWrapper
                    onClick={event =>
                      props.change("location", {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                      })
                    }
                  >
                    {isValidCoordinates(props.location) && (
                      <div>
                        <PanTo latLng={props.location} />

                        <BrandMarker position={props.location} />
                      </div>
                    )}
                  </GoogleMapWrapper>
                ) : (
                  <LeafletMapWrapper
                    onClick={event =>
                      props.change("location", {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                      })
                    }
                  >
                    {isValidCoordinates(props.location) && (
                      <div>
                        <LeafletPanTo latLng={props.location} />

                        <LeafletBrandMaker position={props.location} />
                      </div>
                    )}
                  </LeafletMapWrapper>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <FormOpenCloseTime
                  openTimeField="businessHours.sunday.openTime"
                  closeTimeField="businessHours.sunday.closeTime"
                  labelText={getLocalisationMessage("sunday", "Sunday")}
                />
                <FormOpenCloseTime
                  openTimeField="businessHours.monday.openTime"
                  closeTimeField="businessHours.monday.closeTime"
                  labelText={getLocalisationMessage("monday", "Monday")}
                />
                <FormOpenCloseTime
                  openTimeField="businessHours.tuesday.openTime"
                  closeTimeField="businessHours.tuesday.closeTime"
                  labelText={getLocalisationMessage("tuesday", "Tuesday")}
                />
                <FormOpenCloseTime
                  openTimeField="businessHours.wednesday.openTime"
                  closeTimeField="businessHours.wednesday.closeTime"
                  labelText={getLocalisationMessage("wednesday", "Wednesday")}
                />
                <FormOpenCloseTime
                  openTimeField="businessHours.thursday.openTime"
                  closeTimeField="businessHours.thursday.closeTime"
                  labelText={getLocalisationMessage("thursday", "Thursday")}
                />
                <FormOpenCloseTime
                  openTimeField="businessHours.friday.openTime"
                  closeTimeField="businessHours.friday.closeTime"
                  labelText={getLocalisationMessage("friday", "Friday")}
                />
                <FormOpenCloseTime
                  openTimeField="businessHours.saturday.openTime"
                  closeTimeField="businessHours.saturday.closeTime"
                  labelText={getLocalisationMessage("saturday", "Saturday")}
                />
              </FlexBox>
              <FlexBox
                flex={true}
                direction="column"
                className={classes.attachments}
              >
                <FormImageListUpload
                  name="photos"
                  label={getLocalisationMessage("attachments", "Attachments")}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox gutter={8} flex={true}>
              <FlexBox>
                <CardActions>
                  <Button variant="text" onClick={props.onDismissClick}>
                    {getLocalisationMessage("dismiss", "Dismiss")}
                  </Button>
                  <Button variant="text" type="submit">
                    {getLocalisationMessage("save", "Save")}
                  </Button>
                </CardActions>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </CardContent>
    </Card>
  );
}

export default enhancer(AdminVenueForm);
