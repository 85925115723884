import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITable, { LINK, RENDER } from "../../components/orders-core/MUITable";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Add, FilterList, Refresh, Search } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import {
  LOCKER_POST_CREATE_URL,
  LOCKER_POST_ITEM_URL,
} from "../../constants/AdminPathConstants";
import _ from "lodash";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString } from "../../helpers/SerializeUtils";
import { getValue } from "../../helpers/DataUtils";
import { getLockerPostList } from "../../api/admin/AdminLockerpostApi";
import SimpleTabs from "../../components/ui-core/SimpleTabs";
import { ALL } from "../../constants/OverallStatusTabs";
import LockerPostFilterForm from "../../wrappers/admin/LockerPostFilterForm";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import Text from "../../components/ui-core/Text";
import { formatText } from "../../helpers/FormatUtils";

export function lockerPostLocalisation(option, getLocalisationMessage) {
  switch (option) {
    case "enable":
      return (
        <Typography variant="p" style={{ color: "#21C004" }}>
          {getLocalisationMessage("connected")}
        </Typography>
      );

    case "disable":
      return (
        <Typography variant="p" style={{ color: "#f44336" }}>
          {getLocalisationMessage("disconnected")}
        </Typography>
      );

    default:
      return option
        ? getLocalisationMessage(option, formatText(option))
        : getLocalisationMessage("na");
  }
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

AdminLockerPostList.propTypes = {
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminLockerPostList(props) {
  const { getLocalisationMessage } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
    status: null,
  });
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [tabs, setTabs] = useState([
    {
      title: getLocalisationMessage("all"),
      value: ALL,
      count: 0,
    },
    {
      title: getLocalisationMessage("active"),
      value: "active",
      count: 0,
    },
    {
      title: getLocalisationMessage("inactive"),
      value: "inactive",
      count: 0,
    },
  ]);

  const classes = useStyles();

  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  useEffect(() => {
    setIsLoading(true);
    getLockerPostList(filter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
          setTabs([
            {
              title: getLocalisationMessage("all"),
              value: null,
              count: getValue(res, "data.total", 0),
            },
            {
              title: getLocalisationMessage("active"),
              value: "active",
              count: getValue(res, "data.active", 0),
            },
            {
              title: getLocalisationMessage("inactive"),
              value: "inactive",
              count: getValue(res, "data.inactive", 0),
            },
          ]);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  return (
    <AdminAppLayout title={getLocalisationMessage("locker_post")}>
      <LockerPostFilterForm
        initialValues={filter}
        open={isOpenFilter}
        onFilterChange={v =>
          setFilter({
            ...filter,
            jurisdiction_id: v.jurisdiction_id,
          })
        }
        onRequestClose={() => setIsOpenFilter(false)}
      />
      <div className={classes.root}>
        <div className={classes.tabs}>
          <SimpleTabs
            variant="fullWidth"
            items={tabs}
            value={filter.status}
            onChange={(e, status) => {
              setFilter({
                ...filter,
                status,
              });
            }}
          />
        </div>
      </div>
      <MUITable
        isLoading={isLoading}
        size="medium"
        list={list}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            style={{
              flex: "1 1 auto",
              padding: ".5rem 0",
            }}
          >
            <FlexBox flex={true} style={{ margin: "0 1rem" }}>
              <div className={classes.search}>
                <Search
                  fontSize="large"
                  style={{
                    color: "rgba(0, 0, 0, 0.23)",
                    top: ".5rem",
                    left: ".5rem",
                    position: "absolute",
                  }}
                />
                <ChipTextField
                  style={{
                    padding: ".5rem 1rem 0 3rem",
                  }}
                  fullWidth={true}
                  addOnBlur={false}
                  clearOnBlur={false}
                  value={parseString(filter.search)}
                  disableUnderline={true}
                  placeholder={getLocalisationMessage("type_here_to_search")}
                  onChange={v => {
                    setFilter({ ...filter, search: v[v.length - 1] });
                  }}
                />
              </div>
            </FlexBox>
            <FlexBox>
              <CustomButton
                onClick={() => props.setLocation(LOCKER_POST_CREATE_URL)}
                startIcon={<Add />}
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px" }}
              >
                {getLocalisationMessage(
                  "add_new_locker_post",
                  "Add new Locker post",
                )}
              </CustomButton>
              <IconButton
                className={isLoading && classes.refresh}
                tooltip={getLocalisationMessage("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
              <IconButton onClick={() => setIsOpenFilter(true)}>
                <FilterList />
              </IconButton>
            </FlexBox>
          </FlexBox>
        }
        columns={[
          {
            type: LINK,
            name: "locker_post_id",
            label: getLocalisationMessage("locker_post_id", "Locker Post ID"),
            link: LOCKER_POST_ITEM_URL,
            align: "center",
          },
          {
            type: RENDER,
            name: "name",
            label: getLocalisationMessage("name_1", "Name"),
            render: row => (
              <span style={{ textAlign: "center" }}>
                {_.get(row, "name", "-")}
              </span>
            ),
          },
          {
            type: RENDER,
            name: "warehouse",
            label: getLocalisationMessage("warehouse"),
            render: row => {
              const warehouse = _.get(row, `warehouse`, {});
              return warehouse
                ? getValue(warehouse, "name")
                : getLocalisationMessage("na", "N/A");
            },
          },
          {
            type: RENDER,
            name: "description",
            label: getLocalisationMessage("jurisdiction"),
            render: row => {
              const jurisdiction = _.get(row, `jurisdiction`, {});
              return jurisdiction
                ? getValue(jurisdiction, "name")
                : getLocalisationMessage("na", "N/A");
            },
          },
          {
            type: RENDER,
            name: "address",
            label: getLocalisationMessage("address"),
            render: row => <span>{row.address}</span>,
          },
          {
            type: RENDER,
            name: "locker_post_status",
            label: getLocalisationMessage(
              "lockerpost_status",
              "Lockerpost Status",
            ),
            render: row => (
              <span>
                {lockerPostLocalisation(
                  row.locker_post_status,
                  getLocalisationMessage,
                )}
              </span>
            ),
          },
          {
            type: RENDER,
            name: "small",
            label: getLocalisationMessage("small"),
            render: row => (
              <MultiLineCell
                firstLine={
                  <FlexBox direction="row" align={ALIGN_CENTER}>
                    <Text style={{ color: "#21C004", width: 100 }}>
                      {getLocalisationMessage("free")}:
                    </Text>
                    <span style={{ color: "#21C004" }}>
                      {_.get(row, "small.free", "0")}
                    </span>
                  </FlexBox>
                }
                secondLine={
                  <FlexBox direction="row" align={ALIGN_CENTER}>
                    <Text style={{ width: 100 }}>
                      {getLocalisationMessage("total")}:
                    </Text>
                    <span>{_.get(row, "small.total", "0")}</span>
                  </FlexBox>
                }
              />
            ),
          },
          {
            type: RENDER,
            name: "medium",
            label: getLocalisationMessage("medium"),
            render: row => (
              <MultiLineCell
                firstLine={
                  <FlexBox direction="row" align={ALIGN_CENTER}>
                    <Text style={{ color: "#21C004", width: 100 }}>
                      {getLocalisationMessage("free")}:
                    </Text>
                    <span style={{ color: "#21C004" }}>
                      {_.get(row, "medium.free", "0")}
                    </span>
                  </FlexBox>
                }
                secondLine={
                  <FlexBox direction="row" align={ALIGN_CENTER}>
                    <Text style={{ width: 100 }}>
                      {getLocalisationMessage("total")}:
                    </Text>
                    <span>{_.get(row, "medium.total", "0")}</span>
                  </FlexBox>
                }
              />
            ),
          },
          {
            type: RENDER,
            name: "large",
            label: getLocalisationMessage("large"),
            render: row => (
              <MultiLineCell
                firstLine={
                  <FlexBox direction="row" align={ALIGN_CENTER}>
                    <Text style={{ color: "#21C004", width: 100 }}>
                      {getLocalisationMessage("free")}:
                    </Text>
                    <span style={{ color: "#21C004" }}>
                      {_.get(row, "large.free", "0")}
                    </span>
                  </FlexBox>
                }
                secondLine={
                  <FlexBox direction="row" align={ALIGN_CENTER}>
                    <Text style={{ width: 100 }}>
                      {getLocalisationMessage("total")}:
                    </Text>
                    <span>{_.get(row, "large.total", "0")}</span>
                  </FlexBox>
                }
              />
            ),
          },
          {
            type: RENDER,
            name: "active",
            label: getLocalisationMessage("active"),
            render: row => <span>{getLocalisationMessage(row.status)}</span>,
          },
        ]}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminLockerPostList);
