import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DRIVER_ORDER_RADAR_URL } from "../../constants/AdminPathConstants";
import RadarDriverOrderFilterTabs from "./RadarDriverOrderFilterTabs";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const batchGetDriverOrdersCount = filters =>
      Observable.from(
        filters
          .map(({ filter, getOrderList }, key) =>
            getOrderList(
              filter.setValueMap({
                tab: null,
                is_delivery_mode: null,
              }),
            )
              .catch(error => Observable.of({ error }))
              .map(fp.get("payload.data.total"))
              .takeLast(1)
              .delay(1000)
              .map(value => ({ key, value })),
          )
          .toArray(),
      )
        .concatAll()
        .scan((acc, response) => acc.set(response.key, response.value), Map())
        .startWith(Map());

    return propsStream.combineLatest(props => ({
      ...props,
      batchGetDriverOrdersCount,
    }));
  }),
);

RadarDriverOrderTabs.propTypes = {
  onFilterClick: PropTypes.func,
  batchGetDriverOrdersCount: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  onOrderRefreshRequest: PropTypes.func,
  getOrderList: PropTypes.func,
};

function RadarDriverOrderTabs(props) {
  return (
    <FlexBox flex={true}>
      <RadarDriverOrderFilterTabs
        onFilterClick={props.onFilterClick}
        basePathname={DRIVER_ORDER_RADAR_URL}
        filter={props.filter}
        batchGetDriverOrdersCount={props.batchGetDriverOrdersCount}
        onOrderRefreshRequest={props.onOrderRefreshRequest}
        getOrderList={props.getOrderList}
      />
    </FlexBox>
  );
}

export default enhancer(RadarDriverOrderTabs);
