import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  setPropTypes,
} from "recompose";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";

const enhancer = compose(
  setPropTypes({ uploadFileStream: PropTypes.func.isRequired }),
  useSheet({
    buttonOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: "100%",
    },
    input: { extend: "buttonOverlay", opacity: 0, cursor: "pointer" },
    progress: { lineHeight: "24px" },
    progressOverlay: {
      extend: "buttonOverlay",
      textAlign: "center",
      cursor: "not-allowed",
      backgroundColor: "#fff",
    },
  }),
  mapPropsStream(propsStream => {
    const {
      handler: handleSubmit,
      stream: onChangeStream,
    } = createEventHandler();

    const uploadStream = onChangeStream
      .map(fp.get(["target", "files", 0]))
      .withLatestFrom(propsStream)
      .switchMap(
        ([file, props]) =>
          props.uploadFileStream(file).catch(error => Observable.of({ error })),
        ([, props], response) => [props, response],
      )
      .do(([props, response]) => {
        if (fp.isError(response.error)) {
          if (props.onError) {
            props.onError(
              response.error.reasonMessage || response.error.message,
            );
          }
        } else if (response.payload) {
          if (props.onChange) {
            props.onChange(response.payload);
          }
        }
      })
      .map(([, response]) => ({
        pending: response.pending,
        progress: response.progress,
      }))
      .distinctUntilChanged(fp.isEqual)
      .startWith(null);

    return propsStream
      .combineLatest(uploadStream, (props, uploadData) => ({
        ...props,
        ...uploadData,
        onChange: handleSubmit,
      }))
      .map(
        fp.omit([
          "visited",
          "autofilled",
          "uploadFileStream",
          "initialValue",
          "autofill",
          "onUpdate",
          "valid",
          "invalid",
          "dirty",
          "pristine",
          "active",
          "touched",
        ]),
      )
      .distinctUntilChanged(fp.isEqual);
  }),
);

UploadButton.propTypes = {
  sheet: PropTypes.object,
  style: PropTypes.object,
  pending: PropTypes.bool,
  progress: PropTypes.number,
  accept: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  buttonComponent: PropTypes.func,
};

UploadButton.defaultProps = {
  onBlur: fp.noop,
  onFocus: fp.noop,
  buttonComponent: Button,
};

function UploadButton(props) {
  const {
    sheet: { classes },
    buttonComponent: ButtonComponent,
    style,
  } = props;

  return (
    <ButtonComponent
      component="a"
      className={props.className}
      style={{
        ...style,
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        color: "rgba(0, 0, 0, 0.55)",
        borderRadius: "5px",
      }}
      onChange={props.onChange}
      onBlur={() => props.onBlur()}
      onFocus={() => props.onFocus()}
      disabled={props.disabled || props.pending}
    >
      {props.label}
      {props.pending ? (
        <div className={classes.progressOverlay}>
          <CircularProgress
            size={24}
            thickness={2}
            variant="determinate"
            value={props.progress}
            className={classes.progress}
          />
        </div>
      ) : (
        <input type="file" accept={props.accept} className={classes.input} />
      )}
    </ButtonComponent>
  );
}

export default enhancer(UploadButton);
