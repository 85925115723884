import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Add } from "@material-ui/icons";
import { loadBankList } from "../../actions/AdminBankActions";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import AdminBankEditDialogWrapper from "../../wrappers/admin/AdminBankEditDialogWrapper";
import AdminBankCreateDialogWrapper from "../../wrappers/admin/AdminBankCreateDialogWrapper";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import BankList from "../../components/bank-core/BankList";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_SUPPLIER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "8px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { loadBankList },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map(query => new DataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        props
          .loadBankList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .startWith({})
      .map(response => fromJS(response))
      .map(response =>
        Map({
          pending: response.get("pending", false),
          total: response.getIn(["payload", "data", "total"], 0),
          list: response.getIn(["payload", "data", "list"], List()),
        }),
      );

    return propsStream
      .combineLatest(
        filterStream,
        listResponseStream,
        (props, filter, listResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: listResponse.get("list"),
          total: listResponse.get("total"),
          isLoading: listResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminBankListContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  i18n: PropTypes.instanceOf(Map),
};

function AdminBankListContainer(props) {
  const {
    i18n,
    sheet: { classes },
  } = props;
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      title={i18n.get("banks", "Banks")}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={i18n.get("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <AdminBankCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminBankEditDialogWrapper
        bankId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view"))}
      />

      <BankList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        location={props.location}
        cardActionIcons={
          <div>
            <IconButton
              tooltip={i18n.get("create_new_bank", "Create New Bank")}
              onClick={() => props.setLocationQuery(fp.set("create", true))}
            >
              <Add />
            </IconButton>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_SUPPLIER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {i18n.get("download_csv", "Download CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminBankListContainer);
