import { Observable } from "rxjs";
import React from "react";
import { isPast, isFuture } from "date-fns";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { red, grey, green } from "@material-ui/core/colors";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatDate,
  formatText,
  DATE_SHORT_FORMAT,
} from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  PROMO_ITEM_URL,
  PROMO_CREATE_URL,
} from "../../constants/AdminPathConstants";
import { getPromoList } from "../../api/admin/AdminPromoApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { primary1 } from "../../../shared/theme/main-theme";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const enhancer = compose(
  getContext({ setLocationQueryFilter: PropTypes.func.isRequired }),
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), DataListFilter.create))
      .distinctUntilChanged(isEqualData);

    const responseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .switchMap(filter => getPromoList(filter).catch(() => Observable.of({})))
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        responseStream,
        (props, filter, response) => ({
          ...props,
          filter,
          list: response.get("list"),
          total: response.get("total"),
          isLoading: response.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminPromoList.propTypes = {
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
  isAdminViewer: PropTypes.bool,
};

const isDateBetweenInterval = (fromDate, toDate, status) => {
  if (status !== "active") return 0;

  return isFuture(fromDate) ? 1 : isPast(toDate) ? 3 : 2;
};

const formatPromoValidationDate = (
  fromDate,
  toDate,
  status,
  getLocalisationMessage,
) => {
  let response = null;

  switch (isDateBetweenInterval(fromDate, toDate, status)) {
    case 1:
      response = (
        <div style={{ color: grey[500], fontWeight: "bold" }}>
          {getLocalisationMessage("not_started", "Not Started")}
        </div>
      );
      break;
    case 2:
      response = (
        <div style={{ color: green[900], fontWeight: "bold" }}>
          {getLocalisationMessage("valid", "Valid")}
        </div>
      );
      break;
    case 3:
      response = (
        <div style={{ color: red[900], fontWeight: "bold" }}>
          {getLocalisationMessage("expired", "Expired")}
        </div>
      );
      break;
    default:
      response = (
        <div>{getLocalisationMessage(status, formatText(status))}</div>
      );
  }

  return response;
};

const colorPromoValidationDate = (fromDate, toDate, status) => {
  switch (isDateBetweenInterval(fromDate, toDate, status)) {
    case 1:
      return grey[500];
    case 2:
      return green[900];
    case 3:
      return red[900];
    default:
      return primary1;
  }
};

function AdminPromoList(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      slug="promos"
      title={getLocalisationMessage("promos", "Promos")}
    >
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={9}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Tooltip
                title={props.getLocalisationMessage(
                  "create_new_promo",
                  "Create New Promo",
                )}
              >
                <Link to={PROMO_CREATE_URL}>
                  <IconButton>
                    <Add />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </div>
        }
      >
        <DataListColumn
          width={104}
          label={props.getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          cellRenderer={row => (
            <strong>
              {!props.isAdminViewer ? (
                <Link
                  to={PROMO_ITEM_URL + row.cellData.get("id")}
                  style={{
                    color: colorPromoValidationDate(
                      row.cellData.get("valid_from"),
                      row.cellData.get("valid_to"),
                      row.cellData.get("status"),
                    ),
                  }}
                >
                  {row.cellData.get("id")}
                </Link>
              ) : (
                row.cellData.get("id")
              )}
            </strong>
          )}
        />

        <DataListColumn
          width={104}
          label={props.getLocalisationMessage("name_1", "Name")}
          dataKey="name"
          disableSort={true}
          cellRenderer={row => row.cellData.get("private_name", "N/A")}
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={props.getLocalisationMessage("code", "Code")}
          dataKey="code"
          disableSort={true}
          cellRenderer={row => row.cellData.get("code", "N/A")}
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={props.getLocalisationMessage("valid_from", "Valid From")}
          dataKey="valid_from"
          disableSort={true}
          cellRenderer={row =>
            formatDate(row.cellData.get("valid_from"), DATE_SHORT_FORMAT)
          }
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={props.getLocalisationMessage("valid_to", "Valid To")}
          dataKey="valid_to"
          disableSort={true}
          cellRenderer={row =>
            formatDate(row.cellData.get("valid_to"), DATE_SHORT_FORMAT)
          }
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={props.getLocalisationMessage("count_of_used", "Count of Used")}
          dataKey="count_of_used"
          disableSort={true}
          cellRenderer={row => row.cellData.get("count_of_used") || 0}
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={props.getLocalisationMessage("privacy", "Privacy")}
          dataKey="is_public"
          disableSort={true}
          cellRenderer={row =>
            props.getLocalisationMessage(
              row.cellData.get("is_public", "N/A"),
              formatText(row.cellData.get("is_public", "N/A")),
            )
          }
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={props.getLocalisationMessage("status", "Status")}
          dataKey="to"
          disableSort={true}
          cellRenderer={row =>
            props.getLocalisationMessage(
              row.cellData.get("status"),
              formatText(row.cellData.get("status")),
            )
          }
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={props.getLocalisationMessage("valid_status", "Valid Status")}
          dataKey="valid_status"
          disableSort={true}
          cellRenderer={row => (
            <div>
              {formatPromoValidationDate(
                row.cellData.get("valid_from"),
                row.cellData.get("valid_to"),
                row.cellData.get("status"),
                getLocalisationMessage,
              )}
            </div>
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminPromoList);
