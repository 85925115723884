import { compose, mapPropsStream } from "recompose";
import { getMessages } from "../../reducers/LocalizationReducer";
import { connect } from "react-redux";
import Tabs from "../../components/ui-core/Tabs";
import PropTypes from "prop-types";
import { isEqualData } from "../../helpers/DataUtils";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  mapPropsStream(propsStream => {
    const tabsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props => [
        {
          value: "voice_sorting",
          path: updateQuery(
            props.location,
            props.filter.setValue("tab", "voice_sorting").getDefinedValues(),
          ),
          label: props.i18n.get("voice_sorting", "Сортировка звука"),
        },
        {
          value: "upload_voice",
          path: updateQuery(
            props.location,
            props.filter.setValue("tab", "upload_voice").getDefinedValues(),
          ),
          label: props.i18n.get("upload_voice", "Загрузка звука"),
        },
      ]);

    return propsStream.combineLatest(tabsStream, (props, tabs) => ({
      ...props,
      tabs,
      value: props.filter.getValue("tab"),
    }));
  }),
);

const AdminVoiceSortingTabsWrapper = enhancer(Tabs);

AdminVoiceSortingTabsWrapper.propTypes = {
  tabs: PropTypes.object,
  filter: PropTypes.object,
  location: PropTypes.object,
  value: PropTypes.any,
};

export default AdminVoiceSortingTabsWrapper;
