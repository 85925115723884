import React, { useEffect, useState } from "react";
import { compose, getContext, mapPropsStream } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Map, OrderedSet } from "immutable";
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import {
  BANDEROL,
  LETTER,
  SMALL_PACKETS,
} from "../../helpers/OrderOutboundSortingHelper";
import FormSelectField from "../form/FormSelectField";
import { formatText } from "../../helpers/FormatUtils";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import FormTextField from "../form/FormTextField";
import IconButton from "@material-ui/core/IconButton";
import { Add, ArrowBackIos } from "@material-ui/icons";
import { ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import {
  getValue,
  isShallowEqual,
  kirilToLatinByKeyboardPlace,
} from "../../helpers/DataUtils";
import fp from "lodash/fp";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import {
  createNotEmptyValidator,
  createObjectIdValidator,
} from "../../helpers/FormUtils";
import { createSingleSimpleOrder } from "../../api/admin/AdminSimpleOrderApi";
import { validateString } from "../../helpers/ValidateUtils";
// import { BARCODE_VALIDATION_URL } from "./AdminPostForm";
// import { api } from "../../api/shared/BaseApi";
// import _ from "lodash";

const serviceType = OrderedSet.of(LETTER, BANDEROL, SMALL_PACKETS);

const styles = makeStyles({
  root: {
    padding: "10px 24px",
    gap: 16,
    "@media (max-width: 1440px)": {
      "& .MuiCardContent-root:last-child": {
        paddingBottom: "none",
      },
      "& .MuiCardContent-root": {
        padding: "10px 16px",
      },
    },
  },
  orderType: {
    minHeight: 80,
    "@media (max-width: 1440px)": {
      minHeight: 60,
    },
  },
  card: {
    minHeight: 140,
    "@media (max-width: 1440px)": {
      minHeight: 110,
    },
  },
  title: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    textTransform: "capitalize",
  },
  cardTitle: {
    borderBottom: "1px solid #EEEEEE",
  },
  saveButton: {
    "& .MuiButton-containedSecondary": {
      backgroundColor: "#27AE60",
    },
  },
});

const OrderRegisteredItems = props => {
  const { i18n } = props;

  // const [barcodeErrorMessage, setBarcodeErrorMessage] = useState([]);
  // const [barcode, setBarcode] = useState(null);
  // const [findIndex, setFindIndex] = useState(null);
  // const [findBarcode, setFindBarcode] = useState(null);
  const [jurisdiction, setJurisdiction] = useState([]);
  const [senderJurisdiction, setSenderJurisdiction] = useState([]);
  useEffect(() => {
    if (props.fields.getAll() && props.fields.getAll().length > 0) {
      const tempFields = [];
      const tempSenderFields = [];
      // eslint-disable-next-line array-callback-return
      props.fields.getAll().map((field, index) => {
        tempFields[index] = field.recipient_data.jurisdiction;
        tempSenderFields[index] = field.sender_data.jurisdiction;
      });
      setJurisdiction([...tempFields]);
      setSenderJurisdiction([...tempSenderFields]);
    }
  }, [props.fields]);

  // useEffect(() => {
  //   if (barcode) {
  //     const delayDebounceFn = setTimeout(() => {
  //       const convertedValue = kirilToLatinByKeyboardPlace(barcode);
  //       const tempBarcode =
  //         convertedValue !== barcode ? convertedValue : barcode;
  //
  //       if (props.barcodes.includes(tempBarcode)) {
  //         const tempBarcodeErrorMessage = [...barcodeErrorMessage];
  //         tempBarcodeErrorMessage[findIndex] = i18n.get(
  //           "you_used_this_barcode",
  //           "You used this barcode",
  //         );
  //
  //         setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
  //       } else {
  //         api
  //           .get(BARCODE_VALIDATION_URL, {
  //             params: { barcode: tempBarcode },
  //           })
  //           .then(res => {
  //             if (res.status !== SUCCESS_MESSAGE && res.code === "DUPLICATE") {
  //               const tempBarcodeErrorMessage = [...barcodeErrorMessage];
  //               tempBarcodeErrorMessage[findIndex] = res.message;
  //
  //               setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
  //             } else {
  //               const tempBarcodeErrorMessage = [...barcodeErrorMessage];
  //               tempBarcodeErrorMessage[findIndex] = null;
  //
  //               setBarcodeErrorMessage([...tempBarcodeErrorMessage]);
  //             }
  //           })
  //           .catch(error => {
  //             props.showErrorMessage(error);
  //           })
  //           .finally(
  //             () =>
  //               convertedValue !== barcode &&
  //               props.change(findBarcode, convertedValue),
  //           );
  //       }
  //     }, 100);
  //     return () => clearTimeout(delayDebounceFn);
  //   }
  //   return null;
  // }, [barcode, findIndex]);

  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column" style={{ gap: 16 }}>
        {props.fields.map((field, fieldIndex) => (
          <FlexBox
            style={{
              gap: 16,
              padding: 16,
              border: "1px dashed rgba(0, 0, 0, 0.1)",
              borderRadius: 10,
            }}
            key={fieldIndex}
          >
            <FlexBox align={ALIGN_CENTER}>
              <FlexBox
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#27AE60",
                  borderRadius: 20,
                }}
                align={ALIGN_CENTER}
                justify={ALIGN_CENTER}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#FFFFFF",
                  }}
                >
                  {fieldIndex + 1}
                </span>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                disabled={true}
                fullWidth={true}
                name={`${field}.sender_data.customer.name`}
                label={`${i18n.get("sender_name", "Sender Name")} `}
                validate={createNotEmptyValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                disabled={true}
                immediatelyShowError={true}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                hintText={i18n.get("type_to_search_hint")}
                fullWidth={true}
                name={`${field}.sender_data.jurisdiction`}
                label={`${i18n.get(
                  "sender_jurisdiction",
                  "Sender Jurisdiction",
                )} `}
                validate={createObjectIdValidator(
                  i18n.get("this_field_is_required"),
                )}
                onChange={value => {
                  const tempJur = [...senderJurisdiction];
                  tempJur[fieldIndex] = { ...value };
                  setSenderJurisdiction(tempJur);
                }}
              />
            </FlexBox>
            <FlexBox style={{ width: 100 }}>
              <PostcodeAutoCompleteWithJM
                disabled={true}
                name={`${field}.sender_data.postcode`}
                fullWidth={true}
                jurisdictionId={
                  senderJurisdiction[fieldIndex]
                    ? senderJurisdiction[fieldIndex].id
                    : null
                }
                jurisdiction={senderJurisdiction[fieldIndex]}
                label={i18n.get("index", "Index")}
                hintText={i18n.get("type_to_search", "Type To Search...")}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                disabled={true}
                fullWidth={true}
                name={`${field}.sender_data.address`}
                label={`${i18n.get("sender_address", "Sender Address")} `}
                validate={createNotEmptyValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormTextField
                disabled={true}
                fullWidth={true}
                name={`${field}.recipient_data.customer.name`}
                label={`${i18n.get("recipient_name", "Recipient Name")} `}
                validate={createNotEmptyValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormJMAutoComplete
                disabled={true}
                immediatelyShowError={true}
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                hintText={i18n.get("type_to_search_hint")}
                fullWidth={true}
                name={`${field}.recipient_data.jurisdiction`}
                label={`${i18n.get(
                  "recipient_jurisdiction",
                  "Recipient Jurisdiction",
                )} `}
                onChange={value => {
                  const tempJur = [...jurisdiction];
                  tempJur[fieldIndex] = { ...value };
                  setJurisdiction(tempJur);
                }}
                validate={createObjectIdValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>

            <FlexBox style={{ width: 100 }}>
              <PostcodeAutoCompleteWithJM
                disabled={true}
                name={`${field}.recipient_data.postcode`}
                fullWidth={true}
                jurisdictionId={
                  jurisdiction[fieldIndex] ? jurisdiction[fieldIndex].id : null
                }
                jurisdiction={jurisdiction[fieldIndex]}
                label={i18n.get("index", "Index")}
                hintText={i18n.get("type_to_search", "Type To Search...")}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                disabled={true}
                fullWidth={true}
                name={`${field}.recipient_data.address`}
                label={`${i18n.get("recipient_address", "Recipient address")} `}
                validate={createNotEmptyValidator(
                  i18n.get("this_field_is_required"),
                )}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormTextField
                name={`${field}.barcode`}
                label={`${i18n.get("barcode", "Barcode")} `}
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                        <path
                          fill="currentColor"
                          d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                disabled={true}
                // onChange={(e, value) => {
                //   setBarcode(value);
                //   setFindIndex(fieldIndex);
                //   setFindBarcode(`${field}.barcode`);
                // }}
                // validate={createRegisteredBarcodeValidator(
                //   barcodeErrorMessage[fieldIndex],
                //   i18n.get("this_field_is_required"),
                // )}
              />
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

OrderRegisteredItems.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  fields: PropTypes.array,
  // barcodes: PropTypes.array,
  // showErrorMessage: PropTypes.func,
  // change: PropTypes.func,
};

const getValues = getFormValues("AdminSingleSimplePostForm");

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const handleSubmit = props => (values, change) => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values, change);
    };
    const initialValuesStream = propsStream
      .map(() => ({
        unRegisteredOrders: [],
        senderJurisdiction: {},
        unRecipientJurisdiction: {},
      }))
      .startWith({});

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdminSingleSimplePostForm",
    validate: (values, props) => {
      const barcodeArray = values.barcode ? values.barcode.split("") : [];
      const regEx = /^\d+$/;

      return {
        serviceType:
          !values.serviceType && props.i18n.get("this_field_is_required"),

        barcode:
          barcodeArray && barcodeArray.length > 0 && barcodeArray[0] !== "P"
            ? props.i18n.get("barcode_should_9_digit")
            : values.barcode &&
              (values.barcode.length !== 10 ||
                !regEx.test(values.barcode.slice(1))) &&
              props.i18n.get("barcode_should_9_digit"),

        senderJurisdiction:
          !fp.get(["senderJurisdiction", "id"], values) &&
          props.i18n.get("this_field_is_required"),

        senderName: validateString(
          values.senderName,
          props.i18n.get("this_field_is_required"),
        ),

        unRecipientName: validateString(
          values.unRecipientName,
          props.i18n.get("this_field_is_required"),
        ),

        recipientAddress: validateString(
          values.recipientAddress,
          props.i18n.get("this_field_is_required"),
        ),

        unRecipientJurisdiction:
          !fp.get(["unRecipientJurisdiction", "id"], values) &&
          props.i18n.get("this_field_is_required"),
      };
    },
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
        unRegisteredOrders: values.unRegisteredOrders || [],
      },
    })),
  ),
);

function AdminSingleSimplePostForm(props) {
  const { i18n, values } = props;

  const classes = styles();

  const {
    senderJurisdiction,
    unRecipientJurisdiction,
    orderType,
    barcode,
  } = values;
  const senderJurisdictionId = getValue(senderJurisdiction, "id");
  const unRecipientJurisdictionId = getValue(unRecipientJurisdiction, "id");
  const [confirm, setConfirm] = useState(false);
  const [finish, setFinish] = useState(false);
  const [
    showErrorUnRecipientJurisdiction,
    setErrorUnRecipientJurisdiction,
  ] = useState(false);
  const [
    showErrorUnSenderJurisdiction,
    setErrorUnSenderJurisdiction,
  ] = useState(false);
  const [showErrorUnRecipientName, setErrorUnRecipientName] = useState(false);
  const [showErrorUnSenderName, setErrorUnSenderName] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (barcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(barcode);
        props.change("barcode", convertedValue);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }

    return null;
  }, [barcode]);

  return (
    <form
      onSubmit={props.handleSubmit}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {finish && (
        <ConfirmDialog
          confirmButtonLabel={i18n.get("yes", "Yes")}
          dismissButtonLabel={i18n.get("no", "No")}
          open={finish}
          onConfirm={() => props.setLocation(ORDER_LIST_URL)}
          onRequestClose={() => setFinish(false)}
        >
          {i18n.get(
            "are_you_sure_you_want_to_finish",
            "Are you sure you want to finish?",
          )}
        </ConfirmDialog>
      )}

      {confirm && (
        <ConfirmDialog
          confirmButtonLabel={i18n.get("yes", "Yes")}
          dismissButtonLabel={i18n.get("no", "No")}
          open={confirm}
          onConfirm={() => props.setLocation(ORDER_LIST_URL)}
          onRequestClose={() => setConfirm(false)}
        >
          {i18n.get(
            "are_you_sure_you_want_to_exit",
            "Are you sure you want to exit?",
          )}
        </ConfirmDialog>
      )}

      <FlexBox
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <FlexBox
          className={classes.root}
          direction="column"
          justify="space-between"
        >
          <Typography variant="h5" className={classes.title}>
            <IconButton
              style={{ width: 40, height: 40 }}
              onClick={() => setConfirm(true)}
            >
              <ArrowBackIos style={{ width: 20, height: 20 }} />
            </IconButton>

            {i18n.get("order_creation", "Order Creation")}
          </Typography>

          <Card className={classes.orderType}>
            <CardContent>
              <FlexBox style={{ gap: 16, padding: 5 }} align="center">
                {!orderType && (
                  <FlexBox flex={true}>
                    <FormSelectField
                      style={{ width: 222 }}
                      name="serviceType"
                      options={serviceType}
                      formatOption={option =>
                        i18n.get(option.toLowerCase(), formatText(option))
                      }
                      label={`${i18n.get("shipment_type")} *`}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </CardContent>
          </Card>

          <Card style={{ overflow: "auto" }}>
            <CardContent className={classes.cardTitle}>
              <Typography variant="body1">
                {i18n.get("order_information", "Order Information")}
              </Typography>
            </CardContent>
            <CardContent>
              <FlexBox style={{ gap: 16 }} direction="column">
                <FlexBox style={{ gap: 16 }}>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      ignoreError={showErrorUnSenderName}
                      name="senderName"
                      label={`${i18n.get("sender_name", "Sender name")} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormJMAutoComplete
                      immediatelyShowError={showErrorUnSenderJurisdiction}
                      levelIds={[3]}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{option.name}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {option.value.hierarchy.map((item, index, arr) =>
                              index === arr.length - 1
                                ? item.name
                                : `${item.name} > `,
                            )}
                          </span>
                        </FlexBox>
                      )}
                      fullWidth={true}
                      name="senderJurisdiction"
                      label={`${i18n.get(
                        "sender_jurisdiction",
                        "Sender Jurisdiction",
                      )} *`}
                      hintText={i18n.get("type_to_search_hint")}
                    />
                  </FlexBox>
                  <FlexBox style={{ width: 100 }}>
                    <PostcodeAutoCompleteWithJM
                      name="senderPostcode"
                      fullWidth={true}
                      jurisdictionId={senderJurisdictionId}
                      jurisdiction={senderJurisdiction}
                      label={i18n.get("index", "Index")}
                      hintText={i18n.get("type_to_search", "Type To Search...")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      name="senderAddress"
                      label={i18n.get("sender_address", "Sender Address")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      ignoreError={showErrorUnRecipientName}
                      name="unRecipientName"
                      label={`${i18n.get(
                        "recipient_name",
                        "Recipient name",
                      )} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormJMAutoComplete
                      immediatelyShowError={showErrorUnRecipientJurisdiction}
                      levelIds={[3]}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{option.name}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {option.value.hierarchy.map((item, index, arr) =>
                              index === arr.length - 1
                                ? item.name
                                : `${item.name} > `,
                            )}
                          </span>
                        </FlexBox>
                      )}
                      fullWidth={true}
                      name="unRecipientJurisdiction"
                      label={`${i18n.get(
                        "recipient_jurisdiction",
                        "Recipient Jurisdiction",
                      )} *`}
                      hintText={i18n.get("type_to_search_hint")}
                    />
                  </FlexBox>
                  <FlexBox style={{ width: 100 }}>
                    <PostcodeAutoCompleteWithJM
                      name="unRecipientPostIndex"
                      fullWidth={true}
                      jurisdictionId={unRecipientJurisdictionId}
                      jurisdiction={unRecipientJurisdiction}
                      label={i18n.get("index", "Index")}
                      hintText={i18n.get("type_to_search", "Type To Search...")}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      ignoreError={showErrorUnRecipientName}
                      fullWidth={true}
                      name="recipientAddress"
                      label={`${i18n.get(
                        "recipient_address",
                        "Recipient Address",
                      )} *`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="barcode"
                      label={i18n.get("barcode", "Barcode")}
                      fullWidth={true}
                      onInput={e => {
                        // eslint-disable-next-line radix
                        e.target.value = e.target.value.toString().slice(0, 10);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                              <path
                                fill="currentColor"
                                d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                              />
                            </svg>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox flex={true} justify="flex-end">
                  <FlexBox
                    flex={true}
                    justify="flex-end"
                    style={{ gap: 16, width: 280 }}
                  >
                    <CustomButton
                      variant={OUTLINED}
                      color={SECONDARY}
                      style={{ minWidth: 130, maxWidth: 130, height: "40px" }}
                      onClick={() => {
                        props.change("senderJurisdiction", {});
                        props.change("senderName", null);
                        props.change("senderAddress", null);
                        props.change("recipientAddress", null);
                        props.change("senderPostcode", {});
                        props.change("serviceType", null);
                        props.change("unRecipientName", null);
                        props.change("unRecipientJurisdiction", {});
                        props.change("unRecipientPostIndex", {});
                        props.change("barcode", "");
                      }}
                    >
                      {i18n.get("clear", "Clear")}
                    </CustomButton>
                    <CustomButton
                      type={props.valid ? "button" : "submit"}
                      variant={CONTAINED}
                      color={SECONDARY}
                      startIcon={
                        isLoading ? (
                          <CircularProgress size={20} color="secondary" />
                        ) : (
                          <Add />
                        )
                      }
                      style={{ minWidth: 130, maxWidth: 130, height: "40px" }}
                      onClick={() => {
                        if (props.valid) {
                          setIsLoading(true);
                          createSingleSimpleOrder({
                            service_type: {
                              code: values.serviceType,
                            },
                            sender_data: {
                              jurisdiction: values.senderJurisdiction,
                              customer: {
                                name: values.senderName,
                              },
                              postcode: values.senderPostcode,
                              address: values.senderAddress,
                            },
                            recipient_data: {
                              customer: {
                                name: values.unRecipientName,
                              },
                              jurisdiction: values.unRecipientJurisdiction,
                              postcode: values.unRecipientPostIndex,
                              address: values.recipientAddress,
                            },
                            barcode: values.barcode,
                            dimensions: {
                              width: 0,
                              length: 0,
                              height: 0,
                              weight: 0.02,
                            },
                          })
                            .then(res => {
                              if (res) {
                                props.change("unRegisteredOrders", [
                                  ...values.unRegisteredOrders,
                                  res.data,
                                ]);
                                props.showSuccessMessage(
                                  props.i18n.get("created_1", "Created"),
                                );
                                setErrorUnSenderJurisdiction(false);
                                setErrorUnSenderName(false);
                                setErrorUnRecipientName(false);
                                setErrorUnRecipientJurisdiction(false);
                                props.change("recipientAddress", null);
                                props.change("unRecipientJurisdiction", {});
                                props.change("unRecipientPostIndex", {});
                                props.change("barcode", null);
                                props.change("unRecipientName", null);
                                setIsLoading(false);
                              }
                            })
                            .catch(error => {
                              props.showErrorMessage(error);
                              setIsLoading(false);
                            });
                        } else {
                          setErrorUnSenderJurisdiction(true);
                          setErrorUnRecipientJurisdiction(true);
                          setErrorUnSenderName(true);
                          setErrorUnRecipientName(true);
                        }
                      }}
                      disabled={isLoading}
                    >
                      {i18n.get("add", "Add")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>

                <FlexBox>
                  <FieldArray
                    name="unRegisteredOrders"
                    component={OrderRegisteredItems}
                    props={{
                      i18n,
                      // change: props.change,
                      // showErrorMessage: props.showErrorMessage,
                      // barcodes:
                      //   props.values.unRegisteredOrders &&
                      //   props.values.unRegisteredOrders.length > 0
                      //     ? props.values.unRegisteredOrders.map(
                      //         order => order.barcode,
                      //       )
                      //     : [],
                    }}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>

      <Card style={{ bottom: 0, zIndex: 1000 }}>
        <CardContent>
          <FlexBox justify="flex-end">
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              style={{ width: 246, height: 40 }}
              onClick={() => setFinish(true)}
              disabled={
                !(
                  values.unRegisteredOrders &&
                  values.unRegisteredOrders.length > 0
                )
              }
            >
              {i18n.get("finish", "Finish")}
            </CustomButton>
          </FlexBox>
        </CardContent>
      </Card>
    </form>
  );
}

AdminSingleSimplePostForm.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  orderType: PropTypes.bool,
  setLocation: PropTypes.func,
  change: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  valid: PropTypes.bool,
};

export default enhancer(AdminSingleSimplePostForm);
