import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  getMarketplaceReceiveOrderFromWing,
  isCustomMarketplace,
} from "../../reducers/MarketplaceReducer";
import AdminCODCodes from "../../constants/AdminCODCodes";
import OrderStatusCodes, {
  OTHER_REASON,
  OUT_FOR_RETURN,
} from "../../constants/OrderStatusCodes";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { getMessage } from "../../reducers/LocalizationReducer";
import RouteDialogForm from "../../components/orders-core/RouteDialogForm";
import { routeAssign } from "../../api/shared/RouteTemplateApi";
import { ROUTE } from "../../constants/OrderType";

const enhancer = compose(
  connect((state) => ({
    isMarketplaceCustom: isCustomMarketplace(state),
    isMarketplaceReceiveOrderFromWing:
      getMarketplaceReceiveOrderFromWing(state),
    defaultBatchUpdateStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

RouteDialogFormWrapper.propTypes = {
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  isMarketplaceReceiveOrderFromWing: PropTypes.bool,
  defaultBatchUpdateStatuses: PropTypes.object,
  isMarketplaceCustom: PropTypes.bool,
  list: PropTypes.any,
  selectedItems: PropTypes.any,
  isSorting: PropTypes.bool,
  warehouseIds: PropTypes.array,
};

function RouteDialogFormWrapper(props) {
  let statuses;
  if (
    props.defaultBatchUpdateStatuses &&
    props.defaultBatchUpdateStatuses.size > 0
  ) {
    statuses = props.defaultBatchUpdateStatuses;
  } else if (
    !props.isMarketplaceCustom ||
    !props.isMarketplaceReceiveOrderFromWing
  ) {
    statuses = OrderStatusCodes;
  } else {
    statuses = OrderStatusCodes.delete(OUT_FOR_RETURN);
  }

  return (
    <RouteDialogForm
      warehouseIds={props.warehouseIds}
      open={props.open}
      isEdit={props.isEdit}
      onRequestClose={props.onRequestClose}
      codCodes={AdminCODCodes}
      statusCodes={statuses.delete(OTHER_REASON)}
      withInTransitToSupplier={true}
      privacyTypes={AdminPrivacyTypes}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      getPostcodePredictions={getPostcodePredictions}
      initialValues={props.initialValues}
      selectedItems={props.selectedItems}
      list={props.list}
      isSorting={props.isSorting}
      onSubmit={(values) => {
        const request = {
          ...toSnakeCase(values),
          privacy: PUBLIC,
          type: ROUTE,
        };
        return props.isEdit
          ? routeAssign(toSnakeCase(values)).catch(ResponseError.throw)
          : batchAsyncUpdateOrder(
              fp.omit(["batch_ids", "is_barcode", "only_barcode"], request),
            ).catch(ResponseError.throw);
      }}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}

export default enhancer(RouteDialogFormWrapper);
