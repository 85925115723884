import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import TimeSlotFilterForm from "../../components/time-slots-core/TimeSlotFilterForm";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({
    paper: { minWidth: "900px", maxWidth: "900px" },
  }),
);

AdminTimeSlotAvailabilityFilterDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminTimeSlotAvailabilityFilterDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("timeslot_filter", "Timeslot Filter")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <TimeSlotFilterForm
        filter={props.filter}
        onDismiss={props.onRequestClose}
        onFilterChange={props.onFilterChange}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedPostcode={getCachedPostcode}
        getPostcodePredictions={getPostcodePredictions}
        getCachedServiceType={getCachedServiceType}
        getServiceTypePredictions={getServiceTypePredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminTimeSlotAvailabilityFilterDialog);
