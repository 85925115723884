import React from "react";
import { Collection, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Text, { DANGER, MUTED } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import DataList, { DataListColumn } from "../data-list/DataList";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import {
  formatDateDistanceToNow,
  formatWeight,
} from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import MultiLineCell from "../data-list/MultiLineCell";

const searchTextToSet = fp.flow(fp.trim, fp.split(","), fp.compact, Set);

OrderFieldCell.propTypes = {
  children: PropTypes.node,
  failed: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderFieldCell(props) {
  return props.fetched ? (
    <Text fallbackType={MUTED} fallbackValue="-">
      {props.children}
    </Text>
  ) : props.failed ? (
    <Text type={DANGER}>
      {props.getLocalisationMessage("failed", "Failed")}
    </Text>
  ) : (
    <Text type={MUTED}>
      {props.getLocalisationMessage("loading", "Loading...")}
    </Text>
  );
}

const getOrderJurisdictionHierarchy = fp.flow(
  fp.defaultTo(Set),
  toJS,
  fp.map((item) => fp.get("name", item)),
  fp.reverse,
  fp.join(", "),
);

const enhancer = compose(
  connect((state) => {
    const statusMessages = getStatusLocalisation(state);

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      statusMessages,
    };
  }),
  useSheet({
    root: {
      flex: "1 1 100%",
      display: "flex",
      height: `${64 + 52 + 56 * 10}px`,
    },
    btn: {
      flex: "1 1 auto",
      margin: "0 .5rem",
    },
  }),
  withState("state", "setState", {
    showSelected: false,
    filter: new DataListFilter(),
  }),
  mapPropsStream((propsStream) => {
    const orderListStream = propsStream
      .map((props) => ({
        orders: props.orders.toList(),
        filter: props.state.filter,
        showSelected: props.state.showSelected,
        selectedOrders: props.selectedOrders,
      }))
      .distinctUntilChanged(isEqualData)
      .map((props) => {
        const { orders, selectedOrders, filter, showSelected } = props;
        const hasSelected = selectedOrders.size > 0;

        const orderBy = fp.toPath(filter.getOrderBy());
        const orderByAsc = filter.isOrderByAsc();
        const searchBy = searchTextToSet(filter.getSearch());

        const shouldFilterSelected = hasSelected && showSelected;
        const shouldFilterBySearch = searchBy.size > 0;

        const orderList =
          !shouldFilterSelected && !shouldFilterBySearch
            ? orders
            : orders.filter((x) => {
                if (shouldFilterBySearch) {
                  return searchBy.has(x.get("number"));
                }

                if (shouldFilterSelected) {
                  return selectedOrders.has(x.get("number"));
                }

                return true;
              });

        const shouldSortByField = orderBy.length > 0;
        const shouldSortBySelection = hasSelected && !showSelected;

        return orderList.sort((a, b) => {
          if (shouldSortBySelection) {
            const aSelected = selectedOrders.has(a.get("number"));
            const bSelected = selectedOrders.has(b.get("number"));

            if (aSelected !== bSelected) {
              return aSelected ? -1 : 1;
            }
          }

          if (shouldSortByField) {
            const aValue = fp.trim(a.getIn(orderBy));
            const bValue = fp.trim(b.getIn(orderBy));

            return aValue.localeCompare(bValue) * (orderByAsc ? 1 : -1);
          }

          const aFailed = Boolean(a.get("failed"));
          const bFailed = Boolean(b.get("failed"));

          const aFound = a.has("hash");
          const bFound = b.has("hash");

          const aLoading = !aFound && !aFailed;
          const bLoading = !bFound && !bFailed;

          if (aLoading !== bLoading) {
            return aLoading ? -1 : 1;
          }

          if (aFailed !== bFailed) {
            return aFailed ? -1 : 1;
          }

          if (aFound && bFound) {
            return b.get("hash_time") - a.get("hash_time");
          }

          return 0;
        });
      })
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(orderListStream, (props, orderList) => ({
      ...props,
      orderList,
    }));
  }),
  pureComponent(fp.pick(["state", "orderList", "selectedOrders"])),
);

OrderCustomsSortingTable.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,
  orderList: PropTypes.instanceOf(List),

  headerActions: PropTypes.node,
  cardActionIcons: PropTypes.node,
  showBatchUpdate: PropTypes.bool,
  showAllButton: PropTypes.bool,

  onOrdersSelect: PropTypes.func.isRequired,

  onOrderClick: PropTypes.func.isRequired,
  onCancelRegistry: PropTypes.func,
  onReloadClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,

  orders: PropTypes.instanceOf(Collection).isRequired,
  getLocalisationMessage: PropTypes.func,
  statusMessages: PropTypes.instanceOf(Map),
};

OrderCustomsSortingTable.defaultProps = {
  showAllButton: true,
  showBatchUpdate: false,
};

function OrderCustomsSortingTable(props) {
  const { state, classes, orderList, getLocalisationMessage } = props;

  return (
    <div className={classes.root}>
      <DataList
        isLoading={false}
        withFooter={false}
        withHeader={true}
        overscanRowCount={10}
        maxSearchItems={Infinity}
        filter={state.filter}
        onFilterChange={(x) => props.setState(fp.set("filter", x))}
        rowCount={orderList.size}
        totalCount={orderList.size}
        rowGetter={(row) => orderList.get(row.index)}
        cardActionIcons={props.cardActionIcons}
        altHeader={<FlexBox align="center">{props.headerActions}</FlexBox>}
      >
        <DataListColumn
          width={100}
          dataKey="info.barcode"
          disableSort={true}
          label={getLocalisationMessage("barcode", "Barcode")}
          cellRenderer={(row) => (
            <div>
              {row.cellData.get("failed") ? (
                row.cellData.get("number")
              ) : (
                <LinkButton
                  onClick={() =>
                    props.onOrderClick(row.cellData.getIn(["info", "id"]))
                  }
                >
                  {row.cellData.getIn(["info", "barcode"])}
                </LinkButton>
              )}
            </div>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("type")}
          dataKey="info.package"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {getLocalisationMessage(
                row.cellData.getIn(["info", "package", "courier_type_name"]),
              )}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("weight")}
          dataKey="info.weight"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {formatWeight(row.cellData.getIn(["info", "weight"]))}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("warehouse")}
          dataKey="info.warehouse.name"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              {row.cellData.getIn(["info", "warehouse", "name"])}
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage(
            "receipient_address",
            "Recipient Address",
          )}
          dataKey="info.package.to_jurisdiction"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={`${row.cellData.getIn(
                  ["info", "to_jurisdiction", "name"],
                  "",
                )} ${row.cellData.getIn(["info", "to_city"], "")}`}
                secondLine={`${getOrderJurisdictionHierarchy(
                  row.cellData.getIn(
                    ["info", "to_jurisdiction", "hierarchy"],
                    Set(),
                  ),
                )}`}
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={100}
          label={getLocalisationMessage("sender_address", "Sender Address")}
          dataKey="info.package.from_jurisdiction"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={`${row.cellData.getIn(
                  ["info", "from_jurisdiction", "name"],
                  "",
                )} ${row.cellData.getIn(["info", "from_city"], "")}`}
                secondLine={`${getOrderJurisdictionHierarchy(
                  row.cellData.getIn(
                    ["info", "from_jurisdiction", "hierarchy"],
                    Set(),
                  ),
                )}`}
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={80}
          label={getLocalisationMessage("status")}
          dataKey="info.status"
          justifyContent="center"
          cellRenderer={(row) => (
            <OrderFieldCell
              getLocalisationMessage={getLocalisationMessage}
              failed={Boolean(row.cellData.get("failed"))}
              fetched={Boolean(row.cellData.get("info"))}
            >
              <MultiLineCell
                firstLine={
                  <span
                    style={{
                      color: "#FF9800",
                    }}
                  >
                    {formatOrderStatusCodeForLocalisation(
                      row.cellData.getIn(["info", "status"]),
                      getLocalisationMessage,
                    )}
                  </span>
                }
                secondLine={formatDateDistanceToNow(
                  row.cellData.getIn(["info", "last_status_date"]),
                  "",
                  getLocalisationMessage,
                )}
              />
            </OrderFieldCell>
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("action")}
          justifyContent="center"
          dataKey="action"
          cellRenderer={(row) =>
            row.cellData.get("info") ? (
              <div>
                <LinkButton
                  onClick={() =>
                    props.onRemoveClick(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("remove")}
                </LinkButton>
              </div>
            ) : row.cellData.get("failed") ? (
              <div>
                <LinkButton
                  onClick={() =>
                    props.onReloadClick(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("reload")}
                </LinkButton>{" "}
                /{" "}
                <LinkButton
                  onClick={() =>
                    props.onRemoveClick(row.cellData.get("number"))
                  }
                >
                  {getLocalisationMessage("remove")}
                </LinkButton>
              </div>
            ) : (
              <Text type={MUTED}>{getLocalisationMessage("Loading")}</Text>
            )
          }
        />
      </DataList>
    </div>
  );
}

export default enhancer(OrderCustomsSortingTable);
