import React from "react";
import { Set } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormAutoComplete from "../form/FormAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import {
  isEqualData,
  isEqualWithoutFunctions,
  toJS,
} from "../../helpers/DataUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { KeyboardArrowLeft, Save } from "@material-ui/icons";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { TransitionUp } from "../dialog/TransitionUp";
import { IN_TRANSIT } from "../../constants/OrderStatusCodes";
import FormChipInput from "../form/FormChipInput";
import { renderIf } from "../../helpers/HOCUtils";

export const INSURED_BAG = "insured_bag";
export const LETTER_BAG = "letter_bag";
export const PERIODIC_NEWS_BAG = "periodic_news_bag";
export const INTERNATIONAL_SHIPMENT_BAG = "international_shipment_bag";
export const INSURED_PACKAGES = "insured_packages";
export const FRAGILE_PARCELS = "fragile_parcels";
export const POST_PACKET = "post_packet";
export const PARCEL = "parcel";

export const categoriesConstants = Set.of(
  INSURED_BAG,
  LETTER_BAG,
  POST_PACKET,
  PARCEL,
);

const formatOrderStatus = (status, getLocalisationMessage) => {
  switch (status) {
    // case ACCEPTED:
    //   return "Assign Driver For Pickup";

    default:
      return formatOrderStatusCodeForLocalisation(
        status,
        getLocalisationMessage,
      );
  }
};

const enhancer = compose(
  renderIf("open"),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .map((props) => ({
        orderBarcodes: props.selectedItems,
        orderStatus: IN_TRANSIT,
      }))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
  withHandlers({
    onSubmit: (props) => (values) => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "HybridCreateConsolidatedForm",
    validate: (values, props) => ({
      orderBarcodes:
        fp.isEmpty(values.orderBarcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_orders")) ||
          "Add Orders"),
      orderStatus:
        !toJS(props.statusCodes).some((v) => v === values.orderStatus) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_status")) ||
          "Select Status"),
      category:
        fp.isEmpty(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
    }),
  }),
);
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.appBarTextColor,
    backgroundColor: theme.palette.primary1Color,
  },
  chip: { margin: "4px" },
  moreInfo: { marginTop: "5px", marginLeft: "10px" },
  form: {
    paddingTop: 15,
    "&> div": {
      marginBottom: 15,
    },
  },
  dialogHeaderContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  },
  dialogHeaderTitle: {
    flexGrow: 1,
  },
  dialogHeaderToggleContainer: {
    padding: "10px 5px 10px 20px",
    borderRadius: 1000,
    backgroundColor: "#DBE1E6",
  },
}));

function HybridCreateConsolidatedForm({
  getLocalisationMessage,
  open,
  statusCodes,
  onRequestClose,
  handleSubmit,
  isLoadingSubmit,
}) {
  const classes = useStyles();
  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      maxWidth="lg"
      fullWidth={true}
      // onClose={onRequestClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogHeaderContainer}>
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage("create_bag")}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <FlexBox flex={true} direction="column">
            <FormChipInput
              disabled={true}
              name="orderBarcodes"
              focus={true}
              fullWidth={true}
              addOnBlur={true}
              label={getLocalisationMessage("orders_numbers", "Orders Numbers")}
            />
            <FormAutoComplete
              disabled={true}
              name="orderStatus"
              fullWidth={true}
              label={getLocalisationMessage("status", "Status")}
              options={statusCodes}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
              // formatOption={formatOrderStatus}
              formatOption={(x) => formatOrderStatus(x, getLocalisationMessage)}
              margin="normal"
            />

            <FormWarehouseAutoComplete
              disableP7={false}
              name="to_warehouse"
              label={getLocalisationMessage("destination_warehouse")}
              fullWidth={true}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
              margin="normal"
            />
            <FormTextField
              type="number"
              name="weight"
              fullWidth={true}
              label={getLocalisationMessage("weight_kg", "Weight (kg)")}
              margin="normal"
            />
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <FlexBox
          style={{ marginBottom: "1rem" }}
          gutter={16}
          flex={true}
          justify={JUSTIFY_CENTER}
        >
          <CustomButton
            style={{ marginRight: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            startIcon={<KeyboardArrowLeft />}
            primary={true}
            onClick={onRequestClose}
          >
            {getLocalisationMessage("cancel", "Cancel")}
          </CustomButton>
          <CustomButton
            disabled={isLoadingSubmit}
            style={{ marginLeft: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
            endIcon={
              isLoadingSubmit ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <Save />
              )
            }
            primary={true}
            onClick={() => {
              handleSubmit();
            }}
          >
            {getLocalisationMessage("create")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

HybridCreateConsolidatedForm.propTypes = {
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  statusCodes: PropTypes.instanceOf(Set).isRequired,
  getLocalisationMessage: PropTypes.func,
  isLoadingSubmit: PropTypes.bool,
};

export default enhancer(HybridCreateConsolidatedForm);
