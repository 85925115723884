import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../../helpers/HOCUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  getCachedCountry,
  getCountryPredictions,
} from "../../../api/shared/CountryV2Api";
import ModalPaper from "../../../components/ui-core/ModalPaper";
import MarketplaceFilterForm from "../../../components/marketplaces-core/MarketplaceFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "400px",
      minHeight: "auto",
    },
  }),
);

AdminMarketplaceFilterWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
};

function AdminMarketplaceFilterWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={props.open}
      title={getLocalisationMessage("marketplace_filter", "Marketplace Filter")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <MarketplaceFilterForm
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        onDismiss={props.onRequestClose}
        getCachedCountry={getCachedCountry}
        getCountryPredictions={getCountryPredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminMarketplaceFilterWrapper);
