import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, CardActions, Button } from "@material-ui/core";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { VOID, REJECTION } from "../../constants/DriverAttendanceTypes";

const enhancer = compose(
  reduxForm({
    form: "DriverRejectionReasonForm",
  }),
  formValues("note"),
);

DriverRejectionReasonForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  note: PropTypes.string,
  worked: PropTypes.string,
  submitting: PropTypes.bool,
};

function DriverRejectionReasonForm(props) {
  return (
    <FlexBox container={8} element={<form onSubmit={props.handleSubmit} />}>
      <FlexBox gutter={8} flex={true} direction="column">
        <PageLoading isLoading={props.submitting} />

        <FlexBox element={<Card />} direction="column">
          <FlexBox
            gutter={8}
            flex={true}
            direction="column"
            element={<CardContent />}
          >
            <FlexBox>
              <FormTextField
                name="note"
                rows={7}
                multiLine={true}
                rowsMax={7}
                fullWidth={true}
                label="Note"
              />
            </FlexBox>
            <FlexBox justify="flex-end">
              <Button disabled={Boolean(!props.note)} type="submit">
                Save Note
              </Button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox element={<CardActions />}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} justify="flex-start">
              <Button variant="outlined" onClick={props.onDismiss}>
                Cancel
              </Button>
            </FlexBox>

            {props.worked === "NO" && (
              <FlexBox>
                <Button
                  variant="outlined"
                  onClick={props.handleSubmit(values =>
                    props.onSubmit({
                      ...values,
                      type: VOID,
                    }),
                  )}
                  primary={true}
                >
                  Void Rejection
                </Button>
              </FlexBox>
            )}

            <FlexBox>
              <Button
                variant="outlined"
                onClick={props.handleSubmit(values =>
                  props.onSubmit({
                    ...values,
                    type: REJECTION,
                  }),
                )}
                primary={true}
              >
                Reject Attendance
              </Button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverRejectionReasonForm);
