import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ADMIN_BANK_ITEM_URL = `${API_ROOT_URL}/admin/bank/%s`;
const ADMIN_BANK_SELECT_ITEM_URL = `${API_ROOT_URL}/admin/bank/%s/select_item`;
const ADMIN_BANK_URL = `${API_ROOT_URL}/admin/bank`;
const ADMIN_BANK_LIST_URL = `${API_ROOT_URL}/admin/banks`;
const ADMIN_BANK_CHOOSE_LIST_URL = `${API_ROOT_URL}/admin/bank/choose`;
const ADMIN_BANK_RECONCILE_UPLOAD_FILE = `${API_ROOT_URL}/admin/reconcile/upload_file`;

const ADMIN_BANK_RECONCILE_SETTINGS_UPLOAD_FILE = `${API_ROOT_URL}/admin/reconcile_settings/upload_file`;

const ADMIN_BANK_RECONCILE_SETTINGS_LIST = `${API_ROOT_URL}/admin/reconcile/settings`;

export const getBankList = (request: DataListFilter) =>
  api.getStream(ADMIN_BANK_LIST_URL, { params: request.getDefinedValues() });

export const getBankItem = id =>
  api.getStream(sprintf(ADMIN_BANK_ITEM_URL, id));

export const createBank = values => api.post(ADMIN_BANK_URL, { body: values });
export const updateBank = (id, values) =>
  api.put(sprintf(ADMIN_BANK_ITEM_URL, id), { body: values });

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(ADMIN_BANK_SELECT_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(ADMIN_BANK_CHOOSE_LIST_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getBank = id => itemCache.refresh(id);

export const getCachedBank = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getBankPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getBankReconcileSettingsList = (request: DataListFilter) =>
  api.getStream(ADMIN_BANK_RECONCILE_SETTINGS_LIST, {
    params: request.getDefinedValues(),
  });

export const getBankReconcileSettingsListBeta = (request: DataListFilter) =>
  api.get(ADMIN_BANK_RECONCILE_SETTINGS_LIST, {
    params: request.getDefinedValues(),
  });

export const uploadBankReconcileFile = (values, file: File) =>
  api.postStream(ADMIN_BANK_RECONCILE_UPLOAD_FILE, {
    file,
    params: {
      bankId: values.bankId,
      type: values.type,
      headerRow: values.headerRow,
    },
  });

export const uploadBankReconcileSettingsFile = (values, file: File) =>
  api.postStream(ADMIN_BANK_RECONCILE_SETTINGS_UPLOAD_FILE, {
    file,
    params: {
      bankId: values.bankId,
      type: values.type,
      headerRow: values.headerRow,
    },
  });

export const createReconcileSettings = values =>
  api.post(ADMIN_BANK_RECONCILE_SETTINGS_LIST, { body: values });
