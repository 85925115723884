import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import { red, lightGreen } from "@material-ui/core/colors";
import FormSupplierChips from "../form/FormSupplierChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FormNeighborhoodChips from "../form/FormNeighborhoodChips";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseString,
  parseIntString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  DROP,
  PICKUP,
  DEFAULT_DROP,
  DEFAULT_PICKUP,
} from "../../constants/TimeSlotType";

const valueSelector = formValueSelector("TimeSlotFilterForm");

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const TimeSlotTypes = OrderedSet.of(DROP, PICKUP, DEFAULT_DROP, DEFAULT_PICKUP);

const enhancer = compose(
  useSheet({
    includeButton: { paddingBottom: "12px" },
  }),
  withContext(
    {
      getCachedPostcode: PropTypes.func,
      getPostcodePredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    props => ({
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        const includeSuppliers = Boolean(
          x.getValue("supplierIds") || !x.getValue("excludeSupplierIds"),
        );

        const includeNeighborhoods = Boolean(
          x.getValue("neighborhoodIds") ||
            !x.getValue("excludeNeighborhoodIds"),
        );

        return {
          courierTypes: parseString(fp.toLower(x.getValue("courierTypes"))),
          timeslotTypes: parseString(x.getValue("timeslotTypes")),

          includeNeighborhoods,
          neighborhoodIds: includeNeighborhoods
            ? idsToObjectArray(x.getValue("neighborhoodIds"))
            : idsToObjectArray(x.getValue("excludeNeighborhoodIds")),

          includeSuppliers,
          supplierIds: includeSuppliers
            ? idsToObjectArray(x.getValue("supplierIds"))
            : idsToObjectArray(x.getValue("excludeSupplierIds")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            neighborhoodIds: null,
            excludeNeighborhoodIds: null,
            supplierIds: null,
            excludeSupplierIds: null,
            courierTypes: fp.toUpper(stringifyArray(values.courierTypes)),
            timeslotTypes: stringifyArray(values.timeslotTypes),
          });

          if (!fp.isEmpty(values.neighborhoodIds)) {
            if (values.includeNeighborhoods) {
              filter.setValue(
                "neighborhoodIds",
                stringifyArray(values.neighborhoodIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "excludeNeighborhoodIds",
                stringifyArray(values.neighborhoodIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.supplierIds)) {
            if (values.includeSuppliers) {
              filter.setValue(
                "supplierIds",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "excludeSupplierIds",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            }
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "TimeSlotFilterForm",
    enableReinitialize: true,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "nieghborhoodIds",
      "includeNeighborhoods",
      "supplierIds",
      "includeSuppliers",
    ),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

TimeSlotFilterForm.propTypes = {
  classes: PropTypes.object,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,

  onDismiss: PropTypes.func,
  onFilterChange: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),

  getCachedPostcode: PropTypes.func,
  getPostcodePredictions: PropTypes.func,
  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  getServiceTypePredictions: PropTypes.func,
};

function TimeSlotFilterForm(props) {
  const { classes, values, getLocalisationMessage } = props;

  return (
    <FlexBox flex="none" container={8} element={<form />}>
      <FlexBox gutter={8} flex={true} direction="column">
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true} direction="column">
                {Boolean(props.getPostcodePredictions) && (
                  <FlexBox flex={true}>
                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true}>
                        <FormNeighborhoodChips
                          name="neighborhoodIds"
                          includeUnknownNeighborhood={true}
                          fullWidth={true}
                          hintText={getLocalisationMessage(
                            "type_to_search",
                            "Type to search ...",
                          )}
                          label={`${
                            values.includeNeighborhoods
                              ? getLocalisationMessage(
                                  "include_neighborhoods",
                                  "Include Neighborhoods",
                                )
                              : getLocalisationMessage(
                                  "exclude_neighborhoods",
                                  "Exclude Neighborhoods",
                                )
                          }`}
                        />
                      </FlexBox>
                      <FlexBox
                        align="flex-end"
                        className={classes.includeButton}
                      >
                        <IconButton
                          onClick={() =>
                            props.change(
                              "includeNeighborhoods",
                              !values.includeNeighborhoods,
                            )
                          }
                        >
                          {values.includeNeighborhoods ? (
                            <AddCircle color={lightGreen[600]} />
                          ) : (
                            <RemoveCircle color={red[500]} />
                          )}
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                )}

                {Boolean(props.getSupplierPredictions) && (
                  <FlexBox flex={true}>
                    <FlexBox gutter={8} flex={true}>
                      <FlexBox flex={true}>
                        <FormSupplierChips
                          name="supplierIds"
                          fullWidth={true}
                          hintText={getLocalisationMessage(
                            "type_to_search",
                            "Type to search ...",
                          )}
                          label={`${
                            values.includeSuppliers
                              ? getLocalisationMessage(
                                  "include_suppliers",
                                  "Include Suppliers ",
                                )
                              : getLocalisationMessage(
                                  "exclude_suppliers",
                                  "Exclude Suppliers",
                                )
                          }`}
                        />
                      </FlexBox>
                      <FlexBox
                        align="flex-end"
                        className={classes.includeButton}
                      >
                        <IconButton
                          onClick={() =>
                            props.change(
                              "includeSuppliers",
                              !values.includeSuppliers,
                            )
                          }
                        >
                          {values.includeSuppliers ? (
                            <AddCircle color={lightGreen[600]} />
                          ) : (
                            <RemoveCircle color={red[500]} />
                          )}
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                )}

                {Boolean(props.getServiceTypePredictions) && (
                  <FlexBox flex={true}>
                    <FormCourierTypeChips
                      name="courierTypes"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage(
                        "service_type",
                        "Service Type",
                      )}
                    />
                  </FlexBox>
                )}

                <FlexBox flex={true}>
                  <FormChipAutoComplete
                    name="timeslotTypes"
                    formatOption={option =>
                      formatText(getLocalisationMessage(option, option))
                    }
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage(
                      "timeslot_types",
                      "Timeslot Types",
                    )}
                    options={TimeSlotTypes}
                    fullWidth={true}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox justify="flex-end">
          <CardActions>
            <Button
              onClick={() => {
                const includeKeys = [
                  "includeNeighborhoods",
                  "includeSuppliers",
                ];

                fp.keys(props.initialValues).forEach(key => {
                  if (includeKeys.indexOf(key) === -1) {
                    props.change(key, null);
                  } else {
                    props.change(key, true);
                  }
                });
              }}
            >
              {getLocalisationMessage("clear", "Clear")}
            </Button>
            {props.dirty ? (
              <Button onClick={props.reset}>
                {getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}
            <Button onClick={props.handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </CardActions>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(TimeSlotFilterForm);
