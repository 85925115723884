import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import FormSwitcherDatePicker from "../form/FormSwitcherDatePicker";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";

const enhancer = compose(
  useSheet({
    datePicker: {
      margin: 0,
    },
  }),
  reduxForm({
    form: "DriversRadarNewDateForm",
  }),
  pureComponent(fp.pick([])),
);

DriversRadarNewDateForm.propTypes = {
  classes: PropTypes.object,
};

function DriversRadarNewDateForm(props) {
  const { classes } = props;

  return (
    <FlexBox>
      <FlexBox flex={true}>
        <FlexBox element={<form />} align="center">
          <FormSwitcherDatePicker
            dateWithWords={true}
            className={classes.datePicker}
            name="date"
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriversRadarNewDateForm);
