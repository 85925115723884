import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import { SHOP_API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CITY_CREATE_URL = `${SHOP_API_ROOT_URL}/admin/city`;
const CITY_LIST_URL = `${SHOP_API_ROOT_URL}/public/city`;
const CITY_BATCH_DELETE_URL = `${SHOP_API_ROOT_URL}/admin/city`;
const CITY_ITEM_URL = `${SHOP_API_ROOT_URL}/admin/city/%s`;

export type CityItemRequest = {
  id: number,
  name: string,
  code: string,
};

export const getCityItem = id => api.getStream(sprintf(CITY_ITEM_URL, id));
export const createCityItem = (body: CityItemRequest) =>
  api.post(CITY_CREATE_URL, { body });
export const updateCityItem = (id, body: CityItemRequest) =>
  api.put(sprintf(CITY_ITEM_URL, id), { body });
export const getShopCityList = request =>
  api.getStream(CITY_LIST_URL, {
    params: request.getDefinedValues(),
  });
export const removeShopCities = ids =>
  api.delete(CITY_BATCH_DELETE_URL, { params: { ids } });
