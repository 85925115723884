import { Map, Set } from "immutable";
import fp from "lodash/fp";
import { injectReducer } from "../../shared/helpers/ReducerContext";

// const UPDATE_TASK = "ORDER_SORTING/UPDATE_TASK";

const CACHE_JURISDICTIONS = "JURISDICTION_MANAGEMENT/CACHE_JURISDICTIONS";
// const CLEAR_ORDERS = "ORDER_SORTING/CLEAR_ORDERS";
// const REMOVE_ORDERS = "ORDER_SORTING/REMOVE_ORDERS";

const isJurisdictionManagement = fp.startsWith("JURISDICTION_MANAGEMENT");

const castToMap = x => (Map.isMap(x) ? x : Map());
// eslint-disable-next-line no-unused-vars
const castToSet = x => (Set.isSet(x) ? x : Set());

const cleanupState = fp.flow(castToMap, initialState =>
  initialState.withMutations(state => {
    state.set("omit", Set.of("jurisdictions"));
    state.update("jurisdictions", castToMap);

    // state.update(
    //   "task",
    //   fp.flow(castToMap, x =>
    //     x
    //       .update("filter", castToMap)
    //       .update("counter", castToSet)
    //       .update("boxCounter", castToSet)
    //       .update("autoAssign", Boolean)
    //       .update("cacheServer", Boolean)
    //       .update("activeOrder", fp.trim)
    //       .update("selectedOrders", castToSet)
    //       .update("warehouse", w =>
    //         Map.isMap(w) && fp.isFinite(w.get("id")) ? w : null,
    //       ),
    //   ),
    // );
  }),
);

const selector = injectReducer(
  "jurisdictions-management@1.0.0",
  (initialState = cleanupState(), action) => {
    const state = isJurisdictionManagement(action.type)
      ? cleanupState(initialState)
      : initialState;

    switch (action.type) {
      // case UPDATE_TASK:
      //   return state.withMutations(nextState => {
      //     nextState.set("task", action.payload);
      //
      //     nextState.update(cleanupState);
      //   });
      //
      case CACHE_JURISDICTIONS: {
        const { payload } = action;

        return state.withMutations(nextState => {
          payload.forEach(jurisdiction => {
            nextState.setIn(
              ["jurisdictions", jurisdiction.get("id")],
              jurisdiction,
            );
          });
        });
      }

      // case REMOVE_ORDERS: {
      //   const { payload } = action;
      //
      //   return state.withMutations(nextState => {
      //     if (payload.has(state.getIn(["task", "activeOrder"]))) {
      //       nextState.setIn(["task", "activeOrder"], null);
      //     }
      //
      //     payload.forEach(number => {
      //       nextState.updateIn(["task", "counter"], x => x.delete(number));
      //       nextState.updateIn(["task", "selectedOrders"], x =>
      //         x.delete(number),
      //       );
      //     });
      //   });
      // }
      //
      // case CLEAR_ORDERS: {
      //   return state.withMutations(nextState => {
      //     nextState.update("orders", x => x.clear());
      //     nextState.setIn(["task", "activeOrder"], null);
      //     nextState.updateIn(["task", "selectedOrders"], x => x.clear());
      //   });
      // }

      default:
        return state;
    }
  },
);

// export const getOrderSortingTask = state => selector(state).get("task");
export const getJurisdictions = state =>
  selector(state).get("jurisdictions", Map());

// export const updateSortingTask = updater => (dispatch, getState) => {
//   dispatch({
//     type: UPDATE_TASK,
//     payload: updater(getOrderSortingTask(getState())),
//   });
// };

export const addJurisdictions = jurisdictions => ({
  payload: jurisdictions,
  type: CACHE_JURISDICTIONS,
});

// export const removeOrderSortingOrders = orders => ({
//   payload: orders,
//   type: REMOVE_ORDERS,
// });

// export const clearOrderSortingOrders = () => ({
//   type: CLEAR_ORDERS,
// });
