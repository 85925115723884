import React from "react";
import sprintf from "sprintf";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SHOP_PRODUCT_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedVenueMenu,
  getVenueMenuPredictions,
} from "../../api/admin/AdminShopMenuApi";
import { createProductItem } from "../../api/admin/AdminShopProductApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminVenueProductsForm from "../../components/admin/AdminVenueProductsForm";

const enhancer = compose(
  connect(null, { showErrorMessage, showSuccessMessage }),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(propsStream =>
    propsStream
      .combineLatest(
        propsStream.map(fp.flow(fp.get("params.venueId"), fp.toFinite)),
        (props, venueId) => ({
          ...props,
          venueId,
        }),
      )
      .distinctUntilChanged(isEqualData),
  ),
);

AdminVenueProductCreate.propTypes = {
  venueId: PropTypes.number,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
};

function AdminVenueProductCreate(props) {
  return (
    <AdminAppLayout title="Create Product">
      <AdminVenueProductsForm
        onSubmit={values =>
          createProductItem(props.venueId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.setLocation(sprintf(SHOP_PRODUCT_LIST_URL, props.venueId));
        }}
        onSubmitFail={props.showErrorMessage}
        onDismissClick={() =>
          props.setLocation(sprintf(SHOP_PRODUCT_LIST_URL, props.venueId))
        }
        getCachedVenueMenu={getCachedVenueMenu}
        getVenueMenuPredictions={getVenueMenuPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminVenueProductCreate);
