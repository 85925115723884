import React from "react";
import { endOfToday } from "date-fns";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormDateField from "../form/FormDateField";
import { renderIf } from "../../helpers/HOCUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_ORDER_COD_AGING_RECEIVABLE_REPORT_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  withTheme,
  renderIf("open"),
  useSheet({
    container: { maxWidth: "600px" },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  reduxForm({
    form: "OrderAgingReportDialog",
    initialValues: { toDate: endOfToday() },
  }),
  formValues({ toDate: "toDate" }),
);

OrderAgingReportDialog.propTypes = {
  classes: PropTypes.object,
  toDate: PropTypes.instanceOf(Date),
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

function OrderAgingReportDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      open={true}
      onClose={props.onRequestClose}
      contentClassName={classes.container}
    >
      <DialogTitle className={classes.dialogTitle}>
        {getLocalisationMessage("cod_aging_report", "COD Aging Report")}
      </DialogTitle>
      <DialogContent>
        <FormDateField
          name="toDate"
          fullWidth={true}
          label={getLocalisationMessage("to_date", "To Date")}
        />
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button
          primary={true}
          target="_blank"
          component="a"
          href={updateQuery(CREATE_ORDER_COD_AGING_RECEIVABLE_REPORT_URL, {
            to_date: formatDateToUrl(props.toDate),
            csv_type: "customer",
          })}
        >
          {getLocalisationMessage("customer_report", "Customer Report")}
        </Button>
        <Button
          primary={true}
          target="_blank"
          component="a"
          href={updateQuery(CREATE_ORDER_COD_AGING_RECEIVABLE_REPORT_URL, {
            to_date: formatDateToUrl(props.toDate),
            csv_type: "supplier",
          })}
        >
          {getLocalisationMessage("supplier_report", "Supplier Report")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderAgingReportDialog);
