import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminSimplePostForm from "../../components/orders-core/AdminSimplePostForm";
import { Map } from "immutable";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
);

AdminSimplePostCreate.propTypes = {
  i18n: PropTypes.instanceOf(Map),
};

function AdminSimplePostCreate(props) {
  return (
    <AdminAppLayout title={props.i18n.get("order_creation", "Order Creation")}>
      <AdminSimplePostForm />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSimplePostCreate);
