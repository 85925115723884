import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { TransitionUp } from "../../dialog/TransitionUp";
import FlexBox from "../../ui-core/FlexBox";
import { SECONDARY } from "../../form/FormTextField";
import { getFormValues, reduxForm } from "redux-form";
import { formatText } from "../../../helpers/FormatUtils";
import FormSelectField from "../../form/FormSelectField";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import { Print } from "@material-ui/icons";

const getValues = getFormValues("InternationalParcelMPPDFFilter");

const years = [2022, 2023, 2024];
const dateTypes = ["MONTH", "QUARTER"];
const dateMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const dateQuarter = [1, 2, 3, 4];

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "InternationalParcelMPPDFFilter",
    enableReinitialize: true,
    validate: (values, props) => ({
      year:
        !values.year && props.getLocalisationMessage("this_field_is_required"),
      dateType:
        !values.dateType &&
        props.getLocalisationMessage("this_field_is_required"),
      date:
        !values.date && props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(state => ({
    values: getValues(state),
  })),
);

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "400px",
    maxWidth: "500px",
    minHeight: "auto",
  },
  dialogTitle: {
    color: theme.palette.appBarTextColor,
    backgroundColor: theme.palette.primary1Color,
  },
  dialogHeaderTitle: {
    flexGrow: 1,
  },
  dialogHeaderToggleContainer: {
    padding: "10px 5px 10px 20px",
    borderRadius: 1000,
    backgroundColor: "#DBE1E6",
  },
}));

const InternationalParcelMPPDFFilter = ({
  values,
  getLocalisationMessage,
  onRequestClose,
  open,
  onSubmit,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={onRequestClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FlexBox flex={true} justify="space-between">
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage("mp_pdf")}
          </div>
        </FlexBox>
      </DialogTitle>
      <FlexBox align="center" container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FlexBox gutter={16} flex={true} align="center">
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="year"
                      fullWidth={true}
                      options={years}
                      formatOption={option => option}
                      label={`${getLocalisationMessage("year", "Year")}`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="dateType"
                      fullWidth={true}
                      options={dateTypes}
                      formatOption={option =>
                        getLocalisationMessage(option, formatText(option))
                      }
                      label={`${getLocalisationMessage(
                        "date_type",
                        "Date Type",
                      )}`}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="date"
                      fullWidth={true}
                      disabled={!values.dateType}
                      options={
                        values.dateType === "QUARTER" ? dateQuarter : dateMonth
                      }
                      formatOption={option => option}
                      label={`${getLocalisationMessage(
                        "date_type",
                        "Date Type",
                      )}`}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
              <CardActions>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} justify="flex-end" style={{ gap: 16 }}>
                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      onClick={() => {
                        onRequestClose();
                      }}
                    >
                      {getLocalisationMessage("close", "close")}
                    </CustomButton>
                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      onClick={() => {
                        onSubmit(values);
                      }}
                      endIcon={
                        loading ? (
                          <CircularProgress size={20} color="white" />
                        ) : (
                          <Print />
                        )
                      }
                    >
                      {getLocalisationMessage("download_pdf", "Download PDF")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>
              </CardActions>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Dialog>
  );
};
InternationalParcelMPPDFFilter.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  values: PropTypes.object,
};

export default enhancer(InternationalParcelMPPDFFilter);
