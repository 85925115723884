import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Place } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import UserAvatar from "../avatars/UserAvatar";
import { ListSubheader } from "@material-ui/core";

const enhancer = compose(
  useSheet({
    details: {
      color: "#fff",
    },
    photo: {
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
    },
    title: {
      color: "white",
      fontSize: "11px",
      textTransform: "uppercase",
      paddingLeft: 0,
      lineHeight: "13px",
    },
    name: {
      lineHeight: "28px",
      fontSize: "24px",
      fontWeight: 500,
    },
    phone: {
      lineHeight: "16px",
      fontSize: "14px",
    },
    mapsPlace: {
      color: "#fff",
      width: "12px",
      height: "12px",
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderBriefSubjectInfo.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  subject: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function OrderBriefSubjectInfo(props) {
  const { classes, subject } = props;

  if (!subject) return null;

  return (
    <FlexBox className={classes.details}>
      <UserAvatar
        className={classes.photo}
        size={60}
        name={subject.get("name")}
      />
      <FlexBox flex={true} direction="column">
        <ListSubheader className={classes.title}>{props.title}</ListSubheader>
        <h3 className={classes.name}>{subject.get("name", "")}</h3>
        <div className={classes.phone}>{subject.get("phone", "")}</div>
        <div className={classes.address}>
          <Place className={classes.mapsPlace} /> {subject.get("address", "")}
        </div>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderBriefSubjectInfo);
