import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import sprintf from "sprintf";
import { List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  SHOP_VENUE_ITEM_URL,
  SHOP_ADDRESS_LIST_URL,
  SHOP_PRODUCT_ITEM_URL,
  SHOP_PRODUCT_LIST_URL,
  SHOP_PRODUCT_CREATE_URL,
  SHOP_VENUE_MENU_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  removeProduct,
  getProdustList,
} from "../../api/admin/AdminShopProductApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import ProductListFilterDialog from "../../components/admin/ProductListFilterDialog";
import PageLoading from "../../components/ui-core/PageLoading";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../../components/data-list/DataList";
import RoutingTabs from "../../components/deprecated/RoutingTabs";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const NA = "N/A";
const VENUE_PRODUCT_FILTER_DIALOG_HASH = "#VPFDH";

const enhancer = compose(
  connect(null, {
    showErrorMessage,
    showSuccessMessage,
  }),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const venueIdStream = propsStream.map(
          fp.flow(fp.get("params.venueId"), fp.toFinite),
        );

        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          venueIdStream,
          filterStream,
          (props, venueId, filter) => ({
            ...props,
            venueId,
            filter,
          }),
        );
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getProdustList(props.venueId, props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds, menuId, status) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              menuId,
              status,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminVenueProductList.propTypes = {
  total: PropTypes.number,
  isListLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  setLocation: PropTypes.func,
  onRowSelect: PropTypes.func,
  selectedIds: PropTypes.instanceOf(OrderedSet),
  filter: PropTypes.instanceOf(DataListFilter),
  status: PropTypes.string,
  venueId: PropTypes.number,
  menuId: PropTypes.number,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationHash: PropTypes.func,
};

function AdminVenueProductList(props) {
  const { location, venueId, list } = props;

  return (
    <AdminAppLayout title="Products">
      <PageLoading isLoading={!list} />
      <RoutingTabs
        width={300}
        tabs={[
          {
            label: "Venue Details",
            value: SHOP_VENUE_ITEM_URL + venueId,
          },
          {
            label: "Menus",
            value: sprintf(SHOP_VENUE_MENU_LIST_URL, venueId),
          },
          {
            label: "Products",
            value: sprintf(SHOP_PRODUCT_LIST_URL, venueId),
          },
          {
            label: "Addresses",
            value: sprintf(SHOP_ADDRESS_LIST_URL, venueId),
          },
        ]}
        omitQueryParams={["page"]}
      />

      <DataList
        isLoading={props.isLoading}
        selectedRowCount={props.selectedIds.size}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={list.size}
        rowGetter={options => list.get(options.index)}
        altHeader={
          <Button
            onClick={() =>
              removeProduct(props.selectedIds.toArray().join(",")).then(
                props.onRequestRefresh,
              )
            }
          >
            Delete Products
          </Button>
        }
        cardActionIcons={
          <div>
            <IconButton
              tooltip="Create New Product"
              onClick={() =>
                props.setLocation(sprintf(SHOP_PRODUCT_CREATE_URL, venueId))
              }
            >
              <Add />
            </IconButton>

            <IconButton
              tooltip="Menus Filter"
              onClick={() =>
                props.setLocationHash(VENUE_PRODUCT_FILTER_DIALOG_HASH)
              }
            >
              <FilterList />
              <ProductListFilterDialog
                title="Products"
                open={location.hash === VENUE_PRODUCT_FILTER_DIALOG_HASH}
                initialValues={{
                  status,
                  menu: { id: _.toInteger(props.menuId) },
                }}
                onSubmit={data => {
                  const query = _.omit(location.query, ["status", "menuId"]);

                  if (_.get(data, "status")) {
                    query.status = _.upperCase(_.get(data, "status"));
                  }

                  if (_.get(data, "menu.id")) {
                    query.menuId = _.get(data, "menu.id");
                  }
                  props.setLocation(updateQuery(location, query));
                  props.setLocationHash(null);
                }}
                onRequestClose={() => props.setLocationHash(null)}
              />
            </IconButton>
          </div>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            props.selectedIds.size > 0 &&
            props.selectedIds.size === props.list.size
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.list.toOrderedSet().map(item => item.get("id"))
                : OrderedSet(),
            )
          }
          rowSelected={row => props.selectedIds.has(row.cellData.get("id"))}
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedIds.add(row.cellData.get("id"))
                : props.selectedIds.delete(row.cellData.get("id")),
            )
          }
        />

        <DataListColumn
          width={96}
          label="Name"
          dataKey="name"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              <Link
                to={sprintf(
                  SHOP_PRODUCT_ITEM_URL,
                  venueId,
                  row.cellData.get("id"),
                )}
              >
                {row.cellData.get("name")}
              </Link>
            </strong>
          )}
        />

        <DataListColumn
          width={96}
          label="Cost"
          dataKey="cost"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <PriceWrapper price={row.cellData.get("cost")} />
            </div>
          )}
        />

        <DataListColumn
          width={96}
          label="Price"
          dataKey="price"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <PriceWrapper price={row.cellData.get("price")} />
            </div>
          )}
        />

        <DataListColumn
          width={96}
          label="Old Price"
          dataKey="old_price"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <PriceWrapper price={row.cellData.get("old_price")} />
            </div>
          )}
        />

        <DataListColumn
          width={96}
          label="Discount"
          dataKey="discount"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <PriceWrapper price={row.cellData.get("discount")} />
            </div>
          )}
        />

        <DataListColumn
          width={96}
          label="Weight"
          dataKey="weight"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("weight") || NA}</div>}
        />

        <DataListColumn
          width={96}
          label="Created Date"
          dataKey="created_date"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>{formatDate(row.cellData.get("created_date")) || NA}</div>
          )}
        />

        <DataListColumn
          width={96}
          label="Status"
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => <div>{row.cellData.get("status") || NA}</div>}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminVenueProductList);
