import React, { useEffect, useRef, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITable, {
  ACTION,
  COLUMN,
  DIALOG,
} from "../../components/orders-core/MUITable";
import _ from "lodash";
import { Add, Delete, KeyboardArrowLeft, Search } from "@material-ui/icons";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { TransitionUp } from "../../components/dialog/TransitionUp";
import {
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import FlexBox, { JUSTIFY_CENTER } from "../../components/ui-core/FlexBox";
import {
  deleteProductCategoryType,
  getProductCategoryServiceType,
  getProductCategoryTypeList,
  updateProductCategoryServiceType,
} from "../../api/shared/ProductCategoryApi";
import UpdateProductCategoryDialog from "../../components/product-category/UpdateProductCategoryDialog";
import CreateProductCategoryDialog from "../../components/product-category/CreateProductCategoryDialog";
import CategoryServiceTypeForm from "../../components/product-category/CategoryServiceTypeForm";
import {
  getCachedServiceTypeById,
  getServiceTypeCategoryPredictions,
} from "../../api/admin/AdminServiceTypesApi";
import { getValue } from "../../helpers/DataUtils";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminProductCategoriesList.propTypes = {
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminProductCategoriesList({
  getLocalisationMessage,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
}) {
  const [deletedId, setDeletedId] = useState(0);
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [list, setList] = useState([]);
  const [id, setId] = useState(0);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypeRefresh, setServiceTypeRefresh] = useState(false);

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  const [filter, setFilter] = useState({
    refresh: false,
    page: 0,
    size: 20,
    level_id: 1,
  });

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });

  const [total, setTotal] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getProductCategoryTypeList(filter)
      .then(res => {
        setIsLoading(false);
        setList(_.get(res, "data.list"));
        setTotal(_.get(res, "data.total"));
      })
      .catch(error => {
        setIsLoading(false);
        showErrorMessage1(error);
      });
  }, [filter]);

  const deleteCategory = () => {
    setIsLoadingDelete(true);
    deleteProductCategoryType(deletedId)
      .then(() => {
        setDeletedId(0);
        showSuccessMessage1(getLocalisationMessage("successfully_deleted"));
        refreshList();
      })
      .catch(error => showErrorMessage1(error))
      .finally(() => setIsLoadingDelete(false));
  };
  const deleteDispatch = deleteId => setDeletedId(deleteId);

  useEffect(() => {
    getProductCategoryServiceType().then(res => {
      if (res && res.data) {
        setServiceTypes(getValue(res, "data", []));
      }
    });
  }, [serviceTypeRefresh]);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("product_categories", "Product Categories")}
    >
      <CreateProductCategoryDialog
        open={isOpenNew}
        title={getLocalisationMessage("create", "Create")}
        refreshList={refreshList}
        onRequestClose={() => {
          setIsOpenNew(false);
          setId(null);
        }}
      />

      <UpdateProductCategoryDialog
        open={id > 0}
        id={id}
        title={getLocalisationMessage("update", "Update")}
        refreshList={refreshList}
        onRequestClose={() => {
          setId(0);
        }}
      />

      <Dialog
        TransitionComponent={TransitionUp}
        open={deletedId}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setDeletedId(0)}
      >
        <DialogTitle style={{ borderBottom: "1px solid #eeeeee" }}>
          <h5 style={{ textAlign: "center" }}>
            {getLocalisationMessage("are_you_sure_you_want_to_remove")}
          </h5>
        </DialogTitle>
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox>
              <CustomButton
                onClick={() => setDeletedId(0)}
                startIcon={<KeyboardArrowLeft />}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("close_window", "Close window")}
              </CustomButton>
              <CustomButton
                style={{ marginLeft: "1rem" }}
                onClick={() => deleteCategory()}
                endIcon={
                  isLoadingDelete ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <Delete />
                  )
                }
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("delete")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      </Dialog>

      <Card>
        <CardContent>
          <CategoryServiceTypeForm
            onSubmit={values => {
              const ids =
                values.ids && values.ids.length
                  ? values.ids.map(item => item.id)
                  : [];
              return updateProductCategoryServiceType({
                ids,
                category: true,
              })
                .then(() => {
                  showSuccessMessage1(getLocalisationMessage("successfully"));
                  setServiceTypeRefresh(prev => !prev);
                })
                .catch(error => showErrorMessage1(error));
            }}
            getCachedServiceTypeById={getCachedServiceTypeById}
            getServiceTypePredictions={getServiceTypeCategoryPredictions}
            initialValues={{
              ids: serviceTypes,
            }}
          />
        </CardContent>
      </Card>
      <MUITable
        isLoading={isLoading}
        size="small"
        setId={setId}
        list={list}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        headerActions={
          <React.Fragment>
            <div
              style={{
                marginRight: "1rem",
                flex: "1 1 auto",
                position: "relative",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: 4,
              }}
              ref={ref}
            >
              <Search
                fontSize="large"
                style={{
                  color: "rgba(0, 0, 0, 0.23)",
                  top: ".5rem",
                  left: ".5rem",
                  position: "absolute",
                }}
              />
              <ChipTextField
                style={{
                  padding: ".5rem 1rem 0 3rem",
                  maxHeight: divHeight > 86 && "5.8rem",
                  overflow: divHeight > 86 && "auto",
                }}
                fullWidth={true}
                addOnBlur={false}
                clearOnBlur={false}
                value={parseString(filter.search)}
                disableUnderline={true}
                placeholder={getLocalisationMessage("type_here_to_search")}
                onChange={v =>
                  setFilter({ ...filter, search: stringifyArray(v) })
                }
              />
            </div>
            <CustomButton
              onClick={() => setIsOpenNew(true)}
              startIcon={<Add />}
              variant={OUTLINED}
              color={SECONDARY}
              style={{
                marginTop: "1rem",
                marginRight: "1rem",
                marginBottom: ".5rem",
              }}
            >
              {getLocalisationMessage("create")}
            </CustomButton>
          </React.Fragment>
        }
        columns={[
          {
            type: DIALOG,
            name: "id",
            label: getLocalisationMessage("id"),
            align: "center",
            keyName: "id",
          },
          {
            type: COLUMN,
            name: "name",
            label: getLocalisationMessage("name"),
          },
          {
            type: COLUMN,
            name: "name_ru",
            label: getLocalisationMessage("name_ru"),
          },
          {
            type: COLUMN,
            name: "description",
            label: getLocalisationMessage("description"),
          },
          {
            type: ACTION,
            label: getLocalisationMessage("delete"),
            dispatch: deleteDispatch,
          },
        ]}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminProductCategoriesList);
