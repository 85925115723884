import React from "react";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const enhancer = getContext({
  getCachedCity: PropTypes.func.isRequired,
  getCityPredictions: PropTypes.func.isRequired,
});

FormCityChips.propTypes = {
  getCachedCity: PropTypes.func,
  getCityPredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  countryId: PropTypes.number.isRequired,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
};

function FormCityChips({
  getCachedCity,
  getCityPredictions,
  countryId,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={getCachedCity}
      getPredictions={searchText =>
        getCityPredictions(
          baseFilter.setSearch(searchText).setValue("country_id", countryId),
        )
      }
    />
  );
}

export default enhancer(FormCityChips);
