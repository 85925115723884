import DataListFilter, { DEFAULT_PAGE_SIZE } from "./DataListFilter";
import fp from "lodash/fp";
import { types, mapSchema } from "../../shared/helpers/ObjectMapper";
import { fromJS } from "immutable";
import {
  ACCEPTED,
  ARRIVED,
  ARRIVED_TO_DELIVERY_ADDRESS,
  BAD_RECIPIENT_ADDRESS,
  BAD_WEATHER_DURING_DELIVERY,
  COD_NOT_READY,
  COLLECTION_ARRANGED_OR_REQUESTED,
  COMPLETED,
  DAMAGED,
  DELIVERY_ATTEMPT,
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
  DELIVERY_FAILED,
  DELIVERY_REJECTED,
  DELIVERY_SCHEDULED,
  DESTROYED_ON_CUSTOMER_REQUEST,
  DISPATCHED,
  DRIVER_REJECTED,
  FIRST_DELIVERY_ATTEMPT,
  FIRST_PICKUP_ATTEMPT,
  FUTURE_DELIVERY_REQUESTED,
  ID_OR_DOCUMENT_REQUIRED_MISSING,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  IN_TRANSIT_TO_SUPPLIER,
  INCOMPLETE_PARCEL,
  LOST,
  LOST_OR_DAMAGED,
  LOST_OR_DAMAGED_CLOSED,
  NO_CAPACITY_FOR_DELIVERY,
  NO_TIME_FOR_DELIVERY,
  OFFICE_CLOSED,
  ON_HIS_WAY,
  ON_HOLD,
  OTHER_REASON,
  OUT_FOR_DELIVERY,
  OUT_FOR_RETURN,
  OUT_OF_DELIVERY_AREA,
  PARTIAL_COMPLETE,
  PENDING_ASSIGN_TO_COURIER,
  PICK_UP_ATTEMPT,
  PICKED_UP,
  PICKED_UP_AUTO,
  PREPARED_FOR_TRANSIT,
  RECIPIENT_ADDRESS_CHANGE_REQUESTED,
  RECIPIENT_MOBILE_NO_RESPONSE,
  RECIPIENT_MOBILE_SWITCHED_OFF,
  RECIPIENT_MOBILE_WRONG,
  RECIPIENT_NAME_MISSING,
  RECIPIENT_NOT_AVAILABLE,
  RECIPIENT_WANTS_INSPECT_ITEM,
  REJECTED,
  RETURNING_TO_ORIGIN,
  SECOND_DELIVERY_ATTEMPT,
  THIRD_DELIVERY_ATTEMPT,
  TRANSITED_TO_SUPPLIER,
  UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
  WRONG_SHIPMENT,
} from "../constants/OrderStatusCodes";

const toPositiveInteger = fp.flow(fp.toInteger, fp.clamp(0, Infinity));
const stringifyParam = fp.flow(fp.trim, (v) => v || null);

const getListFilterSchema = () => ({
  driver_page: toPositiveInteger,
  size: fp.flow(toPositiveInteger, (v) => v || DEFAULT_PAGE_SIZE),

  search: stringifyParam,
  search_type: stringifyParam,
});

export const toDriverListMapper = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),

    from_date_time: types.string,
    to_date_time: types.string,

    driver_availability: types.string,
    driver_status: types.string,
    duty_status: types.string,

    is_delivery_mode: types.boolean,
  }),
);

export const toSuggestedDriversFilter = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),
    from_date_time: types.string,
    to_date_time: types.string,

    use_solr: types.booleanOr(true),
    duty_status: types.string,
    suggested_driver_status: types.string,
    is_delivery_mode: types.boolean,
  }),
);

export const defaultDriverOrdersFilter = fromJS([
  {
    name: "All",
    sort_order: 0,
    filter: {
      status: null,
      order_by: "pickup_time",
      order_by_direction: "asc",
    },
  },
  {
    name: "In Progress",
    sort_order: 1,
    filter: {
      status: [
        ACCEPTED,
        DRIVER_REJECTED,
        FIRST_PICKUP_ATTEMPT,
        PICK_UP_ATTEMPT,
        ON_HIS_WAY,
        ARRIVED,
        PICKED_UP,
        PICKED_UP_AUTO,
        BAD_RECIPIENT_ADDRESS,
        ON_HOLD,
        IN_SORTING_FACILITY,
        IN_TRANSIT,
        DELIVERY_SCHEDULED,
        FIRST_DELIVERY_ATTEMPT,
        RECIPIENT_NOT_AVAILABLE,
        RECIPIENT_MOBILE_SWITCHED_OFF,
        RECIPIENT_ADDRESS_CHANGE_REQUESTED,
        RECIPIENT_MOBILE_WRONG,
        RECIPIENT_WANTS_INSPECT_ITEM,
        DISPATCHED,
        OUT_FOR_DELIVERY,
        ARRIVED_TO_DELIVERY_ADDRESS,
        COD_NOT_READY,
        DELIVERY_REJECTED,
        RETURNING_TO_ORIGIN,
        DELIVERY_ATTEMPT,
        DELIVERY_FAILED,
        OUT_FOR_RETURN,
        RECIPIENT_MOBILE_NO_RESPONSE,
        FUTURE_DELIVERY_REQUESTED,
        OUT_OF_DELIVERY_AREA,
        UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
        WRONG_SHIPMENT,
        INCOMPLETE_PARCEL,
        DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
        NO_TIME_FOR_DELIVERY,
        NO_CAPACITY_FOR_DELIVERY,
        ID_OR_DOCUMENT_REQUIRED_MISSING,
        BAD_WEATHER_DURING_DELIVERY,
        SECOND_DELIVERY_ATTEMPT,
        THIRD_DELIVERY_ATTEMPT,
        RECIPIENT_NAME_MISSING,
        COLLECTION_ARRANGED_OR_REQUESTED,
        DESTROYED_ON_CUSTOMER_REQUEST,
        LOST_OR_DAMAGED,
        IN_TRANSIT_TO_SUPPLIER,
        TRANSITED_TO_SUPPLIER,
        PARTIAL_COMPLETE,
        PENDING_ASSIGN_TO_COURIER,
        PREPARED_FOR_TRANSIT,
        OFFICE_CLOSED,
        LOST_OR_DAMAGED_CLOSED,
        OTHER_REASON,
        LOST,
        DAMAGED,
        REJECTED,
      ].join(","),
      order_by: "pickup_time",
      order_by_direction: "asc",
    },
  },
  {
    name: "Completed",
    sort_order: 2,
    filter: {
      status: [COMPLETED].join(","),
      order_by: "pickup_time",
      order_by_direction: "desc",
    },
  },
]);
