import React from "react";
import Immutable, { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper, IconButton, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import {
  LastPage,
  FirstPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import RadarOrderTabs from "./RadarOrderTabs";
import NewRadarOrderItem from "./NewRadarOrderItem";
import Text, { MUTED } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import ReactAutoScroll from "../drivers-radar/ScrollToPosition";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import TextField from "../deprecated/TextField";
import RadarDriverOrderTabs from "./RadarDriverOrderTabs";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
  useSheet({
    container: {
      width: "500px",
      position: "relative",
    },
    pages: {
      fontSize: "13px",
      color: "#6f6a6a",
      fontFamily: "Blogger Sans",
    },
    headerContainer: {
      padding: "0",
      fontSize: "16px",
      fontFamily: "Blogger Sans",
    },
    listContainer: {
      padding: 0,
      fontSize: "16px",
      overflowY: "auto",
      overflowX: "hidden",
      fontFamily: "Blogger Sans",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgb(162 162 162 / 87%)",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgb(125 125 125 / 87%)",
      },
    },
    sizeInput: {
      fontSize: "12px",
      maxWidth: "48px",
      marginLeft: "8px",
      marginRight: "8px",
      borderBottom: "1px solid #eee",
      overflow: "hidden",
      "& input": { textAlign: "right" },
      "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button": {
        margin: 0,
        WebkitAppearance: "none",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const pagesCountStream = propsStream
      .map(fp.pick(["orders", "orderFilter", "total"]))
      .distinctUntilChanged(isEqualData)
      .map(({ orders, orderFilter, total }) => {
        const listSize = orders && orders.size;
        const page = orderFilter.getPage() || 0;
        const pageSize = orderFilter.getSize() || 50;

        if (!total) {
          return "";
        }

        if (page === 0 && listSize < total) {
          return `1-${pageSize} of ${formatNumber(total)}`;
        } else if (listSize === total) {
          return `1-${listSize} of ${listSize}`;
        }

        return `${page * pageSize + 1}-${page * pageSize +
          listSize} of ${formatNumber(total)}`;
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(pagesCountStream, (props, pagesCount) => ({
        ...props,
        pagesCount,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "alertItem",
      "orders",
      "listPosition",
      "showDateFilter",
      "orderItemDetails",
      "orderFilter",
      "isOrdersLoading",
      "scrollingList",
      "isLoadingAlert",
      "newOrdersCount",
      "orderItem",
    ]),
  ),
);

class DriversRadarOrdersList extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  static propTypes = {
    classes: PropTypes.object,

    onItemClick: PropTypes.func,
    onItemCancel: PropTypes.func,
    onSortClick: PropTypes.func,
    onFilterClick: PropTypes.func,
    onScrollingDone: PropTypes.func,
    onItemCloseClick: PropTypes.func,
    onItemFilterClick: PropTypes.func,
    onAlertStatusClick: PropTypes.func,

    orderFilter: PropTypes.instanceOf(DataListFilter),

    orderItem: PropTypes.number,

    isOrdersLoading: PropTypes.bool,
    orderItemDetailsLoading: PropTypes.bool,

    pagesCount: PropTypes.string,

    ordersCount: PropTypes.number,

    total: PropTypes.number,
    orders: PropTypes.instanceOf(Immutable.List),
    orderItemDetails: PropTypes.instanceOf(Immutable.Map),

    isLoadingAlert: PropTypes.bool,
    showCustomFilter: PropTypes.bool,
    depends: PropTypes.bool,

    onChangePage: PropTypes.func,
    onChangePageSize: PropTypes.func,

    listPosition: PropTypes.number,
    scrollingList: PropTypes.bool,
    getLocalisationMessage: PropTypes.func,
    onCustomFilterClick: PropTypes.func,
    onOrderRefreshRequest: PropTypes.func,
    newOrdersCount: PropTypes.number,

    filters: PropTypes.instanceOf(List),
    isLoadingFilters: PropTypes.bool,
    onRequestRefreshFilters: PropTypes.func,
    getOrderList: PropTypes.func,

    showDateFilter: PropTypes.bool,
    onClickDateFilter: PropTypes.func,
    isFoodDelivery: PropTypes.bool,
  };

  render() {
    const { classes, orderFilter, getLocalisationMessage } = this.props;
    const page = orderFilter.getPage() || 0;
    const disabledFirstButtons =
      page === 0 || this.props.total === this.props.orders.size;
    const disabledLastButtons =
      page * orderFilter.getSize() >= this.props.total ||
      this.props.total === this.props.orders.size;

    return (
      <ReactAutoScroll
        targetPosition={this.props.listPosition}
        easeType="linear"
        speed={30}
        updateInterval={40}
        onScrollingDone={this.props.onScrollingDone}
        scrollTargetRef={this.scrollList}
        isEnabled={this.props.scrollingList}
      >
        <FlexBox className={classes.container}>
          <div className={classes.arrow} />

          <FlexBox flex={true} direction="column" element={<Paper />}>
            <FlexBox>
              <FlexBox
                gutter={8}
                flex={true}
                className={classes.headerContainer}
              >
                {this.props.depends ? (
                  <RadarDriverOrderTabs
                    filter={this.props.orderFilter}
                    onFilterClick={this.props.onFilterClick}
                    showCustomFilter={this.props.showCustomFilter}
                    onOrderRefreshRequest={this.props.onOrderRefreshRequest}
                    getOrderList={this.props.getOrderList}
                  />
                ) : (
                  <RadarOrderTabs
                    orderFilter={this.props.orderFilter}
                    onFilterClick={this.props.onFilterClick}
                    showCustomFilter={this.props.showCustomFilter}
                    newOrdersCount={this.props.newOrdersCount}
                    onOrderRefreshRequest={this.props.onOrderRefreshRequest}
                    filters={this.props.filters}
                    onRequestRefresh={this.props.onRequestRefreshFilters}
                    isLoadingFilters={this.props.isLoadingFilters}
                    getOrderList={this.props.getOrderList}
                    showDateFilter={this.props.showDateFilter}
                    onClickDateFilter={this.props.onClickDateFilter}
                  />
                )}
              </FlexBox>
            </FlexBox>

            <FlexBox
              flex={true}
              direction="column"
              className={classes.listContainer}
              element={
                <div
                  ref={x => {
                    this.scrollList = x;
                  }}
                />
              }
            >
              {this.props.isOrdersLoading && <LinearProgress />}

              {Boolean(
                !this.props.isOrdersLoading && this.props.orders.size === 0,
              ) && (
                <FlexBox justify="center">
                  <Text type={MUTED}>
                    {getLocalisationMessage(
                      "orders_not_found",
                      "Orders Not Found!",
                    )}
                  </Text>
                </FlexBox>
              )}

              {!this.props.isOrdersLoading &&
                this.props.orders.map((item, idx) => (
                  <NewRadarOrderItem
                    item={item}
                    orderDetails={this.props.orderItemDetails}
                    orderItem={this.props.orderItem}
                    orderFilter={this.props.orderFilter}
                    onItemCancel={x => {
                      this.props.onItemCancel(x, idx);
                    }}
                    key={item.get("id")}
                    onItemCloseClick={this.props.onItemCloseClick}
                    onItemClick={x => {
                      this.props.onItemClick(x, idx);
                    }}
                  />
                ))}
            </FlexBox>

            {Boolean(
              !this.props.isOrdersLoading && this.props.orders.size > 0,
            ) && (
              <FlexBox>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox>
                    <IconButton
                      disabled={disabledFirstButtons}
                      onClick={() => this.props.onChangePage(0)}
                    >
                      <FirstPage />
                    </IconButton>
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledFirstButtons}
                      onClick={() => this.props.onChangePage(page - 1)}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </FlexBox>

                  <FlexBox
                    flex={true}
                    align="center"
                    justify="center"
                    className={classes.pages}
                  >
                    <TextField
                      id="DataListFooter-size"
                      type="number"
                      value={this.props.orderFilter.getSize()}
                      debounceTime={5000}
                      debounceChange={true}
                      className={classes.sizeInput}
                      parse={fp.flow(fp.toFinite, fp.clamp(1, 200))}
                      onBlur={x => this.props.onChangePageSize(x)}
                      onChange={x => this.props.onChangePageSize(x)}
                    />
                    {this.props.pagesCount}
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledLastButtons}
                      onClick={() => this.props.onChangePage(page + 1)}
                    >
                      <ChevronRight />
                    </IconButton>
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledLastButtons}
                      onClick={() =>
                        this.props.onChangePage(
                          Math.ceil(this.props.total / 50 - 1),
                        )
                      }
                    >
                      <LastPage />
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </ReactAutoScroll>
    );
  }
}

export default enhancer(DriversRadarOrdersList);
