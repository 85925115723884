import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate, formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { toTransactionsFilter } from "../../helpers/TransactionsFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getEndingBalance,
  getTransactionItem,
  getTransactionList,
} from "../../api/admin/AdminFinanceApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import CustomerTransactions from "../../components/finance-core/CustomerTransactions";
import CustomerTransactionsFilter from "../../components/finance-core/CustomerTransactionsFilter";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_COD_STATEMENT_HISTORY_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  withTheme,
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const idStream = propsStream
          .map(fp.flow(fp.get("location.query.id"), fp.toFinite))
          .distinctUntilChanged();

        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toTransactionsFilter))
          .distinctUntilChanged(isEqualData);

        const customerIdStream = propsStream
          .map(fp.flow(fp.get("location.query.customer_id"), fp.toFinite))
          .distinctUntilChanged();

        return propsStream.combineLatest(
          idStream,
          filterStream,
          customerIdStream,
          (props, id, filter, customerId) => ({
            ...props,
            id,
            filter,
            customerId,
          }),
        );
      },
      propsStream => {
        const filterValuesStream = propsStream
          .map(props => ({
            customer: { id: props.customerId },
            fromDate: safeParseDate(props.filter.getValue("from_date")),
            toDate: safeParseDate(props.filter.getValue("to_date")),
          }))
          .distinctUntilChanged(isEqualData);

        const endingBalanceStream = propsStream
          .distinctUntilKeyChanged("customerId")
          .switchMap(props =>
            props.customerId > 0
              ? getEndingBalance(props.customerId).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(fp.flow(fp.get("payload.data"), fp.toPlainObject, fromJS))
          .distinctUntilChanged(isEqualData);

        const itemResponseStream = propsStream
          .map(fp.pick(["id", "customerId"]))
          .distinctUntilChanged(isEqualData)
          .switchMap(props =>
            props.id > 0 && props.customerId > 0
              ? getTransactionItem(props.customerId, props.id).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const listResponseStream = propsStream
          .map(fp.pick(["filter", "customerId"]))
          .distinctUntilChanged(isEqualData)
          .switchMap(props =>
            props.customerId > 0
              ? getTransactionList(
                  props.customerId,
                  props.filter,
                ).catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(
            itemResponseStream,
            listResponseStream,
            filterValuesStream,
            endingBalanceStream,
            (
              props,
              itemResponse,
              listResponse,
              filterValues,
              endingBalance,
            ) => ({
              ...props,
              filterValues,
              endingBalance,
              item: itemResponse.get("payload"),
              itemFetching: itemResponse.get("pending"),
              list: listResponse.get("payload"),
              listFetching: listResponse.get("pending"),
            }),
          );
      },
    ),
  ),
);

AdminFinanceCustomerTransactionsContainer.propTypes = {
  setLocationQuery: PropTypes.func,
  id: PropTypes.number,
  filterValues: PropTypes.object,
  itemFetching: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  listFetching: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  endingBalance: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

function AdminFinanceCustomerTransactionsContainer(props) {
  const { filterValues, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="customer_cod_balance"
      title={getLocalisationMessage("transactions", "Transactions")}
      appBarRightAction={
        isValidObjectId(filterValues.customer) ? (
          <FlexBox style={{ paddingTop: "8px" }}>
            <Button
              target="_blank"
              component="a"
              href={updateQuery(CREATE_COD_STATEMENT_HISTORY_URL, {
                id: filterValues.customer.id,
              })}
              style={{ color: props.theme.palette.appBarTextColor }}
            >
              {getLocalisationMessage(
                "download_full_statement",
                "Download Full Statement",
              )}
            </Button>
          </FlexBox>
        ) : null
      }
    >
      <CustomerTransactionsFilter
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        initialValues={filterValues}
        onChange={(values, dispatch, formProps) => {
          if (formProps.dirty) {
            props.setLocationQuery(
              fp.flow(
                fp.set("customer_id", values.customer.id),
                fp.set("from_date", formatDateToUrl(values.fromDate)),
                fp.set("to_date", formatDateToUrl(values.toDate)),
              ),
            );
          }
        }}
      />

      <br />

      {isValidObjectId(filterValues.customer) && (
        <CustomerTransactions
          item={props.item}
          itemFetching={props.itemFetching}
          list={props.list}
          listFetching={props.listFetching}
          endingBalance={props.endingBalance}
          selectedId={props.id}
          onItemSelect={id => props.setLocationQuery(fp.set("id", id))}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminFinanceCustomerTransactionsContainer);
