import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import Barchart from "../charts/Barchart";
import { getOrderCountByRegion } from "../../api/admin/AdminDashboardNewApi";
import { getValue } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import AdminDashboardFilterDialogWrapper from "../../wrappers/admin/dashboard/AdminDashboardFilterDialogWrapper";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderCountByRegionChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
};

function AdminOrderCountByRegionChartWrapper(props) {
  const [chartData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const orderRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [filterRefresh, setFilterRefresh] = useState(false);
  const [filter, setFilter] = useState({
    ...props.filter,
  });

  useEffect(() => {
    setFilter({ ...props.filter });
    setFilterRefresh(false);
  }, [props.filter]);

  useEffect(() => {
    setIsLoading(true);
    getOrderCountByRegion(filterRefresh ? filter : props.filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");

          setData({
            labels: barData.map(item => item.label),
            datasets: [
              {
                label: props.getLocalisationMessage("region", "Region"),
                data: barData.map(item => item.value),
                backgroundColor: barData.map(() => "#3F51B5"),
                hoverBackgroundColor: "#F50057",
              },
            ],
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData([]);
      });
  }, [props.filter, filter]);

  return (
    <Card
      style={{
        width: "100%",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
        paddingBottom: "26px",
      }}
      ref={orderRef}
    >
      <AdminDashboardFilterDialogWrapper
        open={open}
        onRequestClose={() => {
          setOpen(false);
          setFilterRefresh(false);
        }}
        initialValues={{
          jurisdiction: {
            id: filter.jurisdiction_id,
          },
          fromDateTime: safeParseDate(filter.from_date_time),
          toDateTime: safeParseDate(filter.to_date_time),
        }}
        onSubmit={v => {
          setFilter({
            jurisdiction_id: getValue(v, "jurisdiction.id"),
            from_date_time: formatDateTimeToUrl(v.fromDateTime),
            to_date_time: formatDateTimeToUrl(v.toDateTime),
          });
          setOpen(false);
          setFilterRefresh(true);
        }}
      />

      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,

            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_count_by_region",
              "Order count by province/district/branches",
            )}
          </FlexBox>
          <FlexBox>
            <CustomButton
              color={SECONDARY}
              fullWidth={true}
              startIcon={<FilterList />}
              onClick={() => {
                setOpen(true);
                setFilterRefresh(false);
              }}
            >
              {props.getLocalisationMessage("filter", "Filter")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      {isLoading && orderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(orderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}

      {!isLoading && (
        <Barchart
          width={450}
          height={400}
          data={chartData}
          tooltipFormat={{
            callbacks: {
              label(tooltipItem, data) {
                const datasetLabel = data.labels[tooltipItem.index];
                return `${datasetLabel}:  ${new Intl.NumberFormat(
                  "ru-RU",
                ).format(tooltipItem.yLabel)}`;
              },
            },
          }}
        />
      )}
    </Card>
  );
}

export default enhancer(AdminOrderCountByRegionChartWrapper);
