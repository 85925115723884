import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderExceptionsFilterForm from "../../components/orders-core/OrderExceptionsFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "700px",
      maxWidth: "700px",
    },
  }),
);

AdminOrderExceptionsFilterDialogWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,

  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderExceptionsFilterDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={true}
      title={props.getLocalisationMessage(
        "filter_exception",
        "Filter Exception",
      )}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <OrderExceptionsFilterForm
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        getSupplierPredictions={getSupplierPredictions}
        getCachedSupplier={getCachedSupplier}
        getDriverPredictions={getDriverPredictions}
        getCachedDriver={getCachedDriver}
        onDismiss={props.onRequestClose}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminOrderExceptionsFilterDialogWrapper);
