import React from "react";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormChipInput from "../form/FormChipInput";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "BankReconcileSettingsForm",
    enableReinitialize: true,
  }),
  withContext(
    {
      getCachedBank: PropTypes.func,
      getBankPredictions: PropTypes.func,
    },
    props => ({
      getCachedBank: props.getCachedBank,
      getBankPredictions: props.getBankPredictions,
    }),
  ),
  formValues({
    isParseKeyEnabled: "isParseKeyEnabled",
  }),
);

const getHeaderOptions = headers => headers.map((key, value) => value).toSet();

BankReconcileSettingsForm.propTypes = {
  initialValues: PropTypes.object,
  isParseKeyEnabled: PropTypes.bool,

  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function BankReconcileSettingsForm(props) {
  if (!props.initialValues) return <div />;

  const { getLocalisationMessage } = props;
  const headerOptions = getHeaderOptions(fromJS(props.initialValues.headers));
  const headerArray = fp.toArray(fromJS(props.initialValues.headers));

  return (
    <form>
      <Card>
        <CardContent>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true}>
              <FormSelectField
                name="merge_column_id"
                fullWidth={true}
                label={getLocalisationMessage(
                  "merge_column_id",
                  "Merge Column ID",
                )}
                options={headerOptions}
                formatOption={item => (
                  <span>{headerArray[fp.toFinite(item)][1]}</span>
                )}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormSelectField
                name="reference_column_id"
                fullWidth={true}
                label={getLocalisationMessage(
                  "reference_column_id",
                  "Reference Column ID",
                )}
                options={headerOptions}
                formatOption={item => (
                  <span>{headerArray[fp.toFinite(item)][1]}</span>
                )}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true}>
              <FormSelectField
                name="value_column_id"
                fullWidth={true}
                label={getLocalisationMessage(
                  "value_column_id",
                  "Value Column ID",
                )}
                options={headerOptions}
                formatOption={item => (
                  <span>{headerArray[fp.toFinite(item)][1]}</span>
                )}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormSelectField
                name="transaction_date_column_id"
                fullWidth={true}
                label={getLocalisationMessage(
                  "transaction_date_column_id",
                  "Transaction Date Column ID",
                )}
                options={headerOptions}
                formatOption={item => (
                  <span>{headerArray[fp.toFinite(item)][1]}</span>
                )}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true}>
              <FormChipInput
                name="include_values"
                fullWidth={true}
                addOnBlur={true}
                label={getLocalisationMessage(
                  "include_values",
                  "Include Values",
                )}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true}>
              <FormTextField
                type="number"
                fullWidth={true}
                name="allowed_difference"
                label={getLocalisationMessage(
                  "allowed_difference",
                  "Allowed Difference",
                )}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FlexBox flex={true} direction="column">
                {props.isParseKeyEnabled ? (
                  <FormTextField
                    fullWidth={true}
                    name="parse_key"
                    label={getLocalisationMessage("parse_key", "Parse Key")}
                  />
                ) : (
                  <div style={{ height: "40px" }} />
                )}

                <FormCheckbox
                  name="isParseKeyEnabled"
                  label={getLocalisationMessage(
                    "is_parsable_reference",
                    "Is Parsable Reference?",
                  )}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>
        <FlexBox justify="flex-end">
          <CardActions>
            <Button type="submit" onClick={props.handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </CardActions>
        </FlexBox>
      </Card>
    </form>
  );
}

export default enhancer(BankReconcileSettingsForm);
