import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { mapSchema, queryTypes } from "../../shared/helpers/ObjectMapper";

export const toOrderExceptionsFilter = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),

    from_date: queryTypes.date,
    to_date: queryTypes.date,
    driver_ids: queryTypes.IDSeq,
    supplier_ids: queryTypes.IDSeq,
    status: queryTypes.stringSeq,
  }),
);
