import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SAMPLE_ITEMS_SETTINGS_URL } from "../../constants/AdminPathConstants";
import { saveSampleItemSettings } from "../../api/shared/SampleItemsApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import SampleItemForm from "../../components/sample-items-core/SampleItemForm";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func.isRequired }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminSampleItemCreateContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  location: PropTypes.object,
};

function AdminSampleItemCreateContainer(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage("create_sample_item", "Create Sample Item")}
    >
      <SampleItemForm
        sampleItemsForSettings={true}
        onSubmit={values => {
          saveSampleItemSettings(toSnakeCase(values)).catch(
            ResponseError.throw,
          );
        }}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
          props.setLocation(SAMPLE_ITEMS_SETTINGS_URL);
        }}
        onSubmitFail={props.showErrorMessage}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSampleItemCreateContainer);
