import React from "react";
import { Map } from "immutable";
import { compose, getContext, withContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessages } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  getActivePlan,
  buyPricePlanItem,
  getCachedPricePlans,
  getCachedPriceAddOns,
} from "../../../api/shared/PricePlanApi";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import SubscriptionPlansWizard from "../../../components/subscription-core/SubscriptionPlansWizard";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  withContext(
    {
      getCachedPricePlans: PropTypes.func,
      getCachedPriceAddOns: PropTypes.func,
      buyPricePlanItem: PropTypes.func,
      getActivePlan: PropTypes.func,
    },
    () => ({
      getCachedPricePlans,
      getCachedPriceAddOns,
      buyPricePlanItem,
      getActivePlan,
    }),
  ),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminSubscriptionContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function AdminSubscriptionContainer(props) {
  const { i18n } = props;

  return (
    <AdminAppLayout
      slug="subscription"
      title={i18n.get("subscription", "Subscription")}
    >
      <SubscriptionPlansWizard />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSubscriptionContainer);
