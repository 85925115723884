import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import {
  validateEmail,
  validateString,
  validatePhoneNumber,
} from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import CourierContactTypes from "../../constants/CourierContactTypes";

const statusOptions = OrderedSet.of(ACTIVE, INACTIVE);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({ padding: { paddingTop: 10 } }),
  withContext(
    {
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  reduxForm({
    form: "SupplierContactsForm",
    enableReinitialize: true,
    validate: (values, props) => {
      const supplierId = fp.get(["supplier", "id"], values);

      return {
        contactRole: validateString(values.contactRole)
          ? props.getLocalisationMessage(
              "select_contact_role",
              "Select Contact Role",
            )
          : null,
        supplier:
          !supplierId &&
          props.getLocalisationMessage(
            "supplier_is_required",
            "Supplier is Required",
          ),
        phoneNumber: validatePhoneNumber(values.phoneNumber),
        email: validateEmail(values.email)
          ? props.getLocalisationMessage(
              "enter_valid_email",
              "Enter valid Email",
            )
          : null,
      };
    },
  }),
);

SupplierContactForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function SupplierContactForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormSupplierAutoComplete
                  name="supplier"
                  fullWidth={true}
                  hintText={getLocalisationMessage("supplier", "Supplier")}
                  label={getLocalisationMessage("supplier", "Supplier")}
                />

                <FormSelectField
                  name="contactRole"
                  fullWidth={true}
                  options={CourierContactTypes}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  label={getLocalisationMessage("contact_type", "Contact Type")}
                />

                <FormTextField
                  name="firstName"
                  fullWidth={true}
                  label={getLocalisationMessage("first_name", "First Name")}
                />

                <FormTextField
                  name="lastName"
                  fullWidth={true}
                  label={getLocalisationMessage("last_name", "Last Name")}
                />

                <FormTextField
                  name="phoneNumber"
                  fullWidth={true}
                  label={getLocalisationMessage("phone_number", "Phone Number")}
                />

                <FormTextField
                  name="email"
                  fullWidth={true}
                  label={getLocalisationMessage("email", "Email")}
                />

                <FormSelectField
                  name="status"
                  autoWidth={true}
                  fullWidth={true}
                  label={getLocalisationMessage("status", "Status")}
                  options={statusOptions}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <CardActions>
        <FlexBox justify="flex-end">
          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </CardActions>
    </form>
  );
}

export default enhancer(SupplierContactForm);
