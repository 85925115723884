import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const JOBS_ITEM_URL = `${API_ROOT_URL}/job/%s`;
const JOBS_LIST_URL = `${API_ROOT_URL}/admin/jobs`;
const JOBS_CREATE_URL = `${API_ROOT_URL}/admin/job`;
const JOB_ORDERS_URL = `${API_ROOT_URL}/admin/order/job_orders/%s`;
const REMOVE_JOB_ORDERS_URL = `${API_ROOT_URL}/admin/job/%s/remove_orders`;
const JOB_DRIVERS_SUGGESTION_URL = `${
  API_ROOT_URL
}/admin/job/%s/driver/suggestion/`;

export const getJob = id => api.getStream(sprintf(JOBS_ITEM_URL, id));
export const getJobOrders = id => api.getStream(sprintf(JOB_ORDERS_URL, id));
export const getJobs = (request: DataListFilter) =>
  api.getStream(JOBS_LIST_URL, { params: request.getDefinedValues() });

export const createJob = values => api.post(JOBS_CREATE_URL, { body: values });

export const removeJobOrders = (id, values) =>
  api.put(sprintf(REMOVE_JOB_ORDERS_URL, id), { body: values });

export const getSuggestedDrivers = (id, request: DataListFilter) =>
  api.getStream(sprintf(JOB_DRIVERS_SUGGESTION_URL, id), {
    params: request.getDefinedValues(),
  });
