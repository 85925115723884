import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export default class RenderInNode extends React.Component {
  static propTypes = {
    node: PropTypes.any,
    children: PropTypes.node,
  };

  componentDidMount() {
    this.renderChildren();
  }

  shouldComponentUpdate(nextProps) {
    const { node, children } = this.props;

    return nextProps.node !== node || nextProps.children !== children;
  }

  componentDidUpdate(prevProps) {
    const { node } = this.props;

    if (prevProps.node !== node) {
      this.unmount(prevProps);
    }

    this.renderChildren();
  }

  componentWillUnmount() {
    this.unmount();
  }

  unmount({ node } = this.props) {
    if (node) {
      ReactDOM.unmountComponentAtNode(node);
    }
  }

  renderChildren() {
    const { children, node } = this.props;

    if (node) {
      ReactDOM.unstable_renderSubtreeIntoContainer(this, children, node);
    }
  }

  render() {
    return null;
  }
}
