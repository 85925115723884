import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ADMIN_EMAIL_URL = `${API_ROOT_URL}/admin/emailconfigurations/`;
const DELETE_EMAIL = `${API_ROOT_URL}/admin/emailconfigurations/deleteAll`;
const ADMIN_EMAIL_PUT_URL = `${API_ROOT_URL}/admin/emailconfigurations/%s`;
const ACTIVE_EMAIL = `${API_ROOT_URL}/admin/emailconfigurations/last`;
const ADMIN_EMAIL_TEST_URL = `${
  API_ROOT_URL
}/admin/emailconfigurations/testConnection`;

export const getActiveEmail = () => api.getStream(ACTIVE_EMAIL);

export const createEmail = values =>
  api.post(ADMIN_EMAIL_URL, { body: values });
export const updateEmail = values =>
  api.put(sprintf(ADMIN_EMAIL_PUT_URL, values.id), { body: values });
export const testEmail = values =>
  api.post(ADMIN_EMAIL_TEST_URL, { body: values });

export const deleteEmail = () => api.delete(DELETE_EMAIL);
