import React from "react";
import { List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import Toggle from "../form/Toggle";
import { formatDate } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import DataList, { DataListColumn } from "../../components/data-list/DataList";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
);

HolidaySettingsList.propTypes = {
  list: PropTypes.instanceOf(List).isRequired,
  isLoading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  onSwitchRepeated: PropTypes.func,
  onDeleteHolidayClick: PropTypes.func,
  onCreateHolidayClick: PropTypes.func,
  onEditHolidayClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function HolidaySettingsList(props) {
  const { getLocalisationMessage } = props;
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.total}
      list={props.list}
      rowCount={props.list.size}
      overscanRowCount={5}
      rowGetter={options => props.list.get(options.index)}
      cardActionIcons={
        <div>
          <Tooltip title={getLocalisationMessage("add_holiday", "Add Holiday")}>
            <IconButton onClick={props.onCreateHolidayClick}>
              <Add />
            </IconButton>
          </Tooltip>
        </div>
      }
    >
      <DataListColumn
        width={94}
        label={getLocalisationMessage("holiday", "Holiday")}
        dataKey="holiday_name"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <Link to={() => props.onEditHolidayClick(row.rowIndex)}>
            {row.cellData.get("holiday_name")}
          </Link>
        )}
      />

      <DataListColumn
        width={94}
        label={getLocalisationMessage("start_date", "Start Date")}
        dataKey="start_date"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => formatDate(row.cellData.get("start_date"))}
      />

      <DataListColumn
        width={94}
        label={getLocalisationMessage("end_date", "End Date")}
        dataKey="end_date"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => formatDate(row.cellData.get("end_date"))}
      />

      <DataListColumn
        width={94}
        label={getLocalisationMessage("recurring", "Recurring")}
        dataKey="repeated"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <Toggle
            style={{ width: "auto" }}
            value={row.cellData.get("repeated")}
            onChange={repeated =>
              props.onSwitchRepeated({
                ...row.rowData.toJS(),
                repeated,
              })
            }
          />
        )}
      />

      <DataListColumn
        width={94}
        label={getLocalisationMessage("actions", "Actions")}
        dataKey="id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <Button
            onClick={() => props.onDeleteHolidayClick(row.cellData.get("id"))}
          >
            {getLocalisationMessage("remove", "Remove")}
          </Button>
        )}
      />
    </DataList>
  );
}

export default enhancer(HolidaySettingsList);
