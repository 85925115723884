import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  withContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../../helpers/DataUtils";
import ResponseError from "../../../helpers/ResponseError";
import { getMarketplaceId } from "../../../reducers/MarketplaceReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../../api/admin/AdminServiceTypesApi";
import {
  getMobileSettings,
  getMarketplaceSettings,
  updateMarketplaceSettings,
} from "../../../api/shared/MarketplaceApi";
import PageLoading from "../../../components/ui-core/PageLoading";
import MarketplaceSettingsForm from "../../../components/marketplaces-core/MarketplaceSettingsForm";

const enhancer = compose(
  connect(
    state => ({
      marketplaceId: getMarketplaceId(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withContext(
    {
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    () => ({
      getCachedServiceType,
      getServiceTypePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const {
      stream: onRequestRefreshStream,
      handler: onRequestRefresh,
    } = createEventHandler();

    const settingsFieldsStream = propsStream
      .first()
      .switchMap(() =>
        getMobileSettings().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], List()),
            }),
        ),
      );

    const marketplaceSettingsStream = propsStream
      .first()
      .switchMap(props =>
        getMarketplaceSettings(props.marketplaceId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );

    return propsStream
      .combineLatest(
        settingsFieldsStream,
        marketplaceSettingsStream,
        (props, settings, marketplaceSettings) => ({
          ...props,
          onRequestRefresh,
          settings: settings.get("payload"),
          isSettingsLoading: settings.get("pending"),
          marketplaceSettings: marketplaceSettings.get("payload"),
          isMarketplaceSettingsLoading: marketplaceSettings.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminMarketplaceDetailsMobileSettingsContainer.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  settings: PropTypes.instanceOf(List),
  marketplaceSettings: PropTypes.instanceOf(Map),
  isMarketplaceSettingsLoading: PropTypes.bool,
  isSettingsLoading: PropTypes.bool,
  marketplaceId: PropTypes.number,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
};

function AdminMarketplaceDetailsMobileSettingsContainer(props) {
  return (
    <div>
      <PageLoading
        isLoading={
          props.isMarketplaceSettingsLoading || props.isSettingsLoading
        }
      />

      <MarketplaceSettingsForm
        item={props.marketplaceSettings}
        settings={props.settings}
        marketplaceId={props.marketplaceId}
        onSubmit={settings =>
          updateMarketplaceSettings({
            marketplace_id: props.marketplaceId,
            settings,
          }).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      />
    </div>
  );
}

export default enhancer(AdminMarketplaceDetailsMobileSettingsContainer);
