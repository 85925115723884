import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import { Avatar, ListSubheader } from "@material-ui/core";
import moneyIcon from "./assets/money.svg";
import RadarBriefItem from "./RadarBriefItem";
import {
  formatLocalisedPaymentType,
  mapChargeItems,
} from "../../helpers/OrderHelper";
import PriceWrapper from "../ui-core/PriceWrapper";
import {
  COD,
  DISCOUNT,
  SERVICE,
  SERVICE_CUSTOM,
  TOTAL,
} from "../../constants/OrderChargeItemTypes";

const getPaymentStatus = (paid, refund, getLocalisationMessage) =>
  paid
    ? getLocalisationMessage("paid", "Paid")
    : refund
    ? getLocalisationMessage("refunded", "Refunded")
    : getLocalisationMessage("not_paid", "Not Paid");

const enhancer = compose(
  useSheet({
    details: {
      color: "#fff",
    },
    title: {
      color: "white",
      fontSize: "16px",
      textTransform: "uppercase",
      paddingLeft: 0,
      lineHeight: "19px",
      marginBottom: "10px",
    },
    name: {
      lineHeight: "28px",
      fontSize: "24px",
      fontWeight: 500,
    },
    phone: {
      lineHeight: "16px",
      fontSize: "14px",
    },
    total: {
      fontWeight: "bold",
      marginRight: "15px",
      marginTop: "10px",
      fontSize: "18px",
      textTransform: "uppercase",
    },
    bottomSpacing: {
      marginBottom: "10px",
    },
    paymentPrices: {
      "& > div span span + span": {
        display: "none",
      },
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderItemPaymentInfo.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function OrderItemPaymentInfo(props) {
  const { classes, order, getLocalisationMessage } = props;

  if (!order) return null;

  const chargeItems = mapChargeItems(order.get("charge_items"));
  const currencyCode = order.getIn(["currency", "code"]);

  return (
    <FlexBox className={classes.details}>
      <FlexBox flex={true} direction="column">
        <ListSubheader className={classes.title}>
          {getLocalisationMessage("payment_info", "Payment info")}
        </ListSubheader>
        <FlexBox className={classes.bottomSpacing}>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("payment_method")}
              text={formatLocalisedPaymentType(
                order.get("payment_type"),
                getLocalisationMessage,
              )}
              avatar={
                <Avatar
                  backgroundColor="transparent"
                  size={15}
                  src={moneyIcon}
                />
              }
            />
          </FlexBox>
          <FlexBox flex={true}>
            <RadarBriefItem
              textColor="#ffc107"
              title={getLocalisationMessage("service_charges_paid_by")}
              text={order.getIn([
                order.get("payer") === "sender"
                  ? "sender_data"
                  : "recipient_data",
                "name",
              ])}
              avatar={
                <Avatar
                  backgroundColor="transparent"
                  size={15}
                  src={moneyIcon}
                />
              }
            />
          </FlexBox>
        </FlexBox>

        <FlexBox className={classes.paymentPrices}>
          <FlexBox style={{ width: "70%" }}>
            <FlexBox direction="column">
              <div>
                {getLocalisationMessage(
                  "public_service_fee",
                  "Public Service Fee",
                )}
              </div>

              <div>
                {getLocalisationMessage(
                  "actual_service_fee",
                  "Actual Service Fee",
                )}
              </div>

              <div>
                {getLocalisationMessage("cash_on_delivery", "Cash On Delivery")}
              </div>

              {Boolean(order.get("promo_code")) && (
                <div>{order.get("promo_code")}</div>
              )}
            </FlexBox>

            <FlexBox
              flex={true}
              direction="column"
              style={{ paddingLeft: "30px", fontWeight: "bold" }}
            >
              <PriceWrapper
                code={currencyCode}
                paid={chargeItems.getIn([SERVICE, "paid"])}
                refund={chargeItems.getIn([SERVICE, "refund"])}
                price={chargeItems.getIn([SERVICE, "charge"], 0)}
              />

              <PriceWrapper
                code={currencyCode}
                paid={chargeItems.getIn([SERVICE_CUSTOM, "paid"])}
                refund={chargeItems.getIn([SERVICE_CUSTOM, "refund"])}
                price={chargeItems.getIn([SERVICE_CUSTOM, "charge"], 0)}
              />

              <PriceWrapper
                code={currencyCode}
                paid={chargeItems.getIn([COD, "paid"])}
                refund={chargeItems.getIn([COD, "refund"])}
                price={chargeItems.getIn([COD, "charge"], 0)}
              />

              {Boolean(order.get("promo_code")) && (
                <PriceWrapper
                  code={currencyCode}
                  price={chargeItems.getIn([DISCOUNT, "charge"])}
                />
              )}
            </FlexBox>
          </FlexBox>

          <FlexBox
            flex={true}
            direction="column"
            style={{ paddingLeft: "30px" }}
          >
            <FlexBox flex={true}>
              {chargeItems.getIn([SERVICE, "charge"], 0) > 0 && (
                <span className={classes.paymentStatus}>
                  {getPaymentStatus(
                    chargeItems.getIn([SERVICE, "paid"]),
                    chargeItems.getIn([SERVICE, "refund"]),
                    getLocalisationMessage,
                  )}
                </span>
              )}
            </FlexBox>
            <FlexBox flex={true}>
              {chargeItems.getIn([SERVICE_CUSTOM, "charge"], 0) > 0 && (
                <span className={classes.paymentStatus}>
                  {getPaymentStatus(
                    chargeItems.getIn([SERVICE_CUSTOM, "paid"]),
                    chargeItems.getIn([SERVICE_CUSTOM, "refund"]),
                    getLocalisationMessage,
                  )}
                </span>
              )}
            </FlexBox>
            <FlexBox flex={true}>
              {chargeItems.getIn([COD, "charge"], 0) > 0 && (
                <span className={classes.paymentStatus}>
                  {getPaymentStatus(
                    chargeItems.getIn([COD, "paid"]),
                    chargeItems.getIn([COD, "refund"]),
                    getLocalisationMessage,
                  )}
                </span>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox flex={true}>
          <span className={classes.total}>
            {getLocalisationMessage("total", "Total")}:
          </span>
          <span className={classes.total}>
            <PriceWrapper
              code={currencyCode}
              price={chargeItems.getIn([TOTAL, "charge"])}
            />
          </span>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderItemPaymentInfo);
