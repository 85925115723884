import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SMS_PROVIDER_LIST_URL = `${API_ROOT_URL}/admin/sms_providers`;
const SMS_PROVIDER_URL = `${API_ROOT_URL}/admin/sms_provider`;
const SMS_PROVIDER_FIELDS_URL = `${API_ROOT_URL}/admin/sms_provider_fields`;

export const getSMSProviderList = () => api.getStream(SMS_PROVIDER_LIST_URL);
export const getSMSProvider = () => api.getStream(SMS_PROVIDER_URL);

export const saveSMSProvider = body =>
  api.post(SMS_PROVIDER_URL, {
    body,
  });

export const getSMSProviderFields = params =>
  api.getStream(SMS_PROVIDER_FIELDS_URL, { params });
