import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ACTIVE } from "../../constants/OverallStatus";
import { createCashier } from "../../api/admin/AdminFinanceApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CashierForm from "../../components/finance-core/CashierForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  useSheet({
    paper: { minWidth: "400px", maxWidth: "400px" },
  }),
);

AdminCashierCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCashierCreateDialogWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage("add_cashier", "Add Cashier")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox direction="column">
        <CashierForm
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          onDismiss={props.onRequestClose}
          initialValues={{ status: ACTIVE }}
          onSubmit={values =>
            createCashier(toSnakeCase(values)).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminCashierCreateDialogWrapper);
