import React from "react";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { ListSubheader, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import UserImagePlaceholder from "../../assets/placeholder.png";
import UploadPublicImageDialog from "../upload-photo-dialog/UploadPublicImageDialog";

const getValues = formValueSelector("SampleItemForm");

const enhancer = compose(
  reduxForm({
    enableReinitialize: true,
    form: "SampleItemForm",
  }),
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      values: getValues(state, "image", "imageId"),
      getLocalisationMessage,
    };
  }),
  useSheet({
    photo: {
      maxWidth: "100%",
      maxHeight: "200px",
      objectFit: "contain",
    },
    file: {
      width: "100%",
      display: "block",
      textAlign: "left",
      paddingLeft: "20px",
    },
  }),
  mapPropsStream((prop$) => {
    const {
      handler: onShowSelectImageDialog,
      stream: onShowSelectImageDialogStream,
    } = createEventHandler();

    return prop$.combineLatest(
      onShowSelectImageDialogStream.startWith(false),
      (props, showSelectImageDialog) => ({
        ...props,
        showSelectImageDialog,
        onShowSelectImageDialog,
      }),
    );
  }),
);

SampleItemImage.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  header: PropTypes.string,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  showSelectImageDialog: PropTypes.bool,
  onShowSelectImageDialog: PropTypes.func,
};

function SampleItemImage(props) {
  const { classes, getLocalisationMessage, values } = props;

  return (
    <div>
      <UploadPublicImageDialog
        open={props.showSelectImageDialog}
        onRequestClose={() => props.onShowSelectImageDialog(false)}
        initialValues={{
          photoId: values.imageId,
          photo: values.image,
        }}
        onSubmit={(response) => {
          props.change("imageId", response.photoId);
          props.change("image", response.photo);
          props.onShowSelectImageDialog(false);
        }}
      />

      <FlexBox gutter={8} flex={true} direction="column">
        <FlexBox>
          <ListSubheader style={{ textAlign: "center" }}>
            {`${getLocalisationMessage(
              "sample_item_image",
              "Sample Item Image",
            )} *`}
          </ListSubheader>
        </FlexBox>

        <FlexBox justify="center" className={classes.imageContainer}>
          {values.image && (
            <img
              className={classes.photo}
              alt="Sample Item"
              src={values.image}
            />
          )}
          {!values.image && (
            <img
              className={classes.photo}
              alt="Sample Item"
              src={UserImagePlaceholder}
            />
          )}
        </FlexBox>
        <FlexBox>
          <FlexBox gutter={8} flex={true} direction="column">
            <FlexBox>
              <FlexBox gutter={8} flex={true}>
                {values.image && (
                  <FlexBox flex={true} justify="center">
                    <Button onClick={() => props.onShowSelectImageDialog(true)}>
                      {getLocalisationMessage("change_photo", "Change Photo")}
                    </Button>
                  </FlexBox>
                )}
                {values.image && (
                  <FlexBox flex={true} justify="center">
                    <Button
                      onClick={() => {
                        props.change("imageId", null);
                        props.change("image", null);
                      }}
                    >
                      {getLocalisationMessage("remove_photo", "Remove Photo")}
                    </Button>
                  </FlexBox>
                )}
                {!values.image && (
                  <FlexBox flex={true} justify="center">
                    <Button onClick={() => props.onShowSelectImageDialog(true)}>
                      {`${getLocalisationMessage(
                        "select_photo",
                        "Select Photo",
                      )} *`}
                    </Button>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </div>
  );
}

export default enhancer(SampleItemImage);
