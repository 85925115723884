import React from "react";
import { OrderedSet } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormTimeField from "../form/FormTimeField";
import FormSelectField from "../form/FormSelectField";
import FormCitySelectFieldV2 from "../form/FormCitySelectFieldV2";
import FormCompanyAutoComplete from "../form/FormCompanyAutoComplete";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormTimeSlotAutoComplete from "../form/FormTimeSlotAutoComplete";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FormPostcodeIndexAutoComplete from "../form/FormPostcodeIndexAutoComplete";
import FlexBox, { ALIGN_END } from "../ui-core/FlexBox";
import {
  createDateValidator,
  createFiniteValidator,
  createNotNilValidator,
  createNotFalsyValidator,
  createObjectIdValidator,
  createObjectIdValidatorForNeighborhood,
} from "../../helpers/FormUtils";
import {
  formatText,
  parseFloat,
  parseInteger,
} from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ORDER_RULE,
  ZONING_RULE,
  PICKUP_JOB_AUTOMATION,
} from "../../constants/RuleType";
import DayOfWeek from "../../constants/DayOfWeek";
import AddressType from "../../constants/AddressType";
import OrderSizeCodes from "../../constants/OrderSizeCodes";
import OrderPayerTypes from "../../constants/OrderPayerTypes";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import TriggerConstants from "../../constants/TriggerConstants";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import {
  COD,
  COST,
  LIMIT,
  PRICE,
  RATING,
  CITY_TO,
  COMPANY,
  TRIGGER,
  CUSTOMER,
  MERCHANT,
  CITY_FROM,
  IS_RETURN,
  RTO_PRICE,
  SELLER_ID,
  COUNTRY_TO,
  ITEM_VALUE,
  ORDER_TYPE,
  DAY_OF_WEEK,
  PARCEL_SIZE,
  SELLER_NAME,
  SENDER_NAME,
  COUNTRY_FROM,
  COURIER_TYPE,
  CREATED_DATE,
  CREATED_TIME,
  ORDER_STATUS,
  PAYMENT_METHOD,
  SENDER_ADDRESS,
  HAS_FETCH_ITEMS,
  NEIGHBORHOOD_TO,
  PAYMENT_DONE_BY,
  PICKUP_WAREHOUSE,
  CURRENT_WAREHOUSE,
  NEIGHBORHOOD_FROM,
  RECIPIENT_ADDRESS,
  TIME_SLOT_PICK_UP,
  TIME_SLOT_DROP_OFF,
  SENDER_ADDRESS_TYPE,
  DESTINATION_WAREHOUSE,
  RECIPIENT_ADDRESS_TYPE,
} from "../../constants/RuleConditionField";
import RuleComparationOperator from "../../constants/RuleComparationOperator";
import RuleConditionOrderTypes from "../../constants/RuleConditionOrderTypes";

const valueSelector = formValueSelector("SlaRuleTree");

const orderRuleFields = OrderedSet.of(
  CITY_FROM,
  CITY_TO,
  COD,
  COMPANY,
  COUNTRY_FROM,
  COUNTRY_TO,
  COURIER_TYPE,
  CREATED_DATE,
  CREATED_TIME,
  CURRENT_WAREHOUSE,
  CUSTOMER,
  DAY_OF_WEEK,
  DESTINATION_WAREHOUSE,
  NEIGHBORHOOD_FROM,
  NEIGHBORHOOD_TO,
  PAYMENT_DONE_BY,
  PAYMENT_METHOD,
  PARCEL_SIZE,
  PICKUP_WAREHOUSE,
  PRICE,
  TIME_SLOT_DROP_OFF,
  TIME_SLOT_PICK_UP,
  MERCHANT,
  HAS_FETCH_ITEMS,
  ORDER_TYPE,
  SENDER_ADDRESS_TYPE,
  RECIPIENT_ADDRESS_TYPE,
  TRIGGER,
  SELLER_ID,
  SELLER_NAME,
  SENDER_NAME,
  SENDER_ADDRESS,
  ITEM_VALUE,
  RECIPIENT_ADDRESS,
  RTO_PRICE,
  IS_RETURN,
);

const supplierRuleFields = OrderedSet.of(COURIER_TYPE, ORDER_STATUS);

const trueFalseOptions = OrderedSet.of(true, false);
const formatTrueFalseOption = (x, getLocalisationMessage) =>
  x === true
    ? getLocalisationMessage("yes", "Yes")
    : getLocalisationMessage("no", "No");

const enhancer = compose(
  connect((state, props) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    ruleType: valueSelector(state, "ruleType"),
    field: valueSelector(state, `${props.name}.field`),
  })),
);

SlaRuleTreeLeaf.propTypes = {
  field: PropTypes.string,
  ruleType: PropTypes.string,
  name: PropTypes.string.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SlaRuleTreeLeaf(props) {
  const fieldName = `${props.name}.${props.field}-value`;
  const extraFieldName = `${props.name}.${props.field}-extra-value`;
  const { getLocalisationMessage } = props;

  return (
    <FlexBox gutter={8} align={ALIGN_END}>
      <FlexBox align="center">
        <FormSelectField
          maxHeight={320}
          hintText={getLocalisationMessage("field", "Field")}
          formatOption={x => getLocalisationMessage(x) || formatText(x)}
          name={`${props.name}.field`}
          validate={createNotFalsyValidator(
            getLocalisationMessage("select_field", "Select Field"),
          )}
          options={
            props.ruleType === ORDER_RULE ||
            props.ruleType === PICKUP_JOB_AUTOMATION ||
            props.ruleType === ZONING_RULE
              ? orderRuleFields
              : supplierRuleFields
          }
        />
      </FlexBox>

      {Boolean(props.field) && (
        <FlexBox align="center">
          <FormSelectField
            maxHeight={320}
            hintText={getLocalisationMessage("compare_by", "Compare By")}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            options={RuleComparationOperator}
            name={`${props.name}.comparationOperator`}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_comparator", "Select Comparator"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(props.field === CITY_FROM || props.field === CITY_TO) && (
        <FlexBox align="center">
          <FormCitySelectFieldV2
            maxHeight={320}
            hintText={getLocalisationMessage("city", "City")}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_city", "Select City"),
            )}
            name={fieldName}
          />
        </FlexBox>
      )}

      {Boolean(props.field === COUNTRY_FROM || props.field === COUNTRY_TO) && (
        <FlexBox align="center">
          <FormCountryAutoComplete
            hintText={getLocalisationMessage("country", "Country")}
            name={fieldName}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_country", "Select Country"),
            )}
          />
        </FlexBox>
      )}

      {props.field === COMPANY && (
        <FlexBox align="center">
          <FormCompanyAutoComplete
            hintText={getLocalisationMessage("company", "Company")}
            name={fieldName}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_company", "Select Company"),
            )}
          />
        </FlexBox>
      )}

      {props.field === CUSTOMER && (
        <FlexBox align="center">
          <FormCustomerAutoComplete
            hintText={getLocalisationMessage("customer", "Customer")}
            name={fieldName}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_customer", "Select Customer"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === PICKUP_WAREHOUSE ||
          props.field === CURRENT_WAREHOUSE ||
          props.field === DESTINATION_WAREHOUSE,
      ) && (
        <FlexBox align="center">
          <FormWarehouseAutoComplete
            hintText={getLocalisationMessage("warehouse", "Warehouse")}
            name={fieldName}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_warehouse", "Select Warehouse"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === NEIGHBORHOOD_FROM || props.field === NEIGHBORHOOD_TO,
      ) && (
        <FlexBox align="center">
          <FormPostcodeIndexAutoComplete
            includeUnknownNeighborhood={true}
            hintText={getLocalisationMessage("neighborhood", "Neighborhood")}
            name={fieldName}
            validate={createObjectIdValidatorForNeighborhood(
              getLocalisationMessage(
                "select_neighborhood",
                "Select Neighborhood",
              ),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === TIME_SLOT_PICK_UP || props.field === TIME_SLOT_DROP_OFF,
      ) && (
        <FlexBox align="center">
          <FormTimeSlotAutoComplete
            hintText={getLocalisationMessage("time_slot", "Time Slot")}
            name={fieldName}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_time_slot", "Select Time Slot"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === COD ||
          props.field === COST ||
          props.field === PRICE ||
          props.field === RTO_PRICE ||
          props.field === RATING,
      ) && (
        <FlexBox align="center">
          <FormTextField
            parseOnBlur={parseFloat}
            hintText={getLocalisationMessage(
              "eg_05_10_365",
              "eg: 0.5, 10, 365",
            )}
            name={fieldName}
            validate={createFiniteValidator(
              getLocalisationMessage("value", "Value"),
            )}
          />
        </FlexBox>
      )}

      {props.field === LIMIT && (
        <FlexBox align="center">
          <FormTextField
            parseOnBlur={parseInteger}
            hintText={getLocalisationMessage("eg_10_50_100", "eg: 10, 50, 100")}
            validate={createFiniteValidator(
              getLocalisationMessage("add_value", "Add Value"),
            )}
            name={fieldName}
          />
        </FlexBox>
      )}

      {props.field === ITEM_VALUE && (
        <FlexBox align="center">
          <FormTextField
            parseOnBlur={parseInteger}
            hintText={getLocalisationMessage("eg_10_50_100", "eg: 10, 50, 100")}
            validate={createFiniteValidator(
              getLocalisationMessage("add_value", "Add Value"),
            )}
            name={fieldName}
          />
        </FlexBox>
      )}

      {props.field === CREATED_DATE && (
        <FlexBox align="center">
          <FormDateField
            hintText={getLocalisationMessage("created_time", "Created Time")}
            name={fieldName}
            validate={createDateValidator(
              getLocalisationMessage(
                "select_created_time",
                "Select Created Time",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === CREATED_TIME && (
        <FlexBox align="center">
          <FormTimeField
            hintText={getLocalisationMessage("created_time", "Created Time")}
            name={fieldName}
            validate={createDateValidator(
              getLocalisationMessage(
                "select_created_time",
                "Select Created Time",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === PAYMENT_DONE_BY && (
        <FlexBox align="center">
          <FormSelectField
            hintText={getLocalisationMessage("payer", "Payer")}
            options={OrderPayerTypes}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            name={fieldName}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_payer", "Select Payer"),
            )}
          />
        </FlexBox>
      )}

      {props.field === DAY_OF_WEEK && (
        <FlexBox align="center">
          <FormSelectField
            hintText={getLocalisationMessage("day_of_week", "Day Of Week")}
            options={DayOfWeek}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            name={fieldName}
            validate={createNotFalsyValidator(
              getLocalisationMessage(
                "select_day_of_week",
                "Select Day Of Week",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === ORDER_STATUS && (
        <FlexBox align="center">
          <FlexBox align="center">
            <FormSelectField
              hintText={getLocalisationMessage("status", "Status")}
              options={OrderStatusCodes}
              formatOption={x =>
                formatOrderStatusCodeForLocalisation(x, getLocalisationMessage)
              }
              name={fieldName}
              validate={createNotFalsyValidator(
                getLocalisationMessage("select_status", "Select Status"),
              )}
            />
          </FlexBox>
          <FlexBox align="center" style={{ marginLeft: "8px" }}>
            <FormTextField
              name={extraFieldName}
              parseOnBlur={parseInteger}
              hintText={getLocalisationMessage(
                "eg_10_50_100",
                "eg: 10, 50, 100",
              )}
              validate={createFiniteValidator(
                getLocalisationMessage("add_value", "Add Value"),
              )}
            />
          </FlexBox>
        </FlexBox>
      )}

      {props.field === TRIGGER && (
        <FlexBox align="center">
          <FormSelectField
            hintText={getLocalisationMessage("trigger", "Trigger")}
            options={TriggerConstants}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            name={fieldName}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_trigger", "Select Trigger"),
            )}
          />
        </FlexBox>
      )}

      {props.field === PAYMENT_METHOD && (
        <FlexBox align="center">
          <FormSelectField
            hintText={getLocalisationMessage(
              "payment_method",
              "Payment Method",
            )}
            options={OrderPaymentTypes}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            name={fieldName}
            validate={createNotFalsyValidator(
              getLocalisationMessage(
                "select_payment_method",
                "Select Payment Method",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === PARCEL_SIZE && (
        <FlexBox align="center">
          <FormSelectField
            hintText={getLocalisationMessage("parcel_size", "Parcel Size")}
            options={OrderSizeCodes}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            name={fieldName}
            validate={createNotFalsyValidator(
              getLocalisationMessage(
                "select_parcel_size",
                "Select Parcel Size",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === COURIER_TYPE && (
        <FlexBox align="center">
          <FormCourierTypeSelectField
            hintText={getLocalisationMessage("courier", "Courier")}
            name={fieldName}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_courier", "Select Courier"),
            )}
          />
        </FlexBox>
      )}

      {props.field === ORDER_TYPE && (
        <FlexBox align="center">
          <FormSelectField
            name={fieldName}
            hintText={getLocalisationMessage("order_type", "Order Type")}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            options={RuleConditionOrderTypes}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_order_type", "Select Order Type"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === MERCHANT ||
          props.field === HAS_FETCH_ITEMS ||
          props.field === IS_RETURN,
      ) && (
        <FlexBox align="center">
          <FormSelectField
            name={fieldName}
            hintText={getLocalisationMessage("value", "Value")}
            options={trueFalseOptions}
            formatOption={x => formatTrueFalseOption(x, getLocalisationMessage)}
            validate={createNotNilValidator(
              getLocalisationMessage("select_value", "Select Value"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === SENDER_ADDRESS_TYPE ||
          props.field === RECIPIENT_ADDRESS_TYPE,
      ) && (
        <FlexBox align="center">
          <FormSelectField
            name={fieldName}
            hintText={getLocalisationMessage("value", "Value")}
            options={AddressType}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            validate={createNotNilValidator(
              getLocalisationMessage("select_value", "Select Value"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === SELLER_NAME ||
          props.field === SELLER_ID ||
          props.field === SENDER_ADDRESS ||
          props.field === SENDER_NAME ||
          props.field === RECIPIENT_ADDRESS,
      ) && (
        <FlexBox align="center">
          <FormTextField
            name={fieldName}
            hintText={getLocalisationMessage("value", "Value")}
            validate={createNotNilValidator(
              getLocalisationMessage("value", "Value"),
            )}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(SlaRuleTreeLeaf);
