import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITable, { LINK, RENDER } from "../../components/orders-core/MUITable";
import _ from "lodash";
import {
  SAMPLE_ORDER_ITEM_URL,
  SIMPLE_POST_CREATE_URL,
} from "../../constants/AdminPathConstants";
import { Add, Refresh, ReportProblemOutlined } from "@material-ui/icons";
import { getSampleOrderList } from "../../api/admin/AdminSimpleOrderApi";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import FlexBox from "../../components/ui-core/FlexBox";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import { formatText } from "../../helpers/FormatUtils";
import Typography from "@material-ui/core/Typography";
import fp from "lodash/fp";
import SimpleOrderCreateProgressDialog from "../../components/orders-core/SimpleOrderCreateProgressDialog";
import { IconButton, makeStyles } from "@material-ui/core";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      showCreateSampleOrder: hasUserPermission(
        state,
        "CAN_CREATE_SIMPLE_ORDER",
      ),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

AdminSimpleOrderList.propTypes = {
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  location: PropTypes.object,
  showCreateSampleOrder: PropTypes.bool,
};

function AdminSimpleOrderList(props) {
  const { getLocalisationMessage, showCreateSampleOrder } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [batchId, setBatch] = useState(
    fp.toFinite(props.location.query.batch_id),
  );

  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);
    getSampleOrderList(page, size)
      .then(res => {
        setIsLoading(false);
        setList(_.get(res, "data.list"));
        setTotal(_.get(res, "data.total"));
      })
      .catch(error => {
        setIsLoading(false);
        props.showErrorMessage(error);
      });
  }, [page, size, refresh]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <AdminAppLayout title=" ">
      <SimpleOrderCreateProgressDialog
        batchId={batchId}
        open={Boolean(batchId)}
        onSubmit={() => {
          if (props.location.query.batch_id) {
            props.setLocationQuery(fp.unset("batch_id"));
          }
          setBatch(null);
          setRefresh(prev => !prev);
        }}
        showErrorMessage={props.showErrorMessage}
        showSuccessMessage={props.showSuccessMessage}
      />
      <MUITable
        isLoading={isLoading}
        title={getLocalisationMessage("created_orders", "Created Orders")}
        headerActions={
          <FlexBox align="center" style={{ gap: 16, padding: "6px 10px" }}>
            {showCreateSampleOrder && (
              <CustomButton
                onClick={() => props.setLocation(SIMPLE_POST_CREATE_URL)}
                startIcon={<Add />}
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px" }}
              >
                {getLocalisationMessage(
                  "create_new_simple_post",
                  "Create New Simple Post",
                )}
              </CustomButton>
            )}

            <IconButton
              className={isLoading && classes.refresh}
              tooltip={getLocalisationMessage("refresh", "Refresh")}
              onClick={() => setRefresh(prev => !prev)}
            >
              <Refresh />
            </IconButton>
          </FlexBox>
        }
        list={list}
        total={total}
        page={page}
        rowsPerPage={size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        columns={[
          {
            type: LINK,
            name: "id",
            label: getLocalisationMessage("id"),
            link: SAMPLE_ORDER_ITEM_URL,
            align: "center",
          },
          {
            type: RENDER,
            name: "uploaded_by",
            render: row => _.get(row, `uploaded_by`, ""),
            label: getLocalisationMessage("uploaded_by", "Upload By"),
          },
          {
            type: RENDER,
            name: "created_date",
            label: getLocalisationMessage("created_date"),
            render: row => (
              <DateTimeCell date={_.get(row, `created_date`, "")} />
            ),
          },
          {
            type: RENDER,
            name: "number_of_orders",
            label: getLocalisationMessage(
              "number_of_orders",
              "Number of orders",
            ),
            render: row =>
              _.get(row, "total_failed", "") ? (
                <MultiLineCell
                  firstLine={`${getLocalisationMessage(
                    "total_orders",
                    "Total Orders",
                  )}: ${_.get(row, "total", "")}`}
                  secondLine={
                    <FlexBox>
                      <ReportProblemOutlined style={{ color: "#EB5757" }} />
                      <Typography
                        style={{ color: "#EB5757" }}
                      >{`${getLocalisationMessage("failed", "Failed")}: ${_.get(
                        row,
                        "total_failed",
                        "",
                      )}/${_.get(row, "total", "")}`}</Typography>
                    </FlexBox>
                  }
                />
              ) : (
                _.get(row, "total", "")
              ),
          },
          {
            type: RENDER,
            name: "status",
            render: row =>
              formatText(
                getLocalisationMessage(
                  _.get(row, `status`, ""),
                  _.get(row, `status`, ""),
                ),
              ),
            label: getLocalisationMessage("status", "status"),
          },
        ]}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSimpleOrderList);
