import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DRIVER_ORDER_RADAR_URL } from "../../constants/AdminPathConstants";
import RadarSuggestedDriverFilterTabs from "./RadarSuggestedDriverFilterTabs";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const batchGetAlertListCount = filters =>
      Observable.from(
        filters
          .map(({ orderId, filter, getRadarOrderSuggestedDrivers }, key) =>
            getRadarOrderSuggestedDrivers(orderId, filter)
              .catch(error => Observable.of({ error }))
              .map(fp.get("payload.data"))
              .takeLast(1)
              .delay(1000)
              .map(value => ({ key, value: value && value.length })),
          )
          .toArray(),
      )
        .concatAll()
        .scan((acc, response) => acc.set(response.key, response.value), Map())
        .startWith(Map());

    return propsStream.combineLatest(props => ({
      ...props,
      batchGetAlertListCount,
    }));
  }),
);

RadarSuggestedDriverTabs.propTypes = {
  onFilterClick: PropTypes.func,
  batchGetAlertListCount: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  onListRequestRefresh: PropTypes.func,
  getRadarOrderSuggestedDrivers: PropTypes.func,
  orderItem: PropTypes.number.isRequired,
};

function RadarSuggestedDriverTabs(props) {
  return (
    <FlexBox flex={true}>
      <RadarSuggestedDriverFilterTabs
        onFilterClick={props.onFilterClick}
        basePathname={DRIVER_ORDER_RADAR_URL}
        filter={props.filter}
        orderItem={props.orderItem}
        batchGetAlertListCount={props.batchGetAlertListCount}
        onListRequestRefresh={props.onListRequestRefresh}
        getRadarOrderSuggestedDrivers={props.getRadarOrderSuggestedDrivers}
      />
    </FlexBox>
  );
}

export default enhancer(RadarSuggestedDriverTabs);
