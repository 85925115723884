import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormSupplierChips from "../form/FormSupplierChips";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { getObjectId, idsToObjectArray } from "../../helpers/FormUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const startTime = startOfToday();
const endTime = endOfToday();

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  useSheet({
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        supplierIds: idsToObjectArray(props.filter.getValue("supplier_ids")),
        fromDateTime: safeParseDate(props.filter.getValue("from_date_time")),
        toDateTime: safeParseDate(props.filter.getValue("to_date_time")),
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            supplier_ids: null,
            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),
          });

          if (!fp.isEmpty(values.supplierIds)) {
            filter.setValue(
              "supplier_ids",
              stringifyArray(values.supplierIds.map(getObjectId)),
            );
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "DriversDashboardFilterForm",
    enableReinitialize: true,
  }),
  formValues({
    status: "status",

    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
  }),
);

DriversDashboardFilterForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  dirty: PropTypes.bool,
  onDismiss: PropTypes.func,
  reset: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,
};

function DriversDashboardFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox container={8} direction="column">
      <form onSubmit={props.handleSubmit}>
        <Card>
          <CardContent>
            <FlexBox flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                Period
              </ListSubheader>

              <FlexBox gutter={8} flex={true} className={classes.wrapContent}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage(
                        "from_date",
                        "From Date",
                      )}
                      initialTime={startTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true} direction="column">
                    <FormTimeField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage(
                        "from_time",
                        "From Time",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_date", "To Date")}
                      initialTime={endTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true} direction="column">
                    <FormTimeField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_time", "To Time")}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true}>
              <FormSupplierChips
                name="supplierIds"
                fullWidth={true}
                label={props.getLocalisationMessage("suppliers", "Suppliers")}
              />
            </FlexBox>
          </CardContent>
          <FlexBox justify="flex-end">
            <CardActions>
              {props.dirty ? (
                <Button onClick={props.reset}>
                  {getLocalisationMessage("reset", "Reset")}
                </Button>
              ) : (
                Boolean(props.onDismiss) && (
                  <Button onClick={props.onDismiss}>
                    {getLocalisationMessage("dismiss", "Dismiss")}
                  </Button>
                )
              )}
              <Button type="submit">
                {getLocalisationMessage("submit", "Submit")}
              </Button>
            </CardActions>
          </FlexBox>
        </Card>
      </form>
    </FlexBox>
  );
}

export default enhancer(DriversDashboardFilterForm);
