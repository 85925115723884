import { Observable } from "rxjs";
import React, { useState } from "react";
import { fromJS, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  hideTextByKeyboardPlace,
  isEqualData,
  isEqualDataIn,
} from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDriverLiabilityFilterMapper } from "../../helpers/DriverLiabiltyFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  clearDriverLiabilityQueue,
  getDriverLiabilityQueue,
  updateDriverLiabilityQueue,
} from "../../reducers/DriverLiabilityReducer";
import {
  getCashierLiabilityTotals,
  getOperatorLiability,
  getStatusTotal,
  updateCashierOrderUpdatePsfCodCharge,
} from "../../api/admin/AdminOperatorApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import OperatorLiabilityStatusTabs from "../../components/ui-core/OperatorLiabilityStatusTabs";
import OperatorLiabilityCODPendingTable from "../../components/driver-liability/OperatorLiabilityCODPendingTable";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import OperatorLiabilityFilterForm from "../../components/driver-liability/OperatorLiabilityFilterForm";
import OperatorLiabiltyStatus, {
  WITH_CASHIER,
} from "../../constants/OperatorLiabiltyStatus";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import { paymentTypeIcons } from "../../components/orders-core/PaymentTypeAvatarAndInfo";
import {
  ADVANCE_BOOK,
  CARD,
  CASH,
  NON_CASH,
  NON_PAYMENT,
} from "../../constants/OrderPaymentTypes";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import { TOTAL } from "../../constants/OrderChargeItemTypes";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { AccountBalanceWallet } from "@material-ui/icons";
import { OPERATOR_BALANCE_REFUND_URL } from "../../constants/AdminPathConstants";
import { Link } from "react-router";

const BATCH_UPDATE_LIABILITY_TOTAL_DIALOG_HASH = "#BULTDH";
const useStyles = makeStyles({
  card: { "& > div": { display: "flex", flex: 1 } },
  totals: { textAlign: "center", padding: "12px 0" },
  withoutMargin: { margin: 0 },
  iconsWrapper: {
    flexWrap: "wrap",
    border: "1px solid #eee",
    "& > *": {
      flex: "1 1 auto",
    },
  },
  paymentType: {
    paddingLeft: ".5rem",
  },
  iconImg: {
    width: "2rem",
    height: "2rem",
  },
});
const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        selectedItems: getDriverLiabilityQueue(state),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      clearDriverLiabilityQueue,
      updateDriverLiabilityQueue,
    },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const filterStream = propsStream
          .map(
            fp.flow(
              fp.get("location.query"),
              fp.assign({ size: 100 }),
              toDriverLiabilityFilterMapper,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const initialValuesStream = filterStream
          .first()
          .map((filter: DataListFilter) => ({
            operator: { id: filter.getNumberValue("operator_id") },
          }))
          .distinctUntilChanged(isEqualData);

        const { stream: onRowSelectStream, handler: onRowSelect } =
          createEventHandler();

        const { stream: onRequestRefreshStream, handler: onRequestRefresh } =
          createEventHandler();

        const statusCountsStream = filterStream
          .map((filter) => filter.getValue("operator_id"))
          .filter((operatorId) => operatorId)
          .distinctUntilChanged(isEqualData)
          .switchMap((operatorId) =>
            getStatusTotal(operatorId)
              .catch((error) => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(fp.flow(fp.get("payload.data")))
          .distinctUntilChanged(isEqualData)
          .startWith({});

        const operatorLiabilityPendingStream = filterStream
          .switchMap((filter) =>
            filter.getValue("operator_id")
              ? getOperatorLiability(filter.getValue("operator_id"), filter)
                  .catch((error) => Observable.of({ error }))
                  .repeatWhen(() => onRequestRefreshStream)
              : Observable.of({}),
          )
          .startWith({})
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  pending: response.get("pending", false),
                  payload: response.getIn(
                    ["payload", "data", "content"],
                    List(),
                  ),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const operatorLiabilityTotalsStream = filterStream
          .map((filter: DataListFilter) =>
            filter.setValueMap({
              payment_flow: null,
            }),
          )
          .switchMap((filter) =>
            filter.getValue("operator_id")
              ? getCashierLiabilityTotals(
                  filter.getValue("operator_id"),
                  filter.setValue("operator_id", null),
                )
                  .catch((error) => Observable.of({ error }))
                  .repeatWhen(() => onRequestRefreshStream)
              : Observable.of({}),
          )
          .startWith({})
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  pending: response.get("pending", false),
                  payload: response.getIn(["payload", "data"], Map()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const sideEffectStream = Observable.merge(
          propsStream.take(1).do((props) => props.clearDriverLiabilityQueue()),
          propsStream
            .distinctUntilChanged(
              isEqualDataIn(["location", "query", "operator_id"]),
            )
            .skip(1)
            .do((props) => {
              onRowSelect(Set());
              props.clearDriverLiabilityQueue();
            }),
          onRowSelectStream
            .distinctUntilChanged(isEqualData)
            .withLatestFrom(propsStream)
            .do(([numbers, props]) =>
              props.updateDriverLiabilityQueue(numbers),
            ),
        ).startWith(null);

        return propsStream
          .combineLatest(
            filterStream,
            initialValuesStream,
            operatorLiabilityPendingStream,
            operatorLiabilityTotalsStream,
            statusCountsStream,
            sideEffectStream,
            (
              props,
              filter,
              initialValues,
              operatorPendingLiability,
              operatorLiabilityTotals,
              statusCounts,
            ) => ({
              ...props,
              filter,
              initialValues,
              statusCounts,
              onRowSelect,
              onRequestRefresh,
              isLoadingLiability: operatorPendingLiability.get("pending"),
              liabilityList: operatorPendingLiability.get("payload"),
              liabilityListTotal: operatorPendingLiability.get("payload").size,
              isLoadingLiabilityTotals: operatorLiabilityTotals.get("pending"),
              liabilityTotals: operatorLiabilityTotals.get("payload"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      (propsStream) =>
        propsStream
          .combineLatest(
            propsStream
              .map((props) => props.filter.getNumberValue("operator_id"))
              .startWith(0),
            (props, operatorId) => ({
              ...props,
              operatorId,
            }),
          )
          .distinctUntilChanged(isEqualData),
    ),
  ),
);

AdminOperatorBalanceContainer.propTypes = {
  operatorId: PropTypes.number,
  isLoadingLiability: PropTypes.bool,
  isLoadingLiabilityTotals: PropTypes.bool,
  setLocationQueryFilter: PropTypes.func,
  liabilityList: PropTypes.instanceOf(List),
  liabilityListTotal: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  location: PropTypes.object,
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Set),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  clearDriverLiabilityQueue: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  liabilityTotals: PropTypes.instanceOf(Map),
  statusCounts: PropTypes.instanceOf(Map),
};

function AdminOperatorBalanceContainer(props) {
  const classes = useStyles();
  const { getLocalisationMessage, liabilityTotals } = props;
  const [operatorId, setOperatorId] = useState(props.operatorId);
  const [operatorName, setOperatorName] = useState(null);
  const orderNumbersArray = props.selectedItems.toArray();

  return (
    <AdminAppLayout
      slug="operator_balance"
      title={getLocalisationMessage("operator_balance", "operator balance")}
    >
      <ConfirmDialog
        modal={true}
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("back", "Back")}
        onConfirm={() =>
          updateCashierOrderUpdatePsfCodCharge({
            order_codes: orderNumbersArray,
            payment_flow: WITH_CASHIER,
            sender_user_id: operatorId,
          })
            .then((message) => {
              props.onRequestRefresh();
              props.showSuccessMessage(message);
              props.replaceLocationHash(null);
              props.clearDriverLiabilityQueue();
            })
            .catch(props.showErrorMessage)
        }
        open={props.location.hash === BATCH_UPDATE_LIABILITY_TOTAL_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      >
        {getLocalisationMessage(
          "update_orders_like_a_cashier",
          "Update orders like a cashier",
        )}
      </ConfirmDialog>
      <Redirect
        when={!OperatorLiabiltyStatus.has(props.location.query.payment_flow)}
        to={updateQuery(
          props.location,
          fp.set("payment_flow", OperatorLiabiltyStatus.first()),
        )}
      />

      <FlexBox
        container={8}
        flex={true}
        element={<Card className={classes.card} />}
      >
        <FlexBox
          gutter={8}
          flex={true}
          direction="column"
          element={<CardContent />}
        >
          <FlexBox>
            <FlexBox
              gutter={8}
              flex={true}
              className={classes.iconsWrapper}
              align={ALIGN_CENTER}
            >
              <OperatorLiabilityFilterForm
                initialValues={props.initialValues}
                onChange={(values) => {
                  const tempId = getObjectId(values.operator);
                  if (tempId) {
                    setOperatorId(tempId);
                    setOperatorName(values.operator.name);
                    props.setLocationQueryFilter(
                      props.filter
                        .setValueMap({
                          operator_id: tempId,
                        })
                        .setPage(0),
                    );
                  }
                }}
              />
              {operatorId > 0 && (
                <React.Fragment>
                  <FlexBox align={ALIGN_CENTER}>
                    <Avatar
                      className={classes.iconImg}
                      src={paymentTypeIcons[CASH]}
                    />
                    <span className={classes.paymentType}>
                      {getLocalisationMessage(CASH)}:
                    </span>
                    <PriceWrapper price={liabilityTotals.get("CASH")} />
                  </FlexBox>
                  <FlexBox align={ALIGN_CENTER}>
                    <Avatar
                      className={classes.iconImg}
                      src={paymentTypeIcons[CARD]}
                    />
                    <span className={classes.paymentType}>
                      {getLocalisationMessage(CARD)}:
                    </span>
                    <PriceWrapper price={liabilityTotals.get("CARD")} />
                  </FlexBox>
                  <FlexBox align={ALIGN_CENTER}>
                    <Avatar
                      className={classes.iconImg}
                      src={paymentTypeIcons[NON_PAYMENT]}
                    />
                    <span className={classes.paymentType}>
                      {getLocalisationMessage(NON_PAYMENT)}:
                    </span>
                    <PriceWrapper price={liabilityTotals.get("NON_PAYMENT")} />
                  </FlexBox>
                  <FlexBox align={ALIGN_CENTER}>
                    <Avatar
                      className={classes.iconImg}
                      src={paymentTypeIcons[ADVANCE_BOOK]}
                    />
                    <span className={classes.paymentType}>
                      {getLocalisationMessage(ADVANCE_BOOK)}:
                    </span>
                    <PriceWrapper price={liabilityTotals.get("ADVANCE_BOOK")} />
                  </FlexBox>
                  <FlexBox align={ALIGN_CENTER}>
                    <Avatar
                      className={classes.iconImg}
                      src={paymentTypeIcons[NON_CASH]}
                    />
                    <span className={classes.paymentType}>
                      {getLocalisationMessage(NON_CASH)}:
                    </span>
                    <PriceWrapper price={liabilityTotals.get("NON_CASH")} />
                  </FlexBox>
                  <FlexBox align={ALIGN_CENTER}>
                    <Avatar
                      className={classes.iconImg}
                      src={paymentTypeIcons[TOTAL]}
                    />
                    <span className={classes.paymentType}>
                      {getLocalisationMessage(TOTAL)}:
                    </span>
                    <PriceWrapper price={liabilityTotals.get("TOTAL")} />
                  </FlexBox>
                  <Link
                    to={`${
                      OPERATOR_BALANCE_REFUND_URL + operatorId
                    }/${hideTextByKeyboardPlace(operatorName)}`}
                  >
                    <CustomButton
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => {
                        // console.log("value")
                      }}
                      endIcon={<AccountBalanceWallet />}
                      style={{ maxHeight: "30px" }}
                    >
                      {getLocalisationMessage(
                        "operator_credits",
                        "operator credits",
                      )}
                    </CustomButton>
                  </Link>
                </React.Fragment>
              )}
            </FlexBox>
          </FlexBox>
          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true} direction="column">
              <OperatorLiabilityStatusTabs
                location={props.location}
                statusCounts={props.statusCounts}
              />
              <FlexBox flex={true}>
                <OperatorLiabilityCODPendingTable
                  location={props.location}
                  onRowSelect={props.onRowSelect}
                  selectedItems={props.selectedItems}
                  altHeader={
                    <FlexBox>
                      <Button
                        onClick={() =>
                          props.replaceLocationHash(
                            BATCH_UPDATE_LIABILITY_TOTAL_DIALOG_HASH,
                          )
                        }
                      >
                        {" "}
                        {getLocalisationMessage("update", "Update")}{" "}
                      </Button>
                    </FlexBox>
                  }
                  isLoading={
                    props.isLoadingLiability || props.isLoadingLiabilityTotals
                  }
                  list={operatorId > 0 ? props.liabilityList : new List()}
                  total={operatorId > 0 ? props.liabilityListTotal : 0}
                  filter={props.filter}
                  onFilterChange={props.setLocationQueryFilter}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOperatorBalanceContainer);
