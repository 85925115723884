import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getTimeSlotAvailability,
  updateTimeSlotAvailability,
} from "../../api/admin/AdminTimeSlotAvailabilityApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import TimeSlotAvailabilityForm from "../../components/time-slots-core/TimeSlotAvailabilityForm";

const enhancer = compose(
  renderIf(props => props.timeslotAvailabilityId > 0),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const timeSlotAvailabilityResponseStream = propsStream
      .distinctUntilKeyChanged("timeslotAvailabilityId")
      .switchMap(props =>
        getTimeSlotAvailability(props.timeslotAvailabilityId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        timeSlotAvailabilityResponseStream,
        (props, timeslotAvailabilityResponse) => ({
          ...props,
          onRequestRefresh,
          timeslotAvailability: timeslotAvailabilityResponse.get("payload"),
          isLoading: timeslotAvailabilityResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminTimeSlotEditDialogWrapper.propTypes = {
  timeslotAvailability: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  rightIcon: PropTypes.node,

  timeslotAvailabilityId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminTimeSlotEditDialogWrapper(props) {
  const data = props.timeslotAvailability;
  const newData = data.set(
    "cutoff_time",
    new Date(`1970-01-01 ${data.get("cutoff_time")}`),
  );

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={`${props.getLocalisationMessage("timeslot_availability")} #${
        props.timeslotAvailabilityId
      }`}
    >
      <FlexBox container={8} direction="column">
        <TimeSlotAvailabilityForm
          onDismiss={props.onRequestClose}
          initialValues={toCamelCase(newData)}
          onSubmit={values =>
            updateTimeSlotAvailability(toSnakeCase(values)).catch(
              ResponseError.throw,
            )
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_updated",
                "Successfully Updated",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedTimeSlot={getCachedTimeSlot}
          getTimeSlotPredictions={getTimeSlotPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminTimeSlotEditDialogWrapper);
