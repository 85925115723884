import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { POSTCODES_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  getPostcode,
  deleteNeighborhood,
  updateNeighborhood,
  uploadNeighborhoodKML,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedCity,
  getCityPredictions,
} from "../../api/shared/CountryV2Api";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminPostcodesForm from "../../components/admin/AdminPostcodesForm";
import Redirect from "../../components/router/Redirect";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  connect(
    (state) => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const neighborhoodIdStream = propsStream.map(
          fp.flow(fp.get("params.id"), fp.toFinite),
        );

        return propsStream
          .combineLatest(neighborhoodIdStream, (props, neighborhoodId) => ({
            ...props,
            neighborhoodId,
          }))
          .distinctUntilChanged(isEqualData);
      },
      (propsStream) => {
        const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
          createEventHandler();

        const neighborhoodItemResponseStream = propsStream
          .distinctUntilKeyChanged("neighborhoodId")
          .switchMap((props) =>
            getPostcode(props.neighborhoodId)
              .repeatWhen(() => onRequestRefreshStream)
              .catch((error) => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            neighborhoodItemResponseStream,
            (props, neighborhoodItemResponse) => ({
              ...props,
              onRequestRefresh,
              neighborhood: neighborhoodItemResponse.get("payload"),
              isLoading: neighborhoodItemResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminPostcodesItem.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  neighborhoodId: PropTypes.number,
  isLoading: PropTypes.bool,
  setLocation: PropTypes.func,
  router: PropTypes.object,
  neighborhood: PropTypes.instanceOf(Map),
  i18n: PropTypes.instanceOf(Map),
};

function AdminPostcodesItem(props) {
  return (
    <AdminAppLayout
      title={
        props.neighborhood
          ? `${props.i18n.get(
              "postcode_index",
              "Postcode Index",
            )} - ${props.neighborhood.get("index")}`
          : props.i18n.get("postcode_index_details", "Postcode Index Details")
      }
    >
      <Redirect to={POSTCODES_LIST_URL} when={Boolean(!props.neighborhoodId)} />

      <PageLoading isLoading={props.isLoading} />

      <AdminPostcodesForm
        router={props.router}
        wkt={props.neighborhood.get("wkt")}
        initialValues={toCamelCase(props.neighborhood)}
        onSubmit={(values) =>
          updateNeighborhood(props.neighborhoodId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onDelete={() =>
          deleteNeighborhood(props.neighborhood.get("id"))
            .then(() => {
              props.setLocation(POSTCODES_LIST_URL);
              props.showSuccessMessage(
                props.i18n.get("successfully_deleted", "Successfully Deleted!"),
              );
            })
            .catch(props.showErrorMessage)
        }
        uploadNeighborhoodKML={(file) =>
          uploadNeighborhoodKML(props.neighborhoodId, file)
        }
        onUploadFile={() => {
          props.onRequestRefresh();
          props.showSuccessMessage(
            props.i18n.get("successfully_uploaded", "Successfully Uploaded"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.setLocation(POSTCODES_LIST_URL);
          props.showSuccessMessage(
            props.i18n.get("successfully_saved", "Successfully Saved"),
          );
        }}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedCity={getCachedCity}
        getCityPredictions={getCityPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPostcodesItem);
