import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormToggle from "../form/FormToggle";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import adminColumnOptions from "../../constants/marketplace/AdminCustomizableOrderListConstants";
import customerColumnOptions from "../../constants/marketplace/CustomerCustomizableOrderListContainer";
import supplierColumnOptions from "../../constants/marketplace/SupplierCustomizableOrderListContainer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    content: { width: "500px" },
    row: { padding: "5px 0" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    td: {
      alignSelf: "center",
    },
  }),
  reduxForm({
    form: "CustomizableOrderListSettingsDialogForm",
    enableReinitialize: true,
    initialValues: {
      customer_name: { active: true },
      warehouse: { active: true },
      supplier_name: { active: true },
      dropoff_address: { active: true },
      service: { active: true },
      timeslot: { active: true },
      piece_count: { active: true },
      attempts_count: { active: true },
      created_date: { active: true },
      pickup_time: { active: true },
      deadline_time: { active: true },
      merchant_promise_date: { active: true },
      last_modified_date: { active: true },
      cod_charge: { active: true },
      cod_status: { active: true },
      amount: { active: true },
      shipping_type: { active: true },
    },
  }),
);

CustomizableOrderListSettingsDialogForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  type: PropTypes.string.isRequired,
};

function CustomizableOrderListSettingsDialogForm(props) {
  const { classes, getLocalisationMessage } = props;

  const listColumnOptions =
    props.type === "ORDER_LIST_COLUMNS"
      ? adminColumnOptions
      : props.type === "ORDER_LIST_COLUMNS_FOR_CUSTOMER"
      ? customerColumnOptions
      : supplierColumnOptions;

  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onClose={props.handleSubmit}
      contentClassName={props.classes.content}
      PaperProps={{
        style: {
          minWidth: 400,
          maxWidth: 600,
        },
      }}
    >
      <DialogTitle>
        {getLocalisationMessage("select_columns", "Select Columns")}
      </DialogTitle>
      <DialogContent>
        {listColumnOptions.map(item => (
          <FlexBox key={item.slug} className={classes.row}>
            <FlexBox flex={true}>
              <FormTextField
                name={`${item.slug}.custom_name`}
                placeholder={item.name}
              />
            </FlexBox>
            <FlexBox flex={true} className={classes.td}>
              <FormToggle
                className={props.classes.toggle}
                name={`${item.slug}.active`}
              />
            </FlexBox>
          </FlexBox>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CustomizableOrderListSettingsDialogForm);
