import { compose } from "recompose";
import fp from "lodash/fp";
import ChipTextField from "../deprecated/ChipTextField";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import DataListFilter from "../../helpers/DataListFilter";

const styles = {
  chipContainer: {
    minHeight: "40px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: 5,
    float: "left",
    paddingLeft: 5,
    "& > div": {
      paddingTop: 5,
    },
  },
};

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCustomsSearchWrapper.propTypes = {
  maxItems: PropTypes.number,

  filter: PropTypes.instanceOf(DataListFilter),
  onChange: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

OrderCustomsSearchWrapper.defaultProps = {
  maxItems: 1,
  filter: new DataListFilter(),
};

function OrderCustomsSearchWrapper(props) {
  const { onChange, getLocalisationMessage, filter } = props;

  const value = !fp.isEmpty(filter.getSearch()) ? [filter.getSearch()] : "";

  return (
    <ChipTextField
      fullWidth={true}
      addOnBlur={false}
      clearOnBlur={false}
      disableUnderline={true}
      value={value}
      hintText={getLocalisationMessage("search", "Search")}
      maxItems={props.maxItems}
      style={styles.chipContainer}
      onChange={onChange}
    />
  );
}

export default enhancer(OrderCustomsSearchWrapper);
