import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import _ from "lodash";
import { safeParseDate } from "../../helpers/DateUtils";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import {
  CloudDownloadOutlined,
  FilterList,
  KeyboardArrowLeft,
  Refresh,
} from "@material-ui/icons";
import MUITable, { RENDER } from "../../components/orders-core/MUITable";
import TableListHeader from "../../wrappers/admin/TableListHeader";
import fp from "lodash/fp";
import { getUserId, getUserWarehouse } from "../../reducers/ProfileReducer";
import { clearHybridOrders } from "../../reducers/HybridOrdersReducer";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import {
  ACCEPT_PDF_URL,
  getOrderShiftList,
  HANDOVER_PDF_URL,
} from "../../api/v2/admin/AdminOrderSortingApi";
import ShiftingHistoryFilterDialog from "../../wrappers/admin/ShiftingHistoryFilterDialog";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { getShiftPDF } from "../../helpers/FormUtils";
import { ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_URL } from "../../constants/AdminPathConstants";
import { DATE } from "../../components/orders-core/MUITableSimple";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { endOfDay, startOfDay } from "date-fns";

const enhancer = compose(
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      userId: getUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, clearHybridOrders },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);
const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  input: { "& input": { fontSize: "20px" } },
});

const AdminBatchHistoryUpdateList = props => {
  const { getLocalisationMessage, showErrorMessage: showErrorMessage1 } = props;
  const classes = useStyles();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState({
    refresh: false,
    page: 0,
    size: 20,
  });

  const [handoverPDF, setHandoverPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: HANDOVER_PDF_URL,
  });

  const [acceptPDF, setAcceptPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ACCEPT_PDF_URL,
  });

  const [pdfId, setPDFId] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getOrderShiftList(filter)
      .then(res => {
        setIsLoading(false);
        setList(_.get(res, "data.list"));
        setTotal(_.get(res, "data.total"));
      })
      .catch(error => {
        setIsLoading(false);
        showErrorMessage1(error);
        setList([]);
      });
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });
  return (
    <AdminAppLayout
      title={getLocalisationMessage("shift_history", " Shifting History")}
      className={classes.contentRoot}
    >
      <ShiftingHistoryFilterDialog
        initialValues={{
          ...filter,
          fromDate: safeParseDate(fp.get("from_date", filter)),
          toDate: safeParseDate(fp.get("to_date", filter)),
          type: fp.get("type", filter),
        }}
        open={isOpenFilter}
        onFilterChange={v => {
          setFilter({
            ...filter,
            type: v.type,
            from_date: formatDateTimeToUrl(startOfDay(v.from_date)),
            to_date: formatDateTimeToUrl(endOfDay(v.to_date)),
          });
          setIsOpenFilter(false);
        }}
        onRequestClose={() => setIsOpenFilter(false)}
      />

      <FlexBox
        style={{ backgroundColor: "white", height: "100%" }}
        direction="column"
        justify="space-between"
      >
        <TableListHeader
          buttons={
            <FlexBox
              flex={true}
              style={{ gap: 20, marginLeft: 20, marginRight: 20 }}
              align="center"
              justify="space-between"
            >
              <FlexBox
                flex={true}
                style={{ gap: 20, fontSize: 18 }}
                align="center"
              >
                <CustomButton
                  className={classes.btn}
                  onClick={() =>
                    props.setLocation(
                      `${ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_URL}?shift=true`,
                    )
                  }
                  startIcon={<KeyboardArrowLeft />}
                  variant={OUTLINED}
                  color={SECONDARY}
                >
                  {getLocalisationMessage("back", "Back")}
                </CustomButton>
              </FlexBox>
              <FlexBox
                align={ALIGN_CENTER}
                style={{ flex: "1 1 auto", gap: 16 }}
                justify="flex-end"
              >
                <FlexBox>
                  <IconButton
                    className={isLoading && classes.refresh}
                    tooltip={getLocalisationMessage("refresh")}
                    onClick={() => refreshOrderList()}
                  >
                    <Refresh style={{ color: "#1976d2" }} />
                  </IconButton>
                </FlexBox>
                <FlexBox>
                  <IconButton onClick={() => setIsOpenFilter(true)}>
                    <FilterList style={{ color: "#1976d2" }} />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          }
        />

        <MUITable
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          filter={filter}
          isLoading={isLoading}
          list={list}
          total={total}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          columns={[
            {
              name: "id",
              label: getLocalisationMessage("id"),
              align: "center",
              keyName: "id",
            },
            {
              type: RENDER,
              name: "from_operator",
              label: getLocalisationMessage("from"),
              render: row => _.get(row, `from_operator.name`, ""),
            },
            {
              type: RENDER,
              name: "to_operator",
              label: getLocalisationMessage("to"),
              render: row => _.get(row, `to_operator.name`, ""),
            },
            {
              type: RENDER,
              name: "supervisor",
              label: getLocalisationMessage("supervisor"),
              render: row => _.get(row, `supervisor.name`, ""),
            },
            {
              type: RENDER,
              name: "type",
              label: getLocalisationMessage("type"),
              render: row =>
                row.type === "HANDOVER"
                  ? getLocalisationMessage("handover", "Handover")
                  : row.type === "ACCEPTED"
                  ? getLocalisationMessage("accepted_1", "Accepted")
                  : "",
            },
            {
              type: DATE,
              name: "created_date",
              keyName: "created_date",
              label: getLocalisationMessage("created_date"),
            },
            {
              type: RENDER,
              name: "action",
              width: 330,
              label: getLocalisationMessage("action", "Action"),
              render: row => (
                <div>
                  {row.type === "HANDOVER" && (
                    <IconButton
                      className={
                        _.get(handoverPDF, IS_PRINTING, false) &&
                        pdfId === _.get(row, "id") &&
                        classes.refresh
                      }
                      onClick={() => {
                        setPDFId(_.get(row, "id"));
                        getShiftPDF(
                          handoverPDF,
                          setHandoverPDF,
                          IS_PRINTING,
                          _.get(row, "id"),
                          props.showErrorMessage,
                        );
                      }}
                    >
                      {_.get(handoverPDF, IS_PRINTING, false) &&
                      pdfId === _.get(row, "id") ? (
                        <Refresh />
                      ) : (
                        <CloudDownloadOutlined />
                      )}
                    </IconButton>
                  )}

                  {row.type === "ACCEPTED" && (
                    <IconButton
                      className={
                        _.get(acceptPDF, IS_PRINTING, false) &&
                        pdfId === _.get(row, "id") &&
                        classes.refresh
                      }
                      onClick={() => {
                        setPDFId(_.get(row, "id"));
                        getShiftPDF(
                          acceptPDF,
                          setAcceptPDF,
                          IS_PRINTING,
                          _.get(row, "id"),
                          props.showErrorMessage,
                        );
                      }}
                    >
                      {_.get(acceptPDF, IS_PRINTING, false) &&
                      pdfId === _.get(row, "id") ? (
                        <Refresh />
                      ) : (
                        <CloudDownloadOutlined />
                      )}
                    </IconButton>
                  )}
                </div>
              ),
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
};
AdminBatchHistoryUpdateList.propTypes = {
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

export default enhancer(AdminBatchHistoryUpdateList);
