import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const baseFilter = {
  use_solr: types.booleanOr(true),

  parent_id: types.ID,
  search: queryTypes.stringSeq,
};

export const toJurisdictionFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    ...baseFilter,
  }),
);
