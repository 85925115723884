import React from "react";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import DefaultPackageEditDialogNew from "../../components/package-pricelist-new/DefaultPackageEditDialogNew";

function AdminPackageNewEditDialogWrapper(props) {
  return (
    <DefaultPackageEditDialogNew
      getCachedCountry={getCachedCountry}
      getCountryPredictions={getCountryPredictions}
      getCachedCity={getCachedCity}
      getCityPredictions={getCityPredictions}
      {...props}
    />
  );
}

export default AdminPackageNewEditDialogWrapper;
