import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { connect } from "react-redux";
import FlexBox, { ALIGN_CENTER } from "../../../components/ui-core/FlexBox";
import OfflineOrderSortingTabs from "../../../components/order-outbound-sorting/OfflineOrderSortingTabs";
import OfflineOrderSortingChips from "../../../components/order-outbound-sorting/OfflineOrderSortingChips";
import { CardContent, makeStyles, MenuItem } from "@material-ui/core";
import ChipTextField from "../../../components/deprecated/ChipTextField";
import { DIALOG, RENDER } from "../../../components/orders-core/MUITable";
import _ from "lodash";
import fp from "lodash/fp";
import OfflineOrderSortingTable from "../../../components/order-outbound-sorting/OfflineOrderSortingTable";
import {
  getAVVALUserId,
  getUser,
  getUserPermissions,
  getUserWarehouseId,
} from "../../../reducers/ProfileReducer";
import { hasRole } from "../../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../../constants/AdminRoleTypes";
import { ORDER, SIMPLE } from "../../../constants/OrderType";
import {
  formatDateTimeToUrlWithSeconds,
  formatErrorMessage,
  formatText,
  formatWeight,
} from "../../../helpers/FormatUtils";
import { getValue, isEmpty } from "../../../helpers/DataUtils";
import MultiLineCell from "../../../components/data-list/MultiLineCell";
import { formatOrderStatusCodeForLocalisation } from "../../../helpers/OrderHelper";
import DateTimeCell from "../../../components/data-list/DateTimeCell";
import {
  updateOrderIssuance,
  updateRecipientPostcode,
  updateRTOInternational,
  uploadOrderSignature,
} from "../../../api/admin/AdminOrderApi";
import ResponseError from "../../../helpers/ResponseError";
import OfflineOrderSortingRedirectOrderDialog from "../../../components/order-outbound-sorting/OfflineOrderSortingRedirectOrderDialog";
import {
  getCheckId,
  getOrder2,
  setReceipt,
} from "../../../api/v2/admin/AdminOrderApi";
import { fromJS } from "immutable";
import AdminOrderDetailsDialogWrapperForOrderList from "../../../wrappers/admin/AdminOrderDetailsDialogWrapperForOrderList";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import AdminOfflineOutboundOrderSortingBatchUpdateDialogWrapper from "../../../wrappers/admin/AdminOfflineOutboundOrderSortingBatchUpdateDialogWrapper";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import OfflineBatchAssignOrdersToDriverFormDialogWrapper from "../../../wrappers/admin/OfflineBatchAssignOrdersToDriverFormDialogWrapper";
import OfflineBatchRedirectOrdersFormDialogWrapper from "../../../wrappers/admin/OfflineBatchRedirectOrdersFormDialogWrapper";
import AdminBatchUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import OfflineBatchRTOOrdersFormDialogWrapper from "../../../wrappers/admin/OfflineBatchRTOOrdersFormDialogWrapper";
import BatchRTOOrdersFormDialogWrapper from "../../../wrappers/admin/BatchRTOOrdersFormDialogWrapper";
import BatchAssignOrdersToDriverFormDialogWrapper from "../../../wrappers/admin/BatchAssignOrdersToDriverFormDialogWrapper";
import OrderDetailsDialogIssuance2 from "../../../components/order-details-dialog/OrderDetailsDialogIssuance2";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import {
  isOtpSettingsServiceType,
  isOtpSettingsTransportationType,
} from "../../../reducers/MarketplaceReducer";
import OfflineBatchCanceledUpdateDialogWrapper from "../../../wrappers/admin/OfflineBatchCanceledUpdateDialogWrapper";
import AdminBatchCanceledUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchCanceledUpdatesItemDialogWrapper";
import OfflineBatchReturningUpdateDialogWrapper from "../../../wrappers/admin/OfflineBatchReturningUpdateDialogWrapper";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import AdminOutboundOrderSortingBatchUpdateDialogWrapper from "../../../components/order-outbound-sorting/AdminOutboundOrderSortingBatchUpdateDialogWrapper";
import ConfirmDialog from "../../../components/deprecated/ConfirmDialog";
import { compose, getContext } from "recompose";
import {
  clearShipment,
  syncShipment,
} from "../../../api/v2/admin/AdminOrderSortingApi";
import { avvalSearchUrl } from "../../../components/orders-core/CommissionTINDialog";
import { CARD, CASH } from "../../../constants/OrderPaymentTypes";
import { getPDF } from "../../../helpers/FormUtils";
import {
  COD_RECEIPT_URL,
  CUSTOMS_RECEIPT_URL,
  IS_DOWNLOADING,
  IS_PRINTING,
  RefundInfo,
  RTO_RECEIPT_URL,
  serviceId,
  urlAVVAL,
} from "../../../components/orders-core/AdminPostForm";
import {
  COD,
  CUSTOMS_FEE_PERCENTAGE,
  CUSTOMS_FEE_V2,
  RTO_INTERNATI0NAL,
} from "../../../constants/OrderChargeItemTypes";
import BatchRTOPPriceOrdersDialog from "../../../components/orders-core/BatchRTOPPriceOrdersDialog";
import { RETURNED_TO_ORIGIN } from "../../../constants/OrderStatusCodes";
import { calculateVAT } from "../../../components/order-details-dialog/v2/OrderDetailsDialog";
import OrderDetailsDialogCustoms from "../../../components/order-details-dialog/OrderDetailsDialogCustoms";

const options = commissionTin => ({
  method: "POST",
  headers: {
    Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    search_inn_comm: {
      comm_inn: commissionTin,
    },
  }),
});

const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  btn: {
    margin: "0 .5rem",
  },
  input: { "& input": { fontSize: "20px" } },
});

function AdminOrderOfflineOutboundSortingTableContainer(props) {
  const {
    sortingServiceTypes,
    sortingBeans,
    total,
    onServiceTypeFilter,
    onBeanFilter,
    filter,
    isLoading,
    getLocalisationMessage,
    sortingItems,
    refreshOrderList,
    handleChangeRowsPerPage,
    handleChangePage,
    selectedItems,
    // excludeSelectedItems,
    setSelectedItems,
    // setExcludeSelectedItems,
    onBeanUnFilter,
    setFilter,
    tabCount,
    userWarehouseId,
    setLocationQuery,
    location,
    setRefreshTab,
    // eslint-disable-next-line no-shadow
    isOtpSettingsServiceType,
    // eslint-disable-next-line no-shadow
    isOtpSettingsTransportationType,
    setIsLoading,
    selectBin,
    setSelectBin,
    selectBean,
    setSelectBean,
    savedFilter,
    userIdAVVAL,
    isSelected,
    setIsSelected,
  } = props;
  const classes = useStyles();
  const [isOpenRTO, setIsOpenRTO] = useState(false);
  const [isOrderOpenRTO, setIsOrderOpenRTO] = useState(false);
  const [isOpenBatchUpdate, setIsOpenBatchUpdate] = useState(false);
  const [isAssignDriver, setIsAssignDriver] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isOpenIssuance, setIsOpenIssuance] = useState(false);
  const [isOpenIssuanceCustoms, setIsOpenIssuanceCustoms] = useState(false);
  const [isOpenRedirect, setIsOpenRedirect] = useState(false);
  const [isOpenDispatch, setIsOpenDispatch] = useState(false);
  const [isOpenBrief, setIsOpenBrief] = useState(false);
  const [openRedirectOrder, setOpenRedirectOrder] = useState(null);
  const [isOpenRTOPrice, setIsOpenRTOPrice] = useState(false);
  const [isLoadingRTOPrice, setIsLoadingRTOPrice] = useState(false);
  const [isLoadingCOD, setIsLoadingCOD] = useState(false);
  const [showBatchRedirected, setShowBatchRedirected] = useState({
    open: false,
    is_redirected: false,
  });
  const [isRefreshOrderData, setIsRefreshOrderData] = useState(false);
  const [isHybrid, setIsHybrid] = useState(false);

  const [stateReceiptCOD, setStateReceiptCOD] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: COD_RECEIPT_URL,
  });

  const [stateReceiptRTO, setStateReceiptRTO] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RTO_RECEIPT_URL,
  });

  const [stateReceiptCustoms, setStateReceiptCustoms] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CUSTOMS_RECEIPT_URL,
  });

  const [refreshChekId, setRefreshChekId] = useState(false);

  const [order, setOrder] = useState({});
  const [checkID, setCheckID] = useState(0);

  const [orderId, setOrderId] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [resBatchId, setResBatchId] = useState(
    getValue(location, "query.batch_id"),
  );
  const [isBulkReturning, setIsBulkReturning] = useState(false);
  const [showVerifyBinDialog, setShowVerifyBinDialog] = useState(false);
  const [showConfim, setShowConfirm] = useState(false);
  const [errorOtpData, setErrorOtpError] = useState("");

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);

  const canceledBatchId = getValue(location, "query.cancel_batch_id");

  const resBatchLocationId = getValue(location, "query.batch_id");

  useEffect(() => {
    if (filter.bean && filter.bean.code) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [filter.bean]);

  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  useEffect(() => {
    if (orderId || openRedirectOrder) {
      const id = orderId || openRedirectOrder;
      getOrder2(id)
        .then(res => {
          setOrder(_.get(res, "data", {}));
          setIsHybrid(_.get(res, "data.hybrid", false));
        })
        .catch(err => props.showErrorMessage(err));
    }
  }, [orderId, isRefreshOrderData, openRedirectOrder]);

  const orderBarcode = _.get(order, "barcode");

  const otpSettingsServiceTypes = isOtpSettingsServiceType
    ? isOtpSettingsServiceType.toJS()
    : [];
  const otpSettingsTransportationTypes = isOtpSettingsTransportationType
    ? isOtpSettingsTransportationType.toJS()
    : [];

  const showOtpSettings =
    otpSettingsServiceTypes.includes(getValue(order, "service_type.code")) &&
    otpSettingsTransportationTypes.includes(
      getValue(order, "transportation_type"),
    );

  const chargeItems = _.get(order, "charge_items", []);
  const chargeItemCustom =
    _.find(chargeItems, { charge_type: CUSTOMS_FEE_V2 }) || {};

  const chargeItemCustomFeePercentage =
    _.find(chargeItems, { charge_type: CUSTOMS_FEE_PERCENTAGE }) || {};
  const chargeItemCOD = _.find(chargeItems, { charge_type: COD }) || {};
  const chargeItemRTO =
    _.find(chargeItems, { charge_type: RTO_INTERNATI0NAL }) || {};
  const isHaveCOD = chargeItemCOD.charge > 0;
  const isHaveCustom = chargeItemCustom.charge > 0;
  const contentItems = _.get(order, "content_items", []);

  useEffect(() => {
    if (!(checkID > 0)) {
      getCheckId().then(res => setCheckID(_.get(res, "data")));
    }
  }, [refreshChekId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isHaveCOD && isOpenIssuance && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenIssuance, isHaveCOD]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isHaveCustom && isOpenIssuanceCustoms && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenIssuanceCustoms, isHaveCustom]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOpenRTOPrice && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenRTOPrice]);

  const optionsPayment = tosender => ({
    method: "POST",
    headers: {
      Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
      "Content-Type": "application/json",
      Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
    },
    body: JSON.stringify({
      search_chek: {
        tosender,
        barcode: orderBarcode,
        method: "sale-services",
      },
    }),
  });

  const optionsPaymentCustoms = () => ({
    method: "POST",
    headers: {
      Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
      "Content-Type": "application/json",
      Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
    },
    body: JSON.stringify({
      search_chek: {
        service_id: 382,
        barcode: orderBarcode,
        method: "sale-services",
      },
    }),
  });

  const checkAVVALres = response => {
    if (response === null) {
      props.showErrorMessage(
        getLocalisationMessage(
          "unexpected_error_occurred_and_payment_failed_please_contact_your_manage_or_try_later",
        ),
      );
      return false;
    } else if (
      response &&
      getValue(response, "resInfo.result.QRCodeURL") === null
    ) {
      props.showErrorMessage(
        getLocalisationMessage("the_payment_was_not_successful"),
      );
      return false;
    } else if (_.get(response, "msg") === "error") {
      const errorMessage = `${getLocalisationMessage(
        "error_msg_from_avval",
      )} : ${_.get(response, "resInfo.message")}`;
      props.showErrorMessage(errorMessage);
      return false;
    }
    return true;
  };

  const [packageCode, setPackageCode] = useState("");

  useEffect(() => {
    if (isOpenRTOPrice) {
      const optionsMixkCode = {
        method: "POST",
        headers: {
          Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_inn_spic: {
            spic: "10105001001000000",
          },
        }),
      };

      fetch(avvalSearchUrl, optionsMixkCode)
        .then(response => response.json())
        .then(res => {
          if (res && res.mess === "success") {
            setPackageCode(getValue(res, "data.packageNames[0].code"));
          }
        });
    }
  }, [isOpenRTOPrice]);

  const createAVVALDataRTO = (paymentType, orderDto) => {
    const servicesAvval = [];
    const customerName = _.get(orderDto, "customer.name", "");
    const Items = [];
    const charges = _.get(orderDto, "charge_items", []);
    const chargeItemRTOInternational =
      _.find(charges, { charge_type: RTO_INTERNATI0NAL }) || {};
    const createService = v =>
      servicesAvval.push({
        type: _.get(v, "charge_type"),
        id: _.get(v, "id"),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });
    const createRequest = (v, price, Amount) =>
      Items.push({
        Name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: Number(_.multiply(price, 100).toFixed(2)),
        VatPercent: 12,
        PackageCode: packageCode,
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: Number(_.multiply(Amount, 100).toFixed(2)),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });

    charges
      .filter(x => x.charge_type === RTO_INTERNATI0NAL)
      .forEach(v => {
        createService(v);
        createRequest(v, v.charge);
      });

    const ReceivedCash =
      paymentType === CASH
        ? Number((_.get(chargeItemRTOInternational, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCard =
      paymentType === CARD
        ? Number((_.get(chargeItemRTOInternational, "charge") * 100).toFixed(2))
        : 0;
    return {
      method: "sale-services",
      userId: userIdAVVAL,
      tosender: 1,
      serviceId,
      barcode: _.get(orderDto, "barcode", ""),
      checkID,
      chNumber: _.get(orderDto, "chNumber", orderId),
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash,
        ReceivedCard,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            _.get(chargeItemRTOInternational, "card_type") === "CORPORATIVE"
              ? 1
              : _.get(chargeItemRTOInternational, "card_type") === "PRIVATE"
              ? 2
              : "",
          PPTID: _.get(chargeItemRTOInternational, "ppt_id"),
        },
      },
    };
  };

  const createAVVALData = (paymentType, cardType, pptId, QQS) => {
    const customerName = _.get(order, "customer.name", "");
    const servicesCod = [];
    const commissionINNItems = [];
    contentItems.map(item => {
      const vat = parseFloat(
        ((Number(_.get(item, "price")) * Number(_.get(item, "qty"))) / 1.12) *
          0.12,
      ).toFixed(2);

      const Amount = Number(_.get(item, "qty")) * 1000;
      const Price =
        Number(_.get(item, "qty")) * Number(_.get(item, "price", 0)) * 100;
      servicesCod.push({
        type: "COD",
        id: Number(_.get(item, "service_id", "")),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });

      return commissionINNItems.push({
        Name:
          _.get(item, "name", "") && _.get(item, "name", "").length
            ? _.get(item, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(item, "barcode", "$10406001001000000$"),
        Label: "",
        Spic: _.get(item, "spic", "10105001001000000"),
        Units: 0,
        Price,
        VatPercent: QQS || 0,
        PackageCode: _.get(item, "package_code", 0),
        CommissionInfo: {
          TIN:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 9
              ? _.get(item, "commission_tin", "")
              : "",
          PINFL:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 14
              ? _.get(item, "commission_tin", "")
              : "",
        },
        Vat: QQS > 0 ? Number((vat * 100).toFixed(0)) : 0,
        Amount,
        OwnerType: 0,
        Discount: _.get(item, "discount", 0),
        Other: _.get(item, "other", 0),
      });
    });

    const ReceivedCashCOD =
      paymentType === CASH
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCOD =
      paymentType === CARD
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;

    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      barcode: _.get(order, "barcode", ""),
      checkID,
      chNumber: _.get(order, "chNumber", orderId),
      services: servicesCod,
      request: {
        Items: commissionINNItems,
        ReceivedCash: ReceivedCashCOD,
        ReceivedCard: ReceivedCardCOD,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            cardType === "CORPORATIVE" ? 1 : cardType === "PRIVATE" ? 2 : "",
          PPTID: pptId,
        },
      },
    };
  };

  const createAVVALDataCustoms = (paymentType, cardType, pptId) => {
    const services = [
      {
        type: "other_pay",
        id: 382,
        name: "Bojxona xizmati",
      },
    ];
    const items = [
      {
        Name: "Davlat boji",
        Barcode: "$11199008002000000$",
        Label: "",
        Spic: "11199008002000000",
        Units: 0,
        Price: Number((_.get(chargeItemCustom, "charge") * 100).toFixed(2)),
        VatPercent: 0,
        PackageCode: "1516277",
        CommissionInfo: {
          TIN: "192881307",
          PINFL: "",
        },
        Vat: 0,
        Amount: 1000,
        OwnerType: 0,
        Discount: 0,
        Other: 0,
      },
      {
        Name: "To'lovlarni qabul qilish xizmati",
        Barcode: "$10406001001000000$",
        Label: "",
        Spic: "10406001001000000",
        Units: 0,
        PackageCode: "1508530",
        Price: Number(
          (_.get(chargeItemCustomFeePercentage, "charge") * 100).toFixed(2),
        ),
        VatPercent: 12,
        Vat: 0,
        Amount: 1000,
        Discount: 0,
        Other: 0,
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
      },
    ];

    const ReceivedCashCustoms =
      paymentType === CASH
        ? Number((_.get(chargeItemCustom, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCustoms =
      paymentType === CARD
        ? Number((_.get(chargeItemCustom, "charge") * 100).toFixed(2))
        : 0;

    const ReceivedCashCustomsFeePercentage =
      paymentType === CASH
        ? Number(
            (_.get(chargeItemCustomFeePercentage, "charge") * 100).toFixed(2),
          )
        : 0;
    const ReceivedCardCustomsFeePercentage =
      paymentType === CARD
        ? Number(
            (_.get(chargeItemCustomFeePercentage, "charge") * 100).toFixed(2),
          )
        : 0;

    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      barcode: _.get(order, "barcode", ""),
      checkID,
      chNumber: orderId,
      services,
      request: {
        Items: items,
        ReceivedCash: ReceivedCashCustoms + ReceivedCashCustomsFeePercentage,
        ReceivedCard: ReceivedCardCustoms + ReceivedCardCustomsFeePercentage,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            cardType === "CORPORATIVE" ? 1 : cardType === "PRIVATE" ? 2 : "",
          PPTID: pptId,
        },
      },
    };
  };

  return (
    <FlexBox direction="column" style={{ overflow: "hidden", height: "100%" }}>
      {fp.toFinite(resBatchId) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(resBatchId)}
          onRequestClose={() => {
            setResBatchId(null);
          }}
        />
      )}

      {fp.toFinite(resBatchLocationId) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(resBatchLocationId)}
          onRequestClose={() => {
            setLocationQuery(fp.unset("batch_id"));
          }}
          isRefresh={true}
          refreshTabAndList={() => {
            setRefreshTab(prev => !prev);
            setFilter({
              ...savedFilter,
              init: true,
              page: 0,
              size: 20,
              service_type_id: null,
              barcodes: [],
            });
          }}
        />
      )}

      <BatchRTOPPriceOrdersDialog
        open={isOpenRTOPrice}
        isLoadingRTOPrice={isLoadingRTOPrice}
        close={() => {
          setIsOpenRTOPrice(false);
          setIsOpenBrief(true);
        }}
        initialValues={{
          barcode: _.get(order, "barcode", ""),
          status: RETURNED_TO_ORIGIN,
          paymentType: getValue(chargeItemRTO, "payment_type")
            ? getValue(chargeItemRTO, "payment_type")
            : CASH,
          price: getValue(chargeItemRTO, "charge")
            ? getValue(chargeItemRTO, "charge")
            : _.get(order, "package_type.price.total", ""),
          cardType: getValue(chargeItemRTO, "card_type"),
          pptId: getValue(chargeItemRTO, "ppt_id"),
        }}
        onSubmit={values => {
          setIsLoadingRTOPrice(true);
          updateRTOInternational(toSnakeCase(values))
            .then(r => {
              if (r && r.status === "success") {
                setTimeout(() => {
                  fetch(avvalSearchUrl, optionsPayment(1))
                    .then(response => response.json())
                    .then(rescheck => {
                      if (rescheck && rescheck.res_id === 103) {
                        setReceipt(orderId, {
                          check_number: orderId,
                          receipt: {
                            TerminalID: getValue(rescheck, "data.terminal_id"),
                            ReceiptSeq: getValue(
                              rescheck,
                              "data.tartib_raqami",
                            ),
                            DateTime: getValue(rescheck, "data.date_time"),
                            FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                            AppletVersion: getValue(rescheck, "data.versiya"),
                            QRCodeURL: getValue(rescheck, "data.link"),
                            checkID,
                          },
                          type: "RTO",
                          rto_payment_type: _.get(values, "paymentType", CASH),
                        })
                          .then(resAvval => {
                            if (resAvval) {
                              setIsRefreshOrderData(!isRefreshOrderData);

                              refreshOrderList();

                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                              props.showSuccessMessage(
                                getLocalisationMessage(
                                  "successfully_created",
                                  "Successfully Created",
                                ),
                              );

                              setCheckID(0);
                            }
                          })
                          .catch(err => {
                            setIsLoadingRTOPrice(false);
                            props.showErrorMessage(err);
                          })
                          .finally(() => {
                            setIsLoadingRTOPrice(false);
                            getPDF(
                              stateReceiptRTO,
                              setStateReceiptRTO,
                              IS_PRINTING,
                              orderId,
                              props.showErrorMessage,
                            );
                            setIsOpenRTOPrice(false);
                            setIsOpenBrief(true);
                          });
                      } else {
                        fetch(urlAVVAL, {
                          method: "POST",
                          body: JSON.stringify(
                            createAVVALDataRTO(
                              _.get(values, "paymentType", CASH),
                              _.get(r, "data", {}),
                            ),
                          ),
                        })
                          .then(response => response.json())
                          .then(res => {
                            if (res && getValue(res, "code") === "1") {
                              props.showErrorMessage(
                                getLocalisationMessage(
                                  fp.toString(getValue(res, "resInfo.code")),
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (res && getValue(res, "code") === "-1") {
                              props.showErrorMessage(
                                getLocalisationMessage(
                                  fp.toString(getValue(res, "resInfo")),
                                  getValue(res, "resInfo"),
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (
                              res &&
                              (getValue(res, "resInfo.result.QRCodeURL") ===
                                null ||
                                !getValue(res, "resInfo.result.QRCodeURL"))
                            ) {
                              props.showErrorMessage(
                                getLocalisationMessage(
                                  "the_payment_was_not_successful",
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (res && res.msg === "succes") {
                              if (checkAVVALres(res)) {
                                setReceipt(orderId, {
                                  check_number: orderId,
                                  receipt: {
                                    ..._.get(res, "resInfo.result"),
                                    checkID,
                                  },
                                  type: "RTO",
                                  rto_payment_type: _.get(
                                    values,
                                    "paymentType",
                                    CASH,
                                  ),
                                })
                                  .then(resAvval => {
                                    if (resAvval) {
                                      setIsRefreshOrderData(
                                        !isRefreshOrderData,
                                      );

                                      refreshOrderList();

                                      setTimeout(
                                        () => setIsLoadingRTOPrice(false),
                                        1500,
                                      );
                                      props.showSuccessMessage(
                                        getLocalisationMessage(
                                          "successfully_created",
                                          "Successfully Created",
                                        ),
                                      );
                                    }

                                    setCheckID(0);
                                  })
                                  .catch(e => {
                                    setIsLoadingRTOPrice(false);
                                    props.showErrorMessage(e);
                                  })
                                  .finally(() => {
                                    setIsOpenRTOPrice(false);
                                    getPDF(
                                      stateReceiptRTO,
                                      setStateReceiptRTO,
                                      IS_PRINTING,
                                      orderId,
                                      props.showErrorMessage,
                                    );
                                    setIsOpenRTOPrice(false);
                                    setIsOpenBrief(true);
                                  });
                              }
                            } else {
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            }
                          })
                          .catch(() => {
                            props.showErrorMessage(
                              getLocalisationMessage(
                                "user_is_not_connected_to_avval",
                              ),
                            );
                            setTimeout(() => setIsLoadingRTOPrice(false), 1500);
                          });
                      }
                    })
                    .catch(() => {
                      setTimeout(() => setIsLoadingRTOPrice(false), 1500);
                    }, 300);
                }, 1000);
              }
            })
            .catch(err => {
              props.showErrorMessage(err);
              setTimeout(() => setIsLoadingRTOPrice(false), 1500);
            });
        }}
      />

      {isOpenIssuance && (
        <OrderDetailsDialogIssuance2
          errorOtpData={errorOtpData}
          setErrorOtpError={setErrorOtpError}
          showOtpSettings={showOtpSettings}
          isLoadingCOD={isLoadingCOD}
          open={isOpenIssuance}
          isHaveCOD={isHaveCOD}
          chargeItemCOD={_.get(chargeItemCOD, "charge")}
          close={() => {
            setIsOpenIssuance(false);
            setIsOpenBrief(true);
            setIsLoadingCOD(false);
          }}
          initialValues={{
            name: _.get(order, "recipient_data.name", ""),
            without_otp: !showOtpSettings,
          }}
          header={getLocalisationMessage("recipient", "Recipient")}
          orderId={orderId}
          onSubmit={values => {
            setIsLoadingCOD(true);
            if (isHaveCOD) {
              fetch(avvalSearchUrl, optionsPayment())
                .then(response => response.json())
                .then(rescheck => {
                  if (rescheck && rescheck.res_id === 103) {
                    setReceipt(orderId, {
                      check_number: orderId,
                      cod_payment_type: _.get(values, "codPaymentType", CASH),
                      type: "COD",
                      card_type: getValue(values, "cardType"),
                      ppt_id: getValue(values, "pptId"),
                      receipt: {
                        TerminalID: getValue(rescheck, "data.terminal_id"),
                        ReceiptSeq: getValue(rescheck, "data.tartib_raqami"),
                        DateTime: getValue(rescheck, "data.date_time"),
                        FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                        AppletVersion: getValue(rescheck, "data.versiya"),
                        QRCodeURL: getValue(rescheck, "data.link"),
                        checkID,
                      },
                    })
                      .then(() => {
                        updateOrderIssuance(orderId, toSnakeCase(values))
                          .then(() => {
                            uploadOrderSignature(orderId, {
                              name: values.name,
                              order_id: orderId,
                              phone: "",
                              photo_id: values.photoId,
                              signature_id: values.signatureId,
                              type: "delivery_signature",
                            })
                              .then(() => {
                                setIsRefreshOrderData(!isRefreshOrderData);
                                props.showSuccessMessage(
                                  getLocalisationMessage(
                                    "successfully_updated",
                                  ),
                                );
                                setIsOpenIssuance(false);
                                setCheckID(0);
                                setIsOpenBrief(true);
                              })
                              .catch(err => {
                                setIsLoadingCOD(false);
                                props.showErrorMessage(err);
                              })
                              .finally(() => {
                                setIsLoadingCOD(false);
                                getPDF(
                                  stateReceiptCOD,
                                  setStateReceiptCOD,
                                  IS_PRINTING,
                                  orderId,
                                  props.showErrorMessage,
                                );
                                setTimeout(() => {
                                  refreshOrderList();
                                  setRefreshTab(prev => !prev);
                                }, 1000);
                              });
                          })
                          .catch(err => {
                            setErrorOtpError(formatErrorMessage(err));

                            setIsLoadingCOD(false);
                            props.showErrorMessage(err);
                          });
                      })
                      .catch(err => {
                        setIsLoadingCOD(false);
                        props.showErrorMessage(err);
                      });
                  } else {
                    fetch(
                      avvalSearchUrl,
                      options(contentItems[0].commission_tin),
                    )
                      .then(response => response.json())
                      .then(resAvval => {
                        if (resAvval && resAvval.mess === "success") {
                          const QQS = resAvval.data.nds_active ? 12 : 0;
                          fetch(urlAVVAL, {
                            method: "POST",
                            body: JSON.stringify(
                              createAVVALData(
                                _.get(values, "codPaymentType", CASH),
                                getValue(values, "cardType"),
                                getValue(values, "pptId"),
                                QQS,
                              ),
                            ),
                          })
                            .then(response => response.json())
                            .then(res => {
                              if (res && getValue(res, "code") === "1") {
                                props.showErrorMessage(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo.code")),
                                  ),
                                );
                                setIsLoadingCOD(false);
                              } else if (
                                res &&
                                getValue(res, "code") === "-1"
                              ) {
                                props.showErrorMessage(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo")),
                                    getValue(res, "resInfo"),
                                  ),
                                );
                                setIsLoadingCOD(false);
                              } else if (
                                res &&
                                (getValue(res, "resInfo.result.QRCodeURL") ===
                                  null ||
                                  !getValue(res, "resInfo.result.QRCodeURL"))
                              ) {
                                props.showErrorMessage(
                                  getLocalisationMessage(
                                    "the_payment_was_not_successful",
                                  ),
                                );
                                setIsLoadingCOD(false);
                              } else if (res && res.msg === "succes") {
                                if (checkAVVALres(res)) {
                                  setReceipt(orderId, {
                                    check_number: orderId,
                                    vat_active: resAvval.data.nds_active,
                                    cod_payment_type: _.get(
                                      values,
                                      "codPaymentType",
                                      CASH,
                                    ),
                                    type: "COD",
                                    receipt: {
                                      ..._.get(res, "resInfo.result"),
                                      checkID,
                                    },
                                  })
                                    .then(() => {
                                      updateOrderIssuance(
                                        orderId,
                                        toSnakeCase(values),
                                      )
                                        .then(() => {
                                          uploadOrderSignature(orderId, {
                                            name: values.name,
                                            order_id: orderId,
                                            phone: "",
                                            photo_id: values.photoId,
                                            signature_id: values.signatureId,
                                            type: "delivery_signature",
                                          })
                                            .then(res2 => {
                                              setIsRefreshOrderData(
                                                !isRefreshOrderData,
                                              );
                                              props.showSuccessMessage(
                                                res2.status,
                                              );
                                              setIsOpenIssuance(false);
                                              setCheckID(0);
                                              setIsOpenBrief(true);
                                              setTimeout(() => {
                                                refreshOrderList();
                                                setRefreshTab(prev => !prev);
                                              }, 1000);
                                            })
                                            .catch(err => {
                                              setIsLoadingCOD(false);
                                              props.showErrorMessage(err);
                                            })
                                            .finally(() => {
                                              setIsLoadingCOD(false);
                                              getPDF(
                                                stateReceiptCOD,
                                                setStateReceiptCOD,
                                                IS_PRINTING,
                                                orderId,
                                                props.showErrorMessage,
                                              );
                                            });
                                        })
                                        .catch(err => {
                                          setErrorOtpError(
                                            formatErrorMessage(err),
                                          );

                                          setIsLoadingCOD(false);
                                          props.showErrorMessage(err);
                                        });
                                    })
                                    .catch(err => {
                                      setIsLoadingCOD(false);
                                      props.showErrorMessage(err);
                                    });
                                }
                              } else {
                                setIsLoadingCOD(false);
                              }
                            })
                            .catch(() => {
                              setIsLoadingCOD(false);
                              props.showErrorMessage(
                                getLocalisationMessage(
                                  "user_is_not_connected_to_avval",
                                ),
                              );
                            });
                        }
                      });
                  }
                })
                .catch(() => {
                  setIsLoadingCOD(false);
                  props.showErrorMessage(
                    getLocalisationMessage("user_is_not_connected_to_avval"),
                  );
                });
            } else {
              updateOrderIssuance(orderId, toSnakeCase(values))
                .then(() => {
                  uploadOrderSignature(orderId, {
                    name: values.name,
                    order_id: orderId,
                    phone: "",
                    photo_id: values.photoId,
                    signature_id: values.signatureId,
                    type: "delivery_signature",
                  })
                    .then(res => {
                      setIsRefreshOrderData(!isRefreshOrderData);
                      props.showSuccessMessage(res.status);
                      setIsOpenIssuance(false);
                      setIsOpenBrief(true);
                      setTimeout(() => {
                        refreshOrderList();
                        setRefreshTab(prev => !prev);
                      }, 1000);
                    })
                    .catch(err => props.showErrorMessage(err));
                })
                .catch(err => {
                  setErrorOtpError(formatErrorMessage(err));
                  props.showErrorMessage(err);
                })
                .finally(() => setIsLoadingCOD(false));
            }
          }}
        />
      )}

      <OrderDetailsDialogCustoms
        errorOtpData={errorOtpData}
        setErrorOtpError={setErrorOtpError}
        showOtpSettings={showOtpSettings}
        isLoading={isLoadingCOD}
        open={isOpenIssuanceCustoms}
        isHave={isHaveCustom}
        chargeItem={_.get(chargeItemCustom, "charge")}
        chargeItemCustomFeePercentage={_.get(
          chargeItemCustomFeePercentage,
          "charge",
        )}
        close={() => {
          setIsOpenIssuanceCustoms(false);
          setIsOpenBrief(true);
        }}
        initialValues={{
          name: _.get(order, "recipient_data.name", ""),
          without_otp: !showOtpSettings,
        }}
        header={getLocalisationMessage("recipient", "Recipient")}
        orderId={orderId}
        onSubmit={values => {
          setIsLoadingCOD(true);
          fetch(avvalSearchUrl, optionsPaymentCustoms())
            .then(response => response.json())
            .then(rescheck => {
              if (rescheck && rescheck.res_id === 103) {
                setReceipt(orderId, {
                  check_number: orderId,
                  payment_type: _.get(values, "paymentType", CASH),
                  type: "CUSTOMS",
                  card_type: getValue(values, "cardType"),
                  ppt_id: getValue(values, "pptId"),
                  receipt: {
                    TerminalID: getValue(rescheck, "data.terminal_id"),
                    ReceiptSeq: getValue(rescheck, "data.tartib_raqami"),
                    DateTime: getValue(rescheck, "data.date_time"),
                    FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                    AppletVersion: getValue(rescheck, "data.versiya"),
                    QRCodeURL: getValue(rescheck, "data.link"),
                    checkID,
                  },
                })
                  .then(() => {
                    updateOrderIssuance(orderId, toSnakeCase(values))
                      .then(() => {
                        uploadOrderSignature(orderId, {
                          name: values.name,
                          order_id: orderId,
                          phone: "",
                          photo_id: values.photoId,
                          signature_id: values.signatureId,
                          type: "delivery_signature",
                        })
                          .then(() => {
                            setIsRefreshOrderData(!isRefreshOrderData);
                            props.showSuccessMessage(
                              getLocalisationMessage("successfully_updated"),
                            );
                            setIsOpenIssuanceCustoms(false);
                            setCheckID(0);
                            setIsOpenBrief(true);
                          })
                          .catch(err => {
                            setIsLoadingCOD(false);
                            props.showErrorMessage(err);
                          })
                          .finally(() => {
                            setIsLoadingCOD(false);
                            getPDF(
                              stateReceiptCustoms,
                              setStateReceiptCustoms,
                              IS_PRINTING,
                              orderId,
                              props.showErrorMessage,
                            );
                            setTimeout(() => {
                              refreshOrderList();
                              setRefreshTab(prev => !prev);
                            }, 1000);
                          });
                      })
                      .catch(err => {
                        setErrorOtpError(formatErrorMessage(err));

                        setIsLoadingCOD(false);
                        props.showErrorMessage(err);
                      });
                  })
                  .catch(err => {
                    setIsLoadingCOD(false);
                    props.showErrorMessage(err);
                  });
              } else {
                fetch(urlAVVAL, {
                  method: "POST",
                  body: JSON.stringify(
                    createAVVALDataCustoms(
                      _.get(values, "paymentType", CASH),
                      getValue(values, "cardType"),
                      getValue(values, "pptId"),
                    ),
                  ),
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res && getValue(res, "code") === "1") {
                      props.showErrorMessage(
                        getLocalisationMessage(
                          fp.toString(getValue(res, "resInfo.code")),
                        ),
                      );
                      setIsLoadingCOD(false);
                    } else if (res && getValue(res, "code") === "-1") {
                      props.showErrorMessage(
                        getLocalisationMessage(
                          fp.toString(getValue(res, "resInfo")),
                          getValue(res, "resInfo"),
                        ),
                      );
                      setIsLoadingCOD(false);
                    } else if (
                      res &&
                      (getValue(res, "resInfo.result.QRCodeURL") === null ||
                        !getValue(res, "resInfo.result.QRCodeURL"))
                    ) {
                      props.showErrorMessage(
                        getLocalisationMessage(
                          "the_payment_was_not_successful",
                        ),
                      );
                      setIsLoadingCOD(false);
                    } else if (res && res.msg === "succes") {
                      if (checkAVVALres(res)) {
                        setReceipt(orderId, {
                          check_number: orderId,
                          payment_type: _.get(values, "paymentType", CASH),
                          type: "CUSTOMS",
                          receipt: {
                            ..._.get(res, "resInfo.result"),
                            checkID,
                          },
                        })
                          .then(() => {
                            updateOrderIssuance(orderId, toSnakeCase(values))
                              .then(() => {
                                uploadOrderSignature(orderId, {
                                  name: values.name,
                                  order_id: orderId,
                                  phone: "",
                                  photo_id: values.photoId,
                                  signature_id: values.signatureId,
                                  type: "delivery_signature",
                                })
                                  .then(res2 => {
                                    setIsRefreshOrderData(!isRefreshOrderData);
                                    props.showSuccessMessage(res2.status);
                                    setIsOpenIssuanceCustoms(false);
                                    setCheckID(0);
                                    setIsOpenBrief(true);
                                  })
                                  .catch(err => {
                                    setIsLoadingCOD(false);
                                    props.showErrorMessage(err);
                                  })
                                  .finally(() => {
                                    setIsLoadingCOD(false);
                                    getPDF(
                                      stateReceiptCustoms,
                                      setStateReceiptCustoms,
                                      IS_PRINTING,
                                      orderId,
                                      props.showErrorMessage,
                                    );
                                    setTimeout(() => {
                                      refreshOrderList();
                                      setRefreshTab(prev => !prev);
                                    }, 1000);
                                  });
                              })
                              .catch(err => {
                                setErrorOtpError(formatErrorMessage(err));

                                setIsLoadingCOD(false);
                                props.showErrorMessage(err);
                              });
                          })
                          .catch(err => {
                            setIsLoadingCOD(false);
                            props.showErrorMessage(err);
                          });
                      }
                    } else {
                      setIsLoadingCOD(false);
                    }
                  })
                  .catch(() => {
                    setIsLoadingCOD(false);
                    props.showErrorMessage(
                      getLocalisationMessage("user_is_not_connected_to_avval"),
                    );
                  });
              }
            })
            .catch(() => {
              setIsLoadingCOD(false);
            });
        }}
      />

      <BatchRTOOrdersFormDialogWrapper
        open={isOrderOpenRTO}
        isHybrid={isHybrid}
        initialValues={{
          orderBarcodes: [orderBarcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOrderOpenRTO(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={response => {
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOrderOpenRTO(false);
          setSelectBin([]);
          setSelectBean({});
          setLocationQuery(fp.set("batch_id", _.get(response, "data.id")));
          setTimeout(() => refreshOrderList(), 1000);
        }}
      />

      <BatchAssignOrdersToDriverFormDialogWrapper
        open={isOpenDispatch}
        disableBarcodes={true}
        initialValues={{
          orderBarcodes: [orderBarcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOpenDispatch(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={res => {
          setSelectBin([]);
          setSelectBean({});
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOpenDispatch(false);
          setLocationQuery(fp.set("batch_id", _.get(res, "data.id")));
          setTimeout(() => refreshOrderList(), 1000);
        }}
      />

      <OfflineBatchReturningUpdateDialogWrapper
        open={isBulkReturning}
        userWarehouseId={userWarehouseId}
        selectedOrderBarcodes={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        filter={filter}
        isBulkReturning={true}
        onRequestClose={() => {
          setIsBulkReturning(false);
        }}
        onSubmitSuccess={response => {
          const id = fp.get("data.id", response);
          if (id) {
            setLocationQuery(fp.set("batch_id", id));

            setIsBulkReturning(false);
            setSelectedItems([]);
            setSelectBin([]);
            setSelectBean({});
            // setExcludeSelectedItems([]);
            setTimeout(() => refreshOrderList(), 1000);
          }
        }}
      />

      <OfflineBatchRTOOrdersFormDialogWrapper
        open={isOpenRTO}
        userWarehouseId={userWarehouseId}
        selectedOrderBarcodes={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        filter={filter}
        onRequestClose={() => {
          setIsOpenRTO(false);
        }}
        onSubmitSuccess={response => {
          const id = fp.get("data.id", response);
          if (id) {
            setSelectBin([]);
            setSelectBean({});
            setLocationQuery(fp.set("batch_id", id));
            setSelectedItems([]);
            // setExcludeSelectedItems([]);
            setIsOpenRTO(false);
            setTimeout(() => refreshOrderList(), 1000);
          }
        }}
      />

      <OfflineBatchRedirectOrdersFormDialogWrapper
        open={showBatchRedirected.open}
        setSelectedItems={setSelectedItems}
        // setExcludeSelectedItems={setExcludeSelectedItems}
        setFilter={setFilter}
        selectedOrderBarcodes={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        filter={filter}
        onSubmitSuccess={() => {
          setSelectedItems([]);
          setSelectBin([]);
          // setExcludeSelectedItems([]);
          setSelectBean({});
          setRefreshTab(prev => !prev);
          setFilter({
            ...savedFilter,
            init: true,
            page: 0,
            size: 20,
            service_type_id: null,
            barcodes: [],
          });
          setShowBatchRedirected({
            open: false,
            is_redirected: false,
          });
        }}
        isRedirected={showBatchRedirected.is_redirected}
        onRequestClose={() => {
          setShowBatchRedirected({
            open: false,
            is_redirected: false,
          });
        }}
      />

      <AdminOfflineOutboundOrderSortingBatchUpdateDialogWrapper
        open={isOpenBatchUpdate}
        isSorting={true}
        selectedOrderBarcodes={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        filter={filter}
        onRequestClose={() => setIsOpenBatchUpdate(false)}
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={response => {
          setIsOpenBatchUpdate(false);
          setSelectBin([]);
          setSelectBean({});
          setSelectedItems([]);
          // setExcludeSelectedItems([]);
          setLocationQuery(fp.set("batch_id", fp.get("data.id", response)));
          setTimeout(() => refreshOrderList(), 1000);
        }}
      />

      <OfflineBatchAssignOrdersToDriverFormDialogWrapper
        open={isAssignDriver}
        userWarehouseId={userWarehouseId}
        selectedOrderBarcodes={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        filter={filter}
        onRequestClose={() => {
          setIsAssignDriver(false);
        }}
        onSubmitSuccess={response => {
          const id = fp.get("data.id", response);
          if (id) {
            setSelectBin([]);
            setSelectBean({});
            setLocationQuery(fp.set("batch_id", id));
            setSelectedItems([]);
            // setExcludeSelectedItems([]);
            setIsAssignDriver(false);
            setTimeout(() => refreshOrderList(), 1000);
          }
        }}
      />

      <OfflineOrderSortingRedirectOrderDialog
        open={Boolean(isOpenRedirect)}
        order={order}
        initialValues={{
          jurisdiction: getValue(order, "to_jurisdiction", {}),
          barcode: order.barcode,
        }}
        onSubmit={({ barcode, index }) =>
          updateRecipientPostcode({
            index: fp.get("name", index),
            barcodes: [barcode],
            is_redirected: true,
          }).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          setSelectBin([]);
          setSelectBean({});
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated",
              "Successfully Updated",
            ),
          );
          setIsOpenRedirect(null);
          setRefreshTab(prev => !prev);
          setFilter({
            ...savedFilter,
            init: true,
            page: 0,
            size: 20,
            service_type_id: null,
            barcodes: [],
          });
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
        }}
        onRequestClose={() => setIsOpenRedirect(null)}
      />

      <OfflineOrderSortingRedirectOrderDialog
        open={Boolean(openRedirectOrder)}
        showOrderData={true}
        order={order}
        initialValues={{
          jurisdiction: getValue(order, "to_jurisdiction", {}),
          barcode: order.barcode,
        }}
        onSubmit={({ barcode, index }) =>
          updateRecipientPostcode({
            index: fp.get("name", index),
            barcodes: [barcode],
            is_redirected: true,
          }).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          setSelectBin([]);
          setSelectBean({});
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated",
              "Successfully Updated",
            ),
          );
          setRefreshTab(prev => !prev);
          setFilter({
            ...savedFilter,
            init: true,
            page: 0,
            size: 20,
            service_type_id: null,
            barcodes: [],
          });
          setOpenRedirectOrder(null);
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
        }}
        onRequestClose={() => setOpenRedirectOrder(null)}
      />

      {fp.toFinite(canceledBatchId) > 0 && (
        <AdminBatchCanceledUpdatesItemDialogWrapper
          batchId={fp.toFinite(canceledBatchId)}
          onRequestClose={() => {
            setFilter({
              ...savedFilter,
              init: true,
              page: 0,
              size: 20,
              service_type_id: null,
              barcodes: [],
            });
            setLocationQuery(fp.unset("cancel_batch_id"));
          }}
        />
      )}

      <AdminOutboundOrderSortingBatchUpdateDialogWrapper
        open={showVerifyBinDialog}
        isLoading={isLoading}
        selectedOrderBarcodes={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        filter={filter}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        warehouse={userWarehouseId}
        onSubmitSuccess={response => {
          const id = fp.get("data.id", response);
          if (id) {
            setLocationQuery(fp.set("batch_id", id));
            setShowVerifyBinDialog(false);
            setSelectedItems([]);
            setSelectBin([]);
            setSelectBean({});
            // setExcludeSelectedItems([]);
            setTimeout(() => refreshOrderList(), 1000);
          }
        }}
        onRequestClose={() => {
          setShowConfirm(true);
        }}
      />

      {showConfim && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => {
            setShowConfirm(false);
          }}
          onConfirm={() => {
            setShowConfirm(false);
            setShowVerifyBinDialog(false);
          }}
          title={
            getLocalisationMessage(
              "are_you_sure_you_want_to_close_order_verification_dialog",
            ) || "Are you sure you want to close order verification dialog?"
          }
        >
          <p>
            {getLocalisationMessage("all_order_scanned_verification_removed")} (
            <span style={{ fontStyle: "italic" }}>
              {getLocalisationMessage("not_from_sorting_list")}
            </span>
            )
          </p>
        </ConfirmDialog>
      )}

      <OfflineBatchCanceledUpdateDialogWrapper
        open={isCanceled}
        userWarehouseId={userWarehouseId}
        selectedOrderBarcodes={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        filter={filter}
        onRequestClose={() => {
          setIsCanceled(false);
        }}
        setIsCanceled={setIsCanceled}
        onSubmitSuccess={response => {
          const id = fp.get("data.id", response);
          if (id) {
            setLocationQuery(fp.set("cancel_batch_id", id));
            setIsCanceled(false);
            setSelectedItems([]);
            setSelectBin([]);
            setSelectBean({});
            // setExcludeSelectedItems([]);
            setTimeout(() => refreshOrderList(), 1000);
          }
        }}
      />

      <AdminOrderDetailsDialogWrapperForOrderList
        isRefreshOrderData={isRefreshOrderData}
        order={fromJS(order)}
        isOpen={!batchId && isOpenBrief}
        setBatchId={setResBatchId}
        setIsOpenIssuance={
          isHaveCustom ? setIsOpenIssuanceCustoms : setIsOpenIssuance
        }
        refreshOrderData={() => setIsRefreshOrderData(prev => !prev)}
        setIsOpenBrief={setIsOpenBrief}
        orderId={orderId}
        setIsOpenDispatch={setIsOpenDispatch}
        setIsOpenRTOPrice={setIsOpenRTOPrice}
        setIsOpenRedirect={setIsOpenRedirect}
        setIsOpenRTO={setIsOrderOpenRTO}
        setOrderId={id => setOrderId(id)}
        refreshOrderList={refreshOrderList}
        onRequestClose={() => {
          setIsOpenBrief(false);
          setOrderId(null);
        }}
      />

      <OfflineOrderSortingTabs
        onTabChange={props.onTabChange}
        tabValue={ORDER}
        tabCount={tabCount}
      />

      <FlexBox
        style={{
          height: "100%",
          overflowY: "auto",
          background: "white",
          borderRadius: 4,
          boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        }}
        flex={true}
        direction="column"
      >
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <OfflineOrderSortingChips
            selectBin={selectBin}
            total={total}
            sortingServiceTypes={sortingServiceTypes}
            sortingBeans={sortingBeans}
            onServiceTypeFilter={onServiceTypeFilter}
            onBeanFilter={onBeanFilter}
            onBeanUnFilter={onBeanUnFilter}
            filter={filter}
            selectBean={selectBean}
            isSelected={isSelected}
          />

          <OfflineOrderSortingTable
            sortingBeans={sortingBeans}
            setSelectBean={setSelectBean}
            filter={filter}
            setSelectBin={setSelectBin}
            setFilter={setFilter}
            isLoading={isLoading}
            isCheckboxEnabled={true}
            isSelected={isSelected}
            selectedItems={selectedItems}
            // excludeSelectedItems={excludeSelectedItems}
            setSelectedItems={setSelectedItems}
            // setExcludeSelectedItems={setExcludeSelectedItems}
            size="medium"
            list={sortingItems}
            setOrderId={setOrderId}
            setBatchId={setBatchId}
            setIsOpenBrief={setIsOpenBrief}
            total={total}
            page={filter.page}
            rowsPerPage={filter.size}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            columns={[
              {
                label: getLocalisationMessage("barcode", "Barcode"),
                keyName: "barcode",
                name: "Barcode",
                type: DIALOG,
                align: "center",
              },
              {
                type: RENDER,
                name: "service_type",
                label: getLocalisationMessage("shipment_type"),
                render: row =>
                  row.type === SIMPLE
                    ? formatText(getValue(row, "category"))
                    : getValue(row, "service_type.name", "N/A"),
              },
              {
                type: RENDER,
                name: "paid",
                label: getLocalisationMessage("paid"),
                render: row =>
                  row.paid
                    ? getLocalisationMessage("yes")
                    : getLocalisationMessage("no"),
              },
              {
                type: RENDER,
                name: "from_jurisdiction.name",
                label: getLocalisationMessage("from_city", "From City"),
                render: row => (
                  <MultiLineCell
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "from_jurisdiction.name", "N/A")}
                      </span>
                    }
                    secondLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "from_postcode.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
              {
                type: RENDER,
                name: "to_jurisdiction.name",
                label: getLocalisationMessage("to_city", "To City"),
                render: row => (
                  <MultiLineCell
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "to_jurisdiction.name", "N/A")}
                      </span>
                    }
                    secondLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "to_postcode.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
              {
                type: RENDER,
                name: "weight",
                label: getLocalisationMessage("weight", "weight"),
                render: row => (
                  <span style={{ padding: 10 }}>
                    {formatWeight(getValue(row, "weight"), "N/A")}
                  </span>
                ),
              },
              {
                type: RENDER,
                name: "flow",
                label: getLocalisationMessage("flow", "flow"),
                render: row => (
                  <span style={{ padding: 10 }}>
                    {getLocalisationMessage(
                      getValue(row, "flow", "N/A").toLowerCase(),
                    )}
                  </span>
                ),
              },
              {
                type: RENDER,
                name: "flow",
                label: getLocalisationMessage("status", "Status"),
                render: row => (
                  <MultiLineCell
                    firstLine={
                      <span
                        style={{
                          color: "#FF9800",
                        }}
                      >
                        {formatOrderStatusCodeForLocalisation(
                          row.status,
                          getLocalisationMessage,
                        )}
                      </span>
                    }
                    secondLine={
                      <DateTimeCell
                        date={getValue(row, "last_status_date", "N/A")}
                      />
                    }
                  />
                ),
              },
              {
                name: getLocalisationMessage(
                  "redirect_order",
                  "Redirect Order",
                ),
                label: getLocalisationMessage("action", "Action"),
                dispatch: id => {
                  setOpenRedirectOrder(id);
                },
              },
            ]}
            headerActions={
              <FlexBox
                align={ALIGN_CENTER}
                style={{
                  padding: ".25rem 1rem",
                  width: "100%",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                {isSelected ? (
                  <React.Fragment>
                    <FlexBox flex={true}>
                      {total}{" "}
                      {getLocalisationMessage(
                        "items_selected",
                        "item(s) selected",
                      )}
                      .
                    </FlexBox>
                  </React.Fragment>
                ) : selectedItems.length ? (
                  <React.Fragment>
                    <FlexBox flex={true}>
                      {selectedItems.length}{" "}
                      {getLocalisationMessage(
                        "items_selected",
                        "item(s) selected",
                      )}
                      .
                    </FlexBox>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={classes.search} ref={ref}>
                      <ChipTextField
                        style={{
                          maxHeight: divHeight > 86 && "5.8rem",
                          overflow: divHeight > 86 && "auto",
                        }}
                        fullWidth={true}
                        addOnBlur={false}
                        clearOnBlur={false}
                        value={filter.barcodes}
                        disableUnderline={true}
                        placeholder={getLocalisationMessage("search")}
                        hintText={getLocalisationMessage("search")}
                        onChange={v =>
                          setFilter({
                            ...filter,
                            init: Boolean(filter.service_type_id),
                            barcodes: v,
                          })
                        }
                      />
                    </div>
                  </React.Fragment>
                )}

                {props.isOperator && (
                  <CustomButton
                    className={classes.btn}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => setIsOpenBatchUpdate(true)}
                  >
                    {getLocalisationMessage("prepared_for_transit_button")}
                  </CustomButton>
                )}

                <CustomButton
                  style={{
                    margin: "0 .5rem",
                  }}
                  variant={OUTLINED}
                  color={SECONDARY}
                  onClick={() => setIsAssignDriver(true)}
                >
                  {getLocalisationMessage(
                    "assign_to_driver",
                    "Assign To Driver",
                  )}
                </CustomButton>

                {props.permissions.includes(
                  "CAN_BATCH_UPDATE_AS_DELIVERED",
                ) && (
                  <CustomButton
                    style={{
                      margin: "0 .5rem",
                    }}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => setIsCanceled(true)}
                  >
                    {getLocalisationMessage(
                      "bulk_update_shipments",
                      "Bulk update shipments",
                    )}
                  </CustomButton>
                )}

                {props.permissions.includes("OUTBOUND_BATCH_UPDATE_BUTTON") && (
                  <CustomButton
                    style={{
                      margin: "0 .5rem",
                    }}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => setIsBulkReturning(true)}
                  >
                    {getLocalisationMessage(
                      "bulk_returning_orders",
                      "Bulk returning orders",
                    )}
                  </CustomButton>
                )}

                <FlexBox align="center" direction="row">
                  {!isEmpty(filter.bean) && !props.isOperator && (
                    <CustomButton
                      style={{
                        margin: "0 .5rem",
                      }}
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => setShowVerifyBinDialog(true)}
                    >
                      {getLocalisationMessage("prepared_for_transit_button")}
                    </CustomButton>
                  )}

                  <MenuButtonMore desktop={false}>
                    <MenuItem
                      onClick={() => {
                        setIsLoading(true);
                        syncShipment()
                          .then(r => {
                            if (r && r.status === "success") {
                              refreshOrderList();
                              setIsLoading(false);
                            }
                          })
                          .catch(() => {
                            refreshOrderList();
                            setIsLoading(false);
                          });
                      }}
                    >
                      {getLocalisationMessage(
                        "regroups_orders",
                        "Regroups Orders",
                      )}
                    </MenuItem>

                    {props.permissions.includes(
                      "ORDER_SORTING_CLEAR_OUTBOUND_ITEMS",
                    ) && (
                      <MenuItem
                        onClick={() => {
                          clearShipment({
                            ...props.filter,
                            type: "ORDER",
                            barcodes: selectedItems,
                          })
                            .then(r => {
                              if (r && r.status === "success") {
                                setFilter({
                                  ...savedFilter,
                                  init: true,
                                  page: 0,
                                  size: 20,
                                  service_type_id: null,
                                  barcodes: [],
                                });

                                setRefreshTab(prev => !prev);
                                setSelectedItems([]);
                                setSelectBin([]);
                                setSelectBean({});

                                props.showSuccessMessage(
                                  getLocalisationMessage(
                                    "successfully_deleted",
                                  ),
                                );
                              }
                            })
                            .catch(error => {
                              props.showErrorMessage(error);
                            });
                        }}
                      >
                        {getLocalisationMessage("delete", "Delete")}
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={() => {
                        setIsOpenRTO(true);
                      }}
                    >
                      {getLocalisationMessage(
                        "batch_rto_orders",
                        "Batch RTO Orders",
                      )}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setShowBatchRedirected({
                          open: true,
                          is_redirected: true,
                        });
                      }}
                    >
                      {getLocalisationMessage(
                        "batch_redirect_orders",
                        "Batch Redirect Orders",
                      )}
                    </MenuItem>
                  </MenuButtonMore>
                </FlexBox>
              </FlexBox>
            }
          />
        </CardContent>
      </FlexBox>
    </FlexBox>
  );
}

AdminOrderOfflineOutboundSortingTableContainer.propTypes = {
  setIsLoading: PropTypes.func,
  onTabChange: PropTypes.func,
  userWarehouseId: PropTypes.number,
  tabCount: PropTypes.object,
  setFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  sortingItems: PropTypes.array,
  sortingServiceTypes: PropTypes.array,
  permissions: PropTypes.array,
  sortingBeans: PropTypes.array,
  total: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  selectedItems: PropTypes.func,
  // excludeSelectedItems: PropTypes.func,
  setSelectedItems: PropTypes.func,
  // setExcludeSelectedItems: PropTypes.func,
  refreshOrderList: PropTypes.func,
  onBeanFilter: PropTypes.func,
  onBeanUnFilter: PropTypes.func,
  onServiceTypeFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setRefreshTab: PropTypes.func,
  filter: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  isOperator: PropTypes.bool,
  isOtpSettingsServiceType: PropTypes.bool,
  isOtpSettingsTransportationType: PropTypes.bool,
  selectBin: PropTypes.array,
  setSelectBin: PropTypes.func,
  selectBean: PropTypes.array,
  savedFilter: PropTypes.object,
  userIdAVVAL: PropTypes.number,
  setSelectBean: PropTypes.func,
  isSelected: PropTypes.bool,
  setIsSelected: PropTypes.func,
};

const mapStateToProps = state => {
  const userRoles = getUser(state).get("roles") || [];
  return {
    userIdAVVAL: getAVVALUserId(state),
    isOtpSettingsServiceType: isOtpSettingsServiceType(state),
    isOtpSettingsTransportationType: isOtpSettingsTransportationType(state),
    userWarehouseId: getUserWarehouseId(state),
    permissions: getUserPermissions(state),
    isOperator: hasRole(userRoles, ROLE_OPERATOR),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  };
};

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

export default enhancer(AdminOrderOfflineOutboundSortingTableContainer);
