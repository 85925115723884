import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toTermsSettingsFilter } from "../../helpers/TermsSettingFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  UZBEK_LANGUAGE,
  ARABIC_LANGUAGE,
  FRENCH_LANGUAGE,
  ENGLISH_LANGUAGE,
  RUSSIAN_LANGUAGE,
  SERBIAN_LANGUAGE,
  SPANISH_LANGUAGE,
  TURKISH_LANGUAGE,
  HUNGARIAN_LANGUAGE,
  MONGOLIAN_LANGUAGE,
} from "../../constants/LocaleTypes";
import {
  TERMS_SETTINGS_ITEM_URL,
  TERMS_SETTINGS_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  deleteTermsSettings,
  getTermsSettingsList,
} from "../../api/admin/AdminTermsApi";
import AdminTermsFilterWrapper from "../../wrappers/admin/AdminTermsFilterWrapper";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";

function formatLanguage(code, getLocalisationMessage) {
  switch (code) {
    case RUSSIAN_LANGUAGE:
      return getLocalisationMessage("russian", "Russian");
    case UZBEK_LANGUAGE:
      return getLocalisationMessage("uzbek", "Uzbek");
    case TURKISH_LANGUAGE:
      return getLocalisationMessage("turkish", "Turkish");
    case FRENCH_LANGUAGE:
      return getLocalisationMessage("french", "French");
    case SERBIAN_LANGUAGE:
      return getLocalisationMessage("serbian", "Serbian");
    case MONGOLIAN_LANGUAGE:
      return getLocalisationMessage("mongolian", "Mongolian");
    case SPANISH_LANGUAGE:
      return getLocalisationMessage("spanish", "Spanish");
    case ARABIC_LANGUAGE:
      return getLocalisationMessage("arabic", "Arabic");
    case ENGLISH_LANGUAGE:
      return getLocalisationMessage("english", "English");
    case HUNGARIAN_LANGUAGE:
      return getLocalisationMessage("hungarian", "Hungarian");
    default:
      return getLocalisationMessage("english", "English");
  }
}

function formatTrueFalseOption(x, getLocalisationMessage) {
  switch (x) {
    case true:
      return getLocalisationMessage("yes", "Yes");
    case false:
      return getLocalisationMessage("no", "No");
    default:
      return getLocalisationMessage("na", "N/A");
  }
}

const TERMS_FILTER_DIALOG_HASH = "#TFDH";

const enhancer = compose(
  useSheet({
    terms: {
      flex: "1 1 0%",
      display: "flex",
      marginTop: "12px",
      "& > div": { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    },
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
    appBarRightActionButton: {
      color: "#fff",
      top: "-7px",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toTermsSettingsFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualWithoutFunctions);
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilChanged("filter", isEqualData)
          .switchMap(props =>
            getTermsSettingsList(props.filter)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .startWith({})
          .map(response => fromJS(response))
          .map(response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          listResponseStream,
          (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }),
        );
      },
    ),
  ),
);

AdminTermsSettingsList.propTypes = {
  isLoading1: PropTypes.bool,
  sheet: PropTypes.object,
  location: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  onRequestRefresh: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  formInitialValues: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminTermsSettingsList(props) {
  const { getLocalisationMessage } = props;
  const removeId = fp.toFinite(props.location.query.remove);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("terms_settings", "Terms Settings")}
    >
      <FormDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset("remove"))}
        onSubmit={() =>
          deleteTermsSettings(removeId).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("remove"));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_terms",
          "Are you sure you want to remove this Terms?",
        )}
      </FormDialog>

      <AdminTermsFilterWrapper
        open={props.location.hash === TERMS_FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />

      <DataList
        overscanRowCount={10}
        totalCount={props.total}
        rowCount={props.list.size}
        isLoading={props.isLoading}
        filter={props.filter}
        onFilterChange={props.setLocationQueryFilter}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <div>
            <Link to={TERMS_SETTINGS_CREATE_URL}>
              <IconButton
                tooltip={getLocalisationMessage(
                  "create_new_terms",
                  "Create New Terms",
                )}
              >
                <Add />
              </IconButton>
            </Link>
            <Link to={updateHash(props.location, TERMS_FILTER_DIALOG_HASH)}>
              <IconButton
                tooltip={props.getLocalisationMessage(
                  "terms_filter",
                  "Terms Filter",
                )}
              >
                <FilterList />
              </IconButton>
            </Link>
          </div>
        }
      >
        <DataListColumn
          label={getLocalisationMessage("id", "ID")}
          width={104}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              <Link to={TERMS_SETTINGS_ITEM_URL + row.cellData.get("id")}>
                {row.cellData.get("id")}
              </Link>
            </strong>
          )}
        />
        <DataListColumn
          label={getLocalisationMessage("name", "Name")}
          width={100}
          cellRenderer={row => row.cellData.get("name")}
          dataKey="name"
          justifyContent="center"
        />
        <DataListColumn
          label={getLocalisationMessage("language", "Language")}
          width={100}
          cellRenderer={row =>
            formatLanguage(
              row.cellData.get("language_code"),
              getLocalisationMessage,
            )
          }
          dataKey="language_code"
          disableSort={true}
          justifyContent="center"
        />
        <DataListColumn
          label={getLocalisationMessage("terms_type", "Terms Type")}
          width={100}
          cellRenderer={row =>
            getLocalisationMessage(
              row.cellData.get("terms_type"),
              formatText(row.cellData.get("terms_type")),
            )
          }
          dataKey="terms_type"
          disableSort={true}
          justifyContent="center"
        />
        <DataListColumn
          label={getLocalisationMessage("visible", "Visible")}
          width={100}
          cellRenderer={row =>
            formatTrueFalseOption(
              row.cellData.get("enabled"),
              getLocalisationMessage,
            )
          }
          dataKey="enabled"
          disableSort={true}
          justifyContent="center"
        />
        <DataListColumn
          label={getLocalisationMessage("required", "Required")}
          width={100}
          cellRenderer={row =>
            formatTrueFalseOption(
              row.cellData.get("required"),
              getLocalisationMessage,
            )
          }
          dataKey="required"
          disableSort={true}
          justifyContent="center"
        />
        <DataListColumn
          label={getLocalisationMessage("action", "Action")}
          width={200}
          dataKey="action"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Link
              to={updateQuery(props.location, {
                remove: row.cellData.get("id"),
              })}
            >
              <Button>{getLocalisationMessage("remove", "Remove")}</Button>
            </Link>
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminTermsSettingsList);
