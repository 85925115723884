import React from "react";
import { List, Map, OrderedMap } from "immutable";
import { Link } from "react-router";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DataList, { DataListColumn } from "../data-list/DataList";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceSettings,
  isReverseLogisticEnabled,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getAppName } from "../../../shared/reducers/AppReducer";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { DeleteOutline } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export const SELECTION_PER_PAGE = "per-page";
export const SELECTION_ALL = "all";

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        marketplaceSettings: getMarketplaceSettings(state),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
        reverseLogisticEnabled: isReverseLogisticEnabled(state),
        app: getAppName(state),
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    hoverable: { cursor: "pointer", textDecoration: "underline" },
    actionColumn: { paddingLeft: 0, justifyContent: "center" },
  }),

  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .pluck("list")
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
);

VoiceUploadList.propTypes = {
  sheet: PropTypes.object,
  stats: PropTypes.instanceOf(Map),
  marketplaceSettings: PropTypes.instanceOf(Map),
  altHeader: PropTypes.node,
  cardActionIcons: PropTypes.node,
  withCustomer: PropTypes.bool,
  withLastDriver: PropTypes.bool,
  withAttempts: PropTypes.bool,
  withCreatedTime: PropTypes.bool,
  withPickupTime: PropTypes.bool,
  withEstDeliveryTime: PropTypes.bool,
  withCODStatus: PropTypes.bool,
  withFooter: PropTypes.bool,
  createCustomerHref: PropTypes.func,
  createOrderHref: PropTypes.func,
  createWarehouseHref: PropTypes.func,
  createAddressChangedNotificationHref: PropTypes.func,
  onStatusClick: PropTypes.func,
  onEstimatedDeliveryDateClick: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  onFilterChange: PropTypes.func,
  rowActionIconRenderer: PropTypes.func,
  selectionType: PropTypes.oneOf([SELECTION_ALL, SELECTION_PER_PAGE]),
  withService: PropTypes.bool,
  withLocation: PropTypes.bool,
  location: PropTypes.object,
  withWarehouse: PropTypes.bool,
  withAmount: PropTypes.bool,
  withShipmentNumber: PropTypes.bool,
  withReferenceId: PropTypes.bool,
  withCODAmount: PropTypes.bool,
  withPickupCollectible: PropTypes.bool,
  withDropOffCollectible: PropTypes.bool,
  withPickedUpBy: PropTypes.bool,
  withDeliveredBy: PropTypes.bool,
  chipTextHint: PropTypes.string,
  withMerchantPromiseDate: PropTypes.bool,
  withPieceCount: PropTypes.bool,
  isAdminViewer: PropTypes.bool,
  withTimeslot: PropTypes.bool,
  showPickUpAddress: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  withLogisticType: PropTypes.bool,
  reverseLogisticEnabled: PropTypes.bool,
  app: PropTypes.string,
  updateQuery: PropTypes.func,
  data: PropTypes.instanceOf(OrderedMap),
  onRemoveClick: PropTypes.func,
};

VoiceUploadList.defaultProps = {
  withCustomer: true,
  withLastDriver: true,
  withLocation: true,
  withAttempts: true,
  withAmount: true,
  withCODAmount: false,
  withService: true,
  withWarehouse: true,
  withLogisticType: false,
  withShipmentNumber: true,
  withReferenceId: true,
  withCreatedTime: true,
  withPickupTime: true,
  withEstDeliveryTime: true,
  withCODStatus: true,
  withFooter: true,
  withPieceCount: true,
  withTimeslot: true,
  createCustomerHref: fp.noop,
  createOrderHref: fp.noop,
  createWarehouseHref: fp.noop,
  createAddressChangedNotificationHref: fp.noop,
  maxSearchItems: Infinity,
  selectionType: SELECTION_PER_PAGE,
  withMerchantPromiseDate: false,
  showPickUpAddress: false,
  list: List(),
};

function VoiceUploadList(props) {
  const { getLocalisationMessage } = props;

  return (
    <DataList
      filter={props.filter}
      list={props.data}
      chipTextHint="Search"
      withHeader={true}
      maxSearchItems={Infinity}
      onFilterChange={props.onFilterChange}
      altHeader={props.altHeader}
      selectedRowCount={props.selectedItems ? props.selectedItems.size : 0}
      cardActionIcons={props.cardActionIcons}
      totalCount={props.totalCount}
      rowCount={props.totalCount}
      isLoading={false}
      overscanRowCount={10}
      withFooter={true}
      rowGetter={options => props.data.get(options.index)}
    >
      <DataListColumn
        width={150}
        label="ID"
        dataKey="id"
        justifyContent="center"
        overscanRowCount={4}
        cellRenderer={cell => (
          <Link
            to={updateQuery(props.location, {
              ...props.filter.getDefinedValues(),
              view: cell.cellData ? cell.cellData.get("id") : null,
            })}
          >
            {cell.cellData ? cell.cellData.get("id") : "N/A"}
          </Link>
        )}
      />
      <DataListColumn
        width={150}
        label={getLocalisationMessage("voice_title", "Voice Title")}
        dataKey="title"
        justifyContent="center"
        cellRenderer={cell =>
          cell.cellData ? cell.cellData.get("title") : "N/A"
        }
      />
      <DataListColumn
        width={150}
        label={getLocalisationMessage("voice_title", "File Name")}
        dataKey="name"
        justifyContent="center"
        cellRenderer={cell =>
          cell.cellData ? cell.cellData.get("name") : "N/A"
        }
      />

      <DataListColumn
        width={150}
        label={getLocalisationMessage("delete", "Delete")}
        justifyContent="center"
        cellRenderer={cell => (
          <IconButton
            onClick={() => {
              if (cell.cellData) {
                props.onRemoveClick(cell.cellData.get("id"));
              }
            }}
          >
            <DeleteOutline />
          </IconButton>
        )}
      />
      {/* {props.selectedItems && (
        <DataListCheckbox
          allRowsSelected={() => {
            if (props.list.size > 0) {
              switch (props.selectionType) {
                case SELECTION_ALL:
                  return props.selectedItems.isSuperset(idNumberMap);
                case SELECTION_PER_PAGE:
                  return props.selectedItems.size === props.list.size;
                // no default
              }
            }

            return false;
          }}
          onAllRowsSelect={value => {
            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  value
                    ? props.selectedItems.merge(idNumberMap)
                    : props.selectedItems.filter(
                        (n, id) => !idNumberMap.has(id),
                      ),
                );
              case SELECTION_PER_PAGE:
                return props.onRowSelect(value ? idNumberMap : OrderedMap());

              default:
                return null;
            }
          }}
          rowSelected={row => props.selectedItems.has(row.cellData.get("id"))}
          onRowSelect={row => {
            const id = row.cellData.get("id");
            const orderNumber = row.cellData.get("order_number");

            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  row.selected
                    ? props.selectedItems.set(id, orderNumber)
                    : props.selectedItems.delete(id),
                );

              case SELECTION_PER_PAGE: {
                const selectedItems = row.selected
                  ? props.selectedItems.set(id, orderNumber)
                  : props.selectedItems.delete(id);

                const sortedSelectedItems =
                  selectedItems.size > 1
                    ? idNumberMap.filter((v, k) => selectedItems.has(k))
                    : selectedItems;

                return props.onRowSelect(sortedSelectedItems);
              }

              default:
                return null;
            }
          }}
        />
      )} */}
    </DataList>
  );
}

export default enhancer(VoiceUploadList);
