import { MongoDBSDK } from "./MongoDBSDK";
import fp from "lodash/fp";
import { fromJS, Map } from "immutable";
import { getTokenUserId } from "../../shared/reducers/AuthReducer";
import { getInitialState } from "../helpers/InitialState";
import { isArray } from "lodash";

const arrayResponse = fp.flow(fp.defaultTo([]), fromJS);
const page = "inbound";

const uid = getTokenUserId(getInitialState());

const mapOrders = fp.flow(arrayResponse, (data) =>
  Map().withMutations((map) =>
    data.forEach((item) => {
      map.set(item.get("number"), item);
    }),
  ),
);

class OrderBinValidationCache {
  constructor() {
    this.cache = new Map()
      .set("orders", [])
      .set("queue", [])
      .set("tasks", [])
      .set("tree", []);
  }

  clear() {
    this.cache = undefined;
  }

  batchUpdate(name: string, array: any[], key: string): any[] {
    const cacheItems: any[] = this.cache.get(name);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of array) {
      const found = cacheItems.find((row) => row && row[key] === item[key]);
      if (found) {
        cacheItems[cacheItems.indexOf(found)] = item;
      } else {
        cacheItems.push(item);
      }
    }
    this.cache = this.cache.set(name, cacheItems);
    return cacheItems;
  }

  removeByField(name: string, field: string, values: string): any {
    const cacheItems = this.cache.get(name);
    const result = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const value of values) {
      // eslint-disable-next-line no-restricted-syntax
      const found = cacheItems.find((r) => r && r[field] === value);
      if (found) {
        cacheItems.splice(cacheItems.indexOf(found), 1);
        this.cache = this.cache.set(cacheItems);
        result.push(found);
      }
    }

    return result;
  }

  clearByName(name: string): any[] {
    // console.log("clear", name)
    this.cache = this.cache.set(name, []);
    // console.log("clear after", this.cache.get(name))
    return [];
  }

  getState(name: string): any[] {
    return this.cache.get(name);
  }
}

export class OrderBinValidationDB {
  constructor(socket = null) {
    this.db = new MongoDBSDK(socket);
    this.cache = new OrderBinValidationCache();
  }

  getOrders() {
    const name = "orders";
    return this.db
      .get({ name, page })
      .map((data) => {
        if (data.event === "ADDED" || data.event === "CHANGED") {
          return isArray(data.item) ? data.item : [data.item];
        }
        if (
          data.event === "GET_DATA" ||
          data.event === "BATCH_UPDATE" ||
          data.event === "CLEARED"
        ) {
          // console.log("data", this.cache.getState(name))
          return data.item;
        }
        return data;
      })
      .filter(isArray)
      .map((arr): any[] => {
        // console.log("iiiiii", name, arr)
        if (arr.length) {
          // const result = this.cache.batchUpdate(name, arr, "number");
          // console.log("resposne", result)
          return this.cache.batchUpdate(name, arr, "number");
        }
        return this.cache.clearByName(name);
      })
      .map(mapOrders);
  }

  getBatchTree() {
    return this.db.get("queue").map((response) => new Map(response));
  }
  getBatchScannedOrders() {
    return this.db
      .get({ name: "orders", page })
      .map((data) => {
        if (data.event === "ADDED" || data.event === "CHANGED") {
          return isArray(data.item) ? data.item : [data.item];
        }
        if (data.event === "GET_DATA" || data.event === "BATCH_UPDATE") {
          return data.item;
        }
        return data;
      })
      .filter(isArray)
      .map((response) => response.filter((row) => row.scanned))
      .map(mapOrders);
  }
  getBatchQueue() {
    return this.db.get("queue").map((response) => new Map(response));
  }

  updateBatchOrders(orders) {
    return this.db.batchUpdate({
      name: "orders",
      key: "number",
      type: "deep",
      items: orders,
      page,
    });
  }

  addEventListener(tableName, event) {
    return this.db.addEventListener(tableName, event);
  }

  onTaskUpdateStream() {
    return this.getTasks();
  }

  getTasks() {
    const name = "tasks";
    return this.db
      .get({ name, page })
      .map((data) => {
        // console.log("data", data, uid)
        if (data.event === "DELETED" && data.item) {
          this.cache.removeByField(name, "number", [data.item.number]);
          return this.cache.getState(name);
        }
        if (data.event === "ADDED" || data.event === "CHANGED") {
          return isArray(data.item) ? data.item : [data.item];
        }
        if (
          data.event === "GET_DATA" ||
          data.event === "CLEARED" ||
          data.event === "BATCH_UPDATE"
        ) {
          return data.item;
        }
        return data;
      })
      .filter(isArray)
      .map((arr): any[] => {
        if (arr.length) {
          return this.cache.batchUpdate(name, arr, "number");
        }
        return this.cache.clearByName(name);
      })
      .map((response) => response.filter((task) => task && task.uid === uid));
  }

  updateTask(orderNumber = null, values = null) {
    return this.db.update({
      name: "tasks",
      key: "number",
      value: orderNumber,
      values,
      page,
    });
  }

  //
  // // eslint-disable-next-line class-methods-use-this
  // getPath(...args) {
  //   return [jobPath, ...args].join("/");
  // }
  //
  // trackAction(type, payload = {}) {
  //   this.db.create(payload).then();
  // }
  //
  // getTasksPath(...args) {
  //   return this.getPath("tasks", ...args);
  // }

  updateOrder(orderNumber, order) {
    return this.db.update({
      name: "orders",
      key: "number",
      value: orderNumber,
      item: order,
      page,
    });
  }
  //
  // getTask(taskId = null) {
  //   return this.db.get(this.getTasksPath(taskId));
  // }
  //
  // getTasks() {
  //   return this.db.get(this.getTasksPath()).map(mapResponse);
  // }
  //
  // getTaskAddStream() {
  //   return this.db.onChildAdd(this.getTasksPath());
  // }
  //
  addTask(orderNumber, task) {
    return this.db.update({
      name: "tasks",
      key: "number",
      item: { ...task, number: orderNumber, uid },
      page,
    });
  }

  // updateTask(taskId = null, values = null) {
  //   return this.db.update(this.getTasksPath(taskId), values);
  // }

  removeTask(key, value) {
    return this.db.remove("tasks", key, value, "inbound");
  }

  // retryTask(taskId = null) {
  //   return this.getTask(taskId)
  //     .take(1)updateTask
  //     .takeWhile((snapshot) => snapshot.exists())
  //     .switchMap((response) => {
  //       const payload = response.val();
  //
  //       return this.removeTask(taskId).concat(
  //         this.addTask(payload.number, payload.task),
  //       );
  //     });
  // }

  // checkDuplicated(orderNumber) {
  //   return this.addTask(orderNumber, { duplicated: true });
  // }

  checkBatchCompleted(orderNumber, completed) {
    return this.addTask(orderNumber, { completed });
  }

  addBarcodeChildren(barcode) {
    return this.addTask(barcode, { children: true });
  }
  updateShipmentStatus(barcode, params) {
    return this.addTask(barcode, {
      shipment_status_update: params,
    });
  }

  // updateBatchStatus(barcode, params, recursive = false) {
  //   return this.addTask(barcode, {
  //     batch_status_update: {
  //       ...params,
  //       barcode,
  //       with_recursive: recursive,
  //     },
  //   });
  // }

  updateRegistryStatus(barcode, params, recursive = false) {
    return this.addTask(barcode, {
      ...params,
      barcodes: barcode,
      with_recursive: recursive,
    });
  }

  removeBatchOrders() {
    return this.db
      .clear({ name: "tree", page: "inbound" })
      .mergeMap(() => this.db.clear({ name: "orders", page: "inbound" }))
      .mergeMap(() => this.db.clear({ name: "queue", page: "inbound" }));
  }

  batchUpdateOrders(values) {
    const items = Object.keys(values).reduce((acc, key) => {
      const splitted = key.split("/");
      const number = splitted.length ? splitted[0] : "";
      const found = acc.find((row) => row.number === number);
      if (!found) {
        acc.push({
          number,
          bin: values[`${number}/bin`],
        });
      }
      return acc;
    }, []);
    return this.db.batchUpdate({
      name: "sorting_orders",
      key: "number",
      items,
    });
  }

  //
  // forceCompleteBatch(barcode, forceComplete) {
  //   return this.addTask(barcode, { forceComplete });
  // }
  //
  // //
  // // Batch Validation functions
  // //
  //
  // getBatchPath(...args) {
  //   return this.getPath("batch", ...args);
  // }
  //
  // getBatches() {
  //   return this.db.get(this.getBatchPath()).map(mapResponse);
  // }
  //
  // getBatchOrdersPath(...args) {
  //   return this.getBatchPath("batch_orders", ...args);
  // }
  //
  // getBatchOrders() {
  //   return this.db
  //     .get(this.getBatchOrdersPath())
  //     .map(
  //       fp.flow(mapResponse, (response) =>
  //         response.filter((x, key) => Map.isMap(x) && x.get("number") === key),
  //       ),
  //     );
  // }
  //
  // getBatchOrderScannedChildren(parentId) {
  //   return this.db
  //     .get(this.getBatchOrdersPath())
  //     .map(
  //       fp.flow(mapResponse, (response) =>
  //         response.filter(
  //           (x, key) =>
  //             Map.isMap(x) &&
  //             x.get("number") === key &&
  //             x.get("parent_id") === parentId &&
  //             x.get("scanned"),
  //         ),
  //       ),
  //     );
  // }
  //
  // getBatchScannedOrders() {
  //   return this.db
  //     .get(this.getBatchOrdersPath())
  //     .map(
  //       fp.flow(mapResponse, (response) =>
  //         response.filter(
  //           (x, key) =>
  //             Map.isMap(x) && x.get("number") === key && x.get("scanned"),
  //         ),
  //       ),
  //     );
  // }
  //
  // removeBatchOrders() {
  //   return this.db.update(this.getBatchPath(), {
  //     tree: null,
  //     batch_orders: null,
  //     queue: null,
  //   });
  // }
  //
  // reloadOrder(orderNumber = null) {
  //   return this.batchReloadOrders([orderNumber]);
  // }
  //
  // batchReloadOrders(orderNumbers) {
  //   const values = orderNumbers.reduce((acc, x) => {
  //     acc[`${x}/hash`] = null;
  //     acc[`${x}/failed`] = null;
  //
  //     return acc;
  //   }, {});
  //
  //   return this.db.update(this.getBatchOrdersPath(), values);
  // }
  //
  getBatchOrder(orderNumber = null) {
    return this.db.getDataByKeyValue(
      orderNumber
        ? {
            name: "orders",
            key: "number",
            value: orderNumber,
            page,
          }
        : undefined,
    );
  }
  //
  // getBatchOrderAddStream() {
  //   return this.db
  //     .onChildAdd(this.getBatchOrdersPath())
  //     .filter(validateOrder)
  //     .map(mapResponse);
  // }
  //
  // updateBatchOrders(orders) {
  //   return this.db.update(this.getBatchOrdersPath(), orders);
  // }
  //
  // updateBatchOrderScannedCount(orderNumber, scannedCount = 0) {
  //   return this.updateBatchOrders(
  //     [orderNumber].reduce((acc, number) => {
  //       acc[`${number}/scanned_count`] = scannedCount;
  //
  //       return acc;
  //     }, {}),
  //   );
  // }
  //
  // //
  // // Current Root Order/Batch
  // //
  //
  // getRootOrderPath(...args) {
  //   return this.getBatchPath("root_order", ...args);
  // }
  //
  // getRootOrder() {
  //   return this.db.get(this.getRootOrderPath());
  // }
  //
  // updateRootOrder(orderNumber = null) {
  //   return this.getRootOrder()
  //     .take(1)
  //     .switchMap((response) => {
  //       const payload = response.val();
  //
  //       return fp.isEmpty(payload)
  //         ? this.db.update(this.getBatchPath(), { root_order: orderNumber })
  //         : Observable.of(payload);
  //     });
  // }
  //
  // getBatchTreePath(...args) {
  //   return this.getBatchPath("tree", ...args);
  // }
  //
  // getBatchTree() {
  //   return this.db.get(this.getBatchTreePath()).map(mapResponse);
  // }
  //
  // getBarcodeFromTree(barcode) {
  //   return this.db.get(this.getBatchTreePath(barcode));
  // }
  //
  // updateBarcodeTree(orders) {
  //   return this.db.update(this.getBatchTreePath(), orders);
  // }
  //
  // addBarcodeToTree(scannedBarcode, parentBarcode) {
  //   return this.getBarcodeFromTree(parentBarcode)
  //     .take(1)
  //     .switchMap((response) => {
  //       const payload = response.val();
  //
  //       const newPayload = {};
  //       if (payload) {
  //         const payloadArray = parseString(payload);
  //         payloadArray.push(scannedBarcode);
  //         newPayload[`${parentBarcode}`] = payloadArray.join(",");
  //       } else if (parentBarcode)
  //         newPayload[`${parentBarcode}`] = scannedBarcode;
  //
  //       return this.updateBarcodeTree(newPayload);
  //     });
  // }
  //
  // getBatchQueuePath(...args) {
  //   return this.getBatchPath("queue", ...args);
  // }
  //
  // getBatchQueue() {
  //   return this.db
  //     .get(this.getBatchQueuePath())
  //     .map(fp.flow(mapResponse, (response) => response.sort()));
  // }
  //
  // addBatchQueue(orderNumber) {
  //   const values = {};
  //   values[`${orderNumber}`] = Date.now();
  //   return this.db.update(this.getBatchQueuePath(), values);
  // }
  //
  // removeBatchQueue(orderNumber) {
  //   const values = {};
  //   values[`${orderNumber}`] = null;
  //   return this.db.update(this.getBatchQueuePath(), values);
  // }
}

// const sideEffects = mergeSideEffectStreams(
//   propsStream
//     .filter((props) => props.warehouseId > 0)
//     .distinctUntilKeyChanged("warehouseId")
//
//     .switchMap((props) => {
//       const db = new OrderBinValidationDB(props.warehouseId);
//
//       return mergeSideEffectStreams(
//         onOrderSubmitStream.do((request) => {
//           props.addBatchSortingTaskOrderNumber(
//             Set(request.orderNumbers),
//           );
//         }),
//         onOrderSubmitStream.mergeMap(({ orderNumbers }) => {
//           const orderValues = {};
//
//           orderNumbers.forEach((orderNumber) => {
//             orderValues[`${orderNumber}/number`] = orderNumber;
//             orderValues[`${orderNumber}/scanned`] = true;
//           });
//
//           return Observable.merge(
//             db.updateBatchOrders(orderValues),
//           );
//         }),
//         onOrderSubmitStream.switchMap((request) =>
//           props
//             .scanBarcodes(request)
//             .take(1)
//             .switchMap(() =>
//               request.cursorOrder
//                 ? db.checkBatchCompleted(request.cursorOrder, {
//                   assignToWarehouse: request.assignToWarehouse,
//                 })
//                 : Observable.of({}),
//             ),
//         ),
//       );
//     }),
// );
