import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminMarketplaceDetailsAllSettingsContainer from "./AdminMarketplaceDetailsAllSettingsContainer";
import AdminMarketplaceDetailsMobileSettingsContainer from "./AdminMarketplaceDetailsMobileSettingsContainer";
import { getMarketplaceId } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import Tabs from "../../../components/ui-core/Tabs";

const OVERALL = "Overall";
const MOBILE = "Mobile";

const tabs = [OVERALL, MOBILE];

const enhancer = compose(
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    state => ({
      // values: getValues(state),
      marketplaceId: getMarketplaceId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminMarketplaceDetailsSettingsContainer.propTypes = {
  onTabChange: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  tab: PropTypes.oneOf(tabs),
};

AdminMarketplaceDetailsSettingsContainer.defaultProps = {
  tab: OVERALL,
};

function AdminMarketplaceDetailsSettingsContainer(props) {
  const { getLocalisationMessage, onTabChange } = props;
  const tab = tabs.includes(props.tab) ? props.tab : tabs[0];

  return (
    <AdminAppLayout
      title={getLocalisationMessage("company_setting", "Company Settings")}
    >
      <Tabs
        value={tab}
        onChange={(e, v) => onTabChange(v)}
        tabs={[
          {
            label: getLocalisationMessage("overall", "Overall"),
            value: OVERALL,
          },
          {
            label: getLocalisationMessage("mobile", "Mobile"),
            value: MOBILE,
          },
        ]}
      />
      {tab === OVERALL && <AdminMarketplaceDetailsAllSettingsContainer />}
      {tab === MOBILE && <AdminMarketplaceDetailsMobileSettingsContainer />}
    </AdminAppLayout>
  );
}

export default enhancer(AdminMarketplaceDetailsSettingsContainer);
