import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import OperationalReportFilterForm from "./OperationalReportFilterForm";
import FlexBox from "../ui-core/FlexBox";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  URGENCY,
  BY_DRIVER,
  BY_SUPPLIER,
  SHIPMENTS_DELIVERED,
} from "../../constants/OperationalReportTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    grandRow: { fontWeight: "bold" },
    reportTable: {
      "& td, th": { textAlign: "center" },
      "& th": { fontWeight: "bold", color: "#000000" },
    },
  }),
);

ShipmentsDeliveredAndCreatedReport.propTypes = {
  reportType: PropTypes.string,
  onChangeFilter: PropTypes.func,
  classes: PropTypes.object,
  reportList: PropTypes.instanceOf(Map),
  groupBy: PropTypes.string,
  showBy: PropTypes.string,
  showTable: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ShipmentsDeliveredAndCreatedReport(props) {
  const { classes, getLocalisationMessage } = props;

  const showByUrgency = props.showBy === URGENCY;

  const tableRow = [];

  if (props.showTable) {
    props.reportList.delete("all").forEach((item, key) => {
      tableRow.push(
        <TableRow key={key}>
          <TableCell>{key || getLocalisationMessage("na", "N/A")}</TableCell>

          {!props.showBy ? <TableCell>{item}</TableCell> : <TableCell />}

          {props.showBy && !showByUrgency && item.size > 0 ? (
            <TableCell>{item.get("all")}</TableCell>
          ) : (
            <TableCell />
          )}
        </TableRow>,
      );

      if (props.showBy && !showByUrgency && item.size > 0) {
        item.forEach((item2, index) => {
          if (index !== "all") {
            tableRow.push(
              <TableRow key={`${key}, child, ${index}`}>
                <TableCell />
                <TableCell>
                  {index || getLocalisationMessage("na", "N/A")}
                </TableCell>
                <TableCell>{formatNumber(item2)}</TableCell>
              </TableRow>,
            );
          }
        });
      }
    });
  }

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex="none" element={<Paper />}>
            <OperationalReportFilterForm
              onChange={props.onChangeFilter}
              reportType={props.reportType}
            />
          </FlexBox>

          {props.showTable && (
            <FlexBox>
              <Table className={classes.reportTable}>
                <TableHead adjustForCheckbox={false} displaySelectAll={false}>
                  <TableRow>
                    <TableCell
                      className={classes.columnName}
                      style={{ textAlign: "center" }}
                    >
                      {props.groupBy === BY_SUPPLIER
                        ? getLocalisationMessage(
                            "courier_name",
                            "COURIER NAME",
                          ).toLocaleUpperCase()
                        : props.groupBy === BY_DRIVER
                        ? getLocalisationMessage(
                            "driver_name",
                            "DRIVER NAME",
                          ).toLocaleUpperCase()
                        : getLocalisationMessage(
                            "warehouse",
                            "WAREHOUSE",
                          ).toLocaleUpperCase()}
                    </TableCell>

                    <TableCell />

                    {props.showBy &&
                    !showByUrgency &&
                    props.reportList.size > 0 ? (
                      <TableCell style={{ textAlign: "center" }}>
                        {props.reportType === SHIPMENTS_DELIVERED
                          ? getLocalisationMessage(
                              "delivered",
                              "DELIVERED",
                            ).toLocaleUpperCase()
                          : getLocalisationMessage(
                              "count",
                              "COUNT",
                            ).toLocaleUpperCase()}
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                  </TableRow>
                </TableHead>
                <TableBody displayRowCheckbox={false} showRowHover={true}>
                  {tableRow}
                  {!props.showBy && (
                    <TableRow className={classes.grandRow}>
                      <TableCell>
                        {getLocalisationMessage("grand_total", "Grand Total")}
                      </TableCell>
                      <TableCell>
                        {formatNumber(props.reportList.get("all"))}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ShipmentsDeliveredAndCreatedReport);
