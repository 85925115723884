import React from "react";
import fp from "lodash/fp";
import { compose, shouldUpdate, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Paper, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Search as ActionSearch } from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  reduxForm({
    form: "NeighborhoodsCoordinatesForm",
  }),
  withHandlers({
    handleSubmit: props => event => {
      props.handleSubmit(event);
      props.change("neighborhood", null);
    },
  }),
  shouldUpdate(fp.stubFalse),
);

NeighborhoodsCoordinatesForm.propTypes = {
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function NeighborhoodsCoordinatesForm(props) {
  return (
    <FlexBox container={16} flex={true} element={<Paper />}>
      <FlexBox
        gutter={8}
        flex={true}
        element={<form onSubmit={props.handleSubmit} />}
      >
        <FlexBox flex={true}>
          <FormTextField
            name="neighborhood"
            fullWidth={true}
            hintText={props.getLocalisationMessage(
              "enter_neighborhood_name",
              "Enter Neighborhood Name",
            )}
          />
        </FlexBox>

        <FlexBox align="center">
          <IconButton type="submit">
            <ActionSearch />
          </IconButton>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NeighborhoodsCoordinatesForm);
