import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReceptacleForm from "../../components/order-create-wizard-new/ips/ReceptacleForm";
import { getValue } from "../../helpers/DataUtils";
import { getReceptacleItem } from "../../api/admin/AdminIPSApi";
import { getMessage } from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { connect } from "react-redux";
import { formatMailCategory } from "../../components/order-create-wizard-new/ips/NewDispatchForm";

function AdminReceptacleItemWrapper(props) {
  const [receptacle, setReceptacle] = useState({});

  const receptacleID = getValue(props.params, "id");
  const dispatchId = getValue(props.params, "dispatchId");

  useEffect(() => {
    if (receptacleID) {
      getReceptacleItem(receptacleID)
        .then(r => {
          if (r && r.data) {
            setReceptacle(r.data);
          }
        })
        .catch(error => props.showErrorMessage(error));
    }
  }, [receptacleID]);

  return (
    <AdminAppLayout
      title={props.getLocalisationMessage(
        "create_receptacle",
        "Create Receptacle",
      )}
    >
      <ReceptacleForm
        receptacleID={receptacleID}
        dispatchId={dispatchId}
        initialValues={{
          originOffice: getValue(receptacle, "receptacle_id.origin_office", ""),
          destinationOffice: getValue(
            receptacle,
            "receptacle_id.destination_office",
            "",
          ),
          mailCategory: formatMailCategory(
            getValue(receptacle, "receptacle_id.mail_category", ""),
            props.getLocalisationMessage,
          ),
          mailSubClass: getValue(
            receptacle,
            "receptacle_id.mail_sub_class",
            "",
          ),
          yearDigit: getValue(receptacle, "receptacle_id.year_digit", ""),
          serialNo: getValue(receptacle, "receptacle_id.serial_no", ""),
          receptacleNo: getValue(receptacle, "receptacle_id.receptacle_no", ""),
          subType: getValue(receptacle, "sub_type", ""),
          receptacleMailSubclass: getValue(receptacle, "mail_sub_class", ""),
          contentFormat: "",
          ownership: getValue(receptacle, "ownership", ""),
          weight: getValue(receptacle, "weight", 0),
          receptacle_id_string: getValue(
            receptacle,
            "receptacle_id_string",
            "",
          ),
        }}
      />
    </AdminAppLayout>
  );
}

AdminReceptacleItemWrapper.propTypes = {
  params: PropTypes.object,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

const mapStateToProps = state => ({
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = { showErrorMessage };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminReceptacleItemWrapper);
