import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { unemojify } from "node-emoji";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

class ControlledEditor extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this.props.onChange(
      draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
    );
  }

  // shouldComponentUpdate(props): boolean {
  //   const { value } = props;
  //   const { onChange } = this.props;
  //
  //   const contentBlock = htmlToDraft(value);
  //
  //   if (contentBlock) {
  //     console.log("should component update");
  //     console.log(props.value);
  //     console.log(value);
  //
  //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //     const editorState = EditorState.createWithContent(contentState);
  //
  //     onChange(
  //       draftToHtml(convertToRaw(editorState.getCurrentContent())),
  //     );
  //
  //     this.setState({editorState})
  //
  //     return true;
  //   }
  //   return false;
  // }

  componentWillReceiveProps(props) {
    const { value } = props;
    const { onChange } = this.props;

    const contentBlock = htmlToDraft(value);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);

      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));

      this.setState({ editorState });

      return true;
    }
    return false;
  }

  onEditorStateChange: Function = editorState => {
    const { onChange, value } = this.props;

    const newValue = unemojify(
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );

    if (value !== newValue) {
      onChange(newValue);
    }

    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;

    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </div>
    );
  }
}

export default ControlledEditor;
