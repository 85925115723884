import React from "react";
import Immutable from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import fp from "lodash/fp";
import PinMarker from "../maps/PinMarker";
import { isEqualData } from "../../helpers/DataUtils";
import PolylineWrapper from "../maps/PolylineWrapper";
import VehicleMarker from "../maps/VehicleMarker2";
import { COMPLETED } from "../../constants/OrderStatusCodes";

const getWaypoints = route => {
  const direction = {};
  const waypoints = [];

  route.forEach((x, idx) => {
    if (idx === 0) {
      direction.origin = {
        lat: x.get("lat"),
        lng: x.get("lng"),
      };
    }

    if (idx === route.size - 1) {
      direction.destination = {
        lat: x.get("lat"),
        lng: x.get("lng"),
      };
    }

    if (idx !== 0 && idx !== route.size - 1) {
      waypoints.push({
        // stopover: true,
        location: {
          lat: x.get("lat"),
          lng: x.get("lng"),
        },
      });
    }
  });

  direction.waypoints = waypoints.reverse();

  return direction;
};

const enhancer = compose(
  mapPropsStream(propsStream => {
    const driverRouteStream = propsStream
      .map(fp.pick(["orders", "liveDriverLocations", "driverId"]))
      .distinctUntilChanged(isEqualData)
      .map(props => {
        let currentDriver = Immutable.Map();
        props.liveDriverLocations.forEach(item => {
          if (
            fp.toFinite(item.getIn(["location", "driver_id"])) ===
            fp.toFinite(props.driverId)
          )
            currentDriver = item;
        });

        const inCompleteRoute = Immutable.List().withMutations(route => {
          props.orders.forEach(order => {
            if (order.get("status") !== COMPLETED) {
              route.push(
                Immutable.Map({
                  lat: order.getIn(["locations", 1, "lat"]),
                  lng: order.getIn(["locations", 1, "lon"]),
                  order_id: order.get("id"),
                }),
              );
            }
          });
        });

        const completeRoute = Immutable.List().withMutations(route => {
          props.orders.forEach(order => {
            if (order.get("status") === COMPLETED) {
              route.push(
                Immutable.Map({
                  lat: order.getIn(["locations", 1, "lat"]),
                  lng: order.getIn(["locations", 1, "lon"]),
                  order_id: order.get("id"),
                }),
              );
            }
          });
        });

        return Immutable.Map({
          currentDriverLiveLocation: currentDriver,
          inCompleteRoute,
          completeRoute:
            completeRoute.size > 0 && currentDriver
              ? completeRoute.push(
                  Immutable.Map({
                    driver_id: props.driverId,
                    lat: currentDriver.getIn(["location", "lat"]),
                    lng: currentDriver.getIn(["location", "lon"]),
                  }),
                )
              : completeRoute,
        });
      })
      .startWith(
        Immutable.fromJS({
          currentDriverLiveLocation: Immutable.Map(),
          inCompleteRoute: Immutable.List(),
          completeRoute: Immutable.List(),
        }),
      );

    return propsStream
      .combineLatest(driverRouteStream, (props, driverRoutes) => ({
        ...props,

        inCompleteRoute: driverRoutes.get("inCompleteRoute"),
        completeRoute: driverRoutes.get("completeRoute"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

DriverOrderLocationsMapV2.propTypes = {
  driverId: PropTypes.number,
  onOrderClick: PropTypes.func,
  orders: PropTypes.instanceOf(Immutable.List),
  driverLocation: PropTypes.instanceOf(Immutable.Map),
  completeRoute: PropTypes.instanceOf(Immutable.List),
  inCompleteRoute: PropTypes.instanceOf(Immutable.List),
  liveDriverLocations: PropTypes.instanceOf(Immutable.List),
};

function DriverOrderLocationsMapV2(props) {
  const { orders, inCompleteRoute, completeRoute } = props;

  const inCompleteWaypoints = getWaypoints(inCompleteRoute);
  const completeWaypoints = getWaypoints(completeRoute);

  return (
    <div>
      {props.liveDriverLocations.map((x, idx) => {
        if (
          fp.toFinite(x.getIn(["location", "driver_id"])) ===
          fp.toFinite(props.driverId)
        ) {
          return (
            <VehicleMarker
              key={idx}
              rotation={x.getIn(["location", "angle"])}
              position={{
                lat: x.getIn(["location", "lat"]),
                lng: x.getIn(["location", "lon"]),
              }}
            />
          );
        }

        return null;
      })}

      {orders.map((x, idx) => (
        <PinMarker
          key={idx}
          onClick={() => props.onOrderClick(x.get("id"), idx)}
          complete={x.get("status") === COMPLETED}
          position={{
            lat: x.getIn(["locations", 1, "lat"]),
            lng: x.getIn(["locations", 1, "lon"]),
          }}
        />
      ))}

      <PolylineWrapper
        origin={completeWaypoints.origin}
        waypoints={completeWaypoints.waypoints}
        destination={completeWaypoints.destination}
        strokeColor="#09A0FD"
        strokeWeight={4}
        strokeOpacity={1}
      />

      <PolylineWrapper
        origin={inCompleteWaypoints.origin}
        waypoints={inCompleteWaypoints.waypoints}
        destination={inCompleteWaypoints.destination}
        strokeColor="#979797"
        strokeWeight={4}
        strokeOpacity={1}
      />
    </div>
  );
}

export default enhancer(DriverOrderLocationsMapV2);
