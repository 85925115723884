import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SortableList, { SortableListItem } from "../sortable-list/SortableList";
import { pureComponent } from "../../helpers/HOCUtils";

const enhancer = compose(pureComponent(fp.pick(["OfflineRuleList"])));

OfflineRuleList.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  ruleList: PropTypes.array,
};

function OfflineRuleList(props) {
  const { ruleList } = props;

  return (
    <SortableList
      onSortEnd={({ oldIndex, newIndex }) => {
        [ruleList[oldIndex], ruleList[newIndex]] = [
          ruleList[newIndex],
          ruleList[oldIndex],
        ];
        if (oldIndex !== newIndex) {
          props.onChange(
            ruleList.map((x, idx) => ({
              ...x,
              weight: idx,
            })),
          );
        }
      }}
    >
      {ruleList.map((x, index) => (
        <SortableListItem
          key={index}
          primaryText={x.name}
          onClick={() => props.onEditClick(x.id)}
          rightIconButton={
            <IconButton onClick={() => props.onDeleteClick(x.id)}>
              <Close />
            </IconButton>
          }
        />
      ))}
    </SortableList>
  );
}

export default enhancer(OfflineRuleList);
