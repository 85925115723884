import React from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import BatchOrderCanceledUpdate from "../../components/orders-core/BatchOrderCanceledUpdate";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { RETURNING_TO_ORIGIN } from "../../constants/OrderStatusCodes";
import { isEqualData } from "../../helpers/DataUtils";
import {
  BANDEROL,
  LETTER,
  MIXED,
  POSTCARD,
} from "../../helpers/OrderOutboundSortingHelper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.pick(["selectedItems", "orders", "warehouseId"]))
      .distinctUntilChanged(isEqualData)
      .map(({ orders, selectedItems, warehouseId }) => {
        const data = selectedItems.reduce(
          (acc, orderNumber) => {
            const order = orders.get(orderNumber, null);
            if (order) {
              // Courier Types
              const courierType = order.getIn(
                ["info", "package", "courier_type"],
                null,
              );

              if (
                courierType === BANDEROL &&
                order.getIn(["info", "package", "valuable"], false)
              )
                acc.valued = true;

              if (courierType && !acc.courierTypes.includes(courierType)) {
                acc.courierTypes.push(courierType);
              }

              // Calculate Weight
              acc.weight += parseFloat(order.getIn(["info", "weight"]) || 0);
            }
            return acc;
          },
          {
            valued: false,
            weight: 0,
            courierTypes: [],
          },
        );

        let innerShipmentType = null;
        if (data.courierTypes.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          innerShipmentType = data.courierTypes[0];
        } else if (data.courierTypes.length > 1) {
          const isNotPK = data.courierTypes.filter(
            item => item !== LETTER && item !== BANDEROL && item !== POSTCARD,
          );
          if (isNotPK.length > 0) {
            innerShipmentType = MIXED;
          } else {
            innerShipmentType = LETTER;
          }
        }

        const values = {
          innerShipmentType,
          privacy: PUBLIC,
          warehouse: { id: warehouseId },
          orderBarcodes: selectedItems.toArray(),
          weight: Math.round(data.weight * 100) / 100,
        };

        return {
          ...values,
          innerShipmentType,
          privacy: PUBLIC,
          orderBarcodes: selectedItems.toArray(),
        };
      });

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
      }),
    );
  }),
);

BatchReturningUpdateDialogWrapper.propTypes = {
  open: PropTypes.bool,
  isBulkReturning: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  warehouseId: PropTypes.number,
  i18n: PropTypes.instanceOf(Map),
};

function BatchReturningUpdateDialogWrapper({
  initialValues,
  isBulkReturning,
  ...props
}) {
  return (
    <BatchOrderCanceledUpdate
      open={props.open}
      initialValues={{
        ...initialValues,
        orderStatus: RETURNING_TO_ORIGIN,
        supplier: { id: 1 },
      }}
      isBulkReturning={isBulkReturning}
      onRequestClose={props.onRequestClose}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
    />
  );
}

export default enhancer(BatchReturningUpdateDialogWrapper);
