import React, { useEffect, useState } from "react";
import { List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withContext,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import Audio from "../ui-core/Audio";
import ScannerTextField from "../deprecated/ScannerTextField";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import BeepSound from "../../assets/voices/beep.wav";
import { withTheme } from "@material-ui/core/styles";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FlexBox, { JUSTIFY_END, JUSTIFY_SPACE_AROUND } from "../ui-core/FlexBox";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { formValues, formValueSelector, reduxForm } from "redux-form";
import OrderStatusCodes, {
  ACCEPTED,
  DISPATCHED,
  IN_TRANSIT,
  ON_HIS_WAY,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import OrderRegistrySortingCreateBagsTableWrapper from "./OrderRegistrySortingCreateBagsTableWrapper";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { formatText, formatWeight } from "../../helpers/FormatUtils";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormTextField from "../form/FormTextField";
import { categoriesConstants } from "../orders-core/BatchUpdateOrderDialog2";
import cx from "classnames";
import { green, red } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import { MIXED } from "../../helpers/OrderOutboundSortingHelper";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import TransportationType from "../../constants/TransportationType";
import FormSelectField from "../form/FormSelectField";
import { Done } from "@material-ui/icons";

const statusesWithDriver = Set.of(ACCEPTED, ON_HIS_WAY, DISPATCHED);
const valueSelector = formValueSelector("CreateBagFormDialog");

const enhancer = compose(
  renderIf("open"),
  connect((state) => ({
    values: valueSelector(state, "registries"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    content: { paddingTop: 15 },
    chip: { margin: "4px" },
    alert: { marginBottom: 15 },
    chipDanger: { backgroundColor: danger1 },
    modal: { width: "800px", minWidth: 800 },
    total: { display: "inline-block", marginRight: 10 },
    scannerField: { marginBottom: "10px" },
    weight: { color: green[700] },
    weightOverload: { color: red[500], fontWeight: "bold" },
    actionRow: { marginBottom: 5 },
  }),
  withState(
    "state",
    "setState",
    Map({
      currentOrder: null,
      inputRef: null,
    }),
  ),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    (props) => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .map(
        fp.pick(["selectedOrders", "orders", "binRules", "currentWarehouse"]),
      )
      .distinctUntilChanged(isEqualData)
      .map(({ orders, selectedOrders, currentWarehouse, binRules }) => {
        const values = {
          status: IN_TRANSIT,
          privacy: PUBLIC,
          weight: 0,
          barcodes: selectedOrders.toJS(),
        };
        const categories = [];
        const warehouses = [];
        const destinationWarehouses = [];
        const ruleWarehouses = [];
        const ruleDestinationWarehouses = [];
        const innerTypes = [];
        const transportationTypes = [];
        let warehouse = null;
        let toWarehouse = null;

        const registries = List().withMutations((data) => {
          selectedOrders.forEach((selected) => {
            const order = orders.get(selected);
            const orderCategory = order.getIn(["info", "category"]);
            const warehouseId = order.getIn(["info", "warehouse", "id"]);
            const ruleCode = order.get("code");
            const destinationWarehouseId = order.getIn([
              "info",
              "to_warehouse",
              "id",
            ]);
            const innerType = order.getIn(["info", "inner_shipment_type"]);
            const transportationType = order.getIn([
              "info",
              "transportation_type",
            ]);
            const ruleWarehouseId = order.get("warehouse");

            if (!categories.includes(orderCategory) && orderCategory) {
              categories.push(orderCategory);
            }

            if (!innerTypes.includes(innerType) && innerType) {
              innerTypes.push(innerType);
            }

            if (
              !transportationTypes.includes(transportationType) &&
              transportationType
            ) {
              transportationTypes.push(transportationType);
            }

            if (
              currentWarehouse !== warehouseId &&
              warehouseId &&
              !warehouses.includes(warehouseId)
            ) {
              warehouses.push(warehouseId);
            }

            if (!ruleWarehouses.includes(ruleWarehouseId) && ruleWarehouseId) {
              ruleWarehouses.push(ruleWarehouseId);
            }

            if (binRules && ruleCode && binRules.get(ruleCode)) {
              const ruleToWarehouseId = binRules.getIn([
                ruleCode,
                "to_warehouse",
                "id",
              ]);
              if (
                !ruleDestinationWarehouses.includes(ruleToWarehouseId) &&
                ruleToWarehouseId
              ) {
                ruleDestinationWarehouses.push(ruleToWarehouseId);
              }
            }

            if (
              destinationWarehouseId &&
              destinationWarehouseId !== currentWarehouse &&
              !destinationWarehouses.includes(destinationWarehouseId)
            ) {
              destinationWarehouses.push(destinationWarehouseId);
            }

            data.push(order);
          });
        });

        if (warehouses.length === 1) {
          warehouse = { id: warehouses[0] };
        }

        if (destinationWarehouses.length === 1) {
          toWarehouse = { id: destinationWarehouses[0] };
        }

        if (!toWarehouse && warehouse) {
          toWarehouse = warehouse;
        }

        if (ruleWarehouses.length > 0) {
          if (!warehouse) {
            if (ruleWarehouses[0] === currentWarehouse && toWarehouse) {
              warehouse = toWarehouse;
            } else {
              warehouse = { id: ruleWarehouses[0] };
            }
          }

          if (!toWarehouse) {
            if (ruleDestinationWarehouses.length === 1) {
              toWarehouse = { id: ruleDestinationWarehouses[0] };
            } else if (ruleWarehouses.length === 1) {
              toWarehouse = { id: ruleWarehouses[0] };
            }
          }
        }

        // const nextWarehouse = estimateRegistryWarehouses(warehouse);
        // console.log(warehouses, ruleWarehouses, transportationTypes, innerTypes, categories, selectedOrders.toJS())

        return {
          ...values,
          registries,
          warehouse,
          to_warehouse: toWarehouse,
          category: categories.length === 1 ? categories[0] : null,
          innerShipmentType: innerTypes.length === 1 ? innerTypes[0] : null,
          transportationType:
            transportationTypes.length === 1 ? transportationTypes[0] : null,
          countShipmentTypes: innerTypes,
          countTransportationTypes: transportationTypes,
          countCategories: categories,
          countWarehouses: warehouses,
          countRuleWarehouses: ruleWarehouses,
        };
      });

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
      }),
    );
  }),
  withHandlers({
    onSubmit:
      (props) =>
      ({
        countWarehouses,
        countCategories,
        countRuleWarehouses,
        countShipmentTypes,
        registries,
        barcodes,
        status,
        ...values
      }) =>
        props.onSubmit({
          ...values,
          orderBarcodes: barcodes,
          orderStatus: status,
        }),
  }),
  reduxForm({
    form: "CreateBagFormDialog",
    validate: (values, props) => ({
      barcodes:
        fp.isEmpty(values.barcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_barcodes")) ||
          "Add Barcodes"),
      status:
        !values.status &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("select_status", "Select Status"),
      category:
        fp.isEmpty(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      transportationType:
        fp.isEmpty(values.transportationType) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("select_warehouse", "Select Warehouse"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        props.getLocalisationMessage(
          "select_destination_warehouse",
          "Select Destination Warehouse",
        ),
    }),
  }),
  formValues({
    registries: "registries",
    barcodes: "barcodes",
    status: "status",
    weight: "weight",
    category: "category",
    countCategories: "countCategories",
    countWarehouses: "countWarehouses",
    countRuleWarehouses: "countRuleWarehouses",
    countShipmentTypes: "countShipmentTypes",
    supplier: "supplier",
  }),
  mapPropsStream((propsStream) => {
    const { handler: refInput, stream: refStream } = createEventHandler();
    const { handler: onSetFocus, stream: onSetFocusStream } =
      createEventHandler();

    const sideEffectsStream = mergeSideEffectStreams(
      onSetFocusStream
        .withLatestFrom(refStream)
        .delay(1000)
        .do(([, input]) => {
          input.focus();
        }),

      propsStream
        .map(fp.pick(["registries", "change"]))
        .distinctUntilKeyChanged("registries", isEqualData)
        .do((props) => {
          let weight = 0;
          const categories = [];
          const warehouses = [];
          const ruleWarehouses = [];
          const innerTypes = [];

          if (props.registries) {
            props.registries.forEach((item) => {
              weight += item.getIn(["info", "weight"], 0);

              const orderCategory = item.getIn(["info", "category"]);
              const warehouseId = item.getIn(["info", "warehouse", "id"]);
              const ruleWarehouseId = item.get("warehouse");
              const innerType = item.getIn(["info", "inner_shipment_type"]);

              if (!categories.includes(orderCategory) && orderCategory) {
                categories.push(orderCategory);
              }

              if (!innerTypes.includes(innerType) && innerType) {
                innerTypes.push(innerType);
              }

              if (!warehouses.includes(warehouseId) && warehouseId) {
                warehouses.push(warehouseId);
              }

              if (
                !ruleWarehouses.includes(ruleWarehouseId) &&
                ruleWarehouseId
              ) {
                ruleWarehouses.push(ruleWarehouseId);
              }
            });
          }

          props.change("countCategories", categories);
          props.change("countWarehouses", warehouses);
          props.change("countRuleWarehouses", ruleWarehouses);
          props.change("countShipmentTypes", innerTypes);
          props.change("weight", +weight.toFixed(2));
        }),
    ).startWith(null);

    return propsStream.combineLatest(sideEffectsStream, (props) => ({
      ...props,

      onSetFocus,
      refInput,
    }));
  }),
  withHandlers({
    onScanOrder:
      ({ barcodes, orders, registries, ...props }) =>
      (orderNumber) => {
        if (!barcodes.includes(orderNumber)) {
          barcodes.push(orderNumber);

          if (orders.has(orderNumber)) {
            props.change(
              "registries",
              registries.push(orders.get(orderNumber)),
            );

            props.onAddOrder(orderNumber);
          } else {
            props.change(
              "registries",
              registries.push(Map({ number: orderNumber, failed: true })),
            );
          }
          props.change("barcodes", barcodes);
        }

        props.setState((s) => s.update("currentOrder", () => orderNumber));
      },

    onRemoveOrder:
      ({ barcodes, registries, ...props }) =>
      (orderNumber) => {
        if (barcodes.includes(orderNumber)) {
          barcodes.push(orderNumber);
          const filteredBarcodes = barcodes.filter(
            (number) => number !== orderNumber,
          );
          const filteredRegistry = registries.filter(
            (item) => item.get("number") !== orderNumber,
          );

          props.onUnselectOrder(orderNumber);
          props.change("barcodes", filteredBarcodes);
          props.change("registries", filteredRegistry);
        }
      },
  }),
  withTheme,
);

OrderRegistrySortingCreateBagsDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  weight: PropTypes.number,

  status: PropTypes.string,

  countWarehouses: PropTypes.array,
  countRuleWarehouses: PropTypes.array,
  countShipmentTypes: PropTypes.array,

  classes: PropTypes.object,
  theme: PropTypes.object,
  to_postcode: PropTypes.object,
  to_jurisdiction: PropTypes.object,
  next_postcode: PropTypes.object,
  next_jurisdiction: PropTypes.object,

  supplier: PropTypes.instanceOf(Map),
  state: PropTypes.instanceOf(Map),
  registries: PropTypes.instanceOf(List),

  refInput: PropTypes.func,
  onScanOrder: PropTypes.func,
  onRemoveOrder: PropTypes.func,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

function OrderRegistrySortingCreateBagsDialog(props) {
  const { classes, getLocalisationMessage, registries, status } = props;

  const [isHaveFailed, setIsHaveFailed] = useState(false);
  useEffect(() => {
    if (2 === 3 && !registries.isEmpty()) {
      const temp = toJS(registries);
      if (temp.filter((v) => v.failed).length > 0) setIsHaveFailed(true);
      else setIsHaveFailed(false);
    }
  }, [registries]);

  const totalCount = registries && registries.size;
  const blockView = false;

  const showDriverField = statusesWithDriver.has(status);

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: "1200px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${getLocalisationMessage("create_bag123123", "123123123")}`}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            <span className={classes.total}>
              {getLocalisationMessage("total", "Total")}: {totalCount},
            </span>
            <span>
              {getLocalisationMessage("weight", "Weight")}:{" "}
              <span
                className={cx(classes.weight, {
                  [classes.weightOverload]: props.weight > 20,
                })}
              >
                {formatWeight(props.weight)}
              </span>{" "}
            </span>
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <form className={classes.form}>
          {props.countShipmentTypes && props.countShipmentTypes.length > 1 && (
            <Alert severity="error" className={classes.alert}>
              <AlertTitle>
                {getLocalisationMessage("error", "Error")} -{" "}
                {getLocalisationMessage("mixed_categories", "Mixed Categories")}
              </AlertTitle>
            </Alert>
          )}
          {isHaveFailed && (
            <Alert severity="error" className={classes.alert}>
              <AlertTitle>
                {getLocalisationMessage("error")} -{" "}
                {getLocalisationMessage("scanned_error_barcodes")}
              </AlertTitle>
            </Alert>
          )}

          {(props.countWarehouses && props.countWarehouses.length > 1) ||
            (props.countRuleWarehouses &&
              props.countRuleWarehouses.length > 1 && (
                <Alert severity="warning" className={classes.alert}>
                  <AlertTitle>
                    {getLocalisationMessage(
                      "there_are_registries_which_have_different_destinations",
                      "There are registries which have different destinations",
                    )}
                  </AlertTitle>
                </Alert>
              ))}

          <FlexBox flex={true}>
            <FlexBox flex={9} direction="column">
              <ScannerTextField
                className={classes.scannerField}
                focus={true}
                autoFocus={true}
                fullWidth={true}
                disabled={blockView}
                onChange={props.onScanOrder}
                inputRef={props.refInput}
              />

              {props.state.get("currentOrder", null) && (
                <Audio
                  play={true}
                  key={props.state.get("currentOrder", null)}
                  src={BeepSound}
                />
              )}
              <OrderRegistrySortingCreateBagsTableWrapper
                list={registries || List()}
                onRemove={props.onRemoveOrder}
              />
            </FlexBox>
            <FlexBox
              flex={3}
              direction="column"
              justify={JUSTIFY_SPACE_AROUND}
              style={{ paddingLeft: 15 }}
            >
              <FlexBox direction="column">
                <FormAutoComplete
                  name="status"
                  fullWidth={true}
                  label={getLocalisationMessage("status", "Status")}
                  options={OrderStatusCodes}
                  margin="dense"
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                  formatOption={(x) =>
                    formatOrderStatusCodeForLocalisation(
                      x,
                      getLocalisationMessage,
                    )
                  }
                />

                {(status === IN_TRANSIT || status === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    name="warehouse"
                    label={getLocalisationMessage("warehouse", "Warehouse")}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("name", props.next_postcode)
                        ? [fp.get("name", props.next_postcode)]
                        : null
                    }
                    jurisdictionIds={
                      !fp.get("name", props.next_postcode) &&
                      fp.get("id", props.next_jurisdiction)
                        ? [fp.get("id", props.next_jurisdiction)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                {/* {(status === IN_TRANSIT || */}
                {/*  status === PREPARED_FOR_TRANSIT || */}
                {/*  status === ASSIGNED_TO_COURIER) && ( */}
                {/*  <FormSupplierAutoComplete */}
                {/*    name="supplier" */}
                {/*    fullWidth={true} */}
                {/*    margin="dense" */}
                {/*    label={getLocalisationMessage("supplier", "Supplier")} */}
                {/*    hintText={getLocalisationMessage( */}
                {/*      "type_to_search", */}
                {/*      "Type To Search...", */}
                {/*    )} */}
                {/*  /> */}
                {/* )} */}

                {props.supplier && showDriverField && (
                  <FormDriverAutoComplete
                    name="driver"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("driver", "Driver")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <div style={{ marginBottom: 8, marginTop: 8 }}>
                  <FormCourierTypeSelectField
                    hintText={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    label={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    name="innerShipmentType"
                    fullWidth={true}
                    additionalOption={{
                      code: MIXED,
                      name: getLocalisationMessage(fp.toLower(MIXED)),
                    }}
                  />
                </div>

                {(status === IN_TRANSIT || status === PREPARED_FOR_TRANSIT) && (
                  <FormAutoComplete
                    name="category"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("category", "Category")}
                    options={categoriesConstants}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                    formatOption={(x) => getLocalisationMessage(x)}
                  />
                )}

                {(status === IN_TRANSIT || status === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    disableP7={false}
                    name="to_warehouse"
                    label={getLocalisationMessage(
                      "destination_warehouse",
                      "Destination Warehouse",
                    )}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("name", props.to_postcode)
                        ? [fp.get("name", props.to_postcode)]
                        : null
                    }
                    jurisdictionIds={
                      !fp.get("name", props.to_postcode) &&
                      fp.get("id", props.to_jurisdiction)
                        ? [fp.get("id", props.to_jurisdiction)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <FormSelectField
                  name="transportationType"
                  fullWidth={true}
                  margin="dense"
                  options={TransportationType}
                  formatOption={(x) =>
                    getLocalisationMessage(x.toLowerCase(), formatText(x))
                  }
                  label={getLocalisationMessage(
                    "transportation_type",
                    "Transportation Type",
                  )}
                />

                <FormTextField
                  type="number"
                  margin="dense"
                  name="weight"
                  fullWidth={true}
                  label={getLocalisationMessage("weight_kg", "Weight (kg)")}
                />
              </FlexBox>

              <FlexBox>
                <FlexBox
                  flex={true}
                  justify={JUSTIFY_END}
                  className={classes.actionRow}
                >
                  <Button
                    style={{ marginRight: 15 }}
                    onClick={props.onRequestClose}
                  >
                    {getLocalisationMessage("close", "Close")}
                  </Button>
                  <CustomButton
                    variant={CONTAINED}
                    color={SECONDARY}
                    onClick={props.handleSubmit}
                    disabled={isHaveFailed || props.isLoading}
                    endIcon={
                      props.isLoading ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : (
                        <Done size={20} />
                      )
                    }
                  >
                    {props.isLoading
                      ? getLocalisationMessage("loading")
                      : getLocalisationMessage("confirm", "Confirm")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(OrderRegistrySortingCreateBagsDialog);
