import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import _ from "lodash";
import { safeParseDate } from "../../helpers/DateUtils";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { Add, FilterList, Refresh } from "@material-ui/icons";
import MUITable, {
  DIALOG,
  RENDER,
} from "../../components/orders-core/MUITable";
import TableListHeader from "../../wrappers/admin/TableListHeader";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import fp from "lodash/fp";
import { getUserId, getUserWarehouse } from "../../reducers/ProfileReducer";
import { DATE } from "../../components/orders-core/MUITableSimple";
import { clearHybridOrders } from "../../reducers/HybridOrdersReducer";
import { getList } from "../../api/admin/AdminBatchHistoryUpdateApi";
import BatchHistoryUpdateDialog from "../../wrappers/admin/BatchHistoryUpdateDialog";
import { getValue } from "../../helpers/DataUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import BatchHistoryUpdateFilterDialog from "../../wrappers/admin/BatchHistoryUpdateFilterDialog";
import BatchUpdateHistoryItemDialog from "../../components/batch-updates-dialog/BatchUpdateHistoryItemDialog";
import { formatDateToUrl } from "../../helpers/FormatUtils";

const enhancer = compose(
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      userId: getUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, clearHybridOrders },
  ),
);
const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  input: { "& input": { fontSize: "20px" } },
});

const AdminBatchHistoryUpdateList = props => {
  const { getLocalisationMessage, showErrorMessage: showErrorMessage1 } = props;
  const classes = useStyles();
  const [openCreate, setOpenCreate] = useState(false);
  const [batchId, setBatchId] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState({
    refresh: false,
    page: 0,
    size: 20,
  });

  useEffect(() => {
    setIsLoading(true);
    getList(filter)
      .then(res => {
        setIsLoading(false);
        setList(_.get(res, "data.list"));
      })
      .catch(error => {
        setIsLoading(false);
        showErrorMessage1(error);
      });
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });
  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "custom_batch_update",
        "Custom Batch update",
      )}
      className={classes.contentRoot}
    >
      <BatchHistoryUpdateFilterDialog
        initialValues={{
          ...filter,
          startDate: safeParseDate(fp.get("start_date", filter)),
          endDate: safeParseDate(fp.get("end_date", filter)),
          statuses: parseString(fp.get("statuses", filter)),
        }}
        open={isOpenFilter}
        onFilterChange={v => {
          setFilter({
            ...filter,
            statuses: stringifyArray(v.statuses),
            start_date: formatDateToUrl(v.start_date),
            end_date: formatDateToUrl(v.end_date),
          });
          setIsOpenFilter(false);
        }}
        onRequestClose={() => setIsOpenFilter(false)}
      />
      <BatchUpdateHistoryItemDialog
        open={batchId > 0}
        id={batchId}
        onRequestClose={() => setBatchId(null)}
      />

      <BatchHistoryUpdateDialog
        open={openCreate}
        setBatchId={setBatchId}
        onRequestClose={() => setOpenCreate(false)}
        onSubmitSuccess={response => {
          setBatchId(getValue(response, "data.id"));
          setOpenCreate(false);
          refreshOrderList();
        }}
      />

      <FlexBox
        style={{ backgroundColor: "white", height: "100%" }}
        direction="column"
        justify="space-between"
      >
        <TableListHeader
          buttons={
            <FlexBox
              align={ALIGN_CENTER}
              style={{ flex: "1 1 auto", gap: 16 }}
              justify="flex-end"
            >
              <FlexBox>
                <CustomButton
                  size="small"
                  startIcon={<Add />}
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{ borderRadius: "20px" }}
                  onClick={() => setOpenCreate(true)}
                >
                  {getLocalisationMessage("create")}
                </CustomButton>
              </FlexBox>
              <FlexBox>
                <IconButton
                  className={isLoading && classes.refresh}
                  tooltip={getLocalisationMessage("refresh")}
                  onClick={() => refreshOrderList()}
                >
                  <Refresh />
                </IconButton>
              </FlexBox>
              <FlexBox>
                <IconButton onClick={() => setIsOpenFilter(true)}>
                  <FilterList />
                </IconButton>
              </FlexBox>
            </FlexBox>
          }
        />

        <MUITable
          setId={setBatchId}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          filter={filter}
          isLoading={isLoading}
          list={list}
          total={list.length}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          columns={[
            {
              type: DIALOG,
              name: "id",
              label: getLocalisationMessage("id"),
              align: "center",
              keyName: "id",
            },
            {
              type: DATE,
              name: "created_date",
              keyName: "created_date",
              label: getLocalisationMessage("created_date"),
            },
            {
              type: RENDER,
              name: "created_by",
              label: getLocalisationMessage("created_by"),
              render: row => _.get(row, `created_by`, ""),
            },
            {
              type: RENDER,
              name: "status",
              label: getLocalisationMessage("status"),
              render: row =>
                formatOrderStatusCodeForLocalisation(
                  _.get(row, `status`, ""),
                  getLocalisationMessage,
                ),
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
};
AdminBatchHistoryUpdateList.propTypes = {
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

export default enhancer(AdminBatchHistoryUpdateList);
