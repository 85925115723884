import React from "react";
import { reduxForm } from "redux-form";
import { Button, makeStyles } from "@material-ui/core";
import { compose, mapPropsStream, withContext } from "recompose";

import FlexBox from "../../components/ui-core/FlexBox";
import FormVoiceAutocomplete from "../../components/form/FormVoiceAutocomplete";
import FormToggle from "../../components/form/FormToggle";
import PropTypes from "prop-types";
import { isEqualData } from "../../helpers/DataUtils";
import { SettingsApi } from "../../api/shared/SettingsApi";
import fp from "lodash/fp";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";

const useStyles = makeStyles({
  root: {
    padding: 20,
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    fontSize: 17,
    marginBottom: 25,
  },
  form: {
    marginTop: 10,
    // width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowItem: {
    marginLeft: 10,
    width: 220,
  },
  button: {
    display: "flex",
    marginTop: 30,
    justifyContent: "flex-end",
  },
});

const voiceSortingDataPath = "map-voice";

const enhanced = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedVoice: PropTypes.func.isRequired,
      getVoicePredictions: PropTypes.func.isRequired,
    },
    (props) => ({
      getCachedVoice: props.getCachedVoice,
      getVoicePredictions: props.getVoicePredictions,
    }),
  ),
  mapPropsStream((propsStream) => {
    const db = new SettingsApi({ domain: "voice" });

    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .mergeMap(() => db.getItem(voiceSortingDataPath))
      .map(fp.get("payload.data.settings"))
      .map((settings) => {
        if (fp.isString(settings)) {
          return JSON.parse(settings);
        }
        return {};
      })
      .startWith({});

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
        saveData: (values) =>
          db.saveItem(voiceSortingDataPath, JSON.stringify(values)),
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdminVoiceSortingMapForm",
    onSubmit: (values, dispatch, props) => {
      props.saveData(values);
    },
  }),
);

const AdminVoiceSortingMapForm = ({ getLocalisationMessage, handleSubmit }) => {
  const classes = useStyles();

  return (
    <FlexBox direction="column" className={classes.root}>
      <div className={classes.title}>
        {getLocalisationMessage("link_sound_to_operations")}
      </div>
      <FlexBox direction="row" className={classes.form}>
        <FlexBox direction="column">
          <div className={classes.row}>
            <div className={classes.rowItem}>
              {" "}
              {getLocalisationMessage("failed")}:{" "}
            </div>
            <div className={classes.rowItem}>
              <FormVoiceAutocomplete
                name="failedVoice"
                label={getLocalisationMessage("choose_sound")}
                hintText="Type here ..."
              />
            </div>
            <div className={classes.rowItem}>
              <FormToggle name="failedEnabled" withLabel={false} />
            </div>
          </div>
          <div className={classes.row} style={{ marginTop: 10 }}>
            <div className={classes.rowItem}>
              {" "}
              {getLocalisationMessage("duplicated")}:{" "}
            </div>
            <div className={classes.rowItem}>
              <FormVoiceAutocomplete
                name="duplicatedVoice"
                label={getLocalisationMessage("choose_sound")}
                hintText="Type here ..."
              />
            </div>
            <div className={classes.rowItem}>
              <FormToggle name="duplicatedEnabled" withLabel={false} />
            </div>
          </div>
          <div className={classes.row} style={{ marginTop: 10 }}>
            <div className={classes.rowItem}>
              {" "}
              label={getLocalisationMessage("multi_box")}:{" "}
            </div>
            <div className={classes.rowItem}>
              <FormVoiceAutocomplete
                name="multiboxVoice"
                label={getLocalisationMessage("choose_sound")}
                hintText="Type here ..."
              />
            </div>
            <div className={classes.rowItem}>
              <FormToggle name="multiboxEnabled" withLabel={false} />
            </div>
          </div>
          <div className={classes.button}>
            <Button type="submit" color="secondary" onClick={handleSubmit}>
              label={getLocalisationMessage("save")}
            </Button>
          </div>
        </FlexBox>
        <FlexBox flex={true} />
        <FlexBox flex={true} />
      </FlexBox>
    </FlexBox>
  );
};

AdminVoiceSortingMapForm.propTypes = {
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

export default enhanced(AdminVoiceSortingMapForm);
