import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Avatar, Chip, Icon, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import {
  amber,
  blue,
  blueGrey,
  indigo,
  lightGreen,
  orange,
  red,
} from "@material-ui/core/colors";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import {
  FAILED_BIN,
  INCOMPLETE_BIN,
  UNASSIGNED_BIN,
} from "../../helpers/OrderSortingHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { accent1, success1 } from "../../../shared/theme/main-theme";
import {
  BANDEROL,
  LETTER,
  LETTER_VALUED,
  ONE_STEP,
  PARCEL,
  POSTCARD,
  SEKOGRAMM,
  SMALL_PACKETS,
  COURIER_SERVICE,
  COURIER_SERVICE_WITHOUT_BAG,
  ONE_STEP_WITHOUT_BAG,
} from "../../helpers/OrderOutboundSortingHelper";
import allTypes from "../../assets/orderTypes/all.png";
import letter from "../../assets/orderTypes/letter.png";
import banderol from "../../assets/orderTypes/banderol.png";
import smallParcel from "../../assets/orderTypes/small-parcel.png";
import parcel from "../../assets/orderTypes/parcel.png";
import oneStep from "../../assets/orderTypes/one-step.png";
import courierService from "../../assets/orderTypes/courier.png";
import cekogram from "../../assets/orderTypes/cekogram.png";
import undefinedPic from "../../assets/placeholder.png";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import useSheet from "react-jss";
import { getValue } from "../../helpers/DataUtils";
import { wordsTo2 } from "../../helpers/FormatUtils";

const ALL_TAB = "all";

const checkAccent = (selectBinCode, chip) => {
  const chipCode = getValue(chip, "code", null);
  return !!selectBinCode.includes(chipCode);
};

const checkAll = (selectBean, chip) =>
  getValue(selectBean, "code") === getValue(chip, "code", null);

function binArray(arr) {
  const elements = [];
  const open = {};

  for (let i = 0; i < arr.length; i++) {
    if (!open[arr[i]]) {
      elements.push(arr[i]);
      open[arr[i]] = true;
    }
  }

  return elements;
}

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    tabs: {
      margin: "0 -1rem",
    },
    chipWrapper: {
      marginTop: ".5rem",
    },
    chip: {
      marginRight: 5,
      marginBottom: 5,
    },
    chipAccent: { backgroundColor: accent1 },
    chipSuccess: { backgroundColor: success1 },
    chipAvatar: { backgroundColor: blueGrey[100] },
    chipPostPackage: { backgroundColor: blue[600], color: "white" },
    chipReadyPostPackage: { backgroundColor: orange[500], color: "white" },
    chipInsuredBag: { backgroundColor: blue[500], color: "white" },
    mixedTab: { backgroundColor: blueGrey[500] },
    currentTab: { backgroundColor: indigo[500] },
    saver: { backgroundColor: blue[500] },
    nextDay: { backgroundColor: lightGreen[500] },
    sameDay: { backgroundColor: amber[500] },
    urgent: { backgroundColor: red[500] },
    tab: {
      backgroundColor: "white",
      width: 182,
      height: 100,
      margin: "0 1rem",
      borderRadius: "1rem",
      cursor: "pointer",
    },
    activeTab: {
      backgroundColor: "#3F51B5",
      color: "white",
    },
  }),
);

OfflineOrderSortingChips.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object,
  sortingServiceTypes: PropTypes.array,
  sortingBeans: PropTypes.array,
  selectBin: PropTypes.array,
  onBeanUnFilter: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,
  onServiceTypeFilter: PropTypes.func,
  onBeanFilter: PropTypes.func,
  selectBean: PropTypes.array,
  selectFilterBin: PropTypes.array,
  simple: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function OfflineOrderSortingChips(props) {
  const {
    classes,
    getLocalisationMessage,
    sortingServiceTypes,
    sortingBeans,
    onServiceTypeFilter,
    onBeanFilter,
    onBeanUnFilter,
    filter,
    selectBin = [],
    selectBean,
    selectFilterBin,
    isSelected,
  } = props;

  return (
    <React.Fragment>
      {sortingServiceTypes && sortingServiceTypes.length > 0 && (
        <FlexBox flex={true} style={{ margin: "1rem 0" }}>
          {sortingServiceTypes.map((x, index) => (
            <Paper
              key={index}
              elevation={3}
              className={`${classes.tab} ${getValue(
                filter,
                "service_type_id",
              ) === getValue(x, "id", null) && classes.activeTab}`}
              onClick={() =>
                getValue(filter, "service_type_id") !==
                  getValue(x, "id", null) &&
                onServiceTypeFilter(getValue(x, "id", null))
              }
            >
              <FlexBox
                style={{ height: "100%", justifyContent: "space-evenly" }}
                direction="column"
                align={ALIGN_CENTER}
              >
                <img
                  style={{
                    height: 35,
                    objectFit: "contain",
                  }}
                  alt="asd"
                  src={
                    getValue(x, "code", null) === ALL_TAB
                      ? allTypes
                      : getValue(x, "code", null) === LETTER ||
                        getValue(x, "code", null) === POSTCARD
                      ? letter
                      : getValue(x, "code", null) === LETTER_VALUED
                      ? letter
                      : getValue(x, "code", null) === SMALL_PACKETS
                      ? smallParcel
                      : getValue(x, "code", null) === BANDEROL
                      ? banderol
                      : getValue(x, "code", null) === SEKOGRAMM
                      ? cekogram
                      : getValue(x, "code", null) === PARCEL
                      ? parcel
                      : getValue(x, "code", null) === ONE_STEP ||
                        getValue(x, "code", null) === ONE_STEP_WITHOUT_BAG
                      ? oneStep
                      : getValue(x, "code", null) === COURIER_SERVICE ||
                        getValue(x, "code", null) ===
                          COURIER_SERVICE_WITHOUT_BAG
                      ? courierService
                      : undefinedPic
                  }
                />
                <FlexBox>
                  {getLocalisationMessage(
                    fp.snakeCase(getValue(x, "code", null)),
                    getValue(x, "name", null),
                  )}
                  : {getValue(x, "count", null)}
                </FlexBox>
              </FlexBox>
            </Paper>
          ))}
        </FlexBox>
      )}

      <div className={classes.chipWrapper}>
        {!props.simple &&
          sortingBeans &&
          sortingBeans
            .sort(x =>
              getValue(x, "name", "") === UNASSIGNED_BIN ||
              getValue(x, "name", "") === FAILED_BIN ||
              getValue(x, "name", "") === INCOMPLETE_BIN
                ? 1
                : 0,
            )
            .map((chip, index) => {
              const binIcon =
                getValue(chip, "category") === "POST_PACKET"
                  ? "dynamic_feed"
                  : "mail_outline";

              const selectedAll =
                selectBin.filter(
                  bin => getValue(bin, "code") === getValue(chip, "code"),
                ).length === getValue(chip, "count");
              const readyForDirect =
                getValue(chip, "category") === "POST_PACKET";
              const selectBinCode = selectBin.map(select =>
                getValue(select, "code"),
              );

              const chipAccent = checkAccent(selectBinCode, chip);

              const chipAll = checkAll(selectBean, chip);
              return (
                <TooltipOverlay
                  touch={true}
                  label={`${getLocalisationMessage(
                    "shipments",
                    "Shipments",
                  )}: ${getValue(chip, "count")}`}
                  key={index}
                >
                  <Chip
                    className={cx(classes.chip, {
                      [classes.chipSuccess]: isSelected
                        ? getValue(filter, "bean.code") ===
                          getValue(chip, "code", null)
                        : (selectedAll && chipAll) ||
                          (chipAccent && selectedAll),
                      [classes.chipAccent]: chipAccent,
                    })}
                    onClick={() => {
                      if (
                        getValue(filter, "bean.code") !==
                        getValue(chip, "code", null)
                      ) {
                        onBeanFilter(chip);
                      } else {
                        onBeanUnFilter();
                      }
                    }}
                    avatar={
                      <Avatar
                        className={cx(classes.chipAvatar, {
                          [classes.chipReadyPostPackage]: readyForDirect,
                        })}
                        size={32}
                      >
                        {binIcon && <Icon fontSize="small">{binIcon}</Icon>}
                      </Avatar>
                    }
                    label={`${getLocalisationMessage(
                      wordsTo2(getValue(chip, "name")),
                      getValue(chip, "name"),
                    )} 
                  (${getValue(chip, "count")})`}
                  />
                </TooltipOverlay>
              );
            })}

        {props.simple &&
          sortingBeans &&
          sortingBeans
            .sort(x =>
              getValue(x, "name", "") === UNASSIGNED_BIN ||
              getValue(x, "name", "") === FAILED_BIN ||
              getValue(x, "name", "") === INCOMPLETE_BIN
                ? 1
                : 0,
            )
            .map((chip, index) => {
              const binIcon =
                getValue(chip, "category") === "POST_PACKET"
                  ? "dynamic_feed"
                  : "mail_outline";

              const selectedAll =
                selectBin.filter(bin => bin === getValue(chip, "code"))
                  .length === getValue(chip, "count");
              const readyForDirect =
                getValue(chip, "category") === "POST_PACKET";
              const beansCode = binArray(
                selectFilterBin.map(bean => getValue(bean, "code")),
              );
              const selectBinCode = selectBin.map(select => select);

              const filterBarcodes = getValue(filter, "beans", []).map(item =>
                getValue(item, "code"),
              );

              return (
                <TooltipOverlay
                  touch={true}
                  label={`${getLocalisationMessage(
                    "shipments",
                    "Shipments",
                  )}: ${getValue(chip, "count")}`}
                  key={index}
                >
                  <Chip
                    className={cx(classes.chip, {
                      [classes.chipSuccess]:
                        (selectBinCode.includes(getValue(chip, "code", null)) &&
                          selectedAll) ||
                        (selectedAll &&
                          beansCode.includes(getValue(chip, "code", null))),
                      [classes.chipAccent]: selectBinCode.includes(
                        getValue(chip, "code", null),
                      ),
                    })}
                    onClick={() => {
                      if (
                        !filterBarcodes.includes(getValue(chip, "code", null))
                      ) {
                        onBeanFilter(chip);
                      } else {
                        onBeanUnFilter(chip);
                      }
                    }}
                    avatar={
                      <Avatar
                        className={cx(classes.chipAvatar, {
                          [classes.chipReadyPostPackage]: readyForDirect,
                        })}
                        size={32}
                      >
                        {binIcon && <Icon fontSize="small">{binIcon}</Icon>}
                      </Avatar>
                    }
                    label={`${getLocalisationMessage(
                      wordsTo2(getValue(chip, "name")),
                      getValue(chip, "name"),
                    )} 
                  (${getValue(chip, "count")})`}
                  />
                </TooltipOverlay>
              );
            })}
      </div>
    </React.Fragment>
  );
}

export default enhancer(OfflineOrderSortingChips);
