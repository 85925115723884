import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { IconButton, CircularProgress, Button } from "@material-ui/core";
import { Add, Cancel } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { uploadFile } from "../../api/shared/FileApi";
import { muted2 } from "../../../shared/theme/main-theme";

const enhancer = compose(
  useSheet({
    root: {
      padding: "12px",
      position: "relative",
      marginBottom: "24px",
      border: `1px dashed ${muted2}`,
    },
    label: {
      userSelect: "none",
      fontSize: "12px",
      marginTop: "-6px",
      marginLeft: "-6px",
      marginBottom: "-6px",
    },
    button: {
      right: "12px",
      bottom: "-20px",
      position: "absolute",
      "& input": { display: "none" },
      "& label": { backgroundColor: "#ffc107" },
    },

    imageRow: {},
    imageRowScroll: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      overflowY: "hidden",
    },

    image: { maxHeight: "100%", maxWidth: "100%", userSelect: "none" },
    imageItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "64px",
      maxHeight: "64px",
      minWidth: "64px",
      maxWidth: "128px",
      marginTop: "12px",
      marginLeft: "12px",
      position: "relative",
    },

    imageButton: {
      padding: 0,
      width: "24px",
      height: "24px",

      top: "-12px",
      right: "-12px",
      position: "absolute",

      "&:after": {
        zIndex: -1,
        top: "7px",
        left: "7px",
        right: "7px",
        bottom: "7px",
        content: '""',
        position: "absolute",
        backgroundColor: "#fff",
      },

      "& svg": { fill: "#ff2b2b" },
    },

    imageProgress: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
  mapPropsStream(propsStream => {
    const { handler: onUpload, stream: onUploadStream } = createEventHandler();

    const imageListStream = onUploadStream
      .map(fp.flow(fp.get("target.files"), fp.map(fp.identity)))
      .mergeMap(files => Observable.from(files))
      .mergeMap(
        file =>
          uploadFile(file)
            .retryWhen(error => error.delay(300))
            .map(
              fp.flow(r =>
                fromJS(r).update("payload", p =>
                  p ? p.getIn(["data", "url"]) : p,
                ),
              ),
            ),
        (file, response) => [file, response],
      )
      .withLatestFrom(propsStream)
      .do(([[, response], props]) => {
        if (response.get("payload")) {
          props.fields.push(response.get("payload"));
        }
      })
      .map(fp.nth(0))
      .startWith(OrderedMap())
      .scan((acc, [file, response]) =>
        response.get("pending") ? acc.set(file, response) : acc.delete(file),
      )
      .map(item => item.toList())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(imageListStream, (props, imageList) => ({
        ...props,
        imageList,
        onUpload,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

const ImageUploadComponent = enhancer(
  ({ classes, imageList, fields, onUpload, ...custom }) => (
    <div className={classes.root}>
      <div className={classes.label}>{custom.label}</div>

      <div className={classes.imageRow}>
        <div className={classes.imageRowScroll}>
          {_.map(fields.getAll(), (url, index) => (
            <div key={index} className={classes.imageItem}>
              <IconButton
                className={classes.imageButton}
                onClick={() => fields.remove(index)}
              >
                <Cancel />
              </IconButton>

              <img src={url} alt="Parcel" className={classes.image} />
            </div>
          ))}

          {imageList
            .map((item, index) => (
              <div key={index} className={classes.imageItem}>
                <CircularProgress />
              </div>
            ))
            .toArray()}
        </div>
      </div>

      <Button mini={true} className={classes.button} component="label">
        <input
          type="file"
          multiple={true}
          accept="image/*"
          onChange={onUpload}
          style={{ display: "none" }}
        />

        <Add />
      </Button>
    </div>
  ),
);

FormImageListUpload.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default function FormImageListUpload(props) {
  return <FieldArray {...props} component={ImageUploadComponent} />;
}
