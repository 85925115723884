import React, { useEffect, useState } from "react";
import { fromJS, List, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormSelectField from "../form/FormSelectField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEmpty, toJS } from "../../helpers/DataUtils";
import { isEnableEventDateAtBatchUpdate } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import transitModeOptions from "../../constants/TransitModeTypes";
import { ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import _ from "lodash";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import {
  getDriverVehicles,
  getRouteChoose,
  getRouteDrivers,
  getRouteTemplatesChoose,
} from "../../api/shared/RouteTemplateApi";

export const INSURED_BAG = "insured_bag";
export const LETTER_BAG = "letter_bag";
export const PERIODIC_NEWS_BAG = "periodic_news_bag";
export const INTERNATIONAL_SHIPMENT_BAG = "international_shipment_bag";
export const INSURED_PACKAGES = "insured_packages";
export const FRAGILE_PARCELS = "fragile_parcels";
export const POST_PACKET = "post_packet";

export const categoriesConstants = Set.of(INSURED_BAG, LETTER_BAG, POST_PACKET);

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isEnableEventDateAtBatchUpdate: isEnableEventDateAtBatchUpdate(state),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: (props) => props.theme.palette.appBarTextColor,
      backgroundColor: (props) => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
    chip: { margin: "4px" },
    moreInfo: { marginTop: "5px", marginLeft: "10px" },
    form: {
      paddingTop: 15,
      "&> div": {
        marginBottom: 15,
      },
    },
    dialogHeaderContainer: {
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
    },
    dialogHeaderTitle: {
      flexGrow: 1,
    },
    dialogHeaderToggleContainer: {
      padding: "10px 5px 10px 20px",
      borderRadius: 1000,
      backgroundColor: "#DBE1E6",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  withHandlers({ onSubmit: (props) => (values) => props.onSubmit(values) }),
  reduxForm({
    enableReinitialize: true,
    form: "RouteDialogForm",
    validate: (values, props) => ({
      orderBarcodes:
        fp.isEmpty(values.orderBarcodes) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_orders")) ||
          "Add Orders"),
      route:
        isEmpty(values.route) &&
        props.getLocalisationMessage("this_field_is_required"),
      route_template:
        isEmpty(values.route_template) &&
        props.getLocalisationMessage("this_field_is_required"),
      driver:
        isEmpty(values.driver) &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    (props) => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,

      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  connect((state) => ({
    values: getFormValues("RouteDialogForm")(state),
  })),
);

RouteDialogForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  orderBarcodes: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,
  isSorting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  isEdit: PropTypes.bool,
  warehouseIds: PropTypes.array,
};

RouteDialogForm.defaultProps = {
  isSorting: false,
  onlyBarcode: false,
  isSupplierTransitOrdersVisibility: true,
  showSupplierDriverOnly: true,
  transitModeOptions,
};

function RouteDialogForm(props) {
  const { classes, getLocalisationMessage, isEdit, warehouseIds } = props;
  const [routes, setRoutes] = useState(List());
  const [templateRoutes, setTemplateRoutes] = useState(List());
  const [drivers, setDrivers] = useState(List());
  const [vehicles, setVehicles] = useState(List());
  const [isDifferentWarehouses, setIsDifferentWarehouses] = useState(false);

  const routeTemplate = _.get(props, "values.route_template");
  const route = _.get(props, "values.route");
  const driver = _.get(props, "values.driver");

  useEffect(
    () =>
      getRouteChoose()
        .then((res) => setRoutes(fromJS(_.get(res, "data"))))
        .catch((error) => showErrorMessage(error)),
    [],
  );
  useEffect(() => {
    const routeWarehouses = isEdit
      ? _.get(toJS(route), "warehouse_ids", [])
      : _.get(toJS(routeTemplate), "warehouse_ids", []);
    setIsDifferentWarehouses(
      !warehouseIds.every((element) => routeWarehouses.includes(element)),
    );
  }, [routeTemplate, route]);
  useEffect(
    () =>
      getRouteTemplatesChoose()
        .then((res) => setTemplateRoutes(fromJS(_.get(res, "data"))))
        .catch((error) => showErrorMessage(error)),
    [],
  );
  useEffect(() => {
    if (!isEmpty(routeTemplate))
      getRouteDrivers(routeTemplate.get("id"))
        .then((res) => setDrivers(fromJS(_.get(res, "data"))))
        .catch((error) => showErrorMessage(error));
  }, [routeTemplate]);
  useEffect(() => {
    if (!isEmpty(driver))
      getDriverVehicles(driver.get("id"))
        .then((res) => setVehicles(fromJS(_.get(res, "data"))))
        .catch((error) => showErrorMessage(error));
  }, [driver]);
  return (
    <Dialog open={props.open} maxWidth="auto" onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <div className={classes.dialogHeaderContainer}>
          <div className={classes.dialogHeaderTitle}>
            {getLocalisationMessage(isEdit ? "add_to_route" : "create_route")}
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form className={classes.form}>
          <FormChipInput
            name="orderBarcodes"
            focus={true}
            fullWidth={true}
            addOnBlur={true}
            label={getLocalisationMessage("orders_numbers", "Orders Numbers")}
          />
          {isEdit ? (
            <React.Fragment>
              <FormSelectField
                autoWidth={true}
                fullWidth={true}
                disabled={isEmpty(routes)}
                name="route"
                label={getLocalisationMessage("routes")}
                options={routes}
                formatOption={(x) =>
                  `${x.get("name")} ${x.getIn(["vehicle", "name"], "")}`
                }
              />
              {isDifferentWarehouses && (
                <p style={{ color: "#f57c00", textAlign: "center" }}>
                  {getLocalisationMessage("selected_diff_warehouses_route")}
                </p>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <FormSelectField
                autoWidth={true}
                fullWidth={true}
                disabled={isEmpty(templateRoutes)}
                name="route_template"
                label={getLocalisationMessage("route_templates")}
                options={templateRoutes}
                formatOption={(x) => x.get("name")}
              />
              {isDifferentWarehouses && (
                <p style={{ color: "#f57c00", textAlign: "center" }}>
                  {getLocalisationMessage("selected_diff_warehouses_route")}
                </p>
              )}
              <FormSelectField
                autoWidth={true}
                fullWidth={true}
                disabled={isEmpty(drivers)}
                name="driver"
                label={getLocalisationMessage("driver")}
                options={drivers}
                formatOption={(x) => x.get("name")}
              />
              <FormSelectField
                autoWidth={true}
                fullWidth={true}
                disabled={isEmpty(vehicles)}
                name="vehicle"
                label={getLocalisationMessage("vehicle")}
                options={vehicles}
                formatOption={(x) => x.get("name")}
              />
            </React.Fragment>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {getLocalisationMessage("cancel", "Cancel")}
        </Button>
        {props.isSorting ? (
          <Button
            primary={true}
            onClick={() =>
              props.setLocation(
                updateQuery(ORDER_LIST_URL, {
                  search: props.orderBarcodes.join(","),
                }),
              )
            }
          >
            {getLocalisationMessage("show_in_the_list", "Show In The List")}
          </Button>
        ) : (
          <Button
            primary={true}
            onClick={() =>
              props.setLocationQuery({ search: props.orderBarcodes.join(",") })
            }
          >
            {getLocalisationMessage("show_in_the_list", "Show In The List")}
          </Button>
        )}

        <Button primary={true} onClick={props.handleSubmit}>
          {getLocalisationMessage("ok", "Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(RouteDialogForm);
