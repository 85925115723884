import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITable, {
  ACTION,
  COLUMN,
  LINK,
  RENDER,
} from "../../components/orders-core/MUITable";
import _ from "lodash";
import {
  deleteAdditionalService,
  getAdditionalServices,
} from "../../api/shared/OrderApi";
import {
  ADDITIONAL_SERVICES_SETTINGS_CREATE_URL,
  ADDITIONAL_SERVICES_SETTINGS_ITEM_URL,
} from "../../constants/AdminPathConstants";
import { Add } from "@material-ui/icons";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdminAdditionalServicesList.propTypes = {
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
};

function AdminAdditionalServicesList({
  getLocalisationMessage,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  setLocation,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(20);
  useEffect(() => {
    setIsLoading(true);
    getAdditionalServices(page, size)
      .then((res) => {
        setIsLoading(false);
        setAdditionalServices(_.get(res, "data.list"));
        setTotal(_.get(res, "data.total"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage1(error);
      });
  }, [page, size]);

  const deleteAdditionalServiceDispatch = (id) => {
    deleteAdditionalService(id)
      .then(() => {
        showSuccessMessage1(
          getLocalisationMessage(
            "successfully_deleted",
            "Successfully deleted",
          ),
        );
        setAdditionalServices(additionalServices.filter((v) => v.id !== id));
        setTotal(total - 1);
      })
      .catch((error) => showErrorMessage1(error));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <AdminAppLayout title={getLocalisationMessage("additional_services")}>
      <MUITable
        isLoading={isLoading}
        title={getLocalisationMessage("additional_services")}
        size="small"
        list={additionalServices}
        total={total}
        page={page}
        rowsPerPage={size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        headerActions={
          <CustomButton
            onClick={() => setLocation(ADDITIONAL_SERVICES_SETTINGS_CREATE_URL)}
            startIcon={<Add />}
            variant={OUTLINED}
            color={SECONDARY}
            style={{
              marginTop: "1rem",
              marginRight: "1rem",
            }}
          >
            {getLocalisationMessage("create")}
          </CustomButton>
        }
        columns={[
          {
            type: LINK,
            name: "id",
            label: getLocalisationMessage("id"),
            link: ADDITIONAL_SERVICES_SETTINGS_ITEM_URL,
          },
          {
            type: COLUMN,
            name: "name",
            label: getLocalisationMessage("name"),
          },
          {
            type: RENDER,
            name: "active",
            label: getLocalisationMessage("status"),
            render: (row) =>
              getLocalisationMessage(
                _.get(row, `active`, false) ? "active" : "inactive",
              ),
          },
          {
            type: COLUMN,
            name: "rate_legal",
            label: getLocalisationMessage("rate_legal"),
          },
          {
            type: COLUMN,
            name: "rate_individual",
            label: getLocalisationMessage("rate_individual"),
          },
          {
            type: ACTION,
            label: getLocalisationMessage("delete"),
            dispatch: deleteAdditionalServiceDispatch,
          },
        ]}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminAdditionalServicesList);
