import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import Toggle from "../form/Toggle";

const enhancer = compose(
  useSheet({
    textNoWrap: {
      whiteSpace: "nowrap",
    },
    active: {
      fontWeight: "bold",
      color: "#4DB666",
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderDriversToggleElement.propTypes = {
  value: PropTypes.bool,
  classes: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderDriversToggleElement(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox align={ALIGN_CENTER}>
      <div
        className={cx(classes.textNoWrap, { [classes.active]: !props.value })}
      >
        {getLocalisationMessage("by_driver", "By Driver")}
      </div>
      <Toggle
        accent={true}
        value={props.value}
        onChange={value => props.onToggle(value)}
      />
      <div
        style={{ marginLeft: "1rem" }}
        className={cx(classes.textNoWrap, { [classes.active]: props.value })}
      >
        {getLocalisationMessage("by_delivery", "By Delivery")}
      </div>
    </FlexBox>
  );
}

export default enhancer(OrderDriversToggleElement);
