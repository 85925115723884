import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  Table,
  ListSubheader,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Text, { INVERT } from "../ui-core/Text";

const enhancer = compose(
  useSheet({
    details: {
      color: "#fff",
    },
    title: {
      color: "white",
      fontSize: "16px",
      textTransform: "uppercase",
      paddingLeft: 0,
      lineHeight: "19px",
      marginBottom: "10px",
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderItemProducts.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function OrderItemProducts(props) {
  const { classes, order, getLocalisationMessage } = props;

  if (
    !order ||
    !order.get("pickup_meta_data") ||
    !order.get("pickup_meta_data")
  )
    return null;

  const productItems = order.get("pickup_meta_data");

  return (
    <FlexBox className={classes.details}>
      <FlexBox flex={true} direction="column">
        <ListSubheader className={classes.title}>
          {getLocalisationMessage("products", "Products")}
        </ListSubheader>
        <FlexBox>
          <Table
            style={{
              width: "100%",
              background: "transparent",
            }}
          >
            <TableHead
              style={{
                color: "#ffffff",
                border: 0,
              }}
              displaySelectAll={false}
              adjustForCheckbox={false}
            >
              <TableRow>
                <TableCell>
                  <Text type={INVERT}>
                    {getLocalisationMessage("name", "Name")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text type={INVERT}>
                    {getLocalisationMessage("quantity", "Quantity")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text type={INVERT}>
                    {getLocalisationMessage("price", "Price")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text type={INVERT}>
                    {getLocalisationMessage("instruction", "Instruction")}
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody displayRowCheckbox={false}>
              {productItems &&
                productItems.map((item, key) => (
                  <TableRow
                    style={{
                      color: "#ffffff",
                    }}
                    selectable={false}
                    key={key}
                  >
                    <TableCell>
                      <span>{item.get("name")}</span>
                    </TableCell>
                    <TableCell>
                      <span>{item.get("quantity")}</span>
                    </TableCell>
                    <TableCell>
                      <span>{item.get("price_per_unit")}</span>
                    </TableCell>
                    <TableCell>
                      <span>{item.get("instruction")}</span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderItemProducts);
