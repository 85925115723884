import React from "react";
import { Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";

import {
  SLA_RULE_ITEM_URL,
  SLA_RULE_LIST_URL,
  SLA_RULE_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedWarehouse,
  getWarehousePolygons,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  createRule,
  getCachedRule,
  getRulePredictions,
  getChildRulePredictions,
} from "../../api/admin/AdminOrderRulesApi";
import {
  getCachedPostcode,
  getNeighborhoodPolygons,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedCountry,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import SlaRuleTree from "../../components/sla-rule/SlaRuleTree";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdminSlaManagementRuleCreateContainer.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function AdminSlaManagementRuleCreateContainer(props) {
  return (
    <AdminAppLayout
      title={props.i18n.get(
        "create_sla_management_rule",
        "Create SLA Management Rule",
      )}
    >
      <SlaRuleTree
        onSubmit={values => createRule(values).catch(ResponseError.throw)}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.i18n.get(
              "successfully_sla_management_rule_created",
              "Successfully Sla Management Rule Create",
            ),
          );
          props.setLocation(SLA_RULE_LIST_URL);
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
        createRuleUrl={SLA_RULE_CREATE_URL}
        getRuleUrl={rule => `${SLA_RULE_ITEM_URL}/${rule.id}`}
        getWarehousePolygons={getWarehousePolygons}
        getNeighborhoodPolygons={getNeighborhoodPolygons}
        getCachedRule={getCachedRule}
        getRulePredictions={getRulePredictions}
        getChildRulePredictions={getChildRulePredictions}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
        getCachedTimeSlot={getCachedTimeSlot}
        getTimeSlotPredictions={getTimeSlotPredictions}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        getCachedPostcode={getCachedPostcode}
        getPostcodePredictions={getPostcodePredictions}
        getCachedCountry={getCachedCountry}
        getCountryPredictions={getCountryPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminSlaManagementRuleCreateContainer);
