import React from "react";
import { Map } from "immutable";
import PropTypes from "prop-types";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import ParseBatchIdsDialog from "../../components/orders-core/ParseBatchIdsDialog";

AdminParseBatchIdsDialogWrapper.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmit: PropTypes.func,
  onBatchAssignSupplier: PropTypes.func,
  messages: PropTypes.instanceOf(Map),
  showBatchAssignSupplier: PropTypes.bool,
};

AdminParseBatchIdsDialogWrapper.defaultProps = {
  showBatchAssignSupplier: true,
};

export default function AdminParseBatchIdsDialogWrapper(props) {
  return (
    <ParseBatchIdsDialog
      messages={props.messages}
      open={props.open}
      showBatchAssignSupplier={props.showBatchAssignSupplier}
      onRequestClose={props.onRequestClose}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      onSubmit={props.onSubmit}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
      onBatchAssignSupplier={props.onBatchAssignSupplier}
    />
  );
}
