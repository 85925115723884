import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import FlexBox from "../../components/ui-core/FlexBox";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
    flex: "0 1 auto",
    marginRight: "1rem",
  },
});

const TableListHeader = ({ buttons, moreMenu }) => {
  const { root } = useStyles();
  return (
    <div className={root}>
      <FlexBox style={{ flex: "1 1 100%" }}>{buttons}</FlexBox>
      {moreMenu}
    </div>
  );
};

TableListHeader.propTypes = {
  buttons: PropTypes.elementType,
  moreMenu: PropTypes.element,
};

export default TableListHeader;
