import React from "react";
import { List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Timeline from "../timeline/Timeline";
import TimelineEvent from "../timeline/TimelineEvent";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { USERS_LIST_URL } from "../../constants/AdminPathConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCODHistoryTimeline.propTypes = {
  activities: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
};

function OrderCODHistoryTimeline(props) {
  const { getLocalisationMessage } = props;
  if (!props.activities) {
    return null;
  }

  return (
    <Timeline>
      {props.activities
        .map((item, key) => {
          const note = item.get("note");
          const codStatus = item.get("cod_status");
          const psfStatus = item.get("psf_status");

          const userName =
            item.getIn(["user", "name"]) || item.getIn(["user", "description"]);

          if (note) {
            return (
              <TimelineEvent
                key={key}
                date={item.get("date")}
                title={
                  <span>
                    {formatText(note.get("category"))}{" "}
                    {getLocalisationMessage(
                      "order_history_note_from",
                      "Note from",
                    )}{" "}
                    <strong>{userName}</strong>
                  </span>
                }
              >
                <div>
                  {note.get("reason") && (
                    <p>
                      {getLocalisationMessage(
                        "order_history_with_reason",
                        "With Reason",
                      )}
                      <strong>{note.get("reason")}</strong>
                    </p>
                  )}

                  <p>{note.get("content")}</p>
                </div>
              </TimelineEvent>
            );
          }

          return (
            <TimelineEvent
              key={key}
              date={item.get("date")}
              title={
                <span>
                  <div>
                    <span>{codStatus.get("text")} </span>{" "}
                    {codStatus.get("text") && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={updateQuery(USERS_LIST_URL, {
                          view: item.getIn(["user", "id"]),
                        })}
                      >
                        {item.getIn(["user", "name"])}
                      </a>
                    )}
                  </div>
                  <div>
                    <span>{psfStatus.get("text")} </span>{" "}
                    {psfStatus.get("text") && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={updateQuery(USERS_LIST_URL, {
                          view: item.getIn(["user", "id"]),
                        })}
                      >
                        {item.getIn(["user", "name"])}
                      </a>
                    )}
                  </div>
                </span>
              }
            />
          );
        })
        .toArray()}
    </Timeline>
  );
}

export default enhancer(OrderCODHistoryTimeline);
