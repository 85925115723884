import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
  Add as ContentAdd,
  CloudDownload as FileFileDownload,
  FilterList as ContentFilterList,
} from "@material-ui/icons";
import { batchAssignDriversWarehouse } from "../../actions/AdminDriverActions";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDriverListFilter } from "../../helpers/DriverFilterMapper";
import {
  getMarketplaceSettings,
  showDriverDynamicRunSheet,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getDriverList } from "../../api/admin/AdminDriverApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import AdminDriverFilterWrapper from "../../wrappers/admin/AdminDriverFilterWrapper";
import AdminDriverEditDialogWrapper from "../../wrappers/admin/AdminDriverEditDialogWrapper";
import AdminDriverCreateDialogWrapper from "../../wrappers/admin/AdminDriverCreateDialogWrapper";
import AdminDriverRunSheetFilterWrapper from "../../wrappers/admin/AdminDriverRunSheetFilterWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import UploadDriversDialog from "../../components/drivers-core/UploadDriversDialog";
import AssignDriverToWarehouseDialog from "../../components/drivers-core/AssignDriverToWarehouseDialog";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_DRIVER_CSV_URL,
  CREATE_DRIVER_RUNSHEET_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

const BATCH_ASSIGN_WAREHOUSE_DIALOG_HASH = "#BAWDH";
const DRIVER_FILTER_DIALOG_HASH = "#DFDH";
const DRIVER_UPLOAD_ORDER_DIALOG_HASH = "#DUODH"; //+

const enhancer = compose(
  connect(
    state => ({
      settings: getMarketplaceSettings(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      showDriverRunSheet: showDriverDynamicRunSheet(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      batchAssignDriversWarehouse,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  withState("isBatchLoading", "setBatchLoading", false),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDriverListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getDriverList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminDriverList.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  location: PropTypes.object,
  setLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  batchAssignDriversWarehouse: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRowSelect: PropTypes.func,
  selectedIds: PropTypes.instanceOf(OrderedSet),
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,

  isBatchLoading: PropTypes.bool,
  setBatchLoading: PropTypes.func,

  settings: PropTypes.instanceOf(Map),
  showDriverRunSheet: PropTypes.bool,
};

function AdminDriverList(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="drivers"
      title={getLocalisationMessage("drivers", "Drivers")}
    >
      <UploadDriversDialog
        onComplete={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        getLocalisationMessage={getLocalisationMessage}
        open={location.hash === DRIVER_UPLOAD_ORDER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <CheckOverallStatus location={location} />

      <PageLoading isLoading={props.isLoading || props.isBatchLoading} />
      <AssignDriverToWarehouseDialog
        onRequestClose={() => props.setLocationHash(null)}
        open={location.hash === BATCH_ASSIGN_WAREHOUSE_DIALOG_HASH}
        onSubmit={values =>
          props.batchAssignDriversWarehouse(
            props.selectedIds,
            values.warehouse.id,
          )
        }
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.setLocationHash(null);
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_assigned",
              "Successfully assigned",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
      />

      <OverallStatusTabs location={location} />

      <AdminDriverCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
        onVehicleClick={id =>
          props.setLocationQuery(fp.set("view_vehicle", id))
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create_vehicle", true))
        }
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminDriverFilterWrapper
        open={location.hash === DRIVER_FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />

      {props.showDriverRunSheet && (
        <AdminDriverRunSheetFilterWrapper
          open={fp.has("run_sheet_filter")(location.query)}
          driverId={fp.get("run_sheet_filter")(location.query)}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("run_sheet_filter"))
          }
        />
      )}

      <AdminDriverEditDialogWrapper
        tab={location.query.view_tab}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        driverId={fp.toFinite(location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.omit(["view", "view_tab"]));
        }}
        onVehicleClick={id =>
          props.setLocationQuery(fp.set("view_vehicle", id))
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create_vehicle", true))
        }
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <DataList
        isLoading={props.isLoading}
        selectedRowCount={props.selectedIds.size}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={8}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        altHeader={
          <div>
            <Button
              onClick={() =>
                props.setLocationHash(BATCH_ASSIGN_WAREHOUSE_DIALOG_HASH)
              }
            >
              {getLocalisationMessage(
                "assign_to_warehouse",
                "Assign To Warehouse",
              )}
            </Button>
            {props.showDriverRunSheet && (
              <Button
                target="_blank"
                component="a"
                href={updateQuery(CREATE_DRIVER_RUNSHEET_URL, {
                  ids: props.selectedIds.toJS().join(","),
                })}
              >
                {getLocalisationMessage(
                  "download_run_sheet",
                  "Download Run Sheet",
                )}
              </Button>
            )}
          </div>
        }
        cardActionIcons={
          <div>
            <Tooltip
              title={props.getLocalisationMessage(
                "create_new_driver",
                "Create New Driver",
              )}
            >
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <ContentAdd />
              </IconButton>
            </Tooltip>

            <Link to={updateHash(location, DRIVER_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={props.getLocalisationMessage(
                  "driver_filter",
                  "Driver Filter",
                )}
              >
                <IconButton>
                  <ContentFilterList />
                </IconButton>
              </Tooltip>
            </Link>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_DRIVER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {props.getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
              <MenuItem
                component="a"
                onClick={() => {
                  props.replaceLocationHash(DRIVER_UPLOAD_ORDER_DIALOG_HASH);
                }}
              >
                {props.getLocalisationMessage(
                  "upload_drivers",
                  "Upload Drivers",
                )}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            props.selectedIds.size > 0 &&
            props.selectedIds.size === props.list.size
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.list.toOrderedSet().map(item => item.get("driver_id"))
                : OrderedSet(),
            )
          }
          rowSelected={row =>
            props.selectedIds.has(row.cellData.get("driver_id"))
          }
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedIds.add(row.cellData.get("driver_id"))
                : props.selectedIds.delete(row.cellData.get("driver_id")),
            )
          }
        />

        <DataListColumn
          width={94}
          label={props.getLocalisationMessage("id", "ID")}
          dataKey="driver_id"
          /* disableSort={true} */
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <Link
                to={updateQuery(
                  location,
                  fp.set("view", row.cellData.get("driver_id")),
                )}
              >
                {row.cellData.get("driver_id")}
              </Link>
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("first_name", "First Name")}
          dataKey="name"
          /* disableSort={true} */
          cellRenderer={row =>
            row.cellData.get("first_name") || getLocalisationMessage("na")
          }
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("last_name", "Last Name")}
          dataKey="last_name"
          /* disableSort={true} */
          cellRenderer={row =>
            row.cellData.get("last_name") || getLocalisationMessage("na")
          }
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("login", "Login")}
          dataKey="login"
          /* disableSort={true} */
          cellRenderer={row =>
            row.cellData.get("login") || getLocalisationMessage("na")
          }
        />

        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("email", "Email")}
          dataKey="email"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("email") || getLocalisationMessage("na")
          }
        />
        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("warehouse", "Warehouse")}
          dataKey="warehouse"
          /* disableSort={true} */
          cellRenderer={row =>
            row.cellData.getIn(["warehouse", "name"]) ||
            getLocalisationMessage("na")
          }
        />
        <DataListColumn
          width={128}
          label={props.getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          disableSort={true}
          cellRenderer={row =>
            row.cellData.get("phone") || getLocalisationMessage("na")
          }
        />
        <DataListColumn
          width={94}
          label={props.getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => {
            const status = row.cellData.get("status");
            return getLocalisationMessage(status, formatText(status));
          }}
        />
        <DataListColumn
          width={94}
          label={props.getLocalisationMessage("join_date", "Join date")}
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("duty_status", "Duty Status")}
          dataKey="duty_status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row =>
            getLocalisationMessage(
              row.cellData.get("duty_status"),
              formatText(row.cellData.get("duty_status")),
            )
          }
        />

        {props.showDriverRunSheet && (
          <DataListColumn
            width={64}
            disableSort={true}
            label={getLocalisationMessage("runsheet", "Runsheet")}
            dataKey="runsheet_filter"
            cellRenderer={row => (
              <Tooltip
                title={props.getLocalisationMessage(
                  "runsheet_filter",
                  "Runsheet Filter",
                )}
              >
                <IconButton
                  onClick={() =>
                    props.setLocationQuery(
                      fp.set("run_sheet_filter", row.cellData.get("driver_id")),
                    )
                  }
                >
                  <FileFileDownload />
                </IconButton>
              </Tooltip>
            )}
          />
        )}
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDriverList);
