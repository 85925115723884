import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValues, getFormValues, reduxForm } from "redux-form";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  Cancel as NavigationCancel,
  ClearAll,
  Close,
  Done,
  Restore,
} from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId, idsToObjectArray } from "../../helpers/FormUtils";
import {
  formatDateTimeToUrl,
  formatText,
  safeParseDate,
} from "../../helpers/FormatUtils";
import {
  formatLocalisedPaymentType,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  isCustomMarketplace,
  isReverseLogisticEnabled,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import { filterFormPayTypes } from "../../constants/OrderPaymentTypes";
import { hasRole } from "../../helpers/RoleUtils";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import _ from "lodash";
import FormOperatorAutoComplete from "../form/FormOperatorAutoComplete";
import FormJMChips from "../form/FormJMChips";
import FormServiceTypeChips from "../form/FormServiceTypeChips";

const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";

const startTime = startOfToday();
const endTime = endOfToday();
const useStyles = makeStyles(theme => ({
  subheader: { flex: "1 1 0%", paddingLeft: "0px" },
  includeButton: {},
  marginBottom: {
    marginBottom: "15px",
  },
  marginBottom10: {
    marginBottom: "10px",
  },
  toggle: {
    marginTop: "13px",
    whiteSpace: "nowrap",
    "& label": { color: theme.palette.appBarTextColor },
  },
  filterName: {
    marginTop: "-8px",
    marginRight: "4px",
  },
  isTab: {
    marginLeft: "4px",
    "& label": {
      color: theme.palette.appBarTextColor,
      fontWeight: 400,
      fontSize: "18px",
    },
  },
  paddingTop: {
    paddingTop: "14px",
  },
  wrapContent: {
    "@media (max-width: 998px)": {
      flexWrap: "wrap",
      justifyContent: "flex-start",
      flexDirection: "column",
      "& > div": { justifyContent: "flex-start", width: "100%" },
    },
  },
  buttonHeightInput: {
    padding: "8px 8px 8px 0",
    "& button": {
      padding: "8px",
    },
  },
}));
const styles = {
  toggle: {
    thumbSwitchedStyle: { backgroundColor: "#fdd000" },
    trackSwitchedStyle: { backgroundColor: "#e3bf16" },
  },
  chipAutoComplete: {
    chipContainer: {
      maxHeight: "96px",
      overflowY: "auto",
    },
  },
};
const handleValuesToFilter = (values, filter, setFilter, setIsSelected) => {
  const tempValues = {};
  tempValues.service_types = values.courierTypes;
  tempValues.from_date_time = formatDateTimeToUrl(values.fromDateTime);
  tempValues.to_date_time = formatDateTimeToUrl(values.toDateTime);
  tempValues.operator_id = getObjectId(values.operator);
  tempValues.source_warehouse_id = getObjectId(values.sourceWarehouse);
  tempValues.destination_warehouse_id = getObjectId(
    values.destinationWarehouse,
  );
  tempValues.payment_types = values.paymentTypes;

  if (!fp.isEmpty(values.toJurisdictions)) {
    tempValues.to_jurisdiction_ids = values.toJurisdictions.map(v => v.id);
  }
  if (!fp.isEmpty(values.fromJurisdictions)) {
    tempValues.from_jurisdiction_ids = values.fromJurisdictions.map(v => v.id);
  }
  if (!fp.isEmpty(values.statuses)) {
    tempValues.statuses = values.statuses;
  }
  setFilter({
    ..._.omitBy(tempValues, v => v !== 0 && !v),
    page: 0,
    size: filter.size,
    init: true,
  });
  setIsSelected(false);
  localStorage.setItem(
    "filter",
    JSON.stringify({
      ..._.omitBy(tempValues, v => v !== 0 && !v),
      page: 0,
      size: filter.size,
      init: true,
    }),
  );
};
const getValues = getFormValues("OrderFilterFormForOffline");
const enhancer = compose(
  withTheme,
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isFinanceAdmin: hasRole(userRoles, ROLE_FINANCE_ADMIN),
      isCustom: isCustomMarketplace(state),
      orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
      reverseLogisticEnabled: isReverseLogisticEnabled(state),
    };
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const { filter: x } = props;

        return {
          statuses: x.statuses,

          courierTypes: x.service_types,

          toJurisdictions: idsToObjectArray(x.to_jurisdiction_ids),

          fromJurisdictions: idsToObjectArray(x.from_jurisdiction_ids),

          fromDateTime: safeParseDate(x.from_date_time),
          toDateTime: safeParseDate(x.to_date_time),

          operator: { id: x.operator_id || null },
          sourceWarehouse: {
            id: x.source_warehouse_id || null,
          },
          destinationWarehouse: {
            id: x.destination_warehouse_id || null,
          },
          paymentTypes: x.payment_types,
        };
      })
      .distinctUntilChanged(isEqualData);
    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "OrderFilterFormForOffline" }),
  formValues({
    fromJurisdictions: "fromJurisdictions",
    toJurisdictions: "toJurisdictions",
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
  }),
  connect(state => ({
    values: getValues(state),
  })),
);

OrderFilterFormForOffline.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  initialValues: PropTypes.object,
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,
  onDismiss: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  orderStatuses: PropTypes.object,
  filter: PropTypes.object,
  values: PropTypes.object,
  setFilter: PropTypes.func,
  closeDialog: PropTypes.func,
  setIsSelected: PropTypes.func,
};

function OrderFilterFormForOffline({
  change,
  dirty,
  fromDateTime,
  getLocalisationMessage,
  initialValues,
  onDismiss,
  orderStatuses,
  reset,
  toDateTime,
  values,
  filter,
  setFilter,
  closeDialog,
  setIsSelected,
}) {
  const classes = useStyles();
  const statusList =
    orderStatuses && orderStatuses.size > 0 ? orderStatuses : OrderStatusCodes;

  return (
    <form>
      <Card style={{ display: "flex", flexDirection: "column" }}>
        <CardContent>
          <FlexBox gutter={8} direction="column">
            <FlexBox flex={true}>
              <FlexBox gutter={16} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true}>
                  <FormChipAutoComplete
                    name="statuses"
                    fullWidth={true}
                    options={statusList}
                    formatOption={x =>
                      formatOrderStatusCodeForLocalisation(
                        x,
                        getLocalisationMessage,
                      ) || formatText(x)
                    }
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("include_statuses")}
                    chipContainerStyle={styles.chipAutoComplete.chipContainer}
                  />
                </FlexBox>

                <FlexBox flex={true}>
                  <FormOperatorAutoComplete
                    name="operator"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                    label={getLocalisationMessage("operator", "Operator")}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FlexBox gutter={16} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormChipAutoComplete
                    name="paymentTypes"
                    fullWidth={true}
                    options={filterFormPayTypes}
                    formatOption={x =>
                      formatLocalisedPaymentType(x, getLocalisationMessage)
                    }
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("payment_method")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormServiceTypeChips
                    name="courierTypes"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                    label={getLocalisationMessage(
                      "service_type",
                      "Service Type",
                    )}
                    chipContainerStyle={styles.chipAutoComplete.chipContainer}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column" flex={true}>
              <FlexBox flex={true} direction="column">
                <ListSubheader>
                  {getLocalisationMessage("created_date")}
                </ListSubheader>
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true}>
                    <FormDateField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage("from_date")}
                      initialTime={startTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTimeField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage("from_time")}
                    />
                  </FlexBox>

                  {Boolean(fromDateTime) && (
                    <FlexBox className={classes.buttonHeightInput}>
                      <IconButton onClick={() => change("fromDateTime", null)}>
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  )}

                  <FlexBox flex={true}>
                    <FormDateField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_date")}
                      initialTime={endTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTimeField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_time", "To Time")}
                    />
                  </FlexBox>

                  {Boolean(toDateTime) && (
                    <FlexBox className={classes.buttonHeightInput}>
                      <IconButton onClick={() => change("toDateTime", null)}>
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column">
              <ListSubheader>
                {getLocalisationMessage("jurisdiction")}
              </ListSubheader>
              <FlexBox flex={true} style={{ gap: 16 }}>
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormJMChips
                      name="fromJurisdictions"
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction_from")}
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormJMChips
                      name="toJurisdictions"
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction_to")}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column">
              <ListSubheader>
                {getLocalisationMessage("warehouses")}
              </ListSubheader>
              <FlexBox gutter={16} flex={true}>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    fullWidth={true}
                    name="sourceWarehouse"
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("pickup_warehouse")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    fullWidth={true}
                    name="destinationWarehouse"
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("destination_warehouse")}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>
        <CardActions
          style={{
            flex: "1 1 auto",
            boxShadow:
              "0px 2px 4px 1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <FlexBox
            gutter={16}
            container={16}
            flex={true}
            justify={JUSTIFY_CENTER}
          >
            <FlexBox>
              {dirty ? (
                <CustomButton
                  startIcon={<Restore />}
                  variant={OUTLINED}
                  color={SECONDARY}
                  onClick={reset}
                >
                  {" "}
                  {getLocalisationMessage("reset", "Reset")}{" "}
                </CustomButton>
              ) : (
                Boolean(onDismiss) && (
                  <CustomButton
                    startIcon={<Close />}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={onDismiss}
                  >
                    {" "}
                    {getLocalisationMessage("dismiss", "Dismiss")}{" "}
                  </CustomButton>
                )
              )}
            </FlexBox>
            <FlexBox>
              <CustomButton
                endIcon={<ClearAll />}
                variant={OUTLINED}
                color={SECONDARY}
                onClick={() => {
                  fp.keys(initialValues).forEach(key => {
                    change(key, null);
                  });
                }}
              >
                {" "}
                {getLocalisationMessage("clear", "Clear")}{" "}
              </CustomButton>
            </FlexBox>
            <FlexBox>
              <CustomButton
                endIcon={<Done />}
                variant={CONTAINED}
                color={SECONDARY}
                onClick={() => {
                  handleValuesToFilter(
                    values,
                    filter,
                    setFilter,
                    setIsSelected,
                  );
                  closeDialog();
                }}
              >
                {" "}
                {getLocalisationMessage("submit", "Submit")}{" "}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(OrderFilterFormForOffline);
