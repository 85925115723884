import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toCustomerFeedbacksFilter } from "../../helpers/CustomerFeedbacksMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  DRIVER_LIST_URL,
  SUPPLIER_LIST_URL,
  USERS_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  getCustomerFeedbackList,
  saveCustomerFeedback,
} from "../../api/admin/AdminCustomerFeedbacksApi";
import AdminUserEditDialogWrapper from "../../wrappers/admin/AdminUserEditDialogWrapper";
import AdminDriverEditDialogWrapper from "../../wrappers/admin/AdminDriverEditDialogWrapper";
import AdminSupplierEditDialogWrapper from "../../wrappers/admin/AdminSupplierEditDialogWrapper";
import AdminCustomerFeedbackFilterWrapper from "../../wrappers/admin/AdminCustomerFeedbackFilterWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import CustomerFeedbackList, {
  ADD_FEEDBACK_DIALOG_HASH,
} from "../../components/customer/CustomerFeedbackList";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { CUSTOMER_FEEDBACK } from "../../constants/RaitingType";
import FeedbackDialogV2 from "../../components/feedback-dialog/FeedbackDialogV2";
import ResponseError from "../../helpers/ResponseError";

const FILTER_DIALOG_HASH = "#FD";

const enhancer = compose(
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func,
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      stream: onRequestRefreshStream,
      handler: onRequestRefresh,
    } = createEventHandler();
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toCustomerFeedbacksFilter))
      .distinctUntilChanged(isEqualData);

    const responseStream = filterStream
      .switchMap(filter =>
        getCustomerFeedbackList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(
        filterStream,
        responseStream,
        (props, filter, response) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: response.get("list"),
          total: response.get("total"),
          isLoading: response.get("pending"),
        }),
      );
  }),
);

AdminCustomerFeedbackList.propTypes = {
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
  userWarehouseId: PropTypes.string,
};

function AdminCustomerFeedbackList(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="customer_feedback"
      title={getLocalisationMessage("customer_feedback", "Customer Feedback")}
    >
      <CheckOverallStatus location={location} />
      <AdminSupplierEditDialogWrapper
        supplierId={fp.toFinite(location.query.view_supplier)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_supplier"))}
      />
      <AdminOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_tab"]))
        }
        location={location}
      />

      <FeedbackDialogV2
        initialValues={{ score: 3 }}
        open={location.hash === ADD_FEEDBACK_DIALOG_HASH}
        onSubmit={values =>
          saveCustomerFeedback(
            toSnakeCase({
              ...values,
              rating_type: CUSTOMER_FEEDBACK,
            }),
          ).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.replaceLocationHash(null);
          props.onRequestRefresh();
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <AdminDriverEditDialogWrapper
        driverId={fp.toFinite(location.query.view_driver)}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("view_driver"));
        }}
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
      />

      <AdminUserEditDialogWrapper
        userId={fp.toFinite(props.location.query.view_user)}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("view_user"));
        }}
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
      />

      <AdminCustomerFeedbackFilterWrapper
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);

          props.setLocationQueryFilter(filter);
        }}
        open={location.hash === FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <CustomerFeedbackList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        hasSupplier={true}
        filter={props.filter}
        setLocationQueryFilter={props.setLocationQueryFilter}
        replaceLocationHash={props.replaceLocationHash}
        onRequestRefresh={props.onRequestRefresh}
        location={location}
        userUrl={USERS_LIST_URL}
        driverUrl={DRIVER_LIST_URL}
        supplierUrl={SUPPLIER_LIST_URL}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomerFeedbackList);
