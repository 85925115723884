import React, { useState } from "react";
import { Map } from "immutable";
import { compose, getContext, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getUser } from "../../reducers/ProfileReducer";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SidebarMenu } from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import { ROLE_AREA_EDITOR } from "../../../shared/constants/Authorities";
import JMAreasMap from "../../components/jurisdiction-management-core/JMAreasMap";
import SearchWrapper from "../../components/jurisdiction-management-core/SearchWrapper";
import AppLayoutDrawer from "../../components/app-layout/AppLayoutDrawer";
import JMAreaList from "../../components/jurisdiction-tree/JMAreaList";

const hasRole = (roles, role) =>
  Boolean(roles.find(r => r.get("code") === role));

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    padding: 0,
    overflow: "hidden",
    position: "relative",
  },
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  leftWrapper: {
    position: "absolute",
    width: "420px",
    height: "100%",
    zIndex: 3,
  },
  searchWrapper: {
    position: "absolute",
    width: "100%",
    zIndex: 3,
  },
}));

const enhancer = compose(
  getContext({
    replaceLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withState("activeNode", "setActiveNode", Map()),
  withState("editItem", "setEditItem", Map()),
  withState("isCreate", "setIsCreate", false),
  connect(
    state => {
      const userRoles = getUser(state).get("roles");

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        country: getMarketplaceCountry(state),
        isAreaEditable: hasRole(userRoles, ROLE_AREA_EDITOR),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

AdminJurisdictionManagementContainer.propTypes = {
  editItem: PropTypes.instanceOf(Map),
  isCreate: PropTypes.bool,
  activeNode: PropTypes.instanceOf(Map),

  setActiveNode: PropTypes.func,
  setEditItem: PropTypes.func,
  setIsCreate: PropTypes.func,
};

function AdminJurisdictionManagementContainer(props) {
  const [searchText, setSearchText] = useState("");
  const { setActiveNode } = props;

  const classes = useStyles();

  return (
    <FlexBox container={8} className={classes.container}>
      <FlexBox flex={true} className={classes.root}>
        <FlexBox className={classes.leftWrapper}>
          <FlexBox direction="column" flex={true}>
            <FlexBox className={classes.searchWrapper}>
              <FlexBox>
                <SearchWrapper
                  searchText={searchText}
                  setSearchText={setSearchText}
                  menuLayoutDrawer={({ visibleMenu, toggleMenu }) => (
                    <AppLayoutDrawer
                      open={visibleMenu}
                      className={classes.drawer}
                      onRequestChange={() => toggleMenu()}
                    >
                      <SidebarMenu />
                    </AppLayoutDrawer>
                  )}
                />
              </FlexBox>
            </FlexBox>

            <JMAreaList
              search={searchText}
              onNodeSelected={item => {
                props.setIsCreate(false);
                props.setEditItem(Map());
                setActiveNode(item);
              }}
              onEdit={editItem => {
                props.setEditItem(editItem);
                props.setIsCreate(false);
              }}
              onCreate={() => {
                props.setEditItem(Map());
                props.setIsCreate(true);
              }}
              activeNode={props.activeNode}
              onDismiss={() => {
                props.setIsCreate(false);
                props.setEditItem(Map());
              }}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <JMAreasMap
        currentRegion={props.activeNode}
        isEdit={props.editItem.has("id")}
        isCreate={props.isCreate}
      />
    </FlexBox>
  );
}

export default enhancer(AdminJurisdictionManagementContainer);
