import React from "react";
import { Map, List, OrderedMap } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withTheme } from "@material-ui/core/styles";
import DataList, { DataListColumn } from "../data-list/DataList";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  FRENCH_LANGUAGE,
  ENGLISH_LANGUAGE,
  RUSSIAN_LANGUAGE,
  SERBIAN_LANGUAGE,
  SPANISH_LANGUAGE,
  TURKISH_LANGUAGE,
  HUNGARIAN_LANGUAGE,
  MONGOLIAN_LANGUAGE,
} from "../../constants/LocaleTypes";
import {
  SAMPLE_ITEM_URL,
  SAMPLE_ITEM_URL_WITH_SETTINGS,
} from "../../constants/AdminPathConstants";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

function formatLanguage(code, getLocalisationMessage, notAvailableValue) {
  switch (code) {
    case ENGLISH_LANGUAGE:
      return getLocalisationMessage("english", "English");
    case RUSSIAN_LANGUAGE:
      return getLocalisationMessage("russian", "Russian");
    case TURKISH_LANGUAGE:
      return getLocalisationMessage("turkish", "Turkish");
    case FRENCH_LANGUAGE:
      return getLocalisationMessage("french", "French");
    case SERBIAN_LANGUAGE:
      return getLocalisationMessage("serbian", "Serbian");
    case MONGOLIAN_LANGUAGE:
      return getLocalisationMessage("mongolian", "Mongolian");
    case SPANISH_LANGUAGE:
      return getLocalisationMessage("spanish", "Spanish");
    case HUNGARIAN_LANGUAGE:
      return getLocalisationMessage("hungarian", "Hungarian");
    default:
      return notAvailableValue;
  }
}

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      statusMessages: getStatusLocalisation(state),
    };
  }),
  withTheme,
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
    buttonUpdate: {
      marginRight: "10px",
      color: props => props.theme.palette.appBarTextColor,
    },
  }),
);

SampleItemsList.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
  totalCount: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  onDeleteItem: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  overscanRowCount: PropTypes.number,
  rowCount: PropTypes.number,
  rowGetter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func.isRequired,
  statusMessages: PropTypes.instanceOf(Map),
  sampleItemsForSettings: PropTypes.bool,
  theme: PropTypes.object,
  location: PropTypes.object,
};

SampleItemsList.defaultProps = {
  overscanRowCount: 6,
};

function SampleItemsList(props) {
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      filter={props.filter}
      list={props.list}
      searchTypes={props.searchTypes}
      onFilterChange={props.onFilterChange}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.rowCount}
      rowGetter={props.rowGetter}
      cardActionIcons={props.cardActionIcons}
    >
      <DataListColumn
        width={96}
        label={getLocalisationMessage("id", "ID")}
        dataKey="id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row =>
          props.sampleItemsForSettings ? (
            <Link
              to={updateQuery(
                `${SAMPLE_ITEM_URL_WITH_SETTINGS}${row.cellData.get("id")}`,
              )}
            >
              {row.cellData.get("id")}
            </Link>
          ) : (
            <Link
              to={updateQuery(`${SAMPLE_ITEM_URL}${row.cellData.get("id")}`)}
            >
              {row.cellData.get("id")}
            </Link>
          )
        }
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("name", "Name")}
        disableSort={true}
        dataKey="name"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("name", NA)}</div>}
      />
      {props.sampleItemsForSettings && (
        <DataListColumn
          width={50}
          label={getLocalisationMessage("language", "Language")}
          disableSort={true}
          dataKey="language_code"
          justifyContent="center"
          cellRenderer={row =>
            formatLanguage(
              row.cellData.get("language_code"),
              getLocalisationMessage,
              NA,
            )
          }
        />
      )}
      <DataListColumn
        width={50}
        label={getLocalisationMessage("width_sm", "Width (sm)")}
        disableSort={true}
        dataKey="width"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("width_in_cm", NA)}</div>}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("length_sm", "Length (sm)")}
        disableSort={true}
        dataKey="length"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("length_in_cm", NA)}</div>}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("height_sm", "Height (sm)")}
        disableSort={true}
        dataKey="height"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("height_in_cm", NA)}</div>}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("weight_kg", "Weight (kg)")}
        disableSort={true}
        dataKey="weight"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("weight_in_kg", NA)}</div>}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("priority", "Priority")}
        disableSort={true}
        dataKey="priority"
        justifyContent="center"
        cellRenderer={row => <div>{row.cellData.get("priority", NA)}</div>}
      />

      <DataListColumn
        width={80}
        label={getLocalisationMessage("action", "Action")}
        disableSort={false}
        dataKey="action"
        justifyContent="center"
        cellRenderer={row => (
          <Link
            to={updateQuery(props.location, { remove: row.cellData.get("id") })}
          >
            <Button>{getLocalisationMessage("delete", "Delete")}</Button>
          </Link>
        )}
      />
    </DataList>
  );
}

export default enhancer(SampleItemsList);
