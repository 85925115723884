import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  clearDriverLiabilityQueue,
  getDriverLiabilityQueue,
  updateDriverLiabilityQueue,
} from "../../reducers/DriverLiabilityReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import { get } from "lodash";
import OperatorRefundForm from "../../components/driver-liability/OperatorRefundForm";
import { fromJS, List } from "immutable";
import {
  encodeTextByKeyboardPlace,
  isEqualData,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import { Observable } from "rxjs";
import {
  getOperatorCreditsBalance,
  getRefundList,
} from "../../api/admin/AdminOperatorApi";
import { toDriverLiabilityFilterMapper } from "../../helpers/DriverLiabiltyFilterMapper";

const useStyles = makeStyles({
  card: { "& > div": { display: "flex", flex: 1 } },
  totals: { textAlign: "center", padding: "12px 0" },
  withoutMargin: { margin: 0 },
  iconsWrapper: {
    flexWrap: "wrap",
    border: "1px solid #eee",
    "& > *": {
      flex: "1 1 auto",
    },
  },
  paymentType: {
    paddingLeft: ".5rem",
  },
  iconImg: {
    width: "2rem",
    height: "2rem",
  },
  topText: {
    textTransform: "uppercase",
    fontSize: "1.5rem",
  },
});
const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        selectedItems: getDriverLiabilityQueue(state),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      clearDriverLiabilityQueue,
      updateDriverLiabilityQueue,
    },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream((propsStream) => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toDriverLiabilityFilterMapper))
      .distinctUntilChanged(isEqualData);

    const customerListResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        getRefundList(get(props, "params.id"), filter).catch(() =>
          Observable.of({}),
        ),
      )
      .map((response) => fromJS(response))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        customerListResponseStream,
        (props, filter, customerList) => ({
          ...props,
          filter,
          list: customerList.getIn(["payload", "data", "content"], List()),
          total: customerList.getIn(["payload", "data", "total_elements"], 0),
          isLoading: customerList.get("pending", false),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminOperatorRefundBalanceList.propTypes = {
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  params: PropTypes.object,
  list: PropTypes.instanceOf(List),
};

function AdminOperatorRefundBalanceList(props) {
  const classes = useStyles();
  const { getLocalisationMessage } = props;
  const params = get(props, "params");
  const operatorId = get(params, "id");
  const operatorName = encodeTextByKeyboardPlace(get(params, "name"));
  const [isLoadOperatorCreditBalance, setIsLoadOperatorCreditBalance] =
    useState(true);
  // eslint-disable-next-line no-unused-vars
  const [operatorBalance, setOperatorBalance] = useState(0);
  useEffect(() => {
    if (isLoadOperatorCreditBalance && !operatorBalance) {
      setIsLoadOperatorCreditBalance(false);
      getOperatorCreditsBalance(operatorId)
        .then((res) => {
          // eslint-disable-next-line no-console
          console.log("res", res);
          // setOperatorBalance()
        })
        .catch((error) => showErrorMessage(error));
    }
  }, [operatorId]);
  return (
    <AdminAppLayout
      slug="operator_balance"
      title={getLocalisationMessage("operator_balance", "operator balance")}
    >
      <FlexBox
        container={8}
        flex={true}
        element={<Card className={classes.card} />}
      >
        <FlexBox
          gutter={8}
          flex={true}
          direction="column"
          element={<CardContent />}
        >
          <FlexBox justify={JUSTIFY_SPACE_AROUND} style={{ margin: "1rem 0" }}>
            <span className={classes.topText}>
              {getLocalisationMessage("credits", "Credits")}{" "}
              <span style={{ color: "#3F51B5", fontWeight: "bold" }}>
                {operatorName}
              </span>
            </span>
            <span className={classes.topText}>
              {getLocalisationMessage("total_debt", "total debt")}{" "}
              <span style={{ color: "#3F51B5", fontWeight: "bold" }}>
                {123.8888}
              </span>
            </span>
          </FlexBox>
          <FlexBox>
            <FlexBox
              gutter={8}
              flex={true}
              className={classes.iconsWrapper}
              align={ALIGN_CENTER}
            >
              <OperatorRefundForm operatorId={operatorId} />
            </FlexBox>
          </FlexBox>
          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox flex={true}>
                <DataList
                  isLoading={props.isLoading}
                  totalCount={props.total}
                  list={props.list}
                  rowCount={props.list.size}
                  rowGetter={(row) => props.list.get(row.index)}
                  filter={props.filter}
                  onFilterChange={(filter) =>
                    props.setLocationQueryFilter(filter)
                  }
                >
                  <DataListColumn
                    label={getLocalisationMessage("date", "Date")}
                    dataKey="date"
                    disableSort={true}
                    cellRenderer={(row) => (
                      <DateTimeCell
                        date={row.cellData.get("transaction_date")}
                      />
                    )}
                  />
                  <DataListColumn
                    label={getLocalisationMessage("sum", "Sum")}
                    disableSort={true}
                    dataKey="sum"
                    cellRenderer={(row) => (
                      <div
                        style={{
                          color:
                            row.cellData.get("accounting_type") === "CREDIT"
                              ? "#EB5757"
                              : "4CAF50",
                        }}
                      >
                        {row.cellData.get("accounting_type") === "CREDIT"
                          ? "-"
                          : " "}
                        {row.cellData.get("amount")}
                      </div>
                    )}
                  />
                </DataList>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOperatorRefundBalanceList);
