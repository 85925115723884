import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { componentFromStream, compose, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { Autocomplete } from "@material-ui/lab";
import { Chip, TextField } from "@material-ui/core";

// const dataSourceConfig = { text: "text", value: "value" };

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    chipInputRTL: {
      "& svg": {
        marginRight: "-8px",
        marginLeft: "4px",
      },
      '& div[style*="float"]': {
        float: "right",
      },
    },
  }),
);

const AbstractChips = componentFromStream(propsStream => {
  const {
    handler: onUpdateInput,
    stream: onUpdateInputStream,
  } = createEventHandler();

  // const {
  //   stream: onRequestAddStream,
  //   handler: onRequestAdd,
  // } = createEventHandler();
  // const {
  //   stream: onRequestDeleteStream,
  //   handler: onRequestDelete,
  // } = createEventHandler();

  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(props => {
      const mapValues = fp.flow(
        fp.map(fp.pick(["id", "name"])),
        fp.filter(isValidObjectId),
        fp.toPairs,
        fp.map(([index, value]) =>
          value.name
            ? Observable.of({ index, value, text: value.name })
            : props
                .getValue(value.id)
                .map(item => ({
                  index,
                  value: { id: value.id, name: item.get("name") },
                  text: item.get("name"),
                }))
                .startWith({ index, value, text: "Loading ..." })
                .catch(error =>
                  Observable.of({
                    index,
                    value,
                    text: `Failed to load "${value.id}"`,
                    error,
                  }),
                ),
        ),
      );

      return Observable.from(mapValues(props.input.value))
        .mergeAll()
        .scan((acc: List, item) => acc.set(item.index, fromJS(item)), List())
        .distinctUntilChanged(isEqualData)
        .map(fp.flow(acc => acc.toJS(), fp.toArray))
        .startWith([]);
    });

  const dataSourceStream = onUpdateInputStream
    .withLatestFrom(propsStream)
    .switchMap(([searchText, props]) =>
      props
        .getPredictions(searchText)
        .map(fp.flow(fp.get("payload"), fp.toArray, fromJS))
        .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .map(list =>
      list
        .map(item => ({
          value: {
            id: item.get("id"),
            name:
              item.get("phone") && item.get("phone") !== "null"
                ? `${item.get("name")} (${item.get("phone")})`
                : item.get("name"),
          },
          text: item.get("name"),
        }))
        .toArray(),
    )
    .startWith([]);

  return propsStream
    .map(fp.omit(["maxItems", "getValue", "getPredictions"]))
    .combineLatest(
      valueStream,
      dataSourceStream,
      // sideEffectsStream,
      (
        {
          input,
          // meta,
          // maxHeight,
          // canAutoPosition,
          // popoverProps,
          // isRTL,
          margin,
          ...props
        },
        value,
        dataSource,
      ) => (
        // console.log("props", props.hintText, props.label)

        <Autocomplete
          {...props}
          multiple={true}
          value={value}
          onFocus={fp.flow(fp.get("target.value"), onUpdateInput)}
          onChange={(_, newValue) => {
            const arr = fp.flow(
              fp.map(fp.get("value")),
              fp.compact,
              fp.uniqBy(v => (fp.isObject(v) ? fp.get("id", v) : v)),
            );
            input.onChange(arr(newValue));
          }}
          fullWidth={true}
          options={dataSource}
          getOptionLabel={option => option.value.name}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option.text} {...getTagProps({ index })} />
            ))
          }
          onInputChange={fp.flow(fp.get("target.value"), onUpdateInput)}
          renderInput={params => (
            <TextField
              {...params}
              variant={props.variant}
              size={props.size}
              label={props.label}
              placeholder={props.hintText}
              margin={margin}
            />
          )}
        />
      ),

      // return <ChipInput
      //   {...props}
      //   alwaysShowPlaceholder={true}
      //   value={value}
      //   onAdd={onRequestAdd}
      //   onDelete={onRequestDelete}
      //   // onChange={(e) => {console.log("e", e)}}
      //   defaultValue={value}
      //   blurBehavior="add"
      //   autoComplete="off"
      //   dataSource={dataSource.map(d => d.text)}
      //   // dataSourceConfig={dataSourceConfig}
      //   onUpdateInput={fp.flow(fp.get("target.value"), onUpdateInput)}
      //   popoverProps={{ canAutoPosition, ...popoverProps }}
      //   className={isRTL ? classes.chipInputRTL : ""}
      //   onBlur={fp.flow(fp.noop, input.onBlur)}
      //   onFocus={fp.flow(fp.noop, input.onFocus)}
      //   errorText={
      //     meta.touched && meta.invalid ? meta.error : ""
      //   }
      //   menuStyle={{
      //     maxHeight: `${maxHeight}px`,
      //     overflowX: "hidden",
      //     overflowY: "auto",
      //   }}
      // />
    );
});

AbstractChips.displayName = "AbstractChips";

FormAbstractChipsV2.propTypes = {
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,

  filter: PropTypes.func,
  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,
  canAutoPosition: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  getValue: PropTypes.func.isRequired,
  getPredictions: PropTypes.func.isRequired,
  style: PropTypes.object,

  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
};

FormAbstractChipsV2.defaultProps = {
  maxHeight: 320,
  openOnFocus: true,
  canAutoPosition: true,
  clearOnBlur: false,
  maxItems: Infinity,
  filter: fp.stubTrue,

  variant: "outlined",
  size: "small",
};

function FormAbstractChipsV2(props) {
  return <Field {...props} component={AbstractChips} />;
}

export default enhancer(FormAbstractChipsV2);
