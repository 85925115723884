import React from "react";
import { Map, List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import DataList, { DataListColumn } from "../data-list/DataList";
import {
  formatDate,
  formatText,
  FULL_DATE_URL_FORMAT,
} from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { BY_SIP } from "../../constants/OperationalReportTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

DailyCallPerformanceReport.propTypes = {
  showTable: PropTypes.bool,
  callsBy: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  reportList: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

DailyCallPerformanceReport.defaultProps = {
  callsBy: BY_SIP,
};

function DailyCallPerformanceReport(props) {
  const { getLocalisationMessage } = props;
  let list;
  let totalCount;
  let columnHeaders = new OrderedSet();

  if (props.showTable) {
    list = List()
      .withMutations(newList =>
        props.reportList.get(props.callsBy).forEach((item, key) => {
          let newItem = item.set("time", key);

          columnHeaders = columnHeaders.union(item.keySeq());

          if (props.callsBy === BY_SIP) {
            let total = 0;

            item.forEach(count => {
              total += fp.toFinite(count);
            });

            newItem = newItem.set("total", total);
          }

          newList.push(newItem);
        }),
      )
      .sort((a, b) => a.get("time").localeCompare(b.get("time")));

    totalCount = Map().withMutations(map =>
      list.forEach(item => {
        item.forEach((i, k) => {
          map.update(k, v => fp.toFinite(v) + i);
        });
      }),
    );

    list = list.push(
      totalCount.set("time", getLocalisationMessage("total", "Total")),
    );

    columnHeaders = columnHeaders.sort();
  }

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          {props.showTable && (
            <FlexBox direction="column" flex={true}>
              <DataList
                isLoading={false}
                totalCount={list.size}
                list={list}
                rowCount={list.size}
                overscanRowCount={columnHeaders.size}
                rowGetter={options => list.get(options.index)}
              >
                <DataListColumn
                  width={94}
                  label={`${formatDate(
                    props.fromDate,
                    FULL_DATE_URL_FORMAT,
                  )} - ${formatDate(props.toDate, FULL_DATE_URL_FORMAT)}`}
                  dataKey="time"
                  disableSort={true}
                  justifyContent="center"
                  cellRenderer={row => {
                    let time = row.cellData.get("time");

                    if (time === "21:00-") {
                      time += "onwards";
                    }

                    return time;
                  }}
                />

                {columnHeaders.map((item, key) => (
                  <DataListColumn
                    key={key}
                    width={94}
                    label={props.callsBy === BY_SIP ? item : formatText(item)}
                    dataKey={item}
                    disableSort={true}
                    justifyContent="center"
                    cellRenderer={row => row.cellData.get(item)}
                  />
                ))}

                {props.callsBy === BY_SIP && (
                  <DataListColumn
                    width={94}
                    label={getLocalisationMessage("total", "Total")}
                    dataKey="total"
                    disableSort={true}
                    justifyContent="center"
                    cellRenderer={row => row.cellData.get("total")}
                  />
                )}
              </DataList>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DailyCallPerformanceReport);
