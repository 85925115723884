import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  mapSchema,
  queryTypes,
  types,
} from "../../shared/helpers/ObjectMapper";

export const toJobsFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    use_solr: types.booleanOr(true),

    simple: types.boolean,
    include_dw: types.boolean,
    is_merchant: types.boolean,
    include_exception: types.boolean,
    auto_cancelled: types.boolean,
    requires_attention: types.boolean,
    scheduled_for_cancellation: types.boolean,

    cod_status: types.string,
    courier_type: types.string,
    payment_type: types.string,
    job_type: types.string,

    status: queryTypes.stringSeq,
    courier_types: queryTypes.stringSeq,

    vehicle_id: types.ID,
    company_id: types.ID,
    contract_owner_id: types.ID,
    last_mile_driver_id: types.ID,
    from_city_id: types.ID,
    to_city_id: types.ID,
    warehouse_id: types.ID,
    destination_warehouse_id: types.ID,

    driver_ids: queryTypes.IDSeq,
    exclude_driver_ids: queryTypes.IDSeq,
    supplier_ids: queryTypes.IDSeq,
    exclude_supplier_ids: queryTypes.IDSeq,
    customer_ids: queryTypes.IDSeq,
    exclude_customer_ids: queryTypes.IDSeq,
    parent_supplier_ids: queryTypes.IDSeq,
    exclude_parent_supplier_ids: queryTypes.IDSeq,
    pickupTimeslotIds: queryTypes.IDSeq,
    dropoffTimeslotIds: queryTypes.IDSeq,

    from_date_time: queryTypes.dateTime,
    to_date_time: queryTypes.dateTime,
    from_deadline_time: queryTypes.dateTime,
    to_deadline_time: queryTypes.dateTime,
    status_from_date_time: queryTypes.dateTime,
    status_to_date_time: queryTypes.dateTime,
  }),
);
