import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList as ContentFilterList } from "@material-ui/icons";
import { isEqualData } from "../../../helpers/DataUtils";
import { pipeStreams } from "../../../helpers/StreamUtils";
import ResponseError from "../../../helpers/ResponseError";
import DataListFilter from "../../../helpers/DataListFilter";
import { toBadAddressFilter } from "../../../helpers/BadAddressFilterMapper";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  confirmAsGoodAddress,
  getBadAddressItem,
  getBadAddressList,
} from "../../../api/admin/AdminAddressesApi";
import AdminBadAddressesFilterWrapper from "../../../wrappers/admin/AdminBadAddressesFilterWrapper";
import AdminBadAddressEditDialogWrapper from "../../../wrappers/admin/AdminBadAddressEditDialogWrapper";
import AdminOrderDetailsDialogWrapperV2 from "../../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import Redirect from "../../../components/router/Redirect";
import BadAddressList from "../../../components/bad-address-core/BadAddressList";
import BadAddressStatusTabs from "../../../components/bad-address-core/BadAddressStatusTabs";
import { updateHash, updateQuery } from "../../../../shared/helpers/UrlUtils";

const BAD_ADDRESS_FILTER_DIALOG_HASH = "#BAFDH";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "15px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      float: "right",
      marginLeft: "12px",
    },
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      showErrorMessage,
      showSuccessMessage,
    };
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toBadAddressFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getBadAddressList(props.filter.setValue("use_solr", true))
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response => fromJS(response))
          .map(response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminBadAddressListContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminBadAddressListContainer(props) {
  const { location, getLocalisationMessage } = props;

  const searchTypes = OrderedMap()
    .set("all", getLocalisationMessage("everything", "Everything"))
    .set(
      "order_number",
      getLocalisationMessage("search_by_order_number", "Order Number"),
    );

  return (
    <AdminAppLayout
      slug="bad_addresses"
      title={getLocalisationMessage("bad_addresses", "Bad Addresses")}
    >
      <AdminBadAddressEditDialogWrapper
        orderId={fp.toFinite(location.query.order_id)}
        addressId={fp.toFinite(location.query.address_id)}
        getLocation={getBadAddressItem}
        createOrderHref={(id, version) =>
          updateQuery(location, fp.set(version === 2 ? "view_v2" : "view", id))
        }
        onRequestClose={() => {
          props.setLocationQuery(fp.omit(["order_id", "address_id"]));
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
      />

      <AdminBadAddressesFilterWrapper
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        open={location.hash === BAD_ADDRESS_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <AdminOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view_v2)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_v2", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(fp.flow(fp.unset("view"), fp.unset("view_v2")))
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <Redirect
        when={
          !searchTypes.has(location.query.search_type) ||
          fp.isEmpty(props.location.query.badAddress)
        }
        to={updateQuery(
          location,
          fp.flow(
            fp.set("search_type", "order_number"),
            fp.set("badAddress", true),
          ),
        )}
      />

      <BadAddressStatusTabs location={location} />

      <BadAddressList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        searchTypes={searchTypes}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        location={props.location}
        createOrderHref={(id, version) =>
          updateQuery(location, fp.set(version === 2 ? "view_v2" : "view", id))
        }
        onUpdateAddress={(addressId, orderId) =>
          props.setLocationQuery(
            fp.flow(
              fp.set("address_id", addressId),
              fp.set("order_id", orderId),
            ),
          )
        }
        onSetGoodAddress={(addressId, orderId) =>
          Promise.resolve(
            confirmAsGoodAddress(addressId, orderId).catch(ResponseError.throw),
          )
            .then(() => {
              props.showSuccessMessage(
                getLocalisationMessage(
                  "address_is_successfully_updated",
                  "Address is successfully Updated",
                ),
              );
              props.onRequestRefresh();
            })
            .catch(error => {
              props.showErrorMessage(error);
            })
        }
        cardActionIcons={
          <Link to={updateHash(location, BAD_ADDRESS_FILTER_DIALOG_HASH)}>
            <Tooltip title={getLocalisationMessage("filter", "Filter")}>
              <IconButton>
                <ContentFilterList />
              </IconButton>
            </Tooltip>
          </Link>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminBadAddressListContainer);
