import React from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createDriver } from "../../api/admin/AdminDriverApi";
import {
  getVehicle,
  updateVehicle,
  getCachedVehicle,
  getVehiclePredictions,
} from "../../api/admin/AdminVehicleApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriverForm from "../../components/drivers-core/DriverForm";
import { getUserWarehouse } from "../../reducers/ProfileReducer";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { ACTIVE } from "../../constants/OverallStatus";
import { OrderedSet } from "immutable";
import { getCachedUserRoles } from "../../api/admin/AdminUserApi";
import { Observable } from "rxjs";
import _ from "lodash";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const userRolesResponseStream = getCachedUserRoles()
      .catch(() => Observable.of(OrderedSet()))
      .map(list => list.toOrderedSet());

    return propsStream
      .combineLatest(userRolesResponseStream, (props, userRoles) => ({
        ...props,
        userRoles,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminDriverCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  userWarehouse: PropTypes.object,
  onVehicleClick: PropTypes.func,
  onCreateVehicleClick: PropTypes.func,
  onSupplierClick: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  userRoles: PropTypes.instanceOf(OrderedSet),
};

function AdminDriverCreateDialogWrapper(props) {
  return (
    <ModalPaper
      title={props.getLocalisationMessage("create_driver", "Create Driver")}
      open={props.open}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <DriverForm
          initialValues={{
            warehouse: toJS(props.userWarehouse),
            status: ACTIVE,
            roles:
              props.userRoles && props.userRoles.size > 0
                ? props.userRoles
                    .filter(uRole => uRole.get("code") === "ROLE_DRIVER")
                    .toJS()
                : [],
          }}
          disableEmail={false}
          onDismiss={props.onRequestClose}
          onSubmit={values => {
            const roles = values.roles.map(role => {
              let found;
              if (_.isString(role)) {
                found = props.userRoles.find(
                  uRole => uRole.get("name") === role,
                );
              } else {
                found = props.userRoles.find(
                  uRole => uRole.get("name") === role.name,
                );
              }
              return found ? found.toJS() : null;
            });
            return createDriver(
              toSnakeCase({ ...values, roles: roles.filter(Boolean) }),
            ).catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedVehicle={getCachedVehicle}
          getVehiclePredictions={getVehiclePredictions}
          onVehicleClick={props.onVehicleClick}
          onCreateVehicleClick={props.onCreateVehicleClick}
          onSupplierClick={props.onSupplierClick}
          onCreateSupplierClick={props.onCreateSupplierClick}
          getVehicle={getVehicle}
          updateVehicle={updateVehicle}
          userRoles={props.userRoles.map(item => item.get("name"))}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminDriverCreateDialogWrapper);
