import React from "react";
import fp from "lodash/fp";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CourierContactTypes from "../../constants/CourierContactTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        return {
          contactTypes: parseString(x.getValue("contact_types")),

          supplier: {
            id: x.getIntegerValue("supplier_id") || null,
          },
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            supplier_id: getObjectId(values.supplier),
            contact_types: stringifyArray(values.contactTypes),
          });
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "SupplierContactsFilterForm", enableReinitialize: true }),
  pureComponent(fp.pick(["dirty", "initialValues"])),
);

SupplierContactsFilterForm.propTypes = {
  classes: PropTypes.object,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,

  onDismiss: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  getSupplierPredictions: PropTypes.func,
};

function SupplierContactsFilterForm(props) {
  return (
    <form onSubmit={props.handleSubmit}>
      <Card>
        <CardContent>
          <FlexBox flex={true}>
            <FormSupplierAutoComplete
              name="supplier"
              fullWidth={true}
              hintText={props.getLocalisationMessage("supplier", "Supplier")}
              label={props.getLocalisationMessage("supplier", "Supplier")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormChipAutoComplete
              name="contactTypes"
              fullWidth={true}
              options={CourierContactTypes}
              hintText={props.getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              label={props.getLocalisationMessage(
                "contact_type",
                "Contact Type",
              )}
              formatOption={x => props.getLocalisationMessage(x, formatText(x))}
            />
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox justify="flex-end">
            {props.dirty ? (
              <Button onClick={props.reset}>
                {props.getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {props.getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}
            <Button type="submit">
              {props.getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(SupplierContactsFilterForm);
