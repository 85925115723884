import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FormSwitcherDatePicker from "../form/FormSwitcherDatePicker";
import { formatDate, FULL_DATE_URL_FORMAT } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    datePicker: { borderBottom: "1px solid #eeeeee" },
  }),
  reduxForm({
    form: "DriversAttendanceNavigationForm",
  }),
);

DriversAttendanceNavigationForm.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  rightButtonLabel: PropTypes.string,
  navigationType: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function DriversAttendanceNavigationForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FormSwitcherDatePicker
      name="date"
      type={props.navigationType}
      title={getLocalisationMessage("select_date", "Select Date")}
      formatDate={date => formatDate(date, FULL_DATE_URL_FORMAT)}
      rightButtonLabel={props.rightButtonLabel}
      className={classes.datePicker}
      leftButtonLabel={props.leftButtonLabel}
      disabled={props.isLoading}
    />
  );
}

export default enhancer(DriversAttendanceNavigationForm);
