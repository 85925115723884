import { Observable } from "rxjs";
import React from "react";
import { startOfToday } from "date-fns";
import { Map, Set, List } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  withHandlers,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  MenuItem,
  CardHeader,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import {
  loadAllValues,
  loadListWithLongSearchQuery,
} from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import {
  getOrderReturnWarehouseSettings,
  removeOrderReturnWarehouseBinRule,
  setOrderReturnWarehouseBinRuleList,
  setDefaultOrderReturnWarehouseBinRules,
} from "../../firebase/OrderReturnDB";
import {
  FAILED_BIN,
  LOADING_BIN,
  UNSORTED_BIN,
  resetSortingTask,
  removeSortingTask,
  updateSortingTask,
  getOrderSortingTask,
  getOrderSortingOrders,
  removeSortingTaskOrders,
  addSortingTaskOrderNumbers,
  changeSortingTaskOrdersBin,
  fetchOrderSortingTaskOrders,
  fulfillOrderSortingTaskOrders,
  regroupSortingTaskBinsByRules,
  orderSortingTaskOrdersAssigned,
  orderSortingTaskOrdersNotAssigned,
} from "../../reducers/OrderReturnReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  DISPATCHED,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
} from "../../constants/OrderStatusCodes";
import {
  getOrderList,
  batchSetOrderSize,
  assignOrderToWarehouse,
} from "../../api/admin/AdminOrderApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import AdminOrderFilterWrapper from "../../wrappers/admin/AdminOrderFilterWrapper";
import AdminBatchUpdateOrderDialogWrapper from "../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import AdminBatchAssignSupplierDialogWrapper from "../../wrappers/admin/AdminBatchAssignSupplierDialogWrapper";
import FormWarehouseDialog from "../../components/form/FormWarehouseDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import ExportFileDialog from "../../components/files/ExportFileDialog";
import ImportFileDialog from "../../components/files/ImportFileDialog";
import FlexBox from "../../components/ui-core/FlexBox";
import LinkButton from "../../components/ui-core/LinkButton";
import PageLoading from "../../components/ui-core/PageLoading";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import OrderResultCard from "../../components/order-return/OrderResultCard";
import OrderReturnChips from "../../components/order-return/OrderReturnChips";
import OrderReturnTable from "../../components/order-return/OrderReturnTable";
import OrderReturnScanner from "../../components/order-return/OrderReturnScanner";
import OrderReturnRuleDialog from "../../components/order-return/OrderReturnRuleDialog";
import OrderReturnDriverOrders from "../../components/order-return/OrderReturnDriverOrders";
import OrderReturnRuleListDialog from "../../components/order-return/OrderReturnRuleListDialog";
import OrderReturnChangeBinDialog from "../../components/order-return/OrderReturnChangeBinDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { toJSON, fromJSON } from "../../../shared/helpers/DataSerializer";
import {
  CREATE_ORDER_MANIFEST_URL,
  CREATE_DRIVER_RUNSHEET_URL,
  CREATE_ORDER_AIRWAYBILL_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

const STATIC_BINS = [LOADING_BIN, FAILED_BIN, UNSORTED_BIN];

const baseFilter = new DataListFilter({
  size: 100,
  is_uae: true,
  include_dw: true,
  search_type: "order_number",
});

const basePrefetchFilter = baseFilter.setValueMap({
  size: 200,
  from_date_time: formatDateTimeToUrl(startOfToday()),
});

const getOrderListByChunks = loadListWithLongSearchQuery(
  fp.flow(getOrderList, x => x.takeLast(1)),
);

const getAllOrdersByFilter = loadAllValues(getOrderList);

const enhancer = compose(
  withTheme,
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    state => ({
      task: getOrderSortingTask(state),
      orders: getOrderSortingOrders(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      resetSortingTask,
      removeSortingTask,
      updateSortingTask,
      addSortingTaskOrderNumbers,
      removeSortingTaskOrders,
      fetchOrderSortingTaskOrders,
      fulfillOrderSortingTaskOrders,
      orderSortingTaskOrdersAssigned,
      orderSortingTaskOrdersNotAssigned,
      changeSortingTaskOrdersBin,
      regroupSortingTaskBinsByRules,
    },
  ),
  withHandlers({
    loadAndFulfillOrders: props => orderNumbers => {
      props.fetchOrderSortingTaskOrders(orderNumbers);

      return getOrderListByChunks(baseFilter.setSearch(orderNumbers.join(",")))
        .reduce(
          (acc, response) => acc.concat(response.getIn(["payload", "list"])),
          List(),
        )
        .map(orderList =>
          Map().withMutations(orders => {
            const notLoaded = orderNumbers.asMutable();

            orderList.forEach(order => {
              const orderNumber = order.get("order_number");

              notLoaded.delete(orderNumber);

              orders.set(
                orderNumber,
                Map({ number: orderNumber, payload: order }),
              );
            });

            notLoaded.forEach(orderNumber => {
              orders.set(
                orderNumber,
                Map({ number: orderNumber, failed: true }),
              );
            });
          }),
        )
        .do(x => props.fulfillOrderSortingTaskOrders(x))
        .toPromise();
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const warehouseSettingsStream = propsStream
          .map(props => props.task.getIn(["warehouse", "id"]))
          .distinctUntilChanged()
          .switchMap(id =>
            !id
              ? Observable.of(null)
              : getOrderReturnWarehouseSettings(id).startWith(null),
          );

        const taskOrdersStream = propsStream
          .map(fp.pick(["task", "orders"]))
          .distinctUntilChanged(isEqualData)
          .map(props =>
            props.task
              .get("orderNumbers")
              .map(x => props.orders.get(x))
              .toList(),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          taskOrdersStream,
          warehouseSettingsStream,
          (props, taskOrders, warehouseSettings) => ({
            ...props,
            taskOrders,
            warehouseSettings,
          }),
        );
      },
      propsStream => {
        const {
          handler: onOrderSubmit,
          stream: onAddOrderStream,
        } = createEventHandler();

        const {
          handler: onSizeChange,
          stream: onSizeChangeStream,
        } = createEventHandler();

        const taskBinsStream = propsStream
          .map(props => ({
            orderBins: props.task.get("orderBins"),
            taskOrders: props.task.get("orderNumbers"),
            selectedOrders: props.task.get("selectedOrders"),
          }))
          .distinctUntilChanged(isEqualData)
          .map(props =>
            Map()
              .withMutations(binMap => {
                props.orderBins.forEach((binName, orderNumber) => {
                  if (props.taskOrders.has(orderNumber)) {
                    if (!binMap.has(binName)) {
                      binMap.setIn([binName, "label"], binName);
                    }

                    binMap.updateIn([binName, "orders"], Set(), x =>
                      x.add(orderNumber),
                    );

                    if (props.selectedOrders.has(orderNumber)) {
                      binMap.updateIn([binName, "selected"], fp.add(1));
                    }
                  }
                });
              })
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                for (let i = 0; i < STATIC_BINS.length; i++) {
                  const staticBin = STATIC_BINS[i];

                  if (aName === staticBin) {
                    return -1;
                  }

                  if (bName === staticBin) {
                    return 1;
                  }
                }

                return aName.localeCompare(bName);
              }),
          )
          .distinctUntilChanged(isEqualData);

        const sideEffectsStream = Observable.merge(
          // Reset meta data of sorting task.
          propsStream.take(1).do(props => props.resetSortingTask()),
          // Sync reducer with firebase.
          propsStream
            .distinctUntilChanged(
              (a, b) =>
                a.task.getIn(["warehouse", "id"]) ===
                b.task.getIn(["warehouse", "id"]),
            )
            .do(props => props.updateSortingTask(x => x.delete("binRules")))
            .filter(props => props.task.hasIn(["warehouse", "id"]))
            .switchMap(props =>
              getOrderReturnWarehouseSettings(
                props.task.getIn(["warehouse", "id"]),
              ).do(settings =>
                props.updateSortingTask(x =>
                  x.set("binRules", settings.get("bin_rules")),
                ),
              ),
            ),
          // Fulfill orders once bin rules are loaded.
          propsStream
            .filter(
              props =>
                props.task.get("binRules").size > 0 &&
                props.task.get("orderNumbers").size > 0,
            )
            .take(1)
            .switchMap(props =>
              props.loadAndFulfillOrders(props.task.get("orderNumbers")),
            ),
          // Handle new orders.
          onAddOrderStream
            .withLatestFrom(propsStream)
            .do(([request, props]) =>
              props.addSortingTaskOrderNumbers(request.orderNumbers),
            )
            .mergeMap(([request, props]) =>
              Observable.defer(() =>
                props.loadAndFulfillOrders(request.orderNumbers),
              ).mergeMap(orders =>
                !request.warehouse
                  ? Observable.never()
                  : Observable.from(
                      orders.filter(x => x.hasIn(["payload", "id"])).toArray(),
                    )
                      .concatMap(order =>
                        Observable.defer(() =>
                          assignOrderToWarehouse(
                            order.getIn(["payload", "id"]),
                            request.warehouse.get("id"),
                          ),
                        ).retryWhen(x => x.delay(300)),
                      )
                      .toArray()
                      .switchMap(() =>
                        props.loadAndFulfillOrders(request.orderNumbers),
                      ),
              ),
            ),
          // Handle size change requests.
          onSizeChangeStream
            .withLatestFrom(propsStream)
            .mergeMap(([{ size, orderNumbers }, props]) =>
              Observable.defer(() =>
                batchSetOrderSize({
                  size,
                  order_numbers: orderNumbers.toArray(),
                }),
              )
                .retryWhen(x => x.delay(300))
                .switchMap(() => props.loadAndFulfillOrders(orderNumbers)),
            ),
        )
          .switchMapTo(Observable.never())
          .startWith(null);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(
            taskBinsStream,
            sideEffectsStream,
            (props, taskBins) => ({
              ...props,
              taskBins,
              onSizeChange,
              onOrderSubmit,
            }),
          );
      },
      propsStream => {
        const statsStream = propsStream
          .map(props => ({
            orders: props.orders,
            selectedOrders: props.task.get("selectedOrders"),
          }))
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const ids = Set().asMutable();
            const driverIds = Set().asMutable();
            const warehouseIds = Set().asMutable();

            const failedNumbers = Set().asMutable();
            const loadedNumbers = Set().asMutable();

            props.orders.forEach(order => {
              const orderNumber = order.get("number");

              if (order.hasIn(["payload", "id"])) {
                loadedNumbers.add(orderNumber);

                if (props.selectedOrders.has(orderNumber)) {
                  const payload = order.get("payload");

                  ids.add(payload.get("id"));

                  if (payload.hasIn(["driver", "id"])) {
                    driverIds.add(payload.getIn(["driver", "id"]));
                  }

                  if (payload.hasIn(["destination_warehouse", "id"])) {
                    warehouseIds.add(
                      payload.getIn(["destination_warehouse", "id"]),
                    );
                  }
                }
              } else if (order.get("failed")) {
                failedNumbers.add(orderNumber);
              }
            });

            return {
              selectedIds: ids.asImmutable(),
              selectedDriverIds: driverIds.asImmutable(),
              selectedWarehouseIds: warehouseIds.asImmutable(),
              failedOrders: failedNumbers.size,
              loadedOrders: loadedNumbers.size,
            };
          })
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(statsStream, (props, stats) => ({
          ...props,
          ...stats,
        }));
      },
    ),
  ),
);

AdminOrderReturnContainer.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  onSizeChange: PropTypes.func,
  onOrderSubmit: PropTypes.func,

  task: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
  taskBins: PropTypes.instanceOf(List),
  taskOrders: PropTypes.instanceOf(List),
  warehouseSettings: PropTypes.instanceOf(Map),

  failedOrders: PropTypes.number,
  loadedOrders: PropTypes.number,
  selectedIds: PropTypes.instanceOf(Set),
  selectedDriverIds: PropTypes.instanceOf(Set),
  selectedWarehouseIds: PropTypes.instanceOf(Set),

  loadAndFulfillOrders: PropTypes.func,
  fulfillOrderSortingTaskOrders: PropTypes.func,

  resetSortingTask: PropTypes.func,
  removeSortingTask: PropTypes.func,
  updateSortingTask: PropTypes.func,
  addSortingTaskOrders: PropTypes.func,
  removeSortingTaskOrders: PropTypes.func,
  changeSortingTaskOrdersBin: PropTypes.func,
  regroupSortingTaskBinsByRules: PropTypes.func,
  theme: PropTypes.object,
};
function AdminOrderReturnContainer(props) {
  const { task, location, getLocalisationMessage } = props;

  const orderNumbers = task.get("orderNumbers");
  const selectedOrders = task.get("selectedOrders");

  const hasActiveOrder = props.orders.has(task.get("activeOrder"));
  const activeOrder = hasActiveOrder
    ? props.orders.get(task.get("activeOrder"))
    : null;

  return (
    <AdminAppLayout
      slug="order_return"
      title={getLocalisationMessage("order_return", "Order Return")}
      appBarRightAction={
        <MenuButtonMore color={props.theme.palette.appBarTextColor}>
          <MenuItem
            onClick={() => props.setLocationQuery(fp.set("export", true))}
          >
            {getLocalisationMessage("export_task", "Export Task")}
          </MenuItem>

          <MenuItem
            onClick={() => props.setLocationQuery(fp.set("import", true))}
          >
            {getLocalisationMessage("import_task", "Import Task")}
          </MenuItem>
        </MenuButtonMore>
      }
    >
      <ExportFileDialog
        fileType="application/json"
        open={location.query.export === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("export"))}
        fileContent={location.query.export === "true" ? toJSON(task) : ""}
        fileName={`${fp.kebabCase(formatDateTimeToUrl(new Date()))}.wostjson`}
      />

      <ImportFileDialog
        accept=".wostjson"
        open={location.query.import === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("import"))}
        onSubmit={json => props.updateSortingTask(x => x.merge(fromJSON(json)))}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage("task_imported", "Task Imported"),
          );
          props.setLocationQuery(fp.unset("import"));
        }}
        onSubmitFail={props.showErrorMessage}
      />

      {!task.hasIn(["warehouse", "id"]) ? (
        <FormWarehouseDialog
          open={true}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          onSubmit={values =>
            getOrderReturnWarehouseSettings(values.warehouse.id)
              .take(1)
              .toPromise()
              .then(x => x.set("warehouse", Map(values.warehouse)))
          }
          onSubmitSuccess={settings =>
            props.updateSortingTask(x =>
              x.merge({
                binRules: settings.get("bin_rules"),
                warehouse: settings.get("warehouse"),
              }),
            )
          }
          onSubmitFail={props.showErrorMessage}
        />
      ) : (
        <div>
          <PageLoading isLoading={!props.warehouseSettings} />

          <ConfirmDialog
            open={Boolean(
              props.warehouseSettings &&
                task.get("binRules").isEmpty() &&
                location.query.view_rule_list !== "true",
            )}
            confirmButtonLabel={getLocalisationMessage(
              "add_rules",
              "Add Rules",
            )}
            onConfirm={() =>
              props.setLocationQuery(fp.set("view_rule_list", true))
            }
          >
            {getLocalisationMessage(
              "no_bin_create_new",
              "There are no bin sorting rules for current warehouse, do you want to create new?",
            )}
          </ConfirmDialog>

          <OrderReturnRuleListDialog
            open={location.query.view_rule_list === "true"}
            onRequestClose={() =>
              props.setLocationQuery(fp.unset("view_rule_list"))
            }
            onLoadDefaultPresetClick={() =>
              setDefaultOrderReturnWarehouseBinRules(
                task.getIn(["warehouse", "id"]),
              )
                .toPromise()
                .catch(props.showErrorMessage)
            }
            binRules={props.task.get("binRules")}
            onRuleClick={code =>
              props.setLocationQuery(
                fp.flow(fp.set("edit_rule", code), fp.unset("view_rule_list")),
              )
            }
            onAddRuleClick={() =>
              props.setLocationQuery(
                fp.flow(
                  fp.set("add_rule", true),
                  fp.unset("edit_rule"),
                  fp.unset("view_rule_list"),
                ),
              )
            }
            onRuleRemoveClick={code =>
              removeOrderReturnWarehouseBinRule(
                task.getIn(["warehouse", "id"]),
                code,
              ).toPromise()
            }
          />

          <OrderReturnRuleDialog
            open={
              location.query.add_rule === "true" ||
              props.task.hasIn(["binRules", location.query.edit_rule])
            }
            initialValues={toCamelCase(
              task.getIn(["binRules", location.query.edit_rule]),
            )}
            onRequestClose={() =>
              props.setLocationQuery(
                fp.flow(
                  fp.unset("add_rule"),
                  fp.unset("edit_rule"),
                  fp.set("view_rule_list", true),
                ),
              )
            }
            getCachedSupplier={getCachedSupplier}
            getSupplierPredictions={getSupplierPredictions}
            getCachedWarehouse={getCachedWarehouse}
            getWarehousePredictions={getWarehousePredictions}
            onSubmit={values =>
              setOrderReturnWarehouseBinRuleList(
                task.getIn(["warehouse", "id"]),
                task
                  .get("binRules")
                  .delete(location.query.edit_rule)
                  .set(values.code, toSnakeCase(values))
                  .toJS(),
              )
                .toPromise()
                .catch(ResponseError.throw)
            }
            onSubmitSuccess={() => {
              props.setLocationQuery(
                fp.flow(
                  fp.unset("add_rule"),
                  fp.unset("edit_rule"),
                  fp.set("view_rule_list", true),
                ),
              );
            }}
            onSubmitFail={props.showErrorMessage}
          />
        </div>
      )}

      <AdminOrderFilterWrapper
        filter={basePrefetchFilter}
        onFilterChange={filter => {
          props.setLocationQuery(fp.unset("prefetch"));

          getAllOrdersByFilter(filter)
            .do(response =>
              props.fulfillOrderSortingTaskOrders(
                Map().withMutations(orders => {
                  response.get("list").forEach(order => {
                    const orderNumber = order.get("order_number");

                    orders.set(
                      orderNumber,
                      Map({ number: orderNumber, payload: order }),
                    );
                  });
                }),
              ),
            )
            .toPromise()
            .catch(props.showErrorMessage);
        }}
        open={location.query.prefetch === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("prefetch"))}
      />

      <ConfirmDialog
        open={location.query.remove_all === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
        onConfirm={() => {
          props.removeSortingTask();
          props.setLocationQuery(fp.unset("remove_all"));
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_all_orders_from_queue",
          "Are you sure you want to remove all orders from queue?",
        )}
      </ConfirmDialog>

      <ConfirmDialog
        open={location.query.remove_selected === "true"}
        onRequestClose={() =>
          props.setLocationQuery(fp.unset("remove_selected"))
        }
        onConfirm={() => {
          props.setLocationQuery(fp.unset("remove_selected"));
          props.removeSortingTaskOrders(selectedOrders);
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove",
          "Are you sure you want to remove",
        )}
        {selectedOrders.size}
        {getLocalisationMessage("orders_from_queue", "orders from queue ?")}
      </ConfirmDialog>

      {props.selectedIds.size !== selectedOrders.size ? (
        <div>
          <ConfirmDialog
            open={
              location.query.set_supplier === "true" ||
              location.query.batch_update === "true"
            }
            onRequestClose={() =>
              props.setLocationQuery(
                fp.flow(fp.unset("set_supplier"), fp.unset("batch_update")),
              )
            }
          >
            {getLocalisationMessage(
              "there_are_orders_that_havent_been_loaded",
              "There are orders that haven't been loaded.",
            )}
          </ConfirmDialog>
        </div>
      ) : (
        <div>
          <ConfirmDialog
            open={location.query.regroup_bins === "true"}
            onConfirm={() => {
              props.regroupSortingTaskBinsByRules();
              props.setLocationQuery(fp.unset("regroup_bins"));
            }}
            onRequestClose={() =>
              props.setLocationQuery(fp.unset("regroup_bins"))
            }
          >
            {getLocalisationMessage(
              "all_custom_bins_will_be_removed",
              "All custom bins will be removed",
            )}
          </ConfirmDialog>

          <AdminBatchUpdateOrderDialogWrapper
            open={location.query.batch_update === "true"}
            initialValues={
              location.query.batch_update !== "true"
                ? {}
                : {
                    orderNumbers: selectedOrders.toArray(),
                    orderStatus: location.query.batch_update_status,
                    driver: {
                      id:
                        props.selectedDriverIds.size !== 1
                          ? null
                          : props.selectedDriverIds.first(),
                    },
                    warehouse: {
                      id:
                        props.selectedWarehouseIds.size !== 1
                          ? null
                          : props.selectedWarehouseIds.first(),
                    },
                  }
            }
            onRequestClose={() =>
              props.setLocationQuery(fp.unset("batch_update"))
            }
            onSubmitFail={props.showErrorMessage}
            onSubmitSuccess={response => {
              props.setLocationQuery(
                fp.flow(
                  fp.unset("batch_update"),
                  fp.set("batch_id", response.data.id),
                ),
              );
            }}
          />

          <AdminBatchAssignSupplierDialogWrapper
            open={location.query.set_supplier === "true"}
            onRequestClose={() =>
              props.setLocationQuery(fp.unset("set_supplier"))
            }
            initialValues={
              location.query.set_supplier !== "true"
                ? {}
                : { orderNumbers: props.selectedIds.toArray() }
            }
            onSubmitSuccess={response =>
              props.setLocationQuery(
                fp.flow(
                  fp.unset("set_supplier"),
                  fp.set("batch_id", response.data.id),
                ),
              )
            }
            onSubmitFail={props.showErrorMessage}
          />

          <AdminBatchUpdatesItemDialogWrapper
            batchId={fp.toFinite(props.location.query.batch_id)}
            onRequestClose={() => props.setLocationQuery(fp.unset("batch_id"))}
          />

          <OrderReturnChangeBinDialog
            orderBins={task.get("orderBins")}
            selectedOrders={selectedOrders}
            open={props.location.query.change_bin === "true"}
            onRequestClose={() =>
              props.setLocationQuery(fp.unset("change_bin"))
            }
            onSubmit={values => {
              props.setLocationQuery(fp.unset("change_bin"));
              props.changeSortingTaskOrdersBin(values.name, selectedOrders);
            }}
          />
        </div>
      )}

      <FlexBox container={8} direction="column" flex="none">
        <FlexBox gutter={8} direction="column">
          <FlexBox direction="column">
            {hasActiveOrder && (
              <OrderReturnDriverOrders
                order={activeOrder}
                taskOrders={props.taskOrders}
                orderNumbers={orderNumbers}
                getOrderList={getOrderList}
              />
            )}
          </FlexBox>
          <FlexBox direction="column">
            <Card>
              <CardHeader
                title={
                  task.hasIn(["warehouse", "id"]) ? (
                    <FlexBox justify="space-between">
                      <span>
                        {getLocalisationMessage(
                          "order_sorting",
                          "Order sorting:",
                        )}{" "}
                        <strong>
                          <LinkButton
                            onClick={() =>
                              props.updateSortingTask(x =>
                                x.delete("warehouse"),
                              )
                            }
                          >
                            {task.getIn(["warehouse", "name"])}
                          </LinkButton>
                        </strong>{" "}
                        ({orderNumbers.size}{" "}
                        {getLocalisationMessage("in_queue", "in queue")}
                        {props.loadedOrders > 0 &&
                          `, ${props.loadedOrders} ${getLocalisationMessage(
                            "prefetched",
                            "prefetched",
                          )}`}
                        {props.failedOrders > 0 &&
                          `, ${props.failedOrders} ${getLocalisationMessage(
                            "failed",
                            "failed",
                          )}`}
                        )
                      </span>

                      <span>
                        {getLocalisationMessage("counter", "Counter:")}{" "}
                        {task.get("counter").size} (
                        <LinkButton
                          onClick={() =>
                            props.updateSortingTask(x =>
                              x.update("counter", counter => counter.clear()),
                            )
                          }
                        >
                          {getLocalisationMessage("reset", "Reset")}
                        </LinkButton>
                        )
                      </span>
                    </FlexBox>
                  ) : null
                }
              />
              <CardContent>
                <FlexBox gutter={8}>
                  <OrderReturnScanner
                    focusInput={false}
                    onSubmit={values =>
                      props.onOrderSubmit({
                        orderNumbers: Set(values.orderNumbers),
                        warehouse: !values.assignToWarehouse
                          ? null
                          : task.get("warehouse"),
                      })
                    }
                    onPrefetchOrdersClick={() =>
                      props.setLocationQuery(fp.set("prefetch", true))
                    }
                    onRemoveAllOrdersClick={
                      orderNumbers.isEmpty()
                        ? null
                        : () =>
                            props.setLocationQuery(fp.set("remove_all", true))
                    }
                    onReloadAllOrdersClick={
                      orderNumbers.isEmpty()
                        ? null
                        : () => props.loadAndFulfillOrders(orderNumbers)
                    }
                  />

                  {props.orders.has(task.get("activeOrder")) && (
                    <OrderResultCard
                      order={props.orders.get(task.get("activeOrder"))}
                      filterChips={props.taskBins}
                      onReloadClick={() =>
                        props.loadAndFulfillOrders(
                          Set.of(task.get("activeOrder")),
                        )
                      }
                      onRemoveClick={() =>
                        props.removeSortingTaskOrders(
                          Set.of(task.get("activeOrder")),
                        )
                      }
                      onSizeChange={size =>
                        props.onSizeChange({
                          size,
                          orderNumbers: Set.of(task.get("activeOrder")),
                        })
                      }
                    />
                  )}
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>

          {props.taskOrders.size > 0 && (
            <FlexBox direction="column">
              <Card>
                <CardHeader
                  title={getLocalisationMessage(
                    "orders_in_queue",
                    "Orders In Queue",
                  )}
                />

                <CardContent>
                  <OrderReturnChips
                    filterChips={props.taskBins}
                    onAddOrders={orders =>
                      props.updateSortingTask(x =>
                        x.update("selectedOrders", selected =>
                          selected.merge(orders),
                        ),
                      )
                    }
                    onRemoveOrders={orders =>
                      props.updateSortingTask(x =>
                        x.update("selectedOrders", selected =>
                          selected.subtract(orders),
                        ),
                      )
                    }
                  />
                  <br />
                  <Button
                    onClick={() =>
                      props.setLocationQuery(fp.set("regroup_bins", true))
                    }
                  >
                    {" "}
                    {getLocalisationMessage(
                      "regroup_by_rules",
                      "Regroup By Rules",
                    )}{" "}
                  </Button>

                  <Button
                    onClick={() =>
                      props.setLocationQuery(fp.set("view_rule_list", true))
                    }
                  >
                    {" "}
                    {getLocalisationMessage(
                      "edit_grouping_rules",
                      "Edit Grouping Rules",
                    )}{" "}
                  </Button>
                </CardContent>

                <CardContent>
                  <OrderReturnTable
                    orders={props.taskOrders}
                    onSizeChange={(orderNumber, size) =>
                      props.onSizeChange({
                        size,
                        order_numbers: Set.of(orderNumber),
                      })
                    }
                    selectedOrders={selectedOrders}
                    onOrdersSelect={orders =>
                      props.updateSortingTask(x =>
                        x.set("selectedOrders", orders),
                      )
                    }
                    onReloadClick={orderNumber =>
                      props.loadAndFulfillOrders(Set.of(orderNumber))
                    }
                    onRemoveClick={orderNumber =>
                      props.removeSortingTaskOrders(Set.of(orderNumber))
                    }
                    headerActions={
                      <MenuButtonMore desktop={false}>
                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(fp.set("change_bin", true))
                          }
                        >
                          {getLocalisationMessage("change_bin", "Change Bin")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(
                              fp.set("remove_selected", true),
                            )
                          }
                        >
                          {getLocalisationMessage("remove", "Remove")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(
                              fp.flow(
                                fp.set("batch_update", true),
                                fp.unset("batch_update_status"),
                              ),
                            )
                          }
                        >
                          {getLocalisationMessage("update", "Update")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(
                              fp.flow(
                                fp.set("batch_update", true),
                                fp.set("batch_update_status", IN_TRANSIT),
                              ),
                            )
                          }
                        >
                          {getLocalisationMessage("to_transit", "To Transit")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(
                              fp.flow(
                                fp.set("batch_update", true),
                                fp.set("batch_update_status", DISPATCHED),
                              ),
                            )
                          }
                        >
                          {getLocalisationMessage("dispatch", "Dispatch")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(
                              fp.flow(
                                fp.set("batch_update", true),
                                fp.set("batch_update_status", OUT_FOR_DELIVERY),
                              ),
                            )
                          }
                        >
                          {getLocalisationMessage("deliver", "Deliver")}
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            props.setLocationQuery(fp.set("set_supplier", true))
                          }
                        >
                          {getLocalisationMessage(
                            "set_supplier",
                            "Set Supplier",
                          )}
                        </MenuItem>

                        <MenuItem
                          target="_blank"
                          component="a"
                          href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
                            ids: props.selectedIds.join(","),
                          })}
                        >
                          {getLocalisationMessage("airwaybill", "Airwaybill")}
                        </MenuItem>

                        <MenuItemForm
                          url={CREATE_ORDER_MANIFEST_URL}
                          params={{
                            ids: props.selectedIds.toArray(),
                          }}
                          primaryText={getLocalisationMessage(
                            "manifest",
                            "Manifest",
                          )}
                        />

                        <MenuItemForm
                          url={CREATE_ORDER_MANIFEST_URL}
                          params={{
                            compact: true,
                            ids: props.selectedIds.toArray(),
                          }}
                          primaryText={getLocalisationMessage(
                            "compact_manifest",
                            "Compact Manifest",
                          )}
                        />

                        {props.selectedDriverIds.size > 0 && (
                          <MenuItem
                            target="_blank"
                            component="a"
                            href={updateQuery(CREATE_DRIVER_RUNSHEET_URL, {
                              ids: props.selectedDriverIds.join(","),
                            })}
                          >
                            {getLocalisationMessage("runsheet", "Runsheet")}
                          </MenuItem>
                        )}
                      </MenuButtonMore>
                    }
                  />
                </CardContent>
              </Card>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderReturnContainer);
