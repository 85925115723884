import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { HorizontalBar } from "./ChartComponent";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
      // paddingBottom: "120px"
    },
    title: {
      fontSize: "16px",
    },
    mainContainer: {
      "& > div:nth-child(1)": {
        fontSize: "16px",
      },
      "& > div:nth-child(2)": {
        fontSize: "20px",
        color: "#E91E63",
        "& > span": {
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    },
    priceValue: {
      fontWeight: "bold",
      "& > span": {
        paddingRight: "5px",
      },
    },
  }),
);

HorizantalStackedBarChart.propTypes = {
  data: PropTypes.object.isRequired,
};

HorizantalStackedBarChart.defaultProps = {
  width: 100,
  height: 20,
};

function HorizantalStackedBarChart(props) {
  const options = {
    tooltips: {
      mode: "nearest",

      callbacks: {
        title() {
          return null;
        },
        label(tooltipItem, data) {
          return `${
            data.datasets[tooltipItem.datasetIndex].label
          }: ${new Intl.NumberFormat("ru-RU").format(tooltipItem.value)}`;
        },
      },
      yPadding: 10,
      bodySpacing: 3,
      titleFontSize: 18,
      bodyFontSize: 18,
      displayColors: false,
    },
    maintainAspectRatio: false,
    legend: {
      position: "top",
      labels: {
        boxWidth: 19,
        fontSize: 18,
      },
    },
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 15,
        bottom: 15,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,

          gridLines: {
            drawOnChartArea: false,
            drawBorder: 0,
            drawTicks: 0,
          },
          ticks: {
            fontSize: 1,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          categoryPercentage: 0.8,
          barPercentage: 1,
          gridLines: {
            drawOnChartArea: false,
            drawBorder: 0,
            drawTicks: 0,
          },
          ticks: {
            fontSize: 1,
          },
        },
      ],
    },
  };

  return (
    <div
      style={{
        maxHeight: "130px",
        margin: 10,
      }}
    >
      <HorizontalBar data={props.data} options={options} height={180} />
    </div>
  );
}

export default enhancer(HorizantalStackedBarChart);
