import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getSupplierContact,
  updateSupplierContact,
} from "../../api/admin/AdminSupplierContactsApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SupplierContactForm from "../../components/suppliers-contacts-core/SupplierContactForm";

const enhancer = compose(
  renderIf(props => props.supplierContactId > 0),
  useSheet({
    modal: {
      maxWidth: "600px",
      minWidth: "600px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const supplierContactResponseStream = propsStream
      .distinctUntilKeyChanged("supplierContactId")
      .switchMap(props =>
        getSupplierContact(props.supplierContactId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        supplierContactResponseStream,
        (props, supplierContactResponse) => ({
          ...props,
          onRequestRefresh,
          supplierContact: supplierContactResponse.get("payload"),
          isLoading: supplierContactResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierContactEditDialogWrapper.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  supplierContactId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  supplierContact: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminSupplierContactEditDialogWrapper(props) {
  return (
    <ModalPaper
      title={`${props.getLocalisationMessage("contact", "Contact")} #${
        props.supplierContactId
      }`}
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      paperClassName={props.classes.modal}
    >
      <FlexBox container={8} direction="column">
        <SupplierContactForm
          onDismiss={props.onRequestClose}
          onSubmit={fp.flow(
            toSnakeCase,
            values => ({
              ...values,
              id: props.supplierContactId,
            }),
            values => updateSupplierContact(values).catch(ResponseError.throw),
          )}
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          initialValues={toCamelCase(props.supplierContact)}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminSupplierContactEditDialogWrapper);
