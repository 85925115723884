import "react-image-crop/dist/ReactCrop.css"; // eslint-disable-line import/no-internal-modules
import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import ReactCrop from "react-image-crop";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { uploadPublicFile } from "../../api/shared/FileApi";
import ImagePlaceholder from "../../assets/placeholder.png";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    photo: {
      maxWidth: "100%",
      maxHeight: "256px",
      objectFit: "contain",
    },
    buttonOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
    },
    modal: {
      maxWidth: "450px",
      minWidth: "450px",
      minHeight: "auto",
    },
    ".ReactCrop__crop-selection": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
    },
    canvas: { display: "none" },
    imageContainer: { minHeight: "256px" },
    input: { extend: "buttonOverlay", opacity: 0, cursor: "pointer" },
  }),
  reduxForm({
    form: "UploadPublicImageDialog",
  }),
  formValues("photo", "photoId"),
  withState("state", "setState", {}),
  mapPropsStream(propsStream => {
    const {
      handler: onUploadImage,
      stream: onUploadImageStream,
    } = createEventHandler();

    const uploadImageStream = onUploadImageStream
      .withLatestFrom(propsStream)
      .map(([, props]) => props)
      .filter(({ state }) => fp.isPlainObject(state.completeCrop))
      .map(({ state }) => {
        const img = new Image();
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");

        img.src = state.selectedImage.data;

        canvas.width = state.completeCrop.width;
        canvas.height = state.completeCrop.height;

        ctx.drawImage(
          img,
          state.completeCrop.x,
          state.completeCrop.y,
          state.completeCrop.width,
          state.completeCrop.height,
          0,
          0,
          state.completeCrop.width,
          state.completeCrop.height,
        );

        const dataURL = canvas.toDataURL("image/jpg");

        const blobBin = atob(dataURL.split(",")[1]);
        const array = [];

        for (let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }

        const file = new Blob([new Uint8Array(array)], { type: "image/jpg" });

        file.name = state.selectedImage.name;

        return file;
      })
      .switchMap(file =>
        uploadPublicFile(file).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .let(mapObjectResponseStream)
      .share();

    const sideEffectsStream = uploadImageStream
      .filter(
        uploadImage =>
          !uploadImage.get("pending") && uploadImage.get("payload"),
      )
      .withLatestFrom(propsStream)
      .do(([uploadImage, props]) => {
        props.setState(
          fp.flow(fp.unset("imageLoaded"), fp.unset("selectedImage")),
        );

        props.change("photoId", uploadImage.getIn(["payload", "id"]));
        props.change("photo", uploadImage.getIn(["payload", "url"]));
      })
      .startWith(null);

    return propsStream
      .combineLatest(
        uploadImageStream,
        sideEffectsStream,
        (props, uploadImage) => ({
          ...props,
          onUploadImage,
          isUploadingImage: uploadImage.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

UploadPublicImageDialog.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  handleSubmit: PropTypes.func,
  change: PropTypes.func,

  onUploadImage: PropTypes.func,

  isUploadingImage: PropTypes.bool,

  photoId: PropTypes.number,
  photo: PropTypes.string,

  state: PropTypes.object,
  setState: PropTypes.func,

  getLocalisationMessage: PropTypes.func,
};

function UploadPublicImageDialog(props) {
  const { classes, state, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={props.open}
      title={getLocalisationMessage("image", "Image")}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.modal}
    >
      <PageLoading isLoading={props.isUploadingImage} />

      <form>
        <FlexBox container={16}>
          <FlexBox gutter={8} flex={true} direction="column">
            <FlexBox justify="center" className={classes.imageContainer}>
              {Boolean(props.photo && !state.selectedImage) && (
                <img className={classes.photo} alt="Upload" src={props.photo} />
              )}
              {state.selectedImage && (
                <ReactCrop
                  crop={state.crop}
                  src={state.selectedImage.data}
                  onImageLoaded={image =>
                    props.setState(
                      fp.flow(
                        fp.set("imageLoaded", true),
                        fp.set("crop", {
                          unit: "%",
                          width: 100,
                          height: 100,
                          x: 0,
                          y: 0,
                        }),
                        fp.set("completeCrop", {
                          width: image.naturalWidth,
                          height: image.naturalHeight,
                          x: 0,
                          y: 0,
                        }),
                      ),
                    )
                  }
                  onComplete={(x, pixels) =>
                    props.setState(fp.set("completeCrop", pixels))
                  }
                  onChange={crop => props.setState(fp.set("crop", crop))}
                />
              )}
              {Boolean(!props.photo && !state.selectedImage) && (
                <img
                  alt="Upload"
                  className={classes.photo}
                  src={ImagePlaceholder}
                />
              )}
            </FlexBox>
            <FlexBox>
              <FlexBox gutter={8} flex={true} direction="column">
                <FlexBox>
                  <FlexBox gutter={8} flex={true}>
                    {!state.imageLoaded && (
                      <FlexBox flex={true} justify="center">
                        <Button
                          labelPosition="before"
                          onChange={event => {
                            const file = event.target.files[0];
                            const reader = new FileReader();

                            reader.onload = ({ target }) => {
                              props.setState(
                                fp.set("selectedImage", {
                                  name: file.name,
                                  data: target.result,
                                }),
                              );
                            };

                            reader.readAsDataURL(file);
                          }}
                        >
                          {getLocalisationMessage(
                            "select_photo",
                            "Select Photo",
                          )}
                          <input
                            type="file"
                            accept="image/*"
                            className={classes.input}
                          />
                        </Button>
                      </FlexBox>
                    )}
                    {state.imageLoaded && (
                      <FlexBox flex={true} justify="center">
                        <Button
                          onClick={() => {
                            props.onUploadImage();
                            props.setState(fp.unset("crop"));
                          }}
                        >
                          {getLocalisationMessage("apply", "Apply")}
                        </Button>
                      </FlexBox>
                    )}
                    {Boolean(
                      props.photo && !state.selectedImage && !state.imageLoaded,
                    ) && (
                      <FlexBox flex={true} justify="center">
                        <Button onClick={() => props.change("photo", null)}>
                          {getLocalisationMessage(
                            "remove_photo",
                            "Remove Photo",
                          )}
                        </Button>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <canvas id="canvas" className={classes.canvas} />
      </form>

      <FlexBox container={16}>
        <FlexBox gutter={8} flex={true} justify="flex-end">
          <FlexBox>
            <Button onClick={props.onRequestClose}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
          </FlexBox>
          <FlexBox>
            <Button onClick={props.handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(UploadPublicImageDialog);
