import fp from "lodash/fp";
import { compose, mapPropsStream, withHandlers } from "recompose";
import { formValues, reduxForm } from "redux-form";
import { TransitionUp } from "../dialog/TransitionUp";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { Map } from "immutable";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import FormTextField from "../form/FormTextField";
import { parseOnlyPositiveFloat } from "../../helpers/FormatUtils";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import { KeyboardArrowLeft, Save } from "@material-ui/icons";
import React from "react";
import PropTypes from "prop-types";
import { renderIf } from "../../helpers/HOCUtils";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import FormCheckbox from "../form/FormCheckbox";
import { LETTER } from "../../helpers/OrderOutboundSortingHelper";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import _ from "lodash";

const enhancer = compose(
  renderIf("open"),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .map(({ order }) => {
        const initialValues = {};
        let fieldFocus = null;

        if (order) {
          initialValues.id = _.get(order, "draft_order_id");
          initialValues.serviceType = _.get(order, "service_type.code");
          initialValues.isNotification = _.get(order, "is_notification");
          initialValues.jurisdiction = _.get(order, "to_jurisdiction");
          initialValues.weight = _.get(order, "weight", 0);
          initialValues.postcode = _.get(order, "to_post_code");
        }

        if (!initialValues.serviceType) {
          fieldFocus = "service_type";
        } else if (!initialValues.jurisdiction) {
          fieldFocus = "jurisdiction";
        } else if (!initialValues.postcode) {
          fieldFocus = "postcode";
        } else if (!initialValues.weight) {
          fieldFocus = "weight";
        }

        initialValues.fieldFocus = fieldFocus;

        return initialValues;
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  withHandlers({
    onSubmit:
      (props) =>
      ({
        jurisdiction,
        postcode,
        serviceType,
        fieldFocus,
        isNotification,
        ...values
      }) =>
        props.onSubmit(
          toSnakeCase({
            ...values,
            service_type: { code: serviceType },
            is_notification: serviceType === LETTER ? isNotification : null,
            recipient_jurisdiction: jurisdiction,
            recipient_postcode: postcode,
          }),
        ),
  }),
  reduxForm({
    form: "OrderSortingEditInsufficientOrderForm",
    enableReinitialize: true,
  }),
  formValues({
    postcode: "postcode",
    jurisdiction: "jurisdiction",
    weight: "weight",
    serviceType: "serviceType",
    fieldFocus: "fieldFocus",
  }),
);

OrderSortingEditInsufficientOrderFormDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,

  weight: PropTypes.number,
  fieldFocus: PropTypes.string,
  serviceType: PropTypes.string,

  initialValues: PropTypes.object,
  postcode: PropTypes.object,
  jurisdiction: PropTypes.object,

  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,

  order: PropTypes.instanceOf(Map),
};

function OrderSortingEditInsufficientOrderFormDialog(props) {
  const { getLocalisationMessage, isLoading, fieldFocus } = props;

  const isNotValid =
    !props.serviceType ||
    !isValidObjectId(props.jurisdiction) ||
    !isValidObjectId(props.postcode);

  return (
    <form>
      <Dialog
        TransitionComponent={TransitionUp}
        open={props.open}
        fullWidth={true}
        maxWidth="lg"
        onClose={props.onDismiss}
      >
        <DialogTitle style={{ borderBottom: "1px solid #eeeeee" }}>
          {getLocalisationMessage("edit")} - {_.get(props.order, "barcode")}
        </DialogTitle>
        <DialogContent>
          <FlexBox direction="column" flex={true}>
            <FormCourierTypeSelectField
              customError={
                !props.serviceType &&
                getLocalisationMessage("this_field_is_required")
              }
              s={true}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
              label={getLocalisationMessage("category", "What is Category in?")}
              name="serviceType"
              fullWidth={true}
            />

            {false && props.serviceType === LETTER && (
              <FlexBox style={{ marginBottom: ".5rem" }}>
                <FormCheckbox
                  name="isNotification"
                  style={{ padding: ".5rem .5rem" }}
                  label={getLocalisationMessage(
                    "with_notification",
                    "with notification",
                  )}
                />
              </FlexBox>
            )}
            <FormJMAutoComplete
              margin="normal"
              levelIds={[2, 3]}
              fullWidth={true}
              name="jurisdiction"
              autoFocus={fieldFocus === "jurisdiction"}
              onChange={(v) => {
                if (fp.get("id", v)) {
                  props.change("postcode", "");
                }
              }}
              label={getLocalisationMessage("recipient_jurisdiction")}
              customError={
                !isValidObjectId(props.jurisdiction) &&
                getLocalisationMessage("this_field_is_required")
              }
              renderOption={(option) => (
                <FlexBox direction="column">
                  <span>{option.name}</span>
                  <span
                    style={{
                      fontSize: ".8rem",
                      fontStyle: "italic",
                    }}
                  >
                    {option.value.hierarchy.map((item, index, arr) =>
                      index === arr.length - 1 ? item.name : `${item.name} > `,
                    )}
                  </span>
                </FlexBox>
              )}
            />

            <PostcodeAutoCompleteWithJM
              customError={
                !isValidObjectId(props.postcode) &&
                getLocalisationMessage("this_field_is_required")
              }
              label={getLocalisationMessage("index", "Index")}
              name="postcode"
              autoFocus={fieldFocus === "postcode"}
              margin="normal"
              fullWidth={true}
              jurisdictionId={fp.get("id", props.jurisdiction)}
              disabled={!fp.get("id", props.jurisdiction)}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />
            <FormTextField
              margin="normal"
              name="weight"
              autoFocus={fieldFocus === "weight"}
              label={getLocalisationMessage("weight_kg")}
              fullWidth={true}
              parseOnBlur={parseOnlyPositiveFloat}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {getLocalisationMessage("kg", "kg")}
                  </InputAdornment>
                ),
              }}
            />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox>
              <CustomButton
                onClick={props.onDismiss}
                startIcon={<KeyboardArrowLeft />}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("close_window", "Close window")}
              </CustomButton>

              <CustomButton
                disabled={isNotValid}
                style={{ marginLeft: "1rem" }}
                onClick={props.handleSubmit}
                endIcon={
                  isLoading ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <Save />
                  )
                }
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("save")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default enhancer(OrderSortingEditInsufficientOrderFormDialog);
