import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import MapAreasFilterForm from "../../components/map-areas/MapAreasFilterForm";
import { Dialog, DialogTitle } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  withTheme,
  renderIf("show"),
  useSheet({
    paper: { minWidth: "555px", maxWidth: "555px", minHeight: "220px" },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
);

AdminMapAreasFilterDialogWrapper.propTypes = {
  filter: PropTypes.instanceOf(DataListFilter),
  classes: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function AdminMapAreasFilterDialogWrapper(props) {
  const { classes, i18n } = props;
  return (
    <Dialog
      open={true}
      isLoading={false}
      paperClassName={classes.paper}
      onClose={props.onRequestClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get("map_areas_filter", "Map Areas Filter")}
      </DialogTitle>
      <MapAreasFilterForm
        initialValues={{
          country: {
            id: props.filter.getIntegerValue("countryId"),
          },
          city: {
            id: props.filter.getIntegerValue("areaId"),
          },
        }}
        onSubmit={props.onSubmit}
        onDismiss={props.onRequestClose}
      />
    </Dialog>
  );
}

export default enhancer(AdminMapAreasFilterDialogWrapper);
