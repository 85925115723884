import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Edit } from "@material-ui/icons";
import { formatText } from "../../helpers/FormatUtils";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { COMPLETED } from "../../constants/CODTransferStatus";
import { hasRole } from "../../helpers/RoleUtils";

const ROLE_FINANCE_APPROVER = "ROLE_FINANCE_APPROVER";
const ROLE_FINANCE_ADMIN = "ROLE_FINANCE_ADMIN";

const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isFinanceAdmin:
        hasRole(userRoles, ROLE_FINANCE_ADMIN) ||
        hasRole(userRoles, ROLE_FINANCE_APPROVER),
    };
  }),
);

BankDepositDetails.propTypes = {
  onEditStatusClick: PropTypes.func,
  item: PropTypes.instanceOf(Map).isRequired,
  isFinanceAdmin: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function BankDepositDetails(props) {
  const { getLocalisationMessage } = props;
  return (
    <Card>
      <CardContent>
        <List>
          <ListItem>
            <ListItemText
              disabled={true}
              primary={getLocalisationMessage("status", "Status")}
              secondary={getLocalisationMessage(
                props.item.get("status"),
                formatText(props.item.get("status")),
              )}
            />
            {props.onEditStatusClick &&
              props.item.get("status") !== COMPLETED &&
              props.isFinanceAdmin && (
                <ListItemSecondaryAction>
                  <IconButton onClick={props.onEditStatusClick}>
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
          </ListItem>
          <ListItem>
            <ListItemText
              disabled={true}
              primary={getLocalisationMessage("payment_ref", "Payment ref #")}
              secondary={
                getLocalisationMessage(
                  props.item.get("reference"),
                  formatText(props.item.get("reference")),
                ) || getLocalisationMessage("na", "N/A")
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disabled={true}
              primary={getLocalisationMessage("note", "Note")}
              secondaryLines={2}
              secondary={props.item.get("note") || "-"}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

export default enhancer(BankDepositDetails);
