import React, { useState } from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { getOrder, updateOrder } from "../../api/v2/admin/AdminOrderApi";
import AdminPostForm, {
  ERROR,
  LOADING,
  NOT_STARTED,
  SUCCESS,
} from "../../components/orders-core/AdminPostForm";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func,
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      baseCountry: getMarketplaceCountry(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const requestRefresh = createEventHandler();

    const responseStream = propsStream
      .map(fp.get("params.id"))
      .distinctUntilChanged()
      .switchMap(id =>
        getOrder(id)
          .let(mapObjectResponseStream)
          .repeatWhen(() => requestRefresh.stream),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        item: response.get("payload"),
        isLoading: response.get("pending"),
        onRequestRefresh: requestRefresh.handler,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminPostItem.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  item: PropTypes.instanceOf(Map),
  params: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
};

function AdminPostItem(props) {
  const { getLocalisationMessage } = props;
  const [isLoading, setIsLoading] = useState(NOT_STARTED);
  const [resMessage, setResMessage] = useState(
    getLocalisationMessage("successfully_updated"),
  );
  const [orderId, setOrderId] = useState(props.params.id);
  const [printReceipt, setPrintReceipt] = useState(false);

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage("post", "Post")} - ${props.params.id}`}
    >
      <AdminPostForm
        printReceipt={printReceipt}
        setPrintReceipt={setPrintReceipt}
        edit={true}
        orderId={orderId}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        resMessage={resMessage}
        order={props.item}
        orderBarcode={props.item.get("barcode")}
        onSubmit={values => {
          setIsLoading(LOADING);
          updateOrder(props.params.id, values)
            .then(res => {
              if (res.status === "success") {
                setOrderId(fp.get("data.id", res));
                setIsLoading(SUCCESS);
                if (!fp.get("data.without_pay", res)) {
                  setPrintReceipt(true);
                }
                setResMessage(
                  getLocalisationMessage(
                    "successfully_updated",
                    "Successfully Updated",
                  ),
                );
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_updated",
                    "Successfully Updated",
                  ),
                );
              } else {
                setIsLoading(ERROR);
                setResMessage(res.message);
                props.showErrorMessage(res.message);
              }
            })
            .catch(error => {
              setIsLoading(ERROR);
              setResMessage(error.reasonMessage);
              if (props.onSubmitFail) {
                props.onSubmitFail(error);
              } else {
                props.showErrorMessage(error);
              }
            });
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminPostItem);
