import sprintf from "sprintf";

import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const BAD_ADDRESS_LIST_URL = `${API_ROOT_URL}/admin/bad_addresses`;
const BAD_ADDRESS_ITEM_URL = `${API_ROOT_URL}/admin/order_location/%s/%s`;
const CONFIRM_ADDRESS_URL = `${
  API_ROOT_URL
}/admin/order_location/confirm_to_good_address/%s/%s`;

export const getBadAddressList = (filter: DataListFilter) =>
  api.getStream(BAD_ADDRESS_LIST_URL, { params: filter.getDefinedValues() });

export const getBadAddressItem = (locationId: Number, orderId: Number) =>
  api.getStream(sprintf(BAD_ADDRESS_ITEM_URL, locationId, orderId));

export const updateBadAddressItem = (
  locationId: Number,
  orderId: Number,
  values,
) =>
  api.put(sprintf(BAD_ADDRESS_ITEM_URL, locationId, orderId), { body: values });

export const confirmAsGoodAddress = (locationId: Number, orderId: Number) =>
  api.put(sprintf(CONFIRM_ADDRESS_URL, locationId, orderId));
