import React from "react";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

const enhancer = compose(
  useSheet({
    root: {
      width: "36px",
      height: "36px",
      position: "relative",
    },
    activeOrders: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      marginTop: "1px",
      zIndex: 3,
    },
    activeProgress: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
    },
    circle: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1,
    },
  }),
);

DriverAlertProgress.propTypes = {
  itemOpen: PropTypes.bool,
  activeOrders: PropTypes.number,
  totalOrders: PropTypes.number,
  classes: PropTypes.object,
  style: PropTypes.object,
  alertDetailed: PropTypes.instanceOf(Immutable.Map),
};

DriverAlertProgress.defaultProps = {
  activeOrders: 0,
  totalOrders: 0,
};

function DriverAlertProgress(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.activeProgress}
        variant="determinate"
        value={props.activeOrders || 1}
        max={props.totalOrders}
        size={36}
        thickness={3}
        color="secondary"
      />

      <CircularProgress
        className={classes.circle}
        variant="determinate"
        value={100}
        size={36}
        thickness={3}
      />

      <div className={classes.activeOrders}>{props.activeOrders || 0}</div>
    </div>
  );
}

export default enhancer(DriverAlertProgress);
