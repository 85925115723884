// eslint-disable-next-line import/extensions,import/no-internal-modules
import "gridstack/dist/gridstack.js";
// eslint-disable-next-line import/extensions,import/no-internal-modules
import "gridstack/dist/gridstack.jQueryUI.js";
// eslint-disable-next-line import/no-internal-modules
import "gridstack/dist/gridstack.css";
import React from "react";
import $ from "jquery";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-internal-modules
import differenceWith from "lodash/differenceWith";
import "./gridstack.css";
import { toArray } from "../../helpers/DataUtils";

export default class GridStack extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    children: PropTypes.array,
  };
  clear() {
    // eslint-disable-next-line react/no-string-refs
    $(this.refs.gridstack)
      .data("gridstack")
      .removeAll();
  }

  destroy() {
    // eslint-disable-next-line react/no-string-refs
    $(this.refs.gridstack)
      .data("gridstack")
      .destroy(false);
  }

  onChange(e, items) {
    if (this.props.onChange) {
      this.props.onChange(items);
    }
  }

  componentDidMount() {
    const options = {
      animate: true,
      width: 12,
      cellHeight: 36,
      verticalMargin: 0,
      resizable: {
        autoHide: true,
        handles: "se, s, sw",
      },
      draggable: {
        handle: ".grid-stack-item-content",
      },
      alwaysShowResizeHandle: true,
    };

    // eslint-disable-next-line react/no-string-refs
    const gridStack = $(this.refs.gridstack)
      .gridstack(options)
      .data("gridstack");
    // eslint-disable-next-line react/no-string-refs
    $(this.refs.gridstack).on("change", this.onChange.bind(this));

    // eslint-disable-next-line react/prop-types
    if (this.props.children) {
      // eslint-disable-next-line react/prop-types
      this.props.children.forEach(child => {
        gridStack.addWidget(
          `<div class="grid-stack-item"><div id="${child.props.id}" class="grid-stack-item-content"></div></div>`,
          child.props.x,
          child.props.y,
          child.props.width,
          child.props.height,
          child.props.autoPosition,
          child.props.minWidth,
          child.props.maxWidth || null,
          child.props.minHeight,
          child.props.maxHeight || null,
          child.props.id,
        );
        ReactDOM.render(child, document.getElementById(child.props.id));
      });
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/no-string-refs
    $(this.refs.gridstack).off("change", this.onChange.bind(this));
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/prop-types
    if (!this.props.children || this.props.children === 0) {
      this.clear();
      return;
    }
    // eslint-disable-next-line react/no-string-refs
    const gridstack = $(this.refs.gridstack).data("gridstack");

    const prevChild = (prevProps.children && toArray(prevProps.children)) || [];
    const currChild =
      (this.props.children && toArray(this.props.children)) || [];
    const toRemove = differenceWith(
      prevChild,
      currChild,
      (a, b) => a.props.id === b.props.id,
    );

    toRemove.forEach(child => {
      const el = document.getElementById(child.props.id);
      if (el !== null) {
        ReactDOM.unmountComponentAtNode(el);
        gridstack.removeWidget(el.parentElement);
      }
    });
    this.props.children.forEach(child => {
      const el = document.getElementById(child.props.id);
      if (el !== null) {
        ReactDOM.unmountComponentAtNode(el);
      } else {
        gridstack.addWidget(
          `<div class="grid-stack-item"><div id="${child.props.id}" class="grid-stack-item-content"></div></div>`,
          child.props.x,
          child.props.y,
          child.props.width,
          child.props.height,
          child.props.autoPosition,
          child.props.minWidth,
          child.props.maxWidth,
          child.props.minHeight,
          child.props.maxHeight,
          child.props.id,
        );
      }
      ReactDOM.render(child, document.getElementById(child.props.id));
    });
  }

  render() {
    // eslint-disable-next-line react/no-string-refs
    return <div ref="gridstack" className="grid-stack" />;
  }
}
