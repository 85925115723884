import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import TextField from "../deprecated/TextField";
import { isEqualData } from "../../helpers/DataUtils";
import { getAddressLocationByAddressV2 } from "../../api/shared/PublicOrderApi";

export const LocationTextFieldComponent = componentFromStream(propsStream => {
  const valueStream = propsStream
    .map(fp.get("input.value"))
    .distinctUntilChanged(isEqualData)
    .map(value => (fp.isObject(value) ? value : { address: value }));

  const sideEffectsStream = Observable.merge(
    Observable.combineLatest(
      propsStream.pluck("countryName").distinctUntilChanged(),
      valueStream,
      propsStream.pluck("cityName").distinctUntilChanged(),
      propsStream.pluck("regionName").distinctUntilChanged(),
      propsStream.pluck("countryCode").distinctUntilChanged(),
      (country, { address }, city, neighborhood, countryCode) => ({
        address,
        country,
        city,
        neighborhood,
        countryCode,
      }),
    )
      .filter(fp.flow(fp.get("country"), Boolean))
      .distinctUntilChanged(isEqualData)
      .debounceTime(1000)
      .switchMap(query =>
        Observable.defer(() => getAddressLocationByAddressV2(query))
          .catch(() => Observable.of(null))
          .map(
            fp.flow(result =>
              fp.isEmpty(result)
                ? null
                : {
                    lat: result.data.lat,
                    lng: result.data.lon,
                    country: (query && query.countryCode) || "",
                    address: (query && query.address) || "",
                  },
            ),
          ),
      )
      .filter(Boolean),
  )
    .startWith(null)
    .distinctUntilChanged();

  return propsStream
    .map(fp.omit(["countryName", "cityName", "regionName"]))
    .distinctUntilChanged(isEqualData)
    .combineLatest(
      valueStream,
      sideEffectsStream,
      (props, { address }, effect) => (
        <TextField
          {...props}
          value={address}
          onChange={v => props.input.onChange({ ...effect, address: v })}
        />
      ),
    );
});

LocationTextFieldComponent.propTypes = {
  countryName: PropTypes.string.isRequired,
  cityName: PropTypes.string,
  regionName: PropTypes.string,
  countryCode: PropTypes.string,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormLocationTextField.propTypes = {
  name: PropTypes.string.isRequired,
  regionName: PropTypes.string.isRequired,
  cityName: PropTypes.string,
  provinceOrState: PropTypes.string,
  countryCode: PropTypes.string,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  underlineShow: PropTypes.bool,
  required: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

export default function FormLocationTextField(props) {
  return <Field {...props} component={LocationTextFieldComponent} />;
}
