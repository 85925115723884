import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getValue } from "../../helpers/DataUtils";
import { RENDER } from "../orders-core/MUITable";
import _ from "lodash";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { getPDF } from "../../helpers/FormUtils";
import { IS_DOWNLOADING, IS_PRINTING } from "../orders-core/AdminPostForm";
import { GetApp, Print } from "@material-ui/icons";
import { getBatchItem } from "../../api/admin/AdminBatchHistoryUpdateApi";
import Text, { SUCCESS } from "../ui-core/Text";
import { MANIFEST_URL } from "../../api/admin/AdminBatchApi";
import { formatOrderStatusCodeForLocalisation2 } from "../../helpers/OrderHelper";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import ReceptacleMailItemsTable from "../orders-core/ReceptacleMailItemsTable";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
      },
    },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
);

BatchUpdateHistoryItemDialog.propTypes = {
  classes: PropTypes.object,

  id: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  open: PropTypes.bool,
};

function BatchUpdateHistoryItemDialog(props) {
  const { classes, getLocalisationMessage, id } = props;

  const [batch, setbatch] = useState({});
  const [barcodes, setBarcodes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getBatchItem(id)
        .then(r => {
          if (r && r.data) {
            setbatch(r.data);
            const successes = getValue(r, "data.success_orders", []).map(
              item => ({
                ...item,
                success: (
                  <Text type={SUCCESS}>
                    {getLocalisationMessage("success", "Success")}
                  </Text>
                ),
              }),
            );
            const fails = getValue(r, "data.failed_orders", []).map(item => ({
              ...item,
              success: (
                <Text type="danger">
                  {getLocalisationMessage("failed", "Failed")}
                </Text>
              ),
            }));
            setBarcodes([...fails, ...successes]);
            setLoading(false);
          }
        })
        .catch(() => setLoading(false));
    }
  }, [id]);

  const [stateManifest, setStateManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: MANIFEST_URL,
  });

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={props.open}
      modal={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      bodyClassName={classes.body}
      contentClassName={classes.contentStyle}
      className={classes.rootStyle}
    >
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <FlexBox align="center" direction="row">
          <FlexBox flex={true} gutter={8}>
            {getValue(batch, "id", "")}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <FlexBox style={{ gap: 20 }} direction="column">
          <FlexBox container={24} flex={true} direction="column">
            <FlexBox gutter={8} className={classes.wrapContent} align="center">
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("status", "Status")}
                  secondary={formatOrderStatusCodeForLocalisation2(
                    getValue(batch, "status", "N/A"),
                    getLocalisationMessage,
                  )}
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("update_date", "Update Date")}
                  secondary={formatDateTimeToUrl(
                    getValue(batch, "updated_date", "N/A"),
                  )}
                />
              </FlexBox>
              {false && (
                <FlexBox
                  style={{ gap: 13 }}
                  justify="flex-end"
                  flex={true}
                  align="center"
                >
                  <ButtonGroup
                    style={{ marginRight: "1rem" }}
                    variant={CONTAINED}
                    color={SECONDARY}
                  >
                    <CustomButton
                      onClick={() =>
                        getPDF(
                          stateManifest,
                          setStateManifest,
                          IS_PRINTING,
                          getValue(batch, "id", ""),
                          props.showErrorMessage,
                        )
                      }
                      endIcon={
                        stateManifest[IS_PRINTING] ? (
                          <CircularProgress size={20} color="white" />
                        ) : (
                          <Print />
                        )
                      }
                    >
                      {getLocalisationMessage("simple")}
                    </CustomButton>
                    <CustomButton
                      onClick={() =>
                        getPDF(
                          stateManifest,
                          setStateManifest,
                          IS_DOWNLOADING,
                          getValue(batch, "id", ""),
                          props.showErrorMessage,
                        )
                      }
                    >
                      {stateManifest[IS_DOWNLOADING] ? (
                        <CircularProgress size={20} />
                      ) : (
                        <GetApp />
                      )}
                    </CustomButton>
                  </ButtonGroup>
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            <Divider style={{ width: "100%", height: 1 }} />
          </FlexBox>

          <ReceptacleMailItemsTable
            list={barcodes}
            isLoading={loading}
            columns={[
              {
                type: RENDER,
                name: "barcode",
                render: row => _.get(row, `barcode`, ""),
                label: getLocalisationMessage("barcode"),
              },
              {
                type: RENDER,
                name: "status",
                label: getLocalisationMessage("status", "status"),
                render: row => _.get(row, `success`, "N/A"),
              },
              {
                type: RENDER,
                name: "reason",
                label: getLocalisationMessage("reason", "Reason"),
                render: row =>
                  _.get(row, `reason`) && (
                    <Text type="danger">{_.get(row, `reason`, "N/A")}</Text>
                  ),
              },
            ]}
          />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant={OUTLINED}
          color={SECONDARY}
          onClick={() => {
            props.onRequestClose();
            setbatch({});
            setBarcodes([]);
          }}
        >
          {getLocalisationMessage("close", "Close")}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchUpdateHistoryItemDialog);
