import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RadarDriverFilterTabs from "./RadarDriverFilterTabs";
import FlexBox from "../ui-core/FlexBox";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DRIVER_ORDER_RADAR_URL } from "../../constants/AdminPathConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const batchGetAlertListCount = filters =>
      Observable.from(
        filters
          .map(({ filter, getDriverList }, key) =>
            getDriverList(filter)
              .catch(error => Observable.of({ error }))
              .map(fp.get("payload.data.total"))
              .takeLast(1)
              .delay(1000)
              .map(value => ({ key, value })),
          )
          .toArray(),
      )
        .concatAll()
        .scan((acc, response) => acc.set(response.key, response.value), Map())
        .startWith(Map());

    return propsStream.combineLatest(props => ({
      ...props,
      batchGetAlertListCount,
    }));
  }),
);

RadarDriverTabs.propTypes = {
  onFilterClick: PropTypes.func,
  batchGetAlertListCount: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
  onListRequestRefresh: PropTypes.func,
  getDriverList: PropTypes.func,
};

function RadarDriverTabs(props) {
  return (
    <FlexBox flex={true}>
      <RadarDriverFilterTabs
        onFilterClick={props.onFilterClick}
        basePathname={DRIVER_ORDER_RADAR_URL}
        filter={props.filter}
        batchGetAlertListCount={props.batchGetAlertListCount}
        onListRequestRefresh={props.onListRequestRefresh}
        getDriverList={props.getDriverList}
      />
    </FlexBox>
  );
}

export default enhancer(RadarDriverTabs);
