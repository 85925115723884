import { OrderedSet } from "immutable";

export const CEO = "CEO";
export const GM = "GM";
export const OPS_MANAGER = "OPS_MANAGER";
export const OPS = "OPS";
export const ACCOUNT_MANAGER = "ACCOUNT_MANAGER";
export const CORP_DEFAULT = "CORP_DEFAULT";
export const FINANCE_MANAGER = "FINANCE_MANAGER";
export const ACCOUNTANT = "ACCOUNTANT";

export default OrderedSet.of(
  ACCOUNTANT,
  ACCOUNT_MANAGER,
  CEO,
  CORP_DEFAULT,
  FINANCE_MANAGER,
  GM,
  OPS,
  OPS_MANAGER,
);
