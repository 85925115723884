import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toNeighborhoodFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    city_id: types.ID,
    customerIds: queryTypes.IDSeq,
    excludeCustomerIds: queryTypes.IDSeq,
    supplierIds: queryTypes.IDSeq,
    from_date_time: types.string,
    to_date_time: types.string,
    courier_type: types.string,
    is_pick_up: types.boolean,
  }),
);
