import React from "react";
import { OrderedSet } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { isValidDate, validateString } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  DROP,
  PICKUP,
  DEFAULT_DROP,
  DEFAULT_PICKUP,
} from "../../constants/TimeSlotType";
import FormTextField from "../../../client/components/form/FormTextField";
import FormTimeField from "../../../client/components/form/FormTimeField";
import FormSelectField from "../../../client/components/form/FormSelectField";

const timeslotStatusOptions = OrderedSet.of(
  PICKUP,
  DROP,
  DEFAULT_PICKUP,
  DEFAULT_DROP,
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "TimeSlotForm",
    enableReinitialize: true,
    validate: ({ type, name, startTime, endTime }, props) => ({
      type:
        !type &&
        props.getLocalisationMessage(
          "please_select_type",
          "Please Select Type",
        ),
      name:
        validateString(name) &&
        props.getLocalisationMessage("enter_name", "Enter Name"),
      startTime: isValidDate(startTime)
        ? null
        : props.getLocalisationMessage(
            "select_start_time",
            "Select Start Time",
          ),
      endTime: isValidDate(endTime)
        ? null
        : props.getLocalisationMessage("select_end_time", "Select End Time"),
    }),
  }),
);

TimeSlotForm.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function TimeSlotForm(props) {
  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox gutter={8}>
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FormTextField
                name="sortOrder"
                fullWidth={false}
                label={props.getLocalisationMessage("sort_order", "Sort Order")}
              />
              <FormTextField
                name="name"
                fullWidth={true}
                label={`${props.getLocalisationMessage("name", "Name")} *`}
              />
              <FormTextField
                name="description"
                fullWidth={true}
                label={props.getLocalisationMessage(
                  "description",
                  "Description",
                )}
              />
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <FormTimeField
                name="startTime"
                fullWidth={true}
                label={`${props.getLocalisationMessage(
                  "start_time",
                  "Start Time",
                )} *`}
              />
              <FormTimeField
                name="endTime"
                fullWidth={true}
                label={`${props.getLocalisationMessage(
                  "end_time",
                  "End Time",
                )} *`}
              />

              <FormSelectField
                name="type"
                label={`${props.getLocalisationMessage("type", "Type")} *`}
                autoWidth={false}
                formatOption={x =>
                  props.getLocalisationMessage(x) || formatText(x)
                }
                options={timeslotStatusOptions}
              />
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>

      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {props.getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {props.getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </form>
  );
}

export default enhancer(TimeSlotForm);
