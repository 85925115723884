import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getDispatchOffices } from "../../api/admin/AdminIPSApi";

const baseFilter = new DataListFilter({
  size: 20,
  page: 0,
});

const AddressBookAutoCompleteComponent = componentFromStream(propsStream => {
  const optionsStream = propsStream
    .map(
      fp.flow(
        fp.over([fp.get("input.value.name"), fp.get("local")]),
        ([searchText, local]) =>
          baseFilter.setSearch(searchText).setValue("local", local),
      ),
    )
    .distinctUntilChanged(isEqualData)
    .switchMap(filter =>
      getDispatchOffices(filter).catch(() => Observable.of({})),
    )
    .map(fp.flow(fp.get("payload.data"), fp.toArray));

  return propsStream.combineLatest(optionsStream, (props, options) => (
    <AutoCompleteComponent
      {...props}
      optionStyle={{ maxHeight: "auto" }}
      filter={fp.stubTrue}
      options={options}
      parseInput={name => ({ name })}
      formatInput={fp.flow(fp.toPlainObject, fp.get("name"))}
      formatOption={option => option.name}
    />
  ));
});

FormIPSCountryAutoComplete.propTypes = {
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default function FormIPSCountryAutoComplete(props) {
  return <Field {...props} component={AddressBookAutoCompleteComponent} />;
}
