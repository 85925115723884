import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toBankReconcileSettingsFilter } from "../../helpers/BankReconcileFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminBankReconcileSettingsCreateDialogWrapper from "../../wrappers/admin/AdminBankReconcileSettingsCreateDialogWrapper";
import FlexBox from "../../components/ui-core/FlexBox";

const getValues = getFormValues("BankReconcileSettingsCreateForm");

const enhancer = compose(
  useSheet({
    container: { width: "820px" },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
  reduxForm({
    form: "BankReconcileSettingsCreateForm",
    enableReinitialize: true,
  }),
  connect(
    state => ({
      values: getValues(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(propsStream => {
      const filterStream = propsStream
        .map(fp.flow(fp.get("location.query"), toBankReconcileSettingsFilter))
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(filterStream, (props, filter) => ({
        ...props,
        filter,
      }));
    }),
  ),
);

AdminBankReconcileSettingsWizard.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminBankReconcileSettingsWizard(props) {
  const { classes } = props;

  return (
    <div>
      <FlexBox container={8} align="center" flex={true} direction="column">
        <FlexBox element={<Card />} gutter={8}>
          <FlexBox
            flex={true}
            element={<CardContent className={classes.container} />}
          >
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <AdminBankReconcileSettingsCreateDialogWrapper
                  onTabChange={tab =>
                    props.setLocationQuery(fp.set("view_tab", tab))
                  }
                  data={props.location.query.data}
                  tab={props.location.query.view_tab}
                  initialValues={props.initialValues}
                  location={props.location}
                  filter={props.filter}
                  onSubmitSuccess={() => {
                    props.showSuccessMessage(
                      props.getLocalisationMessage(
                        "settings_has_been_saved_successfully",
                        "Settings has been saved successfully",
                      ),
                    );
                  }}
                  onSubmitFail={error => {
                    props.showErrorMessage(error);
                  }}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </div>
  );
}

export default enhancer(AdminBankReconcileSettingsWizard);
