import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  withContext,
  withHandlers,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  List as Lists,
  CardContent,
  ListItem,
  ListSubheader,
  Button,
  CardActions,
  ListItemAvatar,
} from "@material-ui/core";
import { connect } from "react-redux";
import { PanTo, CustomControl } from "react-google-map-components";
import { withTheme } from "@material-ui/core/styles";
import FormTextField from "../form/FormTextField";
import FormNeighborhoodV2AutoComplete from "../form/FormNeighborhoodV2AutoComplete";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import UserAvatar from "../avatars/UserAvatar";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import MultiLineCell from "../data-list/MultiLineCell";
import FormMapPinDialog from "../deprecated/FormMapPinDialog";
import {
  getValue,
  isEqualData,
  mapResponseData,
} from "../../helpers/DataUtils";
import { getObjectId, createNotEmptyValidator } from "../../helpers/FormUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  validateString,
  isValidCoordinates,
} from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getLocationDetails } from "../../api/customer/CustomerApiV2";

import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import { PanTo as LeafletPanTo } from "react-leflet-map-components";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";

const valueSelector = formValueSelector("UpdateBadAddressForm");

const enhancer = compose(
  withTheme,
  useSheet({
    subheader: { paddingLeft: "0px", lineHeight: 1 },
    map: { height: "200px", width: "100%" },
    fullAddress: { lineHeight: 1, marginTop: "10px" },
    listStyle: { padding: "12px 16px 10px 56px" },
    multiLineItem1: { fontSize: "10px", maxWidth: "250px" },
    multiLineItem2: { fontSize: "14px" },
    multiLineItem2Root: { whiteSpace: "normal", maxWidth: "250px" },
  }),
  withState("state", "setState", {
    showMapDialog: false,
  }),
  withContext(
    {
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }
      const request = {};

      const neighborhoodId = getObjectId(values.neighborhood);

      request.neighborhood = neighborhoodId
        ? values.neighborhood
        : { name: values.neighborhood_txt };
      request.landmark = values.landmark;
      request.street = values.street;
      request.apartment = values.apartment;
      request.building = values.building;
      request.city = values.city;
      request.lat = values.location.lat;
      request.lon = values.location.lng;
      request.pickup = values.pickup;
      return props.onSubmit(request);
    },
  }),
  connect(state => ({
    marketplaceId: getMarketplaceId(state),
    location: valueSelector(state, "location"),
    badLocation: valueSelector(state, "bad_location"),
    badAddress: valueSelector(state, "bad_address"),
    neighborhood: valueSelector(state, "neighborhood"),
    mapProvider: getMapProvider(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "UpdateBadAddressForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      building: validateString(values.building)
        ? props.getLocalisationMessage("enter_building", "Enter Building")
        : null,
      apartment: validateString(values.apartment)
        ? props.getLocalisationMessage("enter_apartment", "Enter Apartment")
        : null,
    }),
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const locationDetailsStream = propsStream
          .distinctUntilKeyChanged("location")
          .map(props =>
            props.location && props.location.lat
              ? new DataListFilter({
                  lat: props.location.lat,
                  lon: props.location.lng,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getLocationDetails(filter).catch(error =>
                  Observable.of({ error }),
                )
              : Observable.of({}),
          )
          .startWith(Map())
          .map(mapResponseData)
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(locationDetailsStream, (props, locationDetails) => ({
            ...props,
            isLocationDetailsLoading: locationDetails.get("pending"),
            locationDetails: locationDetails.get("payload"),
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilKeyChanged("locationDetails")
            .filter(fp.get("locationDetails"))
            .do(props => {
              if (props.locationDetails.get("neighborhood")) {
                if (props.locationDetails.get("neighborhood")) {
                  props.change(
                    "neighborhood",
                    props.locationDetails.get("neighborhood").toJS(),
                  );
                } else
                  props.change(
                    "neighborhood_txt",
                    props.locationDetails.getIn(["neighborhood", "name"]) || "",
                  );
              } else if (_.isObject(props.locationDetails))
                props.change("neighborhood", null);
            }),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream.combineLatest(sideEffectsStream, fp.identity);
      },
    ),
  ),
);

UpdateBadAddressForm.propTypes = {
  state: PropTypes.object,
  classes: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  setState: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  location: PropTypes.object,
  badLocation: PropTypes.object,
  neighborhood: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onVerifyAddress: PropTypes.func,

  getCachedPostcode: PropTypes.func,
  getPostcodePredictions: PropTypes.func,

  isLocationDetailsLoading: PropTypes.bool,
  locationDetails: PropTypes.instanceOf(Map),
  details: PropTypes.instanceOf(Map),
  badAddress: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  mapProvider: PropTypes.string,
};

function UpdateBadAddressForm(props) {
  const {
    classes,
    state,
    locationDetails,
    details,
    getLocalisationMessage,
  } = props;

  const countryCode = details.getIn(["country", "description"], "AE");
  const countryId = details.getIn(["country", "id"], null);

  const isNewAutoComplete = getValue(props.neighborhood, "id");
  const dropOffCountryId = locationDetails
    ? locationDetails.getIn(["country", "id"], null)
    : countryId;

  const cityId = locationDetails
    ? locationDetails.getIn(["city", "id"], null)
    : details.getIn(["city_dto", "id"], null);

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FormMapPinDialog
        countryCode={countryCode}
        open={state.showMapDialog}
        initialValues={{ location: props.location }}
        onRequestClose={() => props.setState(fp.set("showMapDialog", false))}
        onSubmit={values => {
          props.setState(fp.set("showMapDialog", false));
          props.change("location", values.location);
        }}
      />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <FlexBox flex={true}>
              {isGoogleMapProvider ? (
                <GoogleMapWrapper className={props.classes.map} zoom={7}>
                  {isValidCoordinates(props.location) && (
                    <div>
                      <PanTo latLng={props.location} />
                      <BrandMarker position={props.location} />
                    </div>
                  )}

                  {isValidCoordinates(props.badLocation) && (
                    <div>
                      <PanTo latLng={props.badLocation} />
                      <BrandMarker
                        accent={false}
                        position={props.badLocation}
                      />
                    </div>
                  )}

                  <CustomControl position="TOP_RIGHT">
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          props.setState(fp.set("showMapDialog", true))
                        }
                      >
                        {getLocalisationMessage(
                          "select_address_from_map",
                          "Select Address From Map",
                        )}
                      </Button>
                    </CardActions>
                  </CustomControl>
                </GoogleMapWrapper>
              ) : (
                <LeafletMapWrapper className={props.classes.map} zoom={7}>
                  {isValidCoordinates(props.location) && (
                    <div>
                      <LeafletPanTo latLng={props.location} />
                      <LeafletBrandMarker position={props.location} />
                    </div>
                  )}

                  {isValidCoordinates(props.badLocation) && (
                    <div>
                      <LeafletPanTo latLng={props.badLocation} />
                      <LeafletBrandMarker
                        accent={false}
                        position={props.badLocation}
                      />
                    </div>
                  )}
                  {/** TODO map integration */}
                </LeafletMapWrapper>
              )}
            </FlexBox>
            <Card>
              <CardContent>
                <FlexBox>
                  <FlexBox container={16} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FlexBox
                        direction="column"
                        gutter={24}
                        style={{ marginBottom: "30px", marginTop: "0" }}
                      >
                        <ListSubheader className={classes.subheader}>
                          {getLocalisationMessage("sender", "Sender")}
                        </ListSubheader>
                        <Lists>
                          <ListItem
                            disabled={true}
                            className={classes.listStyle}
                          >
                            <ListItemAvatar>
                              <UserAvatar
                                style={{ left: 0 }}
                                name={details.getIn([
                                  "order",
                                  "customer",
                                  "name",
                                ])}
                              />
                            </ListItemAvatar>
                            <MultiLineCell
                              firstLine={
                                <div className={classes.multiLineItem1}>
                                  {getLocalisationMessage(
                                    "customer",
                                    "Customer",
                                  )}
                                </div>
                              }
                              secondLine={
                                <div className={classes.multiLineItem2}>
                                  {details.getIn(["order", "customer", "name"])}
                                </div>
                              }
                            />
                          </ListItem>
                          <ListItem
                            disabled={true}
                            className={classes.listStyle}
                          >
                            <ListItemAvatar>
                              <UserAvatar
                                style={{ left: 0 }}
                                name={details.getIn([
                                  "sender_info",
                                  "contact_name",
                                ])}
                              />
                            </ListItemAvatar>
                            <MultiLineCell
                              firstLine={
                                <div className={classes.multiLineItem1}>
                                  {getLocalisationMessage("sender", "Sender")}
                                </div>
                              }
                              secondLine={
                                <div
                                  className={classes.multiLineItem2}
                                >{`${details.getIn([
                                  "sender_info",
                                  "contact_name",
                                ])} / ${details.getIn([
                                  "sender_info",
                                  "phone",
                                ])}`}</div>
                              }
                            />
                          </ListItem>
                        </Lists>
                      </FlexBox>

                      <FlexBox direction="column" gutter={24}>
                        <ListSubheader className={classes.ListSubheader}>
                          {getLocalisationMessage(
                            "current_bad_address",
                            "Current Bad Address",
                          )}
                        </ListSubheader>

                        <Lists>
                          <ListItem
                            disabled={true}
                            className={classes.listStyle}
                          >
                            <ListItemAvatar>
                              <UserAvatar
                                style={{ left: 0 }}
                                name={details.get("contact_name")}
                              />
                            </ListItemAvatar>
                            <MultiLineCell
                              firstLine={
                                <div className={classes.multiLineItem1}>
                                  {getLocalisationMessage(
                                    "recipient",
                                    "Recipient",
                                  )}
                                </div>
                              }
                              secondLine={
                                <div className={classes.multiLineItem2}>
                                  {details.get("contact_name")}
                                </div>
                              }
                            />
                          </ListItem>
                          <ListItem disabled={true}>
                            <MultiLineCell
                              firstLine={
                                <div className={classes.multiLineItem1}>
                                  {getLocalisationMessage("address", "Address")}
                                </div>
                              }
                              secondLine={
                                <div className={classes.multiLineItem2}>
                                  {details.get("address")}
                                </div>
                              }
                              secondLineClass={classes.multiLineItem2Root}
                            />
                          </ListItem>
                        </Lists>
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox container={16} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <ListSubheader className={classes.ListSubheader} />

                      {isNewAutoComplete ? (
                        <FormNeighborhoodV2AutoComplete
                          includeUnknownNeighborhood={true}
                          fullWidth={true}
                          name="neighborhood"
                          disabled={false}
                          countryId={dropOffCountryId}
                          cityId={cityId}
                          hintText={getLocalisationMessage("region", "Region")}
                          label={getLocalisationMessage("region", "Region")}
                        />
                      ) : (
                        <FormTextField
                          name="neighborhood_txt"
                          fullWidth={true}
                          label={getLocalisationMessage("region", "Region")}
                          validate={createNotEmptyValidator(
                            getLocalisationMessage(
                              "enter_value",
                              "Enter Value",
                            ),
                          )}
                        />
                      )}

                      <FormTextField
                        name="street"
                        fullWidth={true}
                        label={getLocalisationMessage("street", "Street")}
                      />

                      <FormTextField
                        name="building"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "building",
                          "Building",
                        )} *`}
                      />

                      <FormTextField
                        name="apartment"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "suite_apartment",
                          "Suite/Apartment",
                        )} *`}
                      />

                      <FormTextField
                        name="landmark"
                        fullWidth={true}
                        label={getLocalisationMessage("landmark", "Landmark")}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <CardActions>
        <FlexBox flex={true} justify="flex-end">
          <Button onClick={props.onVerifyAddress}>
            {getLocalisationMessage("it_is_good_address", "It is good address")}
          </Button>

          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </CardActions>
    </form>
  );
}

export default enhancer(UpdateBadAddressForm);
