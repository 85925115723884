import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    content: { width: "600px" },
    toggle: { width: "200px", "& > div": { justifyContent: "center" } },
    td: {
      alignSelf: "center",
    },
  }),
  reduxForm({
    form: "MarketplaceOrderStatusSettingsDialogForm",
    enableReinitialize: true,
  }),
);

MarketplaceOrderStatusSettingsDialogForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function MarketplaceOrderStatusSettingsDialogForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onClose={props.handleSubmit}
    >
      <DialogTitle>
        {getLocalisationMessage("select_status", "Select Status")}
      </DialogTitle>
      <DialogContent className={props.classes.content}>
        <FlexBox align="center" flex={true}>
          <FormChipAutoComplete
            name="status"
            fullWidth={true}
            options={OrderStatusCodes}
            label={getLocalisationMessage("statuses", "Statuses")}
            formatOption={value =>
              formatOrderStatusCodeForLocalisation(
                value,
                getLocalisationMessage,
              )
            }
          />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(MarketplaceOrderStatusSettingsDialogForm);
