import React from "react";
import { updateQuery } from "../../shared/helpers/UrlUtils";
import { useNavigationHooks } from "../hooks/core-hooks/useNavigationHooks";
import fp from "lodash/fp";

export const usePermissionList = props => {
  const navigation = useNavigationHooks(props.router);

  const [deletingId, setDeletingId] = React.useState();

  React.useEffect(() => {
    const query = fp.get("location.query", props);
    props.fetchRoleList({
      ...query,
      status: query.status === "ALL" ? undefined : query.status,
    });
    const status = fp.path(["router", "location", "query", "status"], props);
    if (!status) {
      navigation.replaceLocationQuery(props.filter.getDefinedValues());
    }
  }, [window.location.search]);

  return {
    updateQuery,
    navigation,
    deletingId,
    setDeletingId,
  };
};
