import React from "react";
import PropTypes from "prop-types";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import AppTitle from "../../components/deprecated/AppTitle";
import TariffForm from "../../components/order-create-wizard-new/international-reports/TariffForm";
import { createInternationalInTariff } from "../../api/admin/AdminInternationalReportsApi";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { INTERNATIONAL_IN_TARIFF_URL } from "../../constants/AdminPathConstants";
import { Card, CardContent } from "@material-ui/core";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

function AdminInboundTariffCreateContainer(props) {
  return (
    <AdminAppLayout
      title={props.i18n.get("create_new_tariff", "Create new tariff")}
    >
      <FlexBox direction="column" style={{ padding: 16, gap: 16 }}>
        <AppTitle
          title={props.i18n.get("create_new_tariff", "Create new tariff")}
        />

        <Card>
          <CardContent>
            <TariffForm
              onSubmit={values =>
                createInternationalInTariff(toSnakeCase(values))
                  .then(res => {
                    if (res && res.status === "success") {
                      props.showSuccessMessage(
                        props.i18n.get("successfully_created"),
                      );
                      props.setLocation(INTERNATIONAL_IN_TARIFF_URL);
                    }
                  })
                  .catch(error => props.showErrorMessage(error))
              }
            />
          </CardContent>
        </Card>
      </FlexBox>
    </AdminAppLayout>
  );
}

AdminInboundTariffCreateContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

export default enhancer(AdminInboundTariffCreateContainer);
