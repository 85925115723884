import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "../ui-core/NavTabs";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
);

BadAddressStatusTabs.propTypes = {
  location: PropTypes.object,
  statusKey: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

BadAddressStatusTabs.defaultProps = {
  statusKey: "badAddress",
};

function BadAddressStatusTabs(props) {
  const currentTab = updateQuery(props.location, fp.unset("page"));

  return (
    <NavTabs
      width={300}
      value={currentTab}
      tabs={[
        {
          label: props.getLocalisationMessage("bad_addresses", "Bad Addresses"),
          value: updateQuery(currentTab, fp.set(props.statusKey, true)),
        },
        {
          label: props.getLocalisationMessage(
            "confirmed_addresses",
            "Confirmed Addresses",
          ),
          value: updateQuery(currentTab, fp.set(props.statusKey, false)),
        },
      ]}
    />
  );
}

export default enhancer(BadAddressStatusTabs);
