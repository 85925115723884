import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { mapSchema, queryTypes } from "../../shared/helpers/ObjectMapper";

export const toDriversDashboardFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    supplier_ids: queryTypes.IDSeq,
    from_date_time: queryTypes.dateTime,
    to_date_time: queryTypes.dateTime,
  }),
);
