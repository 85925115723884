import { fromJS } from "immutable";

import { MongoDBSDK } from "./MongoDBSDK";
import { defaultTo, isArray } from "lodash";
import { Subject, Observable } from "rxjs";
import * as localforage from "localforage";

const LOADING_STATE_INTERVAL = 10000;
let timer;

export const loadingSubject = new Subject();

const mapBinRules = (rules = []) =>
  fromJS(
    defaultTo(rules, []).reduce((acc, item) => {
      acc[item.code] = item;
      return acc;
    }, {}),
  );
// eslint-disable-next-line no-unused-vars
class OrderSortingCacheSqliteBased {
  constructor() {
    this.store = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: "uzpost",
      version: 1.0,
      storeName: "uzpost.db",
    });
  }

  async clear(): Promise<void> {
    const keys = await this.store.keys();

    // const keys = this.getLsKeys();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      // eslint-disable-next-line no-await-in-loop
      await this.store.removeItem(key);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getLsKeys(filter: string = ""): Promise<string[]> {
    const keys = await this.store.keys();
    return keys.filter((item) => item.startsWith(filter));
  }

  async batchUpdate(
    name: string,
    array: any[],
    key: string = "number",
  ): Promise<any[]> {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of array) {
      // eslint-disable-next-line no-await-in-loop
      await this.store.setItem(`${name}:${item[key]}`, JSON.stringify(item));
    }

    const result = [];

    const keys = await this.getLsKeys(name);
    // eslint-disable-next-line no-restricted-syntax
    for (const key1 of keys) {
      // eslint-disable-next-line no-await-in-loop
      let item = await this.store.getItem(key1);
      if (item) {
        item = JSON.parse(item);
      }
      result.push(item);
    }

    return result;
  }

  async removeByField(
    name: string,
    field: string,
    values: string,
  ): Promise<any> {
    // const cacheItems = await this.db.allDocs({ include_docs: true });
    // eslint-disable-next-line no-restricted-syntax
    for (const value of values) {
      // eslint-disable-next-line no-await-in-loop
      await this.store.removeItem(`${name}:${value}`);
    }
    const result = [];

    const keys = await this.getLsKeys(name);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      // eslint-disable-next-line no-await-in-loop
      const item = await this.store.getItem(key);

      if (item) {
        result.push(JSON.parse(item));
      }
    }

    return result;
  }

  async clearByName(name: string): Promise<any[]> {
    const keys = await this.getLsKeys(name);

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      // eslint-disable-next-line no-await-in-loop
      await this.store.removeItem(key);
    }
    return [];
  }

  async getState(name: string): Promise<any[]> {
    const result = [];

    const keys = await this.getLsKeys(name);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      // eslint-disable-next-line no-await-in-loop
      const item = await this.store.getItem(key);
      if (item) {
        result.push(JSON.parse(item));
      }
    }

    return result;
  }
}

// eslint-disable-next-line no-unused-vars
// class OrderSortingCache {
//   constructor() {
//     this.cache = new Map()
//       .set("sorting_orders", [])
//       .set("bin_rules", [])
//       .set("sorting_tasks", [])
//       .set("sorting_registries", [])
//       .set("registry_tasks", [])
//       .set("registry_bin_rules", []);
//   }
//
//   clear() {
//     this.cache = undefined;
//   }
//
//   batchUpdate(name: string, array: any[], key: string): any[] {
//     const cacheItems: any[] = this.cache.get(name);
//
//     // eslint-disable-next-line no-restricted-syntax
//     for (const item of array) {
//       const found = cacheItems.find((row) => row && row[key] === item[key]);
//       if (found) {
//         cacheItems[cacheItems.indexOf(found)] = item;
//       } else {
//         cacheItems.push(item);
//       }
//     }
//     return cacheItems;
//   }
//
//   removeByField(name: string, field: string, values: string): any {
//     const cacheItems = this.cache.get(name);
//     const result = [];
//
//     // eslint-disable-next-line no-restricted-syntax
//     for (const value of values) {
//       // eslint-disable-next-line no-restricted-syntax
//       const found = cacheItems.find((r) => r[field] === value);
//       if (found) {
//         cacheItems.splice(cacheItems.indexOf(found), 1);
//         this.cache = this.cache.set(cacheItems);
//         result.push(found);
//       }
//     }
//
//     return result;
//   }
//
//   clearByName(name: string): any[] {
//     this.cache = this.cache.set(name, []);
//     return [];
//   }
//
//   getState(name: string): any[] {
//     return this.cache.get(name);
//   }
// }

// export class OrderSortingDB {
//   constructor(socket = null) {
//     this.db = new MongoDBSDK(socket);
//     // this.cache = new OrderSortingCache();
//     this.cache = new OrderSortingCacheSqliteBased();
//     this.stateChangedStream = new Subject();
//   }
//
//   clearCache() {
//     this.cache.clear().then();
//   }
//
//   getBinRules() {
//     return this.db
//       .get({ name: "bin_rules" })
//       .map((data) => {
//         if (data.event === "BATCH_UPDATE" || data.event === "CLEARED") {
//           return data.item;
//         }
//         return data;
//       })
//       .filter(isArray)
//       .map(mapBinRules);
//   }
//
//   batchUpdateOrders(orders, forceUpdate = false) {
//     if (forceUpdate) return this.db.batchUpdate(orders);
//
//     return this.db.batchUpdate({
//       name: "sorting_orders",
//       key: "number",
//       // type: "hard",
//       items: isArray(orders) ? orders : orders.items,
//     });
//   }
//
//   setBinRules(rules) {
//     return this.db.batchUpdate({
//       name: "bin_rules",
//       key: "code",
//       type: "hard",
//       items: Object.keys(rules).map((key) => rules[key]),
//     });
//   }
//
//   setRegistryBinRules(rules) {
//     return this.db.batchUpdate({
//       name: "registry_bin_rules",
//       key: "code",
//       type: "hard",
//       items: Object.keys(rules).map((key) => rules[key]),
//     });
//   }
//
//   reloadOrder(orderNumber = null) {
//     const values = [orderNumber].map((item) => ({
//       hash: null,
//       hash_time: null,
//       failed: null,
//       number: item,
//       key: "number",
//     }));
//     return this.batchUpdateOrders(values);
//   }
//
//   batchReloadOrders(orderNumbers = []) {
//     const values = orderNumbers.map((item) => ({
//       hash_time: null,
//       hash: null,
//       // hash_time: null,
//       failed: null,
//       info: null,
//       number: item,
//       key: "number",
//     }));
//     return this.batchUpdateOrders(values);
//   }
//
//   getRegistryBinRules() {
//     return this.db
//       .get({ name: "registry_bin_rules" })
//       .map((data) => {
//         if (data.event === "BATCH_UPDATE" || data.event === "CLEARED") {
//           return data.item;
//         }
//         return data;
//       })
//       .filter(isArray)
//       .map(mapBinRules);
//   }
//
//   // eslint-disable-next-line class-methods-use-this
//   batchUpdateOrderBins(orderNumbers, value) {
//     const values = (orderNumbers || []).reduce((acc, item) => {
//       acc.push({
//         number: item,
//         bin: value,
//       });
//       return acc;
//     }, []);
//
//     return this.batchUpdateOrders(
//       {
//         name: "sorting_orders",
//         key: "number",
//         type: "deep",
//         items: values,
//       },
//       true,
//     );
//   }
//
//   // eslint-disable-next-line class-methods-use-this
//   batchMoveRegistriesFromOrdersToShipmentsPanel(orders) {
//     // return Observable.of({ orders });
//     return this.batchUpdateRegistries(
//       orders.reduce((acc, number) => {
//         acc.push({
//           number,
//         });
//
//         return acc;
//       }, []),
//     ).switchMapTo(this.batchRemoveOrders(orders));
//   }
//
//   addEventListener(tableName, eventName) {
//     return this.db.addEventListener(tableName, eventName);
//   }
//
//   onOrdersRemoveStream() {
//     const name = "sorting_orders";
//     const self = this;
//     return this.addEventListener(name, "BATCH_DELETED")
//       .mergeMap((numbers) =>
//         Observable.from(this.cache.removeByField(name, "number", numbers)),
//       )
//       .mergeMap((orders) => Observable.of(orders))
//       .map((item) => fromJS(item))
//       .do(() => {
//         self.stateChangedStream.next(true);
//       });
//   }
//
//   onStateChangedStream() {
//     const self = this;
//     return new Observable((emit) => {
//       self.stateChangedStream.asObservable().subscribe((data) => {
//         emit.next(data);
//       });
//       emit.next();
//     });
//   }
//
//   getOrders() {
//     const name = "sorting_orders";
//     return Observable.combineLatest(
//       this.db.get({ name }),
//       this.onStateChangedStream(),
//     )
//
//       .mergeMap((merged) => {
//         if (merged[1]) {
//           return new Observable((emit) => {
//             // const result = this.cache.getState(name);
//             const self = this;
//             setTimeout(() => {
//               self.stateChangedStream.next(undefined);
//             }, 200);
//             this.cache.getState(name).then((result) => emit.next(result));
//             // return result;
//           });
//         }
//         return new Observable((emit) => {
//           // eslint-disable-next-line consistent-return
//           const data = merged[0];
//           if (data.event === "CLEARED" || data.event === "BATCH_UPDATE") {
//             if (timer) clearInterval(timer);
//             loadingSubject.next(true);
//             timer = setInterval(() => {
//               loadingSubject.next(false);
//             }, LOADING_STATE_INTERVAL);
//             emit.next(data.item);
//           } else {
//             emit.next(data);
//           }
//         });
//       })
//
//       .filter(isArray)
//       .mergeMap((arr): any[] => {
//         if (arr.length) {
//           return Observable.from(this.cache.batchUpdate(name, arr, "number"));
//         }
//         return Observable.from(this.cache.clearByName(name));
//       })
//       .map((orders) => fromJS(orders || []));
//   }
//
//   clearOrders() {
//     return this.db.clear({ name: "sorting_orders" });
//   }
//
//   clearRegistries() {
//     return this.db.clear({ name: "sorting_registries" });
//   }
//
//   getOrder(orderNumber = null) {
//     return this.db
//       .getDataByKeyValue({
//         name: "sorting_orders",
//         key: "number",
//         value: orderNumber,
//       })
//       .map((data) => fromJS(defaultTo(data, [])));
//   }
//
//   batchRemoveOrders(orderNumbers) {
//     return this.db.batchDelete({
//       name: "sorting_orders",
//       key: "number",
//       value: orderNumbers,
//     });
//   }
//
//   removeOrder(orderNumber) {
//     return this.db.remove("sorting_orders", "number", orderNumber);
//   }
//
//   batchRemoveRegistries(orderNumbers) {
//     return this.db.batchDelete({
//       name: "sorting_registries",
//       key: "number",
//       value: orderNumbers,
//     });
//   }
//
//   getRegistry(orderNumber = null) {
//     const name = "sorting_registries";
//     return this.db
//       .getDataByKeyValue({
//         name,
//         key: "number",
//         value: orderNumber,
//       })
//       .map((data) => {
//         if (data.event === "BATCH_UPDATE" || data.event === "CLEARED") {
//           return data.item;
//         }
//         return data;
//       })
//       .filter(isArray)
//       .mergeMap((arr): any[] => {
//         if (arr.length) {
//           return Observable.from(this.cache.batchUpdate(name, arr, "number"));
//         }
//         return Observable.from(this.cache.clearByName(name));
//       })
//       .map((data) => fromJS(defaultTo(data, [])));
//   }
//
//   getOrderRemoveStream() {
//     return this.db
//       .get({ name: "sorting_orders" })
//       .filter((data) => data && data.event)
//       .filter((data) => data.event === "DELETED")
//       .map((data) => fromJS(data.item));
//   }
//
//   getTaskAddStream() {
//     return this.db
//       .get({ name: "sorting_tasks" })
//       .filter((data) => data && data.event)
//       .filter((data) => data.event === "ADDED")
//       .map((data) => fromJS(data.result));
//   }
//
//   getCorruptedOrderStream() {
//     return this.db
//       .get({ name: "sorting_orders" })
//       .filter((data) => data && data.event)
//       .filter((data) => data.event === "ADDED")
//       .filter((data) => !data.number);
//   }
//
//   getCorruptedRegistryStream() {
//     const name = "sorting_registries";
//     return this.db
//       .get({ name })
//       .filter((data) => data && data.event)
//       .filter((data) => data.event === "ADDED")
//       .filter((data) => !data.number);
//   }
//
//   getTasks() {
//     return this.db.get({ name: "sorting_tasks" });
//   }
//
//   addTask(orderNumber, task) {
//     return this.db.update({
//       name: "sorting_tasks",
//       key: "number",
//       item: { ...task, number: orderNumber },
//     });
//   }
//
//   updateTask(orderNumber = null, values = null) {
//     return this.db.update({
//       name: "sorting_tasks",
//       key: "number",
//       value: orderNumber,
//       item: values,
//     });
//   }
//
//   updateRegistryTask(orderNumber = null, values = null) {
//     return this.db.update({
//       name: "registry_tasks",
//       key: "number",
//       value: orderNumber,
//       item: values,
//     });
//   }
//
//   cancelRegistryTask(orderNumber) {
//     return this.db.update({
//       name: "registry_tasks",
//       key: "number",
//       value: orderNumber,
//       item: {
//         number: orderNumber,
//         cancel_registry: true,
//       },
//     });
//   }
//
//   batchUpdateRegistryBins(orderNumbers, value) {
//     return this.db.batchUpdate({
//       name: "registry_bin_rules",
//       key: "number",
//       type: "deep",
//       items: orderNumbers.reduce((acc, number) => {
//         acc.push({
//           number,
//           bin: value,
//         });
//         return acc;
//       }, []),
//     });
//   }
//
//   removeTask(key, value) {
//     return this.db.remove("tasks", key, value);
//   }
//
//   removeRegistryTask(key, value) {
//     return this.db.remove("registry_tasks", key, value);
//   }
//
//   getRegistryTaskAddStream() {
//     return this.addEventListener("registry_tasks", ["ADDED", "CHANGED"]).map(
//       (item) =>
//         isArray(item) ? fromJS(item.length ? item[0] : item) : fromJS(item),
//     );
//   }
//
//   getRegistries() {
//     const name = "sorting_registries";
//     return this.db
//       .get({ name: "sorting_registries" })
//       .mergeMap((data) => {
//         if (data.event === "BATCH_DELETED") {
//           return Observable.from(
//             this.cache.removeByField(name, "number", data.item),
//           ).mergeMap(() => Observable.from(this.cache.getState(name)));
//           // return this.cache.getState(name);
//           // console.log("state", this.cache.getState(name))
//           // return this.cache.getState(name);
//         }
//         if (data.event === "BATCH_UPDATE") {
//           return Observable.of(data.item);
//         }
//
//         return Observable.of(data);
//       })
//       .filter(isArray)
//       .mergeMap((arr): any[] => {
//         if (arr.length) {
//           return Observable.from(this.cache.batchUpdate(name, arr, "number"));
//         }
//         return Observable.from(this.cache.clearByName(name));
//       })
//       .map((orders) => fromJS(orders));
//   }
//
//   batchUpdateRegistries(orders) {
//     return this.db.batchUpdate({
//       name: "sorting_registries",
//       key: "number",
//       // type: "hard",
//       items: orders,
//     });
//   }
//
//   batchUpdateRegistriesAppend(orders) {
//     return this.db.batchUpdate({
//       name: "sorting_registries",
//       key: "number",
//       items: orders,
//     });
//   }
//
//   batchReloadRegistries(orderNumbers) {
//     const values = orderNumbers.reduce((acc, x) => {
//       acc.push({
//         number: x,
//         hash: null,
//         failed: null,
//       });
//       return acc;
//     }, []);
//
//     return this.db.batchUpdate({
//       name: "sorting_registries",
//       key: "number",
//       type: "deep",
//       items: values,
//     });
//   }
//
//   updateShipmentStatus(barcode, params) {
//     return this.db.update({
//       name: "registry_tasks",
//       key: "number",
//       value: barcode,
//       item: params,
//     });
//   }
// }

// eslint-disable-next-line no-unused-vars
class OrderSortingCache {
  constructor() {
    this.cache = new Map()
      .set("sorting_orders", [])
      .set("bin_rules", [])
      .set("sorting_tasks", [])
      .set("sorting_registries", [])
      .set("registry_tasks", [])
      .set("registry_bin_rules", []);
  }

  clear() {
    this.cache = undefined;
  }

  batchUpdate(name: string, array: any[], key: string): any[] {
    const cacheItems: any[] = this.cache.get(name);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of array) {
      const found = cacheItems.find((row) => row && row[key] === item[key]);
      if (found) {
        cacheItems[cacheItems.indexOf(found)] = item;
      } else {
        cacheItems.push(item);
      }
    }
    return cacheItems;
  }

  removeByField(name: string, field: string, values: string): any {
    const cacheItems = this.cache.get(name);
    const result = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const value of values) {
      // eslint-disable-next-line no-restricted-syntax
      const found = cacheItems.find((r) => r[field] === value);
      if (found) {
        cacheItems.splice(cacheItems.indexOf(found), 1);
        this.cache = this.cache.set(cacheItems);
        result.push(found);
      }
    }

    return result;
  }

  clearByName(name: string): any[] {
    this.cache = this.cache.set(name, []);
    return [];
  }

  getState(name: string): any[] {
    return this.cache.get(name);
  }
}

export class OrderSortingDB {
  constructor(socket = null) {
    this.db = new MongoDBSDK(socket);
    this.cache = new OrderSortingCache();
    this.stateChangedStream = new Subject();
  }

  clearCache() {
    this.cache.clear();
  }

  getBinRules() {
    return this.db
      .get({ name: "bin_rules" })
      .map((data) => {
        if (data.event === "ADDED" || data.event === "CHANGED") {
          return isArray(data.item) ? data.item : [data.item];
        }
        if (
          data.event === "GET_DATA" ||
          data.event === "BATCH_UPDATE" ||
          data.event === "CLEARED"
        ) {
          return data.item;
        }
        return data;
      })
      .filter(isArray)
      .map(mapBinRules);
  }

  batchUpdateOrders(orders, forceUpdate = false, type: string) {
    if (forceUpdate) return this.db.batchUpdate(orders);

    return this.db.batchUpdate({
      type,
      name: "sorting_orders",
      key: "number",
      items: isArray(orders) ? orders : orders.items,
    });
  }

  setBinRules(rules) {
    return this.db.batchUpdate({
      name: "bin_rules",
      key: "code",
      type: "hard",
      items: Object.keys(rules).map((key) => rules[key]),
    });
  }

  setRegistryBinRules(rules) {
    return this.db.batchUpdate({
      name: "registry_bin_rules",
      key: "code",
      type: "hard",
      items: Object.keys(rules).map((key) => rules[key]),
    });
  }

  reloadOrder(orderNumber = null) {
    const values = [orderNumber].map((item) => ({
      hash: null,
      hash_time: null,
      failed: null,
      number: item,
      key: "number",
    }));
    return this.batchUpdateOrders(values);
  }

  batchReloadOrders(orderNumbers = []) {
    const values = orderNumbers.map((item) => ({
      hash_time: null,
      hash: null,
      // hash_time: null,
      failed: null,
      info: null,
      number: item,
      key: "number",
    }));
    return this.batchUpdateOrders(values);
  }

  getRegistryBinRules() {
    return this.db
      .get({ name: "registry_bin_rules" })
      .map((data) => {
        if (data.event === "ADDED" || data.event === "CHANGED") {
          return isArray(data.item) ? data.item : [data.item];
        }
        if (
          data.event === "GET_DATA" ||
          data.event === "BATCH_UPDATE" ||
          data.event === "CLEARED"
        ) {
          return data.item;
        }
        return data;
      })
      .filter(isArray)
      .map(mapBinRules);
  }

  // eslint-disable-next-line class-methods-use-this
  batchUpdateOrderBins(orderNumbers, value) {
    const values = (orderNumbers || []).reduce((acc, item) => {
      acc.push({
        number: item,
        bin: value,
      });
      return acc;
    }, []);

    return this.batchUpdateOrders(
      {
        name: "sorting_orders",
        key: "number",
        type: "deep",
        items: values,
      },
      true,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  batchMoveRegistriesFromOrdersToShipmentsPanel(orders) {
    // return Observable.of({ orders });
    return this.batchUpdateRegistries(
      orders.reduce((acc, number) => {
        acc.push({
          number,
        });

        return acc;
      }, []),
    ).switchMapTo(this.batchRemoveOrders(orders));
  }

  addEventListener(tableName, eventName) {
    return this.db.addEventListener(tableName, eventName);
  }

  onOrdersRemoveStream() {
    const name = "sorting_orders";
    const self = this;
    return this.addEventListener(name, "BATCH_DELETED")
      .map((numbers) => this.cache.removeByField(name, "number", numbers))
      .mergeMap((orders) => Observable.of(orders))
      .map((item) => fromJS(item))
      .do(() => {
        self.stateChangedStream.next(true);
      });
  }

  onStateChangedStream() {
    const self = this;
    return new Observable((emit) => {
      self.stateChangedStream.asObservable().subscribe((data) => {
        emit.next(data);
      });
      emit.next();
    });
  }

  getOrdersWithPagination(page: number, size: number) {
    const name = "sorting_orders";
    return this.db.get({ name, page, size });
  }

  getOrders() {
    const name = "sorting_orders";
    return Observable.combineLatest(
      this.db.get({ name }),
      this.onStateChangedStream(),
    )

      .map((merged) => {
        if (merged[1]) {
          const result = this.cache.getState(name);
          const self = this;
          setTimeout(() => {
            self.stateChangedStream.next(undefined);
          }, 200);
          return result;
        }
        // eslint-disable-next-line consistent-return
        const data = merged[0];
        if (
          data.event === "GET_DATA" ||
          data.event === "CLEARED" ||
          data.event === "BATCH_UPDATE"
        ) {
          if (timer) clearInterval(timer);
          loadingSubject.next(true);
          timer = setInterval(() => {
            loadingSubject.next(false);
          }, LOADING_STATE_INTERVAL);
          return data.item;
        }
        if (data.event === "CHANGED" || data.event === "ADDED") {
          return [data.item];
        }

        return data;
      })

      .filter(isArray)
      .map((arr): any[] => {
        if (arr.length) {
          return this.cache.batchUpdate(name, arr, "number");
        }
        return this.cache.clearByName(name);
      })
      .map((orders) => fromJS(orders || []));
  }

  clearOrders() {
    return this.db.clear({ name: "sorting_orders" });
  }

  clearRegistries() {
    return this.db.clear({ name: "sorting_registries" });
  }

  getOrder(orderNumber = null) {
    return this.db
      .getDataByKeyValue({
        name: "sorting_orders",
        key: "number",
        value: orderNumber,
      })
      .map((data) => fromJS(defaultTo(data, [])));
  }

  batchRemoveOrders(orderNumbers) {
    return this.db.batchDelete({
      name: "sorting_orders",
      key: "number",
      value: orderNumbers,
    });
  }

  removeOrder(orderNumber) {
    return this.db.remove("sorting_orders", "number", orderNumber);
  }

  batchRemoveRegistries(orderNumbers) {
    return this.db.batchDelete({
      name: "sorting_registries",
      key: "number",
      value: orderNumbers,
    });
  }

  getRegistry(orderNumber = null) {
    const name = "sorting_registries";
    return this.db
      .getDataByKeyValue({
        name,
        key: "number",
        value: orderNumber,
      })
      .map((data) => {
        if (data.event === "ADDED" || data.event === "CHANGED") {
          return isArray(data.item) ? data.item : [data.item];
        }
        if (
          data.event === "GET_DATA" ||
          data.event === "BATCH_UPDATE" ||
          data.event === "CLEARED"
        ) {
          return data.item;
        }
        return data;
      })
      .filter(isArray)
      .map((arr): any[] => {
        if (arr.length) {
          return this.cache.batchUpdate(name, arr, "number");
        }
        return this.cache.clearByName(name);
      })
      .map((data) => fromJS(defaultTo(data, [])));
  }

  getOrderRemoveStream() {
    return this.db
      .get({ name: "sorting_orders" })
      .filter((data) => data && data.event)
      .filter((data) => data.event === "DELETED")
      .map((data) => fromJS(data.item));
  }

  getTaskAddStream() {
    return this.db
      .get({ name: "sorting_tasks" })
      .filter((data) => data && data.event)
      .filter((data) => data.event === "ADDED")
      .map((data) => fromJS(data.result));
  }

  getCorruptedOrderStream() {
    return this.db
      .get({ name: "sorting_orders" })
      .filter((data) => data && data.event)
      .filter((data) => data.event === "ADDED")
      .filter((data) => !data.number);
  }

  getCorruptedRegistryStream() {
    const name = "sorting_registries";
    return this.db
      .get({ name })
      .filter((data) => data && data.event)
      .filter((data) => data.event === "ADDED")
      .filter((data) => !data.number);
  }

  getTasks() {
    return this.db.get({ name: "sorting_tasks" });
  }

  addTask(orderNumber, task) {
    return this.db.update({
      name: "sorting_tasks",
      key: "number",
      item: { ...task, number: orderNumber },
    });
  }

  updateTask(orderNumber = null, values = null) {
    return this.db.update({
      name: "sorting_tasks",
      key: "number",
      value: orderNumber,
      item: values,
    });
  }

  updateRegistryTask(orderNumber = null, values = null) {
    return this.db.update({
      name: "registry_tasks",
      key: "number",
      value: orderNumber,
      item: values,
    });
  }

  cancelRegistryTask(orderNumber) {
    return this.db.update({
      name: "registry_tasks",
      key: "number",
      value: orderNumber,
      item: {
        number: orderNumber,
        cancel_registry: true,
      },
    });
  }

  batchUpdateRegistryBins(orderNumbers, value) {
    return this.db.batchUpdate({
      name: "registry_bin_rules",
      key: "number",
      type: "deep",
      items: orderNumbers.reduce((acc, number) => {
        acc.push({
          number,
          bin: value,
        });
        return acc;
      }, []),
    });
  }

  removeTask(key, value) {
    return this.db.remove("tasks", key, value);
  }

  removeRegistryTask(key, value) {
    return this.db.remove("registry_tasks", key, value);
  }

  getRegistryTaskAddStream() {
    return this.addEventListener("registry_tasks", ["ADDED", "CHANGED"]).map(
      (item) =>
        isArray(item) ? fromJS(item.length ? item[0] : item) : fromJS(item),
    );
  }

  getRegistries() {
    const name = "sorting_registries";
    return this.db
      .get({ name: "sorting_registries" })
      .map((data) => {
        if (data.event === "BATCH_DELETED") {
          this.cache.removeByField(name, "number", data.item);
          return this.cache.getState(name);
          // console.log("state", this.cache.getState(name))
          // return this.cache.getState(name);
        }
        if (data.event === "GET_DATA" || data.event === "BATCH_UPDATE") {
          return data.item;
        }

        if (data.event === "CHANGED" || data.event === "ADDED") {
          return [data.item];
        }

        return data;
      })
      .filter(isArray)
      .map((arr): any[] => {
        if (arr.length) {
          return this.cache.batchUpdate(name, arr, "number");
        }
        return this.cache.clearByName(name);
      })
      .map((orders) => fromJS(orders));
  }

  onOrdersChangeStream() {
    return this.db
      .addEventListener2("sorting_orders")
      .map((data) => {
        if (data.event === "GET_DATA" || data.event === "BATCH_UPDATE") {
          return data.item;
        }
        if (data.event === "CHANGED" || data.event === "ADDED") {
          return [data.item];
        }
        return this.cache.getState("sorting_orders");
      })
      .filter(isArray)
      .map((orders) => fromJS(orders));
  }

  batchUpdateRegistries(orders) {
    return this.db.batchUpdate({
      name: "sorting_registries",
      key: "number",
      // type: "hard",
      items: orders,
    });
  }

  batchUpdateRegistriesAppend(orders) {
    return this.db.batchUpdate({
      name: "sorting_registries",
      key: "number",
      items: orders,
    });
  }

  batchReloadRegistries(orderNumbers) {
    const values = orderNumbers.reduce((acc, x) => {
      acc.push({
        number: x,
        hash: null,
        failed: null,
      });
      return acc;
    }, []);

    return this.db.batchUpdate({
      name: "sorting_registries",
      key: "number",
      type: "deep",
      items: values,
    });
  }

  updateShipmentStatus(barcode, params) {
    return this.db.update({
      name: "registry_tasks",
      key: "number",
      value: barcode,
      item: params,
    });
  }
}
