import { Map, Set } from "immutable";
import fp from "lodash/fp";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const UPDATE_TASK = "ORDER_SORTING/UPDATE_TASK";

const ADD_ORDER_NUMBERS = "ORDER_SORTING/ADD_ORDER_NUMBERS";
const ADD_ORDER_BARCODES = "ORDER_SORTING/ADD_ORDER_BARCODES";
const UPDATE_VERIFIED_ORDERS = "ORDER_SORTING/UPDATE_VERIFIED_ORDERS";

const CACHE_ORDERS = "ORDER_SORTING/CACHE_ORDERS";
const CLEAR_ORDERS = "ORDER_SORTING/CLEAR_ORDERS";
const REMOVE_ORDERS = "ORDER_SORTING/REMOVE_ORDERS";

const ADD_BATCH_ROOT_ORDER = "ORDER_SORTING/ADD_BATCH_ROOT_ORDER";
const ADD_BATCH_ACTIVE_ORDER = "ORDER_SORTING/ADD_BATCH_ACTIVE_ORDER";
const ADD_BATCH_PARENT_ORDER = "ORDER_SORTING/ADD_BATCH_PARENT_ORDER";
const CLEAR_BATCH_ORDERS = "ORDER_SORTING/CLEAR_BATCH_ORDERS";

const CLEAR_REGISTRIES = "ORDER_SORTING/CLEAR_REGISTRIES";

const isOrderSortingAction = fp.startsWith("ORDER_SORTING");

const castToMap = (x) => (Map.isMap(x) ? x : Map());
const castToSet = (x) => (Set.isSet(x) ? x : Set());

const cleanupState = fp.flow(castToMap, (initialState) =>
  initialState.withMutations((state) => {
    state.set("omit", Set.of("orders", "registries"));
    state.update("orders", castToMap);
    state.update("registries", castToMap);
    state.update("verifiedOrders", castToMap);

    state.update(
      "task",
      fp.flow(castToMap, (x) =>
        x
          .update("filter", castToMap)
          .update("counter", castToSet)
          .update("boxCounter", castToSet)
          .update("autoAssign", Boolean)
          .update("cacheServer", Boolean)
          .update("activeOrder", fp.trim)
          .update("selectedOrders", castToSet)
          .update("selectedRegistries", castToSet)
          .update("warehouse", (w) =>
            Map.isMap(w) && fp.isFinite(w.get("id")) ? w : null,
          ),
      ),
    );
  }),
);

const selector = injectReducer(
  "order-sorting@3.0.0",
  (initialState = cleanupState(), action) => {
    const state = isOrderSortingAction(action.type)
      ? cleanupState(initialState)
      : initialState;

    switch (action.type) {
      case UPDATE_TASK:
        return state.withMutations((nextState) => {
          nextState.set("task", action.payload);

          nextState.update(cleanupState);
        });

      case UPDATE_VERIFIED_ORDERS:
        return state.withMutations((nextState) => {
          nextState.set("verifiedOrders", action.payload);

          nextState.update(cleanupState);
        });

      case ADD_ORDER_NUMBERS: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeOrder"], payload.last());

          payload.forEach((number) => {
            nextState.updateIn(["task", "counter"], (x) => x.add(number));
          });
        });
      }

      case ADD_ORDER_BARCODES: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          payload.forEach((number) => {
            nextState.updateIn(["task", "boxCounter"], (x) => x.add(number));
          });
        });
      }

      case CACHE_ORDERS: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          payload.forEach((order) => {
            nextState.setIn(["orders", order.get("order_number")], order);
          });
        });
      }

      case CLEAR_REGISTRIES: {
        return state.withMutations((nextState) => {
          nextState.update("registries", (x) => x.clear());
          nextState.updateIn(["task", "selectedRegistries"], (x) => x.clear());
        });
      }

      case REMOVE_ORDERS: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          if (payload.has(state.getIn(["task", "activeOrder"]))) {
            nextState.setIn(["task", "activeOrder"], null);
          }

          payload.forEach((number) => {
            nextState.updateIn(["task", "counter"], (x) => x.delete(number));
            nextState.updateIn(["task", "selectedOrders"], (x) =>
              x.delete(number),
            );
          });
        });
      }

      case CLEAR_ORDERS: {
        return state.withMutations((nextState) => {
          nextState.update("orders", (x) => x.clear());
          nextState.update("verifiedOrders", (x) => x.clear());
          nextState.setIn(["task", "activeOrder"], null);
          nextState.updateIn(["task", "selectedOrders"], (x) => x.clear());
        });
      }

      // BIN Validation Actions

      case ADD_BATCH_ROOT_ORDER: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeBatchRootOrder"], payload);
        });
      }

      case ADD_BATCH_PARENT_ORDER: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeBatchParentOrder"], payload);
        });
      }

      case ADD_BATCH_ACTIVE_ORDER: {
        const { payload } = action;

        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeBatchActiveOrderNumber"], payload);
        });
      }

      case CLEAR_BATCH_ORDERS: {
        return state.withMutations((nextState) => {
          nextState.setIn(["task", "activeBatchRootOrder"], null);
          nextState.setIn(["task", "activeBatchParentOrder"], null);
          nextState.setIn(["task", "activeBatchActiveOrderNumber"], null);
        });
      }

      default:
        return state;
    }
  },
);

export const getOrderSortingTask = (state) => selector(state).get("task");
export const getOrderSortingOrders = (state) =>
  selector(state).get("orders", Map());

export const getOrderSortingVerifiedOrders = (state) =>
  selector(state).get("verifiedOrders", Map());

export const updateSortingVerifiedOrders =
  (updater) => (dispatch, getState) => {
    dispatch({
      type: UPDATE_VERIFIED_ORDERS,
      payload: updater(getOrderSortingVerifiedOrders(getState())),
    });
  };

export const getOrderSortingTaskBatchRootOrder = (state) =>
  selector(state).getIn(["task", "activeBatchRootOrder"], null);

export const updateSortingTask = (updater) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_TASK,
    payload: updater(getOrderSortingTask(getState())),
  });
};

export const cacheOrderSortingOrders = (orders) => ({
  payload: orders,
  type: CACHE_ORDERS,
});

export const removeOrderSortingOrders = (orders) => ({
  payload: orders,
  type: REMOVE_ORDERS,
});

export const clearOrderSortingOrders = () => ({
  type: CLEAR_ORDERS,
});

export const addSortingTaskOrderNumbers = (numbers) => ({
  payload: numbers,
  type: ADD_ORDER_NUMBERS,
});

export const addSortingTaskOrderBarcodes = (numbers) => ({
  payload: numbers,
  type: ADD_ORDER_BARCODES,
});

// BIN Validation functions
export const addBatchSortingTaskOrderNumber =
  (numbers) => (dispatch, getState) => {
    const activeRootOrder = getOrderSortingTaskBatchRootOrder(getState());

    dispatch({
      payload: numbers.first(),
      type: ADD_BATCH_ACTIVE_ORDER,
    });

    if (!activeRootOrder) {
      dispatch({
        payload: numbers.first(),
        type: ADD_BATCH_ROOT_ORDER,
      });
    }
  };

export const addBatchSortingTaskParentOrderNumber = (numbers) => ({
  payload: numbers.first(),
  type: ADD_BATCH_PARENT_ORDER,
});

export const clearOrderSortingBatchOrders = () => ({
  type: CLEAR_BATCH_ORDERS,
});

export const clearOrderSortingRegistries = () => ({
  type: CLEAR_REGISTRIES,
});
