import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fromJS, List, Set } from "immutable";
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Paper,
} from "@material-ui/core";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../../../components/data-list/DataList";
import fp from "lodash/fp";
import DataListFilter from "../../../helpers/DataListFilter";
import { connect } from "react-redux";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import PageLoading from "../../../components/ui-core/PageLoading";
import FlexBox, { JUSTIFY_CENTER } from "../../../components/ui-core/FlexBox";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import { getValue, isEqualData, toJS } from "../../../helpers/DataUtils";
import {
  completeRoute,
  getBatchOrderUpdateList,
} from "../../../api/admin/AdminBatchApi";
import { getMessage } from "../../../reducers/LocalizationReducer";
import LinkButton from "../../../components/ui-core/LinkButton";
import DateTimeCell from "../../../components/data-list/DateTimeCell";
import AdminBatchUpdateOrderDialogWrapper from "../../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import { hasUserPermission } from "../../../reducers/ProfileReducer";
import { PUBLIC } from "../../../constants/NotePrivacyTypes";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import AdminBatchUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { IN_TRANSIT } from "../../../constants/OrderStatusCodes";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import { AssignmentTurnedIn, KeyboardArrowLeft } from "@material-ui/icons";
import { toUserFilterMapper } from "../../../helpers/UserFilterMapper";
import { ROUTE_ITEM_URL } from "../../../constants/AdminPathConstants";
import RouteDialogFormWrapper from "../../../wrappers/admin/RouteDialogFormWrapper";
import {
  CONSOLIDATED,
  CONTAINER,
  ROUTE,
  SIMPLE,
} from "../../../constants/OrderType";
import { green } from "@material-ui/core/colors";
import { TransitionUp } from "../../../components/dialog/TransitionUp";
import OfflineOrderSortingTabs from "../../../components/order-outbound-sorting/OfflineOrderSortingTabs";

const useStyles = makeStyles({
  breadCrumbTitle: {
    color: "#777",
    textTransform: "uppercase",
    fontSize: 11,
  },
  title: {
    color: "#555",
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 4,
  },
  container: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  table: {
    height: "100%",
  },
  longString: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  containerRow: {
    extend: "row",
    background: "#aceb84",
    "&:hover": {
      background: "#aceb84 !important",
    },
  },
  searchField: {
    flex: "1 1 auto",
  },
  chip: {
    cursor: "pointer",
    marginRight: 5,
    marginBottom: 5,
  },
  chipSuccess: {
    cursor: "pointer",
    marginRight: 5,
    marginBottom: 5,
    backgroundColor: green[300],
    "&:hover": {
      backgroundColor: green[500],
    },
  },
});
const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isRouteCreator: hasUserPermission(state, "ADMIN_ROUTE_CREATE"),
      isRouteAssign: hasUserPermission(state, "ADMIN_ROUTE_ASSIGN"),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toUserFilterMapper))
      .distinctUntilChanged(isEqualData);

    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const listResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        getBatchOrderUpdateList(
          filter.setValueMap({
            status: IN_TRANSIT,
            type: props.tab,
            size: props.tab === CONSOLIDATED ? 200 : 50,
          }),
        ).repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("progress", fp.toFinite),
          fp.update("payload", fp.get("data")),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(List());

    return propsStream.combineLatest(
      filterStream,
      listResponseStream,
      (props, filter, response) => ({
        ...props,
        filter,
        onRequestRefresh,
        isLoading: response.get("pending"),
        list: response.getIn(["payload", "list"]),
        total: response.getIn(["payload", "total"]),
      }),
    );
  }),
);

AdminOfflineBatchUpdateContainer.propTypes = {
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  location: PropTypes.object,
  withFooter: PropTypes.bool,
  filter: PropTypes.objectOf(DataListFilter),
  list: PropTypes.objectOf(List),
  total: PropTypes.number,
  getLocalisationMessage: PropTypes.func,
  setLocationQueryFilter: PropTypes.func.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  outBoundSorting: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isRouteCreator: PropTypes.bool,
  isRouteAssign: PropTypes.bool,
  setLocation: PropTypes.func,
  tab: PropTypes.string,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
  setRefreshTab: PropTypes.func,
  tabCount: PropTypes.string,
};

AdminOfflineBatchUpdateContainer.defaultProps = {
  withFooter: true,
  filter: new DataListFilter({
    page: 0,
    size: 200,
    type: SIMPLE,
  }),
  list: List(),
};

function AdminOfflineBatchUpdateContainer({
  filter,
  getLocalisationMessage,
  isLoading,
  list,
  onRequestRefresh,
  setLocationQuery,
  setLocationQueryFilter,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  total,
  withFooter,
  outBoundSorting,
  setLocation,
  isRouteCreator,
  isRouteAssign,
  tab,
  onTabChange,
  tabCount,
  setRefreshTab,
}) {
  const classes = useStyles();
  const [isLoadingCompleteRoute, setIsLoadingCompleteRoute] = useState(false);
  const [completeId, setCompleteId] = useState(0);
  const [batchId, setBatchId] = useState(false);
  // const [isDifferentTypes, setIsDifferentTypes] = useState(true);
  const [isOpenCreateRouteDialog, setIsOpenCreateRouteDialog] = useState(false);
  const [isOpenEditRouteDialog, setIsOpenEditRouteDialog] = useState(false);
  const [warehouseBins, setWarehouseBins] = useState([]);
  const [sortedList, setSortedList] = useState(list);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(false);
  const [state, setState] = useState({
    createDialog: false,
    selectedRows: Set(),
    batch_item_id: null,
    orderStatus: null,
  });
  const tempList = toJS(state.selectedRows);
  const firstItem = tempList[0];
  const filteredByWarehouse = sortedList.filter(
    v => v.getIn(["to_warehouse", "id"]) === selectedWarehouseId,
  );
  useEffect(() => setSortedList(list), [list]);
  useEffect(
    () =>
      selectedWarehouseId &&
      setState({
        ...state,
        selectedRows: filteredByWarehouse.toSet(),
      }),
    [selectedWarehouseId],
  );

  useEffect(() => {
    if (selectedWarehouseId) {
      const idsArray = toJS(filteredByWarehouse).map(v => v.id);
      setSortedList(
        fromJS([
          ...toJS(sortedList).filter(v => idsArray.find(x => x === v.id)),
          ...toJS(sortedList).filter(v => !idsArray.find(x => x === v.id)),
        ]),
      );
    }
  }, [selectedWarehouseId]);

  useEffect(() => {
    const temp = [];
    toJS(list).forEach(v => {
      const foundIndex = temp.findIndex(
        x => x.id === getValue(v, "to_warehouse.id"),
      );
      if (foundIndex > -1) temp[foundIndex].count += 1;
      else temp.push({ ...v.to_warehouse, count: 1 });
    });
    setWarehouseBins(temp);
  }, [list]);

  const selectedType = firstItem && firstItem.type;
  const ids =
    state.selectedRows.size > 0
      ? state.selectedRows.toList().map(r => r.get("id"))
      : List();
  const barcodes =
    state.selectedRows.size > 0
      ? state.selectedRows.toList().map(r => r.get("barcode"))
      : List();
  const buttons = ids.size > 0 && (
    <FlexBox style={{ marginRight: "2rem" }}>
      {(outBoundSorting && selectedType === SIMPLE) ||
      selectedType === CONSOLIDATED ? (
        <FlexBox>
          <CustomButton
            fullWidth={true}
            color={SECONDARY}
            style={{ maxWidth: "200px" }}
            variant={CONTAINED}
            onClick={() => {
              setState({
                ...state,
                orderStatus: IN_TRANSIT,
                createDialog: true,
                parentWarehouse: fp.get("next_warehouse", firstItem),
                to_warehouse: fp.get("next_warehouse", firstItem),
              });
            }}
          >
            {getLocalisationMessage("create_container")}
          </CustomButton>
        </FlexBox>
      ) : (
        selectedType === CONTAINER && (
          <FlexBox>
            {isRouteCreator && (
              <CustomButton
                fullWidth={true}
                color={SECONDARY}
                style={{ minWidth: "200px", marginRight: "1rem" }}
                variant={CONTAINED}
                onClick={() => setIsOpenCreateRouteDialog(true)}
              >
                {getLocalisationMessage("create_route")}
              </CustomButton>
            )}
            {isRouteAssign && (
              <CustomButton
                fullWidth={true}
                color={SECONDARY}
                style={{ minWidth: "230px", marginRight: "1rem" }}
                variant={CONTAINED}
                onClick={() => setIsOpenEditRouteDialog(true)}
              >
                {getLocalisationMessage("add_to_route")}
              </CustomButton>
            )}
          </FlexBox>
        )
      )}
    </FlexBox>
  );
  return (
    <AdminAppLayout
      title={getLocalisationMessage("order_batch_update", "order batch update")}
    >
      <PageLoading isLoading={isLoading} />
      <Dialog
        TransitionComponent={TransitionUp}
        open={Boolean(completeId)}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setCompleteId(0)}
      >
        <DialogTitle style={{ borderBottom: "1px solid #eeeeee" }}>
          <h5 style={{ textAlign: "center" }}>
            {getLocalisationMessage("are_you_sure")}
          </h5>
        </DialogTitle>
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox>
              <CustomButton
                onClick={() => setCompleteId(0)}
                startIcon={<KeyboardArrowLeft />}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("close_window", "Close window")}
              </CustomButton>
              <CustomButton
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  setIsLoadingCompleteRoute(true);
                  completeRoute(completeId)
                    .then(() => {
                      showSuccessMessage1("successfully");
                      onRequestRefresh();
                      setRefreshTab(prev => !prev);
                    })
                    .catch(error => {
                      showErrorMessage1(error);
                    })
                    .finally(() => {
                      setCompleteId(0);
                      setIsLoadingCompleteRoute(false);
                    });
                }}
                endIcon={
                  isLoadingCompleteRoute ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <AssignmentTurnedIn />
                  )
                }
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("complete_route")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      </Dialog>
      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        refreshBatchList={onRequestRefresh}
        onRequestClose={() => {
          setIsOpenEditRouteDialog(false);
          setIsOpenCreateRouteDialog(false);
          setBatchId(false);
        }}
        onShowOrdersClick={numbers =>
          setLocationQuery({ search: numbers.join(",") })
        }
      />
      <RouteDialogFormWrapper
        warehouseIds={toJS(state.selectedRows).map(v => v.warehouse.id)}
        open={isOpenCreateRouteDialog || isOpenEditRouteDialog}
        isEdit={isOpenEditRouteDialog}
        initialValues={{
          batchType: "active",
          orderBarcodes: state.selectedRows.size > 0 ? barcodes : List(),
          orderStatus: state.orderStatus,
          privacy: PUBLIC,
          weight:
            Math.round(
              state.selectedRows.reduce(
                (a, b) => a + parseFloat(b.get("weight") || 0),
                0,
              ) * 100,
            ) / 100,
        }}
        onRequestClose={() => {
          setIsOpenCreateRouteDialog(false);
          setIsOpenEditRouteDialog(false);
        }}
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={res => {
          setIsOpenCreateRouteDialog(false);
          setIsOpenEditRouteDialog(false);
          setBatchId(res.data.id);
          setRefreshTab(prev => !prev);
          setState({ createDialog: false, selectedRows: Set() });
          onRequestRefresh();
        }}
      />

      <AdminBatchUpdateOrderDialogWrapper
        title="create_container"
        isContainer={tab === CONSOLIDATED}
        isSameWarehouses={tempList.every(
          i => fp.get("warehouse.id", i) === fp.get("warehouse.id", firstItem),
        )}
        // isDifferentTypes={isDifferentTypes}
        open={state.createDialog}
        initialValues={{
          batchType: "active",
          orderBarcodes: toJS(barcodes),
          orderStatus: state.orderStatus,
          warehouse: state.parentWarehouse,
          to_warehouse: state.to_warehouse,
          privacy: PUBLIC,
          weight:
            Math.round(
              state.selectedRows.reduce(
                (a, b) => a + parseFloat(b.get("weight") || 0).toFixed(3),
                0,
              ) * 100,
            ) / 100,
        }}
        onRequestClose={() =>
          setState({
            selectedRows: Set(),
            createDialog: false,
            orderStatus: null,
          })
        }
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={res => {
          setBatchId(res.data.id);
          setState({ createDialog: false, selectedRows: Set() });
          onRequestRefresh();
          setRefreshTab(prev => !prev);
        }}
      />

      <div className={classes.container}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <OfflineOrderSortingTabs
              tabValue={tab}
              onTabChange={onTabChange}
              tabCount={tabCount}
            />
            {tab === CONSOLIDATED && (
              <div
                style={{
                  margin: "1rem",
                  maxHeight: "20vh",
                  overflow: "auto",
                }}
              >
                {warehouseBins.map((v, i) => (
                  <Chip
                    key={i}
                    className={`${classes.chip} ${v.id ===
                      selectedWarehouseId && classes.chipSuccess}`}
                    style={{
                      backgroundColor:
                        v.id === selectedWarehouseId && green[300],
                    }}
                    onClick={() => {
                      if (v.id === selectedWarehouseId) {
                        setState({ ...state, selectedRows: Set() });
                        setSelectedWarehouseId(false);
                      } else {
                        setSelectedWarehouseId(v.id);
                      }
                    }}
                    label={`${v.name} (${v.count})`}
                  />
                ))}
              </div>
            )}
          </React.Fragment>

          <DataList
            overscanRowCount={10}
            totalCount={total}
            className={classes.table}
            rowCount={sortedList.size}
            list={sortedList}
            rowGetter={options => sortedList.get(options.index)}
            withFooter={withFooter}
            onFilterChange={f => setLocationQueryFilter(f)}
            filter={filter}
            cardActionIcons={<FlexBox>{buttons}</FlexBox>}
          >
            {tab !== ROUTE && (
              <DataListCheckbox
                rowSelected={row => state.selectedRows.has(row.cellData)}
                allRowsSelected={() =>
                  state.selectedRows.size > 0 &&
                  state.selectedRows.size === sortedList.size
                }
                onRowSelect={row => {
                  setSelectedWarehouseId(false);
                  setState({
                    ...state,
                    selectedRows: row.selected
                      ? state.selectedRows.add(row.cellData)
                      : state.selectedRows.delete(row.cellData),
                  });
                }}
                onAllRowsSelect={value => {
                  setSelectedWarehouseId(false);
                  setState({
                    selectedRows: value ? sortedList.toSet() : Set(),
                  });
                }}
              />
            )}
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("batch_number", "Batch Number")}
              dataKey="barcode"
              justifyContent="center"
              cellRenderer={row => (
                <LinkButton
                  onClick={() =>
                    tab === ROUTE
                      ? setLocation(ROUTE_ITEM_URL + row.cellData.get("id"))
                      : setBatchId(row.cellData.get("id"))
                  }
                >
                  {row.cellData.get("barcode")}
                </LinkButton>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("created_date", "Created Date")}
              dataKey="created_date"
              justifyContent="center"
              cellRenderer={row => (
                <DateTimeCell date={row.cellData.get("created_date")} />
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("category", "Category")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>
                  {row.cellData.get("category")
                    ? getLocalisationMessage(
                        row.cellData.get("category").toLowerCase(),
                      )
                    : "-"}
                </div>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("type", "Type")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>
                  {getLocalisationMessage(
                    tab === CONSOLIDATED &&
                      row.cellData.get("type") !== CONSOLIDATED
                      ? row.cellData.get("inner_shipment_type")
                      : row.cellData.get("type"),
                  )}
                </div>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("from_warehouse", "From Warehouse")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>{row.cellData.getIn(["from_warehouse", "name"])}</div>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("next_warehouse")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>{row.cellData.getIn(["next_warehouse", "name"])}</div>
              )}
            />
            <DataListColumn
              width={120}
              disableSort={true}
              label={getLocalisationMessage("to_warehouse", "Warehouse")}
              dataKey="type"
              justifyContent="center"
              cellRenderer={row => (
                <div>{row.cellData.getIn(["to_warehouse", "name"])}</div>
              )}
            />
            {tab === ROUTE && (
              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("action")}
                dataKey="type"
                justifyContent="center"
                cellRenderer={row =>
                  !row.cellData.get("completed") ? (
                    <CustomButton
                      color={SECONDARY}
                      variant={CONTAINED}
                      endIcon={<AssignmentTurnedIn />}
                      onClick={() => {
                        setCompleteId(row.cellData.get("id"));
                      }}
                    >
                      {getLocalisationMessage("complete_route")}
                    </CustomButton>
                  ) : (
                    <CustomButton disabled={true}>
                      {getLocalisationMessage("completed")}
                    </CustomButton>
                  )
                }
              />
            )}
          </DataList>
        </Paper>
      </div>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOfflineBatchUpdateContainer);
