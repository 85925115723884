import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import {
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  withTheme,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Map } from "immutable";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import cx from "classnames";
import { getMessages } from "../../reducers/LocalizationReducer";
import { parseMultipolygonLeaflet } from "../../helpers/MapPolygonUtils";
import PageLoading from "../../components/ui-core/PageLoading";
import LeafletMapPostcodesArea from "../../components/map-areas/LeafletMapPostcodesArea";
import FlexBox from "../../components/ui-core/FlexBox";
import { getPostcodeIndexMapView } from "../../api/admin/AdminPostcodesApi";
import FormJurisdictionSelectField from "../../components/form/FormJurisdictionSelectField";
import { AccountTree } from "@material-ui/icons";
import { formValues, reduxForm } from "redux-form";
import FormJMAutoComplete from "../../components/form/FormJMAutoComplete";
import { getJurisdictionItemV2 } from "../../api/admin/AdminJurisdictionsApi";
import fp from "lodash/fp";
import { getUserJurisdiction } from "../../reducers/ProfileReducer";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    gap: 10,
    overflow: "hidden",
  },
  leftSide: {
    maxWidth: "430px",
  },
  listCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  nested: {
    paddingLeft: "16px",
  },
  accordionRoot: {
    paddingLeft: "20px",
  },
  active: {
    background: `${theme.palette.primary1Color}21`,
  },
  listRoot: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: 20,
  },
  jmIcon: {
    border: "1px solid #bdbdbd",
    borderLeft: "none",
    borderRadius: 0,
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  jmWrapper: {
    paddingRight: "0 !important",
    overflow: "hidden",
  },
  map: {
    "& .leaflet-container": {
      zIndex: 0,
    },
  },
}));

const enhancer = compose(
  withTheme,
  connect(
    state => ({
      i18n: getMessages(state),
      userJurisdiction: getUserJurisdiction(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  reduxForm({
    enableReinitialize: true,
    form: "AdminPostcodesMapView",
  }),
  formValues({
    jurisdiction: "jurisdiction",
  }),
);

AdminPostcodesMapView.propTypes = {
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  setLocationQuery: PropTypes.func,
  change: PropTypes.func,
  jurisdiction: PropTypes.object,
  location: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
  userJurisdiction: PropTypes.instanceOf(Map),
};

function AdminPostcodesMapView(props) {
  const classes = useStyles();
  const [postcodes, setPostcodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [editJurisdiction, setEditJurisdiction] = useState(false);
  const [currentParentPolygon, setCurrentParentPolygon] = useState({});
  const [searchResults, setSearchResults] = useState([]);

  const jurisObj = props.userJurisdiction ? props.userJurisdiction.toJS() : {};

  useEffect(() => {
    if (props.jurisdiction && props.jurisdiction.id) {
      props.setLocationQuery(fp.set("jurisdiction", props.jurisdiction.id));
    } else if (jurisObj && jurisObj.level_id === 3) {
      props.setLocationQuery(fp.set("jurisdiction", jurisObj.parent.id));
    } else if (jurisObj && jurisObj.level_id === 2) {
      props.setLocationQuery(fp.set("jurisdiction", jurisObj.id));
    } else {
      props.setLocationQuery(fp.unset("jurisdiction"));
    }
  }, [props.jurisdiction]);

  useEffect(() => {
    if (
      !props.jurisdiction &&
      props.location &&
      props.location.query.jurisdiction
    ) {
      props.change("jurisdiction", {
        id: Number(props.location.query.jurisdiction),
      });
    }
  }, [props.location]);

  useEffect(() => {
    if (props.jurisdiction && props.jurisdiction.id) {
      getJurisdictionItemV2(props.jurisdiction.id).then(res => {
        if (res) {
          const newObj = {
            polygon: res.data.polygon
              ? parseMultipolygonLeaflet(res.data.polygon)
              : [],
          };
          setCurrentParentPolygon(newObj);
        }
      });
    } else {
      setCurrentParentPolygon({});
    }
  }, [props.jurisdiction, props.location]);

  useEffect(() => {
    if (props.jurisdiction && props.jurisdiction.id) {
      setLoading(true);
      getPostcodeIndexMapView({
        search: searchText,
        jurisdiction_ids: props.jurisdiction.id,
      })
        .then(res => {
          const postcodesPolygon =
            res.data &&
            res.data.map(item => ({
              id: item.id,
              name: item.index,
              address: item.address,
              lat: item.center_latitude,
              lon: item.center_longitude,
              polygon: item.wkt ? parseMultipolygonLeaflet(item.wkt) : [],
            }));

          setPostcodes(postcodesPolygon);
          setLoading(false);
        })
        .catch(error => {
          props.showErrorMessage(error);
          setLoading(false);
        });
    } else {
      setPostcodes([]);
    }
  }, [props.jurisdiction, props.location]);

  useEffect(() => {
    if (postcodes) {
      if (searchText) {
        const newArray = [];
        postcodes.forEach(item => {
          const isItemIncludesSearchText = item.name
            .toUpperCase()
            .includes(searchText.toUpperCase());

          if (isItemIncludesSearchText) {
            const foundItem = {
              ...item,
              isCityFound: true,
            };
            newArray.push(foundItem);
          }
        });
        setSearchResults(newArray);
      } else {
        setSearchResults(postcodes);
      }
    }
  }, [searchText, postcodes]);

  const handleItemSelect = (e, id) => {
    if (e.target.checked) {
      setSelectedItems(prev => [...prev, id]);
    } else {
      setSelectedItems(prev => {
        const idx = prev.findIndex(i => i === id);
        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      });
    }
  };

  return (
    <FlexBox flex={true} className={classes.root}>
      <PageLoading isLoading={loading} />
      <FlexBox flex={true} className={classes.leftSide}>
        <Card className={classes.listCard}>
          <ListItem>
            <FlexBox flex={true} gutter={16}>
              <FlexBox flex={true} className={classes.jmWrapper}>
                <FormJMAutoComplete
                  renderOption={option => (
                    <FlexBox direction="column">
                      <span>{option.name}</span>
                      <span
                        style={{
                          fontSize: ".8rem",
                          fontStyle: "italic",
                        }}
                      >
                        {option.value.hierarchy.map((item, index, arr) =>
                          index === arr.length - 1
                            ? item.name
                            : `${item.name} > `,
                        )}
                      </span>
                    </FlexBox>
                  )}
                  fullWidth={true}
                  name="jurisdiction"
                  label={props.i18n.get("jurisdiction", "Jurisdiction")}
                />
              </FlexBox>
              <FlexBox style={{ paddingLeft: 0, maxHeight: "55px" }}>
                <FormJurisdictionSelectField
                  forPostcode={true}
                  closeDialog={() => setEditJurisdiction(false)}
                  withoutInput={true}
                  withoutInputOpen={editJurisdiction}
                  name="jurisdiction"
                />
                <IconButton
                  size="small"
                  className={classes.jmIcon}
                  onClick={() => setEditJurisdiction(true)}
                >
                  <AccountTree />
                </IconButton>
              </FlexBox>
            </FlexBox>
          </ListItem>
          <ListItem>
            {Boolean(postcodes.length > 0) && (
              <ListItemIcon>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedItems.length === postcodes.length}
                      disabled={!searchResults.length}
                      inputProps="aria-labelledby"
                      color="default"
                      onChange={e => {
                        if (e.target.checked) {
                          setSelectedItems(() =>
                            postcodes.map(item => item.id),
                          );
                        } else {
                          setSelectedItems([]);
                        }
                      }}
                    />
                  }
                  label={props.i18n.get("all", "All")}
                />
              </ListItemIcon>
            )}
            <TextField
              name="search"
              value={searchText}
              label={props.i18n.get("search", "Search")}
              onChange={e => setSearchText(e.target.value)}
              variant="outlined"
              fullWidth={true}
              size="small"
              type="search"
            />
          </ListItem>
          <div style={{ flex: "1 1", overflow: "auto" }}>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.listRoot}
            >
              {Boolean(searchResults.length) &&
                searchResults.map(item => (
                  <React.Fragment>
                    <ListItem
                      key={item.id}
                      className={cx({
                        [classes.active]:
                          selectedItems && selectedItems.includes(item.id),
                      })}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          color="default"
                          tabIndex={-1}
                          checked={
                            selectedItems && selectedItems.includes(item.id)
                          }
                          inputProps="aria-labelledby"
                          onChange={e =>
                            handleItemSelect(
                              e,
                              item.id,
                              postcodes.filter(i => item.id === i.id)[0],
                            )
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        secondary={item.address}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
            </List>
          </div>
        </Card>
      </FlexBox>
      <FlexBox flex={true} className={classes.map}>
        <LeafletMapPostcodesArea
          areaList={postcodes}
          selectedItems={selectedItems}
          setLocation={props.setLocation}
          currentParentPolygon={currentParentPolygon}
        />
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AdminPostcodesMapView);
