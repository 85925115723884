import React from "react";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { connect } from "react-redux";
import RuleConditionsUploadForm from "./SlaRuleConditionsUploadForm";
import { renderIf } from "../../helpers/HOCUtils";
import ResponseError from "../../helpers/ResponseError";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { uploadRuleConditionsCSV } from "../../api/admin/AdminOrderRulesApi";

const enhancer = compose(
  renderIf("open"),
  connect(null, { showErrorMessage }),
  reduxForm({
    form: "RuleConditionsUploadDialog",
    enableReinitialize: true,
    initialValues: { fields: true },
  }),
  formValues({
    fields: "fields",
  }),
);

SlaRuleConditionsUploadDialog.propTypes = {
  isDomestic: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showErrorMessage: PropTypes.func,
  change: PropTypes.func,

  value: PropTypes.array,
  field: PropTypes.string,
  cityId: PropTypes.number,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  ruleType: PropTypes.string.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

function SlaRuleConditionsUploadDialog(props) {
  return (
    <RuleConditionsUploadForm
      open={true}
      ruleType={props.ruleType}
      onRequestClose={props.onRequestClose}
      onSubmit={fp.flow(
        values => {
          const params = {
            field: values.field,
            operator: values.operator,
            cityId: values.cityId,
          };
          return uploadRuleConditionsCSV(params, values.file);
        },
        request => request.toPromise().catch(ResponseError.throw),
      )}
      onSubmitSuccess={fp.flow(fp.get("payload.data"), fromJS, items => {
        props.change("fields", items);
        props.handleSubmit();
      })}
      onSubmitFail={props.showErrorMessage}
    />
  );
}

export default enhancer(SlaRuleConditionsUploadDialog);
