import React, { useEffect, useState } from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Button, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import ClearOrderDialog from "./ClearOrderDialog";
import DeleteOrderDialog from "./DeleteOrderDialog";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import DataList, { DataListColumn } from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { formatNumber, formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  clearOrderUploadList,
  deleteOrderUploadList,
  getOrderUploadList2,
} from "../../api/admin/AdminOrderUploadApi";
import DataListFilter from "../../helpers/DataListFilter";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return { getLocalisationMessage };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
);

AdminOrderUploadList.propTypes = {
  onOrderUploadClick: PropTypes.func.isRequired,
  onClearUploadClick: PropTypes.func.isRequired,
  onRemoveUploadClick: PropTypes.func.isRequired,
  onOrderCreateUploadClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  removeId: PropTypes.number,
  clearId: PropTypes.number,
  customerId: PropTypes.number,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
};

function AdminOrderUploadList(props) {
  const { getLocalisationMessage, removeId, clearId, customerId } = props;
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState(List());

  useEffect(() => {
    setIsLoading(true);
    getOrderUploadList2({
      customer_id: customerId,
      page: props.filter.getPage(),
      size: props.filter.getSize(),
      search: props.filter.getSearch(),
    })
      .then(res => {
        if (res && res.data) {
          setList(fromJS(res.data.list));
          setTotal(res.data.total);
          setIsLoading(false);
        }
      })
      .catch(error => {
        showErrorMessage(error);
        setIsLoading(false);
      });
  }, [customerId, refresh, props.filter]);
  return (
    <FlexBox flex={true} direction="column" element={<Paper />}>
      <DeleteOrderDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset(["remove"]))}
        onSubmit={() => deleteOrderUploadList(removeId)}
        onSubmitSuccess={() => {
          setRefresh(!refresh);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
          props.setLocationQuery(fp.unset(["remove"]));
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
          props.setLocationQuery(fp.unset(["remove"]));
        }}
      />

      <ClearOrderDialog
        open={clearId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset(["clear"]))}
        onSubmit={() => clearOrderUploadList(clearId)}
        onSubmitSuccess={() => {
          setRefresh(!refresh);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_cleared",
              "Successfully Cleared",
            ),
          );
          props.setLocationQuery(fp.unset(["clear"]));
        }}
        onSubmitFail={error => {
          props.showErrorMessage(error);
          props.setLocationQuery(fp.unset(["clear"]));
        }}
      />

      <DataList
        isLoading={isLoading}
        overscanRowCount={10}
        totalCount={total}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        rowCount={list.size}
        filter={props.filter}
        rowGetter={row => list.get(row.index)}
        cardActionIcons={
          <div>
            <Button onClick={props.onOrderCreateUploadClick}>
              {" "}
              {getLocalisationMessage("upload_file", "Upload File")}{" "}
            </Button>
            <Button onClick={props.onClearUploadClick}>
              {" "}
              {getLocalisationMessage("clear", "Clear")}{" "}
            </Button>
          </div>
        }
      >
        <DataListColumn
          width={120}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          cellRenderer={row => (
            <LinkButton
              onClick={() => {
                props.setLocationQueryFilter(props.filter.setPage(0));

                props.onOrderUploadClick(row.cellData.get("id"));
              }}
            >
              {row.cellData.get("id")}
            </LinkButton>
          )}
        />
        <DataListColumn
          width={120}
          label={getLocalisationMessage("uploaded_by", "Uploaded By")}
          dataKey="id"
          cellRenderer={row =>
            row.cellData.get("uploaded_by")
              ? row.cellData.get("uploaded_by")
              : getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("type", "Type")}
          dataKey="domestic"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("domestic")
              ? getLocalisationMessage("domestic", "Domestic")
              : getLocalisationMessage("international", "International")
          }
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("process_date", "Process Date")}
          dataKey="completed_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("completed_date")} />
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("of_orders", "# of orders")}
          dataKey="total"
          justifyContent="end"
          cellRenderer={row => formatNumber(row.cellData.get("total"))}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          justifyContent="center"
          cellRenderer={row =>
            formatText(
              getLocalisationMessage(
                row.cellData.get("status"),
                row.cellData.get("status"),
              ),
            )
          }
        />
        <DataListColumn
          label={getLocalisationMessage("action", "Action")}
          width={200}
          dataKey="action"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <Button
                onClick={() =>
                  props.onRemoveUploadClick(row.cellData.get("id"))
                }
              >
                {" "}
                {getLocalisationMessage("remove", "Remove")}{" "}
              </Button>
            </div>
          )}
        />
      </DataList>
    </FlexBox>
  );
}

export default enhancer(AdminOrderUploadList);
