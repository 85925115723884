import { Set } from "immutable";

export const ACCURATE_ADDRESS = "accurate_address";
export const ACCURATE_TO_AREA = "accurate_to_area";
export const ACCURATE_TO_CITY = "accurate_to_city";
export const ACCURATE_TO_UNKNOWN = "accurate_to_unknown";
export const ACCURACY_IMPROVED_BY_RECIPIENT = "accuracy_improved_by_recipient";
export const ACCURACY_IMPROVED_BY_CS = "accuracy_improved_by_cs";
export const ACCURACY_AUTO_IMPROVED = "accuracy_auto_improved";

export default Set([
  ACCURATE_ADDRESS,
  ACCURATE_TO_AREA,
  ACCURATE_TO_CITY,
  ACCURATE_TO_UNKNOWN,
  ACCURACY_IMPROVED_BY_RECIPIENT,
  ACCURACY_IMPROVED_BY_CS,
  ACCURACY_AUTO_IMPROVED,
]);
