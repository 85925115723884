import { Observable } from "rxjs";
import fp from "lodash/fp";
import axios from "axios";
import Cookies from "js-cookie";
import MongoApi from "../helpers/MongoApi";
import { isString, isArray } from "lodash";
import { MONGO_SOCKET_HOST } from "../../shared/constants/ApiConstants";

export const MONGO_DB_URL = MONGO_SOCKET_HOST;
export const MONGODB_TOKEN_URL = "/auth/signup";

export const fetchMongoToken = (warehouseId) =>
  axios
    .get(MONGODB_TOKEN_URL, {
      params: {
        warehouse_id: warehouseId,
      },
    })
    .then((response) => {
      Cookies.set("mongo_token", response.data.token);
      return response.data.token;
    });

export class MongoDBSDK {
  constructor(socket) {
    this.api = new MongoApi();
    this.socket = socket;
    this.map = new Map();
  }

  // eslint-disable-next-line class-methods-use-this
  addEventListener(tableName, name) {
    return new Observable((emit) => {
      if (this.socket) {
        this.socket.on(tableName, (data) => {
          if (isString(name)) {
            if (data.event === name) {
              emit.next(data.item);
            }
          } else if (isArray(name)) {
            if (name.includes(data.event)) {
              emit.next(data.item);
            }
          }
        });
      }
    });
  }

  addEventListener2(tableName) {
    return new Observable((emit) => {
      if (this.socket) {
        this.socket.on(tableName, (data) => {
          emit.next(data || {});
        });
      }
    });
  }

  get(params) {
    return new Observable((emit) => {
      this.api.get("/getData", { params }).subscribe({
        next: (response) => {
          if (emit) {
            emit.next(fp.get("data.data.content", response) || []);
          }
        },
      });
      if (this.socket) {
        this.socket.on(params.name, (data) => {
          if (emit) {
            emit.next(data || {});
          }
        });
        return {
          dispose: this.socket.close,
        };
      }
      return {
        dispose: undefined,
      };
    });
  }

  getWithPagination(params) {
    return new Observable((emit) => {
      this.api.get("/getData", { params }).subscribe({
        next: (response) => {
          if (emit) {
            emit.next(fp.get("data.data.content", response) || []);
          }
        },
      });
    });
  }

  getDataByKeyValue(params) {
    return new Observable((emit) => {
      this.api.get("/getDataByKeyValue", { params }).subscribe({
        next: (response) => {
          // eslint-disable-next-line no-console
          // console.log("axios", response);
          emit.next(fp.get("data.data.content", response) || []);
        },
      });
      if (params) {
        if (this.socket) {
          this.socket.on(params.name, (data) => {
            // console.log("mongo-socket", params.name);
            emit.next(data.result || {});
          });
        }
      }
      if (this.socket) {
        return {
          dispose: this.socket.close,
        };
      }
      return {
        dispose: undefined,
      };
    });
  }

  create(data) {
    return this.api.post("/create", { data });
  }

  update(data) {
    return this.api.put("/update", { data });
  }

  clear(data) {
    return this.api.delete("/clearDb", { data });
  }

  batchUpdate(data) {
    return this.api.put("/batchUpdate", { data });
  }

  batchDelete(data) {
    return this.api.delete("/batchDelete", { data });
  }

  onChildAdd(name, event) {
    return this.addEventListener(name).map((response) => {
      if (response.event === event) return response.item;

      return null;
    });
  }

  remove(name, key, value, page = "outbound") {
    return this.api.delete("/delete", { data: { name, key, value, page } });
  }

  //
  // onChildChanged(path) {
  //   return this.addEventListener(path, "child_changed");
  // }
  //
  // onChildRemoved(path) {
  //   return this.addEventListener(path, "child_removed");
  // }
  //
  // isOnline() {
  //   return this.dbCache
  //     .get()
  //     .switchMap(db =>
  //       createEventStream(db.ref(".info/connected"), "value").map(
  //         fp.method("val"),
  //       ),
  //     );
  // }
}
