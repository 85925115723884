import React from "react";
import { List, Set } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../data-list/DataList";
import { formatDate } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import PaymentTypeAvatarAndInfo from "../orders-core/PaymentTypeAvatarAndInfo";
import MultiLineCell from "../data-list/MultiLineCell";
import _ from "lodash";
import OperatorLiabiltyStatus, {
  WITH_OPERATOR,
} from "../../constants/OperatorLiabiltyStatus";

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    publicService: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconPrice: {
      justifyContent: "center",
      alignItems: "center",
      width: "30px",
    },
    price: {
      justifyContent: "center",
      alignItems: "center",
      width: "120px",
    },
  }),
);

OperatorLiabilityCODPendingTable.propTypes = {
  list: PropTypes.instanceOf(List).isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Set),
  altHeader: PropTypes.node,
  location: PropTypes.object,
  maxSearchItems: PropTypes.number,
};

OperatorLiabilityCODPendingTable.defaultProps = {
  maxSearchItems: Infinity,
};

function OperatorLiabilityCODPendingTable(props) {
  const { getLocalisationMessage } = props;
  const query = _.omit(props.location.query, ["page"]);
  const tabName = OperatorLiabiltyStatus.has(query.payment_flow)
    ? query.payment_flow
    : OperatorLiabiltyStatus.first();
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.total}
      list={props.list}
      rowCount={props.list.size}
      maxSearchItems={props.maxSearchItems}
      overscanRowCount={4}
      rowGetter={(options) => props.list.get(options.index)}
      selectedRowCount={props.selectedItems ? props.selectedItems.size : 0}
      filter={props.filter}
      classNameDataList={true}
      onFilterChange={props.onFilterChange}
      altHeader={props.altHeader}
    >
      {tabName === WITH_OPERATOR && (
        <DataListCheckbox
          allRowsSelected={() =>
            props.selectedItems.size > 0 &&
            props.selectedItems.size === props.list.size
          }
          onAllRowsSelect={(value) =>
            props.onRowSelect(
              !value
                ? Set()
                : props.list.toSet().map((item) => item.get("order_code")),
            )
          }
          rowSelected={(row) =>
            props.selectedItems.has(row.cellData.get("order_code"))
          }
          onRowSelect={(row) =>
            props.onRowSelect(
              row.selected
                ? props.selectedItems.add(row.cellData.get("order_code"))
                : props.selectedItems.delete(row.cellData.get("order_code")),
            )
          }
        />
      )}

      <DataListColumn
        label={getLocalisationMessage("order_id", "Order ID")}
        dataKey="code"
        justifyContent="center"
        disableSort={true}
        cellRenderer={(row) =>
          row.cellData.get("order_code") || getLocalisationMessage("na", "N/A")
        }
      />

      <DataListColumn
        label={getLocalisationMessage("created_date", "Created Date")}
        dataKey="create_date"
        justifyContent="center"
        disableSort={true}
        cellRenderer={(row) =>
          formatDate(row.cellData.get("create_date")) ||
          getLocalisationMessage("created_date", "Created Date")
        }
      />

      <DataListColumn
        label={getLocalisationMessage("package", "Package")}
        dataKey="package"
        justifyContent="center"
        disableSort={true}
        cellRenderer={(row) =>
          <PriceWrapper price={row.cellData.get("service_fee")} code="UZS" /> ||
          getLocalisationMessage("na", "N/A")
        }
      />
      <DataListColumn
        label={getLocalisationMessage("Сумма услуг", "Сумма услуг")}
        dataKey="package"
        justifyContent="center"
        disableSort={true}
        cellRenderer={(row) =>
          (
            <PriceWrapper
              price={row.cellData.get("insurance_fee")}
              code="UZS"
            />
          ) || getLocalisationMessage("na", "N/A")
        }
      />
      <DataListColumn
        label={getLocalisationMessage("Прочие сборы", "Прочие сборы")}
        dataKey="package"
        justifyContent="center"
        disableSort={true}
        cellRenderer={(row) =>
          <PriceWrapper price={row.cellData.get("other_fee")} code="UZS" /> ||
          getLocalisationMessage("na", "N/A")
        }
      />
      <DataListColumn
        dataKey="amount"
        disableSort={true}
        justifyContent="center"
        label={getLocalisationMessage("total", "Total")}
        cellRenderer={(row) => (
          <PaymentTypeAvatarAndInfo
            paymentType={row.cellData.get("payment_type")}
          >
            <MultiLineCell
              style={{ paddingRight: "10px" }}
              firstLine={
                <div style={{ textAlign: "center" }}>
                  {getLocalisationMessage(row.cellData.get("payment_type"))}
                </div>
              }
              secondLine={
                <div style={{ textAlign: "center" }}>
                  <PriceWrapper price={row.cellData.get("total_fee")} />
                </div>
              }
            />
          </PaymentTypeAvatarAndInfo>
        )}
      />
    </DataList>
  );
}

export default enhancer(OperatorLiabilityCODPendingTable);
