import { compose, mapPropsStream, withHandlers } from "recompose";
import { connect } from "react-redux";
import { List, Map, Set } from "immutable";
import { getMessage } from "../../reducers/LocalizationReducer";
import useSheet from "react-jss";
import { pureComponent, renderIf } from "../../helpers/HOCUtils";
import fp from "lodash/fp";
import FlexBox from "../ui-core/FlexBox";
import React from "react";
import PropTypes from "prop-types";
import { pipeStreams } from "../../helpers/StreamUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formValues, reduxForm } from "redux-form";
import ScannerTextField from "../deprecated/ScannerTextField";
import Text from "../ui-core/Text";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import {
  HOLD_ON_AT_CUSTOMS,
  RETURNED_FROM_CUSTOMS,
} from "../../constants/OrderStatusCodes";
import ConfirmationButton from "../ui-core/ConfirmationButton";

const enhancer = compose(
  renderIf("open"),
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage },
  ),
  useSheet({
    modal: {
      maxWidth: "600px",
      minWidth: "600px",
      minHeight: "auto",
    },
    hintText: { fontSize: "14px", marginTop: "5px" },
    input: { "& input": { fontSize: "20px" } },
    scanner: { flexDirection: "column", width: "100%" },
    status: { marginBottom: 15 },
    chips: { width: "100%", marginTop: 15 },
    chip: { margin: 4, flex: "1 auto" },
    action: {
      borderTop: "1px solid rgba(0, 0, 0, 0.5)",
      paddingTop: 15,
      marginTop: 15,
    },
  }),
  mapPropsStream(
    pipeStreams((propStream) => {
      const ordersStream = propStream
        .map(fp.get("list"))
        .filter((list) => list && list.size > 0)
        .distinctUntilChanged(isEqualData)
        .map((list) => {
          const orders = list.map((item) => item.get("barcode"));

          return orders.toSet();
        })
        .startWith(Set());

      return propStream
        .combineLatest(ordersStream, (props, orders) => ({
          ...props,
          orders,
        }))
        .distinctUntilChanged(isEqualData);
    }),
  ),
  withHandlers({
    onSubmit:
      (props) =>
      ({ unVerifiedOrders }) => {
        if (!props.onSubmit) {
          return null;
        }
        const statuses = Map().asMutable();

        const unVerified = unVerifiedOrders || Set();
        const verifiedOrders = props.orders.subtract(unVerified);

        if (unVerified.size > 0) {
          statuses.set(
            HOLD_ON_AT_CUSTOMS,
            Map({
              status: HOLD_ON_AT_CUSTOMS,
              barcodes: unVerified,
              batch_id: props.batchId,
            }),
          );
        }

        if (verifiedOrders.size > 0) {
          statuses.set(
            RETURNED_FROM_CUSTOMS,
            Map({
              status: RETURNED_FROM_CUSTOMS,
              barcodes: verifiedOrders,
              batch_id: props.batchId,
            }),
          );
        }

        const values = {
          allOrders: props.orders,
          orderNumbers: verifiedOrders.toArray(),
          statuses: statuses.asMutable().valueSeq().toSet().toJS(),
        };

        return props.onSubmit(values);
      },
  }),
  reduxForm({
    form: "OrderCustomsCompleteForm",
    // destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  }),
  formValues("unVerifiedOrders"),
  withHandlers({
    handleScanBarcode:
      ({ change, orders, unVerifiedOrders, ...props }) =>
      (value) => {
        const unverified = (unVerifiedOrders || Set()).asMutable();
        if (orders.has(value)) {
          unverified.add(value);
          change("unVerifiedOrders", unverified.asImmutable());
        } else {
          props.showErrorMessage(
            props.getLocalisationMessage(
              "barcode_does_not_exists_on_this_batch",
              "Barcode does not exists on this Batch",
            ),
          );
        }
      },
    handleDeleteBarcode:
      ({ change, orders, unVerifiedOrders, ...props }) =>
      (value) => {
        const unverified = (unVerifiedOrders || Set()).asMutable();
        if (orders.has(value)) {
          unverified.delete(value);
          change("unVerifiedOrders", unverified.asImmutable());
        } else {
          props.showErrorMessage(
            props.getLocalisationMessage(
              "barcode_does_not_exists_on_this_batch",
              "Barcode does not exists on this Batch",
            ),
          );
        }
      },
  }),
  pureComponent(fp.pick(["focusInput", "orders", "unVerifiedOrders"])),
);

OrderCustomsCompleteDialog.propTypes = {
  open: PropTypes.bool,

  classes: PropTypes.object,
  list: PropTypes.instanceOf(List),
  orders: PropTypes.instanceOf(Set),
  unVerifiedOrders: PropTypes.instanceOf(Set),

  handleSubmit: PropTypes.func,
  focusInput: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleScanBarcode: PropTypes.func,
  handleDeleteBarcode: PropTypes.func,
};

function OrderCustomsCompleteDialog(props) {
  const { classes, getLocalisationMessage } = props;

  const unVerifiedOrders = props.unVerifiedOrders || Set();
  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 600,
          maxWidth: 800,
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {getLocalisationMessage("ready_for_transit", "Ready For Transit")}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <FlexBox direction="column">
          <div className={classes.status}>
            <Text element="h6" type="align-left">
              {getLocalisationMessage("verified_orders", "Verified Orders")}:{" "}
              {props.orders.size - unVerifiedOrders.size}
            </Text>
            <Text element="h6" type="align-left">
              {getLocalisationMessage("unverified_orders", "Unverified Orders")}
              : {unVerifiedOrders.size}
            </Text>
          </div>
          <ScannerTextField
            fullWidth={true}
            label={getLocalisationMessage("scan_barcode", "Scan Barcode")}
            focus={props.focusInput}
            className={classes.input}
            id="OrderCustomsFormScannerTextField"
            onChange={props.handleScanBarcode}
          />
          <div className={classes.chips}>
            {unVerifiedOrders &&
              unVerifiedOrders.map((item) => (
                <Chip
                  className={classes.chip}
                  key={item}
                  label={item}
                  onDelete={() => props.handleDeleteBarcode(item)}
                />
              ))}
          </div>
        </FlexBox>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={props.onRequestClose} primary={true}>
          {getLocalisationMessage("dismiss")}
        </Button>

        <ConfirmationButton
          variant={CONTAINED}
          color={SECONDARY}
          buttonLabel={getLocalisationMessage(
            "send_to_transit",
            "Send to Transit",
          )}
          onConfirm={props.handleSubmit}
        >
          {getLocalisationMessage(
            "please_note_that_you_can_not_change_this_after_confirmation",
            "Please note that You can not change this after confirmation",
          )}
        </ConfirmationButton>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderCustomsCompleteDialog);
