import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  PACKAGE_RULE_ITEM_URL,
  PACKAGE_RULE_LIST_URL,
  PACKAGE_RULE_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedWarehouse,
  getWarehousePolygons,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getChildRulePredictions } from "../../api/admin/AdminOrderRulesApi";
import {
  getCachedPostcode,
  getNeighborhoodPolygons,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getPackageRule,
  updatePackageRule,
  getCachedPackageRule,
  getPackageRulePredictions,
} from "../../api/admin/AdminPackageRulesApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import PackageRuleTree from "../../components/package-rule-tree/PackageRuleTree";
import {
  getCachedUser,
  getOperatorPredictions,
} from "../../api/admin/AdminUserApi";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const responseStream = propsStream
      .map(fp.get("params.id"))
      .distinctUntilChanged()
      .switchMap(id =>
        getPackageRule(id)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .distinctUntilChanged(isEqualData)
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              pending: response.get("pending", false),
              item: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      onRequestRefresh,
      item: response.get("item"),
      isFetching: response.get("pending"),
    }));
  }),
);

AdminPackageRuleItemContainer.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  isFetching: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  i18n: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function AdminPackageRuleItemContainer(props) {
  return (
    <AdminAppLayout
      title={props.i18n.get("edit_package_rule", "Edit Package Rule")}
    >
      <PageLoading isLoading={props.isFetching} />

      {!props.isFetching && (
        <PackageRuleTree
          tree={props.item}
          onSubmit={values =>
            updatePackageRule(values).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              props.i18n.get(
                "successfully_package_rule_updated",
                "Successfully Package Rule Updated",
              ),
            );
            props.setLocation(PACKAGE_RULE_LIST_URL);
          }}
          onSubmitFail={error => props.showErrorMessage(error)}
          createRuleUrl={PACKAGE_RULE_CREATE_URL}
          getRuleUrl={rule => `${PACKAGE_RULE_ITEM_URL}/${rule.id}`}
          getWarehousePolygons={getWarehousePolygons}
          getNeighborhoodPolygons={getNeighborhoodPolygons}
          getCachedRule={getCachedPackageRule}
          getRulePredictions={getPackageRulePredictions}
          getChildRulePredictions={getChildRulePredictions}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedTimeSlot={getCachedTimeSlot}
          getTimeSlotPredictions={getTimeSlotPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
          getCachedUser={getCachedUser}
          getOperatorPredictions={getOperatorPredictions}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminPackageRuleItemContainer);
