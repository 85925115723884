import sprintf from "sprintf";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const PRICE_PLAN_URL = `${API_ROOT_URL}/price_plans/`;
const GET_ACTIVE_PRICE_PLAN_URL = `${API_ROOT_URL}/price_plans/active_plan`;
const GET_PRICE_PLAN_ITEM_URL = `${API_ROOT_URL}/price_plans/%s`;
const GET_PLAN_ADD_ONS_URL = `${API_ROOT_URL}/price_plans/addons`;
const REACTIVATE_SUBSCRIPTION = `${API_ROOT_URL}/reactivate_subscription`;
const GET_MARKETPLACE_PRICE_PLAN_ITEM_URL = `${API_ROOT_URL}/price_plans/%s/plans`;
const BUY_PRICE_PLAN_ITEM_URL = `${API_ROOT_URL}/price_plans/%s/create_dms_plan`;
const STRIPE_SETUP_INTENT_URL = `${API_ROOT_URL}/stripe/setup_intent`;
const ATTACH_PAYMENT_METHOD = `${API_ROOT_URL}/stripe/attach_payment_method`;
const DETACH_PAYMENT_METHOD = `${API_ROOT_URL}/stripe/detach_payment_method`;
const SET_AS_DEFAULT_PAYMENT_METHOD = `${API_ROOT_URL}/stripe/mark_payment_method_as_default`;
const CANCEL_SUBSCRIBED_PRICE_PLAN_URL = `${API_ROOT_URL}/price_plans/marketplaceplan/%s/cancel`;
const GET_MARKETPLACE_PRICE_PLAN_LIST_URL = `${API_ROOT_URL}/price_plans/%s/plans`;
const GET_MARKETPLACE_PURCHASE_HISTORY_URL = `${API_ROOT_URL}/price_plans/%s/invoices`;
const GET_SUBSCRIPTION_PRICES = `${API_ROOT_URL}/price_plans/subscription_price/%s`;
const GET_STRIPE_PAYMENT_METHODS = `${API_ROOT_URL}/stripe/marketplaces_payment_methods`;
const SUBSCRIBE_PRICING_PLAN = `${API_ROOT_URL}/price_plans/subscribe`;
const UNSUBSCRIBE_PRICING_PLAN = `${API_ROOT_URL}/price_plans/cancel_subscription`;

const plansCache = new ObservableCache({
  loader: () => api.get(PRICE_PLAN_URL),
});
const priceAddOnsCache = new ObservableCache({
  loader: () => api.get(GET_PLAN_ADD_ONS_URL),
});
export const getCachedPricePlans = () => plansCache.get();

export const getActivePlan = () => api.getStream(GET_ACTIVE_PRICE_PLAN_URL);

export const getCachedPriceAddOns = () => priceAddOnsCache.get();

export const getPricePlanItem = id =>
  api.getStream(sprintf(GET_PRICE_PLAN_ITEM_URL, id));

export const getMarketplacePricePlanItem = marketplaceId =>
  api.getStream(sprintf(GET_MARKETPLACE_PRICE_PLAN_ITEM_URL, marketplaceId));

export const getMarketplacePricePlanList = marketplaceId =>
  api.getStream(sprintf(GET_MARKETPLACE_PRICE_PLAN_LIST_URL, marketplaceId));

export const getMarketplacePurchaseHistory = marketplaceId =>
  api.getStream(sprintf(GET_MARKETPLACE_PURCHASE_HISTORY_URL, marketplaceId));

export const getSubscriptionPrices = stripeId =>
  api.getStream(sprintf(GET_SUBSCRIPTION_PRICES, stripeId));

export const buyPricePlanItem = (planId, body) =>
  api.post(sprintf(BUY_PRICE_PLAN_ITEM_URL, planId), { body });

export const reactivateSubscription = () =>
  api.post(sprintf(REACTIVATE_SUBSCRIPTION));

export const setupStripeIntent = () => api.post(STRIPE_SETUP_INTENT_URL);

export const attachPaymentMethodToCustomer = body =>
  api.put(ATTACH_PAYMENT_METHOD, { body });

export const detachPaymentMethodToCustomer = body =>
  api.put(DETACH_PAYMENT_METHOD, { body });

export const setAsDefaultPaymentMethod = body =>
  api.post(SET_AS_DEFAULT_PAYMENT_METHOD, { body });

export const getStripePaymentMethods = () =>
  api.getStream(GET_STRIPE_PAYMENT_METHODS);

export const cancelPricePlanItem = id =>
  api.post(sprintf(CANCEL_SUBSCRIBED_PRICE_PLAN_URL, id));

export const subscribePricingPlan = () =>
  api.post(sprintf(SUBSCRIBE_PRICING_PLAN));

export const unsubscribePricingPlan = () =>
  api.delete(sprintf(UNSUBSCRIBE_PRICING_PLAN));
