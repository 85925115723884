import React from "react";
import { OrderedSet } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormFileField from "../form/FormFileField";
import FormSelectField from "../form/FormSelectField";
import FormCitySelectField from "../form/FormCitySelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { createNotFalsyValidator } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { isBlankString } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ORDER_RULE } from "../../constants/RuleType";
import {
  SELLER_ID,
  SENDER_NAME,
  NEIGHBORHOOD_TO,
  NEIGHBORHOOD_FROM,
} from "../../constants/RuleConditionField";
import RuleComparationOperator from "../../constants/RuleComparationOperator";

const supplierRuleFields = OrderedSet.of(NEIGHBORHOOD_FROM, NEIGHBORHOOD_TO);
const orderRuleFields = OrderedSet.of(
  SELLER_ID,
  SENDER_NAME,
  NEIGHBORHOOD_FROM,
  NEIGHBORHOOD_TO,
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({
    form: "RuleConditionsUploadForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      file: values.file
        ? values.file.error
        : props.getLocalisationMessage(
            "please_select_file",
            "Please Select File",
          ),
      field:
        isBlankString(values.field) &&
        props.getLocalisationMessage(
          "please_select_field",
          "Please Select Field",
        ),
      operator:
        isBlankString(values.operator) &&
        props.getLocalisationMessage(
          "please_select_operator",
          "Please Select Operator",
        ),
      cityId:
        (values.field === NEIGHBORHOOD_FROM ||
          values.field === NEIGHBORHOOD_TO) &&
        !values.cityId &&
        props.getLocalisationMessage(
          "please_select_city",
          "Please Select City",
        ),
    }),
  }),
  formValues({
    field: "field",
    value: "value",
    cityId: "cityId",
  }),
);

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

SlaRuleConditionsUploadForm.propTypes = {
  isDomestic: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  value: PropTypes.array,
  field: PropTypes.string,
  cityId: PropTypes.number,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  ruleType: PropTypes.string.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SlaRuleConditionsUploadForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog modal={true} open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogTitle>
        {getLocalisationMessage(
          "import_rule_conditions",
          "Import Rule Conditions",
        )}
      </DialogTitle>
      <DialogContent style={{ maxWidth: "400px" }}>
        <FlexBox container={16} direction="column">
          <FlexBox gutter={8}>
            <FormSelectField
              name="field"
              style={{ marginBottom: "20px" }}
              fullWidth={true}
              hintText={getLocalisationMessage("field", "Field")}
              formatOption={x => getLocalisationMessage(x) || formatText(x)}
              validate={createNotFalsyValidator(
                getLocalisationMessage("select_field", "Select Field"),
              )}
              options={
                props.ruleType === ORDER_RULE
                  ? orderRuleFields
                  : supplierRuleFields
              }
            />
          </FlexBox>

          {(props.field === NEIGHBORHOOD_FROM ||
            props.field === NEIGHBORHOOD_TO) && (
            <FlexBox align="center">
              <FormCitySelectField
                name="cityId"
                style={{ marginBottom: "20px" }}
                domestic={true}
                fullWidth={true}
                hintText={getLocalisationMessage("select_city", "Select City")}
              />
            </FlexBox>
          )}

          {Boolean(props.field) && (
            <FlexBox align="center">
              <FormSelectField
                fullWidth={true}
                style={{ marginBottom: "20px" }}
                hintText={getLocalisationMessage("compare_by", "Compare By")}
                name="operator"
                formatOption={x => getLocalisationMessage(x) || formatText(x)}
                options={RuleComparationOperator}
                validate={createNotFalsyValidator(
                  getLocalisationMessage(
                    "select_comparator",
                    "Select Comparator",
                  ),
                )}
              />
            </FlexBox>
          )}

          <FlexBox gutter={8}>
            <FlexBox flex={true}>
              <FormFileField
                name="file"
                fullWidth={true}
                hintText={getLocalisationMessage("select_file", "Select File")}
                accept={CSV_FILE_TYPES}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <div>
          {Boolean(props.onRequestClose) && (
            <Button onClick={props.onRequestClose}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(SlaRuleConditionsUploadForm);
