import React, { useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { GetApp, Publish } from "@material-ui/icons";
import { makeStyles, TextField } from "@material-ui/core";
import { api } from "../../api/shared/BaseApi";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import {
  REFUND_CREATE_URL,
  REFUND_REVERT_URL,
} from "../../api/admin/AdminOperatorApi";

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
);

OperatorRefundForm.propTypes = {
  getLocalisationMessage: PropTypes.func.isRequired,
  operatorId: PropTypes.number,
};
const useStyles = makeStyles({
  wrapper: {
    flexWrap: "wrap",
    "& > *": {
      flex: "1 1 auto",
      margin: "0 1rem",
    },
  },
});
function OperatorRefundForm(props) {
  const classes = useStyles();
  const { getLocalisationMessage, operatorId } = props;
  const [amount, setAmount] = React.useState("0");
  // eslint-disable-next-line no-unused-vars
  const [isLoading1, setIsLoading1] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading2, setIsLoading2] = useState(false);
  const handleChange = (event) => {
    setAmount(event.target.value);
  };
  return (
    <FlexBox
      flex={true}
      align={ALIGN_CENTER}
      justify={JUSTIFY_CENTER}
      className={classes.wrapper}
    >
      <TextField
        type="number"
        label={getLocalisationMessage("credit_amount", "Credit amount")}
        value={amount}
        onChange={handleChange}
        variant={OUTLINED}
        size="small"
      />
      <CustomButton
        disabled={amount < 1}
        variant={CONTAINED}
        color={SECONDARY}
        onClick={() => {
          api
            .post(REFUND_REVERT_URL, {
              body: { operator_id: operatorId, amount },
            })
            .catch((error) => {
              setIsLoading1(false);
              showErrorMessage(error);
            })
            // eslint-disable-next-line no-unused-vars
            .then((res) => {
              // console.log("res", res);
              setIsLoading1(false);
            });
        }}
        endIcon={<GetApp />}
      >
        {getLocalisationMessage("accept", "Accept")}
      </CustomButton>
      <CustomButton
        disabled={amount < 1}
        variant={OUTLINED}
        color={SECONDARY}
        onClick={() => {
          api
            .post(REFUND_CREATE_URL, {
              body: { operator_id: operatorId, amount },
            })
            .catch((error) => {
              setIsLoading1(false);
              showErrorMessage(error);
            })
            // eslint-disable-next-line no-unused-vars
            .then((res) => {
              // console.log("res", res);
              setIsLoading1(false);
            });
        }}
        endIcon={<Publish />}
      >
        {getLocalisationMessage("new_credit", "new credit")}
      </CustomButton>
    </FlexBox>
  );
}

export default enhancer(OperatorRefundForm);
