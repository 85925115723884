import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRange } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import FlexBox from "../ui-core/FlexBox";
import MultiLineCell from "../data-list/MultiLineCell";
import {
  fade,
  formatDateDistanceToNowV2,
  formatText,
} from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    info: {
      color: fade("#263238", 0.7),
    },
    active: {
      color: "white",
    },
    multiLineFirst: {
      lineHeight: "20px",
      fontSize: "18px",
      textAlign: "center",
      fontWeight: 500,
      marginTop: "1px,",
    },
    multiLineSecond: {
      lineHeight: "18px",
      fontSize: "16px",
    },
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderDateInfo.propTypes = {
  classes: PropTypes.object,
  isActiveOrder: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function OrderDateInfo(props) {
  const { classes, item } = props;

  const deadline = formatDateDistanceToNowV2(
    item.get("internal_promise_date", null),
  );

  if (!deadline) return null;

  return (
    <FlexBox
      className={cx(classes.info, { [classes.active]: props.isActiveOrder })}
    >
      <DateRange
        style={{ marginRight: "10px", width: "40px", height: "40px" }}
        color={props.isActiveOrder ? "white" : grey[800]}
      />{" "}
      <MultiLineCell
        firstLine={
          <div className={classes.multiLineFirst}>{deadline.dateDistance}</div>
        }
        secondLine={
          <div className={classes.multiLineSecond}>
            {props.getLocalisationMessage(
              deadline.addText,
              formatText(deadline.addText),
            )}
          </div>
        }
      />
    </FlexBox>
  );
}

export default enhancer(OrderDateInfo);
