import React from "react";
import { Map, List } from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper, Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Add as ContentAdd } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import DataList, { DataListColumn } from "../data-list/DataList";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const binRuleListStream = propsStream
      .distinctUntilKeyChanged("binRules", isEqualData)
      .map(props =>
        !props.binRules
          ? List()
          : props.binRules
              .map((x, code) => x.set("code", code))
              .toList()
              .sortBy(x => x.get("weight")),
      );

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(binRuleListStream, (props, binRuleList) => ({
        ...props,
        binRuleList,
      }));
  }),
);

OrderReturnRuleListDialog.propTypes = {
  binRuleList: PropTypes.instanceOf(List),

  binRules: PropTypes.instanceOf(Map),
  open: PropTypes.bool.isRequired,
  onRuleClick: PropTypes.func.isRequired,
  onAddRuleClick: PropTypes.func.isRequired,
  onRuleRemoveClick: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onLoadDefaultPresetClick: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderReturnRuleListDialog(props) {
  const { binRules, binRuleList, getLocalisationMessage } = props;

  if (!binRules) {
    return <PageLoading isLoading={true} />;
  }

  return (
    <ModalPaper open={props.open} onRequestClose={props.onRequestClose}>
      <FlexBox flex={true} element={<Paper />} direction="column">
        {binRules.isEmpty() ? (
          <FlexBox
            flex={true}
            align="center"
            justify="center"
            direction="column"
          >
            <p>
              {getLocalisationMessage(
                "there_are_no_rules_in_your_settings_do_you_want_to",
                "There are no rules in your settings, do you want to ",
              )}
              <LinkButton onClick={props.onLoadDefaultPresetClick}>
                {getLocalisationMessage(
                  "load_default_preset",
                  "load default preset",
                )}
              </LinkButton>
              ?
            </p>
          </FlexBox>
        ) : (
          <FlexBox direction="column">
            <DataList
              isLoading={false}
              rowCount={binRuleList.size}
              overscanRowCount={10}
              rowGetter={row => binRuleList.get(row.index)}
              cardActionIcons={
                <div>
                  <IconButton onClick={props.onAddRuleClick}>
                    <ContentAdd />
                  </IconButton>
                </div>
              }
            >
              <DataListColumn
                label={getLocalisationMessage("code", "Code")}
                width={120}
                dataKey="code"
                cellRenderer={row => (
                  <LinkButton
                    onClick={() => props.onRuleClick(row.cellData.get("code"))}
                  >
                    {row.cellData.get("code")}
                  </LinkButton>
                )}
              />

              <DataListColumn
                width={120}
                label={getLocalisationMessage("name", "Name")}
                dataKey="name"
                cellRenderer={row => row.cellData.get("name")}
              />

              <DataListColumn
                width={88}
                label={getLocalisationMessage("weight", "Weight")}
                dataKey="weight"
                justifyContent="center"
                cellRenderer={row => row.cellData.get("weight")}
              />

              <DataListColumn
                width={88}
                label={getLocalisationMessage("action", "Action")}
                dataKey="action"
                justifyContent="center"
                cellRenderer={row => (
                  <Button
                    onClick={() =>
                      props.onRuleRemoveClick(row.cellData.get("code"))
                    }
                  >
                    {" "}
                    {getLocalisationMessage("remove", "Remove")}{" "}
                  </Button>
                )}
              />
            </DataList>
          </FlexBox>
        )}
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(OrderReturnRuleListDialog);
