import React from "react";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { KeyboardArrowLeft } from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FlexBox, {
  ALIGN_END,
  JUSTIFY_END,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ADDITIONAL_SERVICES_SETTINGS_URL } from "../../constants/AdminPathConstants";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import PageLoading from "../ui-core/PageLoading";
import { parseOnlyPositiveFloat } from "../../helpers/FormatUtils";
import FormCheckbox from "../form/FormCheckbox";
import { InputAdornment } from "@material-ui/core";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream((propsStream) => {
    const handleSubmit = (props) => (values) => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values);
    };

    return propsStream.combineLatest((props) => ({
      ...props,
      onSubmit: handleSubmit(props),
    }));
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdditionalServiceForm",
    validate: (values, { getLocalisationMessage }) => ({
      name: !values.name && getLocalisationMessage("this_field_is_required"),
      name_ru:
        !values.name_ru && getLocalisationMessage("this_field_is_required"),
    }),
  }),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdditionalServiceForm.propTypes = {
  setLocation: PropTypes.func,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  isLoading: PropTypes.string,
  serviceId: PropTypes.number,
};

function AdditionalServiceForm({
  getLocalisationMessage,
  handleSubmit,
  isLoading,
  serviceId,
  setLocation,
  edit,
}) {
  return (
    <div>
      <PageLoading isLoading={isLoading} />
      <FlexBox
        element={<form onSubmit={handleSubmit} />}
        direction="column"
        style={{ margin: "0 3rem" }}
      >
        <FlexBox
          flex={true}
          justify={JUSTIFY_SPACE_AROUND}
          align={ALIGN_END}
          style={{ margin: "1rem 0" }}
        >
          <FlexBox direction="column">
            <h4
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "28px",
                textTransform: "capitalize",
                color: "#263238",
              }}
            >
              {edit
                ? `${getLocalisationMessage("edit")} ${serviceId}`
                : getLocalisationMessage("create_new")}
            </h4>
          </FlexBox>
          <FlexBox>
            <CustomButton
              onClick={() => setLocation(ADDITIONAL_SERVICES_SETTINGS_URL)}
              startIcon={<KeyboardArrowLeft />}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("back", "Back")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
        <FlexBox flex={true}>
          <FormTextField
            name="name"
            fullWidth={true}
            margin="normal"
            withSuccessIcon={true}
            label={getLocalisationMessage("name")}
          />
        </FlexBox>
        <FlexBox flex={true}>
          <FormTextField
            name="name_ru"
            fullWidth={true}
            margin="normal"
            withSuccessIcon={true}
            label={getLocalisationMessage("name_ru")}
          />
        </FlexBox>
        <FlexBox flex={true}>
          <FormTextField
            name="rate_legal"
            parseOnBlur={parseOnlyPositiveFloat}
            fullWidth={true}
            margin="normal"
            withSuccessIcon={true}
            label={getLocalisationMessage("rate_legal")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getLocalisationMessage("som", "so'm")}
                </InputAdornment>
              ),
            }}
          />
        </FlexBox>
        <FlexBox flex={true}>
          <FormTextField
            name="rate_individual"
            parseOnBlur={parseOnlyPositiveFloat}
            fullWidth={true}
            margin="normal"
            withSuccessIcon={true}
            label={getLocalisationMessage("rate_individual")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getLocalisationMessage("som", "so'm")}
                </InputAdornment>
              ),
            }}
          />
        </FlexBox>
        <FlexBox flex={true}>
          <FormCheckbox
            name="active"
            label={getLocalisationMessage("active")}
          />
        </FlexBox>
        <FlexBox flex={true} justify={JUSTIFY_END}>
          <CustomButton type="submit" color={SECONDARY} variant={CONTAINED}>
            {getLocalisationMessage("save")}
          </CustomButton>
        </FlexBox>
      </FlexBox>
    </div>
  );
}

export default enhancer(AdditionalServiceForm);
