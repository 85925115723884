import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  isCustomMarketplace,
  getMarketplaceReceiveOrderFromWing,
  getMarketplaceDefaultBatchUpdateStatuses,
} from "../../reducers/MarketplaceReducer";
import AdminCODCodes from "../../constants/AdminCODCodes";
import {
  IN_TRANSIT,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import AdminPrivacyTypes from "../../constants/AdminPrivacyTypes";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import CreateOrderRegistryDialog from "../../components/order-outbound-sorting/CreateOrderRegistryDialog";
import { isEqualData } from "../../helpers/DataUtils";
import {
  BANDEROL,
  COURIER_SERVICE_WITHOUT_BAG,
  estimateRegistryCategory,
  getDestinationJurisdiction,
  getRegistryStatusByShipmentType,
  isRegistryBag,
  LETTER,
  MIXED,
  ONE_STEP_WITHOUT_BAG,
  PARCEL,
  POSTCARD,
  SMALL_PACKETS,
} from "../../helpers/OrderOutboundSortingHelper";
import { Map, OrderedSet } from "immutable";
import {
  INSURED_BAG,
  POST_PACKET,
} from "../../components/orders-core/BatchUpdateOrderDialog2";

const OrderStatusCodes = OrderedSet.of(PREPARED_FOR_TRANSIT, IN_TRANSIT);

const enhancer = compose(
  connect(state => ({
    isMarketplaceCustom: isCustomMarketplace(state),
    isMarketplaceReceiveOrderFromWing: getMarketplaceReceiveOrderFromWing(
      state,
    ),
    defaultBatchUpdateStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.pick(["selectedItems", "orders", "activeBin"]))
      .distinctUntilChanged(isEqualData)
      .map(({ orders, selectedItems, activeBin }) => {
        const data = selectedItems.reduce(
          (acc, orderNumber) => {
            const order = orders.get(orderNumber, null);
            if (order) {
              // Destination Countries
              const jurisdictions = getDestinationJurisdiction(
                order.get("info", Map()),
              );
              if (jurisdictions && jurisdictions.length > 0) {
                if (!acc.countries.includes(fp.get("0.id", jurisdictions))) {
                  acc.countries.push(fp.get("0.id", jurisdictions));
                }
              }

              // Transportation Types
              const transportationType = order.getIn(
                ["info", "transportation_type"],
                null,
              );
              if (
                transportationType &&
                !acc.transportationTypes.includes(transportationType)
              ) {
                acc.transportationTypes.push(transportationType);
              }

              // Courier Types
              const courierType = order.getIn(
                ["info", "package", "courier_type"],
                null,
              );

              if (
                courierType === BANDEROL &&
                order.getIn(["info", "package", "valuable"], false)
              )
                acc.valued = true;

              if (courierType && !acc.courierTypes.includes(courierType)) {
                acc.courierTypes.push(courierType);
              }

              // Calculate Weight
              acc.weight += parseFloat(order.getIn(["info", "weight"]) || 0);
            }
            return acc;
          },
          {
            valued: false,
            weight: 0,
            courierTypes: [],
            countries: [],
            transportationTypes: [],
          },
        );

        const bag = isRegistryBag(data.courierTypes);
        const orderStatus = getRegistryStatusByShipmentType(
          data.courierTypes,
          activeBin.get("transitStatus", null),
        );
        const isDomestic = data.countries.includes(246);

        let innerShipmentType = null;
        if (data.courierTypes.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          innerShipmentType = data.courierTypes[0];
        } else if (data.courierTypes.length > 1) {
          const isNotPK = data.courierTypes.filter(
            item => item !== LETTER && item !== BANDEROL && item !== POSTCARD,
          );
          if (isNotPK.length > 0) {
            innerShipmentType = MIXED;
          } else {
            innerShipmentType = LETTER;
          }
        }

        const values = {
          bag,
          orderStatus,
          isDomestic,
          innerShipmentType,
          privacy: PUBLIC,
          orderBarcodes: selectedItems.toArray(),
          onlyBarcode: true,
          weight: Math.round(data.weight * 100) / 100,
          transportationType:
            data.transportationTypes.length === 1
              ? data.transportationTypes[0]
              : null,
        };

        if (activeBin.getIn(["selectedBin", "nextPostcode"], null)) {
          values.next_postcode = {
            name: activeBin.getIn(["selectedBin", "nextPostcode"], null),
          };
        }

        if (activeBin.getIn(["selectedBin", "nextJurisdiction"], null)) {
          values.next_jurisdiction = {
            id: activeBin.getIn(["selectedBin", "nextJurisdiction"], null),
          };
        }

        if (activeBin.get("binPostcode", null)) {
          values.to_postcode = {
            name: activeBin.get("binPostcode", null),
          };
        } else if (
          !activeBin.get("binJurisdiction", null) &&
          values.next_postcode
        ) {
          values.to_postcode = values.next_postcode;
        }

        if (activeBin.get("binJurisdiction", null)) {
          values.to_jurisdiction = {
            id: activeBin.get("binJurisdiction", null),
          };
        } else if (
          !activeBin.get("binPostcode", null) &&
          values.next_jurisdiction
        ) {
          values.to_jurisdiction = values.next_jurisdiction;
        }

        if (
          !activeBin.getIn(["selectedBin", "nextPostcode"], null) &&
          !activeBin.getIn(["selectedBin", "nextJurisdiction"], null) &&
          activeBin.get("transitWarehouse", null)
        ) {
          values.warehouse = {
            id: activeBin.get("transitWarehouse", null),
          };
          if (activeBin.get("group", null) !== POST_PACKET) {
            values.to_warehouse = {
              id:
                activeBin.get("destinationWarehouse", null) ||
                activeBin.get("transitWarehouse", null),
            };
          }
        }

        const category = data.valued
          ? INSURED_BAG
          : fp.get("to_warehouse.id", values) !==
              fp.get("warehouse.id", values) &&
            innerShipmentType !== PARCEL &&
            innerShipmentType !== COURIER_SERVICE_WITHOUT_BAG &&
            innerShipmentType !== ONE_STEP_WITHOUT_BAG &&
            innerShipmentType !== SMALL_PACKETS &&
            selectedItems.size >= 8
          ? POST_PACKET
          : activeBin.get("group", null) ||
            estimateRegistryCategory(data.courierTypes);

        return {
          ...values,
          bag,
          orderStatus,
          isDomestic,
          category,
          innerShipmentType,
          onlyBarcode: true,
          privacy: PUBLIC,
          orderBarcodes: selectedItems.toArray(),
          weight: Math.round(data.weight * 100) / 100,
          transportationType:
            data.transportationTypes.length === 1
              ? data.transportationTypes[0]
              : null,
        };
      });

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
      }),
    );
  }),
);

AdminOutboundOrderSortingBatchUpdateDialogWrapper.propTypes = {
  open: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  list: PropTypes.any,
  selectedItems: PropTypes.any,
  isSorting: PropTypes.bool,
};

function AdminOutboundOrderSortingBatchUpdateDialogWrapper(props) {
  return (
    <CreateOrderRegistryDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      codCodes={AdminCODCodes}
      statusCodes={OrderStatusCodes}
      withInTransitToSupplier={true}
      privacyTypes={AdminPrivacyTypes}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      getPostcodePredictions={getPostcodePredictions}
      initialValues={props.initialValues}
      selectedItems={props.selectedItems}
      list={props.list}
      isSorting={props.isSorting}
      onSubmit={fp.flow(toSnakeCase, values => {
        const request = { ...values, privacy: PUBLIC };

        return batchAsyncUpdateOrder(
          fp.omit(["batch_ids", "is_barcode", "only_barcode"], request),
        ).catch(ResponseError.throw);
      })}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}

export default enhancer(AdminOutboundOrderSortingBatchUpdateDialogWrapper);
