import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withHandlers, withState } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import { IconButton, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { DateRange, Menu, Search } from "@material-ui/icons";
import OrderDriversToggleElement from "./OrderDriversToggleElement";
import FormTextField from "../form/FormTextField";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMarketplace } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    root: { width: "100%" },
    visible: { display: "flex" },
    invisible: { display: "none" },
    search: { marginRight: "12px" },
    inner: { marginLeft: 0 },
    textField: {
      fontFamily: "Blogger Sans",
      "& > div:first-child": {
        bottom: 11,
      },
    },
    groupStatistics: {
      position: "relative",
      cursor: "pointer",
      "& > div:not(:last-child)": {
        fontFamily: "Blogger Sans",
        fontSize: "20px",
        padding: "0 12px",
        width: "70px",
        display: "inline",
        textAlign: "center",
        borderLeft: "2px solid #ccc",
      },
    },
    drawer: {
      "& > div": {
        top: 0,
      },
    },
    inactivePage: {
      transform: "translate3d(16px, 0, 0)",
    },
    activePage: {
      transform: "translate3d(86px, 0, 0)",
    },
    hasOrdersPage: {
      transform: "translate3d(156px, 0, 0)",
    },
    hasAlertsPage: {
      transform: "translate3d(226px, 0, 0)",
    },
    inactiveStatistic: {
      color: "#9E9E9E",
    },
    activeStatistic: {
      color: "#8BC34A",
    },
    hasOrdersStatistic: {
      color: "#2196F3",
    },
    hasAlertsStatistic: {
      color: "#D32F2F",
    },
    hiddenArrow: {
      display: "none",
    },
    arrow: {
      zIndex: 1,
      top: "60px",
      width: 0,
      height: 0,
      position: "absolute",
      border: "20px solid transparent",
      borderBottomColor: "white",
      borderTop: 0,
      transition: ".7s ease",
    },
  }),
  withState("state", "setState", { search: false, showMenu: false }),
  reduxForm({ form: "DriversRadarSearch" }),
  formValues("search"),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplace: getMarketplace(state),
  })),
  withHandlers({
    handleSubmit: props => event => {
      props.handleSubmit(event);
      props.change("search", null);
      props.setState(fp.set("search", false));
    },
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = propsStream
      .distinctUntilKeyChanged("state", isEqualData)
      .do(props => {
        if (props.state.search && props.filter.getSearch()) {
          props.change("search", props.filter.getSearch());
        }
      })
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, props => ({
        ...props,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(fp.pick(["state", "filter", "search", "groupStatistics"])),
);

OrderDriversRadarSearch.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  handleSubmit: PropTypes.func,
  setState: PropTypes.func,
  search: PropTypes.string,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  onChangePage: PropTypes.func,
  onChangeDeliveryMode: PropTypes.func,
  groupStatistics: PropTypes.instanceOf(Immutable.Map),
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func,
  menuLayoutDrawer: PropTypes.any.isRequired,
  value: PropTypes.any,
  onClickDateFilter: PropTypes.func,
  isFoodDelivery: PropTypes.bool,
};

function OrderDriversRadarSearch(props) {
  const { state, classes, filter, getLocalisationMessage } = props;

  return (
    <FlexBox container={8} className={classes.root}>
      <FlexBox gutter={8} flex={true} element={<Paper />}>
        {props.menuLayoutDrawer({
          visibleMenu: state.showMenu,
          toggleMenu: () => props.setState(fp.update("showMenu", x => !x)),
        })}

        <FlexBox align={ALIGN_CENTER}>
          <IconButton
            onClick={() => props.setState(fp.update("showMenu", x => !x))}
          >
            <Menu />
          </IconButton>
        </FlexBox>
        {!state.search && (
          <FlexBox flex={true} align={ALIGN_CENTER} justify={JUSTIFY_CENTER}>
            <OrderDriversToggleElement
              value={filter.getBoolValue("is_delivery_mode")}
              onToggle={value =>
                props.onChangeDeliveryMode(
                  filter.setValue("is_delivery_mode", value),
                )
              }
            />
          </FlexBox>
        )}

        {!state.search && (
          <FlexBox align={ALIGN_CENTER}>
            <IconButton onClick={props.onClickDateFilter}>
              <DateRange />
            </IconButton>
          </FlexBox>
        )}

        {state.search && (
          <FlexBox flex={true} align={ALIGN_CENTER} justify={JUSTIFY_CENTER}>
            <FlexBox
              flex={true}
              gutter={8}
              element={
                <form autoComplete="off" onSubmit={props.handleSubmit} />
              }
            >
              <FlexBox flex={true}>
                <FormTextField
                  fullWidth={true}
                  className={classes.textField}
                  name="search"
                  hintText={getLocalisationMessage(
                    "search_for_a_driver_or_order",
                    "Search for a Driver or Order",
                  )}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
        <FlexBox align={ALIGN_CENTER}>
          <IconButton
            onClick={() => props.setState(fp.update("search", x => !x))}
          >
            <Search />
          </IconButton>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderDriversRadarSearch);
