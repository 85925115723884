import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { IconButton, MuiThemeProvider } from "@material-ui/core";
import { Delete, Create } from "@material-ui/icons";

import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";

const enhancer = compose(
  useSheet({
    iconGroup: {
      position: "absolute",
    },
    height: {
      height: "15px",
      width: "100%",
    },
    button: {
      height: "24px",
      width: "24px",
      border: 0,
      "& > div": {
        position: "absolute",
        height: "100%",
        width: "100%",
        left: 0,
        top: 0,
      },
    },
    editIcon: {
      left: 0,
      height: "15px",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
  }),
);

AreaInfoWindow.propTypes = {
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  label: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  muiTheme: PropTypes.object,
};

function AreaInfoWindow({ classes, ...props }) {
  return (
    <MuiThemeProvider>
      <FlexBox direction="column">
        <FlexBox>{props.label}</FlexBox>
        <FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_CENTER}>
            <div className={classes.height} />
            <div className={classes.iconGroup}>
              <IconButton
                tooltip={props.getLocalisationMessage("edit", "Edit")}
                className={classes.button}
                onClick={props.onEdit}
              >
                <Create className={classes.editIcon} />
              </IconButton>
              <IconButton
                tooltip={props.getLocalisationMessage("delete", "Delete")}
                className={classes.button}
                onClick={props.onDelete}
              >
                <Delete className={classes.editIcon} />
              </IconButton>
            </div>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </MuiThemeProvider>
  );
}

export default enhancer(AreaInfoWindow);
