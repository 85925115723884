import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createJob } from "../../api/admin/AdminJobsApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderJobForm from "../../components/orders-core/OrderJobForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    modal: {
      maxWidth: "450px",
      minWidth: "450px",
      minHeight: "auto",
    },
  }),
  connect(null, { showErrorMessage, showSuccessMessage }),
);

AdminOrderJobCreateDialogWrapper.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderJobCreateDialogWrapper(props) {
  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage("create_job", "Create Job")}
      onRequestClose={props.onRequestClose}
      paperClassName={props.classes.modal}
    >
      <FlexBox direction="column">
        <OrderJobForm
          initialValues={props.initialValues}
          onDismiss={props.onRequestClose}
          onSubmit={values => createJob(values).catch(ResponseError.throw)}
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_jobs_created",
                "Successfully Jobs Created",
              ),
            );
          }}
          getCustomerPredictions={getCustomerPredictions}
          getCachedCustomer={getCachedCustomer}
          getWarehousePredictions={getWarehousePredictions}
          getCachedWarehouse={getCachedWarehouse}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderJobCreateDialogWrapper);
