import React from "react";
import { Map } from "immutable";
// import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "../ui-core/Tabs";
import FlexBox from "../ui-core/FlexBox";
import { getMessages } from "../../reducers/LocalizationReducer";
import { YEARLY, MONTHLY } from "../../constants/SubscriptionPeriodTypes";
import { getMarketplace } from "../../api/shared/MarketplaceApi";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
    marketplace: getMarketplace(state),
  })),
  withRouter,
);

SubscriptionPeriodTabs.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  onChange: PropTypes.func,
  filter: PropTypes.instanceOf(Map),
  children: PropTypes.node,
};

function SubscriptionPeriodTabs(props) {
  const { i18n } = props;
  return (
    <FlexBox direction="column" style={{ width: "100%" }}>
      <Tabs
        width="auto"
        value={props.filter.get("period_type")}
        onChange={(e, v) => props.onChange(v)}
        tabs={[
          {
            label: i18n.get("monthly", "Monthly"),
            value: MONTHLY,
          },
          {
            label: i18n.get("yearly", "Yearly"),
            value: YEARLY,
          },
        ]}
      />

      {props.children}
    </FlexBox>
  );
}

export default enhancer(SubscriptionPeriodTabs);
