import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import { Button, CardActions, IconButton, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Archive as ContentArchive,
  Close as NavigationClose,
} from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";

import OrderSortingRuleDialog from "./OrderSortingRuleDialog";
import RuleList from "../rules/RuleList";
import Text from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import { pureComponent, renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import { getWarehouse2 } from "../../api/admin/AdminWarehouseApi";
import { Observable } from "rxjs";
import { get } from "lodash";

const getDefaultPreset = (warehouseId, postcodeName) =>
  fromJS([
    {
      code: "Local",
      group_by: ["rule_name"],
      name: "LOCAL",
      predicate: {
        args: [
          {
            value: {
              supplier: {
                id: 1,
              },
              to_jurisdiction: {
                id: 270,
              },
              to_postcode: postcodeName,
            },
          },
        ],
        fn: "matches",
      },
      status: "in_transit",
      warehouse: {
        id: warehouseId,
      },
      weight: 0,
    },

    {
      code: "global",
      count: 8,
      group_by: ["count", "courier_type", "rule_name"],
      name: "Xalqaro",
      predicate: {
        args: [
          {
            value: {
              supplier: {
                id: 1,
              },
              to_jurisdiction: {
                id: 246,
              },
            },
          },
        ],
        fn: "notMatches",
      },
      status: "prepared_for_transit",
      warehouse: {
        id: 957,
      },
      weight: 3,
    },
    {
      code: "tas",
      count: 8,
      group_by: ["rule_name", "count", "courier_type"],
      name: "TASH",
      predicate: {
        args: [
          {
            value: {
              supplier: {
                id: 1,
              },
              to_jurisdiction: {
                id: 270,
              },
            },
          },
        ],
        fn: "matches",
      },
      status: "prepared_for_transit",
      warehouse: {
        id: 606,
      },
      weight: 1,
    },
    {
      code: "uzb",
      count: 8,
      group_by: ["count", "courier_type", "rule_name"],
      name: "UZB",
      predicate: {
        args: [
          {
            value: {
              supplier: {
                id: 1,
              },
              to_jurisdiction: {
                id: 246,
              },
            },
          },
        ],
        fn: "matches",
      },
      status: "prepared_for_transit",
      warehouse: {
        id: 957,
      },
      weight: 2,
    },
  ]);

const enhancer = compose(
  connect(
    (state) => ({
      userWarehouseId: getUserWarehouseId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withTheme,
  renderIf("open"),
  useSheet({
    root: {
      maxWidth: "384px",
      minWidth: "384px",
    },
    scrollWrapper: { overflowY: "auto" },
    icons: {
      color: (props) => props.theme.palette.alternateTextColor,
    },
  }),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(
        fp.flow(fp.get("initialValues"), fp.toPlainObject, (initial) => ({
          rules: !initial.rules
            ? List()
            : initial.rules
                .toList()
                .sortBy((x) => x.get("weight"))
                .map((x, idx) => x.set("weight", idx)),
        })),
      );

    const warehouseStream = propsStream
      .filter((v) => v.userWarehouseId)
      .pluck("userWarehouseId")
      .distinctUntilChanged(isEqualData)
      .switchMap((userWarehouseId) =>
        getWarehouse2(userWarehouseId).catch(() => Observable.of({})),
      )
      .distinctUntilChanged(isEqualData)
      .map((response) => get(response, "data"));

    const onSubmit = (values, dispatch, props) =>
      props.actualOnSubmit({
        rules: values.rules.toMap().mapEntries(([, v]) => [v.get("code"), v]),
      });

    return propsStream.combineLatest(
      initialValuesStream,
      warehouseStream,
      (props, initialValues, warehouse) => ({
        ...props,
        initialValues,
        warehouse,
        actualOnSubmit: props.onSubmit,
        onSubmit: props.onSubmit && onSubmit,
      }),
    );
  }),
  reduxForm({ form: "OrderSortingRuleListDialog", enableReinitialize: true }),
  formValues("rules"),
  withState("state", "setState", { edit: null }),
  pureComponent(fp.pick(["submitting", "rules", "state"])),
);

OrderSortingRuleListDialog.propTypes = {
  classes: PropTypes.object,
  warehouse: PropTypes.object,
  rules: PropTypes.instanceOf(List),

  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  state: PropTypes.object,
  setState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getCachedSupplier: PropTypes.func.isRequired,
  getSupplierPredictions: PropTypes.func.isRequired,
  getCachedWarehouse: PropTypes.func.isRequired,
  getWarehousePredictions: PropTypes.func.isRequired,
  getCachedPostcode: PropTypes.func.isRequired,
  getPostcodePredictions: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  theme: PropTypes.object,
};

function OrderSortingRuleListDialog(props) {
  const { state, rules, classes, getLocalisationMessage, warehouse } = props;
  const warehouseId = get(warehouse, "id");
  const postcodes = get(warehouse, "postal_codes", []);
  const postcodeName = postcodes.length > 0 ? postcodes[0].name : "";
  if (state.edit === "new" || rules.has(state.edit)) {
    return (
      <OrderSortingRuleDialog
        open={true}
        onRequestClose={() => props.setState(fp.set("edit", null))}
        initialValues={
          state.edit === "new" ? {} : toCamelCase(rules.get(state.edit))
        }
        onSubmit={fp.flow(toSnakeCase, fromJS, (rule) => {
          if (state.edit === "new") {
            props.change("rules", rules.push(rule.set("weight", rules.size)));
          } else {
            props.change("rules", rules.set(state.edit, rule));
          }

          props.setState(fp.set("edit", null));
        })}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_order_sorting_rule_created",
              "Successfully Order Sorting Rule Created",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        getCachedSupplier={props.getCachedSupplier}
        getSupplierPredictions={props.getSupplierPredictions}
        getCachedWarehouse={props.getCachedWarehouse}
        getWarehousePredictions={props.getWarehousePredictions}
        getCachedPostcode={props.getCachedPostcode}
        getPostcodePredictions={props.getPostcodePredictions}
      />
    );
  }

  return (
    <ModalPaper
      title={getLocalisationMessage(
        "order_sorting_rules",
        "Order Sorting Rules",
      )}
      open={props.open}
      paperClassName={classes.root}
      leftIcon={
        <IconButton
          color="inherit"
          disableTouchRipple={true}
          style={{ cursor: "default" }}
          className={classes.icons}
        >
          <ContentArchive />
        </IconButton>
      }
      rightIcon={
        <IconButton
          color="inherit"
          onClick={props.onRequestClose}
          className={classes.icons}
        >
          <NavigationClose />
        </IconButton>
      }
      onRequestClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />

      {rules.isEmpty() ? (
        <FlexBox
          flex={true}
          justify="center"
          element={<Paper />}
          direction="column"
        >
          <FlexBox direction="column" align="center">
            <Text element="h4" type="align-center">
              {getLocalisationMessage(
                "you_havent_created",
                "You haven't created",
              )}
              <br />
              {getLocalisationMessage("any_rules_yet", "any rules yet")}
            </Text>

            <br />

            <CardActions>
              <Button onClick={() => props.setState(fp.set("edit", "new"))}>
                {" "}
                {getLocalisationMessage("create_new", "Create New")}{" "}
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{ color: "#fff" }}
                onClick={() =>
                  props.change(
                    "rules",
                    getDefaultPreset(warehouseId, postcodeName),
                  )
                }
              >
                {" "}
                {getLocalisationMessage("use_defaults", "Use Defaults")}{" "}
              </Button>
            </CardActions>
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox flex={true} element={<Paper />} direction="column">
          <FlexBox
            flex={true}
            direction="column"
            className={classes.scrollWrapper}
          >
            <RuleList
              ruleList={rules}
              onChange={(x) => props.change("rules", x)}
              onEditClick={(x) => props.setState(fp.set("edit", x))}
            />
          </FlexBox>

          <FlexBox element={<CardActions />} justify="flex-end">
            <Button onClick={() => props.setState(fp.set("edit", "new"))}>
              {getLocalisationMessage("add", "Add")}
            </Button>

            <Button
              hoverColor={props.theme.palette.primary2Color}
              backgroundColor={props.theme.palette.primary1Color}
              labelStyle={{ color: props.theme.palette.appBarTextColor }}
              onClick={props.handleSubmit}
            >
              {" "}
              {getLocalisationMessage("save", "Save")}{" "}
            </Button>
          </FlexBox>
        </FlexBox>
      )}
    </ModalPaper>
  );
}

export default enhancer(OrderSortingRuleListDialog);
