import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  getMarketplaceReceiveOrderFromWing,
  isCustomMarketplace,
} from "../../reducers/MarketplaceReducer";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { isEqualData } from "../../helpers/DataUtils";
import {
  BANDEROL,
  estimateRegistryCategory,
  estimateWarehouses,
  getDestinationJurisdiction,
  getRegistryStatusByShipmentType,
  isRegistryBag,
  LETTER,
  MIXED,
  POSTCARD,
  UZBEKISTAN,
} from "../../helpers/OrderOutboundSortingHelper";
import { Map, Set } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";
import OrderOutboundSortingVerifyOrdersDialog from "./OrderOutboundSortingVerifyOrdersDialog";
import {
  INSURED_BAG,
  POST_PACKET,
} from "../orders-core/BatchUpdateOrderDialog2";
import {
  getOrderSortingVerifiedOrders,
  updateSortingVerifiedOrders,
} from "../../reducers/OrderOutboundSortingReducer";

const enhancer = compose(
  renderIf("open"),
  connect(
    (state) => ({
      isMarketplaceCustom: isCustomMarketplace(state),
      isMarketplaceReceiveOrderFromWing:
        getMarketplaceReceiveOrderFromWing(state),
      defaultBatchUpdateStatuses:
        getMarketplaceDefaultBatchUpdateStatuses(state),
      scannedOrders: getOrderSortingVerifiedOrders(state),
    }),
    {
      updateSortingVerifiedOrders,
    },
  ),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .map(fp.pick(["selectedItems", "orders", "activeBin", "warehouse"]))
      .distinctUntilChanged(isEqualData)
      .map(({ orders, selectedItems, activeBin, warehouse }) => {
        const data = selectedItems.reduce(
          (acc, orderNumber) => {
            const order = orders.get(orderNumber, null);
            if (order) {
              const type = order.getIn(["info", "type"], null);

              // Destination Countries
              const jurisdictions = getDestinationJurisdiction(
                order.get("info", Map()),
              );
              if (jurisdictions && jurisdictions.length > 0) {
                if (!acc.countries.includes(fp.get("0.id", jurisdictions))) {
                  acc.countries.push(fp.get("0.id", jurisdictions));
                }
              }

              // Transportation Types
              const transportationType = order.getIn(
                ["info", "transportation_type"],
                null,
              );
              if (
                transportationType &&
                !acc.transportationTypes.includes(transportationType)
              ) {
                acc.transportationTypes.push(transportationType);
              }

              // Courier Types
              const courierType = type
                ? order.getIn(["info", "inner_shipment_type"], null)
                : order.getIn(["info", "package", "courier_type"], null);

              if (type) {
                if (order.getIn(["info", "category"], null) === INSURED_BAG)
                  acc.valued = true;
              } else if (
                courierType === BANDEROL &&
                order.getIn(["info", "package", "valuable"], false)
              )
                acc.valued = true;

              if (courierType && !acc.courierTypes.includes(courierType)) {
                acc.courierTypes.push(courierType);
              }
            }
            return acc;
          },
          {
            valued: false,
            courierTypes: [],
            countries: [],
            transportationTypes: [],
          },
        );

        const bag = isRegistryBag(data.courierTypes);
        const isLocalRule =
          activeBin.get("transitWarehouse", null) === warehouse;
        const orderStatus = getRegistryStatusByShipmentType(
          data.courierTypes,
          activeBin.get("transitStatus", null),
        );
        const isDomestic = data.countries.includes(UZBEKISTAN);

        let innerShipmentType = null;
        if (data.courierTypes.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          innerShipmentType = data.courierTypes[0];
        } else if (data.courierTypes.length > 1) {
          const isNotPK = data.courierTypes.filter(
            (item) => item !== LETTER && item !== BANDEROL && item !== POSTCARD,
          );
          if (isNotPK.length > 0) {
            innerShipmentType = MIXED;
          } else {
            innerShipmentType = LETTER;
          }
        }

        let values = {
          bag,
          orderStatus,
          isDomestic,
          innerShipmentType,
          privacy: PUBLIC,
          orderBarcodes: selectedItems.toArray(),
          onlyBarcode: true,
          weight: 0,
          transportationType:
            data.transportationTypes.length === 1
              ? data.transportationTypes[0]
              : null,
        };

        if (isDomestic) {
          if (activeBin.getIn(["selectedBin", "nextPostcode"], null)) {
            values.next_postcode = {
              name: activeBin.getIn(["selectedBin", "nextPostcode"], null),
            };
          }

          if (activeBin.getIn(["selectedBin", "nextJurisdiction"], null)) {
            values.next_jurisdiction = {
              id: activeBin.getIn(["selectedBin", "nextJurisdiction"], null),
            };
          }

          if (activeBin.get("binPostcode", null)) {
            values.to_postcode = {
              name: activeBin.get("binPostcode", null),
            };
          } else if (
            isLocalRule &&
            !activeBin.get("binJurisdiction", null) &&
            values.next_postcode
          ) {
            values.to_postcode = values.next_postcode;
          }

          if (activeBin.get("binJurisdiction", null)) {
            values.to_jurisdiction = {
              id: activeBin.get("binJurisdiction", null),
            };
          } else if (
            isLocalRule &&
            !activeBin.get("binPostcode", null) &&
            values.next_jurisdiction
          ) {
            values.to_jurisdiction = values.next_jurisdiction;
          }
        }

        if (
          !activeBin.getIn(["selectedBin", "nextPostcode"], null) &&
          !activeBin.getIn(["selectedBin", "nextJurisdiction"], null) &&
          activeBin.get("transitWarehouse", null)
        ) {
          const estimatedWarehouses = estimateWarehouses(
            activeBin,
            innerShipmentType,
            data.countries,
          );
          values = { ...values, ...estimatedWarehouses };
        }

        const category = data.valued
          ? INSURED_BAG
          : fp.get("to_warehouse.id", values) !== fp.get("warehouse.id", values)
          ? POST_PACKET
          : activeBin.get("group", null) ||
            estimateRegistryCategory(data.courierTypes);

        return {
          ...values,
          bag,
          orderStatus,
          isDomestic,
          category,
          innerShipmentType,
          onlyBarcode: true,
          privacy: PUBLIC,
          orderBarcodes: selectedItems.toArray(),
          transportationType:
            data.transportationTypes.length === 1
              ? data.transportationTypes[0]
              : null,
        };
      });

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
      }),
    );
  }),
);

AdminOutboundOrderSortingBatchUpdateDialogWrapper.propTypes = {
  warehouse: PropTypes.number,

  initialValues: PropTypes.object,

  selectedItems: PropTypes.instanceOf(Set),
  bins: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),
  activeBin: PropTypes.instanceOf(Map),
  scannedOrders: PropTypes.instanceOf(Map),

  onSubmit: PropTypes.func,
  onRequestClose: PropTypes.func,
  onConfirmUnknownOrder: PropTypes.func,
  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  updateSortingVerifiedOrders: PropTypes.func,
  isLoading: PropTypes.bool,
};

function AdminOutboundOrderSortingBatchUpdateDialogWrapper(props) {
  const { initialValues, activeBin, warehouse } = props;
  const label = activeBin && activeBin.getIn(["selectedBin", "label"], null);

  const scannedOrders =
    props.scannedOrders && label && props.scannedOrders.get(label, Set());

  return (
    <OrderOutboundSortingVerifyOrdersDialog
      open={true}
      warehouseId={warehouse}
      isLoading={props.isLoading}
      getCachedSupplier={props.getCachedSupplier}
      getSupplierPredictions={props.getSupplierPredictions}
      initialValues={{ ...initialValues, scannedOrders }}
      selectedOrders={props.selectedItems}
      selectedBin={props.activeBin.get("selectedBin", null)}
      activeBin={props.activeBin}
      orders={props.orders}
      bins={props.bins}
      onSubmit={props.onSubmit}
      onConfirmUnknownOrder={props.onConfirmUnknownOrder}
      onRequestClose={props.onRequestClose}
      onUpdateScannedOrders={(orders) => {
        if (label)
          props.updateSortingVerifiedOrders((x) => x.set(label, orders));
      }}
    />
  );
}

export default enhancer(AdminOutboundOrderSortingBatchUpdateDialogWrapper);
