import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { Button, Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import {
  isEqualData,
  isEqualDataIn,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatDateToUrl, safeParseDate } from "../../helpers/FormatUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrdersSummaryFilter } from "../../helpers/OrdersSummaryFilterMapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  clearFinanceSettlementQueue,
  getFinanceSettlementQueue,
  updateFinanceSettlementQueue,
} from "../../reducers/FinanceSettlementReducer";
import {
  CUSTOMER_ITEM_URL,
  ORDER_TRACK_URL,
} from "../../constants/AdminPathConstants";
import {
  calculateCodWithdrawTotal,
  createCODWithdraw,
  getWithdrawableOrders,
} from "../../api/admin/AdminFinanceApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import NavigationPrompt from "../../components/router/NavigationPrompt";
import CODWithdrawOrderList from "../../components/finance-core/CODWithdrawOrderList";
import CreateCODWithdrawDialog from "../../components/finance-core/CreateCODWithdrawDialog";
import OrdersSettlementFilterForm from "../../components/finance-core/OrdersSettlementFilterForm";
import Notification from "../../components/notifications/Notification";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_SETTLEABLE_CUSTOMERS_URL } from "../../../shared/constants/FileProxyControllerConstants";

const CREATE_COD_WITHDRAW_DIALOG_HASH = "#CCWD";

const getObjectId = fp.get("id");

const enhancer = compose(
  useSheet({
    orders: {
      flex: "1 1 0%",
      display: "flex",
      marginTop: "12px",
      "& > div": { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    },
    appBarRightAction: {
      marginTop: "5px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
    appBarRightActionButton: {
      color: "#fff",
      top: "-7px",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  connect(
    state => ({
      i18n: getMessages(state),
      selectedNumbers: getFinanceSettlementQueue(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      clearFinanceSettlementQueue,
      updateFinanceSettlementQueue,
    },
  ),
  getContext({
    replaceLocationHash: PropTypes.func,
    setLocationQuery: PropTypes.func,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRowSelect,
      stream: onRowSelectStream,
    } = createEventHandler();
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toOrdersSummaryFilter))
      .distinctUntilChanged(isEqualData);

    const formInitialValuesStream = filterStream
      .map((filter: DataListFilter) => ({
        customer: { id: filter.getIntegerValue("customer_id") },
        onlyWithWing: filter.getBoolValue("only_with_wing"),
        onlyWithSupplier: filter.getBoolValue("only_with_supplier"),
        fromDate: safeParseDate(filter.getValue("from_date")),
        toDate: safeParseDate(filter.getValue("to_date")),
      }))
      .distinctUntilChanged(isEqualData);

    const responseStream = filterStream
      .switchMap(filter =>
        filter.getValue("from_date") || filter.getValue("to_date")
          ? getWithdrawableOrders(filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error =>
                Observable.of({
                  error: error.reasonMessage || error.message,
                }),
              )
          : Observable.of({}),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              isLoading: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const sideEffectStream = Observable.merge(
      propsStream.take(1).do(props => props.clearFinanceSettlementQueue()),
      propsStream
        .distinctUntilChanged(
          isEqualDataIn(["location", "query", "customer_id"]),
        )
        .skip(1)
        .do(props => {
          onRowSelect(Set());
          props.clearFinanceSettlementQueue();
        }),
      onRowSelectStream
        .distinctUntilChanged(isEqualData)
        .withLatestFrom(propsStream)
        .do(([numbers, props]) => props.updateFinanceSettlementQueue(numbers)),
    ).startWith(null);

    return propsStream
      .combineLatest(
        filterStream,
        responseStream,
        formInitialValuesStream,
        sideEffectStream,
        (props, filter, response, formInitialValues) => ({
          ...props,
          filter,
          formInitialValues,
          onRowSelect,
          onRequestRefresh,
          list: response.get("list"),
          total: response.get("total"),
          listError: response.get("error"),
          isLoading: response.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminFinanceSettlementContainer.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  clearFinanceSettlementQueue: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  listError: PropTypes.string,
  onRequestRefresh: PropTypes.func,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  onRowSelect: PropTypes.func,
  selectedNumbers: PropTypes.instanceOf(Set),
  formInitialValues: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function AdminFinanceSettlementContainer(props) {
  const {
    sheet: { classes },
    location,
    i18n,
  } = props;
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      slug="finance_settlement"
      title={i18n.get("finance_settlement")}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          {Boolean(
            props.filter.getValue("from_date") &&
              props.filter.getValue("to_date"),
          ) && (
            <Button
              target="_blank"
              component="a"
              className={classes.appBarRightActionButton}
              href={updateQuery(
                CREATE_SETTLEABLE_CUSTOMERS_URL,
                props.filter.getDefinedValues(),
              )}
            >
              {i18n.get("generate_customer_list", "Generate Customer List")}
            </Button>
          )}

          <Toggle
            accent={true}
            label={i18n.get("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      <Redirect
        to={updateQuery(
          location,
          fp.set("to_date", formatDateToUrl(new Date())),
        )}
        when={
          !props.filter.getValue("from_date") &&
          !props.filter.getValue("to_date")
        }
      />

      <NavigationPrompt
        when={!props.selectedNumbers.isEmpty()}
        message={nextLocation =>
          location.pathname !== nextLocation.pathname ||
          location.query.customer_id !== nextLocation.query.customer_id
            ? i18n.get(
                "all_selections_will_be_lost_are_you_sure",
                "All selections will be lost, are you sure?",
              )
            : undefined
        }
      />

      <Notification
        uid="AdminFinanceSettlementContainer"
        type="error"
        open={Boolean(props.listError)}
      >
        {props.listError}
      </Notification>

      {location.hash === CREATE_COD_WITHDRAW_DIALOG_HASH && (
        <CreateCODWithdrawDialog
          open={true}
          onRequestClose={() => props.replaceLocationHash(null)}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          calculateCodWithdrawTotal={calculateCodWithdrawTotal}
          initialValues={{
            orderNumbers: props.selectedNumbers.toArray(),
            customer: { id: props.filter.getValue("customer_id") },
            fromDate: safeParseDate(props.filter.getValue("from_date")),
            toDate: safeParseDate(props.filter.getValue("to_date")),
          }}
          onSubmit={values =>
            createCODWithdraw(toSnakeCase(values), i18n).catch(
              ResponseError.throw,
            )
          }
          onSubmitSuccess={() => {
            props.onRequestRefresh();
            props.replaceLocationHash(null);
            props.clearFinanceSettlementQueue();
            props.showSuccessMessage(
              i18n.get(
                "cod_transfer_request_created",
                "COD Transfer Request Created",
              ),
            );
          }}
          onSubmitFailure={error => {
            props.onRequestRefresh();
            props.showErrorMessage(error);
          }}
        />
      )}

      <Card>
        <CardContent>
          <OrdersSettlementFilterForm
            getCachedCustomer={getCachedCustomer}
            getCustomerPredictions={getCustomerPredictions}
            initialValues={props.formInitialValues}
            onChange={(values, dispatch, formProps) => {
              let withWing = values.onlyWithWing;
              let withSupplier = values.onlyWithSupplier;

              if (formProps.dirty) {
                if (withWing && withSupplier) {
                  withWing = withWing !== props.formInitialValues.onlyWithWing;
                  withSupplier =
                    !withWing &&
                    withSupplier !== props.formInitialValues.onlyWithSupplier;
                }

                const nextFilter = props.filter.setValueMap({
                  page: 0,
                  only_with_wing: withWing,
                  only_with_supplier: withSupplier,
                  customer_id: getObjectId(values.customer),
                  from_date: formatDateToUrl(values.fromDate),
                  to_date: formatDateToUrl(values.toDate),
                });

                if (!props.filter.equals(nextFilter)) {
                  props.setLocationQueryFilter(nextFilter);
                }
              }
            }}
          />
        </CardContent>
      </Card>

      <Card style={{ marginTop: "1rem" }}>
        {!props.isLoading && (
          <CardContent>
            <div>
              {props.total} {i18n.get("orders_found", "Orders Found")}.{" "}
              {props.total === 0 &&
                props.filter.getValue("customer_id") > 0 && (
                  <Button
                    onClick={() =>
                      props.replaceLocationHash(CREATE_COD_WITHDRAW_DIALOG_HASH)
                    }
                  >
                    {i18n.get("create_transfer", "Create Transfer")}
                  </Button>
                )}
            </div>
          </CardContent>
        )}

        <CODWithdrawOrderList
          list={props.list}
          selectable={props.filter.getValue("customer_id") > 0}
          onRowSelect={props.onRowSelect}
          selectedNumbers={props.selectedNumbers}
          totalCount={props.total}
          isLoading={props.isLoading}
          filter={props.filter}
          maxSearchItems={Infinity}
          createCustomerHref={id => CUSTOMER_ITEM_URL + id}
          createOrderHref={id => `${ORDER_TRACK_URL}?view=${id}`}
          onFilterChange={filter => props.setLocationQueryFilter(filter)}
          altHeader={
            <div>
              <Button
                onClick={() =>
                  props.replaceLocationHash(CREATE_COD_WITHDRAW_DIALOG_HASH)
                }
              >
                {`${i18n.get("confirm_transfer", "Confirm Transfer")} (${
                  props.selectedNumbers.size
                })`}
              </Button>
              <Button
                onClick={() => {
                  props.onRowSelect(Set());
                  props.clearFinanceSettlementQueue();
                }}
              >
                {" "}
                {i18n.get("clear_queue", "Clear Queue")}{" "}
              </Button>
            </div>
          }
        />
      </Card>
    </AdminAppLayout>
  );
}

export default enhancer(AdminFinanceSettlementContainer);
