import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Remove as ContentRemove } from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import { createNotEmptyValidator } from "../../helpers/FormUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    root: {
      width: "100%",
      position: "relative",
    },
    ul: {
      width: "100%",
      margin: 0,
      padding: 0,
      position: "relative",
      listStyleType: "none",
    },
    li: {
      important: false,
      display: "flex",
      paddingBottom: "8px",
      listStyleType: "none",
    },
    buttonLi: {
      justifyContent: "center",
    },
    actions: {
      margin: "5px 0",
      padding: "4px",
      minWidth: "50%",
    },
    button: {
      textTransform: "capitalize",
      width: "100%",
      fontWeight: "normal",
      textDecoration: "underline",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
);

const AreaMultiAliasField = enhancer(props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <FlexBox align="center">
        <FieldArray
          {...props}
          name={props.name}
          props={{ i18n: props.i18n }}
          component={AreaMultiAlias}
        />
      </FlexBox>
    </div>
  );
});

AreaMultiAliasField.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

AreaMultiAlias.propTypes = {
  classes: PropTypes.object,
  fields: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function AreaMultiAlias(props) {
  const { classes, fields, i18n } = props;
  return (
    <ul className={classes.ul}>
      {fields.length > 0 &&
        fields.map((alias, index) => (
          <li key={index} className={classes.li}>
            <FlexBox style={{ width: "100%", alignItems: "center" }}>
              <FormTextField
                fullWidth={true}
                variant="outlined"
                name={`${alias}.name`}
                label={`${i18n.get("alias_name", "Alias Name")} *`}
                validate={createNotEmptyValidator(
                  i18n.get("add_value", "Add Value"),
                )}
              />
              <IconButton onClick={() => fields.remove(index)}>
                <ContentRemove />
              </IconButton>
            </FlexBox>
          </li>
        ))}
      <li className={`${classes.li} ${classes.buttonLi}`}>
        <FlexBox gutter={8} className={classes.actions}>
          <Button
            className={classes.button}
            size="small"
            onClick={() => fields.push({ name: "" })}
          >
            {i18n.get("add_alias", "Add Alias")}
          </Button>
        </FlexBox>
      </li>
    </ul>
  );
}

export default AreaMultiAliasField;
