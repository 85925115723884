import React from "react";
import { format as timeFormat } from "date-fns";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormTimeField from "../form/FormTimeField";
import FormSelectField from "../form/FormSelectField";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isValidDate } from "../../helpers/DateUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import addressTypes from "../../constants/AddressType";
import callTimingPermissionTypes from "../../constants/CallTimingPermissionTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  withHandlers({
    onSubmit: props => values =>
      props.onSubmit({
        ...values,
        startTime: timeFormat(values.startTime, "HH:mm:ss"),
        endTime: timeFormat(values.endTime, "HH:mm:ss"),
      }),
  }),
  reduxForm({
    form: "CallTimingsForm",
    enableReinitialize: true,

    validate: (
      {
        courierType,
        addressType,
        callTimingPermissionType,
        startTime,
        endTime,
      },
      props,
    ) => ({
      addressType:
        !addressType &&
        props.getLocalisationMessage(
          "please_select_address_type",
          "Please Select Address Type",
        ),
      courierType:
        !courierType &&
        props.getLocalisationMessage(
          "please_select_courier_type",
          "Please Select Courier Type",
        ),
      callTimingPermissionType:
        !callTimingPermissionType &&
        props.getLocalisationMessage(
          "please_select_call_timing_permission_type",
          "Please Select Call Timing Permission Type",
        ),
      startTime: isValidDate(startTime)
        ? null
        : props.getLocalisationMessage(
            "select_start_time",
            "Select Start Time",
          ),
      endTime: isValidDate(endTime)
        ? null
        : props.getLocalisationMessage("select_end_time", "Select End Time"),
    }),
  }),
);

CallTimingsForm.propTypes = {
  values: PropTypes.object,
  initialValues: PropTypes.object,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onDismiss: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function CallTimingsForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <FlexBox
      container={16}
      direction="column"
      element={<form id="CallTimingsForm" onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox flex={true} direction="column" justify="flex-start">
        <FlexBox flex={true}>
          <FormCourierTypeSelectField
            fullWidth={true}
            name="courierType"
            label={`${getLocalisationMessage(
              "courier_type",
              "Courier Type",
            )} *`}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormSelectField
            name="addressType"
            label={`${getLocalisationMessage(
              "address_type",
              "Address Type",
            )} *`}
            fullWidth={true}
            options={addressTypes}
            formatOption={v => getLocalisationMessage(v) || formatText(v)}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormSelectField
            name="callTimingPermissionType"
            label={`${getLocalisationMessage(
              "call_permission_type",
              "Call Permission Type",
            )} *`}
            fullWidth={true}
            options={callTimingPermissionTypes}
            formatOption={v => getLocalisationMessage(v) || formatText(v)}
          />
        </FlexBox>

        <FlexBox direction="row">
          <FlexBox flex={true}>
            <FormTimeField
              name="startTime"
              fullWidth={true}
              label={`${getLocalisationMessage("start_time", "Start Time")} *`}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormTimeField
              name="endTime"
              fullWidth={true}
              label={`${getLocalisationMessage("end_time", "End Time")} *`}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox justify="flex-end" style={{ marginTop: "20px" }}>
        {props.dirty ? (
          <Button onClick={props.reset}>
            {getLocalisationMessage("reset", "Reset")}
          </Button>
        ) : (
          Boolean(props.onDismiss) && (
            <Button onClick={props.onDismiss}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
          )
        )}

        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CallTimingsForm);
