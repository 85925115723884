import { OrderedSet } from "immutable";

export const MONDAY = { value: "MONDAY", shortName: "mo" };
export const TUESDAY = { value: "TUESDAY", shortName: "tu" };
export const WEDNESDAY = { value: "WEDNESDAY", shortName: "we" };
export const THURSDAY = { value: "THURSDAY", shortName: "th" };
export const FRIDAY = { value: "FRIDAY", shortName: "fr" };
export const SATURDAY = { value: "SATURDAY", shortName: "sa" };
export const SUNDAY = { value: "SUNDAY", shortName: "su" };

export default OrderedSet.of(
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
);
