import React from "react";
import { Map, List, OrderedMap } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextWithLink from "../router/TextWithLink";
import DataList, { DataListColumn } from "../data-list/DataList";
import MultiLineCell from "../data-list/MultiLineCell";
import { formatText, formatDateTime } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      statusMessages: getStatusLocalisation(state),
    };
  }),
  withTheme,
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
    buttonUpdate: {
      color: props => props.theme.palette.appBarTextColor,
      marginRight: "10px",
    },
  }),
);

BadAddressList.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
  location: PropTypes.object,
  totalCount: PropTypes.number,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  onUpdateAddress: PropTypes.func,
  onSetGoodAddress: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  overscanRowCount: PropTypes.number,
  rowCount: PropTypes.number,
  rowGetter: PropTypes.func,
  createOrderHref: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func.isRequired,
  statusMessages: PropTypes.instanceOf(Map),
  theme: PropTypes.object,
};

BadAddressList.defaultProps = {
  overscanRowCount: 6,
};

function BadAddressList(props) {
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      filter={props.filter}
      list={props.list}
      searchTypes={props.searchTypes}
      onFilterChange={props.onFilterChange}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.rowCount}
      rowGetter={props.rowGetter}
      cardActionIcons={props.cardActionIcons}
    >
      <DataListColumn
        width={96}
        label={getLocalisationMessage(
          "order_shipment_reference",
          "Order, Shipment, Reference",
        )}
        dataKey="order_number"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              <TextWithLink
                to={props.createOrderHref(
                  row.cellData.getIn(["order", "id"]),
                  row.cellData.get("version"),
                )}
              >
                {row.cellData.getIn(["order", "code"], NA)}
              </TextWithLink>
            }
            secondLine={
              <div>{row.cellData.getIn(["order", "reference_id"], NA)}</div>
            }
            thirdLine={
              <div>{row.cellData.getIn(["order", "shipment_id"], NA)}</div>
            }
          />
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("created_date", "Created Date")}
        disableSort={true}
        dataKey="created_date"
        justifyContent="center"
        cellRenderer={row => (
          <div>{formatDateTime(row.cellData.get("created_date", NA))}</div>
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("status", "Status")}
        disableSort={true}
        dataKey="status"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {formatOrderStatusCodeForLocalisation(
              row.cellData.getIn(["order", "status"]),
              getLocalisationMessage,
            )}
          </div>
        )}
      />

      <DataListColumn
        width={50}
        label={getLocalisationMessage("customer", "Customer")}
        disableSort={true}
        dataKey="customer"
        justifyContent="center"
        cellRenderer={row => (
          <div>{row.cellData.getIn(["order", "customer", "name"], NA)}</div>
        )}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("address", "Address")}
        disableSort={true}
        dataKey="address"
        cellRenderer={row => <div>{row.cellData.get("address") || NA}</div>}
      />

      <DataListColumn
        width={80}
        label={getLocalisationMessage(
          "location_source_type",
          "Location Source Type",
        )}
        dataKey="location_source_type"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {formatText(
              getLocalisationMessage(
                row.cellData.get("location_source_type", null),
                row.cellData.get("location_source_type", null),
              ),
            ) || NA}
          </div>
        )}
      />

      <DataListColumn
        width={80}
        label={getLocalisationMessage("accuracy_status", "Accuracy Status")}
        dataKey="accuracy_status"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {getLocalisationMessage(
              row.cellData.get("accuracy_status", null),
              formatText(row.cellData.get("accuracy_status", null)),
            ) || NA}
          </div>
        )}
      />

      {props.onSetGoodAddress && (
        <DataListColumn
          width={200}
          label=""
          disableSort={false}
          dataKey="action1"
          justifyContent="center"
          cellRenderer={row => (
            <Button
              style={{
                marginRight: "10px",
              }}
              variant="contained"
              color="primary"
              onClick={() =>
                props.onSetGoodAddress(
                  row.cellData.get("id"),
                  row.cellData.getIn(["order", "id"]),
                )
              }
            >
              {" "}
              {getLocalisationMessage(
                "it_is_good_address",
                "It is good address",
              )}{" "}
            </Button>
          )}
        />
      )}

      {props.onUpdateAddress && (
        <DataListColumn
          width={80}
          label=""
          disableSort={false}
          dataKey="action2"
          justifyContent="center"
          cellRenderer={row => (
            <Button
              style={{
                color: props.theme.palette.activeTextColor,
                marginRight: "10px",
              }}
              variant="contained"
              color="primary"
              onClick={() =>
                props.onUpdateAddress(
                  row.cellData.get("id"),
                  row.cellData.getIn(["order", "id"]),
                )
              }
            >
              {" "}
              {getLocalisationMessage("update", "Update")}{" "}
            </Button>
          )}
        />
      )}
    </DataList>
  );
}

export default enhancer(BadAddressList);
