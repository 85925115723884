import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FormDriverChips from "../form/FormDriverChips";
import FormSupplierChips from "../form/FormSupplierChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import {
  formatText,
  safeParseDate,
  formatDateToUrl,
} from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseString,
  parseIntString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ExceptionsStatuses } from "../../constants/ExceptionsConstants";

const startTime = startOfToday();
const endTime = endOfToday();

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    dateTime: {
      flex: "1 1 0%",
      "& > div:first-child": {
        width: "100%",
      },
    },
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
  }),
  withContext(
    {
      getSupplierPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
    },
    props => ({
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        return {
          driverIds: idsToObjectArray(x.getValue("driver_ids")),
          supplierIds: idsToObjectArray(x.getValue("supplier_ids")),
          status: parseString(fp.toLower(x.getValue("status"))),
          fromDate: safeParseDate(x.getValue("from_date")),
          toDate: safeParseDate(x.getValue("to_date")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            status: null,
            driver_ids: null,
            supplier_ids: null,
            from_date: formatDateToUrl(values.fromDate),
            to_date: formatDateToUrl(values.toDate),
          });

          if (!fp.isEmpty(values.status)) {
            filter.setValueMap({
              status: fp.toUpper(stringifyArray(values.status)),
            });
          }

          if (!fp.isEmpty(values.driverIds)) {
            filter.setValue(
              "driver_ids",
              stringifyArray(values.driverIds.map(getObjectId)),
            );
          }

          if (!fp.isEmpty(values.supplierIds)) {
            filter.setValue(
              "supplier_ids",
              stringifyArray(values.supplierIds.map(getObjectId)),
            );
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "OrderExceptionsFilterForm",
  }),
  formValues("fromDate", "toDate"),
);

OrderExceptionsFilterForm.propTypes = {
  classes: PropTypes.object,

  onFilterChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  getSupplierPredictions: PropTypes.func.isRequired,
  getCachedSupplier: PropTypes.func.isRequired,
  getCachedDriver: PropTypes.func.isRequired,
  getDriverPredictions: PropTypes.func.isRequired,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  initialValues: PropTypes.object,

  onDismiss: PropTypes.func,

  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function OrderExceptionsFilterForm(props) {
  const { classes } = props;

  return (
    <FlexBox container={8} element={<form onSubmit={props.handleSubmit} />}>
      <FlexBox
        gutter={8}
        style={{ padding: "1rem" }}
        flex={true}
        direction="column"
        element={<Card />}
      >
        <FlexBox flex={true} element={<CardContent />}>
          <FlexBox gutter={8} flex={true} direction="column">
            <FlexBox>
              <FormDriverChips
                name="driverIds"
                label={props.getLocalisationMessage("driver", "Driver")}
                fullWidth={true}
              />
            </FlexBox>

            <FlexBox>
              <FormSupplierChips
                name="supplierIds"
                label={props.getLocalisationMessage("supplier", "Supplier")}
                fullWidth={true}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {props.getLocalisationMessage("created_date", "Created Date")}
              </ListSubheader>

              <FlexBox gutter={8} flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      fullWidth={true}
                      name="fromDate"
                      hintText={props.getLocalisationMessage(
                        "from_date",
                        "From Date",
                      )}
                      initialTime={startTime}
                    />
                  </FlexBox>

                  {Boolean(props.fromDate) && (
                    <FlexBox>
                      <IconButton
                        onClick={() => props.change("fromDate", null)}
                      >
                        <Cancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>

                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      fullWidth={true}
                      name="toDate"
                      hintText={props.getLocalisationMessage(
                        "to_date",
                        "To Date",
                      )}
                      initialTime={endTime}
                    />
                  </FlexBox>

                  {Boolean(props.toDate) && (
                    <FlexBox>
                      <IconButton onClick={() => props.change("toDate", null)}>
                        <Cancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox>
              <FormChipAutoComplete
                name="status"
                label={props.getLocalisationMessage(
                  "exception_status",
                  "Exception Status",
                )}
                options={ExceptionsStatuses}
                formatOption={x =>
                  props.getLocalisationMessage(x) || formatText(x)
                }
                fullWidth={true}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox element={<CardActions />} justify="flex-end">
          <Button
            onClick={() => {
              fp.keys(props.initialValues).forEach(key => {
                props.change(key, null);
              });
            }}
          >
            {props.getLocalisationMessage("clear", "Clear")}
          </Button>

          {props.dirty ? (
            <Button onClick={props.reset}>
              {props.getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {props.getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button type="submit">
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderExceptionsFilterForm);
