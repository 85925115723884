import { Observable } from "rxjs";
import React from "react";
import Immutable, { fromJS, List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList as ContentFilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceReceiveOrderFromWing,
  isCustomMarketplace,
  marketplaceDefaultOrderCreationEnabled,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  COD_COLLECTED,
  COD_NOT_ASSIGNED,
  COD_PAID_TO_MERCHANT,
  COD_PENDING,
  COD_TO_BE_COLLECTED,
  COD_TRANSFERRED,
  COD_WITH_SAAS_BANK,
  COD_WITH_WING_BANK,
  COD_WITHDRAW_REQUESTED,
} from "../../constants/CODStatus";
import { getCalculateTotal, getOrderList } from "../../api/admin/AdminOrderApi";
import {
  batchUpdateCODStatus,
  calculateCodTransferTotal,
  createCODSaaSTransfer,
  createCODTransfer,
  getCODTotalCount,
  getTransferTypes,
} from "../../api/admin/AdminFinanceApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getMarketplace,
  getSaaSMarketplacesList,
} from "../../api/shared/MarketplaceApi";
import AdminOrderFilterWrapper from "../../wrappers/admin/AdminOrderFilterWrapper";
import AdminOrderEditDialogWrapper from "../../wrappers/admin/AdminOrderEditDialogWrapper";
import AdminOrderEditDialogWrapperV2 from "../../wrappers/admin/AdminOrderEditDialogWrapperV2";
import AdminFinanceOrderDetailsDialogWrapper from "../../wrappers/admin/AdminFinanceOrderDetailsDialogWrapper";
import AdminFinanceOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminFinanceOrderDetailsDialogWrapperV2";
import AdminOrderEditDialogWrapperV2WithoutMap from "../../wrappers/admin/AdminOrderEditDialogWrapperV2WithoutMap";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminFinanceOrderListTabs, {
  ALL_TAB_STATUS_LIST,
  statusCodes,
} from "../../components/admin/AdminFinanceOrderListTabs";
import Redirect from "../../components/router/Redirect";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OrderList from "../../components/orders-core/OrderList";
import SupplierBalanceDialog from "../../components/finance-core/SupplierBalanceDialog";
import OrderAgingReportDialog from "../../components/finance-core/OrderAgingReportDialog";
import CreateCODTransferDialog from "../../components/finance-core/CreateCODTransferDialog";
import OrderRevenueReportDialog from "../../components/finance-core/OrderRevenueReportDialog";
import BatchUpdateCODStatusDialog from "../../components/finance-core/BatchUpdateCODStatusDialog";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_FINANCE_ADMIN } from "../../../shared/constants/Authorities";
import {
  CREATE_FINANCE_ORDER_CSV_URL,
  CREATE_ORDER_CSV_URL,
  CREATE_ORDER_DAILY_DEDICATED_PICKUP_URL,
  CREATE_SUPPLIER_BALANCE_URL,
} from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const ORDER_FILTER_DIALOG_HASH = "#OFD";
const SUPPLIER_BALANCE_DIALOG_HASH = "#SBD";
const COD_STATUS_UPDATE_DIALOG_HASH = "#CSUD";
const CREATE_COD_TRANSFER_DIALOG_HASH = "#CCTD";
const ORDER_REVENUE_REPORT_DIALOG_HASH = "#ORRD";
const ORDER_COD_AGING_RECEIVABLE_REPORT_HASH = "#OCARR";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "5px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        isFinanceAdmin: hasRole(userRoles, ROLE_FINANCE_ADMIN),
        isMarketplaceReceiveOrderFromWing: getMarketplaceReceiveOrderFromWing(
          state,
        ),
        isCustomMarketplace: isCustomMarketplace(state),
        isDefaultOrderCreation: marketplaceDefaultOrderCreationEnabled(state),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const statusStream = propsStream
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const financeStatusCodes = OrderedMap().asMutable();
            const all = ALL_TAB_STATUS_LIST;

            statusCodes.reverse().forEach((value, key) => {
              switch (key) {
                case COD_TO_BE_COLLECTED:
                  financeStatusCodes.set(
                    [COD_TO_BE_COLLECTED, COD_NOT_ASSIGNED].join(","),
                    value,
                  );
                  break;
                case COD_COLLECTED:
                  financeStatusCodes.set(
                    [COD_COLLECTED, COD_TRANSFERRED].join(","),
                    value,
                  );
                  break;
                case COD_WITH_WING_BANK:
                  if (props.isMarketplaceReceiveOrderFromWing) {
                    financeStatusCodes.set(
                      [COD_WITH_WING_BANK, COD_WITHDRAW_REQUESTED].join(","),
                      props.isCustomMarketplace
                        ? props.getLocalisationMessage("with_wing", "With Wing")
                        : props.getLocalisationMessage(
                            "with_bank",
                            "With Bank",
                          ),
                    );
                  } else {
                    const index = all.indexOf(key);
                    if (index !== -1) {
                      all.splice(index, 1);
                    }
                  }
                  break;
                case COD_WITH_SAAS_BANK:
                  financeStatusCodes.set(
                    key,
                    props.isCustomMarketplace
                      ? props.getLocalisationMessage("with_bank", "With Bank")
                      : props.getLocalisationMessage("with_saas", "With SAAS"),
                  );
                  break;
                case COD_PAID_TO_MERCHANT:
                  financeStatusCodes.set(
                    key,
                    props.getLocalisationMessage("paid", "Paid"),
                  );
                  break;
                case COD_PENDING:
                  if (props.isMarketplaceReceiveOrderFromWing) {
                    financeStatusCodes.set(key, value);
                  } else {
                    const index = all.indexOf(key);
                    if (index !== -1) {
                      all.splice(index, 1);
                    }
                  }
                  break;
                default:
                  financeStatusCodes.set(key, value);
                  break;
              }
            });

            financeStatusCodes.set(
              ALL_TAB_STATUS_LIST.join(","),
              props.getLocalisationMessage("all", "All"),
            );

            return financeStatusCodes.reverse().sortBy(
              (a, b) => [a, b],
              ([, b1], [, b2]) => {
                if (
                  b1 === COD_WITH_WING_BANK &&
                  b2 === COD_WITH_SAAS_BANK &&
                  props.isCustomMarketplace
                )
                  return 1;
                return 0;
              },
            );
          })
          .distinctUntilChanged(isEqualData)
          .startWith(null);

        return propsStream
          .combineLatest(statusStream, (props, tabStatusesCodes) => ({
            ...props,
            tabStatusesCodes,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const {
          stream: onRowSelectStream,
          handler: onRowSelect,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toOrderFilter))
          .distinctUntilChanged(isEqualData);

        const listResponseStream = filterStream
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getOrderList(filter.setValue("financial", true))
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  count: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .do(() => onRowSelect(OrderedMap()));

        const totalCountsStream = filterStream
          .map((filter: DataListFilter) =>
            filter.setValueMap({ cod_status: null, financial: true }),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getCODTotalCount(filter)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(fp.flow(fp.get("payload.data"), fp.keyBy("cod_status"), fromJS));

        const marketplaceStream = getMarketplace()
          .takeLast(1)
          .catch(() => Observable.of({}))
          .map(
            fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS),
          );

        const selectedItemsStream = onRowSelectStream
          .distinctUntilChanged(isEqualData)
          .startWith(OrderedMap());

        return propsStream
          .combineLatest(
            filterStream,
            totalCountsStream,
            listResponseStream,
            marketplaceStream,
            selectedItemsStream,
            (
              props,
              filter,
              totalCounts,
              listResponse,
              marketplace,
              selectedItems,
            ) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              filter,
              totalCounts,
              marketplace,
              list: listResponse.get("list"),
              count: listResponse.get("count"),
              isLoading: listResponse.get("isLoading"),
              selectedItems,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const countStream = propsStream
          .map(fp.pick(["totalCounts", "marketplace"]))
          .distinctUntilChanged(isEqualData)
          .map(props =>
            Map().withMutations(map => {
              props.totalCounts.forEach((item, key) => {
                map.set(key, item);

                if (props.marketplace.get("custom")) {
                  map.setIn(
                    [key, "hideTab"],
                    key === COD_WITH_WING_BANK && item.get("total") === 0,
                  );
                } else {
                  map.setIn(
                    [key, "hideTab"],
                    key === COD_WITH_SAAS_BANK && item.get("total") === 0,
                  );
                }
              });
            }),
          )
          .distinctUntilChanged(isEqualData)
          .startWith(Immutable.Map());

        return propsStream.combineLatest(countStream, (props, counts) => ({
          ...props,
          counts,
        }));
      },
    ),
  ),
);

AdminFinanceOrderListContainer.propTypes = {
  classes: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  location: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  totalCounts: PropTypes.instanceOf(Map),
  counts: PropTypes.instanceOf(Map),
  list: PropTypes.instanceOf(List),
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  setLocationQuery: PropTypes.func,
  marketplace: PropTypes.instanceOf(Immutable.Map),

  isFinanceAdmin: PropTypes.bool,
  tabStatusesCodes: PropTypes.instanceOf(OrderedMap),

  isDefaultOrderCreation: PropTypes.bool,
  isCustomMarketplace: PropTypes.bool,
  isMarketplaceReceiveOrderFromWing: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminFinanceOrderListContainer(props) {
  const {
    location,
    location: { hash },
    classes,
    marketplace,
    tabStatusesCodes,
    getLocalisationMessage,
  } = props;

  const orderNumbers = props.selectedItems.toSet();
  const useSolr = props.filter.getBoolValue("use_solr");
  const isStatusHistoryFilter = props.filter.getBoolValue("search_history");

  return (
    <AdminAppLayout
      slug="finance_orders"
      title={getLocalisationMessage("domestic", "Domestic")}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      {tabStatusesCodes.size > 0 && (
        <Redirect
          when={!tabStatusesCodes.has(location.query.cod_status)}
          to={updateQuery(
            location,
            fp.set("cod_status", tabStatusesCodes.keySeq().first()),
          )}
        />
      )}

      <SupplierBalanceDialog
        open={hash === SUPPLIER_BALANCE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <OrderRevenueReportDialog
        open={hash === ORDER_REVENUE_REPORT_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      {hash === ORDER_COD_AGING_RECEIVABLE_REPORT_HASH &&
        props.isFinanceAdmin && (
          <OrderAgingReportDialog
            open={true}
            onRequestClose={() => props.replaceLocationHash(null)}
          />
        )}

      <AdminOrderEditDialogWrapper
        orderId={fp.toFinite(location.query.edit)}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.flow(fp.unset("edit"), fp.set("view", location.query.edit)),
          )
        }
      />

      <AdminFinanceOrderDetailsDialogWrapper
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(fp.unset("view"), fp.set("edit", location.query.view)),
          )
        }
      />

      <AdminFinanceOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view_v2)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view_v2", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view_v2"),
              fp.set(
                props.isDefaultOrderCreation ? "edit_default" : "edit_v2",
                location.query.view_v2,
              ),
            ),
          )
        }
        location={location}
      />

      <AdminOrderEditDialogWrapperV2
        orderId={fp.toFinite(location.query.edit_v2)}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("edit"),
              fp.unset("edit_v2"),
              fp.set("view_v2", location.query.edit_v2),
            ),
          )
        }
      />
      <AdminOrderEditDialogWrapperV2WithoutMap
        orderId={fp.toFinite(location.query.edit_default)}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("edit"),
              fp.unset("edit_v2"),
              fp.unset("edit_default"),
              fp.set("view_v2", location.query.edit_default),
            ),
          )
        }
      />

      {hash === CREATE_COD_TRANSFER_DIALOG_HASH && (
        <CreateCODTransferDialog
          open={true}
          marketplace={marketplace}
          getMarketplace={getSaaSMarketplacesList}
          initialValues={{ orderNumbers: orderNumbers.toArray() }}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          calculateCodTransferTotal={calculateCodTransferTotal}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmit={values =>
            marketplace.get("custom")
              ? createCODSaaSTransfer(
                  toSnakeCase(values),
                  getLocalisationMessage,
                ).catch(ResponseError.throw)
              : createCODTransfer(
                  toSnakeCase(values),
                  getLocalisationMessage,
                ).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestRefresh();
            props.replaceLocationHash(null);
            props.showSuccessMessage(
              getLocalisationMessage(
                "cod_transfer_request_created",
                "COD Transfer Request Created",
              ),
            );
          }}
          onSubmitFail={error => {
            props.onRequestRefresh();
            props.showErrorMessage(error);
          }}
        />
      )}

      <BatchUpdateCODStatusDialog
        filterStatus={props.filter.getValue("cod_status")}
        marketplace={marketplace}
        getTransferTypes={getTransferTypes}
        getCalculateTotal={getCalculateTotal}
        initialValues={{ orderNumbers: orderNumbers.toArray() }}
        open={hash === COD_STATUS_UPDATE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        onSubmit={values =>
          batchUpdateCODStatus(
            toSnakeCase(values),
            getLocalisationMessage,
          ).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage("cod_status_updated", "COD Status Updated"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <AdminOrderFilterWrapper
        open={hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        showCashier={true}
        showFinanceMarketplace={marketplace && marketplace.get("custom")}
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
      />

      {!isStatusHistoryFilter && (
        <AdminFinanceOrderListTabs
          getLocalisationMessage={getLocalisationMessage}
          location={location}
          totalCounts={props.counts}
          tabStatusesCodes={tabStatusesCodes}
        />
      )}

      <OrderList
        cardActionIcons={
          <div>
            <Link to={updateHash(location, ORDER_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={getLocalisationMessage("filter_orders", "Filter Orders")}
              >
                <IconButton>
                  <ContentFilterList />
                </IconButton>
              </Tooltip>
            </Link>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_FINANCE_ORDER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage(
                  "download_finance_csv",
                  "Download Finance CSV",
                )}
              </MenuItem>

              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_ORDER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>

              <MenuItem
                target="_blank"
                component="a"
                href={CREATE_ORDER_DAILY_DEDICATED_PICKUP_URL}
              >
                {getLocalisationMessage(
                  "dedicated_drivers_daily_pickup",
                  "Dedicated Drivers Daily Pickup",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(SUPPLIER_BALANCE_DIALOG_HASH)
                }
              >
                {getLocalisationMessage(
                  "supplier_past_due_balance",
                  "Supplier Past Due Balance",
                )}
              </MenuItem>

              <MenuItem
                target="_blank"
                component="a"
                href={CREATE_SUPPLIER_BALANCE_URL}
              >
                {getLocalisationMessage(
                  "supplier_current_due_balance",
                  "Supplier Current Due Balance",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(COD_STATUS_UPDATE_DIALOG_HASH)
                }
              >
                {getLocalisationMessage(
                  "batch_update_cod_status",
                  "Batch Update COD Status",
                )}
              </MenuItem>

              {/* <MenuItem */}
              {/*  primaryText={getLocalisationMessage( */}
              {/*    "create_cod_transfer_request", */}
              {/*    "Create COD Transfer Request", */}
              {/*  )} */}
              {/*  onClick={() => */}
              {/*    props.replaceLocationHash(CREATE_COD_TRANSFER_DIALOG_HASH) */}
              {/*  } */}
              {/* /> */}

              {false && (
                <MenuItem
                  onClick={() =>
                    props.replaceLocationHash(ORDER_REVENUE_REPORT_DIALOG_HASH)
                  }
                >
                  {getLocalisationMessage(
                    "download_revenue_report",
                    "Download Revenue Report",
                  )}
                </MenuItem>
              )}

              {props.isFinanceAdmin && (
                <MenuItem
                  onClick={() =>
                    props.replaceLocationHash(
                      ORDER_COD_AGING_RECEIVABLE_REPORT_HASH,
                    )
                  }
                >
                  {getLocalisationMessage(
                    "cod_aging_receivable_report",
                    "COD Aging Receivable Report",
                  )}
                </MenuItem>
              )}
            </MenuButtonMore>
          </div>
        }
        withAttempts={false}
        withPickupTime={false}
        withLastDriver={false}
        withCreatedTime={false}
        withEstDeliveryTime={false}
        withWarehouse={false}
        withLocation={false}
        withService={false}
        withAmount={false}
        withDropOffCollectible={true}
        withDeliveredBy={true}
        withPickupCollectible={true}
        withPickedUpBy={true}
        enableCheckbox={
          props.filter.getValue("cod_status") !== COD_PAID_TO_MERCHANT
        }
        createOrderHref={(id, version) =>
          updateQuery(location, fp.set(version === 2 ? "view_v2" : "view", id))
        }
        list={props.list}
        totalCount={props.count}
        isLoading={props.isLoading}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        selectedItems={props.selectedItems}
        onRowSelect={props.onRowSelect}
        altHeader={
          <div>
            {false && (
              <Button
                onClick={() =>
                  props.replaceLocationHash(CREATE_COD_TRANSFER_DIALOG_HASH)
                }
              >
                {getLocalisationMessage(
                  "create_cod_transfer_request",
                  "Create COD Transfer Request",
                )}
              </Button>
            )}
            <Button
              onClick={() =>
                props.replaceLocationHash(COD_STATUS_UPDATE_DIALOG_HASH)
              }
            >
              {getLocalisationMessage(
                "batch_update_cod_status",
                "Batch Update COD Status",
              )}
            </Button>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminFinanceOrderListContainer);
