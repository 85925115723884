import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import RcSlider from "rc-slider";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
  useSheet({
    slider: {},
    priceInfoBox: {
      "&  a:hover": {
        textDecoration: "none",
      },
    },
    priceInfoLink: {},

    priceBoxOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(255,255,255,0.6)",
    },
  }),
);

SubscriptionPlanSlider.propTypes = {
  tabs: PropTypes.instanceOf(List).isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired,
  state: PropTypes.object,
  setState: PropTypes.func,
  classes: PropTypes.object,
};

function SubscriptionPlanSlider(props) {
  const tabsCount = props.tabs.count();
  return (
    <div
      ref={elem => setHorizontalPadding(elem, tabsCount)}
      style={{
        position: "relative",
        padding: `25px 5.7%`,
        minWidth: props.minWidth,
      }}
    >
      <RcSlider
        min={0}
        step={1}
        marks={getMarks(tabsCount)}
        value={(props.selectedIndex > 0 && props.selectedIndex) || 0}
        max={Math.max(1, tabsCount - 1)}
        onChange={index => {
          const tab = props.tabs.get(index);
          if (!tab.get("disabled")) {
            props.setLocationQuery(
              fp.flow(fp.set("tab", props.tabs.get(index).get("value"))),
            );
          }
        }}
      />
    </div>
  );
}

export default enhancer(SubscriptionPlanSlider);

function getMarks(tabsCount) {
  const obj = {};
  Array.from(Array(tabsCount)).forEach((item, index) => {
    obj[index] = { label: "" };
  });
  return obj;
}

function setHorizontalPadding(elem, tabsCount) {
  const tempEl = elem;
  if (tempEl) {
    const horizontalPadding = tempEl.offsetWidth / tabsCount / 2;
    tempEl.style.paddingLeft = `${horizontalPadding}px`;
    tempEl.style.paddingRight = `${horizontalPadding}px`;
  }
}
