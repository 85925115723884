import { Observable } from "rxjs";
import React from "react";
import { isAfter, isSameDay } from "date-fns";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import ButtonForm from "../ui-core/ButtonForm";
import PageLoading from "../ui-core/PageLoading";
import PriceWrapper from "../ui-core/PriceWrapper";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { isValidDate, isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CODTransferType from "../../constants/CODTransferType";
import { CREATE_COD_WITHDRAW_RECONCILE_STATEMENT_POST_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { withTheme } from "@material-ui/core/styles";

const validateFromDate = (fromDate, toDate, getLocalisationMessage) => {
  if (!isValidDate(fromDate)) {
    return getLocalisationMessage("select_start_date", "Select Start Date.");
  }

  if (
    isValidDate(toDate) &&
    !isSameDay(fromDate, toDate) &&
    isAfter(fromDate, toDate)
  ) {
    return getLocalisationMessage(
      "start_date_must_be_before_end_date",
      "Start Date must be before End Date.",
    );
  }

  return null;
};

const validateToDate = (fromDate, toDate, getLocalisationMessage) => {
  if (!isValidDate(toDate)) {
    return getLocalisationMessage("select_end_date", "Select End Date.");
  }

  if (
    isValidDate(toDate) &&
    !isSameDay(fromDate, toDate) &&
    isAfter(fromDate, toDate)
  ) {
    return getLocalisationMessage(
      "end_date_must_be_after_start_date",
      "End Date must be after Start Date.",
    );
  }

  return null;
};

const isValidTransferAmount = fp.overSome([
  fp.isEmpty,
  fp.flow(fp.toNumber, fp.overEvery([fp.isFinite, fp.lte(0)])),
]);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  withTheme,
  useSheet({
    getStatement: { float: "right" },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  reduxForm({
    form: "CreateCODWithdrawDialog",
    validate: (values, props) => ({
      transferAmount:
        !isValidTransferAmount(values.transferAmount) &&
        props.getLocalisationMessage(
          "transfer_amount_must_be_a_positive_finite_number",
          "Transfer amount must be a positive finite number.",
        ),
      fromDate: validateFromDate(
        values.fromDate,
        values.toDate,
        props.getLocalisationMessage,
      ),
      toDate: validateToDate(
        values.fromDate,
        values.toDate,
        props.getLocalisationMessage,
      ),
      note:
        fp.isEmpty(values.note) &&
        props.getLocalisationMessage("add_withdraw_note", "Add Withdraw Note"),
      type:
        fp.isEmpty(values.type) &&
        props.getLocalisationMessage(
          "select_withdraw_type",
          "Select Withdraw Type",
        ),
      orderNumbers:
        fp.isEmpty(values.orderNumbers) &&
        props.getLocalisationMessage("add_orders", "Add Orders"),
      customer:
        props.getCachedCustomer &&
        !isValidObjectId(values.customer) &&
        props.getLocalisationMessage("select_customer", "Select Customer"),
    }),
  }),
  formValues({
    customer: "customer",
    processDate: "processDate",
    orderNumbers: "orderNumbers",
  }),
  mapPropsStream(propsStream => {
    const totalAmountStream = propsStream
      .map(props => ({
        values: {
          orderNumbers: fp.toArray(props.orderNumbers),
          customerId: isValidObjectId(props.customer) && props.customer.id,
        },
        calculateCodWithdrawTotal: props.calculateCodWithdrawTotal,
      }))
      .distinctUntilKeyChanged("values", isEqualData)
      .switchMap(props =>
        !props.values.customerId || !props.values.orderNumbers.length
          ? Observable.of(null)
          : props
              .calculateCodWithdrawTotal({
                order_numbers: props.values.orderNumbers,
                customer_id: props.values.customerId,
              })
              .catch(() => Observable.of(null)),
      )
      .map(fp.flow(fp.get("data"), fromJS))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      totalAmountStream,
      (props, totalAmount) => ({ ...props, totalAmount }),
    );
  }),
  pureComponent(
    fp.pick([
      "customer",
      "submitting",
      "processDate",
      "totalAmount",
      "orderNumbers",
    ]),
  ),
);

CreateCODWithdrawDialog.propTypes = {
  classes: PropTypes.object,

  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  customer: PropTypes.object,
  orderNumbers: PropTypes.array,
  processDate: PropTypes.instanceOf(Date),

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  totalAmount: PropTypes.instanceOf(Map),
  calculateCodWithdrawTotal: PropTypes.func.isRequired,

  getCachedCustomer: PropTypes.func,
  getCustomerPredictions: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CreateCODWithdrawDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      modal={true}
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle className={classes.dialogTitle}>
        {props.orderNumbers.length}{" "}
        {getLocalisationMessage(
          "orders_for_transfer_approval_total_amount",
          "orders for transfer approval. Total Amount",
        )}
        :{" "}
        {props.totalAmount ? (
          <PriceWrapper
            price={props.totalAmount.get("total")}
            code={props.totalAmount.getIn(["currency", "code"])}
          />
        ) : (
          getLocalisationMessage("calculating", "Calculating")
        )}
        {Boolean(
          isValidObjectId(props.customer) && props.orderNumbers.length > 0,
        ) && (
          <ButtonForm
            url={CREATE_COD_WITHDRAW_RECONCILE_STATEMENT_POST_URL}
            label={getLocalisationMessage("get_statement", "Get Statement")}
            target="_blank"
            containerElement="a"
            className={classes.getStatement}
            params={{
              customer_id: props.customer.id,
              orderNumbers: props.orderNumbers.join(","),
            }}
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <FlexBox container={24}>
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox>
                <FormChipInput
                  name="orderNumbers"
                  focus={true}
                  fullWidth={true}
                  label={getLocalisationMessage(
                    "enter_order_numbers",
                    "Enter Order Numbers",
                  )}
                />
              </FlexBox>

              {Boolean(props.getCachedCustomer) && (
                <FlexBox>
                  <FormCustomerAutoComplete
                    name="customer"
                    fullWidth={true}
                    label={getLocalisationMessage("customer", "Customer")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                </FlexBox>
              )}

              <FlexBox>
                <FlexBox flex={true}>
                  <FlexBox flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormDateField
                        name="processDate"
                        fullWidth={true}
                        label={getLocalisationMessage(
                          "process_date",
                          "Process Date",
                        )}
                      />
                    </FlexBox>

                    {props.processDate && (
                      <FlexBox align="center">
                        <IconButton
                          onClick={() => props.change("processDate", null)}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormDateField
                    name="fromDate"
                    fullWidth={true}
                    label={getLocalisationMessage("from_date", "From Date")}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormDateField
                    name="toDate"
                    fullWidth={true}
                    label={getLocalisationMessage("to_date", "To Date")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="transferAmount"
                    fullWidth={true}
                    label={getLocalisationMessage(
                      "transfer_amount",
                      "Transfer Amount",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true}>
                  <FormSelectField
                    name="type"
                    autoWidth={true}
                    fullWidth={true}
                    options={CODTransferType}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    label={getLocalisationMessage(
                      "cod_withdraw_type",
                      "COD Withdraw Type",
                    )}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox>
                <FormTextField
                  name="note"
                  fullWidth={true}
                  multiLine={true}
                  label={getLocalisationMessage(
                    "cod_withdraw_note",
                    "COD Withdraw Note",
                  )}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {" "}
          {getLocalisationMessage("dismiss", "Dismiss")}{" "}
        </Button>
        <Button onClick={props.handleSubmit}>
          {" "}
          {getLocalisationMessage("submit", "Submit")}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CreateCODWithdrawDialog);
