import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { updateRecipientPostcode } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import BatchRedirectOrdersDialog from "../../components/orders-core/BatchRedirectOrdersDialog";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";
import { getSortingBatchUpdateItem } from "../../api/v2/admin/AdminOrderSortingApi";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

OfflineBatchRedirectOrdersFormDialogWrapper.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  filter: PropTypes.object,
  isRedirected: PropTypes.bool,
  selectedOrderBarcodes: PropTypes.array,
  i18n: PropTypes.instanceOf(Map),
};

function OfflineBatchRedirectOrdersFormDialogWrapper(props) {
  const [barcodes, setBarcodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      if (
        props.filter.service_type_id ||
        props.filter.bean ||
        props.selectedOrderBarcodes.length
      ) {
        setIsLoading(true);
        getSortingBatchUpdateItem({
          ...props.filter,
          type: "ORDER",
          barcodes: props.selectedOrderBarcodes,
        })
          .then(res => {
            const orderBarcodes = [];
            res.items.map(item => {
              orderBarcodes.push(item.barcode);
              return item;
            });
            setBarcodes(orderBarcodes);
            setTimeout(() => setIsLoading(false), 1000);
          })
          .catch(() => setIsLoading(false));
      }
    }
  }, [props.filter, props.selectedOrderBarcodes]);

  return (
    <BatchRedirectOrdersDialog
      isLoading={isLoading}
      open={props.open}
      initialValues={{
        orderBarcodes: barcodes,
        is_redirected: props.isRedirected,
      }}
      onRequestClose={() => {
        props.onRequestClose();
      }}
      onSubmit={values =>
        updateRecipientPostcode(values).catch(ResponseError.throw)
      }
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      onSubmitSuccess={() => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess();
      }}
    />
  );
}

export default enhancer(OfflineBatchRedirectOrdersFormDialogWrapper);
