import React, { useContext, useState } from "react";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
  withHandlers,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import FormWarehouseDialog from "../../../components/form/FormWarehouseDialog";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import { Map, Set } from "immutable";
import {
  addBatchSortingTaskOrderNumber,
  clearOrderSortingBatchOrders,
  getOrderSortingTask,
  getOrderSortingTaskBatchRootOrder,
  updateCursorOrder,
  updateSortingTask,
} from "../../../reducers/OrderInboundSortingReducer";
import {
  mergeSideEffectStreams,
  pipeStreams,
} from "../../../helpers/StreamUtils";
import { OrderBinValidationDB } from "../../../firebase/OrderBinValidationDB";
import OrderSortingBinValidationForm from "../../../components/order-inbound-sorting/OrderSortingBinValidationForm";
import { Card, CardContent, CardHeader, MenuItem } from "@material-ui/core";
import { Observable } from "rxjs";
import { getValue, isEqualData, toJS } from "../../../helpers/DataUtils";
import FlexBox, {
  JUSTIFY_SPACE_AROUND,
} from "../../../components/ui-core/FlexBox";
import { pureComponent } from "../../../helpers/HOCUtils";
import fp from "lodash/fp";
import OrderSortingBinDetailsCard from "../../../components/order-inbound-sorting/OrderSortingBinDetailsCard";
import OrderSortingBinHeirarchyCard from "../../../components/order-inbound-sorting/OrderSortingBinHeirarchyCard";
import FirebaseOfflineDialog from "../../../components/firebase/FirebaseOfflineDialog";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import ConfirmDialog from "../../../components/deprecated/ConfirmDialog";
import {
  batchAsyncUpdateOrder,
  batchBarcodesStatusUpdate,
  completeBatch,
  getAsyncBarcode,
  getBarcode,
  getBatchChildren,
  getChildParcels,
  updateRegistryStatusSorting,
} from "../../../api/admin/AdminOrderApi";
import { mapObjectResponseStream } from "../../../helpers/ApiUtils";
import ResponseError from "../../../helpers/ResponseError";
import {
  IN_SORTING_FACILITY,
  LOST_OR_DAMAGED,
  MISROUTED,
  PREPARED_FOR_TRANSIT,
  SENT_TO_CUSTOMS,
} from "../../../constants/OrderStatusCodes";
import { green, grey, orange, red } from "@material-ui/core/colors";
import { CheckCircle, HourglassEmpty, ReportProblem } from "@material-ui/icons";
import { OrderSortingDB } from "../../../firebase/OrderSortingDB";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import OrderInboundSortingVerifyOrdersDialog from "../../../components/order-inbound-sorting/OrderInboundSortingVerifyOrdersDialog";
import {
  getUserWarehouse,
  getUserWarehouseId,
  getUserWarehousesIds,
  hasUserPermission,
} from "../../../reducers/ProfileReducer";
import {
  getDestinationCountry,
  isParcelServiceType,
  validateUserWarehouse,
} from "../../../helpers/OrderSortingHelper";
import _ from "lodash";
import { CONSOLIDATED, CONTAINER, SIMPLE } from "../../../constants/OrderType";
import { GlobalContext } from "../../shared/ClientApp";
import cx from "classnames";
import { SUCCESS } from "../../../components/orders-core/AdminPostForm";
import {
  AIR,
  SIMPLE as TRANSPORT_SIMPLE,
  TERRESTRIAL,
} from "../../../constants/TransportationType";
import { PUBLIC } from "../../../constants/NotePrivacyTypes";
import AdminBatchUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { INSURED_BAG } from "../../../components/order-outbound-sorting/CreateOrderRegistryDialog";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  bottomSpace: {
    marginBottom: 8,
    minHeight: 230,
    "@media (min-width: 401px) and (max-width: 991px)": {
      minHeight: 350,
    },
    "@media (max-width: 400px)": {
      minHeight: 370,
    },
  },
  iconsWrapper: {
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  card: {
    padding: "15px",
    "@media (max-width: 991px)": {
      marginBottom: 15,
      flex: "none",
    },
  },
  cardLeft: {
    marginRight: 4,
    "@media (max-width: 991px)": {
      marginRight: -8,
    },
  },
  cardRight: {
    marginLeft: 4,
    "@media (max-width: 991px)": {
      marginLeft: -8,
    },
  },
  overallInfo: {
    "@media (max-width: 991px)": {
      flexDirection: "column",
      "& div > span": {
        display: "none",
      },
    },
  },
  content: {
    justifyContent: "space-around",
    "@media (max-width: 991px)": {
      justifyContent: "flex-start",
    },
  },
}));

// eslint-disable-next-line no-unused-vars
export const shouldOpen = (order, warehouseId) => {
  if (order.get("type", null) === "CONTAINER") return true;

  if (isParcelServiceType(order.get("inner_shipment_type", null))) {
    const transportType = order.get("transportation_type", null);
    const category = fp.toLower(order.get("category", null));
    const countryCode = getDestinationCountry(order);

    if (
      transportType === TERRESTRIAL &&
      category !== INSURED_BAG &&
      countryCode === "UZ"
    ) {
      return true;
    }
  }

  // if (
  //   order.get("inner_shipment_type", null) === PARCEL &&
  //   order.get("transportation_type", null) === TERRESTRIAL
  // )
  //   return true;

  const toWarehouseId = order.getIn(["to_warehouse", "id"]);

  if (toWarehouseId) return toWarehouseId === warehouseId;

  return order.getIn(["warehouse", "id"]) === warehouseId;
};

const changeCursorOrder = ({
  db,
  id,
  cursor,
  assignToWarehouse,
  getLocalisationMessage,
  ...props
}) =>
  Observable.defer(() => db.getBatchQueue())
    .take(1)
    .switchMap(queueOrders => {
      // let taskStream = db.removeTask(id);

      if (queueOrders.size > 0) {
        const firstQueue = queueOrders
          .keySeq()
          .toSet()
          .first();
        props.updateCursorOrder(firstQueue);

        props.showSuccessMessage(
          `${cursor} ${getLocalisationMessage(
            "has_been_finished_moved_to",
            "has been finished. Moved to",
          )} ${firstQueue}`,
        );

        // if (assignToWarehouse) {
        //   return Observable.merge(
        //     db.updateBatchStatus(
        //       cursor,
        //       {
        //         warehouse_id: props.warehouseId,
        //         status: IN_SORTING_FACILITY,
        //       },
        //       false,
        //     ),
        //     db.removeTask(id),
        //     db.removeBatchQueue(firstQueue),
        //   );
        // }

        return Observable.merge(
          db.removeTask(id),
          db.removeBatchQueue(firstQueue),
        );
      }

      props.showSuccessMessage(
        getLocalisationMessage(
          "the_validation_process_has_been_finished",
          "The Validation Process has been finished.",
        ),
      );
      props.updateCursorOrder(null);

      // if (assignToWarehouse) {
      //   return Observable.merge(
      //     db.removeTask(id),
      //     db.updateBatchStatus(
      //       cursor,
      //       {
      //         warehouse_id: props.warehouseId,
      //         status: IN_SORTING_FACILITY,
      //       },
      //       false,
      //     ),
      //   );
      // }

      return db.removeTask(id);
    });

const generatedStatus = order => {
  if (fp.get("wrong_location", order)) return MISROUTED;

  if (!fp.get("scanned", order)) return LOST_OR_DAMAGED;

  if (!fp.get("info.type", order)) return IN_SORTING_FACILITY;

  return PREPARED_FOR_TRANSIT;
};

const forceCompleteTask = props => {
  const { db, id, task, sortingDB } = props;

  const order = fp.get("forceComplete.details.order", task);
  const assignToWarehouse = fp.get("forceComplete.assignToWarehouse", task);
  const statuses = Map().asMutable();

  if (!assignToWarehouse)
    return changeCursorOrder({
      ...props,
      db,
      id,
      cursor: order.number,
      assignToWarehouse,
    });

  const children = fp.get("children", order);
  const sortedShipments = {};

  if (children) {
    children.forEach(child => {
      const status = generatedStatus(child);

      if (status) {
        if (!statuses.has(status)) {
          statuses.set(
            status,
            Map({
              status,
              barcodes: Set(),
              ids: Set(),
            }),
          );
        }

        if (status === IN_SORTING_FACILITY) {
          sortedShipments[`${child.number}/number`] = fp.get(
            "info.barcode",
            child,
          );
        }

        statuses.updateIn([status, "barcodes"], x => x.add(child.number));
        statuses.updateIn([status, "ids"], x => x.add(child.id));
      }
    });
  }

  const statusesArray = statuses
    .asMutable()
    .valueSeq()
    .toSet();

  return Observable.defer(() =>
    statusesArray.size > 0
      ? batchBarcodesStatusUpdate(statusesArray.toJS()).catch(err =>
          Observable.of(err),
        )
      : Observable.of(null),
  )
    .switchMap(response => {
      if (!fp.isError(response) && sortedShipments) {
        return Observable.merge(sortingDB.batchUpdateOrders(sortedShipments));
      }

      return Observable.of(null);
    })
    .switchMap(() =>
      changeCursorOrder({
        ...props,
        db,
        id,
        assignToWarehouse,
        cursor: order.number,
      }),
    )
    .catch(() => db.removeTask(id));
};

const shipmentStatusUpdate = props => {
  const { db, id, task } = props;

  // return Observable.defer(() =>
  //   batchUpdateOrderWarehouse(task.shipment_status_update),
  // )
  //   .switchMap(() => db.removeTask(id))
  //   .catch(() => db.removeTask(id));

  return Observable.defer(() =>
    batchBarcodesStatusUpdate([
      {
        status: fp.get("order_status", task.shipment_status_update),
        barcodes: fp.get("order_barcodes", task.shipment_status_update),
      },
    ]),
  )
    .switchMap(() => db.removeTask(id))
    .catch(() => db.removeTask(id));
};

const reScanOrders = props => {
  const { db, id, task, orderNumber } = props;

  const expected = fp.get("shipment_retry_barcode.expected", task);
  const assignToWarehouse = fp.get("shipment_retry_barcode.assign", task);

  return Observable.defer(() =>
    props.scanBarcodes({
      orderNumbers: [orderNumber],
      expected,
      assignToWarehouse,
    }),
  )
    .switchMap(() => db.removeTask(id))
    .catch(() => db.removeTask(id));
};

const refreshOrder = props => {
  const { db, id, orderNumber } = props;

  return Observable.defer(() => getAsyncBarcode(orderNumber))
    .switchMap(payload => {
      if (fp.get("status", payload) === "success") {
        const data = fp.get("data", payload);
        if (fp.isObject(data)) {
          const values = {};
          values[`${orderNumber}/info`] = data;
          return Observable.merge(
            db.updateBatchOrders(values),
            db.removeTask(id),
          );
        }
      }
      return db.removeTask(id);
    })
    .catch(() => db.removeTask(id));
};

const completeBatchUpdate = props => {
  const { db, id, task } = props;

  return Observable.defer(() =>
    completeBatch({ batch_ids: task.complete_batch_update }),
  )
    .switchMap(() => db.removeTask(id))
    .catch(() => db.removeTask(id));
};

const batchStatusUpdate = props => {
  const { db, id, task, orderNumber } = props;

  const body = task.registry_status_update;

  return Observable.defer(() =>
    updateRegistryStatusSorting({
      items: [{ ...body, barcodes: [orderNumber] }],
    }),
  )
    .switchMap(() => Observable.merge(db.removeTask(id)))
    .catch(() => db.removeTask(id));
};

const getMultiBatchChildren = props => {
  const { db, id, task, order } = props;

  const barcodes = fp.get("barcode_parcel_children.barcodes", task);

  if (!barcodes) return db.removeTask(id);

  const childCount = order.get("child_count", 0);
  const parentOrderNumber = order.get("number");

  return Observable.defer(() => getChildParcels(fp.join(",", barcodes)))
    .switchMap(({ data }) => {
      const parcels = {};
      if (data) {
        data.forEach(item => {
          parcels[`${item.code}/number`] = item.code;
          parcels[`${item.code}/id`] = item.id;
          parcels[`${item.code}/parent_id`] = parentOrderNumber;
        });

        if (data.length > 0) {
          const parent = {};
          parent[`${parentOrderNumber}/child_count`] =
            childCount - barcodes.length + data.length;

          return Observable.merge(
            db.removeTask(id),
            db.updateBatchOrders(parcels),
            db.updateBatchOrders(parent),
            db.batchRemoveOrders(barcodes),
          );
        }
      }

      return Observable.merge(db.removeTask(id));
    })
    .catch(error => db.updateTask(id, { error: error.message }));
};

const getBatchChildrenTask = props => {
  const { db, id, order } = props;
  const parentId = order.get("number");

  return Observable.defer(() =>
    getBatchChildren(order.getIn(["info", "id"]), { sorting: true }),
  )
    .switchMap(({ children }) => {
      const values = {};
      const parentValue = {};
      const parcels = [];

      if (children) {
        // TODO: code should be changed to barcode
        children.forEach(child => {
          values[`${child.barcode}/number`] = child.barcode;
          values[`${child.barcode}/id`] = child.id;
          values[`${child.barcode}/parent_id`] = parentId;
          values[`${child.barcode}/isSuccess`] = child.status === SUCCESS;

          const transportType = fp.get("transportation_type", child);

          if (
            isParcelServiceType(fp.get("inner_shipment_type", child)) &&
            fp.toLower(fp.get("category", child)) !== INSURED_BAG &&
            transportType !== AIR
          ) {
            parcels.push(child.barcode);
          }
        });

        parentValue[`${order.get("number")}/child_count`] = children.length;
      }

      if (parcels.length > 0)
        return Observable.merge(
          db.removeTask(id),
          db.updateBatchOrders(values),
          db.updateBatchOrders(parentValue),
          db.addBarcodeParcelChildren(parentId, parcels),
        );

      return Observable.merge(
        db.removeTask(id),
        db.updateBatchOrders(parentValue),
        db.updateBatchOrders(values),
      );
    })
    .catch(error => db.updateTask(id, { error: error.message }));
};

const enhancer = compose(
  withTheme,
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  withState("shouldOpenBarcode", "setShouldOpenBarcode", null),
  withState("scannedBarcode", "setScannedBarcode", null),
  withState("isLoadingBarcode", "setIsLoadingBarcode", false),
  connect(
    state => {
      const task = getOrderSortingTask(state);
      const userWarehouse = toJS(getUserWarehouse(state));
      return {
        task,
        userWarehouse,
        userWarehouseId: getUserWarehouseId(state),
        userWarehousesIds: getUserWarehousesIds(state),
        warehouseId: task.getIn(["warehouse", "id"]),
        cursorOrder: getOrderSortingTaskBatchRootOrder(state),
        hasCustomsPermission: hasUserPermission(
          state,
          "INBOUND_SORTING_SENT_TO_CUSTOMS",
        ),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      updateSortingTask,
      addBatchSortingTaskOrderNumber,
      updateCursorOrder,
      clearOrderSortingBatchOrders,
    },
  ),
  withHandlers({
    scanBarcodes: props => ({ orderNumbers, expected, assignToWarehouse }) => {
      props.setIsLoadingBarcode(true);
      return getBarcode(orderNumbers[0])
        .takeLast(1)
        .let(mapObjectResponseStream)
        .do(() => props.setIsLoadingBarcode(false))
        .switchMap(res => {
          const error = res.get("error", Map());
          const order = res.get("payload", Map());
          const db = new OrderBinValidationDB(props.warehouseId);
          const sortingDB = new OrderSortingDB(props.warehouseId);

          const values = {};
          const orderNumber = orderNumbers[0];
          values[`${orderNumber}/failed`] = null;
          values[`${orderNumber}/info`] = order.toJS();
          values[`${orderNumber}/hash`] = order.hashCode();
          values[`${orderNumber}/hash_time`] = Date.now();
          values[`${orderNumber}/scanned_time`] = Date.now();

          if (!order || !order.has("id")) {
            values[`${orderNumber}/failed`] = true;
            const errorMessage = fp.get("response.order", error);
            if (errorMessage) {
              values[`${orderNumber}/failed_message`] = errorMessage;
            }
          }

          if (!order.has("type") && order.has("id") && !expected) {
            values[`${orderNumber}/unexpected`] = true;
          }
          // Add the Order to the Firebase
          const taskStream = db.updateBatchOrders(values);

          let completeBatchStream;
          if (
            order &&
            order.has("type") &&
            props.warehouseId === order.getIn(["to_warehouse", "id"])
          ) {
            completeBatchStream = db.batchUpdateComplete(orderNumber);
          }

          // Check the order is BATCH
          if (
            (order && order.get("type") === SIMPLE) ||
            order.get("type") === CONSOLIDATED ||
            order.get("type") === CONTAINER
          ) {
            // Check if the batch should be opened
            if (!shouldOpen(order, props.warehouseId) && assignToWarehouse) {
              const unOpenedBatch = {};
              unOpenedBatch[`${orderNumber}/number`] = orderNumber;

              if (completeBatchStream) {
                return taskStream.merge(
                  db.updateRegistryStatus(
                    orderNumber,
                    {
                      warehouse: { id: props.warehouseId },
                      status: IN_SORTING_FACILITY,
                    },
                    true,
                  ),
                  completeBatchStream,
                );
              }

              return taskStream.merge(
                db.updateRegistryStatus(
                  orderNumber,
                  {
                    warehouse: { id: props.warehouseId },
                    status: IN_SORTING_FACILITY,
                  },
                  true,
                ),
                // sortingDB.batchUpdateOrders(unOpenedBatch),
              );
            }

            // if (order.get("inner_shipment_type") === PARCEL) {
            //   props.addBatchSortingTaskOrderNumber(Set(null));
            //   return taskStream.merge(db.addBarcodeChildren(orderNumber));
            // }

            if (completeBatchStream) {
              return taskStream.merge(
                db.addBarcodeChildren(orderNumber),
                completeBatchStream,
              );
            }

            // Get Batch Child Orders
            return taskStream.merge(db.addBarcodeChildren(orderNumber));
          }

          if (
            !order.has("type") &&
            order.has("id") &&
            (expected || !order.has("warehouse"))
          ) {
            if (order.get("transportation_type", null) === TRANSPORT_SIMPLE) {
              return taskStream.merge(
                db.updateShipmentStatus(orderNumber, {
                  order_barcodes: [orderNumber],
                  order_status: IN_SORTING_FACILITY,
                  warehouse: { id: props.warehouseId },
                }),
              );
            }

            const sortedShipments = {};
            sortedShipments[`${orderNumber}/number`] = orderNumber;
            // It is shipment and expected order
            return taskStream.merge(
              db.updateShipmentStatus(orderNumber, {
                order_barcodes: [orderNumber],
                order_status: IN_SORTING_FACILITY,
                warehouse: { id: props.warehouseId },
              }),
              sortingDB.batchUpdateOrders(sortedShipments),
            );
          }

          return taskStream;
        });
    },
    onRetryBarcodes: props => ({ barcodes, orders, assign = true }) => {
      const barcodeArray = barcodes.keySeq().toArray();
      const db = new OrderBinValidationDB(props.warehouseId);

      const taskStream = [];
      if (barcodeArray.length > 0) {
        barcodeArray.forEach(order => {
          const expected = orders.has(order);

          taskStream.push(db.retryBarcodes(order, { expected, assign }));
        });
      }

      return Observable.merge(...taskStream).toPromise();
    },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const dbStream = propsStream
          .distinctUntilKeyChanged("warehouseId")
          .map(props => new OrderBinValidationDB(props.warehouseId));

        const initialState = {
          tasks: Map(),
          orders: Map(),
          tree: Map(),
          queue: Map(),
          scannedOrders: Map(),
          retryOrders: Map(),
        };

        const stateStream = dbStream
          .switchMap((db: OrderBinValidationDB) =>
            !db.warehouseId
              ? Observable.of(initialState)
              : Observable.combineLatest(
                  db.getTasks(),
                  db.getBatchOrders(),
                  db.getBatchTree(),
                  db.getBatchQueue(),
                  db.getBatchScannedOrders(),
                  db.getRetryOrders(),
                  (tasks, orders, tree, queue, scannedOrders, retryOrders) => ({
                    tasks,
                    orders,
                    tree,
                    queue,
                    scannedOrders,
                    retryOrders,
                  }),
                ).startWith(initialState),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          ...state,
        }));
      },

      /**
       * Step 2 - Normalize task values.
       */
      // (propsStream) => {
      //   const cursorChildOrdersStream = propsStream
      //     .map(fp.pick(["orders", "task", "warehouseId"]))
      //     .map(({ task, ...otherProps }) => ({...otherProps, activeBatchActiveOrderNumber: task.get("activeBatchActiveOrderNumber", null)}))
      //     .filter((props) => props.warehouseId && props.activeBatchActiveOrderNumber)
      //     .distinctUntilChanged(isEqualData)
      //     .do((props) => {
      //       const db = new OrderBinValidationDB(props.warehouseId);
      //
      //       console.log(props.activeBatchActiveOrderNumber, findParents(props.activeBatchActiveOrderNumber, props.orders))
      //
      //       props.updateSortingTask(() =>
      //         Map({ warehouse: Map(values.warehouse) }),
      //       );
      //     })
      //     .startWith(Map())
      //     .distinctUntilChanged(isEqualData);
      //
      //   return propsStream.combineLatest(
      //     cursorChildOrdersStream,
      //     (props, cursorChildOrders) => ({
      //       ...props,
      //       cursorChildOrders,
      //     }),
      //   );
      // },

      propsStream => {
        const {
          handler: onOrderSubmit,
          stream: onOrderSubmitStream,
        } = createEventHandler();

        const sideEffects = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderBinValidationDB(props.warehouseId);

              return mergeSideEffectStreams(
                onOrderSubmitStream.do(request => {
                  props.addBatchSortingTaskOrderNumber(
                    Set(request.orderNumbers),
                  );
                }),
                onOrderSubmitStream.mergeMap(({ orderNumbers }) => {
                  const orderValues = {};

                  orderNumbers.forEach(orderNumber => {
                    orderValues[`${orderNumber}/number`] = orderNumber;
                    orderValues[`${orderNumber}/scanned`] = true;
                  });

                  return Observable.merge(db.updateBatchOrders(orderValues));
                }),
                onOrderSubmitStream.switchMap(request =>
                  props
                    .scanBarcodes(request)
                    .take(1)
                    .switchMap(() =>
                      request.cursorOrder
                        ? db.checkBatchCompleted(request.cursorOrder, {
                            assignToWarehouse: request.assignToWarehouse,
                          })
                        : Observable.of({}),
                    ),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffects).map(props => ({
          ...props,
          onOrderSubmit,
        }));
      },

      /**
       * Step 4 - Handle order task requests.
       *
       * 1. Handle order size change request.
       * 1. Handle failed task retry request.
       */
      propsStream => {
        const {
          handler: onBatchCompleteRequest,
          stream: onBatchCompleteRequestStream,
        } = createEventHandler();

        const {
          handler: onRetryTaskRequest,
          stream: onRetryTaskRequestStream,
        } = createEventHandler();

        const {
          handler: onCancelTaskRequest,
          stream: onCancelTaskRequestStream,
        } = createEventHandler();

        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderBinValidationDB(props.warehouseId);

              return mergeSideEffectStreams(
                onBatchCompleteRequestStream.mergeMap(x =>
                  db.forceCompleteBatch(x.orderNumber, {
                    details: x.details,
                    assignToWarehouse: x.assignToWarehouse,
                  }),
                ),
                onRetryTaskRequestStream.mergeMap(taskId =>
                  db.retryTask(taskId),
                ),
                onCancelTaskRequestStream.mergeMap(taskId =>
                  db.removeTask(taskId),
                ),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream).map(props => ({
          ...props,
          onBatchCompleteRequest,
          onRetryTaskRequest,
          onCancelTaskRequest,
        }));
      },

      /**
       * Step 5 - Register side effect workers.
       *
       * 1. Sync removed orders in firebase with reducer.
       * 2. Loads orders without hash.
       * 4. Execute order tasks.
       */
      propsStream => {
        const sideEffectsStream = mergeSideEffectStreams(
          propsStream
            .filter(props => props.warehouseId > 0)
            .distinctUntilKeyChanged("warehouseId")
            .switchMap(props => {
              const db = new OrderBinValidationDB(props.warehouseId);
              const sortingDB = new OrderSortingDB(props.warehouseId);

              return mergeSideEffectStreams(
                db.getTaskAddStream().mergeMap(response => {
                  const id = response.key;
                  const payload = response.val();

                  // Remove task if it's corrupted.
                  if (!payload || !payload.task || !payload.number) {
                    return db.removeTask(id);
                  }

                  const { task, number: orderNumber } = payload;

                  return db
                    .getBatchOrder(orderNumber)
                    .take(1)
                    .switchMap(order => {
                      // Remove task if it's order not found or failed to load.
                      if (order.isEmpty() || order.get("failed")) {
                        return db.removeTask(id);
                      }

                      if (task.children) {
                        return getBatchChildrenTask({
                          ...props,
                          id,
                          db,
                          order,
                        });
                      }

                      if (task.registry_status_update) {
                        return batchStatusUpdate({
                          ...props,
                          id,
                          db,
                          task,
                          orderNumber,
                        });
                      }

                      if (task.barcode_parcel_children) {
                        return getMultiBatchChildren({
                          ...props,
                          id,
                          db,
                          task,
                          order,
                        });
                      }

                      if (task.shipment_status_update) {
                        return shipmentStatusUpdate({ ...props, id, db, task });
                      }

                      if (task.complete_batch_update) {
                        return completeBatchUpdate({ ...props, id, db, task });
                      }

                      if (task.shipment_retry_barcode) {
                        return reScanOrders({
                          ...props,
                          orderNumber,
                          id,
                          db,
                          task,
                        });
                        // return props.scanBarcodes({ orderNumbers: [orderNumber], expected: task.shipment_retry_barcode.excepted, assignToWarehouse: task.shipment_retry_barcode.assign });
                      }

                      if (task.refresh) {
                        return refreshOrder({
                          ...props,
                          id,
                          db,
                          task,
                          orderNumber,
                        });
                      }

                      if (task.forceComplete) {
                        return forceCompleteTask({
                          ...props,
                          id,
                          db,
                          task,
                          sortingDB,
                        });
                      }

                      // If it's unknown task - remove it.
                      return db.removeTask(id);
                    });
                }, 5),
              );
            }),
        );

        return propsStream.merge(sideEffectsStream);
      },
    ),
  ),
  pureComponent(
    fp.pick([
      "userWarehouse",
      "userWarehouseId",
      "userWarehousesIds",
      "warehouseId",
      "location",
      "state",
      "task",
      "cursorOrder",
      "scannedBarcode",
      "orders",
      "tree",
      "queue",
      "shouldOpenBarcode",
      "onRetryBarcodes",
    ]),
  ),
);

AdminInboundSortingContainer.propTypes = {
  warehouseId: PropTypes.number,
  userWarehouseId: PropTypes.number,
  shouldOpenBarcode: PropTypes.string,
  scannedBarcode: PropTypes.string,

  userWarehousesIds: PropTypes.array,

  task: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),
  tree: PropTypes.instanceOf(Map),
  cursorChildOrders: PropTypes.instanceOf(Map),
  scannedOrders: PropTypes.instanceOf(Map),
  queue: PropTypes.instanceOf(Map),
  retryOrders: PropTypes.instanceOf(Map),

  onOrderSubmit: PropTypes.func,
  updateSortingTask: PropTypes.func,
  clearOrderSortingBatchOrders: PropTypes.func,

  setLocationQuery: PropTypes.func,
  onBatchCompleteRequest: PropTypes.func,
  setShouldOpenBarcode: PropTypes.func,
  setScannedBarcode: PropTypes.func,
  onRetryBarcodes: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,

  userWarehouse: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  hasCustomsPermission: PropTypes.bool,
  isLoadingBarcode: PropTypes.bool,
};

function AdminInboundSortingContainer(props) {
  const { setUW } = useContext(GlobalContext);
  const classes = useStyles();
  const {
    getLocalisationMessage,
    task,
    tree,
    location: { query },
  } = props;
  const [batchId, setBatchId] = useState(false);

  if (!validateUserWarehouse(props.warehouseId, props.userWarehousesIds)) {
    return (
      <FormWarehouseDialog
        open={true}
        isDisabled={!fp.size(props.userWarehousesIds) > 1}
        initialValues={{ warehouse: props.userWarehouse }}
        includeWarehouses={props.userWarehousesIds}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        onSubmit={values => {
          setUW(_.get(values, "warehouse.id"));
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  const activeBatchActiveOrderNumber = task.get("activeBatchActiveOrderNumber");
  const db = new OrderBinValidationDB(props.warehouseId);
  const orderSortingDB = new OrderSortingDB(props.warehouseId);
  const filteredOrders = props.orders.filter(
    x => x.get("info") && !x.getIn(["info", "type"]),
  );
  const orderBarcodesForCustoms = filteredOrders.keySeq().toArray();
  return (
    <AdminAppLayout
      title={getLocalisationMessage("inbound_sorting_firebase")}
      appBarRightAction={
        <FlexBox>
          {props.hasCustomsPermission && props.orders.size > 0 && (
            <CustomButton
              style={{ margin: ".5rem" }}
              size="small"
              variant={CONTAINED}
              color={SECONDARY}
              onClick={() => {
                const request = {
                  order_status: SENT_TO_CUSTOMS,
                  warehouse: { id: props.warehouseId },
                  order_barcodes: orderBarcodesForCustoms,
                  privacy: PUBLIC,
                };

                batchAsyncUpdateOrder(request)
                  .catch(error => props.showErrorMessage(error))
                  .then(response => {
                    setBatchId(fp.get("data.id", response));
                  });
              }}
            >
              {getLocalisationMessage("finish_and_sent_to_customs")}
            </CustomButton>
          )}
          {props.orders.size > 0 && (
            <CustomButton
              style={{ margin: ".5rem 0" }}
              size="small"
              variant={CONTAINED}
              color={SECONDARY}
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("finish")}
            </CustomButton>
          )}

          <MenuButtonMore color={props.theme.palette.appBarTextColor}>
            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("remove_all", true))}
            >
              {getLocalisationMessage("remove_all", "Remove All")}
            </MenuItem>

            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("log_out_warehouse", "Log Out Warehouse")}
            </MenuItem>
          </MenuButtonMore>
        </FlexBox>
      }
    >
      <FirebaseOfflineDialog />
      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        onRequestClose={() => {
          props.clearOrderSortingBatchOrders();
          props.updateSortingTask(t => t.set("inProgress", false));
          orderSortingDB
            .batchRemoveOrders(orderBarcodesForCustoms)
            .toPromise()
            .catch(ResponseError.throw);
          db.removeBatchOrders()
            .toPromise()
            .catch(ResponseError.throw);
          setBatchId(false);
        }}
      />

      {query.remove_all === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask(t => t.set("inProgress", false));

            return db
              .removeBatchOrders()
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() => props.setLocationQuery(fp.unset("remove_all")));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_all_data",
            "Are you sure you want to remove all data?",
          )}
        </ConfirmDialog>
      )}

      {props.scannedBarcode && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setScannedBarcode(null)}
          onConfirm={() => {
            props.setScannedBarcode(null);

            return props.onOrderSubmit({
              orderNumbers: [props.scannedBarcode],
              expected: props.orders.has(props.scannedBarcode),
              assignToWarehouse: task.get("autoAssign"),
            });
          }}
        >
          {`${getLocalisationMessage("your_current_warehouse_is")} - `}
          <strong>{getValue(props, "userWarehouse.name")}</strong>
          {" ?"}
        </ConfirmDialog>
      )}

      {props.shouldOpenBarcode && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setShouldOpenBarcode(null)}
          onConfirm={() =>
            db
              .addBarcodeChildren(props.shouldOpenBarcode)
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() =>
                props.setLocationQuery(props.setShouldOpenBarcode(null)),
              )
          }
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_open_registry",
            "Are you sure you want to open Registry?",
          )}
        </ConfirmDialog>
      )}

      {query.log_out === "true" && props.orders.size === 0 && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask(x => x.clear());

            return db
              .removeBatchOrders()
              .toPromise()
              .catch(ResponseError.throw)
              .finally(() => props.setLocationQuery(fp.unset("log_out")));
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      {query.log_out === "true" && props.orders.size > 0 && (
        <OrderInboundSortingVerifyOrdersDialog
          open={true}
          orders={props.orders}
          warehouseId={props.warehouseId}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={({ statuses, dbOrders }) =>
            batchBarcodesStatusUpdate(statuses)
              .catch(ResponseError.throw)
              .then(() => {
                props.clearOrderSortingBatchOrders();
                props.updateSortingTask(t => t.set("inProgress", false));

                return Observable.merge(
                  db.removeBatchOrders(),
                  orderSortingDB.batchUpdateOrders(dbOrders),
                )
                  .toPromise()
                  .catch(ResponseError.throw)
                  .finally(() => props.setLocationQuery(fp.unset("log_out")));
              })
          }
        />
      )}

      {task.get("inProgress", false) || props.orders.size > 0 ? (
        <FlexBox container={8} direction="column" className={classes.root}>
          <FlexBox
            element={<Card />}
            direction="column"
            className={classes.bottomSpace}
          >
            <CardHeader title={<h5>{task.getIn(["warehouse", "name"])} </h5>} />
            <CardContent>
              <FlexBox
                flex={true}
                style={{ marginBottom: "5px", fontSize: 20 }}
              >
                <FlexBox
                  flex={true}
                  className={classes.overallInfo}
                  justify={JUSTIFY_SPACE_AROUND}
                >
                  <div>
                    <strong>
                      {getLocalisationMessage("total_orders", "Total Items")}
                    </strong>{" "}
                    {props.orders.size}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                  <div>
                    <strong>
                      {getLocalisationMessage("need_to_scan", "Need to Scan")}:
                    </strong>{" "}
                    {props.orders.size - props.scannedOrders.size}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                  <div>
                    <strong>
                      {getLocalisationMessage(
                        "scanned_orders",
                        "Scanned Items",
                      )}
                      :
                    </strong>{" "}
                    {props.scannedOrders.size}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                </FlexBox>
              </FlexBox>
              <OrderSortingBinValidationForm
                focusInput={!!props.isLoadingBarcode}
                onSubmit={x => {
                  if (
                    props.warehouseId !== props.userWarehouseId &&
                    !task.get("disableWarningWarehouse")
                  ) {
                    props.setScannedBarcode(x[0]);
                  } else {
                    props.onOrderSubmit({
                      orderNumbers: x,
                      expected: props.orders.has(x[0]),
                      assignToWarehouse: task.get("autoAssign"),
                    });
                  }
                }}
                autoAssign={task.get("autoAssign")}
                onAutoAssignChange={x =>
                  props.updateSortingTask(t => t.set("autoAssign", x))
                }
                disableWarningWarehouse={task.get("disableWarningWarehouse")}
                onDisableWarningWarehouse={x =>
                  props.updateSortingTask(t =>
                    t.set("disableWarningWarehouse", x),
                  )
                }
              />

              <FlexBox className={classes.iconsWrapper}>
                <FlexBox>
                  <HourglassEmpty style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("loading", "Loading")}</span>
                </FlexBox>
                <FlexBox>
                  <ReportProblem style={{ color: red[500] }} />
                  <span>{getLocalisationMessage("error", "Error")}</span>
                </FlexBox>
                <FlexBox>
                  <ReportProblem style={{ color: orange[500] }} />
                  <span>{getLocalisationMessage("warning", "Warning")}</span>
                </FlexBox>
                <FlexBox>
                  <CheckCircle style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("success", "Success")}</span>
                </FlexBox>
                <FlexBox>
                  <CheckCircle style={{ color: grey[500] }} />
                  <span>{getLocalisationMessage("inactive", "Inactive")}</span>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </FlexBox>

          <FlexBox flex={true}>
            <FlexBox
              container={16}
              flex={true}
              xsDirection="column"
              lgDirection="row"
              className={classes.content}
            >
              <FlexBox
                direction="column"
                justify={JUSTIFY_SPACE_AROUND}
                flex={true}
                lg={6}
                gutter={16}
                className={cx(classes.card, classes.cardLeft)}
                element={<Card />}
              >
                <OrderSortingBinDetailsCard
                  activeOrder={activeBatchActiveOrderNumber}
                  queueOrders={props.orders}
                  onReloadOrder={orderNumber =>
                    db.refreshOrder(orderNumber).toPromise()
                  }
                />
              </FlexBox>

              <FlexBox
                direction="column"
                flex={true}
                lg={6}
                gutter={16}
                className={cx(classes.card, classes.cardRight)}
                element={<Card />}
              >
                <FlexBox>
                  <OrderSortingBinHeirarchyCard
                    onBatchCompleteRequest={request =>
                      props.onBatchCompleteRequest({
                        assignToWarehouse: task.get("autoAssign"),
                        ...request,
                      })
                    }
                    warehouseId={props.warehouseId}
                    onOpenRegistry={barcode => {
                      props.setShouldOpenBarcode(barcode);
                    }}
                    onRetry={barcode => {
                      props.onOrderSubmit({
                        orderNumbers: [barcode],
                        expected: props.orders.has(barcode),
                        assignToWarehouse: task.get("autoAssign"),
                      });
                    }}
                    onRetryAllBarcodes={() =>
                      props.onRetryBarcodes({
                        barcodes: props.retryOrders,
                        orders: props.orders,
                        assign: task.get("autoAssign"),
                      })
                    }
                    cursorChildOrders={props.cursorChildOrders}
                    orders={props.orders}
                    queueOrders={props.queue}
                    cursorOrder={activeBatchActiveOrderNumber}
                    pendingOrdersCount={
                      props.orders.size - props.scannedOrders.size
                    }
                    tree={tree}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox container={8} direction="column" className={classes.root}>
          <FlexBox
            element={<Card />}
            direction="column"
            justify="center"
            className={classes.bottomSpace}
          >
            <FlexBox direction="column" align="center">
              <CardHeader
                title={<h5>{task.getIn(["warehouse", "name"])} </h5>}
              />

              <CustomButton
                variant={CONTAINED}
                color={SECONDARY}
                size="large"
                onClick={() =>
                  props.updateSortingTask(t => t.set("inProgress", true))
                }
              >
                {getLocalisationMessage(
                  "start_inbound_process",
                  "Start Inbound Process",
                )}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminInboundSortingContainer);
