import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { createEventHandler, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { TextField, CircularProgress } from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";
import { uploadFile } from "../../api/shared/FileApi";

const FileUploadButtonComponent = componentFromStream(propsStream => {
  const { handler: onChange, stream: onChangeStream } = createEventHandler();

  const uploadStream = onChangeStream
    .map(fp.get(["target", "files", 0]))
    .withLatestFrom(propsStream)
    .do(([, props]) => props.input.onChange(null))
    .switchMap(
      ([file]) =>
        uploadFile(file)
          .catch(error => Observable.of({ error }))
          .map(fp.update("payload", fp.get("data.url"))),
      ([, props], response) => [response, props],
    )
    .do(([response, props]) => {
      if (response.payload) {
        props.input.onChange(response.payload);
      }
    })
    .map(fp.nth(0) /* response */)
    .startWith(null);

  return propsStream.combineLatest(
    uploadStream,
    ({ meta, input, ...custom }, upload) =>
      upload && upload.pending ? (
        <FlexBox justify="center">
          <CircularProgress
            size={24}
            thickness={2}
            mode="determinate"
            value={upload.progress}
          />
        </FlexBox>
      ) : (
        <TextField
          {...input}
          {...custom}
          type="file"
          errorText={!meta.active && meta.touched && meta.error}
          value=""
          onChange={onChange}
          onBlur={fp.flow(fp.noop, input.onBlur)}
          onFocus={fp.flow(fp.noop, input.onFocus)}
        />
      ),
  );
});

FormFileUploadField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  fullWidth: PropTypes.bool,
  hintText: PropTypes.node,
};

export default function FormFileUploadField(props) {
  return <Field {...props} component={FileUploadButtonComponent} />;
}
