import fp from "lodash/fp";
import DataListFilter from "./DataListFilter";
import { mapSchema, queryTypes } from "../../shared/helpers/ObjectMapper";

export const toCallTimingsFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    courierType: queryTypes.stringSeq,
    addressType: queryTypes.stringSeq,
    callTimingPermissionType: queryTypes.stringSeq,
  }),
);
