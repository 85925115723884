import React from "react";
import { OrderedSet } from "immutable";
import useSheet from "react-jss";
import { pure, compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, ListSubheader, Button } from "@material-ui/core";
import FormFileField from "../form/FormFileField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormVenueCategoryAutoComplete from "../form/FormVenueCategoryAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";

const enhancer = compose(
  withContext(
    {
      getCachedVenueCategory: PropTypes.func,
      getVenueCategoryPredictions: PropTypes.func,
    },
    props => ({
      getCachedVenueCategory: props.getCachedVenueCategory,
      getVenueCategoryPredictions: props.getVenueCategoryPredictions,
    }),
  ),
  useSheet({
    photo: {
      textAlign: "center",
      marginBottom: "12px",
      "& > img": { maxWidth: "100%" },
    },
    actionsRow: { height: "60px" },
  }),
  reduxForm({
    form: "AdminShopMenuForm",
    enableReinitialize: true,
    validate: values => ({
      name: !values.name && "Enter Name",
      sorder: !values.sorder && "Enter Sort Order",
      status: !values.status && "Select Status",
      parent: !isValidObjectId(values.parent) && "Select Parent Menu",
    }),
  }),
  formValues("image"),
  pure,
);

const shopMenuOptions = OrderedSet.of(ACTIVE, INACTIVE);

AdminShopMenuForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDismissClick: PropTypes.func.isRequired,
  image: PropTypes.string,
  getCachedVenueCategory: PropTypes.func.isRequired,
  getVenueCategoryPredictions: PropTypes.func.isRequired,
};

function AdminShopMenuForm(props) {
  const { classes } = props;

  return (
    <Card>
      <CardContent>
        <PageLoading isLoading={props.submitting} />

        <form autoComplete="off" onSubmit={props.handleSubmit}>
          <FlexBox container={8} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <FlexBox gutter={8} flex={true} direction="column">
                  <FlexBox flex={true}>
                    <FormTextField name="name" label="Name" fullWidth={true} />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormVenueCategoryAutoComplete
                      name="parent"
                      fullWidth={true}
                      label="Select Parent Menu"
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="status"
                      autoWidth={true}
                      fullWidth={true}
                      label="Status"
                      options={shopMenuOptions}
                      formatOption={formatText}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="sorder"
                      label="Sort Order"
                      fullWidth={true}
                      type="number"
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true}>
                <FlexBox gutter={8}>
                  <FlexBox direction="column">
                    <ListSubheader>Photo</ListSubheader>
                    {Boolean(props.image) && (
                      <div className={classes.photo}>
                        <img alt="Menu" src={props.image} />
                      </div>
                    )}
                    <FormFileField
                      fullWidth={true}
                      name="image"
                      label="Upload Image"
                      accept="image/*"
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox gutter={8} className={classes.actionsRow} align="flex-end">
              <FlexBox>
                <Button
                  variant="text"
                  label="Dismiss"
                  onClick={props.onDismissClick}
                />
              </FlexBox>
              <FlexBox>
                <Button variant="text" label="Save" type="submit" />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </CardContent>
    </Card>
  );
}

export default enhancer(AdminShopMenuForm);
