import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import { getCashier } from "../../api/admin/AdminFinanceApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const CashierAutoComplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getCashier(baseFilter.setValue("cashier_id", value.id))
            .takeLast(1)
            .map(
              fp.flow(
                fp.get("payload.data"),
                fp.toArray,
                fp.first,
                fp.pick(["id", "name"]),
              ),
            )
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value.name"))
    .switchMap(({ input: { value } }) =>
      getCashier
        ? getCashier(baseFilter.setSearch(value && value.name))
            .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
            .catch(() => Observable.of(List()))
        : Observable.of(List()),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS());

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    ({ getCachedCashier, ...props }, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ),
  );
});

FormCashierAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormCashierAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormCashierAutoComplete(props) {
  return <Field {...props} component={CashierAutoComplete} />;
}
