import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import Barchart from "../charts/Barchart";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import AdminDashboardFilterDialogWrapper from "../../wrappers/admin/dashboard/AdminDashboardFilterDialogWrapper";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import { getJMById } from "../../api/shared/CountryV2Api";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";

const localisationFromJurisdictionMessages = (
  jurisdiction,
  currentLanguage,
) => {
  switch (currentLanguage) {
    case UZBEK_LANGUAGE:
      return (
        <div>
          <span style={{ textDecoration: "underline" }}>{jurisdiction}</span>{" "}
          dan viloyatlarga yaratilingan jo'natmalar soni
        </div>
      );

    case RUSSIAN_LANGUAGE:
      return (
        <div>
          Из <span style={{ textDecoration: "underline" }}>{jurisdiction}</span>{" "}
          в провинции
        </div>
      );

    default:
      return (
        <div>
          From{" "}
          <span style={{ textDecoration: "underline" }}>{jurisdiction}</span> to
          provinces
        </div>
      );
  }
};

const localisationFromJProvincesMessages = (jurisdiction, currentLanguage) => {
  switch (currentLanguage) {
    case UZBEK_LANGUAGE:
      return (
        <div>
          Viloyatlardan{" "}
          <span style={{ textDecoration: "underline" }}>{jurisdiction}</span> ga
          yaratilingan jo'natmalar soni
        </div>
      );

    case RUSSIAN_LANGUAGE:
      return (
        <div>
          Из провинций в{" "}
          <span style={{ textDecoration: "underline" }}>{jurisdiction}</span>{" "}
        </div>
      );

    default:
      return (
        <div>
          From provinces to{" "}
          <span style={{ textDecoration: "underline" }}>{jurisdiction}</span>{" "}
        </div>
      );
  }
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderCountByJurisdictionChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  filter: PropTypes.object,
  api: PropTypes.string,
  currentLanguage: PropTypes.string,
  out: PropTypes.bool,
};

function AdminOrderCountByJurisdictionChartWrapper(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState();
  const doughnutRef = React.useRef(null);
  const [chartData, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterRefresh, setFilterRefresh] = useState(false);
  const [filter, setFilter] = useState({
    ...props.filter,
  });

  const [jurisdiction, setJurisdiction] = useState({});

  useEffect(() => {
    setFilterRefresh(false);

    setFilter({ ...props.filter });
  }, [props.filter]);

  useEffect(() => {
    if (filter.jurisdiction_id) {
      getJMById(filter.jurisdiction_id).then(res => setJurisdiction(res.data));
    }
  }, [filter.jurisdiction_id]);

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    props
      .api(filterRefresh ? filter : props.filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          setNote(null);
          setData({
            labels: barData.map(item => item.label),
            datasets: [
              {
                label: props.getLocalisationMessage(
                  "jurisdiction",
                  "Jurisdiction",
                ),
                data: barData.map(item => item.value),
                backgroundColor: barData.map(() => "#3F51B5"),
                hoverBackgroundColor: "#F50057",
              },
            ],
          });
        }
        setIsLoading(false);
        setFilterRefresh(false);
      })
      .catch(() => {
        setData([]);
        setFilterRefresh(false);

        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
      });
  }, [props.filter, filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={doughnutRef}
    >
      <AdminDashboardFilterDialogWrapper
        open={open}
        onRequestClose={() => {
          setOpen(false);
          setFilterRefresh(false);
        }}
        initialValues={{
          jurisdiction: {
            id: filter.jurisdiction_id,
          },
          fromDateTime: safeParseDate(filter.from_date_time),
          toDateTime: safeParseDate(filter.to_date_time),
        }}
        onSubmit={v => {
          setFilter({
            jurisdiction_id: getValue(v, "jurisdiction.id"),
            from_date_time: formatDateTimeToUrl(v.fromDateTime),
            to_date_time: formatDateTimeToUrl(v.toDateTime),
          });
          setOpen(false);
          setFilterRefresh(true);
        }}
      />

      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {!props.out
              ? localisationFromJProvincesMessages(
                  getValue(jurisdiction, "name"),
                  props.currentLanguage,
                )
              : localisationFromJurisdictionMessages(
                  getValue(jurisdiction, "name"),
                  props.currentLanguage,
                )}
          </FlexBox>
          <FlexBox>
            <CustomButton
              color={SECONDARY}
              fullWidth={true}
              startIcon={<FilterList />}
              onClick={() => {
                setOpen(true);
                setFilterRefresh(false);
              }}
            >
              {props.getLocalisationMessage("filter", "Filter")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && doughnutRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(doughnutRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading &&
        (note ? (
          <FlexBox
            justify="center"
            align="flex-end"
            style={{ height: 400, position: "relative", gap: "10px" }}
          >
            <FlexBox
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              align="center"
              justify="center"
            >
              <h4>
                <strong>{note}</strong>
              </h4>
            </FlexBox>
          </FlexBox>
        ) : (
          <Barchart
            width={450}
            height={400}
            data={chartData}
            tooltipFormat={{
              callbacks: {
                label(tooltipItem, data) {
                  const datasetLabel = data.labels[tooltipItem.index];
                  return `${datasetLabel}:  ${new Intl.NumberFormat(
                    "ru-RU",
                  ).format(tooltipItem.yLabel)}`;
                },
              },
            }}
          />
        ))}
    </Card>
  );
}

export default enhancer(AdminOrderCountByJurisdictionChartWrapper);
