import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import { uploadCSVTemplate } from "../../actions/AdminOrderActions";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  DOMESTIC,
  OVERWEIGHT,
  INTERNATIONAL,
} from "../../constants/OrderCSVTemplateTypes";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import UploadButton from "../../components/deprecated/UploadButton";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  DOWNLOAD_ORDER_CSV_TEMPLATE_URL,
  DOWNLOAD_ORDER_CSV_V2_TEMPLATE_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

AdminSettingsOrderContainer.propTypes = {
  sheet: PropTypes.object,
  uploadCSVTemplate: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

const enhancer = compose(
  useSheet({
    title: { padding: "12px 24px" },
    uploadButton: { verticalAlign: "middle" },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { uploadCSVTemplate, showErrorMessage, showSuccessMessage },
  ),
);

export default enhancer(AdminSettingsOrderContainer);

function AdminSettingsOrderContainer(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout title={getLocalisationMessage("dashboard", "Dashboard")}>
      <FlexBox container={8}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <Card>
                  <h4 className={props.sheet.classes.title}>
                    {getLocalisationMessage("domestic", "Domestic")}
                  </h4>
                  <CardActions>
                    <UploadButton
                      className={props.sheet.classes.uploadButton}
                      label={getLocalisationMessage(
                        "upload_csv_template",
                        "Upload CSV Template",
                      )}
                      uploadFileStream={props.uploadCSVTemplate(DOMESTIC)}
                      onChange={() =>
                        props.showSuccessMessage(
                          getLocalisationMessage("done", "Done"),
                        )
                      }
                      onError={error => props.showErrorMessage(error)}
                    />
                    <Button
                      component="a"
                      target="_blank"
                      href={updateQuery(DOWNLOAD_ORDER_CSV_TEMPLATE_URL, {
                        fileType: DOMESTIC,
                      })}
                    >
                      {getLocalisationMessage(
                        "download_csv_template",
                        "Download CSV Template",
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <Card>
                  <h4 className={props.sheet.classes.title}>
                    {getLocalisationMessage("international", "International")}
                  </h4>
                  <CardActions>
                    <UploadButton
                      label={getLocalisationMessage(
                        "upload_csv_template",
                        "Upload CSV Template",
                      )}
                      className={props.sheet.classes.uploadButton}
                      uploadFileStream={props.uploadCSVTemplate(INTERNATIONAL)}
                      onChange={() =>
                        props.showSuccessMessage(
                          getLocalisationMessage("done", "Done"),
                        )
                      }
                      onError={error => props.showErrorMessage(error)}
                    />
                    <Button
                      component="a"
                      target="_blank"
                      href={updateQuery(DOWNLOAD_ORDER_CSV_TEMPLATE_URL, {
                        fileType: INTERNATIONAL,
                      })}
                    >
                      {getLocalisationMessage(
                        "download_csv_template",
                        "Download CSV Template",
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <Card>
                  <h4 className={props.sheet.classes.title}>
                    {getLocalisationMessage("overweight", "Overweight")}
                  </h4>
                  <CardActions>
                    <UploadButton
                      className={props.sheet.classes.uploadButton}
                      label={getLocalisationMessage(
                        "upload_csv_template",
                        "Upload CSV Template",
                      )}
                      uploadFileStream={props.uploadCSVTemplate(OVERWEIGHT)}
                      onChange={() =>
                        props.showSuccessMessage(
                          getLocalisationMessage("done", "Done"),
                        )
                      }
                      onError={error => props.showErrorMessage(error)}
                    />
                    <Button
                      component="a"
                      target="_blank"
                      href={updateQuery(DOWNLOAD_ORDER_CSV_TEMPLATE_URL, {
                        fileType: OVERWEIGHT,
                      })}
                    >
                      {getLocalisationMessage(
                        "download_csv_template",
                        "Download CSV Template",
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <Card>
                  <h4 className={props.sheet.classes.title}>
                    {getLocalisationMessage(
                      "new_model_domestic",
                      "New Model Domestic",
                    )}
                  </h4>
                  <CardActions>
                    <Button
                      component="a"
                      target="_blank"
                      href={updateQuery(DOWNLOAD_ORDER_CSV_V2_TEMPLATE_URL)}
                    >
                      {getLocalisationMessage(
                        "download_csv_template",
                        "Download CSV Template",
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}
