import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-leflet-map-components";
import PinCompleted from "./assets/pin_completed.png";
import PinIncomplete from "./assets/pin_incomplete.png";

const enhancer = compose();

LPinMarker.propTypes = {
  size: PropTypes.number,
  complete: PropTypes.bool,

  position: PropTypes.object.isRequired,
};

LPinMarker.defaultProps = {
  size: 45,
};

function LPinMarker(props) {
  const { size, complete, ...markerProps } = props;

  return (
    <Marker
      {...markerProps}
      icon={
        <MarkerIcon
          size={{ width: size, height: size }}
          scaledSize={{ width: size, height: size }}
          url={complete ? PinCompleted : PinIncomplete}
          anchor={{ y: size, x: size / 2 }}
          origin={{ x: size, y: size / 2 }}
        />
      }
    />
  );
}

export default enhancer(LPinMarker);
