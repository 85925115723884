import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { DISPATCHED } from "../../constants/OrderStatusCodes";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { INACTIVE } from "../../constants/OverallStatus";
import BatchOrdersAssignToDriverDialog from "../../components/orders-core/BatchOrdersAssignToDriverDialog";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { getPostcodePredictions } from "../../api/admin/AdminPostcodesApi";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";
import { getSortingBatchUpdateItem } from "../../api/v2/admin/AdminOrderSortingApi";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

OfflineBatchAssignOrdersToDriverFormDialogWrapper.propTypes = {
  open: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  userWarehouseId: PropTypes.number,
  selectedOrderBarcodes: PropTypes.array,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  filter: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function OfflineBatchAssignOrdersToDriverFormDialogWrapper({
  selectedOrderBarcodes,
  userWarehouseId,
  ...props
}) {
  const [barcodes, setBarcodes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      if (
        props.filter.service_type_id ||
        props.filter.bean ||
        selectedOrderBarcodes.length
      ) {
        setIsLoading(true);
        getSortingBatchUpdateItem({
          ...props.filter,
          type: "ORDER",
          barcodes: selectedOrderBarcodes,
        })
          .then(res => {
            const orderBarcodes = [];
            res.items.map(item => {
              orderBarcodes.push(item.barcode);
              return item;
            });
            setBarcodes(orderBarcodes);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      }
    }
  }, [props.filter, selectedOrderBarcodes]);

  return (
    <BatchOrdersAssignToDriverDialog
      open={props.open}
      isLoading={isLoading}
      initialValues={{
        orderBarcodes: barcodes,
        batch_type: INACTIVE,
        privacy: PUBLIC,
        orderStatus: DISPATCHED,
        supplierDriverOnly: true,
        supplier: { id: 1 },
        warehouse: { id: userWarehouseId },
      }}
      onRequestClose={() => {
        props.onRequestClose();
      }}
      onSubmit={fp.flow(toSnakeCase, values =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      getPostcodePredictions={getPostcodePredictions}
      disableBarcodes={props.disableBarcodes}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
    />
  );
}

export default enhancer(OfflineBatchAssignOrdersToDriverFormDialogWrapper);
