import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import sprintf from "sprintf";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { SHOP_PRODUCT_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getCachedVenueMenu,
  getVenueMenuPredictions,
} from "../../api/admin/AdminShopMenuApi";
import {
  getProductItem,
  updateProductItem,
} from "../../api/admin/AdminShopProductApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AdminVenueProductsForm from "../../components/admin/AdminVenueProductsForm";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  connect(null, { showErrorMessage, showSuccessMessage }),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .combineLatest(
            propsStream.map(fp.flow(fp.get("params.venueId"), fp.toFinite)),
            propsStream.map(fp.flow(fp.get("params.productId"), fp.toFinite)),
            (props, venueId, productId) => ({
              ...props,
              venueId,
              productId,
            }),
          )
          .distinctUntilChanged(isEqualData),
      propsStream => {
        const productItemResponseStream = propsStream
          .distinctUntilKeyChanged("productId")
          .switchMap(props =>
            getProductItem(props.venueId, props.productId).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            productItemResponseStream,
            (props, productItemResponse) => ({
              ...props,
              productItem: productItemResponse.get("payload"),
              isLoading: productItemResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminVenueProductItem.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  venueId: PropTypes.number,
  productId: PropTypes.number,
  isLoading: PropTypes.bool,
  setLocation: PropTypes.func,
  productItem: PropTypes.instanceOf(Map),
};

function AdminVenueProductItem(props) {
  return (
    <AdminAppLayout
      title={
        props.isLoading
          ? "Product"
          : `Product - ${props.productItem.get("name", "")}`
      }
    >
      <PageLoading isLoading={props.isLoading} />
      <AdminVenueProductsForm
        onSubmit={({ createdDate, ...values }) =>
          updateProductItem(
            props.venueId,
            props.productId,
            toSnakeCase(values),
          ).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage("Successfully Saved");
          props.setLocation(sprintf(SHOP_PRODUCT_LIST_URL, props.venueId));
        }}
        onSubmitFail={props.showErrorMessage}
        onDismissClick={() =>
          props.setLocation(sprintf(SHOP_PRODUCT_LIST_URL, props.venueId))
        }
        initialValues={{
          ...toCamelCase(props.productItem),
          status: _.lowerCase(props.productItem.get("status")),
        }}
        getCachedVenueMenu={getCachedVenueMenu}
        getVenueMenuPredictions={getVenueMenuPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminVenueProductItem);
