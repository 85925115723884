import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CustomButton from "../ui-core/CustomButton";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import { CircularProgress, LinearProgress, TextField } from "@material-ui/core";
import { SAVE } from "./MUITable";
import { Cancel, Delete, Edit, Save } from "@material-ui/icons";

export const LINK = "link";
export const DIALOG = "dialog";
export const DATE = "date";
export const COLUMN = "column";
export const RENDER = "render";
export const ACTION = "action";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {props.isCheckboxEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array,
  isCheckboxEnabled: PropTypes.bool,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: "100vh",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  container: {
    maxHeight: "calc(100vh - 11rem)",
  },
});

function BatchCategoriesTable({
  columns,
  getLocalisationMessage,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  list,
  rowsPerPage,
  total,
  withCheckbox,
  headerActions,
  size,
  title,
  isLoading,
  editableRow,
  isLoadingUpdate,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState(editableRow.name);
  useEffect(() => {
    if (editableRow.name) {
      setName(editableRow.name);
    }
  }, [editableRow]);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, x) => {
    const selectedIndex = selected.indexOf(x);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, x);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (x) => selected.indexOf(x) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <FlexBox
          flex={true}
          justify={JUSTIFY_SPACE_AROUND}
          align={ALIGN_CENTER}
        >
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          {headerActions}
        </FlexBox>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={total}
              headCells={columns}
              isCheckboxEnabled={withCheckbox}
            />
            {isLoading ? (
              <LinearProgress
                style={{ position: "absolute", width: "100%" }}
                mode="indeterminate"
                color="secondary"
              />
            ) : (
              <TableBody>
                {list.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover={true}
                      onClick={(event) =>
                        withCheckbox && handleClick(event, row.name)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {withCheckbox && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                      )}
                      {columns.map((item, i) => (
                        <TableCell align="center" key={i} padding="normal">
                          {row.id === editableRow.id && item.name === "name" ? (
                            <TextField
                              variant="outlined"
                              size="small"
                              label={item.label}
                              value={name}
                              onChange={handleChange}
                            />
                          ) : item.type === RENDER ? (
                            item.render(row)
                          ) : item.type === COLUMN ? (
                            row[item.name]
                          ) : item.type === SAVE ? (
                            row.id === editableRow.id ? (
                              <React.Fragment>
                                <CustomButton
                                  onClick={() =>
                                    item.cancelDispatch(row.id, name)
                                  }
                                  endIcon={<Cancel />}
                                >
                                  {getLocalisationMessage("cancel")}
                                </CustomButton>
                                <CustomButton
                                  onClick={() =>
                                    item.saveDispatch(row.id, name)
                                  }
                                  endIcon={
                                    isLoadingUpdate ? (
                                      <CircularProgress
                                        size={20}
                                        color="secondary"
                                      />
                                    ) : (
                                      <Save />
                                    )
                                  }
                                >
                                  {getLocalisationMessage("save")}
                                </CustomButton>
                              </React.Fragment>
                            ) : (
                              <CustomButton
                                onClick={() => item.editDispatch(row)}
                                endIcon={<Edit />}
                              >
                                {getLocalisationMessage("edit")}
                              </CustomButton>
                            )
                          ) : (
                            <CustomButton
                              onClick={() => item.dispatch(row.id)}
                              endIcon={<Delete />}
                            >
                              {item.label}
                            </CustomButton>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          <TablePagination
            style={{
              position: "fixed",
              background: "white",
              bottom: 0,
              width: "100vw",
              borderTop: "1px solid rgba(0, 0, 0, 0.3)",
            }}
            labelRowsPerPage={getLocalisationMessage("rows_per_page")}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </div>
  );
}

BatchCategoriesTable.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  columns: PropTypes.array,
  total: PropTypes.number,
  withCheckbox: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  headerActions: PropTypes.any,
  title: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  isLoading: PropTypes.bool,
  editableRow: PropTypes.object,
  isLoadingUpdate: PropTypes.bool,
};

export default enhancer(BatchCategoriesTable);
