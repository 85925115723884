import React from "react";
import { fromJS } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ADDRESS_BOOK_LIST_URL } from "../../constants/AdminPathConstants";
import { createAddressBook } from "../../api/shared/AdminAddressBookApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import AddressBookForm from "../../components/address-book-core/AddressBookForm";

const enhancer = compose(
  getContext({ setLocation: PropTypes.func.isRequired }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminAddressBookCreate.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminAddressBookCreate(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "create_address_book",
        "Create Address Book",
      )}
    >
      <AddressBookForm
        onSubmit={({ country, markLocation, ...values }) =>
          createAddressBook(toSnakeCase(values)).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
          props.setLocation(ADDRESS_BOOK_LIST_URL);
        }}
        onSubmitFail={props.showErrorMessage}
        initialValues={{
          country: fromJS({
            name: "United Arab Emirates",
            code: "AE",
          }),
        }}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminAddressBookCreate);
