import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InfoIcon from "../icons/InfoIcon";
import { getMessages } from "../../reducers/LocalizationReducer";

const labelEnhancer = compose(
  useSheet({
    root: {
      color: "#707181",
      fontSize: 12,
      lineHeight: 1.2,
    },
    title: {
      fontWeight: "normal",
      fontSize: 18,
      color: "#10132D",
    },
    iconSvg: {
      marginLeft: 5,
    },
    description: {
      lineHeight: 1.2,
      color: "#707181",
      marginTop: 5,
      marginBottom: 5,
      fontSize: 12,
    },
    totalLabel: {
      fontSize: 14,
      color: "#707181",
    },
    totalPrice: {
      fontSize: 20,
      color: "#10132D",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
);
SubscriptionCheckboxLabel.propTypes = {
  classes: PropTypes.object,
  i18n: PropTypes.object,
  title: PropTypes.string.isRequired,
  priceLabel: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function SubscriptionCheckboxLabel({
  classes,
  title,
  description,
  priceLabel,
  price,
  i18n,
}) {
  return (
    <div>
      <h5 className={classes.title}>
        {title}
        {false && <InfoIcon className={classes.iconSvg} />}
      </h5>
      <p className={classes.description}>{description}</p>
      <strong className={classes.totalPrice}>{price}</strong>/
      <span className={classes.totalLabel}>
        {priceLabel || i18n.get("month", "month")}
      </span>
    </div>
  );
}

export default labelEnhancer(SubscriptionCheckboxLabel);
